import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import logger from 'core/logger';
import { labConstants } from 'domain/lab';
import { isAllowed } from 'core/permission';
import { DataGridCard } from 'core/dataGrid';
import { useDeviceSize } from 'design-system/hooks';
import { dateTimeFormat } from 'constants/dateTime';
import { orderUtils, orderConstants } from 'domain/order';
import { consultationConstants } from 'domain/consultation';
import {
  patientHistoryGetters as getters,
  patientHistoryConstants as constants,
} from 'domain/patientHistory';
import {
  Box,
  Chip,
  Grid,
  Button,
  Tooltip,
  DataGrid,
  IconButton,
  FilePreview,
} from 'design-system';

const ERROR_MESSAGE = 'Activity type is not defined';

const { ACTIVITY_TYPES } = constants;
const { STATUSES: ORDER_STATUSES } = orderConstants;
const { STATUSES: CONSULTATION_STATUSES } = consultationConstants;
const { STATUSES: LAB_STATUSES } = labConstants;

const CustomNoRowsOverlay = ({ userId, fetchPatientHistory }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" mt={5}>
      <Grid item>
        <Button
          variant="filled"
          spinning={!userId}
          onClick={() => fetchPatientHistory(userId)}
        >
          Load Patient Health History
        </Button>
      </Grid>
    </Grid>
  );
};

const PatientHistorySection = ({
  clear,
  userId,
  getFilesOf,
  referenceId,
  fetchFilesOf,
  referenceType,
  isFetchingFilesOf,
  isFetching = false,
  patientHistory = [],
  fetchPatientHistory,
}) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDeviceSize();
  const isSmall = useMemo(() => isMobile || isTablet, [isMobile, isTablet]);

  const [rows, setRows] = useState([]);
  const [noRecordsAvailable, setNoRecordsAvailable] = useState(false);

  const onRowClick = (row, isInNewTab) => {
    let mainRoute;
    const id = getters.getId(row);
    const type = getters.getType(row);

    if (isAllowed('patientHistory', ACTIVITY_TYPES[type]?.permissionKey)) {
      mainRoute = ACTIVITY_TYPES[type]?.route;
    }

    if (!type || !mainRoute) {
      logger.error(ERROR_MESSAGE);
      throw new Error(ERROR_MESSAGE);
    }

    if (mainRoute) {
      if (isInNewTab) {
        window.open(`/${mainRoute}/${id}`, '_blank');
      } else {
        navigate(`/${mainRoute}/${id}`);
      }
    }
  };

  // TODO: If a general pattern, move it to DataGrid.jsx
  const updateNoRowsOverlay = () => {
    const overlayEl = document.querySelector(
      '.patient-history .MuiDataGrid-virtualScroller'
    )?.parentElement;

    if (overlayEl) {
      if (rows?.length) {
        overlayEl.style.overflow = 'visible';
      } else {
        overlayEl.style.overflow = 'hidden';
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'type',
      headerName: 'Activity',
      flex: 1,
      valueGetter: (params) =>
        ACTIVITY_TYPES[getters.getType(params.row)]?.label,
    },
    {
      field: 'doctor',
      headerName: 'Doctor',
      // TODO: Uncomment if pagination becomes supported
      // hideSortIcons: true,
      // disableColumnMenu: true,
      // sortable: false,
      flex: 1,
      hide: isMobile,
      valueGetter: (params) => getters.getDoctor(params.row) || 'N/A',
    },
    {
      field: 'channel',
      headerName: 'Channel',
      flex: 1,
      hide: isSmall,
      valueGetter: (params) => getters.getChannel(params.row),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: isMobile ? 2 : 1,
      renderCell: (params) => {
        let statusKey;
        let statusList;
        const type = getters.getType(params.row);
        const rawStatus = getters.getStatus(params.row);

        switch (type) {
          case ACTIVITY_TYPES.ORDER.key:
            // Comes as a label, need to get the key
            statusKey = orderUtils.getKey(ORDER_STATUSES, rawStatus, rawStatus);
            statusList = ORDER_STATUSES;
            break;
          case ACTIVITY_TYPES.CONSULTATION.key:
            statusKey = rawStatus;
            statusList = CONSULTATION_STATUSES;
            break;
          case ACTIVITY_TYPES.LAB_TEST_REQUEST.key:
            statusKey = rawStatus;
            statusList = LAB_STATUSES;
            break;
          default:
            logger.error(ERROR_MESSAGE);
            throw new Error(ERROR_MESSAGE);
        }

        return (
          <Tooltip placement="top" title="Status">
            <Chip
              sx={{ minWidth: { xs: 0, md: 100 } }}
              variant="filled"
              soak="light"
              label={statusList[statusKey]?.label}
              color={statusList[statusKey]?.color}
            />
          </Tooltip>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1.3,
      hide: isSmall,
      valueGetter: (params) => {
        const date =
          getters.getCreatedAt(params.row) || getters.getUpdatedAt(params.row);

        if (date) {
          return format(parseISO(date), dateTimeFormat);
        }

        return '-';
      },
    },
    {
      field: 'files',
      headerName: 'Documents',
      flex: 1.5,
      hide: isMobile,
      renderCell: (params) => {
        const files = getters.getFiles(params.row);
        const refId = getters.getId(params.row);
        const refType = getters.getType(params.row);
        const fetchedFiles = getFilesOf(refType, refId);
        const isFetchingFiles = isFetchingFilesOf(refType, refId);

        if (fetchedFiles || isFetchingFiles) {
          return (
            <Box my={1}>
              <FilePreview
                size="small"
                files={fetchedFiles}
                loading={
                  isFetchingFiles && { count: Math.min(files.length, 4) }
                }
              />
            </Box>
          );
        }

        return (
          <Button
            size="medium"
            variant="filled"
            disabled={!files?.length}
            onClick={(e) => {
              e.stopPropagation();
              fetchFilesOf(files, refType, refId);
            }}
          >
            {(() => {
              if (files?.length) {
                return `Show ${files?.length} document${
                  files?.length > 1 ? 's' : ''
                }`;
              }
              return 'No documents available';
            })()}
          </Button>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      flex: 1,
      hide: isSmall,
      renderCell: () => (
        <IconButton>
          <MessageOutlinedIcon fontSize="small" color="secondary" />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    updateNoRowsOverlay();
  });

  useEffect(() => {
    return () => {
      clear();
      setRows([]);
      setNoRecordsAvailable(false);
    };
  }, []);

  useEffect(() => {
    if (patientHistory && referenceId && referenceType) {
      const filteredRows = patientHistory.filter((record) => {
        const recordId = getters.getId(record);
        const recordType = getters.getType(record);
        // Filter current viewable record from the list
        if (recordType === referenceType && recordId === referenceId) {
          return false;
        }

        return true;
      });

      setRows(filteredRows);
      setNoRecordsAvailable(patientHistory?.length && !filteredRows.length);
    } else {
      // Either empty or full
      setRows(patientHistory);
    }
  }, [referenceId, referenceType, patientHistory]);

  return (
    <div className="patient-history">
      <DataGridCard
        header={{
          title: 'Patient Health History',
        }}
      >
        <DataGrid
          rows={rows}
          pageSize={5}
          columns={columns}
          loading={isFetching}
          onRowClick={(prms) => onRowClick(prms?.row, true)}
          getRowId={(row) => `${row.type}-${row.id}`}
          contextMenuItems={[
            {
              label: 'Open',
              handler: (row) => onRowClick(row),
              checkAllowed: (row) => {
                return isAllowed(
                  'patientHistory',
                  ACTIVITY_TYPES[getters.getType(row)]?.permissionKey
                );
              },
            },
            {
              label: 'Open in a new tab',
              handler: (row) => onRowClick(row, true),
              checkAllowed: (row) => {
                return isAllowed(
                  'patientHistory',
                  ACTIVITY_TYPES[getters.getType(row)]?.permissionKey
                );
              },
            },
          ]}
          components={{
            ...(noRecordsAvailable
              ? {}
              : {
                  // eslint-disable-next-line react/no-unstable-nested-components
                  NoRowsOverlay: () => (
                    <CustomNoRowsOverlay
                      userId={userId}
                      fetchPatientHistory={fetchPatientHistory}
                    />
                  ),
                }),
          }}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </div>
  );
};

export default PatientHistorySection;
