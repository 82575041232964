import * as types from './actionTypes';

export const getDoctor = (id) => ({
  type: types.GET_DOCTOR,
  id,
});

export const getDoctors = () => ({
  type: types.GET_DOCTORS,
});

export const updateDoctor = (id, payload) => ({
  type: types.UPDATE_DOCTOR,
  payload,
  id,
});

export const setFetching = (isFetching) => ({
  type: types.SET_FETCHING,
  isFetching,
});

export const setSubmitting = (isSubmitting) => ({
  type: types.SET_SUBMITTING,
  isSubmitting,
});

export const doctorReceived = (doctor) => ({
  type: types.DOCTOR_RECEIVED,
  doctor,
});

export const doctorsReceived = (doctors) => ({
  type: types.DOCTORS_RECEIVED,
  doctors,
});
