/**
 * @deprecated
 * Don't add to this file. Add to src/constants
 * either to a relavent existing file or a new meaningful one
 */
/* Project-wise constants go here */
export const PACKAGE_NAME = 'AlmaAdmin';

export const dateFormat = 'dd/MM/yyyy'; // Ex: 29-08-2022
export const timeFormat = 'p'; // Ex: 12:00 AM
export const dateTimeFormat = 'dd/MM/yyyy p'; // Ex: 29-08-2022 12:00 AM
