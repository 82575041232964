import React, { useState } from 'react';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

import { copyToClipboard } from 'core/utils/document';
import { Link, Button, Message } from 'design-system';
import { isAllowed } from 'core/permission';
import DialerDialog from '../DialerDialog';

export const DialerUrl = 'https://portal.maqsam.com/phone/dialer#autodial=';

const DialerLink = ({
  phoneNumber,
  showIcon = true,
  isFetching,
  open = false,
}) => {
  const isLoading = isFetching !== undefined ? isFetching : !phoneNumber;

  const [openDialer, setOpenDialer] = useState(open);

  const handelPhoneNumberClick = (e) => {
    e.stopPropagation();
    copyToClipboard(phoneNumber)
      .then(() => {
        Message.success('Phone number copied to clipboard!');
      })
      .catch(() => {
        Message.error(
          "Couldn't copy to clipbard. Please report the issue to the team"
        );
      });
  };

  const handleClose = (_event, reason) => {
    if (reason && reason === 'backdropClick') return;

    setOpenDialer(false);
  };

  return (
    <>
      <Button
        size="extraSmall"
        color="primary"
        variant="icon"
        style={{ padding: '0px' }}
        disabled={!isAllowed('dialerLink', 'showPhone')}
        loading={isLoading && { width: 150 }}
        startIcon={
          showIcon ? (
            <PhoneOutlinedIcon color="primary" fontSize="small" />
          ) : null
        }
        onClick={(e) => {
          setOpenDialer(true);
          e.stopPropagation();
        }}
      />
      <Button
        size="extraSmall"
        color="primary"
        variant="text"
        style={{ padding: '0px' }}
        loading={isLoading && { width: 150 }}
        onClick={handelPhoneNumberClick}
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link enablePropagation>{phoneNumber || '----'}</Link>
      </Button>
      <DialerDialog
        phoneNumber={phoneNumber}
        open={openDialer}
        onClose={handleClose}
      />
    </>
  );
};

export default DialerLink;
