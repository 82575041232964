import set from 'date-fns/set';
import add from 'date-fns/add';
import keys from 'lodash/keys';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import List from '@mui/material/List';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MessageIcon from '@mui/icons-material/Message';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import SummarizeIcon from '@mui/icons-material/Summarize';

import { userGetters } from 'domain/user';
import { fileGetters } from 'domain/file';
import Comments from 'presentation/comment';
import { authSelectors } from 'domain/auth';
import { isAllowed } from 'core/permission';
import FileUpload from 'core/file/FileUpload';
import { dateTimeFormat } from 'constants/dateTime';
import { getAppConfigEndpoint } from 'core/endpoint';
import { PaymentSection } from 'presentation/payment';
import ServerAutocomplete from 'core/serverAutocomplete';
import { CUSTOM_LAB_TESTS_LIST_ENDPOINT } from 'model/lab';
import { REFERENCE_TYPES } from 'constants/general';
import PatientInfo from 'presentation/components/PatientInfo';
import { PatientHistorySection } from 'presentation/patientHistory';
import { patientHistoryConstants } from 'domain/patientHistory';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';
import DateTimeRangePicker from 'presentation/components/DateTimeRangePicker';
import SendNotification from 'presentation/consultation/ConsultationView/components/SendNotification';
import {
  labPackageGetters,
  labConstants as constants,
  labRequestGetters as getters,
  LAB_REQUEST_FIELDS as FIELDS,
} from 'domain/lab';
import {
  Grid,
  Card,
  Select,
  Button,
  Tooltip,
  MenuItem,
  Skeleton,
  RowLayout,
  TextField,
  CardHeader,
  IconButton,
  Typography,
  CardActions,
  CardContent,
} from 'design-system';

import { isMidnight } from 'core/utils/date';
import MemberSelection from './components/LabMemberSelection';
import {
  convertToStringList,
  preparePatientLabRequestDocuments,
} from '../utils';
import ConsultationCreationDialog from './ConsultationCreationDialog';

const { STATUSES } = constants;

const LabRequestView = ({
  owner,
  tenants,
  doctors,
  clinics,
  labRequest,
  isFetching,
  isFetchingDocuments,
  isSubmitting,
  members,
  membersHealthProfiles,
  getLabRequest,
  updateLabRequest,
  fetchUser,
  fetchPatientsUserInfo,
  fetchPatientsHealthProfiles,
  createPatientsLabRequestDocument,
  clearMembers,
  clearUser,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;

  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  const [labTestDate, setLabTestDate] = useState(new Date());
  const [hasFormChanged, setFormChanged] = useState(false);
  // Lab results files are uploaded successfully
  const [uploadResults, setUploadResults] = useState(false);
  // Files are in progress uploading
  const [isUploadingFiles, setUploadingFiles] = useState(false);
  const [statusSelectColor, setStatusSelectColor] = useState();

  // Fields' states
  const [userId, setUserId] = useState();
  const [status, setStatus] = useState('');
  const [namespace, setNamespace] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [updatedBy, setUpdatedBy] = useState();
  const [documents, setDocuments] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [documentsWithId, setDocumentsWithId] = useState([]);
  const [endTime, setEndTime] = useState(add(new Date(), { hours: 3 }));
  const [labPackage, setLabPackage] = useState();
  const [results, setResults] = useState([]);
  const [consultationId, setConsultationId] = useState(0);
  const [tenant, setTenant] = useState(defaultTenant);
  const [showConsultationDialog, setShowConsultationDialog] = useState(false);
  const [labTestList, setLabTestList] = useState([]);
  // Used for viewing/deleting objects selection on autocomplete
  const [labTestListView, setLabTestListView] = useState([]);
  const [hasPaymentTransaction, setHasPaymentTransaction] = useState(false);
  const [consultationDialogKey, setConsultationDialogKey] = useState(
    Date.now()
  );
  const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [doctorId, setDoctorId] = useState();
  const [doctor, setDoctor] = useState();
  const [channel, setChannel] = useState();
  const [patientsIds, setPatientsIds] = useState([]);
  const [patients, setPatients] = useState([]);

  const [user, setUser] = useState();
  const [isVerified, setVerified] = useState(false);
  useEffect(() => {
    clearMembers();
    clearUser();
  }, []);

  useEffect(() => {
    getLabRequest(id);
  }, []);

  useEffect(() => {
    if (!isEmpty(labRequest) && id === getters.getId(labRequest)) {
      const labRequestUserId = getters.getUserId(labRequest);
      const createdAtTimestamp = getters.getCreatedAt(labRequest);
      const updatedAtTimestamp = getters.getUpdatedAt(labRequest);
      const startDateTime = new Date(getters.getStartTime(labRequest));

      if (getters.getStartTime(labRequest) != null) {
        setLabTestDate(startDateTime);
        setStartTime(new Date(getters.getStartTime(labRequest)));
      }
      if (getters.getEndTime(labRequest) != null)
        setEndTime(new Date(getters.getEndTime(labRequest)));

      setUserId(labRequestUserId);
      setStatus(getters.getStatus(labRequest));
      setLabPackage(getters.getPackage(labRequest));
      setDocuments(
        getters.getDocuments(labRequest).map((file) => ({ id: file }))
      );
      setConsultationId(getters.getConsultationId(labRequest));
      setTenant(getters.getTenant(labRequest) || tenant);
      setCreatedBy(
        userGetters.getFullName(getters.getCreatedByUser(labRequest))
      );
      setUpdatedBy(
        userGetters.getFullName(getters.getUpdatedByUser(labRequest))
      );
      const labTestDoctorId = getters.getDoctorId(labRequest);
      setDoctorId(labTestDoctorId);
      if (labTestDoctorId) {
        setDoctor(
          userGetters.getFullName(getters.getDoctor(labTestDoctorId, doctors))
        );
      }
      if (createdAtTimestamp) {
        setCreatedAt(format(parseISO(createdAtTimestamp), dateTimeFormat));
      }

      if (updatedAtTimestamp) {
        setUpdatedAt(format(parseISO(updatedAtTimestamp), dateTimeFormat));
      }
      setLabTestList(getters.getLabTestList(labRequest));
      setLabTestListView(getters.getLabTestList(labRequest));
      setChannel(getters.getChannel(labRequest));
      setResults(getters.getResults(labRequest).map((file) => ({ id: file })));
      setNamespace(getters.getNamespace(labRequest));
      setHasPaymentTransaction(getters.hasPaymentTransaction(labRequest));
      setPatientsIds(getters.getPatients(labRequest));
    }

    setUploadResults(false);
  }, [id, labRequest]);

  useEffect(() => {
    if (getters.getUserId(labRequest)) {
      fetchUser(getters.getUserId(labRequest));
    }
  }, [labRequest]);

  useEffect(() => {
    setUser(owner);
  }, [owner]);

  useEffect(() => {
    if (status) {
      setStatusSelectColor(STATUSES[status]?.color || 'warning');
    }
  }, [status]);

  useEffect(() => {
    if (members) {
      setPatients(members);
    }
    // Verified only if all members have identity number or identity
    const notCompletedMembers = members?.filter(
      (member) =>
        !member.identityNumber && !member.identityFront && !member.identityBack
    );
    setVerified(members?.length >= 1 && !notCompletedMembers?.length);
  }, [members]);

  const handleDeleteCustomLabTest = (item) => () => {
    const val = item?.name || item;
    setLabTestList(labTestList.filter((test) => test !== item));
    setLabTestListView(labTestListView.filter((test) => test !== val));
  };

  useEffect(() => {
    if (!isEmpty(labRequest) && !isEmpty(patientsIds)) {
      fetchPatientsUserInfo(
        userId,
        patientsIds.map((patient) => patient.userId)
      );
      // Initiate fetching patients health profiles.
      // The data will be used to generate Pdf
      fetchPatientsHealthProfiles(
        userId,
        patientsIds.map((patient) => patient.userId)
      );
    }
  }, [patientsIds]);

  const createLabDocuments = async () => {
    const labRequestDocumentPayload = preparePatientLabRequestDocuments(
      owner,
      patients,
      membersHealthProfiles,
      clinics[0],
      STATUSES.VERIFIED.key
    );
    createPatientsLabRequestDocument(id, labRequestDocumentPayload);
  };

  const submitChanges = () => {
    const date = getDate(labTestDate);
    const year = getYear(labTestDate);
    const month = getMonth(labTestDate);
    const fullStartTime = set(startTime, { year, month, date });
    let fullEndTime = set(endTime, { year, month, date });
    if (isMidnight(fullEndTime)) {
      fullEndTime = add(fullEndTime, { days: 1 });
    }
    setStartTime(fullStartTime);
    setEndTime(fullEndTime);

    const payload = {
      [FIELDS.STATUS.name]: status,
      [FIELDS.END_TIME.name]: fullEndTime.toISOString(),
      [FIELDS.START_TIME.name]: fullStartTime.toISOString(),
      [FIELDS.DOCUMENTS.name]: documentsWithId.map((file) =>
        fileGetters.getId(file)
      ),
      [FIELDS.PACKAGE_ID.name]: labPackage
        ? labPackageGetters.getId(labPackage)
        : null,
      [FIELDS.RESULTS.name]: results.map((file) => fileGetters.getId(file)),
      [FIELDS.USER_ID.name]: userId,
      [FIELDS.MESSAGE.name]: 'LAB_TEST_RESULTS_OUT',
      [FIELDS.TENANT_ID.name]: tenant,
      [FIELDS.TEST_LIST.name]: convertToStringList(labTestList),
      [FIELDS.CONSULTATION_ID.name]: consultationId,
      [FIELDS.DOCTOR_ID.name]: doctorId,
      [FIELDS.CHANNEL.name]: channel,
      [FIELDS.USER_HAS_WLP_SUBSCRIPTION.name]:
        getters.userHasWlpSubscription(labRequest),
      [FIELDS.NAMESPACE_KEY.name]: namespace,
      [FIELDS.PATIENTS.name]: patientsIds,
    };

    updateLabRequest(id, payload, status === STATUSES.LAB_RESULTS_OUT.key);
    setFormChanged(false);
  };

  const submitLabResults = () => {
    submitChanges();
    setUploadResults(false);
    setFormChanged(false);
  };

  return (
    <Grid container spacing={3} column>
      <Grid item sx={{ width: '100%' }}>
        <RowLayout spacing={3}>
          {/* Lab Request Info Card */}
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 1000,
            }}
          >
            <CardHeader title={`Lab Test Request #${id}`} sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                p: 2,
                height: '100%',
              }}
            >
              <Grid container column>
                {/* Lab package */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 100 }}
                  >
                    LAB PACKAGE
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p3"
                    loading={isFetching && { width: 200 }}
                    style={{ fontWeight: 600 }}
                  >
                    {labPackage
                      ? labPackageGetters.getName(labPackage)
                      : 'Custom Lab Test Request'}
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Typography
                    variant="p3"
                    loading={isFetching && { width: 300 }}
                    sx={{
                      fontStyle: 'italic',
                    }}
                  >
                    {labPackage
                      ? labPackageGetters.getDescription(labPackage)
                      : 'Check the uploaded documents for more info about the requested lab test.'}
                  </Typography>
                </Grid>
                {/* Lab Tests Name */}
                {!labPackage && (
                  <Grid item container column>
                    <Grid item>
                      <Typography variant="p4" loading={isFetching}>
                        CUSTOM LAB TESTS
                      </Typography>
                    </Grid>
                    <List>
                      {(labTestList || []).map((value) => (
                        <ListItem
                          key={value?.name || value}
                          disableGutters
                          secondaryAction={
                            <IconButton
                              loading={isFetching}
                              edge="end"
                              aria-label="delete"
                              onClick={handleDeleteCustomLabTest(value)}
                              sx={{ right: '5px' }}
                            >
                              <DeleteIcon loading={isFetching} />
                            </IconButton>
                          }
                        >
                          <Typography
                            variant="p3"
                            loading={isFetching && { width: 200 }}
                            style={{ fontWeight: 600 }}
                          >
                            {value?.name || value}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                    <Grid item sx={{ mb: 3 }}>
                      {isFetching && <Skeleton width="100%" height={40} />}
                      {!isFetching && (
                        <ServerAutocomplete
                          fullWidth
                          multiple
                          defaultValue={labTestListView}
                          baseURL={getAppConfigEndpoint()}
                          endpoint={CUSTOM_LAB_TESTS_LIST_ENDPOINT}
                          getOptionLabel={(option) =>
                            option ? option?.name : ''
                          }
                          getTagLabel={(option) =>
                            option.name ? option.name : option
                          }
                          onChange={(newValue) => {
                            setLabTestList(newValue);
                            setFormChanged(true);

                            forEach(newValue, (field) => {
                              const val = field.name || field;

                              if (labTestListView.indexOf(val) === -1)
                                labTestListView.push(val);
                            });
                          }}
                          getRequestParams={(searchQuery) => {
                            const urlSearchParams = new URLSearchParams();
                            urlSearchParams.append('term', searchQuery);
                            return urlSearchParams;
                          }}
                          throttle={(fn, searchQuery) => {
                            if (searchQuery.length >= 2) {
                              fn();
                            }
                          }}
                          renderInput={(prms) => (
                            <TextField
                              fullWidth
                              placeholder="Add lab test ex: CBC"
                              {...prms}
                            />
                          )}
                          renderTags={() => {
                            return null;
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                )}
                {/* Status */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    STATUS
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Select
                    fullWidth
                    size="large"
                    soak="light"
                    value={status}
                    variant="filled"
                    loading={isFetching}
                    color={statusSelectColor}
                    placeholder="Update status..."
                    onChange={(event) => {
                      setStatus(event.target.value);
                      setFormChanged(true);
                    }}
                    renderValue={(value) => STATUSES[value]?.label || value}
                  >
                    {keys(STATUSES)
                      .filter((field) => !STATUSES[field].hide)
                      .map((field) => (
                        <MenuItem value={field} key={field}>
                          {STATUSES[field].label}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

                {/* Time */}
                <DateTimeRangePicker
                  title="LAB TEST"
                  endTime={endTime}
                  date={labTestDate}
                  startTime={startTime}
                  isFetching={isFetching}
                  onDateChange={(newValue) => {
                    setLabTestDate(newValue);
                    setFormChanged(true);
                  }}
                  onStartTimeChange={(newValue) => {
                    setStartTime(newValue);
                    setEndTime(add(newValue, { minutes: 30 }));
                    setFormChanged(true);
                  }}
                  onEndTimeChange={(newValue) => {
                    setEndTime(newValue);
                    setFormChanged(true);
                  }}
                  minDate={new Date()}
                />
              </Grid>
              {/* Consultation Id */}
              <Grid item sx={{ mb: 1 }}>
                <Typography variant="p4" loading={isFetching && { width: 50 }}>
                  CONSULTATION ID
                </Typography>
              </Grid>

              <Grid item sx={{ mb: 1 }}>
                <TextField
                  fullWidth
                  size="large"
                  variant="filled"
                  value={consultationId}
                  loading={isFetching}
                  placeholder="Enter here ..."
                  InputProps={{ inputProps: { min: 1 } }}
                  onChange={(event) => {
                    setConsultationId(event.target.value);
                    setFormChanged(true);
                  }}
                />
              </Grid>
              {/* PATIENTS */}
              <>
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 80 }}
                  >
                    PATIENTS
                  </Typography>
                </Grid>
                <MemberSelection
                  owner={{
                    id: userId,
                    fullName: userGetters.getFullName(user),
                  }}
                  referenceId={id}
                  loading={isFetching}
                  members={patients}
                  size="small"
                />
              </>
              {/* Branch */}
              <Grid item sx={{ mb: 1 }}>
                <Typography variant="p4" loading={isFetching && { width: 50 }}>
                  BRANCH
                </Typography>
              </Grid>
              <Grid item sx={{ mb: 3 }}>
                <Select
                  fullWidth
                  loading={isFetching && { width: 50 }}
                  size="large"
                  soak="light"
                  variant="filled"
                  value={tenant}
                  onChange={(event) => {
                    setTenant(event.target.value);
                    setFormChanged(true);
                  }}
                >
                  {(tenants || []).map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {/* Documents */}
              <Grid container row item justifyContent="space-between">
                <Grid container item column xs={5}>
                  <Grid item row sx={{ mb: 0.5 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 80 }}
                    >
                      DOCUMENTS
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={6}
                  justifyContent="right"
                  flexWrap="nowrap"
                >
                  {isAllowed(
                    'labRequestView',
                    'labDetails',
                    'generateLabDocument'
                  ) && (
                    <Button
                      size="small"
                      color="primary"
                      variant="text"
                      disabled={!isVerified}
                      loading={
                        (isFetching || isFetchingDocuments) && { width: 80 }
                      }
                      startIcon={<SummarizeIcon color="success" />}
                      onClick={() => createLabDocuments()}
                    >
                      Generate Lab Documents
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 3 }}>
                <FileUpload
                  multiple
                  userId={userId}
                  files={documents}
                  loading={isFetching || isFetchingDocuments}
                  onChange={(
                    files,
                    isActualChange,
                    _completeFiles,
                    filesWithServerId
                  ) => {
                    setDocuments(files);
                    setDocumentsWithId(filesWithServerId);

                    if (isActualChange) {
                      setFormChanged(true);
                    }
                  }}
                  config={{
                    deletable: true,
                  }}
                />
              </Grid>
              {/* Doctor Details */}
              {doctorId ? (
                <>
                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 130, sx: { mb: 0.5 } }}
                    >
                      Doctor
                    </Typography>
                  </Grid>
                  <UpdatedOrCreatedBy user={doctor} isFetching={isFetching} />
                </>
              ) : null}
              {/* Creation Details */}
              <Grid item>
                <Typography
                  variant="p4"
                  loading={isFetching && { width: 130, sx: { mb: 0.5 } }}
                >
                  Lab Test Created
                </Typography>
              </Grid>
              <UpdatedOrCreatedBy
                user={createdBy}
                time={createdAt}
                isFetching={isFetching}
              />

              {/* Update Details */}
              <Grid item>
                <Typography
                  variant="p4"
                  loading={isFetching && { width: 90, sx: { mb: 0.5 } }}
                >
                  Last Updated
                </Typography>
              </Grid>
              <UpdatedOrCreatedBy
                user={updatedBy}
                time={updatedAt}
                isFetching={isFetching}
              />

              {/* Submit Button */}
              <Grid item pb={3}>
                <Button
                  variant="filled"
                  onClick={submitChanges}
                  loading={isFetching && { width: 160 }}
                  spinning={isSubmitting}
                  disabled={
                    isFetching ||
                    !hasFormChanged ||
                    documents.length !== documentsWithId.length ||
                    isUploadingFiles
                  }
                >
                  Save Changes
                </Button>
              </Grid>
            </CardContent>
          </Card>
          {/* Patient Info Card */}
          <Grid container column flexWrap="nowrap">
            <Grid
              item
              sx={{
                mb: 3,
                minHeight: 750,
              }}
            >
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardContent noPadding sx={{ height: 'calc(100% - 40px)' }}>
                  <PatientInfo userId={userId} />
                </CardContent>
                <CardActions sx={{ p: 0 }}>
                  <Grid container column>
                    <Grid item container sx={{ py: 1, px: 2 }}>
                      <Tooltip
                        placement="top"
                        injectWrapper
                        title="Send a new sms or mobile push notification to the patient"
                      >
                        <Button
                          size="medium"
                          color="primary"
                          variant="text"
                          loading={isFetching && { width: 150 }}
                          startIcon={<MessageIcon color="secondary" />}
                          onClick={() => setNotificationDialogOpen(true)}
                        >
                          Send a notification
                        </Button>
                      </Tooltip>
                    </Grid>
                    {(isFetching || results.length > 0) && (
                      <Grid item container sx={{ py: 1, px: 2 }}>
                        <Tooltip
                          placement="top"
                          title="Send a follow-up consultation to discuss patient's lab results"
                        >
                          <Button
                            size="medium"
                            color="primary"
                            variant="text"
                            loading={isFetching && { width: 150 }}
                            startIcon={<VideoCallIcon color="primary" />}
                            onClick={() => setShowConsultationDialog(true)}
                          >
                            Book a follow-up consultation
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
            {/* Payment Card */}
            <Grid
              item
              sx={{
                minHeight: 400,
              }}
            >
              {isAllowed('labRequestView', 'payment', 'view') && (
                <PaymentSection
                  user={owner}
                  referenceId={id}
                  key="payment-section"
                  isFetching={isFetching}
                  referenceType={REFERENCE_TYPES.LAB}
                  hasPaymentTransaction={hasPaymentTransaction}
                  cardStyle={{ overflowY: 'scroll' }}
                  permissions={{
                    create: isAllowed('labRequestView', 'payment', 'create'),
                    update: isAllowed('labRequestView', 'payment', 'update'),
                    resend: isAllowed('labRequestView', 'payment', 'resend'),
                    cancel: isAllowed('labRequestView', 'payment', 'cancel'),
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container column heightFollower flexWrap="nowrap">
            {/* Lab Results */}
            <Grid
              item
              sx={{
                mb: 3,
                height: 500,
              }}
            >
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  overflowY: 'scroll',
                }}
              >
                <CardHeader title="Lab Tests Results" sx={{ pb: 1 }} />
                <CardContent
                  noPadding
                  sx={{
                    height: 278,
                  }}
                >
                  {/* Lab Results */}
                  <Grid container column p={2}>
                    <Grid item mb={1}>
                      <Typography
                        variant="p4"
                        loading={isFetching && { width: 80 }}
                      >
                        LAB RESULTS
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FileUpload
                        multiple
                        userId={userId}
                        files={results}
                        loading={isFetching}
                        onChange={(files, isActualChange, completeFiles) => {
                          setResults(files);
                          if (isActualChange) {
                            setUploadingFiles(true);

                            if (completeFiles.length === files.length) {
                              setUploadResults(true);
                              setStatus(STATUSES.LAB_RESULTS_OUT.key);
                              setResults(completeFiles);
                              setUploadingFiles(false);
                              setFormChanged(true);
                            }
                          }
                        }}
                        config={{
                          deletable: true,
                        }}
                      />
                    </Grid>
                    {/* Submit Button */}
                    <Grid item sx={{ mt: 3 }}>
                      <Button
                        variant="filled"
                        spinning={isSubmitting}
                        onClick={submitLabResults}
                        loading={isFetching && { width: 160 }}
                        disabled={!uploadResults || isUploadingFiles}
                      >
                        Upload Lab Results
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* Comments Card */}
            <Grid
              item
              sx={{
                height: 'calc(100% - 356px)',
              }}
            >
              <Comments
                referenceId={id}
                referenceType={REFERENCE_TYPES.LAB_TEST_REQUEST}
              />
            </Grid>
          </Grid>
        </RowLayout>
      </Grid>
      {/** Patient History Section */}
      <Grid item xs={12}>
        {isAllowed('patientHistory') && (
          <PatientHistorySection
            referenceId={id}
            userId={userId}
            referenceType={
              patientHistoryConstants.ACTIVITY_TYPES.LAB_TEST_REQUEST.key
            }
          />
        )}
      </Grid>
      <ConsultationCreationDialog
        key={consultationDialogKey}
        open={showConsultationDialog}
        userId={userId}
        tenant={tenant}
        documents={[...results]}
        onComplete={() => setConsultationDialogKey(Date.now())}
        onClose={() => setShowConsultationDialog(false)}
      />
      <SendNotification
        referenceId={id}
        open={isNotificationDialogOpen}
        userId={userId}
        referenceType={REFERENCE_TYPES.LAB_TEST_REQUEST}
        onClose={() => setNotificationDialogOpen(false)}
      />
    </Grid>
  );
};

export default LabRequestView;
