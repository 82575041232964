import { connect } from 'react-redux';
import { doctorNotesActions, doctorNotesSelectors } from 'domain/doctorNotes';
import DiagnosisForm from './DiagnosisForm';

const mapStateToProps = (state) => {
  const getDoctorNotesById = (userId) =>
    doctorNotesSelectors.getDoctorNotesById(state, userId);
  return {
    getDoctorNotesById,
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitPatientDiagnosisForm: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      doctorNotesActions.submitDoctorNotes(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
});
export default connect(mapStateToProps, mapDispatchToProps)(DiagnosisForm);
