import * as types from './actionTypes';

// Actions
export const getConsultationById = (id) => ({
  type: types.GET_CONSULTATION_BY_ID,
  id,
});

export const updateConsultation = (id, payload, successCb, failureCb) => ({
  type: types.UPDATE_CONSULTATION,
  payload,
  id,
  successCb,
  failureCb,
});

export const addConsultationLab = (id) => ({
  type: types.ADD_CONSULTATION_LAB_TEST,
  id,
});

export const addConsultationMeetingStarted = (id, action) => ({
  type: types.ADD_CONSULTATION_MEETING_STARTED,
  id,
  action,
});

export const createConsultation = (payload) => ({
  type: types.CREATE_CONSULTATION,
  payload,
});

export const updateConsultationMembers = (consultationId, memberIds) => ({
  type: types.UPDATE_CONSULTATION_MEMBERS,
  consultationId,
  memberIds,
});

export const deleteConsultationMember = (consultationId, memberId) => ({
  type: types.DELETE_CONSULTATION_MEMBER,
  consultationId,
  memberId,
});

export const updateConsultationPatient = (
  consultationId,
  patientId,
  payload,
  successCallback
) => ({
  type: types.UPDATE_CONSULTATION_PATIENT,
  consultationId,
  patientId,
  payload,
  successCallback,
});

export const createConsultationPatientOutcome = (
  consultationId,
  patientId,
  payload
) => ({
  type: types.CREATE_CONSULTATION_PATIENT_OUTCOME,
  consultationId,
  patientId,
  payload,
});

export const updateConsultationPatientOutcome = (
  consultationId,
  patientId,
  outcomeId,
  payload
) => ({
  type: types.UPDATE_CONSULTATION_PATIENT_OUTCOME,
  consultationId,
  patientId,
  outcomeId,
  payload,
});

export const deleteConsultationPatientOutcome = (
  consultationId,
  patientId,
  outcomeId
) => ({
  type: types.DELETE_CONSULTATION_PATIENT_OUTCOME,
  consultationId,
  patientId,
  outcomeId,
});

export const submitConsultationPrescriptions = (consultationId) => ({
  type: types.SUBMIT_CONSULTATION_PRESCRIPTIONS,
  consultationId,
});
export const submitConsultationLabTests = (consultationId) => ({
  type: types.SUBMIT_CONSULTATION_LAB_TESTS,
  consultationId,
});

// Responses
export const consultationReceived = (consultation, id) => ({
  type: types.CONSULTATION_RECEIVED,
  consultation,
  id,
});

export const consultationUpdated = (consultation) => ({
  type: types.CONSULTATION_UPDATED,
  consultation,
});

export const consultationMemberDeleted = (memberId) => ({
  type: types.CONSULTATION_MEMBER_DELETED,
  memberId,
});

export const consultationMembersUpdated = (consultationMembers) => ({
  type: types.CONSULTATION_MEMBERS_UPDATED,
  consultationMembers,
});

export const consultationPatientUpdated = (patientId, patient) => ({
  type: types.CONSULTATION_PATIENT_UPDATED,
  patient,
  patientId,
});

export const consultationPatientOutcomeCreated = (patientId, outcomes) => ({
  type: types.CONSULTATION_PATIENT_OUTCOME_CREATED,
  outcomes,
  patientId,
});

export const consultationPatientOutcomeUpdated = (patientId, outcomes) => ({
  type: types.CONSULTATION_PATIENT_OUTCOME_UPDATED,
  outcomes,
  patientId,
});

export const consultationPatientOutcomeDeleted = (patientId, outcomeId) => ({
  type: types.CONSULTATION_PATIENT_OUTCOME_DELETED,
  outcomeId,
  patientId,
});

export const consultationPrescriptionsSubmitted = () => ({
  type: types.CONSULTATION_PRESCRIPTIONS_SUBMITTED,
});
export const consultationLabTestsSubmitted = () => ({
  type: types.CONSULTATION_LAB_TESTS_SUBMITTED,
});

// Statuses
export const setFetchingConsultation = (isFetching) => ({
  type: types.SET_FETCHING_CONSULTATION,
  isFetching,
});

export const setSubmittingConsultation = (isSubmitting) => ({
  type: types.SET_SUBMITTING_CONSULTATION,
  isSubmitting,
});

export const setDeletingConsultationMember = (
  isDeletingConsultationMember
) => ({
  type: types.SET_DELETING_CONSULTATION_MEMBER,
  isDeletingConsultationMember,
});

export const setSubmittingConsultationMembers = (
  isSubmittingConsultationMembers
) => ({
  type: types.SET_SUBMITTING_CONSULTATION_MEMBERS,
  isSubmittingConsultationMembers,
});

export const setUpdatingConsultationPatient = (
  isUpdatingConsultationPatient
) => ({
  type: types.SET_UPDATING_CONSULTATION_PATIENT,
  isUpdatingConsultationPatient,
});

export const setSubmittingConsultationPatientOutcome = (
  isSubmittingConsultationPatientOutcome
) => ({
  type: types.SET_SUBMITTING_CONSULTATION_PATIENT_OUTCOME,
  isSubmittingConsultationPatientOutcome,
});

export const setUpdatingConsultationPatientOutcome = (
  isUpdatingConsultationPatientOutcome
) => ({
  type: types.SET_UPDATING_CONSULTATION_PATIENT_OUTCOME,
  isUpdatingConsultationPatientOutcome,
});

export const setDeletingConsultationPatientOutcome = (
  isDeletingConsultationPatientOutcome
) => ({
  type: types.SET_DELETING_CONSULTATION_PATIENT_OUTCOME,
  isDeletingConsultationPatientOutcome,
});

export const setSubmittingConsultationPrescriptions = (
  isSubmittingConsultationPrescriptions
) => ({
  type: types.SET_SUBMITTING_CONSULTATION_PRESCRIPTIONS,
  isSubmittingConsultationPrescriptions,
});
export const setSubmittingConsultationLabTests = (
  isSubmittingConsultationLabTests
) => ({
  type: types.SET_SUBMITTING_CONSULTATION_LAB_TESTS,
  isSubmittingConsultationLabTests,
});
export const clearConsultation = () => ({
  type: types.CLEAR_CONSULTATION,
});
