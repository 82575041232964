import { all, call, put, take, takeLatest } from 'redux-saga/effects';

import logger from 'core/logger';
import { labActions } from 'domain/lab';
import { navigate } from 'routing/utils';
import { userActions } from 'domain/user';
import { fileActions } from 'domain/file';
import { orderActions } from 'domain/order';
import { tenantActions } from 'domain/tenant';
import { clinicActions } from 'domain/clinic';
import { authUtils, authConstants } from 'core/auth';
import { isShowLogisticProvider } from 'core/siteConfig';
import { consultationTypeActions } from 'domain/consultationType';
import { insuranceProviderActions } from 'domain/insuranceProvider';
import { isAllowed, navigateToMainPage } from 'core/permission/utils';
import { authActionTypes, authUtils as authDomainUtils } from 'domain/auth';

import * as types from './actionTypes';

const { onTokenReceived } = authDomainUtils;

export function* reinitialize() {
  if (isAllowed('login', 'fetchDoctors')) {
    yield put(userActions.fetchDoctors());
  }

  if (isAllowed('login', 'fetchConsultationType')) {
    yield put(consultationTypeActions.getConsultationTypes());
  }

  if (isAllowed('login', 'fetchDrivers')) {
    yield put(userActions.fetchDrivers());

    if (isShowLogisticProvider()) {
      yield put(orderActions.fetchLogisticProviders());
    }
  }

  if (isAllowed('login', 'fetchPharmacists')) {
    yield put(userActions.fetchPharmacists());
  }

  yield put(clinicActions.getClinics());
  yield put(tenantActions.fetchTenants());
  yield put(tenantActions.fetchCities());
  yield put(labActions.fetchLabPackages());
  yield put(insuranceProviderActions.fetchInsuranceProviderList());
}

export function* initializeApp() {
  const token = localStorage.getItem(authConstants.LS_TOKEN);

  if (authUtils.validateToken(token)) {
    yield call(onTokenReceived, token);
    yield call(reinitialize);

    const page = yield call(navigateToMainPage);
    navigate(page);
  } else if (!window.location.pathname.includes('login')) {
    authUtils.logOutClean();
    navigate('/login');
  }
}

export function* saveFilters({ filters, lsKey }) {
  try {
    const stringifiedFilters = JSON.stringify(filters);

    localStorage.setItem(lsKey, stringifiedFilters);
  } catch (e) {
    logger.error(
      `saveFilters of ${lsKey} - Error saving into local storage. ${e.toString()}`
    );
  }
}

export function* pursueAction({ actionType, callback }) {
  const action = yield take(actionType);

  callback(action);
}

/**
 * Clear data (E.g: user, health info and files),
 * each time the route changes.
 */
export function* onRouteChanged() {
  yield put(userActions.clearUser());
  yield put(fileActions.clearFiles());
  yield put(userActions.clearPatientHealthInfo());
}

export default function* appSaga() {
  yield all([
    takeLatest(types.SAVE_FILTERS, saveFilters),
    takeLatest(types.PURSUE_ACTION, pursueAction),
    takeLatest(types.ROUTE_CHANGED, onRouteChanged),
    takeLatest(types.INITIALIZE_APP, initializeApp),
    takeLatest(authActionTypes.LOG_IN_SUCCEEDED, reinitialize),
  ]);
}
