import logger from 'core/logger';

export const getCachedDomainObject = (cacheKey) => {
  try {
    // Fetch object from local storage cache if exist
    const cachedObject = localStorage.getItem(cacheKey);
    if (cachedObject) {
      return JSON.parse(cachedObject);
    }
  } catch (e) {
    logger.error(
      `Getting ${cacheKey} object from local storage - Error parsing JSON. ${e.toString()}`
    );
  }
  return null;
};

export const setCachedDomainObject = (cacheKey, domainObject) => {
  try {
    // Fetch object from local storage cache if exist
    return localStorage.setItem(cacheKey, JSON.stringify(domainObject));
  } catch (e) {
    logger.error(
      `Storing ${cacheKey} object from local storage - Error parsing JSON. ${e.toString()}`
    );
  }
  return null;
};
