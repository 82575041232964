import React, { useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import PlacesAutocomplete from 'react-places-autocomplete';

import logger from 'core/logger';
import { useTheme } from 'design-system';
import { getGoogleApiKey } from 'core/siteConfig';

import { fetchLocation } from './utils';

const LocationSearchInput = ({ onChange, addressFields }) => {
  const theme = useTheme();
  const [value, setValue] = useState();

  return (
    <Wrapper apiKey={getGoogleApiKey() || ''} libraries={['places']}>
      <PlacesAutocomplete
        onChange={(newValue) => {
          setValue(newValue);
        }}
        value={value}
        googleCallbackName="initMap"
        onSelect={(_address, placeId) => {
          fetchLocation(addressFields)(placeId)
            .then((addressDetails) => {
              if (addressDetails?.formattedAddress) {
                setValue(addressDetails.formattedAddress);
              }

              onChange(addressDetails);
            })
            .catch((error) => logger.error(error));
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          // TODO: This has to be revisited
          <div
            style={{
              height: '40px',
              display: 'flex',
              borderRadius: '4px',
              position: 'relative',
              alignItems: 'center',
              border: `1px solid ${theme.palette.primary.dark}`,
            }}
          >
            <fieldset
              className="location-fieldset"
              style={{
                width: '100%',
              }}
            >
              <input
                {...getInputProps({
                  placeholder:
                    'Search locations. i.e. Al Reem Island - Jazeerat Al Reem...',
                  className: 'location-search-input',
                })}
                style={{
                  outline: 0,
                }}
              />
            </fieldset>
            <div
              className={loading ? 'autocomplete autoloading' : 'autocomplete'}
            >
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: '#fafafa',
                      cursor: 'pointer',
                    }
                  : {
                      backgroundColor: '#ffffff',
                      cursor: 'pointer',
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    className="suggestion"
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Wrapper>
  );
};

export default LocationSearchInput;
