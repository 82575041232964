import get from 'lodash/get';

import { FIELDS } from './constants';
import { consultationModelGetters } from '../../model/consultation';

const getComments = (consultation) => get(consultation, FIELDS.COMMENTS.name);

export default {
  ...consultationModelGetters,
  getComments,
};
