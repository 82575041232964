import React from 'react';
import { useGridApiContext, useGridSelector } from '@mui/x-data-grid';

import Grid from '../Grid';
import Typography from '../Typography';
import DataGridPagination from './DataGridPagination';
import { gridRowCountSelector } from './gridSelectors';

const DataGridFooter = ({ pagination = true, isCountShown = true }) => {
  const apiRef = useGridApiContext();
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
    >
      <Grid item>
        {isCountShown && Boolean(rowCount) && (
          <Typography variant="h6">Total: {rowCount}</Typography>
        )}
      </Grid>
      <Grid item>{pagination && <DataGridPagination />}</Grid>
    </Grid>
  );
};

export default DataGridFooter;
