import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import {
  consultationTypeActions as actions,
  consultationTypeSelectors as selectors,
} from 'domain/consultationType';

import ConsultationTypeConfigurationSection from './ConsultationTypeConfigurationSection';

const mapStateToProps = (state) => {
  const isFetching = selectors.isFetchingConfiguration(state);
  const isSubmitting = selectors.isSubmittingConfiguration(state);
  const consultationTypeConfigurations =
    selectors.getConsultationTypeConfigurations(state);

  return {
    isFetching,
    isSubmitting,
    consultationTypeConfigurations,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getConsultationTypeConfigurations: (payload) =>
    dispatch(actions.getConsultationTypeConfigurations(payload)),
  deleteConsultationTypeConfiguration: (id) =>
    dispatch(actions.deleteConsultationTypeConfiguration(id)),
});

export default appData(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConsultationTypeConfigurationSection)
);
