import ROLES from 'core/permission/roles';

export const LS_PRE_LOGIN_PATH = 'pre-login-path';
export const LS_TOKEN = 'token';
export const LS_MAIN_ROLE = 'main-role';
export const LS_USER_ROLES = 'user-roles';

const {
  ADMIN,
  DOCTOR,
  CLINIC_MANAGER,
  LOGISTICS_ADMIN,
  PATIENT_SUCCESS,
  PHARMACIST,
} = ROLES;

const list = (...roles) => new Set(roles);

export const MAIN_ROUTES = {
  orders: {
    roles: list(LOGISTICS_ADMIN, PHARMACIST),
  },
  consultations: {
    roles: list(ADMIN, DOCTOR, CLINIC_MANAGER, PATIENT_SUCCESS),
  },
};
