import { connect } from 'react-redux';

import { userSelectors, userActions } from 'domain/user';

import IdentityExtractionDialog from './IdentityExtractionDialog';

const mapStateToProps = (state) => {
  const extractedIdentity = userSelectors.getExtractedIdentity(state);
  const isExtractingIdentity = userSelectors.isExtractingIdentity(state);
  const identityExtractionJobs = userSelectors.getIdentityExtractionJobs(state);
  const isSavingExtractedIdentityData =
    userSelectors.isSavingExtractedIdentityData(state);
  const identityExtractionError =
    userSelectors.getIdentityExtractionError(state);

  return {
    extractedIdentity,
    isExtractingIdentity,
    identityExtractionJobs,
    identityExtractionError,
    isSavingExtractedIdentityData,
  };
};

const mapDispatchToPtops = (dispatch) => ({
  extractIdentity: (documents) =>
    dispatch(userActions.startIdentityExtraction(documents)),
  clearExtractedData: () => dispatch(userActions.clearExtractedIdentityData()),
  saveExtractedIdentityData: (userId, extractedData, successCallback) =>
    dispatch(
      userActions.saveExtractedIdentityData(
        userId,
        extractedData,
        successCallback
      )
    ),
  cancelIdentityExtraction: () =>
    dispatch(userActions.cancelIdentityExtraction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToPtops
)(IdentityExtractionDialog);
