export const getAdminEndpoint = () =>
  `${process.env.REACT_APP_BASE_URL}/admin/api/v1`;

export const getLabEndpoint = () =>
  `${process.env.REACT_APP_BASE_URL}/lab-tests/api`;

export const getLabEndpointV1 = () =>
  `${process.env.REACT_APP_BASE_URL}/lab-tests/api/v1`;

export const getAppConfigEndpoint = () =>
  `${process.env.REACT_APP_BASE_URL}/app/config`;

export const getHealthEndpoint = () =>
  `${process.env.REACT_APP_BASE_URL}/health/api`;

export const getHealthEndpointV1 = () =>
  `${process.env.REACT_APP_BASE_URL}/health/api/v1`;

export const getIntegrationEndpoint = () =>
  `${process.env.REACT_APP_BASE_URL}/integration/api/v1`;

export const getPdfServiceEndpoint = () =>
  process.env.REACT_APP_PDF_GENERATOR_SERVICE_URL;

export const getOrderServiceEndpoint = () =>
  `${process.env.REACT_APP_BASE_URL}/order/api/v1`;

export const getChangeHistoryEndpoint = () =>
  process.env.REACT_APP_CHANGE_HISTORY_ENDPOINT;

export const getPaymentServiceEndpoint = () =>
  process.env.REACT_APP_PAYMENT_SERVICE_ENDPOINT;

export const getDialerEndpoint = () => process.env.REACT_APP_DIALER_ENDPOINT;
