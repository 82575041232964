import keys from 'lodash/keys';
import set from 'date-fns/set';
import add from 'date-fns/add';
import groupBy from 'lodash/groupBy';
import isArray from 'lodash/isArray';
import isEqual from 'date-fns/isEqual';
import isBefore from 'date-fns/isBefore';
import formatISO from 'date-fns/formatISO';
import capitalize from 'lodash/capitalize';
import EditIcon from '@mui/icons-material/Edit';
import { timeFormat } from 'constants/dateTime';
import React, { useState, useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

import { dateUtils } from 'core/utils';
import { userGetters } from 'domain/user';
import { DataGridCard } from 'core/dataGrid';
import {
  scheduleConstants as constants,
  doctorScheduleGetters as getters,
  availabilityRangeGetters as rangeGetters,
} from 'domain/schedule';
import {
  Box,
  Grid,
  Hover,
  Dialog,
  Button,
  DataGrid,
  FormField,
  TextField,
  IconButton,
  PopConfirm,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  MobileTimePicker,
} from 'design-system';

const { AVAILABILITY_RANGE_FIELDS } = constants;

const TimeRangeCell = ({
  row,
  range,
  dayOfWeek,
  removeRange,
  isRemovingRange,
}) => {
  const [popConfirmAnchors, setPopConfirmAnchors] = useState({});

  const showPopConfirm = (id, event) => {
    setPopConfirmAnchors({
      ...popConfirmAnchors,
      [id]: event.currentTarget,
    });
  };

  const closePopConfirm = (id) => {
    setPopConfirmAnchors({
      ...popConfirmAnchors,
      [id]: null,
    });
  };

  return (
    <>
      <Hover
        component={Grid}
        item
        container
        flexWrap="nowrap"
        alignItems="center"
        columnSpacing={1}
        sx={{ height: 36 }}
      >
        {(isHovering) => (
          <>
            <Grid item loading={isRemovingRange(rangeGetters.getId(range))}>
              {rangeGetters.getFormattedStartTime(range)} -{' '}
              {rangeGetters.getFormattedEndTime(range)}
            </Grid>
            <Grid item sx={{ width: 36 }}>
              {(isHovering || popConfirmAnchors[rangeGetters.getId(range)]) && (
                <IconButton
                  disabled={isRemovingRange(rangeGetters.getId(range))}
                  onClick={(e) => {
                    e.stopPropagation();
                    showPopConfirm(rangeGetters.getId(range), e);
                  }}
                >
                  <RemoveCircleOutlineOutlinedIcon
                    color="error"
                    fontSize="small"
                  />
                </IconButton>
              )}
            </Grid>
          </>
        )}
      </Hover>
      <PopConfirm
        primaryButtonTitle="Cancel"
        secondaryButtonTitle="Delete"
        anchorEl={popConfirmAnchors[rangeGetters.getId(range)]}
        onOk={(e) => {
          e.stopPropagation();
          closePopConfirm(rangeGetters.getId(range));
        }}
        open={Boolean(popConfirmAnchors[rangeGetters.getId(range)])}
        onCancel={(e) => {
          e.stopPropagation();
          removeRange(range, row, dayOfWeek);
          closePopConfirm(rangeGetters.getId(range));
        }}
        id={
          popConfirmAnchors[rangeGetters.getId(range)]
            ? `delete-range-${rangeGetters.getId(range)}-confirm`
            : undefined
        }
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        Are you sure you want to delete this time range?
      </PopConfirm>
    </>
  );
};

const DayRangeCells = ({
  row,
  day,
  addRange,
  removeRange,
  isAddingRange,
  removeFreeSlot,
  isRemovingRange,
  freeScheduleSlot,
  fetchDoctorFreeScheduleSlots,
  isFetchingDoctorFreeScheduleSlots,
}) => {
  const ranges = row[day] || [];

  const [endTime, setEndTime] = useState();
  const [startTime, setStartTime] = useState();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const showEditPopup = (doctor) => {
    const currentDate = formatISO(new Date()).split('T')[0];

    fetchDoctorFreeScheduleSlots(
      doctor,
      currentDate,
      dateUtils.LONG_WEEK_DAYS.indexOf(day)
    );
    setEditModalOpen(true);
  };

  return (
    <>
      <Grid container column alignSelf="flex-start">
        <Grid item>
          <Button
            variant="text"
            size="medium"
            disabled={isAddingRange(getters.getId(row))}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={(e) => {
              e.stopPropagation();

              const latestEndTime = ranges.length
                ? rangeGetters.getEndTimeParts(ranges[ranges.length - 1])
                : [8, 0];

              const upcomingStartTime = set(new Date(), {
                seconds: 0,
                milliseconds: 0,
                hours: latestEndTime[0],
                minutes: latestEndTime[1],
              });

              setStartTime(upcomingStartTime);
              setEndTime(add(upcomingStartTime, { minutes: 30 }));

              setTimeout(() => {
                setAddModalOpen(true);
              }, 0);
            }}
          >
            Add
          </Button>
          <Button
            variant="text"
            size="medium"
            disabled={ranges?.length < 1}
            startIcon={<EditIcon />}
            onClick={(e) => {
              e.stopPropagation();
              showEditPopup(getters.getDoctorId(row), e);
            }}
          >
            Edit
          </Button>
        </Grid>
        {Boolean(row?.[day]?.length) &&
          ranges.map((range) => (
            <TimeRangeCell
              row={row}
              range={range}
              removeRange={removeRange}
              isRemovingRange={isRemovingRange}
              dayOfWeek={dateUtils.LONG_WEEK_DAYS.indexOf(day)}
            />
          ))}
      </Grid>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        aria-labelledby={`${getters.getId(row)}-add-range-dialog-title`}
      >
        <DialogTitle>
          {userGetters.getFullName(getters.getDoctor(row))}&apos;s Schedule -{' '}
          {capitalize(day)}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container columnSpacing={2}>
            <FormField
              label="START TIME"
              flex={1}
              field={
                <MobileTimePicker
                  value={startTime}
                  inputFormat={timeFormat}
                  onChange={(newValue) => setStartTime(newValue)}
                  onAccept={(newValue) => {
                    setStartTime(newValue);
                    setEndTime(add(newValue, { minutes: 30 }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="large"
                      variant="filled"
                      {...params}
                    />
                  )}
                />
              }
            />
            <FormField
              label="END TIME"
              flex={1}
              field={
                <MobileTimePicker
                  value={endTime}
                  inputFormat={timeFormat}
                  onChange={(newValue) => setEndTime(newValue)}
                  onAccept={(newValue) => {
                    if (
                      isEqual(newValue, startTime) ||
                      isBefore(newValue, startTime)
                    ) {
                      setEndTime(add(startTime, { minutes: 30 }));
                      return;
                    }

                    setEndTime(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      size="large"
                      variant="filled"
                      {...params}
                    />
                  )}
                />
              }
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            spinning={isAddingRange(getters.getId(row))}
            disabled={!(startTime && endTime)}
            onClick={() => {
              addRange(
                getters.getId(row),
                getters.getDoctorId(row),
                dateUtils.LONG_WEEK_DAYS.indexOf(day),
                formatISO(startTime),
                formatISO(endTime),
                () => {
                  setAddModalOpen(false);
                }
              );
            }}
          >
            Add Time Range
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isEditModalOpen}
        onClose={() => {
          setEditModalOpen(false);
        }}
        aria-labelledby={`${getters.getId(row)}-edit-range-dialog-title`}
      >
        <DialogTitle>
          Edit {userGetters.getFullName(getters.getDoctor(row))}&apos;s Schedule
          for {capitalize(day)}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container columnSpacing={2}>
            {isFetchingDoctorFreeScheduleSlots ? (
              <CircularProgress color="inherit" />
            ) : (
              freeScheduleSlot?.map((range) => {
                return (
                  <TimeRangeCell
                    row={row}
                    range={range}
                    removeRange={removeFreeSlot}
                    isRemovingRange={isRemovingRange}
                    dayOfWeek={dateUtils.LONG_WEEK_DAYS.indexOf(day)}
                  />
                );
              })
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            disabled={isFetchingDoctorFreeScheduleSlots}
            onClick={() => setEditModalOpen(false)}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const getColumns = ({
  addRange,
  removeRange,
  isAddingRange,
  isRemovingRange,
  fetchDoctorFreeScheduleSlots,
  isFetchingDoctorFreeScheduleSlots,
  freeScheduleSlot,
  removeFreeSlot,
}) => [
  {
    flex: 1,
    field: 'doctor',
    headerName: 'Doctor',
    // TODO: Suggest showing phone number as well
    valueGetter: (params) =>
      userGetters.getFullName(getters.getDoctor(params?.row)),
  },
  ...dateUtils.LONG_WEEK_DAYS.map((day) => ({
    flex: 1,
    field: day,
    sortable: false,
    hideSortIcons: true,
    headerName: capitalize(day),
    renderCell: (params) => {
      return (
        <DayRangeCells
          day={day}
          row={params?.row}
          removeFreeSlot={removeFreeSlot}
          addRange={addRange}
          removeRange={removeRange}
          isAddingRange={isAddingRange}
          isRemovingRange={isRemovingRange}
          fetchDoctorFreeScheduleSlots={fetchDoctorFreeScheduleSlots}
          isFetchingDoctorFreeScheduleSlots={isFetchingDoctorFreeScheduleSlots}
          freeScheduleSlot={freeScheduleSlot}
        />
      );
    },
  })),
];

const DoctorAvailabilitySchedule = ({
  addRange,
  schedules,
  isFetching,
  removeRange,
  isAddingRange,
  fetchSchedules,
  removeFreeSlot,
  isRemovingRange,
  freeScheduleSlot,
  fetchDoctorFreeScheduleSlots,
  isFetchingDoctorFreeScheduleSlots,
}) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  useEffect(() => {
    const parsedSchedules = schedules.map((schedule) => {
      return {
        ...schedule,
        ...groupBy(
          getters.getAvailabilityRange(schedule),
          AVAILABILITY_RANGE_FIELDS.READABLE_DAY_OF_WEEK.name
        ),
      };
    });

    setRows(parsedSchedules);
  }, [schedules]);

  return (
    <Box
      sx={{
        overflowX: 'auto',
        width: 'calc(100vw - 140px)',
        height: 'calc(100vh - 200px)',
      }}
    >
      <DataGridCard sx={{ minWidth: 1500, width: '100%' }}>
        <DataGrid
          rows={rows}
          // Show all doctors in one page
          pageSize={100}
          footer={{
            pagination: false,
            isCountShown: false,
          }}
          loading={isFetching}
          columns={getColumns({
            addRange,
            removeRange,
            isAddingRange,
            isRemovingRange,
            fetchDoctorFreeScheduleSlots,
            isFetchingDoctorFreeScheduleSlots,
            freeScheduleSlot,
            removeFreeSlot,
          })}
          disableRowSelectionOnClick
          disableColumnMenu
          getRowHeight={(params) => {
            const maxElements = keys(params.model).reduce((max, key) => {
              if (dateUtils.LONG_WEEK_DAYS.includes(key)) {
                if (isArray(params.model[key])) {
                  return Math.max(params.model[key].length, max);
                }
              }

              return max;
            }, 0);

            return (maxElements + 1) * 40;
          }}
        />
      </DataGridCard>
    </Box>
  );
};

export default DoctorAvailabilitySchedule;
