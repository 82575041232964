import { all } from 'redux-saga/effects';

import labModelSaga from './lab/saga';
import authModelSaga from './auth/saga';
import userModelSaga from './user/saga';
import fileModelSaga from './file/saga';
import orderModelSaga from './order/saga';
import doctorModelSaga from './doctor/saga';
import clinicModelSaga from './clinic/saga';
import tenantModelSaga from './tenant/saga';
import commentModelSaga from './comment/saga';
import meetingModelSaga from './meeting/saga';
import paymentModelSaga from './payment/saga';
import scheduleModelSaga from './schedule/saga';
import dietPlanModelSaga from './dietPlan/saga';
import medicineModelSaga from './medicine/saga';
import { eligibilityModelSaga } from './eligibility';
import doctorNotesModelSaga from './doctorNotes/saga';
import { subscriptionModelSaga } from './subscription';
import consultationModelSaga from './consultation/saga';
import notificationModelSaga from './notification/saga';
import prescriptionModelSaga from './prescription/saga';
import anonymousUserModelSaga from './anonymousUser/saga';
import { userManagementModelSaga } from './userManagement';
import patientHistoryModelSaga from './patientHistory/saga';
import { consultationTypeModelSaga } from './consultationType';
import followUpReminderModelSaga from './followUpReminder/saga';
import insuranceProviderModelSaga from './insuranceProvider/saga';

export default function* modelSaga() {
  yield all([
    labModelSaga(),
    userModelSaga(),
    authModelSaga(),
    fileModelSaga(),
    orderModelSaga(),
    doctorModelSaga(),
    clinicModelSaga(),
    tenantModelSaga(),
    commentModelSaga(),
    meetingModelSaga(),
    paymentModelSaga(),
    dietPlanModelSaga(),
    medicineModelSaga(),
    scheduleModelSaga(),
    eligibilityModelSaga(),
    doctorNotesModelSaga(),
    prescriptionModelSaga(),
    consultationModelSaga(),
    notificationModelSaga(),
    subscriptionModelSaga(),
    anonymousUserModelSaga(),
    userManagementModelSaga(),
    patientHistoryModelSaga(),
    followUpReminderModelSaga(),
    consultationTypeModelSaga(),
    insuranceProviderModelSaga(),
  ]);
}
