import { PAYMENT_TRANSACTION_MODEL_FIELDS } from '../../model/payment';

export const PACKAGE_NAME = 'Payment';

export const FIELDS = {
  ...PAYMENT_TRANSACTION_MODEL_FIELDS,
};

export const PAYMENT_STATUS = {
  READY_FOR_PAYMENT: 'Ready for payment',
  INITIATED: 'Payment initiated',
  SUCCESS: 'Payment success',
  FAILED: 'Payment failed',
  REFUSED: 'Payment refused',
  CANCELLED: 'Payment cancelled',
  UNKNOWN: 'Unknown',
  EXPIRED: 'Expired',
};

export const FAILED_PAYMENT_STATUSES = [
  PAYMENT_STATUS.FAILED,
  PAYMENT_STATUS.REFUSED,
  PAYMENT_STATUS.CANCELLED,
  PAYMENT_STATUS.UNKNOWN,
  PAYMENT_STATUS.EXPIRED,
];
