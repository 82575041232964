import isEmpty from 'lodash/isEmpty';
import subMinutes from 'date-fns/subMinutes';
import addMinutes from 'date-fns/addMinutes';
import isWithinInterval from 'date-fns/isWithinInterval';

import { OUTCOME_OPTIONS } from 'domain/outcome/constants';

// eslint-disable-next-line import/prefer-default-export
export const checkIfMeetingWithin10MinRange = (startT, endT) => {
  const rangeStart = subMinutes(startT, 10);
  const rangeEnd = addMinutes(endT, 10);

  try {
    return isWithinInterval(new Date(), { start: rangeStart, end: rangeEnd });
  } catch (e) {
    return false;
  }
};

/**
 * Check if the meeting is running now, or finished 10 minutes ago
 */
export const checkIfMeetingStarted = (startTime, endTime) => {
  try {
    return isWithinInterval(new Date(), {
      start: startTime,
      end: addMinutes(endTime, 10),
    });
  } catch (e) {
    return false;
  }
};

/**
 * Check if 1- all patients has completed output: Not pending.
 * 2- And has at least one member(attended the consultation) with a referenceType!=no show
 * @param  consultationPatients
 * @returns
 */
export const isOutputSectionCompleted = (consultationPatients) => {
  const isComplete = consultationPatients?.some((patient) => {
    if (isEmpty(patient.output)) {
      return true; // Continue to the next iteration
    }
    const hasOutcome = patient.output.some(
      (outcome) => outcome.referenceType !== OUTCOME_OPTIONS.NoShow.key
    );
    if (hasOutcome) {
      return true; // Exit the loop
    }
    return false; // Continue to the next iteration
  });
  return isComplete;
};

/**
 *
 * Validate if patient is joined the zoom and did not leave
 */
export const isPatientJoined = (
  participants,
  patientName,
  patientPhoneNumber
) => {
  if (!participants || participants?.length === 0) {
    return false;
  }
  // Filter patient's only events
  return participants?.some(
    (participant) =>
      participant.name === patientName ||
      participant.name === patientPhoneNumber
  );
};

// Function to format the time as minutes and seconds
export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
