import React from 'react';

import Grid from '../Grid';
import Typography from '../Typography';

const DisplayField = ({ label, value, loading, labelWidth, valueWidth }) => (
  <Grid item container column rowSpacing={1}>
    <Grid item>
      <Typography variant="p4" loading={loading && { width: labelWidth }}>
        {label}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="l5" loading={loading && { width: valueWidth }}>
        {value}
      </Typography>
    </Grid>
  </Grid>
);

export default DisplayField;
