import { all } from 'redux-saga/effects';

import appSaga from './app/saga';
import labSaga from './lab/saga';
import userSaga from './user/saga';
import authSaga from './auth/saga';
import fileSaga from './file/saga';
import orderSaga from './order/saga';
import doctorSaga from './doctor/saga';
import clinicSaga from './clinic/saga';
import tenantSaga from './tenant/saga';
import paymentSaga from './payment/saga';
import commentSaga from './comment/saga';
import meetingSaga from './meeting/saga';
import outcomeSaga from './outcome/saga';
import medicineSaga from './medicine/saga';
import scheduleSaga from './schedule/saga';
import dietPlanSaga from './dietPlan/saga';
import eligibilitySaga from './eligibility/saga';
import doctorNotesSaga from './doctorNotes/saga';
import notificationSaga from './notification/saga';
import subscriptionSaga from './subscription/saga';
import consultationSaga from './consultation/saga';
import prescriptionSaga from './prescription/saga';
import patientHistorySaga from './patientHistory/saga';
import userManagementSaga from './userManagement/saga';
import followUpReminderSaga from './followUpReminder/saga';
import consultationTypeSaga from './consultationType/saga';
import insuranceProviderSaga from './insuranceProvider/saga';
import anonymousUserSaga from './anonymousUser/saga';

export default function* domainSaga() {
  yield all([
    labSaga(),
    appSaga(),
    authSaga(),
    userSaga(),
    fileSaga(),
    orderSaga(),
    doctorSaga(),
    clinicSaga(),
    tenantSaga(),
    commentSaga(),
    meetingSaga(),
    paymentSaga(),
    outcomeSaga(),
    medicineSaga(),
    scheduleSaga(),
    dietPlanSaga(),
    doctorNotesSaga(),
    eligibilitySaga(),
    notificationSaga(),
    subscriptionSaga(),
    consultationSaga(),
    prescriptionSaga(),
    patientHistorySaga(),
    userManagementSaga(),
    followUpReminderSaga(),
    consultationTypeSaga(),
    insuranceProviderSaga(),
    anonymousUserSaga(),
  ]);
}
