export const PACKAGE_NAME = 'Meeting';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  DOCTOR_ID: {
    name: 'doctorId',
  },
  MEETING_NUMBER: {
    name: 'meetingNumber',
  },
  PASSWORD: {
    name: 'password',
  },
  ENCRYPTED_PASSWORD: {
    name: 'encryptedPassword',
  },
  ZOOM_EMAIL: {
    name: 'zoomEmail',
  },
  ACCOUNT_ID: {
    name: 'accountId',
  },
  CLIENT_ID: {
    name: 'clientId',
  },
  CLIENT_SECRET: {
    name: 'clientSecret',
  },
  WEBHOOK_SECRET_TOKEN: {
    name: 'webhooksSecretToken',
  },
  VERIFICATION_TOKEN: {
    name: 'verificationToken',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
};
