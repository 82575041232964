import * as types from './actionTypes';

export const fetchPatientHistory = (id) => ({
  type: types.FETCH_PATIENT_HISTORY,
  id,
});

export const patientHistoryReceived = (patientHistory) => ({
  type: types.PATIENT_HISTORY_RECEIVED,
  patientHistory,
});

export const setFetchingPatientHistory = (isFetching) => ({
  type: types.SET_FETCHING_PATIENT_HISTORY,
  isFetching,
});

export const clearPatientHistory = () => ({
  type: types.CLEAR_PATIENT_HISTORY,
});
