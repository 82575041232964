import { all, put, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import { dietPlanModelActions, dietPlanModelActionTypes } from 'model/dietPlan';

import * as actions from './actions';
import * as types from './actionTypes';

export function* getLatestDietPlan({ userId }) {
  yield put(actions.setFetchingDietPlan(true));
  yield put(dietPlanModelActions.fetchLatestDietPlan(userId));
}

export function* latestDietPlanReceived({ latestDietPlan }) {
  yield put(actions.latestDietPlanReceived(latestDietPlan?.[0]));
  yield put(actions.setFetchingDietPlan(false));
}

export function* createDietPlan({ payload }) {
  yield put(actions.setSubmittingDietPlan(true));
  yield put(dietPlanModelActions.createDietPlan(payload));
}

export function* dietPlanCreationResponded({ ok, response }) {
  if (ok) {
    yield put(actions.latestDietPlanReceived(response));
    Message.success('Diet plan has been added successfully');
  }

  yield put(actions.setSubmittingDietPlan(false));
}

export default function* dietPlanSaga() {
  yield all([
    takeLatest(types.GET_LATEST_DIET_PLAN, getLatestDietPlan),
    takeLatest(types.CREATE_DIET_PLAN, createDietPlan),
    takeLatest(
      dietPlanModelActionTypes.DIET_PLAN_CREATION_RESPONDED,
      dietPlanCreationResponded
    ),
    takeLatest(
      dietPlanModelActionTypes.LATEST_DIET_PLAN_RECEIVED,
      latestDietPlanReceived
    ),
  ]);
}
