/* eslint-disable react/no-unstable-nested-components */
// Important for rendering the text within the pdf file
import 'react-pdf/dist/esm/Page/TextLayer.css';
import CloseIcon from '@mui/icons-material/Close';
import { pdfjs, Page, Document } from 'react-pdf';
import ImageIcon from '@mui/icons-material/Image';
import React, { useState, useEffect } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DeleteIcon from '@mui/icons-material/Delete';
// Important for rendering the annotations within the pdf file
// Although we don't have annotations, but that protects the view from extra height
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppTwoTone';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import Box from '../Box';
import Grid from '../Grid';
import Button from '../Button';
import Dialog from '../Dialog';
import Tooltip from '../Tooltip';
import Skeleton from '../Skeleton';
import IconButton from '../IconButton';
import Typography from '../Typography';
import DialogTitle from '../Dialog/DialogTitle';
import ReactFileViewer from '../ReactFileViewer';
import DialogActions from '../Dialog/DialogActions';
import DialogContent from '../Dialog/DialogContent';

const DocumentOptions = {
  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

const sizeMap = {
  small: {
    spacing: 1,
    width: 5,
    height: 5,
    fontSize: '2rem',
  },
  medium: {
    spacing: 2,
    width: 12,
    height: 12,
    fontSize: '3.5rem',
  },
};

const FilePreview = ({
  loading,
  onDelete,
  files = [],
  open = false,
  dialogActions,
  size = 'medium',
  dialogProps = {},
  onClose = () => {},
  showThumbnails = false,
}) => {
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [isOpen, setOpen] = useState(open);
  const [filePageCount, setFilePageCount] = useState(1);
  const [selectedFileIdx, setSelectedFileIdx] = useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const navigateToFile = (index) => {
    setSelectedFileIdx(index);
    setScale(1);
    setPage(1);
    setFilePageCount(1);
  };

  const handleClick = (index) => {
    setSelectedFileIdx(index);
    setScale(1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const getFileUrl = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }

    return file.url;
  };

  const downloadFile = (file) => {
    const url = getFileUrl(file);
    const link = document.createElement('a');

    link.download = url?.split('/')?.pop() || '';
    link.href = url;
    link.click();
  };

  const DeleteFileDialog = ({
    open: isDialogOpen,
    onConfirmDelete,
    onClose: onClosed,
  }) => {
    // TODO: Use design-system's PopConfirm
    return (
      <Dialog
        fullWidth
        onClose={onClosed}
        open={isDialogOpen}
        aria-labelledby="delete-file-dialog-title"
        aria-describedby="delete-file-dialog-title"
      >
        <DialogTitle id="delete-file-dialog-title" onClose={onClosed}>
          Are sure you want to delete this file?
        </DialogTitle>
        <DialogActions>
          <Button onClick={onConfirmDelete} color="error">
            Yes
          </Button>
          <Button onClick={onClosed} autoFocus color="success">
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // TODO: Separate into a different file/files
  const ViewByType = ({ file, index }) => {
    if (!file?.type) {
      return null;
    }

    if (file?.type?.includes('image')) {
      if (showThumbnails) {
        return (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleClick(index);
            }}
            tool
            sx={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              '& > img': {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              },
            }}
          >
            <img src={getFileUrl(file)} alt={file.name || ''} />
          </Box>
        );
      }

      return (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index);
          }}
        >
          <ImageIcon
            color="primary"
            sx={{
              fontSize: sizeMap[size].fontSize,
              cursor: 'pointer',
            }}
          />
        </Grid>
      );
    }

    if (file?.type?.includes('pdf')) {
      return (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index);
          }}
        >
          <PictureAsPdfIcon
            color="error"
            sx={{
              fontSize: sizeMap[size].fontSize,
              cursor: 'pointer',
            }}
          />
        </Grid>
      );
    }

    // Excel files
    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <InsertDriveFileIcon
          color="success"
          sx={{
            fontSize: sizeMap[size].fontSize,
            cursor: 'pointer',
          }}
        />
        {file.name}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Grid item container flexWrap="wrap" spacing={1} sx={{ mb: 1 }}>
        {Array.from(
          Array(
            files?.length || loading?.count === undefined ? 3 : loading.count
          )
        ).map(() => (
          <Grid
            item
            sx={{
              mr: 1,
              width: (theme) =>
                theme.spacing(sizeMap[size].width - sizeMap[size].spacing),
            }}
          >
            <Skeleton
              variant="rectangle"
              sx={{
                height: (theme) =>
                  theme.spacing(sizeMap[size].height - sizeMap[size].spacing),
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={sizeMap[size].spacing}>
        {files &&
          files.map((file, idx) => (
            <Grid
              item
              sx={{
                width: (theme) => theme.spacing(sizeMap[size].width),
                height: (theme) => theme.spacing(sizeMap[size].height),
              }}
            >
              <Tooltip autoHide title="Click on file to view" placement="top">
                <ViewByType file={file} index={idx} />
              </Tooltip>
            </Grid>
          ))}
      </Grid>

      <Dialog fullScreen open={isOpen} onClose={handleClose} {...dialogProps}>
        <DialogTitle>
          <Grid
            container
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item sx={{ width: '100%' }}>
              {files[selectedFileIdx]?.name || ''}
            </Grid>
            <Grid item container sx={{ width: '100%' }} justifyContent="center">
              <IconButton
                disabled={selectedFileIdx <= 0}
                onClick={() => navigateToFile(selectedFileIdx - 1)}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                style={{ opacity: loading ? 0 : 1 }}
                onClick={() => downloadFile(files[selectedFileIdx])}
              >
                <GetAppTwoToneIcon />
              </IconButton>
              <IconButton
                disabled={scale >= 2}
                onClick={() => setScale(scale + 0.2)}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                disabled={scale <= 0.5}
                onClick={() => setScale(scale - 0.2)}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton onClick={() => setScale(1)} disabled={scale === 1}>
                <ZoomInMapIcon />
              </IconButton>
              <IconButton
                disabled={selectedFileIdx >= files.length - 1}
                onClick={() => navigateToFile(selectedFileIdx + 1)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
              {onDelete && (
                <IconButton
                  disabled={!onDelete}
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item container sx={{ width: '100%' }} rowReverse>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {files[selectedFileIdx]?.type?.includes('pdf') && (
            <>
              {filePageCount > 1 && (
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{ my: 1 }}
                >
                  {filePageCount > 1 && (
                    <Grid item>
                      <IconButton
                        disabled={page === 1}
                        onClick={() => {
                          if (page === 1) {
                            return;
                          }
                          setPage(page - 1);
                        }}
                      >
                        <ArrowBackIosNewIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography variant="p1">
                      Page {page} of {filePageCount}
                    </Typography>
                  </Grid>
                  {filePageCount > 1 && (
                    <Grid item>
                      <IconButton
                        disabled={page === filePageCount}
                        onClick={() => {
                          if (page === filePageCount) {
                            return;
                          }
                          setPage(page + 1);
                        }}
                      >
                        <ArrowForwardIosIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
              >
                <Grid
                  item
                  sx={{
                    '& .react-pdf__Document': {
                      ...(scale !== 1 && {
                        transform: `scale(${scale})`,
                        transformOrigin: '50% 0',
                      }),
                    },
                  }}
                >
                  <Document
                    file={getFileUrl(files[selectedFileIdx])}
                    options={DocumentOptions}
                    onLoadSuccess={({ numPages }) => setFilePageCount(numPages)}
                  >
                    <Page pageNumber={page} />
                  </Document>
                </Grid>
              </Grid>
            </>
          )}
          {!files[selectedFileIdx]?.type?.includes('pdf') &&
            files[selectedFileIdx]?.type && (
              <Box
                sx={{
                  height: '100%',
                  '& .pdf-canvas': {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                  ...(scale !== 1 && {
                    '& .pg-viewer-wrapper': {
                      transform: `scale(${scale})`,
                      transformOrigin: '50% 0',
                    },
                  }),
                }}
              >
                <ReactFileViewer
                  key={getFileUrl(files[selectedFileIdx])}
                  filePath={getFileUrl(files[selectedFileIdx])}
                  fileType={files[selectedFileIdx].type.split('/')[1]}
                />
              </Box>
            )}
        </DialogContent>
        {dialogActions && (
          <DialogActions sx={{ px: 3, py: 2 }}>{dialogActions}</DialogActions>
        )}
      </Dialog>
      {onDelete && (
        <DeleteFileDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirmDelete={() => {
            onDelete(files[selectedFileIdx]?.id);
            setDeleteDialogOpen(false);
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default FilePreview;
