import * as types from './actionTypes';

export const getConsultationTypes = () => ({
  type: types.GET_CONSULTATION_TYPES,
});

export const getConsultationTypeById = (id) => ({
  type: types.GET_CONSULTATION_TYPE_BY_ID,
  id,
});

export const setIsSubmittingConsultationType = (isSubmitting) => ({
  type: types.SET_SUBMITTING_CONSULTATION_TYPE,
  isSubmitting,
});
export const setFetchingConsultationType = (isFetching) => ({
  type: types.SET_FETCHING_CONSULTATION_TYPE,
  isFetching,
});

export const updateConsultationType = (id, payload) => ({
  type: types.UPDATE_CONSULTATION_TYPE,
  id,
  payload,
});

export const createConsultationType = (payload) => ({
  type: types.CREATE_CONSULTATION_TYPE,
  payload,
});

export const consultationTypeReceived = (consultationType, id) => ({
  type: types.CONSULTATION_TYPE_RECEIVED,
  consultationType,
  id,
});

export const consultationTypesReceived = (consultationTypes) => ({
  type: types.CONSULTATION_TYPES_RECEIVED,
  consultationTypes,
});

export const getConsultationTypeConfigurations = (consultationTypeId) => ({
  type: types.GET_CONSULTATION_TYPE_CONFIGURATIONS,
  consultationTypeId,
});

export const setFetchingConsultationTypeConfigurations = (isFetching) => ({
  type: types.SET_FETCHING_CONSULTATION_TYPE_CONFIGURATION,
  isFetching,
});

export const setIsSubmittingConsultationTypeConfiguration = (isSubmitting) => ({
  type: types.SET_SUBMITTING_CONSULTATION_TYPE_CONFIGURATION,
  isSubmitting,
});

export const consultationTypeConfigurationsReceived = (
  consultationTypeConfigurations,
  consultationId
) => ({
  type: types.CONSULTATION_TYPE_CONFIGURATION_RECEIVED,
  consultationTypeConfigurations,
  consultationId,
});

export const addConsultationTypeConfiguration = (payload) => ({
  type: types.ADD_CONSULTATION_TYPE_CONFIGURATION,
  payload,
});

export const updateConsultationTypeConfiguration = (id, payload) => ({
  type: types.UPDATE_CONSULTATION_TYPE_CONFIGURATION,
  id,
  payload,
});

export const deleteConsultationTypeConfiguration = (id) => ({
  type: types.DELETE_CONSULTATION_TYPE_CONFIGURATION,
  id,
});
