import React, { useState } from 'react';

import { Switch } from 'design-system';

const SwitchFilter = ({
  field: { label, filter = () => {}, disabled, defaultValue = false },
}) => {
  const [isChecked, setChecked] = useState(defaultValue);

  const onSwitchChanged = (event) => {
    const { checked } = event.target;

    setChecked(checked);
    filter(checked);
  };

  return (
    <Switch
      label={label}
      checked={isChecked}
      disabled={disabled}
      onChange={onSwitchChanged}
    />
  );
};

export default SwitchFilter;
