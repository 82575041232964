import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import {
  consultationTypeActions as actions,
  consultationTypeSelectors as selectors,
} from 'domain/consultationType';

import ConsultationTypeManagementView from './ConsultationTypeManagementView';

const mapStateToProps = (state) => {
  const consultationType = selectors.getConsultationType(state);
  const isFetching = selectors.isFetching(state);
  const isSubmitting = selectors.isSubmitting(state);
  const isSubmittingConfiguration = selectors.isSubmittingConfiguration(state);

  return {
    isFetching,
    isSubmitting,
    consultationType,
    isSubmittingConfiguration,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getConsultationType: (id) => dispatch(actions.getConsultationTypeById(id)),
  updateConsultationType: (id, payload) =>
    dispatch(actions.updateConsultationType(id, payload)),
  addConsultationTypeConfiguration: (consultationTypeId, payload) =>
    dispatch(
      actions.addConsultationTypeConfiguration(consultationTypeId, payload)
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(ConsultationTypeManagementView)
);
