import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { Link } from 'design-system';
import { dateTimeFormat } from 'constants/dateTime';
import {
  DOCTOR_FIELDS as FIELDS,
  doctorGetters as getters,
} from 'domain/doctor';

export const getColumns = () => [
  { field: FIELDS.ID.name, headerName: 'ID', width: 80 },
  {
    field: FIELDS.USER_ID.name,
    headerName: 'User ID',
    width: 100,
    renderCell: (params) => {
      const userId = getters.getUserId(params.row);

      return userId ? <Link href={`/user/${userId}`}>{userId}</Link> : '';
    },
  },
  { field: FIELDS.ENGLISH_NAME.name, headerName: 'Name (En)', flex: 1 },
  { field: FIELDS.ARABIC_NAME.name, headerName: 'Name (Ar)', flex: 1 },
  {
    field: FIELDS.LICENSE_TITLE_ENGLISH.name,
    headerName: 'Speciality (En)',
    flex: 1,
  },
  {
    field: FIELDS.LICENSE_TITLE_ARABIC.name,
    headerName: 'Speciality (Ar)',
    flex: 1,
  },
  {
    field: FIELDS.LICENSE_TYPE.name,
    headerName: 'License Type',
    flex: 1,
    valueGetter: (prms) => getters.getLicenseTypeEnumValue(prms.row)?.label,
  },
  {
    field: FIELDS.ACTIVE.name,
    headerName: 'Active',
    type: 'boolean',
    flex: 1,
  },
  {
    field: FIELDS.CREATED_AT.name,
    headerName: 'Created At',
    flex: 1,
    valueGetter: (params) =>
      format(parseISO(getters.getCreatedAt(params.row)), dateTimeFormat),
  },
];
