import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { FILE_TYPES } from 'domain/file';
import FileUpload from 'core/file/FileUpload';
import { Controller } from 'design-system/form';
import PatientOutcomeWizardDialog from 'presentation/components/PatientOutcomeWizardDialog';
import {
  OUTCOME_OPTIONS,
  OUTCOME_SUBMISSION_STATUS,
} from 'domain/outcome/constants';
import {
  Grid,
  Radio,
  Switch,
  Select,
  Message,
  MenuItem,
  FormField,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
} from 'design-system';

const GENERIC_LABEL = 'Generic medicine';
const BRANDED_LABEL = 'Branded medicine';

const SendPrescriptionsDialog = ({
  open,
  userId,
  onClose,
  tenants,
  patients,
  tenantKey,
  initialTab = 0,
  consultationId,
  submitPrescriptions,
  onPrescriptionsSubmitted,
  isSubmittingPrescriptions,
}) => {
  const [isOpen, setOpen] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      branch: tenantKey,
      prescriptions: [],
      hasPrescription: true,
      isGenericMedicine: true,
    };
  }, [tenantKey]);

  const hasFormChanged = useCallback((savedData = {}, currentData = {}) => {
    if (savedData.branch !== currentData.branch) {
      return true;
    }

    if (savedData.hasPrescription !== currentData.hasPrescription) {
      return true;
    }

    if (savedData.isGenericMedicine !== currentData.isGenericMedicine) {
      return true;
    }

    // Only check IDs, because file status and other data can change in the process, and that wouldn't mean a change
    if (
      (savedData.prescriptions || [])
        .map((prescription) => prescription.id)
        .sort()
        .join(',') !==
      (currentData.prescriptions || [])
        .map((prescription) => prescription.id)
        .sort()
        .join(',')
    ) {
      return true;
    }

    return false;
  }, []);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <PatientOutcomeWizardDialog
      open={isOpen}
      onClose={onClosed}
      patients={patients}
      initialTab={initialTab}
      defaultValues={defaultValues}
      consultationId={consultationId}
      hasFormChanged={hasFormChanged}
      hasOutcomeFieldName="hasPrescription"
      referenceType={OUTCOME_OPTIONS.Prescription.key}
      isSubmittingPatients={isSubmittingPrescriptions}
      outcomeToFormData={(outcome) => ({
        branch: outcome.tenantKey,
        hasPrescription: true,
        prescriptions: outcome.referenceFiles.map((file) => ({
          id: file,
        })),
        completePrescriptions: outcome.referenceFiles.map((file) => ({
          id: file,
        })),
        isGenericMedicine: outcome.isGeneral,
        submissionStatus: outcome.submissionStatus,
      })}
      formDataToOutcomePayload={(formData) => ({
        tenantKey: formData.branch,
        referenceType: OUTCOME_OPTIONS.Prescription.key,
        referenceFiles: (formData.prescriptions || []).map(
          (prescription) => prescription.id
        ),
        isGeneral: formData.isGenericMedicine,
      })}
      submitAllPatients={(successCallback) => {
        submitPrescriptions(consultationId);

        onPrescriptionsSubmitted(() => {
          Message.success(
            'Prescriptions have been sent to pharmacy successfully!'
          );

          successCallback();
        });
      }}
      labels={{
        dialogTitle: 'Send Prescriptions',
        ariaLabel: 'send-prescription',
        outcomeLabel: 'prescription',
        submittingLabel: 'Sending prescriptions to pharmacy...',
        submitButtonTooltip:
          'Send prescription for the patient and the pharmacy team will be notified!',
      }}
    >
      {({
        watch,
        control,
        setValue,
        isNoShow,
        setDirtyFields,
        patientOutcomeFields,
      }) => (
        <Grid container column rowSpacing={1}>
          {/* Branch */}
          <Grid item>
            <FormField
              label="Branch"
              field={
                <Controller
                  name="branch"
                  control={control}
                  defaultValue={patientOutcomeFields?.branch}
                  render={({
                    field: { onChange, value },
                    fieldState: { isDirty },
                  }) => (
                    <Select
                      fullWidth
                      size="large"
                      soak="light"
                      value={value}
                      variant="filled"
                      onChange={(e) => {
                        setDirtyFields((previousState) => ({
                          ...previousState,
                          branch: !isDirty,
                        }));
                        onChange(e);
                      }}
                    >
                      {(tenants || []).map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              }
            />
          </Grid>

          {/* Has Prescription */}
          <Grid item>
            <Controller
              name="hasPrescription"
              control={control}
              defaultValue={patientOutcomeFields?.hasPrescription}
              render={({
                field: { onChange, value },
                formState: { isDirty },
              }) => (
                <RadioGroup
                  row
                  value={value ? 'hasPrescription' : 'noPrescription'}
                  aria-labelledby="has-prescription-radio-buttons-group-label"
                  name="has-prescription-radio-buttons-group"
                  onChange={(e) => {
                    setDirtyFields((previousState) => ({
                      ...previousState,
                      hasPrescription: !isDirty,
                    }));
                    onChange(e.target.value === 'hasPrescription');
                  }}
                >
                  <FormControlLabel
                    value="hasPrescription"
                    label="Has prescription"
                    control={<Radio size="small" disabled={isNoShow} />}
                  />
                  <FormControlLabel
                    value="noPrescription"
                    label="No prescription"
                    control={
                      <Radio
                        size="small"
                        disabled={
                          // Checking referenceId should be enough,
                          // except that at first submission it won't be available
                          // but submissionStatus will be set to submitted in consultation/reducer
                          patientOutcomeFields?.submissionStatus ===
                            OUTCOME_SUBMISSION_STATUS.SUBMITTED.key ||
                          Boolean(patientOutcomeFields?.referenceId)
                        }
                      />
                    }
                  />
                </RadioGroup>
              )}
            />
            {!isEmpty(patientOutcomeFields?.prescriptions) &&
              // Without "=== false" it will show the message for undefined value
              watch('hasPrescription') === false && (
                <FormHelperText
                  sx={{
                    color: (theme) => theme.palette.warning.main,
                  }}
                >
                  Marking as no prescription will delete the uploaded file
                </FormHelperText>
              )}
          </Grid>

          {/* Prescription */}
          <Grid item>
            <FormField
              required={watch('hasPrescription')}
              label="Prescription"
              field={
                <Controller
                  name="prescriptions"
                  control={control}
                  // Why using map? In order to refetch the files. Why? Because the File instance will be lost after saving the data
                  // The only source of truth is the id of the file upon which we do the fetch.
                  // We don't need to worry about network activity, because the files are cached in browser memory.
                  defaultValue={(patientOutcomeFields?.prescriptions || []).map(
                    (prescription) => ({
                      id: prescription.id,
                    })
                  )}
                  render={({
                    field: { onChange, value: uploadedFiles = [] },
                    fieldState: { error },
                  }) => (
                    <FileUpload
                      userId={userId}
                      files={uploadedFiles}
                      // Disable if the value of hasPrescription is false
                      // watch watches the state changes on hasPrescription field
                      // Check react-hook-form documentation for more info
                      onChange={(files, isActualChange, _2, completeFiles) => {
                        onChange(files);

                        // Add another field to the form state called completePrescriptions,
                        // to keep track of which files have been fully uploaded or have IDs and being fetched
                        // i.e: statuses COMPLETE and FETCHING
                        // Check core/file/FileUpload for more info
                        setValue('completePrescriptions', completeFiles);

                        setDirtyFields((previousState) => ({
                          ...previousState,
                          prescriptions: isActualChange,
                        }));
                      }}
                      config={{
                        fileType: FILE_TYPES.PRESCRIPTION_IMAGE,
                      }}
                      error={Boolean(error)}
                      helperText={error?.message || null}
                      disabled={!watch('hasPrescription')}
                      placeholder={
                        isEmpty(uploadedFiles)
                          ? 'Upload prescription'
                          : 'Replace current prescription'
                      }
                    />
                  )}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (watch('hasPrescription') && !value?.length) {
                          return 'A prescription should be uploaded';
                        }
                      },
                      pendingUpload: (value) => {
                        if (
                          (watch('completePrescriptions')?.length ?? 0) !==
                          (value?.length ?? 0)
                        ) {
                          return 'The prescription should finish uploading';
                        }
                      },
                    },
                  }}
                />
              }
            />
          </Grid>

          {/* Is Generic Medicine */}
          <Grid item>
            <Controller
              name="isGenericMedicine"
              control={control}
              defaultValue={patientOutcomeFields?.isGenericMedicine}
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => {
                    // Using isDirty is not reflected correctly. Fall back to e.target.checked
                    setDirtyFields((previousState) => ({
                      ...previousState,
                      isGenericMedicine:
                        e?.target?.checked !==
                        patientOutcomeFields?.isGenericMedicine,
                    }));
                    onChange(e);
                  }}
                  positiveLabel={GENERIC_LABEL}
                  negativeLabel={BRANDED_LABEL}
                  disabled={!watch('hasPrescription')}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </PatientOutcomeWizardDialog>
  );
};

export default SendPrescriptionsDialog;
