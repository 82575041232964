import React from 'react';
import MuiTypography from '@mui/material/Typography';

import Skeleton from '../Skeleton';

const Typography = ({ children, variant, loading, ...rest }) => {
  if (loading) {
    return (
      <Skeleton
        variant="rectangle"
        componentName="typography"
        componentVariant={variant}
        {...(typeof loading !== 'boolean' ? loading : {})}
      />
    );
  }

  return (
    <MuiTypography variant={variant} {...rest}>
      {children}
    </MuiTypography>
  );
};

export default Typography;
