import get from 'lodash/get';
import { createSelector } from 'reselect';

export const orderSelector = (state) => get(state, 'domain.order', {});
export const orderImportSelector = (state) =>
  get(state, 'domain.order.import', {});

export const getOrder = createSelector(orderSelector, (results) =>
  get(results, 'view')
);

export const isFetching = createSelector(orderSelector, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(orderSelector, (results) =>
  get(results, 'isSubmitting')
);

export const isFetchingPrescriptions = createSelector(
  orderSelector,
  (results) => get(results, 'isFetchingPrescriptions')
);

export const isUpdatingLineItems = createSelector(orderSelector, (results) =>
  get(results, 'isUpdatingLineItems')
);

export const getSearchedOrder = createSelector(orderImportSelector, (results) =>
  get(results, 'searchedOrder')
);

export const getPinnedOrders = createSelector(orderImportSelector, (results) =>
  get(results, 'pinnedOrders')
);

export const getSearchedOrderFetchError = createSelector(
  orderImportSelector,
  (results) => get(results, 'searchedOrderFetchError')
);

export const isFetchingSearchedOrder = createSelector(
  orderImportSelector,
  (results) => get(results, 'isFetchingSearchedOrder')
);

export const isFetchingPinnedOrders = createSelector(
  orderImportSelector,
  (results) => get(results, 'isFetchingPinnedOrders')
);

export const getLogisticProviders = createSelector(orderSelector, (results) =>
  get(results, 'logisticProviders')
);

export const getShipment = createSelector(orderSelector, (results) =>
  get(results, 'shipment')
);

export const getShipmentLabel = createSelector(orderSelector, (results) =>
  get(results, 'shipmentLabel')
);

export const isFetchingTaskId = createSelector(orderSelector, (results) =>
  get(results, 'isFetchingTaskId')
);

export const getOrderHistory = createSelector(orderSelector, (results) =>
  get(results, 'orderHistory')
);

export const isFetchingOrderHistory = createSelector(orderSelector, (results) =>
  get(results, 'isFetchingOrderHistory')
);

export const getLineItemsHistory = createSelector(orderSelector, (results) =>
  get(results, 'lineItemsHistory')
);

export const isFetchingLineItemsHistory = createSelector(
  orderSelector,
  (results) => get(results, 'isFetchingLineItemsHistory')
);
