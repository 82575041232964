import keys from 'lodash/keys';
import { getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';

import google from 'google';
import httpClient from 'core/httpClient';
import { getGoogleApiKey } from 'core/siteConfig';

import { SHORT_TO_FULL_MAP_URL_ENDPOINT } from './constants';

const getCategorizedAddressComponents = (addressFields) => {
  const categories = [
    {
      field: addressFields.APARTMENT.name,
      types: ['floor', 'room', 'landmark', 'post_box'],
    },
    {
      field: addressFields.BUILDING.name,
      types: ['establishment', 'premise', 'subpremise', 'parking'],
    },
    {
      field: addressFields.STREET.name,
      types: ['intersection', 'street_number', 'street_address', 'route'],
    },
    {
      field: addressFields.AREA.name,
      types: [
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
        'postal_code',
        'natural_feature',
        'airport',
        'park',
        'point_of_interest',
        'postal_town',
        'bus_station',
        'train_station',
        'transit_station',
      ],
    },
    {
      field: addressFields.CITY.name,
      types: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
        'administrative_area_level_6',
        'administrative_area_level_7',
        'colloquial_area',
        'locality',
      ],
    },
    {
      field: addressFields.COUNTRY.name,
      types: ['country'],
    },
  ];

  return categories;
};

export const parseLocation = (addressFields) => (location) => {
  return new Promise((resolve) => {
    const address = location?.address_components ?? [];
    const categories = getCategorizedAddressComponents(addressFields);
    const parsedLocation = {};

    parsedLocation[addressFields.FORMATTED_ADDRESS.name] =
      location?.formatted_address;
    parsedLocation[addressFields.PLACE_ID.name] = location?.place_id;

    const loc = {};
    const assignedTypes = {};

    // For each address component
    address.forEach((component) => {
      // For each type in this address component
      component.types.forEach((type) => {
        // Types can belong to multiple address components.
        // So, if this type has not been assigned to a field
        if (!assignedTypes[type]) {
          assignedTypes[type] = true;

          // Loop over the categories in order
          categories.forEach((category) => {
            const { field, types } = category;

            // If this type belong to this category's types
            if (types.includes(type)) {
              if (!loc[field]) {
                loc[field] = [];
              }

              // Push that address component to the resulting location,
              // under the specified field, without duplication
              if (!loc[field].includes(component.long_name)) {
                loc[field].push(component.long_name);
              }
            }
          });
        }
      });
    });

    // Convert the array of address components to a comma-separated string
    keys(loc).forEach((field) => {
      parsedLocation[field] = (loc[field] || []).join(', ');
    });

    getLatLng(location)
      .then((latLng) => {
        parsedLocation[addressFields.LATITUDE.name] = latLng?.lat;
        parsedLocation[addressFields.LONGITUDE.name] = latLng?.lng;
      })
      .finally(() => {
        resolve(parsedLocation);
      });
  });
};

export const fetchLocation = (addressFields) => (placeId) => {
  return new Promise((resolve, reject) => {
    geocodeByPlaceId(placeId)
      .then((results) => {
        parseLocation(addressFields)(results[0])
          .then((parsedLocation) => resolve(parsedLocation))
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
};

export const parseMapUrl = (addressFields) => (mapUrl) => {
  // TODO: hit on drivers/map/api/search/{GKEY}/{url}
  // /admin/api/v1/drivers/maps/api/search/{key}/{uri}
  // Take the result and pass it to geocode
  const geocode = (param) => {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode(param, (results, status) => {
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status === google.maps.GeocoderStatus.OK) {
          parseLocation(addressFields)(results[0])
            .then((location) => resolve(location))
            .catch((error) => reject(error));
        }
      });
    });
  };

  return new Promise((resolve, reject) => {
    try {
      let url = mapUrl.split('q=');

      if (url.length > 1) {
        // TODO: q=lat,long&z=17...etc
        const coords = url[1].split(',');
        const latFromUrl = parseFloat(coords[0]) || 0;
        const lngFromUrl = parseFloat(coords[1]) || 0;

        if (latFromUrl && lngFromUrl) {
          const latLong = new google.maps.LatLng(latFromUrl, lngFromUrl);

          geocode({ location: latLong })
            .then((location) => resolve(location))
            .catch((error) => reject(error));

          return;
        }
      }

      url = mapUrl.split('place/');

      if (url.length > 1) {
        url = url[1].split('/');

        const address = url[0];

        if (address) {
          geocode({ address })
            .then((location) => resolve(location))
            .catch((error) => reject(error));

          return;
        }
      }

      httpClient
        .get(
          [
            SHORT_TO_FULL_MAP_URL_ENDPOINT,
            getGoogleApiKey(),
            encodeURIComponent(url),
          ].join('/'),
          {
            retry: false,
          }
        )
        .then((response) => {
          const location = response?.data?.results?.[0]?.geometry?.location;

          if (location) {
            geocode({ location })
              .then((loc) => resolve(loc))
              .catch((error) => reject(error));
          }
        })
        .catch((error) => reject(error));
    } catch (err) {
      reject(new Error('Incorrect location. Please use a valid one'));
    }
  });
};

export const getAddressMapUrl = (address) => {
  if (address.referenceMapLink) {
    return address.referenceMapLink;
  }

  if (address.latitude && address.longitude) {
    return `https://www.google.com/maps/search/?api=1&query=${address.latitude}%2C${address.longitude}`;
  }

  return undefined;
};
