import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import appData from 'domain/app/HOCs/appData';
import {
  consultationActions as actions,
  consultationSelectors as selectors,
  consultationActionTypes as actionTypes,
} from 'domain/consultation';

import AddLabTestDialog from './AddLabTestDialog';

const mapStateToProps = (state) => {
  const isSubmittingLabTests =
    selectors.isSubmittingConsultationLabTests(state);

  return {
    isSubmittingLabTests,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitLabTests: (consultationId) =>
    dispatch(actions.submitConsultationLabTests(consultationId)),
  onLabTestsSubmitted: (onLabTestsSubmittedCallback) =>
    dispatch(
      appActions.pursueAction(
        actionTypes.CONSULTATION_LAB_TESTS_SUBMITTED,
        onLabTestsSubmittedCallback
      )
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(AddLabTestDialog)
);
