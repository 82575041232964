import get from 'lodash/get';
import { createSelector } from 'reselect';

export const prescriptionSelector = (state) =>
  get(state, 'domain.prescription', {});

export const isSubmittingPrescription = createSelector(
  prescriptionSelector,
  (results) => get(results, 'isSubmitting')
);
export const isUpdating = createSelector(prescriptionSelector, (results) =>
  get(results, 'isUpdating')
);

export const isSubmittingPatientPrescription = createSelector(
  prescriptionSelector,
  (results) => get(results, 'isSubmittingPrescription')
);

export const isSubmittingAllPrescriptions = createSelector(
  prescriptionSelector,
  (results) => get(results, 'isSubmittingAllPrescriptions')
);

export const isCreatingPatientPrescriptionDocument = createSelector(
  prescriptionSelector,
  (results) => get(results, 'isCreatingPatientPrescriptionDocument')
);

export const getEPrescriptionDocument = createSelector(
  prescriptionSelector,
  (results) => get(results, 'ePrescriptionDocument')
);

export const getPatientPrescriptionDocument = createSelector(
  prescriptionSelector,
  (results) => get(results, 'patientPrescriptionDocument')
);

export const getPatientPrescriptionDocumentById = createSelector(
  [getPatientPrescriptionDocument, (_state, userId) => userId],
  (results, userId) => get(results, userId)
);

export const getPatientPrescription = createSelector(
  prescriptionSelector,
  (results) => get(results, 'prescription', {})
);

export const getPatientPrescriptionById = createSelector(
  [getPatientPrescription, (_state, userId) => userId],
  (results, userId) => get(results, userId)
);

export const isFetchingPatientPrescription = createSelector(
  prescriptionSelector,
  (results) => get(results, 'isFetchingPatientPrescription')
);
