/* Order domain constants */
import { ADDRESS_FIELDS } from 'domain/user/constants';

export const PACKAGE_NAME = 'Order';

export const LS_ORDER_FILTERS = 'order_filters';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  CREATED_BY_USER: {
    name: 'createdByUser',
  },
  UPDATED_BY_USER: {
    name: 'updatedByUser',
  },
  TENANT_KEY: {
    name: 'tenantKey',
  },
  USER: {
    name: 'user',
  },
  USER_ID: {
    name: 'userId',
  },
  STATUS: {
    name: 'status',
  },
  CHANNEL: {
    name: 'channel',
  },
  DOCTOR: {
    name: 'doctor',
  },
  INSTITUTE: {
    name: 'institute',
  },
  PRESCRIPTIONS: {
    name: 'prescriptions',
  },
  DOCUMENTS: {
    name: 'documents',
  },
  LINE_ITEMS: {
    name: 'lineItems',
  },
  CONSULTATION_ID: {
    name: 'consultationId',
  },
  DELIVERY_ADDRESS: {
    name: 'deliveryAddress',
  },
  DELIVERY_ADDRESS_ID: {
    name: 'deliveryAddressId',
  },
  DELIVERY_ADDRESS_DETAILS: {
    name: 'deliveryAddressDetails',
  },
  TOTAL_PRICE: {
    name: 'totalPrice',
  },
  TOTAL_DISCOUNT: {
    name: 'totalDiscount',
  },
  TOTAL_PATIENT_DISCOUNT: {
    name: 'totalPatientDiscount',
  },
  TOTAL_PATIENT_SHARE: {
    name: 'totalPatientShare',
  },
  TOTAL_PAYER_SHARE: {
    name: 'totalPayerShare',
  },
  HAS_PAYMENT_TRANSACTION: {
    name: 'hasPaymentTransaction',
  },
  PAYMENT_TRANSACTION: {
    name: 'paymentTransaction',
  },
  COMMENT: {
    name: 'comment',
  },
  USER_NOTES: {
    name: 'userNotes',
  },
  IS_GENERIC_MEDICINE: {
    name: 'genericMedicine',
  },
  IS_APPEND_PRESCRIPTION: {
    name: 'appendPrescription',
  },
  REMINDER_DATE: {
    name: 'reminderDate',
  },
  FOLLOW_UP_REMINDER_DATE: {
    name: 'followupReminderDate',
  },
  REFERENCE_ORDER_ID: {
    name: 'referenceOrderId',
  },
  NAMESPACE_KEY: {
    name: 'namespaceKey',
  },
  PRIMARY_DIAGNOSIS: {
    name: 'primaryDiagnosis',
  },
  SHIPPING_DETAILS: {
    name: 'shippingDetails',
  },
  DRIVER_ID: {
    name: 'driverId',
    path: 'shippingDetails.driverId',
  },
  FAILED_DELIVERY_ATTEMPT_REASON: {
    path: 'shippingDetails.failedDeliveryAttemptReason',
  },
  SECONDARY_DIAGNOSIS: {
    name: 'secondaryDiagnosis',
  },
  IS_PRESCRIPTION_SHOWN_TO_PATIENT: {
    name: 'showPrescriptions',
  },
  IS_PINNED: {
    name: 'pinned',
  },
  IS_UPDATE_PENDING_REVIEW: {
    name: 'isUpdatePendingReview',
  },
  LOGISTICS_PROVIDERS_ID: {
    name: 'logisticProviderId',
    path: 'shippingDetails.logisticProviderId',
  },
  EXTERNAL_TASK_ID: {
    name: 'externalTaskId',
    path: 'shippingDetails.externalTaskId',
  },
  ASSIGNEE_ID: {
    name: 'assigneeId',
  },
  ORDER_PATIENTS: {
    name: 'orderPatients',
  },
};

export const LINE_ITEM_FIELDS = {
  ID: {
    name: 'id',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  ORDER_ID: {
    name: 'orderId',
  },
  MEDICINE: {
    name: 'medicine',
  },
  MEDICINE_ID: {
    name: 'medicineId',
  },
  QUANTITY: {
    name: 'quantity',
  },
  QUANTITY_UNIT: {
    name: 'quantityUnit',
  },
  INSTRUCTIONS: {
    name: 'instructions',
  },
  DURATION: {
    name: 'duration',
  },
  DURATION_UNIT: {
    name: 'durationUnit',
  },
  FREQUENCY: {
    name: 'frequency',
  },
  FREQUENCY_UNIT: {
    name: 'frequencyUnit',
  },
  USAGE: {
    name: 'frequencyUse',
  },
  USAGE_UNIT: {
    name: 'frequencyUseUnit',
  },
  PRICE: {
    name: 'price',
  },
  DISCOUNT: {
    name: 'discount',
  },
  PATIENT_SHARE_PERCENTAGE: {
    name: 'patientSharePercentage',
  },
  DISCOUNT_PERCENTAGE: {
    name: 'discountPercentage',
  },
  PATIENT_SHARE: {
    name: 'patientShare',
  },
  PAYER_SHARE: {
    name: 'payerShare',
  },
  REMINDER_DATE: {
    name: 'reminderDate',
  },
  IS_APPROVED_BY_INSURANCE: {
    name: 'approvedByInsurance',
  },
  AVAILABILITY: {
    name: 'availability',
  },
  IS_FIXED_DISCOUNT: {
    name: 'isFixedDiscount',
  },
  PATIENT_DISCOUNT: {
    name: 'patientDiscount',
  },
  PATIENT_DISCOUNT_PERCENTAGE: {
    name: 'patientDiscountPercentage',
  },
  PRICE_AFTER_INSURANCE_DISCOUNT: {
    name: 'priceAfterInsuranceDiscount',
  },
};

export const DELIVERY_ADDRESS_FIELDS = {
  ADD_TO_USER_PROFILE: {
    name: 'addToUserProfile',
  },
  ORDER_ID: {
    name: 'orderId',
  },
  USER_ID: {
    name: 'userId',
  },
  ...ADDRESS_FIELDS,
};

export const STATUSES = {
  NEW_ORDER: {
    key: 'NEW_ORDER',
    label: 'New order',
    color: 'warning',
    soak: 'dark',
  },
  NEW_PRESCRIPTION: {
    key: 'NEW_PRESCRIPTION',
    label: 'New prescription',
    color: 'warning',
    soak: 'dark',
  },
  PHARMACY_REVIEW: {
    key: 'PHARMACY_REVIEW',
    label: 'Pharmacy Review',
    color: 'warning',
  },
  PRESCRIPTION_VERIFIED: {
    key: 'PRESCRIPTION_VERIFIED',
    label: 'Prescription Verified',
    color: 'success',
  },
  UPLOAD_CLEAR_PRESCRIPTION: {
    key: 'UPLOAD_CLEAR_PRESCRIPTION',
    label: 'Upload Clear Prescription',
    color: 'error',
  },
  INSURANCE_REVIEW: {
    key: 'INSURANCE_REVIEW',
    label: 'Insurance Review',
    color: 'warning',
  },
  APPROVED_BY_INSURANCE: {
    key: 'APPROVED_BY_INSURANCE',
    label: 'Approved by Insurance',
    color: 'warning',
  },
  PARTIALLY_APPROVED_BY_INSURANCE: {
    key: 'PARTIALLY_APPROVED_BY_INSURANCE',
    label: 'Partially approved by Insurance',
    color: 'warning',
  },
  CONFIRM_MEDICATION: {
    key: 'CONFIRM_MEDICATION',
    label: 'Confirm medication',
    color: 'primary',
  },
  MEDICATION_CONFIRMED: {
    key: 'MEDICATION_CONFIRMED',
    label: 'Medication Confirmed',
    color: 'primary',
  },
  CONFIRM_DELIVERY_ADDRESS: {
    key: 'CONFIRM_DELIVERY_ADDRESS',
    label: 'Confirm delivery address',
    color: 'primary',
  },
  DELIVERY_ADDRESS_CONFIRMED: {
    key: 'DELIVERY_ADDRESS_CONFIRMED',
    label: 'Delivery address confirmed',
    color: 'warning',
  },
  CONFIRM_DELIVERY_TIME: {
    key: 'CONFIRM_DELIVERY_TIME',
    label: 'Confirm delivery time',
    color: 'primary',
  },
  DELIVERY_TIME_CONFIRMED: {
    key: 'DELIVERY_TIME_CONFIRMED',
    label: 'Delivery time Confirmed',
    color: 'warning',
  },
  PREPARING: {
    key: 'PREPARING',
    label: 'Preparing / scheduled',
    color: 'warning',
  },
  REJECTED_BY_INSURANCE: {
    key: 'REJECTED_BY_INSURANCE',
    label: 'Rejected by Insurance',
    color: 'error',
  },
  OUT_FOR_DELIVERY: {
    key: 'OUT_FOR_DELIVERY',
    label: 'Out for delivery',
    color: 'primary',
  },
  DELIVERED: {
    key: 'DELIVERED',
    label: 'Delivered',
    color: 'success',
    soak: 'dark',
  },
  PAYMENT_PROCESSED: {
    key: 'PAYMENT_PROCESSED',
    label: 'Payment processed',
    color: 'warning',
  },
  PARTIALLY_DELIVERED: {
    key: 'PARTIALLY_DELIVERED',
    label: 'Partially Delivered',
    color: 'warning',
  },
  FAILED_DELIVERY_ATTEMPT: {
    key: 'FAILED_DELIVERY_ATTEMPT',
    label: 'Failed Delivery Attempt',
    color: 'error',
  },
  REFUSED_BY_PHARMACY: {
    key: 'REFUSED_BY_PHARMACY',
    label: 'Refused by pharmacy',
    color: 'error',
  },
  CANCELLED: {
    key: 'CANCELLED',
    label: 'Cancelled',
    color: 'error',
  },
  APPROVED_ONLY_CONFIRMED: {
    key: 'APPROVED_ONLY_CONFIRMED',
    label: 'Approved only confirmed',
    color: 'primary',
  },
  SELF_PAYMENT_REQUESTED: {
    key: 'SELF_PAYMENT_REQUESTED',
    label: 'Self payment requested',
    color: 'warning',
  },
  MEDS_NOT_AVAILABLE: {
    key: 'MEDS_NOT_AVAILABLE',
    label: 'Meds not available',
    color: 'error',
  },
  READY_FOR_DELIVERY: {
    key: 'READY_FOR_DELIVERY',
    label: 'Ready for delivery',
    color: 'success',
  },
  PENDING_UPDATE: {
    key: 'PENDING_UPDATE',
    label: 'Pending update',
    color: 'warning',
  },
  ALL_MEDS_CONFIRMED: {
    key: 'ALL_MEDS_CONFIRMED',
    label: 'All meds confirmed',
    color: 'success',
  },
};

/**
 * @deprecated
 */
export const ORDER_CHANNELS = {
  APP: { label: 'Mobile App', key: 'APP' },
  WALK_IN: { label: 'Walk-in', key: 'WALK IN' },
  MOBILE_WEB: { label: 'Mobile Web', key: 'MOBILE WEB' },
  ALMA_CLINIC: { label: 'Alma clinic', key: 'ALMA CLINIC' },
  PARTNER_CLINIC: { label: 'Partner clinic', key: 'PARTNER CLINIC' },
  PARTNER_PHARMACY: { label: 'Partner pharmacy', key: 'PARTNER PHARMACY' },
  NEXTCARE_GK: { label: 'NextCare GK', key: 'NextCare GK' },
  DELIVEROO: { label: 'Deliveroo', key: 'Deliveroo' },
  TALABAT: { label: 'Talabat', key: 'Talabat' },
  INSTASHOP: { label: 'InstaShop', key: 'InstaShop' },
  NUTRITION_PLAN: { label: 'Nutrition Plan', key: 'Nutrition Plan' },
};

export const CHANNELS = {
  APP: { label: 'Mobile App', key: 'APP' },
  'WALK IN': { label: 'Walk-in', key: 'WALK IN' },
  'MOBILE WEB': { label: 'Mobile Web', key: 'MOBILE WEB' },
  'ALMA CLINIC': { label: 'Alma clinic', key: 'ALMA CLINIC' },
  'PARTNER CLINIC': { label: 'Partner clinic', key: 'PARTNER CLINIC' },
  'PARTNER PHARMACY': { label: 'Partner pharmacy', key: 'PARTNER PHARMACY' },
  'NextCare GK': { label: 'NextCare GK', key: 'NextCare GK' },
  Deliveroo: { label: 'Deliveroo', key: 'Deliveroo' },
  Talabat: { label: 'Talabat', key: 'Talabat' },
  InstaShop: { label: 'InstaShop', key: 'InstaShop' },
  'Nutrition Plan': { label: 'Nutrition Plan', key: 'Nutrition Plan' },
  WLP: { label: 'WLP', key: 'WLP' },
};

export const LINE_ITEM_AVAILABILITY = {
  IN_STOCK: 'in_stock',
  OUT_OF_STOCK: 'out_of_stock',
};

export const DELIVERY_ADDRESS_REQUIRED_STATUSES = [
  STATUSES.DELIVERED.key,
  STATUSES.OUT_FOR_DELIVERY.key,
  STATUSES.PARTIALLY_DELIVERED.key,
  STATUSES.FAILED_DELIVERY_ATTEMPT.key,
];

export const DELIVERY_ADDRESS_OPTIONAL_CHANNELS = [CHANNELS['WALK IN'].key];

export const DELIVERY_FAILURE_REASONS = {
  NOT_ANSWERING: {
    key: 'NOT_ANSWERING',
    label: 'The user is not answering',
  },
  WRONG_ADDRESS: {
    key: 'WRONG_ADDRESS',
    label: 'Wrong delivery address/change location',
  },
  RESCHEDULED: {
    key: 'RESCHEDULED',
    label: 'Rescheduled',
  },
  WRONG_ITEMS: {
    key: 'WRONG_ITEMS',
    label: "Wrong items' details",
  },
  DAMAGED_ITEMS: {
    key: 'DAMAGED_ITEMS',
    label: 'Damaged items',
  },
  PAYMENT_NOT_COMPLETED: {
    key: 'PAYMENT_NOT_COMPLETED',
    label: 'Payment not completed',
  },
};
