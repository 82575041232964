import * as types from './actionTypes';

export const getClinics = () => ({
  type: types.GET_CLINICS,
});

export const clinicsReceived = (clinics) => ({
  type: types.CLINICS_RECEIVED,
  clinics,
});
