import forEach from 'lodash/forEach';
import { connect } from 'react-redux';
import { appActions } from 'domain/app';
import { tenantSelector } from 'domain/tenant';
import { anonymousUserConstants as constants } from 'domain/anonymousUser';
import AnonymousUsersList from './AnonymousUsersList';

const mapStateToProps = (state) => {
  const cities = tenantSelector.getCities(state);
  const cityMap = {};

  forEach(cities, (city) => {
    if (city?.name?.en && !cityMap[city.name.en]) {
      cityMap[city.name.en] = city.id;
    }
  });

  return {
    cityMap,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveFilters: (filters) =>
    dispatch(appActions.saveFilters(filters, constants.ANONYMOUS_USER_FILTERS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnonymousUsersList);
