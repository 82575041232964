import add from 'date-fns/add';
import React from 'react';

import { Grid, Button, Typography } from 'design-system';

const PredefinedRenewalReminders = ({ children, onChange }) => {
  return (
    <Grid container column>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        p={1}
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <Grid item>
          <Typography variant="p3">After:</Typography>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={() => {
              onChange(add(new Date(), { days: 25 }));
            }}
          >
            25 days
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={() => {
              onChange(add(new Date(), { days: 50 }));
            }}
          >
            50 days
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={() => {
              onChange(add(new Date(), { days: 80 }));
            }}
          >
            80 days
          </Button>
        </Grid>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default PredefinedRenewalReminders;
