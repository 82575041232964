import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as ORDER } from './constants';

export const FETCH_ORDER = `${DOMAIN}/${ORDER}/FETCH_ORDER`;
export const PATCH_ORDER = `${DOMAIN}/${ORDER}/PATCH_ORDER`;
export const CREATE_ORDER = `${DOMAIN}/${ORDER}/CREATE_ORDER`;
export const ADD_LINE_ITEM = `${DOMAIN}/${ORDER}/ADD_LINE_ITEM`;
export const ADD_LINE_ITEMS = `${DOMAIN}/${ORDER}/ADD_LINE_ITEMS`;
export const UPDATE_LINE_ITEM = `${DOMAIN}/${ORDER}/UPDATE_LINE_ITEM`;
export const DELETE_LINE_ITEM = `${DOMAIN}/${ORDER}/DELETE_LINE_ITEM`;
export const FETCH_PINNED_ORDERS = `${DOMAIN}/${ORDER}/FETCH_PINNED_ORDERS`;
export const FETCH_SEARCHED_ORDER = `${DOMAIN}/${ORDER}/FETCH_SEARCHED_ORDER`;
export const CREATE_PRESCRIPTIONS = `${DOMAIN}/${ORDER}/CREATE_PRESCRIPTIONS`;
export const ADD_DELIVERY_ADDRESS = `${DOMAIN}/${ORDER}/ADD_DELIVERY_ADDRESS`;
export const UPDATE_DELIVERY_ADDRESS = `${DOMAIN}/${ORDER}/UPDATE_DELIVERY_ADDRESS`;
export const FETCH_LOGISTIC_PROVIDERS = `${DOMAIN}/${ORDER}/FETCH_LOGISTIC_PROVIDERS`;
export const FETCH_SHIPMENT = `${DOMAIN}/${ORDER}/FETCH_SHIPMENT`;
export const ORDER_RECEIVED = `${DOMAIN}/${ORDER}/ORDER_RECEIVED`;
export const PINNED_ORDERS_RECEIVED = `${DOMAIN}/${ORDER}/PINNED_ORDERS_RECEIVED`;
export const SEARCHED_ORDER_RECEIVED = `${DOMAIN}/${ORDER}/SEARCHED_ORDER_RECEIVED`;
export const DELIVERY_ADDRESS_CHANGED = `${DOMAIN}/${ORDER}/DELIVERY_ADDRESS_CHANGED`;
export const SET_SEARCHED_ORDER_FETCH_ERROR = `${DOMAIN}/${ORDER}/SET_SEARCHED_ORDER_FETCH_ERROR`;
export const CREATE_LOGISTIC_PROVIDER_SHIPMENT = `${DOMAIN}/${ORDER}/CREATE_LOGISTIC_PROVIDER_SHIPMENT`;
export const SET_FETCHING_TASK_ID = `${DOMAIN}/${ORDER}/SET_FETCHING_TASK_ID`;
export const FETCH_SHIPMENT_LABEL = `${DOMAIN}/${ORDER}/FETCH_SHIPMENT_LABEL`;

export const SET_FETCHING = `${DOMAIN}/${ORDER}/SET_FETCHING`;
export const SET_SUBMITTING = `${DOMAIN}/${ORDER}/SET_SUBMITTING`;
export const SET_UPDATING_LINE_ITEMS = `${DOMAIN}/${ORDER}/SET_UPDATING_LINE_ITEMS`;
export const SET_FETCHING_PINNED_ORDERS = `${DOMAIN}/${ORDER}/SET_FETCHING_PINNED_ORDERS`;
export const SET_FETCHING_PRESCRIPTIONS = `${DOMAIN}/${ORDER}/SET_FETCHING_PRESCRIPTIONS`;
export const SET_FETCHING_SEARCHED_ORDER = `${DOMAIN}/${ORDER}/SET_FETCHING_SEARCHED_ORDER`;
export const LOGISTIC_PROVIDERS_RESPONDED = `${DOMAIN}/${ORDER}/LOGISTIC_PROVIDERS_RESPONDED`;
export const SHIPMENT_FETCH_RECEIVED = `${DOMAIN}/${ORDER}/SHIPMENT_FETCH_RECEIVED`;

export const SHIPMENT_LABEL_RESPONDED = `${DOMAIN}/${ORDER}/SHIPMENT_LABEL_RESPONDED`;

export const FETCH_ORDER_HISTORY = `${DOMAIN}/${ORDER}/FETCH_ORDER_HISTORY`;
export const ORDER_HISTORY_RECEIVED = `${DOMAIN}/${ORDER}/ORDER_HISTORY_RECEIVED`;
export const SET_FETCHING_ORDER_HISTORY = `${DOMAIN}/${ORDER}/SET_FETCHING_ORDER_HISTORY`;

export const FETCH_LINE_ITEMS_HISTORY = `${DOMAIN}/${ORDER}/FETCH_LINE_ITEMS_HISTORY`;
export const LINE_ITEMS_HISTORY_RECEIVED = `${DOMAIN}/${ORDER}/LINE_ITEMS_HISTORY_RECEIVED`;
export const SET_FETCHING_LINE_ITEMS_HISTORY = `${DOMAIN}/${ORDER}/SET_FETCHING_LINE_ITEMS_HISTORY`;
