import React, { useRef, useState, useEffect, useCallback } from 'react';

import { MEETING_FIELDS as FIELDS } from 'domain/meeting';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

import MeetingForm from '../MeetingForm';

const CreateMeetingDialog = ({
  // Props
  open,
  onClose,
  doctorId,
  // State
  isSubmitting,
  // actions
  createMeeting,
}) => {
  const formRef = useRef();

  const [isOpen, setOpen] = useState(open);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        ...data,
        [FIELDS.DOCTOR_ID.name]: doctorId,
      };

      createMeeting(payload, () => {
        onClosed();
      });
    },
    [doctorId]
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClosed}
      aria-labelledby="meeting-create-dialog"
    >
      <DialogTitle id="meeting-create-dialog-title" onClose={onClosed}>
        New Meeting Account
      </DialogTitle>
      <DialogContent dividers>
        <MeetingForm ref={formRef} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          color="primary"
          spinning={isSubmitting}
          onClick={() => {
            formRef.current.submit();
          }}
        >
          Create Meeting Account
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMeetingDialog;
