import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect, useCallback } from 'react';

import { MultiSelectDropdown } from 'design-system';
import { OUTCOME_OPTIONS } from 'domain/outcome/constants';
import { consultationModelGetters as getters } from 'model/consultation';

const PatientOutcomeDropDown = ({
  tenantKey,
  isUpdating,
  values = [],
  disableOptions,
  consultationId,
  consultationPatientId,
  updateConsultationPatient,
  ...rest
}) => {
  const [selectedOutcomes, setSelectedOutcomes] = useState(values);
  const [statusSelectColor, setStatusSelectColor] = useState('success');
  const [disabledOptions, setDisabledOptions] = useState(disableOptions);

  const onChanged = useCallback(
    (outcomes) => {
      if (isEmpty(outcomes)) {
        // Clear the list of disabled outcomes
        setDisabledOptions([]);
      } else if (outcomes.includes(OUTCOME_OPTIONS.NoShow.key)) {
        // If an outcome or more are selected
        // and if No Show is selected, all other options should be disabled.
        setDisabledOptions(
          keys(OUTCOME_OPTIONS).filter(
            (key) => key !== OUTCOME_OPTIONS.NoShow.key
          )
        );
      } else {
        // else not empty and not include NoShow, so there is option and its not NoShow, so just add NoShow to disabledOptions
        // If an outcome or more are selected
        // and if No Show is not selected, all other options should be enabled, except No Show
        setDisabledOptions([OUTCOME_OPTIONS.NoShow.key, ...disabledOptions]);
      }
    },
    [disabledOptions]
  );

  const onClosed = useCallback(
    (outcomes = []) => {
      // Convert selected outcomes into a key value object.
      const outcomeValues = outcomes.reduce((outcomeMap, outcomeKey) => {
        const result = { ...outcomeMap };

        result[outcomeKey] = {
          key: outcomeKey,
          label: OUTCOME_OPTIONS[outcomeKey]?.label || outcomeKey,
        };

        return result;
      }, {});

      // If no show is selected, show red color
      if (outcomeValues[OUTCOME_OPTIONS.NoShow.key]) {
        setStatusSelectColor(OUTCOME_OPTIONS.NoShow.color);
      } else {
        setStatusSelectColor(isEmpty(outcomes) ? 'warning' : 'success');
      }

      if (
        outcomes.sort().join(', ') !==
        keys(selectedOutcomes || {})
          .sort()
          .join(', ')
      ) {
        // If the selected outcomes have changed
        const payload = {
          // For each selected outcome object with referenceType and referenceFiles
          output: outcomes.map((outcomeKey) => ({
            tenantKey,
            referenceType: outcomeKey,
          })),
        };

        updateConsultationPatient(
          consultationId,
          consultationPatientId,
          payload
        );
      }

      // Set the selected outcomes.
      setSelectedOutcomes(outcomeValues);
    },
    [selectedOutcomes]
  );

  useEffect(() => {
    setDisabledOptions(disableOptions);
  }, [disableOptions]);

  useEffect(() => {
    setSelectedOutcomes(values);
    setStatusSelectColor(Object.values(values)[0]?.color || 'warning');
  }, [values]);

  return (
    <MultiSelectDropdown
      onClose={onClosed}
      onChange={onChanged}
      options={OUTCOME_OPTIONS}
      values={selectedOutcomes}
      isDisabled
      disabledOptions={disabledOptions}
      getters={{
        getValue: getters.getOutComeLabel,
        getId: getters.getOutComeKey,
      }}
      placeholder="Pending..."
      color={statusSelectColor}
      renderValue={(selected) => {
        return selected
          .map((item) => OUTCOME_OPTIONS[item]?.label || item)
          .join(', ');
      }}
      loading={isUpdating}
      {...rest}
    />
  );
};

export default PatientOutcomeDropDown;
