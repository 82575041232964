import React, { useState, useEffect } from 'react';

import { Dialog } from 'design-system';
import NewConsultation from 'presentation/consultation/NewConsultation';

const ConsultationCreationDialog = ({
  open,
  onClose,
  onComplete,
  userId,
  documents,
  tenant,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    onClose();
    onComplete();
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="add-consultation-dialog-title"
      maxWidth="700px"
    >
      <NewConsultation
        userId={userId}
        embedded
        documents={documents}
        tenant={tenant}
        embedProps={{
          onClose: handleClose,
        }}
      />
    </Dialog>
  );
};
export default ConsultationCreationDialog;
