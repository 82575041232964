import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { Grid, Typography } from 'design-system';

const UpdatedOrCreatedBy = ({ user, time, isFetching }) => {
  return (
    <Grid
      container
      item
      alignItems="left"
      sx={{
        mb: 1,
      }}
    >
      <Typography
        variant="p3"
        loading={isFetching && { width: 250 }}
        alignItems="center"
        sx={{ pt: 0.5, display: 'flex' }}
      >
        <PersonOutlineOutlinedIcon
          color="standard"
          fontSize="extraSmall"
          sx={{
            mr: 0.5,
          }}
        />
        {user || 'N/A'}

        <AccessTimeIcon
          color="standard"
          fontSize="extraSmall"
          sx={{
            ml: 1,
            mr: 0.5,
          }}
        />
        {time}
      </Typography>
    </Grid>
  );
};

export default UpdatedOrCreatedBy;
