import logger from 'core/logger';

import getFilterParams from './getFilterParams';

const getFilterParamsFromLs = (fields, lsKey) => {
  let filters;

  try {
    const lsFilters = localStorage.getItem(lsKey);

    if (lsFilters) {
      filters = JSON.parse(lsFilters);
    }
  } catch (e) {
    logger.error(
      `Getting ${lsKey} filters from local storage - Error parsing JSON. ${e.toString()}`
    );
  }

  return getFilterParams(fields, filters);
};

export default getFilterParamsFromLs;
