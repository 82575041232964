import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as CONSULTATION_TYPE } from './constants';

export const GET_CONSULTATION_TYPES = `${DOMAIN}/${CONSULTATION_TYPE}/GET_CONSULTATION_TYPES`;
export const GET_CONSULTATION_TYPE_BY_ID = `${DOMAIN}/${CONSULTATION_TYPE}/GET_CONSULTATION_TYPE_BY_ID`;
export const UPDATE_CONSULTATION_TYPE = `${DOMAIN}/${CONSULTATION_TYPE}/UPDATE_CONSULTATION_TYPE`;
export const CREATE_CONSULTATION_TYPE = `${DOMAIN}/${CONSULTATION_TYPE}/CREATE_CONSULTATION_TYPE`;

export const SET_SUBMITTING_CONSULTATION_TYPE = `${DOMAIN}/${CONSULTATION_TYPE}/SET_SUBMITTING_CONSULTATION_TYPE`;
export const CONSULTATION_TYPE_RECEIVED = `${DOMAIN}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_RECEIVED`;
export const CONSULTATION_TYPES_RECEIVED = `${DOMAIN}/${CONSULTATION_TYPE}/CONSULTATION_TYPES_RECEIVED`;

export const SET_FETCHING_CONSULTATION_TYPE = `${DOMAIN}/${CONSULTATION_TYPE}/SET_FETCHING_CONSULTATION_TYPE`;

export const ADD_CONSULTATION_TYPE_CONFIGURATION = `${DOMAIN}/${CONSULTATION_TYPE}/ADD_CONSULTATION_TYPE_CONFIGURATION`;
export const UPDATE_CONSULTATION_TYPE_CONFIGURATION = `${DOMAIN}/${CONSULTATION_TYPE}/UPDATE_CONSULTATION_TYPE_CONFIGURATION`;
export const DELETE_CONSULTATION_TYPE_CONFIGURATION = `${DOMAIN}/${CONSULTATION_TYPE}/DELETE_CONSULTATION_TYPE_CONFIGURATION`;
export const GET_CONSULTATION_TYPE_CONFIGURATIONS = `${DOMAIN}/${CONSULTATION_TYPE}/GET_CONSULTATION_TYPE_CONFIGURATIONS`;
export const SET_SUBMITTING_CONSULTATION_TYPE_CONFIGURATION = `${DOMAIN}/${CONSULTATION_TYPE}/SET_SUBMITTING_CONSULTATION_TYPE_CONFIGURATION`;
export const SET_FETCHING_CONSULTATION_TYPE_CONFIGURATION = `${DOMAIN}/${CONSULTATION_TYPE}/SET_FETCHING_CONSULTATION_TYPE_CONFIGURATION`;
export const CONSULTATION_TYPE_CONFIGURATION_RECEIVED = `${DOMAIN}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_CONFIGURATION_RECEIVED`;
