import React from 'react';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { userGetters } from 'domain/user';
import { dateTimeFormat } from 'constants/dateTime';
import { DialerLink } from 'presentation/components';
import { Box, Chip, Link, Tooltip, IconButton } from 'design-system';
import {
  orderGetters as getters,
  orderConstants as constants,
} from 'domain/order';

const { STATUSES } = constants;

export const getColumns = (onStatusUpdate, isAdmin) => [
  { field: 'id', headerName: 'ID', width: 80 },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    renderCell: (params) => {
      const status = getters.getStatus(params.row);

      return (
        <Tooltip placement="top" title="Status">
          <Chip
            sx={{ minWidth: 150 }}
            variant="filled"
            soak={STATUSES[status]?.soak || 'light'}
            label={STATUSES[status]?.label || status}
            color={STATUSES[status]?.color || 'warning'}
          />
        </Tooltip>
      );
    },
  },
  {
    field: 'patient',
    headerName: 'Patient',
    flex: 1,
    renderCell: (params) => {
      const user = getters.getUser(params.row);
      const content =
        userGetters.getFullName(user) || "Visit patient's profile";

      return user ? (
        <Link href={`/user/${userGetters.getId(user)}`}>{content}</Link>
      ) : (
        content
      );
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 1,
    renderCell: (params) => {
      const user = getters.getUser(params.row);
      const phoneNumber = userGetters.getPhoneNumber(user);
      const content = phoneNumber || '-';

      return user ? (
        <DialerLink phoneNumber={phoneNumber}>{phoneNumber}</DialerLink>
      ) : (
        content
      );
    },
  },
  {
    field: 'doctor',
    headerName: 'Doctor',
    flex: 1,
    valueGetter: (params) => getters.getDoctor(params.row) || '-',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 1,
    valueGetter: (params) =>
      format(parseISO(getters.getCreatedAt(params.row)), dateTimeFormat),
  },
  {
    field: 'updatedAt',
    headerName: 'Last Updated',
    flex: 1,
    valueGetter: (params) =>
      format(parseISO(getters.getUpdatedAt(params.row)), dateTimeFormat),
  },
  {
    field: 'updatedBy',
    headerName: 'Updated By',
    flex: 0.7,
    sortable: false,
    hideSortIcons: true,
    hide: !isAdmin,
    disableColumnMenu: true,
    renderCell: (params) => {
      const updatedByUser = getters.getUpdatedByUser(params.row);
      const content = userGetters.getFullName(updatedByUser) || 'N/A';

      return (
        <Link href={`/user/${userGetters.getId(updatedByUser)}`}>
          {content}
        </Link>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 112,
    sortable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    renderCell: (params) => {
      const id = getters.getId(params.row);
      const status = getters.getStatus(params.row);

      return (
        <>
          {status === STATUSES.OUT_FOR_DELIVERY.key && (
            <IconButton
              sx={{ mr: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                onStatusUpdate(id, STATUSES.DELIVERED.key);
              }}
            >
              <Tooltip
                placement="top"
                title={`Change to ${STATUSES.DELIVERED.label}`}
              >
                <CheckCircleOutlineIcon fontSize="small" color="success" />
              </Tooltip>
            </IconButton>
          )}
          {status !== STATUSES.OUT_FOR_DELIVERY.key && (
            <Box sx={{ width: (theme) => theme.spacing(5.5) }} />
          )}
          <IconButton>
            <Tooltip placement="top" title="View order">
              <MessageOutlinedIcon fontSize="small" color="secondary" />
            </Tooltip>
          </IconButton>
        </>
      );
    },
  },
];

export const getFilterFieldsConfig = () => [
  {
    name: 'channel',
    serverName: 'channel',
  },
  {
    name: 'patient',
    serverName: 'userId',
    valueGetter: userGetters.getId,
  },
  {
    name: 'insuranceProvider',
    serverName: 'orderPatients.insuranceProviderId',
    joins: ['orderPatients'],
    valueGetter: userGetters.getId,
  },
  {
    name: 'status',
    serverName: 'status',
  },
  {
    name: 'doctor',
    serverName: 'doctor',
  },
  {
    name: 'createdAtFrom',
    serverName: 'createdAt',
    operation: 'gte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T00:00:00`
        : value;
    },
  },
  {
    name: 'createdAtUntil',
    serverName: 'createdAt',
    operation: 'lte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T23:59:59`
        : value;
    },
  },

  {
    name: 'assignedAtFrom',
    serverName: 'assignedToLogisticProviderAt',
    operation: 'gte',
    valueGetter: (value) => {
      const timestamp = new Date(value);
      return value && isValid(timestamp)
        ? formatISO(timestamp, {
            representation: 'complete',
          })
        : value;
    },
  },
  {
    name: 'assignedAtUntil',
    serverName: 'assignedToLogisticProviderAt',
    operation: 'lte',
    valueGetter: (value) => {
      const timestamp = new Date(value);
      return value && isValid(timestamp)
        ? formatISO(timestamp, {
            representation: 'complete',
          })
        : value;
    },
  },

  {
    name: 'reminderDate',
    serverName: 'reminderDate',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? formatISO(timestamp, {
            representation: 'date',
          })
        : value;
    },
  },
  {
    name: 'followupReminderDate',
    serverName: 'followupReminderDate',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? formatISO(timestamp, {
            representation: 'date',
          })
        : value;
    },
  },
  {
    name: 'driver',
    serverName: 'driverId',
    valueGetter: userGetters.getId,
  },
  {
    name: 'logisticProvider',
    serverName: 'logisticProviderId',
    valueGetter: (provider) => provider?.id,
  },
  {
    name: 'totalPrice',
    serverName: 'totalPriceValue',
    operation: 'gte',
  },
  {
    name: 'assignee',
    serverName: 'assigneeId',
    valueGetter: userGetters.getId,
  },
];
