import { all, put, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import {
  doctorModelActions as modelActions,
  doctorModelActionTypes as modelActionTypes,
} from 'model/doctor';

import * as actions from './actions';
import * as types from './actionTypes';

export function* getDoctor({ id }) {
  yield put(actions.setFetching(true));
  yield put(modelActions.fetchDoctor(id));
}

export function* doctorFetchResponded({ ok, response }) {
  if (ok) {
    yield put(actions.doctorReceived(response));
  }

  yield put(actions.setFetching(false));
}

export function* getDoctors() {
  yield put(actions.setFetching(true));
  yield put(modelActions.fetchDoctors());
}

export function* doctorsFetchResponded({ ok, response }) {
  if (ok) {
    yield put(actions.doctorsReceived(response));
  }

  yield put(actions.setFetching(false));
}

export function* updateDoctor({ id, payload }) {
  yield put(actions.setSubmitting(true));
  yield put(modelActions.updateDoctor(id, payload));
}

export function* doctorUpdateResponded({ ok, response }) {
  if (ok) {
    yield put(actions.doctorReceived(response));
    Message.success('Doctor profile has been update successfully');
  }

  yield put(actions.setSubmitting(false));
}

export default function* doctorSaga() {
  yield all([
    takeLatest(types.GET_DOCTOR, getDoctor),
    takeLatest(types.GET_DOCTORS, getDoctors),
    takeLatest(types.UPDATE_DOCTOR, updateDoctor),
    takeLatest(modelActionTypes.DOCTOR_FETCH_RESPONDED, doctorFetchResponded),
    takeLatest(modelActionTypes.DOCTORS_FETCH_RESPONDED, doctorsFetchResponded),
    takeLatest(modelActionTypes.DOCTOR_UPDATE_RESPONDED, doctorUpdateResponded),
  ]);
}
