import { connect } from 'react-redux';
import {
  prescriptionActions,
  prescriptionSelectors,
} from 'domain/prescription';

import PrescriptionForm from './PrescriptionForm';

const mapStateToProps = (state) => {
  const isFetchingPatientPrescription =
    prescriptionSelectors.isFetchingPatientPrescription(state);
  const getPatientPrescriptionById = (userId) =>
    prescriptionSelectors.getPatientPrescriptionById(state, userId);
  return {
    getPatientPrescriptionById,
    isFetchingPatientPrescription,
  };
};
const mapDispatchToProps = (dispatch) => ({
  createPatientPrescription: (payload, callbacks) =>
    dispatch(prescriptionActions.createPatientPrescription(payload, callbacks)),
  fetchPatientPrescription: (userId, consultationId, callbacks) =>
    dispatch(
      prescriptionActions.fetchPatientPrescription(
        userId,
        consultationId,
        callbacks
      )
    ),
  updatePatientPrescription: (payload, callbacks) =>
    dispatch(
      prescriptionActions.updatePatientPrescription(
        payload.userId,
        payload.consultationId,
        payload,
        callbacks
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionForm);
