import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as DIET_PLAN } from './constants';

export const CREATE_DIET_PLAN = `${DOMAIN}/${DIET_PLAN}/CREATE_DIET_PLAN`;
export const GET_LATEST_DIET_PLAN = `${DOMAIN}/${DIET_PLAN}/GET_LATEST_DIET_PLAN`;

export const SET_SUBMITTING_DIET_PLAN = `${DOMAIN}/${DIET_PLAN}/SET_SUBMITTING_DIET_PLAN`;
export const SET_FETCHING_DIET_PLAN = `${DOMAIN}/${DIET_PLAN}/SET_FETCHING_DIET_PLAN`;

export const LATEST_DIET_PLAN_RECEIVED = `${DOMAIN}/${DIET_PLAN}/LATEST_DIET_PLAN_RECEIVED`;
export const CLEAR_LATEST_DIET_PLAN = `${DOMAIN}/${DIET_PLAN}/CLEAR_LATEST_DIET_PLAN`;
