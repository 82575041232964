import React from 'react';

import { DataGrid } from 'design-system';
import { DataGridCard } from 'core/dataGrid';

import getColumns from './utils';

const OutcomeSection = ({ isFetching, patients = [], tenantKey }) => {
  return (
    <DataGridCard
      key="outcomeSection"
      header={{
        title: 'Outcome',
      }}
      sx={{ height: '100%' }}
    >
      <DataGrid
        columns={getColumns({ tenantKey })}
        rows={patients}
        loading={isFetching}
        pageSize={4}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </DataGridCard>
  );
};

export default OutcomeSection;
