export const PACKAGE_NAME = 'Clinic';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  KEY: {
    name: 'key',
  },
  NAME: {
    name: 'name',
  },
};

export const CLINICS = {
  ALMA_HEALTH_CARE: {
    key: 'ALMA_HEALTH_CARE',
  },
};
