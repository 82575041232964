import { connect } from 'react-redux';

import { labSelectors } from 'domain/lab';
import { userSelectors } from 'domain/user';
import { tenantSelector } from 'domain/tenant';
import { clinicSelectors } from 'domain/clinic';
import { orderSelectors as selectors } from 'domain/order';
import { consultationTypeSelectors } from 'domain/consultationType';
import { insuranceProviderSelectors } from 'domain/insuranceProvider';

/**
 * Used to get app data from the store. This data is saved from domain/app/saga.
 *
 * Usage:
 *
 * Directly with a component:
 * export default appData(YourComponent)
 *
 * With a connect method:
 *
 * export default appData(connect(mapStateToProps, mapDispatchToProps)(YourComponent));
 */
const mapStateToProps = (state) => {
  const doctors = userSelectors.getDoctors(state) || [];
  const drivers = userSelectors.getDrivers(state) || [];
  const clinics = clinicSelectors.getClinics(state) || [];
  const labPackages = labSelectors.getLabPackages(state) || [];
  const tenants = tenantSelector.getAllowedTenants(state) || [];
  const pharmacists = userSelectors.getPharmacists(state) || [];
  const logisticProviders = selectors.getLogisticProviders(state);
  const consultationTypes =
    consultationTypeSelectors.getConsultationTypes(state) || [];
  const insuranceProvidersList =
    insuranceProviderSelectors.getInsuranceProviderList(state) || [];

  return {
    tenants,
    doctors,
    drivers,
    clinics,
    labPackages,
    pharmacists,
    logisticProviders,
    consultationTypes,
    insuranceProvidersList,
  };
};

export default connect(mapStateToProps);
