import { connect } from 'react-redux';

import { authSelectors, authActions } from 'domain/auth';

import LogIn from './LogIn';

const mapStateToProps = (state) => {
  const isLoggingIn = authSelectors.isLoggingIn(state);
  const errorMessage = authSelectors.getErrorMessage(state);

  return {
    isLoggingIn,
    errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logIn: (username, password) =>
    dispatch(authActions.login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
