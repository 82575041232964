import React from 'react';
import keys from 'lodash/keys';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';

import { cacheUtils } from 'core/cache';
import FileUpload from 'core/file/FileUpload';
import { Grid, Typography } from 'design-system';
import { dateTimeFormat } from 'constants/dateTime';
import { UserInfoLink } from 'presentation/components';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';

import { COLUMN_CONFIG } from './constants';

export const UserCell = ({ userId }) => {
  return (
    <UserInfoLink
      key={userId}
      userId={userId}
      config={{
        isPhoneNumberShown: false,
        namePlaceholder: "Visit user's profile",
      }}
      sx={{
        width: 'auto',
      }}
    />
  );
};

export const dateValueGetter = (from, to) => {
  const fromStr = from ? format(parseISO(from), dateTimeFormat) : 'None';
  const toStr = to ? format(parseISO(to), dateTimeFormat) : 'None';

  return (
    <Typography>
      {fromStr}&nbsp;&rarr;&nbsp;{toStr}
    </Typography>
  );
};

export const textValueGetter = (from, to) => {
  return (
    <Typography>
      {from}&nbsp;&rarr;&nbsp;{to}
    </Typography>
  );
};

export const fileValueGetter = (from, to) => {
  const fromIds = (from || '').split(',').map((file) => ({ id: file }));
  const toIds = (to || '').split(',').map((file) => ({ id: file }));

  return (
    <Grid container alignItems="center">
      <Grid item>
        {fromIds ? (
          <FileUpload
            size="small"
            files={fromIds}
            config={{ readonly: true }}
          />
        ) : (
          'None'
        )}
      </Grid>
      <Grid item sx={{ mt: -1 }}>
        &nbsp;&rarr;&nbsp;
      </Grid>
      <Grid item>
        {toIds ? (
          <FileUpload size="small" files={toIds} config={{ readonly: true }} />
        ) : (
          'None'
        )}
      </Grid>
    </Grid>
  );
};

export const userValueGetter = (from, to) => {
  return (
    <>
      {from ? <UserCell userId={from} /> : 'None'}
      &nbsp;&rarr;&nbsp;
      {to ? <UserCell userId={to} /> : 'None'}
    </>
  );
};

export const tenantValueGetter = (from, to) => {
  const tenants = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.TENANTS
  );
  const previousTenant =
    tenants.find((tenant) => tenant.id === from)?.name || from;
  const currentTenant = tenants.find((tenant) => tenant.id === to)?.name || to;

  return (
    <>
      {previousTenant || 'None'}
      &nbsp;&rarr;&nbsp;
      {currentTenant || 'None'}
    </>
  );
};

export const valueGetterByField = {
  reminderdate: dateValueGetter,
  followupreminderdate: dateValueGetter,
  outofdeliveryat: dateValueGetter,
  deliveredat: dateValueGetter,
  prescriptions: fileValueGetter,
  driverid: userValueGetter,
  medicinepackagename: textValueGetter,
  tenantkey: tenantValueGetter,
};

export const historyToDataGridProps = (
  id,
  history,
  lineItemsHistory,
  isFetching,
  isFetchingLineItems
) => {
  const commonColumns = [
    {
      field: 'eventtime',
      headerName: 'Updated At',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => format(parseISO(row.eventtime), dateTimeFormat),
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const userId = row.updatedBy;

        return userId ? <UserCell userId={userId} /> : 'None';
      },
    },
  ];

  const props = {
    status: {
      rows: [],
      columns: [
        {
          field: 'change',
          headerName: 'Status',
          flex: 2,
          sortable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => {
            return (
              <Typography>
                {row.from}&nbsp;&rarr;&nbsp;{row.to}
              </Typography>
            );
          },
        },
        ...commonColumns,
      ],
      isFetching,
    },
    assigneeid: {
      rows: [],
      columns: [
        {
          field: 'change',
          headerName: 'Assignee',
          flex: 2,
          sortable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => {
            const { from, to } = row;

            return (
              <>
                {from ? <UserCell userId={from} /> : 'None'}
                &nbsp;&rarr;&nbsp;
                {to ? <UserCell userId={to} /> : 'None'}
              </>
            );
          },
        },
        ...commonColumns,
      ],
      isFetching,
    },
    generalChanges: {
      rows: [],
      columns: [
        {
          field: 'fieldName',
          headerName: 'Field',
          flex: 2,
          sortable: false,
          disableColumnMenu: true,
        },
        {
          field: 'change',
          headerName: 'Change',
          flex: 3,
          sortable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => {
            const { field, from, to } = row;

            if (valueGetterByField[field]) {
              return valueGetterByField[field](from, to);
            }
            // Special case for showprescriptions to track show/hide prescriptions
            if (field === 'showprescriptions') {
              return (
                <Typography>
                  {from ? 'Show' : 'Hide'}&nbsp;&rarr;&nbsp;
                  {to ? 'Show' : 'Hide'}
                </Typography>
              );
            }

            return (
              <Typography>
                {from || 'None'}&nbsp;&rarr;&nbsp;{to || 'None'}
              </Typography>
            );
          },
        },
        ...commonColumns,
      ],
      isFetching,
    },
    lineItems: {
      rows: [],
      columns: [
        {
          field: 'changeDescription',
          headerName: 'Activitiy',
          flex: 1,
          sortable: false,
          disableColumnMenu: true,
        },
        {
          field: 'change',
          headerName: 'Medicine Name/Change',
          flex: 2,
          sortable: false,
          disableColumnMenu: true,
          renderCell: ({ row }) => {
            const { change, record } = row;

            if (record) {
              return <Typography>{record.medicinepackagename}</Typography>;
            }

            if (!isEmpty(change)) {
              const { key, from, to } = change;

              if (valueGetterByField[key]) {
                return valueGetterByField[key](from, to);
              }
            }
          },
        },
        ...commonColumns,
      ],
      isFetching: isFetchingLineItems,
    },
  };

  history.forEach((item) => {
    const { eventtime, updatedBy, changes } = item;

    changes.forEach((change) => {
      if (!COLUMN_CONFIG[change.key]) {
        // Continue/Skip
        return true;
      }

      const row = {
        id: `${id}-${change.key}-${eventtime}`,
        field: change.key,
        fieldName: COLUMN_CONFIG[change.key].label,
        from: change.from,
        to: change.to,
        eventtime,
        updatedBy,
      };

      // if status or assigneeId is the change.key, push to the corresponding rows array
      if (keys(props).includes(change.key)) {
        props[change.key].rows.push(row);
      } else {
        // Otherwise, push to general changes
        props.generalChanges.rows.push(row);
      }
    });
  });

  lineItemsHistory.forEach((item) => {
    const { id: lineItemId, eventtype, eventtime, changes } = item;

    switch (eventtype) {
      case 'create':
        props.lineItems.rows.push({
          ...item,
          id: `${lineItemId}-${eventtype}`,
          changeDescription: 'Medication Added',
        });
        break;
      case 'delete':
        props.lineItems.rows.push({
          ...item,
          id: `${lineItemId}-${eventtype}`,
          changeDescription: 'Medication Removed',
        });
        break;
      case 'update':
        // Array.prototype.some breaks the loop once true is returned
        changes.some((change) => {
          if (change.key === 'medicinepackagename') {
            props.lineItems.rows.push({
              ...item,
              id: `${lineItemId}-${eventtype}-${eventtime}`,
              changeDescription: 'Medication Changed',
              change,
            });

            return true;
          }

          return false;
        });
        break;
      default:
        break;
    }
  });

  return props;
};
