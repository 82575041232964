/* Anonymous User domain constants */
export const PACKAGE_NAME = 'AnonymousUser';

export const ANONYMOUS_USER_FILTERS = 'anonymous_user_filters';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  DEVICE_ID: {
    name: 'deviceId',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  LAST_ACTIVE: {
    name: 'lastActive',
  },
  CITY: {
    name: 'city',
  },
  COUNTRY: {
    name: 'country',
  },
  DETECTED_CITY: {
    name: 'detectedCity',
  },
  DETECTED_COUNTRY: {
    name: 'detectedCountry',
  },
  DETECTED_REGION: {
    name: 'detectedRegion',
  },
  LATITUDE: {
    name: 'latitude',
  },
  LONGTITUDE: {
    name: 'longitude',
  },
  MOBILE_APP_VERSION: {
    name: 'mobileVersion',
  },
  IP_ADDRESS: {
    name: 'ipAddress',
  },
  PLATFORM: {
    name: 'platform',
  },
  PLATFORM_VERSION: {
    name: 'platformVersion',
  },
};
