import isEmpty from 'lodash/isEmpty';
import MuiSelect from '@mui/material/Select';
import React, { useEffect, useState } from 'react';

import Skeleton from '../Skeleton';
import FormHelperText from '../FormHelperText';

const Select = ({
  sx,
  value,
  error,
  loading,
  multiple,
  helperText,
  placeholder,
  renderValue,
  size = 'medium',
  color = 'standard',
  ...rest
}) => {
  const defaultPlaceholderProps = {
    displayEmpty: false,
    renderValue,
  };

  const [placeholderProps, setPlaceholderProps] = useState(
    defaultPlaceholderProps
  );

  useEffect(() => {
    if (placeholder) {
      setPlaceholderProps({
        displayEmpty: true,
        renderValue: (val) => {
          if (!val || (multiple && isEmpty(val))) {
            return placeholder;
          }

          return renderValue ? renderValue(val) : val;
        },
      });
    } else {
      setPlaceholderProps(defaultPlaceholderProps);
    }
  }, [placeholder]);

  if (loading) {
    return (
      <Skeleton
        variant="rectangle"
        componentSize={size}
        componentName="select"
        {...(typeof loading !== 'boolean' ? loading : {})}
      />
    );
  }

  return (
    <>
      <MuiSelect
        size={size}
        value={value}
        color={color}
        error={error}
        multiple={multiple}
        sx={{
          ...sx,
          ...(placeholder && !value
            ? {
                '& .MuiSelect-select': {
                  opacity: 0.5,
                },
              }
            : {}),
        }}
        {...placeholderProps}
        {...rest}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </>
  );
};

export default Select;
