/* General domain constants go here */
export const PACKAGE_NAME = 'Domain';

export const SCALAR_FIELD = {
  VALUE: {
    name: 'value',
  },
  UNIT: {
    name: 'unit',
  },
};

export const DISPLAYABLE_FIELD = {
  DISPLAY_VALUE: {
    name: 'displayValue',
  },
};

export const LOCAL_DOMAIN_CACHE_KEYS = {
  AUTH: 'auth',
  DOCTORS: 'doctors',
  DRIVERS: 'drivers',
  TENANTS: 'tenants',
  CITIES: 'cities',
  PHARMACISTS: 'pharmacists',
  LAB_PACKAGES: 'lab_packages',
  INSURANCE_PROVIDERS: 'insurance_providers',
  SUBSCRIPTION_PLANS: 'SUBSCRIPTION_PLANS',
};
