import * as types from './actionTypes';

export const fetchDoctorSchedules = () => ({
  type: types.FETCH_DOCTOR_SCHEDULES,
});

export const fetchDoctorFreeScheduleSlots = (
  doctorId,
  dayOfWeekDate,
  dayOfWeek
) => ({
  type: types.FETCH_DOCTOR_FREE_SCHEDULE_SLOTS,
  doctorId,
  dayOfWeekDate,
  dayOfWeek,
});

export const fetchDoctorBookingAvailability = (doctorId, pageDate) => ({
  type: types.FETCH_DOCTOR_BOOKING_AVAILABILITY,
  doctorId,
  pageDate,
});

export const fetchDailyBookingAvailability = (date) => ({
  type: types.FETCH_DAILY_BOOKING_AVAILABILITY,
  date,
});

export const doctorSchedulesReceived = (doctorSchedules) => ({
  type: types.DOCTOR_SCHEDULES_RECEIVED,
  doctorSchedules,
});

export const doctorFreeScheduleSlotsReceived = (freeBookingSlots) => ({
  type: types.DOCTOR_FREE_SCHEDULES_SLOTS_RECEIVED,
  freeBookingSlots,
});

export const doctorBookingAvailabilityReceived = (bookingSlots) => ({
  type: types.DOCTOR_BOOKING_AVAILABILITY_RECEIVED,
  bookingSlots,
});

export const dailyBookingAvailabilityReceived = (dayBookingSlots) => ({
  type: types.DAILY_BOOKING_AVAILABILITY_RECEIVED,
  dayBookingSlots,
});

export const clearBookingAvailability = () => ({
  type: types.CLEAR_BOOKING_AVAILIABLITY,
});

export const addAvailabilityRange = (
  scheduleId,
  doctorId,
  dayOfWeek,
  startTime,
  endTime,
  callback
) => ({
  type: types.ADD_AVAILABILITY_RANGE,
  scheduleId,
  doctorId,
  dayOfWeek,
  startTime,
  endTime,
  callback,
});

export const removeAvailabilityRange = (rangeId, scheduleId) => ({
  type: types.REMOVE_AVAILABILITY_RANGE,
  scheduleId,
  rangeId,
});

export const setFetching = (isFetching) => ({
  type: types.SET_FETCHING,
  isFetching,
});
export const setFetchingDoctorFreeScheduleSlots = (
  isFetchingDoctorFreeScheduleSlots
) => ({
  type: types.SET_FETCHING_DOCTOR_FREE_SCHEDULE_SLOTS,
  isFetchingDoctorFreeScheduleSlots,
});

export const setFetchingDailyBookingAvailability = (
  isFetchingDailyBookingAvailability
) => ({
  type: types.SET_FETCHING_DAILY_BOOKING_AVAILABILITY,
  isFetchingDailyBookingAvailability,
});

export const setRemovingRange = (rangeId, isRemovingRange) => ({
  type: types.SET_REMOVING_RANGE,
  isRemovingRange,
  rangeId,
});

export const setAddingRange = (scheduleId, isAddingRange) => ({
  type: types.SET_ADDING_RANGE,
  isAddingRange,
  scheduleId,
});

export const removeFreeSlot = (doctorId, startTime, endTime, dayOfWeek) => ({
  type: types.REMOVE_FREE_SLOT_RANGE,
  doctorId,
  startTime,
  endTime,
  dayOfWeek,
});
