import get from 'lodash/get';
import { all, call, takeLatest, put } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as types from './actionTypes';
import * as actions from './actions';
import { ENDPOINT } from './constants';

export function* fetchDoctor({ id }) {
  try {
    const response = yield call(httpClient.get, `${ENDPOINT}/${id}`);

    yield put(actions.doctorFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* fetchDoctors() {
  try {
    const response = yield call(httpClient.get, ENDPOINT);

    yield put(actions.doctorsFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorsFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* updateDoctor({ id, payload }) {
  try {
    const response = yield call(httpClient.patch, `${ENDPOINT}/${id}`, payload);

    yield put(actions.doctorUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorUpdateResponded(false, get(e, 'response.data.message'))
    );
  }
}

export default function* doctorModelSaga() {
  yield all([
    takeLatest(types.FETCH_DOCTOR, fetchDoctor),
    takeLatest(types.FETCH_DOCTORS, fetchDoctors),
    takeLatest(types.UPDATE_DOCTOR, updateDoctor),
  ]);
}
