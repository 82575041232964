import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import groupBy from 'lodash/groupBy';
import parseISO from 'date-fns/parseISO';
import {
  all,
  put,
  take,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Message } from 'design-system';
import { userConstants, userSelectors, userActionTypes } from 'domain/user';
import { scheduleModelActions, scheduleModelActionTypes } from 'model/schedule';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';
import * as selectors from './selectors';
import { mapScheduleServerToState, mapSlotsServerToState } from './utils';
import * as rangeGetters from './getters/availabilityRange';
import * as doctorScheduleGetters from './getters/doctorSchedule';

const { FIELDS: USER_FIELDS } = userConstants;
const { DOCTOR_SCHEDULE_FIELDS, AVAILABILITY_RANGE_FIELDS } = constants;

export function* fetchDoctorSchedules() {
  yield put(actions.setFetching(true));
  yield put(scheduleModelActions.fetchDoctorSchedules());
}

export function* doctorSchedulesFetchResponded({ response: schedules, ok }) {
  if (ok) {
    const state = yield select((st) => st);
    let doctors = userSelectors.getDoctors(state);

    if (isEmpty(doctors)) {
      // If doctors list is not available, wait until it becomes available
      yield take(userActionTypes.DOCTORS_RECEIVED);

      doctors = userSelectors.getDoctors(state);
    }

    if (!isEmpty(schedules)) {
      const updatedSchedules = schedules.map((schedule) =>
        mapScheduleServerToState(schedule, doctors)
      );

      yield put(actions.doctorSchedulesReceived(updatedSchedules));
    }
  }

  yield put(actions.setFetching(false));
}

export function* fetchDoctorBookingAvailability({ doctorId, pageDate }) {
  yield put(actions.setFetching(true));
  yield put(
    scheduleModelActions.fetchDoctorBookingAvailability(doctorId, pageDate)
  );
}

export function* fetchDailyBookingAvailability({ date }) {
  yield put(actions.setFetchingDailyBookingAvailability(true));
  yield put(scheduleModelActions.fetchDailyBookingAvailability(date));
}

export function* fetchDoctorFreeScheduleSlots({
  doctorId,
  dayOfWeekDate,
  dayOfWeek,
}) {
  yield put(actions.setFetchingDoctorFreeScheduleSlots(true));
  yield put(
    scheduleModelActions.fetchDoctorFreeScheduleSlots(
      doctorId,
      dayOfWeekDate,
      dayOfWeek
    )
  );
}

export function* doctorBookingAvailabilityFetchResponded({ response, ok }) {
  if (ok) {
    yield put(
      actions.doctorBookingAvailabilityReceived(response?.availableSlots)
    );
  }
  yield put(actions.setFetching(false));
}

export function* doctorFreeScheduleFetchResponded({ response: freeSlots, ok }) {
  if (ok) {
    const updatedSlots = mapSlotsServerToState(freeSlots);
    yield put(actions.doctorFreeScheduleSlotsReceived(updatedSlots));
  } else {
    yield put(actions.doctorFreeScheduleSlotsReceived([]));
  }

  yield put(actions.setFetchingDoctorFreeScheduleSlots(false));
}

export function* dailyBookingAvailabilityFetchResponded({ response, ok }) {
  if (ok) {
    const state = yield select((st) => st);
    let doctors = userSelectors.getDoctors(state);

    if (isEmpty(doctors)) {
      // If doctors list is not available, wait until it becomes available
      yield take(userActionTypes.DOCTORS_RECEIVED);

      doctors = userSelectors.getDoctors(state);
    }

    const doctorsById = groupBy(doctors, USER_FIELDS.ID.name);
    const slots = (response?.availableSlots || [])
      .map((slot) => {
        const doctor = doctorsById[slot.doctorId]?.[0];

        return {
          ...slot,
          doctor,
        };
      })
      .filter((slot) => Boolean(slot.doctor));

    yield put(actions.dailyBookingAvailabilityReceived(slots));
  }

  yield put(actions.setFetchingDailyBookingAvailability(false));
}

function* addAvailabilityRange({
  doctorId,
  callback,
  dayOfWeek,
  scheduleId,
  endTime: endTimeISO,
  startTime: startTimeISO,
}) {
  yield put(actions.setAddingRange(scheduleId, true));

  const state = yield select((st) => st);
  const schedules = selectors.getDoctorSchedules(state);
  const scheduleIdx = schedules.findIndex(
    (item) => doctorScheduleGetters.getId(item) === scheduleId
  );
  let doctors = userSelectors.getDoctors(state);

  if (isEmpty(doctors)) {
    // If doctors list is not available, wait until it becomes available
    yield take(userActionTypes.DOCTORS_RECEIVED);

    doctors = userSelectors.getDoctors(state);
  }

  const endTime = format(parseISO(endTimeISO), 'HH:mm');
  const startTime = format(parseISO(startTimeISO), 'HH:mm');

  const newRange = {
    [AVAILABILITY_RANGE_FIELDS.SCHEDULE_ID.name]: scheduleId,
    [AVAILABILITY_RANGE_FIELDS.DAY_OF_WEEK.name]: dayOfWeek,
    [AVAILABILITY_RANGE_FIELDS.END_TIME.name]: `${endTime}:00`,
    [AVAILABILITY_RANGE_FIELDS.START_TIME.name]: `${startTime}:00`,
    [DOCTOR_SCHEDULE_FIELDS.DOCTOR_ID.name]: doctorId,
  };

  yield put(scheduleModelActions.addAvailabilityRange(newRange));

  const { ok, response } = yield take(
    scheduleModelActionTypes.AVAILABILITY_RANGE_ADD_RESPONDED
  );

  if (ok) {
    const updatedSchedules = [...schedules];

    updatedSchedules[scheduleIdx] = mapScheduleServerToState(
      response.data,
      doctors
    );
    yield put(actions.doctorSchedulesReceived(updatedSchedules));

    callback();
    Message.success('Range has been added successfully');
  } else {
    Message.error(response);
  }

  yield put(actions.setAddingRange(scheduleId, false));
}

function* removeAvailabilityRange({ rangeId, scheduleId }) {
  yield put(actions.setRemovingRange(rangeId, true));

  const state = yield select((st) => st);
  const schedules = selectors.getDoctorSchedules(state);
  const scheduleIdx = schedules.findIndex(
    (item) => doctorScheduleGetters.getId(item) === scheduleId
  );
  const rangeIdx = doctorScheduleGetters
    .getAvailabilityRange(schedules[scheduleIdx])
    .findIndex((item) => rangeGetters.getId(item) === rangeId);

  /**
   * Assume the operation will succeed, and remove the range from state.
   */
  yield put(
    actions.doctorSchedulesReceived([
      ...schedules.map((schedule, index) => {
        return {
          ...schedule,
          ...(index === scheduleIdx
            ? {
                [DOCTOR_SCHEDULE_FIELDS.AVAILABILITY_RANGES.name]:
                  doctorScheduleGetters
                    .getAvailabilityRange(schedule)
                    .map((time, idx) => (idx === rangeIdx ? null : time))
                    .filter(Boolean),
              }
            : {}),
        };
      }),
    ])
  );

  yield put(scheduleModelActions.removeAvailabilityRange(rangeId));

  const { ok } = yield take(
    scheduleModelActionTypes.AVAILABILITY_RANGE_REMOVAL_RESPONDED
  );

  if (ok) {
    Message.success('Range has been removed successfully');
  } else {
    // If the operation fails, recover the old state
    yield put(actions.doctorSchedulesReceived(schedules));
    Message.error(
      'Failed to remove range. Please refresh the page and try again, or report the issue the team'
    );
  }

  yield put(actions.setRemovingRange(rangeId, false));
}
function* removeFreeSlotRange({ doctorId, startTime, endTime, dayOfWeek }) {
  const state = yield select((st) => st);
  const freeScheduleSlot = selectors.getDoctorFreeScheduleSlots(state);
  yield put(
    scheduleModelActions.removeFreeSlotRange(
      doctorId,
      startTime,
      endTime,
      dayOfWeek
    )
  );

  const { ok } = yield take(
    scheduleModelActionTypes.FREE_SLOT_RANGE_REMOVAL_RESPONDED
  );
  yield put(
    actions.doctorFreeScheduleSlotsReceived(
      freeScheduleSlot.filter((slot) => slot.startTime !== startTime)
    )
  );

  if (ok) {
    Message.success('Range has been removed successfully');
    yield put(actions.fetchDoctorSchedules());
  } else {
    // If the operation fails, recover the old state
    yield put(actions.doctorFreeScheduleSlotsReceived(freeScheduleSlot));
    Message.error(
      'Failed to remove range. Please refresh the page and try again, or report the issue the team'
    );
  }
}
export default function* scheduleSaga() {
  yield all([
    takeLatest(types.FETCH_DOCTOR_SCHEDULES, fetchDoctorSchedules),
    takeLatest(types.ADD_AVAILABILITY_RANGE, addAvailabilityRange),
    takeEvery(types.REMOVE_AVAILABILITY_RANGE, removeAvailabilityRange),
    takeLatest(
      types.FETCH_DOCTOR_BOOKING_AVAILABILITY,
      fetchDoctorBookingAvailability
    ),
    takeLatest(
      types.FETCH_DAILY_BOOKING_AVAILABILITY,
      fetchDailyBookingAvailability
    ),
    takeLatest(
      scheduleModelActionTypes.DOCTOR_SCHEDULES_FETCH_RESPONDED,
      doctorSchedulesFetchResponded
    ),
    takeLatest(
      scheduleModelActionTypes.DOCTOR_BOOKING_AVAILABILITY_FETCH_RESPONDED,
      doctorBookingAvailabilityFetchResponded
    ),
    takeLatest(
      scheduleModelActionTypes.DAILY_BOOKING_AVAILABILITY_FETCH_RESPONDED,
      dailyBookingAvailabilityFetchResponded
    ),
    takeLatest(
      types.FETCH_DOCTOR_FREE_SCHEDULE_SLOTS,
      fetchDoctorFreeScheduleSlots
    ),
    takeLatest(
      scheduleModelActionTypes.DOCTOR_FREE_SCHEDULES_SLOTS_FETCH_RESPONDED,
      doctorFreeScheduleFetchResponded
    ),
    takeEvery(types.REMOVE_FREE_SLOT_RANGE, removeFreeSlotRange),
  ]);
}
