import axios from 'axios';

import logger from 'core/logger';
import { Message } from 'design-system';
import * as httpConstants from './constants';

import './interceptors';

const axiosGet = axios.get;

const get = (url, config = {}) => {
  let currentRetry = 0;

  const retry = config.retry ?? true;
  const retryCount = config.retryCount ?? 3;
  const retryBackoff = config.retryBackoff ?? ((i) => i * 500);

  return new Promise((resolve, reject) => {
    const call = () => {
      setTimeout(() => {
        axiosGet(url, config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            if (
              retry &&
              currentRetry < retryCount &&
              error?.message !== 'canceled'
            ) {
              currentRetry += 1;

              Message.info('Failed request. Retrying');
              logger.log('Retrying get request');

              call();
            } else {
              reject(error);
            }
          });
      }, retryBackoff(currentRetry));
    };

    call();
  });
};

axios.get = get;

export { httpConstants };
export default axios;
