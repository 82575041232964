import {
  subscriptionActions,
  subscriptionSelectors,
} from 'domain/subscription';
import { connect } from 'react-redux';

import SubscriptionPlanDialog from './SubscriptionPlanDialog';

const mapStateToProps = (state) => {
  const isSubscriptionCreated =
    subscriptionSelectors.isSubscriptionCreated(state);
  const isSubmitting = subscriptionSelectors.isSubmitting(state);
  const subscriptionPlans = subscriptionSelectors.getSubscriptionPlans(state);
  const isFetchingSubscriptionPlans =
    subscriptionSelectors.isFetchingSubscriptionPlans(state);

  return {
    isSubscriptionCreated,
    isSubmitting,
    subscriptionPlans,
    isFetchingSubscriptionPlans,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createSubscription: (userId, payload) =>
    dispatch(subscriptionActions.createSubscription(userId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionPlanDialog);
