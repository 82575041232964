import debounce from 'lodash/debounce';
import isPlainObject from 'lodash/isPlainObject';
import React, { useEffect, useState } from 'react';

import { Autocomplete } from 'design-system';
import httpClient, { httpConstants } from 'core/httpClient';

let abortController = new AbortController();

const DEBOUNCE_DURATION = 500;

const ServerAutocomplete = ({
  // is multiple selection
  multiple,
  // Where to find the options
  endpoint,
  // API baseURL
  baseURL,
  // is disabled
  disabled,
  // Gets the params needed to be passed to the request
  getRequestParams,
  // onChange handler
  onChange = () => {},
  // Default value
  defaultValue = null,
  // Control when to call the fetch method
  throttle = (fn) => fn(),
  // How you wanna show the options
  getOptionLabel = (option) => option,
  // Text when no options available
  getNoOptionsText = () => 'No options',
  // How to find the list of options in the response
  responseGetter = (response) => response.data,
  // Custom input
  renderInput,
  // Exclude selected options from showing up again
  filterSelectedOptions = true,
  // Tell Autocomplete how to filter
  filterOptions = (x) => x,
  // Check if two options match. Used in multiple mode to exclude items from the options list
  optionsEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b),
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState('');

  const fetchOptions = async (inputVal) => {
    if (isLoading && abortController) {
      abortController.abort();
      setLoading(false);

      abortController = new AbortController();
    }

    setLoading(true);

    try {
      const response = await httpClient.get(endpoint, {
        params: getRequestParams(inputVal),
        signal: abortController.signal,
        baseURL,
      });

      const retrievedOptions = responseGetter(response);

      if (multiple && filterSelectedOptions) {
        setOptions(
          retrievedOptions.filter(
            (item) => !value.some((item2) => optionsEqual(item, item2))
          )
        );
      } else {
        setOptions(retrievedOptions);
      }

      setLoading(false);
    } catch (error) {
      if (error.code !== httpConstants.ERROR_CODES.CANCELLED.key) {
        setLoading(false);
      }
    }
  };

  const debouncedFetchOptions = debounce(fetchOptions, DEBOUNCE_DURATION);

  const onInputChange = (event, newValue = '') => {
    if (event) event.stopPropagation();
    setInputValue(newValue);

    if (newValue?.length) {
      throttle(() => debouncedFetchOptions(newValue), newValue);
    } else {
      debounce(setOptions, DEBOUNCE_DURATION)([]);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
    // Currently no use case to search when init component onInputChange(null, defaultValue);
  }, [defaultValue]);

  // TODO: support loading/fetching Skeleton
  return (
    <Autocomplete
      {...rest}
      value={value}
      options={options}
      multiple={multiple}
      disabled={disabled}
      loading={isLoading}
      filterSelectedOptions
      forcePopupIcon={false}
      inputValue={inputValue}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      noOptionsText={getNoOptionsText(inputValue)}
      onChange={(event, newValue = '') => {
        event.stopPropagation();
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={onInputChange}
      renderInput={(inputProps) => {
        const updatedInputProps = { ...inputProps };

        if (isPlainObject(inputProps?.inputProps?.value)) {
          updatedInputProps.inputProps.value = getOptionLabel(
            inputProps.inputProps.value
          );
        }

        return renderInput(updatedInputProps);
      }}
    />
  );
};

export default ServerAutocomplete;
