import * as types from './actionTypes';

export const createPaymentTransaction = (payload) => ({
  type: types.CREATE_PAYMENT_TRANSACTION,
  payload,
});

export const getPaymentTransactionByReference = (
  referenceId,
  referenceType
) => ({
  type: types.GET_PAYMENT_TRANSACTION_BY_REFERENCE,
  referenceId,
  referenceType,
});

export const paymentTransactionCreated = (response) => ({
  type: types.PAYMENT_TRANSACTION_CREATED,
  response,
});

export const paymentTransactionReceived = (paymentTransaction) => ({
  type: types.PAYMENT_TRANSACTION_RECEIVED,
  paymentTransaction,
});

export const cancelPaymentTransaction = (referenceId, referenceType) => ({
  type: types.CANCEL_PAYMENT_TRANSACTION,
  referenceId,
  referenceType,
});

export const cancelPaymentTransactionResponded = (ok, response) => ({
  type: types.CANCEL_PAYMENT_TRANSACTION_RESPONDED,
  ok,
  response,
});
