import { all, call, put, takeLatest } from 'redux-saga/effects';

import { getLabEndpoint } from 'core/endpoint';
import { TEST_REQUEST_LIST_ENDPOINT } from 'model/lab/constants';
import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';
import httpClient from '../../core/httpClient';

export function* fetchPatientHistory({ id }) {
  const response = yield call(httpClient.get, constants.ENDPOINT(id)) || [];
  const labsResponse = yield call(
    httpClient.get,
    `${getLabEndpoint()}${TEST_REQUEST_LIST_ENDPOINT}/patientHistory/${id}`
  );

  yield put(
    actions.patientHistoryReceived(
      [...labsResponse.data, ...response.data].sort(
        (a, b) =>
          new Date(b.createdAt || b.updatedAt || null).getTime() -
          new Date(a.createdAt || a.updatedAt || null).getTime()
      )
    )
  );
}

export default function* patientHistoryModelSaga() {
  yield all([takeLatest(types.FETCH_PATIENT_HISTORY, fetchPatientHistory)]);
}
