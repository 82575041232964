import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as USER } from './constants';

export const FETCH_USER = `${DOMAIN}/${USER}/FETCH_USER`;
export const CLEAR_USER = `${DOMAIN}/${USER}/CLEAR_USER`;
export const CREATE_USER = `${DOMAIN}/${USER}/CREATE_USER`;
export const UPDATE_USER_PROFILE = `${DOMAIN}/${USER}/UPDATE_USER_PROFILE`;
export const FETCH_USER_ADDRESSES = `${DOMAIN}/${USER}/FETCH_USER_ADDRESSES`;
export const FETCH_PATIENT_HEALTH_INFO = `${DOMAIN}/${USER}/FETCH_PATIENT_HEALTH_INFO`;
export const CLEAR_PATIENT_HEALTH_INFO = `${DOMAIN}/${USER}/CLEAR_PATIENT_HEALTH_INFO`;
export const UPDATE_USER_HEALTH_PROFILE = `${DOMAIN}/${USER}/UPDATE_USER_HEALTH_PROFILE`;
export const USER_RECEIVED = `${DOMAIN}/${USER}/USER_RECEIVED`;
export const USER_ADDRESSES_RECEIVED = `${DOMAIN}/${USER}/USER_ADDRESSES_RECEIVED`;
export const PATIENT_HEALTH_INFO_RECEIVED = `${DOMAIN}/${USER}/PATIENT_HEALTH_INFO_RECEIVED`;
export const FETCH_DOCTORS = `${DOMAIN}/${USER}/FETCH_DOCTORS`;
export const FETCH_DRIVERS = `${DOMAIN}/${USER}/FETCH_DRIVERS`;
export const FETCH_PHARMACISTS = `${DOMAIN}/${USER}/FETCH_PHARMACISTS`;
export const START_IDENTITY_EXTRACTION = `${DOMAIN}/${USER}/START_IDENTITY_EXTRACTION`;
export const CANCEL_IDENTITY_EXTRACTION = `${DOMAIN}/${USER}/CANCEL_IDENTITY_EXTRACTION`;
export const SAVE_EXTRACTED_IDENTITY_DATA = `${DOMAIN}/${USER}/SAVE_EXTRACTED_IDENTITY_DATA`;
export const CLEAR_EXTRACTED_IDENTITY_DATA = `${DOMAIN}/${USER}/CLEAR_EXTRACTED_IDENTITY_DATA`;

export const SET_FETCHING_USER = `${DOMAIN}/${USER}/SET_FETCHING_USER`;
export const SET_SUBMITTING_USER = `${DOMAIN}/${USER}/SET_SUBMITTING_USER`;
export const SET_FETCHING_BACK_ID = `${DOMAIN}/${USER}/SET_FETCHING_BACK_ID`;
export const SET_FETCHING_FRONT_ID = `${DOMAIN}/${USER}/SET_FETCHING_FRONT_ID`;
export const SET_FETCHING_USER_FILES = `${DOMAIN}/${USER}/SET_FETCHING_USER_FILES`;
export const SET_EXTRACTING_IDENTITY = `${DOMAIN}/${USER}/SET_EXTRACTING_IDENTITY`;
export const SET_FETCHING_INSURANCE_CARD = `${DOMAIN}/${USER}/SET_FETCHING_INSURANCE_CARD`;
export const SET_FETCHING_USER_ADDRESSES = `${DOMAIN}/${USER}/SET_FETCHING_USER_ADDRESSES`;
export const SET_SAVING_EXTRACTED_IDENTITY_DATA = `${DOMAIN}/${USER}/SET_SAVING_EXTRACTED_IDENTITY_DATA`;

export const DOCTORS_RECEIVED = `${DOMAIN}/${USER}/DOCTORS_RECEIVED`;
export const DRIVERS_RECEIVED = `${DOMAIN}/${USER}/DRIVERS_RECEIVED`;
export const PHARMACISTS_RECEIVED = `${DOMAIN}/${USER}/PHARMACISTS_RECEIVED`;

export const IDENTITY_EXTRACTION_SUCCEEDED = `${DOMAIN}/${USER}/IDENTITY_EXTRACTION_SUCCEEDED`;
export const IDENTITY_EXTRACTION_JOBS_RECEIVED = `${DOMAIN}/${USER}/IDENTITY_EXTRACTION_JOBS_RECEIVED`;
export const IDENTITY_EXTRACTION_FAILED = `${DOMAIN}/${USER}/IDENTITY_EXTRACTION_FAILED`;
