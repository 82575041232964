import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import random from 'lodash/random';

import { userGetters } from 'domain/user';
import { memberGetters } from 'domain/user/member';
import MemberRow from 'presentation/user/components/UserMemberRow';
import {
  If,
  Else,
  Grid,
  Button,
  ElseIf,
  Skeleton,
  Typography,
  Conditionally,
  ShowMoreShowLess,
} from 'design-system';

import { SIZE_CONFIG } from './constants';
import CreateMemberDialog from './components/CreateMemberDialog';

const MemberManagement = ({
  ownerId,
  // If parent component is loading
  loading,
  // If members are being fetched
  isFetching,
  isDeleting,
  members = [],
  fetchAllMembers,
  isSubmitting,
  clearMembers,
  readonly = false,
  size = 'medium',
}) => {
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  useEffect(() => {
    if (!loading && ownerId) {
      fetchAllMembers(ownerId);
    }

    return () => clearMembers();
  }, [ownerId, loading]);

  return (
    <>
      <Grid container column>
        <Conditionally>
          <If condition={isFetching || isDeleting || isSubmitting}>
            {Array.from(Array(members?.length || 3)).map(() => (
              // Show members' length or 3 skeletons while waiting
              <Grid
                item
                key={random(true)}
                sx={{
                  height: (theme) => theme.spacing(SIZE_CONFIG.rowHeight[size]),
                }}
              >
                <Skeleton
                  key={`skeleton_${random()}`}
                  variant="rectangle"
                  sx={{
                    height: (theme) =>
                      theme.spacing(SIZE_CONFIG.innerRowHeight[size]),
                  }}
                />
              </Grid>
            ))}
          </If>
          <ElseIf condition={isEmpty(members)}>
            <Typography variant={SIZE_CONFIG.typographyVariant[size]}>
              No members available
            </Typography>
          </ElseIf>
          <Else>
            <ShowMoreShowLess
              limit={5}
              data={members}
              prioritize={(member) =>
                !memberGetters.isDeletedFromOwnerAccount(member)
              }
              renderItem={({ item: member }) => (
                <Grid
                  key={userGetters.getPhoneNumber(member)}
                  item
                  container
                  sx={{
                    height: (theme) =>
                      theme.spacing(SIZE_CONFIG.rowHeight[size]),
                    borderBottomWidth: 1,
                    borderBottomStyle: 'solid',
                    borderBottomColor: (theme) => theme.palette.grey[200],
                  }}
                >
                  <MemberRow member={member} readonly={readonly} size={size} />
                </Grid>
              )}
            />
          </Else>
        </Conditionally>
        {!readonly && (
          <Button
            variant="text"
            loading={isFetching || loading}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            {members.length ? 'Add another member' : 'Add a member'}
          </Button>
        )}
      </Grid>
      <CreateMemberDialog
        ownerId={ownerId}
        open={isCreateDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      />
    </>
  );
};

export default MemberManagement;
