import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import { useState, useEffect } from 'react';

const useObjectMapper = (
  {
    // Condition: Determines when the logic of mapping will execute.
    // It's the condition we used to check before setting the individual fields
    // e.g: !isEmpty(consultation)
    condition,
    // Source Data: The payload that we have fetched and saved in the state. E.g: Order, Consultation, ...etc
    sourceData,
    // Field Mappers: A key-function object.
    // The key represents either an existing field in sourceData or a new field that we want to add to the mappedData
    // The function takes the sourceData and the corresponding field's value.
    fieldMappers,
  },
  // Dependencies: A list of variables, when changed it triggers useEffect to execute. The sourceData must be one of deps.
  deps
) => {
  const [mappedData, setMappedData] = useState({});

  const mapData = () => {
    const mapped = {
      ...sourceData,
    };

    keys(fieldMappers).forEach((key) => {
      mapped[key] = fieldMappers[key](sourceData, sourceData[key]);
    });

    setMappedData(mapped);
  };

  const reset = () => {
    setMappedData({});
  };

  useEffect(() => {
    if (condition()) {
      mapData();
    }

    return () => {
      reset();
    };
  }, deps);

  return {
    reset,
    mappedData,
    setter: (
      field,
      value,
      options = {
        keepReference: false,
      }
    ) => {
      if (isArray(field)) {
        const values = {};

        field.forEach((fld, idx) => {
          if (options?.keepReference) {
            mappedData[fld] = value[idx];
          }

          values[fld] = value[idx];
        });

        if (!options?.keepReference) {
          setMappedData({
            ...mappedData,
            ...values,
          });
        }
      } else if (options?.keepReference) {
        mappedData[field] = value;
      } else {
        setMappedData({
          ...mappedData,
          [field]: value,
        });
      }
    },
  };
};

export default useObjectMapper;

/**
 * Example:
    const { mappedData: order, setter } = useObjectMapper({
      condition: () => !isEmpty(fetchedOrder),
      fieldMappers: {
        createdAt: (theSameFetchedOrder, rawCreatedAt) => format(parseISO(rawCreatedAt, dateTimeFormat)),
        totallyNewField: (order) => order?.status === 'NEW_PRESCRIPTION' ? 'valueX' : 'valueY'
      }
    }, [fetchedOrder]);

    // Then in the rest of the code, use getters to get a field from the mapped data
    // e.g:
    orderGetters.getStatus(order)

    // Use 'setter' to change a field in the order in the internal state (Same as the setter in useState)
    // e.g:
    setter('status', 'CONFIRMED');
    // That changes the field called status in the order, and triggers a re-render.
 */
