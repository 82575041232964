import { all, put, takeLatest } from 'redux-saga/effects';
import { navigate } from 'routing/utils';

import { Message } from 'design-system';
import { userGetters, userActions } from 'domain/user';
import {
  userManagementModelActions,
  userManagementModelActionTypes,
} from 'model/userManagement';

import * as actions from './actions';
import * as types from './actionTypes';

export function* createAlmaosUser({ payload }) {
  yield put(actions.setSubmittingAlmaosUser(true));
  yield put(userManagementModelActions.createAlmaosUser(payload));
}

export function* getAlmaosUser({ id }) {
  yield put(actions.setFetchingAlmaosUser(true));
  yield put(userManagementModelActions.getAlmaosUser(id));
}

export function* almaosUserCreationResponded({ ok, response }) {
  if (ok && response) {
    navigate(`/user-management/${userGetters.getId(response)}`);
    yield put(userActions.clearUser());
    setTimeout(() => {
      Message.success('User account has been registered successfully');
    }, 0);
  }
  yield put(actions.setSubmittingAlmaosUser(false));
}

export function* deactivateAccount({ id }) {
  yield put(userManagementModelActions.deactivateAccount(id));
}

export function* resetPassword({ userId }) {
  yield put(userManagementModelActions.resetPassword(userId));
}

export function* passwordResetResponded({ ok }) {
  if (ok) {
    Message.success('Password has been reset successfully');
  }
}
export function* almaosUserDeactivateResponded({ ok }) {
  if (ok) {
    window.location.reload();
    Message.success('User account has been deactivated successfully');
  }
}

export function* almaosUserFetchingResponded({ ok, response }) {
  yield put(actions.setFetchingAlmaosUser(false));
  if (ok) {
    yield put(actions.setAlmaosUser(response));
  }
}

export function* updateAlmaosUser({ id, payload }) {
  yield put(actions.setSubmittingAlmaosUser(true));
  yield put(userManagementModelActions.updateAlmaosUser(id, payload));
}

export function* almaosUserUpdateResponded({ ok }) {
  yield put(actions.setSubmittingAlmaosUser(false));
  if (ok) {
    Message.success('User account has been updated successfully');
  }
}

export default function* userManagementSaga() {
  yield all([
    takeLatest(types.DEACTIVATE_ACCOUNT, deactivateAccount),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.CREATE_ALMAOS_USER, createAlmaosUser),
    takeLatest(types.GET_ALMAOS_USER, getAlmaosUser),
    takeLatest(types.UPDATE_ALMAOS_USER, updateAlmaosUser),
    takeLatest(
      userManagementModelActionTypes.ALMAOS_USER_CREATION_RESPONDED,
      almaosUserCreationResponded
    ),
    takeLatest(
      userManagementModelActionTypes.PASSWORD_RESET_RESPONDED,
      passwordResetResponded
    ),
    takeLatest(
      userManagementModelActionTypes.ALMAOS_USER_DEACTIVATE_RESPONDED,
      almaosUserDeactivateResponded
    ),
    takeLatest(
      userManagementModelActionTypes.ALMAOS_USER_FETCHING_RESPONDED,
      almaosUserFetchingResponded
    ),
    takeLatest(
      userManagementModelActionTypes.ALMAOS_USER_UPDATE_RESPONDED,
      almaosUserUpdateResponded
    ),
  ]);
}
