import { connect } from 'react-redux';

import { dietPlanActions, dietPlanSelectors } from 'domain/dietPlan';

import LatestDietPlan from './LatestDietPlan';

const mapStateToProps = (state) => {
  const isFetching = dietPlanSelectors.isFetching(state);
  const isSubmitting = dietPlanSelectors.isSubmitting(state);
  const latestDietPlan = dietPlanSelectors.getLatestDietPlan(state);

  return {
    isFetching,
    isSubmitting,
    latestDietPlan,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(dietPlanActions.clearLatestDietPlan()),
  addDietPlan: (payload) => dispatch(dietPlanActions.createDietPlan(payload)),
  getLatestDietPlan: (userId) =>
    dispatch(dietPlanActions.getLatestDietPlan(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestDietPlan);
