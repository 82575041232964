import get from 'lodash/get';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import MoneyIcon from '@mui/icons-material/Money';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useState, useEffect } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { dateTimeFormat } from 'constants/dateTime';
import { copyToClipboard } from 'core/utils/document';
import { PAYMENT_STATUS, FAILED_PAYMENT_STATUSES } from 'domain/payment';
import {
  If,
  Else,
  Card,
  Grid,
  Button,
  Message,
  Tooltip,
  Typography,
  CardHeader,
  CardContent,
  Conditionally,
  PopConfirm,
} from 'design-system';

import CreatePaymentTransaction from '../CreatePaymentTransaction';

export const getPaymentStatusIcon = (status) => {
  if (FAILED_PAYMENT_STATUSES.includes(status)) {
    return <ErrorOutlineIcon color="error" sx={{ pr: 4 }} fontSize="large" />;
  }

  if (status === PAYMENT_STATUS.SUCCESS) {
    return <CreditScoreIcon color="success" sx={{ pr: 4 }} fontSize="large" />;
  }

  if (FAILED_PAYMENT_STATUSES.includes(status)) {
    return <ErrorOutlineIcon color="error" sx={{ pr: 4 }} fontSize="large" />;
  }

  return <HourglassTopIcon color="warning" sx={{ pr: 4 }} fontSize="large" />;
};

/**
 * Determines whether to show the action button based on the user's permissions, action type, and payment status.
 *
 * @param {object} permissions - The user's permissions.
 * @param {string} action - The type of action.
 * @param {string} status - The payment status.
 * @return {boolean} Returns true if the action icon should be shown, otherwise false.
 */

const PaymentSection = ({
  user,
  clear,
  cardStyle,
  isFetchingPaymentTransaction,
  isFetching: isFetchingParent,
  referenceId,
  permissions,
  referenceType,
  paymentTransaction,
  hasPaymentTransaction,
  getPaymentTransaction,
  sendPaymentNotification,
  cancelPaymentTransaction,
}) => {
  const [isUpdatePaymentLink, setUpdatePaymentLink] = useState(false);
  const [isPaymentCreateDialogOpen, setPaymentCreateDialogOpen] =
    useState(false);
  const [popConfirmAnchor, setPopConfirmAnchor] = useState(null);

  const isFetching = isFetchingParent || isFetchingPaymentTransaction;

  useEffect(() => {
    if (hasPaymentTransaction) {
      getPaymentTransaction({
        referenceId,
        referenceType,
      });
    } else {
      clear();
    }

    return () => {
      clear();
    };
  }, [hasPaymentTransaction]);

  useEffect(() => {
    setUpdatePaymentLink(
      !!paymentTransaction &&
        paymentTransaction?.status !== PAYMENT_STATUS.SUCCESS
    );
  }, [paymentTransaction]);

  const showPopConfirm = (event) => {
    setPopConfirmAnchor(event.currentTarget);
  };

  const closePopConfirm = () => {
    setPopConfirmAnchor(null);
  };

  if (paymentTransaction?.link) {
    const showCreateNewPaymentLink =
      get(permissions, 'create', false) &&
      paymentTransaction?.status !== PAYMENT_STATUS.SUCCESS &&
      paymentTransaction?.status !== PAYMENT_STATUS.READY_FOR_PAYMENT;

    const showCancelPaymentLink =
      get(permissions, 'cancel', false) &&
      paymentTransaction?.status !== PAYMENT_STATUS.SUCCESS &&
      paymentTransaction?.status !== PAYMENT_STATUS.CANCELLED &&
      paymentTransaction?.status !== PAYMENT_STATUS.EXPIRED;

    const showUpdateOrCopyPaymentLink =
      get(permissions, 'update', false) &&
      paymentTransaction?.status !== PAYMENT_STATUS.SUCCESS &&
      paymentTransaction?.status !== PAYMENT_STATUS.CANCELLED &&
      paymentTransaction?.status !== PAYMENT_STATUS.EXPIRED;

    const showResendPaymentLink =
      get(permissions, 'resend', false) &&
      paymentTransaction?.status !== PAYMENT_STATUS.SUCCESS &&
      paymentTransaction?.status !== PAYMENT_STATUS.CANCELLED &&
      paymentTransaction?.status !== PAYMENT_STATUS.EXPIRED;

    const actionButtons = (
      <>
        {showResendPaymentLink && (
          <Tooltip
            placement="top"
            title="Send payment link as SMS and push notification to the patient"
            injectWrapper
          >
            <Button
              size="small"
              color="primary"
              variant="text"
              loading={isFetching && { width: 160 }}
              startIcon={<MessageIcon color="secondary" />}
              onClick={() => {
                sendPaymentNotification({
                  dueAmount: {
                    amount: paymentTransaction?.amount?.value,
                    currency: paymentTransaction?.amount?.currency,
                  },
                  user,
                  referenceId,
                  referenceType,
                  isAutoNotification: true,
                  skipReload: true,
                });
              }}
            >
              Resend notification
            </Button>
          </Tooltip>
        )}
        {showUpdateOrCopyPaymentLink && (
          <Tooltip placement="top" title="Update Payment" injectWrapper>
            <Button
              size="small"
              color="primary"
              variant="text"
              width={50}
              loading={isFetching && { width: 160 }}
              startIcon={<MoneyIcon color="secondary" />}
              onClick={() => {
                setUpdatePaymentLink(true);
                setPaymentCreateDialogOpen(true);
              }}
            >
              Update Payment
            </Button>
          </Tooltip>
        )}
        {showCreateNewPaymentLink && (
          <Tooltip placement="top" title="Update Payment" injectWrapper>
            <Button
              size="small"
              color="primary"
              variant="text"
              loading={isFetching && { width: 160 }}
              startIcon={<AddCircleOutlineIcon color="secondary" />}
              onClick={() => {
                setUpdatePaymentLink(false);
                setPaymentCreateDialogOpen(true);
              }}
            >
              Create Payment Link
            </Button>
          </Tooltip>
        )}
        {showCancelPaymentLink && (
          <Tooltip placement="top" title="Cancel Payment" injectWrapper>
            <Button
              size="small"
              color="primary"
              variant="text"
              loading={isFetching && { width: 160 }}
              startIcon={<CancelIcon color="secondary" />}
              onClick={(e) => {
                showPopConfirm(e);
              }}
            >
              Cancel Payment Link
            </Button>
          </Tooltip>
        )}
      </>
    );

    return (
      <Card sx={{ height: '100%', ...cardStyle }}>
        <CardHeader title="Payment" subheader={actionButtons} />
        <CardContent noPadding>
          <Grid container column p={2} pt={0}>
            <Grid item sx={{ mb: 1 }}>
              <Typography
                variant="p4"
                textTransform="uppercase"
                loading={isFetching && { width: 80 }}
                sx={{
                  lineHeight: 1,
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                Due Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="p3" loading={isFetching && { width: 45 }}>
                {paymentTransaction?.amount?.value || 'N/A'}{' '}
                {paymentTransaction?.amount?.currency}
              </Typography>
            </Grid>
            <Grid item sx={{ mb: 1, mt: 2 }}>
              <Typography
                variant="p4"
                textTransform="uppercase"
                loading={isFetching && { width: 80 }}
                sx={{
                  lineHeight: 1,
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                Paid Amount
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="p3"
                loading={isFetching && { width: 45 }}
                sx={{ textDecoration: 'underline' }}
              >
                {paymentTransaction?.paidAmount?.value || 0}{' '}
                {paymentTransaction?.paidAmount?.currency}
              </Typography>
            </Grid>
            <Grid item>
              <Grid item sx={{ mb: 1, mt: 2 }}>
                <Typography
                  variant="p4"
                  textTransform="uppercase"
                  loading={isFetching && { width: 55 }}
                  sx={{
                    lineHeight: 1,
                    color: (theme) => theme.palette.grey[600],
                  }}
                >
                  Status
                </Typography>
              </Grid>
              <Grid item container alignItems="left">
                {!isFetching &&
                  getPaymentStatusIcon(paymentTransaction?.status || 'N/A')}
                <Typography
                  variant="p3"
                  loading={isFetching && { width: 70 }}
                  sx={{ textDecoration: 'underline' }}
                >
                  {paymentTransaction?.status || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            {/** Promo code */}
            <Grid item>
              <Grid item sx={{ mb: 1, mt: 2 }}>
                <Typography
                  variant="p4"
                  textTransform="uppercase"
                  loading={isFetching && { width: 55 }}
                  sx={{
                    lineHeight: 1,
                    color: (theme) => theme.palette.grey[600],
                  }}
                >
                  Promo code
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="p3" loading={isFetching && { width: 70 }}>
                  {paymentTransaction?.promoCode || '-'}
                </Typography>
              </Grid>
            </Grid>
            {(isFetching ||
              paymentTransaction?.paymentMethodDetails?.method) && (
              <Grid item>
                <Grid item sx={{ mb: 1, mt: 2 }}>
                  <Typography
                    variant="p4"
                    textTransform="uppercase"
                    loading={isFetching && { width: 90 }}
                    sx={{
                      lineHeight: 1,
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    Payment Method
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="p3"
                    loading={isFetching && { width: 100 }}
                  >
                    {paymentTransaction?.paymentMethodDetails?.method}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {(isFetching ||
              (paymentTransaction?.link && showUpdateOrCopyPaymentLink)) && (
              <Grid item>
                <Grid item sx={{ mb: 1, mt: 2 }}>
                  <Typography
                    variant="p4"
                    textTransform="uppercase"
                    loading={isFetching && { width: 85 }}
                    sx={{
                      lineHeight: 1,
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    Payment Link
                  </Typography>
                </Grid>
                <Tooltip placement="top" title="Copy">
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    startIcon={<ContentCopyIcon />}
                    loading={isFetching && { width: 120 }}
                    onClick={() => {
                      copyToClipboard(paymentTransaction?.link)
                        .then(() => {
                          Message.success('Payment link is copied.');
                        })
                        .catch(() => {
                          Message.error(
                            "Couldn't copy to clipboard. Please report the issue to the team"
                          );
                        });
                    }}
                  />
                </Tooltip>
              </Grid>
            )}

            {(isFetching || paymentTransaction?.updatedAt) && (
              <Grid item>
                <Grid item sx={{ mb: 1, mt: 2 }}>
                  <Typography
                    variant="p4"
                    textTransform="uppercase"
                    loading={isFetching && { width: 85 }}
                    sx={{
                      lineHeight: 1,
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    Last Updated
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="p3"
                    loading={isFetching && { width: 75 }}
                  >
                    {paymentTransaction?.updatedAt &&
                      format(
                        parseISO(paymentTransaction?.updatedAt),
                        dateTimeFormat
                      )}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
        <CreatePaymentTransaction
          user={user}
          permissions={permissions}
          referenceId={referenceId}
          referenceType={referenceType}
          open={isPaymentCreateDialogOpen}
          isUpdateDialog={isUpdatePaymentLink}
          onClose={() => setPaymentCreateDialogOpen(false)}
        />
        {/* Popup to confirm cancelling a payment link */}
        <PopConfirm
          primaryButtonTitle="No"
          secondaryButtonTitle="Yes"
          anchorEl={popConfirmAnchor}
          onOk={() => closePopConfirm()}
          open={Boolean(popConfirmAnchor)}
          onCancel={() => {
            closePopConfirm();
            cancelPaymentTransaction({
              referenceId,
              referenceType,
            });
          }}
          id={popConfirmAnchor ? 'cancel-payment-confirm' : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          Are you sure you want to cancel this payment link?
        </PopConfirm>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Payment" />
      <CardContent sx={{ height: '100%' }}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Conditionally>
            <If condition={isFetching || get(permissions, 'create', true)}>
              <Grid
                item
                sx={{
                  ...(isFetching
                    ? {
                        width: '50%',
                      }
                    : {}),
                }}
              >
                <Grid
                  sx={{
                    textAlign: 'center',
                    marginBottom: '6px',
                  }}
                >
                  <Tooltip placement="top" title="Create Payment Link">
                    <Button
                      variant="filled"
                      loading={isFetching}
                      onClick={() => setPaymentCreateDialogOpen(true)}
                    >
                      Create Payment Link
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>
            </If>
            <Else>No payment information available</Else>
          </Conditionally>
        </Grid>
      </CardContent>
      <CreatePaymentTransaction
        user={user}
        permissions={permissions}
        referenceId={referenceId}
        referenceType={referenceType}
        open={isPaymentCreateDialogOpen}
        isUpdateDialog={isUpdatePaymentLink}
        onClose={() => setPaymentCreateDialogOpen(false)}
      />
    </Card>
  );
};

export default PaymentSection;
