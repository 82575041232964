import { useRef, useState, useEffect } from 'react';

import getFilterParams from './getFilterParams';

const useServerFiltration = ({
  saveFilters,
  fieldsConfig,
  initialValues,
  setQueryParams,
  onQueryParamsChange,
}) => {
  const queryParamsMap = useRef(initialValues);

  /**
   * Purpose:
   * Listen to changes in queryParamsMap in order to:
   * 1. Save filters to localstorage on destroy
   * 2. Update the params passed to useServerPagination
   */
  const [queryParamsState, setQueryParamsState] = useState(
    queryParamsMap.current
  );

  useEffect(() => {
    const { params: urlSearchParams } = getFilterParams(
      fieldsConfig,
      queryParamsState || {}
    );

    const updatedUrlSearchParams = onQueryParamsChange(
      urlSearchParams,
      queryParamsState
    );

    setQueryParams(updatedUrlSearchParams);
  }, [queryParamsState]);

  useEffect(() => {
    return () => {
      if (saveFilters) {
        saveFilters(queryParamsMap?.current);
      }
    };
  }, [queryParamsState]);

  const updateFilter = (key, value) => {
    queryParamsMap.current = {
      ...queryParamsMap.current,
      [key]: value,
    };
    setQueryParamsState(queryParamsMap.current);
  };

  return {
    updateFilter,
  };
};

export default useServerFiltration;
