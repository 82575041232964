import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import { Form, Controller } from 'design-system/form';
import { Grid, TextField, FormField } from 'design-system';
import {
  MEETING_FIELDS as FIELDS,
  meetingGetters as getters,
} from 'domain/meeting';

const MeetingForm = forwardRef(
  (
    {
      // Props
      onSubmit,
      defaultValues = {},
    },
    ref
  ) => {
    const formRef = useRef();

    useImperativeHandle(ref, () => ({
      submit() {
        formRef.current.submit();
      },
    }));

    return (
      <Form ref={formRef} onSubmit={onSubmit}>
        {({ control }) => (
          <Grid container column rowSpacing={2}>
            <Grid item>
              <FormField
                required
                label="Zoom Email"
                field={
                  <Controller
                    name={FIELDS.ZOOM_EMAIL.name}
                    control={control}
                    defaultValue={getters.getZoomEmail(defaultValues)}
                    rules={{
                      required: 'Zoom email is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Meeting Number"
                field={
                  <Controller
                    name={FIELDS.MEETING_NUMBER.name}
                    control={control}
                    defaultValue={getters.getMeetingNumber(defaultValues)}
                    rules={{
                      required: 'Meeting number is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Meeting Password"
                field={
                  <Controller
                    name={FIELDS.PASSWORD.name}
                    control={control}
                    defaultValue={getters.getPassword(defaultValues)}
                    rules={{
                      required: 'Meeting password is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Meeting Encrypted Password"
                field={
                  <Controller
                    name={FIELDS.ENCRYPTED_PASSWORD.name}
                    control={control}
                    defaultValue={getters.getEncryptedPassword(defaultValues)}
                    rules={{
                      required: 'Meeting encrypted password is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Account ID"
                field={
                  <Controller
                    name={FIELDS.ACCOUNT_ID.name}
                    control={control}
                    defaultValue={getters.getAccountId(defaultValues)}
                    rules={{
                      required: 'Account ID is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Client ID"
                field={
                  <Controller
                    name={FIELDS.CLIENT_ID.name}
                    control={control}
                    defaultValue={getters.getClientId(defaultValues)}
                    rules={{
                      required: 'Client ID is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Client Secret"
                field={
                  <Controller
                    name={FIELDS.CLIENT_SECRET.name}
                    control={control}
                    defaultValue={getters.getClientSecret(defaultValues)}
                    rules={{
                      required: 'Client secret is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Webhook Secret Token"
                field={
                  <Controller
                    name={FIELDS.WEBHOOK_SECRET_TOKEN.name}
                    control={control}
                    defaultValue={getters.getWebhookSecretToken(defaultValues)}
                    rules={{
                      required: 'Webhook secret token is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="Verification Token"
                field={
                  <Controller
                    name={FIELDS.VERIFICATION_TOKEN.name}
                    control={control}
                    defaultValue={getters.getVerificationToken(defaultValues)}
                    rules={{
                      required: 'Verification token is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Form>
    );
  }
);

export default MeetingForm;
