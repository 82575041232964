import httpClient from 'core/httpClient';

import * as constants from './constants';

/**
 * Used to fetch a file from S3 using fileId
 *
 * @param {string} id
 * @returns Promise<{id, fileType, objectUrl}>
 */
export const fetchFile = (id) => {
  const endpoint = [constants.ENDPOINT, id].join('/');

  return new Promise((resolve, reject) => {
    httpClient
      .get(endpoint, { responseType: 'blob' })
      .then((response) => {
        const blob = response.data;

        resolve({
          [constants.FIELDS.ID.name]: id,
          [constants.FIELDS.TYPE.name]: blob.type,
          [constants.FIELDS.URL.name]: URL.createObjectURL(blob),
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Uploads dropped file to S3 and returns response data
 *
 * @param {File} file
 * @param {DOCUMENT_TYPE} fileType
 * @param {number} userId
 * @param {number} isPublic
 * @returns FILE & { id }
 */
export const uploadFile = (file, fileType, userId, isPublic) => {
  return new Promise((resolve, reject) => {
    new Promise((res) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: file.type,
        });
        res(blob);
      };

      reader.readAsArrayBuffer(file);
    }).then((blob) => {
      const formData = new FormData();

      formData.append('file', blob);
      formData.append('type', fileType);

      if (userId) {
        formData.append('userId', userId);
      }
      // WARNING: used only for public files like Profile Picture
      if (isPublic) {
        formData.append('isPublic', isPublic);
      }

      httpClient
        .post(constants.ENDPOINT, formData, {
          headers: {
            contentType: 'multipart/form-data',
          },
        })
        .then((response) => {
          const uploadedFile = file.copy();

          uploadedFile[constants.FIELDS.ID.name] = response?.data?.id;
          resolve(uploadedFile);
        })
        .catch((e) => {
          reject(e);
        });
    });
  });
};

/**
 * Uploads blob file to S3 and returns response data
 *
 * @param {Blob} blob
 * @param {DOCUMENT_TYPE} fileType
 * @param {number} userId
 * @returns FILE & { id }
 */
export const uploadBlob = (blob, fileType, userId) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    formData.append('file', blob);
    formData.append('type', fileType);

    if (userId) {
      formData.append('userId', userId);
    }

    httpClient
      .post(constants.ENDPOINT, formData, {
        headers: {
          contentType: 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve({
          ...blob,
          id: response?.data?.id,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
