export const PACKAGE_NAME = 'Eligibility';

export const FIELDS = {
  REFERENCE_ID: {
    name: 'referenceId',
  },
  REFERENCE_TYPE: {
    name: 'referenceType',
  },

  USER_ID: {
    name: 'userId',
  },
  DOCTOR_ID: {
    name: 'doctorId',
  },
  IDENTITY_NUMBER: {
    name: 'identityNumber',
  },
  STATUS: {
    name: 'status',
  },
  DENIAL_REASON: {
    name: 'denialReason',
  },
};
