import { PACKAGE_NAME as MODEL } from '../../constants';
import { PACKAGE_NAME as USER } from '../constants';
import { PACKAGE_NAME as MEMBER } from './constants';

export const FETCH_MEMBERS = `${MODEL}/${USER}/${MEMBER}/FETCH_MEMBERS`;
export const CREATE_MEMBER = `${MODEL}/${USER}/${MEMBER}/CREATE_MEMBER`;
export const UPDATE_MEMBER = `${MODEL}/${USER}/${MEMBER}/UPDATE_MEMBER`;
export const DELETE_MEMBER = `${MODEL}/${USER}/${MEMBER}/DELETE_MEMBER`;
export const FETCH_BASIC_MEMBERS = `${MODEL}/${USER}/${MEMBER}/FETCH_BASIC_MEMBERS`;
export const FETCH_MEMBERS_HEALTH_PROFILES = `${MODEL}/${USER}/${MEMBER}/FETCH_MEMBERS_HEALTH_PROFILES`;

export const MEMBERS_FETCH_RESPONDED = `${MODEL}/${USER}/${MEMBER}/MEMBERS_FETCH_RESPONDED`;
export const MEMBER_CREATE_RESPONDED = `${MODEL}/${USER}/${MEMBER}/MEMBER_CREATE_RESPONDED`;
export const MEMBER_UPDATE_RESPONDED = `${MODEL}/${USER}/${MEMBER}/MEMBER_UPDATE_RESPONDED`;
export const MEMBER_DELETE_RESPONDED = `${MODEL}/${USER}/${MEMBER}/MEMBER_DELETE_RESPONDED`;
export const BASIC_MEMBERS_FETCH_RESPONDED = `${MODEL}/${USER}/${MEMBER}/BASIC_MEMBERS_FETCH_RESPONDED`;
export const MEMBERS_HEALTH_PROFILES_FETCH_RESPONDED = `${MODEL}/${USER}/${MEMBER}/MEMBERS_HEALTH_PROFILES_FETCH_RESPONDED`;
