import { connect } from 'react-redux';

import { userSelectors } from 'domain/user';
import { scheduleSelectors } from 'domain/schedule';

import BookingAvailabilityDialog from './BookingAvailabilityDialog';

const mapStateToProps = (state) => {
  const doctors = userSelectors.getDoctors(state) || [];
  const bookingSlots = scheduleSelectors.getBookingSlots(state);
  const isFetching = scheduleSelectors.isFetchingSchedules(state);
  const dayBookingSlots = scheduleSelectors.getDayBookingSlots(state);
  const isFetchingDay =
    scheduleSelectors.isFetchingDailyBookingAvailability(state);

  return {
    doctors,
    isFetching,
    bookingSlots,
    isFetchingDay,
    dayBookingSlots,
  };
};

export default connect(mapStateToProps)(BookingAvailabilityDialog);
