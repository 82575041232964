import { all, put, take, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import {
  notificationModelActions,
  notificationModelActionTypes,
} from 'model/notification';

import * as types from './actionTypes';

export function* sendNotification({
  userId,
  message,
  referenceId,
  referenceType,
  sendPushNotification,
}) {
  yield put(
    notificationModelActions.sendNotification(
      message,
      sendPushNotification,
      userId,
      referenceType,
      referenceId
    )
  );
}

export function* sendBulkNotifications({
  payload,
  params,
  callback = () => {},
}) {
  yield put(notificationModelActions.sendBulkNotifications(payload, params));
  yield take(notificationModelActionTypes.BULK_NOTIFICATIONS_SENT);

  callback();
  Message.success('Bulk notifications sent successfully');
}

export function* sendGeneralNotification({ key, userId, referenceId }) {
  yield put(
    notificationModelActions.sendGeneralNotification(key, userId, referenceId)
  );
}

export function* notificationSent() {
  Message.success('Notification sent successfully');
}

export default function* notificationModelSaga() {
  yield all([
    takeLatest(types.SEND_NOTIFICATION, sendNotification),
    takeLatest(types.SEND_BULK_NOTIFICATIONS, sendBulkNotifications),
    takeLatest(
      notificationModelActionTypes.NOTIFICATION_SENT,
      notificationSent
    ),
    takeLatest(types.SEND_GENERAL_NOTIFICATION, sendGeneralNotification),
  ]);
}
