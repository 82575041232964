import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { userSelectors } from 'domain/user';
import MemberSelection from 'presentation/components/MemberSelection';
import { consultationSelectors } from 'domain/consultation';
import { memberActions, memberSelectors } from 'domain/user/member';

const mapStateToProps = (state, ownProps) => {
  const user = userSelectors.getUser(state);
  const owner = isEmpty(user) ? ownProps.owner : user;
  const basicMembers = memberSelectors.getBasicMembers(state) || [];
  const allMembers = basicMembers.filter(
    (member) => !member.deletedFromOwnerAccount
  );
  const isSubmittingMembers =
    consultationSelectors.isSubmittingConsultationMembers(state);
  const isSubmitting = memberSelectors.isSubmittingMember(state);
  const isDeleting = consultationSelectors.isDeletingConsultationMember(state);
  const isFetching = memberSelectors.isFetchingBasicMembers(state);
  const members = ownProps.members || memberSelectors.getMembers(state);

  return {
    owner,
    members,
    allMembers,
    isDeleting,
    isFetching,
    isSubmitting,
    isSubmittingMembers,
    permissions: {
      edit: false,
      delete: false,
      hideShow: false,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllMembers: (ownerId) =>
    dispatch(memberActions.fetchBasicMembers(ownerId)),
  clearMembers: () => dispatch(memberActions.basicMembersReceived([])),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MemberSelection);
