import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './actionTypes';
import httpClient from '../../core/httpClient';
import { FIELDS, ENDPOINT, BULK_ENDPOINT } from './constants';

export function* sendNotification({
  userId,
  message,
  referenceId,
  referenceType,
  sendPushNotification,
}) {
  const payload = {
    [FIELDS.USER_ID.name]: userId,
    [FIELDS.MESSAGE.name]: message,
    [FIELDS.REFERENCE_ID.name]: referenceId,
    [FIELDS.REFERENCE_TYPE.name]: referenceType,
    [FIELDS.SEND_PUSH_NOTIFICATION.name]: sendPushNotification,
  };
  yield call(httpClient.post, ENDPOINT, payload);

  yield put(actions.notificationSent());
}

export function* sendBulkNotifications({ payload, params }) {
  yield call(httpClient.post, BULK_ENDPOINT, payload, { params });
  yield put(actions.bulkNotificationsSent());
}

export function* sendGeneralNotification({ userId, key, referenceId }) {
  yield call(
    httpClient.post,
    [ENDPOINT, 'key', key, 'userId', userId, 'reference', referenceId].join('/')
  );
  yield put(actions.notificationSent());
}

export default function* notificationModelSaga() {
  yield all([
    takeLatest(types.SEND_NOTIFICATION, sendNotification),
    takeLatest(types.SEND_BULK_NOTIFICATIONS, sendBulkNotifications),
    takeLatest(types.SEND_GENERAL_NOTIFICATION, sendGeneralNotification),
  ]);
}
