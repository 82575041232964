/* eslint-disable no-param-reassign */
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState, useEffect } from 'react';

import logger from 'core/logger';
import * as api from 'core/file/apis';
import { FILE_TYPES } from 'domain/file';
import {
  Box,
  Message,
  Dropzone,
  Typography,
  FormHelperText,
} from 'design-system';
import FilePreview from './FilePreview';
import { FILE_STATUS as STATUS } from './constants';

const DEFAULT_CONFIG = {
  readonly: false,
  deletable: false,
  // maxSize: in bytes
  maxSize: 1024 * 1024 * 3,
  fileType: FILE_TYPES.DOCUMENTS,
};

const fetchPromises = {};

const FileUpload = ({
  userId,
  // Set isPublic to false by default
  isPublic = false,
  loading,
  files = [],
  config = {},
  size = 'medium',
  accept,
  placeholder,
  multiple = false,
  onChange = () => {},
  customIcon,
  error,
  helperText,
  ...rest
}) => {
  const conf = { ...DEFAULT_CONFIG, ...(config || {}) };

  const filesState = useRef([]);
  const [errors, setErrors] = useState();
  const [, setForceRender] = useState(Date.now());

  const setFilesList = (list, isChange = true) => {
    // TODO: copy isSubmitting to outside components
    const completeFiles = list.filter(
      (item) => item.status === STATUS.COMPLETE.key
    );

    const isActualChange =
      isChange &&
      completeFiles.length !==
        filesState.current.filter((item) => item.status === STATUS.COMPLETE.key)
          .length;

    filesState.current = list;
    const filesWithServerId = list.filter((file) =>
      [STATUS.COMPLETE.key, STATUS.FETCHING.key].includes(file.status)
    );

    setForceRender(Date.now());
    onChange(list, isActualChange, completeFiles, filesWithServerId);
  };

  useEffect(() => {
    if (
      // If files changed
      (files || [])
        .map((file) => file.id)
        .sort()
        .join(',') !==
      (filesState.current || [])
        .map((file) => file.id)
        .sort()
        .join(',')
    ) {
      const parsedFiles = files.map((file) => {
        if (!file.status) {
          // A newly passed file
          let promise;
          if (fetchPromises[file.id]) {
            promise = fetchPromises[file.id];
          } else {
            promise = api.fetchFile(file.id);
            fetchPromises[file.id] = promise;
          }

          promise
            .then((response) => {
              setFilesList(
                filesState.current.map((_file) => {
                  if (_file.id === response.id) {
                    return {
                      ...response,
                      status: STATUS.COMPLETE.key,
                    };
                  }
                  return _file;
                }),
                false
              );
            })
            .catch((e) => {
              Message.error(
                'Failed to fetch files. Please refresh the page or report it to the team',
                e.toString()
              );
            });

          return {
            ...file,
            status: STATUS.FETCHING.key,
          };
        }
        return file;
      });
      // Prevent editing list of files from outside while uploading
      if (
        parsedFiles.filter((file) =>
          [STATUS.UPLOADING.key].includes(file.status)
        ).length === 0
      ) {
        setFilesList(parsedFiles, false);
      }
    }
  }, [files]);

  const retry = (file) => {
    setFilesList(
      filesState.current.map((_file) => {
        if (_file.id === file.id) {
          return {
            ..._file,
            status: STATUS.UPLOADING.key,
          };
        }

        return _file;
      })
    );

    // eslint-disable-next-line no-use-before-define
    uploadFile(file);
  };

  const uploadFile = async (file) => {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve) => {
      api
        .uploadFile(file, conf.fileType, userId, isPublic)
        .then((response) => {
          response.status = STATUS.COMPLETE.key;
          setFilesList([
            ...filesState.current.filter((item) => {
              return item.id !== file.id;
            }),
            response,
          ]);
        })
        .catch((e) => {
          logger.error('error', e);
          setFilesList(
            filesState.current.map((_file) => {
              if (_file.id === file.id) {
                _file.status = STATUS.UPLOAD_FAILED.key;
                _file.retry = () => retry(file);
                return _file;
              }

              return _file;
            })
          );
        });
    });
  };

  const onDrop = (newFiles) => {
    let filesList = [];

    if (multiple) {
      filesList = [...newFiles];
    } else {
      filesList = [newFiles[0]];
    }

    const errs = {};
    const newFilesLength = filesList?.length;
    const validatedFiles = filesList.filter(
      (file) => file.size <= conf.maxSize
    );

    if (newFilesLength !== validatedFiles.length) {
      errs.maxSize = `Error in ${filesList
        .filter((file) => file.size > conf.maxSize)
        .map((file) => file.name)
        .join(', ')}. File size cannot exceed ${(
        conf.maxSize /
        (1024 * 1024)
      ).toFixed(1)} mega bytes.`;
    }

    if (!isEmpty(errs)) {
      setErrors(errs);

      return;
    }

    setErrors();

    filesList = filesList.map((file, idx) => {
      file.id = Math.random(Date.now() + idx) + 1;
      file.status = STATUS.UPLOADING.key;

      return file;
    });

    if (multiple) {
      setFilesList([...filesState.current, ...filesList]);
    } else {
      setFilesList(filesList);
    }

    filesList.map(async (file) => {
      await uploadFile(file);
    });
  };

  return (
    <Box>
      <FilePreview
        size={size}
        loading={loading}
        files={filesState.current}
        customIcon={customIcon}
        onDelete={
          conf.deletable
            ? (fileId) => {
                setFilesList(
                  filesState.current.filter((file) => file.id !== fileId)
                );
              }
            : undefined
        }
      />
      {!filesState.current.length && conf.readonly && (
        <Typography variant="p4" loading={loading}>
          No available files
        </Typography>
      )}
      {/* show it when:
        1. It's not readonly
            AND
        2. It's not loading (loading === false or loading.dropzone === true or undefined)
            AND
        (
          3. multiple
              OR
          4. !multiple && filesState?.current?.length === 0
              OR
          5. !multiple && !conf.deletable (So that you can replace)
        )
      */}
      {!conf.readonly &&
        (!loading || [undefined, true].includes(loading?.dropzone)) &&
        (multiple || !conf.deletable || filesState?.current?.length === 0) && (
          // If it's not loading, then show drop zone (to be able to upload)
          // If loading.dropzone is undefined or explicitly true,
          // then show Dropzone (loading is truthy so skeleton will show)
          <Dropzone
            onDrop={onDrop}
            loading={loading}
            multiple={multiple}
            accept={accept}
            placeholder={placeholder}
            sx={{
              mt: 2,
            }}
            {...rest}
          />
        )}
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
      {!isEmpty(errors) &&
        values(errors).map((err) => (
          <FormHelperText error>{err}</FormHelperText>
        ))}
    </Box>
  );
};

export default FileUpload;
