import React, { useState } from 'react';

import { Tab, Tabs, TabPanel, Typography } from 'design-system';
import DoctorAvailabilitySchedule from 'presentation/schedule/DoctorAvailabilitySchedule';

import DoctorProfileList from './doctorProfile/DoctorProfileList';

const DoctorManagement = () => {
  const [tab, setTab] = useState(0);

  const onTabChanged = (_event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs value={tab} handleChange={onTabChanged}>
        <Tab
          value={0}
          label={
            <Typography
              variant="h5"
              sx={{
                paddingBottom: 0,
                textTransform: 'none',
              }}
            >
              Doctor Schedules
            </Typography>
          }
        />
        <Tab
          value={1}
          label={
            <Typography
              variant="h5"
              sx={{
                paddingBottom: 0,
                textTransform: 'none',
              }}
            >
              Doctor Profiles
            </Typography>
          }
        />
      </Tabs>
      <TabPanel value={tab} index={0} id="doctor-schedules-tabpanel">
        <DoctorAvailabilitySchedule />
      </TabPanel>
      <TabPanel value={tab} index={1} id="doctor-profiles-tabpanel">
        <DoctorProfileList />
      </TabPanel>
    </>
  );
};

export default DoctorManagement;
