import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as NOTIFICATION } from './constants';

export const SEND_NOTIFICATION = `${MODEL}/${NOTIFICATION}/SEND_NOTIFICATION`;
export const SEND_BULK_NOTIFICATIONS = `${MODEL}/${NOTIFICATION}/SEND_BULK_NOTIFICATIONS`;

export const NOTIFICATION_SENT = `${MODEL}/${NOTIFICATION}/NOTIFICATION_SENT`;
export const BULK_NOTIFICATIONS_SENT = `${MODEL}/${NOTIFICATION}/BULK_NOTIFICATIONS_SENT`;

export const SEND_GENERAL_NOTIFICATION = `${MODEL}/${NOTIFICATION}/SEND_GENERAL_NOTIFICATION`;
