import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Form, Controller } from 'design-system/form';
import { getLogonBanner } from 'core/siteConfig';

import {
  Grid,
  Card,
  Button,
  FormField,
  TextField,
  CardContent,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogTitle,
} from 'design-system';

const LogIn = ({ logIn, isLoggingIn, errorMessage }) => {
  const [showWarningPopup, setShowWarningPopup] = useState(true);
  const onWarningPopupClose = () => {
    setShowWarningPopup(false);
  };
  const onSubmitted = (data) => {
    const { username, password } = data;

    logIn(username, password);
  };

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
          body {
              height: 100%;
          }`}
        </style>
      </Helmet>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid item container sx={{ width: 400 }}>
          <Grid item sx={{ width: '100%' }}>
            <Card>
              <CardContent>
                <Form onSubmit={onSubmitted}>
                  {({ control }) => (
                    <Grid container column rowSpacing={2} alignItems="center">
                      <Grid item>
                        <img
                          width={100}
                          src="/logo192.png"
                          alt="Alma Health Logo"
                        />
                      </Grid>
                      <FormField
                        label="USER NAME"
                        field={
                          <Controller
                            name="username"
                            control={control}
                            rules={{
                              required: 'User name is required',
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                fullWidth
                                size="large"
                                value={value}
                                onChange={onChange}
                                error={Boolean(error)}
                                placeholder="User name.."
                                helperText={error?.message ?? null}
                              />
                            )}
                          />
                        }
                      />
                      <FormField
                        label="PASSSWORD"
                        field={
                          <Controller
                            name="password"
                            control={control}
                            rules={{
                              required: 'Password is required',
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <>
                                <TextField
                                  fullWidth
                                  size="large"
                                  value={value}
                                  type="password"
                                  onChange={onChange}
                                  error={Boolean(error)}
                                  placeholder="Password.."
                                  helperText={error?.message ?? null}
                                />
                                {errorMessage && (
                                  <FormHelperText error>
                                    {errorMessage}
                                  </FormHelperText>
                                )}
                              </>
                            )}
                          />
                        }
                      />
                      <Grid item sx={{ width: '100%' }}>
                        <Button
                          fullWidth
                          variant="filled"
                          type="submit"
                          spinning={isLoggingIn}
                        >
                          Log In
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {getLogonBanner() && (
        <Dialog open={showWarningPopup} onClose={onWarningPopupClose}>
          <DialogContent sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            <DialogTitle sx={{ color: 'error.main' }}>WARNING</DialogTitle>
            <DialogContent sx={{ fontWeight: 'normal' }}>
              This computer system is a property of AH CARE CLINIC. It is for
              authorized use only. By using this system, all users acknowledge
              notice of, and agree to comply with the AH CARE CLINIC. Acceptable
              use of Information Security High Level Policy. Unauthorized or
              improper use of this system may result in administrative
              disciplinary action, civil charges/criminal penalties, and/or
              other sanctions as set forth in the AH CARE CLINIC. By continuing
              to use this system, you indicate your awareness of and consent to
              these terms and conditions of use.
            </DialogContent>
            <Button variant="outlined" onClick={onWarningPopupClose}>
              OK
            </Button>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default LogIn;
