import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect, useImperativeHandle } from 'react';

import { userConstants } from 'domain/user';
import {
  Grid,
  Chip,
  Radio,
  Select,
  MenuItem,
  FormField,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from 'design-system';
import { isAllowed } from 'core/permission';

const { DISEASES } = userConstants;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 450,
      width: 100,
    },
  },
};
const ChronicConditionsField = ({
  label,
  userId,
  required,
  healthInfo,
  forwardedRef,
  fetchHealthInfo,
  isSubmittingUser,
  chronicConditions,
  hasChronicDiseases,
  updateHealthProfile,
}) => {
  const [error, setError] = useState();
  const [value, setValue] = useState(chronicConditions);
  const [hasChronicConditions, setHasChronicConditions] =
    useState(hasChronicDiseases);

  useEffect(() => {
    if (userId && (!healthInfo || healthInfo?.userId !== userId)) {
      fetchHealthInfo(userId);
    }
  }, [userId, userId]);

  useEffect(() => {
    setValue(chronicConditions);
    setHasChronicConditions(hasChronicDiseases);
  }, [chronicConditions, hasChronicDiseases]);

  const onUserInput = (radioValue, selectValue) => {
    // Validate if the values are correct
    if (!(radioValue && isEmpty(selectValue))) {
      // Validate if the values are different from pre-saved data
      if (
        radioValue !== hasChronicDiseases ||
        JSON.stringify(selectValue) !== JSON.stringify(chronicConditions || [])
      ) {
        const payload = {
          diseases: selectValue,
          hasChronicDiseases: radioValue,
        };

        updateHealthProfile(userId, payload);
        setError();
      }
    } else {
      setError('Please do not leave this field unanswered');
    }
  };

  useImperativeHandle(forwardedRef, () => ({
    validate() {
      if (!(hasChronicConditions && isEmpty(value))) {
        setError();
        return true;
      }

      setError('Please do not leave this field unanswered');
      return false;
    },
  }));

  return (
    <>
      <FormField
        key={userId}
        required={required}
        label={label || 'CHRONIC CONDITIONS'}
        labelProps={{
          alignItems: 'baseline',
        }}
        loading={!userId}
        labelWidth={180}
        action={
          userId && (
            <RadioGroup
              disabled={isSubmittingUser}
              row
              value={(() => {
                if (hasChronicConditions === true) {
                  return 'true';
                }
                if (hasChronicConditions === false) {
                  return 'false';
                }
                return hasChronicConditions;
              })()}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                const newValue = (() => {
                  if (e.target.value === 'true') {
                    return true;
                  }
                  if (e.target.value === 'false') {
                    return false;
                  }
                })();

                setHasChronicConditions(newValue);
                onUserInput(newValue, value);
              }}
            >
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    size="small"
                    disabled={
                      isSubmittingUser ||
                      !isAllowed(
                        'orderView',
                        'orderDetails',
                        'chronicCondition'
                      )
                    }
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    size="small"
                    disabled={
                      isSubmittingUser ||
                      !isAllowed(
                        'orderView',
                        'orderDetails',
                        'chronicCondition'
                      )
                    }
                  />
                }
                label="No"
              />
            </RadioGroup>
          )
        }
        field={
          hasChronicConditions && (
            <Select
              fullWidth
              multiple
              size="medium"
              soak="light"
              variant="filled"
              value={value}
              MenuProps={MenuProps}
              loading={isSubmittingUser || !userId}
              onChange={(event) => {
                setValue(event.target.value);
                onUserInput(hasChronicConditions, event.target.value);
              }}
              disabled={
                !isAllowed('orderView', 'orderDetails', 'chronicCondition')
              }
              renderValue={(selected) => (
                <Grid
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                  }}
                >
                  {selected.map((value) => (
                    <Chip
                      size="small"
                      color="primary"
                      key={DISEASES[value]?.key || value}
                      label={DISEASES[value]?.label || value}
                    />
                  ))}
                </Grid>
              )}
              sx={{
                height: 'auto',
                '& .MuiSelect-select': {
                  height: 'auto',
                },
              }}
            >
              {keys(DISEASES).map((option) => (
                <MenuItem value={option} key={option}>
                  <Checkbox checked={value.indexOf(option) > -1} />
                  {DISEASES[option].label}
                </MenuItem>
              ))}
            </Select>
          )
        }
      />
      {(error || isSubmittingUser) && (
        <FormHelperText error={!isSubmittingUser && Boolean(error)}>
          {isSubmittingUser ? 'Saving...' : error}
        </FormHelperText>
      )}
    </>
  );
};

export default ChronicConditionsField;
