import React, { useState } from 'react';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import logger from 'core/logger';
import { Button, Message } from 'design-system';

import { createPdfFile, downloadPdfFile } from '../utils';

const DownloadPdfButton = ({
  payload,
  resource,
  fileName,
  children,
  iconProps = {
    color: 'secondary',
  },
  sx,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Button
      spinning={isLoading}
      startIcon={<PrintOutlinedIcon {...iconProps} />}
      onClick={(e) => {
        e.stopPropagation();
        setLoading(true);

        createPdfFile(resource, fileName, payload)
          .then((blob) => downloadPdfFile(blob, fileName))
          .catch((error) => {
            logger.error('Could not load pdf file: ', error);
            Message.error(
              'An error occurred during downloading the file. Please try again later or report the issue to the support team.'
            );
          })
          .finally(() => setLoading(false));
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default DownloadPdfButton;
