import lowerCase from 'lodash/lowerCase';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

import { getDefaultTimeZone } from 'core/siteConfig';

import { COUNTRIES } from './constants';

export const getCountryByCode = (code) => {
  return COUNTRIES.find((item) => lowerCase(item.code) === lowerCase(code));
};

export const getCountryByLabel = (label) => {
  return COUNTRIES.find((item) => lowerCase(item.label) === lowerCase(label));
};

/**
 * Converts the given time slot to the corresponding time in the deployment's timezone.
 * @param {Object} slot - The time slot object containing hours and minutes.
 * @returns {string} - The formatted time in the user's timezone.
 */
export const convertTimeToPatientTimeZone = (slot) => {
  const { hours, minutes } = slot;

  const currentTime = new Date();
  currentTime.setHours(hours, minutes);
  return formatInTimeZone(currentTime, getDefaultTimeZone(), 'h:mm a');
};
