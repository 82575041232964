export const FILE_STATUS = {
  FETCHING: {
    key: 'FETCHING',
  },
  UPLOADING: {
    key: 'UPLOADING',
  },
  COMPLETE: {
    // When the fils is ready to be shown, with no action happening (Neither fetching, uploading nor erred)
    key: 'COMPLETE',
  },
  UPLOAD_FAILED: {
    key: 'UPLOAD_FAILED',
  },
};
