import get from 'lodash/get';

import { FIELDS, OUTCOME_FIELDS } from './constants';

export const getId = (consultation) => get(consultation, FIELDS.ID.name);

export const getCreatedAt = (consultation) =>
  get(consultation, FIELDS.CREATED_AT.name);

export const getUpdatedAt = (consultation) =>
  get(consultation, FIELDS.UPDATED_AT.name);

export const getCreatedBy = (consultation) =>
  get(consultation, FIELDS.CREATED_BY.name);

export const getUpdatedBy = (consultation) =>
  get(consultation, FIELDS.UPDATED_BY.name);

export const getCreatedByUser = (consultation) =>
  get(consultation, FIELDS.CREATED_BY_USER.name);

export const getUpdatedByUser = (consultation) =>
  get(consultation, FIELDS.UPDATED_BY_USER.name);

export const getStatus = (consultation) =>
  get(consultation, FIELDS.STATUS.name);

export const getUserNotes = (consultation) =>
  get(consultation, FIELDS.CONSULTATION_USER_NOTES.name);

export const getUserId = (consultation) =>
  get(consultation, FIELDS.USER_ID.name);

export const getUser = (consultation) => get(consultation, FIELDS.USER.name);

export const getChannel = (consultation) =>
  get(consultation, FIELDS.CHANNEL.name);

export const getDoctor = (consultation) =>
  get(consultation, FIELDS.DOCTOR.name);

export const getDoctorId = (consultation) =>
  get(consultation, FIELDS.DOCTOR_ID.name);

export const getDocuments = (consultation) =>
  get(consultation, FIELDS.DOCUMENTS.name) || [];

export const getReferenceDocuments = (consultation) =>
  get(consultation, FIELDS.REFERENCE_DOCUMENTS.name) || [];

export const getComments = (consultation) =>
  get(consultation, FIELDS.COMMENTS.name);

export const getPatients = (consultation) =>
  get(consultation, FIELDS.PATIENTS.name);

export const getMeeting = (consultation) =>
  get(consultation, FIELDS.MEETING.name);

export const getMeetingNumber = (consultation) =>
  get(consultation, FIELDS.MEETING.fields.NUMBER.path);

export const getMeetingPassword = (consultation) =>
  get(consultation, FIELDS.MEETING.fields.PASSWORD.path);

export const getMeetingEncryptedPassword = (consultation) =>
  get(consultation, FIELDS.MEETING.fields.ENCRYPTED_PASSWORD.path);

export const getCancellationInfo = (consultation) =>
  get(consultation, FIELDS.CANCELLATION_INFO.name);

export const getConsultationType = (consultation) =>
  get(consultation, FIELDS.CONSULTATION_TYPE.path);

export const getConsultationTypeDetails = (consultation) =>
  get(consultation, FIELDS.CONSULTATION_TYPE_DETAILS.path);

export const getConsultationTypeId = (consultation) =>
  get(consultation, FIELDS.CONSULTATION_TYPE_ID.name);

export const getConsultationChannel = (consultation) =>
  get(consultation, FIELDS.CHANNEL.name);

export const getAssociatedOrderIds = (consultation) =>
  get(consultation, FIELDS.ASSOCIATED_ORDER_IDS.name);

export const getParticipants = (consultation) =>
  get(consultation, FIELDS.PARTICIPANTS.name);

export const getLabTests = (consultation) =>
  get(consultation, FIELDS.LAB_TESTS.name) || [];

export const getPaymentTransaction = (consultation) =>
  get(consultation, FIELDS.PAYMENT_TRANSACTION.name);

export const hasPaymentTransaction = (consultation) =>
  get(consultation, FIELDS.HAS_PAYMENT_TRANSACTION.name);

export const isEligibilityCheckRequested = (consultation) =>
  get(consultation, FIELDS.IS_ELIGIBILITY_CHECK_REQUESTED.name);

export const isLabTestRequested = (consultation) =>
  get(consultation, FIELDS.IS_LAB_TEST_REQUESTED.name);

export const getSchedule = (consultation) =>
  get(consultation, FIELDS.SCHEDULE.name);

export const getStartTime = (consultation, isDirect) =>
  isDirect
    ? get(consultation, FIELDS.START_TIME.name)
    : get(consultation, FIELDS.SCHEDULE.fields.START_TIME.path);

export const getEndTime = (consultation, isDirect) =>
  isDirect
    ? get(consultation, FIELDS.END_TIME.name)
    : get(consultation, FIELDS.SCHEDULE.fields.END_TIME.path);

export const getTenant = (consultation) =>
  get(consultation, FIELDS.TENANT_KEY.name);

export const getNamespace = (consultation) =>
  get(consultation, FIELDS.NAMESPACE_KEY.name);

export const getAttendee = (consultation) =>
  get(consultation, FIELDS.ATTENDEE.name);

export const getOutComeLabel = (obj) => get(obj, OUTCOME_FIELDS.OUTCOME.label);

export const getOutComeKey = (obj) => get(obj, OUTCOME_FIELDS.OUTCOME.key);

export const getMeetingCommunicationMethod = (consultation) =>
  get(consultation, FIELDS.MEETING_COMMUNICATION_METHOD.name);

export const getEnableCalls = (consultation) =>
  get(consultation, FIELDS.MEETING.fields.ENABLE_CALLS.path);

export const getIsPaidByUser = (consultation) =>
  get(consultation, FIELDS.IS_PAID_BY_USER.name);
