import React, { useState, useEffect } from 'react';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { DataGrid, PageTitle } from 'design-system';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { filtration, DataGridCard, useServerPagination } from 'core/dataGrid';
import { anonymousUserConstants as constants } from 'domain/anonymousUser';
import isValid from 'date-fns/isValid';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { getColumns, getFilterFieldsConfig } from './utils';

const AnonymousUsersList = ({ saveFilters, cityMap }) => {
  const { filterValues } = filtration.getFilterParamsFromLs(
    getFilterFieldsConfig(),
    constants.ANONYMOUS_USER_FILTERS
  );

  const [isFetching, setFetching] = useState(false);

  const navigate = useNavigate();

  const {
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/anonymous-user',
    pageSize: 15,
    initialSortModel: { field: 'updatedAt', sort: 'desc' },
    columnFieldsMap: {},
  });

  const { updateFilter } = filtration.useServerFiltration({
    saveFilters,
    setQueryParams,
    initialValues: filterValues,
    fieldsConfig: getFilterFieldsConfig(),
    onQueryParamsChange: (urlSearchParams) => {
      return new URLSearchParams(urlSearchParams);
    },
  });

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const onRowClick = (row) => {
    const { id } = row;

    navigate(`/anonymous-user/${id}`);
  };

  const filtersDef = {
    fields: [
      {
        name: 'firstOpenFrom',
        type: 'date',
        disabled: isFetching,
        icon: CalendarMonthOutlinedIcon,
        placeholder: 'First open from',
        defaultValue: filterValues.firstOpenFrom,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('firstOpenFrom', timestamp);
          }
        },
      },
      {
        name: 'firstOpenUntil',
        type: 'date',
        disabled: isFetching,
        icon: CalendarMonthOutlinedIcon,
        placeholder: 'First open until',
        defaultValue: filterValues.firstOpenUntil,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('firstOpenUntil', timestamp);
          }
        },
      },
      {
        name: 'lastActiveFrom',
        type: 'date',
        disabled: isFetching,
        icon: CalendarMonthOutlinedIcon,
        placeholder: 'Last active from',
        defaultValue: filterValues.lastActiveFrom,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('lastActiveFrom', timestamp);
          }
        },
      },
      {
        name: 'lastActiveUntil',
        type: 'date',
        disabled: isFetching,
        icon: CalendarMonthOutlinedIcon,
        placeholder: 'Last active  until',
        defaultValue: filterValues.lastActiveUntil,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('lastActiveUntil', timestamp);
          }
        },
      },
      {
        name: 'city',
        type: 'autocomplete',
        placeholder: 'City',
        icon: LocationCityIcon,
        options: keys(cityMap),
        withNone: true,
        defaultValue: filterValues?.city,
        disabled: isFetching || isEmpty(cityMap),
        filter: (city) => {
          updateFilter('city', city);
        },
      },
      {
        name: 'userId',
        type: 'autocomplete',
        placeholder: 'User Id',
        icon: PersonOutlineOutlinedIcon,
        withNone: true,
        withValue: true,
        defaultValue: filterValues?.userId,
        disabled: isFetching,
        filter: (userId) => {
          updateFilter('userId', userId);
        },
      },
    ],
  };

  return (
    <>
      <PageTitle title="Anonymous App Users" />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          {...dataGridProps}
          onRowClick={(prms) => onRowClick(prms?.row)}
          columns={getColumns()}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => {
                window.open(`/anonymous-user/${row.id}`, '_blank');
              },
            },
          ]}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
};

export default AnonymousUsersList;
