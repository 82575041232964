import { connect } from 'react-redux';

import { consultationActions } from 'domain/consultation';
import { doctorNotesActions, doctorNotesSelectors } from 'domain/doctorNotes';

import ManagementAndTreatmentPlanForm from './ManagementAndTreatmentPlanForm';

const mapStateToProps = (state) => {
  const getDoctorNotesById = (userId) =>
    doctorNotesSelectors.getDoctorNotesById(state, userId);

  return {
    getDoctorNotesById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createOutcome: (consultationId, patientId, payload) =>
    dispatch(
      consultationActions.createConsultationPatientOutcome(
        consultationId,
        patientId,
        payload
      )
    ),
  submitManagementTreatmentPlanForm: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      doctorNotesActions.submitDoctorNotes(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagementAndTreatmentPlanForm);
