/* File domain constants */
import { FILE_FIELDS } from 'model/file';

export const PACKAGE_NAME = 'File';
export const ENDPOINT = '/file';

export const FILE_TYPES = {
  MISC: 'MISC',
  IMPORT: 'IMPORT',
  DOCUMENTS: 'DOCUMENTS',
  AVATAR_IMAGE: 'AVATAR_IMAGE',
  IDENTITY_IMAGE: 'IDENTITY_IMAGE',
  PRESCRIPTION_IMAGE: 'PRESCRIPTION_IMAGE',
  MEDICINE_PILL_IMAGE: 'MEDICINE_PILL_IMAGE',
  MEDICINE_PACKAGE_IMAGE: 'MEDICINE_PACKAGE_IMAGE',
};

export const FIELDS = {
  ...FILE_FIELDS,
};
