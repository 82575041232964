import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import { getOrderServiceEndpoint, getPdfServiceEndpoint } from 'core/endpoint';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* createPrescription({ payload }) {
  try {
    const response = yield call(httpClient.post, constants.ENDPOINT, payload);
    yield put(actions.prescriptionCreationResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.prescriptionCreationResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* submitAllPrescriptions({ payload, consultationId }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.PRESCRIPTIONS_SUBMISSION_ENDPOINT,
      payload,
      {
        baseURL: getOrderServiceEndpoint(),
        params: { consultationId },
      }
    );
    yield put(
      actions.allPrescriptionsSubmissionResponded(true, response?.data)
    );
  } catch (e) {
    yield put(actions.allPrescriptionsSubmissionResponded(false));
  }
}

export function* createPatientPrescription({ payload }) {
  try {
    const response = yield call(httpClient.post, constants.ENDPOINT, payload, {
      baseURL: getOrderServiceEndpoint(),
    });

    yield put(
      actions.patientPrescriptionCreationResponded(true, response.data)
    );
  } catch (e) {
    yield put(actions.patientPrescriptionCreationResponded(false));
  }
}

export function* updatePatientPrescription({
  userId,
  consultationId,
  payload,
}) {
  try {
    const response = yield call(httpClient.patch, constants.ENDPOINT, payload, {
      params: { userId, consultationId },
      baseURL: getOrderServiceEndpoint(),
    });

    yield put(actions.patientPrescriptionUpdateResponded(true, response.data));
  } catch (e) {
    yield put(actions.patientPrescriptionUpdateResponded(false));
  }
}

export function* fetchPatientPrescription({ userId, consultationId }) {
  try {
    const response = yield call(httpClient.get, constants.ENDPOINT, {
      params: { userId, consultationId },
      baseURL: getOrderServiceEndpoint(),
    });
    yield put(actions.patientPrescriptionFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.patientPrescriptionFetchResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* createEPrescriptionDocument({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.E_PRESCRIPTION_PDF_ENDPOINT,
      payload,
      {
        responseType: 'blob',
        baseURL: getPdfServiceEndpoint(),
      }
    );

    const blob = response.data;

    yield put(
      actions.ePrescriptionDocumentCreationResponded(true, {
        type: blob.type,
        url: URL.createObjectURL(blob),
      })
    );
  } catch (e) {
    yield put(
      actions.ePrescriptionDocumentCreationResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        ),
        false
      )
    );
  }
}

export function* createPatientPrescriptionDocument({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.E_PRESCRIPTION_PDF_ENDPOINT_V2,
      payload,
      {
        responseType: 'blob',
        baseURL: getPdfServiceEndpoint(),
      }
    );

    const blob = response.data;

    yield put(
      actions.patientPrescriptionDocumentCreationResponded(true, {
        type: blob.type,
        url: URL.createObjectURL(blob),
      })
    );
  } catch (e) {
    yield put(
      actions.patientPrescriptionDocumentCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export default function* prescriptionModelSaga() {
  yield all([
    takeLatest(types.CREATE_PRESCRIPTION, createPrescription),
    takeLatest(
      types.CREATE_E_PRESCRIPTION_DOCUMENT,
      createEPrescriptionDocument
    ),
    takeLatest(
      types.CREATE_PATIENT_PRESCRIPTION_DOCUMENT,
      createPatientPrescriptionDocument
    ),
    takeLatest(types.SUBMIT_ALL_PRESCRIPTIONS, submitAllPrescriptions),
    takeLatest(types.CREATE_PATIENT_PRESCRIPTION, createPatientPrescription),
    takeLatest(types.UPDATE_PATIENT_PRESCRIPTION, updatePatientPrescription),
    takeLatest(types.FETCH_PATIENT_PRESCRIPTION, fetchPatientPrescription),
  ]);
}
