/* Consultation model constants */
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { dateTimeFormat } from '../../constants';

export const PACKAGE_NAME = 'Consultation';
export const ENDPOINT = '/consultation';
export const ADD_LAB_TEST = 'labTest';
export const CONSULTATION_BY_ID_ENDPOINT = (id) => `${ENDPOINT}/${id}`;
export const CONSULTATION_PATIENT_ENDPOINT = (consultationId, patientId) =>
  `${CONSULTATION_BY_ID_ENDPOINT(consultationId)}/patients/${patientId}`;
export const CONSULTATION_PATIENT_OUTCOMES_ENDPOINT = (
  consultationId,
  patientId
) =>
  `${CONSULTATION_BY_ID_ENDPOINT(
    consultationId
  )}/patients/${patientId}/outputs`;
export const CONSULTATION_PATIENT_OUTCOME_ENDPOINT = (
  consultationId,
  patientId,
  outputId
) =>
  `${CONSULTATION_BY_ID_ENDPOINT(
    consultationId
  )}/patients/${patientId}/outputs/${outputId}`;
export const CONSULTATION_PRESCRIPTIONS = (id) =>
  `${CONSULTATION_BY_ID_ENDPOINT(id)}/prescription`;
export const CONSULTATION_LAB_TESTS = (id) =>
  `${CONSULTATION_BY_ID_ENDPOINT(id)}/lab-test`;

const MEETING_FIELDS = {
  DOCTOR_ID: {
    name: 'doctorId',
    path: 'meeting.doctorId',
  },
  NUMBER: {
    name: 'meetingNumber',
    path: 'meeting.meetingNumber',
  },
  PASSWORD: {
    name: 'meetingPassword',
    path: 'meeting.password',
  },
  ENCRYPTED_PASSWORD: {
    name: 'meetingEncryptedPassword',
    path: 'meeting.encryptedPassword',
  },
  ENABLE_CALLS: {
    name: 'enableCalls',
    path: 'meeting.enableCalls',
  },
};

function displayUser(user) {
  return user?.fullName || '';
}

function displayDate(date) {
  return date ? format(parseISO(date), dateTimeFormat) : '';
}

const SCHEDULE_FIELDS = {
  START_TIME: {
    name: 'startTime',
    path: 'schedule.startTime',
    valueGetter: displayDate,
  },
  END_TIME: {
    name: 'endTime',
    path: 'schedule.endTime',
    valueGetter: displayDate,
  },
};

export const FIELDS = {
  ID: {
    name: 'id',
  },
  CREATED_AT: {
    name: 'createdAt',
    valueGetter: displayDate,
  },
  UPDATED_AT: {
    name: 'updatedAt',
    valueGetter: displayDate,
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  CREATED_BY_USER: {
    name: 'createdByUser',
    valueGetter: displayUser,
  },
  UPDATED_BY_USER: {
    name: 'updatedByUser',
    valueGetter: displayUser,
  },
  TENANT_KEY: {
    name: 'tenantKey',
  },
  STATUS: {
    name: 'status',
  },
  USER: {
    name: 'user',
    valueGetter: displayUser,
  },
  USER_ID: {
    name: 'userId',
  },
  DOCTOR: {
    name: 'doctor',
    valueGetter: displayUser,
  },
  DOCTOR_ID: {
    name: 'doctorId',
  },
  CHANNEL: {
    name: 'channel',
  },
  NAMESPACE_KEY: {
    name: 'namespaceKey',
  },
  DOCUMENTS: {
    name: 'documents',
    nonExportable: true,
  },
  REFERENCE_DOCUMENTS: {
    name: 'referenceDocuments',
    nonExportable: true,
  },
  COMMENTS: {
    name: 'comments',
    nonExportable: true,
  },
  CONSULTATION_USER_NOTES: {
    name: 'userNotes',
    nonExportable: true,
  },
  PATIENTS: {
    name: 'consultationPatients',
    nonExportable: true,
  },
  MEETING: {
    name: 'meeting',
    fields: MEETING_FIELDS,
    nonExportable: true,
  },
  MEETING_NUMBER: {
    name: 'meetingNumber',
    nonExportable: true,
  },
  MEETING_PASSWORD: {
    name: 'meetingPassword',
    nonExportable: true,
  },
  MEETING_ENCRYPTED_PASSWORD: {
    name: 'meetingEncryptedPassword',
    nonExportable: true,
  },
  CANCELLATION_INFO: {
    name: 'cancellationInfo',
    nonExportable: true,
  },
  CONSULTATION_TYPE: {
    name: 'consultationType',
    path: 'consultationType.englishTitle',
  },
  CONSULTATION_TYPE_DETAILS: {
    name: 'consultationType',
    path: 'consultationType.englishSubtitle',
  },
  CONSULTATION_TYPE_ID: {
    name: 'consultationTypeId',
  },
  ASSOCIATED_ORDER_IDS: {
    name: 'associatedOrderIds',
    nonExportable: true,
  },
  PARTICIPANTS: {
    name: 'participants',
    nonExportable: true,
  },
  LAB_TESTS: {
    name: 'labTests',
    nonExportable: true,
  },
  IS_LAB_TEST_REQUESTED: {
    name: 'isLabTestRequested',
  },
  HAS_PAYMENT_TRANSACTION: {
    name: 'hasPaymentTransaction',
    nonExportable: true,
  },
  IS_ELIGIBILITY_CHECK_REQUESTED: {
    name: 'isEligibilityCheckRequested',
  },
  ATTENDEE: {
    name: 'attendee',
  },
  OUTCOME: {
    name: 'consultationPatients',
  },
  SCHEDULE: {
    name: 'schedule',
    fields: SCHEDULE_FIELDS,
    nonExportable: true,
  },
  MEETING_COMMUNICATION_METHOD: {
    name: 'meetingCommunicationMethod',
  },
  IS_PAID_BY_USER: {
    name: 'isPaidByUser',
  },
  ...SCHEDULE_FIELDS,
};
export const OUTCOME_FIELDS = {
  OUTCOME: {
    label: 'label',
    key: 'key',
    disable: 'disable',
  },
};
