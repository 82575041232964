import get from 'lodash/get';
import { createSelector } from 'reselect';

export const consultationTypeSelector = (state) =>
  get(state, 'domain.consultationType', {});

export const getConsultationType = createSelector(
  consultationTypeSelector,
  (results) => get(results, 'view.consultationTypeInfo')
);

export const getConsultationTypes = createSelector(
  consultationTypeSelector,
  (results) => get(results, 'list')
);

export const getConsultationTypeConfigurations = createSelector(
  consultationTypeSelector,
  (results) => get(results, 'view.consultationTypeConfigurations')
);

export const isFetching = createSelector(consultationTypeSelector, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(
  consultationTypeSelector,
  (results) => get(results, 'isSubmitting')
);

export const isFetchingConfiguration = createSelector(
  consultationTypeSelector,
  (results) => get(results, 'isFetchingConfiguration')
);
export const isSubmittingConfiguration = createSelector(
  consultationTypeSelector,
  (results) => get(results, 'isSubmittingConfiguration')
);
