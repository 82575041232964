import { labActionTypes } from 'domain/lab';
import { prescriptionActionTypes } from 'domain/prescription';
import {
  OUTCOME_OPTIONS,
  OUTCOME_SUBMISSION_STATUS,
} from 'domain/outcome/constants';

import * as types from './actionTypes';

const initialState = {
  view: {},
  isFetching: false,
  isSubmitting: false,
  isSubmittingConsultationMembers: false,
  isDeletingConsultationMember: false,
  isUpdatingConsultationPatient: false,
  isSubmittingConsultationPatientOutcome: false,
  isUpdatingConsultationPatientOutcome: false,
  isDeletingConsultationPatientOutcome: false,
  isSubmittingConsultationPrescriptions: false,
  isSubmittingConsultationLabTests: false,
};

const consultationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONSULTATION_RECEIVED: {
      return {
        ...state,
        view: { ...(action.consultation || {}) },
      };
    }
    case types.SET_FETCHING_CONSULTATION: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION_MEMBERS: {
      return {
        ...state,
        isSubmittingConsultationMembers: action.isSubmittingConsultationMembers,
      };
    }
    case types.CONSULTATION_MEMBERS_UPDATED: {
      return {
        ...state,
        view: {
          ...state.view,
          // Sort patients by id (the order they were added in),
          // earliest first
          consultationPatients: [...action.consultationMembers].sort(
            (patientA, patientB) => patientA.id - patientB.id
          ),
        },
      };
    }
    case types.CLEAR_CONSULTATION: {
      return {
        ...state,
        view: {},
        isFetching: false,
      };
    }

    case types.CONSULTATION_PATIENT_UPDATED: {
      const patients = [...(state.view?.consultationPatients || [])];
      const patientIdx = patients.findIndex(
        (patient) => patient.id === action.patientId
      );

      patients[patientIdx] = {
        ...patients[patientIdx], // In case action.patient only includes partial payload
        ...action.patient,
      };

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: patients,
        },
      };
    }

    case types.CONSULTATION_PATIENT_OUTCOME_CREATED: {
      const patients = [...(state.view?.consultationPatients || [])];
      const patientIdx = patients.findIndex(
        (patient) => patient.id === action.patientId
      );

      patients[patientIdx].output = action.outcomes;

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: patients,
        },
      };
    }

    case types.CONSULTATION_PATIENT_OUTCOME_UPDATED: {
      const patients = [...(state.view?.consultationPatients || [])];
      const patientIdx = patients.findIndex(
        (patient) => patient.id === action.patientId
      );

      patients[patientIdx].output = action.outcomes;

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: patients,
        },
      };
    }

    case types.CONSULTATION_PATIENT_OUTCOME_DELETED: {
      const patients = [...(state.view?.consultationPatients || [])];
      const patientIdx = patients.findIndex(
        (patient) => patient.id === action.patientId
      );
      const outcomeIdx = patients[patientIdx].output.findIndex(
        (outcome) => outcome.id === action.outcomeId
      );

      patients[patientIdx].output.splice(outcomeIdx, 1);

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: patients,
        },
      };
    }

    case types.CONSULTATION_MEMBER_DELETED: {
      const consultationMembers = [...state.view.consultationPatients];
      const consultationMembersUpdated = consultationMembers.filter(
        (member) => member.userId !== action.memberId
      );

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: consultationMembersUpdated,
        },
      };
    }

    case types.SET_DELETING_CONSULTATION_MEMBER: {
      return {
        ...state,
        isDeletingConsultationMember: action.isDeletingConsultationMember,
      };
    }
    case types.SET_UPDATING_CONSULTATION_PATIENT: {
      return {
        ...state,
        isUpdatingConsultationPatient: action.isUpdatingConsultationPatient,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION_PATIENT_OUTCOME: {
      return {
        ...state,
        isSubmittingConsultationPatientOutcome:
          action.isSubmittingConsultationPatientOutcome,
      };
    }
    case types.SET_UPDATING_CONSULTATION_PATIENT_OUTCOME: {
      return {
        ...state,
        isUpdatingConsultationPatientOutcome:
          action.isUpdatingConsultationPatientOutcome,
      };
    }
    case types.SET_DELETING_CONSULTATION_PATIENT_OUTCOME: {
      return {
        ...state,
        isDeletingConsultationPatientOutcome:
          action.isDeletingConsultationPatientOutcome,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION_PRESCRIPTIONS: {
      return {
        ...state,
        isSubmittingConsultationPrescriptions:
          action.isSubmittingConsultationPrescriptions,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION_LAB_TESTS: {
      return {
        ...state,
        isSubmittingConsultationLabTests:
          action.isSubmittingConsultationLabTests,
      };
    }
    case types.CONSULTATION_PRESCRIPTIONS_SUBMITTED:
    case prescriptionActionTypes.ALL_PRESCRIPTIONS_SUBMITED: {
      let patients = [...(state.view?.consultationPatients || [])];

      patients = patients.map((patient) => {
        return {
          ...patient,
          output: patient.output.map((outcome) => ({
            ...outcome,
            // In order for the UI to know that this outcome is submitted and show a confirmed/green tab
            //  Until the consultation is visited again
            ...(outcome.referenceType === OUTCOME_OPTIONS.Prescription.key
              ? {
                  submissionStatus: OUTCOME_SUBMISSION_STATUS.SUBMITTED.key,
                }
              : {}),
          })),
        };
      });

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: patients,
        },
      };
    }
    case types.CONSULTATION_LAB_TESTS_SUBMITTED:
    case labActionTypes.PATIENT_E_LAB_REQUESTS_SUBMITTED: {
      let patients = [...(state.view?.consultationPatients || [])];

      patients = patients.map((patient) => {
        return {
          ...patient,
          output: patient.output.map((outcome) => ({
            ...outcome,
            // In order for the UI to know that this outcome is submitted and show a confirmed/green tab
            //  Until the consultation is visited again
            ...(outcome.referenceType === OUTCOME_OPTIONS.LabTestRequest.key
              ? {
                  submissionStatus: OUTCOME_SUBMISSION_STATUS.SUBMITTED.key,
                }
              : {}),
          })),
        };
      });

      return {
        ...state,
        view: {
          ...state.view,
          consultationPatients: patients,
        },
      };
    }
    default:
      return state;
  }
};

export default consultationReducer;
