import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { userGetters, userSelectors } from 'domain/user';
import { insuranceProviderGetters } from 'domain/insuranceProvider';
import {
  orderActions as actions,
  orderSelectors as selectors,
} from 'domain/order';

import LineItemList from './LineItemList';

const mapStateToProps = (state, ownProps) => {
  const { isFetching } = ownProps;

  const user = userSelectors.getUser(state);
  const isUpdatingLineItems = selectors.isUpdatingLineItems(state);

  const insuranceProvider = userGetters.getInsuranceProvider(user);
  const defaultInsuranceDiscount = Number(
    insuranceProviderGetters.getDiscount(insuranceProvider) || 0
  );

  return {
    ...ownProps,
    isUpdatingLineItems,
    defaultInsuranceDiscount,
    isFetching: isFetching || isEmpty(user),
  };
};

const mapDispatchToProps = (dispatch) => ({
  addLineItem: (orderId, payload) =>
    dispatch(actions.addLineItem(orderId, payload)),
  updateLineItem: (orderId, id, payload) =>
    dispatch(actions.updateLineItem(orderId, id, payload)),
  deleteLineItem: (orderId, id) =>
    dispatch(actions.deleteLineItem(orderId, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LineItemList);
