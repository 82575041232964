import keys from 'lodash/keys';
import React, { useRef, forwardRef, useImperativeHandle } from 'react';

import { Form, Controller } from 'design-system/form';
import { userConstants, userGetters } from 'domain/user';
import { Grid, TextField, FormField, Select, MenuItem } from 'design-system';
import {
  memberConstants as constants,
  memberGetters as getters,
} from 'domain/user/member';

const { FIELDS } = constants;
const { FIELDS: USER_FIELDS, MEMBER_RELATIONSHIP } = userConstants;

const MemberForm = forwardRef(
  (
    {
      // Props
      onSubmit,
      defaultValues = {},
    },
    ref
  ) => {
    const formRef = useRef();

    useImperativeHandle(ref, () => ({
      submit() {
        formRef.current.submit();
      },
    }));

    return (
      <Form ref={formRef} onSubmit={onSubmit}>
        {({ control }) => (
          <Grid container column rowSpacing={2}>
            <Grid item>
              <FormField
                required
                label="FULL NAME"
                field={
                  <Controller
                    name={USER_FIELDS.FULL_NAME.name}
                    control={control}
                    defaultValue={userGetters.getFullName(defaultValues)}
                    rules={{
                      validate: (value) =>
                        Boolean(value?.trim()) || 'Full name is required',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="medium"
                        value={value}
                        variant="outlined"
                        onChange={onChange}
                        error={Boolean(error)}
                        placeholder="Full name.."
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
            </Grid>
            <Grid item>
              <FormField
                required
                label="RELATIONSHIP"
                field={
                  <Controller
                    name={FIELDS.RELATIONSHIP_WITH_OWNER.name}
                    control={control}
                    defaultValue={getters.getRelationshipWithOwner(
                      defaultValues
                    )}
                    rules={{
                      required: 'Relationship is required', // Add required validation rule
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <Select
                        fullWidth
                        soak="light"
                        size="medium"
                        value={value}
                        placeholder="Enter Relationship.."
                        onChange={onChange}
                        error={Boolean(error)}
                        helperText={error?.message ?? null}
                        renderValue={(val) =>
                          MEMBER_RELATIONSHIP[val]?.label || val
                        }
                      >
                        {keys(MEMBER_RELATIONSHIP).map((type) => (
                          <MenuItem key={type} value={type}>
                            {MEMBER_RELATIONSHIP[type].label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Form>
    );
  }
);

export default MemberForm;
