import { all, put, take, select, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import { labActions } from 'domain/lab';
import { navigate } from 'routing/utils';
import { userActions } from 'domain/user';
import { memberActions } from 'domain/user/member';
import { REFERENCE_TYPES } from 'constants/general';
import { orderModelActionTypes } from 'model/order';
import { doctorNotesActions } from 'domain/doctorNotes';
import { prescriptionActions } from 'domain/prescription';
import {
  consultationModelActions,
  consultationModelActionTypes,
} from 'model/consultation';
import {
  FILE_TYPES,
  fileActions,
  fileGetters,
  fileActionTypes,
} from 'domain/file';

import getters from './getters';
import { FIELDS } from './constants';
import * as actions from './actions';
import * as types from './actionTypes';
import * as selectors from './selectors';

const currentState = (state) => state;

export function* getConsultationById({ id }) {
  yield put(actions.setFetchingConsultation(true));
  yield put(consultationModelActions.fetchConsultationById(id));
}

export function* consultationReceived({ consultation, id }) {
  const updatedConsultation = {
    ...consultation,
    // Sort patients by id (the order they were added in),
    // earliest first
    [FIELDS.PATIENTS.name]: consultation[FIELDS.PATIENTS.name].sort(
      (patientA, patientB) => patientA.id - patientB.id
    ),
  };

  yield put(labActions.getLabRequestByConsultationId(id));

  yield put(actions.consultationReceived(updatedConsultation, id));
  yield put(actions.setFetchingConsultation(false));
}

export function* clearConsultation() {
  // Clear owner's user and health profiles
  yield put(userActions.clearUser());
  yield put(userActions.clearPatientHealthInfo());

  // Clear all doctor notes, patients E-Lab requests and patients prescriptions
  yield put(doctorNotesActions.clearDoctorNotes());
  yield put(labActions.clearPatientsELabRequests());
  yield put(prescriptionActions.clearPatientsPrescriptions());

  // Clear all members' user and health profiles
  yield put(memberActions.membersReceived());
  yield put(memberActions.basicMembersReceived());
  yield put(memberActions.membersHealthProfilesReceived());
}

export function* updateConsultation({
  id,
  payload,
  successCb = () => {},
  failureCb = () => {},
}) {
  yield put(actions.setSubmittingConsultation(true));
  yield put(consultationModelActions.updateConsultation(id, payload));

  const { ok, response } = yield take(
    consultationModelActionTypes.CONSULTATION_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationReceived(response, id));
    successCb();

    Message.success('Consultation updated successfully');
  } else {
    failureCb();
  }

  yield put(actions.setSubmittingConsultation(false));
}

export function* addConsultationLabTest({ id }) {
  yield put(consultationModelActions.addConsultationLabTest(id));
}

export function* addConsultationMeetingStarted({ id, action }) {
  yield put(consultationModelActions.addConsultationMeetingStarted(id, action));
}

export function* createConsultation({ payload }) {
  const userId = getters.getUserId(payload);
  const rawFiles = getters.getDocuments(payload);

  yield put(actions.setSubmittingConsultation(true));
  yield put(fileActions.clearFiles(REFERENCE_TYPES.CONSULTATION));
  yield put(
    fileActions.createFiles(
      rawFiles,
      FILE_TYPES.DOCUMENTS,
      userId,
      REFERENCE_TYPES.CONSULTATION
    )
  );

  const { files } = yield take(fileActionTypes.FILES_CREATED);

  const documents = files.map((file) => fileGetters.getId(file));
  const referenceDocuments = getters
    .getReferenceDocuments(payload)
    .map((file) => file.id);
  const updatedPayload = {
    ...payload,
    [FIELDS.DOCUMENTS.name]: [...referenceDocuments, ...documents],
  };
  yield put(consultationModelActions.createConsultation(updatedPayload));
}

export function* consultationCreationResponded({ status, response }) {
  yield put(actions.setSubmittingConsultation(false));

  if (status && response) {
    const { id } = response;

    navigate(`/consultation/${id}`);
    yield put(userActions.clearUser());
    // Why settimout? When we change the route using location.href,
    // the context changes and hides the Message, unless we add it to event loop
    // Only then, it will be executed after the routing is done
    setTimeout(() => {
      Message.success('Consultation created successfully');
    }, 0);
  }
}

export function* deleteConsultationMember({ consultationId, memberId }) {
  yield put(actions.setDeletingConsultationMember(true));
  yield put(
    consultationModelActions.deleteConsultationMember(consultationId, memberId)
  );

  const { ok } = yield take(
    consultationModelActionTypes.CONSULTATION_MEMBER_DELETE_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationMemberDeleted(memberId));
    Message.info('Member has been deleted from consultation successfully');
  }

  yield put(actions.setDeletingConsultationMember(false));
}

export function* prescriptionsCreated({ order }) {
  const state = yield select(currentState);
  const currentConsultation = selectors.getConsultation(state);

  yield put(
    actions.consultationReceived(
      {
        ...currentConsultation,
        [FIELDS.ASSOCIATED_ORDER_IDS.name]: [
          ...(getters.getAssociatedOrderIds(currentConsultation) || []),
          order.id,
        ],
      },
      getters.getId(currentConsultation)
    )
  );
}

export function* updateConsultationMembers({ consultationId, memberIds }) {
  yield put(actions.setSubmittingConsultationMembers(true));
  yield put(
    consultationModelActions.updateConsultationMembers(
      consultationId,
      memberIds
    )
  );

  const { ok, response } = yield take(
    consultationModelActionTypes.CONSULTATION_MEMBERS_UPDATING_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationMembersUpdated(response));
    Message.success('Patient list has been updated successfully');
  }

  yield put(actions.setSubmittingConsultationMembers(false));
}

export function* updateConsultationPatient({
  consultationId,
  patientId,
  payload,
  successCallback,
}) {
  yield put(actions.setUpdatingConsultationPatient(true));
  yield put(
    consultationModelActions.updateConsultationPatient(
      consultationId,
      patientId,
      payload
    )
  );

  const { ok, response } = yield take(
    consultationModelActionTypes.CONSULTATION_PATIENT_UPDATE_RESPONDED
  );

  if (ok) {
    // Response is the whole payload of a patient (patient info + outcomes, ..etc)
    yield put(actions.consultationPatientUpdated(patientId, response));
    Message.success('Outcome has been updated successfully');

    successCallback?.(response);
  }

  yield put(actions.setUpdatingConsultationPatient(false));
}

export function* createConsultationPatientOutcome({
  consultationId,
  patientId,
  payload,
}) {
  yield put(actions.setSubmittingConsultationPatientOutcome(true));
  yield put(
    consultationModelActions.createConsultationPatientOutcome(
      consultationId,
      patientId,
      payload
    )
  );

  const { ok, response } = yield take(
    consultationModelActionTypes.CONSULTATION_PATIENT_OUTCOME_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationPatientOutcomeCreated(patientId, response));
  }

  yield put(actions.setSubmittingConsultationPatientOutcome(false));
}

export function* updateConsultationPatientOutcome({
  consultationId,
  patientId,
  outcomeId,
  payload,
}) {
  yield put(actions.setUpdatingConsultationPatientOutcome(true));
  yield put(
    consultationModelActions.updateConsultationPatientOutcome(
      consultationId,
      patientId,
      outcomeId,
      payload
    )
  );

  const { ok, response } = yield take(
    consultationModelActionTypes.CONSULTATION_PATIENT_OUTCOME_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationPatientOutcomeUpdated(patientId, response));
  }

  yield put(actions.setUpdatingConsultationPatientOutcome(false));
}

export function* deleteConsultationPatientOutcome({
  consultationId,
  patientId,
  outcomeId,
}) {
  yield put(actions.setDeletingConsultationPatientOutcome(false));
  yield put(
    consultationModelActions.deleteConsultationPatientOutcome(
      consultationId,
      patientId,
      outcomeId
    )
  );

  const { ok } = yield take(
    consultationModelActionTypes.CONSULTATION_PATIENT_OUTCOME_DELETE_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationPatientOutcomeDeleted(patientId, outcomeId));
  }

  yield put(actions.setDeletingConsultationPatientOutcome(false));
}

export function* submitConsultationPrescriptions({ consultationId }) {
  yield put(actions.setSubmittingConsultationPrescriptions(true));
  yield put(
    consultationModelActions.submitConsultationPrescriptions(consultationId)
  );

  const { ok } = yield take(
    consultationModelActionTypes.CONSULTATION_PRESCRIPTIONS_SUBMIT_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationPrescriptionsSubmitted());
  }

  yield put(actions.setSubmittingConsultationPrescriptions(false));
}

export function* submitConsultationLabTests({ consultationId }) {
  yield put(actions.setSubmittingConsultationLabTests(true));
  yield put(
    consultationModelActions.submitConsultationLabTests(consultationId)
  );

  const { ok } = yield take(
    consultationModelActionTypes.CONSULTATION_LAB_TESTS_SUBMIT_RESPONDED
  );

  if (ok) {
    yield put(actions.consultationLabTestsSubmitted());
  }
  yield put(actions.setSubmittingConsultationLabTests(false));
}

export default function* consultationSaga() {
  yield all([
    takeLatest(types.CLEAR_CONSULTATION, clearConsultation),
    takeLatest(types.UPDATE_CONSULTATION, updateConsultation),
    takeLatest(types.CREATE_CONSULTATION, createConsultation),
    takeLatest(types.GET_CONSULTATION_BY_ID, getConsultationById),
    takeLatest(types.ADD_CONSULTATION_LAB_TEST, addConsultationLabTest),
    takeLatest(
      types.ADD_CONSULTATION_MEETING_STARTED,
      addConsultationMeetingStarted
    ),
    takeLatest(
      orderModelActionTypes.PRESCRIPTIONS_CREATED,
      prescriptionsCreated
    ),
    takeLatest(
      consultationModelActionTypes.CONSULTATION_RECEIVED,
      consultationReceived
    ),
    takeLatest(
      consultationModelActionTypes.CONSULTATION_CREATION_RESPONDED,
      consultationCreationResponded
    ),
    takeLatest(types.UPDATE_CONSULTATION_MEMBERS, updateConsultationMembers),
    takeLatest(types.DELETE_CONSULTATION_MEMBER, deleteConsultationMember),
    takeLatest(types.UPDATE_CONSULTATION_PATIENT, updateConsultationPatient),
    takeLatest(
      types.CREATE_CONSULTATION_PATIENT_OUTCOME,
      createConsultationPatientOutcome
    ),
    takeLatest(
      types.UPDATE_CONSULTATION_PATIENT_OUTCOME,
      updateConsultationPatientOutcome
    ),
    takeLatest(
      types.DELETE_CONSULTATION_PATIENT_OUTCOME,
      deleteConsultationPatientOutcome
    ),
    takeLatest(
      types.SUBMIT_CONSULTATION_PRESCRIPTIONS,
      submitConsultationPrescriptions
    ),
    takeLatest(types.SUBMIT_CONSULTATION_LAB_TESTS, submitConsultationLabTests),
  ]);
}
