import React, { useRef, useState, useEffect } from 'react';

import { Form, Controller } from 'design-system/form';
import { LocationSearchInput, MapLinkSearchInput } from 'core/location';
import { userConstants, userAddressGetters as getters } from 'domain/user';
import {
  Grid,
  Dialog,
  Button,
  Select,
  Message,
  Divider,
  MenuItem,
  TextField,
  FormField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

const { ADDRESS_FIELDS } = userConstants;

const UserAddressFormDialog = ({
  open,
  userId,
  onClose,
  onConfirm,
  addresses,
  isFetching,
  value: address,
  fetchAddresses,
}) => {
  const formRef = useRef();
  const [isSaving, setSaving] = useState();
  const [isDisabled, setDisabled] = useState();
  const [userAddress, setUserAddress] = useState();

  useEffect(() => {
    setSaving(false);
  }, []);

  useEffect(() => {
    const latitude = getters.getLatitude(address);
    const longitude = getters.getLongitude(address);

    setDisabled(!(longitude && latitude));
  }, [address]);

  useEffect(() => {
    if (userId) {
      fetchAddresses(userId);
    }
  }, [userId]);

  const onClosed = () => {
    onClose();
  };

  const onSubmitted = (data) => {
    const joinedAddress = [
      data.apartment,
      data.building,
      data.street,
      data.area,
      data.city,
      data.country,
    ]
      .filter(Boolean)
      .join(' - ');

    setSaving(true);
    onConfirm({
      ...(data?.sourceAddress || address || {}),
      ...data,
      formattedAddress: joinedAddress,
      searchQuery: encodeURIComponent(joinedAddress),
    });
  };

  const populateAddressFields = (setValue, addr) => {
    const apartment = getters.getApartment(addr) || '';
    const building = getters.getBuilding(addr) || '';
    const street = getters.getStreet(addr) || '';
    const area = getters.getArea(addr) || '';
    const city = getters.getCity(addr) || '';
    const country = getters.getCountry(addr) || '';
    const placeId = getters.getPlaceId(addr) || '';
    const latitude = getters.getLatitude(addr) || '';
    const longitude = getters.getLongitude(addr) || '';
    const referenceMapLink = getters.getReferenceMapLink(addr) || '';
    const formattedAddress = [apartment, building, street, area, city, country]
      .filter(Boolean)
      .join(' - ');

    setValue('apartment', apartment);
    setValue('building', building);
    setValue('street', street);
    setValue('area', area);
    setValue('city', city);
    setValue('country', country);
    setValue('placeId', placeId);
    setValue('latitude', latitude);
    setValue('longitude', longitude);
    setValue('referenceMapLink', referenceMapLink);
    setValue('formattedAddress', formattedAddress);
    setValue('sourceAddress', addr);

    setDisabled(!(longitude && latitude));
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={onClosed}
      aria-labelledby="user-address-dialog"
    >
      <DialogTitle id="user-address-dialog" onClose={onClosed}>
        Order Delivery Address
      </DialogTitle>
      <DialogContent dividers>
        <Form ref={formRef} onSubmit={onSubmitted}>
          {({ setValue, control }) => (
            <Grid container column rowSpacing={2} sx={{ pt: 1 }}>
              {/* Addresses List */}
              <Grid item container column rowSpacing={1}>
                <Grid item>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 140 }}
                  >
                    SELECT FROM PATIENT ADDRESSES
                  </Typography>
                </Grid>
                <Grid item>
                  <Select
                    fullWidth
                    size="medium"
                    value={userAddress}
                    loading={isFetching}
                    renderValue={(addr) => getters.getFormattedAddress(addr)}
                    placeholder="Select address entered by patient on app"
                    onChange={(e) => {
                      const addr = e.target.value;

                      setUserAddress(addr);
                      populateAddressFields(setValue, addr);
                    }}
                  >
                    {addresses.map((addr) => (
                      <MenuItem value={addr} key={getters.getId(addr)}>
                        {getters.getFormattedAddress(addr)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item>
                <Divider>
                  <Typography variant="p4">OR</Typography>
                </Divider>
              </Grid>
              {/* SEARCH LOCATION */}
              <Grid item container column rowSpacing={1}>
                <Grid item>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 140 }}
                  >
                    SEARCH LOCATION
                  </Typography>
                </Grid>
                <Grid item>
                  <LocationSearchInput
                    addressFields={ADDRESS_FIELDS}
                    onChange={(location) => {
                      populateAddressFields(setValue, location);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Divider>
                  <Typography variant="p4">OR</Typography>
                </Divider>
              </Grid>
              {/* MAP LINK */}
              <Grid item container column rowSpacing={1}>
                <FormField
                  label="MAP LINK"
                  field={
                    <Controller
                      name="referenceMapLink"
                      control={control}
                      defaultValue={getters.getReferenceMapLink(address) || ''}
                      render={({ field: { onChange, value } }) => (
                        <MapLinkSearchInput
                          defaultValue={value}
                          fullWidth
                          addressFields={ADDRESS_FIELDS}
                          onChange={(location, url) => {
                            onChange(url);
                            populateAddressFields(setValue, {
                              ...location,
                              referenceMapLink: url,
                            });
                          }}
                        />
                      )}
                    />
                  }
                />
              </Grid>
              <Grid item sx={{ mb: 2 }}>
                <Divider>
                  <Typography variant="p4">Delivery Address Details</Typography>
                </Divider>
              </Grid>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <FormField
                    required
                    label="APARTMENT/VILLA"
                    field={
                      <Controller
                        name="apartment"
                        control={control}
                        defaultValue={getters.getApartment(address) || ''}
                        rules={{
                          required: 'Apartment/Villa is required',
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                            placeholder="Apartment No/Villa/Office.."
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    label="BUILDING"
                    field={
                      <Controller
                        name="building"
                        control={control}
                        defaultValue={getters.getBuilding(address) || ''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            onChange={onChange}
                            placeholder="Building.."
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    label="STREET"
                    field={
                      <Controller
                        name="street"
                        control={control}
                        defaultValue={getters.getStreet(address) || ''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            onChange={onChange}
                            placeholder="Street.."
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    label="AREA"
                    field={
                      <Controller
                        name="area"
                        control={control}
                        defaultValue={getters.getArea(address) || ''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            onChange={onChange}
                            placeholder="Area.."
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    label="CITY"
                    field={
                      <Controller
                        name="city"
                        control={control}
                        defaultValue={getters.getCity(address) || ''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            onChange={onChange}
                            placeholder="City.."
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormField
                    label="COUNTRY"
                    field={
                      <Controller
                        name="country"
                        control={control}
                        defaultValue={getters.getCountry(address) || ''}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            onChange={onChange}
                            placeholder="Country.."
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          columnSpacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          {isSaving && (
            <Grid item>
              <Typography variant="p3">Saving...</Typography>
            </Grid>
          )}
          <Grid item>
            <Button
              color="primary"
              variant="text"
              disabled={isSaving}
              onClick={() => {
                const addr = formRef?.current?.getMethods().watch() || address;
                const addressLink = getters.getAddressLink(addr);

                if (addressLink) {
                  window.open(addressLink, '_blank');
                } else {
                  Message.error('Address is incorrect!');
                }
              }}
            >
              Validate Address
            </Button>
          </Grid>
          <Grid item sx={{ pr: 2 }}>
            <Button
              color="primary"
              variant="contained"
              disabled={isDisabled}
              spinning={isSaving}
              onClick={() => {
                formRef.current.submit();
              }}
            >
              Confirm Delivery Address
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default UserAddressFormDialog;
