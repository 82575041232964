import { all, put, takeLatest, take, call } from 'redux-saga/effects';
import { takeSequential } from 'core/saga';
import { Message } from 'design-system';
import { medicineModelActions, medicineModelActionTypes } from 'model/medicine';

import * as actions from './actions';
import * as types from './actionTypes';
import { FIELDS } from './constants';

export function* createMedicine({ payload }) {
  yield put(actions.setSubmittingMedicine(true));
  yield put(medicineModelActions.createMedicine(payload));
}

export function* medicineCreationResponded({ response, ok }) {
  if (ok) {
    Message.success('Medicine has been added successfully');
  } else {
    Message.error(
      `Medicine failed to be added. Please keep the page open and report the issue to the team.\n${response}`
    );
  }

  yield put(actions.setSubmittingMedicine(false));
}

export function* medicineFetchResponded({ ok, response: medicine }) {
  if (ok) {
    yield put(actions.medicineReceived(medicine));
  }
  yield put(actions.setFetching(false));
}

export function* fetchMedicine({ id }) {
  yield put(actions.medicineReceived());
  yield put(actions.setFetching(true));
  yield put(medicineModelActions.fetchMedicine(id));

  const action = yield take(medicineModelActionTypes.MEDICINE_FETCH_RESPONDED);
  yield call(medicineFetchResponded, action);
}

export function* patchMedicine({ id, payload }) {
  yield put(actions.setSubmittingMedicine(true));
  yield put(
    medicineModelActions.patchMedicine(id, {
      [FIELDS.ID.name]: id,
      ...payload,
    })
  );

  yield take(medicineModelActionTypes.MEDICINE_UPDATE_RESPONDED);
  yield put(actions.setSubmittingMedicine(false));

  Message.success('Medicine has been updated successfully');
}

export default function* medicineSaga() {
  yield all([
    takeSequential(types.PATCH_MEDICINE, patchMedicine),
    takeLatest(types.CREATE_MEDICINE, createMedicine),
    takeLatest(
      medicineModelActionTypes.MEDICINE_CREATION_RESPONDED,
      medicineCreationResponded
    ),
    takeLatest(types.FETCH_MEDICINE, fetchMedicine),
  ]);
}
