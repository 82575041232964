import React, { useState, useEffect } from 'react';

import FileUpload from 'core/file/FileUpload';

const ConsultationFilePreview = ({ customIcon, files, isLoading }) => {
  const [consultationFiles, setConsultationFiles] = useState(files);

  useEffect(() => {
    setConsultationFiles(files);
  }, [files]);

  return (
    <FileUpload
      size="small"
      multiple
      files={consultationFiles}
      loading={isLoading}
      config={{
        readonly: true,
      }}
      customIcon={customIcon}
      onChange={(documents) => {
        setConsultationFiles(documents);
      }}
    />
  );
};

export default ConsultationFilePreview;
