import React, { useState } from 'react';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { userGetters } from 'domain/user';
import { memberGetters as getters } from 'domain/user/member';
import {
  If,
  Else,
  Grid,
  Link,
  IconButton,
  PopConfirm,
  Typography,
  Conditionally,
} from 'design-system';

import { SIZE_CONFIG } from '../../constants';

const MemberRow = ({
  member,
  readonly,
  isDeleting,
  deleteMember,
  isSubmitting,
  undeleteMember,
  size = 'medium',
  deletable,
  disabled = {},
}) => {
  const disable = {
    delete: false,
    ...disabled,
  };

  const [popConfirmAnchor, setPopConfirmAnchor] = useState(null);

  const showPopConfirm = (event) => {
    setPopConfirmAnchor(event.currentTarget);
  };

  const closePopConfirm = () => {
    setPopConfirmAnchor(null);
  };

  return (
    <>
      <Grid
        container
        flexWrap="nowrap"
        alignItems="center"
        columnSpacing={1}
        loading={isDeleting || isSubmitting}
      >
        <Grid item>
          <Conditionally>
            <If condition={getters.isDeletedFromOwnerAccount(member)}>
              <PersonOffOutlinedIcon color="standard" />
            </If>
            <Else>
              <PersonOutlinedIcon color="standard" />
            </Else>
          </Conditionally>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item pb={0.5}>
              <Link
                href={`/user/${member.userId || userGetters.getId(member)}`}
                target="_blank"
                color={
                  getters.isDeletedFromOwnerAccount(member)
                    ? 'error'
                    : 'primary'
                }
                sx={{
                  ...(getters.isDeletedFromOwnerAccount(member)
                    ? {
                        textDecoration: 'underline',
                        textUnderlineOffset: '-35%',
                        textDecorationSkipInk: 'none',
                      }
                    : {}),
                }}
              >
                <Typography variant={SIZE_CONFIG.typographyVariant[size]}>
                  {userGetters.getFullName(member) || 'N/A'}
                </Typography>
              </Link>
            </Grid>
            {!readonly && (
              <Grid item>
                <Conditionally>
                  <If condition={getters.isDeletedFromOwnerAccount(member)}>
                    <IconButton
                      onClick={() => {
                        undeleteMember();
                      }}
                    >
                      <VisibilityOffOutlinedIcon color="error" />
                    </IconButton>
                  </If>
                  <Else>
                    <IconButton
                      onClick={(event) => {
                        showPopConfirm(event);
                      }}
                    >
                      <VisibilityOutlinedIcon color="primary" />
                    </IconButton>
                  </Else>
                </Conditionally>
              </Grid>
            )}

            {deletable && (
              <Grid item>
                <IconButton
                  disabled={disable.delete}
                  onClick={(event) => {
                    showPopConfirm(event);
                  }}
                  sx={{ p: 0 }}
                >
                  <RemoveCircleOutlineIcon
                    color={disable.delete ? 'standard' : 'error'}
                  />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <PopConfirm
        primaryButtonTitle="Cancel"
        secondaryButtonTitle={deletable ? 'Delete ' : 'Hide '}
        anchorEl={popConfirmAnchor}
        onOk={() => closePopConfirm()}
        open={Boolean(popConfirmAnchor)}
        onCancel={() => {
          closePopConfirm();
          deleteMember();
        }}
        id={popConfirmAnchor ? 'delete-member-confirm' : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        Are you sure you want to {deletable ? 'delete ' : 'hide '}
        {userGetters.getFullName(member)} from the
        {deletable ? ' consultation ' : ' app '}?
      </PopConfirm>
    </>
  );
};

export default MemberRow;
