import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as USER_MANAGEMENT } from './constants';

export const DEACTIVATE_ACCOUNT = `${MODEL}/${USER_MANAGEMENT}/DEACTIVATE_ACCOUNT`;
export const CREATE_ALMAOS_USER = `${MODEL}/${USER_MANAGEMENT}/CREATE_ALMAOS_USER`;
export const RESET_PASSWORD = `${MODEL}/${USER_MANAGEMENT}/RESET_PASSWORD`;
export const GET_ALMAOS_USER = `${MODEL}/${USER_MANAGEMENT}/GET_ALMAOS_USER`;
export const UPDATE_ALMAOS_USER = `${MODEL}/${USER_MANAGEMENT}/UPDATE_ALMAOS_USER`;

// Response
export const ALMAOS_USER_CREATED = `${MODEL}/${USER_MANAGEMENT}/ALMAOS_USER_CREATED`;
export const ALMAOS_USER_CREATION_RESPONDED = `${MODEL}/${USER_MANAGEMENT}/ALMAOS_USER_CREATION_RESPONDED`;
export const ALMAOS_USER_DEACTIVATE_RESPONDED = `${MODEL}/${USER_MANAGEMENT}/ALMAOS_USER_DEACTIVATE_RESPONDED`;
export const PASSWORD_RESET_RESPONDED = `${MODEL}/${USER_MANAGEMENT}/PASSWORD_RESET_RESPONDED`;
export const ALMAOS_USER_FETCHING_RESPONDED = `${MODEL}/${USER_MANAGEMENT}/ALMAOS_USER_FETCHING_RESPONDED`;
export const ALMAOS_USER_UPDATE_RESPONDED = `${MODEL}/${USER_MANAGEMENT}/ALMAOS_USER_UPDATE_RESPONDED`;
