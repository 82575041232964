import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Grid, IconButton, Typography } from 'design-system';

const PdfPageNavigator = ({ filePageCount, page, setPage }) => {
  if (filePageCount <= 1) {
    return null;
  }

  return (
    <Grid container alignItems="center" justifyContent="center" sx={{ my: 1 }}>
      {filePageCount > 1 && (
        <Grid item>
          <IconButton
            disabled={page === 1}
            onClick={() => {
              if (page === 1) {
                return;
              }
              setPage(page - 1);
            }}
          >
            <ArrowBackIosNewIcon fontSize="large" />
          </IconButton>
        </Grid>
      )}
      <Grid item>
        <Typography variant="p1">
          Page {page} of {filePageCount}
        </Typography>
      </Grid>
      {filePageCount > 1 && (
        <Grid item>
          <IconButton
            disabled={page === filePageCount}
            onClick={() => {
              if (page === filePageCount) {
                return;
              }
              setPage(page + 1);
            }}
          >
            <ArrowForwardIosIcon fontSize="large" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default PdfPageNavigator;
