import { connect } from 'react-redux';

import {
  meetingActions as actions,
  meetingSelectors as selectors,
} from 'domain/meeting';

import CreateMeetingDialog from './CreateMeetingDialog';

const mapStateToProps = (state) => {
  const isSubmitting = selectors.isSubmitting(state);

  return {
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createMeeting: (payload, callback) =>
    dispatch(actions.createMeeting(payload, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMeetingDialog);
