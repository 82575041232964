/* eslint-disable import/prefer-default-export */
export const SIZE_CONFIG = {
  typographyVariant: {
    small: 'p4',
    medium: 'p3',
  },
  rowHeight: {
    small: 4,
    medium: 5,
  },
  innerRowHeight: {
    small: 3,
    medium: 4,
  },
};
