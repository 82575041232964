import { connect } from 'react-redux';

import { memberSelectors } from 'domain/user/member';
import { userActions, userSelectors } from 'domain/user';

import OwnerInfo from './OwnerInfo';

const mapStateToProps = (state, ownProps) => {
  const owner = ownProps?.ownerId
    ? userSelectors.getUser(state)
    : ownProps?.owner;
  const isFetching = ownProps?.ownerId
    ? userSelectors.isFetchingUser(state)
    : memberSelectors.isFetchingMembers(state);

  return {
    owner,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOwner: (userId) => dispatch(userActions.fetchUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OwnerInfo);
