import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { userActions, userSelectors } from 'domain/user';
import {
  userManagementActions,
  userManagementSelectors,
} from 'domain/userManagement';

import NewUserCredentials from './NewUserCredentials';

const mapStateToProps = (state) => {
  const user = userSelectors.getUser(state);
  const isFetchingUser = userSelectors.isFetchingUser(state);
  const isSubmitting = userManagementSelectors.isSubmittingAlmaosUser(state);

  return {
    user,
    isFetchingUser,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch(userActions.clearUser()),
  fetchUser: (id) => dispatch(userActions.fetchUser(id)),
  createAlmaosUser: (payload) =>
    dispatch(userManagementActions.createAlmaosUser(payload)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(NewUserCredentials)
);
