import * as types from './actionTypes';

// Actions
export const fetchConsultationById = (id) => ({
  type: types.FETCH_CONSULTATION_BY_ID,
  id,
});

export const updateConsultation = (id, payload) => ({
  type: types.UPDATE_CONSULTATION,
  payload,
  id,
});

export const addConsultationLabTest = (id) => ({
  type: types.ADD_CONSULTATION_LAB_TEST,
  id,
});

export const addConsultationMeetingStarted = (id, action) => ({
  type: types.ADD_CONSULTATION_JOIN_MEETING_STARTED,
  id,
  action,
});
export const createConsultation = (payload) => ({
  type: types.CREATE_CONSULTATION,
  payload,
});

export const updateConsultationMembers = (consultationId, memberIds) => ({
  type: types.UPDATE_CONSULTATION_MEMBERS,
  consultationId,
  memberIds,
});

export const deleteConsultationMember = (consultationId, memberId) => ({
  type: types.DELETE_CONSULTATION_MEMBER,
  consultationId,
  memberId,
});

export const updateConsultationPatient = (
  consultationId,
  patientId,
  payload
) => ({
  type: types.UPDATE_CONSULTATION_PATIENT,
  consultationId,
  patientId,
  payload,
});

export const createConsultationPatientOutcome = (
  consultationId,
  patientId,
  payload
) => ({
  type: types.CREATE_CONSULTATION_PATIENT_OUTCOME,
  consultationId,
  patientId,
  payload,
});

export const updateConsultationPatientOutcome = (
  consultationId,
  patientId,
  outcomeId,
  payload
) => ({
  type: types.UPDATE_CONSULTATION_PATIENT_OUTCOME,
  consultationId,
  patientId,
  outcomeId,
  payload,
});

export const deleteConsultationPatientOutcome = (
  consultationId,
  patientId,
  outcomeId
) => ({
  type: types.DELETE_CONSULTATION_PATIENT_OUTCOME,
  consultationId,
  patientId,
  outcomeId,
});

export const submitConsultationPrescriptions = (consultationId) => ({
  type: types.SUBMIT_CONSULTATION_PRESCRIPTIONS,
  consultationId,
});

export const submitConsultationLabTests = (consultationId) => ({
  type: types.SUBMIT_CONSULTATION_LAB_TESTS,
  consultationId,
});

// Responses
export const consultationReceived = (consultation, id) => ({
  type: types.CONSULTATION_RECEIVED,
  consultation,
  id,
});

export const consultationUpdateResponded = (ok, response) => ({
  type: types.CONSULTATION_UPDATE_RESPONDED,
  ok,
  response,
});

export const consultationCreated = (consultation) => ({
  type: types.CONSULTATION_CREATED,
  consultation,
});

export const consultationCreationResponded = (status, response) => ({
  type: types.CONSULTATION_CREATION_RESPONDED,
  status,
  response,
});

export const consultationPatientUpdateResponded = (ok, response) => ({
  type: types.CONSULTATION_PATIENT_UPDATE_RESPONDED,
  ok,
  response,
});

export const consultationMemberDeleteResponded = (ok, response) => ({
  type: types.CONSULTATION_MEMBER_DELETE_RESPONDED,
  ok,
  response,
});

export const consultationPatientOutcomeCreationResponded = (ok, response) => ({
  type: types.CONSULTATION_PATIENT_OUTCOME_CREATION_RESPONDED,
  ok,
  response,
});

export const consultationPatientOutcomeUpdateResponded = (ok, response) => ({
  type: types.CONSULTATION_PATIENT_OUTCOME_UPDATE_RESPONDED,
  ok,
  response,
});

export const consultationPatientOutcomeDeleteResponded = (ok, response) => ({
  type: types.CONSULTATION_PATIENT_OUTCOME_DELETE_RESPONDED,
  ok,
  response,
});

export const consultationPrescriptionsSubmitResponded = (ok, response) => ({
  type: types.CONSULTATION_PRESCRIPTIONS_SUBMIT_RESPONDED,
  ok,
  response,
});

export const consultationLabTestsSubmitResponded = (ok, response) => ({
  type: types.CONSULTATION_LAB_TESTS_SUBMIT_RESPONDED,
  ok,
  response,
});

// Statuses
export const setSubmittingConsultation = (isSubmitting) => ({
  type: types.SET_SUBMITTING_CONSULTATION,
  isSubmitting,
});

export const consultationMembersUpdatingResponded = (ok, response) => ({
  type: types.CONSULTATION_MEMBERS_UPDATING_RESPONDED,
  ok,
  response,
});
