// Important for rendering the text within the pdf file
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs, Page, Document } from 'react-pdf';
import React, { useState } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
// Important for rendering the annotations within the pdf file
// Although we don't have annotations, but that protects the view from extra height
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppTwoTone';

import { Box, Grid, IconButton, ReactFileViewer } from 'design-system';

import PdfPageNavigator from './PdfPageNavigator';

const DocumentOptions = {
  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

/**
 * Inline File preview that can be embedded within any component.
 * In comparison with FilePreview component, FilePreview is multiple files in a dialog with thumbnails
 */
const EmbeddedFilePreview = ({ sx, file, width }) => {
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [angle, setAngle] = useState(0);
  const [filePageCount, setFilePageCount] = useState(1);

  /**
   * Get downloadable file URL from File object (blob)
   */
  const getFileUrl = (_file) => {
    if (_file instanceof File) {
      return URL.createObjectURL(_file);
    }

    return _file?.url;
  };

  /**
   * Do the download action
   */
  const downloadFile = (_file) => {
    const url = getFileUrl(_file);
    const link = document.createElement('a');

    link.download = url?.split('/')?.pop() || '';
    link.href = url;
    link.click();
  };

  return (
    <Grid container column sx={sx}>
      <Grid item>
        <Grid
          container
          alignItems="center"
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Grid item container sx={{ width: '100%' }} justifyContent="center">
            <IconButton disabled={!file} onClick={() => downloadFile(file)}>
              <GetAppTwoToneIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setAngle((angle + 90) % 360);
              }}
            >
              <RotateRightIcon />
            </IconButton>
            <IconButton
              disabled={scale >= 2}
              onClick={() => setScale(scale + 0.2)}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              disabled={scale <= 0.5}
              onClick={() => setScale(scale - 0.2)}
            >
              <ZoomOutIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setScale(1);
                setAngle(0);
              }}
              disabled={scale === 1}
            >
              <ZoomInMapIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {file?.type?.includes('pdf') && (
          // Navigation between pdf pages
          <>
            <PdfPageNavigator
              page={page}
              setPage={setPage}
              filePageCount={filePageCount}
            />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid
                item
                sx={{
                  '& .react-pdf__Document': {
                    ...(scale !== 1 && {
                      transform: `scale(${scale})`,
                      transformOrigin: '50% 0',
                    }),
                  },
                }}
              >
                <Document
                  rotate={angle}
                  renderMode="svg"
                  file={getFileUrl(file)}
                  options={DocumentOptions}
                  onLoadSuccess={({ numPages }) => setFilePageCount(numPages)}
                >
                  <Page pageNumber={page} {...(width ? { width } : {})} />
                </Document>
              </Grid>
            </Grid>
            <PdfPageNavigator
              page={page}
              setPage={setPage}
              filePageCount={filePageCount}
            />
          </>
        )}
        {!file?.type?.includes('pdf') && file?.type && (
          <Box
            sx={{
              height: '100%',
              '& .pdf-canvas': {
                display: 'flex',
                justifyContent: 'center',
              },
              ...(scale !== 1 && {
                '& .pg-viewer-wrapper': {
                  transform: `scale(${scale})`,
                  transformOrigin: '50% 0',
                },
              }),
              ...(angle !== 0 && {
                '& .pg-viewer': {
                  transform: `rotate(${angle}deg)`,
                },
              }),
            }}
          >
            <ReactFileViewer
              key={getFileUrl(file)}
              filePath={getFileUrl(file)}
              fileType={file?.type.split('/')[1]}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default EmbeddedFilePreview;
