import * as types from './actionTypes';

export const fetchDoctorNotes = (userId, consultationId, callbacks) => ({
  type: types.FETCH_DOCTOR_NOTES,
  userId,
  callbacks,
  consultationId,
});

export const submitDoctorNotes = (
  userId,
  consultationId,
  payload,
  successCallback
) => ({
  type: types.SUBMIT_DOCTOR_NOTES,
  userId,
  payload,
  consultationId,
  successCallback,
});

export const clearDoctorNotes = () => ({
  type: types.CLEAR_DOCTOR_NOTES,
});

export const setFetchingDoctorNotes = (isFetchingDoctorNotes) => ({
  type: types.SET_FETCHING_DOCTOR_NOTES,
  isFetchingDoctorNotes,
});

export const setSubmittingDoctorNotes = (isSubmittingDoctorNotes) => ({
  type: types.SET_SUBMITTING_DOCTOR_NOTES,
  isSubmittingDoctorNotes,
});

export const doctorNotesReceived = (doctorNotes, userId) => ({
  type: types.DOCTOR_NOTES_RECEIVED,
  doctorNotes,
  userId,
});

export const doctorNotesSubmitted = (doctorNotes, userId) => ({
  type: types.DOCTOR_NOTES_SUBMITTED,
  doctorNotes,
  userId,
});

export const createPatientAssessmentSheet = (
  payload,
  userId,
  successCallback
) => ({
  type: types.CREATE_PATIENT_ASSESSMENT_SHEET,
  payload,
  userId,
  successCallback,
});
export const patientAssessmentSheetCreated = (
  patientAssessmentSheet,
  userId
) => ({
  type: types.PATIENT_ASSESSMENT_SHEET_CREATED,
  patientAssessmentSheet,
  userId,
});
