import { EVENT_NAMES } from './constants';

const emit = (eventName, detail) => {
  const event = new CustomEvent(eventName, { detail });

  document.dispatchEvent(event);
};

export const info = (message, options = {}) => {
  emit(EVENT_NAMES.INFO, {
    message,
    options: {
      ...options,
      variant: 'info',
    },
  });
};

export const error = (message, options = {}) => {
  emit(EVENT_NAMES.ERROR, {
    message,
    options: {
      ...options,
      variant: 'error',
    },
  });
};

export const warning = (message, options = {}) => {
  emit(EVENT_NAMES.WARNING, {
    message,
    options: {
      ...options,
      variant: 'warning',
    },
  });
};

export const success = (message, options = {}) => {
  emit(EVENT_NAMES.SUCCESS, {
    message,
    options: {
      ...options,
      variant: 'success',
    },
  });
};
