import * as types from './actionTypes';

export const createMedicine = (payload) => ({
  type: types.CREATE_MEDICINE,
  payload,
});

export const patchMedicine = (id, payload) => ({
  type: types.PATCH_MEDICINE,
  payload,
  id,
});
export const medicineCreationResponded = (ok, response) => ({
  type: types.MEDICINE_CREATION_RESPONDED,
  response,
  ok,
});

export const medicineFetchResponded = (ok, response) => ({
  type: types.MEDICINE_FETCH_RESPONDED,
  ok,
  response,
});

export const fetchMedicine = (id) => ({
  type: types.FETCH_MEDICNE,
  id,
});

export const medicineUpdateResponded = (ok) => ({
  type: types.MEDICINE_UPDATE_RESPONDED,
  ok,
});
