import get from 'lodash/get';
import slice from 'lodash/slice';
import isEmpty from 'lodash/isEmpty';
import findKey from 'lodash/findKey';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { useState, useEffect } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';

import { isAllowed } from 'core/permission';
import FileUpload from 'core/file/FileUpload';
import { fetchUserById } from 'model/user/apis';
import { userGetters as getters } from 'domain/user';
import DialerLink from 'presentation/components/DialerLink';
import UserInfoLink from 'presentation/components/UserInfoLink';
import { dateFormat, dateTimeFormat } from 'constants/dateTime';
import { insuranceProviderGetters } from 'domain/insuranceProvider';
import PatientHealthInfo from 'presentation/components/PatientHealthInfo';
import { USER_STATUSES, USER_OWNERSHIP_TYPE } from 'domain/user/constants';
import MemberManagement from 'presentation/user/components/MemberManagement';
import {
  Tab,
  Grid,
  Link,
  Tabs,
  Divider,
  Tooltip,
  TabPanel,
  Typography,
  DisplayField,
} from 'design-system';
import Tags from '../Tags';

/**
 *
 * @param {user}: extensive user object OR
 * @param {userId}: userId to fetch
 * @param {isFetching}: shows skeleton for loading
 * @param {isFetchingFiles}: shows skeleton for file/identity loading
 * @param {config}: default = {
 *      fields: {
 *        name: { show: true },
 *        identity: { show: true },
 *        addresses: { show: true },
 *        phoneNumber: { show: true },
 *        insuranceProvider: { show: true },
 *      }
 *    }
 */
const PatientInfo = ({
  config,
  user,
  userId,
  isFetching,
  healthProfile,
  takePassedHealthProfile = false,
}) => {
  const [isLoading, setLoading] = useState(true);

  // Fields
  const [id, setId] = useState();
  const [tab, setTab] = useState(0);
  const [city, setCity] = useState();
  const [status, setStatus] = useState();
  const [ownerId, setOwnerId] = useState();
  const [fullName, setFullName] = useState();
  const [identity, setIdentity] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [lastActive, setLastActive] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [ownershipType, setOwnershipType] = useState();
  const [identityNumber, setIdentityNumber] = useState();
  const [insuranceProvider, setInsuranceProvider] = useState();
  const [insuranceType, setInsuranceType] = useState();
  const [insuranceCardNumber, setInsuranceCardNumber] = useState();
  const [insuranceValidUntil, setInsuranceValidUntil] = useState();
  const [tags, setTags] = useState();

  const [externalReferenceNumber, setExternalReferenceNumber] = useState();
  // for fetching from cache
  const [patient, setPatient] = useState();
  const [isUserFetching, setUserFetching] = useState();

  const onTabChanged = (_event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (isFetching || isUserFetching) {
      setLoading(true);
    }
  }, [isFetching, isUserFetching]);

  useEffect(() => {
    if (user) {
      setPatient(user);
    } else if (userId) {
      fetchUserById(userId, setUserFetching).then((response) => {
        setPatient(response);
      });
    }
  }, [userId, user]);

  useEffect(() => {
    if (!isEmpty(patient)) {
      const userAddresses = getters
        .getAddresses(patient)
        .filter((item) => item.formattedAddress);
      const identityBack = getters.getIdentityBack(patient);
      const identityFront = getters.getIdentityFront(patient);

      setId(getters.getId(patient));
      setIdentity(
        [identityFront, identityBack]
          .filter(Boolean)
          .map((file) => ({ id: file }))
      );
      setOwnerId(getters.getOwnerId(patient));
      setFullName(getters.getFullName(patient));
      setPhoneNumber(getters.getPhoneNumber(patient));
      setOwnershipType(getters.getOwnershipType(patient));
      setInsuranceProvider(getters.getInsuranceProvider(patient));
      setInsuranceCardNumber(getters.getInsuranceCardNumber(patient));
      setInsuranceType(getters.getInsuranceType(patient));
      setInsuranceValidUntil(getters.getInsuranceValidUntil(patient));
      setCity(getters.getCity(patient));
      setIdentityNumber(getters.getIdentityNumber(patient));
      setExternalReferenceNumber(getters.getExternalReferenceNumber(patient));
      setTags(getters.getTags(patient));

      const statusKey = findKey(USER_STATUSES, {
        key: getters.getStatus(patient),
      });
      setStatus(get(USER_STATUSES, `${statusKey}.label`));

      setLastActive(
        getters.getLastActive(patient)
          ? format(parseISO(getters.getLastActive(patient)), dateTimeFormat)
          : null
      );

      setAddresses([
        ...slice(userAddresses, 0, 2),
        ...(userAddresses.length > 2
          ? [
              {
                formattedAddress: `<i>More addresses are available for <Link href="/user/${id}">this patient</Link>.</i>`,
              },
            ]
          : []),
      ]);

      setLoading(false);
    }
  }, [patient]);

  return (
    <>
      <Tabs value={tab} handleChange={onTabChanged}>
        <Tab
          value={0}
          label={
            <Typography
              variant="h5"
              sx={{
                paddingBottom: 0,
                textTransform: 'none',
              }}
            >
              Patient Info
            </Typography>
          }
        />
        {isAllowed('common', 'healthInfo') && (
          <Tab
            value={1}
            label={
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: 0,
                  textTransform: 'none',
                }}
              >
                Health Info
              </Typography>
            }
          />
        )}
      </Tabs>
      <TabPanel value={tab} index={0} id="patient-card-tabpanel">
        {get(config, 'fields.name.show', true) && (
          <>
            <Grid
              container
              item
              alignItems="center"
              flexWrap="nowrap"
              sx={{ height: (theme) => theme.spacing(7), px: 2 }}
            >
              <PersonOutlineOutlinedIcon
                color="standard"
                fontSize="extraSmall"
                sx={{ pr: 1 }}
              />
              <Link
                href={`/user/${id}`}
                disabled={
                  isFetching || !isAllowed('patientInfo', 'viewUserProfile')
                }
              >
                <Typography
                  variant="p3"
                  sx={{ lineHeight: 1 }}
                  loading={isLoading && { width: 200 }}
                >
                  {fullName ||
                    (isAllowed('patientInfo', 'viewUserProfile')
                      ? 'Visit user profile'
                      : 'N/A')}
                </Typography>
              </Link>
              {isAllowed('patientInfo', 'status') && (
                <>
                  <Typography
                    variant="p4"
                    alignSelf="center"
                    sx={{ mr: 1 }}
                    loading={isLoading && { width: '33%' }}
                  >
                    {status || 'N/A'}
                  </Typography>
                  {lastActive && (
                    <Tooltip
                      placement="top"
                      title="Last seen on the mobile app"
                      injectWrapper
                    >
                      <Grid container item row>
                        <Grid
                          container
                          item
                          alignItems="center"
                          row
                          wrap="nowrap"
                          sx={{
                            ml: 1,
                          }}
                        >
                          <AccessTimeIcon
                            fontSize="extraSmall"
                            color="standard"
                            sx={{ mt: 0, mr: 0.2 }}
                          />
                          <Typography
                            variant="p4"
                            xs
                            wrap="nowrap"
                            display="inline"
                            sx={{
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                            }}
                            textAlign="right"
                          >
                            {lastActive}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Tooltip>
                  )}
                </>
              )}
            </Grid>
            {isAllowed('patientInfo', 'viewTags') &&
              get(config, 'fields.tags.show', true) && (
                <>
                  <Tags tags={tags} isFetching={isLoading} />
                  <Divider />
                </>
              )}
            <Divider />
          </>
        )}
        {(externalReferenceNumber || isLoading) &&
          get(config, 'fields.externalReferenceNumber.show', true) && (
            <>
              <Grid
                container
                item
                column
                sx={{ minHeight: (theme) => theme.spacing(7), p: 2 }}
              >
                <Grid item sx={{ mb: 0.5 }}>
                  <Typography
                    variant="p4"
                    loading={isLoading && { width: 55 }}
                    sx={{
                      lineHeight: 1,
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    EMR # / EXTERNAL REFERNCE ID
                  </Typography>
                </Grid>
                <DisplayField
                  label=""
                  value={externalReferenceNumber}
                  labelWidth={60}
                  valueWidth={200}
                  loading={isLoading}
                />
              </Grid>
              <Divider />
            </>
          )}
        {isAllowed('patientInfo', 'viewPhoneNumber') &&
          get(config, 'fields.phoneNumber.show', true) && (
            <>
              <Grid
                container
                item
                alignItems="center"
                sx={{ height: (theme) => theme.spacing(7), px: 2 }}
              >
                <DialerLink
                  showIcon
                  isFetching={isLoading}
                  phoneNumber={phoneNumber}
                />
              </Grid>
              <Divider />
            </>
          )}
        {get(config, 'fields.identity.show', true) && (
          <>
            <Grid
              container
              row
              item
              spacing={2}
              justifyContent="space-between"
              sx={{
                p: 2,
              }}
            >
              <Grid container item column xs={5}>
                <Grid item row sx={{ mb: 0.5 }}>
                  <Typography
                    variant="p4"
                    textTransform="uppercase"
                    loading={isLoading && { width: 55 }}
                    sx={{
                      lineHeight: 1,
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    Identity
                  </Typography>
                  <DisplayField
                    label=""
                    value={identityNumber}
                    labelWidth={60}
                    valueWidth={200}
                    loading={isLoading}
                  />
                </Grid>

                {!isLoading && isEmpty(identity) && (
                  <Typography variant="p3">No identity provided</Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={6}
                sx={{ mt: 1 }}
                justifyContent="right"
                flexWrap="nowrap"
              >
                {(isLoading || !isEmpty(identity)) && (
                  <FileUpload
                    files={identity}
                    loading={isLoading && { count: 2 }}
                    onChange={(files) => {
                      setIdentity(files);
                    }}
                    config={{
                      readonly: true,
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Divider />
          </>
        )}
        {get(config, 'fields.insuranceProvider.show', true) && (
          <>
            <Grid
              container
              item
              alignItems="center"
              sx={{ height: (theme) => theme.spacing(7), px: 2 }}
            >
              <HealthAndSafetyOutlinedIcon
                color="standard"
                fontSize="extraSmall"
                sx={{ pr: 1 }}
              />
              <Typography
                variant="p3"
                sx={{ lineHeight: 1 }}
                loading={isLoading && { width: 120 }}
              >
                {`Insruance Provider: ${insuranceProviderGetters.getName(
                  insuranceProvider
                )}` || 'N/A'}
              </Typography>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              sx={{ height: (theme) => theme.spacing(7), px: 2 }}
            >
              <HealthAndSafetyOutlinedIcon
                color="standard"
                fontSize="extraSmall"
                sx={{ pr: 1 }}
              />
              <Typography
                variant="p3"
                sx={{ lineHeight: 1 }}
                loading={isLoading && { width: 120 }}
              >
                Insurance Type: {insuranceType || 'N/A'}
              </Typography>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              sx={{ height: (theme) => theme.spacing(7), px: 2 }}
            >
              <HealthAndSafetyOutlinedIcon
                color="standard"
                fontSize="extraSmall"
                sx={{ pr: 1 }}
              />
              <Typography
                variant="p3"
                sx={{ lineHeight: 1 }}
                loading={isLoading && { width: 120 }}
              >
                Insurance ID: {insuranceCardNumber || 'N/A'}
              </Typography>
            </Grid>
            <Grid
              container
              item
              alignItems="center"
              sx={{ height: (theme) => theme.spacing(7), px: 2 }}
            >
              <HealthAndSafetyOutlinedIcon
                color="standard"
                fontSize="extraSmall"
                sx={{ pr: 1 }}
              />
              <Typography
                variant="p3"
                sx={{ lineHeight: 1 }}
                loading={isLoading && { width: 120 }}
              >
                Insurance Valid Until:{' '}
                {insuranceValidUntil
                  ? format(parseISO(insuranceValidUntil), dateFormat)
                  : 'N/A'}
              </Typography>
            </Grid>
            <Divider />
          </>
        )}
        {/** Member Management */}
        {isAllowed('userView', 'memberManagement', 'view') &&
          (isFetching || ownershipType === USER_OWNERSHIP_TYPE.Owner.key) &&
          get(config, 'fields.memberManagement.show', true) && (
            <Grid container item column sx={{ my: 1, px: 2 }}>
              <Grid item sx={{ mb: 1 }}>
                <Typography
                  variant="p4"
                  textTransform="uppercase"
                  loading={isFetching && { width: 70 }}
                  sx={{
                    lineHeight: 1,
                    color: (theme) => theme.palette.grey[600],
                  }}
                >
                  Members
                </Typography>
              </Grid>
              <MemberManagement
                ownerId={patient?.id}
                loading={isFetching}
                readonly
                size="small"
              />
            </Grid>
          )}
        {isAllowed('userView', 'memberManagement', 'view') &&
          ownershipType === USER_OWNERSHIP_TYPE.Member.key &&
          get(config, 'fields.memberManagement.show', true) && (
            <Grid container item column sx={{ my: 1, px: 2 }}>
              <Grid item sx={{ mb: 1 }}>
                <Typography
                  variant="p4"
                  textTransform="uppercase"
                  loading={isFetching && { width: 50 }}
                  sx={{
                    lineHeight: 1,
                    color: (theme) => theme.palette.grey[600],
                  }}
                >
                  Owner
                </Typography>
              </Grid>
              <Grid
                container
                flexWrap="nowrap"
                alignItems="center"
                columnSpacing={1}
              >
                <Grid item>
                  <PersonOutlinedIcon color="standard" />
                </Grid>
                <Grid item sx={{ width: '100%' }}>
                  <UserInfoLink
                    key={ownerId}
                    userId={ownerId}
                    config={{
                      isPhoneNumberShown: false,
                      namePlaceholder: "Visit user's profile",
                    }}
                    sx={{
                      width: 'auto',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        {get(config, 'fields.addresses.show', true) && (
          <>
            {!isLoading && (
              <Grid
                container
                item
                column
                sx={{ minHeight: (theme) => theme.spacing(7), p: 2 }}
                loading={isLoading}
              >
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    textTransform="uppercase"
                    loading={isLoading && { width: 55 }}
                    sx={{
                      lineHeight: 1,
                      mb: 1,
                      color: (theme) => theme.palette.grey[600],
                    }}
                  >
                    City & Delivery Addresses
                  </Typography>
                </Grid>
                {city && (
                  <Grid item container flexWrap="nowrap" alignItems="center">
                    <LocationCityIcon
                      color="standard"
                      fontSize="extraSmall"
                      sx={{ pr: 1 }}
                    />
                    <Typography variant="p3" sx={{ lineHeight: 1 }}>
                      {city}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid
              container
              item
              flexWrap="nowrap"
              column
              justifyContent="center"
            >
              {(isLoading || isEmpty(addresses)) && (
                <>
                  <Grid
                    item
                    container
                    flexWrap="nowrap"
                    alignItems="center"
                    sx={{ p: 2, minHeight: (theme) => theme.spacing(7) }}
                  >
                    <LocationOnOutlinedIcon
                      color="standard"
                      fontSize="extraSmall"
                      sx={{ pr: 1 }}
                    />
                    <Typography
                      variant="p3"
                      sx={{ lineHeight: 1 }}
                      loading={isLoading && { width: '70%' }}
                    >
                      No address provided
                    </Typography>
                  </Grid>
                  <Divider />
                </>
              )}

              {!isLoading &&
                addresses.map((address, idx) => (
                  <Grid
                    item
                    container
                    flexWrap="nowrap"
                    alignItems="center"
                    sx={{
                      p: idx < 2 ? 1 : 0,
                      pl: idx < 2 ? 2 : 5,
                      minHeight: (theme) => theme.spacing(5),
                    }}
                  >
                    {idx < 2 && (
                      <LocationOnOutlinedIcon
                        color="standard"
                        fontSize="extraSmall"
                        sx={{ pr: 1 }}
                      />
                    )}
                    <Typography
                      variant="p3"
                      sx={{ lineHeight: 1 }}
                      dangerouslySetInnerHTML={{
                        __html: address.formattedAddress,
                      }}
                    />
                  </Grid>
                ))}

              <Divider />
            </Grid>
          </>
        )}
      </TabPanel>
      <TabPanel value={tab} index={1} id="health-card-tabpanel">
        <PatientHealthInfo
          user={patient}
          isFetching={isLoading}
          healthProfile={healthProfile}
          takePassedHealthProfile={takePassedHealthProfile}
        />
      </TabPanel>
    </>
  );
};

export default PatientInfo;
