import MuiTextField from '@mui/material/TextField';
import React, { useState, useEffect } from 'react';

import Grid from '../Grid';
import Skeleton from '../Skeleton';

/**
 *
 * shape: pass "round" for round corners
 */
const TextField = ({
  size = 'medium',
  value,
  shape,
  limit,
  loading,
  onChange = () => {},
  helperText,
  defaultValue,
  inputProps = {},
  disableAutofill = true,
  ...rest
}) => {
  const [val, setVal] = useState(defaultValue);
  useEffect(() => {
    if (val !== value) {
      setVal(value);
    }
  }, [value]);
  if (loading) {
    return (
      <Skeleton
        variant="rectangle"
        componentSize={size}
        componentShape={shape}
        componentName="textField"
        {...(typeof loading !== 'boolean' ? loading : {})}
      />
    );
  }

  const handleChange = (event) => {
    onChange(event);
    setVal(event.target.value);
  };

  return (
    <MuiTextField
      size={size}
      value={val}
      shape={shape}
      onChange={handleChange}
      inputProps={{
        ...inputProps,
        ...(disableAutofill
          ? {
              autocomplete: 'off',
            }
          : {}),
        maxLength: limit,
      }}
      helperText={
        val && limit ? (
          <Grid
            container
            justifyContent="space-between"
            sx={{
              direction: 'ltr',
            }}
          >
            <Grid item>{helperText}</Grid>
            <Grid item sx={{ color: 'initial' }}>
              {val?.length} / {limit}
            </Grid>
          </Grid>
        ) : (
          helperText
        )
      }
      {...rest}
    />
  );
};

export default TextField;
