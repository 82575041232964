import React from 'react';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import { isAllowed } from 'core/permission';
import { OUTCOME_OPTIONS } from 'domain/outcome/constants';
import LabRequestIcon from 'design-system/icons/LabRequestIcon';
import PrescriptionIcon from 'design-system/icons/PrescriptionIcon';
import ActionRequired from 'presentation/components/ActionRequired';
import { If, Else, Grid, Link, Tooltip, Conditionally } from 'design-system';

import PatientOutcomeDropDown from './components/PatientOutcomeDropDown';
import ConsultationFilePreview from './components/ConsultationFilePreview';

const getColumns = ({ tenantKey }) => [
  {
    field: 'name',
    flex: 0.8,
    headerName: 'Name',
    valueGetter: ({ row: patient }) => patient.fullName || 'N/A',
    renderCell: (params) => {
      return (
        <Tooltip
          injectWrapper
          placement="top"
          title={params.row.fullName || "Visit patient's profile"}
        >
          <Link href={`/user/${params.row.userId}`}>
            {params.row.fullName || "Visit patient's profile"}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'outcome',
    headerName: 'Outcome',
    flex: 1.3,
    renderCell: ({ row: patient }) => {
      let disabledOptions = [];
      // Convert outcome list into a key value object
      const patientOutcomes = patient.output.reduce((outcomeMap, outcome) => {
        const result = { ...outcomeMap };

        result[outcome.referenceType] = {
          key: outcome.referenceType,
          label:
            OUTCOME_OPTIONS[outcome.referenceType]?.label ||
            outcome.referenceType,
          color: OUTCOME_OPTIONS[outcome.referenceType]?.color || 'success',
        };

        // Check if the output is prescription or lab test request, we have to disable unselect referenceType
        if (
          [
            OUTCOME_OPTIONS.Prescription.key,
            OUTCOME_OPTIONS.LabTestRequest.key,
          ].includes(outcome.referenceType)
        ) {
          disabledOptions.push(OUTCOME_OPTIONS[outcome.referenceType].key);
        }

        if (outcome.referenceType === OUTCOME_OPTIONS.NoShow.key) {
          // If No Show is selected, disable all other options
          disabledOptions = keys(OUTCOME_OPTIONS).filter(
            (key) => key !== OUTCOME_OPTIONS.NoShow.key
          );
        } else {
          // Other options are selected, then disable no show
          disabledOptions.push(OUTCOME_OPTIONS.NoShow.key);
        }

        return result;
      }, {});

      return (
        <Grid container sx={{ width: '100%' }}>
          <Grid item sx={{ width: '100%' }}>
            <PatientOutcomeDropDown
              tenantKey={tenantKey}
              values={patientOutcomes}
              consultationPatientId={patient.id}
              consultationId={patient.consultationId}
              disableOptions={disabledOptions}
              disabled={!isAllowed('consultationView', 'outcome', 'edit')}
            />
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'files',
    headerName: 'Files',
    flex: 1,
    renderCell: (params) => {
      const isNoShow = params.row.output
        .map((outcome) => outcome.referenceType)
        .includes(OUTCOME_OPTIONS.NoShow.key);
      const actionRequiredOutcomes = params.row.output.filter((outcome) => {
        // Select outcomes that are either a prescription or lab test
        //  that do not have files or outputs list (lab test names)
        return (
          [
            OUTCOME_OPTIONS.Prescription.key,
            OUTCOME_OPTIONS.LabTestRequest.key,
          ].includes(outcome.referenceType) &&
          isEmpty(outcome.referenceFiles) &&
          isEmpty(outcome.outputsList)
        );
      });

      const actionRequiredTitle = actionRequiredOutcomes
        .map(
          (outcome) =>
            `${OUTCOME_OPTIONS[outcome.referenceType].label} is missing`
        )
        .join('<br />');

      return (
        <Conditionally>
          <If condition={isEmpty(actionRequiredOutcomes)}>
            <Grid container flexWrap="nowrap" columnSpacing={1}>
              <Conditionally>
                <If
                  condition={
                    // If not no show
                    !isNoShow &&
                    // and if assessment sheet exists
                    !isEmpty(params.row.assessmentSheet)
                  }
                >
                  <Grid item key={params.row.assessmentSheet} pt={0.5}>
                    <ConsultationFilePreview
                      files={[params.row.assessmentSheet]?.map((id) => ({
                        id,
                      }))}
                      customIcon={
                        <AssignmentTurnedInIcon
                          color="secondary"
                          fontSize="extraLarge"
                        />
                      }
                    />
                  </Grid>
                </If>
              </Conditionally>
              {params.row.output.map((outcome) => {
                if (
                  [
                    OUTCOME_OPTIONS.Prescription.key,
                    OUTCOME_OPTIONS.LabTestRequest.key,
                  ].includes(outcome.referenceType) &&
                  !isEmpty(outcome.referenceFiles)
                ) {
                  const key = `${outcome.referenceType}-${
                    outcome.consultationPatientId
                  }-${outcome.referenceFiles.join(',')}`;
                  const files = outcome.referenceFiles.map((id) => ({ id }));

                  return (
                    <Grid item key={key} pt={0.5}>
                      <ConsultationFilePreview
                        files={files}
                        customIcon={
                          outcome.referenceType ===
                          OUTCOME_OPTIONS.Prescription.key ? (
                            <PrescriptionIcon />
                          ) : (
                            <LabRequestIcon />
                          )
                        }
                      />
                    </Grid>
                  );
                }

                return null;
              })}
            </Grid>
          </If>
          <Else>
            <ActionRequired
              tooltipTitle={
                // __html to be able to render <br />
                <span
                  dangerouslySetInnerHTML={{
                    __html: actionRequiredTitle,
                  }}
                />
              }
            />
          </Else>
        </Conditionally>
      );
    },
  },
];

export default getColumns;
