import get from 'lodash/get';
import { all, call, takeLatest, put } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import { getHealthEndpoint } from 'core/endpoint';

import * as types from './actionTypes';
import * as actions from './actions';
import { ENDPOINT } from './constants';

export function* createDietPlan({ payload }) {
  try {
    const response = yield call(httpClient.post, ENDPOINT, payload, {
      baseURL: getHealthEndpoint(),
    });

    yield put(actions.dietPlanCreationResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.dietPlanCreationResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchLatestDietPlan({ userId }) {
  try {
    const response = yield call(httpClient.get, ENDPOINT, {
      baseURL: getHealthEndpoint(),
      params: {
        userId,
        limit: 1,
      },
    });

    yield put(actions.latestDietPlanReceived(response.data));
  } catch (e) {
    yield put(actions.latestDietPlanReceived({}));
  }
}

export default function* eligibilityModelSaga() {
  yield all([
    takeLatest(types.CREATE_DIET_PLAN, createDietPlan),
    takeLatest(types.FETCH_LATEST_DIET_PLAN, fetchLatestDietPlan),
  ]);
}
