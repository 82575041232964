import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import React, { useState, useEffect, useCallback } from 'react';

import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

import MemberSelectForm from '../MemberSelectForm';

const SelectMemberDialog = ({
  // Props
  open,
  onClose,
  onMembersUpdated,
  referenceId,
  permissions = {},
  // State,
  members,
  allMembers,
  isSubmittingMembers,
  setIsFormChanged,
  // actions
  updateMembers,
  footer,
  formRef,
}) => {
  const perms = {
    delete: true,
    ...permissions,
  };

  const [isOpen, setOpen] = useState(open);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, []);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const onSubmit = useCallback(
    (data) => {
      let { newMembersConsultation } = data;
      // Handling no changes on the map
      if (!isArray(newMembersConsultation)) {
        // Get map keys-ids as an array
        newMembersConsultation = keys(newMembersConsultation);
      }
      updateMembers(referenceId, newMembersConsultation);
      onMembersUpdated(() => {
        onClosed();
      });
    },
    [referenceId]
  );

  const onClick = () => {
    formRef.current.submit();
    setIsFormChanged(false);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      disablePortal
      onClose={onClosed}
      aria-labelledby="consultation-members-update-dialog"
    >
      <DialogTitle
        id="consultation-members-update-dialog-title"
        onClose={onClosed}
      >
        Add other patients to this consultation
      </DialogTitle>
      <DialogContent dividers>
        <MemberSelectForm
          allMembers={allMembers}
          members={members}
          ref={formRef}
          onSubmit={onSubmit}
          footer={footer}
          setIsFormChanged={setIsFormChanged}
          permissions={perms}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          color="primary"
          spinning={isSubmittingMembers}
          onClick={onClick}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectMemberDialog;
