import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { userActions, userSelectors } from 'domain/user';
import { orderActions, orderSelectors } from 'domain/order';

import NewOrder from './NewOrder';

const mapStateToProps = (state) => {
  const user = userSelectors.getUser(state);
  const isSubmitting = orderSelectors.isSubmitting(state);
  const isFetchingUser = userSelectors.isFetchingUser(state);

  return {
    user,
    isSubmitting,
    isFetchingUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch(userActions.clearUser()),
  fetchUser: (id) => dispatch(userActions.fetchUser(id)),
  createOrder: (payload) => dispatch(orderActions.createOrder(payload)),
});

export default appData(connect(mapStateToProps, mapDispatchToProps)(NewOrder));
