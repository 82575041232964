import get from 'lodash/get';
import slice from 'lodash/slice';
import isEmpty from 'lodash/isEmpty';
import findKey from 'lodash/findKey';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { useState, useEffect } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { isAllowed } from 'core/permission';
import { dateTimeFormat } from 'constants/dateTime';
import { userGetters as getters } from 'domain/user';
import Tags from 'presentation/components/Tags';
import DialerLink from 'presentation/components/DialerLink';
import { USER_STATUSES } from 'domain/user/constants';
import {
  Card,
  Grid,
  Link,
  Divider,
  Tooltip,
  Typography,
  CardHeader,
  CardContent,
} from 'design-system';

/**
 *
 * @param {owner}: using fetchOwner
 * @param {ownerId}: ownerId to fetch
 * @param {isFetching}: shows skeleton for loading
 * @param {config}: default = {
 *      fields: {
 *        name: { show: true },
 *        addresses: { show: true },
 *        phoneNumber: { show: true },
 *      }
 *    }
 */

const OwnerInfo = ({
  // props
  config,
  ownerId,
  ownerNotes,
  // state
  owner,
  isFetching,
  // actions
  fetchOwner,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  // Fields
  const [id, setId] = useState();
  const [city, setCity] = useState();
  const [tags, setTags] = useState();
  const [status, setStatus] = useState();
  const [fullName, setFullName] = useState();
  const [addresses, setAddresses] = useState([]);
  const [lastActive, setLastActive] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  useEffect(() => {
    if (isFetching) {
      setIsLoading(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (ownerId) {
      fetchOwner(ownerId);
    }
  }, [ownerId]);

  useEffect(() => {
    if (!isEmpty(owner)) {
      const userAddresses = getters
        .getAddresses(owner)
        .filter((item) => item.formattedAddress);

      setId(getters.getId(owner));
      setTags(getters.getTags(owner));
      setFullName(getters.getFullName(owner));
      setPhoneNumber(getters.getPhoneNumber(owner));
      // TODO to remove
      // setConsultationTypeId(
      //   consultationGetters.getConsultationTypeId(consultation)
      // );

      // end
      // setUserNotes(consultationGetters.getUserNotes(consultation));
      setCity(getters.getCity(owner));
      const statusKey = findKey(USER_STATUSES, {
        key: getters.getStatus(owner),
      });
      setStatus(get(USER_STATUSES, `${statusKey}.label`));

      setLastActive(
        getters.getLastActive(owner)
          ? format(parseISO(getters.getLastActive(owner)), dateTimeFormat)
          : null
      );

      setAddresses([
        ...slice(userAddresses, 0, 2),
        ...(userAddresses.length > 2
          ? [
              {
                formattedAddress: `<i>More addresses are available for <Link href="/user/${id}">this patient</Link>.</i>`,
              },
            ]
          : []),
      ]);

      setIsLoading(false);
    }
  }, [id, owner]);

  return (
    <Card
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader title="Account Owner" sx={{ pb: 0 }} />
      <CardContent noPadding sx={{ height: 'calc(100% - 44px)' }}>
        <Grid container column flexWrap="nowrap" sx={{ height: '100%' }}>
          <Grid item sx={{ height: '100%' }}>
            {get(config, 'fields.name.show', true) && (
              <Grid
                item
                container
                flexWrap="nowrap"
                alignItems="center"
                sx={{ height: (theme) => theme.spacing(7), px: 2 }}
              >
                <PersonOutlineOutlinedIcon
                  sx={{ pr: 1 }}
                  color="standard"
                  fontSize="extraSmall"
                />
                <Link
                  href={`/user/${id}`}
                  disabled={
                    isFetching || !isAllowed('userAccount', 'viewUserProfile')
                  }
                >
                  <Typography
                    variant="p3"
                    sx={{ lineHeight: 1 }}
                    loading={isLoading && { width: 200 }}
                  >
                    {fullName ||
                      (isAllowed('userAccount', 'viewUserProfile')
                        ? 'Visit user profile'
                        : 'N/A')}
                  </Typography>
                </Link>
                {isAllowed('userAccount', 'status') && (
                  <>
                    <Typography
                      variant="p4"
                      sx={{ mr: 1 }}
                      alignSelf="center"
                      loading={isLoading && { width: '33%' }}
                    >
                      {status || 'N/A'}
                    </Typography>
                    {lastActive && (
                      <Tooltip
                        injectWrapper
                        placement="top"
                        title="Last seen on the mobile app"
                      >
                        <Grid container item row>
                          <Grid
                            row
                            item
                            container
                            wrap="nowrap"
                            alignItems="center"
                            sx={{
                              ml: 1,
                            }}
                          >
                            <AccessTimeIcon
                              color="standard"
                              fontSize="extraSmall"
                              sx={{ mt: 0, mr: 0.2 }}
                            />
                            <Typography
                              xs
                              variant="p4"
                              wrap="nowrap"
                              display="inline"
                              textAlign="right"
                              sx={{
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                              }}
                            >
                              {`last ${lastActive}` || 'N/A'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Tooltip>
                    )}
                  </>
                )}
              </Grid>
            )}
            {isAllowed('userAccount', 'viewTags') &&
              get(config, 'fields.tags.show', true) && (
                <>
                  <Tags tags={tags} isFetching={isLoading} />
                  <Divider />
                </>
              )}
            {isAllowed('userAccount', 'viewPhoneNumber') &&
              get(config, 'fields.phoneNumber.show', true) && (
                <>
                  <Grid
                    item
                    container
                    alignItems="center"
                    sx={{ height: (theme) => theme.spacing(7), px: 2 }}
                  >
                    <DialerLink
                      showIcon
                      isFetching={isLoading}
                      phoneNumber={phoneNumber}
                    />
                  </Grid>
                  <Divider />
                </>
              )}
            {get(config, 'fields.addresses.show', true) && (
              <>
                {!isLoading && (
                  <Grid
                    item
                    column
                    container
                    loading={isLoading}
                    sx={{ minHeight: (theme) => theme.spacing(7), p: 2 }}
                  >
                    <Grid item sx={{ mb: 1 }}>
                      <Typography
                        variant="p4"
                        textTransform="uppercase"
                        loading={isLoading && { width: 55 }}
                        sx={{
                          mb: 1,
                          lineHeight: 1,
                          color: (theme) => theme.palette.grey[600],
                        }}
                      >
                        City & Delivery Addresses
                      </Typography>
                    </Grid>

                    {city && (
                      <Grid
                        item
                        container
                        flexWrap="nowrap"
                        alignItems="center"
                      >
                        <LocationCityIcon
                          color="standard"
                          fontSize="extraSmall"
                          sx={{ pr: 1 }}
                        />
                        <Typography variant="p3" sx={{ lineHeight: 1 }}>
                          {city}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
                <Divider />
              </>
            )}
            <Divider />
            <Grid
              item
              column
              container
              flexWrap="nowrap"
              justifyContent="center"
            >
              {(isLoading || isEmpty(addresses)) && (
                <Grid
                  item
                  container
                  flexWrap="nowrap"
                  alignItems="center"
                  sx={{ p: 2, minHeight: (theme) => theme.spacing(7) }}
                >
                  <LocationOnOutlinedIcon
                    sx={{ pr: 1 }}
                    color="standard"
                    fontSize="extraSmall"
                  />
                  <Typography
                    variant="p3"
                    sx={{ lineHeight: 1 }}
                    loading={isLoading && { width: '70%' }}
                  >
                    No address provided
                  </Typography>
                </Grid>
              )}

              {!isLoading &&
                addresses.map((address, idx) => (
                  <Grid
                    item
                    container
                    flexWrap="nowrap"
                    alignItems="center"
                    key={address.formattedAddress}
                    sx={{
                      p: idx < 2 ? 1 : 0,
                      pl: idx < 2 ? 2 : 5,
                      minHeight: (theme) => theme.spacing(5),
                    }}
                  >
                    {idx < 2 && (
                      <LocationOnOutlinedIcon
                        sx={{ pr: 1 }}
                        color="standard"
                        fontSize="extraSmall"
                      />
                    )}
                    <Typography
                      variant="p3"
                      sx={{ lineHeight: 1 }}
                      dangerouslySetInnerHTML={{
                        __html: address.formattedAddress,
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Divider />
            <Grid
              item
              column
              container
              sx={{ minHeight: (theme) => theme.spacing(7), p: 2 }}
            >
              <Grid item sx={{ mb: 1 }}>
                <Typography
                  variant="p4"
                  loading={isFetching && { width: 175 }}
                  sx={{
                    lineHeight: 1,
                    color: (theme) => theme.palette.grey[600],
                  }}
                >
                  USER REQUESTS & NOTES
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="p3"
                  color="primary"
                  loading={isLoading && { width: 200 }}
                >
                  {isEmpty(ownerNotes)
                    ? 'No notes provided by patient'
                    : ownerNotes}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OwnerInfo;
