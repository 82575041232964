import get from 'lodash/get';
import { all, call, takeLatest, put } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchClinics() {
  try {
    const response = yield call(httpClient.get, constants.CLINIC_ENDPOINT);

    yield put(actions.clinicsFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.clinicsFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export default function* clinicModelSaga() {
  yield all([takeLatest(types.FETCH_CLINICS, fetchClinics)]);
}
