import { useForm } from 'react-hook-form';
import React, { forwardRef, useImperativeHandle } from 'react';

const Form = forwardRef(
  (
    { defaultValues = {}, onSubmit, onError, resetOnSubmit, children, ...rest },
    ref
  ) => {
    const methods = useForm({
      defaultValues,
    });

    const submitHandler = (data) => {
      onSubmit(data);

      if (resetOnSubmit) {
        methods.reset();
      }
    };

    useImperativeHandle(ref, () => ({
      submit() {
        methods.handleSubmit(submitHandler, onError)();
      },
      getMethods() {
        return methods;
      },
    }));

    return (
      <form onSubmit={methods.handleSubmit(submitHandler, onError)} {...rest}>
        {children(methods)}
      </form>
    );
  }
);

export default Form;
