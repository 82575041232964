import keys from 'lodash/keys';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState, useEffect } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { CountrySelect } from 'core/country';
import FileUpload from 'core/file/FileUpload';
import { GENDER } from 'domain/user/constants';
import { dateFormat } from 'constants/dateTime';
import { Form, Controller } from 'design-system/form';
import { getCountryByLabel } from 'core/country/utils';
import {
  If,
  Grid,
  Dialog,
  Select,
  ElseIf,
  Button,
  Divider,
  Message,
  Checkbox,
  MenuItem,
  FormField,
  TextField,
  PopConfirm,
  DatePicker,
  Typography,
  DialogTitle,
  DialogContent,
  Conditionally,
  DialogActions,
  LinearProgress,
  FormControlLabel,
} from 'design-system';

const CONFIDENCE_LIMIT = 60;

const VIEWS = {
  INITIAL: 'INITIAL',
  PROCESSING: 'PROCESSING',
  ERROR: 'ERROR',
  FORM: 'FORM',
};

const IdentityExtractionDialog = ({
  userId,
  onClose,
  open = false,
  identityBack,
  identityFront,
  extractIdentity,
  extractedIdentity,
  clearExtractedData,
  isExtractingIdentity,
  identityExtractionJobs,
  identityExtractionError,
  cancelIdentityExtraction,
  saveExtractedIdentityData,
  isSavingExtractedIdentityData,
}) => {
  const ref = useRef();

  const [isOpen, setOpen] = useState(open);
  const [view, setView] = useState(VIEWS.INITIAL);
  const [idBack, setIdBack] = useState(identityBack);
  const [idFront, setIdFront] = useState(identityFront);
  const [popConfirmAnchor, setPopConfirmAnchor] = useState(null);
  const [parsedExtractedIdentity, setParsedExtractedIdentity] = useState();
  const [selectedFiles, setSelectedFiles] = useState({
    front: true,
    back: true,
  });

  const showPopConfirm = (event) => {
    setPopConfirmAnchor(event.currentTarget);
  };

  const closePopConfirm = () => {
    setPopConfirmAnchor(null);
  };

  const reset = () => {
    setView(VIEWS.INITIAL);
    setSelectedFiles({
      front: true,
      back: true,
    });
    clearExtractedData();
  };

  const onClosed = (isSuccessful) => {
    setOpen(false);
    onClose(isSuccessful);
    reset();

    if (!isSuccessful) {
      cancelIdentityExtraction();
    }
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (parsedExtractedIdentity) {
      if (!isEmpty(values(parsedExtractedIdentity || {}).filter(Boolean))) {
        setView(VIEWS.FORM);
      } else {
        setView(VIEWS.ERROR);
      }
    } else if (isExtractingIdentity) {
      setView(VIEWS.PROCESSING);
    } else if (identityExtractionError) {
      setView(VIEWS.ERROR);
    }
  }, [parsedExtractedIdentity, isExtractingIdentity, identityExtractionError]);

  useEffect(() => {
    const selected = {
      front: true,
      back: true,
    };

    if (identityFront && identityFront?.[0]?.id) {
      setIdFront([
        {
          id: identityFront[0].id,
        },
      ]);
    } else {
      setIdFront([]);
      selected.front = false;
    }

    if (identityBack && identityBack?.[0]?.id) {
      setIdBack([
        {
          id: identityBack[0].id,
        },
      ]);
    } else {
      setIdBack([]);
      selected.back = false;
    }

    setSelectedFiles(selected);
  }, [identityBack, identityFront]);

  useEffect(() => {
    if (!isEmpty(extractedIdentity)) {
      setParsedExtractedIdentity({
        ...extractedIdentity,
        dateOfBirth: {
          Text: (() => {
            if (extractedIdentity?.dateOfBirth?.Text) {
              return new Date(extractedIdentity.dateOfBirth.Text);
            }

            return null;
          })(),
          Confidence: extractedIdentity?.dateOfBirth?.Confidence || 0,
        },
        expiryDate: {
          Text: (() => {
            if (extractedIdentity?.expiryDate?.Text) {
              return new Date(extractedIdentity.expiryDate.Text);
            }

            return null;
          })(),
          Confidence: extractedIdentity?.expiryDate?.Confidence || 0,
        },
        nationality: {
          Text: (() => {
            if (extractedIdentity?.nationality?.Text) {
              return getCountryByLabel(extractedIdentity.nationality.Text);
            }

            return null;
          })(),
          Confidence: extractedIdentity?.nationality?.Confidence || 0,
        },
      });
    }
  }, [extractedIdentity]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={() => onClosed()}
      aria-labelledby="identity-extraction-dialog-title"
    >
      <DialogTitle
        id="identity-extraction-dialog-title"
        {...(() => {
          if (
            [VIEWS.INITIAL, VIEWS.ERROR, VIEWS.ERROR].includes(view) ||
            (view === VIEWS.PROCESSING && !isEmpty(identityExtractionJobs))
          ) {
            return {
              onClose: () => onClosed(),
            };
          }
        })()}
      >
        {(() => {
          switch (view) {
            case VIEWS.INITIAL:
              return "Verify Patient's Identity for Extraction";
            case VIEWS.PROCESSING:
              return "Extracting Patient's Information from Identity";
            case VIEWS.FORM:
              return "Confirm Patient's Extracted Information";
            case VIEWS.ERROR:
              return "Failed to Extract Patient's Information";
            default:
              return '';
          }
        })()}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          {!isEmpty(identityFront) && (
            <Grid item container column xs={6}>
              <Grid item>
                <FileUpload
                  userId={userId}
                  files={idFront}
                  config={{ readonly: true }}
                  onChange={(value) => {
                    setIdFront(value);
                  }}
                />
              </Grid>
              {!isEmpty(identityBack) && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFiles.front}
                        disabled={view !== VIEWS.INITIAL}
                        onChange={(event) => {
                          setSelectedFiles({
                            ...selectedFiles,
                            front: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={selectedFiles.front ? 'Selected' : 'Deselected'}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {!isEmpty(identityBack) && (
            <Grid item container column xs={6}>
              <Grid item>
                <FileUpload
                  userId={userId}
                  files={idBack}
                  config={{ readonly: true }}
                  onChange={(value) => {
                    setIdBack(value);
                  }}
                />
              </Grid>
              {!isEmpty(identityFront) && (
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFiles.back}
                        disabled={view !== VIEWS.INITIAL}
                        onChange={(event) => {
                          setSelectedFiles({
                            ...selectedFiles,
                            back: event.target.checked,
                          });
                        }}
                      />
                    }
                    label={selectedFiles.back ? 'Selected' : 'Deselected'}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {(!isEmpty(identityFront) || !isEmpty(identityBack)) && (
            <Grid item xs={12} sx={{ my: 2 }}>
              <Divider />
            </Grid>
          )}
        </Grid>
        <Conditionally>
          <If condition={view === VIEWS.INITIAL}>
            <Grid container column alignItems="center" spacing={2}>
              <Grid item container column>
                <Typography variant="p1">
                  {!isEmpty(identityFront) && !isEmpty(identityBack)
                    ? 'Please verify the files above. Uncheck the file that does not not have any user information.'
                    : 'Please verify the file above.'}
                </Typography>
                <Typography variant="p1">
                  Once verified. Please click <i>Confirm & start extraction</i>.
                </Typography>
                <Typography variant="p1">
                  Kindly notice that this operation takes up to a couple of
                  minutes, and the dialog has to remain open.
                </Typography>
              </Grid>
            </Grid>
          </If>
          <ElseIf condition={view === VIEWS.PROCESSING}>
            <Grid container column alignItems="center" spacing={2}>
              <Grid item>
                <AccessTimeIcon fontSize="large" />
              </Grid>
              <Grid item container column>
                <Typography variant="p1">
                  Please wait a couple of minutes until we extract the
                  information.
                </Typography>
                <Typography variant="p1">
                  Please keep this dialog open.
                </Typography>
                <Typography variant="p1">Thanks for your patience.</Typography>
              </Grid>
              <Grid item sx={{ width: '100%', my: 2 }}>
                <LinearProgress />
              </Grid>
            </Grid>
          </ElseIf>
          <ElseIf condition={view === VIEWS.FORM}>
            {!isEmpty(
              values(parsedExtractedIdentity || {}).filter(Boolean)
            ) && (
              <Form
                ref={ref}
                onSubmit={(data) => {
                  saveExtractedIdentityData(userId, data, () => {
                    onClosed(true);
                    Message.success(
                      'Extracted information has been saved successfully'
                    );
                  });
                }}
              >
                {({ control }) => (
                  <Grid container column spacing={1}>
                    <Grid item container justifyContent="space-between">
                      <Grid item>
                        <Typography variant="l3">Field</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="l3">Confidence</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={10}>
                        <FormField
                          label="Identity Number"
                          field={
                            <Controller
                              name="identityNumber"
                              control={control}
                              defaultValue={
                                parsedExtractedIdentity?.identityNumber?.Text ||
                                ''
                              }
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="medium"
                                  value={value}
                                  variant="outlined"
                                  onChange={onChange}
                                  error={
                                    (parsedExtractedIdentity?.identityNumber
                                      ?.Confidence || 0) < CONFIDENCE_LIMIT
                                  }
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          textAlign: 'right',
                          pt: 4.5,
                        }}
                      >
                        %
                        {Math.round(
                          parsedExtractedIdentity?.identityNumber?.Confidence ||
                            0
                        )}
                      </Grid>
                    </Grid>
                    {/*  */}
                    <Grid item container>
                      <Grid item xs={10}>
                        <FormField
                          label="Full Name"
                          field={
                            <Controller
                              name="fullName"
                              control={control}
                              defaultValue={
                                extractedIdentity?.fullName?.Text || ''
                              }
                              render={({ field: { onChange, value } }) => (
                                <TextField
                                  fullWidth
                                  size="medium"
                                  value={value}
                                  variant="outlined"
                                  onChange={onChange}
                                  error={
                                    (extractedIdentity?.fullName?.Confidence ||
                                      0) < CONFIDENCE_LIMIT
                                  }
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          textAlign: 'right',
                          pt: 4.5,
                        }}
                      >
                        %
                        {Math.round(
                          parsedExtractedIdentity?.fullName?.Confidence || 0
                        )}
                      </Grid>
                    </Grid>
                    {/*  */}
                    <Grid item container>
                      <Grid item xs={10}>
                        <FormField
                          label="Date of Birth"
                          field={
                            <Controller
                              name="dateOfBirth"
                              control={control}
                              defaultValue={
                                parsedExtractedIdentity?.dateOfBirth?.Text || ''
                              }
                              render={({ field: { onChange, value } }) => (
                                <DatePicker
                                  name="dateOfBirth"
                                  autoComplete="false"
                                  value={value}
                                  onChange={onChange}
                                  inputFormat={dateFormat}
                                  renderInput={(props) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      onChange={onChange}
                                      {...props}
                                      error={
                                        (parsedExtractedIdentity?.dateOfBirth
                                          ?.Confidence || 0) < CONFIDENCE_LIMIT
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          textAlign: 'right',
                          pt: 4.5,
                        }}
                      >
                        %
                        {Math.round(
                          parsedExtractedIdentity?.dateOfBirth?.Confidence || 0
                        )}
                      </Grid>
                    </Grid>
                    {/*  */}
                    <Grid item container>
                      <Grid item xs={10}>
                        <FormField
                          label="Gender"
                          field={
                            <Controller
                              name="gender"
                              control={control}
                              defaultValue={
                                parsedExtractedIdentity?.gender?.Text || null
                              }
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  fullWidth
                                  soak="light"
                                  size="medium"
                                  value={value}
                                  variant="outlined"
                                  onChange={onChange}
                                  renderValue={(val) =>
                                    GENDER[val]?.label || val
                                  }
                                  error={
                                    (parsedExtractedIdentity?.gender
                                      ?.Confidence || 0) < CONFIDENCE_LIMIT
                                  }
                                >
                                  {keys(GENDER).map((type) => (
                                    <MenuItem key={type} value={type}>
                                      {GENDER[type].label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          textAlign: 'right',
                          pt: 4.5,
                        }}
                      >
                        %
                        {Math.round(
                          parsedExtractedIdentity?.gender?.Confidence || 0
                        )}
                      </Grid>
                    </Grid>
                    {/*  */}
                    <Grid item container>
                      <Grid item xs={10}>
                        <FormField
                          label="Nationality"
                          field={
                            <Controller
                              name="nationality"
                              control={control}
                              defaultValue={
                                parsedExtractedIdentity?.nationality?.Text || ''
                              }
                              render={({ field: { onChange, value } }) => (
                                <CountrySelect
                                  fullWidth
                                  size="medium"
                                  value={value}
                                  variant="outlined"
                                  onChange={(_e, newValue) => {
                                    onChange(newValue);
                                  }}
                                  error={
                                    (parsedExtractedIdentity?.nationality
                                      ?.Confidence || 0) < CONFIDENCE_LIMIT
                                  }
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          textAlign: 'right',
                          pt: 4.5,
                        }}
                      >
                        %
                        {Math.round(
                          parsedExtractedIdentity?.nationality?.Confidence || 0
                        )}
                      </Grid>
                    </Grid>
                    {/*  */}
                    <Grid item container>
                      <Grid item xs={10}>
                        <FormField
                          label="Identity Expiry Date"
                          field={
                            <Controller
                              name="expiryDate"
                              control={control}
                              defaultValue={
                                parsedExtractedIdentity?.expiryDate?.Text || ''
                              }
                              render={({ field: { onChange, value } }) => (
                                <DatePicker
                                  value={value}
                                  name="expiryDate"
                                  onChange={onChange}
                                  autoComplete="false"
                                  inputFormat={dateFormat}
                                  renderInput={(props) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      onChange={onChange}
                                      {...props}
                                      error={
                                        (parsedExtractedIdentity?.expiryDate
                                          ?.Confidence || 0) < CONFIDENCE_LIMIT
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          textAlign: 'right',
                          pt: 4.5,
                        }}
                      >
                        %
                        {Math.round(
                          parsedExtractedIdentity?.expiryDate?.Confidence || 0
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Form>
            )}
          </ElseIf>
          <ElseIf condition={view === VIEWS.ERROR}>
            {identityExtractionError || 'Something went wrong'}
          </ElseIf>
        </Conditionally>
      </DialogContent>
      <DialogActions>
        {view === VIEWS.INITIAL && (
          <Button
            variant="filled"
            disabled={isEmpty(values(selectedFiles).filter(Boolean))}
            onClick={() => {
              extractIdentity([
                ...(selectedFiles.front && identityFront?.[0]?.id
                  ? [identityFront[0].id]
                  : []),
                ...(selectedFiles.back && identityBack?.[0]?.id
                  ? [identityBack[0].id]
                  : []),
              ]);
              setView(VIEWS.PROCESSING);
            }}
          >
            Confirm & start extraction
          </Button>
        )}
        {view === VIEWS.PROCESSING && (
          <Button
            variant="text"
            color="error"
            onClick={() => {
              onClosed();
            }}
          >
            Cancel operation
          </Button>
        )}
        {view === VIEWS.FORM && (
          <>
            <Button
              variant="filled"
              disabled={isEmpty(
                values(
                  parsedExtractedIdentity ||
                    ref?.current?.getMethods()?.watch() ||
                    {}
                ).filter(Boolean)
              )}
              onClick={(e) => showPopConfirm(e)}
              spinning={isSavingExtractedIdentityData}
            >
              {isSavingExtractedIdentityData
                ? 'Saving extracted data..'
                : 'Confirm & save to patient account'}
            </Button>
            <PopConfirm
              primaryButtonTitle="Yes, I'm sure"
              secondaryButtonTitle="No, cancel"
              anchorEl={popConfirmAnchor}
              onOk={() => {
                closePopConfirm();
                ref.current.submit();
              }}
              open={Boolean(popConfirmAnchor)}
              onCancel={() => closePopConfirm()}
              id={popConfirmAnchor ? 'delete-confirm' : undefined}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              Are you sure the information is correct?
            </PopConfirm>
          </>
        )}
        {view === VIEWS.ERROR && (
          <Button
            variant="text"
            onClick={() => {
              onClosed();
            }}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default IdentityExtractionDialog;
