import * as types from './actionTypes';

export const createPaymentTransaction = (payload, callback) => ({
  type: types.CREATE_PAYMENT_TRANSACTION,
  payload,
  callback,
});

export const paymentTransactionCreated = (response) => ({
  type: types.PAYMENT_TRANSACTION_CREATED,
  response,
});

export const getPaymentTransactionByReference = (payload) => ({
  type: types.GET_PAYMENT_TRANSACTION_BY_REFERENCE,
  payload,
});

export const paymentTransactionReceived = (paymentTransaction) => ({
  type: types.PAYMENT_TRANSACTION_RECEIVED,
  paymentTransaction,
});

export const paymentTransactionCancelled = () => ({
  type: types.PAYMENT_TRANSACTION_CANCELLED,
});

export const setFetchingPaymentTransaction = (isFetching) => ({
  type: types.SET_FETCHING_PAYMENT_TRANSACTION,
  isFetching,
});

export const cancelPaymentTransaction = (payload) => ({
  type: types.CANCEL_PAYMENT_TRANSACTION,
  payload,
});
