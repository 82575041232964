import get from 'lodash/get';
import { locationUtils } from 'core/location';
import { ADDRESS_FIELDS as FIELDS } from '../constants';

export const getId = (address) => get(address, FIELDS.ID.name);
export const getArea = (address) => get(address, FIELDS.AREA.name);
export const getCity = (address) => get(address, FIELDS.CITY.name);
export const getStreet = (address) => get(address, FIELDS.STREET.name);
export const getCountry = (address) => get(address, FIELDS.COUNTRY.name);
export const getOrderId = (address) => get(address, FIELDS.ORDER_ID.name);
export const getPlaceId = (address) => get(address, FIELDS.PLACE_ID.name);
export const getLatitude = (address) => get(address, FIELDS.LATITUDE.name);
export const getBuilding = (address) => get(address, FIELDS.BUILDING.name);
export const getApartment = (address) => get(address, FIELDS.APARTMENT.name);
export const getLongitude = (address) => get(address, FIELDS.LONGITUDE.name);
export const getReferenceMapLink = (address) =>
  get(address, FIELDS.REFERENCE_MAP_LINK.name);
export const getFormattedAddress = (address) =>
  get(address, FIELDS.FORMATTED_ADDRESS.name);
export const getAddressLink = (address) =>
  locationUtils.getAddressMapUrl(address);
