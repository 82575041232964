import get from 'lodash/get';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import {
  all,
  put,
  call,
  take,
  fork,
  delay,
  cancel,
  takeLatest,
} from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import { takeSequential } from 'core/saga';
import { getHealthEndpoint } from 'core/endpoint';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';
import memberModelSaga from './member/saga';

const EXTRACTION_POLL_TIMEOUT = 30;

export function* fetchDoctors() {
  try {
    const response = yield call(httpClient.get, constants.DOCTORS_ENDPOINT);

    yield put(actions.doctorsFetchResponded(true, response.data.data));
  } catch (e) {
    yield put(
      actions.doctorsFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* fetchDrivers() {
  try {
    const response = yield call(httpClient.get, constants.DRIVERS_ENDPOINT);

    yield put(actions.driversFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.driversFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* fetchPharmacists() {
  try {
    const response = yield call(httpClient.get, constants.PHARMACISTS_ENDPOINT);

    yield put(actions.pharmacistsFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.pharmacistsFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* fetchUser({ id }) {
  const response = yield call(
    httpClient.get,
    constants.USER_BY_ID_ENDPOINT(id)
  );
  yield put(actions.userReceived(response.data));
}

export function* createUser({ payload }) {
  try {
    const response = yield call(httpClient.post, constants.ENDPOINT, payload);

    yield put(actions.userCreationResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.userCreationResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchPatientHealthInfo({ userId }) {
  const response = yield call(
    httpClient.get,
    `${getHealthEndpoint()}${constants.HEALTH_PROFILE_ENDPOINT}/${userId}`
  );
  yield put(actions.patientHealthInfoReceived(response.data));
}

export function* updateUser({ id, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      [constants.ENDPOINT, id].join('/'),
      payload
    );
    yield put(actions.userUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.userUpdateResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* updateUserHealthProfile({ payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      `${getHealthEndpoint()}${constants.HEALTH_PROFILE_ENDPOINT}`,
      payload
    );
    yield put(actions.updateHealthProfileResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.updateHealthProfileResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchUserAddresses({ id }) {
  const response = yield call(httpClient.get, constants.USER_ADDRESSES(id));

  yield put(actions.userAddressesReceived(response.data));
}

/**
 *
 * @param {Number} x: Iteration index - From 0 to EXTRACTION_POLL_TIMEOUT
 * @returns {Number} The milliseconds needed to wait for. Goes from 10s to 60s
 */
const durationEquation = (x) => {
  return Math.min((60 / EXTRACTION_POLL_TIMEOUT) * x + 10, 60) * 1000;
};

function* identityExtractionProcess(documents) {
  try {
    const response = yield call(
      httpClient.post,
      constants.IDENTITY_EXTRACTION_ENDPOINT,
      {
        documents,
      }
    );

    const jobs = response?.data?.jobs || [];

    let extractedData = null;
    let isSuccessful = false;

    yield put(actions.identityExtractionJobsReceived(jobs));

    if (!isEmpty(jobs)) {
      for (let i = 0; i < EXTRACTION_POLL_TIMEOUT; i += 1) {
        yield delay(durationEquation(i));

        const pollResponse = yield call(
          httpClient.post,
          constants.IDENTITY_EXTRACTION_POLL_ENDPOINT,
          jobs
        );

        extractedData = pollResponse?.data;

        if (!isEmpty(values(extractedData).filter(Boolean))) {
          isSuccessful = true;
          break;
        }
      }
    }

    if (isSuccessful) {
      yield put(actions.identityExtractionResponded(true, extractedData));
    } else {
      yield put(
        actions.identityExtractionResponded(
          false,
          'Could not extract any information from the specified identity files. Please refresh the page and try again, or report the issue the team'
        )
      );
    }
  } catch (e) {
    yield put(
      actions.identityExtractionResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page and try again, or report the issue the team'
        )
      )
    );
  }
}

function* cancelIdentityExtraction(jobs) {
  if (!isEmpty(jobs)) {
    yield call(httpClient.delete, constants.IDENTITY_EXTRACTION_ENDPOINT, jobs);
  }
}

export function* startIdentityExtraction({ documents }) {
  const backgroundProcess = yield fork(identityExtractionProcess, documents);
  const cancelAction = yield take(types.CANCEL_IDENTITY_EXTRACTION);

  yield cancel(backgroundProcess);
  yield call(cancelIdentityExtraction, cancelAction?.jobs);
}

export default function* userModelSaga() {
  yield all([
    takeSequential(types.FETCH_USER, fetchUser),
    takeLatest(types.CREATE_USER, createUser),
    takeLatest(types.FETCH_DOCTORS, fetchDoctors),
    takeLatest(types.FETCH_DRIVERS, fetchDrivers),
    takeLatest(types.FETCH_PHARMACISTS, fetchPharmacists),
    takeLatest(types.UPDATE_USER_PROFILE, updateUser),
    takeLatest(types.FETCH_USER_ADDRESSES, fetchUserAddresses),
    takeLatest(types.FETCH_PATIENT_HEALTH_INFO, fetchPatientHealthInfo),
    takeLatest(types.START_IDENTITY_EXTRACTION, startIdentityExtraction),
    takeLatest(types.UPDATE_USER_HEALTH_PROFILE, updateUserHealthProfile),
    memberModelSaga(),
  ]);
}
