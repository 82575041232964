const Conditionally = ({ children }) =>
  (children.length ? children : [children]).find((child) => {
    if (['If', 'ElseIf'].includes(child.type.identifier)) {
      return child.props.condition;
    } else if (child.type.identifier === 'Else') {
      return true;
    }
  }) || null;

export default Conditionally;

/**
 * Usage Example:
 * import { Conditionally, If, ElseIf, Else } from 'design-system';
 *
 *  <Conditionally>
 *    <If condition={condition1}>Renders this if condition1 is true</If>
 *    <ElseIf condition={condition2}>Renders this if condition1 is false and condition2 true</ElseIf>
 *    <Else>Renders this if condition1 and condition2 are false</Else>
 *  </Conditionally>
 */
