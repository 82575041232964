/* User model constants */
import { userManagementModelConstants } from 'model/userManagement';

export const PACKAGE_NAME = 'User';
export const ENDPOINT = '/user';
export const USER_BY_ID_ENDPOINT = (id) => `/user/${id}`;
export const USER_ADDRESSES = (id) => `/user/address/${id}`;
export const PATIENT_HISTORY_ENDPOINT = (id) => `/consultation/history/${id}`;
export const DOCTORS_ENDPOINT = '/user/doctors';
export const DRIVERS_ENDPOINT = '/drivers';
export const IDENTITY_EXTRACTION_ENDPOINT = '/identity-extraction';
export const IDENTITY_EXTRACTION_POLL_ENDPOINT = '/identity-extraction/poll';
export const USERS_BY_ROLE_ENDPOINT = (role) =>
  `${userManagementModelConstants.ENDPOINT}/roles/${role}`;
export const PHARMACISTS_ENDPOINT = USERS_BY_ROLE_ENDPOINT('Pharmacist');
export const HEALTH_PROFILE_ENDPOINT = '/profile/user';

export const USER_FILTER = {
  // AKA patient
  filter: 'roles||$eq||{user}',
};
