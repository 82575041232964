export const ELIGIBILITY_STATUS = {
  NOT_CHECKED: {
    key: 'NOT_CHECKED',
    label: 'Not Checked',
    color: 'warning',
  },
  ELIGIBLE: {
    key: 'ELIGIBLE',
    label: 'Eligible',
    color: 'success',
  },
  INELIGIBLE: {
    key: 'INELIGIBLE',
    label: 'Ineligible',
    color: 'error',
  },
};

export default ELIGIBILITY_STATUS;
