import { connect } from 'react-redux';

import { userActions, userSelectors } from 'domain/user';

import PatientHealthInfoEdit from './PatientHealthInfoEdit';

const mapStateToProps = (state) => {
  const healthInfo = userSelectors.getHealthInfo(state);
  const isFetchingHealth = userSelectors.isFetchingHealth(state);
  const isSubmitting = userSelectors.isSubmittingUser(state);

  return {
    healthInfo,
    isSubmitting,
    isFetchingHealth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHealthInfo: (userId) =>
    dispatch(userActions.fetchPatientHealthInfo(userId)),
  updateHealthProfile: (userId, payload) =>
    dispatch(userActions.updateHealthProfile(userId, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientHealthInfoEdit);
