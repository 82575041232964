/* eslint-disable import/prefer-default-export */
import httpClient from 'core/httpClient';

const cache = {};

/**
 * A wrapper over httpClient.get,
 * to prevent over-calling the same resource over the same session and prevent exhausting the network.
 *
 * Notice using cache in memory. When the tab is closed it gets cleared automatically.
 *
 * Best used when fetching multiple resources from a list where we don't guarantee duplicates.
 * E.g: Fetching users from the lab request list.
 *
 * Avoid using that overly with other resources (e.g: fetching an order, a consultation ..etc).
 *
 * Note: There's always room for improvement, you can use that but not strictly that.
 */
export const get = async (url, config) => {
  if (!cache[url]) {
    try {
      const response = await httpClient.get(url, config);

      cache[url] = response;
    } catch (e) {
      // In case of an error, don't cache it
      return e;
    }
  }

  return cache[url];
};
