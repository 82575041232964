import { all, put, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import { commentModelActions, commentModelActionTypes } from 'model/comment';

import * as actions from './actions';
import * as types from './actionTypes';
import { processComments } from './utils';

export function* fetchCommentsOf({ referenceType, referenceId }) {
  yield put(actions.clearCommentsOf(referenceType, referenceId));
  yield put(actions.setFetchingCommentsOf(referenceType, referenceId, true));
  yield put(commentModelActions.fetchCommentsOf(referenceType, referenceId));
}

export function* createCommentOf({ referenceType, referenceId, content }) {
  yield put(
    commentModelActions.createCommentOf(referenceType, referenceId, content)
  );
}

export function* commentCreated() {
  Message.success('Comment added successfully');
}

export function* commentsReceived({ comments, referenceType, referenceId }) {
  const sortedComments = comments.sort((a, b) => b.id - a.id);
  const processedComments = processComments(sortedComments);

  yield put(
    actions.commentsReceived(processedComments, referenceType, referenceId)
  );
  yield put(actions.setFetchingCommentsOf(referenceType, referenceId, false));
}

export default function* commentModelSaga() {
  yield all([
    takeLatest(types.FETCH_COMMENTS_OF, fetchCommentsOf),
    takeLatest(types.CREATE_COMMENT_OF, createCommentOf),
    takeLatest(commentModelActionTypes.COMMENT_CREATED, commentCreated),
    takeLatest(commentModelActionTypes.COMMENTS_RECEIVED, commentsReceived),
  ]);
}
