import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect, useRef } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import { userGetters } from 'domain/user';
import MemberRow from 'presentation/user/components/MemberManagement/components/MemberRow';
import SelectMemberDialog from 'presentation/components/MemberSelection/components/SelectMemberDialog';
import CreateMemberDialog from 'presentation/components/MemberSelection/components/CreateMemberDialog';
import {
  If,
  Else,
  Grid,
  Popup,
  Button,
  ElseIf,
  Skeleton,
  Typography,
  Conditionally,
  ShowMoreShowLess,
} from 'design-system';

import { SIZE_CONFIG } from './constants';

const MemberSelection = ({
  owner,
  referenceId,
  allMembers,
  members = [],
  loading,
  isDeleting,
  isSubmitting,
  isSubmittingMembers,
  isFetching,
  size = 'medium',
  undeleteMember,
  deleteMember,
  updateMembers,
  createMember,
  onMembersUpdated,
  onMemberCreated,
  fetchAllMembers,
  clearMembers,
  permissions = {},
}) => {
  const perms = {
    edit: true,
    delete: true,
    hideShow: true,
    ...permissions,
  };

  const formRef = useRef(null); // Reference to the MemberSelectForm

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [isAddNewMember, setIsAddNewMember] = useState(false);
  // check if the form has changes. Save the changes before adding new patient
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);

  useEffect(() => {
    if (owner?.id) {
      fetchAllMembers(owner.id);
    }

    return () => clearMembers();
  }, [owner?.id]);

  const onSendButtonClicked = () => {
    setConfirmPopupOpen(false);
    setCreateDialogOpen(false);
    setIsAddNewMember(true);
    formRef?.current?.submit();
  };

  return (
    <>
      <Grid container column>
        <Conditionally>
          <If condition={loading || isDeleting || isSubmitting}>
            {Array.from(Array(members?.length || 3)).map((idx) => (
              // Show members' length or 3 skeletons while waiting
              <Grid
                item
                key={`${idx}`}
                sx={{
                  height: (theme) => theme.spacing(SIZE_CONFIG.rowHeight[size]),
                }}
              >
                <Skeleton
                  variant="rectangle"
                  sx={{
                    height: (theme) =>
                      theme.spacing(SIZE_CONFIG.innerRowHeight[size]),
                  }}
                />
              </Grid>
            ))}
          </If>
          <ElseIf condition={members?.length === 0}>
            <Typography variant={SIZE_CONFIG.typographyVariant[size]}>
              No patients available
            </Typography>
          </ElseIf>
          <Else>
            <ShowMoreShowLess
              limit={5}
              data={members}
              renderItem={({ item: member }) => {
                return (
                  <Grid
                    item
                    container
                    key={userGetters.getPhoneNumber(member)}
                    sx={{
                      height: (theme) =>
                        theme.spacing(SIZE_CONFIG.rowHeight[size]),
                      borderBottomWidth: 1,
                      borderBottomStyle: 'solid',
                      borderBottomColor: (theme) => theme.palette.grey[200],
                    }}
                  >
                    <MemberRow
                      member={member}
                      readonly={!perms.hideShow}
                      size={size}
                      deletable={perms.delete && members?.length > 1}
                      referenceId={referenceId}
                      isDeleting={isDeleting}
                      undeleteMember={undeleteMember}
                      deleteMember={() =>
                        deleteMember(referenceId, member.userId)
                      }
                      disabled={{
                        delete: !isEmpty(member.output),
                      }}
                    />
                  </Grid>
                );
              }}
            />
          </Else>
        </Conditionally>
        {perms.edit && (
          <Button
            variant="text"
            loading={isSubmitting || loading}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            spinning={isFetching || isSubmittingMembers}
            onClick={() => setCreateDialogOpen(true)}
          >
            {members?.length ? 'Update patient list' : 'Add a patient'}
          </Button>
        )}
      </Grid>
      <SelectMemberDialog
        referenceId={referenceId}
        allMembers={
          !isEmpty(owner) // Owner must exist
            ? [owner, ...allMembers]
            : []
        }
        members={members}
        open={isCreateDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        updateMembers={updateMembers}
        isSubmittingMembers={isSubmittingMembers}
        onMembersUpdated={onMembersUpdated}
        formRef={formRef}
        setIsFormChanged={setIsFormChanged}
        permissions={{
          delete: perms.delete,
        }}
        footer={
          <Button
            size="medium"
            variant="text"
            color="primary"
            onClick={() => {
              if (isFormChanged) {
                setConfirmPopupOpen(true);
                setIsFormChanged(false);
              } else {
                setCreateDialogOpen(false);
                setIsAddNewMember(true);
              }
            }}
            sx={{
              mr: 1,
            }}
          >
            Add a new patient
          </Button>
        }
      />
      <Popup
        id={isConfirmPopupOpen ? 'member-selection-changes-confirm' : undefined}
        open={isConfirmPopupOpen}
        onOk={onSendButtonClicked}
        onCancel={() => {
          setIsAddNewMember(true);
          setConfirmPopupOpen(false);
          setCreateDialogOpen(false);
        }}
        primaryButtonTitle="Save changes"
        secondaryButtonTitle="Discard changes"
        title="Do you want save the selected patients?"
      >
        You did not save the patient list. Please save before adding a new
        member
      </Popup>
      <CreateMemberDialog
        owner={owner}
        referenceId={referenceId}
        open={isAddNewMember && !isConfirmPopupOpen}
        membersList={members}
        isSubmitting={isSubmitting}
        updateMembers={updateMembers}
        createMember={createMember}
        onMemberCreated={onMemberCreated}
        setIsFormChanged={setIsFormChanged}
        onClose={() => setIsAddNewMember(false)}
      />
    </>
  );
};

export default MemberSelection;
