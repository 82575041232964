import { all, put, take, takeLatest } from 'redux-saga/effects';

import { clinicModelActions, clinicModelActionTypes } from 'model/clinic';

import * as actions from './actions';
import * as types from './actionTypes';

export function* getClinics() {
  yield put(clinicModelActions.fetchClinics());

  const { ok, response } = yield take(
    clinicModelActionTypes.CLINICS_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.clinicsReceived(response));
  }
}

export default function* clinicSaga() {
  yield all([takeLatest(types.GET_CLINICS, getClinics)]);
}
