import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as DOCTOR_NOTES } from './constants';

export const FETCH_DOCTOR_NOTES = `${MODEL}/${DOCTOR_NOTES}/FETCH_DOCTOR_NOTES`;
export const SUBMIT_DOCTOR_NOTES = `${MODEL}/${DOCTOR_NOTES}/SUBMIT_DOCTOR_NOTES`;
export const CREATE_PATIENT_ASSESSMENT_SHEET = `${MODEL}/${DOCTOR_NOTES}/CREATE_PATIENT_ASSESSMENT_SHEET`;

export const DOCTOR_NOTES_FETCH_RESPONDED = `${MODEL}/${DOCTOR_NOTES}/DOCTOR_NOTES_FETCH_RESPONDED`;
export const DOCTOR_NOTES_SUBMISSION_RESPONDED = `${MODEL}/${DOCTOR_NOTES}/DOCTOR_NOTES_SUBMISSION_RESPONDED`;
export const PATIENT_ASSESSMENT_SHEET_CREATION_RESPONDED = `${MODEL}/${DOCTOR_NOTES}/PATIENT_ASSESSMENT_SHEET_CREATION_RESPONDED`;
