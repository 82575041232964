import get from 'lodash/get';
import { createSelector } from 'reselect';

export const memberSelector = (state) => get(state, 'domain.user.member', {});

export const getMembers = createSelector(memberSelector, (results) =>
  get(results, 'members')
);

export const getMembersHealthProfiles = createSelector(
  memberSelector,
  (results) => get(results, 'membersHealthProfiles')
);

export const getBasicMembers = createSelector(memberSelector, (results) =>
  get(results, 'basicMembers')
);

export const isFetchingMembers = createSelector(memberSelector, (results) =>
  get(results, 'isFetchingMembers')
);

export const isFetchingMembersHealthProfiles = createSelector(
  memberSelector,
  (results) => get(results, 'isFetchingMembersHealthProfiles')
);

export const isFetchingBasicMembers = createSelector(
  memberSelector,
  (results) => get(results, 'isFetchingBasicMembers')
);

export const isSubmittingMember = createSelector(memberSelector, (results) =>
  get(results, 'isSubmittingMember')
);

export const isDeletingMember = createSelector(memberSelector, (results) =>
  get(results, 'isDeletingMember')
);
