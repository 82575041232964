import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { dateTimeFormat } from 'constants/dateTime';
import isValid from 'date-fns/isValid';
import formatISO from 'date-fns/formatISO';

export const getColumns = () => [
  { field: 'id', headerName: 'ID', width: 80 },
  { field: 'deviceId', headerName: 'Device ID', flex: 1 },
  { field: 'city', headerName: 'City', width: 100 },
  { field: 'country', headerName: 'Country', width: 80 },
  { field: 'detectedCity', headerName: 'Detected City', width: 100 },
  { field: 'detectedCountry', headerName: 'Detected Country', width: 130 },
  { field: 'mobileVersion', headerName: 'Mobile App Version', flex: 1 },
  { field: 'userId', headerName: 'User Id', width: 80 },
  { field: 'platform', headerName: 'Platform', flex: 1 },
  { field: 'platformVersion', headerName: 'Platform Version', flex: 1 },
  {
    field: 'createdAt',
    headerName: 'First Open',
    flex: 2,
    valueGetter: (params) =>
      format(parseISO(params.row?.createdAt), dateTimeFormat),
  },
  {
    field: 'lastActive',
    headerName: 'Last Active',
    flex: 2,
    valueGetter: (params) =>
      format(parseISO(params.row?.lastActive), dateTimeFormat),
  },
];

export const getFilterFieldsConfig = () => [
  {
    name: 'firstOpenFrom',
    serverName: 'createdAt',
    operation: 'gte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T00:00:00`
        : value;
    },
  },
  {
    name: 'firstOpenUntil',
    serverName: 'createdAt',
    operation: 'lte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T23:59:59`
        : value;
    },
  },
  {
    name: 'lastActiveFrom',
    serverName: 'lastActive',
    operation: 'gte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T23:59:59`
        : value;
    },
  },
  {
    name: 'lastActiveUntil',
    serverName: 'lastActive',
    operation: 'lte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T23:59:59`
        : value;
    },
  },

  {
    name: 'city',
    serverName: 'city',
  },
  {
    name: 'userId',
    serverName: 'userId',
  },
];
