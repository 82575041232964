import React, { useRef, useMemo, useState, useCallback } from 'react';

import { userGetters } from 'domain/user';
import { Form, Controller } from 'design-system/form';
import { consultationGetters } from 'domain/consultation';
import { OUTCOME_OPTIONS } from 'domain/outcome/constants';
import { Grid, FormField, Button, TextField } from 'design-system';
import {
  FIELDS,
  MANAGEMENT_AND_TREATMENT_PLAN_FIELDS,
} from 'domain/doctorNotes/constants';

import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const ManagementAndTreatmentPlanForm = ({
  // Owner's user profile
  owner,
  // Consultation Patient's ID
  patientId,
  // Patient's user ID
  userId,
  // Relevant consultation
  consultation,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Selected consultation outcomes
  consultationOutcomes,
  // Action to update/create
  submitManagementTreatmentPlanForm,
  // Get doctor notes by Id from state
  getDoctorNotesById,
  // Create consultation outcome action
  createOutcome,
}) => {
  const formRef = useRef();

  const [isSaving, setSaving] = useState(false);

  const patientManagementTreatmentPlan = useMemo(() => {
    return getDoctorNotesById(userId)?.[
      FIELDS.MANAGEMENT_AND_TREATMENT_PLAN.key
    ];
  }, [getDoctorNotesById, userId]);

  const onSubmit = useCallback(
    (formData) => {
      const consultationId = consultationGetters.getId(consultation);

      setSaving(true);
      submitManagementTreatmentPlanForm(
        userId,
        consultationId,
        {
          [FIELDS.USER_ID.key]: userId,
          [FIELDS.OWNER_ID.key]: userGetters.getId(owner),
          [FIELDS.CONSULTATION_ID.key]: consultationId,
          [FIELDS.MANAGEMENT_AND_TREATMENT_PLAN.key]: {
            ...formData,
            [MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.ID.key]:
              patientManagementTreatmentPlan?.[
                MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.ID.key
              ],
          },
        },
        () => {
          onSubmitted(formData);
          setSaving(false);
        }
      );

      if (
        !consultationOutcomes.includes(
          OUTCOME_OPTIONS.ScheduleForFollowUp.key
        ) &&
        formData[MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.FOLLOW_UP.key]
      ) {
        // If follow up is added, then add schedule for follow up outcome (If it doesn't exist before)
        createOutcome(consultationId, patientId, {
          tenantKey: userGetters.getTenant(owner),
          referenceType: OUTCOME_OPTIONS.ScheduleForFollowUp.key,
        });
      }
    },
    [
      owner,
      userId,
      patientId,
      onSubmitted,
      consultation,
      createOutcome,
      consultationOutcomes,
      patientManagementTreatmentPlan,
      submitManagementTreatmentPlanForm,
    ]
  );

  return (
    <Form ref={formRef} onSubmit={onSubmit} defaultValues={defaultValues}>
      {({ control }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.key}
          title={SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.label}
          actions={
            <Button
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              spinning={isSaving}
              onClick={() => {
                formRef?.current?.submit();
              }}
            >
              Save and continue
            </Button>
          }
        >
          <Grid container spacing={3}>
            <FormField
              xs={12}
              md={6}
              required
              label="MANAGEMENT PLAN"
              field={
                <Controller
                  name={
                    MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.MANAGEMENT_PLAN.key
                  }
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add management plan"
                      onChange={field.onChange}
                      error={Boolean(error)}
                      helperText={error?.message ?? null}
                    />
                  )}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value || !value.trim()) {
                          return 'Management plan is required';
                        }
                        return true; // Indicates validation success
                      },
                    },
                  }}
                />
              }
            />
            <FormField
              xs={12}
              md={6}
              required
              label="HEALTH EDUCATION"
              field={
                <Controller
                  name={
                    MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.HEALTH_EDUCATION.key
                  }
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add health education"
                      onChange={field.onChange}
                      error={Boolean(error)}
                      helperText={error?.message ?? null}
                    />
                  )}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value || !value.trim()) {
                          return 'Health education is required';
                        }
                        return true; // Indicates validation success
                      },
                    },
                  }}
                />
              }
            />
            <FormField
              xs={12}
              md={6}
              label="FOLLOW UP"
              field={
                <Controller
                  name={MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.FOLLOW_UP.key}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      size="medium"
                      variant="filled"
                      value={field.value}
                      placeholder="Add follow up"
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Section>
      )}
    </Form>
  );
};

export default ManagementAndTreatmentPlanForm;
