import { connect } from 'react-redux';

import { consultationActions } from 'domain/consultation';
import { doctorNotesActions, doctorNotesSelectors } from 'domain/doctorNotes';

import DoctorPrivateNotesForm from './DoctorPrivateNotesForm';

const mapStateToProps = (state) => {
  const getDoctorNotesById = (userId) =>
    doctorNotesSelectors.getDoctorNotesById(state, userId);
  return {
    getDoctorNotesById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateConsultationOutcomes: (
    consultationId,
    patientId,
    outcomes,
    tenantKey
  ) =>
    dispatch(
      consultationActions.updateConsultationPatient(consultationId, patientId, {
        output: outcomes.map((outcomeKey) => ({
          tenantKey,
          referenceType: outcomeKey,
        })),
      })
    ),
  submitDoctorPrivateNotesForm: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      doctorNotesActions.submitDoctorNotes(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorPrivateNotesForm);
