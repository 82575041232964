import isEmpty from 'lodash/isEmpty';
import React, { useRef, useMemo, useState, useCallback } from 'react';

import { userGetters } from 'domain/user';
import { outcomeConstants } from 'domain/outcome';
import { useDeviceSize } from 'design-system/hooks';
import { Form, Controller } from 'design-system/form';
import { consultationGetters } from 'domain/consultation';
import {
  Grid,
  Radio,
  Button,
  FormField,
  TextField,
  Typography,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
} from 'design-system';
import {
  FIELDS,
  DOCTOR_PRIVATE_NOTES_FIELDS,
} from 'domain/doctorNotes/constants';

import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const {
  OUTCOME_OPTIONS,
  DOCTOR_PRIVATE_NOTES_OPTIONS,
  SORTED_DOCTOR_PRIVATE_NOTES_OPTIONS,
} = outcomeConstants;

const DoctorPrivateNotesForm = ({
  // Owner's user profile
  owner,
  // Patient's user ID
  userId,
  // Patient
  patient,
  // Relevant consultation
  consultation,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Action to update/create
  submitDoctorPrivateNotesForm,
  // Get doctor notes by Id from state
  getDoctorNotesById,
  // Update consultation outcomes for a patient
  updateConsultationOutcomes,
}) => {
  const formRef = useRef();
  const { isMedium } = useDeviceSize();

  const [isSaving, setSaving] = useState(false);

  const patientDoctorPrivateNotes = useMemo(() => {
    return getDoctorNotesById(userId)?.[FIELDS.DOCTOR_PRIVATE_NOTES.key];
  }, [getDoctorNotesById, userId]);

  // If there are outcomes with no NoShow
  const selectedOutcomesWithNoNoShow = useMemo(() => {
    return !isEmpty(
      (patient?.output || [])
        .map((outcome) => outcome.referenceType)
        .filter((outcomeKey) => outcomeKey !== OUTCOME_OPTIONS.NoShow.key)
    );
  }, [patient]);

  const onSubmit = useCallback(
    (formData) => {
      const consultationId = consultationGetters.getId(consultation);
      const { mappedOutcome } = DOCTOR_PRIVATE_NOTES_OPTIONS[formData.option];
      const isMappedToNoShow = mappedOutcome === OUTCOME_OPTIONS.NoShow.key;
      const tenantKey = userGetters.getTenant(
        consultationGetters.getUser(consultation)
      );

      setSaving(true);
      submitDoctorPrivateNotesForm(
        userId,
        consultationId,
        {
          [FIELDS.USER_ID.key]: userId,
          [FIELDS.OWNER_ID.key]: userGetters.getId(owner),
          [FIELDS.CONSULTATION_ID.key]: consultationId,
          [FIELDS.DOCTOR_PRIVATE_NOTES.key]: {
            ...formData,
            [DOCTOR_PRIVATE_NOTES_FIELDS.ID.key]:
              patientDoctorPrivateNotes?.[DOCTOR_PRIVATE_NOTES_FIELDS.ID.key],
          },
        },
        () => {
          onSubmitted(formData);
          setSaving(false);
        }
      );

      if (isMappedToNoShow) {
        // Remove all other outcomes and set to no show
        updateConsultationOutcomes(
          consultationId,
          patient.id,
          [OUTCOME_OPTIONS.NoShow.key],
          tenantKey
        );
      } else {
        // In case "consent" options were selected
        const consultationOutcomes = (patient?.output || []).map(
          (outcome) => outcome.referenceType
        );

        if (consultationOutcomes.includes(OUTCOME_OPTIONS.NoShow.key)) {
          // and if NoShow outcome is selected on the patient level
          // Remove NoShow consultation outcome
          updateConsultationOutcomes(
            consultationId,
            patient.id,
            [],
            tenantKey /* Means nothing, because outcomes is [] */
          );
        }
      }
    },
    [
      owner,
      userId,
      patient,
      onSubmitted,
      consultation,
      patientDoctorPrivateNotes,
      updateConsultationOutcomes,
      submitDoctorPrivateNotesForm,
    ]
  );

  return (
    <Form ref={formRef} onSubmit={onSubmit} defaultValues={defaultValues}>
      {({ control }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.DOCTOR_PRIVATE_NOTES.key}
          title={SECTIONS.DOCTOR_PRIVATE_NOTES.label}
          actions={
            <Button
              spinning={isSaving}
              onClick={() => {
                formRef?.current?.submit();
              }}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              Save and continue
            </Button>
          }
        >
          <Grid container column rowSpacing={3}>
            <Grid item>
              <Controller
                name={DOCTOR_PRIVATE_NOTES_FIELDS.OPTION.key}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <RadioGroup
                      column={isMedium}
                      row={!isMedium}
                      value={field.value}
                      aria-labelledby="doctor-private-notes-status-radio-buttons-group-label"
                      name="doctor-private-notes-status-radio-buttons-group"
                      onChange={field.onChange}
                      sx={{
                        justifyContent: {
                          xs: 'flex-start',
                          md: 'space-between',
                        },
                      }}
                    >
                      {SORTED_DOCTOR_PRIVATE_NOTES_OPTIONS.map(
                        (privateDoctorNoteOption) => (
                          <FormControlLabel
                            value={privateDoctorNoteOption.key}
                            control={
                              <Radio
                                size="small"
                                disabled={
                                  // If there's an outcome that's not a NoShow
                                  selectedOutcomesWithNoNoShow &&
                                  // and this option is mapped to no show
                                  privateDoctorNoteOption.mappedOutcome ===
                                    OUTCOME_OPTIONS.NoShow.key
                                }
                              />
                            }
                            label={
                              <Typography variant="l4">
                                {privateDoctorNoteOption.label}
                              </Typography>
                            }
                          />
                        )
                      )}
                    </RadioGroup>
                    {error ? (
                      <FormHelperText error>{error?.message}</FormHelperText>
                    ) : undefined}
                  </>
                )}
                rules={{
                  required: 'This field is required',
                }}
              />
            </Grid>
            <FormField
              label="NOTES"
              field={
                <Controller
                  name={DOCTOR_PRIVATE_NOTES_FIELDS.REMARKS.key}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add any remarks..."
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Section>
      )}
    </Form>
  );
};

export default DoctorPrivateNotesForm;
