import React from 'react';
import useTheme from '@mui/material/styles/useTheme';

import Box from '../Box';

const Link = ({
  href,
  onClick,
  disabled,
  children,
  color = 'primary',
  target = '_blank',
  enablePropagation = false,
  sx = {},
}) => {
  const theme = useTheme();

  return (
    <Box
      component="span"
      sx={{
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& > a': {
          color: theme.palette[color]?.main,
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette[color]?.dark,
            textDecoration: 'underline',
          },
        },
        ...sx,
      }}
    >
      {disabled ? (
        children
      ) : (
        <a
          href={href}
          target={target}
          style={enablePropagation && onClick ? { cursor: 'pointer' } : {}}
          onClick={(e) => {
            if (!enablePropagation) {
              e.stopPropagation();
            } else if (onClick) {
              onClick();
            }
          }}
        >
          {children}
        </a>
      )}
    </Box>
  );
};

export default Link;
