import get from 'lodash/get';

import { FIELDS } from './constants';

export const getUser = (obj) => get(obj, FIELDS.USER.name);

export const getId = (user) => get(user, FIELDS.ID.name);

export const getUserId = (user) => get(user, FIELDS.USER_ID.name);

export const getFullName = (user) => get(user, FIELDS.FULL_NAME.name);

export const getReferenceFullName = (user) =>
  get(user, FIELDS.REFERENCE_FULL_NAME.name);

export const getPhoneNumber = (user) => get(user, FIELDS.PHONE_NUMBER.name);

export const getSignUpSource = (user) => get(user, FIELDS.SIGN_UP_SOURCE.name);

export const getTag = (user) => get(user, FIELDS.TAG.name);

export const getTags = (user) =>
  get(user, FIELDS.TAGS.name, '')?.split(',') || [];

export const getAddresses = (user) => get(user, FIELDS.ADDRESSES.name, []);

export const getCreatedAt = (user) => get(user, FIELDS.CREATED_AT.name);

export const getUpdatedAt = (user) => get(user, FIELDS.UPDATED_AT.name);

export const getLanguage = (user) => get(user, FIELDS.LANGUAGE.name);

export const getLastActive = (user) => get(user, FIELDS.LAST_ACTIVE.name);

export const getStatus = (user) => get(user, FIELDS.STATUS.name);

export const isVerified = (user) => get(user, FIELDS.IS_VERIFIED.name);

export const getInsuranceProvider = (user) =>
  get(user, FIELDS.INSURANCE_PROVIDER.name);

export const getInsuranceProviderId = (user) =>
  get(user, FIELDS.INSURANCE_PROVIDER_ID.name);

export const getIdentityNumber = (user) =>
  get(user, FIELDS.IDENTITY_NUMBER.name);

export const getIdentityBack = (user) => get(user, FIELDS.IDENTITY_BACK.name);

export const getIdentityFront = (user) => get(user, FIELDS.IDENTITY_FRONT.name);

export const getCity = (user) => get(user, FIELDS.CITY.name);

export const getTenant = (user) => get(user, FIELDS.TENANT_KEY.name);

export const getNationality = (user) =>
  get(user, FIELDS.NATIONALITY.name) || '';

export const getEmail = (user) => get(user, FIELDS.EMAIL.name);

export const getEmergencyContacts = (user) =>
  get(user, FIELDS.EMERGENCY_CONTACTS.name);

export const getExternalReferenceNumber = (user) =>
  get(user, FIELDS.EXTERNAL_REFERENCE_NUMBER.name);

export const getInsuranceCardNumber = (user) =>
  get(user, FIELDS.INSURANCE_CARD_NUMBER.name);

export const getComments = (user) => get(user, FIELDS.COMMENTS.name);

export const getInsuranceType = (user) => get(user, FIELDS.INSURANCE_TYPE.name);

export const getInsuranceGroup = (user) =>
  get(user, FIELDS.INSURANCE_GROUP.name);

export const getInsurancePlan = (user) => get(user, FIELDS.INSURANCE_PLAN.name);

export const getInsuranceValidUntil = (user) =>
  get(user, FIELDS.INSURANCE_VALID_UNTIL.name);

export const getInsuranceCardFileName = (user) =>
  get(user, FIELDS.INSURANCE_CARD_FILE_NAME.name);

export const getCreatedByUser = (user) =>
  get(user, FIELDS.CREATED_BY_USER.name);

export const getUpdatedByUser = (user) =>
  get(user, FIELDS.UPDATED_BY_USER.name);

export const getNamespace = (user) => get(user, FIELDS.NAMESPACE_KEY.name);

export const getOwnershipType = (user) => get(user, FIELDS.OWNERSHIP_TYPE.name);

export const getOwnerId = (user) => get(user, FIELDS.OWNER_ID.name);
