import { takeLatest, all, put } from 'redux-saga/effects';
import {
  anonymousUserModelActionTypes,
  anonymousUserModelActions,
} from 'model/anonymousUser';
import * as actions from './actions';
import * as types from './actionTypes';

export function* fetchAnonymousUser({ id }) {
  yield put(actions.setFetching(true));
  yield put(anonymousUserModelActions.fetchAnonymousUser(id));
}

export function* anonymousUserReceived({ anonymousUser }) {
  yield put(actions.anonymousUserReceived(anonymousUser));
  yield put(actions.setFetching(false));
}

export default function* anonymousUserSaga() {
  yield all([
    takeLatest(types.FETCH_ANONYMOUS_USER, fetchAnonymousUser),
    takeLatest(
      anonymousUserModelActionTypes.ANONYMOUS_USER_RECEIVED,
      anonymousUserReceived
    ),
  ]);
}
