import keys from 'lodash/keys';
import { v4 as uuidv4 } from 'uuid';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import isValid from 'date-fns/isValid';
import formatISO from 'date-fns/formatISO';
import React, { useRef, useMemo, useCallback } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { userConstants } from 'domain/user';
import { dateFormat } from 'constants/dateTime';
import { Controller, formValidationFunctions } from 'design-system/form';
import {
  Grid,
  Card,
  Chip,
  Button,
  Select,
  Message,
  Checkbox,
  MenuItem,
  TextField,
  FormField,
  DatePicker,
  ModalField,
  CardContent,
} from 'design-system';
import {
  FIELDS as USER_FIELDS,
  HEALTH_PROFILE_FIELDS,
} from 'domain/user/constants';

import AllergiesForm from '../AllergiesForm';
import AllergiesDisplay from './components/AllergiesDisplay';
import ChronicConditionsDisplay from './components/ChronicConditionsDisplay';

const { DISEASES } = userConstants;

const PatientInfoForm = ({
  owner,
  patient,
  formRef,
  control,
  formField,
  defaultValues,
  submitOwnerName,
  submitMemberName,
  submitOwnerHealthProfile,
  submitMemberHealthProfile,
  modifyConsultationPatientFullName,
}) => {
  const allergiesFormRef = useRef();

  const isOwner = useMemo(() => {
    return owner.id === patient.userId;
  }, [owner, patient]);

  const healthProfileUpdateFunction = useMemo(() => {
    return isOwner ? submitOwnerHealthProfile : submitMemberHealthProfile;
  }, [isOwner, submitOwnerHealthProfile, submitMemberHealthProfile]);

  const onHealthProfileFieldSave = useCallback(
    (field, fieldName, fieldMessage) =>
      async (formValue, callbacks, serverValueGetter = (value) => value) => {
        // Reflect the change onto the form state (Must come before the trigger statement below)
        field.onChange(formValue);

        const isValidField = await formRef.current
          .getMethods()
          .trigger(`${formField}.${fieldName}`, { shouldFocus: false });

        // Revert the value (Just for the UI to make sense)
        field.onChange(field.value);

        if (!isValidField) {
          callbacks?.error?.();
          return;
        }

        const serverValue = serverValueGetter(formValue);

        healthProfileUpdateFunction(
          patient.userId,
          {
            [fieldName]: serverValue,
            ...(!isOwner
              ? {
                  [HEALTH_PROFILE_FIELDS.OWNER_ID.name]: owner.id,
                }
              : {}),
          },
          async () => {
            // Call success callback coming from ModalField
            callbacks?.success?.();

            // Reflect the change onto the form state
            field.onChange(formValue);
            await formRef.current
              .getMethods()
              .trigger(`${formField}.${fieldName}`);

            // Show success message
            Message.success(`${fieldMessage} updated successfully`);
          }
        );
      },
    [patient, healthProfileUpdateFunction]
  );

  const handleAddAnotherAllergy = () => {
    const newAllergy = {
      id: uuidv4(), // Function to generate a random ID
    };

    const allergiesArray = allergiesFormRef?.current
      ?.getMethods?.()
      .getValues(HEALTH_PROFILE_FIELDS.ALLERGIES.name);
    const updatedAllergies = allergiesArray
      ? [...allergiesArray, newAllergy]
      : [newAllergy];

    allergiesFormRef?.current
      ?.getMethods?.()
      .setValue(HEALTH_PROFILE_FIELDS.ALLERGIES.name, updatedAllergies);
  };

  // Data is still loading
  if (!defaultValues) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <Grid
          container
          columnSpacing={4}
          rowSpacing={1}
          justifyContent="space-between"
        >
          <Grid item lg={3} md={6} sm={6} xs={12}>
            <Controller
              name={`${formField}.${USER_FIELDS.FULL_NAME.name}`}
              control={control}
              defaultValue={defaultValues[USER_FIELDS.FULL_NAME.name]}
              render={({ field, fieldState: { error } }) => (
                <ModalField
                  required
                  label="Patient Name"
                  error={error}
                  identifier="patient-name"
                  defaultValue={field.value}
                  valueGetter={(name) => name}
                  dialogProps={{
                    maxWidth: 'sm',
                  }}
                  onClosed={() => {
                    // Retrigger validation on close. In case an error was made but the modal was closed, make sure the validation error is gone
                    formRef.current
                      .getMethods()
                      .trigger(`${formField}.${USER_FIELDS.FULL_NAME.name}`);
                  }}
                  onSave={async (savedValue, callbacks) => {
                    // Reflect the change onto the form state (Must come before the trigger statement below)
                    field.onChange(savedValue);

                    const isValidField = await formRef.current
                      .getMethods()
                      .trigger(`${formField}.${USER_FIELDS.FULL_NAME.name}`);

                    // Revert the value (Just for the UI to make sense)
                    field.onChange(field.value);

                    if (!isValidField) {
                      callbacks?.error?.();
                      return;
                    }

                    const submitFunction = isOwner
                      ? submitOwnerName
                      : submitMemberName;

                    submitFunction(
                      patient.userId,
                      {
                        fullName: savedValue,
                        userId: patient.userId,
                        // Temp till get from DB
                        tenantKey: patient.tenantKey || 1,
                      },
                      async () => {
                        // Call success callback coming from ModalField
                        callbacks?.success?.();

                        // Reflect the change onto the form state
                        field.onChange(savedValue);
                        await formRef.current
                          .getMethods()
                          .trigger(
                            `${formField}.${USER_FIELDS.FULL_NAME.name}`,
                            { shouldFocus: false }
                          );

                        // Reflect the change onto consultation patients state
                        modifyConsultationPatientFullName(
                          patient.id,
                          savedValue
                        );

                        // Show success message
                        Message.success('Patient name updated successfully');
                      }
                    );
                  }}
                  render={({ value, onChange, isSaving }) => (
                    <FormField
                      required
                      label="PATIENT NAME"
                      field={
                        <TextField
                          fullWidth
                          autoFocus
                          value={value}
                          variant="filled"
                          disabled={isSaving}
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          placeholder="Enter patient name.."
                        />
                      }
                    />
                  )}
                />
              )}
              rules={{
                required: 'Patient full name is required',
              }}
            />
          </Grid>
          <Grid item lg={3} md={2} sm={6} xs={12}>
            <Controller
              name={`${formField}.${HEALTH_PROFILE_FIELDS.DATE_OF_BIRTH.name}`}
              control={control}
              defaultValue={
                defaultValues[HEALTH_PROFILE_FIELDS.DATE_OF_BIRTH.name]
              }
              render={({ field, fieldState: { error } }) => (
                <ModalField
                  required
                  label="Date Of Birth"
                  error={error}
                  identifier="date-of-birth"
                  defaultValue={field.value}
                  valueGetter={(val) => (val ? format(val, dateFormat) : val)}
                  dialogProps={{
                    maxWidth: 'sm',
                  }}
                  onClosed={() => {
                    formRef.current
                      .getMethods()
                      .trigger(
                        `${formField}.${HEALTH_PROFILE_FIELDS.DATE_OF_BIRTH.name}`
                      );
                  }}
                  onSave={async (savedValue, callbacks) => {
                    const serverValueGetter = (formValue) =>
                      isValid(formValue)
                        ? formatISO(formValue)?.split('T')[0]
                        : '';

                    await onHealthProfileFieldSave(
                      field,
                      HEALTH_PROFILE_FIELDS.DATE_OF_BIRTH.name,
                      'Date of birth'
                    )(savedValue, callbacks, serverValueGetter);
                  }}
                  render={({ value, onChange, isSaving }) => (
                    <FormField
                      required
                      label="DATE OF BIRTH"
                      field={
                        <DatePicker
                          value={value}
                          onChange={onChange}
                          disabled={isSaving}
                          maxDate={new Date()}
                          inputFormat={dateFormat}
                          renderInput={(props) => (
                            <TextField
                              fullWidth
                              variant="filled"
                              {...props}
                              error={Boolean(error)}
                              helperText={error?.message ?? null}
                            />
                          )}
                        />
                      }
                    />
                  )}
                />
              )}
              rules={{
                required: 'Patient date of birth is required',
              }}
            />
          </Grid>
          <Grid item lg={3} md={2} sm={6} xs={12}>
            <Controller
              name={`${formField}.${HEALTH_PROFILE_FIELDS.WEIGHT.name}`}
              control={control}
              defaultValue={defaultValues[HEALTH_PROFILE_FIELDS.WEIGHT.name]}
              render={({ field, fieldState: { error } }) => (
                <ModalField
                  required
                  unit="kg"
                  label="Weight"
                  identifier="weight"
                  error={error}
                  defaultValue={field.value}
                  valueGetter={(val) => val}
                  dialogProps={{
                    maxWidth: 'sm',
                  }}
                  onClosed={() => {
                    formRef.current
                      .getMethods()
                      .trigger(
                        `${formField}.${HEALTH_PROFILE_FIELDS.WEIGHT.name}`
                      );
                  }}
                  onSave={onHealthProfileFieldSave(
                    field,
                    HEALTH_PROFILE_FIELDS.WEIGHT.name,
                    'Weight'
                  )}
                  render={({ value, onChange, isSaving }) => (
                    <FormField
                      required
                      label="WEIGHT"
                      field={
                        <TextField
                          fullWidth
                          autoFocus
                          unit="KG"
                          type="number"
                          value={value}
                          variant="filled"
                          disabled={isSaving}
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          placeholder="Enter weight..."
                        />
                      }
                    />
                  )}
                />
              )}
              rules={{
                required: 'Patient weight is required',
                validate: {
                  positive: (v) => formValidationFunctions.positive(v),
                },
              }}
            />
          </Grid>
          <Grid item lg={3} md={2} sm={6} xs={12}>
            <Controller
              name={`${formField}.${HEALTH_PROFILE_FIELDS.HEIGHT.name}`}
              control={control}
              defaultValue={defaultValues[HEALTH_PROFILE_FIELDS.HEIGHT.name]}
              render={({ field, fieldState: { error } }) => (
                <ModalField
                  required
                  unit="CM"
                  label="Height"
                  identifier="height"
                  error={error}
                  defaultValue={field.value}
                  valueGetter={(val) => val}
                  dialogProps={{
                    maxWidth: 'sm',
                  }}
                  onClosed={() => {
                    formRef.current
                      .getMethods()
                      .trigger(
                        `${formField}.${HEALTH_PROFILE_FIELDS.HEIGHT.name}`
                      );
                  }}
                  onSave={onHealthProfileFieldSave(
                    field,
                    HEALTH_PROFILE_FIELDS.HEIGHT.name,
                    'Height'
                  )}
                  render={({ value, onChange, isSaving }) => (
                    <FormField
                      required
                      label="HEIGHT"
                      field={
                        <TextField
                          fullWidth
                          autoFocus
                          unit="CM"
                          type="number"
                          value={value}
                          variant="filled"
                          disabled={isSaving}
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          placeholder="Enter height..."
                        />
                      }
                    />
                  )}
                />
              )}
              rules={{
                required: 'Patient height is required',
                validate: {
                  positive: (v) => formValidationFunctions.positive(v),
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name={`${formField}.${HEALTH_PROFILE_FIELDS.CHRONIC_CONDITIONS.name}`}
              control={control}
              defaultValue={
                defaultValues[HEALTH_PROFILE_FIELDS.CHRONIC_CONDITIONS.name]
              }
              render={({ field }) => (
                <ModalField
                  label="Chronic Conditions"
                  identifier="chronic-conditions"
                  defaultValue={field.value}
                  isFalsy={(value) => isEmpty(value)}
                  onClosed={() => {
                    formRef.current
                      .getMethods()
                      .trigger(
                        `${formField}.${HEALTH_PROFILE_FIELDS.CHRONIC_CONDITIONS.name}`
                      );
                  }}
                  onSave={onHealthProfileFieldSave(
                    field,
                    HEALTH_PROFILE_FIELDS.CHRONIC_CONDITIONS.name,
                    'Chronic conditions'
                  )}
                  renderValue={({ value: selectedChronicConditions = [] }) => (
                    <ChronicConditionsDisplay
                      selectedChronicConditions={selectedChronicConditions}
                    />
                  )}
                  render={({ value, onChange, isSaving }) => (
                    <FormField
                      label="CHRONIC CONDITIONS"
                      field={
                        <Select
                          fullWidth
                          multiple
                          size="medium"
                          soak="light"
                          variant="filled"
                          value={value}
                          disabled={isSaving}
                          placeholder="Select chronic conditions..."
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 450,
                                width: 100,
                              },
                            },
                          }}
                          onChange={onChange}
                          renderValue={(selectedChronicConditions) => (
                            <Grid container rowSpacing={0.5} columnSpacing={1}>
                              {selectedChronicConditions.map(
                                (selectedChronicCondition) => (
                                  <Grid item>
                                    <Chip
                                      size="small"
                                      color="primary"
                                      key={
                                        DISEASES[selectedChronicCondition]
                                          ?.key || value
                                      }
                                      label={
                                        DISEASES[selectedChronicCondition]
                                          ?.label || value
                                      }
                                    />
                                  </Grid>
                                )
                              )}
                            </Grid>
                          )}
                        >
                          {keys(DISEASES).map((option) => (
                            <MenuItem value={option} key={option}>
                              <Checkbox
                                checked={value && value.indexOf(option) > -1}
                              />
                              {DISEASES[option].label}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              name={`${formField}.${HEALTH_PROFILE_FIELDS.ALLERGIES.name}`}
              control={control}
              defaultValue={defaultValues[HEALTH_PROFILE_FIELDS.ALLERGIES.name]}
              render={({ field, fieldState: { error } }) => (
                <ModalField
                  required
                  error={error}
                  label="Allergies"
                  identifier="allergies"
                  defaultValue={field.value}
                  isFalsy={(value) => isEmpty(value)}
                  dialogProps={{
                    maxWidth: 'lg',
                  }}
                  onClosed={() => {
                    allergiesFormRef?.current?.getMethods()?.trigger?.();
                  }}
                  onSave={async (_value, callbacks) => {
                    const isValidField = await allergiesFormRef?.current
                      ?.getMethods?.()
                      ?.trigger?.();

                    if (isValidField) {
                      allergiesFormRef?.current?.submit?.();
                    } else {
                      callbacks?.error?.();
                    }
                  }}
                  renderValue={({ value: selectedAllergies = [] }) => (
                    <AllergiesDisplay selectedAllergies={selectedAllergies} />
                  )}
                  render={({ close, value, onChange, isSaving, setSaving }) => (
                    <AllergiesForm
                      close={close}
                      patient={patient}
                      isSaving={isSaving}
                      setSaving={setSaving}
                      defaultValues={value}
                      ref={allergiesFormRef}
                      onAllergiesSubmitted={(selectedAllergies) => {
                        onChange(selectedAllergies);
                        field.onChange(selectedAllergies);
                      }}
                      saveAllergies={healthProfileUpdateFunction}
                    />
                  )}
                  renderDialogAction={() => (
                    <Button
                      size="medium"
                      color="primary"
                      variant="text"
                      onClick={() => handleAddAnotherAllergy()}
                      startIcon={<AddCircleOutlineIcon fontSize="medium" />}
                    >
                      Add another
                    </Button>
                  )}
                />
              )}
              rules={{
                required: 'Patient allergies is required',
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PatientInfoForm;
