import get from 'lodash/get';
import { createSelector } from 'reselect';

export const userSelector = (state) => get(state, 'domain.user', {});

export const getUser = createSelector(userSelector, (results) =>
  get(results, 'view')
);

export const isFetchingUser = createSelector(userSelector, (results) =>
  get(results, 'isFetchingUser')
);

export const isSubmittingUser = createSelector(userSelector, (results) =>
  get(results, 'isSubmittingUser')
);

export const getHealthInfo = createSelector(userSelector, (results) =>
  get(results, 'patientHealthInfo')
);

export const isFetchingHealth = createSelector(userSelector, (results) =>
  get(results, 'isFetchingHealth')
);

export const isFetchingFiles = createSelector(userSelector, (results) =>
  get(results, 'isFetchingFiles')
);

export const isFetchingFrontId = createSelector(userSelector, (results) =>
  get(results, 'isFetchingFrontId')
);

export const isFetchingBackId = createSelector(userSelector, (results) =>
  get(results, 'isFetchingBackId')
);

export const isFetchingInsuranceCard = createSelector(userSelector, (results) =>
  get(results, 'isFetchingInsuranceCard')
);

export const isFetchingUserAddresses = createSelector(userSelector, (results) =>
  get(results, 'isFetchingUserAddresses')
);

export const isSavingExtractedIdentityData = createSelector(
  userSelector,
  (results) => get(results, 'isSavingExtractedIdentityData')
);

export const getUserAddresses = createSelector(userSelector, (results) =>
  get(results, 'addresses')
);

export const getDoctors = createSelector(userSelector, (results) =>
  get(results, 'doctors', [])
);

export const getDrivers = createSelector(userSelector, (results) =>
  get(results, 'drivers', [])
);

export const getExtractedIdentity = createSelector(userSelector, (results) =>
  get(results, 'extractedIdentity')
);

export const isExtractingIdentity = createSelector(userSelector, (results) =>
  get(results, 'isExtractingIdentity')
);

export const getIdentityExtractionJobs = createSelector(
  userSelector,
  (results) => get(results, 'identityExtractionJobs')
);

export const getIdentityExtractionError = createSelector(
  userSelector,
  (results) => get(results, 'identityExtractionError')
);

export const getPharmacists = createSelector(userSelector, (results) =>
  get(results, 'pharmacists', [])
);

export default {
  getUser,
  getDoctors,
  getDrivers,
  getHealthInfo,
  isFetchingUser,
  isFetchingFiles,
  getUserAddresses,
  isSubmittingUser,
  isFetchingHealth,
  isFetchingBackId,
  isFetchingFrontId,
  getExtractedIdentity,
  isExtractingIdentity,
  isFetchingUserAddresses,
  isFetchingInsuranceCard,
  getIdentityExtractionJobs,
  getIdentityExtractionError,
  isSavingExtractedIdentityData,
  getPharmacists,
};
