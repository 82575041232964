import { connect } from 'react-redux';
import {
  orderActions as actions,
  orderSelectors as selectors,
} from 'domain/order';

import LogisticProviderDialog from './LogisticProviderDialog';

const mapStateToProps = (state, ownProps) => {
  const isFetchingTaskId = selectors.isFetchingTaskId(state);
  return {
    ...ownProps,
    isFetchingTaskId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createLogisticProviderShipment: (payload, callback) =>
    dispatch(actions.createLogisticProviderShipment(payload, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogisticProviderDialog);
