import set from 'date-fns/set';
import groupBy from 'lodash/groupBy';
import format from 'date-fns/format';

import { dateUtils } from 'core/utils';
import { userConstants } from 'domain/user';
import { timeFormat } from 'constants/dateTime';

import * as constants from './constants';
import * as rangeGetters from './getters/availabilityRange';
import * as doctorScheduleGetters from './getters/doctorSchedule';

const { FIELDS: USER_FIELDS } = userConstants;
const { DOCTOR_SCHEDULE_FIELDS, AVAILABILITY_RANGE_FIELDS } = constants;

export const mapSlotsServerToState = (slots = []) => {
  return slots
    .map((time) => {
      const startTime = rangeGetters.getStartTime(time);
      const endTime = rangeGetters.getEndTime(time);
      const startTimeParts = startTime.split(':').slice(0, 2).map(Number);
      const endTimeParts = endTime.split(':').slice(0, 2).map(Number);
      const formattedStartTime = format(
        set(new Date(), {
          hours: startTimeParts[0],
          minutes: startTimeParts[1],
          seconds: 0,
          milliseconds: 0,
        }),
        timeFormat
      );
      const formattedEndTime = format(
        set(new Date(), {
          hours: endTimeParts[0],
          minutes: endTimeParts[1],
          seconds: 0,
          milliseconds: 0,
        }),
        timeFormat
      );

      return {
        ...time,
        [AVAILABILITY_RANGE_FIELDS.START_TIME.name]: startTimeParts.join(':'),
        [AVAILABILITY_RANGE_FIELDS.START_TIME_PARTS.name]: startTimeParts,
        [AVAILABILITY_RANGE_FIELDS.FORMATTED_START_TIME.name]:
          formattedStartTime,
        [AVAILABILITY_RANGE_FIELDS.END_TIME.name]: endTimeParts.join(':'),
        [AVAILABILITY_RANGE_FIELDS.END_TIME_PARTS.name]: endTimeParts,
        [AVAILABILITY_RANGE_FIELDS.FORMATTED_END_TIME.name]: formattedEndTime,
        [AVAILABILITY_RANGE_FIELDS.READABLE_DAY_OF_WEEK.name]:
          dateUtils.getWeekDayFromNumber(rangeGetters.getDayOfWeek(time)),
      };
    })
    .sort((a, b) => {
      const aTimes = rangeGetters.getStartTime(a).split(':').map(Number);
      const bTimes = rangeGetters.getStartTime(b).split(':').map(Number);

      return aTimes[0] - bTimes[0] || aTimes[1] - bTimes[1];
    });
};

export const mapScheduleServerToState = (schedule, doctors) => {
  const doctorsById = groupBy(doctors, USER_FIELDS.ID.name);

  return {
    ...schedule,
    [DOCTOR_SCHEDULE_FIELDS.DOCTOR.name]:
      doctorsById[doctorScheduleGetters.getDoctorId(schedule)]?.[0] || {},
    [DOCTOR_SCHEDULE_FIELDS.AVAILABILITY_RANGES.name]: mapSlotsServerToState(
      doctorScheduleGetters.getAvailabilityRange(schedule)
    ),
  };
};
