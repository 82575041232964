// eslint-disable-next-line import/prefer-default-export
export const getColumns = () => [
  {
    field: 'id',
    flex: 1,
    headerName: 'ID',
  },
  {
    field: 'zoomEmail',
    flex: 1,
    headerName: 'Email',
  },
];
