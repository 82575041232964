import logger from 'core/logger';

import { router } from './RootRoute';

export const navigate = (path) => {
  if (router) {
    router.navigate(path);
  } else {
    logger.error('Error while navigating to ', path);
  }
};
