import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchFollowUpReminders({ referenceId, referenceType }) {
  const response = yield call(
    httpClient.get,
    constants.ENDPOINT_OF(referenceId, referenceType)
  );

  yield put(
    actions.followUpRemindersReceived(response.data, referenceId, referenceType)
  );
}

export function* createFollowUpReminders({ params }) {
  const response = yield call(httpClient.post, constants.ENDPOINT, params);

  yield put(actions.followUpReminderCreated(response.data));
}

export default function* followUpReminderModelSaga() {
  yield all([
    takeLatest(types.FETCH_FOLLOW_UP_REMINDERS, fetchFollowUpReminders),
    takeLatest(types.CREATE_FOLLOW_UP_REMINDER, createFollowUpReminders),
  ]);
}
