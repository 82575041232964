import add from 'date-fns/add';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';
import isBefore from 'date-fns/isBefore';
import { useState, useEffect } from 'react';

import httpClient, { httpConstants } from 'core/httpClient';

let abortController = new AbortController();

const sortModelToQueryParamValue = (columnFieldsMap) => (sortModel) =>
  `${columnFieldsMap[sortModel.field] || sortModel.field},${toUpper(
    sortModel.sort
  )}`;

const appendSortModeltoQueryParams =
  (defaultSortModel, columnFieldsMap) => (queryParams, sortModel) => {
    const urlSearchParams = new URLSearchParams(queryParams);

    const sortModelToQueryParamValueByFieldMap =
      sortModelToQueryParamValue(columnFieldsMap);

    if (!isEmpty(sortModel)) {
      urlSearchParams.append(
        'sort',
        sortModelToQueryParamValueByFieldMap(sortModel)
      );
    } else {
      urlSearchParams.append(
        'sort',
        sortModelToQueryParamValueByFieldMap(defaultSortModel)
      );
    }

    return urlSearchParams;
  };

const useServerPagination = ({
  /* The resource you want to query */
  endpoint,
  /* The baseURL or microservice */
  baseURL,
  /* The count of records retrieved at a time */
  pageSize = 25,
  /* URLSearchParams: The search/query parameters you send along with the endpoint */
  params = '',
  /* The time for the cached data to live, after which, the data is re-fetched */
  cacheTtl = 0,
  /* The default way to sort the table */
  initialSortModel,
  /* The actual field names on the server, used for sorting */
  columnFieldsMap = {},
}) => {
  /* General state */
  const [isLoading, setLoading] = useState(true);
  const [queryParams, setQueryParams] = useState(params);

  /* Pagination state */
  // The page being fetched
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  // The data list of the current page
  const [pageData, setPageData] = useState([]);
  const [cachePerPage, setCachePerPage] = useState({});

  /* Sorting state */
  const [sortModel, setSortModel] = useState(
    initialSortModel ? [initialSortModel] : []
  );

  /**
   *
   * @param {*} pg = page
   * @param {*} qp = queryParams
   * @param {*} rc = rowCount
   * @param {*} data
   */
  const cachePage = (pg, qp, rc, data) => {
    if (cacheTtl) {
      setCachePerPage({
        ...cachePerPage,
        [`${pg}-${qp.toString()}`]: {
          data,
          rowCount: rc,
          expiresAt: add(new Date(), { minutes: cacheTtl }),
        },
      });
    }
  };

  const getCachedPage = (pg) => {
    if (cachePerPage[pg] && isBefore(new Date(), cachePerPage[pg].expiresAt)) {
      return cachePerPage[pg];
    }

    return null;
  };

  useEffect(() => {
    const urlSearchParams = appendSortModeltoQueryParams(
      initialSortModel,
      columnFieldsMap
    )(queryParams, sortModel?.[0]);
    const cachedPage = getCachedPage(`${page}-${urlSearchParams.toString()}`);

    if (cachedPage) {
      setPageData(cachedPage.data);
      setRowCount(cachedPage.rowCount);
    } else {
      if (isLoading && abortController) {
        abortController.abort();
        abortController = new AbortController();
      }

      setLoading(true);

      (async () => {
        const urlSearchParamsCacheKey = new URLSearchParams(urlSearchParams);

        urlSearchParams.append('limit', pageSize);
        urlSearchParams.append('offset', page * pageSize);

        try {
          const response = await httpClient.get(endpoint, {
            signal: abortController.signal,
            params: urlSearchParams,
            baseURL,
          });

          setPageData(response.data.data);
          setRowCount(response.data.total);

          cachePage(
            page,
            urlSearchParamsCacheKey,
            response.data.total,
            response.data.data
          );
          setLoading(false);
        } catch (error) {
          if (error.code !== httpConstants.ERROR_CODES.CANCELLED.key) {
            setLoading(false);
          }

          setPageData([]);
          setRowCount(0);
        }
      })();
    }
  }, [page, sortModel, queryParams]);

  const onPaginationModelChange = (paginationModel) => {
    setPage(paginationModel.page);
  };

  const onSortModelChange = (newModel) => {
    setSortModel(newModel);
  };

  const setParams = (newParams) => {
    setPage(0);
    setQueryParams(newParams);
  };

  return {
    props: {
      // General grid props
      rows: pageData,
      loading: isLoading,
      // Pagination props
      rowCount,
      paginationMode: 'server',
      paginationModel: { page, pageSize },
      onPaginationModelChange,
      // Sorting props
      sortModel,
      onSortModelChange,
      sortingMode: 'server',
    },
    params: queryParams,
    setters: {
      setParams,
    },
  };
};

export default useServerPagination;
