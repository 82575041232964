import get from 'lodash/get';
import { createSelector } from 'reselect';

export const anonymousUserSelector = (state) =>
  get(state, 'domain.anonymousUser', {});

export const getAnonymousUser = createSelector(
  anonymousUserSelector,
  (results) => get(results, 'view')
);

export const isFetchingAnonymousUser = createSelector(
  anonymousUserSelector,
  (results) => get(results, 'isFetching')
);

export default {
  getAnonymousUser,
  isFetchingAnonymousUser,
};
