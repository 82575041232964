import * as types from './actionTypes';

const initialState = {
  isFetching: false,
  isSubmitting: false,
  eligibilityRequests: [],
};

const eligibilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ELIGIBILITY_REQUESTS_RECEIVED: {
      return {
        ...state,
        eligibilityRequests: action.eligibilityRequests || [],
      };
    }
    case types.SET_FETCHING_ELIGIBILITY: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING_ELIGIBILITY: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        eligibilityRequests: [],
      };
    }
    default:
      return state;
  }
};

export default eligibilityReducer;
