import * as types from './actionTypes';

const initialState = {
  view: {
    consultationTypeInfo: {},
    consultationTypeConfigurations: [],
  },
  list: [],
  isFetching: false,
  isSubmitting: false,
  isFetchingConfiguration: false,
  isSubmittingConfiguration: false,
};

const consultationTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONSULTATION_TYPE_RECEIVED: {
      return {
        ...state,
        view: {
          ...state.view,
          consultationTypeInfo: action.consultationType || {},
        },
      };
    }
    case types.CONSULTATION_TYPES_RECEIVED: {
      return {
        ...state,
        list: action.consultationTypes || [],
      };
    }
    case types.CONSULTATION_TYPE_CONFIGURATION_RECEIVED: {
      return {
        ...state,
        view: {
          ...state.view,
          consultationTypeConfigurations:
            action.consultationTypeConfigurations || [],
        },
      };
    }
    case types.SET_FETCHING_CONSULTATION_TYPE: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION_TYPE: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.SET_SUBMITTING_CONSULTATION_TYPE_CONFIGURATION: {
      return {
        ...state,
        isSubmittingConfiguration: action.isSubmitting,
      };
    }
    case types.SET_FETCHING_CONSULTATION_TYPE_CONFIGURATION: {
      return {
        ...state,
        isFetchingConfiguration: action.isFetching,
      };
    }
    default:
      return state;
  }
};

export default consultationTypeReducer;
