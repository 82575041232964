/* Patient history domain constants */
export const PACKAGE_NAME = 'PatientHistory';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  STATUS: {
    name: 'status',
  },
  CHANNEL: {
    name: 'channel',
  },
  DOCTOR: {
    name: 'doctor',
  },
  TYPE: {
    name: 'type',
  },
  FILES: {
    name: 'files',
  },
  IS_FETCHING_FILES: {
    name: 'isFetchingFiles',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
};

export const ACTIVITY_TYPES = {
  CONSULTATION: {
    key: 'CONSULTATION',
    label: 'Consultation',
    permissionKey: 'consultation',
    route: 'consultation',
  },
  ORDER: {
    key: 'ORDER',
    label: 'Order',
    permissionKey: 'order',
    route: 'order',
  },
  LAB_TEST_REQUEST: {
    key: 'LAB_TEST_REQUEST',
    label: 'Lab Test Request',
    permissionKey: 'labRequest',
    route: 'lab-request',
  },
};
