import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

import { userGetters } from 'domain/user';
import { authSelectors } from 'domain/auth';
import { tenantUtils } from 'domain/tenant';
import { UserSearch } from 'presentation/user';
import PatientInfo from 'presentation/components/PatientInfo';
import { USER_MANAGEMENT_FIELDS, SYSTEM_ROLES } from 'domain/userManagement';
import {
  Grid,
  Card,
  Chip,
  Select,
  Button,
  Checkbox,
  MenuItem,
  TextField,
  PageTitle,
  Typography,
  CardHeader,
  CardContent,
  RequiredMark,
  LinearProgress,
} from 'design-system';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
      width: 100,
    },
  },
};

const NewUserCredentials = ({
  tenants,
  selectedTenant,
  user,
  fetchUser,
  clearUser,
  isSubmitting,
  isFetchingUser,
  createAlmaosUser,
}) => {
  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  // FIELDS
  const [username, setUsername] = useState('');
  const [userId, setUserId] = useState();
  const [allowedTenants, setAllowedTenants] = useState([]);
  const [tenantKey, setTenantKey] = useState(selectedTenant || defaultTenant);
  const [slackMemberId, setSlackMemberId] = useState('');
  const [roles, setRoles] = useState([]);

  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    setSubmitButtonEnabled(
      !isEmpty(user) &&
        username &&
        tenantKey &&
        !isEmpty(allowedTenants) &&
        !isEmpty(roles)
    );
  }, [user, username, tenantKey, allowedTenants, roles]);

  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
  }, [userId, fetchUser]);

  useEffect(() => {
    clearUser();
  }, [clearUser]);

  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
  }, [userId, fetchUser]);

  const onSubmit = () => {
    const payload = {
      [USER_MANAGEMENT_FIELDS.USER_ID.name]: userGetters.getId(user),
      [USER_MANAGEMENT_FIELDS.USERNAME.name]: username,
      [USER_MANAGEMENT_FIELDS.ALLOWED_TENANTS.name]: allowedTenants,
      [USER_MANAGEMENT_FIELDS.TENANT_KEY.name]: tenantKey,
      [USER_MANAGEMENT_FIELDS.SLACK_MEMBER_ID.name]: slackMemberId,
      [USER_MANAGEMENT_FIELDS.ROLES.name]: roles,
    };
    createAlmaosUser(payload);
  };
  return (
    <>
      <PageTitle
        title="New AlmaOs User"
        action={
          <Button
            variant="filled"
            onClick={onSubmit}
            spinning={isSubmitting}
            disabled={!isSubmitButtonEnabled}
          >
            Create
          </Button>
        }
      />
      <Grid
        container
        spacing={3}
        row
        alignItems="stretch"
        justifyContent="left"
      >
        <Grid item xs={6}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title="User" sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              <Grid container column py={2}>
                <Grid item sx={{ mb: 1, px: 2 }}>
                  <Typography variant="p4">
                    Search by phone number or name
                  </Typography>
                </Grid>
                <Grid item container spacing={1} sx={{ mb: 1, px: 2 }}>
                  <Grid item xs={12}>
                    <UserSearch
                      size="large"
                      onUserSelect={(selectedUser) => {
                        const selectedUserId = userGetters.getId(selectedUser);

                        if (selectedUserId) {
                          fetchUser(selectedUserId);
                          setUserId(selectedUserId);
                        } else {
                          clearUser();
                          setUserId(0);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                {!isEmpty(user) && (
                  <Grid item container column>
                    <PatientInfo user={user} isFetching={isFetchingUser} />
                  </Grid>
                )}
                {isEmpty(user) && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    loading={isFetchingUser}
                  >
                    <PersonAddRoundedIcon
                      sx={{
                        color: (theme) => theme.palette.secondary.tint,
                        fontSize: '20rem',
                      }}
                    />
                  </Grid>
                )}
                {isFetchingUser && <LinearProgress />}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title="Account Details" sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              <Grid container column p={2}>
                {/* username */}
                <>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">USERNAME</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <TextField
                      size="medium"
                      fullWidth
                      variant="filled"
                      placeholder="Username"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </Grid>
                </>
                {/* Roles */}
                <>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">ROLES</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Select
                      fullWidth
                      multiple
                      placeholder="Specify user roles"
                      soak="light"
                      variant="filled"
                      value={roles}
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        setRoles(event.target.value);
                      }}
                      renderValue={(selected) => (
                        <Grid
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip
                              size="small"
                              color="primary"
                              sx={{ maxHeight: 20 }}
                              key={SYSTEM_ROLES[value]?.key || value}
                              label={SYSTEM_ROLES[value]?.label || value}
                            />
                          ))}
                        </Grid>
                      )}
                    >
                      {keys(SYSTEM_ROLES).map((option) => (
                        <MenuItem value={option} key={option}>
                          <Checkbox checked={roles.indexOf(option) > -1} />
                          {SYSTEM_ROLES[option].label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
                {/* Tenant */}
                <>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">BRANCH</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Select
                      fullWidth
                      size="medium"
                      soak="light"
                      variant="filled"
                      value={tenantKey}
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        setTenantKey(event.target.value);
                      }}
                    >
                      {(tenants || []).map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
                {/* Allowed Tenants */}
                <>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">ALLOWED BRANCHES</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Select
                      fullWidth
                      multiple
                      size="medium"
                      soak="light"
                      variant="filled"
                      value={allowedTenants}
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        setAllowedTenants(event.target.value);
                      }}
                      renderValue={(selected) => (
                        <Grid
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip
                              size="small"
                              color="primary"
                              sx={{ maxHeight: 20 }}
                              key={value}
                              label={tenantUtils.getTenantName(tenants, value)}
                            />
                          ))}
                        </Grid>
                      )}
                    >
                      {(tenants || []).map((option) => (
                        <MenuItem value={option.id} key={option.id}>
                          <Checkbox
                            checked={allowedTenants.indexOf(option.id) > -1}
                          />
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </>
                {/* Slack member id */}
                <>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">SLACK MEMBER ID</Typography>
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <TextField
                      size="medium"
                      fullWidth
                      variant="filled"
                      placeholder="Slack member id"
                      onChange={(e) => {
                        setSlackMemberId(e.target.value);
                      }}
                    />
                  </Grid>
                </>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default NewUserCredentials;
