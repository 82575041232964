import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import appData from 'domain/app/HOCs/appData';
import {
  consultationActions as actions,
  consultationSelectors as selectors,
  consultationActionTypes as actionTypes,
} from 'domain/consultation';

import PatientOutcomeWizardDialog from './PatientOutcomeWizardDialog';

const mapStateToProps = (state) => {
  const isUpdating = selectors.isUpdatingConsultationPatientOutcome(state);
  const isDeleting = selectors.isDeletingConsultationPatientOutcome(state);
  const isSubmitting = selectors.isSubmittingConsultationPatientOutcome(state);

  return {
    isUpdating,
    isDeleting,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createOutcome: (consultationId, patientId, payload) =>
    dispatch(
      actions.createConsultationPatientOutcome(
        consultationId,
        patientId,
        payload
      )
    ),
  onOutcomeCreated: (onOutcomeCreatedCallback) =>
    dispatch(
      appActions.pursueAction(
        actionTypes.CONSULTATION_PATIENT_OUTCOME_CREATED,
        onOutcomeCreatedCallback
      )
    ),
  updateOutcome: (consultationId, patientId, outcomeId, payload) =>
    dispatch(
      actions.updateConsultationPatientOutcome(
        consultationId,
        patientId,
        outcomeId,
        payload
      )
    ),
  onOutcomeUpdated: (onOutcomeUpdatedCallback) =>
    dispatch(
      appActions.pursueAction(
        actionTypes.CONSULTATION_PATIENT_OUTCOME_UPDATED,
        onOutcomeUpdatedCallback
      )
    ),
  deleteOutcome: (consultationId, patientId, outcomeId) =>
    dispatch(
      actions.deleteConsultationPatientOutcome(
        consultationId,
        patientId,
        outcomeId
      )
    ),
  onOutcomeDeleted: (onOutcomeDeletedCallback) =>
    dispatch(
      appActions.pursueAction(
        actionTypes.CONSULTATION_PATIENT_OUTCOME_DELETED,
        onOutcomeDeletedCallback
      )
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(PatientOutcomeWizardDialog)
);
