import React from 'react';

import Grid from '../Grid';
import Typography from '../Typography';

const PageTitle = ({ title, action = null, sx = {} }) => (
  <Grid
    container
    justifyContent="space-between"
    sx={{
      mb: 3,
      ...sx,
    }}
    flexDirection={{
      xs: 'column',
      sm: 'row',
    }}
    rowSpacing={2}
  >
    <Grid item>
      <Typography variant="h1">{title}</Typography>
    </Grid>
    <Grid item>{action}</Grid>
  </Grid>
);

export default PageTitle;
