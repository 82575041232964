import isEmpty from 'lodash/isEmpty';

/**
 * Take an object {owner} and a list of objects {members},
 * puts them into one list
 * and converts them into a map by {byId}
 */
export const ownerAndMembersToObjectById = (
  owner,
  members = [],
  byId = 'id'
) => {
  let patientsProfilesById = {};

  if (!isEmpty(owner)) {
    // At least owner must exist
    // concat owner, members into one list
    const patientsUserProfiles = [...members, owner];

    // Convert them into a map by id
    patientsProfilesById = patientsUserProfiles.reduce(
      (listAsObject, profile) => {
        const profilesObject = { ...listAsObject };

        profilesObject[profile[byId]] = profile;

        return profilesObject;
      },
      {}
    );
  }

  return patientsProfilesById;
};
