import { connect } from 'react-redux';

import {
  orderSelectors as selectors,
  orderActions as actions,
} from 'domain/order';

import OrderHistory from './OrderHistory';

const mapStateToProps = (state) => {
  const history = selectors.getOrderHistory(state);
  const lineItems = selectors.getLineItemsHistory(state);
  const isFetching = selectors.isFetchingOrderHistory(state);
  const isFetchingLineItems = selectors.isFetchingLineItemsHistory(state);

  return {
    history,
    lineItems,
    isFetching,
    isFetchingLineItems,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHistory: (id) => dispatch(actions.fetchOrderHistory(id)),
  fetchLineItemsHistory: (orderId) =>
    dispatch(actions.fetchLineItemsHistory(orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
