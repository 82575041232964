import { connect } from 'react-redux';

import {
  memberActions as actions,
  memberSelectors as selectors,
} from 'domain/user/member';

import MemberManagement from './MemberManagement';

const mapStateToProps = (state) => {
  const basicMembers = selectors.getBasicMembers(state) || [];

  const isDeleting = selectors.isDeletingMember(state);
  const isFetching = selectors.isFetchingBasicMembers(state);
  const isSubmitting = selectors.isSubmittingMember(state);

  return {
    members: basicMembers,
    isFetching,
    isDeleting,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAllMembers: (ownerId) => dispatch(actions.fetchBasicMembers(ownerId)),
  createMember: (ownerId, member) =>
    dispatch(actions.createMember(ownerId, member)),
  deleteMember: (memberId) => dispatch(actions.deleteMember(memberId)),
  clearMembers: () => dispatch(actions.basicMembersReceived([])),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberManagement);
