import get from 'lodash/get';

import { FIELDS } from './constants';

export const getId = (medicine) => get(medicine, FIELDS.ID.name);

export const getStatus = (medicine) => get(medicine, FIELDS.STATUS.name);

export const getSummary = (medicine) => get(medicine, FIELDS.SUMMARY.name);

export const getStrength = (medicine) => get(medicine, FIELDS.STRENGTH.name);

export const getDrugCode = (medicine) => get(medicine, FIELDS.DRUG_CODE.name);

export const getLegalName = (medicine) => get(medicine, FIELDS.LEGAL_NAME.name);

export const getTenantKey = (medicine) => get(medicine, FIELDS.TENANT_KEY.name);

// TODO: getPrice should return the price object, not the value
export const getPrice = (medicine) =>
  get(medicine, FIELDS.PACKAGE_PRICE.name)?.value;

export const getPriceObject = (medicine) =>
  get(medicine, FIELDS.PACKAGE_PRICE.name);

export const getCost = (medicine) =>
  get(medicine, FIELDS.PACKAGE_COST.name)?.value;

export const getCostObject = (medicine) =>
  get(medicine, FIELDS.PACKAGE_COST.name);

export const getUnitCost = (medicine) =>
  get(medicine, FIELDS.UNIT_COST.name)?.value;

export const getUnitCostObject = (medicine) =>
  get(medicine, FIELDS.UNIT_COST.name);

export const getUnitPrice = (medicine) =>
  get(medicine, FIELDS.UNIT_PRICE.name)?.value;

export const getUnitPriceObject = (medicine) =>
  get(medicine, FIELDS.UNIT_PRICE.name);

export const getPackageName = (medicine) =>
  get(medicine, FIELDS.PACKAGE_NAME.name);

export const getGenericName = (medicine) =>
  get(medicine, FIELDS.GENERIC_NAME.name);

export const getDosageForm = (medicine) =>
  get(medicine, FIELDS.DOSAGE_FORM.name);

export const getDispenseMode = (medicine) =>
  get(medicine, FIELDS.DISPENSE_MODE.name);

export const getPackageSizeSummary = (medicine) =>
  get(medicine, FIELDS.PACKAGE_SIZE_SUMMARY.name);
