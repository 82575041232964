/* Prescription domain constants */
import { SCALAR_FIELD, DISPLAYABLE_FIELD } from 'domain/constants/general';

export const PACKAGE_NAME = 'Prescription';

export const FIELDS = {
  PATIENT: {
    name: 'patient',
    fields: {
      USER_ID: {
        name: 'userId',
      },
      FULL_NAME: {
        name: 'fullName',
      },
      NATIONALITY: {
        name: 'nationality',
      },
      IDENTITY_NUMBER: {
        name: 'identityNumber',
      },
      DATE_OF_BIRTH: {
        name: 'dateOfBirth',
      },
      PHONE_NUMBER: {
        name: 'phoneNumber',
      },
      AGE: {
        name: 'age',
      },
      WEIGHT: {
        name: 'weight',
      },
      HEIGHT: {
        name: 'height',
      },
      ADDRESS: {
        name: 'address',
      },
    },
  },
  CONSULTATION_ID: {
    name: 'consultationId',
  },
  CONSULTATION: {
    name: 'consultation',
    fields: {
      ID: {
        name: 'id',
      },
      START_TIME: {
        name: 'startTime',
      },
    },
  },
  DOCTOR_ID: {
    name: 'doctorId',
  },
  DOCTOR: {
    name: 'doctor',
    fields: {
      DOCTOR_ID: {
        name: 'id',
      },
      FULL_NAME: {
        name: 'fullName',
      },
      LICENSE_NUMBER: {
        name: 'licenseNumber',
      },
      STAMP_FILE: {
        name: 'stampFile',
      },
      SIGNATURE_FILE: {
        name: 'signatureFile',
      },
    },
  },
  DOCTOR_NOTES: {
    name: 'doctorNotes',
  },
  ORDER_ID: {
    name: 'orderId',
  },
  DOCUMENT_FILE_ID: {
    name: 'documentFileId',
  },
  IS_APPEND_PRESCRIPTION: {
    name: 'isAppendPrescription',
  },
  MEDICINES: {
    name: 'medicines',
    fields: {
      MEDICINE_ID: {
        name: 'medicineId',
      },
      MEDICINE_SUMMARY: {
        name: 'medicineSummary',
      },
      ROUTE_OF_ADMIN: {
        name: 'routeOfAdmin',
      },
      USE: {
        name: 'use',
        fields: SCALAR_FIELD,
      },
      FREQUENCY: {
        name: 'frequency',
        fields: SCALAR_FIELD,
      },
      DURATION: {
        name: 'duration',
        fields: {
          ...SCALAR_FIELD,
          ...DISPLAYABLE_FIELD,
        },
      },
      QUANTITY: {
        name: 'totalQuantity',
        fields: {
          ...SCALAR_FIELD,
          ...DISPLAYABLE_FIELD,
        },
      },
      REFILL: {
        name: 'refill',
        fields: {
          ...SCALAR_FIELD,
          ...DISPLAYABLE_FIELD,
        },
      },
      WHEN_HOW: {
        name: 'medicineTiming',
      },
      INSTRUCTIONS: {
        name: 'instructions',
      },
    },
  },
  DIAGNOSIS: {
    name: 'diagnosis',
    fields: {
      PRIMARY_DIAGNOSIS: {
        name: 'primaryDiagnosis',
      },
      SECONDARY_DIAGNOSIS: {
        name: 'secondaryDiagnosis',
      },
    },
  },
};
