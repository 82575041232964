import React from 'react';

import MuiGrid from '@mui/material/Grid';

import Skeleton from '../Skeleton';

const Grid = ({
  row,
  column,
  // e.g: loading = {{ count: 5 }} renders 5 rectangular skeletons
  loading,
  fullWidth,
  direction,
  fullHeight,
  rowReverse,
  columnReverse,
  flexDirection,
  ...props
}) => {
  if (loading) {
    return (
      <MuiGrid direction={direction} {...props}>
        {[...Array(loading.count)].map(() => (
          <Grid item>
            <Skeleton
              variant="rectangle"
              {...(typeof loading !== 'boolean' ? loading : {})}
            />
          </Grid>
        ))}
      </MuiGrid>
    );
  }

  return (
    <MuiGrid
      {...props}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      flexDirection={(() => {
        if (flexDirection || direction) {
          return flexDirection || direction;
        }
        if (row) {
          return 'row';
        }
        if (column) {
          return 'column';
        }
        if (rowReverse) {
          return 'row-reverse';
        }
        if (columnReverse) {
          return 'column-reverse';
        }
        return 'row';
      })()}
    />
  );
};

export default Grid;
