import keys from 'lodash/keys';
import set from 'date-fns/set';
import add from 'date-fns/add';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import List from '@mui/material/List';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import { useSelector } from 'react-redux';
import ListItem from '@mui/material/ListItem';
import React, { useState, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  ADDRESS_FIELDS,
  USER_DOMAIN_FIELDS,
  userAddressGetters,
  userGetters,
} from 'domain/user';
import { fileGetters } from 'domain/file';
import { authSelectors } from 'domain/auth';
import FileUpload from 'core/file/FileUpload';
import { UserSearch } from 'presentation/user';
import { getAppConfigEndpoint } from 'core/endpoint';
import ServerAutocomplete from 'core/serverAutocomplete';
import PatientInfo from 'presentation/components/PatientInfo';
import { CreateUserModal } from 'presentation/user/components';
import { CUSTOM_LAB_TESTS_LIST_ENDPOINT } from 'model/lab/constants';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import DateTimeRangePicker from 'presentation/components/DateTimeRangePicker';
import {
  labConstants,
  labPackageGetters,
  LAB_REQUEST_FIELDS as FIELDS,
  LAB_TEST_CHANNELS,
} from 'domain/lab';
import {
  Grid,
  Card,
  Select,
  Button,
  MenuItem,
  PageTitle,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  CardContent,
  RequiredMark,
  LinearProgress,
} from 'design-system';

import { isMidnight } from 'core/utils/date';
import { convertToStringList } from '../utils';

const { STATUSES } = labConstants;

const LabRequestCreate = ({
  user,
  tenants,
  fetchUser,
  clearUser,
  labPackages,
  isSubmitting,
  isFetchingUser,
  createLabRequest,
}) => {
  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  const [status, setStatus] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [completeDocuments, setCompleteDocuments] = useState([]);
  const [selectedLabPackage, setSelectedLabPackage] = useState(null);
  const [startTime, setStartTime] = useState(new Date());
  const [labTestDate, setLabTestDate] = useState(new Date());
  const [statusSelectColor, setStatusSelectColor] = useState();
  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [endTime, setEndTime] = useState(add(new Date(), { minutes: 30 }));
  const [createUserModalKey, setCreateUserModalKey] = useState(Date.now());
  const [isUserCreationModalOpen, setUserCreationModalOpen] = useState(false);
  const [consultationId, setConsultationId] = useState(0);
  const [labTestList, setLabTestList] = useState([]);
  const [isLabTestAdded, setLabTestAdded] = useState(false);
  const [labTestListView, setLabTestListView] = useState([]);
  const [isHideCustomLab, setHideCustomLab] = useState(false);
  const [tenant, setTenant] = useState(defaultTenant);

  useEffect(() => {
    clearUser();
    setDocuments([]);
    setCompleteDocuments([]);
  }, []);

  useEffect(() => {
    if (!isEmpty(labPackages)) {
      setSelectedLabPackage(labPackages[0]);
    }

    setHideCustomLab(selectedLabPackage && selectedLabPackage.id > -1);
  }, [labPackages]);

  useEffect(() => {
    if (status) {
      setStatusSelectColor(STATUSES[status].color);
    }
  }, [status]);

  useEffect(() => {
    setSubmitButtonEnabled(
      !isEmpty(user) &&
        status &&
        endTime &&
        startTime &&
        labTestDate &&
        documents.length === completeDocuments.length &&
        (completeDocuments.length > 0 ||
          isLabTestAdded ||
          selectedLabPackage?.id > 0)
    );
  }, [
    user,
    documents,
    completeDocuments,
    endTime,
    startTime,
    labTestDate,
    status,
    selectedLabPackage,
    isLabTestAdded,
  ]);

  const handleDeleteCustomLabTest = (item) => () => {
    const val = item?.name || item;

    setLabTestList(labTestList.filter((test) => test !== item));
    setLabTestListView(labTestListView.filter((test) => test !== val));
  };

  const onSubmit = () => {
    const date = getDate(labTestDate);
    const year = getYear(labTestDate);
    const month = getMonth(labTestDate);

    const fullStartTime = set(startTime, { year, month, date });
    let fullEndTime = set(endTime, { year, month, date });
    if (isMidnight(fullEndTime)) {
      fullEndTime = add(fullEndTime, { days: 1 });
    }
    setStartTime(fullStartTime);
    setEndTime(fullEndTime);
    // Add user dto for payment
    const userDto = {
      [USER_DOMAIN_FIELDS.ID.name]: user.id,
      [USER_DOMAIN_FIELDS.FULL_NAME.name]: user?.fullName,
      [ADDRESS_FIELDS.FORMATTED_ADDRESS.name]:
        userAddressGetters.getFormattedAddress(user?.addresses?.[0]),
      [USER_DOMAIN_FIELDS.CITY.name]: user?.city,
      [USER_DOMAIN_FIELDS.PHONE_NUMBER.name]: user.phoneNumber,
    };

    const payload = {
      [FIELDS.STATUS.name]: status,
      [FIELDS.DOCUMENTS.name]: documents.map((file) => fileGetters.getId(file)),
      [FIELDS.PACKAGE_ID.name]: labPackageGetters.getId(selectedLabPackage),
      [FIELDS.USER_ID.name]: userGetters.getId(user),
      [FIELDS.END_TIME.name]: fullEndTime.toISOString(),
      [FIELDS.START_TIME.name]: fullStartTime.toISOString(),
      [FIELDS.TENANT_ID.name]: tenant,
      [FIELDS.CONSULTATION_ID.name]: consultationId,
      [FIELDS.TEST_LIST.name]: convertToStringList(labTestList),
      [FIELDS.CHANNEL.name]: LAB_TEST_CHANNELS.SUCCUSS_TEAM.label,
      [FIELDS.NAMESPACE_KEY.name]: userGetters.getNamespace(user),
      [FIELDS.USER_DTO.name]: userDto,
      [FIELDS.PATIENTS.name]: [
        {
          [USER_DOMAIN_FIELDS.USER_ID.name]: user.id,
        },
      ],
    };

    createLabRequest(payload, true);
  };

  return (
    <>
      <PageTitle
        title="New Lab Test Request"
        action={
          <Button
            variant="filled"
            onClick={onSubmit}
            spinning={isSubmitting}
            disabled={!isSubmitButtonEnabled}
          >
            Create lab test request
          </Button>
        }
      />
      <Grid
        container
        spacing={3}
        row
        alignItems="stretch"
        justifyContent="left"
      >
        <Grid item xs={6}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              {/* Unify user slection with create pages  */}
              <Grid container column py={2}>
                <Grid item sx={{ mb: 1, px: 2 }}>
                  <Typography variant="p4">
                    Search by phone number or name
                  </Typography>
                </Grid>
                <Grid item container spacing={1} sx={{ mb: 1, px: 2 }}>
                  <Grid item xs={7}>
                    <UserSearch
                      size="large"
                      onUserSelect={(selectedPatient) => {
                        const userId = userGetters.getId(selectedPatient);

                        if (userId) {
                          fetchUser(userId);
                        } else {
                          clearUser();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Button
                      variant="text"
                      onClick={() => {
                        setCreateUserModalKey(Date.now());
                        setUserCreationModalOpen(true);
                      }}
                    >
                      Or create a new user
                    </Button>
                  </Grid>
                </Grid>
                {!isEmpty(user) && (
                  <Grid item container column>
                    <PatientInfo
                      user={user}
                      isFetching={isFetchingUser}
                      config={{
                        fields: {
                          identity: {
                            show: false,
                          },
                        },
                      }}
                    />
                  </Grid>
                )}
                {isEmpty(user) && (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    loading={isFetchingUser}
                  >
                    <PersonAddRoundedIcon
                      sx={{
                        color: (theme) => theme.palette.secondary.tint,
                        fontSize: '20rem',
                      }}
                    />
                  </Grid>
                )}
                {isFetchingUser && <LinearProgress />}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title="Lab Request Details" sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                p: 2,
                height: '100%',
              }}
            >
              <Grid container column>
                {/* Package */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">PACKAGE</Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Select
                    fullWidth
                    size="large"
                    soak="light"
                    variant="filled"
                    key={
                      selectedLabPackage
                        ? labPackageGetters.getId(selectedLabPackage)
                        : selectedLabPackage
                    }
                    value={selectedLabPackage}
                    disabled={isFetchingUser}
                    loading={isEmpty(labPackages)}
                    placeholder="Select lab package..."
                    onChange={(event) => {
                      setSelectedLabPackage(event.target.value);
                      setHideCustomLab(
                        labPackageGetters.getId(event.target.value) > -1
                      );
                    }}
                    renderValue={labPackageGetters.getName}
                  >
                    {labPackages.map((item) => (
                      <MenuItem
                        value={item}
                        key={labPackageGetters.getId(item)}
                      >
                        {labPackageGetters.getName(item)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              {/* Custom Lab Test */}
              {!isHideCustomLab && (
                <Grid item container column>
                  <Grid item>
                    <Typography variant="p4">CUSTOM LAB TESTS</Typography>
                    {completeDocuments.length === 0 && <RequiredMark />}
                  </Grid>
                  <List>
                    {(labTestList || []).map((value) => (
                      <ListItem
                        key={value?.name || value}
                        disableGutters
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={handleDeleteCustomLabTest(value)}
                            sx={{ right: '5px' }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <Typography variant="p3" style={{ fontWeight: 600 }}>
                          {value?.name || value}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                  <Grid item sx={{ mb: 2 }}>
                    <ServerAutocomplete
                      fullWidth
                      multiple
                      disabled={isFetchingUser}
                      defaultValue={labTestListView}
                      baseURL={getAppConfigEndpoint()}
                      endpoint={CUSTOM_LAB_TESTS_LIST_ENDPOINT}
                      getOptionLabel={(option) => (option ? option?.name : '')}
                      onChange={(newValue) => {
                        setLabTestList(newValue);
                        forEach(newValue, (field) => {
                          const val = field.name || field;
                          if (labTestListView.indexOf(val) === -1)
                            labTestListView.push(val);
                        });
                        setLabTestAdded(newValue.length > 0);
                      }}
                      getRequestParams={(searchQuery) => {
                        const urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('term', searchQuery);
                        return urlSearchParams;
                      }}
                      throttle={(fn, searchQuery) => {
                        if (searchQuery.length >= 2) {
                          fn();
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          placeholder="Add lab test ex: CBC"
                          {...params}
                        />
                      )}
                      renderTags={() => null}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container column>
                {/* Status */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">STATUS</Typography>
                  <RequiredMark />
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Select
                    fullWidth
                    size="large"
                    soak="light"
                    value={status}
                    variant="filled"
                    disabled={isFetchingUser}
                    color={statusSelectColor}
                    placeholder="Select status..."
                    onChange={(event) => {
                      setStatus(event.target.value);
                    }}
                    renderValue={(value) =>
                      value ? STATUSES[value].label : value
                    }
                  >
                    {keys(STATUSES)
                      .filter((field) => !STATUSES[field].hide)
                      .map((field) => (
                        <MenuItem value={field} key={field}>
                          {STATUSES[field].label}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>

                {/* Lab test time */}
                <DateTimeRangePicker
                  endTime={endTime}
                  title="LAB TEST"
                  date={labTestDate}
                  startTime={startTime}
                  disabled={isFetchingUser}
                  onEndTimeChange={setEndTime}
                  onDateChange={setLabTestDate}
                  onStartTimeChange={(newValue) => {
                    setStartTime(newValue);
                    setEndTime(add(newValue, { minutes: 30 }));
                  }}
                  config={{
                    date: {
                      required: true,
                      showHelp: true,
                    },
                    startTime: {
                      required: true,
                      showHelp: true,
                    },
                    endTime: {
                      required: true,
                      showHelp: true,
                    },
                  }}
                  minDate={new Date()}
                />
                {/* Consultation Id */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">CONSULTATION ID</Typography>
                </Grid>

                <Grid item sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    size="large"
                    variant="filled"
                    source={consultationId}
                    disabled={isFetchingUser}
                    placeholder="Enter here ..."
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(event) => {
                      setConsultationId(event.target.value);
                    }}
                  />
                </Grid>
                {/* Branch */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">BRANCH</Typography>
                  <RequiredMark />
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Select
                    fullWidth
                    size="large"
                    soak="light"
                    variant="filled"
                    value={tenant}
                    disabled={isFetchingUser}
                    onChange={(event) => {
                      setTenant(event.target.value);
                    }}
                  >
                    {(tenants || []).map((option) => (
                      <MenuItem value={option.id} key={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">DOCUMENTS</Typography>
                </Grid>

                <Grid item sx={{ mb: 3 }}>
                  <FileUpload
                    multiple
                    files={documents}
                    userId={userGetters.getId(user)}
                    loading={isFetchingUser && { count: 0 }}
                    onChange={(files, _, completeFiles) => {
                      setDocuments(files);
                      setCompleteDocuments(completeFiles);
                    }}
                    config={{
                      deletable: true,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CreateUserModal
        key={createUserModalKey}
        open={isUserCreationModalOpen}
        onClose={() => setUserCreationModalOpen(false)}
      />
    </>
  );
};

export default LabRequestCreate;
