import React, { useState, useEffect } from 'react';
// import AddTaskIcon from '@mui/icons-material/AddTask';

import { DataGridCard } from 'core/dataGrid';
import { Grid, DataGrid, Link } from 'design-system';
// import { isAllowed } from 'core/permission';

import CreateEligibility from '../CreateEligibility';
import EligibilitySummary from './components/EligibilitySummary';
import SelectEligibility from './components/SelectEligibility';

const EligibilitySection = ({
  referenceId,
  referenceType,
  isFetching,
  defaultIdentityNumber,
  isSubmitting,
  isUpdatingConsultationPatient,
  isFetchingMembers,
  // eslint-disable-next-line no-unused-vars
  eligibilityRequests,
  getEligibilityRequests,
  isEligibilityCheckRequested,
  userId,
  doctorId,
  patients,
  updateConsultationPatient,
}) => {
  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      renderCell: (params) => {
        return (
          <Link href={`/user/${params.row.userId}`}>
            {params.row.fullName || "Visit patient's profile"}
          </Link>
        );
      },
    },
    {
      field: 'identityNumber',
      flex: 1,
      headerName: 'ID',
      valueGetter: (params) => params.row.identityNumber || 'N/A',
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      renderCell: (params) => {
        return (
          <Grid container pr={1}>
            <Grid item fullWidth>
              <SelectEligibility
                updateConsultationPatient={updateConsultationPatient}
                consultationPatientId={params.row.id}
                referenceId={referenceId}
                eligibilityStatus={params.row.eligibilityStatus}
                isUpdatingConsultationPatient={isUpdatingConsultationPatient}
              />
            </Grid>
          </Grid>
        );
      },
    },
  ];

  const [isEligibilityAddDialogOpen, setEligibilityAddDialogOpen] =
    useState(false);

  const [isEligibilitySummaryDialogOpen, setEligibilitySummaryDialogOpen] =
    useState(false);

  const [selectedRow, setSelectedRow] = useState({});

  const onRowClick = (row) => {
    setSelectedRow(row);
    setEligibilitySummaryDialogOpen(true);
  };

  useEffect(() => {
    if (isEligibilityCheckRequested && referenceId && referenceType) {
      getEligibilityRequests(referenceId, referenceType);
    }
  }, [isEligibilityCheckRequested, referenceId, referenceType]);

  return (
    <>
      <DataGridCard
        sx={{
          height: '100%',
        }}
        header={{
          title: 'Eligibility',
          // ...(isAllowed('consultationView', 'eligibilitySection', 'create')
          //   ? {
          //       action: (
          //         <Tooltip
          //           placement="top"
          //           injectWrapper
          //           title="Request eligibility check"
          //         >
          //           <Button
          //             size="small"
          //             color="primary"
          //             variant="text"
          //             loading={isFetching && { width: 150 }}
          //             startIcon={<AddTaskIcon color="success" />}
          //             onClick={() => setEligibilityAddDialogOpen(true)}
          //           >
          //             Check Eligibility
          //           </Button>
          //         </Tooltip>
          //       ),
          //     }
          //   : {}),
        }}
      >
        <DataGrid
          onRowClick={(prms) => onRowClick(prms.row)}
          columns={columns}
          pageSize={4}
          rows={patients}
          loading={
            isFetching ||
            isSubmitting ||
            isUpdatingConsultationPatient ||
            isFetchingMembers
          }
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-cell': {
              paddingRight: '10px',
            },
          }}
        />
      </DataGridCard>
      <CreateEligibility
        referenceId={referenceId}
        referenceType={referenceType}
        open={isEligibilityAddDialogOpen}
        userId={userId}
        defaultIdentityNumber={defaultIdentityNumber}
        doctorId={doctorId}
        onClose={() => setEligibilityAddDialogOpen(false)}
      />

      <EligibilitySummary
        eligibility={selectedRow}
        open={isEligibilitySummaryDialogOpen}
        onClose={() => setEligibilitySummaryDialogOpen(false)}
      />
    </>
  );
};

export default EligibilitySection;
