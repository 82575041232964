import { info, error, warning, success } from './methods';

export { default as MessageProvider } from './MessageProvider';

export default {
  info,
  error,
  warning,
  success,
};
