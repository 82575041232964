import React, { useRef, useMemo, useState, useCallback } from 'react';

import { Form, Controller } from 'design-system/form';
import { Grid, Button, FormField } from 'design-system';
import { FIELDS, DIAGNOSIS_TYPES } from 'domain/doctorNotes/constants';
import DiagnosisAutocomplete from 'presentation/components/DiagnosisAutocomplete';

import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const DiagnosisForm = ({
  // Owner's user ID
  ownerId,
  // Patient's user ID
  userId,
  // Relevant consultation ID
  consultationId,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Action to update/create
  submitPatientDiagnosisForm,
  // Get doctor notes by Id from state
  getDoctorNotesById,
}) => {
  const formRef = useRef();

  const [isSaving, setSaving] = useState(false);

  const patientDiagnosisPopulatedValue = useMemo(() => {
    if (defaultValues) {
      const primaryDiagnosis = defaultValues?.diagnosis.filter(
        (item) => item.type === DIAGNOSIS_TYPES.PRIMARY.key
      );

      const secondaryDiagnosis = defaultValues?.diagnosis.filter(
        (item) => item.type === DIAGNOSIS_TYPES.SECONDARY.key
      );

      return {
        primary: primaryDiagnosis?.map((item) => ({
          cod: item.code,
          name: item.name,
        })),
        secondary: secondaryDiagnosis?.map((item) => ({
          cod: item.code,
          name: item.name,
        })),
      };
    }
  }, [defaultValues]);

  const patientDiagnosisForm = useMemo(() => {
    return getDoctorNotesById(userId)?.[FIELDS.DIAGNOSIS.key];
  }, [getDoctorNotesById, userId]);

  const onSubmit = useCallback(
    (formData) => {
      // Mapping diagnosis to array as expected from BE side having name, code and type(PRIMARY/SECONDARY) for each
      const primaryDiagnosis = formData.primary.map((item) => ({
        name: item.name,
        code: item.cod,
        type: DIAGNOSIS_TYPES.PRIMARY.key,
      }));

      const secondaryDiagnosis = formData.secondary.map((item) => ({
        name: item.name,
        code: item.cod,
        type: DIAGNOSIS_TYPES.SECONDARY.key,
      }));

      const payload = {
        diagnosis: [...primaryDiagnosis, ...secondaryDiagnosis],
      };

      setSaving(true);
      submitPatientDiagnosisForm(
        userId,
        consultationId,
        {
          [FIELDS.USER_ID.key]: userId,
          [FIELDS.OWNER_ID.key]: ownerId,
          [FIELDS.CONSULTATION_ID.key]: consultationId,
          [FIELDS.DIAGNOSIS.key]: payload,
        },
        () => {
          onSubmitted(payload);
          setSaving(false);
        }
      );
    },
    [
      userId,
      ownerId,
      onSubmitted,
      consultationId,
      patientDiagnosisForm,
      submitPatientDiagnosisForm,
      patientDiagnosisPopulatedValue,
    ]
  );

  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      defaultValues={patientDiagnosisPopulatedValue}
    >
      {({ control }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.DIAGNOSIS.key}
          title={SECTIONS.DIAGNOSIS.label}
          actions={
            <Button
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              spinning={isSaving}
              onClick={() => {
                formRef?.current?.submit();
              }}
            >
              Save and continue
            </Button>
          }
        >
          <Grid container rowSpacing={3} columnSpacing={5}>
            <FormField
              xs={12}
              md={6}
              required
              label="PRIMARY DIAGNOSIS"
              field={
                <Controller
                  name="primary"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <DiagnosisAutocomplete
                      multiple
                      longLabel
                      size="medium"
                      onChange={field.onChange}
                      defaultValue={field.value}
                      error={Boolean(error)}
                      helperText={error?.message ?? null}
                    />
                  )}
                  rules={{
                    required: 'Primary diagnosis is required',
                  }}
                />
              }
            />
            <FormField
              xs={12}
              md={6}
              label="SECONDARY DIAGNOSIS"
              field={
                <Controller
                  name="secondary"
                  control={control}
                  defaultValue={[]}
                  render={({ field }) => (
                    <DiagnosisAutocomplete
                      multiple
                      longLabel
                      size="medium"
                      onChange={field.onChange}
                      defaultValue={field.value}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Section>
      )}
    </Form>
  );
};

export default DiagnosisForm;
