import * as types from './actionTypes';

export const fetchAnonymousUser = (id) => ({
  type: types.FETCH_ANONYMOUS_USER,
  id,
});

export const anonymousUserReceived = (anonymousUser) => ({
  type: types.ANONYMOUS_USER_RECEIVED,
  anonymousUser,
});
