import * as types from './actionTypes';

const initialState = {
  list: [],
  cities: [],
};

const tenantReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TENANTS_RECEIVED: {
      return {
        ...state,
        list: [...(action.tenants || [])],
      };
    }
    case types.CITIES_RECEIVED: {
      return {
        ...state,
        cities: [...(action.cities || [])],
      };
    }
    default:
      return state;
  }
};

export default tenantReducer;
