/* eslint-disable import/prefer-default-export */
import uniqBy from 'lodash/uniqBy';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { isAllowed } from 'core/permission';
import { lineItemGetters } from 'domain/order';
import DownloadPdfButton from 'core/pdf/DownloadPdfButton';
import { Box, Tooltip, IconButton, PopConfirm } from 'design-system';
import {
  medicineUtils,
  medicineGetters,
  medicineConstants,
} from 'domain/medicine';

const { QUANTITY_UNIT_TYPES } = medicineConstants;

const WrapText = ({ children }) => (
  <Box sx={{ whiteSpace: 'break-spaces' }}>{children}</Box>
);

const ActionsCell = ({
  params,
  onEditButtonClicked,
  onDelete,
  beforeDelete,
}) => {
  const [popConfirmAnchor, setPopConfirmAnchor] = useState(null);

  const showPopConfirm = (event) => {
    setPopConfirmAnchor(event.currentTarget);
  };

  const closePopConfirm = () => {
    setPopConfirmAnchor(null);
  };

  return (
    <>
      {isAllowed('orderView', 'medicines', 'edit') && (
        <IconButton
          sx={{
            mr: {
              xs: 0.5,
              xl: 1,
            },
            p: {
              xs: 0.5,
              xl: 1,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            onEditButtonClicked(params?.row);
          }}
        >
          <Tooltip placement="top" title="Edit medicine line item">
            <EditIcon fontSize="small" color="primary" />
          </Tooltip>
        </IconButton>
      )}
      {isAllowed('orderView', 'medicines', 'delete') && (
        <>
          <IconButton
            sx={{
              mr: {
                xs: 0.5,
                xl: 1,
              },
              p: {
                xs: 0.5,
                xl: 1,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();

              if (beforeDelete()) {
                showPopConfirm(e);
              }
            }}
          >
            <Tooltip placement="top" title="Delete medicine line item">
              <DeleteOutlinedIcon fontSize="small" color="error" />
            </Tooltip>
          </IconButton>
          <PopConfirm
            primaryButtonTitle="Cancel"
            secondaryButtonTitle="Delete"
            anchorEl={popConfirmAnchor}
            onOk={() => closePopConfirm()}
            open={Boolean(popConfirmAnchor)}
            onCancel={() => {
              onDelete(params?.row);
            }}
            id={popConfirmAnchor ? 'delete-confirm' : undefined}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            Are you sure you want to delete this medicine?
          </PopConfirm>
        </>
      )}
      {isAllowed('orderView', 'medicines', 'printSticker') && (
        <Tooltip injectWrapper placement="top" title="Print medicine sticker">
          <DownloadPdfButton
            size="small"
            variant="text"
            disableRipple
            color="primary"
            resource="medicine"
            fileName={`Order#${
              params.row.orderId
            }_${params.row.medicine.packageName.replace(/\s/g, '')}`}
            payload={{
              name: params.row.medicine.packageName,
              instructionLine: params.row.instructions,
              frequency: params.row.frequency,
              frequencyUnit: params.row.frequencyUnit,
              frequencyUse: params.row.frequencyUse,
              frequencyUseUnit: params.row.frequencyUseUnit,
            }}
            sx={{
              mr: {
                xs: 0.5,
                xl: 1,
              },
              p: {
                xs: 0.5,
                xl: 1,
              },
              pr: 0,
              minWidth: 0,
            }}
          />
        </Tooltip>
      )}
    </>
  );
};

export const getMedicineColumns = ({
  onEditButtonClicked = () => {},
  onDelete,
  beforeDelete,
}) =>
  [
    {
      field: 'medicine',
      headerName: 'Medicine',
      flex: 3,
      renderCell: ({ row }) => {
        // TODO: Add link to medicine view, and check viewMedicine permission
        return (
          <WrapText>
            {(() => {
              const packageName = medicineGetters.getPackageName(row.medicine);
              const genericName = medicineGetters.getGenericName(row.medicine);
              const strength = medicineGetters.getStrength(row.medicine);
              const dosageForm = medicineGetters.getDosageForm(row.medicine);
              const packageSizeSummary = medicineGetters.getPackageSizeSummary(
                row.medicine
              );

              return uniqBy([
                packageName,
                genericName,
                strength,
                dosageForm,
                packageSizeSummary,
              ])
                .filter(Boolean)
                .join(' - ');
            })()}
          </WrapText>
        );
      },
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      flex: 1,
      valueGetter: ({ row }) => {
        return medicineUtils.toReadableQuantity(
          lineItemGetters.getQuantity(row),
          medicineUtils.getKey(
            QUANTITY_UNIT_TYPES,
            lineItemGetters.getQuantityUnit(row)
          ) || lineItemGetters.getQuantityUnit(row)
        );
      },
    },
    {
      field: 'priceAfterInsuranceDiscount',
      headerName: 'Price',
      flex: 1,
      valueGetter: ({ row }) =>
        `${lineItemGetters.getPriceAfterInsuranceDiscount(row)?.currency} ${(
          lineItemGetters.getPriceAfterInsuranceDiscount(row)?.value || 0
        ).toFixed(2)}`,
    },
    {
      field: 'patientShare',
      headerName: 'Patient Share',
      flex: 1,
      valueGetter: ({ row }) =>
        `${lineItemGetters.getPatientShare(row).currency} ${(
          lineItemGetters.getPatientShare(row).value || 0
        ).toFixed(2)}`,
    },
    {
      field: 'patientDiscount',
      headerName: 'Patient Discount',
      flex: 1,
      valueGetter: ({ row }) =>
        `${lineItemGetters.getPatientDiscount(row).currency} ${(
          lineItemGetters.getPatientDiscount(row).value || 0
        ).toFixed(2)}`,
    },
    {
      field: 'actions',
      headerName: '',
      ...(() => {
        const permissions = [
          isAllowed('orderView', 'medicines', 'delete'),
          isAllowed('orderView', 'medicines', 'edit'),
          isAllowed('orderView', 'medicines', 'printSticker'), // For English
          isAllowed('orderView', 'medicines', 'printSticker'), // For Arabic
        ].filter(Boolean);

        return {
          width: permissions.length * 56,
          hide: !permissions.length,
        };
      })(),
      renderCell: (params) => {
        return (
          <ActionsCell
            params={params}
            onEditButtonClicked={onEditButtonClicked}
            onDelete={onDelete}
            beforeDelete={beforeDelete}
          />
        );
      },
    },
  ].map((column) => ({
    ...column,
    sortable: false,
    hideSortIcons: true,
    disableColumnMenu: true,
  }));
