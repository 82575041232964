import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as MEDICINE } from './constants';

// Actions
export const CREATE_MEDICINE = `${DOMAIN}/${MEDICINE}/CREATE_MEDICINE`;
export const FETCH_MEDICINE = `${DOMAIN}/${MEDICINE}/FETCH_MEDICINE`;
export const PATCH_MEDICINE = `${DOMAIN}/${MEDICINE}/PATCH_MEDICINE`;
export const SET_FETCHING = `${DOMAIN}/${MEDICINE}/SET_FETCHING`;

// Responses
export const MEDICINE_CREATED = `${DOMAIN}/${MEDICINE}/MEDICINE_CREATED`;
export const MEDICINE_RECEIVED = `${DOMAIN}/${MEDICINE}/MEDICINE_RECEIVED`;

// Statuses
export const SET_SUBMITTING_MEDICINE = `${DOMAIN}/${MEDICINE}/SET_SUBMITTING_MEDICINE`;
