import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { DataGrid } from 'design-system';
import { DataGridCard, useServerPagination } from 'core/dataGrid';
import {
  doctorGetters as getters,
  DOCTOR_FIELDS as FIELDS,
} from 'domain/doctor';
import { UserSearch } from 'presentation/user';
import { userGetters } from 'domain/user';
import { getColumns } from './utils';

const DoctorProfileList = () => {
  const navigate = useNavigate();
  const viewPath = (row) => `/doctor/${getters.getId(row)}`;
  const params = new URLSearchParams();
  const [queryParamsMap, setQueryParamsMap] = useState({});
  const [isFetching, setFetching] = useState(true);
  const onRowClick = (row) => {
    navigate(viewPath(row));
  };

  const {
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/doctors',
    params,
    pageSize: 15,
    initialSortModel: { field: 'updatedAt', sort: 'desc' },
    columnFieldsMap: {
      doctorUser: 'userId',
    },
  });

  useEffect(() => {
    if (!isEmpty(queryParamsMap)) {
      const filterParams = [];
      const { doctorUserId, isActive, doctorName } = queryParamsMap;
      if (doctorUserId) {
        filterParams.push(`${FIELDS.USER_ID.name}||$eq||${doctorUserId}`);
      }
      if (isActive !== undefined) {
        filterParams.push(`${FIELDS.ACTIVE.name}||eq||${isActive}`);
      }
      if (doctorName) {
        filterParams.push(`${FIELDS.ENGLISH_NAME.name}||$contL||${doctorName}`);
      }
      const urlSearchParams = new URLSearchParams();
      forEach(filterParams, (param) => {
        urlSearchParams.append('filter', param);
      });

      setQueryParams(urlSearchParams);
    }
  }, [queryParamsMap]);

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);
  const filtersDef = {
    fields: [
      {
        name: 'doctorUserId',
        type: 'custom',
        component: (
          <UserSearch
            disabled={isFetching}
            onUserSelect={(selectedPatient) =>
              setQueryParamsMap({
                ...queryParamsMap,
                doctorUserId: userGetters.getId(selectedPatient),
              })
            }
          />
        ),
      },
      {
        name: 'doctorName',
        type: 'textInput',
        placeholder: 'Doctor Name (EN)',
        disabled: isFetching,
        onInputChange: (event) => {
          setQueryParamsMap({
            ...queryParamsMap,
            doctorName: event.target.value,
          });
        },
      },
      {
        name: 'isActive',
        type: 'switch',
        label: 'Active',
        disabled: isFetching,
        filter: (isActive) => {
          setQueryParamsMap({
            ...queryParamsMap,
            isActive,
          });
        },
      },
    ],
  };

  return (
    <DataGridCard filters={filtersDef}>
      <DataGrid
        loading={isFetching}
        columns={getColumns()}
        onRowClick={(prms) => onRowClick(prms.row)}
        contextMenuItems={[
          {
            label: 'Open',
            handler: onRowClick,
          },
          {
            label: 'Open in a new tab',
            handler: (row) => {
              window.open(viewPath(row), '_blank');
            },
          },
        ]}
        {...dataGridProps}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </DataGridCard>
  );
};
DoctorProfileList.propTypes = {};
export default DoctorProfileList;
