import React, { useState, useEffect } from 'react';

import logger from 'core/logger';
import { TextField } from 'design-system';

import { parseMapUrl } from './utils';

const MapLinkSearchInput = ({
  onChange,
  defaultValue,
  addressFields,
  ...rest
}) => {
  const [value, setValue] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <TextField
      value={value}
      variant="outlined"
      placeholder="https://www.google.com/maps/place/Cleveland+Clinic+Abu+Dhabi/@24.4972965,54.3879995,17z"
      onChange={(event) => {
        const url = event.target.value;

        setValue(url);

        if (error) {
          setError(null);
        }
      }}
      onBlur={(event) => {
        const url = event.target.value;

        if (url) {
          if (url.isURL()) {
            setError(null);
            parseMapUrl(addressFields)(url)
              .then((location) => {
                onChange(location, url);
              })
              .catch((err) => {
                setError(err);
                onChange(null);
                logger.error('maplinkerror', err);
              });
          } else {
            setError({ message: 'Invalid URL' });
          }
        }
      }}
      error={Boolean(error)}
      helperText={error?.message ?? null}
      {...rest}
    />
  );
};

export default MapLinkSearchInput;
