import get from 'lodash/get';
import { createSelector } from 'reselect';

export const commentSelector = (state) => get(state, 'domain.comment', {});

export const getCommentsOf = createSelector(
  [
    commentSelector,
    (_state, referenceType) => referenceType,
    (_state, _, referenceId) => referenceId,
  ],
  (results, referenceType, referenceId) =>
    get(results, `list.${referenceType}.${referenceId}`, [])
);

export const isFetching = createSelector(
  [
    commentSelector,
    (_state, referenceType) => referenceType,
    (_state, _, referenceId) => referenceId,
  ],
  (results, referenceType, referenceId) =>
    get(results, `isFetching.${referenceType}.${referenceId}`, [])
);
