import React from 'react';
import { useRouteError } from 'react-router-dom';

import logger from 'core/logger';
import { appUtils } from 'core/utils';
import { Grid, Typography } from 'design-system';

export default function ErrorPage() {
  const error = useRouteError();

  logger.error(error);

  return (
    <Grid column sx={{ textAlign: 'center' }} spacing={2} m={6}>
      <Grid item mb={2}>
        <Typography variant="h1">Oops!</Typography>
      </Grid>
      <Grid item>
        <Typography variant="p1">
          Sorry, an unexpected error has occurred.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="p3">
          <i>{error.statusText || error.message}</i>
        </Typography>
      </Grid>
      {appUtils.isDev() && (
        <Grid item>
          <Typography variant="p3">
            <i>{JSON.stringify(error)}</i>
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
