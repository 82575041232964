import isEmpty from 'lodash/isEmpty';
import SendIcon from '@mui/icons-material/Send';
import React, { useState, useEffect } from 'react';
import AddCommentRoundedIcon from '@mui/icons-material/AddCommentRounded';

import { userGetters } from 'domain/user';
import { commentGetters as getters } from 'domain/comment';
import {
  Grid,
  Card,
  Divider,
  ChatView,
  TextField,
  IconButton,
  CardHeader,
  CardContent,
  InputAdornment,
} from 'design-system';

const Comments = ({
  sx = {},
  loading,
  comments,
  emptyView,
  permissions = {},
  createComment,
  fetchComments,
}) => {
  const { add: isAddAllowed = true } = permissions;

  const [messages, setMessages] = useState([]);
  const [textFieldValue, setTextFieldValue] = useState();

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    if (!isEmpty(comments)) {
      const parsedComments = comments.map((comment) => ({
        content: getters.getContent(comment),
        createdAt: getters.getCreatedAt(comment),
        author: userGetters.getFullName(getters.getCreatedByUser(comment)),
      }));

      setMessages(parsedComments);
    }
  }, [comments]);

  const addComment = () => {
    if (!textFieldValue) {
      return;
    }

    createComment(textFieldValue);
    setTextFieldValue('');

    setMessages([
      {
        content: textFieldValue,
        createdAt: new Date(),
        // TODO: Once the user is added to the jwt, take it from there
        author: 'You',
      },
      ...messages,
    ]);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        ...sx,
      }}
    >
      <CardHeader title="Comments" sx={{ pb: 0 }} />
      <CardContent noPadding sx={{ height: 'calc(100% - 44px)' }}>
        <Grid container column flexWrap="nowrap" sx={{ height: '100%' }}>
          <Grid item p={2} sx={{ height: '100%', overflowY: 'auto' }}>
            <ChatView
              messages={messages}
              loading={loading}
              emptyView={
                emptyView || (
                  <AddCommentRoundedIcon
                    sx={{
                      color: (theme) => theme.palette.secondary.tint,
                      fontSize: '20rem',
                      width: '100%',
                    }}
                  />
                )
              }
            />
          </Grid>
          {isAddAllowed && (
            <Grid
              item
              container
              column
              sx={{
                height: (theme) => theme.spacing(7) + 1,
              }}
            >
              <Grid item>
                <Divider />
              </Grid>
              <Grid item px={2} py={1}>
                <TextField
                  fullWidth
                  size="medium"
                  shape="round"
                  variant="filled"
                  value={textFieldValue}
                  placeholder="Type your comment here"
                  loading={loading && { width: '100%' }}
                  onChange={(event) => {
                    setTextFieldValue(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 /* "Enter: key */) {
                      addComment();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={!textFieldValue}
                          color="primary"
                          onClick={addComment}
                          sx={{
                            py: 0.5,
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Comments;
