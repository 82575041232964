import get from 'lodash/get';
import keys from 'lodash/keys';
import format from 'date-fns/format';
import findKey from 'lodash/findKey';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import differenceInMonths from 'date-fns/differenceInMonths';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';

import Comments from 'presentation/comment';
import { isAllowed } from 'core/permission';
import { authSelectors } from 'domain/auth';
import { CountrySelect } from 'core/country';
import FileUpload from 'core/file/FileUpload';
import { REFERENCE_TYPES } from 'constants/general';
import { USER_STATUSES } from 'domain/user/constants';
import { copyToClipboard } from 'core/utils/document';
import { FILE_TYPES, fileGetters } from 'domain/file';
import { getCountryByLabel } from 'core/country/utils';
import { dateFormat, dateTimeFormat } from 'constants/dateTime';
import { STAGES, SUBSCRIPTION_FIELDS } from 'domain/subscription';
import { DialerLink, UserInfoLink } from 'presentation/components';
import { PatientHistorySection } from 'presentation/patientHistory';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';
import SendNotification from 'presentation/consultation/ConsultationView/components/SendNotification';
import {
  isShowEnrollSubscription,
  isIdentityExtractionEnabled,
} from 'core/siteConfig';
import {
  userGetters as getters,
  userConstants as constants,
  USER_DOMAIN_FIELDS as FIELDS,
} from 'domain/user';
import {
  Grid,
  Card,
  Chip,
  Select,
  Button,
  Divider,
  Tooltip,
  Message,
  Checkbox,
  MenuItem,
  RowLayout,
  FormField,
  TextField,
  PageTitle,
  CardHeader,
  Typography,
  DatePicker,
  PopConfirm,
  CardContent,
  CardActions,
} from 'design-system';

import MemberManagement from '../components/MemberManagement';
import SubscriptionPlanDialog from '../components/SubscriptionPlanDialog';
import { PatientHealthInfoEdit, IdentityExtractionDialog } from '../components';

const { USER_TAGS, USER_OWNERSHIP_TYPE } = constants;

const OwnershipChip = ({ isFetching, ownershipType }) => {
  if (isFetching || ownershipType === USER_OWNERSHIP_TYPE.Owner.key) {
    return (
      <Chip
        icon={<PersonOutlinedIcon />}
        label="Owner"
        variant="filled"
        loading={
          isFetching && {
            width: 80,
          }
        }
        sx={{
          color: (theme) => theme.palette.success.dark,
          backgroundColor: (theme) => theme.palette.success.tint,
        }}
      />
    );
  }

  return (
    <Chip
      icon={<PersonOutlinedIcon />}
      label="Member"
      variant="filled"
      sx={{
        color: (theme) => theme.palette.warning.dark,
        backgroundColor: (theme) => theme.palette.warning.tint,
      }}
    />
  );
};

const UserView = ({
  user,
  tenants,
  getUser,
  isFetching,
  updateUser,
  isSubmitting,
  subscription,
  getSubscription,
  subscriptionPlans,
  updateSubscription,
  cancelSubscription,
  insuranceProvidersList,
  fetchSubscriptionPlans,
  isFetchingSubscriptions,
  isSubmittingSubscriptions,
  isSavingExtractedIdentityData,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;

  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  const [hasFormChanged, setFormChanged] = useState(false);
  const [hasInsuranceFormChanged, setInsuranceFormChanged] = useState(false);
  const [hasSubscriptionFormChanged, setSubscriptionFormChanged] =
    useState(false);
  const [isIdentityExtractionDialogOpen, setIdentityExtractionDialogOpen] =
    useState(false);

  // Fields' states
  const [createdAt, setCreatedAt] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [updatedBy, setUpdatedBy] = useState();
  const [tenant, setTenant] = useState(defaultTenant);

  // Basic Info
  const [phoneNumber, setPhoneNumber] = useState();
  const [fullName, setFullName] = useState('N/A');
  const [referenceFullName, setReferenceFullName] = useState();
  const [signupSource, setSignupSource] = useState();
  const [status, setStatus] = useState('');
  const [verified, setVerified] = useState(false);
  const [lastActive, setLastActive] = useState();
  const [tags, setTags] = useState([]);
  const [city, setCity] = useState();
  const [ownershipType, setOwnershipType] = useState();
  const [ownerId, setOwnerId] = useState();

  const [language, setLanguage] = useState();
  const [addresses, setAddresses] = useState([]);
  const [identityBack, setIdentityBack] = useState([]);
  const [identityFront, setIdentityFront] = useState([]);
  const [identityNumber, setIdentityNumber] = useState();
  const [completeIdentityBack, setCompleteIdentityBack] = useState([]);
  const [completeIdentityFront, setCompleteIdentityFront] = useState([]);
  const [completeInsuranceCardFileName, setCompleteInsuranceCardFileName] =
    useState([]);

  // Additional Info
  const [nationality, setNationality] = useState();
  const [email, setEmail] = useState();
  const [emergencyContacts, setEmergencyContacts] = useState([]);

  // Insurance
  const [externalReferenceId, setExternalReferenceId] = useState();
  const [insuranceCardNumber, setInsuranceCardNumber] = useState();
  const [insuranceProvider, setInsuranceProvider] = useState({});
  const [insuranceCardFileName, setInsuranceCardFileName] = useState([]);
  const [insuranceValidUntil, setInsuranceValidUntil] = useState();
  const [insuranceType, setInsuranceType] = useState();
  const [insuranceGroup, setInsuranceGroup] = useState();
  const [insurancePlan, setInsurancePlan] = useState();
  const [insuranceProviderId, setInsuranceProviderId] = useState();
  const [namespace, setNamespace] = useState();

  const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [isValidEmail, setValidEmail] = useState(true);

  // Subscription
  const [subscriptionId, setSubscriptionId] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [subscriptionStage, setSubscriptionStage] = useState('');
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [subscriptionRenewedAt, setSubscriptionRenewedAt] = useState(null);
  const [subscriptionPaymentLink, setSubscriptionPaymentLink] = useState('');
  const [isCreateSubscriptionDialogOpen, setCreateSubscriptionDialogOpen] =
    useState(false);
  const [isExpiredSubscription, setIsExpiredSubscription] = useState(false);
  const [isShowCancelSubscriptionBtn, setShowCancelSubscriptionBtn] =
    useState(false);
  const [
    subscriptionCancelPopConfirmAnchor,
    setSubscriptionCancelPopConfirmAnchor,
  ] = useState(null);

  const showSubscriptionCancelPopConfirm = (event) => {
    setSubscriptionCancelPopConfirmAnchor(event.currentTarget);
  };
  const closeSubscriptionCancelPopConfirm = () => {
    setSubscriptionCancelPopConfirmAnchor(null);
  };

  useEffect(() => {
    if (isAllowed('userView', 'subscription')) {
      fetchSubscriptionPlans();
    }
  }, []);

  useEffect(() => {
    getUser(id);

    if (isAllowed('userView', 'subscription')) {
      getSubscription(id);
    }
  }, [id]);

  const resetSubscriptionFields = () => {
    setSubscriptionId(null);
    setSubscriptionStage(null);
    setSubscriptionStatus(null);
    setSubscriptionPlan(null);
    setIsExpiredSubscription(false);
    setShowCancelSubscriptionBtn(false);
    setSubscriptionRenewedAt(null);
    setSubscriptionPaymentLink(null);
  };

  const toggleFormChangedStatus = (isEnabled) => {
    if (!isFetching) {
      setFormChanged(isEnabled);
    }
  };

  const toggleInsuranceFormStatus = (isEnabled) => {
    setFormChanged(true);
    if (!isFetching) {
      setInsuranceFormChanged(isEnabled);
    }
  };

  const toggleSubscriptionFormStatus = (isEnabled) => {
    if (!isFetching) {
      setSubscriptionFormChanged(isEnabled);
    }
  };

  const validateEmail = (eml) => {
    if (
      !eml ||
      eml.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setValidEmail(true);
      toggleFormChangedStatus(true);
    } else {
      setValidEmail(false);
    }

    setEmail(eml);
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      setTenant(getters.getTenant(user));
      setOwnershipType(getters.getOwnershipType(user));
      setOwnerId(getters.getOwnerId(user));
      setPhoneNumber(getters.getPhoneNumber(user));
      setFullName(getters.getFullName(user));
      setSignupSource(getters.getSignUpSource(user));
      setVerified(getters.isVerified(user));
      setLastActive(
        getters.getLastActive(user)
          ? format(parseISO(getters.getLastActive(user)), dateTimeFormat)
          : null
      );
      setTags(getters.getTags(user));
      setLanguage(getters.getLanguage(user));
      setEmergencyContacts(getters.getEmergencyContacts(user));
      setIdentityNumber(getters.getIdentityNumber(user));
      setCity(getters.getCity(user));
      setReferenceFullName(getters.getReferenceFullName(user));

      const statusKey = findKey(USER_STATUSES, {
        key: getters.getStatus(user),
      });
      setStatus(get(USER_STATUSES, `${statusKey}.label`));

      // Insurance
      setExternalReferenceId(getters.getExternalReferenceNumber(user));
      setInsuranceCardNumber(getters.getInsuranceCardNumber(user));
      setInsuranceProvider(getters.getInsuranceProvider(user));
      setInsuranceProviderId(getters.getInsuranceProviderId(user));
      setInsuranceGroup(getters.getInsuranceGroup(user));
      setInsurancePlan(getters.getInsurancePlan(user));
      setInsuranceType(getters.getInsuranceType(user));

      if (getters.getInsuranceValidUntil(user) !== null) {
        setInsuranceValidUntil(new Date(getters.getInsuranceValidUntil(user)));
      } else {
        setInsuranceValidUntil(null);
      }

      setCreatedAt(
        format(parseISO(getters.getCreatedAt(user)), dateTimeFormat)
      );
      setUpdatedAt(
        format(parseISO(getters.getUpdatedAt(user)), dateTimeFormat)
      );
      setCreatedBy(getters.getFullName(getters.getCreatedByUser(user)));
      setUpdatedBy(getters.getFullName(getters.getUpdatedByUser(user)));
      setAddresses(getters.getAddresses(user));
      setNamespace(getters.getNamespace(user));
      const patientNationality = getters.getNationality(user);

      if (patientNationality) {
        setNationality(getCountryByLabel(patientNationality));
      }

      setEmail(getters.getEmail(user));

      const idFront = getters.getIdentityFront(user)
        ? [
            {
              id: getters.getIdentityFront(user),
            },
          ]
        : [];

      setIdentityFront(idFront);
      setCompleteIdentityFront(idFront);

      const idBack = getters.getIdentityBack(user)
        ? [
            {
              id: getters.getIdentityBack(user),
            },
          ]
        : [];

      setIdentityBack(idBack);
      setCompleteIdentityBack(idBack);

      const insuranceCard = getters.getInsuranceCardFileName(user)
        ? [
            {
              id: getters.getInsuranceCardFileName(user),
            },
          ]
        : [];

      setInsuranceCardFileName(insuranceCard);
      setCompleteInsuranceCardFileName(insuranceCard);
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(subscription)) {
      setSubscriptionId(subscription.id);
      setSubscriptionStage(subscription.stage);
      setSubscriptionStatus(subscription.status);
      setSubscriptionPlan(subscription.plan?.id);
      setSubscriptionPaymentLink(subscription.paymentTransaction?.link);

      if (subscription.renewedAt) {
        // Parse the "renewedAt" value to a Date object
        const renewedAtDate = parseISO(subscription.renewedAt);
        // Check if "renewedAt" is before one month ago
        const isExpired = differenceInMonths(renewedAtDate, new Date()) < 0;

        // If the subscription renewedAt field (paid subscription) then show the cancelation option
        setShowCancelSubscriptionBtn(true);
        setIsExpiredSubscription(isExpired);
        setSubscriptionRenewedAt(
          format(parseISO(subscription.renewedAt), dateTimeFormat)
        );
      }
    }
  }, [subscription]);

  const submitChanges = (e) => {
    e.stopPropagation();

    if (hasSubscriptionFormChanged) {
      // Update subscription
      const payload = {
        [SUBSCRIPTION_FIELDS.STAGE.name]: subscriptionStage,
        [SUBSCRIPTION_FIELDS.PLAN_ID.name]: subscriptionPlan,
      };
      updateSubscription(subscriptionId, payload);
    }

    if (hasFormChanged) {
      // Update user info
      const payload = {
        // BASIC
        [FIELDS.ID.name]: id,
        [FIELDS.FULL_NAME.name]: fullName,
        [FIELDS.PHONE_NUMBER.name]: phoneNumber,
        [FIELDS.IS_VERIFIED.name]: verified,
        [FIELDS.SIGN_UP_SOURCE.name]: signupSource,
        [FIELDS.LANGUAGE.name]: language,
        [FIELDS.ADDRESSES.name]: getters.getAddresses(user),
        [FIELDS.USER_ID.name]: id,
        [FIELDS.CITY.name]: city,
        // IDENTITY
        [FIELDS.IDENTITY_FRONT.name]:
          fileGetters.getId(completeIdentityFront?.[0]) || '',
        [FIELDS.IDENTITY_BACK.name]:
          fileGetters.getId(completeIdentityBack?.[0]) || '',
        [FIELDS.IDENTITY_NUMBER.name]: identityNumber,
        [FIELDS.TAGS.name]: tags.join(',') || null,
        [FIELDS.EMERGENCY_CONTACTS.name]: emergencyContacts,
        [FIELDS.NATIONALITY.name]: nationality?.label,
        [FIELDS.EMAIL.name]: email,
        [FIELDS.TENANT_KEY.name]: tenant,
        // INSURANCE
        [FIELDS.EXTERNAL_REFERENCE_NUMBER.name]: externalReferenceId,
        [FIELDS.INSURANCE_CARD_FILE_NAME.name]:
          fileGetters.getId(completeInsuranceCardFileName?.[0]) || '',
        [FIELDS.INSURANCE_PROVIDER_ID.name]: insuranceProviderId,
        [FIELDS.INSURANCE_CARD_NUMBER.name]: insuranceCardNumber,
        [FIELDS.INSURANCE_GROUP.name]: insuranceGroup,
        [FIELDS.INSURANCE_TYPE.name]: insuranceType,
        [FIELDS.INSURANCE_PLAN.name]: insurancePlan,
        [FIELDS.INSURANCE_VALID_UNTIL.name]: insuranceValidUntil,
        [FIELDS.INSURANCE_PROVIDER_ID.name]: insuranceProvider?.id,
      };
      updateUser(id, payload);
    }

    setFormChanged(false);
    setInsuranceFormChanged(false);
    setSubscriptionFormChanged(false);
  };

  const onIdentityExtractionDialogClosed = () => {
    setIdentityExtractionDialogOpen(false);
  };

  return (
    <>
      <PageTitle
        title={`Patient #${id}`}
        action={
          <Button
            variant="filled"
            onClick={submitChanges}
            loading={isFetching && { width: 160 }}
            spinning={isSubmitting}
            disabled={
              isFetching ||
              !isValidEmail ||
              (!hasFormChanged &&
                !hasInsuranceFormChanged &&
                !hasSubscriptionFormChanged) ||
              identityFront.length !== completeIdentityFront.length ||
              identityBack.length !== completeIdentityBack.length ||
              insuranceCardFileName.length !==
                completeInsuranceCardFileName.length
            }
          >
            Save Changes
          </Button>
        }
      />
      <Grid container spacing={3} column>
        <Grid item sx={{ width: '100%' }}>
          <RowLayout spacing={3}>
            {/* Patient Info Card 1 */}
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader
                title="Patient Info"
                subheader={namespace?.id > 1 ? namespace?.name : ''} // Don't show clinic when its the default(Alma)
                sx={{ pb: 0 }}
                action={
                  <OwnershipChip
                    isFetching={isFetching}
                    ownershipType={ownershipType}
                  />
                }
              />
              <CardContent
                noPadding
                sx={{
                  p: 2,
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                <Grid item>
                  {/** FULL NAME */}
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                    >
                      FULL NAME
                    </Typography>
                  </Grid>
                  <Grid container item sx={{ mb: 2 }}>
                    <Grid container item row alignItems="center">
                      <Grid item xs sx={{ flexGrow: 1 }}>
                        <TextField
                          fullWidth
                          size="medium"
                          variant="filled"
                          value={fullName}
                          loading={isFetching}
                          onChange={(event) => {
                            setFullName(event.target.value);
                            toggleFormChangedStatus(true);
                          }}
                        />
                      </Grid>
                      {isAllowed('userView', 'lastActive') && lastActive && (
                        <Tooltip
                          placement="top"
                          title="Last seen on the mobile app"
                          injectWrapper
                        >
                          <Grid container item row>
                            <Grid
                              container
                              item
                              alignItems="center"
                              row
                              wrap="nowrap"
                              sx={{
                                ml: 1,
                              }}
                            >
                              <AccessTimeIcon
                                fontSize="extraSmall"
                                color="standard"
                                sx={{ mt: 0, mr: 0.2 }}
                              />
                              <Typography
                                variant="p4"
                                xs={2}
                                wrap="nowrap"
                                textAlign="right"
                                alignItems="right"
                              >
                                {lastActive}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Tooltip>
                      )}
                    </Grid>
                    {isAllowed('userView', 'lastActive') &&
                      !fullName &&
                      referenceFullName && (
                        <Typography
                          variant="p4"
                          loading={isFetching && { width: 50 }}
                        >
                          {referenceFullName}
                        </Typography>
                      )}
                  </Grid>
                  {/** Member Management */}
                  {isAllowed('userView', 'memberManagement', 'view') &&
                    (isFetching ||
                      ownershipType === USER_OWNERSHIP_TYPE.Owner.key) && (
                      <>
                        <Grid item sx={{ mb: 1 }}>
                          <Typography
                            variant="p4"
                            loading={isFetching && { width: 50 }}
                          >
                            Members
                          </Typography>
                        </Grid>
                        <MemberManagement ownerId={id} loading={isFetching} />
                      </>
                    )}
                  {isAllowed('userView', 'memberManagement', 'view') &&
                    ownershipType === USER_OWNERSHIP_TYPE.Member.key && (
                      <>
                        <Grid item sx={{ mb: 1 }}>
                          <Typography
                            variant="p4"
                            loading={isFetching && { width: 50 }}
                          >
                            Owner
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          flexWrap="nowrap"
                          alignItems="center"
                          columnSpacing={1}
                        >
                          <Grid item>
                            <PersonOutlinedIcon color="standard" />
                          </Grid>
                          <Grid item sx={{ width: '100%' }}>
                            <UserInfoLink
                              key={ownerId}
                              userId={ownerId}
                              config={{
                                isPhoneNumberShown: false,
                                namePlaceholder: "Visit user's profile",
                              }}
                              sx={{
                                width: 'auto',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  {/** PHONE NUMBER */}
                  <Grid item sx={{ my: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                    >
                      PHONE NUMBER
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    <DialerLink
                      phoneNumber={phoneNumber}
                      isFetching={isFetching}
                    >
                      {phoneNumber}
                    </DialerLink>
                  </Grid>
                  {/** STATUS */}
                  {isAllowed('userView', 'status') && (
                    <>
                      <Grid item sx={{ mb: 1 }}>
                        <Typography
                          variant="p4"
                          loading={isFetching && { width: 50 }}
                        >
                          STATUS
                        </Typography>
                      </Grid>
                      <Grid item sx={{ mb: 2 }}>
                        <Typography
                          variant="l5"
                          loading={isFetching && { width: 200 }}
                        >
                          {status || 'N/A'}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {/** VERIFIED */}
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                      sx={{ verticalAlign: 'super' }}
                    >
                      VERIFIED
                    </Typography>
                    <Typography
                      variant="l5"
                      loading={isFetching && { width: 200 }}
                    >
                      {verified ? (
                        <CheckCircleOutlineSharpIcon
                          sx={{
                            color: (theme) => theme.palette.success.main,
                            pl: 1.25,
                          }}
                        />
                      ) : (
                        <CancelOutlinedIcon
                          sx={{
                            color: (theme) => theme.palette.error.main,
                            pl: 1.25,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>

                  {/** LANGUAGE */}
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                    >
                      LANGUAGE
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography
                      variant="l5"
                      loading={isFetching && { width: 200 }}
                    >
                      {language || 'N/A'}
                    </Typography>
                  </Grid>
                  {/** SIGNUP SOURCE */}
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                    >
                      SIGNUP SOURCE
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography
                      variant="l5"
                      loading={isFetching && { width: 200 }}
                    >
                      {signupSource}
                    </Typography>
                  </Grid>
                  {/** TAGS */}
                  <Grid item sx={{ mb: 2 }}>
                    <FormField
                      label="TAGS"
                      labelWidth={60}
                      valueWidth={170}
                      loading={isFetching}
                      field={
                        <Select
                          multiple
                          fullWidth
                          soak="light"
                          value={tags}
                          variant="filled"
                          loading={isFetching}
                          placeholder="Specify tags..."
                          onChange={(event) => {
                            setTags(event.target.value);
                            toggleFormChangedStatus(true);
                          }}
                          renderValue={(selected) => (
                            <Grid
                              sx={{
                                gap: 0.5,
                                display: 'flex',
                                flexWrap: 'wrap',
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  size="small"
                                  sx={{ maxHeight: 20 }}
                                  color={USER_TAGS[value]?.color}
                                  label={USER_TAGS[value]?.label || value}
                                />
                              ))}
                            </Grid>
                          )}
                        >
                          {keys(USER_TAGS).map((tag) => (
                            <MenuItem value={tag} key={tag}>
                              <Checkbox
                                checked={
                                  tags.filter((t) => t === tag).length > 0
                                }
                              />
                              {USER_TAGS[tag]?.label || tag}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                    />
                  </Grid>

                  {/** Addresses */}
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 80 }}
                    >
                      ADDRESSES
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    {(isFetching || isEmpty(addresses)) && (
                      <>
                        <Grid
                          item
                          container
                          flexWrap="nowrap"
                          alignItems="center"
                          sx={{ p: 2, minHeight: (theme) => theme.spacing(7) }}
                        >
                          <LocationOnOutlinedIcon
                            color="standard"
                            fontSize="extraSmall"
                            sx={{ pr: 1 }}
                          />
                          <Typography
                            variant="p3"
                            sx={{ lineHeight: 1 }}
                            loading={isFetching && { width: '70%' }}
                          >
                            No address provided
                          </Typography>
                        </Grid>
                        <Divider />
                      </>
                    )}
                    {!isFetching &&
                      addresses.map((address, idx) => (
                        <>
                          <Grid
                            item
                            container
                            flexWrap="nowrap"
                            alignItems="center"
                            sx={{
                              p: 2,
                              pl: idx === 0 ? 2 : 5,
                              minHeight: (theme) => theme.spacing(7),
                            }}
                          >
                            {idx === 0 && (
                              <LocationOnOutlinedIcon
                                color="standard"
                                fontSize="extraSmall"
                                sx={{ pr: 1 }}
                              />
                            )}
                            <Typography
                              variant="p3"
                              sx={{ lineHeight: 1 }}
                              dangerouslySetInnerHTML={{
                                __html: address.formattedAddress,
                              }}
                            />
                          </Grid>
                          <Divider />
                        </>
                      ))}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 130, sx: { mb: 0.5 } }}
                    >
                      User Created
                    </Typography>
                  </Grid>

                  <UpdatedOrCreatedBy
                    isFetching={isFetching}
                    user={createdBy}
                    time={createdAt}
                  />

                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 90, sx: { mb: 0.5 } }}
                    >
                      Last Updated
                    </Typography>
                  </Grid>
                  <UpdatedOrCreatedBy
                    isFetching={isFetching}
                    user={updatedBy}
                    time={updatedAt}
                  />
                </Grid>
              </CardContent>
              <CardActions>
                {isAllowed('userView', 'sendNotification') && (
                  <Tooltip
                    placement="top"
                    title="Send a new sms or mobile push notification to the patient"
                  >
                    <Button
                      size="large"
                      color="primary"
                      variant="text"
                      loading={isFetching && { width: 150 }}
                      startIcon={<MessageIcon color="secondary" />}
                      onClick={() => setNotificationDialogOpen(true)}
                    >
                      Send a notification
                    </Button>
                  </Tooltip>
                )}
              </CardActions>
            </Card>
            {/* Patient Info Card 2 */}
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader title="Patient Info" sx={{ pb: 0 }} />
              <CardContent
                noPadding
                sx={{
                  p: 2,
                  height: '100%',
                }}
              >
                <Grid
                  container
                  item
                  flexDirection="column"
                  sx={{ minHeight: (theme) => theme.spacing(7) }}
                  loading={isFetching}
                >
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 80 }}
                    >
                      CITY
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LocationCityIcon
                      color="standard"
                      fontSize="small"
                      sx={{ pr: 1 }}
                      loading={isFetching && { width: 80 }}
                    />
                    <Typography
                      variant="p4"
                      sx={{ lineHeight: 1 }}
                      loading={isFetching && { width: 80 }}
                    >
                      {city || 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
                {/** BRANCH */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 80 }}
                  >
                    BRANCH
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 2 }}>
                  <Select
                    fullWidth
                    size="medium"
                    soak="light"
                    variant="filled"
                    value={tenant}
                    loading={isFetching}
                    onChange={(event) => {
                      setTenant(event.target.value);
                      toggleFormChangedStatus(true);
                    }}
                  >
                    {(tenants || []).map((option) => (
                      <MenuItem value={option.id} key={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/** NATIONALITY */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 80 }}
                  >
                    NATIONALITY
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 2 }} loading={isFetching}>
                  <CountrySelect
                    size="medium"
                    fullWidth
                    variant="filled"
                    value={nationality}
                    placeholder="Select nationality.."
                    loading={isFetching}
                    onChange={(_e, newValue) => {
                      setNationality(newValue);
                      toggleFormChangedStatus(true);
                    }}
                  />
                </Grid>
                {/** EMAIL */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    EMAIL
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    mb: 2,
                    borderColor: !isValidEmail ? 'red' : 'green',
                    borderStyle: !isValidEmail ? 'solid' : 'none',
                  }}
                >
                  <TextField
                    fullWidth
                    size="medium"
                    variant="filled"
                    value={email}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    onChange={(event) => {
                      validateEmail(event.target.value);
                    }}
                  />
                </Grid>
                {/** IDENTITY NUMBER */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 80 }}
                  >
                    IDENTITY NUMBER
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    size="medium"
                    variant="filled"
                    value={identityNumber}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    onChange={(event) => {
                      setIdentityNumber(event.target.value);
                      toggleFormChangedStatus(true);
                    }}
                  />
                </Grid>
                {/** IDENTITY */}
                <Grid
                  item
                  container
                  flexWrap="nowrap"
                  alignItems="flex-start"
                  sx={{
                    mb: 1,
                  }}
                >
                  <Grid
                    item
                    container
                    flexDirection="column"
                    sx={{ width: '100%' }}
                  >
                    <Grid item sx={{ mb: 1 }}>
                      <Typography
                        variant="p4"
                        loading={isFetching && { width: 80 }}
                      >
                        IDENTITY FRONT
                      </Typography>
                    </Grid>
                    <Grid item sx={{ mr: 1 }}>
                      <FileUpload
                        userId={id}
                        files={identityFront}
                        onChange={(files, isActualChange, completeFiles) => {
                          setIdentityFront(files);
                          setCompleteIdentityFront(completeFiles);

                          if (isActualChange) {
                            toggleFormChangedStatus(true);
                          }
                        }}
                        loading={
                          isFetching && {
                            width: 100,
                            count: 1,
                            dropzone: false,
                          }
                        }
                        config={{
                          deletable: true,
                          fileType: FILE_TYPES.IDENTITY_IMAGE,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    flexDirection="column"
                    sx={{ width: '100%' }}
                  >
                    <Grid item sx={{ mb: 1 }}>
                      <Typography
                        variant="p4"
                        loading={isFetching && { width: 100 }}
                      >
                        IDENTITY BACK
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FileUpload
                        userId={id}
                        files={identityBack}
                        onChange={(files, isActualChange, completeFiles) => {
                          setIdentityBack(files);
                          setCompleteIdentityBack(completeFiles);

                          if (isActualChange) {
                            toggleFormChangedStatus(true);
                          }
                        }}
                        loading={
                          isFetching && {
                            width: 100,
                            count: 1,
                            dropzone: false,
                          }
                        }
                        config={{
                          deletable: true,
                          fileType: FILE_TYPES.IDENTITY_IMAGE,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {isIdentityExtractionEnabled() &&
                  isAllowed('userView', 'identityExtraction') && (
                    <Grid item container sx={{ mb: 3 }}>
                      <Grid item>
                        <Button
                          variant="filled"
                          disabled={
                            isFetching ||
                            !(identityFront?.[0]?.id || identityBack?.[0]?.id)
                          }
                          spinning={isSavingExtractedIdentityData}
                          onClick={() => {
                            setIdentityExtractionDialogOpen(true);
                          }}
                        >
                          {isSavingExtractedIdentityData
                            ? 'Saving extracted data..'
                            : 'Extract identity information'}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                {/** EMERGENCY CONTACTS */}
                {emergencyContacts && (
                  <>
                    <Grid item sx={{ mb: 2 }}>
                      <Typography
                        variant="p4"
                        loading={isFetching && { width: 50 }}
                      >
                        EMERGENCY CONTACTS
                      </Typography>
                    </Grid>
                    <Grid item sx={{ mb: 2 }}>
                      <Typography
                        variant="l5"
                        loading={isFetching && { width: 200 }}
                      >
                        {emergencyContacts?.join(', ')}
                      </Typography>
                    </Grid>
                  </>
                )}

                {isShowEnrollSubscription() &&
                  isAllowed('userView', 'subscription') && (
                    <>
                      <Divider />
                      <Grid item sx={{ mt: 3, mb: 1 }}>
                        <Typography
                          variant="p4"
                          loading={isFetching && { width: 50 }}
                        >
                          SUBSCRIPTION
                        </Typography>
                      </Grid>
                      {!isEmpty(subscription) ? (
                        <Grid item>
                          {/** Subscription Plan Name */}
                          <Grid item sx={{ mb: 1 }}>
                            <Typography
                              variant="p4"
                              loading={
                                (isSubmittingSubscriptions || isFetching) && {
                                  width: 80,
                                }
                              }
                            >
                              PLAN
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mb: 2 }}>
                            <Select
                              fullWidth
                              size="medium"
                              soak="light"
                              variant="filled"
                              value={subscriptionPlan}
                              disabled={
                                !isAllowed('userView', 'editSubscription')
                              }
                              loading={isSubmittingSubscriptions || isFetching}
                              onChange={(event) => {
                                setSubscriptionPlan(event.target.value);
                                toggleSubscriptionFormStatus(true);
                              }}
                              renderValue={(value) =>
                                subscriptionPlans.find(
                                  (plan) => plan.id === value
                                )?.name || value
                              }
                            >
                              {subscriptionPlans.map((item) => (
                                <MenuItem value={item.id} key={item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          {/** Subscription Stage */}
                          <Grid item sx={{ mb: 1 }}>
                            <Typography
                              variant="p4"
                              loading={
                                (isSubmittingSubscriptions || isFetching) && {
                                  width: 80,
                                }
                              }
                            >
                              STAGE
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mb: 2 }}>
                            <Select
                              fullWidth
                              size="medium"
                              soak="light"
                              variant="filled"
                              value={subscriptionStage}
                              disabled={
                                !isAllowed('userView', 'editSubscription')
                              }
                              loading={isSubmittingSubscriptions || isFetching}
                              color={STAGES[subscriptionStage]?.color}
                              placeholder="Select subscription stage..."
                              onChange={(event) => {
                                setSubscriptionStage(event.target.value);
                                toggleSubscriptionFormStatus(true);
                              }}
                              renderValue={(value) =>
                                value ? STAGES[value].label : value
                              }
                            >
                              {keys(STAGES).map((item) => (
                                <MenuItem value={item} key={item}>
                                  {STAGES[item]?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          {/** Subscription Status */}
                          <Grid item sx={{ mb: 1 }}>
                            <Typography
                              variant="p4"
                              loading={
                                (isSubmittingSubscriptions || isFetching) && {
                                  width: 50,
                                }
                              }
                            >
                              STATUS
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mb: 2, ml: 1 }}>
                            <Typography
                              variant="l5"
                              loading={
                                (isSubmittingSubscriptions || isFetching) && {
                                  width: 200,
                                }
                              }
                            >
                              {subscriptionStatus}
                            </Typography>
                          </Grid>
                          {/** Renewed at */}
                          {subscriptionRenewedAt && (
                            <Grid>
                              <Grid item sx={{ mb: 1 }}>
                                <Typography
                                  variant="p4"
                                  loading={
                                    (isSubmittingSubscriptions ||
                                      isFetchingSubscriptions ||
                                      isFetching) && { width: 50 }
                                  }
                                >
                                  RENEWED AT
                                </Typography>
                              </Grid>
                              <Grid item sx={{ mb: 2, ml: 1 }}>
                                <Typography
                                  color={
                                    isExpiredSubscription ? 'error' : 'warning'
                                  }
                                  variant="l5"
                                  loading={
                                    (isSubmittingSubscriptions ||
                                      isFetchingSubscriptions ||
                                      isFetching) && {
                                      width: 200,
                                    }
                                  }
                                >
                                  {subscriptionRenewedAt}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item sx={{ mb: 3 }}>
                            <Grid item>
                              <Typography
                                variant="p4"
                                textTransform="uppercase"
                                loading={
                                  (isSubmittingSubscriptions ||
                                    isFetchingSubscriptions ||
                                    isFetching) && {
                                    width: 85,
                                  }
                                }
                                sx={{
                                  lineHeight: 1,
                                  color: (theme) => theme.palette.grey[600],
                                }}
                              >
                                Payment Link
                              </Typography>
                            </Grid>
                            <Grid sx={{ mt: 2 }}>
                              <Tooltip placement="top" title="Copy">
                                <Button
                                  size="small"
                                  variant="text"
                                  color="primary"
                                  startIcon={<ContentCopyIcon />}
                                  loading={
                                    (isFetchingSubscriptions ||
                                      isSubmittingSubscriptions ||
                                      isFetching) && { width: 120 }
                                  }
                                  onClick={() => {
                                    if (subscriptionPaymentLink) {
                                      copyToClipboard(subscriptionPaymentLink)
                                        .then(() => {
                                          Message.success(
                                            'Payment link is copied.'
                                          );
                                        })
                                        .catch(() => {
                                          Message.error(
                                            "Couldn't copy to clipboard. Please report the issue to the team"
                                          );
                                        });
                                    } else {
                                      // In case payment link was not yet generated
                                      Message.error(
                                        "Couldn't copy to clipboard. Please refresh the page, and then try again"
                                      );
                                    }
                                  }}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                          {/* Show cancel subscription button if subscription is paid */}
                          {isShowCancelSubscriptionBtn && (
                            <Grid item container sx={{ mt: 3, mb: 3 }}>
                              {/* Popup to confirm or cancel subscription cancelation action */}
                              <PopConfirm
                                primaryButtonTitle="No"
                                secondaryButtonTitle="Yes"
                                anchorEl={subscriptionCancelPopConfirmAnchor}
                                onOk={() => closeSubscriptionCancelPopConfirm()}
                                open={Boolean(
                                  subscriptionCancelPopConfirmAnchor
                                )}
                                onCancel={() => {
                                  cancelSubscription(id);
                                  resetSubscriptionFields();
                                  closeSubscriptionCancelPopConfirm();
                                }}
                                id={
                                  subscriptionCancelPopConfirmAnchor
                                    ? 'cancel-confirm'
                                    : undefined
                                }
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                Are you sure you want to cancel this
                                subscription?
                              </PopConfirm>
                              <Grid item>
                                <Button
                                  variant="filled"
                                  spinning={isSubmittingSubscriptions}
                                  disabled={
                                    isFetchingSubscriptions || isFetching
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    showSubscriptionCancelPopConfirm(e);
                                  }}
                                >
                                  Cancel subscription
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      ) : (
                        <Grid item sx={{ mt: 1, mb: 2 }}>
                          <Tooltip
                            placement="top"
                            title="Create new subscription plan for patient"
                          >
                            <Button
                              size="medium"
                              color="primary"
                              variant="text"
                              loading={isFetching && { width: 150 }}
                              startIcon={
                                <CardMembershipIcon color="secondary" />
                              }
                              onClick={() =>
                                setCreateSubscriptionDialogOpen(true)
                              }
                            >
                              Enroll Patient to Subscription
                            </Button>
                          </Tooltip>
                        </Grid>
                      )}
                    </>
                  )}
              </CardContent>
            </Card>
            {/* Insurance Card */}
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader title="Insurance Details" sx={{ pb: 0 }} />
              <CardContent
                sx={{
                  p: 2,
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                {/**   INSURANCE TYPE */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    INSURANCE TYPE
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    size="medium"
                    variant="filled"
                    value={insuranceType}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(event) => {
                      setInsuranceType(event.target.value);
                      toggleInsuranceFormStatus(true);
                    }}
                  />
                </Grid>

                {/** INSURANCE */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    INSURANCE PROVIDER
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <Select
                    fullWidth
                    size="medium"
                    soak="light"
                    variant="filled"
                    value={insuranceProvider}
                    loading={isFetching}
                    onChange={(event) => {
                      setInsuranceProvider(event.target.value);
                      toggleInsuranceFormStatus(true);
                    }}
                    renderValue={(value) => value?.englishName}
                  >
                    {(insuranceProvidersList || []).map((provider) => (
                      <MenuItem value={provider} key={provider.id}>
                        <img
                          src={provider?.logoUrl}
                          alt=" insurance logo"
                          height={40}
                          width={55}
                        />
                        <Typography
                          variant="l5"
                          loading={isFetching}
                          sx={{ paddingLeft: 3 }}
                        >
                          {provider?.englishName}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/** INSURANCE GROUP */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    INSURANCE GROUP
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    size="medium"
                    variant="filled"
                    value={insuranceGroup}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(event) => {
                      setInsuranceGroup(event.target.value);
                      toggleInsuranceFormStatus(true);
                    }}
                  />
                </Grid>
                {/**   INSURANCE PLAN */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    INSURANCE PLAN
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    size="medium"
                    variant="filled"
                    value={insurancePlan}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(event) => {
                      setInsurancePlan(event.target.value);
                      toggleInsuranceFormStatus(true);
                    }}
                  />
                </Grid>
                {/** INSURANCE ID */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    INSURANCE ID
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    size="medium"
                    variant="filled"
                    value={insuranceCardNumber}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    onChange={(event) => {
                      setInsuranceCardNumber(event.target.value);
                      toggleInsuranceFormStatus(true);
                    }}
                  />
                </Grid>

                {/**   INSURANCE VALID UNTIL */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    INSURANCE VALID UNTIL
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <DatePicker
                    inputFormat={dateFormat}
                    value={insuranceValidUntil}
                    renderInput={(props) => (
                      <TextField
                        fullWidth
                        size="medium"
                        variant="filled"
                        loading={isFetching}
                        {...props}
                      />
                    )}
                    onChange={(newValue) => {
                      setInsuranceValidUntil(newValue);
                      toggleInsuranceFormStatus(true);
                    }}
                  />
                </Grid>
                {/**    EMR (External reference id) */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 50 }}
                  >
                    EMR (External reference id)
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    size="medium"
                    type="number"
                    variant="filled"
                    value={externalReferenceId}
                    loading={isFetching}
                    placeholder="Enter here ..."
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={(event) => {
                      setExternalReferenceId(event.target.value);
                      toggleInsuranceFormStatus(true);
                    }}
                  />
                </Grid>

                {/** INSURANCE CARD */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography
                    variant="p4"
                    loading={isFetching && { width: 140 }}
                  >
                    INSURANCE CARD
                  </Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <FileUpload
                    userId={id}
                    files={insuranceCardFileName}
                    onChange={(files, isActualChange, completeFiles) => {
                      setInsuranceCardFileName(files);
                      setCompleteInsuranceCardFileName(completeFiles);

                      if (isActualChange) {
                        toggleInsuranceFormStatus(true);
                      }
                    }}
                    loading={
                      isFetching && {
                        width: 100,
                        count: 1,
                        dropzone: false,
                      }
                    }
                    config={{
                      deletable: true,
                      fileType: FILE_TYPES.IDENTITY_IMAGE,
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
          </RowLayout>
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <RowLayout spacing={3}>
            {/* Health Card */}
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader title="Health Info" sx={{ pb: 0 }} />
              <CardContent
                noPadding
                sx={{
                  p: 2,
                  height: '100%',
                }}
              >
                <PatientHealthInfoEdit ownerId={ownerId} user={user} />
              </CardContent>
            </Card>
            {/* Comments Card */}
            {isAllowed('userView', 'comments') && (
              <Comments
                heightFollower
                referenceId={id}
                referenceType={REFERENCE_TYPES.USER}
              />
            )}
          </RowLayout>
        </Grid>

        {/** Patient History Section */}
        <Grid item xs={12}>
          {isAllowed('patientHistory') && (
            <PatientHistorySection referenceId={id} userId={user?.id} />
          )}
          <SendNotification
            open={isNotificationDialogOpen}
            userId={id}
            referenceId={id}
            referenceType={REFERENCE_TYPES.USER}
            onClose={() => setNotificationDialogOpen(false)}
          />
        </Grid>
      </Grid>
      {/* Identity Extraction Dialog */}
      <IdentityExtractionDialog
        userId={id}
        identityBack={completeIdentityBack}
        identityFront={completeIdentityFront}
        open={isIdentityExtractionDialogOpen}
        onClose={onIdentityExtractionDialogClosed}
      />
      <SubscriptionPlanDialog
        userId={id}
        open={isCreateSubscriptionDialogOpen}
        onClosed={() => setCreateSubscriptionDialogOpen(false)}
      />
    </>
  );
};

export default UserView;
