import React, { useState, useEffect } from 'react';

import { TextField, Chip } from 'design-system';
import { getAppConfigEndpoint } from 'core/endpoint';
import ServerAutocomplete from 'core/serverAutocomplete';
import { prescriptionModelConstants } from 'model/prescription';

const { DIAGNOSIS_CODES_ENDPOINT } = prescriptionModelConstants;

const getTagLabel = (option, longLabel) =>
  longLabel ? `${option.cod} - ${option.name}` : option.cod;

const DiagnosisAutocomplete = ({
  error,
  loading,
  multiple,
  helperText,
  defaultValue,
  size = 'large',
  longLabel = false,
  variant = 'filled',
  onChange = () => {},
  ...rest
}) => {
  const [value, setValue] = useState(multiple ? [] : null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ServerAutocomplete
      multiple={multiple}
      fullWidth
      size={size}
      defaultValue={value}
      baseURL={getAppConfigEndpoint()}
      endpoint={DIAGNOSIS_CODES_ENDPOINT}
      getOptionLabel={(option) =>
        option ? `${option?.cod} - ${option?.name}` : ''
      }
      optionsEqual={(option1, option2) => option1.cod === option2.cod}
      getRequestParams={(searchQuery) => {
        const urlSearchParams = new URLSearchParams();

        urlSearchParams.append('term', searchQuery);

        return urlSearchParams;
      }}
      throttle={(fn, searchQuery) => {
        if (searchQuery.length >= 3) {
          fn();
        }
      }}
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size={size}
          variant={variant}
          loading={loading}
          placeholder="Ex: J30.."
          error={error}
          helperText={helperText}
          sx={{
            '& .MuiInputBase-root': {
              height: 'auto',
            },
          }}
        />
      )}
      renderTags={(val, getTagProps) => {
        return val.map((option, index) => {
          const tagProps = getTagProps({ index });

          return (
            <Chip
              size={size}
              color="primary"
              label={getTagLabel(option, longLabel)}
              onClick={tagProps?.onDelete}
              {...tagProps}
            />
          );
        });
      }}
      {...rest}
    />
  );
};

export default DiagnosisAutocomplete;
