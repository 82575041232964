import get from 'lodash/get';
import { createSelector } from 'reselect';

export const followUpReminderSelector = (state) =>
  get(state, 'domain.followUpReminder', {});

export const getFollowUpRemindersof = createSelector(
  [followUpReminderSelector, (_state, referenceType) => referenceType],
  (results, referenceType) => get(results, `view.${referenceType}`)
);
