import * as types from './actionTypes';

export const fetchPatientHistory = (id) => ({
  type: types.FETCH_PATIENT_HISTORY,
  id,
});

export const patientHistoryReceived = (patientHistory) => ({
  type: types.PATIENT_HISTORY_RECEIVED,
  patientHistory,
});
