import { all, put, takeLatest } from 'redux-saga/effects';

import {
  patientHistoryModelActions,
  patientHistoryModelActionTypes,
} from 'model/patientHistory';

import * as actions from './actions';
import * as types from './actionTypes';

export function* fetchPatientHistory({ id }) {
  yield put(actions.setFetchingPatientHistory(true));
  yield put(patientHistoryModelActions.fetchPatientHistory(id));
}

export function* patientHistoryReceived({ patientHistory }) {
  yield put(actions.patientHistoryReceived(patientHistory));

  yield put(actions.setFetchingPatientHistory(false));
}

export default function* userModelSaga() {
  yield all([
    takeLatest(types.FETCH_PATIENT_HISTORY, fetchPatientHistory),
    takeLatest(
      patientHistoryModelActionTypes.PATIENT_HISTORY_RECEIVED,
      patientHistoryReceived
    ),
  ]);
}
