import { connect } from 'react-redux';

import { notificationActions as actions } from 'domain/notification';

import SendNotification from './SendNotification';

const mapDispatchToProps = (dispatch) => ({
  sendNotification: (
    message,
    sendPushNotification,
    userId,
    referenceId,
    referenceType
  ) =>
    dispatch(
      actions.sendNotification(
        message,
        sendPushNotification,
        userId,
        referenceType,
        referenceId
      )
    ),
});

// Move it to components
export default connect(null, mapDispatchToProps)(SendNotification);
