/**
 * Takes an arbitrary string and shortens it according to these rules:
 * 1. If the string contains white spaces, takes the first letter of each word and returns it upper-case
 *    E.g: "Abu Dhabi".shorten() => "AD"
 * 2. If the string is one part (No white spaces) of only {limit} letters, returns it as is
 *    E.g: "All".shorten() => "All"
 *    E.g: "feel".shorten(4) => "feel"
 * 3. If the string is one part (No white spaces) of more than {limit} letters, returns the first {limit} letters with ellipsis
 *    E.g: "Computer".shorten() => "Com"
 *
 * @param {String} str
 * @param {Number} limit
 * @returns String
 */
String.prototype.shorten = function (limit = 3) {
  const parts = this.split(' ').filter(Boolean);

  if (parts.length === 1) {
    if (parts[0].length === limit) {
      return parts[0];
    }

    return `${parts[0].slice(0, limit)}..`;
  }

  return parts
    .map((item) => item[0])
    .join('')
    .toUpperCase();
};

/**
 *
 * @returns Boolean if the string is a URL
 */
String.prototype.isURL = function () {
  const result = this.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

  return Boolean(result?.[0]);
};
