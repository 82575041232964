import keys from 'lodash/keys';

export const getCoords = (elem) => {
  const box = elem.getBoundingClientRect();

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
    width: box.width,
    height: box.height,
  };
};

export const copyToClipboard = (text) => {
  if (!navigator?.clipboard?.writeText || !window.isSecureContext) {
    // If clipboard is not defined or context is not secure (Not https)
    const textArea = document.createElement('textarea');

    textArea.value = text;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    return new Promise((res, rej) => {
      // execCommand works on focused elements
      const executed = document.execCommand('copy');

      if (executed) {
        res();
      } else {
        rej();
      }

      textArea.remove();
    });
  }
  // navigator clipboard api method
  return navigator.clipboard.writeText(text);
};

/**
 * Retrieves the style object associated with an HTML element.
 *
 * @param {HTMLElement} element - The HTML element to retrieve the style object from.
 * @return {Object} The style object containing the CSS properties and values of the element.
 */
export const getStyleObject = (element) => {
  if (element && element.hasAttribute('style')) {
    const style = element.getAttribute('style');
    // Example: 'overflow-y: auto; overflow-x: scroll;'

    const styleObject = style
      .split(';') // => ['overflow-y: auto', 'overflow-x: scroll', '']
      .filter(Boolean) // => ['overflow-y: auto', 'overflow-x: scroll']
      .reduce((object, pairString) => {
        const pair = pairString.split(':').map((item) => item.trim());

        return {
          ...object,
          [pair[0]]: pair[1],
        };
      }, {}); // => { 'overflow-y': 'auto', 'overflow-x': 'scroll' }

    return styleObject;
  }

  return {};
};

/**
 * Adds the specified style properties to the given element.
 *
 * @param {HTMLElement} element - The element to apply the style to.
 * @param {Object} style - The style properties to add.
 */
export const addStyle = (element, style) => {
  keys(style).forEach((property) => {
    // eslint-disable-next-line no-param-reassign
    element.style[property] = style[property];
  });
};
