import React from 'react';

import Grid from '../Grid';
import Button from '../Button';
import Popover from '../Popover';

const PopConfirm = ({
  onOk,
  children,
  onCancel,
  primaryButtonTitle = 'Ok',
  secondaryButtonTitle = 'Cancel',
  primaryButtonProps = {},
  secondaryButtonProps = {},
  ...rest
}) => {
  return (
    <Popover {...rest}>
      <Grid container column sx={{ p: 2 }}>
        <Grid item>{children}</Grid>
        <Grid
          item
          container
          sx={{
            pt: 2,
          }}
        >
          <Grid
            item
            sx={{
              mr: 1,
            }}
          >
            <Button
              size="small"
              variant="filled"
              onClick={onOk}
              {...primaryButtonProps}
            >
              {primaryButtonTitle}
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              color="error"
              onClick={onCancel}
              {...secondaryButtonProps}
            >
              {secondaryButtonTitle}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default PopConfirm;
