import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import snakeCase from 'lodash/snakeCase';
import formatISO from 'date-fns/formatISO';
import capitalize from 'lodash/capitalize';
import React, { useState, useEffect } from 'react';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';

import { userGetters } from 'domain/user';
import { isAllowed } from 'core/permission';
import { dateTimeFormat } from 'constants/dateTime';
import { DialerLink } from 'presentation/components';
import { Chip, Link, dsUtils, Tooltip, IconButton } from 'design-system';
import {
  consultationGetters as getters,
  consultationConstants as constants,
} from 'domain/consultation';

import { checkIfMeetingWithin10MinRange } from '../utils';

const { STATUSES } = constants;

const BookingDate = ({ isDoctor, startTime, endTime }) => {
  const [isMeetingWithin10MinRange, setMeetingWithin10MinRange] =
    useState(false);

  const formattedStartTime = format(startTime, dateTimeFormat);

  useEffect(() => {
    let timer;

    if (isDoctor && endTime && startTime) {
      setMeetingWithin10MinRange(
        checkIfMeetingWithin10MinRange(startTime, endTime)
      );

      timer = setInterval(() => {
        setMeetingWithin10MinRange(
          checkIfMeetingWithin10MinRange(startTime, endTime)
        );
        // Every 1 minute
      }, 1000 * 60);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [endTime, startTime]);

  return (
    <>
      {formattedStartTime}
      {isMeetingWithin10MinRange && (
        <Tooltip autoHide placement="top" title="Upcoming meeting">
          <OnlinePredictionIcon
            color="error"
            fontSize="small"
            className="big-1-5x-and-fade"
            sx={{ pl: 0.5 }}
          />
        </Tooltip>
      )}
    </>
  );
};

export const getColumns = ({
  isDoctor,
  isMobile,
  isTablet,
  isClinicManager,
}) => [
  { field: 'id', headerName: 'ID', width: 80 },
  {
    field: 'patient',
    headerName: 'Patient',
    flex: (() => {
      if (dsUtils.isMobileDesktopView()) {
        return 2;
      }

      if (isMobile) {
        return 1;
      }

      if (isTablet) {
        return 1.5;
      }

      return 2;
    })(),
    renderCell: (params) => {
      const user = getters.getUser(params.row);

      return !isDoctor && user ? (
        <Link href={`/user/${userGetters.getId(user)}`}>
          {userGetters.getFullName(user) || "Visit patient's profile"}
        </Link>
      ) : (
        userGetters.getFullName(user) || <i>N/A</i>
      );
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: (() => {
      if (dsUtils.isMobileDesktopView()) {
        return 2;
      }

      if (isMobile) {
        return 1;
      }

      if (isTablet) {
        return 1.5;
      }

      return 2;
    })(),
    sortable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    hide:
      (isMobile && !dsUtils.isMobileDesktopView()) ||
      !isAllowed('patientInfo', 'viewPhoneNumber'),
    renderCell: (params) => {
      const user = getters.getUser(params.row);
      const phoneNumber = userGetters.getPhoneNumber(user);
      const content = phoneNumber || '-';
      return user ? (
        <DialerLink phoneNumber={phoneNumber}>{content}</DialerLink>
      ) : (
        content
      );
    },
  },
  {
    field: 'bookingDate',
    headerName: 'Booking Date / Time',
    flex: 1.5,
    renderCell: (params) => {
      const startTime = parseISO(getters.getStartTime(params.row));
      const endTime = parseISO(getters.getEndTime(params.row));

      return (
        <BookingDate
          endTime={endTime}
          isDoctor={isDoctor}
          startTime={startTime}
        />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    hide: (isMobile || isTablet) && !dsUtils.isMobileDesktopView(),
    renderCell: (params) => {
      const status = getters.getStatus(params.row);

      return (
        <Tooltip placement="top" title="Status">
          <Chip
            sx={{ minWidth: { xs: 0, md: 100 } }}
            variant="filled"
            soak="light"
            label={STATUSES[status]?.label}
            color={STATUSES[status]?.color}
            size={
              isMobile && !dsUtils.isMobileDesktopView() ? 'small' : 'medium'
            }
          />
        </Tooltip>
      );
    },
  },
  {
    field: 'doctor',
    headerName: 'Doctor',
    flex: 1.5,
    disableColumnMenu: true,
    hide: isDoctor || (isMobile && !dsUtils.isMobileDesktopView()),
    renderCell: (params) => {
      const doctor = getters.getDoctor(params.row);
      const content = userGetters.getFullName(doctor) || 'Unassigned';

      return doctor ? (
        <Link href={`/user/${userGetters.getId(doctor)}`}>{content}</Link>
      ) : (
        content
      );
    },
  },
  {
    field: 'channel',
    headerName: 'Channel',
    width: 120,
    hide: isDoctor || isClinicManager || isMobile || isTablet,
    renderCell: (params) => {
      const channel = getters.getChannel(params.row);
      const readableChannel = snakeCase(channel)
        .split('_')
        .map((item) => capitalize(item))
        .join(' ');

      return (
        <Tooltip placement="top" title="Channel">
          <Chip
            label={readableChannel}
            variant="filled"
            color="primary"
            soak="light"
            sx={{ minWidth: 100 }}
          />
        </Tooltip>
      );
    },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 1,
    hide: isDoctor || isMobile || isTablet,
    valueGetter: (params) =>
      format(parseISO(getters.getCreatedAt(params.row)), dateTimeFormat),
  },
  {
    field: 'actions',
    headerName: '',
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    hide: isMobile || isTablet,
    renderCell: () => (
      <IconButton>
        <MessageOutlinedIcon fontSize="small" color="secondary" />
      </IconButton>
    ),
  },
];

export const getFilterFieldsConfig = (isAdmin, isDoctor) => [
  {
    name: 'status',
    serverName: 'status',
    defaultValue: isDoctor
      ? STATUSES.CONFIRMED.key
      : STATUSES.PENDING_APPROVAL.key,
  },
  {
    name: 'doctor',
    serverName: 'doctorId',
    valueGetter: userGetters.getId,
  },

  {
    name: 'insuranceProvider',
    serverName: 'consultationPatients.insuranceProviderId',
    joins: ['consultationPatients'],
    valueGetter: userGetters.getId,
  },
  {
    name: 'patient',
    serverName: 'userId',
    valueGetter: userGetters.getId,
  },
  {
    name: 'createdBy',
    serverName: 'createdBy',
    valueGetter: userGetters.getId,
    condition: isAdmin,
  },
  {
    name: 'bookingDateFrom',
    serverName: 'startTime',
    operation: 'gte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T00:00:00`
        : value;
    },
  },
  {
    name: 'bookingDateUntil',
    serverName: 'startTime',
    operation: 'lte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T23:59:59`
        : value;
    },
  },
  {
    name: 'isLabTestRequested',
    serverName: 'isLabTestRequested',
  },
  {
    name: 'meetingCommunicationMethod',
    serverName: 'meetingCommunicationMethod',
  },
];
