import get from 'lodash/get';
import { createSelector } from 'reselect';

export const medicineSelector = (state) => get(state, 'domain.medicine', {});

export const isSubmittingMedicine = createSelector(
  medicineSelector,
  (results) => get(results, 'isSubmitting')
);

export const isFetching = createSelector(medicineSelector, (results) =>
  get(results, 'isFetching')
);

export const getMedicine = createSelector(medicineSelector, (results) =>
  get(results, 'view')
);
