import * as types from './actionTypes';

export const fetchFiles = (fileIds, referenceType) => ({
  type: types.FETCH_FILES,
  referenceType,
  fileIds,
});

export const createFiles = (rawFiles, fileType, userId, patchId) => ({
  type: types.CREATE_FILES,
  rawFiles,
  fileType, // Server-side value, e.g: documents
  userId,
  patchId,
});

export const filesReceived = (files, referenceType) => ({
  type: `${types.FILES_RECEIVED}/${referenceType}`,
  referenceType,
  files,
});

export const filesCreated = (files) => ({
  type: types.FILES_CREATED,
  files,
});

export const filesCreationResponded = (ok, response, patchId) => ({
  type: types.FILES_CREATION_RESPONDED,
  response,
  patchId,
  ok,
});
