import set from 'date-fns/set';
import add from 'date-fns/add';
import isEmpty from 'lodash/isEmpty';
import addDays from 'date-fns/addDays';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import React, { useRef, useState, useEffect } from 'react';

import { isMidnight } from 'core/utils/date';
import DateTimeRangePicker from 'presentation/components/DateTimeRangePicker';
import { Form, Controller, formValidationFunctions } from 'design-system/form';
import { getDefaultCountryCode, getDefaultCurrency } from 'core/siteConfig';
import {
  Grid,
  Dialog,
  Button,
  FormField,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

const TODAY = new Date();
const TOMORROW = addDays(TODAY, 1);

const LogisticProviderDialog = ({
  isOpen,
  setOpen,
  address,
  orderId,
  fullName,
  phoneNumber,
  isFetchingTaskId,
  isShipmentSentToLogisticProvider,
  setShipmentSentToLogisticProvider,
  logisticProvider,
  setLogisticProvider,
  createLogisticProviderShipment,
  shippingDetails,
  shipment,
  orderTotalPrice,
  orderPatientShare,
  paymentTransaction,
}) => {
  const formRef = useRef();

  const [defaultDeliveryTime, setDefaultDeliveryTime] = useState();

  useEffect(() => {
    if (!isEmpty(shipment) || !isEmpty(shippingDetails)) {
      const { deliveryStartTime, deliveryEndTime } =
        shipment || shippingDetails;
      if (deliveryStartTime && deliveryEndTime) {
        const deliveryDate = new Date(deliveryStartTime);
        const startTime = set(new Date(deliveryStartTime), {
          seconds: 0,
          milliseconds: 0,
        });
        const endTime = set(new Date(deliveryEndTime), {
          seconds: 0,
          milliseconds: 0,
        });

        setDefaultDeliveryTime({
          date: deliveryDate,
          startTime,
          endTime,
        });

        return;
      }
    }

    setDefaultDeliveryTime({
      date: TOMORROW,
      startTime: TOMORROW.setUTCHours(9, 0, 0, 0),
      endTime: TOMORROW.setUTCHours(19, 59, 0, 0),
    });
  }, [shipment, shippingDetails]);

  const onClosed = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isFetchingTaskId) {
      setOpen(false);
    }
  }, [isFetchingTaskId]);

  const onSubmit = (shipmentDetails) => {
    const {
      deliveryTime: { date: deliveryDate, endTime, startTime },
    } = shipmentDetails;

    const date = getDate(deliveryDate);
    const year = getYear(deliveryDate);
    const month = getMonth(deliveryDate);
    const fullStartTime = set(startTime, { year, month, date });
    let fullEndTime = set(endTime, { year, month, date });
    if (isMidnight(fullEndTime)) {
      fullEndTime = add(fullEndTime, { days: 1 });
    }

    const payload = {
      deliveryStartTime: fullStartTime,
      deliveryEndTime: fullEndTime,
      recipient: shipmentDetails.recipient,
      phoneNumber: shipmentDetails.phoneNumber,
      addressDetails: {
        apartment: shipmentDetails.apartment,
        building: shipmentDetails.building,
        street: shipmentDetails.street,
        city: shipmentDetails.city,
        area: shipmentDetails.area,
        country: getDefaultCountryCode(),
      },
      shipmentId: orderId,
      totalShipmentQuantity: shipmentDetails.totalShipmentQuantity,
      logisticProviderId: logisticProvider.id,
      logisticProviderCode: logisticProvider.logisticProviderCode,
      totalPrice: orderTotalPrice,
      patientShare:
        paymentTransaction?.paidAmount?.value > 0
          ? { value: 0, currency: getDefaultCurrency() }
          : orderPatientShare,
    };
    createLogisticProviderShipment(payload, (isSuccessfullyCreated) => {
      if (isSuccessfullyCreated) {
        setShipmentSentToLogisticProvider(true);
        setLogisticProvider(logisticProvider);
      } else {
        setShipmentSentToLogisticProvider(false);
      }
    });
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      onClose={onClosed}
      aria-labelledby="logistic-provider-dialog"
    >
      <DialogTitle id="logistic-provider-dialog" onClose={onClosed}>
        Create shipment for logistic provider
      </DialogTitle>
      <DialogContent>
        <Form
          ref={formRef}
          onSubmit={(data) => {
            onSubmit(data);
          }}
        >
          {({ control }) => (
            <Grid container column>
              <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
                <Grid item sx={{ width: '48%' }}>
                  <FormField
                    label="Recipient"
                    required
                    field={
                      <Controller
                        name="recipient"
                        control={control}
                        defaultValue={
                          shipment?.recipient ||
                          shippingDetails?.recipient ||
                          fullName
                        }
                        rules={{
                          pattern: {
                            value: /^[\p{L}\s]+$/u,
                            message: 'Please enter a valid name (strings only)',
                          },
                          validate: (value) =>
                            Boolean(value?.trim()) ||
                            'Recipient/Patient name is required',
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            name="Recipient"
                            autoComplete="false"
                            fullWidth
                            value={value}
                            size="medium"
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item sx={{ width: '48%' }}>
                  <FormField
                    label="Phone number"
                    required
                    field={
                      <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue={
                          (isShipmentSentToLogisticProvider
                            ? shipment?.phoneNumber
                            : phoneNumber) ||
                          shippingDetails?.phoneNumber ||
                          phoneNumber
                        }
                        rules={{
                          required: 'Phone number is required',
                          pattern: {
                            value: /^(\+)?[0-9]+$/,
                            message: 'Please enter a valid phone number',
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            name="Phone Number"
                            value={value}
                            fullWidth
                            size="medium"
                            autoComplete="false"
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              </Grid>
              <Grid item sx={{ width: '100%' }}>
                {defaultDeliveryTime && (
                  <FormField
                    field={
                      <Controller
                        name="deliveryTime"
                        control={control}
                        defaultValue={defaultDeliveryTime}
                        rules={{
                          required: 'Delivery After time is required',
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <DateTimeRangePicker
                            disabledTimePicker
                            title="Delivery"
                            endTime={value.endTime}
                            startTime={value.startTime}
                            date={value.date}
                            minDate={TODAY}
                            textUpperCase={false}
                            onDateChange={(newValue) => {
                              onChange({
                                ...value,
                                date: newValue,
                              });
                            }}
                            config={{
                              date: {
                                required: true,
                              },
                              startTime: {
                                required: true,
                              },
                              endTime: {
                                required: true,
                              },
                            }}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
                <Grid item sx={{ width: '30%' }}>
                  <FormField
                    label="Apartment"
                    required
                    field={
                      <Controller
                        name="apartment"
                        control={control}
                        defaultValue={address?.apartment}
                        rules={{
                          required: 'Address/apartment name is required',
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            name="apartment"
                            autoComplete="false"
                            fullWidth
                            value={value}
                            size="medium"
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item sx={{ width: '25%' }}>
                  <FormField
                    label="Building"
                    field={
                      <Controller
                        name="building"
                        control={control}
                        defaultValue={address?.building}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            name="building"
                            autoComplete="false"
                            fullWidth
                            value={value}
                            size="medium"
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid item sx={{ width: '35%' }}>
                  <FormField
                    label="Street"
                    field={
                      <Controller
                        name="street"
                        control={control}
                        defaultValue={address?.street}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            name="street"
                            autoComplete="false"
                            fullWidth
                            value={value}
                            size="medium"
                            onChange={onChange}
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
                <Grid container justifyContent="space-between" sx={{ mb: 1 }}>
                  <Grid item sx={{ width: '38%' }}>
                    <FormField
                      label="City/Emirate"
                      required
                      field={
                        <Controller
                          name="city"
                          control={control}
                          defaultValue={address?.city}
                          rules={{
                            required: 'City/Emirate name is required',
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              name="city"
                              autoComplete="false"
                              fullWidth
                              value={value}
                              size="medium"
                              required
                              onChange={onChange}
                              error={Boolean(error)}
                              helperText={error?.message ?? null}
                            />
                          )}
                        />
                      }
                    />
                  </Grid>
                  <Grid item sx={{ width: '33%' }}>
                    <FormField
                      label="Distract/Area"
                      required
                      field={
                        <Controller
                          name="area"
                          control={control}
                          valueAsDate
                          defaultValue={address?.area}
                          rules={{
                            required: 'Area is required',
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              name="area"
                              value={value}
                              fullWidth
                              size="medium"
                              autoComplete="false"
                              onChange={onChange}
                              required
                              error={Boolean(error)}
                              helperText={error?.message ?? null}
                            />
                          )}
                        />
                      }
                    />
                  </Grid>
                  <Grid item sx={{ width: '23%' }}>
                    <FormField
                      label="Number of packages"
                      required
                      field={
                        <Controller
                          name="totalShipmentQuantity"
                          control={control}
                          valueAsNumber
                          defaultValue={
                            isShipmentSentToLogisticProvider
                              ? Number(
                                  shipment?.totalShipmentQuantity ||
                                    shippingDetails?.totalShipmentQuantity
                                )
                              : 1
                          }
                          rules={{
                            required: 'Number of packages is required',
                            validate: {
                              positive: (v) =>
                                formValidationFunctions.positive(
                                  v,
                                  'Number of packages should be above zero'
                                ),
                            },
                          }}
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => (
                            <TextField
                              name="Number of packages"
                              value={value}
                              fullWidth
                              type="number"
                              required
                              size="medium"
                              autoComplete="false"
                              onChange={onChange}
                              error={Boolean(error)}
                              helperText={error?.message ?? null}
                            />
                          )}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Grid item sx={{ width: '100%' }}>
          <Button
            fullWidth
            variant="filled"
            spinning={isFetchingTaskId}
            onClick={() => {
              formRef.current.submit();
            }}
            disabled={isShipmentSentToLogisticProvider}
          >
            Create shipment for logistic provider
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default LogisticProviderDialog;
