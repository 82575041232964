import { connect } from 'react-redux';

import { notificationActions as actions } from 'domain/notification';

import SendBulkNotifications from './SendBulkNotifications';

const mapDispatchToProps = (dispatch) => ({
  send: (payload, params, callback) =>
    dispatch(actions.sendBulkNotifications(payload, params, callback)),
});

export default connect(null, mapDispatchToProps)(SendBulkNotifications);
