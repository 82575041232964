import get from 'lodash/get';
import { all, put, call, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import { getHealthEndpoint } from 'core/endpoint';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchMembers({ ownerId, membersUserIds }) {
  try {
    const response = yield call(
      httpClient.get,
      constants.OWNER_MEMBER_ENDPOINT(ownerId),
      {
        params: {
          ...(membersUserIds
            ? {
                membersUserIds: membersUserIds.join(','),
              }
            : {}),
        },
      }
    );

    yield put(actions.membersFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.membersFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* fetchMembersHealthProfiles({ ownerId, membersUserIds }) {
  try {
    const response = yield call(
      httpClient.get,
      constants.OWNER_MEMBER_HEALTH_PROFILES_ENDPOINT(ownerId),
      {
        baseURL: getHealthEndpoint(),
        params: {
          ...(membersUserIds
            ? {
                membersUserIds: membersUserIds.join(','),
              }
            : {}),
        },
      }
    );

    yield put(actions.membersHealthProfilesFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.membersHealthProfilesFetchResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* fetchBasicMembers({ ownerId }) {
  try {
    const response = yield call(
      httpClient.get,
      constants.OWNER_MEMBER_BASIC_USER_INFO_ENDPOINT(ownerId)
    );

    yield put(actions.basicMembersFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.basicMembersFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* createMember({ ownerId, payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.OWNER_MEMBER_ENDPOINT(ownerId),
      payload
    );

    yield put(actions.memberCreateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.memberCreateResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* deleteMember({ memberId }) {
  try {
    const response = yield call(
      httpClient.delete,
      constants.MEMBER_BY_ID_ENDPOINT(memberId)
    );

    yield put(actions.memberDeleteResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.memberDeleteResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* updateMember({ memberId }) {
  try {
    const response = yield call(
      httpClient.patch,
      constants.MEMBER_BY_ID_ENDPOINT(memberId)
    );

    yield put(actions.memberUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.memberUpdateResponded(false, get(e, 'response.data.message'))
    );
  }
}

export default function* memberModelSaga() {
  yield all([
    takeLatest(types.FETCH_MEMBERS, fetchMembers),
    takeLatest(types.CREATE_MEMBER, createMember),
    takeLatest(types.DELETE_MEMBER, deleteMember),
    takeLatest(types.UPDATE_MEMBER, updateMember),
    takeLatest(types.FETCH_BASIC_MEMBERS, fetchBasicMembers),
    takeLatest(types.FETCH_MEMBERS_HEALTH_PROFILES, fetchMembersHealthProfiles),
  ]);
}
