import get from 'lodash/get';
import { createSelector } from 'reselect';

export const subscriptionSelectors = (state) =>
  get(state, 'domain.subscription', {});

export const getSubscription = createSelector(
  subscriptionSelectors,
  (results) => get(results, 'view')
);

export const isFetching = createSelector(subscriptionSelectors, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(subscriptionSelectors, (results) =>
  get(results, 'isSubmitting')
);

export const isSubscriptionCreated = createSelector(
  subscriptionSelectors,
  (results) => get(results, 'isSubscriptionCreated')
);

export const getSubscriptionPlans = createSelector(
  subscriptionSelectors,
  (results) => get(results, 'subscriptionPlans')
);

export const isFetchingSubscriptionPlans = createSelector(
  subscriptionSelectors,
  (results) => get(results, 'isFetchingSubscriptionPlans')
);
