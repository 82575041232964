/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import get from 'lodash/get';
import keys from 'lodash/keys';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import differenceInYears from 'date-fns/differenceInYears';
import { Form, Controller } from 'design-system/form';
import { userGetters } from 'domain/user';
import { doctorGetters } from 'domain/doctor';
import { DataGridCard } from 'core/dataGrid';
import { dateFormat } from 'constants/dateTime';
import ServerAutocomplete from 'core/serverAutocomplete';
import FilePreview from 'core/file/FileUpload/FilePreview';
import { FILE_TYPES } from 'domain/file';
import * as fileAPI from 'core/file/apis';
import * as fileUtils from 'core/file/utils';
import { CountrySelect, countryUtils } from 'core/country';
import { prescriptionConstants } from 'domain/prescription';
import { medicineUtils, medicineConstants } from 'domain/medicine';
import EPrescriptionContext from 'presentation/ePrescription/context/EPrescriptionContext';
import DiagnosisAutocomplete from 'presentation/components/DiagnosisAutocomplete';
import { consultationGetters } from 'domain/consultation';

import {
  Box,
  Grid,
  Card,
  Popup,
  Button,
  Select,
  Dialog,
  Tooltip,
  DataGrid,
  MenuItem,
  useTheme,
  IconButton,
  CardHeader,
  Typography,
  PopConfirm,
  CardContent,
  DialogTitle,
  PageSpinner,
  RequiredMark,
  Autocomplete,
  useMediaQuery,
  DialogContent,
  DialogActions,
  InputAdornment,
  EditableField,
  Message,
  TextField,
  DatePicker,
  Conditionally,
  If,
  Else,
  RadioGroup,
  FormControlLabel,
  Radio,
} from 'design-system';

const { FIELDS } = prescriptionConstants;
const {
  WHEN_HOW,
  ROUTE_OF_ADMIN,
  USE_UNIT_TYPES,
  DURATION_UNIT_TYPES,
  QUANTITY_UNIT_TYPES,
  FREQUENCY_UNIT_TYPES,
} = medicineConstants;

// TODO: Receive userId, and do the fetching here
const EPrescriptionDialog = ({
  user,
  open,
  doctor,
  document,
  onClose,
  isUpdate,
  clearFile,
  isSubmitting,
  associatedOrderId,
  consultation,
  createPrescription,
  createDocument,
  clinics = [],
}) => {
  const ref = useRef();
  const ePrescriptionContext = useContext(EPrescriptionContext);

  const theme = useTheme();
  const isXLarge = useMediaQuery(theme.breakpoints.up('xl'));
  const BASE_10 = 10;

  const [rows, setRows] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isFilePreviewOpen, setFilePreviewOpen] = useState(false);
  const [isSubmitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);
  const [createFormRow, setCreateFormRow] = useState({
    id: 'isCreateForm',
    isCreateForm: true,
  });
  const [isAppendPrescription, setAppendPrescription] = useState();

  // Form fields
  const [roa, setRoa] = useState(null);
  const [useValue, setUseValue] = useState();
  const [medicine, setMedicine] = useState();
  const [refillValue, setRefillValue] = useState();
  const [whenHow, setWhenHow] = useState(null);
  const [durationValue, setDurationValue] = useState();
  const [quantityValue, setQuantityValue] = useState();
  const [frequencyValue, setFrequencyValue] = useState();
  const [useUnit, setUseUnit] = useState(USE_UNIT_TYPES.TABLET.key);
  const [refillUnit, setRefillUnit] = useState(DURATION_UNIT_TYPES.DAY.key);
  const [durationUnit, setDurationUnit] = useState(DURATION_UNIT_TYPES.DAY.key);
  const [quantityUnit, setQuantityUnit] = useState(
    QUANTITY_UNIT_TYPES.PACKET.key
  );
  const [frequencyUnit, setFrequencyUnit] = useState(
    FREQUENCY_UNIT_TYPES.DAY.key
  );
  const [isCreatingFile, setCreatingFile] = useState(false);

  const isCreatingOrUpdating = useMemo(() => {
    return isSubmitting || isCreatingFile;
  }, [isCreatingFile, isSubmitting]);

  const onClosed = () => {
    setOpen(false);
    onClose();
  };

  const clearMedicineRxForm = () => {
    setRoa('');
    setWhenHow(null);
    setUseUnit(USE_UNIT_TYPES.TABLET.key);
    setUseValue('');
    setRefillUnit(DURATION_UNIT_TYPES.DAY.key);
    setRefillValue('');
    setDurationUnit(DURATION_UNIT_TYPES.DAY.key);
    setQuantityUnit(QUANTITY_UNIT_TYPES.PACKET.key);
    setDurationValue('');
    setQuantityValue('');
    setFrequencyUnit(FREQUENCY_UNIT_TYPES.DAY.key);
    setFrequencyValue('');
    setMedicine();

    setCreateFormRow({
      id: `createForm${Date.now()}`,
      isCreateForm: true,
    });
  };

  const addMedicineRx = () => {
    if (
      roa &&
      whenHow &&
      useUnit &&
      useValue &&
      medicine &&
      durationUnit &&
      quantityUnit &&
      durationValue &&
      quantityValue
    ) {
      setRows([
        ...rows,
        {
          id: Date.now(),
          roa,
          whenHow,
          useUnit,
          useValue,
          medicine,
          refillUnit,
          refillValue,
          durationUnit,
          quantityUnit,
          durationValue,
          quantityValue,
          frequencyUnit,
          frequencyValue,
        },
      ]);
      clearMedicineRxForm();
    }
  };

  const saveMedicineRxChanges = (row) => {
    setRows([
      ...rows.map((item) => {
        if (item.id === row.id) {
          return row;
        }
        return item;
      }),
    ]);
  };

  const deleteMedicineRx = (id) => {
    setRows([...rows.filter((item) => item.id !== id)]);
  };

  useEffect(() => {
    if (!isEmpty(document)) {
      setFilePreviewOpen(true);
    } else {
      setOpen(open);
    }
  }, [open, document]);

  const ActionsCell = ({ rowParams: params }) => {
    const { api, row } = params;
    const { id, isCreateForm } = row;

    const [popConfirmAnchor, setPopConfirmAnchor] = useState(null);

    const showPopConfirm = (event) => {
      setPopConfirmAnchor(event.currentTarget);
    };

    const closePopConfirm = () => {
      setPopConfirmAnchor(null);
    };

    if (isCreateForm) {
      return (
        <>
          <IconButton
            sx={{
              mr: {
                xs: 0.5,
                xl: 1,
              },
              p: {
                xs: 0.5,
                xl: 1,
              },
            }}
            // TODO: Disabled
            onClick={(e) => {
              e.stopPropagation();
              addMedicineRx();
            }}
          >
            <Tooltip placement="top" title="Add medicine prescription">
              <DoneIcon fontSize="small" color="success" />
            </Tooltip>
          </IconButton>
          <IconButton
            sx={{
              mr: {
                xs: 0.5,
                xl: 1,
              },
              p: {
                xs: 0.5,
                xl: 1,
              },
            }}
            // Disabled
            onClick={(e) => {
              e.stopPropagation();
              clearMedicineRxForm();
            }}
          >
            <Tooltip placement="top" title="Clear fields">
              <ClearIcon fontSize="small" color="error" />
            </Tooltip>
          </IconButton>
        </>
      );
    }
    if (api.getRowMode(id) === 'edit') {
      // TODO: Handle in renderEditCell
      return (
        <>
          <IconButton
            sx={{
              mr: {
                xs: 0.5,
                xl: 1,
              },
              p: {
                xs: 0.5,
                xl: 1,
              },
            }}
            onClick={(e) => {
              const draftRow = api.getRowWithUpdatedValues(id);

              if (
                draftRow.roa &&
                draftRow.whenHow &&
                draftRow.useValue &&
                draftRow.medicine &&
                draftRow.durationValue &&
                draftRow.quantityValue
              ) {
                e.stopPropagation();
                saveMedicineRxChanges(draftRow);
                api.stopRowEditMode({ id });
              }
            }}
          >
            <Tooltip placement="top" title="Save changes">
              <SaveIcon fontSize="small" color="success" />
            </Tooltip>
          </IconButton>
          <IconButton
            sx={{
              mr: {
                xs: 0.5,
                xl: 1,
              },
              p: {
                xs: 0.5,
                xl: 1,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              api.stopRowEditMode({
                id,
                ignoreModifications: true,
              });
            }}
          >
            <Tooltip placement="top" title="Discard changes">
              <ClearIcon fontSize="small" color="primary" />
            </Tooltip>
          </IconButton>
        </>
      );
    }

    return (
      <>
        <IconButton
          sx={{
            mr: {
              xs: 0.5,
              xl: 1,
            },
            p: {
              xs: 0.5,
              xl: 1,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            api.startRowEditMode({ id });
          }}
        >
          <Tooltip placement="top" title="Edit medicine prescription">
            <EditIcon fontSize="small" color="primary" />
          </Tooltip>
        </IconButton>
        <IconButton
          sx={{
            mr: {
              xs: 0.5,
              xl: 1,
            },
            p: {
              xs: 0.5,
              xl: 1,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            showPopConfirm(e);
          }}
        >
          <Tooltip placement="top" title="Delete medicine prescription">
            <DeleteIcon fontSize="small" color="error" />
          </Tooltip>
        </IconButton>
        <PopConfirm
          primaryButtonTitle="Cancel"
          secondaryButtonTitle="Delete"
          anchorEl={popConfirmAnchor}
          onOk={() => closePopConfirm()}
          open={Boolean(popConfirmAnchor)}
          onCancel={() => deleteMedicineRx(row.id)}
          id={popConfirmAnchor ? 'delete-confirm' : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          Are you sure you want to delete this medicine?
        </PopConfirm>
      </>
    );
  };

  const disableEdit = (params, e) => {
    e.defaultMuiPrevented = true;
  };

  const onPreviewClicked = (data) => {
    ePrescriptionContext.setFormData(data);
    const payload = {
      [FIELDS.CONSULTATION.name]: {
        [FIELDS.CONSULTATION.fields.ID.name]:
          consultationGetters.getId(consultation),
        [FIELDS.CONSULTATION.fields.START_TIME.name]:
          consultationGetters.getStartTime(consultation),
      },
      clinic: clinics[0],
      [FIELDS.DOCTOR.name]: {
        [FIELDS.DOCTOR.fields.DOCTOR_ID.name]: userGetters.getId(doctor),
        [FIELDS.DOCTOR.fields.FULL_NAME.name]: userGetters.getFullName(doctor),
        [FIELDS.DOCTOR.fields.LICENSE_NUMBER.name]:
          doctorGetters.getLicenseNumber(doctor),
        [FIELDS.DOCTOR.fields.SIGNATURE_FILE.name]:
          doctorGetters.getSignatureFile(doctor),
        [FIELDS.DOCTOR.fields.STAMP_FILE.name]:
          doctorGetters.getStampFile(doctor),
      },
      [FIELDS.DOCTOR_NOTES.name]: data.doctorNotes,
      [FIELDS.PATIENT.name]: {
        [FIELDS.PATIENT.fields.USER_ID.name]: userGetters.getId(user),
        [FIELDS.PATIENT.fields.FULL_NAME.name]: data.patientName,
        [FIELDS.PATIENT.fields.IDENTITY_NUMBER.name]:
          userGetters.getIdentityNumber(user),
        [FIELDS.PATIENT.fields.NATIONALITY.name]: data.patientNationality,
        [FIELDS.PATIENT.fields.DATE_OF_BIRTH.name]: data.dateOfBirth
          ? format(new Date(data.dateOfBirth), dateFormat)
          : '',
        [FIELDS.PATIENT.fields.AGE.name]: data.dateOfBirth
          ? differenceInYears(new Date(), new Date(data.dateOfBirth))
          : '',
        [FIELDS.PATIENT.fields.PHONE_NUMBER.name]:
          userGetters.getPhoneNumber(user),
        [FIELDS.PATIENT.fields.WEIGHT.name]: data.patientWeight
          ? parseInt(data.patientWeight, BASE_10)
          : '',
        [FIELDS.PATIENT.fields.HEIGHT.name]: data.patientWeight
          ? parseInt(data.patientHeight, BASE_10)
          : '',
      },
      [FIELDS.DIAGNOSIS.name]: {
        [FIELDS.DIAGNOSIS.fields.PRIMARY_DIAGNOSIS.name]: (
          data.primaryDiagnosis || []
        ).map((item) => `${item.cod}-${item.name}`),
        [FIELDS.DIAGNOSIS.fields.SECONDARY_DIAGNOSIS.name]: (
          data.secondaryDiagnosis || []
        ).map((item) => `${item.cod}-${item.name}`),
      },
      [FIELDS.MEDICINES.name]: rows.map((item) => ({
        [FIELDS.MEDICINES.fields.MEDICINE_ID.name]: item.medicine.id,
        [FIELDS.MEDICINES.fields.MEDICINE_SUMMARY.name]: item.medicine.summary,
        [FIELDS.MEDICINES.fields.ROUTE_OF_ADMIN.name]: item.roa,
        [FIELDS.MEDICINES.fields.USE.name]: {
          [FIELDS.MEDICINES.fields.USE.fields.VALUE.name]: item.useValue,
          [FIELDS.MEDICINES.fields.USE.fields.UNIT.name]: item.useUnit,
        },
        [FIELDS.MEDICINES.fields.FREQUENCY.name]: {
          [FIELDS.MEDICINES.fields.FREQUENCY.fields.VALUE.name]:
            item.frequencyValue,
          [FIELDS.MEDICINES.fields.FREQUENCY.fields.UNIT.name]:
            item.frequencyUnit,
        },
        [FIELDS.MEDICINES.fields.WHEN_HOW.name]: item.whenHow,
        [FIELDS.MEDICINES.fields.DURATION.name]: {
          [FIELDS.MEDICINES.fields.DURATION.fields.VALUE.name]:
            item.durationValue,
          [FIELDS.MEDICINES.fields.DURATION.fields.UNIT.name]:
            item.durationUnit,
          [FIELDS.MEDICINES.fields.DURATION.fields.DISPLAY_VALUE.name]:
            medicineUtils.toReadableDuration(
              item.durationValue,
              item.durationUnit
            ),
        },
        [FIELDS.MEDICINES.fields.QUANTITY.name]: {
          [FIELDS.MEDICINES.fields.QUANTITY.fields.VALUE.name]:
            item.quantityValue,
          [FIELDS.MEDICINES.fields.QUANTITY.fields.UNIT.name]:
            item.quantityUnit,
          [FIELDS.MEDICINES.fields.QUANTITY.fields.DISPLAY_VALUE.name]:
            medicineUtils.toReadableQuantity(
              item.quantityValue,
              item.quantityUnit
            ),
        },
        [FIELDS.MEDICINES.fields.REFILL.name]: {
          [FIELDS.MEDICINES.fields.REFILL.fields.VALUE.name]: item.refillValue,
          [FIELDS.MEDICINES.fields.REFILL.fields.UNIT.name]: item.refillUnit,
          [FIELDS.MEDICINES.fields.REFILL.fields.DISPLAY_VALUE.name]:
            medicineUtils.toReadableDuration(item.refillValue, item.refillUnit),
        },
        [FIELDS.MEDICINES.fields.INSTRUCTIONS.name]:
          medicineUtils.buildInstructions({
            ...item,
            isLong: true,
          }),
      })),
    };
    createDocument(payload);
  };

  const handleFilePreviewClose = () => {
    clearFile();
    setFilePreviewOpen(false);
  };

  const onSubmit = async (callback = () => {}) => {
    if (!document?.url) {
      Message.error('Document not available. Please report it to the team.');
      return;
    }

    const blob = await fileUtils.objectUrlToBlob(document?.url);

    setCreatingFile(true);
    fileAPI
      .uploadBlob(blob, FILE_TYPES.DOCUMENTS, userGetters.getId(user))
      .then((file) => {
        const data = ePrescriptionContext?.formData;
        let payload = {
          [FIELDS.CONSULTATION_ID.name]:
            consultationGetters.getId(consultation),
          [FIELDS.IS_APPEND_PRESCRIPTION.name]: isAppendPrescription,
          [FIELDS.DOCUMENT_FILE_ID.name]: file.id,
          [FIELDS.DOCTOR_ID.name]: userGetters.getId(doctor),
          [FIELDS.DOCTOR_NOTES.name]: data.doctorNotes,
          [FIELDS.PATIENT.name]: {
            [FIELDS.PATIENT.fields.USER_ID.name]: userGetters.getId(user),
            [FIELDS.PATIENT.fields.FULL_NAME.name]: data.patientName,
            [FIELDS.PATIENT.fields.IDENTITY_NUMBER.name]:
              userGetters.getIdentityNumber(user),
            [FIELDS.PATIENT.fields.NATIONALITY.name]: data.patientNationality,
            [FIELDS.PATIENT.fields.DATE_OF_BIRTH.name]: data.dateOfBirth
              ? new Date(data.dateOfBirth).toISOString()
              : null,
            [FIELDS.PATIENT.fields.AGE.name]: data.dateOfBirth
              ? differenceInYears(new Date(), new Date(data.dateOfBirth))
              : null,
            [FIELDS.PATIENT.fields.WEIGHT.name]: data.patientWeight
              ? parseInt(data.patientWeight, BASE_10)
              : '',
            [FIELDS.PATIENT.fields.HEIGHT.name]: data.patientHeight
              ? parseInt(data.patientHeight, BASE_10)
              : '',
          },
          [FIELDS.DIAGNOSIS.name]: {
            [FIELDS.DIAGNOSIS.fields.PRIMARY_DIAGNOSIS.name]: (
              data.primaryDiagnosis || []
            )
              .map((item) => `${item.cod} - ${item.name}`)
              .join(' / '),
            [FIELDS.DIAGNOSIS.fields.SECONDARY_DIAGNOSIS.name]: (
              data.secondaryDiagnosis || []
            )
              .map((item) => `${item.cod} - ${item.name}`)
              .join(' / '),
          },
          [FIELDS.MEDICINES.name]: rows.map((item) => ({
            [FIELDS.MEDICINES.fields.MEDICINE_ID.name]: item.medicine.id,
            [FIELDS.MEDICINES.fields.MEDICINE_SUMMARY.name]:
              item.medicine.summary,
            [FIELDS.MEDICINES.fields.ROUTE_OF_ADMIN.name]: item.roa,
            [FIELDS.MEDICINES.fields.USE.name]: {
              [FIELDS.MEDICINES.fields.USE.fields.VALUE.name]: item.useValue,
              [FIELDS.MEDICINES.fields.USE.fields.UNIT.name]: item.useUnit,
            },
            [FIELDS.MEDICINES.fields.FREQUENCY.name]: {
              [FIELDS.MEDICINES.fields.FREQUENCY.fields.VALUE.name]:
                item.frequencyValue,
              [FIELDS.MEDICINES.fields.FREQUENCY.fields.UNIT.name]:
                item.frequencyUnit,
            },
            [FIELDS.MEDICINES.fields.WHEN_HOW.name]: item.whenHow,
            [FIELDS.MEDICINES.fields.DURATION.name]: {
              [FIELDS.MEDICINES.fields.DURATION.fields.VALUE.name]:
                item.durationValue,
              [FIELDS.MEDICINES.fields.DURATION.fields.UNIT.name]:
                item.durationUnit,
              [FIELDS.MEDICINES.fields.DURATION.fields.DISPLAY_VALUE.name]:
                medicineUtils.toReadableDuration(
                  item.durationValue,
                  item.durationUnit
                ),
            },
            [FIELDS.MEDICINES.fields.QUANTITY.name]: {
              [FIELDS.MEDICINES.fields.QUANTITY.fields.VALUE.name]:
                item.quantityValue,
              [FIELDS.MEDICINES.fields.QUANTITY.fields.UNIT.name]:
                item.quantityUnit,
              [FIELDS.MEDICINES.fields.QUANTITY.fields.DISPLAY_VALUE.name]:
                medicineUtils.toReadableQuantity(
                  item.quantityValue,
                  item.quantityUnit
                ),
            },
            [FIELDS.MEDICINES.fields.REFILL.name]: {
              [FIELDS.MEDICINES.fields.REFILL.fields.VALUE.name]:
                item.refillValue,
              [FIELDS.MEDICINES.fields.REFILL.fields.UNIT.name]:
                item.refillUnit,
              [FIELDS.MEDICINES.fields.REFILL.fields.DISPLAY_VALUE.name]:
                medicineUtils.toReadableDuration(
                  item.refillValue,
                  item.refillUnit
                ),
            },
            [FIELDS.MEDICINES.fields.INSTRUCTIONS.name]:
              medicineUtils.buildInstructions({
                ...item,
                isLong: true,
              }),
          })),
        };

        if (associatedOrderId !== undefined && associatedOrderId !== null) {
          payload = {
            ...payload,
            [FIELDS.ORDER_ID.name]: associatedOrderId,
          };
        }
        callback(payload);
        handleFilePreviewClose();
        onClosed();
      })
      .finally(() => {
        setCreatingFile(false);
      });
  };
  const onCreate = () => {
    onSubmit((payload) => {
      createPrescription(payload);
    });
  };

  const onUpdate = () => {
    onSubmit((payload) => {
      createPrescription(payload);
      setAppendPrescription();
    });
  };

  const WrapText = ({ children }) => (
    <Box sx={{ whiteSpace: 'break-spaces' }}>{children}</Box>
  );

  const columns = [
    {
      field: 'medicine',
      renderHeader: () => (
        <>
          <Typography variant="l4">Medicine</Typography>
          <RequiredMark />
        </>
      ),
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: true,
      renderCell: ({ row }) => {
        const { isCreateForm } = row;

        if (isCreateForm) {
          // TODO: Separate this into MedicineSearch component
          return (
            <ServerAutocomplete
              size="medium"
              onChange={(value) => {
                setMedicine(value);
              }}
              // defaultValue={defaultValue}
              endpoint="/medicine"
              getOptionLabel={(option) => option.summary}
              responseGetter={(response) => get(response, 'data.data', [])}
              sx={{ width: '100%' }}
              getRequestParams={(searchQuery) => {
                const urlSearchParams = new URLSearchParams();

                urlSearchParams.append('limit', 35);
                urlSearchParams.append('offset', 0);
                urlSearchParams.append('sort', 'id,DESC');

                urlSearchParams.append(
                  'or',
                  `genericName||$startsL||${searchQuery}`
                );
                urlSearchParams.append(
                  'or',
                  `packageName||$startsL||${searchQuery}`
                );
                urlSearchParams.append(
                  'or',
                  `drugCode||$contL||${searchQuery}`
                );

                return urlSearchParams;
              }}
              throttle={(fn, searchQuery) => {
                if (searchQuery.length >= 3) {
                  fn();
                }
              }}
              renderInput={(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  size="medium"
                  // shape="round"
                  // variant="filled"
                  // defaultValue={getOptionLabel(defaultValue)}
                  placeholder="Medicine"
                  sx={{
                    '& .MuiInputBase-root': {
                      pl: {
                        xs: 1,
                        xl: 2,
                      },
                    },
                  }}
                />
              )}
            />
          );
        }
        return <WrapText>{row.medicine?.summary}</WrapText>;
      },
      renderEditCell: ({ id, field, row, api }) => {
        return (
          // TODO: Use MedicineAutocomplete
          <ServerAutocomplete
            size="medium"
            onChange={(value) => {
              api.setEditCellValue({ id, field, value });
            }}
            defaultValue={row.medicine}
            endpoint="/medicine"
            getOptionLabel={(option) => option.summary}
            responseGetter={(response) => get(response, 'data.data', [])}
            sx={{ width: '100%' }}
            getRequestParams={(searchQuery) => {
              const urlSearchParams = new URLSearchParams();

              urlSearchParams.append('limit', 35);
              urlSearchParams.append('offset', 0);
              urlSearchParams.append('sort', 'id,DESC');

              urlSearchParams.append(
                'or',
                `genericName||$startsL||${searchQuery}`
              );
              urlSearchParams.append(
                'or',
                `packageName||$startsL||${searchQuery}`
              );
              urlSearchParams.append('or', `drugCode||$contL||${searchQuery}`);

              return urlSearchParams;
            }}
            throttle={(fn, searchQuery) => {
              if (searchQuery.length >= 3) {
                fn();
              }
            }}
            renderInput={(inputProps) => (
              <TextField
                {...inputProps}
                fullWidth
                size="medium"
                // shape="round"
                // variant="filled"
                defaultValue={row.medicine.summary}
                placeholder="Medicine"
                sx={{
                  '& .MuiInputBase-root': {
                    pl: {
                      xs: 1,
                      xl: 2,
                    },
                  },
                }}
              />
            )}
          />
        );
      },
    },
    {
      field: 'roa',
      renderHeader: () => (
        <>
          <Typography variant="l4">Route of Admin</Typography>
          <RequiredMark />
        </>
      ),
      flex: 1,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      editable: true,
      renderCell: ({ row }) => {
        const { isCreateForm } = row;

        if (isCreateForm) {
          return (
            <Autocomplete
              fullWidth
              value={roa}
              size="medium"
              options={ROUTE_OF_ADMIN}
              onChange={(_e, newValue) => {
                setRoa(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="medium"
                  placeholder="Select.."
                  sx={{
                    '& .MuiInputBase-root': {
                      pl: {
                        xs: '8px !important',
                        xl: '16px !important',
                      },
                    },
                  }}
                />
              )}
            />
          );
        }
        return row.roa;
      },
      renderEditCell: ({ id, field, row, api }) => {
        return (
          <Autocomplete
            fullWidth
            value={row.roa}
            size="medium"
            options={ROUTE_OF_ADMIN}
            onChange={(_e, newValue) => {
              api.setEditCellValue({ id, field, value: newValue });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="medium"
                placeholder="Select.."
                sx={{
                  '& .MuiInputBase-root': {
                    pl: {
                      xs: 1,
                      xl: 2,
                    },
                  },
                }}
              />
            )}
          />
        );
      },
    },
    {
      field: 'duration',
      renderHeader: () => (
        <>
          <Typography variant="l4">Duration</Typography>
          <RequiredMark />
        </>
      ),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: true,
      renderCell: ({ row }) => {
        const { isCreateForm } = row;

        if (isCreateForm) {
          return (
            <TextField
              fullWidth
              value={durationValue}
              type="number"
              size="medium"
              min="1"
              placeholder="Until.."
              onChange={(e) => {
                e.stopPropagation();
                setDurationValue(e.target.value);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  pr: 0,
                  pl: {
                    xs: 1,
                    xl: 2,
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      m: 0,
                      '& .MuiInputBase-root': {
                        pl: 0,
                        width: {
                          xs: (theme) => theme.spacing(7),
                          xl: (theme) => theme.spacing(8),
                        },
                        backgroundColor: (theme) => theme.palette.grey[300],
                      },
                      '& .MuiSelect-select': {
                        pl: {
                          xs: 0.5,
                          xl: 1,
                        },
                        pr: {
                          xs: '16px !important',
                          xl: '20px !important',
                        },
                      },
                      '& .MuiSelect-icon': {
                        right: {
                          xs: '-3px',
                          xl: (theme) => theme.spacing(0.25),
                        },
                      },
                    }}
                  >
                    <Select
                      value={durationUnit}
                      size="medium"
                      color="standard"
                      variant="filled"
                      onChange={(e) => {
                        e.stopPropagation();
                        setDurationUnit(e.target.value);
                      }}
                      renderValue={(value) =>
                        value ? DURATION_UNIT_TYPES[value]?.label : value
                      }
                    >
                      {keys(DURATION_UNIT_TYPES).map((item) => (
                        <MenuItem value={item} key={item}>
                          {DURATION_UNIT_TYPES[item].label}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          );
        }
        return medicineUtils.toReadableDuration(
          row.durationValue,
          row.durationUnit
        );
      },
      renderEditCell: ({ id, row, api }) => {
        return (
          <TextField
            fullWidth
            value={row.durationValue}
            type="number"
            size="medium"
            min="1"
            placeholder="Until.."
            onChange={(e) => {
              e.stopPropagation();
              api.setEditCellValue({
                id,
                field: 'durationValue',
                value: e.target.value,
              });
            }}
            sx={{
              '& .MuiInputBase-root': {
                pr: 0,
                pl: {
                  xs: 1,
                  xl: 2,
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    m: 0,
                    '& .MuiInputBase-root': {
                      pl: 0,
                      width: {
                        xs: (theme) => theme.spacing(7),
                        xl: (theme) => theme.spacing(8),
                      },
                      backgroundColor: (theme) => theme.palette.grey[300],
                    },
                    '& .MuiSelect-select': {
                      pl: {
                        xs: 0.5,
                        xl: 1,
                      },
                      pr: {
                        xs: '16px !important',
                        xl: '20px !important',
                      },
                    },
                    '& .MuiSelect-icon': {
                      right: {
                        xs: '-3px',
                        xl: (theme) => theme.spacing(0.25),
                      },
                    },
                  }}
                >
                  <Select
                    value={row.durationUnit}
                    size="medium"
                    color="standard"
                    variant="filled"
                    onChange={(e) => {
                      e.stopPropagation();
                      api.setEditCellValue({
                        id,
                        field: 'durationUnit',
                        value: e.target.value,
                      });
                    }}
                    renderValue={(value) =>
                      value ? DURATION_UNIT_TYPES[value]?.label : value
                    }
                  >
                    {keys(DURATION_UNIT_TYPES).map((item) => (
                      <MenuItem value={item} key={item}>
                        {DURATION_UNIT_TYPES[item].label}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        );
      },
    },
    {
      field: 'quantity',
      renderHeader: () => (
        <>
          <Typography variant="l4">Total Quantity</Typography>
          <RequiredMark />
        </>
      ),
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: true,
      renderCell: ({ row }) => {
        const { isCreateForm } = row;

        if (isCreateForm) {
          return (
            <TextField
              fullWidth
              value={quantityValue}
              type="number"
              size="medium"
              min="1"
              placeholder="Total quantity"
              onChange={(e) => {
                e.stopPropagation();
                setQuantityValue(e.target.value);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  pr: 0,
                  pl: {
                    xs: 1,
                    xl: 2,
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      m: 0,
                      '& .MuiInputBase-root': {
                        pl: 0,
                        width: {
                          xs: (theme) => theme.spacing(7),
                          xl: (theme) => theme.spacing(8),
                        },
                        backgroundColor: (theme) => theme.palette.grey[300],
                      },
                      '& .MuiSelect-select': {
                        pl: {
                          xs: 0.5,
                          xl: 1,
                        },
                        pr: {
                          xs: '16px !important',
                          xl: '20px !important',
                        },
                      },
                      '& .MuiSelect-icon': {
                        right: {
                          xs: '-3px',
                          xl: (theme) => theme.spacing(0.25),
                        },
                      },
                    }}
                  >
                    <Select
                      value={quantityUnit}
                      size="medium"
                      color="standard"
                      variant="filled"
                      onChange={(e) => {
                        e.stopPropagation();
                        setQuantityUnit(e.target.value);
                      }}
                      renderValue={(value) =>
                        value ? QUANTITY_UNIT_TYPES[value]?.label : value
                      }
                    >
                      {keys(QUANTITY_UNIT_TYPES)
                        .filter((item) => !QUANTITY_UNIT_TYPES[item].hidden)
                        .map((item) => (
                          <MenuItem value={item} key={item}>
                            {QUANTITY_UNIT_TYPES[item].label}
                          </MenuItem>
                        ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          );
        }
        return medicineUtils.toReadableQuantity(
          row.quantityValue,
          row.quantityUnit
        );
      },
      renderEditCell: ({ id, row, api }) => {
        return (
          <TextField
            fullWidth
            value={row.quantityValue}
            type="number"
            size="medium"
            min="1"
            placeholder="Total quantity"
            onChange={(e) => {
              e.stopPropagation();
              api.setEditCellValue({
                id,
                field: 'quantityValue',
                value: e.target.value,
              });
            }}
            sx={{
              '& .MuiInputBase-root': {
                pr: 0,
                pl: {
                  xs: 1,
                  xl: 2,
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    m: 0,
                    '& .MuiInputBase-root': {
                      pl: 0,
                      width: {
                        xs: (theme) => theme.spacing(7),
                        xl: (theme) => theme.spacing(8),
                      },
                      backgroundColor: (theme) => theme.palette.grey[300],
                    },
                    '& .MuiSelect-select': {
                      pl: {
                        xs: 0.5,
                        xl: 1,
                      },
                      pr: {
                        xs: '16px !important',
                        xl: '20px !important',
                      },
                    },
                    '& .MuiSelect-icon': {
                      right: {
                        xs: '-3px',
                        xl: (theme) => theme.spacing(0.25),
                      },
                    },
                  }}
                >
                  <Select
                    value={row.quantityUnit}
                    size="medium"
                    color="standard"
                    variant="filled"
                    onChange={(e) => {
                      e.stopPropagation();
                      api.setEditCellValue({
                        id,
                        field: 'quantityUnit',
                        value: e.target.value,
                      });
                    }}
                    renderValue={(value) =>
                      value ? QUANTITY_UNIT_TYPES[value]?.label : value
                    }
                  >
                    {keys(QUANTITY_UNIT_TYPES).map((item) => (
                      <MenuItem value={item} key={item}>
                        {QUANTITY_UNIT_TYPES[item].label}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        );
      },
    },
    {
      field: 'refill',
      headerName: 'Refill',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: true,
      renderCell: ({ row }) => {
        const { isCreateForm } = row;

        if (isCreateForm) {
          return (
            <TextField
              fullWidth
              value={refillValue}
              type="number"
              size="medium"
              min="1"
              placeholder="Refill in.."
              onChange={(e) => {
                e.stopPropagation();
                setRefillValue(e.target.value);
              }}
              sx={{
                '& .MuiInputBase-root': {
                  pr: 0,
                  pl: {
                    xs: 1,
                    xl: 2,
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      m: 0,
                      '& .MuiInputBase-root': {
                        pl: 0,
                        width: {
                          xs: (theme) => theme.spacing(7),
                          xl: (theme) => theme.spacing(8),
                        },
                        backgroundColor: (theme) => theme.palette.grey[300],
                      },
                      '& .MuiSelect-select': {
                        pl: {
                          xs: 0.5,
                          xl: 1,
                        },
                        pr: {
                          xs: '16px !important',
                          xl: '20px !important',
                        },
                      },
                      '& .MuiSelect-icon': {
                        right: {
                          xs: '-3px',
                          xl: (theme) => theme.spacing(0.25),
                        },
                      },
                    }}
                  >
                    <Select
                      value={refillUnit}
                      size="medium"
                      color="standard"
                      variant="filled"
                      onChange={(e) => {
                        e.stopPropagation();
                        setRefillUnit(e.target.value);
                      }}
                      renderValue={(value) =>
                        value ? DURATION_UNIT_TYPES[value]?.label : value
                      }
                    >
                      {keys(DURATION_UNIT_TYPES).map((item) => (
                        <MenuItem value={item} key={item}>
                          {DURATION_UNIT_TYPES[item].label}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          );
        }
        return medicineUtils.toReadableDuration(
          row.refillValue,
          row.refillUnit
        );
      },
      renderEditCell: ({ id, row, api }) => {
        return (
          <TextField
            fullWidth
            value={row.refillValue}
            type="number"
            size="medium"
            min="1"
            placeholder="Refill in.."
            onChange={(e) => {
              e.stopPropagation();
              api.setEditCellValue({
                id,
                field: 'refillValue',
                value: e.target.value,
              });
            }}
            sx={{
              '& .MuiInputBase-root': {
                pr: 0,
                pl: {
                  xs: 1,
                  xl: 2,
                },
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    m: 0,
                    '& .MuiInputBase-root': {
                      pl: 0,
                      width: {
                        xs: (theme) => theme.spacing(7),
                        xl: (theme) => theme.spacing(8),
                      },
                      backgroundColor: (theme) => theme.palette.grey[300],
                    },
                    '& .MuiSelect-select': {
                      pl: {
                        xs: 0.5,
                        xl: 1,
                      },
                      pr: {
                        xs: '16px !important',
                        xl: '20px !important',
                      },
                    },
                    '& .MuiSelect-icon': {
                      right: {
                        xs: '-3px',
                        xl: (theme) => theme.spacing(0.25),
                      },
                    },
                  }}
                >
                  <Select
                    value={row.refillUnit}
                    size="medium"
                    color="standard"
                    variant="filled"
                    onChange={(e) => {
                      e.stopPropagation();
                      api.setEditCellValue({
                        id,
                        field: 'refillUnit',
                        value: e.target.value,
                      });
                    }}
                    renderValue={(value) =>
                      value ? DURATION_UNIT_TYPES[value]?.label : value
                    }
                  >
                    {keys(DURATION_UNIT_TYPES).map((item) => (
                      <MenuItem value={item} key={item}>
                        {DURATION_UNIT_TYPES[item].label}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            }}
          />
        );
      },
    },
    {
      field: 'instructions',
      renderHeader: () => (
        <Grid container>
          <Grid item sx={{ width: '33%' }}>
            <Typography variant="l4">Usage Unit</Typography>
            <RequiredMark />
          </Grid>
          <Grid item sx={{ width: '33%' }}>
            <Typography variant="l4">Frequency</Typography>
          </Grid>
          <Grid item sx={{ width: '33%' }}>
            <Typography variant="l4">Intake Instructions</Typography>
            <RequiredMark />
          </Grid>
        </Grid>
      ),
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: true,
      renderCell: (params) => {
        let draftRow;
        const { row, api } = params;
        const { id, isCreateForm } = row;

        try {
          draftRow = api.getRowWithUpdatedValues(id);
          // eslint-disable-next-line no-empty
        } catch (e) {}

        if (isCreateForm) {
          return (
            <Grid container column sx={{ mt: 2.5 }}>
              <Grid item container>
                <Grid
                  item
                  sx={{
                    width: '33%',
                    pr: {
                      xs: 1,
                      xl: 2,
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    value={useValue}
                    type="number"
                    size="medium"
                    min="1"
                    placeholder="Take.."
                    onChange={(e) => {
                      e.stopPropagation();
                      setUseValue(e.target.value);
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        pr: 0,
                        pl: {
                          xs: 1,
                          xl: 2,
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            m: 0,
                            '& .MuiInputBase-root': {
                              pl: 0,
                              width: {
                                xs: (theme) => theme.spacing(7),
                                xl: (theme) => theme.spacing(8),
                              },
                              backgroundColor: (theme) =>
                                theme.palette.grey[300],
                            },
                            '& .MuiSelect-select': {
                              pl: {
                                xs: 0.5,
                                xl: 1,
                              },
                              pr: {
                                xs: '16px !important',
                                xl: '20px !important',
                              },
                            },
                            '& .MuiSelect-icon': {
                              right: {
                                xs: '-3px',
                                xl: (theme) => theme.spacing(0.25),
                              },
                            },
                          }}
                        >
                          <Select
                            value={useUnit}
                            size="medium"
                            color="standard"
                            variant="filled"
                            onChange={(e) => {
                              e.stopPropagation();
                              setUseUnit(e.target.value);
                            }}
                            renderValue={(value) =>
                              value ? USE_UNIT_TYPES[value]?.label : value
                            }
                          >
                            {keys(USE_UNIT_TYPES)
                              .filter((item) => !USE_UNIT_TYPES[item].hidden)
                              .map((item) => (
                                <MenuItem value={item} key={item}>
                                  {USE_UNIT_TYPES[item].label}
                                </MenuItem>
                              ))}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    width: '33%',
                    pr: {
                      xs: 1,
                      xl: 2,
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    value={frequencyValue}
                    type="number"
                    size="medium"
                    min="1"
                    placeholder="Times"
                    onChange={(e) => {
                      e.stopPropagation();
                      setFrequencyValue(e.target.value);
                    }}
                    sx={{
                      '& .MuiInputBase-root': {
                        pr: 0,
                        pl: {
                          xs: 1,
                          xl: 2,
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            m: 0,
                            '& .MuiInputBase-root': {
                              pl: 0,
                              width: {
                                xs: (theme) => theme.spacing(7),
                                xl: (theme) => theme.spacing(8),
                              },
                              backgroundColor: (theme) =>
                                theme.palette.grey[300],
                            },
                            '& .MuiSelect-select': {
                              pl: {
                                xs: 0.5,
                                xl: 1,
                              },
                              pr: {
                                xs: '16px !important',
                                xl: '20px !important',
                              },
                            },
                            '& .MuiSelect-icon': {
                              right: {
                                xs: '-3px',
                                xl: (theme) => theme.spacing(0.25),
                              },
                            },
                          }}
                        >
                          <Select
                            value={frequencyUnit}
                            size="medium"
                            color="standard"
                            variant="filled"
                            onChange={(e) => {
                              e.stopPropagation();
                              setFrequencyUnit(e.target.value);
                            }}
                            renderValue={(value) =>
                              value ? FREQUENCY_UNIT_TYPES[value]?.label : value
                            }
                          >
                            {keys(FREQUENCY_UNIT_TYPES)
                              .filter(
                                (item) => !FREQUENCY_UNIT_TYPES[item].hidden
                              )
                              .map((item) => (
                                <MenuItem value={item} key={item}>
                                  {FREQUENCY_UNIT_TYPES[item].label}
                                </MenuItem>
                              ))}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sx={{ width: '33%' }}>
                  <Select
                    fullWidth
                    value={whenHow}
                    size="medium"
                    color="primary"
                    variant="outlined"
                    placeholder="Select.."
                    onChange={(e) => {
                      setWhenHow(e.target.value);
                    }}
                    renderValue={(value) =>
                      value ? WHEN_HOW[value]?.label : value
                    }
                    sx={{
                      // TODO: Handle later in theme
                      backgroundColor: (theme) => theme.palette.grey[200],
                      ...(!whenHow && {
                        color: (theme) => theme.palette.grey[500],
                      }),
                      '& .MuiSelect-select': {
                        pl: {
                          xs: '8px !important',
                          xl: '16px !important',
                        },
                      },
                    }}
                  >
                    {keys(WHEN_HOW)
                      .filter((item) => !WHEN_HOW[item].hidden)
                      .map((item) => (
                        <MenuItem value={item} key={item}>
                          {WHEN_HOW[item].label}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="p4">
                  {useValue && whenHow ? (
                    <Tooltip
                      placement="top"
                      title={medicineUtils.buildInstructions({
                        useValue,
                        useUnit,
                        frequencyValue,
                        frequencyUnit,
                        durationValue,
                        durationUnit,
                        whenHow,
                        isLong: true,
                      })}
                    >
                      <span>
                        {medicineUtils.buildInstructions({
                          useValue,
                          useUnit,
                          frequencyValue,
                          frequencyUnit,
                          whenHow,
                        })}
                      </span>
                    </Tooltip>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        return (
          <Tooltip
            placement="top"
            title={medicineUtils.buildInstructions({
              ...(draftRow || row),
              isLong: true,
            })}
          >
            <span>
              <b>Instructions: </b>
              {medicineUtils.buildInstructions({
                ...(draftRow || row),
              })}
            </span>
          </Tooltip>
        );
      },
      renderEditCell: (params) => {
        let draftRow;
        const { id, row, api } = params;

        try {
          draftRow = api.getRowWithUpdatedValues(id);
          // eslint-disable-next-line no-empty
        } catch (e) {}

        return (
          <Grid container column sx={{ mt: 2.5 }}>
            <Grid item container>
              <Grid
                item
                sx={{
                  width: '33%',
                  pr: {
                    xs: 1,
                    xl: 2,
                  },
                }}
              >
                <TextField
                  fullWidth
                  value={row.useValue}
                  type="number"
                  size="medium"
                  min="1"
                  placeholder="Take.."
                  onChange={(e) => {
                    api.setEditCellValue({
                      id,
                      field: 'useValue',
                      value: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      pr: 0,
                      pl: {
                        xs: 1,
                        xl: 2,
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          m: 0,
                          '& .MuiInputBase-root': {
                            pl: 0,
                            width: {
                              xs: (theme) => theme.spacing(7),
                              xl: (theme) => theme.spacing(8),
                            },
                            backgroundColor: (theme) => theme.palette.grey[300],
                          },
                          '& .MuiSelect-select': {
                            pl: {
                              xs: 0.5,
                              xl: 1,
                            },
                            pr: {
                              xs: '16px !important',
                              xl: '20px !important',
                            },
                          },
                          '& .MuiSelect-icon': {
                            right: {
                              xs: '-3px',
                              xl: (theme) => theme.spacing(0.25),
                            },
                          },
                        }}
                      >
                        <Select
                          value={row.useUnit}
                          size="medium"
                          color="standard"
                          variant="filled"
                          onChange={(e) => {
                            api.setEditCellValue({
                              id,
                              field: 'useUnit',
                              value: e.target.value,
                            });
                          }}
                          renderValue={(value) =>
                            value ? USE_UNIT_TYPES[value]?.label : value
                          }
                        >
                          {keys(USE_UNIT_TYPES)
                            .filter((item) => !USE_UNIT_TYPES[item].hidden)
                            .map((item) => (
                              <MenuItem value={item} key={item}>
                                {USE_UNIT_TYPES[item].label}
                              </MenuItem>
                            ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: '33%',
                  pr: {
                    xs: 1,
                    xl: 2,
                  },
                }}
              >
                <TextField
                  fullWidth
                  value={row.frequencyValue}
                  type="number"
                  size="medium"
                  min="1"
                  placeholder="Times"
                  onChange={(e) => {
                    e.stopPropagation();
                    api.setEditCellValue({
                      id,
                      field: 'frequencyValue',
                      value: e.target.value,
                    });
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      pr: 0,
                      pl: {
                        xs: 1,
                        xl: 2,
                      },
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          m: 0,
                          '& .MuiInputBase-root': {
                            pl: 0,
                            width: {
                              xs: (theme) => theme.spacing(7),
                              xl: (theme) => theme.spacing(8),
                            },
                            backgroundColor: (theme) => theme.palette.grey[300],
                          },
                          '& .MuiSelect-select': {
                            pl: {
                              xs: 0.5,
                              xl: 1,
                            },
                            pr: {
                              xs: '16px !important',
                              xl: '20px !important',
                            },
                          },
                          '& .MuiSelect-icon': {
                            right: {
                              xs: '-3px',
                              xl: (theme) => theme.spacing(0.25),
                            },
                          },
                        }}
                      >
                        <Select
                          value={row.frequencyUnit}
                          size="medium"
                          color="standard"
                          variant="filled"
                          onChange={(e) => {
                            e.stopPropagation();
                            api.setEditCellValue({
                              id,
                              field: 'frequencyUnit',
                              value: e.target.value,
                            });
                          }}
                          renderValue={(value) =>
                            value ? FREQUENCY_UNIT_TYPES[value]?.label : value
                          }
                        >
                          {keys(FREQUENCY_UNIT_TYPES).map((item) => (
                            <MenuItem value={item} key={item}>
                              {FREQUENCY_UNIT_TYPES[item].label}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sx={{ width: '33%' }}>
                <Select
                  fullWidth
                  value={row.whenHow}
                  size="medium"
                  color="primary"
                  variant="outlined"
                  placeholder="Select.."
                  onChange={(e) => {
                    api.setEditCellValue({
                      id,
                      field: 'whenHow',
                      value: e.target.value,
                    });
                  }}
                  sx={{
                    // TODO: Handle later in theme
                    backgroundColor: (theme) => theme.palette.grey[200],
                    ...(!row.whenHow && {
                      color: (theme) => theme.palette.grey[500],
                    }),
                    '& .MuiSelect-select': {
                      pl: {
                        xs: '8px !important',
                        xl: '16px !important',
                      },
                    },
                  }}
                >
                  {WHEN_HOW.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="p4">
                {row.useValue && row.whenHow ? (
                  <Tooltip
                    placement="top"
                    title={medicineUtils.buildInstructions({
                      ...(draftRow || row),
                      isLong: true,
                    })}
                  >
                    <span>
                      {medicineUtils.buildInstructions({
                        ...(draftRow || row),
                        whenHow,
                      })}
                    </span>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: isXLarge ? 112 : 72,
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return <ActionsCell rowParams={params} />;
        // TODO: In edit mode, add save and discard changes
      },
    },
    {
      field: 'useValue',
      hide: true,
      editable: true,
    },
    {
      field: 'useUnit',
      hide: true,
      editable: true,
    },
    {
      field: 'frequencyValue',
      hide: true,
      editable: true,
    },
    {
      field: 'frequencyUnit',
      hide: true,
      editable: true,
    },
    {
      field: 'durationValue',
      hide: true,
      editable: true,
    },
    {
      field: 'durationUnit',
      hide: true,
      editable: true,
    },
    {
      field: 'quantityValue',
      hide: true,
      editable: true,
    },
    {
      field: 'quantityUnit',
      hide: true,
      editable: true,
    },
    {
      field: 'refillValue',
      hide: true,
      editable: true,
    },
    {
      field: 'refillUnit',
      hide: true,
      editable: true,
    },
  ];

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen && !isSubmitting}
        onClose={onClosed}
        aria-labelledby="e-prescription-dialog-title"
      >
        <DialogTitle id="e-prescription-dialog-title" onClose={onClosed}>
          {isUpdate ? 'Update E-Prescription' : 'E-Prescription'}
        </DialogTitle>
        <DialogContent dividers>
          <Form
            ref={ref}
            onSubmit={(data) => {
              onPreviewClicked(data);
            }}
            onError={() => {
              Message.error('Please fill in the required fields');
            }}
          >
            {({ control }) => (
              <Grid container column spacing={2}>
                {/* Patient Profile */}
                <Grid item>
                  <Card>
                    <CardHeader title="Patient Profile" sx={{ pb: 0 }} />
                    <CardContent>
                      <Grid container column>
                        {/* Row 1 */}
                        <Grid
                          item
                          container
                          spacing={2}
                          justifyContent="space-between"
                          sx={{ mb: 1 }}
                        >
                          {/* Patient Name Field */}
                          <Grid item xs={4}>
                            <Controller
                              name="patientName"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData?.patientName ||
                                userGetters.getFullName(user)
                              }
                              rules={{
                                required: 'Patient name is required',
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <EditableField
                                  label="PATIENT NAME"
                                  required
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  error={Boolean(error)}
                                  render={({ value, onChange }) => (
                                    <TextField
                                      fullWidth
                                      autoFocus
                                      size="small"
                                      value={value}
                                      onChange={onChange}
                                      placeholder="Patient Name.."
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* Nationality Field */}
                          <Grid item xs={4}>
                            <Controller
                              name="patientNationality"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData
                                  ?.patientNationality ||
                                countryUtils.getCountryByCode(
                                  process.env.REACT_APP_COUNTRY_CODE
                                )?.label
                              }
                              rules={{
                                required: 'Patient nationality is required',
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <EditableField
                                  label="PATIENT NATIONALITY"
                                  required
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  error={Boolean(error)}
                                  render={({ value, onChange, onBlur }) => (
                                    <CountrySelect
                                      fullWidth
                                      onBlur={onBlur}
                                      size="medium"
                                      value={value?.label}
                                      variant="outlined"
                                      onChange={(_e, newValue) => {
                                        onChange(newValue?.label);
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* Phone No Field */}
                          <Grid item xs={4}>
                            <EditableField
                              readonly
                              label="PHONE NO"
                              defaultValue={userGetters.getPhoneNumber(user)}
                            />
                          </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid
                          item
                          container
                          spacing={2}
                          justifyContent="space-between"
                          sx={{ mb: 1 }}
                        >
                          {/* DOB Field */}
                          <Grid item xs={4}>
                            <Controller
                              name="dateOfBirth"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData?.dateOfBirth
                              }
                              render={({ field }) => (
                                <EditableField
                                  label="DATE OF BIRTH"
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  valueGetter={(val) =>
                                    val ? format(val, dateFormat) : val
                                  }
                                  render={({
                                    value,
                                    onBlur,
                                    onAccept,
                                    onChange,
                                  }) => (
                                    <DatePicker
                                      value={value}
                                      onChange={onChange}
                                      onAccept={onAccept}
                                      maxDate={new Date()}
                                      inputFormat={dateFormat}
                                      renderInput={(props) => (
                                        <TextField
                                          fullWidth
                                          size="small"
                                          {...props}
                                          onBlur={onBlur}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* Height Field */}
                          <Grid item xs={4}>
                            <Controller
                              name="patientHeight"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData?.patientHeight
                                  ? parseInt(
                                      ePrescriptionContext?.formData
                                        ?.patientHeight,
                                      BASE_10
                                    )
                                  : ''
                              }
                              render={({ field }) => (
                                <EditableField
                                  label="HEIGHT"
                                  onSave={field.onChange}
                                  defaultValue={
                                    field.value
                                      ? parseInt(field.value, BASE_10)
                                      : ''
                                  }
                                  render={({ value, onChange }) => (
                                    <TextField
                                      fullWidth
                                      autoFocus
                                      unit="CM"
                                      type="number"
                                      size="small"
                                      value={value}
                                      onChange={(event) => {
                                        const inputValue = event.target.value;
                                        onChange(parseInt(inputValue, BASE_10)); // Set the value as an integer
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* Weight Field */}
                          <Grid item xs={4}>
                            <Controller
                              name="patientWeight"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData?.patientWeight
                                  ? parseInt(
                                      ePrescriptionContext?.formData
                                        ?.patientWeight,
                                      BASE_10
                                    )
                                  : ''
                              }
                              render={({ field }) => (
                                <EditableField
                                  label="WEIGHT"
                                  onSave={field.onChange}
                                  defaultValue={
                                    field.value
                                      ? parseInt(field.value, BASE_10)
                                      : ''
                                  }
                                  render={({ value, onChange }) => (
                                    <TextField
                                      fullWidth
                                      autoFocus
                                      unit="KG"
                                      type="number"
                                      size="small"
                                      value={value}
                                      onChange={(event) => {
                                        const inputValue = event.target.value;
                                        onChange(parseInt(inputValue, BASE_10)); // Set the value as an integer
                                      }}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Diagnosis */}
                <Grid item>
                  <Card>
                    <CardHeader title="Diagnosis" sx={{ pb: 0 }} />
                    <CardContent>
                      <Grid container column>
                        {/* Row 1 */}
                        <Grid
                          item
                          container
                          spacing={3}
                          justifyContent="space-between"
                        >
                          {/* Primary diagnosis */}
                          <Grid item xs={6}>
                            <Controller
                              name="primaryDiagnosis"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData?.primaryDiagnosis
                              }
                              rules={{
                                required: 'Primary diagnosis is required',
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <EditableField
                                  label="PRIMARY DIAGNOSIS"
                                  required
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  error={Boolean(error)}
                                  valueGetter={(value) => {
                                    return (value || [])
                                      .map(
                                        (item) => `${item.cod} - ${item.name}`
                                      )
                                      .join(' / ');
                                  }}
                                  render={({ value, onChange }) => (
                                    <DiagnosisAutocomplete
                                      multiple
                                      size="medium"
                                      onChange={onChange}
                                      defaultValue={value}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* Secondary diagnosis */}
                          <Grid item xs={6}>
                            <Controller
                              name="secondaryDiagnosis"
                              control={control}
                              defaultValue={
                                ePrescriptionContext?.formData
                                  ?.secondaryDiagnosis
                              }
                              render={({ field }) => (
                                <EditableField
                                  label="SECONDARY DIAGNOSIS"
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  valueGetter={(value) => {
                                    return (value || [])
                                      .map(
                                        (item) => `${item.cod} - ${item.name}`
                                      )
                                      .join(' / ');
                                  }}
                                  render={({ value, onChange }) => (
                                    <DiagnosisAutocomplete
                                      multiple
                                      size="medium"
                                      defaultValue={value}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Medicine RX List */}
                <Grid
                  item
                  container
                  sx={{
                    '& .MuiCard-root': {
                      width: '100%',
                    },
                    '& .MuiDataGrid-root .MuiDataGrid-cell, & .MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing':
                      {
                        padding: {
                          xs: '0 4px !important',
                          xl: '0 8px !important',
                        },
                      },
                    '& .MuiDataGrid-root .MuiDataGrid-row.Mui-selected': {
                      backgroundColor: 'initial',
                    },
                  }}
                >
                  <DataGridCard
                    header={{
                      title: 'Medicine List',
                    }}
                  >
                    <DataGrid
                      rowHeight={80}
                      editMode="row"
                      columns={columns}
                      onRowEditStop={disableEdit}
                      onRowEditStart={disableEdit}
                      rows={[...rows, createFormRow]}
                      footer={{
                        isCountShown: false,
                      }}
                      isCellEditable={(params) =>
                        !(
                          params.row.isCreateForm ||
                          ['actions'].includes(params.field)
                        )
                      }
                      onCellKeyDown={(_params, events) => {
                        events.stopPropagation();
                      }}
                      sx={{
                        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                          width: '100%',
                        },
                        '& .MuiDataGrid-columnHeader': {
                          paddingLeft: {
                            xs: '4px !important',
                            xl: '8px !important',
                          },
                        },
                      }}
                    />
                  </DataGridCard>
                </Grid>
                {/* Doctor Notes */}
                <Grid item>
                  <Card>
                    <CardHeader title="Doctor Notes" sx={{ pb: 0 }} />
                    <CardContent>
                      <Controller
                        name="doctorNotes"
                        control={control}
                        defaultValue={
                          ePrescriptionContext?.formData?.doctorNotes
                        }
                        render={({ field }) => (
                          <TextField
                            rows={3}
                            fullWidth
                            multiline
                            variant="filled"
                            value={field.value}
                            placeholder="Add notes.."
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Form>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            variant="filled"
            onClick={() => {
              ref.current.submit();
            }}
            disabled={isCreatingOrUpdating}
          >
            Preview E-Prescription Document
          </Button>
        </DialogActions>
      </Dialog>
      <PageSpinner open={isSubmitting} />
      <FilePreview
        files={(() => {
          return document ? [document] : [];
        })()}
        open={isFilePreviewOpen}
        dialogActions={
          <>
            <Button
              variant="filled"
              disabled={isCreatingOrUpdating}
              onClick={() => {
                handleFilePreviewClose();
                setOpen(true);
              }}
            >
              Edit E Prescription
            </Button>
            <Conditionally>
              <If condition={isUpdate}>
                <Button
                  variant="filled"
                  spinning={isCreatingOrUpdating}
                  onClick={() => {
                    setUpdatePopupOpen(true);
                  }}
                >
                  Update E Prescription
                </Button>
              </If>
              <Else>
                <Button
                  variant="filled"
                  spinning={isCreatingOrUpdating}
                  onClick={() => {
                    setSubmitPopupOpen(true);
                  }}
                >
                  Create E Prescription
                </Button>
              </Else>
            </Conditionally>
          </>
        }
        dialogProps={{
          scrollable: true,
        }}
        onClose={() => {
          setFilePreviewOpen(false);
        }}
        showCloseConfirm
        closeConfirmProps={{
          title: 'Close E-Prescription',
          content:
            "You're about to lose E-Prescription changes and document. Are you sure you want to close and start over later?",
          secondaryButtonTitle: 'Yes, discard changes',
          onYes: () => {
            onClosed();
            handleFilePreviewClose();
          },
        }}
      />
      <Popup
        id={isSubmitPopupOpen ? 'e-prescription-submit-confirm' : undefined}
        open={isSubmitPopupOpen}
        primaryButtonTitle="Yes"
        onOk={() => {
          onCreate();
          setSubmitPopupOpen(false);
        }}
        secondaryButtonTitle="No"
        onCancel={() => {
          setSubmitPopupOpen(false);
        }}
        title="Create E Prescription"
      >
        Do you want to send this prescription?
      </Popup>
      <Popup
        id={isUpdatePopupOpen ? 'e-prescription-update-mode' : undefined}
        open={isUpdatePopupOpen}
        primaryButtonTitle="Update"
        onOk={() => {
          onUpdate();
          setUpdatePopupOpen(false);
        }}
        primaryButtonProps={{
          disabled: isAppendPrescription === undefined,
        }}
        secondaryButtonTitle="Cancel"
        onCancel={() => {
          setUpdatePopupOpen(false);
          setAppendPrescription();
        }}
        title="Update Lab Request"
      >
        <Grid>
          <Typography variant="l3">
            There is at least one prescription already sent for this
            consultation. Please choose one of the below options to reflect it
            on the existing prescription:
          </Typography>
          <RadioGroup
            row
            aria-labelledby="e-prescription-request-update-mode-radio-buttons-group-label"
            name="e-prescription-request-update-mode-radio-buttons-group"
            onChange={(e) => {
              if (e.target.value === 'append') {
                setAppendPrescription(true);
              } else if (e.target.value === 'replace') {
                setAppendPrescription(false);
              }
            }}
          >
            <FormControlLabel
              value="append"
              control={<Radio size="small" />}
              label="Append the new prescription in addition to the existing one"
            />
            <FormControlLabel
              value="replace"
              control={<Radio size="small" />}
              label="Replace the new prescription with all old prescriptions"
            />
          </RadioGroup>
        </Grid>
      </Popup>
    </>
  );
};

export default EPrescriptionDialog;
