import * as types from './actionTypes';

export const fetchFollowUpReminders = (referenceId, referenceType) => ({
  type: types.FETCH_FOLLOW_UP_REMINDERS,
  referenceId,
  referenceType,
});

export const createFollowUpReminders = (
  referenceId,
  referenceType,
  tenantKey,
  namespaceKey,
  params
) => ({
  type: types.CREATE_FOLLOW_UP_REMINDER,
  params,
  tenantKey,
  referenceId,
  namespaceKey,
  referenceType,
});

export const followUpRemindersReceived = (
  followUpReminders,
  referenceId,
  referenceType
) => ({
  type: types.FOLLOW_UP_REMINDER_RECEIVED,
  followUpReminders,
  referenceId,
  referenceType,
});
