import * as types from './actionTypes';

export const createMeeting = (payload) => ({
  type: types.CREATE_MEETING,
  payload,
});

export const meetingCreationResponded = (ok, response) => ({
  type: types.MEETING_CREATION_RESPONDED,
  ok,
  response,
});

export const updateMeeting = (id, payload) => ({
  type: types.UPDATE_MEETING,
  id,
  payload,
});

export const meetingUpdateResponded = (ok, response) => ({
  type: types.MEETING_UPDATE_RESPONDED,
  ok,
  response,
});

export const fetchDoctorMeetings = (doctorId) => ({
  type: types.FETCH_DOCTOR_MEETINGS,
  doctorId,
});

export const doctorMeetingsFetchResponded = (ok, response) => ({
  type: types.DOCTOR_MEETINGS_FETCH_RESPONDED,
  ok,
  response,
});

export const deleteMeeting = (id) => ({
  type: types.DELETE_MEETING,
  id,
});

export const meetingDeleteResponded = (ok, response) => ({
  type: types.MEETING_DELETE_RESPONDED,
  ok,
  response,
});
