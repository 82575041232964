import * as types from './actionTypes';

const initialState = {
  token: '',
  userId: '',
  mainRole: '',
  userRoles: [],
  errorMessage: '',
  defaultTenant: '',
  isLoggingIn: false,
  allowedTenants: [],
  selectedTenant: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGGING_IN: {
      return {
        ...state,
        isLoggingIn: action.isLoggingIn,
      };
    }
    case types.SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }
    case types.SET_USER_ID: {
      return {
        ...state,
        userId: action.userId,
      };
    }
    case types.SET_MAIN_ROLE: {
      return {
        ...state,
        mainRole: action.mainRole,
      };
    }
    case types.SET_USER_ROLES: {
      return {
        ...state,
        userRoles: action.userRoles,
      };
    }
    case types.SET_DEFAULT_TENANT: {
      return {
        ...state,
        defaultTenant: action.defaultTenant,
      };
    }
    case types.SET_ALLOWED_TENANTS: {
      return {
        ...state,
        allowedTenants: action.allowedTenants,
      };
    }
    case types.SET_SELECTED_TENANT: {
      return {
        ...state,
        selectedTenant: action.selectedTenant,
      };
    }
    case types.SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
