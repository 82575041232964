import get from 'lodash/get';

import { DOCTOR_SCHEDULE_FIELDS } from '../constants';

export const getId = (doctorSchedule) =>
  get(doctorSchedule, DOCTOR_SCHEDULE_FIELDS.ID.name);

export const getDoctorId = (doctorSchedule) =>
  get(doctorSchedule, DOCTOR_SCHEDULE_FIELDS.DOCTOR_ID.name);

export const getDoctor = (doctorSchedule) =>
  get(doctorSchedule, DOCTOR_SCHEDULE_FIELDS.DOCTOR.name);

export const getAvailabilityRange = (doctorSchedule) =>
  get(doctorSchedule, DOCTOR_SCHEDULE_FIELDS.AVAILABILITY_RANGES.name, []);
