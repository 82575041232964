/* Lab model constants */
export const PACKAGE_NAME = 'Lab';
export const ENDPOINT = 'test-request';
export const LAB_PACKAGES_ENDPOINT = 'packages';
export const CONSULTATION_LAB_LIST_ENDPOINT = (id) =>
  `${ENDPOINT}/consultation/${id}`;
export const TEST_REQUEST_LIST_ENDPOINT = '/test-request';
export const LAB_REQUEST_PDF_ENDPOINT = '/lab-request/pdf';
export const PATIENT_LAB_REQUEST_PDF_ENDPOINT = '/elab-requests';
export const PATIENT_ELAB_REQUEST_ENDPOINT = '/elab-test-requests';
export const PATIENT_ELAB_REQUEST_SUBMISSION_ENDPOINT = `${PATIENT_ELAB_REQUEST_ENDPOINT}/submission`;

export const PATIENTS_LAB_REQUEST_DOCUMENT_ENDPOINT = (id) =>
  `${TEST_REQUEST_LIST_ENDPOINT}/${id}/pdf-generation`;

// Config service endpoints
export const LAB_CPT_LIST_ENDPOINT = '/lab-cpt';
export const CUSTOM_LAB_TESTS_LIST_ENDPOINT = '/lab-tests';
