import * as types from './actionTypes';

export const createPrescription = (payload) => ({
  type: types.CREATE_PRESCRIPTION,
  payload,
});

export const createEPrescriptionDocument = (payload) => ({
  type: types.CREATE_E_PRESCRIPTION_DOCUMENT,
  payload,
});
export const createPatientPrescriptionDocument = (payload) => ({
  type: types.CREATE_PATIENT_PRESCRIPTION_DOCUMENT,
  payload,
});
export const submitAllPrescriptions = (payload, consultationId) => ({
  type: types.SUBMIT_ALL_PRESCRIPTIONS,
  payload,
  consultationId,
});
export const createPatientPrescription = (payload) => ({
  type: types.CREATE_PATIENT_PRESCRIPTION,
  payload,
});

export const updatePatientPrescription = (userId, consultationId, payload) => ({
  type: types.UPDATE_PATIENT_PRESCRIPTION,
  userId,
  consultationId,
  payload,
});

export const prescriptionCreationResponded = (ok, response) => ({
  type: types.PRESCRIPTION_CREATION_RESPONDED,
  response,
  ok,
});

export const ePrescriptionDocumentCreationResponded = (ok, response) => ({
  type: types.E_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED,
  response,
  ok,
});

export const patientPrescriptionDocumentCreationResponded = (ok, response) => ({
  type: types.PATIENT_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED,
  response,
  ok,
});

export const patientPrescriptionCreationResponded = (ok, response) => ({
  type: types.PATIENT_PRESCRIPTION_CREATION_RESPONDED,
  response,
  ok,
});

export const allPrescriptionsSubmissionResponded = (ok, response) => ({
  type: types.ALL_PRESCRIPTIONS_SUBMISSION_RESPONDED,
  response,
  ok,
});

export const patientPrescriptionUpdateResponded = (ok, response) => ({
  type: types.PATIENT_PRESCRIPTION_UPDATE_RESPONDED,
  response,
  ok,
});

export const fetchPatientPrescription = (userId, consultationId) => ({
  type: types.FETCH_PATIENT_PRESCRIPTION,
  userId,
  consultationId,
});

export const patientPrescriptionFetchResponded = (ok, response) => ({
  type: types.PATIENT_PRESCRIPTION_FETCH_RESPONDED,
  ok,
  response,
});
