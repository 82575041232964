import { calculateAge } from 'core/utils/date';
import format from 'date-fns/format';
import { GENDER } from 'domain/user/constants';
import { dateFormat } from 'constants/dateTime';

export const convertToStringList = (list) => {
  return list.map((item) => item?.name || item);
};

export const preparePatients = (owner, members, membersHealthProfiles) => {
  const patientsDetails = members.map((member) => {
    // Step 3: build patient details
    const patientHealthProfile = membersHealthProfiles?.find(
      (m) => m.userId === member.id
    );
    const patient = {
      userId: member.id,
      fullName: member.fullName,
      formattedAddress: owner.address?.[0].formattedAddress,
      phoneNumber: owner.phoneNumber,
      age: patientHealthProfile?.dob
        ? calculateAge(new Date(patientHealthProfile.dob)).ageDisplay
        : null,
      weight: patientHealthProfile?.weight,
      height: patientHealthProfile?.height,
      gender: patientHealthProfile?.gender
        ? GENDER[patientHealthProfile.gender]?.label ||
          patientHealthProfile.gender
        : null,
      insuranceProviderName: member.insuranceProvider?.englishName,
      dateOfBirth: patientHealthProfile?.dob
        ? format(new Date(patientHealthProfile.dob), dateFormat)
        : null,
      identityNumber: member.identityNumber,
      nationality: member.nationality,
    };
    return patient;
  });
  return patientsDetails;
};
export const preparePatientLabRequestDocuments = (
  owner,
  members,
  membersHealthProfiles,
  clinic,
  status
) => {
  const patients = preparePatients(owner, members, membersHealthProfiles);
  return {
    patients,
    clinic,
    status,
  };
};
