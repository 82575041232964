import React from 'react';
import { pdfjs } from 'react-pdf';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';

import store from 'store';
import { appActions } from 'domain/app';
import RootRoute from 'routing/RootRoute';
import {
  dsUtils,
  lightTheme,
  DateAdapter,
  ThemeProvider,
  MessageProvider,
  LocalizationProvider,
} from 'design-system';

import './App.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

store.dispatch(appActions.initalizeApp());

function App() {
  return (
    <>
      {!dsUtils.isMobileDesktopView() && (
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Helmet>
      )}
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <MessageProvider>
              <RootRoute />
            </MessageProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
