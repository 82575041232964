import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import appData from 'domain/app/HOCs/appData';
import { userModelActionTypes } from 'model/user';
import { userActions, userSelectors } from 'domain/user';

import CreateUserModal from './CreateUserModal';

const mapStateToProps = (state) => ({
  isSubmitting: userSelectors.isSubmittingUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(userActions.userReceived(user)),
  createUser: (payload) => dispatch(userActions.createUser(payload)),
  pursueUserCreationResponse: (callback) =>
    dispatch(
      appActions.pursueAction(
        userModelActionTypes.USER_CREATION_RESPONDED,
        callback
      )
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(CreateUserModal)
);
