import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* logIn({ payload }) {
  try {
    const response = yield call(httpClient.post, constants.ENDPOINT, payload, {
      isAutoErrorHandling: false,
      isAutoAuthErrorHandling: false,
    });

    yield put(actions.logInSucceeded(response.data));
  } catch (e) {
    yield put(actions.logInFailed(e?.response?.data));
  }
}

export default function* authModelSaga() {
  yield all([takeLatest(types.LOG_IN, logIn)]);
}
