import * as types from './actionTypes';

export const fetchUser = (id) => ({
  type: types.FETCH_USER,
  id,
});

export const clearUser = () => ({
  type: types.CLEAR_USER,
});

export const createUser = (payload) => ({
  type: types.CREATE_USER,
  payload,
});

export const fetchPatientHealthInfo = (userId) => ({
  type: types.FETCH_PATIENT_HEALTH_INFO,
  userId,
});

export const clearPatientHealthInfo = () => ({
  type: types.CLEAR_PATIENT_HEALTH_INFO,
});

export const updateUser = (id, payload, successCallback) => ({
  type: types.UPDATE_USER_PROFILE,
  id,
  payload,
  successCallback,
});

export const updateHealthProfile = (id, payload, successCallback) => ({
  type: types.UPDATE_USER_HEALTH_PROFILE,
  id,
  payload,
  successCallback,
});

export const startIdentityExtraction = (documents) => ({
  type: types.START_IDENTITY_EXTRACTION,
  documents,
});

export const cancelIdentityExtraction = () => ({
  type: types.CANCEL_IDENTITY_EXTRACTION,
});

export const saveExtractedIdentityData = (
  userId,
  identityData,
  successCallback = () => {}
) => ({
  type: types.SAVE_EXTRACTED_IDENTITY_DATA,
  identityData,
  userId,
  successCallback,
});

export const clearExtractedIdentityData = () => ({
  type: types.CLEAR_EXTRACTED_IDENTITY_DATA,
});

export const fetchUserAddresses = (id) => ({
  type: types.FETCH_USER_ADDRESSES,
  id,
});

export const userReceived = (user) => ({
  type: types.USER_RECEIVED,
  user,
});

export const patientHealthInfoReceived = (patient) => ({
  type: types.PATIENT_HEALTH_INFO_RECEIVED,
  patient,
});

export const userAddressesReceived = (addresses, id) => ({
  type: types.USER_ADDRESSES_RECEIVED,
  id,
  addresses,
});

export const setFetchingUser = (isFetching) => ({
  type: types.SET_FETCHING_USER,
  isFetching,
});

export const setExtractingIdentity = (isExtractingIdentity) => ({
  type: types.SET_EXTRACTING_IDENTITY,
  isExtractingIdentity,
});

export const setFetchingFrontIdentity = (isFetchingFrontId) => ({
  type: types.SET_FETCHING_FRONT_ID,
  isFetchingFrontId,
});

export const setFetchingBackIdentity = (isFetchingBackId) => ({
  type: types.SET_FETCHING_BACK_ID,
  isFetchingBackId,
});

export const setFetchingInsuranceCard = (isFetchingInsuranceCard) => ({
  type: types.SET_FETCHING_INSURANCE_CARD,
  isFetchingInsuranceCard,
});

export const setFetchingUserFiles = (isFetchingFiles) => ({
  type: types.SET_FETCHING_USER_FILES,
  isFetchingFiles,
});

export const setSubmittingUser = (isSubmitting) => ({
  type: types.SET_SUBMITTING_USER,
  isSubmitting,
});

export const setFetchingUserAddresses = (isFetchingUserAddresses) => ({
  type: types.SET_FETCHING_USER_ADDRESSES,
  isFetchingUserAddresses,
});

export const setSavingExtractedIdentityData = (
  isSavingExtractedIdentityData
) => ({
  type: types.SET_SAVING_EXTRACTED_IDENTITY_DATA,
  isSavingExtractedIdentityData,
});

export const fetchDoctors = () => ({
  type: types.FETCH_DOCTORS,
});

export const fetchDrivers = () => ({
  type: types.FETCH_DRIVERS,
});

export const fetchPharmacists = () => ({
  type: types.FETCH_PHARMACISTS,
});

export const doctorsReceived = (doctors) => ({
  type: types.DOCTORS_RECEIVED,
  doctors,
});

export const driversReceived = (drivers) => ({
  type: types.DRIVERS_RECEIVED,
  drivers,
});

export const pharmacistsReceived = (pharmacists) => ({
  type: types.PHARMACISTS_RECEIVED,
  pharmacists,
});

export const identityExtractionJobsReceived = (jobs) => ({
  type: types.IDENTITY_EXTRACTION_JOBS_RECEIVED,
  jobs,
});

export const identityExtractionSucceeded = (extractedData) => ({
  type: types.IDENTITY_EXTRACTION_SUCCEEDED,
  extractedData,
});

export const identityExtractionFailed = (extractionError) => ({
  type: types.IDENTITY_EXTRACTION_FAILED,
  extractionError,
});
