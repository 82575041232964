import { OUTCOME_OPTIONS } from 'domain/outcome/constants';

export const PATIENT_INFORMATION = {
  key: 'PATIENT_INFORMATION',
  formField: 'patientInfo',
};

export const SECTIONS = {
  DOCTOR_PRIVATE_NOTES: {
    key: 'DOCTOR_PRIVATE_NOTES',
    label: 'Doctor Private Notes',
    formField: 'privateNotes',
    order: 1,
  },
  COMPLAINT: {
    key: 'COMPLAINT',
    label: 'Complaint',
    formField: 'complaints',
    order: 2,
  },
  REVIEW_OF_SYSTEMS: {
    key: 'REVIEW_OF_SYSTEMS',
    label: 'Review of Systems',
    formField: 'patientExaminationsReview',
    order: 3,
  },
  PATIENT_AND_FAMILY_HISTORY: {
    key: 'PATIENT_AND_FAMILY_HISTORY',
    label: 'Patient and Family History',
    formField: 'patientHistoryInformation',
    order: 4,
  },
  DIAGNOSIS: {
    key: 'DIAGNOSIS',
    label: 'Diagnosis',
    formField: 'patientDiagnosis',
    order: 5,
  },
  CONSULTATION_OUTCOMES: {
    key: 'CONSULTATION_OUTCOMES',
    label: 'Consultation Outcomes',
    formField: 'consultationOutcomes',
    order: 6,
  },
  PRESCRIPTION: {
    key: 'PRESCRIPTION',
    label: 'Prescription',
    formField: 'prescription',
    mappedOutcome: OUTCOME_OPTIONS.Prescription.key,
    order: 7,
  },
  LAB_REQUEST: {
    key: 'LAB_REQUEST',
    label: 'Lab Request',
    formField: 'labRequest',
    mappedOutcome: OUTCOME_OPTIONS.LabTestRequest.key,
    order: 8,
  },
  MANAGEMENT_AND_TREATMENT_PLAN: {
    key: 'MANAGEMENT_AND_TREATMENT_PLAN',
    label: 'Management and Treatment Plan',
    formField: 'managementTreatmentPlan',
    order: 9,
  },
};
