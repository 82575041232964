import get from 'lodash/get';

import { FIELDS } from '../constants';

export const getId = (lab) => get(lab, FIELDS.ID.name);

export const getCreatedAt = (lab) => get(lab, FIELDS.CREATED_AT.name);

export const getUpdatedAt = (lab) => get(lab, FIELDS.UPDATED_AT.name);

export const getCreatedBy = (lab) => get(lab, FIELDS.CREATED_BY.name);

export const getUpdatedBy = (lab) => get(lab, FIELDS.UPDATED_BY.name);

export const getCreatedByUser = (lab) => get(lab, FIELDS.CREATED_BY_USER.name);

export const getUpdatedByUser = (lab) => get(lab, FIELDS.UPDATED_BY_USER.name);

export const getStatus = (lab) => get(lab, FIELDS.STATUS.name);

export const getUserId = (lab) => get(lab, FIELDS.USER_ID.name);

export const getDocuments = (lab) => get(lab, FIELDS.DOCUMENTS.name);

export const getPackageId = (lab) => get(lab, FIELDS.PACKAGE_ID.name);

export const getPackage = (lab) => get(lab, FIELDS.PACKAGE.name);

export const getSchedule = (lab) => get(lab, FIELDS.SCHEDULE.name);

export const getComments = (lab) => get(lab, FIELDS.COMMENTS.name);

export const getStartTime = (lab, isDirect) =>
  isDirect
    ? get(lab, FIELDS.START_TIME.name)
    : get(lab, FIELDS.SCHEDULE.fields.START_TIME.path);

export const getEndTime = (lab, isDirect) =>
  isDirect
    ? get(lab, FIELDS.END_TIME.name)
    : get(lab, FIELDS.SCHEDULE.fields.END_TIME.path);

export const getResults = (lab) => get(lab, FIELDS.RESULTS.name);

export const getConsultationId = (lab) => get(lab, FIELDS.CONSULTATION_ID.name);

export const getLabTestList = (lab) => get(lab, FIELDS.TEST_LIST.name) || [];

export const getTenant = (lab) => get(lab, FIELDS.TENANT_ID.name);

export const getDoctorId = (lab) => get(lab, FIELDS.DOCTOR_ID.name);

export const getChannel = (lab) => get(lab, FIELDS.CHANNEL.name);

export const getDoctor = (doctorId, doctors = []) =>
  doctors.filter((doctor) => doctor.id === doctorId)?.[0];

export const getNamespace = (lab) => get(lab, FIELDS.NAMESPACE_KEY.name);

export const hasPaymentTransaction = (lab) =>
  get(lab, FIELDS.HAS_PAYMENT_TRANSACTION.name);

export const userHasWlpSubscription = (lab) =>
  get(lab, FIELDS.USER_HAS_WLP_SUBSCRIPTION.name);

export const getPatients = (lab) => get(lab, FIELDS.PATIENTS.name);
