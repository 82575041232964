import * as types from './actionTypes';

export const fetchDoctor = (id) => ({
  type: types.FETCH_DOCTOR,
  id,
});

export const fetchDoctors = () => ({
  type: types.FETCH_DOCTORS,
});

export const updateDoctor = (id, payload) => ({
  type: types.UPDATE_DOCTOR,
  payload,
  id,
});

export const doctorUpdateResponded = (ok, response) => ({
  type: types.DOCTOR_UPDATE_RESPONDED,
  response,
  ok,
});

export const doctorFetchResponded = (ok, response) => ({
  type: types.DOCTOR_FETCH_RESPONDED,
  response,
  ok,
});

export const doctorsFetchResponded = (ok, response) => ({
  type: types.DOCTORS_FETCH_RESPONDED,
  response,
  ok,
});
