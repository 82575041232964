import { canDoctorViewPatientPhoneNumber } from 'core/siteConfig';

import ROLES from './roles';

const {
  ALL,
  ADMIN,
  DOCTOR,
  PROMOTOR,
  CLINIC_MANAGER,
  SCHEDULE_MANAGER,
  SUPER_ADMIN,
  LOGISTICS_ADMIN,
  PHARMACIST,
  PATIENT_SUCCESS,
  MARKETING_MANAGER,
} = ROLES;

const list = (...roles) => new Set([SUPER_ADMIN, ...roles]);
const except = (...roles) => new Set([...roles]);

const PERMISSIONS = {
  consultationView: {
    sections: {
      isPaidByUser: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      viewLabRequest: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      attendee: {
        components: {
          view: {
            roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST),
          },
          edit: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
        },
      },
      patients: {
        components: {
          view: {
            roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST, DOCTOR),
          },
          edit: {
            roles: list(ADMIN, PATIENT_SUCCESS, DOCTOR),
          },
          delete: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
        },
      },
      outcome: {
        components: {
          view: {
            roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST, DOCTOR),
          },
          edit: {
            roles: list(ADMIN, DOCTOR),
          },
        },
      },
      time: {
        components: {
          view: {
            roles: list(ADMIN, DOCTOR, PHARMACIST, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
        },
      },
      documents: {
        components: {
          view: {
            roles: list(ADMIN, DOCTOR, PHARMACIST, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
        },
      },
      payment: {
        components: {
          create: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          view: {
            roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST),
          },
          update: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          resend: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          cancel: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
        },
      },
      eligibilitySection: {
        components: {
          view: { roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST) },
          create: { roles: list(ADMIN, PATIENT_SUCCESS) },
        },
      },
      dietPlanSection: {
        components: {
          edit: { roles: list(DOCTOR, PATIENT_SUCCESS) },
        },
      },
      eprescribe: {
        roles: list(DOCTOR),
      },
      elab: {
        roles: list(DOCTOR),
      },
      addLabTest: {
        roles: list(DOCTOR, ADMIN),
      },
      editBranch: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      editStatus: {
        roles: list(DOCTOR, ADMIN, PATIENT_SUCCESS),
      },
      editDoctor: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      sendNotification: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      enableCall: {
        roles: list(DOCTOR, SUPER_ADMIN),
      },
    },
  },
  orderView: {
    roles: list(ALL),
    sections: {
      saveButton: {
        roles: list(ADMIN, PHARMACIST),
      },
      deliveryAddress: {
        components: {
          edit: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN),
          },
          add: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN),
          },
          share: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN, PATIENT_SUCCESS),
          },
          printDeliveryConsentForm: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN),
          },
        },
      },
      status: {
        components: {
          edit: {
            roles: list(ADMIN, LOGISTICS_ADMIN, PHARMACIST),
          },
        },
      },
      assignee: {
        components: {
          view: {
            roles: list(ADMIN, LOGISTICS_ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      reminder: {
        components: {
          view: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      branch: {
        components: {
          view: {
            roles: list(ADMIN, LOGISTICS_ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      institute: {
        components: {
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      diagnosis: {
        components: {
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      doctor: {
        components: {
          view: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      driver: {
        components: {
          view: {
            roles: list(ADMIN, LOGISTICS_ADMIN, PHARMACIST),
          },
        },
      },
      orderDetails: {
        roles: list(
          ADMIN,
          LOGISTICS_ADMIN,
          CLINIC_MANAGER,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
        components: {
          printOrderSticker: {
            roles: list(ADMIN, PHARMACIST),
          },
          printMedicineSticker: {
            roles: list(ADMIN, PHARMACIST),
          },
          pharmacyReviewButton: {
            roles: list(ADMIN, PHARMACIST),
          },
          refuseButton: {
            roles: list(ADMIN, PHARMACIST),
          },
          originalOrder: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },

          /**
           * cashOrderChip roles should have access to get consultation details
           * otherwise the user will be logged out and redirected to login page
           */
          cashOrderChip: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          copy: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          sourceChannel: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          updatedBy: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          originalConsultation: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          followUpReminder: {
            roles: list(ADMIN, PHARMACIST),
          },
          pinOrder: {
            roles: list(ADMIN, PHARMACIST),
          },
          addMedicine: {
            roles: list(ADMIN, PHARMACIST),
          },
          chronicCondition: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      uploadDocuments: {
        roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN),
        components: {
          edit: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN),
          },
          delete: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN),
          },
        },
      },
      prescriptions: {
        components: {
          view: {
            roles: list(ADMIN, PHARMACIST, CLINIC_MANAGER, PATIENT_SUCCESS),
          },
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
          delete: {
            roles: list(ADMIN, PHARMACIST),
          },
          showPrescriptions: {
            roles: list(ADMIN, PHARMACIST),
          },
          importLineItems: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      medicines: {
        roles: list(ADMIN, CLINIC_MANAGER, PHARMACIST, PATIENT_SUCCESS),
        components: {
          add: {
            roles: list(ADMIN, PHARMACIST),
          },
          delete: {
            roles: list(ADMIN, PHARMACIST),
          },
          edit: {
            roles: list(ADMIN, PHARMACIST),
          },
          printSticker: {
            roles: list(ADMIN, PHARMACIST),
          },
          viewMedicine: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          editPrice: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      payment: {
        components: {
          create: {
            roles: list(ADMIN, PHARMACIST),
          },
          view: {
            roles: list(ADMIN, PHARMACIST, LOGISTICS_ADMIN, PATIENT_SUCCESS),
          },
          update: {
            roles: list(ADMIN, PHARMACIST),
          },
          resend: {
            roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
          cancel: {
            roles: list(ADMIN, PHARMACIST),
          },
        },
      },
      totals: {
        roles: list(
          ADMIN,
          CLINIC_MANAGER,
          LOGISTICS_ADMIN,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      comments: {
        roles: list(ADMIN, LOGISTICS_ADMIN, PHARMACIST, PATIENT_SUCCESS),
        components: {
          add: {
            roles: list(ADMIN, LOGISTICS_ADMIN, PHARMACIST, PATIENT_SUCCESS),
          },
        },
      },
      orderHistory: {
        roles: list(ADMIN, PHARMACIST),
      },
      sendNotification: {
        roles: list(ADMIN, PHARMACIST),
      },
    },
  },
  routes: {
    sections: {
      consultations: {
        path: 'consultations',
        roles: list(ADMIN, DOCTOR, CLINIC_MANAGER, PHARMACIST, PATIENT_SUCCESS),
      },
      consultationView: {
        path: 'consultation/:id',
        roles: list(ADMIN, DOCTOR, PHARMACIST, PATIENT_SUCCESS),
      },
      consultationCreate: {
        path: 'consultation',
        roles: list(ADMIN, CLINIC_MANAGER, PATIENT_SUCCESS),
      },
      orders: {
        path: 'orders',
        roles: list(
          ADMIN,
          CLINIC_MANAGER,
          LOGISTICS_ADMIN,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      orderView: {
        path: 'order/:id',
        roles: list(
          ADMIN,
          CLINIC_MANAGER,
          LOGISTICS_ADMIN,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      orderCreate: {
        path: 'order',
        roles: list(ADMIN, CLINIC_MANAGER, PHARMACIST),
      },
      labRequests: {
        path: 'lab-requests',
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      labRequestView: {
        path: 'lab-request/:id',
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      labRequestCreate: {
        path: 'lab-request',
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      medicines: {
        path: 'medicines',
        roles: list(ADMIN, PHARMACIST),
      },
      medicineView: {
        path: 'medicine/:id',
        roles: list(ADMIN, PHARMACIST),
      },
      medicineCreate: {
        path: 'medicine',
        roles: list(ADMIN, PHARMACIST),
      },
      users: {
        path: 'users',
        roles: list(
          ADMIN,
          PROMOTOR,
          CLINIC_MANAGER,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      userView: {
        path: 'user/:id',
        roles: list(
          ADMIN,
          PROMOTOR,
          CLINIC_MANAGER,
          LOGISTICS_ADMIN,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      userCreate: {
        path: 'user',
        roles: list(
          ADMIN,
          PROMOTOR,
          CLINIC_MANAGER,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      leadUsers: {
        path: 'lead-users',
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      schedules: {
        path: 'schedules',
        roles: list(SCHEDULE_MANAGER, PATIENT_SUCCESS),
      },
      doctorManagement: {
        path: 'doctor-management',
        roles: list(SCHEDULE_MANAGER),
      },
      bookingSchedule: {
        path: 'booking-schedule',
        roles: list(ADMIN, SCHEDULE_MANAGER, PATIENT_SUCCESS),
      },
      reviews: {
        path: 'reviews',
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      userManagement: {
        path: 'user-management',
        roles: list(),
      },
      addUserCredentials: {
        path: 'user-management/create',
        roles: list(),
      },
      userManagementView: {
        path: 'user-management/:id',
        roles: list(),
      },
      consultationTypes: {
        path: 'consultation-types',
        roles: list(),
      },
      consultationTypeView: {
        path: 'consultation-type/:id',
        roles: list(),
      },
      consultationTypeCreate: {
        path: 'consultation-type',
        roles: list(),
      },
      doctorView: {
        path: 'doctor/:id',
        roles: list(SCHEDULE_MANAGER),
      },
      anonymousUsers: {
        path: 'anonymous-users',
        roles: list(ADMIN, SUPER_ADMIN),
      },
      anonymousUserView: {
        path: 'anonymous-user/:id',
        roles: list(ADMIN, SUPER_ADMIN),
      },
    },
  },
  patientInfo: {
    sections: {
      viewUserProfile: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      viewTags: {
        roles: list(
          ADMIN,
          LOGISTICS_ADMIN,
          CLINIC_MANAGER,
          PHARMACIST,
          PATIENT_SUCCESS
        ),
      },
      viewPhoneNumber: {
        roles: list(ALL),
        ...(!canDoctorViewPatientPhoneNumber()
          ? {
              except: except(DOCTOR),
            }
          : {}),
      },
      status: { roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS) },
    },
  },
  labRequestView: {
    sections: {
      payment: {
        components: {
          create: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          view: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          update: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          resend: {
            roles: list(ADMIN, PATIENT_SUCCESS),
          },
          cancel: {
            roles: list(PATIENT_SUCCESS),
          },
        },
      },
      labDetails: {
        components: {
          generateLabDocument: {
            roles: list(ADMIN, SUPER_ADMIN, PATIENT_SUCCESS),
          },
        },
      },
    },
  },
  consultation: {
    sections: {
      editConsultation: {
        roles: list(ADMIN, PATIENT_SUCCESS, DOCTOR),
      },
      sendPrescription: {
        roles: list(ADMIN, DOCTOR),
      },
      addDoctorNotes: {
        roles: list(ADMIN, DOCTOR),
      },
    },
  },
  consultationList: {
    sections: {
      createdByFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      newConsultation: {
        roles: list(ADMIN, CLINIC_MANAGER, PATIENT_SUCCESS),
      },
      meetingCommunicationMethod: {
        roles: list(DOCTOR, ADMIN),
      },
      insuranceProviderFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
    },
  },
  userList: {
    sections: {
      status: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      verified: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      tags: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      signupSource: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, PROMOTOR),
      },
      createdBy: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
    },
  },
  orderList: {
    sections: {
      channel: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      reminderDate: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      newOrder: {
        roles: list(ADMIN, PHARMACIST),
      },
      openLink: {
        roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST),
      },
      searchByPhone: {
        roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST, LOGISTICS_ADMIN),
      },
      doctorFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      driverFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, LOGISTICS_ADMIN),
      },
      logisticsProviderFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, LOGISTICS_ADMIN),
      },
      insuranceProviderFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
    },
  },
  userView: {
    sections: {
      lastActive: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      subscription: {
        roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST),
      },
      editSubscription: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      status: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      sendNotification: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      comments: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      identityExtraction: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      memberManagement: {
        components: {
          view: {
            roles: list(
              ADMIN,
              PHARMACIST,
              PATIENT_SUCCESS,
              CLINIC_MANAGER,
              PROMOTOR
            ),
          },
        },
      },
    },
  },
  newConsultation: {
    sections: {
      doctor: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      attendee: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      status: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
    },
  },
  newOrder: {
    sections: {
      canViewConsultationId: {
        roles: list(ADMIN, PATIENT_SUCCESS, PHARMACIST),
      },
      submitButton: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      signupSource: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
      status: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
    },
  },
  common: {
    sections: {
      export: {
        roles: list(ADMIN),
      },
      tenantFilter: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, LOGISTICS_ADMIN),
      },
      updatedBy: { roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS) },
      healthInfo: {
        roles: list(ADMIN, DOCTOR, PHARMACIST, PATIENT_SUCCESS, CLINIC_MANAGER),
      },
    },
  },
  login: {
    sections: {
      fetchDoctors: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, SCHEDULE_MANAGER),
      },
      fetchConsultationType: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, CLINIC_MANAGER),
      },
      fetchDrivers: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, LOGISTICS_ADMIN),
      },
      fetchPharmacists: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, LOGISTICS_ADMIN),
      },
    },
  },
  dialerLink: {
    sections: {
      showPhone: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, PROMOTOR),
      },
    },
  },
  patientHistory: {
    roles: list(
      ADMIN,
      PHARMACIST,
      PATIENT_SUCCESS,
      DOCTOR,
      CLINIC_MANAGER,
      LOGISTICS_ADMIN
    ),
    sections: {
      consultation: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, DOCTOR),
      },
      order: {
        roles: list(
          ADMIN,
          PHARMACIST,
          PATIENT_SUCCESS,
          LOGISTICS_ADMIN,
          CLINIC_MANAGER
        ),
      },
      labRequest: {
        roles: list(ADMIN, PATIENT_SUCCESS),
      },
    },
  },
  userAccount: {
    sections: {
      viewUserProfile: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS),
      },
      viewTags: {
        roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS, DOCTOR),
      },
      viewPhoneNumber: {
        roles: list(ALL),
        ...(!canDoctorViewPatientPhoneNumber()
          ? {
              except: except(DOCTOR),
            }
          : {}),
      },
      status: { roles: list(ADMIN, PHARMACIST, PATIENT_SUCCESS) },
    },
  },
  bulkNotification: {
    sections: {
      orders: {
        roles: list(SUPER_ADMIN, MARKETING_MANAGER),
      },
      users: {
        roles: list(SUPER_ADMIN, MARKETING_MANAGER),
      },
      consultations: {
        roles: list(SUPER_ADMIN, MARKETING_MANAGER),
      },
      anonymousUsers: {
        roles: list(SUPER_ADMIN, ADMIN),
      },
    },
  },
};
export default PERMISSIONS;
