import { all, call, put, takeLatest } from 'redux-saga/effects';

import { navigate } from 'routing/utils';
import { authConstants } from 'core/auth';
import { authModelActions, authModelActionTypes } from 'model/auth';

import { navigateToMainPage } from 'core/permission/utils';
import * as actions from './actions';
import * as types from './actionTypes';
import { onTokenReceived } from './utils';

export function* login({ username, password }) {
  yield put(actions.setLoggingIn(true));
  yield put(actions.setErrorMessage(''));

  const payload = {
    username,
    password,
  };

  yield put(authModelActions.logIn(payload));
}

export function* onLoginSuccess({ response }) {
  const { token } = response;

  yield call(onTokenReceived, token);
  yield put(actions.setLoggingIn(false));
  yield put(actions.logInSucceeded());
  const page = yield call(navigateToMainPage);
  navigate(page);
  localStorage.removeItem(authConstants.LS_PRE_LOGIN_PATH);
}

export function* onLoginFailed({ response }) {
  const { message, statusCode } = response;

  switch (statusCode) {
    case 401:
      yield put(actions.setErrorMessage('User name or password is incorrect'));
      break;
    default:
      yield put(actions.setErrorMessage(message?.message || message));
      break;
  }

  yield put(actions.setLoggingIn(false));
}

export default function* authSaga() {
  yield all([
    takeLatest(types.LOG_IN, login),
    takeLatest(authModelActionTypes.LOG_IN_SUCCEEDED, onLoginSuccess),
    takeLatest(authModelActionTypes.LOG_IN_FAILED, onLoginFailed),
  ]);
}
