import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as SUBSCRIPTION } from './constants';

export const UPDATE_SUBSCRIPTION = `${MODEL}/${SUBSCRIPTION}/UPDATE_SUBSCRIPTION`;
export const SUBSCRIPTION_UPDATE_RESPONDED = `${MODEL}/${SUBSCRIPTION}/SUBSCRIPTION_UPDATE_RESPONDED`;

export const GET_SUBSCRIPTION = `${MODEL}/${SUBSCRIPTION}/GET_SUBSCRIPTION`;
export const SUBSCRIPTION_RECEIVED = `${MODEL}/${SUBSCRIPTION}/SUBSCRIPTION_RECEIVED`;

export const CANCEL_SUBSCRIPTION = `${MODEL}/${SUBSCRIPTION}/CANCEL_SUBSCRIPTION`;
export const SUBSCRIPTION_CANCEL_RESPONDED = `${MODEL}/${SUBSCRIPTION}/SUBSCRIPTION_CANCEL_RESPONDED`;

export const CREATE_SUBSCRIPTION = `${MODEL}/${SUBSCRIPTION}/CREATE_SUBSCRIPTION`;
export const SUBSCRIPTION_CREATION_RESPONDED = `${MODEL}/${SUBSCRIPTION}/SUBSCRIPTION_CREATION_RESPONDED`;

export const FETCH_SUBSCRIPTION_PLANS = `${MODEL}/${SUBSCRIPTION}/FETCH_SUBSCRIPTION_PLANS`;
export const SUBSCRIPTION_PLANS_RECEIVED = `${MODEL}/${SUBSCRIPTION}/SUBSCRIPTION_PLANS_RECEIVED`;
