import get from 'lodash/get';
import React, { useState, useEffect } from 'react';

import { CURRENCIES, CURRENCIES_LIST } from 'constants/currency';
import {
  Grid,
  Button,
  Dialog,
  Select,
  Switch,
  Tooltip,
  MenuItem,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from 'design-system';

function CreatePaymentTransaction({
  open,
  user,
  onClose,
  permissions,
  referenceId,
  referenceType,
  isUpdateDialog,
  createPaymentTransaction,
}) {
  const defaultCurrency =
    process.env.REACT_APP_DEFAULT_CURRENCY || CURRENCIES.AED.key;
  const defaultValues = {
    amount: 0,
    currency: defaultCurrency,
  };

  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [isAutoNotification, setAutoNotification] = useState(true);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const resetFields = () => {
    setValues(defaultValues);
    setAutoNotification(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
    resetFields();
  };

  const onCreateButtonClicked = () => {
    const payload = {
      referenceId,
      referenceType,
      dueAmount: values,
      user,
      isAutoNotification,
    };
    createPaymentTransaction(payload);
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="create-payment-transaction-title"
    >
      <DialogTitle id="create-payment-transaction-title" onClose={handleClose}>
        {get(permissions, 'update', true) &&
          (isUpdateDialog ? 'Update Payment Link' : 'Create Payment Link')}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container column>
          <Grid item sx={{ mb: 1 }}>
            <Typography variant="p4">DUE AMOUNT</Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size="large"
              type="number"
              variant="filled"
              value={values.amount}
              onChange={handleChange('amount')}
              placeholder="Set due amount for consultation"
              sx={{
                '& > .MuiInputBase-root': {
                  pr: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      size="large"
                      variant="filled"
                      value={values.currency}
                      onChange={handleChange('currency')}
                    >
                      {CURRENCIES_LIST.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container ml={1} justifyContent="space-between">
          <Tooltip
            placement="top"
            title="If checked, an SMS and push notification will be sent automatically to the patient "
            injectWrapper
          >
            <Switch
              label="Send payment message to patient"
              onChange={(e) => setAutoNotification(e.target.checked)}
              checked={isAutoNotification}
            />
          </Tooltip>
          <Button
            variant="filled"
            disabled={!values.amount || values.amount <= 0}
            onClick={() => {
              onCreateButtonClicked();
            }}
          >
            {isUpdateDialog ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default CreatePaymentTransaction;
