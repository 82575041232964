import isEmpty from 'lodash/isEmpty';

import { OUTCOME_SUBMISSION_STATUS } from 'domain/outcome/constants';

import { STATUSES, SCHEDULE_BOOKED_STATUSES } from './constants';

export const isDoctorRequired = (status) =>
  [
    STATUSES.PENDING_APPROVAL.key,
    STATUSES.COMPLETED.key,
    STATUSES.CONFIRMED.key,
    STATUSES.TIME_FINISHED.key,
    STATUSES.RESERVED.key,
  ].includes(status);

export const isPendingStatuses = (status) => {
  return [
    STATUSES.PENDING_APPROVAL.key,
    STATUSES.PAID.key,
    STATUSES.PENDING_DOCUMENTS_UPLOAD.key,
    STATUSES.UNCONFIRMED.key,
    STATUSES.CHECK_DOCUMENTS.key,
    STATUSES.REGISTRATION_REQUIRED.key,
  ].includes(status);
};
/** This method used to get the booked consultation status on schedule */
export const getConsultationBookingStatus = (consultationStatus) => {
  return isPendingStatuses(consultationStatus)
    ? SCHEDULE_BOOKED_STATUSES.PENDING
    : SCHEDULE_BOOKED_STATUSES[consultationStatus];
};

/**
 * Loops over patients' outcomes and
 * checks if any of the outcomes is pending submission.
 *
 * In order not to show Action Required twice (once in outcome section and another in send prescription button),
 *  we also check if there's already uploaded files.
 * Because the action required of files shows up in OutcomeSection.
 *
 * @param patients: List of consultation patients
 * @param byReferenceType: Either OUTCOME_OPTIONS.Prescription.key or OUTCOME_OPTIONS.LabTestRequest.key
 * @returns boolean
 */
export const arePatientsOutcomesPendingSubmission = (
  patients = [],
  byReferenceType
) => {
  return patients.some((patient) => {
    const outcomeOfType = patient.output.find((outcome) => {
      return outcome.referenceType === byReferenceType;
    });

    return (
      // We need to show the action required (Needs submission),
      // only when there's an outcome with PENDING_SUBMISSION and there are files/outputsList.
      // The action required prompting to upload files shows up in OutcomeSection.
      // This way we avoid showing 2 action required labels.
      (!isEmpty(outcomeOfType?.referenceFiles) ||
        !isEmpty(outcomeOfType?.outputsList)) &&
      outcomeOfType?.submissionStatus ===
        OUTCOME_SUBMISSION_STATUS.PENDING_SUBMISSION.key
    );
  });
};

/**
 * Loops over patients' outcomes and
 * checks whether there's a referenceId
 *
 * @param patients: List of consultation patients
 * @param byReferenceType: Either OUTCOME_OPTIONS.Prescription.key or OUTCOME_OPTIONS.LabTestRequest.key
 * @returns boolean
 */
export const havePatientsOutcomesBeenSubmittedBefore = (
  patients,
  byReferenceType
) => {
  const hasBeenSubmittedBefore = patients.some((patient) => {
    const outcomeOfType = patient.output.find((outcome) => {
      return outcome.referenceType === byReferenceType;
    });

    return outcomeOfType?.referenceId;
  });

  return hasBeenSubmittedBefore;
};

/**
 * Loops over patients' outcomes and
 * checks whether resubmission is required.
 *
 * How?
 * First, we need to know if the outcomes have been submitted before,
 * we do that by checking if referenceId exists in any of the outcomes.
 *
 * Second, we check if any of the outcomes is pending submission.
 *
 * If both conditions are achieved, we return true.
 *
 * @param patients: List of consultation patients
 * @param byReferenceType: Either OUTCOME_OPTIONS.Prescription.key or OUTCOME_OPTIONS.LabTestRequest.key
 * @returns boolean
 */
export const arePatientsOutcomesPendingResubmission = (
  patients,
  byReferenceType
) => {
  const hasBeenSubmittedBefore = patients.some((patient) => {
    const outcomeOfType = patient.output.find((outcome) => {
      return outcome.referenceType === byReferenceType;
    });

    return outcomeOfType?.referenceId;
  });

  const isPendingSubmission = patients.some((patient) => {
    const outcomeOfType = patient.output.find((outcome) => {
      return outcome.referenceType === byReferenceType;
    });

    return (
      outcomeOfType?.submissionStatus ===
      OUTCOME_SUBMISSION_STATUS.PENDING_SUBMISSION.key
    );
  });

  // Why two different loops?
  // Think of the case where outcome X has been submited (obtained a referneceId),
  // then we add a new outcome Y which won't have referenceId
  // but its status is pending submission.
  // Having two loops to check guarantees that an outcome or two outcomes achieve the condition.
  return hasBeenSubmittedBefore && isPendingSubmission;
};
