import get from 'lodash/get';
import { createSelector } from 'reselect';

export const doctorNotesSelector = (state) =>
  get(state, 'domain.doctorNotes', {});

export const getDoctorNotes = createSelector(doctorNotesSelector, (results) =>
  get(results, 'doctorNotes', {})
);

export const getDoctorNotesById = createSelector(
  [getDoctorNotes, (_state, userId) => userId],
  (results, userId) => get(results, userId)
);

export const isFetchingDoctorNotes = createSelector(
  doctorNotesSelector,
  (results) => get(results, 'isFetching')
);

export const isSubmittingDoctorNotes = createSelector(
  doctorNotesSelector,
  (results) => get(results, 'isSubmitting')
);

export const getPatientAssessmentSheet = createSelector(
  doctorNotesSelector,
  (results) => get(results, 'patientAssessmentSheet')
);
export const getPatientAssessmentSheetById = createSelector(
  [getPatientAssessmentSheet, (_state, userId) => userId],
  (results, userId) => get(results, userId)
);
