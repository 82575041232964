import * as types from './actionTypes';

export const fetchOrder = (id) => ({
  type: types.FETCH_ORDER,
  id,
});

export const fetchPinnedOrders = () => ({
  type: types.FETCH_PINNED_ORDERS,
});

export const createOrder = (payload) => ({
  type: types.CREATE_ORDER,
  payload,
});

export const patchOrder = (id, payload) => ({
  type: types.PATCH_ORDER,
  payload,
  id,
});

export const addDeliveryAddress = (payload) => ({
  type: types.ADD_DELIVERY_ADDRESS,
  payload,
});

export const updateDeliveryAddress = (id, payload) => ({
  type: types.UPDATE_DELIVERY_ADDRESS,
  payload,
  id,
});

export const deliveryAddressAdded = (deliveryAddress) => ({
  type: types.DELIVERY_ADDRESS_ADDED,
  deliveryAddress,
});

export const deliveryAddressUpdated = () => ({
  type: types.DELIVERY_ADDRESS_UPDATED,
});

export const addLineItem = (payload) => ({
  type: types.ADD_LINE_ITEM,
  payload,
});

export const updateLineItem = (id, payload) => ({
  type: types.UPDATE_LINE_ITEM,
  payload,
  id,
});

export const deleteLineItem = (id) => ({
  type: types.DELETE_LINE_ITEM,
  id,
});

export const createPrescriptions = (payload) => ({
  type: types.CREATE_PRESCRIPTIONS,
  payload,
});

export const prescriptionsCreated = (order) => ({
  type: types.PRESCRIPTIONS_CREATED,
  order,
});

export const orderFetchResponded = (ok, response) => ({
  type: types.ORDER_FETCH_RESPONDED,
  ok,
  response,
});

export const pinnedOrdersFetchResponded = (ok, response) => ({
  type: types.PINNED_ORDERS_FETCH_RESPONDED,
  ok,
  response,
});

export const orderUpdateResponded = (ok) => ({
  type: types.ORDER_UPDATE_RESPONDED,
  ok,
});

export const logisticProviderShipmentCreationResponded = (ok, response) => ({
  type: types.LOGISTIC_PROVIDER_SHIPMENT_CREATION_RESPONDED,
  ok,
  response,
});

export const lineItemAdded = (lineItem) => ({
  type: types.LINE_ITEM_ADDED,
  lineItem,
});

export const lineItemUpdated = (lineItem, id) => ({
  type: types.LINE_ITEM_UPDATED,
  lineItem,
  id,
});

export const lineItemDeleted = (id) => ({
  type: types.LINE_ITEM_DELETED,
  id,
});

export const orderCreationResponded = (status, data, message) => ({
  type: types.ORDER_CREATION_RESPONDED,
  status,
  message,
  data,
});

export const fetchLogisticProviders = () => ({
  type: types.FETCH_LOGISTIC_PROVIDERS,
});

export const fetchShipment = (orderId) => ({
  type: types.FETCH_SHIPMENT,
  orderId,
});

export const shipmentFetchResponded = (ok, shipment) => ({
  type: types.SHIPMENT_FETCH_RESPONDED,
  ok,
  shipment,
});

export const logisticProvidersResponded = (logisticProviders) => ({
  type: types.LOGISTIC_PROVIDERS_RESPONDED,
  logisticProviders,
});

export const fetchShipmentLabel = (orderId) => ({
  type: types.FETCH_SHIPMENT_LABEL,
  orderId,
});

export const orderShipmentLabelResponded = (shipmentLabel) => ({
  type: types.SHIPMENT_LABEL_RESPONDED,
  shipmentLabel,
});

export const createLogisticProviderShipment = (payload) => ({
  type: types.CREATE_LOGISTIC_PROVIDER_SHIPMENT,
  payload,
});

export const fetchOrderHistory = (id) => ({
  type: types.FETCH_ORDER_HISTORY,
  id,
});

export const orderHistoryFetchResponded = (ok, response) => ({
  type: types.ORDER_HISTORY_FETCH_RESPONDED,
  ok,
  response,
});

export const fetchLineItemsHistory = (orderId) => ({
  type: types.FETCH_LINE_ITEMS_HISTORY,
  orderId,
});

export const lineItemsHistoryFetchResponded = (ok, response) => ({
  type: types.LINE_ITEMS_HISTORY_FETCH_RESPONDED,
  ok,
  response,
});
