import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import LogIn from 'presentation/auth/LogIn';
import { userConstants } from 'domain/user';
import UserView from 'presentation/user/UserView';
import NewOrder from 'presentation/order/NewOrder';
import UsersList from 'presentation/user/UsersList';
import OrderView from 'presentation/order/OrderView';
import UserCreate from 'presentation/user/UserCreate';
import OrdersList from 'presentation/order/OrdersList';
import ReviewsList from 'presentation/review/ReviewsList';
import { REFERENCE_TYPES } from 'constants/general';
import NewMedicine from 'presentation/medicine/NewMedicine';
import LabRequestList from 'presentation/lab/LabRequestList';
import LabRequestView from 'presentation/lab/LabRequestView';
import MedicineView from 'presentation/medicine/MedicineView';
import PageContainer from 'presentation/layout/PageContainer';
import MedicinesList from 'presentation/medicine/MedicinesList';
import LabRequestCreate from 'presentation/lab/LabRequestCreate';
import BookingSchedule from 'presentation/schedule/BookingSchedule';
import NewConsultation from 'presentation/consultation/NewConsultation';
import ConsultationView from 'presentation/consultation/ConsultationView';
import ConsultationsList from 'presentation/consultation/ConsultationsList';
import UserManagement from 'presentation/userManagement/UserManagementList';
import DoctorManagement from 'presentation/doctorManagement/DoctorManagement';
import NewUserCredentials from 'presentation/userManagement/NewUserCredentials';
import UserManagementView from 'presentation/userManagement/UserManagementView';
import DoctorProfileView from 'presentation/doctorManagement/doctorProfile/DoctorProfileView';
import NewConsultationType from 'presentation/consultationTypeManagement/NewConsultationType';
import ConsultationTypeManagementList from 'presentation/consultationTypeManagement/ConsultationTypeManagementList';
import ConsultationTypeManagementView from 'presentation/consultationTypeManagement/ConsultationTypeManagementView';
import AnonymousUsersList from 'presentation/anonymousUser/AnonymousUsersList';
import AnonymousUserView from 'presentation/anonymousUser/AnonymousUserView';
import ErrorPage from './ErrorPage';

const { SIGN_UP_SOURCE } = userConstants;

export const defineRootRoutes = () => {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <LogIn />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/',
      element: <PageContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: 'consultations',
          element: <ConsultationsList />,
        },
        {
          path: 'consultation',
          element: <NewConsultation />,
        },
        {
          path: 'consultation/:id',
          element: <ConsultationView />,
        },
        {
          path: 'orders',
          element: <OrdersList />,
        },
        {
          path: 'order',
          element: <NewOrder />,
        },
        {
          path: 'order/:id',
          element: <OrderView />,
        },
        {
          path: 'lab-requests',
          element: <LabRequestList />,
        },
        {
          path: 'lab-request',
          element: <LabRequestCreate />,
        },
        {
          path: 'lab-request/:id',
          element: <LabRequestView />,
        },
        {
          path: 'medicines',
          element: <MedicinesList />,
        },
        {
          path: 'medicine',
          element: <NewMedicine />,
        },
        {
          path: 'medicine/:id',
          element: <MedicineView />,
        },
        {
          path: 'reviews',
          element: <ReviewsList />,
        },
        {
          path: 'booking-schedule',
          element: <BookingSchedule />,
        },
        {
          path: 'users',
          element: (
            <UsersList
              key="users"
              endpoint="/user"
              referenceType={REFERENCE_TYPES.USER}
            />
          ),
        },
        {
          path: 'user',
          element: <UserCreate />,
        },
        {
          path: 'user/:id',
          element: <UserView endpoint="/user" />,
        },
        {
          path: 'lead-users',
          element: (
            <UsersList
              key="lead-users"
              endpoint="/lead-user"
              title="Lead Patients"
              referenceType={REFERENCE_TYPES.LEAD_USER}
              filterSignUpSources={[
                SIGN_UP_SOURCE['NextCare GK'].key,
                SIGN_UP_SOURCE['CONTACTS IMPORT'].key,
                SIGN_UP_SOURCE.IMPORT.key,
              ]}
            />
          ),
        },
        {
          path: 'user-management',
          element: <UserManagement />,
        },
        {
          path: 'anonymous-users',
          element: <AnonymousUsersList />,
        },
        {
          path: 'anonymous-user/:id',
          element: <AnonymousUserView />,
        },
        {
          path: 'consultation-types',
          element: <ConsultationTypeManagementList />,
        },
        {
          path: 'consultation-type/:id',
          element: <ConsultationTypeManagementView />,
        },
        {
          path: 'consultation-type',
          element: <NewConsultationType />,
        },
        {
          path: 'user-management/create',
          element: <NewUserCredentials />,
        },
        {
          path: 'user-management/:id',
          element: <UserManagementView />,
        },
        {
          path: 'doctor-management',
          element: <DoctorManagement />,
        },
        {
          path: 'doctor/:id',
          element: <DoctorProfileView />,
        },
      ],
    },
  ]);

  const supportBackwardCompatibility = () => {
    const oldRoute = (router?.state?.location?.hash || '').replace(
      /#\/|#/g,
      ''
    );
    const rawOldRoute = oldRoute.replace(/\d+/g, ':id');

    const oldies = [
      {
        old: 'consultation/create',
        new: '/consultation',
      },
      {
        old: 'consultation/:id/show',
        new: '/consultation/:id',
      },
      {
        old: 'consultation/:id',
        new: '/consultation/:id',
      },
      {
        old: 'consultation',
        new: '/consultations',
      },
      //
      {
        old: 'order/create',
        new: '/order',
      },
      {
        old: 'order/:id/show',
        new: '/order/:id',
      },
      {
        old: 'order/:id',
        new: '/order/:id',
      },
      {
        old: 'order',
        new: '/orders',
      },
      //
      {
        old: 'user/create',
        new: '/user',
      },
      {
        old: 'user/:id/show',
        new: '/user/:id',
      },
      {
        old: 'user/:id',
        new: '/user/:id',
      },
      {
        old: 'user',
        new: '/users',
      },
      //
      {
        old: 'lab/create',
        new: '/lab-request',
      },
      {
        old: 'lab/:id/show',
        new: '/lab-request/:id',
      },
      {
        old: 'lab/:id',
        new: '/lab-request/:id',
      },
      {
        old: 'lab',
        new: '/lab-requests',
      },
      //
      {
        old: 'medicine/create',
        new: '/medicine',
      },
      {
        old: 'medicine/:id/show',
        new: '/medicine/:id',
      },
      {
        old: 'medicine/:id',
        new: '/medicine/:id',
      },
      {
        old: 'medicine',
        new: '/medicines',
      },
      //
      {
        old: 'lead-user',
        new: '/lead-users',
      },
      //
      {
        old: 'reviews',
        new: '/reviews',
      },
      //
      {
        old: 'schedule',
        new: '/doctor-management',
      },
    ];

    if (!oldRoute) {
      return;
    }

    oldies.some((route) => {
      const id = oldRoute.match(/\d+/g)?.[0];

      if (rawOldRoute === route.old) {
        router.navigate(route.new.replace(':id', id));
        return true;
      }

      return false;
    });
  };

  supportBackwardCompatibility();

  return router;
};

export const router = defineRootRoutes();

const RootRoute = () => {
  return <RouterProvider router={router} />;
};

export default RootRoute;
