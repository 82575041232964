import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import isValid from 'date-fns/isValid';
import React, { useState, useEffect } from 'react';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import formatISO from 'date-fns/formatISO';

import { dateFormat } from 'constants/dateTime';
import { userConstants, userGetters as getters } from 'domain/user';
import {
  Grid,
  Chip,
  Select,
  Checkbox,
  Button,
  Divider,
  Tooltip,
  MenuItem,
  Skeleton,
  DataGrid,
  TextField,
  Typography,
  DatePicker,
} from 'design-system';

const formatTitle = (title) => {
  return title?.replace(/_/g, ' ');
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
      width: 100,
    },
  },
};
const PatientHealthInfoEdit = ({
  user,
  ownerId,
  isFetching,
  healthInfo,
  isFetchingHealth,
  fetchHealthInfo,
  isSubmitting,
  updateHealthProfile,
}) => {
  const { BLOOD_TYPE, DISEASES, GENDER, HEALTH_PROFILE_FIELDS } = userConstants;

  const [gender, setGender] = useState('');
  const [bloodType, setBloodType] = useState();
  const [weight, setWeight] = useState(); // {Math.round(weight * 100) / 100 || '-'}
  const [height, setHeight] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [chronicConditions, setChronicConditions] = useState([]);
  // Don't show this section unless its data and consultation data are ready
  const [isLoading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [hasFormChanged, setFormChanged] = useState(false);

  const toggleFormChangedStatus = (isEnabled) => {
    if (!isFetchingHealth) {
      setFormChanged(isEnabled);
    }
  };

  const submitChanges = (e) => {
    e.stopPropagation();

    const payload = {
      [HEALTH_PROFILE_FIELDS.OWNER_ID.name]: ownerId,
      [HEALTH_PROFILE_FIELDS.USER_ID.name]: user.id,
      [HEALTH_PROFILE_FIELDS.WEIGHT.name]: weight,
      [HEALTH_PROFILE_FIELDS.HEIGHT.name]: height,
      [HEALTH_PROFILE_FIELDS.GENDER.name]: gender,
      [HEALTH_PROFILE_FIELDS.BLOOD_TYPE.name]: bloodType,
      [HEALTH_PROFILE_FIELDS.DATE_OF_BIRTH.name]: isValid(dateOfBirth)
        ? formatISO(dateOfBirth)?.split('T')[0]
        : '',
      [HEALTH_PROFILE_FIELDS.CHRONIC_CONDITIONS.name]: chronicConditions,
    };
    updateHealthProfile(user.id, payload);
    setFormChanged(false);
  };

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 2,
      renderCell: (params) => {
        return (
          <Tooltip title={formatTitle(params.row.type)}>
            <span>{formatTitle(params.row.type)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'averageValue',
      headerName: 'Average',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={parseFloat(params.row?.averageValue)?.toFixed(2)}>
            <span>{parseFloat(params.row?.averageValue)?.toFixed(2)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'maxValue',
      headerName: 'Maximum',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={parseFloat(params.row?.maxValue)?.toFixed(2)}>
            <span>{parseFloat(params.row?.maxValue)?.toFixed(2)}</span>
          </Tooltip>
        );
      },
    },
    {
      field: 'minValue',
      headerName: 'Minimum',
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={parseFloat(params.row?.minValue)?.toFixed(2)}>
            <span>{parseFloat(params.row?.minValue)?.toFixed(2)}</span>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (!isEmpty(user)) {
      fetchHealthInfo(getters.getId(user));
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user) && !isFetchingHealth) {
      setBloodType(healthInfo?.bloodType);
      setGender(healthInfo?.gender || '');
      setWeight(healthInfo?.weight || '');
      setHeight(healthInfo?.height || '');
      setChronicConditions(healthInfo?.diseases || []);
      setDateOfBirth(healthInfo?.dob);
      setLoading(isFetching || isFetchingHealth);
      setRows(healthInfo?.healthRecords || []);
    }
  }, [healthInfo]);

  return (
    <>
      <Grid item sx={{ mb: 1 }}>
        <MedicalServicesIcon
          color="standard"
          fontSize="extraSmall"
          sx={{ pr: 1 }}
        />

        <Typography variant="p4" loading={isLoading && { width: 80 }}>
          CHRONIC CONDITIONS
        </Typography>
      </Grid>
      <Grid item sx={{ mb: 1 }}>
        <Select
          style={{ height: 'auto' }}
          fullWidth
          multiple
          soak="light"
          variant="filled"
          value={chronicConditions}
          loading={isLoading}
          MenuProps={MenuProps}
          onChange={(event) => {
            setChronicConditions(event.target.value);
            toggleFormChangedStatus(true);
          }}
          renderValue={(selected) => (
            <Grid
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.5,
              }}
            >
              {selected.map((value) => (
                <Chip
                  size="small"
                  color="primary"
                  key={DISEASES[value]?.key || value}
                  label={DISEASES[value]?.label || value}
                />
              ))}
            </Grid>
          )}
        >
          {keys(DISEASES).map((option) => (
            <MenuItem value={option} key={option}>
              <Checkbox checked={chronicConditions.indexOf(option) > -1} />
              {DISEASES[option].label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item container alignItems="center" sx={{ mt: 2, mb: 1 }}>
        <Grid item container sx={{ width: '100%' }} flexWrap="nowrap">
          <Typography
            variant="p4"
            sx={{ lineHeight: 3, marginRight: 1 }}
            loading={isLoading && { width: 40 }}
          >
            WEIGHT
          </Typography>

          <TextField
            size="medium"
            type="number"
            variant="filled"
            value={weight}
            loading={isLoading}
            onChange={(event) => {
              setWeight(event.target.value);
              toggleFormChangedStatus(true);
            }}
            sx={{
              marginRight: '6px',
              width: '80%',
            }}
          />

          <Grid item container flexWrap="nowrap" sx={{ width: '100%' }}>
            <Typography
              variant="p4"
              sx={{ lineHeight: 3, marginRight: 1 }}
              loading={isLoading && { width: 40 }}
            >
              HEIGHT
            </Typography>
            <TextField
              size="medium"
              type="number"
              variant="filled"
              value={height}
              loading={isLoading}
              onChange={(event) => {
                setHeight(event.target.value);
                toggleFormChangedStatus(true);
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" sx={{ mt: 2 }}>
        <Grid item container sx={{ width: '30%' }} flexWrap="nowrap">
          <Typography
            variant="p4"
            sx={{ lineHeight: 3, marginRight: 2 }}
            loading={isLoading && { width: 50 }}
          >
            DATE OF BIRTH
          </Typography>
        </Grid>
        <Grid item container sx={{ width: '70%' }} flexWrap="nowrap">
          <DatePicker
            inputFormat={dateFormat}
            value={dateOfBirth || ''}
            maxDate={new Date()}
            defaultValue={dateOfBirth}
            renderInput={(props) => (
              <TextField
                fullWidth
                size="medium"
                variant="filled"
                loading={isLoading}
                {...props}
              />
            )}
            onChange={(newValue) => {
              setDateOfBirth(newValue);
              toggleFormChangedStatus(true);
            }}
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="center" sx={{ mt: 2, mb: 1 }}>
        <Grid item container flexWrap="nowrap">
          <Typography
            variant="p4"
            sx={{ lineHeight: 3, marginRight: 1 }}
            loading={isLoading && { width: 50 }}
          >
            GENDER
          </Typography>
          <Select
            fullWidth
            size="medium"
            soak="light"
            variant="filled"
            value={gender}
            loading={isLoading}
            onChange={(event) => {
              setGender(event.target.value);
              toggleFormChangedStatus(true);
            }}
            renderValue={(value) => GENDER[value]?.label || value}
            sx={{ width: '70%', marginRight: '6px' }}
          >
            {keys(GENDER).map((type) => (
              <MenuItem key={type} value={type}>
                {GENDER[type].label}
              </MenuItem>
            ))}
          </Select>

          <Grid item container flexWrap="nowrap">
            <Typography
              variant="p4"
              sx={{ lineHeight: 3, marginRight: 1, width: '78%' }}
              loading={isLoading && { width: 50 }}
            >
              BLOOD TYPE
            </Typography>
            <Select
              fullWidth
              size="medium"
              soak="light"
              variant="filled"
              value={bloodType}
              loading={isLoading}
              onChange={(event) => {
                setBloodType(event.target.value);
                toggleFormChangedStatus(true);
              }}
              renderValue={(value) => value}
            >
              {keys(BLOOD_TYPE).map((type) => (
                <MenuItem value={type} key={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="filled"
          onClick={submitChanges}
          loading={isLoading && { width: 160 }}
          spinning={isSubmitting}
          disabled={!hasFormChanged}
          sx={{ mb: 2 }}
        >
          Save health data
        </Button>
      </Grid>
      <Divider />
      <Grid>
        {isLoading && (
          <Skeleton
            variant="h5"
            width={140}
            height={50}
            sx={{ borderRadius: 32 }}
          />
        )}
        <Typography variant="h5" sx={{ margin: 2, ml: 0 }} loading={isLoading}>
          Highlights on your health
        </Typography>
        <DataGrid
          rows={rows}
          pageSize={5}
          columns={columns}
          loading={isLoading}
        />
      </Grid>
    </>
  );
};
export default PatientHealthInfoEdit;
