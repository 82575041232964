import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  TextField,
  Box,
  InputAdornment,
  DateTimePicker,
  IconButton,
} from 'design-system';

const DateTimePickerFilter = ({ field }) => {
  const { defaultValue = null } = field;

  const [dateTimeValue, setDateTimeValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState(defaultValue);

  const onChange = (value) => {
    setDateTimeValue(value);
    field.filter(value);
  };

  return (
    <DateTimePicker
      inputFormat="PP p"
      value={dateTimeValue}
      onChange={(value) => {
        setDateTimeValue(value);
        field.filter(value);
      }}
      {...field}
      renderInput={(params) => {
        // delete params.InputProps.endAdornment;
        const inputParams = params;
        if (field.icon) {
          inputParams.InputProps = {
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <field.icon color="standard" fontSize="extraSmall" />
              </InputAdornment>
            ),
          };
        }
        if (field?.placeholder) {
          inputParams.inputProps.placeholder = field.placeholder;
        }
        inputParams.InputProps = {
          ...params.InputProps,
          endAdornment: (
            <>
              {dateTimeValue && (
                <IconButton
                  shape="round"
                  variant="icon"
                  color="standard"
                  className="dateFilter_closeIcon"
                  onClick={() => {
                    onChange(null);
                    setInputValue(null);
                  }}
                  sx={{
                    m: 0,
                    display: 'none',
                    '& > .MuiButton-startIcon': {
                      m: 0,
                    },
                  }}
                >
                  <CloseRoundedIcon
                    size="small"
                    sx={{
                      fontSize: 18,
                      color: (theme) => theme.palette.grey[700],
                    }}
                  />
                </IconButton>
              )}
              <Box
                sx={{
                  '& .MuiInputAdornment-positionEnd': {
                    margin: 0,
                  },
                }}
              >
                {params.InputProps.endAdornment}
              </Box>
            </>
          ),
        };
        return (
          <TextField
            {...params}
            fullWidth
            size="medium"
            shape="round"
            value={inputValue}
            sx={{
              '&:hover .dateFilter_closeIcon': {
                display: 'inline-flex',
              },
            }}
            variant="filled"
          />
        );
      }}
    />
  );
};

export default DateTimePickerFilter;
