import { connect } from 'react-redux';

import { memberSelectors } from 'domain/user/member';

import EPrescription from './EPrescription';

const mapStateToProps = (state) => {
  const isFetchingHealth =
    memberSelectors.isFetchingMembersHealthProfiles(state);

  return {
    isFetchingHealth,
  };
};

export default connect(mapStateToProps)(EPrescription);
