import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { userActions, userSelectors } from 'domain/user';
import {
  consultationActions,
  consultationSelectors,
} from 'domain/consultation';

import NewConsultation from './NewConsultation';

const mapStateToProps = (state, ownProps) => {
  const referenceDocuments = ownProps?.documents;

  const user = userSelectors.getUser(state);
  const isFetchingUser = userSelectors.isFetchingUser(state);
  const isSubmitting = consultationSelectors.isSubmittingConsultation(state);

  return {
    user,
    isSubmitting,
    isFetchingUser,
    referenceDocuments,
    userId: ownProps?.userId,
    embedded: ownProps?.embedded,
    selectedTenant: ownProps?.tenant,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch(userActions.clearUser()),
  fetchUser: (id) => dispatch(userActions.fetchUser(id)),
  createConsultation: (payload) =>
    dispatch(consultationActions.createConsultation(payload)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(NewConsultation)
);
