/* eslint-disable react/no-unstable-nested-components */
import keys from 'lodash/keys';
import add from 'date-fns/add';
import set from 'date-fns/set';
import isEmpty from 'lodash/isEmpty';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import { useSelector } from 'react-redux';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import React, { useMemo, useState, useEffect } from 'react';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

import { userGetters } from 'domain/user';
import { isAllowed } from 'core/permission';
import { authSelectors } from 'domain/auth';
import { UserSearch } from 'presentation/user';
import { timeFormat } from 'constants/dateTime';
import { getDefaultCountryCodeLong, getDefaultTimeZone } from 'core/siteConfig';
import PatientInfo from 'presentation/components/PatientInfo';
import { CreateUserModal } from 'presentation/user/components';
import { isMidnight } from 'core/utils/date';
import BookingAvailability from 'presentation/schedule/BookingAvailability';
import DateTimeRangePicker from 'presentation/components/DateTimeRangePicker';
import {
  consultationUtils as utils,
  CONSULTATION_DOMAIN_FIELDS as FIELDS,
  consultationConstants as constants,
} from 'domain/consultation';
import {
  CONSULTATION_CHANNELS,
  CONSULTATION_ATTENDEE,
} from 'domain/consultation/constants';
import {
  If,
  Else,
  Card,
  Grid,
  Button,
  Select,
  MenuItem,
  FormField,
  PageTitle,
  FileInput,
  Typography,
  CardHeader,
  CardContent,
  DialogTitle,
  RequiredMark,
  DialogContent,
  DialogActions,
  Conditionally,
  LinearProgress,
} from 'design-system';

const { STATUSES } = constants;

const Content = ({
  user,
  status,
  tenant,
  doctor,
  doctors,
  endTime,
  tenants,
  embedded,
  attendee,
  setDoctor,
  fetchUser,
  clearUser,
  setStatus,
  setTenant,
  startTime,
  setEndTime,
  setAttendee,
  bookedSlots,
  setStartTime,
  isFetchingUser,
  setBookedSlots,
  consultationDate,
  consultationType,
  statusSelectColor,
  consultationTypes,
  referenceDocuments,
  setConsultationDate,
  setConsultationType,
  newUploadedDocuments,
  setNewUploadedDocuments,
}) => {
  const { isClinicManager } = useSelector(authSelectors.getRole);
  const [documents, setDocuments] = useState(referenceDocuments || []);
  const [createUserModalKey, setCreateUserModalKey] = useState(Date.now());
  const [isUserCreationModalOpen, setUserCreationModalOpen] = useState(false);

  const onFilesUploaded = (newFiles, allFiles) => {
    setDocuments(allFiles);
    setNewUploadedDocuments([...newUploadedDocuments, ...newFiles]);
  };

  const getTime = (time) => {
    if (consultationDate) {
      const date = getDate(consultationDate);
      const year = getYear(consultationDate);
      const month = getMonth(consultationDate);

      return set(time, { year, month, date });
    }
    return time;
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        row
        alignItems="stretch"
        justifyContent="left"
      >
        {!embedded && (
          <Grid item xs={12} sm={6}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent
                noPadding
                sx={{
                  height: '100%',
                }}
              >
                <Grid container column py={2}>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">
                      Search by phone number or name
                    </Typography>
                  </Grid>
                  <Grid item container spacing={1} sx={{ mb: 1, px: 2 }}>
                    <Grid item xs={12} md={7}>
                      <UserSearch
                        size="large"
                        onUserSelect={(selectedPatient) => {
                          const uid = userGetters.getId(selectedPatient);

                          if (uid) {
                            fetchUser(uid);
                          } else {
                            clearUser();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Button
                        variant="text"
                        onClick={() => {
                          setCreateUserModalKey(Date.now());
                          setUserCreationModalOpen(true);
                        }}
                      >
                        Or create a new user
                      </Button>
                    </Grid>
                  </Grid>
                  {!isEmpty(user) && (
                    <Grid item container column>
                      <PatientInfo
                        user={user}
                        isFetching={isFetchingUser}
                        config={{
                          fields: {
                            identity: {
                              show: false,
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}
                  {isEmpty(user) && (
                    <Grid
                      item
                      container
                      justifyContent="center"
                      loading={isFetchingUser}
                    >
                      <PersonAddRoundedIcon
                        sx={{
                          color: (theme) => theme.palette.secondary.tint,
                          fontSize: {
                            xs: '10rem',
                            md: '20rem',
                          },
                        }}
                      />
                    </Grid>
                  )}
                  {isFetchingUser && <LinearProgress />}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={12} sm={embedded ? 12 : 6}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title="Consultation Details" sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              <Grid container column p={2}>
                {/* Status */}
                {isAllowed('newConsultation', 'status') && (
                  <Grid>
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="p4">STATUS</Typography>
                      <RequiredMark />
                    </Grid>
                    <Grid item sx={{ mb: 3 }}>
                      <Select
                        fullWidth
                        size="large"
                        soak="light"
                        value={status}
                        variant="filled"
                        disabled={isFetchingUser}
                        color={statusSelectColor}
                        placeholder="Select status..."
                        onChange={(event) => {
                          setStatus(event.target.value);
                        }}
                        renderValue={(value) =>
                          value ? STATUSES[value]?.label : value
                        }
                      >
                        {keys(STATUSES).map((item) => (
                          <MenuItem value={item} key={item}>
                            {STATUSES[item]?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                )}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">CONSULTATION TYPE</Typography>
                  <RequiredMark />
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Select
                    fullWidth
                    size="large"
                    soak="light"
                    variant="filled"
                    value={consultationType}
                    disabled={isFetchingUser}
                    renderValue={(val) => val?.englishTitle}
                    placeholder="Select consultation type.."
                    onChange={(event) => {
                      setConsultationType(event.target.value);
                    }}
                  >
                    {consultationTypes.map((option) => (
                      <MenuItem value={option} key={option.englishTitle}>
                        {option.englishTitle}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                {/* Branch */}
                {isAllowed('common', 'tenantFilter') && (
                  <>
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="p4">BRANCH</Typography>
                      <RequiredMark />
                    </Grid>
                    <Grid item sx={{ mb: 3 }}>
                      <Select
                        fullWidth
                        size="large"
                        soak="light"
                        value={tenant}
                        variant="filled"
                        disabled={isFetchingUser}
                        onChange={(event) => {
                          setTenant(event.target.value);
                        }}
                      >
                        {(tenants || []).map((option) => (
                          <MenuItem value={option.id} key={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
                {/* Doctor */}
                {isAllowed('newConsultation', 'doctor') &&
                  utils.isDoctorRequired(status) && (
                    <>
                      <Grid item sx={{ mb: 1 }}>
                        <Typography variant="p4">DOCTOR</Typography>
                        {utils.isDoctorRequired(status) && <RequiredMark />}
                      </Grid>
                      <Grid item sx={{ mb: 3 }}>
                        <Select
                          fullWidth
                          size="large"
                          soak="light"
                          value={doctor}
                          variant="filled"
                          disabled={isFetchingUser}
                          placeholder="Select a doctor..."
                          onChange={(event) => {
                            setDoctor(event.target.value);
                            setBookedSlots([]);
                          }}
                          renderValue={(value) =>
                            value ? userGetters.getFullName(value) : value
                          }
                        >
                          {doctors.map((item) => (
                            <MenuItem
                              value={item}
                              key={userGetters.getFullName(item)}
                            >
                              {userGetters.getFullName(item)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </>
                  )}
                {/* ATTENDEES */}
                {isAllowed('newConsultation', 'attendee') && (
                  <Grid>
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="p4">ATTENDEES</Typography>
                    </Grid>
                    <Grid item sx={{ mb: 3 }}>
                      <Select
                        fullWidth
                        size="large"
                        soak="light"
                        value={attendee}
                        variant="filled"
                        disabled={isFetchingUser}
                        placeholder="Select attendee..."
                        onChange={(event) => {
                          setAttendee(event.target.value);
                        }}
                        renderValue={(value) =>
                          CONSULTATION_ATTENDEE[value]?.label || value
                        }
                      >
                        {keys(CONSULTATION_ATTENDEE).map((item) => (
                          <MenuItem value={item} key={item}>
                            {CONSULTATION_ATTENDEE[item]?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                )}
                {/* Consultation time */}
                {/* TODO: use permissions */}
                <Conditionally>
                  <If
                    condition={
                      (!isClinicManager &&
                        status === STATUSES.PENDING_APPROVAL.key) ||
                      status === STATUSES.CONFIRMED.key ||
                      status === STATUSES.RESERVED.key
                    }
                  >
                    <FormField
                      label="CONSULTATION TIME"
                      labelWidth={170}
                      field={
                        <BookingAvailability
                          doctor={doctor}
                          value={bookedSlots}
                          endTime={getTime(endTime)}
                          startTime={getTime(startTime)}
                          onConfirm={(slots, selectedDoctor) => {
                            setBookedSlots(slots);
                            setDoctor(selectedDoctor);
                          }}
                        />
                      }
                    />
                  </If>
                  <Else>
                    <DateTimeRangePicker
                      endTime={endTime}
                      title="CONSULTATION"
                      startTime={startTime}
                      date={consultationDate}
                      disabled={isFetchingUser}
                      onEndTimeChange={(newValue) => {
                        const time = set(newValue, {
                          seconds: 0,
                          milliseconds: 0,
                        });

                        setEndTime(time);
                      }}
                      onDateChange={setConsultationDate}
                      onStartTimeChange={(newValue) => {
                        const time = set(newValue, {
                          seconds: 0,
                          milliseconds: 0,
                        });

                        setStartTime(time);
                        setEndTime(add(time, { minutes: 30 }));
                      }}
                      config={{
                        date: {
                          required: true,
                          showHelp: true,
                        },
                        startTime: {
                          required: true,
                          showHelp: true,
                        },
                        endTime: {
                          required: true,
                          showHelp: true,
                        },
                      }}
                    />
                    <Grid
                      item
                      container
                      flexWrap="nowrap"
                      alignItems="center"
                      sx={{
                        my: 1,
                      }}
                    >
                      <Grid
                        item
                        column
                        container
                        sx={{ width: '100%', gap: '10px' }}
                      >
                        <Typography variant="p4">
                          {getDefaultCountryCodeLong()} START TIME
                        </Typography>
                        <Typography variant="l5">
                          {startTime &&
                            formatInTimeZone(
                              startTime,
                              getDefaultTimeZone(),
                              timeFormat
                            )}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        column
                        container
                        sx={{ width: '100%', gap: '10px' }}
                      >
                        <Typography variant="p4">
                          {getDefaultCountryCodeLong()} END TIME
                        </Typography>
                        <Typography variant="l5">
                          {endTime &&
                            formatInTimeZone(
                              endTime,
                              getDefaultTimeZone(),
                              timeFormat
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Else>
                </Conditionally>

                {/* Documents */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">DOCUMENTS</Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <FileInput
                    multiple
                    files={documents}
                    onFilesUploaded={onFilesUploaded}
                    loading={isFetchingUser && { count: 0 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CreateUserModal
        key={createUserModalKey}
        open={isUserCreationModalOpen}
        onClose={() => setUserCreationModalOpen(false)}
      />
    </>
  );
};

const NewConsultation = ({
  user,
  userId,
  doctors,
  tenants,
  embedded,
  fetchUser,
  clearUser,
  embedProps,
  isSubmitting,
  selectedTenant,
  isFetchingUser,
  consultationTypes,
  referenceDocuments,
  createConsultation,
}) => {
  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);
  const { isClinicManager } = useSelector(authSelectors.getRole);

  const [status, setStatus] = useState(
    isClinicManager ? STATUSES.PENDING_APPROVAL.key : null
  );
  const [doctor, setDoctor] = useState(null);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [startTime, setStartTime] = useState(new Date());
  const [statusSelectColor, setStatusSelectColor] = useState();
  const [consultationType, setConsultationType] = useState(null);
  const [newUploadedDocuments, setNewUploadedDocuments] = useState([]);
  const [consultationDate, setConsultationDate] = useState(new Date());
  const [tenant, setTenant] = useState(selectedTenant || defaultTenant);
  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [endTime, setEndTime] = useState(add(new Date(), { minutes: 30 }));
  const [attendee, setAttendee] = useState(CONSULTATION_ATTENDEE.PERSONAL.key);

  useEffect(() => {
    clearUser();
  }, [clearUser]);

  useEffect(() => {
    if (userId) {
      fetchUser(userId);
    }
  }, [userId, fetchUser]);

  useEffect(() => {
    if (status) {
      setStatusSelectColor(STATUSES[status].color);
    }
  }, [status]);

  useEffect(() => {
    if (
      !isEmpty(user) &&
      status &&
      endTime &&
      startTime &&
      consultationDate &&
      consultationType
    ) {
      if (!doctor && utils.isDoctorRequired(status)) {
        setSubmitButtonEnabled(false);
      } else {
        setSubmitButtonEnabled(true);
      }
    } else {
      setSubmitButtonEnabled(false);
    }
  }, [
    user,
    status,
    doctor,
    endTime,
    startTime,
    consultationType,
    consultationDate,
  ]);

  const isManualSchedule = useMemo(() => {
    return (
      !doctor ||
      isClinicManager ||
      (status !== STATUSES.CONFIRMED.key &&
        status !== STATUSES.RESERVED.key &&
        status !== STATUSES.PENDING_APPROVAL.key)
    );
  }, [doctor, isClinicManager, status]);

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      !isSubmitButtonEnabled || (!isManualSchedule && isEmpty(bookedSlots))
    );
  }, [isSubmitButtonEnabled, isManualSchedule, bookedSlots]);

  const onSubmit = () => {
    const date = getDate(consultationDate);
    const year = getYear(consultationDate);
    const month = getMonth(consultationDate);

    const fullStartTime = set(startTime, { year, month, date });
    let fullEndTime = set(endTime, { year, month, date });
    if (isMidnight(fullEndTime)) {
      fullEndTime = add(fullEndTime, { days: 1 });
    }
    setStartTime(fullStartTime);
    setEndTime(fullEndTime);

    const payload = {
      [FIELDS.STATUS.name]: status,
      [FIELDS.DOCUMENTS.name]: newUploadedDocuments, // Raw files. Will be uploaded in saga
      [FIELDS.REFERENCE_DOCUMENTS.name]: referenceDocuments, // Files already uploaded and we have their ids
      [FIELDS.USER_ID.name]: userGetters.getId(user),
      [FIELDS.END_TIME.name]:
        bookedSlots.last()?.endTime || fullEndTime.toISOString(),
      [FIELDS.DOCTOR_ID.name]: userGetters.getId(doctor),
      [FIELDS.START_TIME.name]:
        bookedSlots[0]?.startTime || fullStartTime.toISOString(),
      [FIELDS.TENANT_KEY.name]: tenant,
      [FIELDS.ATTENDEE.name]: attendee,
      [FIELDS.CONSULTATION_TYPE_ID.name]: consultationType.id,
      [FIELDS.CHANNEL.name]: (() => {
        if (embedded) {
          return CONSULTATION_CHANNELS.LAB_TEST_FOLLOWUP.key;
        }
        if (isClinicManager) {
          return CONSULTATION_CHANNELS.PARTNER_CLINIC.key;
        }
        return CONSULTATION_CHANNELS.SUCCUSS_TEAM.key;
      })(),
    };

    createConsultation(payload);
  };

  const SubmitButton = () => (
    <Button
      fullWidth={{
        xs: true,
        sm: false,
      }}
      variant="filled"
      onClick={onSubmit}
      spinning={isSubmitting}
      disabled={isSubmitButtonDisabled}
    >
      Create consultation
    </Button>
  );

  if (embedded) {
    return (
      <>
        <DialogTitle
          id="lab-consultation-follow-up-dialog-title"
          onClose={embedProps?.onClose}
          sx={{ py: 2, px: 3 }}
        >
          New Consultation
        </DialogTitle>
        <DialogContent>
          <Content
            user={user}
            status={status}
            tenant={tenant}
            doctor={doctor}
            doctors={doctors}
            endTime={endTime}
            tenants={tenants}
            embedded={embedded}
            attendee={attendee}
            setDoctor={setDoctor}
            fetchUser={fetchUser}
            clearUser={clearUser}
            setStatus={setStatus}
            setTenant={setTenant}
            startTime={startTime}
            setEndTime={setEndTime}
            setAttendee={setAttendee}
            bookedSlots={bookedSlots}
            setStartTime={setStartTime}
            isFetchingUser={isFetchingUser}
            setBookedSlots={setBookedSlots}
            consultationDate={consultationDate}
            statusSelectColor={statusSelectColor}
            referenceDocuments={referenceDocuments}
            setConsultationDate={setConsultationDate}
            newUploadedDocuments={newUploadedDocuments}
            setNewUploadedDocuments={setNewUploadedDocuments}
            consultationType={consultationType}
            setConsultationType={setConsultationType}
            consultationTypes={consultationTypes}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3, pt: 0 }}>
          <SubmitButton />
        </DialogActions>
      </>
    );
  }

  return (
    <>
      <PageTitle title="New Consultation" action={<SubmitButton />} />
      <Content
        user={user}
        status={status}
        tenant={tenant}
        doctor={doctor}
        doctors={doctors}
        endTime={endTime}
        tenants={tenants}
        embedded={embedded}
        attendee={attendee}
        setDoctor={setDoctor}
        fetchUser={fetchUser}
        clearUser={clearUser}
        setStatus={setStatus}
        setTenant={setTenant}
        startTime={startTime}
        setEndTime={setEndTime}
        setAttendee={setAttendee}
        bookedSlots={bookedSlots}
        setStartTime={setStartTime}
        isFetchingUser={isFetchingUser}
        setBookedSlots={setBookedSlots}
        consultationDate={consultationDate}
        statusSelectColor={statusSelectColor}
        referenceDocuments={referenceDocuments}
        setConsultationDate={setConsultationDate}
        newUploadedDocuments={newUploadedDocuments}
        setNewUploadedDocuments={setNewUploadedDocuments}
        consultationType={consultationType}
        setConsultationType={setConsultationType}
        consultationTypes={consultationTypes}
      />
    </>
  );
};

export default NewConsultation;
