import * as types from './actionTypes';

export const createPrescription = (payload) => ({
  type: types.CREATE_PRESCRIPTION,
  payload,
});

export const createEPrescriptionDocument = (payload) => ({
  type: types.CREATE_E_PRESCRIPTION_DOCUMENT,
  payload,
});

export const createPatientPrescription = (payload, callbacks) => ({
  type: types.CREATE_PATIENT_PRESCRIPTION,
  payload,
  callbacks,
});

export const submitAllPrescriptions = (payload, consultationId) => ({
  type: types.SUBMIT_ALL_PRESCRIPTIONS,
  payload,
  consultationId,
});

export const createPatientPrescriptionDocument = (
  payload,
  userId,
  successCallback
) => ({
  type: types.CREATE_PATIENT_PRESCRIPTION_DOCUMENT,
  payload,
  userId,
  successCallback,
});

export const updatePatientPrescription = (
  userId,
  consultationId,
  payload,
  callbacks
) => ({
  type: types.UPDATE_PATIENT_PRESCRIPTION,
  userId,
  consultationId,
  payload,
  callbacks,
});

export const clearPatientsPrescriptions = () => ({
  type: types.CLEAR_PATIENTS_PRESCRIPTIONS,
});

export const ePrescriptionDocumentCreated = (document) => ({
  type: types.E_PRESCRIPTION_DOCUMENT_CREATED,
  document,
});

export const allPrescriptionsSubmited = (response) => ({
  type: types.ALL_PRESCRIPTIONS_SUBMITED,
  response,
});

export const patientPrescriptionDocumentCreated = (
  patientPrescriptionDocument,
  userId
) => ({
  type: types.PATIENT_PRESCRIPTION_DOCUMENT_CREATED,
  patientPrescriptionDocument,
  userId,
});

export const prescriptionCreated = (prescription) => ({
  type: types.PRESCRIPTION_CREATED,
  prescription,
});
export const patientPrescriptionCreated = (prescription, userId) => ({
  type: types.PATIENT_PRESCRIPTION_CREATED,
  prescription,
  userId,
});

export const patientPrescriptionUpdated = (prescription, userId) => ({
  type: types.PATIENT_PRESCRIPTION_UPDATED,
  prescription,
  userId,
});
export const setSubmittingPrescription = (isSubmitting) => ({
  type: types.SET_SUBMITTING_PRESCRIPTION,
  isSubmitting,
});

export const setCreatingPatientPrescriptionDocument = (
  isCreatingPatientPrescriptionDocument
) => ({
  type: types.SET_SUBMITTING_PRESCRIPTION,
  isCreatingPatientPrescriptionDocument,
});

export const setSubmittingAllPrescriptions = (
  isSubmittingAllPrescriptions
) => ({
  type: types.SET_SUBMITTING_ALL_PRESCRIPTIONS,
  isSubmittingAllPrescriptions,
});

export const setSubmittingPatientPrescription = (isSubmitting) => ({
  type: types.SET_SUBMITTING_PATIENT_PRESCRIPTION,
  isSubmitting,
});

export const setUpdatingPatientPrescription = (isUpdating) => ({
  type: types.SET_UPDATING_PATIENT_PRESCRIPTION,
  isUpdating,
});

export const setFetchingPatientPrescription = (
  isFetchingPatientPrescription
) => ({
  type: types.SET_FETCHING_PATIENT_PRESCRIPTION,
  isFetchingPatientPrescription,
});

export const patientPrescriptionReceived = (prescription, userId) => ({
  type: types.PATIENT_PRESCRIPTION_RECEIVED,
  prescription,
  userId,
});
export const fetchPatientPrescription = (
  userId,
  consultationId,
  callbacks
) => ({
  type: types.FETCH_PATIENT_PRESCRIPTION,
  userId,
  consultationId,
  callbacks,
});
