import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { Form } from 'design-system/form';
import { userGetters } from 'domain/user';
import { doctorGetters } from 'domain/doctor';
import { dateFormat } from 'constants/dateTime';
import { outcomeConstants } from 'domain/outcome';
import { consultationGetters } from 'domain/consultation';
import { clinicGetters, clinicConstants } from 'domain/clinic';
import {
  MANAGEMENT_AND_TREATMENT_PLAN_FIELDS,
  REVIEW_OF_SYSTEMS_FIELDS,
} from 'domain/doctorNotes/constants';
import {
  If,
  Tab,
  Tabs,
  Grid,
  Else,
  Popup,
  Dialog,
  Button,
  Message,
  TabPanel,
  Typography,
  PageSpinner,
  DialogTitle,
  DialogContent,
  DialogActions,
  Conditionally,
} from 'design-system';

import * as utils from './utils';
import ComplaintForm from '../ComplaintForm';
import DiagnosisForm from '../DiagnosisForm';
import LabRequestForm from '../LabRequestForm';
import PatientInfoForm from '../PatientInfoForm';
import PrescriptionForm from '../PrescriptionForm';
import ReviewOfSystemsForm from '../ReviewOfSystemsForm';
import DoctorPrivateNotesForm from '../DoctorPrivateNotesForm';
import { SECTIONS, PATIENT_INFORMATION } from '../../constants';
import ConsultationOutcomesForm from '../ConsultationOutcomesForm';
import PatientAndFamilyHistoryForm from '../PatientAndFamilyHistoryForm';
import ManagementAndTreatmentPlanForm from '../ManagementAndTreatmentPlanForm';
import OutcomeManagementDialogDocumentsPreview from '../OutcomeManagementDialogDocumentsPreview';

const { OUTCOME_OPTIONS, DOCTOR_PRIVATE_NOTES_OPTIONS } = outcomeConstants;

const OutcomeManagementDialog = ({
  // Props
  open,
  doctor,
  onClose,
  consultation,
  userHasWlpSubscription,

  // State
  owner,
  clinics = [],
  patientsUserInfoById,
  isCreatingLabRequestDocument,
  isSubmittingAllPrescriptions,
  isSubmittingELabTestRequests,
  getPatientAssessmentSheetById,
  patientsHealthProfilesByUserId,
  patientsLabRequestDocumentsById,
  getPatientPrescriptionDocumentById,

  // Actions
  attachPatientDocuments,
  submitAllPrescriptions,
  submitELabTestRequests,
  clearDoctorNotesAndOutcomes,
  fetchDoctorNotesAndOutcomes,
  createPatientAssessmentSheet,
  createPatientLabRequestDocument,
  createPatientPrescriptionDocument,
  isSubmittingDoctorNotes,
  isCreatingPatientPrescriptionDocument,
}) => {
  const formRef = useRef(null);

  /**
   * Dialog-wide state
   */
  const [tab, setTab] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPreviewMode, setPreviewMode] = useState(false);
  const [expandedSection, setExpandedSection] = useState();
  const [onPopupConfirmed, setOnPopupConfirmed] = useState();
  const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);
  // is hidden by no show per tab
  const [isHiddenByNoShow, setHiddenByNoShow] = useState({});
  // Confirmed sections per tab
  const [confirmedSections, setConfirmedSections] = useState({});

  const patients = useMemo(() => {
    return consultationGetters.getPatients(consultation);
  }, [consultation]);

  const getClinicById = (id) => {
    return clinics?.find((clinic) => clinicGetters.getId(clinic) === id);
  };

  const defaultSelectedDoctorClinic = useMemo(() => {
    const doctorClinicsIds = doctorGetters.getClinics(doctor);
    const doctorDefaultClinicId = doctorGetters.getDefaultClinic(doctor);
    const almaHealthClinic = clinics?.find(
      (clinic) =>
        clinicGetters.getKey(clinic) ===
        clinicConstants.CLINICS.ALMA_HEALTH_CARE.key
    );
    const firstDefaultClinic = clinics?.[0];

    return (
      // The selected default clinic from doctor profile
      getClinicById(doctorDefaultClinicId) ||
      // This shouldn't happen, but in case it happens, take the first clinic (from doctor profile)
      getClinicById(doctorClinicsIds?.[0]) ||
      // Get ALMA_HEALTH_CARE clinic
      almaHealthClinic ||
      // Get first clinic in the general clinics list
      firstDefaultClinic
    );
  }, [doctor, clinics]);

  /**
   * Checks whether all previous tabs before the last tab are confirmed,
   *  by either being filled with assessment sheet or marked as no show.
   * Used to enable/disable the Submit Button
   */
  const areAllPreviousPatientsConfirmedOrNoShow = useMemo(() => {
    return patients.every((patient, patientIdx) => {
      if (patientIdx === tab) {
        // Knowing that tab is the last tab,
        // if the current patient in the iteration is the last patient, return true
        return true;
      }

      // Otherwise, check if the tab is confirmed or marked as no show
      return (
        utils.isPatientTabConfirmed(patient) || isHiddenByNoShow[patientIdx]
      );
    });
  }, [tab, patients, isHiddenByNoShow]);

  /**
   * From the passed patient doctor notes, outcomes, prescription and lab request figure out which sections are confirmed
   */
  const initializeConfirmedSectionsFromDoctorNotes = useCallback(
    (
      patientIdx,
      patientDoctorNotes,
      consultationOutcomes,
      patientPrescription,
      patientLabRequest
    ) => {
      const patientConfirmedSections = values(SECTIONS).reduce(
        (confirmedSectionsSoFar, section) => {
          if (section.key === SECTIONS.CONSULTATION_OUTCOMES.key) {
            return {
              ...confirmedSectionsSoFar,
              [section.key]: !isEmpty(consultationOutcomes),
            };
          }

          if (section.key === SECTIONS.PRESCRIPTION.key) {
            return {
              ...confirmedSectionsSoFar,
              [section.key]: !isEmpty(patientPrescription),
            };
          }

          if (section.key === SECTIONS.LAB_REQUEST.key) {
            return {
              ...confirmedSectionsSoFar,
              [section.key]: !isEmpty(patientLabRequest),
            };
          }

          if (!isEmpty(patientDoctorNotes[section.formField])) {
            return {
              ...confirmedSectionsSoFar,
              [section.key]: true,
            };
          }

          return confirmedSectionsSoFar;
        },
        {}
      );

      setConfirmedSections((previousConfirmedSections) => ({
        ...previousConfirmedSections,
        [patientIdx]: patientConfirmedSections,
      }));
    },
    []
  );

  /**
   * Check the sections in the order they are presented in,
   * and if their corresponding form data is not present, show them expanded.
   * The first section with no data should be expanded.
   */
  const getFirstExpandedSection = (
    patientDoctorNotes,
    consultationOutcomes,
    patientPrescription,
    patientLabRequest
  ) => {
    // All statements should be in order
    const sections = [
      SECTIONS.DOCTOR_PRIVATE_NOTES,
      SECTIONS.COMPLAINT,
      SECTIONS.REVIEW_OF_SYSTEMS,
      SECTIONS.PATIENT_AND_FAMILY_HISTORY,
      SECTIONS.DIAGNOSIS,
    ];

    // eslint-disable-next-line no-restricted-syntax
    for (const section of sections) {
      if (isEmpty(patientDoctorNotes?.[section.formField])) {
        return section.key;
      }
    }

    if (isEmpty(consultationOutcomes)) {
      return SECTIONS.CONSULTATION_OUTCOMES.key;
    }

    if (
      consultationOutcomes.includes(OUTCOME_OPTIONS.Prescription.key) &&
      isEmpty(patientPrescription)
    ) {
      return SECTIONS.PRESCRIPTION.key;
    }

    if (
      consultationOutcomes.includes(OUTCOME_OPTIONS.LabTestRequest.key) &&
      isEmpty(patientLabRequest)
    ) {
      return SECTIONS.LAB_REQUEST.key;
    }

    if (
      isEmpty(
        patientDoctorNotes?.[SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.formField]
      )
    ) {
      return SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.key;
    }
  };

  /**
   * Populate the visible form of patient of index patientIdx, with user profile, health profile and doctor notes
   */
  const initializeForm = useCallback(
    (
      patientIdx,
      patientDoctorNotes,
      consultationOutcomes,
      patientPrescription,
      patientLabRequest
    ) => {
      const patient = patients[patientIdx];
      const patientUserId = patient.userId;
      const formMethods = formRef?.current?.getMethods?.();

      const patientUserInfo = patientsUserInfoById[patientUserId];
      const patientHealthInfo = patientsHealthProfilesByUserId[patientUserId];

      let selectedDoctorPrivateNotes =
        patientDoctorNotes?.[SECTIONS.DOCTOR_PRIVATE_NOTES.formField];
      // Check if the Doctor Private Notes are mapped to NoShow
      const mappedOutcome =
        DOCTOR_PRIVATE_NOTES_OPTIONS[selectedDoctorPrivateNotes?.option]
          ?.mappedOutcome;

      const isConsultationOutcomeNoShow = consultationOutcomes.includes(
        OUTCOME_OPTIONS.NoShow.key
      );
      const isDoctorPrivateNotesMappedToNoShow =
        mappedOutcome === OUTCOME_OPTIONS.NoShow.key;

      if (isConsultationOutcomeNoShow !== isDoctorPrivateNotesMappedToNoShow) {
        // In case the no show status has changed from outside, then there will be mismatch
        //  doctor private notes has to be filled in again
        // It's null (can be {} or falsy value),
        //   because we want initializeConfirmedSectionsFromDoctorNotes to initialize it as unconfirmed section
        selectedDoctorPrivateNotes = null;

        // Should we handle it another way?
        // Should we actually update doctorPrivateNotes to null when no show is selected on the consultation level?
      }

      formMethods?.setValue(`patient.${patientIdx}`, {
        patientInfo: {
          fullName: userGetters.getFullName(patientUserInfo),
          dob: patientHealthInfo?.dob ? new Date(patientHealthInfo.dob) : '',
          weight: patientHealthInfo?.weight || '',
          height: patientHealthInfo?.height || '',
          diseases: patientHealthInfo?.diseases || [],
          allergies: patientHealthInfo?.allergies || [],
        },
        [SECTIONS.DOCTOR_PRIVATE_NOTES.formField]: selectedDoctorPrivateNotes,
        [SECTIONS.COMPLAINT.formField]:
          patientDoctorNotes?.[SECTIONS.COMPLAINT.formField],
        [SECTIONS.REVIEW_OF_SYSTEMS.formField]: (() => {
          const patientExaminationsFormData = {};
          const patientExaminations =
            patientDoctorNotes?.[SECTIONS.REVIEW_OF_SYSTEMS.formField]?.[
              REVIEW_OF_SYSTEMS_FIELDS.PATIENT_EXAMINATIONS.key
            ];
          patientExaminations?.forEach((examination) => {
            patientExaminationsFormData[examination.examination] = {
              status: examination.status,
              remarks: examination.remarks,
              id: examination.id,
              examination: examination.examination,
            };
          });
          return patientExaminationsFormData;
        })(),
        [SECTIONS.PATIENT_AND_FAMILY_HISTORY.formField]: {
          ...patientDoctorNotes?.[
            SECTIONS.PATIENT_AND_FAMILY_HISTORY.formField
          ],
          patientMedicalHistory: patientHealthInfo?.patientMedicalHistory,
          familyHistory: patientHealthInfo?.familyHistory,
        },
        [SECTIONS.DIAGNOSIS.formField]:
          patientDoctorNotes?.[SECTIONS.DIAGNOSIS.formField],
        [SECTIONS.CONSULTATION_OUTCOMES.formField]: {
          outcomes: consultationOutcomes,
        },
        [SECTIONS.PRESCRIPTION.formField]: patientPrescription,
        [SECTIONS.LAB_REQUEST.formField]: patientLabRequest,
        [SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.formField]:
          patientDoctorNotes?.[
            SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.formField
          ],
      });

      setHiddenByNoShow((previousHiddenByNoShow) => ({
        ...previousHiddenByNoShow,
        [patientIdx]:
          isDoctorPrivateNotesMappedToNoShow && isConsultationOutcomeNoShow, // && with both conditions, because they have to be in sync
      }));

      const firstExpandedSection = getFirstExpandedSection(
        patientDoctorNotes,
        consultationOutcomes,
        patientPrescription,
        patientLabRequest
      );

      setExpandedSection(firstExpandedSection);

      initializeConfirmedSectionsFromDoctorNotes(
        patientIdx,
        patientDoctorNotes,
        consultationOutcomes,
        patientPrescription,
        patientLabRequest
      );
    },
    [
      patients,
      formRef?.current,
      patientsUserInfoById,
      patientsHealthProfilesByUserId,
      initializeConfirmedSectionsFromDoctorNotes,
    ]
  );

  const resetView = useCallback(() => {
    setExpandedSection();
    setPreviewMode(false);
  }, []);

  const reset = useCallback(() => {
    setTab(0);
    resetView();
    clearDoctorNotesAndOutcomes();
    setHiddenByNoShow({});
  }, [resetView, clearDoctorNotesAndOutcomes]);

  /**
   * Directly handles the form, by setting the loader, doing checks, fetching and initializing
   */
  const populatePatientTab = useCallback(
    (patientIdx) => {
      // If patients are available
      if (!isLoading && !isEmpty(patients)) {
        const patient = patients[patientIdx];
        const consultationOutcomes = (patient?.output || []).map(
          (outcome) => outcome.referenceType
        );

        setLoading(true);
        fetchDoctorNotesAndOutcomes(
          patient.userId,
          consultationGetters.getId(consultation),
          {
            success: ([
              doctorNotesResponse,
              prescriptionResponse,
              labRequestResponse,
            ]) => {
              initializeForm(
                patientIdx,
                doctorNotesResponse,
                consultationOutcomes,
                prescriptionResponse,
                labRequestResponse
              );
              setLoading(false);
            },
          }
        );
      }
    },
    [
      patients,
      isLoading,
      consultation,
      initializeForm,
      fetchDoctorNotesAndOutcomes,
    ]
  );

  const handleSettingTab = useCallback(
    (tabIndex) => {
      resetView();
      setTab(tabIndex);
      populatePatientTab(tabIndex);
    },
    [resetView, populatePatientTab]
  );

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
    reset();
  }, [onClose, reset]);

  // Check if there's an expanded section, then show pop confirm
  const confirmOnClose = useCallback(() => {
    if (expandedSection) {
      setConfirmPopupOpen(true);
      setOnPopupConfirmed(() => onClosed); // As a function () => onClosed
    } else {
      onClosed();
    }
  }, [onClosed, expandedSection]);

  // On tab change, check if there's an expanded section then show pop confirm
  const onTabChanged = useCallback(
    (_event, newTab) => {
      if (expandedSection) {
        setConfirmPopupOpen(true);
        setOnPopupConfirmed(() => () => handleSettingTab(newTab)); // As a function that returns a function
      } else {
        handleSettingTab(newTab);
      }
    },
    [expandedSection, handleSettingTab]
  );

  const getNextSection = useCallback(() => {
    const patientIdx = tab;
    const currentSection = SECTIONS[expandedSection];
    let currentOrder = currentSection?.order || 0;
    const consultationOutcomes =
      formRef?.current
        ?.getMethods?.()
        ?.watch(
          `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}.outcomes`
        ) || [];
    const hasPrescriptionOutcome = consultationOutcomes.includes(
      OUTCOME_OPTIONS.Prescription.key
    );
    const hasLabRequestOutcome = consultationOutcomes.includes(
      OUTCOME_OPTIONS.LabTestRequest.key
    );

    const nextSection = values(SECTIONS).find((section) => {
      if (currentOrder > section.order) {
        // No need to go through the checks below until we reach the current order
        return false;
      }

      if (
        (section.key === SECTIONS.PRESCRIPTION.key &&
          !hasPrescriptionOutcome) ||
        (section.key === SECTIONS.LAB_REQUEST.key && !hasLabRequestOutcome)
      ) {
        // If there is no prescription outcome or no lab request outcome,
        // do not return their corresponding sections.
        // Increase the currentOrder so that we get the one after according to the check below
        currentOrder += 1;
        return false;
      }

      return section.order === currentOrder + 1;
    });

    return nextSection?.key;
  }, [formRef?.current, tab, expandedSection]);

  /**
   * Takes the specific form data and reflects it onto the bigger form
   */
  const updateGrandFormData = useCallback(
    (sectionFormField, sectionFormData) => {
      const patientIdx = tab;

      formRef?.current
        ?.getMethods?.()
        ?.setValue(
          `patient.${patientIdx}.${sectionFormField}`,
          sectionFormData
        );
    },
    [tab]
  );

  /**
   * All sections share this logic on submit:
   * 1. Update the bigger form with their data
   * 2. Mark their corresponding sections as confirmed
   * 3. expand the next section
   */
  const onSectionSubmitCommonHandler = useCallback(
    (section, sectionFormData) => {
      updateGrandFormData(section.formField, sectionFormData);
      setConfirmedSections((previousConfirmedSections) => ({
        ...previousConfirmedSections,
        [tab]: {
          ...(previousConfirmedSections[tab] || {}),
          [section.key]: true,
        },
      }));
      setExpandedSection(getNextSection());
    },
    [tab, getNextSection, updateGrandFormData]
  );

  const onDoctorPrivateNotesSubmit = useCallback(
    (doctorPrivateNotesFormData) => {
      const { mappedOutcome } =
        DOCTOR_PRIVATE_NOTES_OPTIONS[doctorPrivateNotesFormData.option];
      const isMappedToNoShow = mappedOutcome === OUTCOME_OPTIONS.NoShow.key;

      onSectionSubmitCommonHandler(
        SECTIONS.DOCTOR_PRIVATE_NOTES,
        doctorPrivateNotesFormData
      );

      // On NoShow, hide all other sections
      setHiddenByNoShow((previousHiddenByNoShow) => ({
        ...previousHiddenByNoShow,
        [tab]: isMappedToNoShow,
      }));

      const formMethods = formRef?.current?.getMethods?.();
      const consultationOutcomes =
        formMethods?.getValues?.(
          `patient.${tab}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`
        )?.outcomes || [];

      if (isMappedToNoShow) {
        // If NoShow, then remove all other outcomes
        formMethods?.setValue(
          `patient.${tab}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`,
          {
            outcomes: [],
          }
        );
      } else {
        // Otherwise, make sure NoShow is filtered out
        const updatedConsultationOutcomes = consultationOutcomes.filter(
          (outcomeKey) => {
            return outcomeKey !== OUTCOME_OPTIONS.NoShow.key;
          }
        );

        formMethods?.setValue(
          `patient.${tab}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`,
          {
            outcomes: updatedConsultationOutcomes,
          }
        );

        // Make sure consultation outcomes section is set as confirmed/unconfirmed
        setConfirmedSections((previousConfirmedSections) => ({
          ...previousConfirmedSections,
          [tab]: {
            ...previousConfirmedSections[tab],
            [SECTIONS.CONSULTATION_OUTCOMES.key]: !isEmpty(
              updatedConsultationOutcomes
            ),
          },
        }));
      }
    },
    [tab, onSectionSubmitCommonHandler]
  );

  const onComplaintSubmit = useCallback(
    (complaintFormData) => {
      onSectionSubmitCommonHandler(SECTIONS.COMPLAINT, complaintFormData);
    },
    [onSectionSubmitCommonHandler]
  );

  const onReviewOfSystemsSubmit = useCallback(
    (reviewOfSystemsFormData) => {
      onSectionSubmitCommonHandler(
        SECTIONS.REVIEW_OF_SYSTEMS,
        reviewOfSystemsFormData
      );
    },
    [onSectionSubmitCommonHandler]
  );

  const onPatientAndFamilyHistorySubmit = useCallback(
    (patientAndFamilyHistoryFormData) => {
      onSectionSubmitCommonHandler(
        SECTIONS.PATIENT_AND_FAMILY_HISTORY,
        patientAndFamilyHistoryFormData
      );
    },
    [onSectionSubmitCommonHandler]
  );

  const onDiagnosisSubmit = useCallback(
    (diagnosisFormData) => {
      onSectionSubmitCommonHandler(SECTIONS.DIAGNOSIS, diagnosisFormData);
    },
    [onSectionSubmitCommonHandler]
  );

  const onConsultationOutcomesSubmit = useCallback(
    (consultationOutcomesFormData) => {
      onSectionSubmitCommonHandler(
        SECTIONS.CONSULTATION_OUTCOMES,
        consultationOutcomesFormData
      );
    },
    [onSectionSubmitCommonHandler]
  );

  const onLabTestsSubmit = useCallback(
    (labRequestFormData) => {
      onSectionSubmitCommonHandler(SECTIONS.LAB_REQUEST, labRequestFormData);
    },
    [onSectionSubmitCommonHandler]
  );

  const onPrescriptionSubmit = useCallback(
    (prescriptionFormData) => {
      onSectionSubmitCommonHandler(SECTIONS.PRESCRIPTION, prescriptionFormData);
    },
    [onSectionSubmitCommonHandler]
  );

  const onManagementAndTreatmentPlanSubmit = useCallback(
    (managementAndTreatmentPlanFormData) => {
      onSectionSubmitCommonHandler(
        SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN,
        managementAndTreatmentPlanFormData
      );

      if (
        managementAndTreatmentPlanFormData[
          MANAGEMENT_AND_TREATMENT_PLAN_FIELDS.FOLLOW_UP.key
        ]
      ) {
        // If follow up is added, then add schedule for follow up outcome
        const formMethods = formRef?.current?.getMethods?.();
        const consultationOutcomes =
          formMethods?.getValues(
            `patient.${tab}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`
          )?.outcomes || [];

        if (
          !consultationOutcomes.includes(
            OUTCOME_OPTIONS.ScheduleForFollowUp.key
          )
        ) {
          formMethods?.setValue(
            `patient.${tab}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`,
            {
              outcomes: [
                ...consultationOutcomes,
                OUTCOME_OPTIONS.ScheduleForFollowUp.key,
              ],
            }
          );
        }
      }
    },
    [tab, formRef, onSectionSubmitCommonHandler]
  );

  const prepareLabRequestDocumentPayload = useCallback(
    (patient, formData) => {
      const patientUserId = patient.userId;
      const patientUserProfile = patientsUserInfoById[patientUserId];
      const patientHealthProfile =
        patientsHealthProfilesByUserId[patientUserId];
      const patientFormData = formData.patient[tab];

      const labRequestDocumentPayload = utils.prepareLabRequestDocumentPayload({
        owner,
        doctor,
        clinic: clinics[0],
        patient,
        consultation,
        patientFormData,
        patientUserProfile,
        patientHealthProfile,
      });

      return labRequestDocumentPayload;
    },
    [
      tab,
      owner,
      doctor,
      consultation,
      patientsUserInfoById,
      patientsHealthProfilesByUserId,
    ]
  );

  const preparePrescriptionDocumentPayload = useCallback(
    (patient, formData) => {
      const patientUserId = patient.userId;
      const patientUserProfile = patientsUserInfoById[patientUserId];
      const patientHealthProfile =
        patientsHealthProfilesByUserId[patientUserId];
      const patientFormData = formData.patient[tab];

      const prescriptionDocumentPayload =
        utils.preparePrescriptionDocumentPayload({
          owner,
          doctor,
          clinic: defaultSelectedDoctorClinic,
          patient,
          consultation,
          patientFormData,
          patientUserProfile,
          patientHealthProfile,
        });

      return prescriptionDocumentPayload;
    },
    [
      tab,
      owner,
      doctor,
      consultation,
      patientsUserInfoById,
      patientsHealthProfilesByUserId,
    ]
  );

  const prepareAssessmentSheetPayload = useCallback(
    (patient, formData) => {
      const patientUserId = patient.userId;
      const patientUserProfile = patientsUserInfoById[patientUserId];
      const patientHealthProfile =
        patientsHealthProfilesByUserId[patientUserId];
      const patientFormData = formData.patient[tab];

      const assessmentSheetPayload = utils.prepareAssessmentSheetPayload({
        owner,
        doctor,
        clinic: defaultSelectedDoctorClinic,
        patient,
        consultation,
        patientFormData,
        patientUserProfile,
        patientHealthProfile,
      });

      return assessmentSheetPayload;
    },
    [
      tab,
      owner,
      doctor,
      consultation,
      patientsUserInfoById,
      patientsHealthProfilesByUserId,
    ]
  );

  const onNextMemberClicked = useCallback(
    async (goToNextTab = true) => {
      const { userId } = patients[tab];
      const assessmentSheetDocument = getPatientAssessmentSheetById(userId);
      const consultationOutcomes =
        formRef?.current
          ?.getMethods?.()
          ?.getValues(
            `patient.${tab}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`
          )?.outcomes || [];
      let labRequestDocument;
      let prescriptionDocument;

      if (
        consultationOutcomes.includes(OUTCOME_OPTIONS.LabTestRequest.key) ||
        consultationOutcomes.includes(OUTCOME_OPTIONS.Prescription.key)
      ) {
        labRequestDocument = patientsLabRequestDocumentsById[userId];
        prescriptionDocument = getPatientPrescriptionDocumentById(userId);
      }

      setLoading(true);

      await attachPatientDocuments(
        userGetters.getId(owner),
        consultationGetters.getId(consultation),
        patients[tab],
        { labRequestDocument, prescriptionDocument, assessmentSheetDocument }
      );

      setLoading(false);

      if (goToNextTab) {
        handleSettingTab((tab + 1) % patients.length); // Circular index
      }
    },
    [
      tab,
      owner,
      formRef,
      patients,
      consultation,
      handleSettingTab,
      attachPatientDocuments,
      getPatientAssessmentSheetById,
      patientsLabRequestDocumentsById,
      getPatientPrescriptionDocumentById,
    ]
  );

  const submitELabTestRequestsHandler = useCallback(() => {
    submitELabTestRequests(consultationGetters.getId(consultation), {
      userHasWlpSubscription,
      ownerId: userGetters.getId(owner),
      doctorId: doctorGetters.getId(doctor),
      doctorName: doctorGetters.getEnglishName(doctor),
      channel: consultationGetters.getChannel(consultation),
    });
  }, [owner, doctor, consultation, userHasWlpSubscription]);

  const onSubmitOutcomesClicked = useCallback(async () => {
    const somePatientsHavePrescriptions = patients.some((patient) =>
      patient.output.some(
        (outcome) => outcome.referenceType === OUTCOME_OPTIONS.Prescription.key
      )
    );
    const somePatientsHaveLabTestRequests = patients.some((patient) =>
      patient.output.some(
        (outcome) =>
          outcome.referenceType === OUTCOME_OPTIONS.LabTestRequest.key
      )
    );

    await onNextMemberClicked(false);

    if (somePatientsHavePrescriptions) {
      submitAllPrescriptions(
        { orderId: null },
        consultationGetters.getId(consultation)
      );
    }

    if (somePatientsHaveLabTestRequests) {
      submitELabTestRequestsHandler();
    }

    onClosed();
  }, [
    onClosed,
    consultation,
    onNextMemberClicked,
    submitAllPrescriptions,
    submitELabTestRequestsHandler,
  ]);

  const onSaveOutcomeClicked = useCallback(
    (data) => {
      const consultationOutcomes =
        data?.patient?.[tab]?.[SECTIONS.CONSULTATION_OUTCOMES.formField]
          ?.outcomes || [];

      const patient = patients[tab];

      if (consultationOutcomes.includes(OUTCOME_OPTIONS.LabTestRequest.key)) {
        // If LabTestRequest outcome is selected, prepare and create lab request document
        const labRequestDocumentPayload = prepareLabRequestDocumentPayload(
          patient,
          data
        );

        createPatientLabRequestDocument(
          labRequestDocumentPayload,
          patient.userId
        );
      }

      if (consultationOutcomes.includes(OUTCOME_OPTIONS.Prescription.key)) {
        const prescriptionDocumentPayload = preparePrescriptionDocumentPayload(
          patient,
          data
        );
        createPatientPrescriptionDocument(
          prescriptionDocumentPayload,
          patient.userId
        );
      }

      const assessmentSheetPayload = prepareAssessmentSheetPayload(
        patient,
        data
      );
      createPatientAssessmentSheet(assessmentSheetPayload, patient.userId);

      setPreviewMode(true);
    },
    [
      tab,
      patients,
      createPatientLabRequestDocument,
      createPatientPrescriptionDocument,
      createPatientAssessmentSheet,
      prepareLabRequestDocumentPayload,
      preparePrescriptionDocumentPayload,
      prepareAssessmentSheetPayload,
    ]
  );

  const onSubmitted = useCallback(
    (data) => {
      onSaveOutcomeClicked(data);
    },
    [onSaveOutcomeClicked]
  );

  // Sync "isOpen" with "open"
  useEffect(() => {
    setOpen(open);
  }, [open]);

  // On first open
  useEffect(() => {
    if (open && !isOpen) {
      populatePatientTab(0 /* Initial tab */);
    }
  }, [open, isOpen]);

  return (
    <>
      <Dialog
        fullScreen
        scrollable
        open={isOpen}
        onClose={confirmOnClose}
        aria-labelledby="outcome-management-dialog-title"
      >
        <DialogTitle
          id="outcome-management-dialog-title"
          onClose={confirmOnClose}
        >
          Submit Outcomes
        </DialogTitle>
        <DialogContent dividers>
          {/* 
            Form Structire:
            Dialog level form + section level forms
          */}
          <Form
            ref={formRef}
            onSubmit={onSubmitted}
            onError={() => {
              Message.error(
                'Some fields have validation errors. Please review the form and add missing fields.'
              );
            }}
          >
            {({ control: patientControl }) => (
              <>
                <Tabs
                  value={tab}
                  tabVariant="border"
                  variant="scrollable"
                  scrollButtons="auto"
                  handleChange={onTabChanged}
                  sx={{
                    mb: 2,
                  }}
                >
                  {patients.map((patient, patientIdx) => (
                    <Tab
                      key={patient.id}
                      value={patientIdx}
                      tabVariant="border"
                      label={
                        <Grid
                          container
                          column
                          alignItems="flex-start"
                          sx={{
                            color: 'common.black',
                          }}
                        >
                          <Grid item>
                            <Typography
                              variant="h5"
                              sx={{
                                pb: 0,
                                width: '19rem',
                                textAlign: 'left',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {patient.fullName || 'N/A'}
                            </Typography>
                          </Grid>
                          {patientsHealthProfilesByUserId?.[patient?.userId]
                            ?.dob ? (
                            <Grid item>
                              <Typography variant="p4">
                                DOB:{' '}
                                {format(
                                  new Date(
                                    patientsHealthProfilesByUserId[
                                      patient.userId
                                    ].dob
                                  ),
                                  dateFormat
                                )}
                              </Typography>
                            </Grid>
                          ) : undefined}
                        </Grid>
                      }
                      iconPosition="end"
                      {
                        // If patient tab confirmed (Assessment sheet exists)
                        ...(utils.isPatientTabConfirmed(patients[patientIdx]) ||
                        // Or marked as no show
                        isHiddenByNoShow[patientIdx]
                          ? {
                              color: 'success',
                              soak: 'dark',
                              icon: <CheckCircleIcon />,
                            }
                          : {
                              icon: <RadioButtonUncheckedIcon />,
                            })
                      }
                    />
                  ))}
                </Tabs>
                {patients.map((patient, patientIdx) => (
                  <TabPanel
                    value={tab}
                    index={patientIdx}
                    id={`outcome-management-tabpanel-${patient.id}`}
                  >
                    {!isLoading && !isPreviewMode && (
                      <Grid container column rowSpacing={4}>
                        {/* Patient info */}
                        <Grid item>
                          <PatientInfoForm
                            owner={owner}
                            formRef={formRef}
                            control={patientControl}
                            patient={patients[patientIdx]}
                            formField={`patient.${patientIdx}.${PATIENT_INFORMATION.formField}`}
                            defaultValues={formRef?.current
                              ?.getMethods?.()
                              ?.getValues(
                                `patient.${patientIdx}.${PATIENT_INFORMATION.formField}`
                              )}
                          />
                        </Grid>

                        {/* Private Doctor Notes */}
                        <Grid item>
                          <DoctorPrivateNotesForm
                            owner={owner}
                            consultation={consultation}
                            patient={patients[patientIdx]}
                            userId={patients[patientIdx].userId}
                            defaultValues={formRef?.current
                              ?.getMethods?.()
                              ?.getValues(
                                `patient.${patientIdx}.${SECTIONS.DOCTOR_PRIVATE_NOTES.formField}`
                              )}
                            expanded={
                              expandedSection ===
                              SECTIONS.DOCTOR_PRIVATE_NOTES.key
                            }
                            confirmed={
                              confirmedSections[patientIdx]?.[
                                SECTIONS.DOCTOR_PRIVATE_NOTES.key
                              ]
                            }
                            onEdit={() => {
                              setExpandedSection(
                                SECTIONS.DOCTOR_PRIVATE_NOTES.key
                              );
                            }}
                            onSubmitted={(doctorPrivateNotesFormData) => {
                              onDoctorPrivateNotesSubmit(
                                doctorPrivateNotesFormData
                              );
                            }}
                          />
                        </Grid>

                        {/* Complaint */}
                        {!isHiddenByNoShow[patientIdx] && (
                          <Grid item>
                            <ComplaintForm
                              ownerId={userGetters.getId(owner)}
                              userId={patients[patientIdx].userId}
                              consultationId={consultationGetters.getId(
                                consultation
                              )}
                              defaultValues={formRef?.current
                                ?.getMethods?.()
                                ?.getValues(
                                  `patient.${patientIdx}.${SECTIONS.COMPLAINT.formField}`
                                )}
                              expanded={
                                expandedSection === SECTIONS.COMPLAINT.key
                              }
                              confirmed={
                                confirmedSections[patientIdx]?.[
                                  SECTIONS.COMPLAINT.key
                                ]
                              }
                              onEdit={() => {
                                setExpandedSection(SECTIONS.COMPLAINT.key);
                              }}
                              onSubmitted={(complaintFormData) => {
                                onComplaintSubmit(complaintFormData);
                              }}
                            />
                          </Grid>
                        )}

                        {/* Review of Systems */}
                        {!isHiddenByNoShow[patientIdx] && (
                          <Grid item>
                            <ReviewOfSystemsForm
                              ownerId={userGetters.getId(owner)}
                              userId={patients[patientIdx].userId}
                              consultationId={consultationGetters.getId(
                                consultation
                              )}
                              defaultValues={formRef?.current
                                ?.getMethods?.()
                                ?.getValues(
                                  `patient.${patientIdx}.${SECTIONS.REVIEW_OF_SYSTEMS.formField}`
                                )}
                              expanded={
                                expandedSection ===
                                SECTIONS.REVIEW_OF_SYSTEMS.key
                              }
                              confirmed={
                                confirmedSections[patientIdx]?.[
                                  SECTIONS.REVIEW_OF_SYSTEMS.key
                                ]
                              }
                              onEdit={() => {
                                setExpandedSection(
                                  SECTIONS.REVIEW_OF_SYSTEMS.key
                                );
                              }}
                              onSubmitted={(reviewOfSystemsFormData) => {
                                onReviewOfSystemsSubmit(
                                  reviewOfSystemsFormData
                                );
                              }}
                            />
                          </Grid>
                        )}
                        {/* Patient and Family History */}
                        {!isHiddenByNoShow[patientIdx] && (
                          <Grid item>
                            <PatientAndFamilyHistoryForm
                              ownerId={userGetters.getId(owner)}
                              userId={patients[patientIdx].userId}
                              consultationId={consultationGetters.getId(
                                consultation
                              )}
                              defaultValues={formRef?.current
                                ?.getMethods?.()
                                ?.getValues(
                                  `patient.${patientIdx}.${SECTIONS.PATIENT_AND_FAMILY_HISTORY.formField}`
                                )}
                              expanded={
                                expandedSection ===
                                SECTIONS.PATIENT_AND_FAMILY_HISTORY.key
                              }
                              confirmed={
                                confirmedSections[patientIdx]?.[
                                  SECTIONS.PATIENT_AND_FAMILY_HISTORY.key
                                ]
                              }
                              onEdit={() => {
                                setExpandedSection(
                                  SECTIONS.PATIENT_AND_FAMILY_HISTORY.key
                                );
                              }}
                              onSubmitted={(
                                patientAndFamilyHistoryFormData
                              ) => {
                                onPatientAndFamilyHistorySubmit(
                                  patientAndFamilyHistoryFormData
                                );
                              }}
                            />
                          </Grid>
                        )}
                        {/* Diagnosis */}
                        {!isHiddenByNoShow[patientIdx] && (
                          <Grid item>
                            <DiagnosisForm
                              ownerId={userGetters.getId(owner)}
                              userId={patients[patientIdx].userId}
                              consultationId={consultationGetters.getId(
                                consultation
                              )}
                              defaultValues={formRef?.current
                                ?.getMethods?.()
                                ?.getValues(
                                  `patient.${patientIdx}.${SECTIONS.DIAGNOSIS.formField}`
                                )}
                              expanded={
                                expandedSection === SECTIONS.DIAGNOSIS.key
                              }
                              confirmed={
                                confirmedSections[patientIdx]?.[
                                  SECTIONS.DIAGNOSIS.key
                                ]
                              }
                              onEdit={() => {
                                setExpandedSection(SECTIONS.DIAGNOSIS.key);
                              }}
                              onSubmitted={(diagnosisFormData) => {
                                onDiagnosisSubmit(diagnosisFormData);
                              }}
                            />
                          </Grid>
                        )}
                        {/* Consultation Outcomes */}
                        {!isHiddenByNoShow[patientIdx] && (
                          <Grid item>
                            <ConsultationOutcomesForm
                              defaultValues={formRef?.current
                                ?.getMethods?.()
                                ?.getValues(
                                  `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}`
                                )}
                              patient={patients[patientIdx]}
                              consultation={consultation}
                              expanded={
                                expandedSection ===
                                SECTIONS.CONSULTATION_OUTCOMES.key
                              }
                              confirmed={
                                confirmedSections[patientIdx]?.[
                                  SECTIONS.CONSULTATION_OUTCOMES.key
                                ]
                              }
                              onEdit={() => {
                                setExpandedSection(
                                  SECTIONS.CONSULTATION_OUTCOMES.key
                                );
                              }}
                              onSubmitted={(consultationOutcomesFormData) => {
                                onConsultationOutcomesSubmit(
                                  consultationOutcomesFormData
                                );
                              }}
                            />
                          </Grid>
                        )}
                        {/* Prescription */}
                        {!isHiddenByNoShow[patientIdx] &&
                          // If prescription outcome is selected
                          (
                            formRef?.current
                              ?.getMethods?.()
                              ?.watch(
                                `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}.outcomes`
                              ) || []
                          ).includes(OUTCOME_OPTIONS.Prescription.key) && (
                            <Grid item>
                              <PrescriptionForm
                                defaultValues={formRef?.current
                                  ?.getMethods?.()
                                  ?.getValues(
                                    `patient.${patientIdx}.${SECTIONS.PRESCRIPTION.formField}`
                                  )}
                                expanded={
                                  expandedSection === SECTIONS.PRESCRIPTION.key
                                }
                                identifier={SECTIONS.PRESCRIPTION.key}
                                title={SECTIONS.PRESCRIPTION.label}
                                confirmed={
                                  confirmedSections[patientIdx]?.[
                                    SECTIONS.PRESCRIPTION.key
                                  ]
                                }
                                onEdit={() => {
                                  setExpandedSection(SECTIONS.PRESCRIPTION.key);
                                }}
                                onSubmitted={(prescriptionFormData) => {
                                  onPrescriptionSubmit(prescriptionFormData);
                                }}
                                prescriptionDocumentFileId={getPatientPrescriptionDocumentById(
                                  patients?.[tab]?.userId
                                )}
                                patient={patients[patientIdx]}
                                consultation={consultation}
                                patientDiagnosis={formRef?.current
                                  ?.getMethods?.()
                                  ?.watch(
                                    `patient.${patientIdx}.${SECTIONS.DIAGNOSIS.formField}`
                                  )}
                              />
                            </Grid>
                          )}
                        {/* Lab Request */}
                        {!isHiddenByNoShow[patientIdx] &&
                          // If lab request outcome is selected
                          (
                            formRef?.current
                              ?.getMethods?.()
                              ?.watch(
                                `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}.outcomes`
                              ) || []
                          ).includes(OUTCOME_OPTIONS.LabTestRequest.key) && (
                            <Grid item>
                              <LabRequestForm
                                defaultValues={formRef?.current
                                  ?.getMethods?.()
                                  ?.getValues(
                                    `patient.${patientIdx}.${SECTIONS.LAB_REQUEST.formField}`
                                  )}
                                ownerId={userGetters.getId(owner)}
                                patient={patients[patientIdx]}
                                consultation={consultation}
                                expanded={
                                  expandedSection === SECTIONS.LAB_REQUEST.key
                                }
                                confirmed={
                                  confirmedSections[patientIdx]?.[
                                    SECTIONS.LAB_REQUEST.key
                                  ]
                                }
                                onEdit={() => {
                                  setExpandedSection(SECTIONS.LAB_REQUEST.key);
                                }}
                                onSubmitted={(labRequestFormData) => {
                                  onLabTestsSubmit(labRequestFormData);
                                }}
                                patientDiagnosis={formRef?.current
                                  ?.getMethods?.()
                                  ?.watch(
                                    `patient.${patientIdx}.${SECTIONS.DIAGNOSIS.formField}`
                                  )}
                              />
                            </Grid>
                          )}
                        {/* Management and Treatment Plan */}
                        {!isHiddenByNoShow[patientIdx] && (
                          <Grid item>
                            <ManagementAndTreatmentPlanForm
                              owner={owner}
                              consultation={consultation}
                              patientId={patients[patientIdx].id}
                              userId={patients[patientIdx].userId}
                              defaultValues={formRef?.current
                                ?.getMethods?.()
                                ?.getValues(
                                  `patient.${patientIdx}.${SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.formField}`
                                )}
                              expanded={
                                expandedSection ===
                                SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.key
                              }
                              confirmed={
                                confirmedSections[patientIdx]?.[
                                  SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.key
                                ]
                              }
                              onEdit={() => {
                                setExpandedSection(
                                  SECTIONS.MANAGEMENT_AND_TREATMENT_PLAN.key
                                );
                              }}
                              onSubmitted={(
                                managementAndTreatmentPlanFormData
                              ) => {
                                onManagementAndTreatmentPlanSubmit(
                                  managementAndTreatmentPlanFormData
                                );
                              }}
                              consultationOutcomes={
                                formRef?.current
                                  ?.getMethods?.()
                                  ?.watch(
                                    `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}.outcomes`
                                  ) || []
                              }
                            />
                          </Grid>
                        )}
                        <Grid item container justifyContent="center" py={8}>
                          <Grid item>
                            {!isPreviewMode && (
                              <Button
                                variant="filled"
                                onClick={() => {
                                  // Get doctor private notes from form
                                  const selectedDoctorPrivateNotes =
                                    formRef?.current
                                      ?.getMethods?.()
                                      ?.getValues(
                                        `patient.${patientIdx}.${SECTIONS.DOCTOR_PRIVATE_NOTES.formField}`
                                      );

                                  // Get the mapped outcome of the doctor private notes selected status
                                  const mappedOutcome =
                                    DOCTOR_PRIVATE_NOTES_OPTIONS[
                                      selectedDoctorPrivateNotes?.option
                                    ]?.mappedOutcome;

                                  // Check the mapped outcome
                                  const isDoctorPrivateNotesMappedToNoShow =
                                    mappedOutcome ===
                                    OUTCOME_OPTIONS.NoShow.key;

                                  if (isDoctorPrivateNotesMappedToNoShow) {
                                    // In case of no show, just move to next member or
                                    // submit if it's last tab and all previous tabs are confirmed
                                    if (
                                      // If the last tab
                                      tab === (patients?.length || 0) - 1 &&
                                      // and all previous patients are confirmed
                                      areAllPreviousPatientsConfirmedOrNoShow
                                    ) {
                                      onSubmitOutcomesClicked();
                                    } else {
                                      onNextMemberClicked();
                                    }
                                  } else {
                                    // Otherwise, submit, and show preview mode
                                    formRef?.current?.submit();
                                  }
                                }}
                                disabled={
                                  !utils.areAllSectionsConfirmed(
                                    confirmedSections[patientIdx],
                                    formRef?.current
                                      ?.getMethods?.()
                                      ?.watch(
                                        `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}.outcomes`
                                      ),
                                    isHiddenByNoShow[patientIdx]
                                  )
                                }
                                sx={{
                                  width: (theme) => theme.spacing(58), // around 465px
                                }}
                              >
                                <Conditionally>
                                  <If
                                    condition={
                                      DOCTOR_PRIVATE_NOTES_OPTIONS[
                                        formRef?.current
                                          ?.getMethods?.()
                                          ?.watch(
                                            `patient.${patientIdx}.${SECTIONS.DOCTOR_PRIVATE_NOTES.formField}.option`
                                          )
                                      ]?.mappedOutcome ===
                                      OUTCOME_OPTIONS.NoShow.key
                                    }
                                  >
                                    <Conditionally>
                                      <If
                                        condition={
                                          // If the last tab
                                          tab === (patients?.length || 0) - 1 &&
                                          // and all previous patients are confirmed
                                          areAllPreviousPatientsConfirmedOrNoShow
                                        }
                                      >
                                        Submit Outcomes
                                      </If>
                                      <Else>Next Member</Else>
                                    </Conditionally>
                                  </If>
                                  <Else>Save Outcome</Else>
                                </Conditionally>
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {isPreviewMode && (
                      <OutcomeManagementDialogDocumentsPreview
                        selectedOutcomes={formRef?.current
                          ?.getMethods?.()
                          ?.watch(
                            `patient.${patientIdx}.${SECTIONS.CONSULTATION_OUTCOMES.formField}.outcomes`
                          )}
                        labRequestDocument={
                          patientsLabRequestDocumentsById?.[
                            patients?.[tab]?.userId
                          ]
                        }
                        prescriptionDocument={getPatientPrescriptionDocumentById(
                          patients?.[tab]?.userId
                        )}
                        assessmentSheetDocument={getPatientAssessmentSheetById(
                          patients?.[tab]?.userId
                        )}
                      />
                    )}
                  </TabPanel>
                ))}
              </>
            )}
          </Form>
          <PageSpinner
            open={
              isLoading ||
              isCreatingLabRequestDocument ||
              isSubmittingAllPrescriptions ||
              isCreatingPatientPrescriptionDocument ||
              isSubmittingDoctorNotes ||
              isSubmittingELabTestRequests
            }
          />
        </DialogContent>
        {isPreviewMode && (
          // Dialog actions is only shown while previewing files
          <DialogActions sx={{ px: 3, py: 2 }}>
            <Button
              variant="outlined"
              onClick={() => {
                setPreviewMode(false);
              }}
            >
              Edit Outcome
            </Button>
            <Conditionally>
              <If
                condition={
                  // If the last tab
                  tab === (patients?.length || 0) - 1 &&
                  // and all previous patients are confirmed
                  areAllPreviousPatientsConfirmedOrNoShow
                }
              >
                <Button
                  variant="filled"
                  onClick={() => {
                    onSubmitOutcomesClicked();
                  }}
                >
                  Submit Outcomes
                </Button>
              </If>
              <Else>
                <Button
                  variant="filled"
                  onClick={() => {
                    onNextMemberClicked();
                  }}
                >
                  Next Member
                </Button>
              </Else>
            </Conditionally>
          </DialogActions>
        )}
      </Dialog>
      <Popup
        id={isConfirmPopupOpen ? 'submit-outcome-close-confirm' : undefined}
        open={isConfirmPopupOpen}
        title="Outcome has not been saved yet"
        primaryButtonTitle="No, continue editing"
        secondaryButtonTitle="Yes, leave this screen"
        onOk={() => {
          setConfirmPopupOpen(false);
        }}
        onCancel={() => {
          // Do the action
          onPopupConfirmed?.();

          // Reset
          setConfirmPopupOpen(false);
        }}
      >
        You might have not saved the outcome yet. Are you sure you want to leave
        this screen?
      </Popup>
    </>
  );
};

export default OutcomeManagementDialog;
