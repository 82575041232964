import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as MEETING } from './constants';

export const CREATE_MEETING = `${DOMAIN}/${MEETING}/CREATE_MEETING`;
export const UPDATE_MEETING = `${DOMAIN}/${MEETING}/UPDATE_MEETING`;
export const DELETE_MEETING = `${DOMAIN}/${MEETING}/DELETE_MEETING`;
export const GET_DOCTOR_MEETINGS = `${DOMAIN}/${MEETING}/GET_DOCTOR_MEETINGS`;

export const SET_SUBMITTING_MEETING = `${DOMAIN}/${MEETING}/SET_SUBMITTING_MEETING`;
export const SET_FETCHING_MEETINGS = `${DOMAIN}/${MEETING}/SET_FETCHING_MEETINGS`;
export const SET_DELETING_MEETING = `${DOMAIN}/${MEETING}/SET_DELETING_MEETING`;

export const MEETING_CREATED = `${DOMAIN}/${MEETING}/MEETING_CREATED`;
export const MEETING_UPDATED = `${DOMAIN}/${MEETING}/MEETING_UPDATED`;
export const MEETING_DELETED = `${DOMAIN}/${MEETING}/MEETING_DELETED`;
export const MEETINGS_RECEIVED = `${DOMAIN}/${MEETING}/MEETINGS_RECEIVED`;

export const CLEAR = `${DOMAIN}/${MEETING}/CLEAR`;
