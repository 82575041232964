import React, { useRef, useState } from 'react';

import { Form, Controller } from 'design-system/form';
import {
  Grid,
  Popup,
  Button,
  Dialog,
  FormField,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';
import { REFERENCE_TYPES } from 'constants/general';

const SendBulkNotifications = ({
  send,
  params,
  loading,
  rowCount,
  referenceType,
}) => {
  const formRef = useRef();

  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmitted = (data = {}) => {
    setSubmitting(true);
    setConfirmOpen(false);

    const payload = {
      pushNotification: {
        en: {
          link: data.link,
          title: data.en.title,
          body: data.en.body,
        },
        ar: {
          link: data.link,
          title: data.ar.title,
          body: data.ar.body,
        },
        reason: data.reason,
        analyticsLabel: data.analyticsLabel,
      },
      referenceType,
    };
    send(payload, new URLSearchParams(params), () => {
      formRef.current.getMethods().reset();
      setSubmitting(false);
      setOpen(false);
    });
  };

  const bulkConfirmationText =
    referenceType !== REFERENCE_TYPES.USER
      ? `Sending bulk mobile push notifications to users of ${rowCount}${` ${referenceType?.toLowerCase()}`}`
      : `Sending bulk mobile push notifications to ${rowCount} users.`;

  return (
    <>
      <Button
        variant="text"
        disabled={loading}
        onClick={() => {
          onOpen();
        }}
      >
        Send bulk push notifications
      </Button>
      <Dialog
        fullWidth
        open={isOpen}
        maxWidth="md"
        onClose={onClose}
        aria-labelledby="send-bulk-notifications-dialog-title"
      >
        <DialogTitle id="send-bulk-notifications-title" onClose={onClose}>
          Send Bulk Push Notifications
        </DialogTitle>
        <DialogContent dividers>
          <Form ref={formRef} onSubmit={onSubmitted}>
            {({ control }) => (
              <Grid container column rowSpacing={2}>
                <Grid item>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                      <Grid container column rowSpacing={2}>
                        <FormField
                          label="ENGLISH TITLE"
                          required
                          field={
                            <Controller
                              name="en.title"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: 'English title is required',
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  fullWidth
                                  size="medium"
                                  value={value}
                                  name="en.title"
                                  variant="filled"
                                  onChange={onChange}
                                  error={Boolean(error)}
                                  placeholder="Enter title.."
                                  helperText={error?.message ?? null}
                                />
                              )}
                            />
                          }
                        />
                        <FormField
                          label="ENGLISH BODY"
                          required
                          field={
                            <Controller
                              name="en.body"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: 'English body is required',
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  multiline
                                  fullWidth
                                  limit={178}
                                  size="medium"
                                  value={value}
                                  name="en.body"
                                  variant="filled"
                                  onChange={onChange}
                                  error={Boolean(error)}
                                  placeholder="Enter body.."
                                  helperText={error?.message ?? null}
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container column rowSpacing={2}>
                        <FormField
                          label="ARABIC TITLE"
                          required
                          field={
                            <Controller
                              name="ar.title"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: 'Arabic title is required',
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  fullWidth
                                  size="medium"
                                  value={value}
                                  name="ar.title"
                                  variant="filled"
                                  onChange={onChange}
                                  error={Boolean(error)}
                                  placeholder="أدخل العنوان.."
                                  helperText={error?.message ?? null}
                                  sx={{
                                    direction: 'rtl',
                                  }}
                                />
                              )}
                            />
                          }
                        />
                        <FormField
                          label="ARABIC BODY"
                          required
                          field={
                            <Controller
                              name="ar.body"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: 'Arabic body is required',
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <TextField
                                  fullWidth
                                  multiline
                                  limit={178}
                                  size="medium"
                                  value={value}
                                  name="ar.body"
                                  variant="filled"
                                  onChange={onChange}
                                  error={Boolean(error)}
                                  placeholder="أدخل النص.."
                                  helperText={error?.message ?? null}
                                  sx={{
                                    direction: 'rtl',
                                  }}
                                />
                              )}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container column rowSpacing={2}>
                  <FormField
                    label="LINK"
                    field={
                      <Controller
                        name="link"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            name="link"
                            variant="filled"
                            onChange={onChange}
                            placeholder="Optional. Defaults to Home Screen"
                          />
                        )}
                      />
                    }
                  />
                  <FormField
                    label="ANALYTICS LABEL"
                    field={
                      <Controller
                        name="analyticsLabel"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            name="analyticsLabel"
                            variant="filled"
                            onChange={onChange}
                            placeholder="Optional"
                          />
                        )}
                      />
                    }
                  />
                  <FormField
                    label="REASON"
                    field={
                      <Controller
                        name="reason"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="medium"
                            value={value}
                            name="reason"
                            variant="filled"
                            onChange={onChange}
                            placeholder="Optional"
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Form>
        </DialogContent>
        <DialogActions>
          <Typography variant="p3" sx={{ mx: 1 }}>
            <strong>Total {referenceType?.toLowerCase()} count: </strong>
            {rowCount}
          </Typography>
          <Button
            spinning={isSubmitting}
            variant="filled"
            onClick={async () => {
              const isValid = await formRef.current.getMethods().trigger();

              if (isValid) {
                setConfirmOpen(true);
              }
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <Popup
        id={isConfirmOpen ? 'sent-bulk-notifications-confirm' : undefined}
        open={isConfirmOpen}
        title="Are you sure?"
        primaryButtonTitle="Yes, send now"
        secondaryButtonTitle="Give it another look"
        onCancel={() => setConfirmOpen(false)}
        onOk={() => {
          formRef.current.submit();
        }}
      >
        <Grid container column>
          <Grid item>
            <Typography variant="l3">{bulkConfirmationText}</Typography>
          </Grid>
        </Grid>
      </Popup>
    </>
  );
};

export default SendBulkNotifications;
