import get from 'lodash/get';
import { createSelector } from 'reselect';

export const paymentSelector = (state) => get(state, 'domain.payment', {});

export const getPaymentTransaction = createSelector(
  paymentSelector,
  (results) => get(results, `view.paymentTransaction`)
);

export const isFetchingPaymentTransaction = createSelector(
  paymentSelector,
  (results) => get(results, 'view.isFetching')
);
