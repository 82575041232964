import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as SUBSCRIPTION } from './constants';

export const UPDATE_SUBSCRIPTION = `${DOMAIN}/${SUBSCRIPTION}/UPDATE_SUBSCRIPTION`;
export const GET_SUBSCRIPTION = `${DOMAIN}/${SUBSCRIPTION}/GET_SUBSCRIPTION`;
export const CANCEL_SUBSCRIPTION = `${DOMAIN}/${SUBSCRIPTION}/CANCEL_SUBSCRIPTION`;
export const SET_SUBMITTING = `${DOMAIN}/${SUBSCRIPTION}/SET_SUBMITTING`;
export const SET_FETCHING = `${DOMAIN}/${SUBSCRIPTION}/SET_FETCHING`;
export const SUBSCRIPTION_RECEIVED = `${DOMAIN}/${SUBSCRIPTION}/SUBSCRIPTION_RECEIVED`;

export const CLEAR = `${DOMAIN}/${SUBSCRIPTION}/CLEAR`;

export const CREATE_SUBSCRIPTION = `${DOMAIN}/${SUBSCRIPTION}/CREATE_SUBSCRIPTION`;
export const SUBSCRIPTION_CREATION_RESPONDED = `${DOMAIN}/${SUBSCRIPTION}/SUBSCRIPTION_CREATION_RESPONDED`;

export const FETCH_SUBSCRIPTION_PLANS = `${DOMAIN}/${SUBSCRIPTION}/FETCH_SUBSCRIPTION_PLANS`;
export const SUBSCRIPTION_PLANS_RECEIVED = `${DOMAIN}/${SUBSCRIPTION}/SUBSCRIPTION_PLANS_RECEIVED`;
export const SET_FETCHING_SUBSCRIPTION_PLANS = `${DOMAIN}/${SUBSCRIPTION}/SET_FETCHING_SUBSCRIPTION_PLANS`;
