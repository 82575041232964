import * as types from './actionTypes';

const initialState = {
  list: [],
  view: undefined,
  isFetching: false,
  isSubmitting: false,
};

const doctorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOCTOR_RECEIVED: {
      return {
        ...state,
        view: action.doctor,
      };
    }
    case types.DOCTORS_RECEIVED: {
      return {
        ...state,
        list: action.doctors || [],
      };
    }
    case types.SET_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    default:
      return state;
  }
};

export default doctorReducer;
