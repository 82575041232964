import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './actionTypes';
import httpClient from '../../core/httpClient';
import { FIELDS, ENDPOINT, COMMENTS_PARAMS } from './constants';

export function* fetchCommentsOf({ referenceType, referenceId }) {
  const response = yield call(httpClient.get, ENDPOINT, {
    params: COMMENTS_PARAMS(referenceType, referenceId),
  });

  yield put(
    actions.commentsReceived(response.data.data, referenceType, referenceId)
  );
}

export function* createCommentOf({ referenceType, referenceId, content }) {
  const payload = {
    [FIELDS.CONTENT.name]: content,
    [FIELDS.REFERENCE_ID.name]: referenceId,
    [FIELDS.REFERENCE_TYPE.name]: referenceType,
  };
  const response = yield call(httpClient.post, ENDPOINT, payload);

  yield put(actions.commentCreated(response.data, referenceType, referenceId));
}

export default function* commentModelSaga() {
  yield all([
    takeLatest(types.FETCH_COMMENTS_OF, fetchCommentsOf),
    takeLatest(types.CREATE_COMMENT_OF, createCommentOf),
  ]);
}
