import get from 'lodash/get';

import { LINE_ITEM_FIELDS } from '../constants';

export const getId = (lineItem) => get(lineItem, LINE_ITEM_FIELDS.ID.name);

export const getCreatedAt = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.CREATED_AT.name);

export const getUpdatedAt = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.UPDATED_AT.name);

export const getCreatedBy = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.CREATED_BY.name);

export const getUpdatedBy = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.UPDATED_BY.name);

export const getOrderId = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.ORDER_ID.name);

export const getMedicine = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.MEDICINE.name);

export const getMedicineId = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.MEDICINE_ID.name);

export const getQuantity = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.QUANTITY.name);

export const getQuantityUnit = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.QUANTITY_UNIT.name);

export const getInstructions = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.INSTRUCTIONS.name);

export const getDuration = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.DURATION.name);

export const getDurationUnit = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.DURATION_UNIT.name);

export const getFrequency = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.FREQUENCY.name);

export const getFrequencyUnit = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.FREQUENCY_UNIT.name);

export const getUsage = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.USAGE.name);

export const getUsageUnit = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.USAGE_UNIT.name);

export const getPrice = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PRICE.name);

export const getPriceAfterInsuranceDiscount = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PRICE_AFTER_INSURANCE_DISCOUNT.name);

export const getDiscount = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.DISCOUNT.name);

export const getPatientDiscount = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PATIENT_DISCOUNT.name);

export const getPatientDiscountPercentage = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PATIENT_DISCOUNT_PERCENTAGE.name);

export const getPatientSharePercentage = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PATIENT_SHARE_PERCENTAGE.name);

export const getDiscountPercentage = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.DISCOUNT_PERCENTAGE.name);

export const getPatientShare = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PATIENT_SHARE.name);

export const getPayerShare = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.PAYER_SHARE.name);

export const getReminderDate = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.REMINDER_DATE.name);

export const isApprovedByInsurance = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.IS_APPROVED_BY_INSURANCE.name);

export const getAvailability = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.AVAILABILITY.name);

export const isFixedDiscount = (lineItem) =>
  get(lineItem, LINE_ITEM_FIELDS.IS_FIXED_DISCOUNT.name);
