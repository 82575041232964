import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

import Grid from '../Grid';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import IconButton from '../IconButton';
import RequiredMark from '../RequiredMark';

/**
 * A field wrapper view. Initially shows text and an edit icon,
 * if clicked, shows the field specified in render property, save icon and discard changes icon.
 *
 * Examples: E-Prescription and E-Lab
 */
const EditableField = ({
  label,
  onSave,
  render,
  required,
  readonly,
  defaultValue,
  error,
  onEdit = (v) => v,
  valueGetter = (v) => v,
  isFalsy = (v) => !v,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [isEditMode, setEditMode] = useState(required && isFalsy(defaultValue));

  if (readonly) {
    return (
      <Grid container column>
        {label && (
          <Grid item>
            <Typography variant="p4" color={error && 'error'}>
              {label}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          alignItems="center"
          sx={{ minHeight: (thm) => thm.spacing(4) }}
        >
          <Typography
            variant={isFalsy(value) ? 'p4' : 'l4'}
            sx={{ ...(isFalsy(value) && { fontStyle: 'italic' }) }}
          >
            {valueGetter(value) || 'None'}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container column>
      <Grid item>
        <Typography variant="p4" color={error && 'error'}>
          {label}
        </Typography>
        {required && <RequiredMark />}
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        sx={{ flexWrap: 'nowrap', minHeight: (thm) => thm.spacing(4) }}
      >
        {isEditMode && (
          <>
            {render({
              value,
              onChange: (e) =>
                setValue(
                  (() => {
                    if (e?.target) {
                      return e.target.value;
                    }

                    return e;
                  })()
                ),
              onBlur: (e) => {
                e.stopPropagation();

                if (!required || value) {
                  setEditMode(false);
                }

                onSave(value);
              },
              onAccept: (val) => {
                setValue(val);
                onSave(val);
                setEditMode(false);
              },
            })}
            <IconButton
              sx={{ ml: 0.5, alignSelf: 'flex-start' }}
              onClick={(e) => {
                e.stopPropagation();
                setEditMode(false);
                onSave(value);
              }}
            >
              <Tooltip title="Save" placement="top">
                <DoneIcon fontSize="extraSmall" color="success" />
              </Tooltip>
            </IconButton>
            <IconButton
              sx={{ ml: 0.5, alignSelf: 'flex-start' }}
              onClick={(e) => {
                e.stopPropagation();
                setEditMode(false);
                setValue(defaultValue);
              }}
            >
              <Tooltip title="Discard changes" placement="top">
                <ClearIcon fontSize="extraSmall" color="primary" />
              </Tooltip>
            </IconButton>
          </>
        )}
        {!isEditMode && (
          <>
            <Typography
              variant={isFalsy(value) ? 'p4' : 'l4'}
              sx={{
                ...(isFalsy(value) && {
                  fontStyle: 'italic',
                  minWidth: '50px',
                }),
              }}
            >
              {required && isFalsy(value) && 'Enter value..'}
              {!required && isFalsy(value) && 'None'}
              {valueGetter(value)}
            </Typography>
            <IconButton
              sx={{ ml: 0.5, alignSelf: 'flex-start' }}
              onClick={(e) => {
                e.stopPropagation();
                setEditMode(true);
                onEdit();
              }}
            >
              <Tooltip title="Edit" placement="top">
                <EditIcon fontSize="extraSmall" color="primary" />
              </Tooltip>
            </IconButton>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default EditableField;
