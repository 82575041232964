import get from 'lodash/get';
import { all, call, takeLatest, put } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import { ENDPOINT } from './constants';

export function* fetchDoctorMeetings({ doctorId }) {
  try {
    const response = yield call(httpClient.get, ENDPOINT, {
      params: {
        filter: `doctorId||eq||${doctorId}`,
      },
    });

    yield put(actions.doctorMeetingsFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorMeetingsFetchResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* createMeeting({ payload }) {
  try {
    const response = yield call(httpClient.post, ENDPOINT, payload);

    yield put(actions.meetingCreationResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.meetingCreationResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* updateMeeting({ id, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      [ENDPOINT, id].join('/'),
      payload
    );

    yield put(actions.meetingUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.meetingUpdateResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* deleteMeeting({ id }) {
  try {
    const response = yield call(httpClient.delete, `${ENDPOINT}/${id}`);

    yield put(actions.meetingDeleteResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.meetingDeleteResponded(false, get(e, 'response.data.message'))
    );
  }
}

export default function* meetingModelSaga() {
  yield all([
    takeLatest(types.FETCH_DOCTOR_MEETINGS, fetchDoctorMeetings),
    takeLatest(types.CREATE_MEETING, createMeeting),
    takeLatest(types.UPDATE_MEETING, updateMeeting),
    takeLatest(types.DELETE_MEETING, deleteMeeting),
  ]);
}
