import get from 'lodash/get';
import { createSelector } from 'reselect';

export const userManagementSelector = (state) =>
  get(state, 'domain.userManagement', {});

export const isSubmittingAlmaosUser = createSelector(
  userManagementSelector,
  (results) => get(results, 'isSubmittingAlmaosUser')
);

export const isFetchingAlmaosUser = createSelector(
  userManagementSelector,
  (results) => get(results, 'isFetchingAlmaosUser')
);

export const getAlmaosUser = createSelector(userManagementSelector, (results) =>
  get(results, 'almaosUser')
);

export default {
  isSubmittingAlmaosUser,
  isFetchingAlmaosUser,
  getAlmaosUser,
};
