import { PACKAGE_NAME as CONSULTATION_TYPE } from './constants';
import { PACKAGE_NAME as MODEL } from '../constants';

// Consultation type
export const FETCH_CONSULTATION_TYPE_BY_ID = `${MODEL}/${CONSULTATION_TYPE}/FETCH_CONSULTATION_TYPE_BY_ID`;
export const FETCH_CONSULTATION_TYPES = `${MODEL}/${CONSULTATION_TYPE}/FETCH_CONSULTATION_TYPES`;
export const UPDATE_CONSULTATION_TYPE = `${MODEL}/${CONSULTATION_TYPE}/UPDATE_CONSULTATION_TYPE`;
export const CREATE_CONSULTATION_TYPE = `${MODEL}/${CONSULTATION_TYPE}/CREATE_CONSULTATION_TYPE`;

export const CONSULTATION_TYPE_RECEIVED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_TYPE_RECEIVED`;
export const CONSULTATION_TYPES_RECEIVED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPES_RECEIVED`;
export const CONSULTATION_TYPE_UPDATE_RESPONDED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_UPDATE_RESPONDED`;
export const CONSULTATION_TYPE_CREATE_RESPONDED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_CREATE_RESPONDED`;

// Consultation type configuration
export const FETCH_CONSULTATION_TYPE_CONFIGURATIONS = `${MODEL}/${CONSULTATION_TYPE}/FETCH_CONSULTATION_TYPE_CONFIGURATIONS`;
export const ADD_CONSULTATION_TYPE_CONFIGURATION = `${MODEL}/${CONSULTATION_TYPE}/ADD_CONSULTATION_TYPE_CONFIGURATION`;
export const DELETE_CONSULTATION_TYPE_CONFIGURATION = `${MODEL}/${CONSULTATION_TYPE}/DELETE_CONSULTATION_TYPE_CONFIGURATION`;

export const UPDATE_CONSULTATION_TYPE_CONFIGURATION = `${MODEL}/${CONSULTATION_TYPE}/UPDATE_CONSULTATION_TYPE_CONFIGURATION`;
export const CONSULTATION_TYPE_CONFIGURATIONS_RECEIVED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_CONFIGURATIONS_RECEIVED`;
export const CONSULTATION_TYPE_CONFIGURATION_ADD_RESPONDED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_CONFIGURATION_ADD_RESPONDED`;
export const CONSULTATION_TYPE_CONFIGURATION_UPDATE_RESPONDED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_CONFIGURATION_UPDATE_RESPONDED`;
export const CONSULTATION_TYPE_CONFIGURATION_DELETE_RESPONDED = `${MODEL}/${CONSULTATION_TYPE}/CONSULTATION_TYPE_CONFIGURATION_DELETE_RESPONDED`;
