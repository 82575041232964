import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';

import { userGetters } from 'domain/user';
import { labConstants } from 'domain/lab';
import { UserSearch } from 'presentation/user';
import { getLabEndpoint } from 'core/endpoint';
import { Button, DataGrid, PageTitle } from 'design-system';
import { TEST_REQUEST_LIST_ENDPOINT } from 'model/lab/constants';
import { DataGridCard, useServerPagination } from 'core/dataGrid';

import { getColumns } from './utils';

const { STATUSES } = labConstants;

const LabRequestList = () => {
  const navigate = useNavigate();

  const [queryParamsMap, setQueryParamsMap] = useState({});

  const {
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: TEST_REQUEST_LIST_ENDPOINT,
    pageSize: 10,
    baseURL: getLabEndpoint(),
    initialSortModel: { field: 'updatedAt', sort: 'desc' },
  });

  const onRowClick = (row) => {
    const { id } = row;

    navigate(`/lab-request/${id}`);
  };

  useEffect(() => {
    if (!isEmpty(queryParamsMap)) {
      const { patient, status } = queryParamsMap;
      const urlSearchParams = new URLSearchParams();

      if (patient) {
        urlSearchParams.append('userId', patient);
      }

      if (status) {
        urlSearchParams.append('status', status);
      }
      setQueryParams(urlSearchParams);
    }
  }, [queryParamsMap]);

  const filtersDef = {
    fields: [
      {
        name: 'patient',
        type: 'custom',
        component: (
          <UserSearch
            onUserSelect={(selectedPatient) => {
              setQueryParamsMap({
                ...queryParamsMap,
                patient: userGetters.getId(selectedPatient),
              });
            }}
          />
        ),
      },
      {
        name: 'status',
        type: 'autocomplete',
        placeholder: 'Status',
        icon: MonitorHeartOutlinedIcon,
        options: keys(STATUSES),
        getOptionLabel: (option) => STATUSES[option]?.label,
        filter: (selectedStatus) => {
          setQueryParamsMap({
            ...queryParamsMap,
            status: STATUSES[selectedStatus]?.key,
          });
        },
      },
    ],
  };

  return (
    <>
      <PageTitle
        title="Lab Test Requests"
        action={
          <Button
            variant="filled"
            onClick={() => {
              navigate('/lab-request');
            }}
          >
            Request a new lab test
          </Button>
        }
      />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          columns={getColumns()}
          onRowClick={(params) => onRowClick(params?.row)}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => {
                window.open(`/lab-request/${row.id}`, '_blank');
              },
            },
          ]}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              width: '100%',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
};

export default LabRequestList;
