import * as types from './actionTypes';

const initialState = {
  view: {},
};

const followUpReminderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FOLLOW_UP_REMINDER_RECEIVED: {
      return {
        ...state,
        view: {
          ...state.view,
          [action.referenceType]: action.followUpReminders,
        },
      };
    }
    default:
      return state;
  }
};

export default followUpReminderReducer;
