import React from 'react';
import ReactDropzone from 'react-dropzone';

import Box from '../Box';
import Grid from '../Grid';
import Skeleton from '../Skeleton';
import Typography from '../Typography';

const Dropzone = ({
  sx,
  onDrop,
  loading,
  placeholder = 'Drop your image or PDF file here',
  accept = {
    'image/*': ['.jpeg', '.png', '.jpg'],
    'application/pdf': ['.pdf'],
  },
  ...rest
}) => {
  if (loading) {
    return <Skeleton variant="rectangle" height={48} />;
  }

  return (
    <ReactDropzone onDrop={onDrop} {...rest} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <Box
          sx={{
            display: 'flex',
            cursor: 'pointer',
            alignItems: 'center',
            minHeight: (theme) => theme.spacing(6),
            borderRadius: (theme) => theme.spacing(1),
            backgroundColor: (theme) => theme.palette.grey[200],
            ...sx,
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            sx={{
              height: '100%',
              width: '100%',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Typography
              variant="p2"
              textAlign="center"
              sx={{
                p: 1,
              }}
            >
              {placeholder}
            </Typography>
          </Grid>
        </Box>
      )}
    </ReactDropzone>
  );
};

export default Dropzone;
