import values from 'lodash/values';
import HistoryIcon from '@mui/icons-material/History';
import React, { useState, useEffect, useCallback } from 'react';

import {
  Tab,
  Tabs,
  Grid,
  Dialog,
  Button,
  Tooltip,
  TabPanel,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

import { TABS } from './constants';
import { historyToDataGridProps } from './utils';

const OrderHistory = ({
  // Props
  id,
  loading,
  // State
  history,
  lineItems,
  isFetching,
  isFetchingLineItems,
  // Actions
  fetchHistory,
  fetchLineItemsHistory,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [tab, setTab] = useState(TABS.STATUS.key);
  const [dataGridProps, setDataGridProps] = useState({});

  const onClosed = () => {
    setOpen(false);
  };

  const onViewClicked = useCallback(() => {
    setOpen(true);
    setTab(TABS.STATUS.key);

    fetchHistory(id);
    fetchLineItemsHistory(id);
  }, [id, fetchHistory, fetchLineItemsHistory]);

  const onTabChanged = useCallback((_event, newValue) => {
    setTab(newValue);
  }, []);

  useEffect(() => {
    setDataGridProps(
      historyToDataGridProps(
        id,
        history,
        lineItems,
        isFetching,
        isFetchingLineItems
      )
    );
  }, [id, history, lineItems, isFetching, isFetchingLineItems]);

  return (
    <>
      <Tooltip placement="top" injectWrapper title="View order history">
        <Button
          size="medium"
          variant="text"
          color="primary"
          loading={loading}
          onClick={() => onViewClicked()}
          startIcon={<HistoryIcon color="secondary" />}
        >
          View Order History
        </Button>
      </Tooltip>
      <Dialog
        fullScreen
        scrollable
        open={isOpen}
        onClose={onClosed}
        aria-labelledby="order-history-dialog"
      >
        <DialogTitle id="order-history-dialog" onClose={onClosed}>
          Order History
        </DialogTitle>
        <DialogContent dividers>
          <Grid container column rowSpacing={3}>
            <Grid item>
              <Tabs value={tab} handleChange={onTabChanged}>
                {values(TABS).map((tabConfig) => (
                  <Tab
                    key={tabConfig.id}
                    value={tabConfig.key}
                    label={
                      <Typography
                        variant="h5"
                        sx={{
                          paddingBottom: 0,
                          textTransform: 'none',
                        }}
                      >
                        {tabConfig.label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
              {values(TABS).map((tabConfig) => (
                <TabPanel
                  value={tab}
                  id={tabConfig.id}
                  key={tabConfig.id}
                  index={tabConfig.key}
                >
                  {dataGridProps?.[tabConfig.identifier] &&
                    tabConfig.component(dataGridProps[tabConfig.identifier])}
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ pr: 2 }}>
              <Button variant="text" color="primary" onClick={onClosed}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderHistory;
