import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState, useEffect } from 'react';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

import { Button, Tooltip } from 'design-system';
import EPrescriptionContext from 'presentation/ePrescription/context/EPrescriptionContext';

import EPrescriptionDialog from '../EPrescriptionDialog';

/**
 * Flows:
 * 1. Create flow
 * 2. Update after create flow
 * 3. Update flow - amend or replace
 */
const EPrescription = ({
  user,
  loading,
  isUpdate,
  healthInfo,
  isFetchingHealth,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const [formData, setFormData] = useState({});

  const isLoading = useMemo(() => {
    return loading || isFetchingHealth;
  }, [loading, isFetchingHealth]);

  const contextValue = useMemo(() => {
    return { formData, setFormData };
  }, [formData, setFormData]);

  useEffect(() => {
    if (!isEmpty(healthInfo)) {
      if (healthInfo.dob || healthInfo.height || healthInfo.weight) {
        setFormData({
          ...formData,
          ...(healthInfo.dob
            ? {
                dateOfBirth: new Date(healthInfo.dob),
              }
            : {}),
          ...(healthInfo.height
            ? {
                patientHeight: healthInfo.height,
              }
            : {}),
          ...(healthInfo.weight
            ? {
                patientWeight: healthInfo.weight,
              }
            : {}),
        });
      }
    }
  }, [healthInfo]);

  return (
    <>
      <Tooltip
        placement="right"
        injectWrapper
        title="Send a prescription for the consultation's owner account"
      >
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={() => setOpen(true)}
          loading={isLoading && { width: 150 }}
          startIcon={<AppRegistrationIcon color="primary" />}
        >
          {isUpdate ? 'Update E-Prescription' : 'E-Prescription'}
        </Button>
      </Tooltip>
      <EPrescriptionContext.Provider value={contextValue}>
        <EPrescriptionDialog
          user={user}
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          isUpdate={isUpdate}
          {...rest}
        />
      </EPrescriptionContext.Provider>
    </>
  );
};

export default EPrescription;
