import isEmpty from 'lodash/isEmpty';
import { all, call, select, takeLatest } from 'redux-saga/effects';

import { labSelectors } from 'domain/lab';
import { fetchPatientELabRequest } from 'domain/lab/saga';
import { doctorNotesSelectors } from 'domain/doctorNotes';
import { fetchDoctorNotes } from 'domain/doctorNotes/saga';
import { prescriptionSelectors } from 'domain/prescription';
import { fetchPatientPrescription } from 'domain/prescription/saga';

import * as types from './actionTypes';

export function* fetchDoctorNotesAndOutcomes({
  userId,
  consultationId,
  callbacks,
}) {
  const doctorNotesById = yield select((state) =>
    doctorNotesSelectors.getDoctorNotesById(state, userId)
  );
  const eLabRequestById = yield select((state) =>
    labSelectors.getELabRequestById(state, userId)
  );
  const prescriptionById = yield select((state) =>
    prescriptionSelectors.getPatientPrescriptionById(state, userId)
  );

  const promises = []; // Order of pushed elements is important

  /**
   * In parallel, get patient's doctor notes, prescription and lab request.
   *
   * If it already exists, take it from state (as shown above in the select statements).
   * If consultation outcome doesn't prescription or lab, do not fetch them
   */

  // Push doctor notes call if it doesn't exist, or pass the exsting value
  promises.push(
    isEmpty(doctorNotesById)
      ? call(fetchDoctorNotes, { userId, consultationId })
      : doctorNotesById
  );

  // Push prescription call if it's empty, otherwise pass the existing value
  // Even if there's no corresponding outcome
  //      (This case can happen if you already added a prescription,
  //        then you removed the outcome from outside the dialog,
  //        then you re-added it in the dialog)
  promises.push(
    (() => {
      if (isEmpty(prescriptionById)) {
        return call(fetchPatientPrescription, { userId, consultationId });
      }

      return prescriptionById;
    })()
  );

  // Push lab request call if it's empty, otherwise pass the existing value
  // Even if there's no corresponding outcome
  //      (This case can happen if you already added a lab request,
  //        then you removed the outcome from outside the dialog,
  //        then you re-added it in the dialog)
  promises.push(
    (() => {
      if (isEmpty(eLabRequestById)) {
        return call(fetchPatientELabRequest, { userId, consultationId });
      }

      return eLabRequestById;
    })()
  );

  const responses = yield all(promises);

  callbacks?.success?.(responses);
}

export default function* outcomeSaga() {
  yield all([
    takeLatest(
      types.FETCH_DOCTOR_NOTES_AND_OUTCOMES,
      fetchDoctorNotesAndOutcomes
    ),
  ]);
}
