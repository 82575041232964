import get from 'lodash/get';

import { gridPaginationModelSelector } from '@mui/x-data-grid';

/**
 * Total number of rows on the server (not within the page)
 */
export const gridRowCountSelector = (state) => get(state, 'rows.totalRowCount');

/**
 * Total number of pages .. row count divided by page size
 */
export const gridPageCountSelector = (state) => {
  const rowCount = gridRowCountSelector(state);
  const { pageSize } = gridPaginationModelSelector(state);

  return Math.ceil(rowCount / pageSize);
};
