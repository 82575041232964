/* Schedule model constants */
export const PACKAGE_NAME = 'Schedule';
export const DOCTOR_SCHEDULE_ENDPOINT = '/doctor/schedule';
export const AVAILABILITY_RANGE_ENDPOINT = '/doctor/availability-time';
export const BOOKING_AVAILABILITY_ENDPOINT = '/booking-availability';
export const DOCTOR_BOOKING_AVAILABILITY_ENDPOINT = (doctorId) =>
  `${BOOKING_AVAILABILITY_ENDPOINT}/doctor/${doctorId}`;
export const FREE_SLOTS_SCHEDULE_ENDPOINT = '/doctor/availability-free-slot';
export const DOCTOR_FREE_SLOTS_SCHEDULE_ENDPOINT = (
  doctorId,
  dayOfWeekDate,
  dayOfWeek
) =>
  `${FREE_SLOTS_SCHEDULE_ENDPOINT}/${doctorId}?dayOfWeekDate=${dayOfWeekDate}&dayOfWeek=${dayOfWeek}`;
