import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

import { Grid, Tooltip, Typography } from 'design-system';

/**
 * An warning icon and "Action Required" text, with a custom tooltip,
 * shown in outcome section to tell that there's an action to be taken
 */
const ActionRequired = ({ tooltipTitle, color = 'error' }) => {
  return (
    <Grid container justifyContent="flex-start" columnSpacing={1}>
      <Grid item>
        <WarningIcon color={color} fontSize="small" />
      </Grid>
      <Grid item>
        <Tooltip placement="top" injectWrapper title={tooltipTitle}>
          <Typography variant="p4" color={color}>
            Action Required
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default ActionRequired;
