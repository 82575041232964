import get from 'lodash/get';

import { FIELDS } from './constants';

export const getId = (meeting) => get(meeting, FIELDS.ID.name);

export const getDoctorId = (meeting) => get(meeting, FIELDS.DOCTOR_ID.name);

export const getMeetingNumber = (meeting) =>
  get(meeting, FIELDS.MEETING_NUMBER.name);

export const getPassword = (meeting) => get(meeting, FIELDS.PASSWORD.name);

export const getEncryptedPassword = (meeting) =>
  get(meeting, FIELDS.ENCRYPTED_PASSWORD.name);

export const getZoomEmail = (meeting) => get(meeting, FIELDS.ZOOM_EMAIL.name);

export const getAccountId = (meeting) => get(meeting, FIELDS.ACCOUNT_ID.name);

export const getClientId = (meeting) => get(meeting, FIELDS.CLIENT_ID.name);

export const getClientSecret = (meeting) =>
  get(meeting, FIELDS.CLIENT_SECRET.name);

export const getWebhookSecretToken = (meeting) =>
  get(meeting, FIELDS.WEBHOOK_SECRET_TOKEN.name);

export const getVerificationToken = (meeting) =>
  get(meeting, FIELDS.VERIFICATION_TOKEN.name);

export const getCreatedAt = (meeting) => get(meeting, FIELDS.CREATED_AT.name);

export const getCreatedBy = (meeting) => get(meeting, FIELDS.CREATED_BY.name);
