import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { userSelectors } from 'domain/user';
import { memberSelectors } from 'domain/user/member';
import { eligibilitySelectors, eligibilityActions } from 'domain/eligibility';
import {
  consultationSelectors,
  consultationActions,
} from 'domain/consultation';

import EligibilitySection from './EligibilitySection';

const mapStateToProps = (state) => {
  const isFetching = eligibilitySelectors.isFetching(state);
  const isFetchingMembers = memberSelectors.isFetchingMembers(state);
  const isSubmitting = eligibilitySelectors.isSubmitting(state);
  const isUpdatingConsultationPatient =
    consultationSelectors.isUpdatingConsultationPatient(state);

  const owner = userSelectors.getUser(state);
  const eligibilityRequests =
    eligibilitySelectors.getEligibilityRequests(state);
  let patients = consultationSelectors.getConsultationMembers(state) || [];
  const members = memberSelectors.getMembers(state) || [];

  const membersMap = members.reduce((result, item) => {
    const { id } = item;
    const member = { ...result };
    member[id] = item;
    return member;
  }, {});

  if (!isEmpty(owner)) {
    patients = patients.map((patient) => {
      // we need the id from patient "consultationPatientId", to not overwite the id from member, spread patient should be at the end
      if (patient.userId === owner.id) {
        return { ...owner, ...patient };
      }

      return {
        ...membersMap[patient.userId],
        ...patient,
      };
    });
  }

  return {
    patients,
    isFetching,
    isSubmitting,
    isFetchingMembers,
    eligibilityRequests,
    isUpdatingConsultationPatient,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEligibilityRequests: (referenceId, referenceType) =>
    dispatch(
      eligibilityActions.getEligibilityRequests(referenceId, referenceType)
    ),
  updateConsultationPatient: (consultationId, patientId, payload) =>
    dispatch(
      consultationActions.updateConsultationPatient(
        consultationId,
        patientId,
        payload
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EligibilitySection);
