import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import FileUpload from 'core/file/FileUpload';
import { getAppConfigEndpoint } from 'core/endpoint';
import { Controller } from 'design-system/form';
import ServerAutocomplete from 'core/serverAutocomplete';
import { CUSTOM_LAB_TESTS_LIST_ENDPOINT } from 'model/lab/constants';
import PatientOutcomeWizardDialog from 'presentation/components/PatientOutcomeWizardDialog';
import {
  OUTCOME_OPTIONS,
  OUTCOME_SUBMISSION_STATUS,
} from 'domain/outcome/constants';
import {
  Chip,
  Grid,
  Radio,
  Message,
  FormField,
  TextField,
  RadioGroup,
  FormHelperText,
  FormControlLabel,
} from 'design-system';

const AddLabTestDialog = ({
  open,
  userId,
  onClose,
  patients,
  initialTab = 0,
  submitLabTests,
  consultationId,
  onLabTestsSubmitted,
  isSubmittingLabTests,
}) => {
  const [isOpen, setOpen] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      labTests: [],
      hasLabTests: true,
      documents: [],
    };
  }, []);

  const hasFormChanged = useCallback((savedData = {}, currentData = {}) => {
    if (savedData.hasLabTests !== currentData.hasLabTests) {
      return true;
    }

    if (
      (savedData.labTests || []).sort().join(',') !==
      (currentData.labTests || []).sort().join(',')
    ) {
      return true;
    }

    // Only check IDs, because file status and other data can change in the process, and that wouldn't mean a change
    if (
      (savedData.documents || [])
        .map((labTest) => labTest.id)
        .sort()
        .join(',') !==
      (currentData.documents || [])
        .map((labTest) => labTest.id)
        .sort()
        .join(',')
    ) {
      return true;
    }

    return false;
  }, []);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <PatientOutcomeWizardDialog
      open={isOpen}
      onClose={onClosed}
      patients={patients}
      initialTab={initialTab}
      defaultValues={defaultValues}
      consultationId={consultationId}
      hasFormChanged={hasFormChanged}
      hasOutcomeFieldName="hasLabTests"
      referenceType={OUTCOME_OPTIONS.LabTestRequest.key}
      isSubmittingPatients={isSubmittingLabTests}
      outcomeToFormData={(outcome) => ({
        labTests: outcome.outputsList.map((test) => {
          return test?.name || test;
        }),
        hasLabTests: true,
        documents: outcome.referenceFiles.map((file) => ({
          id: file,
        })),
        completeDocuments: outcome.referenceFiles.map((file) => ({
          id: file,
        })),
        submissionStatus: outcome.submissionStatus,
      })}
      formDataToOutcomePayload={(formData) => ({
        outputsList: formData.labTests || [],
        referenceType: OUTCOME_OPTIONS.LabTestRequest.key,
        referenceFiles: (formData.documents || []).map(
          (document) => document.id
        ),
      })}
      submitAllPatients={(successCallback) => {
        submitLabTests(consultationId);
        onLabTestsSubmitted(() => {
          Message.success(
            'Lab test requests have been submitted successfully!'
          );

          successCallback();
        });
      }}
      labels={{
        dialogTitle: 'Request Lab Tests',
        ariaLabel: 'add-lab-test',
        outcomeLabel: 'lab test request',
        submittingLabel: 'Sending lab test requests to lab...',
        submitButtonTooltip:
          'Request lab tests for the patient and the team will be notified!',
      }}
    >
      {({
        watch,
        control,
        setValue,
        isNoShow,
        setDirtyFields,
        patientOutcomeFields,
      }) => (
        <Grid container column rowSpacing={1}>
          {/* Has Lab Tests */}
          <Grid item>
            <Controller
              name="hasLabTests"
              control={control}
              defaultValue={patientOutcomeFields?.hasLabTests}
              render={({
                field: { onChange, value },
                fieldState: { isDirty },
              }) => (
                <RadioGroup
                  row
                  value={value ? 'hasLabTests' : 'noLabTest'}
                  aria-labelledby="has-lab-test-radio-buttons-group-label"
                  name="has-lab-test-radio-buttons-group"
                  onChange={(e) => {
                    setDirtyFields((previousState) => ({
                      ...previousState,
                      hasLabTests: !isDirty,
                    }));
                    onChange(e.target.value === 'hasLabTests');
                  }}
                >
                  <FormControlLabel
                    value="hasLabTests"
                    label="Has lab tests"
                    control={<Radio size="small" disabled={isNoShow} />}
                  />
                  <FormControlLabel
                    value="noLabTest"
                    label="No lab tests"
                    control={
                      <Radio
                        size="small"
                        disabled={
                          // Checking referenceId should be enough,
                          // except that at first submission it won't be available
                          // but submissionStatus will be set to submitted in consultation/reducer
                          patientOutcomeFields?.submissionStatus ===
                            OUTCOME_SUBMISSION_STATUS.SUBMITTED.key ||
                          Boolean(patientOutcomeFields?.referenceId)
                        }
                      />
                    }
                  />
                </RadioGroup>
              )}
            />
            {(!isEmpty(patientOutcomeFields?.labTests) ||
              !isEmpty(patientOutcomeFields?.documents)) &&
              // Without "=== false" it will show the message for undefined value
              watch('hasLabTests') === false && (
                <FormHelperText
                  sx={{
                    color: (theme) => theme.palette.warning.main,
                  }}
                >
                  Marking as no lab tests will delete the uploaded document
                  and/or selected lab tests
                </FormHelperText>
              )}
          </Grid>

          {/* Lab Test */}
          <Grid item>
            <FormField
              required={
                watch('hasLabTests') && isEmpty(watch('completeDocuments'))
              }
              label="Lab Tests"
              field={
                <Controller
                  name="labTests"
                  control={control}
                  defaultValue={patientOutcomeFields?.labTests}
                  render={({
                    field: { onChange, value },
                    fieldState: { error, isDirty },
                  }) => (
                    <ServerAutocomplete
                      fullWidth
                      multiple
                      defaultValue={value || []}
                      baseURL={getAppConfigEndpoint()}
                      endpoint={CUSTOM_LAB_TESTS_LIST_ENDPOINT}
                      optionsEqual={(optionA, optionB) => {
                        const nameA = optionA?.name ?? optionA;
                        const nameB = optionB?.name ?? optionB;

                        return nameA === nameB;
                      }}
                      getOptionLabel={(option) => {
                        return option?.name || option || '';
                      }}
                      onChange={(selectedTests = []) => {
                        setDirtyFields((previousState) => ({
                          ...previousState,
                          labTests: !isDirty,
                        }));
                        onChange(
                          selectedTests
                            .map((test) => test?.name || test)
                            .filter(Boolean)
                        );
                      }}
                      getRequestParams={(searchQuery) => {
                        const urlSearchParams = new URLSearchParams();
                        urlSearchParams.append('term', searchQuery);
                        return urlSearchParams;
                      }}
                      throttle={(fn, searchQuery) => {
                        if (searchQuery.length >= 2) {
                          fn();
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          placeholder="ex: CBC"
                          error={error}
                          helperText={error?.message || ''}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 'auto',
                            },
                          }}
                          {...params}
                        />
                      )}
                      renderTags={(renderValue, getTagProps) => {
                        return renderValue.map((option, renderIndex) => {
                          const tagProps = getTagProps({
                            renderIndex,
                          });

                          return (
                            <Chip
                              size="medium"
                              color="primary"
                              label={option?.name || option}
                              onClick={tagProps?.onDelete}
                              {...tagProps}
                            />
                          );
                        });
                      }}
                      disabled={!watch('hasLabTests')}
                    />
                  )}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (
                          watch('hasLabTests') &&
                          isEmpty(watch('completeDocuments')) &&
                          isEmpty(value)
                        ) {
                          return 'Either a document or lab tests are required';
                        }
                      },
                    },
                  }}
                />
              }
            />
          </Grid>

          {/* Lab Tests' Request Documents */}
          <Grid item>
            <FormField
              required={watch('hasLabTests') && isEmpty(watch('labTests'))}
              label="Document"
              field={
                <Controller
                  name="documents"
                  control={control}
                  // Why using map? In order to refetch the files. Why? Because the File instance will be lost after saving the data
                  // The only source of truth is the id of the file upon which we do the fetch.
                  // We don't need to worry about network activity, because the files are cached in browser memory.
                  defaultValue={(patientOutcomeFields?.documents || []).map(
                    (document) => ({
                      id: document.id,
                    })
                  )}
                  render={({
                    field: { onChange, value = [] },
                    fieldState: { error },
                  }) => (
                    <FileUpload
                      userId={userId}
                      files={value}
                      // Disable if the value of has lab test is false
                      // watch watches the state changes on hasLabtest field
                      // Check react-hook-form documentation for more info
                      onChange={(files, isActualChange, _2, completeFiles) => {
                        onChange(files);

                        // Add another field to the form state called completePrescriptions,
                        // to keep track of which files have been fully uploaded or have IDs and being fetched
                        // i.e: statuses COMPLETE and FETCHING
                        // Check core/file/FileUpload for more info
                        setValue('completeDocuments', completeFiles);

                        setDirtyFields((previousState) => ({
                          ...previousState,
                          documents: isActualChange,
                        }));
                      }}
                      error={Boolean(error)}
                      helperText={error?.message || null}
                      disabled={!watch('hasLabTests')}
                      placeholder={
                        isEmpty(patientOutcomeFields?.documents)
                          ? 'Upload document'
                          : 'Replace current document'
                      }
                    />
                  )}
                  rules={{
                    validate: {
                      pendingUpload: (value) => {
                        if (
                          (watch('completeDocuments')?.length ?? 0) !==
                          (value?.length ?? 0)
                        ) {
                          return 'The document should finish uploading';
                        }
                      },
                      required: () => {
                        if (
                          watch('hasLabTests') &&
                          isEmpty(watch('completeDocuments')) &&
                          isEmpty(watch('labTests'))
                        ) {
                          return 'Either a document or lab tests are required';
                        }
                      },
                    },
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      )}
    </PatientOutcomeWizardDialog>
  );
};

export default AddLabTestDialog;
