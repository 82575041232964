import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import isValid from 'date-fns/isValid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';

import { userGetters } from 'domain/user';
import { isAllowed } from 'core/permission';
import { authSelectors } from 'domain/auth';
import { CsvExportButton } from 'core/export';
import { UserSearch } from 'presentation/user';
import { useDeviceSize } from 'design-system/hooks';
import { getDefaultListSorting } from 'core/siteConfig';
import { Button, DataGrid, PageTitle } from 'design-system';
import { filtration, DataGridCard, useServerPagination } from 'core/dataGrid';
import {
  consultationGetters as getters,
  consultationConstants as constants,
  CONSULTATION_DOMAIN_FIELDS as FIELDS,
} from 'domain/consultation';

import { SendBulkNotifications } from 'presentation/notification';
import { REFERENCE_TYPES } from 'constants/general';
import { getColumns, getFilterFieldsConfig } from './utils';

const { STATUSES, LS_CONSULTATION_FILTERS, MEETING_COMMUNICATION_METHOD } =
  constants;

function ConsultationsList({
  saveFilters,
  doctorsFullNamesMap,
  insuranceProvidersNameMap,
}) {
  const { isMobile, isTablet } = useDeviceSize();

  const selectedTenant = useSelector((state) =>
    authSelectors.getSelectedActiveTenant(state, true)
  );
  const { isDoctor, isClinicManager } = useSelector(authSelectors.getRole);

  const { params, filterValues } = filtration.getFilterParamsFromLs(
    getFilterFieldsConfig(isAllowed('common', 'tenantFilter'), isDoctor),
    LS_CONSULTATION_FILTERS
  );

  if (isAllowed('common', 'tenantFilter')) {
    params.append('filter', `tenantKey||$in||${selectedTenant}`);
  }

  const navigate = useNavigate();

  const [isFetching, setFetching] = useState(false);
  const {
    params: apiParams,
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/consultation',
    pageSize: 8,
    params,
    initialSortModel: (() => {
      if (isDoctor) {
        return { field: 'bookingDate', sort: 'asc' };
      }

      return getDefaultListSorting();
    })(),
    columnFieldsMap: {
      patient: 'userId',
      bookingDate: 'startTime',
      doctor: 'doctorId',
      phoneNumber: 'user.phoneNumber',
    },
  });

  const { updateFilter } = filtration.useServerFiltration({
    saveFilters,
    setQueryParams,
    initialValues: filterValues,
    fieldsConfig: getFilterFieldsConfig(
      isAllowed('common', 'tenantFilter'),
      isDoctor
    ),
    onQueryParamsChange: (urlSearchParams) => {
      const prms = new URLSearchParams(urlSearchParams);

      if (isAllowed('common', 'tenantFilter')) {
        prms.append('filter', `tenantKey||$in||${selectedTenant}`);
      }
      return prms;
    },
  });

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const filtersDef = {
    fields: [
      {
        name: 'patient',
        type: 'custom',
        component: (
          <UserSearch
            disabled={isFetching}
            defaultValue={filterValues.patient}
            onUserSelect={(selectedPatient) => {
              updateFilter('patient', selectedPatient);
            }}
          />
        ),
      },
      {
        name: 'status',
        type: 'autocomplete',
        placeholder: 'Status',
        icon: MonitorHeartOutlinedIcon,
        options: keys(STATUSES),
        getOptionLabel: (option) => STATUSES[option].label,
        defaultValue: filterValues.status,
        disabled: isFetching,
        filter: (selectedStatus) => {
          updateFilter('status', selectedStatus);
        },
      },
      {
        name: 'doctor',
        type: 'autocomplete',
        placeholder: 'Doctor',
        icon: LocalHospitalOutlinedIcon,
        options: keys(doctorsFullNamesMap),
        withNone: true,
        hide: isDoctor || isClinicManager,
        defaultValue: userGetters.getFullName(filterValues.doctor),
        disabled: isFetching || isEmpty(doctorsFullNamesMap),
        filter: (selectedFullName) => {
          const id = doctorsFullNamesMap[selectedFullName];

          updateFilter('doctor', {
            id,
            fullName: selectedFullName,
          });
        },
      },
      {
        name: 'bookingDateFrom',
        type: 'date',
        disabled: isFetching,
        icon: CalendarMonthOutlinedIcon,
        placeholder: 'From Booking Date',
        defaultValue: filterValues.bookingDateFrom,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('bookingDateFrom', timestamp);
          }
        },
      },
      {
        name: 'bookingDateUntil',
        type: 'date',
        disabled: isFetching,
        icon: CalendarMonthOutlinedIcon,
        placeholder: 'To Booking Date',
        defaultValue: filterValues.bookingDateUntil,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('bookingDateUntil', timestamp);
          }
        },
      },
      {
        name: 'createdBy',
        type: 'custom',
        hide: !isAllowed('consultationList', 'createdByFilter'),
        component: (
          <UserSearch
            disabled={isFetching}
            defaultValue={filterValues.createdBy}
            placeholder="Created by"
            onUserSelect={(selectedUser) => {
              if (isAllowed('consultationList', 'createdByFilter')) {
                updateFilter('createdBy', selectedUser);
              }
            }}
          />
        ),
      },
      {
        name: 'meetingCommunicationMethod',
        type: 'autocomplete',
        placeholder: 'Meeting Method',
        icon: PhoneOutlinedIcon,
        hide: !isAllowed('consultationList', 'meetingCommunicationMethod'),
        options: keys(MEETING_COMMUNICATION_METHOD),
        getOptionLabel: (option) => MEETING_COMMUNICATION_METHOD[option].label,
        defaultValue: filterValues.meetingCommunicationMethod,
        disabled: isFetching,
        filter: (selectedMethod) => {
          updateFilter('meetingCommunicationMethod', selectedMethod);
        },
      },
      {
        name: 'insuranceProvider',
        type: 'autocomplete',
        placeholder: 'Insurance Provider',
        icon: ShieldOutlinedIcon,
        options: keys(insuranceProvidersNameMap),
        withNone: true,
        defaultValue: filterValues.insuranceProvider?.englishName,
        hide: !isAllowed('consultationList', 'insuranceProviderFilter'),
        disabled: isFetching || isEmpty(insuranceProvidersNameMap),
        filter: (selectedInsuranceProviderName) => {
          const id = insuranceProvidersNameMap[selectedInsuranceProviderName];
          updateFilter('insuranceProvider', {
            id,
            englishName: selectedInsuranceProviderName,
          });
        },
      },
      {
        name: 'isLabTestRequested',
        type: 'switch',
        hide: isClinicManager,
        label: 'Lab Test',
        disabled: isFetching,
        defaultValue: filterValues.isLabTestRequested,
        filter: (isRequested) => {
          updateFilter('isLabTestRequested', isRequested);
        },
      },
    ],
  };

  const onRowClick = (row) => {
    navigate(`/consultation/${getters.getId(row)}`);
  };

  return (
    <>
      <PageTitle
        title="Consultations"
        action={
          <>
            {isAllowed('common', 'export') && (
              <CsvExportButton
                endpoint="/consultation"
                params={apiParams}
                fields={FIELDS}
              />
            )}

            {isAllowed('bulkNotification', 'consultations') && (
              <SendBulkNotifications
                loading={isFetching}
                referenceType={REFERENCE_TYPES.CONSULTATION}
                params={apiParams}
                rowCount={dataGridProps?.rowCount || 0}
              />
            )}

            {isAllowed('consultationList', 'newConsultation') && (
              <Button
                variant="filled"
                onClick={() => {
                  navigate('/consultation');
                }}
              >
                New consultation
              </Button>
            )}
          </>
        }
      />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          columns={getColumns({
            isDoctor,
            isMobile,
            isTablet,
            isClinicManager,
          })}
          {...(isAllowed('routes', 'consultationView')
            ? {
                onRowClick: (prms) => onRowClick(prms.row),
                onRowDblClick: ({ row }) => {
                  window.open(`/consultation/${getters.getId(row)}`, '_blank');
                },
                contextMenuItems: [
                  {
                    label: 'Open',
                    handler: onRowClick,
                  },
                  {
                    label: 'Open in a new tab',
                    handler: (row) => {
                      window.open(
                        `/consultation/${getters.getId(row)}`,
                        '_blank'
                      );
                    },
                  },
                ],
              }
            : {})}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
}

ConsultationsList.propTypes = {};

export default ConsultationsList;
