import React, { useState, useEffect } from 'react';

import { orderGetters } from 'domain/order';
import { isAllowed } from 'core/permission';
import { PaymentSection } from 'presentation/payment';
import { REFERENCE_TYPES } from 'constants/general';
import {
  If,
  Else,
  Card,
  Grid,
  Button,
  CardHeader,
  CardContent,
  Conditionally,
} from 'design-system';

import OrderPaymentAmountDialog from '../OrderPaymentAmountDialog';

const OrderPaymentSection = ({ user, order, onSubmit, loading }) => {
  const [isOpen, setOpen] = useState(false);

  const [id, setId] = useState();
  const [hasPaymentTransaction, setHasPaymentTransaction] = useState();

  useEffect(() => {
    if (order) {
      setId(orderGetters.getId(order));
      setHasPaymentTransaction(orderGetters.hasPaymentTransaction(order));
    }
  }, [order]);

  if (hasPaymentTransaction) {
    return (
      <PaymentSection
        user={user}
        isFetching={loading}
        referenceId={id}
        referenceType={REFERENCE_TYPES.ORDER}
        hasPaymentTransaction={hasPaymentTransaction}
        permissions={{
          update: isAllowed('orderView', 'payment', 'update'),
          resend: isAllowed('orderView', 'payment', 'resend'),
          cancel: isAllowed('orderView', 'payment', 'cancel'),
          create: isAllowed('orderView', 'payment', 'create'),
        }}
      />
    );
  }

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Payment" />
        <CardContent sx={{ height: '100%' }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
          >
            <Conditionally>
              <If
                condition={
                  isAllowed('orderView', 'payment', 'create') || loading
                }
              >
                <Grid
                  item
                  sx={{
                    ...(loading
                      ? {
                          width: '50%',
                        }
                      : {}),
                  }}
                >
                  <Button
                    variant="filled"
                    loading={loading}
                    onClick={() => setOpen(true)}
                  >
                    Create Payment Link
                  </Button>
                </Grid>
              </If>
              <Else>No payment information available</Else>
            </Conditionally>
          </Grid>
        </CardContent>
      </Card>
      <OrderPaymentAmountDialog
        open={isOpen}
        order={order}
        onSubmit={onSubmit}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default OrderPaymentSection;
