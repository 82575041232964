import React from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';
import { getDialerEndpoint } from 'core/endpoint';

const DialerDialog = ({ phoneNumber, open, onClose }) => {
  const link = `${getDialerEndpoint()}${phoneNumber}`;

  const handleClose = (_event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialer-dialog-title"
    >
      <DialogTitle id="dialer-dialog-title" onClose={handleClose}>
        Calling {phoneNumber}
      </DialogTitle>
      <DialogContent dividers>
        <iframe
          src={link}
          width={500}
          height={700}
          title="Maqsam"
          allow="microphone"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="filled" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialerDialog;
