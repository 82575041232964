import isEmpty from 'lodash/isEmpty';
import React, { useRef, useMemo, useState } from 'react';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

import Box from '../Box';
import Menu, { MenuItem } from '../Menu';
import DataGridFooter from './DataGridFooter';

const DataGrid = ({
  sx,
  rows,
  components,
  columns = [],
  pageSize = 10,
  defaultPage = 0,
  initialState = {},
  /**
   * contextMenuItems: Array of objects
   *
   * Schema: [{ handler: Function, label: String }]
   */
  contextMenuItems = [],
  pageSizeOptions = [5, 10, 25, 50, 100],
  footer = {
    pagination: true,
    isCountShown: true,
  },
  onRowClick,
  onRowDblClick,
  ...rest
}) => {
  const { pagination } = footer;

  const clickCount = useRef(0);
  const [page, setPage] = useState(defaultPage);
  const [contextMenu, setContextMenu] = useState(null);
  const [contextedRowId, setContextedRowId] = useState(null);

  /**
   * While migrating data-grid from v5 to v6, hiding columns approach changed.
   * It was part of the column definition (hide: true), but now it's in the initial state:
   * initialState={{
   *  columns: {
   *    columnVisibilityModel: {
   *      columnName: false,
   *    }
   *  }
   * }}
   */
  const columnVisibility = useMemo(() => {
    const visibility = {};

    columns.forEach((column) => {
      if (column.hide) {
        visibility[column.field] = false;
      }
    });

    return visibility;
  }, [columns]);

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextedRowId(Number(event.currentTarget.getAttribute('data-id')));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );

    // Auto close after 5 seconds
    setTimeout(() => {
      handleClose();
    }, 5000);
  };

  const contextMenuHandlerWrapper = (handler) => {
    return () => {
      const row = rows.find((r) => r.id === contextedRowId);

      handler(row);
      handleClose();
    };
  };

  const isContextMenuItemAllowed = (checkAllowed) => {
    const row = rows.find((r) => r.id === contextedRowId);

    return checkAllowed(row);
  };

  /**
   * Having both onRowClick and onRowDoubleClick is impossible according to DataGrid
   * onRowClick preceeds the double click event, so onRowDoubleClick doesn't execute.
   *
   * Had to have a workaround and count the count of clicks before execute either rowClick or rowDoubleClick.
   *
   * If you're still using onRowDoubleClick on its own, you don't have to use onRowDblClick. (Backward compatibility).
   * Bottom line, use onRowDblClick along with onRowClick.
   */
  const handleRowClick = (...args) => {
    clickCount.current += 1;

    setTimeout(() => {
      if (clickCount.current === 1) {
        onRowClick(...args);
      } else if (onRowDblClick && clickCount.current === 2) {
        onRowDblClick(...args);
      }

      clickCount.current = 0;
    }, 500);
  };

  return (
    <>
      <MuiDataGrid
        rows={rows}
        columns={columns}
        pagination={pagination}
        pageSizeOptions={pageSizeOptions}
        paginationModel={{ page, pageSize }}
        onPaginationModelChange={(model) => {
          setPage(model.page);
        }}
        initialState={{
          ...initialState,
          columns: {
            ...(initialState?.columns || {}),
            columnVisibilityModel: columnVisibility,
          },
        }}
        components={{
          ...(footer
            ? {
                // eslint-disable-next-line react/no-unstable-nested-components
                Footer: () => (
                  <Box sx={{ p: 2 }}>
                    <DataGridFooter {...footer} />
                  </Box>
                ),
              }
            : {}),
          ...components,
        }}
        componentsProps={{
          ...(!isEmpty(contextMenuItems)
            ? {
                row: {
                  onContextMenu: handleContextMenu,
                },
              }
            : {}),
        }}
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell': {
            pl: {
              xs: 1,
              md: 2,
            },
          },
          ...sx,
        }}
        {...(onRowClick
          ? {
              onRowClick: onRowDblClick ? handleRowClick : onRowClick,
            }
          : {})}
        {...rest}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        {(() => {
          const items = contextMenuItems
            .map((item) => {
              const { handler = () => {}, label, checkAllowed } = item;
              // If checkAllowed is passed, it has to be processed
              // otherwise, it's allowed by default
              const isAllowed = checkAllowed
                ? isContextMenuItemAllowed(checkAllowed)
                : true;

              if (isAllowed) {
                return (
                  <MenuItem onClick={contextMenuHandlerWrapper(handler)}>
                    {label}
                  </MenuItem>
                );
              }

              return null;
            })
            .filter(Boolean);

          if (isEmpty(items)) {
            return (
              <MenuItem>
                <i>No action available.</i>
              </MenuItem>
            );
          }

          return items;
        })()}
      </Menu>
    </>
  );
};

export default DataGrid;
