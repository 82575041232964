import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';

import Box from '../Box';
import Dropzone from './Dropzone';
import FilePreview from './FilePreview';
import FormHelperText from '../FormHelperText';
import { LinearProgress } from '../Progress';

const FileInput = ({
  loading,
  files = [],
  uploading,
  multiple = false,
  onDelete,
  onFilesUploaded = () => {},
  // maxSize: in bytes
  maxSize = 1024 * 1024 * 3,
  ...rest
}) => {
  const [errors, setErrors] = useState();
  const [filesState, setFilesState] = useState(files);

  useEffect(() => {
    if (!loading) setFilesState(files);
  }, [files]);

  const onDrop = (newFiles) => {
    const errs = {};
    const newFilesLength = newFiles?.length;
    const validatedFiles = newFiles.filter((file) => file.size <= maxSize);

    if (newFilesLength !== validatedFiles.length) {
      errs.maxSize = `Error in ${newFiles
        .filter((file) => file.size > maxSize)
        .map((file) => file.name)
        .join(', ')}. File size cannot exceed ${(
        maxSize /
        (1024 * 1024)
      ).toFixed(1)} mega bytes.`;
    }

    if (!isEmpty(errs)) {
      setErrors(errs);

      return;
    }

    setErrors();

    const allFiles = [...filesState, ...newFiles];
    onFilesUploaded(
      newFiles, // New files
      allFiles,
      filesState // Current files
    );
    setFilesState(allFiles);
  };

  return (
    <Box>
      <FilePreview
        files={filesState}
        loading={loading}
        onDelete={
          onDelete
            ? (fileId) => {
                setFilesState(filesState.filter((file) => file.id !== fileId));
                onDelete(fileId);
              }
            : undefined
        }
      />
      {uploading && (
        <>
          <FormHelperText>Uploading..</FormHelperText>
          <LinearProgress />
        </>
      )}
      {!isEmpty(errors) &&
        values(errors).map((err) => (
          <FormHelperText error>{err}</FormHelperText>
        ))}
      {(!loading || [undefined, true].includes(loading?.dropzone)) && (
        // If it's not loading, then show drop zone (to be able to upload)
        // If loading.dropzone is undefined or explicitly true,
        // then show Dropzone (loading is truthy so skeleton will show)
        <Dropzone
          onDrop={onDrop}
          loading={loading}
          multiple={multiple}
          sx={{
            mt: 2,
          }}
          {...rest}
        />
      )}
    </Box>
  );
};

export default FileInput;
