import { connect } from 'react-redux';

import { memberActions } from 'domain/user/member';
import { userActions, userConstants } from 'domain/user';
import { consultationActions } from 'domain/consultation';

import PatientInfoForm from './PatientInfoForm';

const { FIELDS } = userConstants;

const mapDispatchToProps = (dispatch) => ({
  modifyConsultationPatientFullName: (patientId, fullName) =>
    dispatch(
      consultationActions.consultationPatientUpdated(patientId, {
        [FIELDS.FULL_NAME.name]: fullName,
      })
    ),
  submitOwnerHealthProfile: (userId, payload, successCallback) =>
    dispatch(userActions.updateHealthProfile(userId, payload, successCallback)),
  submitMemberHealthProfile: (memberUserId, payload, successCallback) =>
    dispatch(
      memberActions.updateMemberHealthProfile(
        memberUserId,
        payload,
        successCallback
      )
    ),
  submitOwnerName: (userId, payload, successCallback) =>
    dispatch(userActions.updateUser(userId, payload, successCallback)),
  submitMemberName: (memberUserId, payload, successCallback) =>
    dispatch(
      memberActions.updateMemberUserProfile(
        memberUserId,
        payload,
        successCallback
      )
    ),
});

export default connect(null, mapDispatchToProps)(PatientInfoForm);
