import * as types from './actionTypes';

const initialState = {
  isSubmittingAlmaosUser: false,
  isFetchingAlmaosUser: false,
  almaosUser: {},
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SUBMITTING_ALMAOS_USER: {
      return {
        ...state,
        isSubmittingAlmaosUser: action.isSubmitting,
      };
    }
    case types.SET_FETCHING_ALMAOS_USER: {
      return {
        ...state,
        isFetchingAlmaosUser: action.isFetching,
      };
    }
    case types.ALMAOS_USER_RECEIVED: {
      return {
        ...state,
        almaosUser: action.user,
      };
    }
    default:
      return state;
  }
};

export default userManagementReducer;
