import * as types from './actionTypes';

export const fetchCommentsOf = (referenceType, referenceId) => ({
  type: types.FETCH_COMMENTS_OF,
  referenceType,
  referenceId,
});

export const createCommentOf = (referenceType, referenceId, content) => ({
  type: types.CREATE_COMMENT_OF,
  referenceType,
  referenceId,
  content,
});

export const commentsReceived = (comments, referenceType, referenceId) => ({
  type: types.COMMENTS_RECEIVED,
  referenceType,
  referenceId,
  comments,
});

export const commentCreated = (comment, referenceType, referenceId) => ({
  type: types.COMMENT_CREATED,
  referenceType,
  referenceId,
  comment,
});
