import { all, put, take, takeEvery } from 'redux-saga/effects';

import { fileModelActions, fileModelActionTypes } from 'model/file';

import * as actions from './actions';
import * as types from './actionTypes';

export function* createFiles({
  rawFiles,
  fileType: type,
  userId,
  referenceType,
  successCallback = () => {},
  failCallback = () => {},
}) {
  const patchId = Date.now();

  yield put(actions.setCreatingFiles(true));

  const promises = rawFiles.map((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        const blob = new Blob([new Uint8Array(e.target.result)], {
          type: file.type,
        });
        resolve(blob);
      };

      reader.readAsArrayBuffer(file);
    });
  });

  const blobs = yield all(promises);

  yield put(fileModelActions.createFiles(blobs, type, userId, patchId));

  while (true) {
    const {
      ok,
      response,
      patchId: id,
    } = yield take(fileModelActionTypes.FILES_CREATION_RESPONDED);

    if (patchId === id) {
      if (ok) {
        const fileIds = response.map((file) => file.id);

        yield put(fileModelActions.fetchFiles(fileIds, referenceType));

        const action = yield take(
          `${fileModelActionTypes.FILES_RECEIVED}/${referenceType}`
        );

        yield put(actions.filesCreated(action.files, referenceType));

        successCallback(fileIds);
      } else {
        failCallback();
      }

      yield put(actions.setCreatingFiles(false));
    }
  }
}

export function* fetchFilesOf({ fileIds, referenceType, referenceId }) {
  yield put(actions.setFetchingFilesOf(true, referenceType, referenceId));
  yield put(
    fileModelActions.fetchFiles(fileIds, `${referenceType}/${referenceId}`)
  );

  const { files } = yield take(
    `${fileModelActionTypes.FILES_RECEIVED}/${referenceType}/${referenceId}`
  );

  yield put(actions.filesReceivedOf(files, referenceType, referenceId));
  yield put(actions.setFetchingFilesOf(false, referenceType, referenceId));
}

export default function* fileSaga() {
  yield all([
    takeEvery(types.CREATE_FILES, createFiles),
    takeEvery(types.FETCH_FILES_OF, fetchFilesOf),
  ]);
}
