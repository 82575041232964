import React, { useEffect, useState } from 'react';
import keys from 'lodash/keys';
import { Select, MenuItem } from 'design-system';
import ELIGIBILITY_STATUS from '../constants';

function SelectEligibility({
  eligibilityStatus,
  referenceId,
  consultationPatientId,
  updateConsultationPatient,
  isUpdatingConsultationPatient,
}) {
  const [status, setStatus] = useState(eligibilityStatus);
  const handleChange = (event) => {
    setStatus(event.target.value);
    const payload = {
      eligibilityStatus: event.target.value,
    };
    updateConsultationPatient(referenceId, consultationPatientId, payload);
  };
  useEffect(() => {
    setStatus(eligibilityStatus);
  }, [eligibilityStatus]);

  return (
    <Select
      fullWidth
      size="large"
      soak="light"
      value={status || '---'}
      disabled={isUpdatingConsultationPatient}
      variant="filled"
      color={ELIGIBILITY_STATUS[status]?.color}
      onChange={(event) => handleChange(event)}
    >
      {keys(ELIGIBILITY_STATUS).map((key) => {
        return (
          <MenuItem key={key} value={key}>
            {ELIGIBILITY_STATUS[key].label || 'N/A'}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default SelectEligibility;
