import React, { useState } from 'react';
import MuiTooltip from '@mui/material/Tooltip';

import Conditionally, { If, Else } from '../Conditionally';

const DEFAULT_AUTO_HIDE_DURATION = 5000;

const Tooltip = ({
  autoHide = false,
  injectWrapper = false,
  children,
  ...rest
}) => {
  const [open, setOpen] = useState(null);

  const handleOpen = () => {
    setOpen(true);

    if (autoHide) {
      setTimeout(
        () => {
          setOpen(false);
        },
        typeof autoHide === 'number' ? autoHide : DEFAULT_AUTO_HIDE_DURATION
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Conditionally>
      <If condition={injectWrapper}>
        <MuiTooltip
          {...rest}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          <span>{children}</span>
        </MuiTooltip>
      </If>
      <Else>
        <MuiTooltip
          {...rest}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {children}
        </MuiTooltip>
      </Else>
    </Conditionally>
  );
};

export default Tooltip;
