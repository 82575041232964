/* User domain constants */
export const PACKAGE_NAME = 'User';

export const LS_USER_FILTERS = 'user_filters';
export const LS_LEAD_USER_FILTERS = 'lead_user_filters';

function displayUser(user) {
  return user?.fullName || '';
}

export const FIELDS = {
  USER: {
    name: 'user',
  },
  ID: {
    name: 'id',
  },
  FULL_NAME: {
    name: 'fullName',
  },
  PHONE_NUMBER: {
    name: 'phoneNumber',
  },
  CITY: {
    name: 'city',
  },
  INSURANCE_PROVIDER: {
    name: 'insuranceProvider',
  },
  INSURANCE_PROVIDER_ID: {
    name: 'insuranceProviderId',
  },
  IDENTITY_NUMBER: {
    name: 'identityNumber',
  },
  IDENTITY_BACK: {
    name: 'identityBack',
  },
  IDENTITY_FRONT: {
    name: 'identityFront',
  },
  ADDRESSES: {
    name: 'addresses',
  },
  SIGN_UP_SOURCE: {
    name: 'signupSource',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  LAST_ACTIVE: {
    name: 'lastActive',
  },
  STATUS: {
    name: 'status',
  },
  IS_VERIFIED: {
    name: 'verified',
  },
  LANGUAGE: {
    name: 'language',
  },
  TAG: {
    name: 'tag',
  },
  TAGS: {
    name: 'tags',
  },
  TENANT_KEY: {
    name: 'tenantKey',
  },
  NATIONALITY: {
    name: 'nationality',
  },
  EMAIL: {
    name: 'email',
  },
  EMERGENCY_CONTACTS: {
    name: 'emergencyContacts',
  },
  EXTERNAL_REFERENCE_NUMBER: {
    name: 'externalReferenceId',
  },
  INSURANCE_CARD_NUMBER: { name: 'insuranceCardNumber' },
  INSURANCE_TYPE: { name: 'insuranceType' },
  INSURANCE_GROUP: { name: 'insuranceGroup' },
  INSURANCE_PLAN: { name: 'insurancePlan' },
  INSURANCE_VALID_UNTIL: { name: 'insuranceValidUntil' },
  INSURANCE_CARD_FILE_NAME: { name: 'insuranceCardFileName' },
  COMMENTS: { name: 'comments' },
  USER_ID: { name: 'userId' },
  CREATED_BY_USER: {
    name: 'createdByUser',
    valueGetter: displayUser,
  },
  UPDATED_BY_USER: {
    name: 'updatedByUser',
    valueGetter: displayUser,
  },
  NAMESPACE_KEY: {
    name: 'namespaceKey',
  },
  REFERENCE_FULL_NAME: {
    name: 'referenceFullName',
  },
  IDENTITY: {
    name: 'identity',
  },
  OWNERSHIP_TYPE: {
    name: 'type',
  },
  OWNER_ID: {
    name: 'ownerId',
  },
};

export const HEALTH_PROFILE_FIELDS = {
  WEIGHT: {
    name: 'weight',
  },
  HEIGHT: {
    name: 'height',
  },
  BLOOD_TYPE: {
    name: 'bloodType',
  },
  GENDER: {
    name: 'gender',
  },
  DATE_OF_BIRTH: {
    name: 'dob',
  },
  CHRONIC_CONDITIONS: {
    name: 'diseases',
  },
  USER_ID: {
    name: 'userId',
  },
  OWNER_ID: {
    name: 'ownerId',
  },
  ALLERGIES: {
    name: 'allergies',
  },
};

export const ADDRESS_FIELDS = {
  ID: {
    name: 'id',
  },
  ORDER_ID: {
    name: 'orderId',
  },
  APARTMENT: {
    name: 'apartment',
  },
  BUILDING: {
    name: 'building',
  },
  AREA: {
    name: 'area',
  },
  CITY: {
    name: 'city',
  },
  COUNTRY: {
    name: 'country',
  },
  FORMATTED_ADDRESS: {
    name: 'formattedAddress',
  },
  LATITUDE: {
    name: 'latitude',
  },
  LONGITUDE: {
    name: 'longitude',
  },
  PLACE_ID: {
    name: 'placeId',
  },
  STREET: {
    name: 'street',
  },
  REFERENCE_MAP_LINK: {
    name: 'referenceMapLink',
  },
};

export const SIGN_UP_SOURCE = {
  APP: {
    key: 'APP',
    label: 'Mobile App',
    color: 'success',
  },
  'MOBILE WEB': {
    key: 'MOBILE WEB',
    label: 'Mobile Web',
    color: 'success',
  },
  SOCIAL: {
    key: 'SOCIAL',
    label: 'Social',
    color: 'primary',
  },
  CLINIC: {
    key: 'CLINIC',
    label: 'Clinic',
    color: 'primary',
  },
  'PARTNER CLINIC': {
    key: 'PARTNER CLINIC',
    label: 'Partner Clinic',
    color: 'primary',
  },
  PHARMACY: {
    key: 'PHARMACY',
    label: 'Pharmacy',
    color: 'primary',
  },
  'NextCare GK': {
    key: 'NextCare GK',
    label: 'NextCare GK',
    color: 'primary',
  },
  IMPORT: {
    key: 'IMPORT',
    label: 'Import',
    color: 'warning',
  },
  'CONTACTS IMPORT': {
    key: 'CONTACTS IMPORT',
    label: 'Contacts Import',
    color: 'warning',
  },
  WLP: {
    key: 'WLP',
    label: 'WLP',
    color: 'primary',
  },
  MEMBER_IMPORT: {
    key: 'MEMBER_IMPORT',
    label: 'MEMBER IMPORT',
    color: 'warning',
  },
  APP_MEMBER: {
    key: 'APP_MEMBER',
    label: 'APP MEMBER',
    color: 'warning',
  },
};

export const USER_TAGS = {
  'Not Interested': {
    key: 'Not Interested',
    label: 'Not Interested',
    color: 'error',
  },
  'Low Quality': {
    key: 'Low Quality',
    label: 'Low Quality',
    color: 'error',
  },
  'Not Covered Insurance': {
    key: 'Not Covered Insurance',
    label: 'Not Covered Insurance',
    color: 'warning',
  },
  'Booked Consultation': {
    key: 'Booked Consultation',
    label: 'Booked Consultation',
    color: 'success',
  },
  'High Value': {
    key: 'High Value',
    label: 'High Value',
    color: 'success',
  },
  RequestedDeleteAccount: {
    key: 'RequestedDeleteAccount',
    label: 'Requested Delete Account',
    color: 'error',
  },
  'Eligible No Answer': {
    key: 'Eligible No Answer',
    label: 'Eligible / No Answer',
    color: 'warning',
  },
  'Called No Answer': {
    key: 'Called No Answer',
    label: 'Called No Answer',
    color: 'warning',
  },
  'Over Utilizing': {
    key: 'Over Utilizing',
    label: 'Over-utilization',
    color: 'error',
  },
};

export const GENDER = {
  male: { key: 'male', label: 'Male' },
  female: { key: 'female', label: 'Female' },
};

export const BLOOD_TYPE = {
  'AB-': { name: 'AB-' },
  'AB+': { name: 'AB+' },
  'A-': { name: 'A-' },
  'A+': { name: 'A+' },
  'B-': { name: 'B-' },
  'B+': { name: 'B+' },
  'O-': { name: 'O-' },
  'O+': { name: 'O+' },
};

export const DISEASES = {
  diabetes: { label: 'Diabetes', key: 'diabetes' },
  hypertension: {
    label: 'Hypertension (High blood pressure)',
    key: 'hypertension',
  },
  cholesterol: { label: 'Cholesterol', key: 'cholesterol' },
  heartDisease: { label: 'Heart Disease', key: 'heartDisease' },
  asthma: { label: 'Asthma', key: 'asthma' },
  rheumatoid: { label: 'Rheumatoid', key: 'rheumatoid' },
  epilepsy: { label: 'Epilepsy', key: 'epilepsy' },
  chronicKidneyDisease: {
    label: 'Chronic Kidney Disease',
    key: 'chronicKidneyDisease',
  },
  headache: { label: 'Headache disorders', key: 'headache' },
  adrenalGlandDisorder: {
    label: 'Adrenal Gland Disorders',
    key: 'adrenalGlandDisorder',
  },
  heartFailure: { label: 'Heart Failure', key: 'heartFailure' },
  alzheimerDisease: {
    label: 'Alzheimer Disease and Dementia',
    key: 'alzheimerDisease',
  },
  chronicObstructivePulmonary: {
    label: 'Chronic Obstructive Pulmonary',
    key: 'chronicObstructivePulmonary',
  },
  stroke: { label: 'Stroke', key: 'stroke' },
  hepatitisC: { label: 'Hepatitis C', key: 'hepatitisC' },
  chronicCough: { label: 'Chronic Cough', key: 'chronicCough' },
  hepaticFailure: { label: 'Hepatic Failure', key: 'hepaticFailure' },
  neuropathy: { label: 'Neuropathy', key: 'neuropathy' },
  anemia: { label: 'Anemia', key: 'anemia' },
  cirrhosis: { label: 'Cirrhosis', key: 'cirrhosis' },
  osteoporosis: { label: 'Osteoporosis', key: 'osteoporosis' },
  acidReflux: { label: 'Acid reflux', key: 'acidReflux' },
  sciatica: { label: 'Sciatica', key: 'sciatica' },
  sinusitis: { label: 'Sinusitis', key: 'sinusitis' },
  allergie: { label: 'Allergie', key: 'allergie' },
  thyroidDisease: { label: 'Thyroid disease', key: 'thyroidDisease' },
  autoimmuneDisease: { label: 'Autoimmune Disease', key: 'autoimmuneDisease' },
  liverDisease: { label: 'Liver Disease', key: 'liverDisease' },
  arthralgia: { label: 'Arthralgia', key: 'arthralgia' },
  allergicRhinitis: { label: 'Allergic Rhinitis', key: 'allergicRhinitis' },
  vitaminDDeficiency: {
    label: 'Vitamin D Deficiency',
    key: 'vitaminDDeficiency',
  },
  irritableBowelSyndrome: {
    label: 'Irritable bowel syndrome',
    key: 'irritableBowelSyndrome',
  },
  degenerativeDiskDisease: {
    label: 'Degenerative Disk Disease',
    key: 'degenerativeDiskDisease',
  },
  colitis: { label: 'Colitis', key: 'colitis' },
  eczyma: { label: 'Eczyma', key: 'eczyma' },
  skinAllergy: { label: 'Skin Allergy', key: 'skinAllergy' },
  psoriasis: { label: 'Psoriasis', key: 'psoriasis' },
  acne: { label: 'Acne', key: 'acne' },
  hyperpigmentation: { label: 'Hyperpigmentation', key: 'hyperpigmentation' },
  lossOfAppetite: { label: 'Loss of Appetite', key: 'lossOfAppetite' },
  hairLoss: { label: 'Hair Loss', key: 'hairLoss' },
  anxiety: { label: 'Anxiety', key: 'anxiety' },
  depression: { label: 'Depression', key: 'depression' },
  cancer: { label: 'Cancer', key: 'cancer' },
};

export const USER_STATUSES = {
  LeadImported: {
    key: 'LeadImported',
    label: 'Lead Imported',
  },
  LeadUnverified: {
    key: 'LeadUnverified',
    label: 'Lead Unverified',
  },
  LeadVerified: {
    key: 'LeadVerified',
    label: 'Lead Verified',
  },
  Active: {
    key: 'Active',
    label: 'Active',
  },
  Inactive: {
    key: 'Inactive',
    label: 'Inactive',
  },
};

export const USER_OWNERSHIP_TYPE = {
  Owner: {
    key: 'Owner',
    label: 'Owner',
  },
  Member: {
    key: 'Member',
    label: 'Member',
  },
};

export const MEMBER_RELATIONSHIP = {
  Wife: {
    key: 'Wife',
    label: 'Wife',
  },
  Husband: {
    key: 'Husband',
    label: 'Husband',
  },
  Son: {
    key: 'Son',
    label: 'Son',
  },
  Daughter: {
    key: 'Daughter',
    label: 'Daughter',
  },
  Father: {
    key: 'Father',
    label: 'Father',
  },
  Mother: {
    key: 'Mother',
    label: 'Mother',
  },
  Other: {
    key: 'Other',
    label: 'Other',
  },
};

export const ALLERGIES_LIST_TYPE = {
  FOOD_ALLERGY: {
    key: 'FOOD_ALLERGY',
    label: 'Food Allergy',
  },
  DRUG_ALLERGY: {
    key: 'DRUG_ALLERGY',
    label: 'Drug Allergy',
  },
  MISCELLANEOUS_ALLERGY: {
    key: 'MISCELLANEOUS_ALLERGY',
    label: 'Miscellaneous Allergy',
  },
  MISCELLANEOUS_CONTRAINDICATION: {
    key: 'MISCELLANEOUS_CONTRAINDICATION',
    label: 'Miscellaneous Contraindication',
  },
  ENVIRONMENTAL_ALLERGY: {
    key: 'ENVIRONMENTAL_ALLERGY',
    label: 'Environmental Allergy',
  },
  ANIMAL_ALLERGY: {
    key: 'ANIMAL_ALLERGY',
    label: 'Animal Allergy',
  },
  PLANT_ALLERGY: {
    key: 'PLANT_ALLERGY',
    label: 'Plant Allergy',
  },
  NOT_AVAILABLE: {
    key: 'NOT_AVAILABLE',
    label: 'Not Available',
  },
};
export const ALLERGIES_SEVERITY_LIST_TYPE = {
  SEVERE: {
    key: 'SEVERE',
    label: 'Severe',
  },
  MODERATE: {
    key: 'MODERATE',
    label: 'Moderate',
  },
  MILD: {
    key: 'MILD',
    label: 'Mild',
  },
  UNKNOWN: {
    key: 'UNKNOWN',
    label: 'Unknown',
  },
};
