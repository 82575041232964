import * as types from './actionTypes';

const initialState = {
  view: {},
  eLabRequests: {}, // By Id
  labPackages: [],
  isFetching: false,
  isFetchingDocuments: false,
  isSubmitting: false,
  isFetchingOthers: false,
  eLabRequestDocument: undefined,
  patientsLabRequestDocuments: {},
  isSubmittingPatientELabRequests: false,
};

const labReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LAB_REQUEST_RECEIVED: {
      return {
        ...state,
        view: { ...(action.labRequest || {}) },
      };
    }
    case types.LAB_PACKAGES_RECEIVED: {
      return {
        ...state,
        labPackages: [...(action.labPackages || [])],
      };
    }
    case types.E_LAB_REQUEST_DOCUMENT_CREATED: {
      return {
        ...state,
        eLabRequestDocument: action.document,
      };
    }
    case types.PATIENT_E_LAB_REQUEST_RECEIVED: {
      const { eLabRequest, userId } = action;

      return {
        ...state,
        eLabRequests: {
          ...state.eLabRequests,
          [userId]: eLabRequest,
        },
      };
    }
    case types.PATIENT_E_LAB_REQUEST_DELETED: {
      const { userId } = action;
      const stateELabRequests = { ...(state?.eLabRequests || {}) };

      delete stateELabRequests[userId];

      return {
        ...state,
        eLabRequests: stateELabRequests,
      };
    }
    case types.SET_SUBMITTING_PATIENT_E_LAB_REQUESTS: {
      const { isSubmittingPatientELabRequests } = action;

      return {
        ...state,
        isSubmittingPatientELabRequests,
      };
    }
    case types.CLEAR_PATIENTS_E_LAB_REQUESTS: {
      return {
        ...state,
        eLabRequests: {},
      };
    }
    case types.PATIENT_E_LAB_REQUEST_SUBMITTED: {
      const { eLabRequest, userId } = action;

      return {
        ...state,
        eLabRequests: {
          ...state.eLabRequests,
          [userId]: eLabRequest,
        },
      };
    }
    case types.PATIENT_LAB_REQUEST_DOCUMENT_CREATED: {
      const patientsDocuments = { ...state.patientsLabRequestDocuments };

      return {
        ...state,
        patientsLabRequestDocuments: {
          ...patientsDocuments,
          [action.patientId]: action.document,
        },
      };
    }
    case types.SET_FETCHING_LAB_REQUEST: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_FETCHING_LAB_REQUEST_OTHERS: {
      return {
        ...state,
        isFetchingOthers: action.isFetching,
      };
    }
    case types.SET_FETCHING_DOCUMENTS: {
      return {
        ...state,
        isFetchingDocuments: action.isFetchingDocuments,
      };
    }
    case types.SET_SUBMITTING_LAB_REQUEST: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.CONSULTATION_LABS_RECEIVED: {
      return {
        ...state,
        consultationLabRequests: [...(action.labTests || [])],
      };
    }
    default:
      return state;
  }
};

export default labReducer;
