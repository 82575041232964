import React from 'react';
import { DataGrid, PageTitle, Button } from 'design-system';
import { DataGridCard, useServerPagination } from 'core/dataGrid';
import { useNavigate } from 'react-router-dom';

const ConsultationTypeManagementList = () => {
  const navigate = useNavigate();

  const onRowClick = (row) => {
    navigate(`/consultation-type/${row.id}`);
  };

  const { props: dataGridProps } = useServerPagination({
    endpoint: '/consultation-types',
    initialSortModel: { field: 'displayOrder', sort: 'asc' },
  });

  const columns = [
    { field: 'id', headerName: 'ID', width: 60 },
    {
      field: 'englishTitle',
      headerName: 'Name',
      flex: 1,
    },

    {
      field: 'displayOrder',
      headerName: 'Display Order',
      flex: 1,
    },

    {
      field: 'isVisibleForUsers',
      headerName: 'Visible For Patient',
      flex: 1,
      valueGetter: (params) => (params.row?.isVisibleForUsers ? 'YES' : 'NO'),
    },
  ];

  return (
    <>
      <PageTitle
        title="Consultation Type Management"
        action={
          <Button
            variant="filled"
            onClick={() => {
              navigate(`/consultation-type`);
            }}
          >
            Add new consultation type
          </Button>
        }
      />
      <DataGridCard>
        <DataGrid
          onRowClick={(prms) => onRowClick(prms.row)}
          columns={columns}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => {
                window.open(`/consultation-type/${row.id}`, '_blank');
              },
            },
          ]}
        />
      </DataGridCard>
    </>
  );
};

export default ConsultationTypeManagementList;
