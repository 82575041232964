import { connect } from 'react-redux';

import { labActions, labSelectors } from 'domain/lab';

import LabRequestForm from './LabRequestForm';

const mapStateToProps = (state) => {
  const patientELabRequestById = (userId) =>
    labSelectors.getELabRequestById(state, userId);

  return {
    patientELabRequestById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createPatientELabRequest: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      labActions.createPatientELabRequest(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
  updatePatientELabRequest: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      labActions.updatePatientELabRequest(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabRequestForm);
