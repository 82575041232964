import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as COMMENT } from './constants';

export const FETCH_COMMENTS_OF = `${DOMAIN}/${COMMENT}/FETCH_COMMENTS_OF`;
export const CLEAR_COMMENTS_OF = `${DOMAIN}/${COMMENT}/CLEAR_COMMENTS_OF`;
export const CREATE_COMMENT_OF = `${DOMAIN}/${COMMENT}/CREATE_COMMENT_OF`;

export const COMMENTS_RECEIVED = `${DOMAIN}/${COMMENT}/COMMENTS_RECEIVED`;

export const SET_FETCHING_COMMENTS_OF = `${DOMAIN}/${COMMENT}/SET_FETCHING_COMMENTS_OF`;
