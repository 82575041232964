import * as types from './actionTypes';

export const createPrescriptions = (
  prescriptions,
  genericMedicine,
  consultationId,
  userId,
  tenant
) => ({
  type: types.CREATE_PRESCRIPTIONS,
  genericMedicine,
  consultationId,
  prescriptions,
  userId,
  tenant,
});

export const fetchOrder = (id) => ({
  type: types.FETCH_ORDER,
  id,
});

export const fetchPinnedOrders = () => ({
  type: types.FETCH_PINNED_ORDERS,
});

export const createOrder = (payload) => ({
  type: types.CREATE_ORDER,
  payload,
});

export const patchOrder = (id, payload, successCallback, failureCallback) => ({
  type: types.PATCH_ORDER,
  payload,
  id,
  successCallback,
  failureCallback,
});

export const fetchSearchedOrder = (id, successCb) => ({
  type: types.FETCH_SEARCHED_ORDER,
  id,
  successCb,
});

export const addLineItem = (orderId, payload) => ({
  type: types.ADD_LINE_ITEM,
  payload,
  orderId,
});

export const addLineItems = (orderId, lineItems) => ({
  type: types.ADD_LINE_ITEMS,
  lineItems,
  orderId,
});

export const updateLineItem = (orderId, id, payload) => ({
  type: types.UPDATE_LINE_ITEM,
  payload,
  orderId,
  id,
});

export const deleteLineItem = (orderId, id) => ({
  type: types.DELETE_LINE_ITEM,
  orderId,
  id,
});

export const addDeliveryAddress = (orderId, payload) => ({
  type: types.ADD_DELIVERY_ADDRESS,
  orderId,
  payload,
});

export const updateDeliveryAddress = (id, orderId, payload) => ({
  type: types.UPDATE_DELIVERY_ADDRESS,
  orderId,
  payload,
  id,
});

export const orderReceived = (order) => ({
  type: types.ORDER_RECEIVED,
  order,
});

export const searchedOrderReceived = (searchedOrder) => ({
  type: types.SEARCHED_ORDER_RECEIVED,
  searchedOrder,
});

export const pinnedOrdersReceived = (pinnedOrders) => ({
  type: types.PINNED_ORDERS_RECEIVED,
  pinnedOrders,
});

export const setSearchedOrderFetchError = (error) => ({
  type: types.SET_SEARCHED_ORDER_FETCH_ERROR,
  error,
});

export const deliveryAddressChanged = () => ({
  type: types.DELIVERY_ADDRESS_CHANGED,
});

export const setFetching = (isFetching) => ({
  type: types.SET_FETCHING,
  isFetching,
});

export const setSubmittingOrder = (isSubmitting) => ({
  type: types.SET_SUBMITTING,
  isSubmitting,
});

export const setFetchingPrescriptions = (isFetchingPrescriptions) => ({
  type: types.SET_FETCHING_PRESCRIPTIONS,
  isFetchingPrescriptions,
});

export const setFetchingSearchedOrder = (isFetchingSearchedOrder) => ({
  type: types.SET_FETCHING_SEARCHED_ORDER,
  isFetchingSearchedOrder,
});

export const setFetchingPinnedOrders = (isFetchingPinnedOrders) => ({
  type: types.SET_FETCHING_PINNED_ORDERS,
  isFetchingPinnedOrders,
});

export const setUpdatingLineItems = (isUpdatingLineItems) => ({
  type: types.SET_UPDATING_LINE_ITEMS,
  isUpdatingLineItems,
});

export const fetchLogisticProviders = () => ({
  type: types.FETCH_LOGISTIC_PROVIDERS,
});
export const logisticProvidersResponded = (logisticProviders) => ({
  type: types.LOGISTIC_PROVIDERS_RESPONDED,
  logisticProviders,
});

export const fetchShipment = (orderId) => ({
  type: types.FETCH_SHIPMENT,
  orderId,
});
export const shipmentFetchReceived = (shipment) => ({
  type: types.SHIPMENT_FETCH_RECEIVED,
  shipment,
});

export const shipmentLabelResponded = (shipmentLabel) => ({
  type: types.SHIPMENT_LABEL_RESPONDED,
  shipmentLabel,
});

export const createLogisticProviderShipment = (payload, callback) => ({
  type: types.CREATE_LOGISTIC_PROVIDER_SHIPMENT,
  payload,
  callback,
});
export const setFetchingTaskId = (isFetchingTaskId) => ({
  type: types.SET_FETCHING_TASK_ID,
  isFetchingTaskId,
});

export const fetchShipmentLabel = (orderId, callback) => ({
  type: types.FETCH_SHIPMENT_LABEL,
  orderId,
  callback,
});

export const fetchOrderHistory = (id) => ({
  type: types.FETCH_ORDER_HISTORY,
  id,
});

export const orderHistoryReceived = (orderHistory) => ({
  type: types.ORDER_HISTORY_RECEIVED,
  orderHistory,
});

export const setFetchingOrderHistory = (isFetchingOrderHistory) => ({
  type: types.SET_FETCHING_ORDER_HISTORY,
  isFetchingOrderHistory,
});

export const fetchLineItemsHistory = (orderId) => ({
  type: types.FETCH_LINE_ITEMS_HISTORY,
  orderId,
});

export const lineItemsHistoryReceived = (lineItemsHistory) => ({
  type: types.LINE_ITEMS_HISTORY_RECEIVED,
  lineItemsHistory,
});

export const setFetchingLineItemsHistory = (isFetchingLineItemsHistory) => ({
  type: types.SET_FETCHING_LINE_ITEMS_HISTORY,
  isFetchingLineItemsHistory,
});
