/* Schedule domain constants */
export const PACKAGE_NAME = 'Schedule';

export const DOCTOR_SCHEDULE_FIELDS = {
  ID: {
    name: 'id',
  },
  DOCTOR_ID: {
    name: 'doctorId',
  },
  DOCTOR: {
    name: 'doctor',
  },
  AVAILABILITY_RANGES: {
    name: 'availabilityTimes',
  },
};

export const AVAILABILITY_RANGE_FIELDS = {
  ID: {
    name: 'id',
  },
  SCHEDULE_ID: {
    name: 'scheduleId',
  },
  DAY_OF_WEEK: {
    name: 'dayOfWeek',
  },
  READABLE_DAY_OF_WEEK: {
    name: 'readableDayOfWeek',
  },
  START_TIME: {
    name: 'startTime',
  },
  END_TIME: {
    name: 'endTime',
  },
  FORMATTED_START_TIME: {
    name: 'formattedStartTime',
  },
  FORMATTED_END_TIME: {
    name: 'formattedEndTime',
  },
  START_TIME_PARTS: {
    name: 'startTimeParts',
  },
  END_TIME_PARTS: {
    name: 'endTimeParts',
  },
};

export const TIME_SLOT_STATUSES = {
  FREE: {
    key: 'FREE',
  },
  BOOKED: {
    key: 'BOOKED',
  },
};
