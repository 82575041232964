import {
  all,
  put,
  call,
  delay,
  take,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { Message } from 'design-system';
import { navigate } from 'routing/utils';
import { userActions } from 'domain/user';
import { takeSequential } from 'core/saga';
import { REFERENCE_TYPES } from 'constants/general';
import { fileModelActions, fileModelActionTypes } from 'model/file';
import { orderModelActions, orderModelActionTypes } from 'model/order';
import {
  FILE_TYPES,
  fileActions,
  fileGetters,
  fileActionTypes,
} from 'domain/file';

import * as actions from './actions';
import * as types from './actionTypes';
import * as selectors from './selectors';
import * as getters from './getters/order';
import { FIELDS, CHANNELS } from './constants';

const currentState = (state) => state;

/**
 *
 * Create a new prescription/order for a consulation
 */
export function* createPrescriptions({
  userId,
  tenant,
  prescriptions,
  consultationId,
  genericMedicine,
}) {
  const payload = {
    [FIELDS.USER_ID.name]: userId,
    [FIELDS.TENANT_KEY.name]: tenant,
    [FIELDS.PRESCRIPTIONS.name]: prescriptions,
    [FIELDS.CONSULTATION_ID.name]: consultationId,
    [FIELDS.IS_GENERIC_MEDICINE.name]: genericMedicine,
    [FIELDS.CHANNEL.name]: CHANNELS['ALMA CLINIC'].key,
  };

  yield put(orderModelActions.createPrescriptions(payload));
}

export function* prescriptionsCreated() {
  Message.success('Prescriptions sent successfully');
}

export function* orderFetchResponded({ ok, response: order }) {
  const prescriptionsIds = getters.getPrescriptions(order);

  if (ok) {
    yield put(actions.orderReceived(order));
    yield put(actions.setFetching(false));

    if (prescriptionsIds) {
      yield put(
        fileModelActions.fetchFiles(prescriptionsIds, REFERENCE_TYPES.ORDER)
      );

      const action = yield take(
        `${fileModelActionTypes.FILES_RECEIVED}/${REFERENCE_TYPES.ORDER}`
      );

      yield put(actions.setFetchingPrescriptions(false));
      yield put(fileActions.filesCreated(action.files, REFERENCE_TYPES.ORDER));
    }
  } else {
    yield put(actions.setFetching(false));
  }

  yield put(actions.setFetchingPrescriptions(false));
}

export function* fetchOrder({ id }) {
  yield put(actions.orderReceived());
  yield put(actions.setSearchedOrderFetchError(''));
  yield put(fileActions.clearFiles(REFERENCE_TYPES.ORDER));

  yield put(actions.setFetching(true));
  yield put(orderModelActions.fetchOrder(id));
  yield put(actions.setFetchingPrescriptions(true));

  const action = yield take(orderModelActionTypes.ORDER_FETCH_RESPONDED);
  yield call(orderFetchResponded, action);
}

export function* createOrder({ payload }) {
  const userId = getters.getUserId(payload);
  const rawFiles = getters.getPrescriptions(payload);

  yield put(actions.setSubmittingOrder(true));
  yield put(fileActions.clearFiles(REFERENCE_TYPES.ORDER));
  yield put(
    fileActions.createFiles(
      rawFiles,
      FILE_TYPES.DOCUMENTS,
      userId,
      REFERENCE_TYPES.ORDER
    )
  );

  const { files } = yield take(fileActionTypes.FILES_CREATED);
  const documents = files.map((file) => fileGetters.getId(file));
  const updatedPayload = {
    ...payload,
    [FIELDS.PRESCRIPTIONS.name]: documents,
  };

  yield put(orderModelActions.createOrder(updatedPayload));
}

export function* patchOrder({ id, payload, successCallback, failureCallback }) {
  yield put(actions.setSubmittingOrder(true));
  yield put(
    orderModelActions.patchOrder(id, {
      [FIELDS.ID.name]: id,
      ...payload,
    })
  );

  const { ok } = yield take(orderModelActionTypes.ORDER_UPDATE_RESPONDED);

  if (ok && successCallback) {
    successCallback();
  } else if (failureCallback) {
    failureCallback();
  }

  yield put(actions.setSubmittingOrder(false));
}

export function* updateOrderAfterLineItemChange(orderId) {
  const state = yield select(currentState);
  const isSubmitting = selectors.isSubmitting(state);

  if (isSubmitting) {
    yield take(orderModelActionTypes.ORDER_UPDATE_RESPONDED);
  }

  yield put(orderModelActions.fetchOrder(orderId));

  const { ok, response: order } = yield take(
    orderModelActionTypes.ORDER_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.orderReceived(order));
  }
}

export function* fetchSearchedOrder({ id, successCb }) {
  yield put(actions.searchedOrderReceived());
  yield put(actions.setFetchingSearchedOrder(true));
  yield put(actions.setSearchedOrderFetchError(''));

  yield put(orderModelActions.fetchOrder(id));

  const { ok, response } = yield take(
    orderModelActionTypes.ORDER_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.searchedOrderReceived(response));

    if (successCb) {
      successCb(response);
    }
  } else {
    yield put(actions.setSearchedOrderFetchError(response));
  }

  yield put(actions.setFetchingSearchedOrder(false));
}

export function* fetchPinnedOrders() {
  yield put(actions.pinnedOrdersReceived());
  yield put(actions.setFetchingPinnedOrders(true));

  yield put(orderModelActions.fetchPinnedOrders());

  const { ok, response } = yield take(
    orderModelActionTypes.PINNED_ORDERS_FETCH_RESPONDED
  );

  if (ok) {
    yield put(
      actions.pinnedOrdersReceived(
        response.map((order) => {
          return {
            ...order,
            [FIELDS.IS_PINNED.name]: true,
          };
        })
      )
    );
  }

  yield put(actions.setFetchingPinnedOrders(false));
}

export function* fetchOrderHistory({ id }) {
  yield put(actions.orderHistoryReceived());
  yield put(actions.setFetchingOrderHistory(true));
  yield put(orderModelActions.fetchOrderHistory(id));

  const { ok, response } = yield take(
    orderModelActionTypes.ORDER_HISTORY_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.orderHistoryReceived(response));
  }

  yield put(actions.setFetchingOrderHistory(false));
}

export function* fetchLineItemsHistory({ orderId }) {
  yield put(actions.lineItemsHistoryReceived());
  yield put(actions.setFetchingLineItemsHistory(true));
  yield put(orderModelActions.fetchLineItemsHistory(orderId));

  const { ok, response } = yield take(
    orderModelActionTypes.LINE_ITEMS_HISTORY_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.lineItemsHistoryReceived(response));
  }

  yield put(actions.setFetchingLineItemsHistory(false));
}

export function* addLineItem({ orderId, payload }) {
  yield put(actions.setUpdatingLineItems(true));
  yield put(orderModelActions.addLineItem(payload));
  yield take(orderModelActionTypes.LINE_ITEM_ADDED);
  yield call(updateOrderAfterLineItemChange, orderId);
  yield put(actions.setUpdatingLineItems(false));

  Message.success('Medicine line item has been added successfully');
}

export function* addLineItems({ orderId, lineItems }) {
  yield put(actions.setUpdatingLineItems(true));

  for (let i = 0; i < lineItems.length; i += 1) {
    yield put(orderModelActions.addLineItem(lineItems[i]));
    yield take(orderModelActionTypes.LINE_ITEM_ADDED);
  }

  yield call(updateOrderAfterLineItemChange, orderId);
  yield put(actions.setUpdatingLineItems(false));
}

export function* updateLineItem({ id, orderId, payload }) {
  yield put(actions.setUpdatingLineItems(true));
  yield put(orderModelActions.updateLineItem(id, payload));
  yield take(orderModelActionTypes.LINE_ITEM_UPDATED);
  yield call(updateOrderAfterLineItemChange, orderId);
  yield put(actions.setUpdatingLineItems(false));

  Message.success('Medicine line item has been updated successfully');
}

export function* deleteLineItem({ id, orderId }) {
  yield put(actions.setUpdatingLineItems(true));
  yield put(orderModelActions.deleteLineItem(id));
  yield take(orderModelActionTypes.LINE_ITEM_DELETED);
  yield call(updateOrderAfterLineItemChange, orderId);
  yield put(actions.setUpdatingLineItems(false));

  Message.info('Medicine line item has been deleted successfully');
}

export function* addDeliveryAddress({ payload }) {
  yield put(orderModelActions.addDeliveryAddress(payload));

  const state = yield select(currentState);
  const oldOrder = selectors.getOrder(state);

  yield take(orderModelActionTypes.DELIVERY_ADDRESS_ADDED);
  yield put(orderModelActions.fetchOrder(getters.getId(oldOrder)));

  const { ok, response: order } = yield take(
    orderModelActionTypes.ORDER_FETCH_RESPONDED
  );

  if (ok) {
    yield put(
      actions.orderReceived({
        ...order,
      })
    );
  }

  yield put(actions.deliveryAddressChanged(false));

  Message.success('Delivery address has been added to the order successfully');
}

export function* updateDeliveryAddress({ id, payload }) {
  yield put(orderModelActions.updateDeliveryAddress(id, payload));
  yield take(orderModelActionTypes.DELIVERY_ADDRESS_UPDATED);
  yield put(actions.deliveryAddressChanged(false));

  Message.success("Order's delivery address has been updated successfully");
}

export function* orderCreationResponded({ status, data, message }) {
  yield put(actions.setSubmittingOrder(false));

  if (status) {
    yield put(userActions.clearUser());
    yield put(fileActions.clearFiles(REFERENCE_TYPES.ORDER));

    navigate(`/order/${data.id}`);

    setTimeout(() => {
      Message.success('Order created successfully');
    }, 0);
    yield put(actions.setFetchingTaskId(false));
  } else {
    Message.error(message);
  }
}

export function* fetchLogisticProviders() {
  // TODO cache them
  yield put(orderModelActions.fetchLogisticProviders());
}

export function* shipmentFetchReceived({ ok, shipment }) {
  if (ok) {
    yield put(actions.shipmentFetchReceived(shipment));
  }
}

export function* fetchShipment({ orderId }) {
  yield put(orderModelActions.fetchShipment(orderId));
}

export function* logisticProvidersResponded({ logisticProviders }) {
  yield put(actions.logisticProvidersResponded(logisticProviders));
}

export function* shipmentLabelResponded({ shipmentLabel }) {
  yield put(actions.shipmentLabelResponded(shipmentLabel));
}

export function* createLogisticProviderShipment({ payload, callback }) {
  yield put(actions.setSubmittingOrder(true));
  yield put(actions.setFetchingTaskId(true));
  yield put(orderModelActions.createLogisticProviderShipment(payload));
  const shipmentCreatedResponse = yield take(
    orderModelActionTypes.LOGISTIC_PROVIDER_SHIPMENT_CREATION_RESPONDED
  );
  callback(shipmentCreatedResponse.ok);
  yield delay(5000);

  yield put(orderModelActions.fetchOrder(payload.shipmentId));
  const { ok, response: order } = yield take(
    orderModelActionTypes.ORDER_FETCH_RESPONDED
  );
  yield put(actions.setFetchingTaskId(false));
  if (ok) {
    yield put(actions.orderReceived(order));
  }
  yield put(actions.setSubmittingOrder(false));
}

export function* fetchShipmentLabel({ orderId, callback }) {
  yield put(orderModelActions.fetchShipmentLabel(orderId));
  yield put(actions.setFetchingTaskId(true));
  const response = yield take(orderModelActionTypes.SHIPMENT_LABEL_RESPONDED);
  yield put(actions.setSubmittingOrder(false));
  yield put(actions.setFetchingTaskId(false));
  callback(response.shipmentLabel);
}

export default function* orderSaga() {
  yield all([
    takeSequential(types.PATCH_ORDER, patchOrder),
    takeLatest(types.FETCH_ORDER, fetchOrder),
    takeLatest(types.CREATE_ORDER, createOrder),
    takeLatest(types.ADD_LINE_ITEM, addLineItem),
    takeLatest(types.ADD_LINE_ITEMS, addLineItems),
    takeLatest(types.UPDATE_LINE_ITEM, updateLineItem),
    takeLatest(types.DELETE_LINE_ITEM, deleteLineItem),
    takeLatest(types.FETCH_ORDER_HISTORY, fetchOrderHistory),
    takeLatest(types.FETCH_PINNED_ORDERS, fetchPinnedOrders),
    takeLatest(types.ADD_DELIVERY_ADDRESS, addDeliveryAddress),
    takeLatest(types.FETCH_SEARCHED_ORDER, fetchSearchedOrder),
    takeLatest(types.CREATE_PRESCRIPTIONS, createPrescriptions),
    takeLatest(types.UPDATE_DELIVERY_ADDRESS, updateDeliveryAddress),
    takeLatest(types.FETCH_LINE_ITEMS_HISTORY, fetchLineItemsHistory),
    takeLatest(
      orderModelActionTypes.PRESCRIPTIONS_CREATED,
      prescriptionsCreated
    ),
    takeLatest(
      orderModelActionTypes.ORDER_CREATION_RESPONDED,
      orderCreationResponded
    ),
    takeLatest(types.FETCH_LOGISTIC_PROVIDERS, fetchLogisticProviders),
    takeLatest(types.FETCH_SHIPMENT, fetchShipment),

    takeLatest(
      orderModelActionTypes.LOGISTIC_PROVIDERS_RESPONDED,
      logisticProvidersResponded
    ),

    takeLatest(
      orderModelActionTypes.SHIPMENT_FETCH_RESPONDED,
      shipmentFetchReceived
    ),

    takeLatest(
      orderModelActionTypes.SHIPMENT_LABEL_RESPONDED,
      shipmentLabelResponded
    ),
    takeLatest(
      types.CREATE_LOGISTIC_PROVIDER_SHIPMENT,
      createLogisticProviderShipment
    ),
    takeLatest(types.FETCH_SHIPMENT_LABEL, fetchShipmentLabel),
  ]);
}
