/**
 * Returns a new clone of the file
 *
 * @param {File} file
 */
File.prototype.copy = function () {
  return new File([this], this.name, {
    type: this.type,
  });
};
