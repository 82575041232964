import * as types from './actionTypes';

const initialState = {
  list: {},
  isFetching: {},
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMMENTS_RECEIVED: {
      return {
        ...state,
        list: {
          ...state.list,
          [action.referenceType]: {
            ...state.list[action.referenceType],
            [action.referenceId]: action.comments,
          },
        },
      };
    }
    case types.CLEAR_COMMENTS_OF: {
      return {
        ...state,
        list: {
          ...state.list,
          [action.referenceType]: {
            ...state.list[action.referenceType],
            [action.referenceId]: {},
          },
        },
      };
    }
    case types.SET_FETCHING_COMMENTS_OF: {
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [action.referenceType]: {
            ...state.isFetching[action.referenceType],
            [action.referenceId]: action.isFetching,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default commentReducer;
