import get from 'lodash/get';
import React, { useState, useEffect } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { dateFormat, timeFormat } from 'constants/dateTime';
import {
  Box,
  Grid,
  Tooltip,
  TextField,
  DatePicker,
  Typography,
  RequiredMark,
  FormHelperText,
  MobileTimePicker,
} from 'design-system';

const DateTimeRangePicker = ({
  date,
  error,
  config,
  endTime,
  disabled,
  disabledTimePicker,
  startTime,
  helperText,
  title = '',
  isFetching,
  onDateChange,
  onEndTimeChange,
  onStartTimeChange,
  textUpperCase = true,
  minDate,
}) => {
  const [internalEndTime, setInternalEndTime] = useState();
  const [internalStartTime, setInternalStartTime] = useState();
  const [internalDate, setInternalDate] = useState();

  useEffect(() => {
    if (
      new Date(startTime).getTime() !== new Date(internalStartTime).getTime()
    ) {
      setInternalStartTime(startTime);
    }
  }, [startTime]);

  useEffect(() => {
    if (new Date(endTime).getTime() !== new Date(internalEndTime).getTime()) {
      setInternalEndTime(endTime);
    }
  }, [endTime]);

  useEffect(() => {
    if (
      new Date(date).getDay() !== new Date(internalDate).getDay() &&
      new Date(date).getMonth() !== new Date(internalDate).getMonth() &&
      new Date(date).getFullYear() !== new Date(internalDate).getFullYear()
    ) {
      setInternalDate(endTime);
    }
  }, [date]);

  return (
    <>
      <Grid item>
        <Typography
          variant="p4"
          loading={isFetching && { width: 125 }}
          sx={{
            textTransform: textUpperCase ? 'uppercase' : 'capitalize',
          }}
        >
          {`${title} Date`.trim()}
        </Typography>
        {get(config, 'date.required', false) && <RequiredMark />}
        {get(config, 'date.showHelp', false) && (
          <Tooltip
            placement="top"
            title="Click on the icon that appears to the right of the field in order to choose the day of the consultation."
          >
            <HelpOutlineOutlinedIcon
              fontSize="extraSmall"
              color="secondary"
              sx={{ mb: -0.375 }}
            />
          </Tooltip>
        )}
      </Grid>
      <Grid item sx={{ mb: 1 }}>
        <DatePicker
          disabled={disabled}
          inputFormat={dateFormat}
          value={internalDate}
          minDate={minDate}
          renderInput={(props) => (
            <TextField
              fullWidth
              size="large"
              error={error}
              variant="filled"
              loading={isFetching}
              {...props}
            />
          )}
          onChange={(newValue) => {
            onDateChange(newValue);
            setInternalDate(newValue);
          }}
        />
      </Grid>
      <Grid
        item
        container
        flexWrap="nowrap"
        alignItems="center"
        sx={{
          mb: 1,
        }}
      >
        <Grid item container column sx={{ width: '100%' }}>
          <Grid item sx={{ mb: 1 }}>
            <Typography
              variant="p4"
              loading={isFetching && { width: 70 }}
              sx={{
                textTransform: textUpperCase ? 'uppercase' : 'capitalize',
              }}
            >
              Start Time
            </Typography>
            {get(config, 'startTime.required', false) && <RequiredMark />}
            {get(config, 'startTime.showHelp', false) && (
              <Tooltip
                placement="top"
                title="Click on the the field in order to choose the start time of the consultation."
              >
                <HelpOutlineOutlinedIcon
                  fontSize="extraSmall"
                  color="secondary"
                  sx={{ mb: -0.375 }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            <MobileTimePicker
              disabled={disabled || disabledTimePicker}
              value={internalStartTime}
              inputFormat={timeFormat}
              onChange={(newValue) => {
                setInternalStartTime(newValue);
              }}
              onAccept={(newValue) => {
                onStartTimeChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="large"
                  error={error}
                  variant="filled"
                  loading={isFetching && { width: '100%' }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            alignSelf: 'flex-end',
            mb: 1.25,
          }}
        >
          {!isFetching && <RemoveIcon fontSize="small" />}
          {isFetching && <Box sx={{ width: (theme) => theme.spacing(2) }} />}
        </Grid>
        <Grid item container column sx={{ width: '100%' }}>
          <Grid item sx={{ mb: 1 }}>
            <Typography
              variant="p4"
              loading={isFetching && { width: 70 }}
              sx={{
                textTransform: textUpperCase ? 'uppercase' : 'capitalize',
              }}
            >
              End Time
            </Typography>
            {get(config, 'endTime.required', false) && <RequiredMark />}
            {get(config, 'endTime.showHelp', false) && (
              <Tooltip
                placement="top"
                title="Click on the field in order to choose the end time of the consultation."
              >
                <HelpOutlineOutlinedIcon
                  fontSize="extraSmall"
                  color="secondary"
                  sx={{ mb: -0.375 }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            <MobileTimePicker
              disabled={disabled || disabledTimePicker}
              value={internalEndTime}
              onChange={(newValue) => {
                setInternalEndTime(newValue);
              }}
              onAccept={(newValue) => {
                onEndTimeChange(newValue);
              }}
              inputFormat={timeFormat}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="large"
                  error={error}
                  variant="filled"
                  loading={isFetching && { width: '100%' }}
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      {helperText && (
        <Grid item>
          <FormHelperText error={error}>{helperText}</FormHelperText>
        </Grid>
      )}
    </>
  );
};

export default DateTimeRangePicker;
