import jwtDecode from 'jwt-decode';

import logger from 'core/logger';
import * as authConstants from './constants';

export const validateToken = (token) => {
  if (!token) return false;

  try {
    const { exp } = jwtDecode(token);

    if (exp * 1000 > new Date().getTime()) {
      return true;
    }

    return false;
  } catch (e) {
    logger.error(e);
    return false;
  }
};

export const logOutClean = () => {
  // clear all local storage including auth, filters .. etc
  localStorage.clear();
  localStorage.setItem(
    authConstants.LS_PRE_LOGIN_PATH,
    window.location.pathname
  );
};
