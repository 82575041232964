import pluralize from 'pluralize';
import intervalToDuration from 'date-fns/intervalToDuration';

export const LONG_WEEK_DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const SHORT_WEEK_DAYS = LONG_WEEK_DAYS.map((day) => day.slice(0, 3));

export const getWeekDayFromNumber = (dayNumber, isLong = true) => {
  if (isLong) {
    return LONG_WEEK_DAYS[dayNumber];
  }
  return SHORT_WEEK_DAYS[dayNumber];
};

export const getLocalTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const isMidnight = (date) => {
  return date.getHours() === 0; // Consider midnight if date between(12:00-12:59)
};

/**
 *
 * @param {Date} date
 */
export const calculateAge = (date) => {
  const durationObject = intervalToDuration({
    start: date,
    end: new Date(),
  });

  const { years, months, days } = durationObject;

  // E.g: 20 years 1 month 2 days
  const fullDisplay = `${years} ${pluralize(
    'year',
    years
  )} ${months} ${pluralize('month', months)} ${days} ${pluralize(
    'days',
    days
  )}`;

  // General display. E.g: 29 years or 9 months or 20 days
  const ageDisplay = (() => {
    if (years) {
      return years;
    }

    if (months) {
      return `${months} ${pluralize('month', months)}`;
    }

    if (days) {
      return `${days} ${pluralize('day', days)}`;
    }

    return 0;
  })();

  return {
    durationObject,
    years,
    months,
    days,
    fullDisplay,
    ageDisplay,
  };
};
