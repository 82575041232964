import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import appData from 'domain/app/HOCs/appData';
import { userModelActionTypes } from 'model/user';
import { userActions, userSelectors } from 'domain/user';

import UserCreate from './UserCreate';

const mapStateToProps = (state) => {
  const isSubmitting = userSelectors.isSubmittingUser(state);

  return {
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createUser: (payload) => dispatch(userActions.createUser(payload)),
  pursueUserCreationResponse: (callback) =>
    dispatch(
      appActions.pursueAction(
        userModelActionTypes.USER_CREATION_RESPONDED,
        callback
      )
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(UserCreate)
);
