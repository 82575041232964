import { connect } from 'react-redux';
import { doctorNotesActions, doctorNotesSelectors } from 'domain/doctorNotes';
import ReviewOfSystemsForm from './ReviewOfSystemsForm';

const mapStateToProps = (state) => {
  const getDoctorNotesById = (userId) =>
    doctorNotesSelectors.getDoctorNotesById(state, userId);
  return {
    getDoctorNotesById,
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitPatientReviewOfSystemsForm: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      doctorNotesActions.submitDoctorNotes(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewOfSystemsForm);
