import get from 'lodash/get';

import { AVAILABILITY_RANGE_FIELDS } from '../constants';

export const getId = (time) => get(time, AVAILABILITY_RANGE_FIELDS.ID.name);

export const getScheduleId = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.SCHEDULE_ID.name);

export const getDayOfWeek = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.DAY_OF_WEEK.name);

export const getReadableDayOfWeek = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.READABLE_DAY_OF_WEEK.name);

export const getStartTime = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.START_TIME.name);

export const getEndTime = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.END_TIME.name);

export const getFormattedStartTime = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.FORMATTED_START_TIME.name);

export const getFormattedEndTime = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.FORMATTED_END_TIME.name);

export const getStartTimeParts = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.START_TIME_PARTS.name);

export const getEndTimeParts = (time) =>
  get(time, AVAILABILITY_RANGE_FIELDS.END_TIME_PARTS.name);
