import React from 'react';

const PrescriptionIcon = ({ width = 32, height = 32 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 3.55556V28.4444C32 30.4 30.4 32 28.4444 32H3.55556C1.6 32 0 30.4 0 28.4444V3.55556C0 1.6 1.6 0 3.55556 0H28.4444C30.4 0 32 1.6 32 3.55556ZM7.50664 13.9614H7.82804L12.0386 20.2772L7.42457 27.5981H11.4029L13.9867 23.1891L16.9191 27.5981H21L16.2424 20.4618L20.8154 13.2024H16.8371L14.2943 17.5498L11.9077 13.9614H11.9245C12.9772 13.647 13.8227 12.8472 14.3559 12.0953C14.9027 11.3434 15.1761 10.4889 15.1761 9.53197C15.1761 8.68436 14.9779 7.91878 14.5814 7.23522C14.185 6.538 13.5834 5.99115 12.7768 5.59469C11.9839 5.19823 11.0201 5 9.88541 5H4V19.3957H7.50664V13.9614ZM7.50664 11.4801H9.68034C10.3229 11.4801 10.8014 11.3229 11.1158 11.0084C11.4439 10.694 11.608 10.2497 11.608 9.67552C11.608 9.12867 11.4439 8.69803 11.1158 8.3836C10.8014 8.06916 10.3229 7.91194 9.68034 7.91194H7.50664V11.4801Z"
      fill="#00D6AA"
    />
  </svg>
);

export default PrescriptionIcon;
