import EditIcon from '@mui/icons-material/Edit';
import React, { useState, useCallback } from 'react';

import Grid from '../Grid';
import Button from '../Button';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import IconButton from '../IconButton';
import RequiredMark from '../RequiredMark';
import Conditionally, { If, Else } from '../Conditionally';
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog';

/**
 * A field with an edit icon, which clicked a modal is shown to edit that field.
 *
 * Check PatientInfoForm.jsx for an example
 */
const ModalField = ({
  // Field label
  label,
  // Error object { message: 'error message' }
  error,
  // Boolean if field is read only
  readonly,
  // Boolean if field is required
  required,
  // Passed value from outside
  defaultValue,
  // Unit
  unit,
  // Used to distinguish between fields
  identifier = 'modal-field-default-identifier',
  // The field as JSX
  render,
  // Checks whether the value is untruthy
  isFalsy = (v) => !v,
  // Function that returns a string (or null) as content
  // eslint-disable-next-line no-unused-vars
  valueGetter = (_v) => null,
  // Function as component that returns JSX (or null)
  // eslint-disable-next-line no-unused-vars
  renderValue = (_v) => null,
  // Render extra action in dialog actions
  renderDialogAction,
  // On save button clicked
  onSave,
  // On closed
  onClosed,
  // Props related to <Dialog />
  dialogProps = {},
}) => {
  const [isSaving, setSaving] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const onClose = useCallback(() => {
    setEditModalOpen(false);

    onClosed?.();
  }, [onClosed]);

  /**
   * Used when the X button is closed
   */
  const close = useCallback(() => {
    // Do close the modal
    onClose();

    // Reset the value (Discard changes)
    setValue(defaultValue);
  }, [defaultValue]);

  if (readonly) {
    // If readonly is true, we only show the label without being able to edit
    // There's no current case for it now, but it was a case when we used to show Phone Number
    return (
      <Grid container column>
        {label && (
          <Grid item>
            <Typography
              variant="p4"
              textTransform="uppercase"
              color={Boolean(error) && 'error'}
            >
              {label}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          alignItems="center"
          sx={{ minHeight: (thm) => thm.spacing(4) }}
        >
          <Typography
            variant={isFalsy(defaultValue) ? 'p4' : 'l4'}
            sx={{ ...(isFalsy(defaultValue) && { fontStyle: 'italic' }) }}
          >
            {valueGetter(defaultValue) ||
              renderValue({ defaultValue }) ||
              'None'}{' '}
            {!isFalsy(defaultValue) ? unit : ''}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {/* Field */}
      <Grid container column>
        <Grid item>
          <Typography
            variant="p4"
            textTransform="uppercase"
            color={Boolean(error) && 'error'}
          >
            {label}
          </Typography>
          {required && <RequiredMark />}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          flexWrap="nowrap"
          sx={{
            minHeight: (theme) => theme.spacing(4),
          }}
        >
          <Conditionally>
            <If condition={isFalsy(defaultValue)}>
              <Typography
                variant="p4"
                sx={{
                  fontStyle: 'italic',
                  minWidth: '50px',
                  ...(error
                    ? {
                        color: (theme) => theme.palette.error.main,
                      }
                    : {}),
                }}
              >
                {(() => {
                  if (error?.message) {
                    return error.message;
                  }
                  if (required) {
                    return 'Enter value...';
                  }

                  return 'None';
                })()}
              </Typography>
            </If>
            <Else>
              <Typography variant="l4">
                {valueGetter(defaultValue) ||
                  renderValue({ value: defaultValue })}{' '}
                {unit}
              </Typography>
            </Else>
          </Conditionally>
          <IconButton
            sx={{ ml: 0.5, alignSelf: 'flex-start' }}
            onClick={(e) => {
              e.stopPropagation();
              setEditModalOpen(true);
            }}
          >
            <Tooltip title="Edit" placement="top">
              <EditIcon fontSize="extraSmall" color="primary" />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
      {/* Dialog/Modal */}
      <Dialog
        maxWidth="md"
        {...dialogProps} // Overrides props above. Props below are not overridable
        fullWidth
        onClose={close}
        open={isEditModalOpen}
        aria-labelledby={`${identifier}-dialog`}
      >
        <DialogTitle id={`${identifier}-dialog`} onClose={close}>
          Edit {label}
        </DialogTitle>
        <DialogContent dividers>
          {render({
            value,
            onChange: (e) =>
              setValue(
                (() => {
                  if (e?.target) {
                    return e.target.value;
                  }

                  return e;
                })()
              ),
            setSaving,
            isSaving,
            close: onClose,
          })}
        </DialogContent>
        <DialogActions sx={{ px: 2 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
          >
            <Grid item>{renderDialogAction ? renderDialogAction() : null}</Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: '100%',
                  md: 'auto',
                },
              }}
            >
              <Button
                spinning={isSaving}
                onClick={() => {
                  setSaving(true);
                  onSave(value, {
                    success: () => {
                      onClose();
                      setSaving(false);
                    },
                    error: () => {
                      setSaving(false);
                    },
                  });
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalField;
