import { connect } from 'react-redux';

import { commentActions, commentSelectors } from 'domain/comment';

import Comments from './Comments';

const mapStateToProps = (state, ownProps) => {
  const { referenceType, referenceId } = ownProps;

  const comments = commentSelectors.getCommentsOf(
    state,
    referenceType,
    referenceId
  );
  const loading = commentSelectors.isFetching(
    state,
    referenceType,
    referenceId
  );

  return {
    loading,
    comments,
    referenceId,
    referenceType,
  };
};

const mapDispatchToProps = (dispatch, { referenceType, referenceId }) => ({
  fetchComments: () =>
    dispatch(commentActions.fetchCommentsOf(referenceType, referenceId)),
  createComment: (content) =>
    dispatch(
      commentActions.createCommentOf(referenceType, referenceId, content)
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
