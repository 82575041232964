import get from 'lodash/get';
import { all, call, takeLatest, put } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as types from './actionTypes';
import * as actions from './actions';
import { ENDPOINT } from './constants';

export function* createEligibilityRequest({ payload }) {
  let response;
  try {
    response = yield call(httpClient.post, ENDPOINT, payload);
    if (response.status) {
      yield put(actions.eligibilityCreateResponded(true, response.data));
    }
  } catch (e) {
    yield put(
      actions.eligibilityCreateResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* getEligibilityRequests({ referenceId, referenceType }) {
  const response = yield call(
    httpClient.get,
    `${ENDPOINT}?referenceId=${referenceId}&referenceType=${referenceType}`
  );
  yield put(actions.eligibilityRequestsReceived(response.data));
}

export default function* eligibilityModelSaga() {
  yield all([
    takeLatest(types.CREATE_ELIGIBILITY_REQUEST, createEligibilityRequest),
    takeLatest(types.GET_ELIGIBILITY_REQUESTS, getEligibilityRequests),
  ]);
}
