import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as CONSULTATION } from './constants';

export const GET_CONSULTATION_BY_ID = `${DOMAIN}/${CONSULTATION}/GET_CONSULTATION_BY_ID`;
export const UPDATE_CONSULTATION = `${DOMAIN}/${CONSULTATION}/UPDATE_CONSULTATION`;
export const CREATE_CONSULTATION = `${DOMAIN}/${CONSULTATION}/CREATE_CONSULTATION`;
export const CLEAR_CONSULTATION = `${DOMAIN}/${CONSULTATION}/CLEAR_CONSULTATION`;
export const ADD_CONSULTATION_LAB_TEST = `${DOMAIN}/${CONSULTATION}/ADD_CONSULTATION_LAB_TEST`;
export const ADD_CONSULTATION_MEETING_STARTED = `${DOMAIN}/${CONSULTATION}/ADD_CONSULTATION_MEETING_STARTED`;
export const UPDATE_CONSULTATION_MEMBERS = `${DOMAIN}/${CONSULTATION}/UPDATE_CONSULTATION_MEMBERS`;
export const DELETE_CONSULTATION_MEMBER = `${DOMAIN}/${CONSULTATION}/DELETE_CONSULTATION_MEMBER`;
export const UPDATE_CONSULTATION_PATIENT = `${DOMAIN}/${CONSULTATION}/UPDATE_CONSULTATION_PATIENT`;
export const CREATE_CONSULTATION_PATIENT_OUTCOME = `${DOMAIN}/${CONSULTATION}/CREATE_CONSULTATION_PATIENT_OUTCOME`;
export const UPDATE_CONSULTATION_PATIENT_OUTCOME = `${DOMAIN}/${CONSULTATION}/UPDATE_CONSULTATION_PATIENT_OUTCOME`;
export const DELETE_CONSULTATION_PATIENT_OUTCOME = `${DOMAIN}/${CONSULTATION}/DELETE_CONSULTATION_PATIENT_OUTCOME`;
export const SUBMIT_CONSULTATION_PRESCRIPTIONS = `${DOMAIN}/${CONSULTATION}/SUBMIT_CONSULTATION_PRESCRIPTIONS`;
export const SUBMIT_CONSULTATION_LAB_TESTS = `${DOMAIN}/${CONSULTATION}/SUBMIT_CONSULTATION_LAB_TESTS`;

export const CONSULTATION_RECEIVED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_RECEIVED`;
export const CONSULTATION_UPDATED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_UPDATED`;
export const CONSULTATION_MEMBERS_UPDATED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_MEMBERS_UPDATED`;
export const CONSULTATION_MEMBER_DELETED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_MEMBER_DELETED`;
export const CONSULTATION_PATIENT_UPDATED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_PATIENT_UPDATED`;
export const CONSULTATION_PATIENT_OUTCOME_CREATED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_PATIENT_OUTCOME_CREATED`;
export const CONSULTATION_PATIENT_OUTCOME_UPDATED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_PATIENT_OUTCOME_UPDATED`;
export const CONSULTATION_PATIENT_OUTCOME_DELETED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_PATIENT_OUTCOME_DELETED`;
export const CONSULTATION_PRESCRIPTIONS_SUBMITTED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_PRESCRIPTIONS_SUBMITTED`;
export const CONSULTATION_LAB_TESTS_SUBMITTED = `${DOMAIN}/${CONSULTATION}/CONSULTATION_LAB_TESTS_SUBMITTED`;

export const SET_FETCHING_CONSULTATION = `${DOMAIN}/${CONSULTATION}/SET_FETCHING_CONSULTATION`;
export const SET_DELETING_CONSULTATION_MEMBER = `${DOMAIN}/${CONSULTATION}/SET_DELETING_CONSULTATION_MEMBER`;
export const SET_SUBMITTING_CONSULTATION = `${DOMAIN}/${CONSULTATION}/SET_SUBMITTING_CONSULTATION`;
export const SET_SUBMITTING_CONSULTATION_MEMBERS = `${DOMAIN}/${CONSULTATION}/SET_SUBMITTING_CONSULTATION_MEMBERS`;
export const SET_UPDATING_CONSULTATION_PATIENT = `${DOMAIN}/${CONSULTATION}/SET_UPDATE_CONSULTATION_PATIENT`;
export const SET_SUBMITTING_CONSULTATION_PATIENT_OUTCOME = `${DOMAIN}/${CONSULTATION}/SET_SUBMITTING_CONSULTATION_PATIENT_OUTCOME`;
export const SET_UPDATING_CONSULTATION_PATIENT_OUTCOME = `${DOMAIN}/${CONSULTATION}/SET_UPDATING_CONSULTATION_PATIENT_OUTCOME`;
export const SET_DELETING_CONSULTATION_PATIENT_OUTCOME = `${DOMAIN}/${CONSULTATION}/SET_DELETING_CONSULTATION_PATIENT_OUTCOME`;
export const SET_SUBMITTING_CONSULTATION_PRESCRIPTIONS = `${DOMAIN}/${CONSULTATION}/SET_SUBMITTING_CONSULTATION_PRESCRIPTIONS`;
export const SET_SUBMITTING_CONSULTATION_LAB_TESTS = `${DOMAIN}/${CONSULTATION}/SET_SUBMITTING_CONSULTATION_LAB_TESTS`;
