import { httpCache } from 'core/cache';

import * as constants from './constants';

export const fetchUserById = async (id) => {
  const endpoint = constants.USER_BY_ID_ENDPOINT(id);
  const response = await httpCache.get(endpoint);

  return response?.data;
};
