import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';

import IconButton from '../IconButton';

const DialogTitle = ({ children, onClose, ...rest }) => (
  <MuiDialogTitle sx={{ m: 0, p: 2 }} {...rest}>
    {children}
    {onClose ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    ) : null}
  </MuiDialogTitle>
);

export default DialogTitle;
