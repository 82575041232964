import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

import saga from '../saga';
import reducer from '../reducer';

const composeEnhancers = composeWithDevTools({
  name: 'AlmaOS',
  trace: true,
  traceLimit: 20,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(saga);

export default store;
