import * as types from './actionTypes';

export const fetchDoctorSchedules = () => ({
  type: types.FETCH_DOCTOR_SCHEDULES,
});

export const fetchDoctorFreeScheduleSlots = (
  doctorId,
  dayOfWeekDate,
  dayOfWeek
) => ({
  type: types.FETCH_DOCTOR_FREE_SCHEDULE_SLOTS,
  doctorId,
  dayOfWeekDate,
  dayOfWeek,
});

export const fetchDoctorBookingAvailability = (doctorId, pageDate) => ({
  type: types.FETCH_DOCTOR_BOOKING_AVAILABILITY,
  doctorId,
  pageDate,
});

export const fetchDailyBookingAvailability = (date) => ({
  type: types.FETCH_DAILY_BOOKING_AVAILABILITY,
  date,
});

export const addAvailabilityRange = (payload) => ({
  type: types.ADD_AVAILABILITY_RANGE,
  payload,
});

export const removeAvailabilityRange = (rangeId) => ({
  type: types.REMOVE_AVAILABILITY_RANGE,
  rangeId,
});
export const removeFreeSlotRange = (
  doctorId,
  startTime,
  endTime,
  dayOfWeek
) => ({
  type: types.REMOVE_FREE_SLOT_RANGE,
  doctorId,
  startTime,
  endTime,
  dayOfWeek,
});

export const doctorSchedulesFetchResponded = (ok, response) => ({
  type: types.DOCTOR_SCHEDULES_FETCH_RESPONDED,
  response,
  ok,
});
export const doctorFreeSchedulesSlotsFetchResponded = (ok, response) => ({
  type: types.DOCTOR_FREE_SCHEDULES_SLOTS_FETCH_RESPONDED,
  response,
  ok,
});
export const doctorBookingAvailabilityFetchResponded = (ok, response) => ({
  type: types.DOCTOR_BOOKING_AVAILABILITY_FETCH_RESPONDED,
  response,
  ok,
});

export const dailyBookingAvailabilityFetchResponded = (ok, response) => ({
  type: types.DAILY_BOOKING_AVAILABILITY_FETCH_RESPONDED,
  response,
  ok,
});

export const availabilityRangeAddResponded = (ok, response) => ({
  type: types.AVAILABILITY_RANGE_ADD_RESPONDED,
  ok,
  response,
});

export const availabilityRangeRemovalResponded = (ok) => ({
  type: types.AVAILABILITY_RANGE_REMOVAL_RESPONDED,
  ok,
});

export const freeSlotRangeRemovalResponded = (ok) => ({
  type: types.FREE_SLOT_RANGE_REMOVAL_RESPONDED,
  ok,
});
