import React, { useRef, useState, useEffect, useCallback } from 'react';

import { memberConstants as constants } from 'domain/user/member';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

import { userConstants } from 'domain/user';
import MemberForm from '../MemberForm';

const { FIELDS } = constants;
const { FIELDS: USER_FIELDS } = userConstants;

const CreateMemberDialog = ({
  // Props
  open,
  onClose,
  ownerId,
  // State
  isSubmitting,
  // actions
  createMember,
  onMemberCreated,
  ...rest
}) => {
  const formRef = useRef();

  const [isOpen, setOpen] = useState(open);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, []);

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        ...data,
        [FIELDS.OWNER_ID.name]: ownerId,
        [USER_FIELDS.FULL_NAME.name]: data?.fullName?.trim(),
      };

      createMember(ownerId, payload);
      onMemberCreated(() => {
        onClosed();
      });
    },
    [ownerId]
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClosed}
      aria-labelledby="member-create-dialog"
      {...rest}
    >
      <DialogTitle id="member-create-dialog-title" onClose={onClosed}>
        New Member
      </DialogTitle>
      <DialogContent dividers>
        <MemberForm ref={formRef} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          color="primary"
          spinning={isSubmitting}
          onClick={() => {
            formRef.current.submit();
          }}
        >
          Add new member
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMemberDialog;
