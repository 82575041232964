import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchConsultationById({ id }) {
  const response = yield call(
    httpClient.get,
    constants.CONSULTATION_BY_ID_ENDPOINT(id)
  );

  yield put(actions.consultationReceived(response.data, id));
}

export function* updateConsultation({ id, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      constants.CONSULTATION_BY_ID_ENDPOINT(id),
      payload
    );

    yield put(actions.consultationUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.consultationUpdateResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* addConsultationLabTest({ id }) {
  const consultationAddLabTestEndpoint = [
    constants.ENDPOINT,
    id,
    'actions',
  ].join('/');

  yield call(httpClient.patch, consultationAddLabTestEndpoint, {
    id,
    action: 'LabTestRequested',
  });
}

export function* addConsultationJoinMeetingStarted({ id, action }) {
  const consultationJoinMeetingStarted = [
    constants.ENDPOINT,
    id,
    'actions',
  ].join('/');

  yield call(httpClient.patch, consultationJoinMeetingStarted, {
    id,
    action,
  });
}

export function* createConsultation({ payload }) {
  let response;

  try {
    response = yield call(httpClient.post, constants.ENDPOINT, payload);

    yield put(actions.consultationCreated(response.data));
    yield put(actions.consultationCreationResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.consultationCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* deleteConsultationMember({ consultationId, memberId }) {
  try {
    const deleteConsultationMemberEndpoint = [
      constants.ENDPOINT,
      consultationId,
      'patients',
      memberId,
    ].join('/');
    const response = yield call(
      httpClient.delete,
      deleteConsultationMemberEndpoint,
      {
        id: consultationId,
        patientUserId: memberId,
      }
    );
    yield put(actions.consultationMemberDeleteResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.consultationMemberDeleteResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* updateConsultationMembers({ consultationId, memberIds }) {
  const updateConsultationMembersEndpoint = [
    constants.ENDPOINT,
    consultationId,
    'patients',
  ].join('/');

  try {
    const response = yield call(
      httpClient.patch,
      updateConsultationMembersEndpoint,
      {
        consultationId,
        patientsList: memberIds,
      }
    );

    yield put(
      actions.consultationMembersUpdatingResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.consultationMembersUpdatingResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* updateConsultationPatient({
  consultationId,
  patientId,
  payload,
}) {
  try {
    const response = yield call(
      httpClient.patch,
      constants.CONSULTATION_PATIENT_ENDPOINT(consultationId, patientId),
      payload
    );

    yield put(actions.consultationPatientUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.consultationPatientUpdateResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* createConsultationPatientOutcome({
  consultationId,
  patientId,
  payload,
}) {
  try {
    const response = yield call(
      httpClient.post,
      constants.CONSULTATION_PATIENT_OUTCOMES_ENDPOINT(
        consultationId,
        patientId
      ),
      payload
    );

    yield put(
      actions.consultationPatientOutcomeCreationResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.consultationPatientOutcomeCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* updateConsultationPatientOutcome({
  consultationId,
  patientId,
  outcomeId,
  payload,
}) {
  try {
    const response = yield call(
      httpClient.patch,
      constants.CONSULTATION_PATIENT_OUTCOME_ENDPOINT(
        consultationId,
        patientId,
        outcomeId
      ),
      payload
    );

    yield put(
      actions.consultationPatientOutcomeUpdateResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.consultationPatientOutcomeUpdateResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* deleteConsultationPatientOutcome({
  consultationId,
  patientId,
  outcomeId,
}) {
  try {
    const response = yield call(
      httpClient.delete,
      constants.CONSULTATION_PATIENT_OUTCOME_ENDPOINT(
        consultationId,
        patientId,
        outcomeId
      )
    );

    yield put(
      actions.consultationPatientOutcomeDeleteResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.consultationPatientOutcomeDeleteResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* submitConsultationPrescriptions({ consultationId }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.CONSULTATION_PRESCRIPTIONS(consultationId)
    );

    yield put(
      actions.consultationPrescriptionsSubmitResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.consultationPrescriptionsSubmitResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* submitConsultationLabTests({ consultationId }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.CONSULTATION_LAB_TESTS(consultationId)
    );

    yield put(actions.consultationLabTestsSubmitResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.consultationLabTestsSubmitResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export default function* consultationModelSaga() {
  yield all([
    takeLatest(types.FETCH_CONSULTATION_BY_ID, fetchConsultationById),
    takeLatest(types.UPDATE_CONSULTATION, updateConsultation),
    takeLatest(types.CREATE_CONSULTATION, createConsultation),
    takeLatest(types.ADD_CONSULTATION_LAB_TEST, addConsultationLabTest),
    takeLatest(
      types.ADD_CONSULTATION_JOIN_MEETING_STARTED,
      addConsultationJoinMeetingStarted
    ),
    takeLatest(types.UPDATE_CONSULTATION_MEMBERS, updateConsultationMembers),
    takeLatest(types.DELETE_CONSULTATION_MEMBER, deleteConsultationMember),
    takeLatest(types.UPDATE_CONSULTATION_PATIENT, updateConsultationPatient),
    takeLatest(
      types.CREATE_CONSULTATION_PATIENT_OUTCOME,
      createConsultationPatientOutcome
    ),
    takeLatest(
      types.UPDATE_CONSULTATION_PATIENT_OUTCOME,
      updateConsultationPatientOutcome
    ),
    takeLatest(
      types.DELETE_CONSULTATION_PATIENT_OUTCOME,
      deleteConsultationPatientOutcome
    ),
    takeLatest(
      types.SUBMIT_CONSULTATION_PRESCRIPTIONS,
      submitConsultationPrescriptions
    ),
    takeLatest(types.SUBMIT_CONSULTATION_LAB_TESTS, submitConsultationLabTests),
  ]);
}
