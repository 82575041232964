import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as PRESCRIPTION } from './constants';

// Actions
export const CREATE_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/CREATE_PRESCRIPTION`;
export const CREATE_E_PRESCRIPTION_DOCUMENT = `${DOMAIN}/${PRESCRIPTION}/CREATE_E_PRESCRIPTION_DOCUMENT`;
export const CREATE_PATIENT_PRESCRIPTION_DOCUMENT = `${DOMAIN}/${PRESCRIPTION}/CREATE_PATIENT_PRESCRIPTION_DOCUMENT`;
export const SUBMIT_ALL_PRESCRIPTIONS = `${DOMAIN}/${PRESCRIPTION}/SUBMIT_ALL_PRESCRIPTIONS`;
export const CREATE_PATIENT_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/CREATE_PATIENT_PRESCRIPTION`;
export const UPDATE_PATIENT_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/UPDATE_PATIENT_PRESCRIPTION`;
export const CLEAR_PATIENTS_PRESCRIPTIONS = `${DOMAIN}/${PRESCRIPTION}/CLEAR_PATIENTS_PRESCRIPTIONS`;

// Responses
export const PRESCRIPTION_CREATED = `${DOMAIN}/${PRESCRIPTION}/PRESCRIPTION_CREATED`;
export const PATIENT_PRESCRIPTION_CREATED = `${DOMAIN}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_CREATED`;
export const PATIENT_PRESCRIPTION_UPDATED = `${DOMAIN}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_UPDATED`;
export const E_PRESCRIPTION_DOCUMENT_CREATED = `${DOMAIN}/${PRESCRIPTION}/E_PRESCRIPTION_DOCUMENT_CREATED`;
export const PATIENT_PRESCRIPTION_DOCUMENT_CREATED = `${DOMAIN}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_DOCUMENT_CREATED`;
export const ALL_PRESCRIPTIONS_SUBMITED = `${DOMAIN}/${PRESCRIPTION}/ALL_PRESCRIPTIONS_SUBMITED`;

// Statuses
export const SET_SUBMITTING_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/SET_SUBMITTING_PRESCRIPTION`;
export const SET_CREATING_PATIENT_PRESCRIPTION_DOCUMENT = `${DOMAIN}/${PRESCRIPTION}/SET_CREATING_PATIENT_PRESCRIPTION_DOCUMENT`;
export const SET_SUBMITTING_ALL_PRESCRIPTIONS = `${DOMAIN}/${PRESCRIPTION}/SET_SUBMITTING_ALL_PRESCRIPTIONS`;
export const SET_SUBMITTING_PATIENT_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/SET_SUBMITTING_PATIENT_PRESCRIPTION`;
export const SET_UPDATING_PATIENT_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/SET_UPDATING_PATIENT_PRESCRIPTION`;
export const SET_FETCHING_PATIENT_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/SET_FETCHING_PATIENT_PRESCRIPTION`;
export const PATIENT_PRESCRIPTION_RECEIVED = `${DOMAIN}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_RECEIVED`;
export const FETCH_PATIENT_PRESCRIPTION = `${DOMAIN}/${PRESCRIPTION}/FETCH_PATIENT_PRESCRIPTION`;
