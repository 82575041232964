import get from 'lodash/get';

import { FIELDS } from './constants';

export const getId = (patientRecord) => get(patientRecord, FIELDS.ID.name);

export const getStatus = (patientRecord) =>
  get(patientRecord, FIELDS.STATUS.name);

export const getChannel = (patientRecord) =>
  get(patientRecord, FIELDS.CHANNEL.name) || 'APP';

export const getDoctor = (patientRecord) =>
  get(patientRecord, FIELDS.DOCTOR.name);

export const getType = (patientRecord) => get(patientRecord, FIELDS.TYPE.name);

export const getFiles = (patientRecord) =>
  get(patientRecord, FIELDS.FILES.name);

export const isFetchingFiles = (patientRecord) =>
  get(patientRecord, FIELDS.IS_FETCHING_FILES.name);

export const getUpdatedAt = (patientRecord) =>
  get(patientRecord, FIELDS.UPDATED_AT.name);

export const getCreatedAt = (patientRecord) =>
  get(patientRecord, FIELDS.CREATED_AT.name);
