import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { userSelectors, userActions } from 'domain/user';
import { labActions, labSelectors } from 'domain/lab';

import {
  memberActions,
  memberSelectors,
  memberUtils,
} from 'domain/user/member';
import LabRequestView from './LabRequestView';

const mapStateToProps = (state) => {
  const labRequest = labSelectors.getLabRequest(state);
  const owner = userSelectors.getUser(state);
  const isFetching = labSelectors.isFetchingLabRequest(state);
  const isSubmitting = labSelectors.isSubmittingLabRequest(state);
  const isFetchingDocuments = labSelectors.isFetchingDocuments(state);

  let members = memberSelectors.getMembers(state);
  const patientsUserInfoById = memberUtils.ownerAndMembersToObjectById(
    owner,
    members
  );
  const isLabForOwner = labRequest?.labTestRequestPatient?.some(
    (patient) => patient.userId === owner.id
  );
  if (isLabForOwner && !members.some((member) => member.id === owner.id)) {
    // if lab request is for owner add it to the patients list
    members = [...members, owner];
  }
  const membersHealthProfiles =
    memberSelectors.getMembersHealthProfiles(state) || [];

  return {
    owner,
    labRequest,
    isFetching,
    isFetchingDocuments,
    isSubmitting,
    members,
    membersHealthProfiles,
    patientsUserInfoById,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getLabRequest: (id) => dispatch(labActions.getLabRequestById(id)),
  updateLabRequest: (id, payload, isUpdateLabResults) =>
    dispatch(labActions.updateLabRequest(id, payload, isUpdateLabResults)),
  fetchUser: (id) => dispatch(userActions.fetchUser(id)),
  fetchPatientsUserInfo: (ownerId, patientsUserIds) => {
    dispatch(memberActions.fetchMembers(ownerId, patientsUserIds));
  },
  fetchPatientsHealthProfiles: (ownerId, patientsUserIds) =>
    dispatch(
      memberActions.fetchMembersHealthProfiles(ownerId, patientsUserIds)
    ),
  clearMembers: () => dispatch(memberActions.membersReceived([])),
  clearUser: () => dispatch(userActions.clearUser()),
  createPatientsLabRequestDocument: (labId, payload) =>
    dispatch(labActions.createPatientsLabRequestDocument(labId, payload)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(LabRequestView)
);
