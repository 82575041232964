import * as types from './actionTypes';

const initialState = {
  meetings: [],
  isFetching: false,
  isDeleting: false,
  isSubmitting: false,
};

const meetingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MEETINGS_RECEIVED: {
      return {
        ...state,
        meetings: action.meetings || [],
      };
    }
    case types.MEETING_CREATED: {
      return {
        ...state,
        meetings: [...state.meetings, action.meeting],
      };
    }
    case types.MEETING_UPDATED: {
      const meetings = [...state.meetings];
      const idx = meetings.findIndex((item) => item.id === action?.meeting?.id);

      if (idx >= 0) {
        meetings[idx] = action.meeting;
      }

      return {
        ...state,
        meetings,
      };
    }
    case types.MEETING_DELETED: {
      return {
        ...state,
        meetings: state.meetings.filter(
          (meeting) => meeting.id !== action.meeting.id
        ),
      };
    }
    case types.SET_FETCHING_MEETINGS: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING_MEETING: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.SET_DELETING_MEETING: {
      return {
        ...state,
        isDeleting: action.isDeleting,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        meetings: [],
      };
    }
    default:
      return state;
  }
};

export default meetingReducer;
