import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { scheduleActions, scheduleSelectors } from 'domain/schedule';

import BookingAvailability from './BookingAvailability';

const mapStateToProps = (state) => {
  const bookingSlots = scheduleSelectors.getBookingSlots(state);
  const isFetching = scheduleSelectors.isFetchingSchedules(state);
  const dayBookingSlots = scheduleSelectors.getDayBookingSlots(state);

  return {
    isFetching,
    bookingSlots,
    dayBookingSlots,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(scheduleActions.clearBookingAvailability()),
  fetchDayBookingSlots: (date) =>
    dispatch(scheduleActions.fetchDailyBookingAvailability(date)),
  fetchBookingSlots: (doctorId, pageDate) =>
    dispatch(
      scheduleActions.fetchDoctorBookingAvailability(doctorId, pageDate)
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(BookingAvailability)
);
