/* Payment model constants */
export const PACKAGE_NAME = 'Payment';
export const ENDPOINT = '/payment-transaction';
export const PAYMENT_TRANSACTION_ENDPOINT = (referenceId, referenceType) =>
  `purchase-order/payment-transaction?referenceId=${referenceId}&referenceType=${referenceType}`;
export const CREATE_PAYMENT_LINK_ENDPOINT = 'create';

const AMOUNT_FIELDS = {
  VALUE: {
    name: 'value',
    path: 'amount.value',
  },
  CURRENCY: {
    name: 'currency',
    path: 'amount.currency',
  },
};

export const FIELDS = {
  REFERENCE_ID: {
    name: 'referenceId',
  },
  REFERENCE_TYPE: {
    name: 'referenceType',
  },
  AMOUNT: {
    name: 'amount',
    fields: AMOUNT_FIELDS,
  },
  IS_TOTAL_PRICE: {
    name: 'isTotalPrice',
  },
  IS_TOTAL_PAYER_SHARE: {
    name: 'totalPayerShare',
  },
  PAYMENT_TRANSACTION: {
    name: 'paymentTransaction',
  },
  USER_ID: {
    name: 'userId',
  },
  SEND_AUTO_NOTIFICATION: {
    name: 'sendAutoNotification',
  },
};
