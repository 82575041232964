import keys from 'lodash/keys';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import isValid from 'date-fns/isValid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import React, { useState, useEffect } from 'react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';

import { userGetters } from 'domain/user';
import { authSelectors } from 'domain/auth';
import { isAllowed } from 'core/permission';
import { CsvExportButton } from 'core/export';
import { UserSearch } from 'presentation/user';
import { isShowLogisticProvider } from 'core/siteConfig';
import { Button, DataGrid, PageTitle } from 'design-system';
import { SendBulkNotifications } from 'presentation/notification';
import { filtration, DataGridCard, useServerPagination } from 'core/dataGrid';
import {
  orderUtils as utils,
  orderGetters as getters,
  orderConstants as constants,
} from 'domain/order';
import { REFERENCE_TYPES } from 'constants/general';

import { getColumns, getFilterFieldsConfig } from './utils';

const { FIELDS, STATUSES, CHANNELS, LS_ORDER_FILTERS } = constants;

const OrdersList = ({
  saveFilters,
  updateStatus,
  doctorsNames,
  pharmacistsMap,
  driversFullNamesMap,
  logisticProvidersMap,
  insuranceProvidersNameMap,
}) => {
  const navigate = useNavigate();

  const selectedTenant = useSelector((state) =>
    authSelectors.getSelectedActiveTenant(state, true)
  );

  const { params, filterValues } = filtration.getFilterParamsFromLs(
    getFilterFieldsConfig(),
    LS_ORDER_FILTERS
  );

  if (isAllowed('common', 'tenantFilter')) {
    params.append('filter', `tenantKey||$in||${selectedTenant}`);
  }

  const [rows, setRows] = useState([]);
  const [isFetching, setFetching] = useState(false);

  const {
    params: apiParams,
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/order',
    params,
    pageSize: 15,
    initialSortModel: { field: 'updatedAt', sort: 'desc' },
    columnFieldsMap: {
      patient: 'userId',
      driver: 'driverId',
      phoneNumber: 'userId',
      assignee: 'assigneeId',
      logisticProvider: 'logisticProviderId',
    },
  });

  const { updateFilter } = filtration.useServerFiltration({
    saveFilters,
    setQueryParams,
    initialValues: filterValues,
    fieldsConfig: getFilterFieldsConfig(),
    onQueryParamsChange: (urlSearchParams) => {
      const prms = new URLSearchParams(urlSearchParams);

      if (isAllowed('common', 'tenantFilter')) {
        prms.append('filter', `tenantKey||$in||${selectedTenant}`);
      }
      return prms;
    },
  });

  useEffect(() => {
    if (dataGridProps?.rows) {
      setRows(dataGridProps.rows);
    }

    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const filtersDef = {
    fields: [
      {
        name: 'status',
        type: 'autocomplete',
        placeholder: 'Status',
        icon: MonitorHeartOutlinedIcon,
        options: values(STATUSES).map((item) => item.label),
        defaultValue: filterValues.status,
        disabled: isFetching,
        filter: (selectedStatus) => {
          updateFilter('status', selectedStatus);
        },
      },
      {
        name: 'channel',
        type: 'autocomplete',
        placeholder: 'Channel',
        icon: LoginIcon,
        hide: !isAllowed('orderList', 'channel'),
        options: keys(CHANNELS),
        defaultValue: filterValues.channel,
        getOptionLabel: (option) => CHANNELS[option]?.label,
        filter: (channel) => {
          updateFilter('channel', CHANNELS[channel]?.key);
        },
      },
      {
        name: 'patient',
        type: 'custom',
        component: (
          <UserSearch
            size="medium"
            disabled={isFetching}
            defaultValue={filterValues.patient}
            onUserSelect={(selectedPatient) => {
              updateFilter('patient', selectedPatient);
            }}
          />
        ),
      },
      {
        name: 'assignee',
        type: 'autocomplete',
        placeholder: 'Assignee',
        icon: HowToRegIcon,
        options: keys(pharmacistsMap),
        withNone: true,
        hide: !isAllowed('orderView', 'assignee', 'view'),
        defaultValue: userGetters.getFullName(filterValues.assignee),
        disabled: isFetching || isEmpty(pharmacistsMap),
        filter: (selectedFullName) => {
          const id = pharmacistsMap[selectedFullName];

          updateFilter('assignee', {
            id,
            fullName: selectedFullName,
          });
        },
      },
      {
        name: 'doctor',
        type: 'autocomplete',
        placeholder: 'Doctor',
        icon: LocalHospitalOutlinedIcon,
        options: doctorsNames,
        withNone: true,
        defaultValue: filterValues.doctor,
        hide: !isAllowed('orderList', 'doctorFilter'),
        filter: (selectedFullName) => {
          updateFilter('doctor', selectedFullName);
        },
      },
      {
        name: 'logisticProvider',
        type: 'autocomplete',
        placeholder: 'Logistic Provider',
        icon: LocalShippingOutlinedIcon,
        options: keys(logisticProvidersMap),
        withNone: true,
        hide:
          !isShowLogisticProvider() ||
          !isAllowed('orderList', 'logisticsProviderFilter'),
        defaultValue: filterValues?.logisticProvider?.name,
        disabled: isFetching || isEmpty(logisticProvidersMap),
        filter: (selectedName) => {
          const id = logisticProvidersMap[selectedName];

          updateFilter('logisticProvider', {
            id,
            name: selectedName,
          });
        },
      },
      {
        name: 'driver',
        type: 'autocomplete',
        placeholder: 'Driver',
        icon: DeliveryDiningIcon,
        options: keys(driversFullNamesMap),
        withNone: true,
        hide: !isAllowed('orderList', 'driverFilter'),
        defaultValue: userGetters.getFullName(filterValues.driver),
        disabled: isFetching || isEmpty(driversFullNamesMap),
        filter: (selectedFullName) => {
          const id = driversFullNamesMap[selectedFullName];
          updateFilter('driver', {
            id,
            fullName: selectedFullName,
          });
        },
      },
      {
        name: 'createdAtFrom',
        type: 'date',
        placeholder: 'From Created Date',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.createdAtFrom,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('createdAtFrom', timestamp);
          }
        },
      },
      {
        name: 'createdAtUntil',
        type: 'date',
        placeholder: 'Until Created Date',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.createdAtUntil,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('createdAtUntil', timestamp);
          }
        },
      },

      {
        name: 'assignedAtFrom',
        type: 'datetime',
        placeholder: 'From Delivery Date',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.assignedAtFrom,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('assignedAtFrom', timestamp);
          }
        },
      },

      {
        name: 'assignedAtUntil',
        type: 'datetime',
        placeholder: 'Until Delivery Date',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.assignedAtUntil,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('assignedAtUntil', timestamp);
          }
        },
      },

      {
        name: 'reminderDate',
        type: 'date',
        placeholder: 'Reminder Date',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.reminderDate,
        hide: !isAllowed('orderList', 'reminderDate'),
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('reminderDate', timestamp);
          }
        },
      },
      {
        name: 'followupReminderDate',
        type: 'date',
        placeholder: 'Followup Reminder Date',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        hide: !isAllowed('orderList', 'reminderDate'),
        defaultValue: filterValues.followupReminderDate,
        filter: (timestamp) => {
          if (isValid(new Date(timestamp))) {
            updateFilter('followupReminderDate', timestamp);
          }
        },
      },
      {
        name: 'insuranceProvider',
        type: 'autocomplete',
        placeholder: 'Insurance Provider',
        icon: ShieldOutlinedIcon,
        options: keys(insuranceProvidersNameMap),
        withNone: true,
        defaultValue: filterValues.insuranceProvider?.englishName,
        hide: !isAllowed('orderList', 'insuranceProviderFilter'),
        disabled: isFetching || isEmpty(insuranceProvidersNameMap),
        filter: (selectedInsuranceProviderName) => {
          const id = insuranceProvidersNameMap[selectedInsuranceProviderName];
          updateFilter('insuranceProvider', {
            id,
            englishName: selectedInsuranceProviderName,
          });
        },
      },
    ],
  };

  const onRowClick = (row) => {
    const { id } = row;

    navigate(`/order/${id}`);
  };

  const onStatusUpdate = (id, newStatus) => {
    const oldRows = [...rows];

    oldRows.some((item, idx) => {
      if (getters.getId(item) === id) {
        oldRows[idx][FIELDS.STATUS.name] = newStatus;

        return true;
      }

      return false;
    });

    setRows([...oldRows]);
    updateStatus(id, utils.getLabel(STATUSES, newStatus));
  };

  return (
    <>
      <PageTitle
        title="Orders"
        action={
          <>
            {isAllowed('common', 'export') && (
              <CsvExportButton
                endpoint="/order"
                params={apiParams}
                fields={FIELDS}
              />
            )}
            {isAllowed('bulkNotification', 'orders') && (
              <SendBulkNotifications
                loading={isFetching}
                referenceType={REFERENCE_TYPES.ORDER}
                params={apiParams}
                rowCount={dataGridProps?.rowCount || 0}
              />
            )}
            {isAllowed('orderList', 'newOrder') && (
              <Button
                variant="filled"
                onClick={() => {
                  navigate('/order');
                }}
              >
                New order
              </Button>
            )}
          </>
        }
      />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          {...{
            // Order is important
            ...dataGridProps, // 1
            rows, // 2
          }}
          onRowClick={(prms) => onRowClick(prms?.row)}
          columns={getColumns(
            onStatusUpdate,
            isAllowed('orderList', 'openLink')
          )}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => {
                window.open(`/order/${row.id}`, '_blank');
              },
            },
          ]}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
};

OrdersList.propTypes = {};

export default OrdersList;
