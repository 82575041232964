import React from 'react';
import { Chip, Grid } from 'design-system';

import { USER_TAGS } from 'domain/user/constants';

const Tags = ({ tags, isFetching }) => {
  return (
    <Grid
      item
      gap={1}
      container
      alignItems="center"
      loading={isFetching}
      sx={{ px: 2, mb: 1.5 }}
    >
      {tags?.map((tag) => {
        return (
          <Chip
            soak="light"
            size="medium"
            variant="filled"
            label={USER_TAGS[tag]?.label}
            color={USER_TAGS[tag]?.color}
            sx={{ minWidth: { xs: 0, md: 100 }, borderRadius: 1 }}
          />
        );
      })}
    </Grid>
  );
};

export default Tags;
