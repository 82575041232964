import * as types from './actionTypes';

export const createDietPlan = (payload) => ({
  type: types.CREATE_DIET_PLAN,
  payload,
});

export const getLatestDietPlan = (userId) => ({
  type: types.GET_LATEST_DIET_PLAN,
  userId,
});

export const latestDietPlanReceived = (latestDietPlan) => ({
  type: types.LATEST_DIET_PLAN_RECEIVED,
  latestDietPlan,
});

export const clearLatestDietPlan = () => ({
  type: types.CLEAR_LATEST_DIET_PLAN,
});

export const setFetchingDietPlan = (isFetching) => ({
  type: types.SET_FETCHING_DIET_PLAN,
  isFetching,
});

export const setSubmittingDietPlan = (isSubmitting) => ({
  type: types.SET_SUBMITTING_DIET_PLAN,
  isSubmitting,
});
