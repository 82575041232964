import get from 'lodash/get';

import { PACKAGE_FIELDS as FIELDS } from '../constants';

export const getId = (labPackage) => get(labPackage, FIELDS.ID.name);

export const getName = (labPackage) => get(labPackage, FIELDS.NAME.name);

export const getDescription = (labPackage) =>
  get(labPackage, FIELDS.DESCRIPTION.name);
