import { all, put, takeLatest, delay, take } from 'redux-saga/effects';

import { Message } from 'design-system';
import { REFERENCE_TYPES } from 'constants/general';
import { paymentModelActions, paymentModelActionTypes } from 'model/payment';
import {
  ADDRESS_FIELDS,
  USER_DOMAIN_FIELDS,
  userAddressGetters,
} from 'domain/user';

import { FIELDS } from './constants';
import * as actions from './actions';
import * as types from './actionTypes';

const prepare = (payload) => {
  let paymentPayload = {
    [FIELDS.REFERENCE_ID.name]: payload.referenceId,
    [FIELDS.REFERENCE_TYPE.name]: payload.referenceType,
    [FIELDS.AMOUNT.name]: {
      [FIELDS.AMOUNT.fields.VALUE.name]: payload.dueAmount.amount,
      [FIELDS.AMOUNT.fields.CURRENCY.name]: payload.dueAmount.currency,
    },
    [FIELDS.SEND_AUTO_NOTIFICATION.name]: payload.isAutoNotification,
    [FIELDS.USER_ID.name]: payload.user?.id,
  };
  if (payload.referenceType === REFERENCE_TYPES.ORDER) {
    paymentPayload = {
      ...paymentPayload,
      [FIELDS.IS_TOTAL_PRICE.name]: payload.isFullAmount,
      [FIELDS.IS_TOTAL_PAYER_SHARE.name]: payload.isPatientShare,
    };
  }
  if (payload.referenceType === REFERENCE_TYPES.LAB) {
    paymentPayload = {
      [FIELDS.PAYMENT_TRANSACTION.name]: { ...paymentPayload },
      [USER_DOMAIN_FIELDS.USER.name]: {
        [USER_DOMAIN_FIELDS.ID.name]: payload.user.id,
        [USER_DOMAIN_FIELDS.FULL_NAME.name]: payload.user?.fullName,
        [ADDRESS_FIELDS.FORMATTED_ADDRESS.name]:
          userAddressGetters.getFormattedAddress(payload.user?.addresses?.[0]),
        [USER_DOMAIN_FIELDS.CITY.name]: payload.user?.city,
        [USER_DOMAIN_FIELDS.PHONE_NUMBER.name]: payload.user.phoneNumber,
      },
      [FIELDS.SEND_AUTO_NOTIFICATION.name]: payload.isAutoNotification,
    };
  }
  return paymentPayload;
};

export function* createPaymentTransaction({ payload, callback }) {
  if (!payload.skipReload) {
    yield put(actions.setFetchingPaymentTransaction(true));
  }
  const paymentPayload = prepare(payload);
  yield put(paymentModelActions.createPaymentTransaction(paymentPayload));
  yield take(paymentModelActionTypes.PAYMENT_TRANSACTION_CREATED);
  // In case of send notification don't reload payment
  if (!payload.skipReload) {
    yield delay(8000);
    yield put(actions.getPaymentTransactionByReference(paymentPayload));

    if (callback) {
      callback();
    }
    Message.success('Payment Link Created Successfully');
  }
  if (paymentPayload.sendAutoNotification) {
    Message.success('Payment link sent to patient');
  }
}

export function* getPaymentTransactionByReference({ payload }) {
  const referenceId =
    payload.referenceId || payload?.paymentTransaction.referenceId;
  const referenceType =
    payload.referenceType || payload?.paymentTransaction.referenceType;
  yield put(actions.setFetchingPaymentTransaction(true));
  yield put(
    paymentModelActions.getPaymentTransactionByReference(
      referenceId,
      referenceType
    )
  );
  const action = yield take(
    paymentModelActionTypes.PAYMENT_TRANSACTION_RECEIVED
  );
  yield put(actions.paymentTransactionReceived(action.paymentTransaction));
  yield put(actions.setFetchingPaymentTransaction(false));
}

export function* cancelPaymentTransaction({ payload }) {
  const referenceId =
    payload.referenceId || payload?.paymentTransaction.referenceId; // take referenceId directly or from payment state
  const referenceType =
    payload.referenceType || payload?.paymentTransaction.referenceType; // take referenceType directly or from payment state

  yield put(actions.setFetchingPaymentTransaction(true));
  yield put(
    paymentModelActions.cancelPaymentTransaction(referenceId, referenceType)
  );
  const { ok } = yield take(
    paymentModelActionTypes.CANCEL_PAYMENT_TRANSACTION_RESPONDED
  );

  if (ok) {
    Message.info('Payment Link Cancelled Successfully');
    yield put(actions.paymentTransactionCancelled());
  }

  yield put(actions.setFetchingPaymentTransaction(false));
}

export default function* paymentSaga() {
  yield all([
    takeLatest(types.CREATE_PAYMENT_TRANSACTION, createPaymentTransaction),
    takeLatest(
      types.GET_PAYMENT_TRANSACTION_BY_REFERENCE,
      getPaymentTransactionByReference
    ),
    takeLatest(types.CANCEL_PAYMENT_TRANSACTION, cancelPaymentTransaction),
  ]);
}
