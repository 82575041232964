import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as ELIGIBILITY } from './constants';

export const CREATE_ELIGIBILITY_REQUEST = `${DOMAIN}/${ELIGIBILITY}/CREATE_ELIGIBILITY_REQUEST`;
export const GET_ELIGIBILITY_REQUESTS = `${DOMAIN}/${ELIGIBILITY}/GET_ELIGIBILITY_REQUESTS`;
export const SET_SUBMITTING_ELIGIBILITY = `${DOMAIN}/${ELIGIBILITY}/SET_SUBMITTING_ELIGIBILITY`;
export const SET_FETCHING_ELIGIBILITY = `${DOMAIN}/${ELIGIBILITY}/SET_FETCHING_ELIGIBILITY`;
export const ELIGIBILITY_REQUESTS_RECEIVED = `${DOMAIN}/${ELIGIBILITY}/ELIGIBILITY_REQUESTS_RECEIVED`;

export const CLEAR = `${DOMAIN}/${ELIGIBILITY}/CLEAR`;
