import { all, put, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import {
  eligibilityModelActions,
  eligibilityModelActionTypes,
} from 'model/eligibility';

import * as actions from './actions';
import * as types from './actionTypes';

export function* createEligibilityRequest({ payload }) {
  yield put(actions.setSubmittingEligibility(true));
  yield put(eligibilityModelActions.createEligibilityRequest(payload));
}

export function* eligibilityCreatedResponded({ ok, response }) {
  if (ok) {
    yield put(
      actions.getEligibilityRequests(
        response.referenceId,
        response.referenceType
      )
    );
    Message.success('Eligibility Request has been added successfully');
  }
  yield put(actions.setSubmittingEligibility(false));
}

export function* getEligibilityRequests({ referenceId, referenceType }) {
  yield put(actions.setFetchingEligibility(true));
  yield put(
    eligibilityModelActions.getEligibilityRequests(referenceId, referenceType)
  );
}

export function* eligibilityRequestsReceived({ eligibilityRequests }) {
  yield put(actions.eligibilityRequestsReceived(eligibilityRequests));
  yield put(actions.setFetchingEligibility(false));
}

export default function* eligibilitySaga() {
  yield all([
    takeLatest(types.GET_ELIGIBILITY_REQUESTS, getEligibilityRequests),
    takeLatest(types.CREATE_ELIGIBILITY_REQUEST, createEligibilityRequest),
    takeLatest(
      eligibilityModelActionTypes.ELIGIBILITY_CREATE_RESPONDED,
      eligibilityCreatedResponded
    ),
    takeLatest(
      eligibilityModelActionTypes.ELIGIBILITY_REQUESTS_RECEIVED,
      eligibilityRequestsReceived
    ),
  ]);
}
