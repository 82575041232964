import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import { userConstants } from 'domain/user';
import { REFERENCE_TYPES } from 'constants/general';

import UsersList from './UsersList';

const { LS_USER_FILTERS, LS_LEAD_USER_FILTERS } = userConstants;

const mapStateToProps = (_state, ownProps) => {
  let lsKey;

  switch (ownProps.referenceType) {
    case REFERENCE_TYPES.USER:
      lsKey = LS_USER_FILTERS;
      break;
    case REFERENCE_TYPES.LEAD_USER:
      lsKey = LS_LEAD_USER_FILTERS;
      break;
    default:
      return;
  }

  return {
    lsKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveFilters: (filters, lsKey) =>
    dispatch(appActions.saveFilters(filters, lsKey)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  saveFilters: (filters) =>
    dispatchProps.saveFilters(filters, stateProps.lsKey),
  ...stateProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UsersList);
