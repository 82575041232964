/* Order model constants */
export const PACKAGE_NAME = 'Order';
export const ENDPOINT = '/order';
export const LINE_ITEM_ENDPOINT = '/line-item';
export const PINNED_ORDERS_ENDPOINT = `${ENDPOINT}/pinned`;
export const DELIVERY_ADDRESS_ENDPOINT = '/orders/delivery-address';
export const LOGISTIC_PROVIDER_ENDPOINT = '/logistic-providers';
export const SHIPMENT_ENDPOINT = '/shipments/reference';
export const SHIPMENT_LABEL_ENDPOINT = '/shipments/label/reference';
export const SHIPMENTS = 'shipments';

export const ORDER_HISTORY_ENDPOINT = (orderId) => `order/${orderId}`;
export const LINE_ITEMS_HISTORY_ENDPOINT = (orderId) =>
  `${ORDER_HISTORY_ENDPOINT(orderId)}/line_item`;
