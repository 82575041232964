import * as types from './actionTypes';

export const fetchMembers = (ownerId, membersUserIds) => ({
  type: types.FETCH_MEMBERS,
  ownerId,
  membersUserIds,
});

export const fetchBasicMembers = (ownerId) => ({
  type: types.FETCH_BASIC_MEMBERS,
  ownerId,
});

export const fetchMembersHealthProfiles = (ownerId, membersUserIds) => ({
  type: types.FETCH_MEMBERS_HEALTH_PROFILES,
  ownerId,
  membersUserIds,
});

export const createMember = (ownerId, payload) => ({
  type: types.CREATE_MEMBER,
  ownerId,
  payload,
});

export const deleteMember = (memberId) => ({
  type: types.DELETE_MEMBER,
  memberId,
});

export const updateMember = (memberId) => ({
  type: types.UPDATE_MEMBER,
  memberId,
});

export const membersFetchResponded = (ok, response) => ({
  type: types.MEMBERS_FETCH_RESPONDED,
  ok,
  response,
});

export const basicMembersFetchResponded = (ok, response) => ({
  type: types.BASIC_MEMBERS_FETCH_RESPONDED,
  ok,
  response,
});

export const membersHealthProfilesFetchResponded = (ok, response) => ({
  type: types.MEMBERS_HEALTH_PROFILES_FETCH_RESPONDED,
  ok,
  response,
});

export const memberCreateResponded = (ok, response) => ({
  type: types.MEMBER_CREATE_RESPONDED,
  ok,
  response,
});

export const memberDeleteResponded = (ok, response) => ({
  type: types.MEMBER_DELETE_RESPONDED,
  ok,
  response,
});

export const memberUpdateResponded = (ok, response) => ({
  type: types.MEMBER_UPDATE_RESPONDED,
  ok,
  response,
});
