import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Grid,
  Card,
  TextField,
  PageTitle,
  CardContent,
  Checkbox,
  FormField,
  Button,
  Select,
  Chip,
  MenuItem,
  InputAdornment,
  RowLayout,
} from 'design-system';

import {
  consultationTypeGetters as getters,
  CONSULTATION_TYPE_DOMAIN_FIELDS,
} from 'domain/consultationType';
import ConsultationTypeConfigurationSection from './components/ConsultationTypeConfiguration';

const SLOT_DURATIONS = [20, 30];

const ConsultationTypeManagementView = ({
  consultationType,
  getConsultationType,
  updateConsultationType,
  isSubmitting,
  isFetching,
  doctors,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;
  const [FormChanged, setFormChanged] = useState(false);

  const [englishTitle, setEnglishTitle] = useState('');
  const [englishSubtitle, setEnglishSubtitle] = useState('');
  const [arabicTitle, setArabicTitle] = useState('');
  const [slotDuration, setSlotDuration] = useState(30);
  const [arabicSubtitle, setArabicSubtitle] = useState('');
  const [displayOrder, setDisplayOrder] = useState(1);
  const [focused, setFocused] = useState(false);
  const [isVisibleForUsers, setIsVisibleForUsers] = useState(false);
  const [assignDoctorOnBooking, setAssignDoctorOnBooking] = useState(false);
  const [doctorsWithNoInsurance, setDoctorsWithNoInsurance] = useState([]);

  const [priceValue, setPriceValue] = useState(0);

  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  useEffect(() => {
    if (id) {
      getConsultationType(id);
    }
  }, [id, isSubmitting]);

  useEffect(() => {
    if (!isEmpty(consultationType)) {
      setEnglishTitle(getters.getEnglishTitle(consultationType));
      setEnglishSubtitle(getters.getEnglishSubtitle(consultationType));
      setArabicTitle(getters.getArabicTitle(consultationType));
      setArabicSubtitle(getters.getArabicSubtitle(consultationType));
      setFocused(getters.getIsFocused(consultationType));
      setIsVisibleForUsers(getters.getIsVisibleForUsers(consultationType));
      setAssignDoctorOnBooking(
        getters.getIsAssignDoctorOnBooking(consultationType)
      );
      setDoctorsWithNoInsurance(getters.getDoctorIds(consultationType));
      setPriceValue(getters.getPrice(consultationType)?.value);
      setDisplayOrder(getters.getDisplayOrder(consultationType));
      setSlotDuration(getters.getSlotDuration(consultationType));
    }
  }, [consultationType]);

  useEffect(() => {
    if (englishTitle?.trim()) {
      setSubmitButtonEnabled(englishTitle);
    } else {
      setSubmitButtonEnabled(false);
    }
  }, [englishTitle]);

  const toggleFormChangedStatus = (isEnabled) => {
    if (!isFetching) {
      setFormChanged(isEnabled);
    }
  };

  const submitChanges = (e) => {
    e.stopPropagation();
    const payload = {
      [CONSULTATION_TYPE_DOMAIN_FIELDS.ID.name]: id,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.ARABIC_SUBTITLE.name]: arabicSubtitle,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.ARABIC_TITLE.name]: arabicTitle,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.ENGLISH_SUBTITLE.name]: englishSubtitle,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.ENGLISH_TITLE.name]: englishTitle,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.ASSIGN_DOCTOR_ON_BOOKING.name]:
        assignDoctorOnBooking,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.DISPLAY_ORDER.name]: displayOrder,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.DOCTOR_IDS.name]: doctorsWithNoInsurance,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.FOCUSED.name]: focused,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.SLOT_DURATION.name]: slotDuration,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.IS_VISIBLE_FOR_USERS.name]:
        isVisibleForUsers,
      [CONSULTATION_TYPE_DOMAIN_FIELDS.PRICE.name]: {
        value: priceValue,
        currency: getters.getPrice(consultationType)?.currency,
      },
    };

    updateConsultationType(id, payload);
    setFormChanged(false);
  };

  return (
    <Grid item sx={{ width: '100%' }}>
      <PageTitle title={`Consultation Type #${id}`} />
      <Grid container spacing={3} column>
        <Grid item sx={{ width: '100%' }}>
          <RowLayout spacing={2}>
            {/* ConsultationTypeInfo */}
            <Card
              key="consultation-Type-info"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent sx={{ height: '100%' }}>
                <Grid container column rowSpacing={2}>
                  <FormField
                    label="English Title"
                    value={getters.getEnglishTitle(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <TextField
                        size="medium"
                        fullWidth
                        variant="filled"
                        value={englishTitle}
                        loading={isFetching}
                        placeholder="english Subtitle"
                        onChange={(e) => {
                          setEnglishTitle(e.target.value);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="English Subtitle"
                    value={getters.getEnglishSubtitle(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <TextField
                        size="medium"
                        fullWidth
                        variant="filled"
                        value={englishSubtitle}
                        loading={isFetching}
                        placeholder="English Subtitle"
                        onChange={(e) => {
                          setEnglishSubtitle(e.target.value);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Arabic Title"
                    value={getters.getArabicTitle(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <TextField
                        size="medium"
                        fullWidth
                        variant="filled"
                        value={arabicTitle}
                        loading={isFetching}
                        placeholder="Arabic Title"
                        onChange={(e) => {
                          setArabicTitle(e.target.value);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Arabic Subtitle"
                    value={getters.getArabicSubtitle(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <TextField
                        size="medium"
                        fullWidth
                        variant="filled"
                        value={arabicSubtitle}
                        loading={isFetching}
                        placeholder="Arabic Subtitle"
                        onChange={(e) => {
                          setArabicSubtitle(e.target.value);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Display Order"
                    value={getters.getDisplayOrder(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <TextField
                        size="medium"
                        fullWidth
                        variant="filled"
                        value={displayOrder}
                        loading={isFetching}
                        placeholder="Display Order"
                        onChange={(e) => {
                          setDisplayOrder(e.target.value);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Assign Doctor On Booking"
                    value={getters.getIsAssignDoctorOnBooking(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <Checkbox
                        checked={assignDoctorOnBooking}
                        onChange={(event) => {
                          setAssignDoctorOnBooking(event.target.checked);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="is Visible For Users"
                    value={getters.getIsVisibleForUsers(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <Checkbox
                        checked={isVisibleForUsers}
                        onChange={(event) => {
                          setIsVisibleForUsers(event.target.checked);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Focused"
                    value={getters.getIsFocused(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <Checkbox
                        checked={focused}
                        onChange={(event) => {
                          setFocused(event.target.checked);
                          toggleFormChangedStatus(true);
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Price"
                    value={getters.getPrice(consultationType)?.value}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <TextField
                        row
                        size="medium"
                        fullWidth
                        variant="filled"
                        value={priceValue}
                        loading={isFetching}
                        placeholder="price value"
                        onChange={(e) => {
                          setPriceValue(e.target.value);
                          toggleFormChangedStatus(true);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {getters.getPrice(consultationType)?.currency}
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                  <FormField
                    label="Slot Duration"
                    value={getters.getSlotDuration(consultationType)}
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <Select
                        fullWidth
                        size="medium"
                        soak="light"
                        value={slotDuration}
                        variant="filled"
                        loading={isFetching}
                        placeholder="Select duration"
                        onChange={(event) => {
                          const newValue = event.target.value;
                          setSlotDuration(newValue);
                          toggleFormChangedStatus(true);
                        }}
                        renderValue={(value) => `${value} Minutes`}
                      >
                        {SLOT_DURATIONS.map((item) => (
                          <MenuItem value={item} key={item}>
                            {item} Minutes
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    sx={{
                      my: 0,
                      width: 300,
                    }}
                  />
                  <FormField
                    label="Doctors for patient with no insurance"
                    labelWidth={60}
                    valueWidth={170}
                    loading={isFetching}
                    field={
                      <Select
                        fullWidth
                        multiple
                        placeholder="Specify doctors"
                        soak="light"
                        variant="filled"
                        value={doctorsWithNoInsurance}
                        loading={isFetching}
                        onChange={(event) => {
                          setDoctorsWithNoInsurance(event.target.value);
                          toggleFormChangedStatus(true);
                        }}
                        renderValue={(selected) => (
                          <Grid
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip
                                size="small"
                                color="primary"
                                sx={{ maxHeight: 20 }}
                                key={value}
                                label={
                                  doctors?.find((doctor) => doctor.id === value)
                                    ?.fullName || value
                                }
                              />
                            ))}
                          </Grid>
                        )}
                      >
                        {doctors.map((doctor) => (
                          <MenuItem value={doctor.id} key={doctor.id}>
                            <Checkbox
                              checked={
                                doctorsWithNoInsurance.filter(
                                  (c) => c === doctor.id
                                ).length > 0
                              }
                            />
                            {doctor.fullName}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                  />
                </Grid>
                <Grid item sx={{ mt: 2 }}>
                  <Button
                    variant="filled"
                    onClick={submitChanges}
                    spinning={isSubmitting}
                    disabled={
                      !FormChanged || isFetching || !isSubmitButtonEnabled
                    }
                  >
                    Save Changes
                  </Button>
                </Grid>
              </CardContent>
            </Card>
            <ConsultationTypeConfigurationSection consultationTypeId={id} />
          </RowLayout>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsultationTypeManagementView;
