import get from 'lodash/get';
import keys from 'lodash/keys';
import uniqBy from 'lodash/uniqBy';
import formatISO from 'date-fns/formatISO';
import React, { useRef, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { isAllowed } from 'core/permission';
import { dateFormat } from 'constants/dateTime';
import { isDefaultApprovedByInsurance } from 'core/siteConfig';
import DownloadMultiplePdfButton from 'core/pdf/DownloadMultiplePdfButton';
import { lineItemGetters, orderConstants as constants } from 'domain/order';
import { Form, Controller, formValidationFunctions } from 'design-system/form';
import MedicineAutocomplete from 'presentation/medicine/components/MedicineAutocomplete';
import {
  medicineUtils,
  medicineGetters,
  medicineConstants,
} from 'domain/medicine';
import {
  If,
  Else,
  Grid,
  Card,
  Select,
  Switch,
  Button,
  Tooltip,
  MenuItem,
  DataGrid,
  Collapse,
  TextField,
  FormField,
  CardHeader,
  Typography,
  DatePicker,
  CardContent,
  Conditionally,
  InputAdornment,
  FormHelperText,
} from 'design-system';

import { getMedicineColumns } from './utils';
import PredefinedRenewalReminders from '../PredefinedRenewalReminders';

const { LINE_ITEM_FIELDS, LINE_ITEM_AVAILABILITY } = constants;
const {
  WHEN_HOW,
  USE_UNIT_TYPES,
  DURATION_UNIT_TYPES,
  QUANTITY_UNIT_TYPES,
  FREQUENCY_UNIT_TYPES,
} = medicineConstants;

const FORM_MODE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};

const LineItemList = ({
  list,
  orderId,
  isFetching,
  addLineItem,
  updateLineItem,
  deleteLineItem,
  isUpdatingLineItems,
  onFormStateChange = () => {},
  defaultInsuranceDiscount = 0,
  // Triggers a check before doing a delete. Returns a boolean, if true, the delete continues, if false, it doesn't
  beforeDelete = () => true,
}) => {
  const ref = useRef();
  const scrollRef = useRef();

  const [formMode, setFormMode] = useState(FORM_MODE.NEW);
  const [isFormExpanded, setFormExpanded] = useState(false);

  const populateForm = (lineItem) => {
    if (ref?.current) {
      const { reset } = ref.current.getMethods();

      const medicine = lineItemGetters.getMedicine(lineItem);
      const isFixedDiscount = lineItemGetters.isFixedDiscount(lineItem);
      const discountPercentage = Number(
        lineItemGetters.getDiscountPercentage(lineItem)
      );
      const patientDiscountValue = Number(
        lineItemGetters.getPatientDiscount(lineItem)?.value
      );
      const patientDiscountPercentage = Number(
        lineItemGetters.getPatientDiscountPercentage(lineItem)
      );
      const priceAfterInsuranceDiscount = Number(
        lineItemGetters.getPriceAfterInsuranceDiscount(lineItem)?.value || 0
      );
      const isApprovedByInsurance =
        lineItemGetters.isApprovedByInsurance(lineItem);
      const patientSharePercentage = isApprovedByInsurance
        ? Number(lineItemGetters.getPatientSharePercentage(lineItem) || 0) * 100
        : 100;

      const currency = medicine.packagePrice?.currency;
      const patientShareValue = (
        (patientSharePercentage * priceAfterInsuranceDiscount) /
        100
      ).toFixed(2);
      const netPrice = (
        priceAfterInsuranceDiscount - patientDiscountValue
      ).toFixed(2);

      const formattedNetPrice = `${netPrice} ${currency}`;
      const formattedPatientShareValue = `${patientShareValue} ${currency}`;
      const formattedPatientDiscountValue = `${patientDiscountValue.toFixed(
        2
      )} ${currency}`;
      const formattedPriceAfterInsuranceDiscount = `${priceAfterInsuranceDiscount.toFixed(
        2
      )} ${currency}`;

      reset({
        id: lineItemGetters.getId(lineItem),
        medicine,
        isApprovedByInsurance,
        isInStock:
          lineItemGetters.getAvailability(lineItem) ===
          LINE_ITEM_AVAILABILITY.IN_STOCK,
        price: Number(lineItemGetters.getPrice(lineItem)?.value || 0),
        priceAfterInsuranceDiscount: priceAfterInsuranceDiscount.toFixed(2),
        patientSharePercentage,
        isFixedDiscount: Boolean(isFixedDiscount),
        discountPercentage,
        patientDiscountValue: patientDiscountValue.toFixed(2),
        patientDiscountPercentage: patientDiscountPercentage * 100,
        quantity: Number(lineItemGetters.getQuantity(lineItem)),
        quantityUnit:
          medicineUtils.getKey(
            QUANTITY_UNIT_TYPES,
            lineItemGetters.getQuantityUnit(lineItem)
          ) ||
          lineItemGetters.getQuantityUnit(lineItem) ||
          QUANTITY_UNIT_TYPES.PACKET.key,
        duration: Number(lineItemGetters.getDuration(lineItem)),
        durationUnit:
          lineItemGetters.getDurationUnit(lineItem) ||
          DURATION_UNIT_TYPES.DAY.key,
        reminder: lineItemGetters.getReminderDate(lineItem)
          ? new Date(lineItemGetters.getReminderDate(lineItem))
          : null,
        dosage: Number(lineItemGetters.getUsage(lineItem)),
        dosageUnit:
          lineItemGetters.getUsageUnit(lineItem) || USE_UNIT_TYPES.TABLET.key,
        frequency: Number(lineItemGetters.getFrequency(lineItem)),
        frequencyUnit:
          lineItemGetters.getFrequencyUnit(lineItem) ||
          FREQUENCY_UNIT_TYPES.DAY.key,
        whenHow:
          keys(WHEN_HOW).find(
            (item) =>
              WHEN_HOW[item]?.key === lineItemGetters.getInstructions(lineItem)
          ) || null,
        patientShareValue,
        netPrice,
        formattedNetPrice,
        formattedPatientShareValue,
        formattedPatientDiscountValue,
        formattedPriceAfterInsuranceDiscount,
      });
    }
  };

  const resetForm = () => {
    if (ref?.current) {
      const { reset } = ref.current.getMethods();

      reset({
        id: 0,
        medicine: null,
        isApprovedByInsurance: isDefaultApprovedByInsurance(),
        isInStock: true,
        price: '',
        priceAfterInsuranceDiscount: '',
        patientSharePercentage: !isDefaultApprovedByInsurance() ? 100 : 0,
        isFixedDiscount: false,
        discountPercentage: defaultInsuranceDiscount,
        patientDiscount: 0,
        patientPercentageDiscount: 0,
        quantity: '',
        quantityUnit: QUANTITY_UNIT_TYPES.PACKET.key,
        duration: '',
        durationUnit: DURATION_UNIT_TYPES.DAY.key,
        reminder: null,
        dosage: '',
        dosageUnit: USE_UNIT_TYPES.TABLET.key,
        frequency: '',
        frequencyUnit: FREQUENCY_UNIT_TYPES.DAY.key,
        whenHow: null,
        patientShareValue: 0,
        patientDiscountValue: 0,
        netPrice: 0,
        formattedNetPrice: '',
        formattedPatientShareValue: '',
        formattedPatientDiscountValue: '',
        formattedPriceAfterInsuranceDiscount: '',
      });

      onFormStateChange({
        isDirty: false,
      });
    }
  };

  const onSubmit = (data) => {
    const { isApprovedByInsurance } = data;

    const price = Number(data.price || 0);
    const quantity = Number(data.quantity || 0);
    const currency = data?.medicine?.packagePrice?.currency;
    const discountPercentage = Number(data.discountPercentage || 0);
    const patientDiscountValue = Number(data.patientDiscountValue || 0);
    const packagePrice = Number(data.medicine?.packagePrice?.value || 0);
    const patientSharePercentage = Number(data.patientSharePercentage || 0);
    const priceAfterInsuranceDiscount = Number(
      data.priceAfterInsuranceDiscount || 0
    );
    const patientDiscountPercentage = Number(
      data.patientDiscountPercentage || 0
    );

    const hasPriceChanged = price !== packagePrice * quantity;

    const discountValue = (() => {
      if (isApprovedByInsurance) {
        return price * discountPercentage;
      }

      return price;
    })().toFixed(2);

    const payload = {
      [LINE_ITEM_FIELDS.ID.name]: data.id,
      [LINE_ITEM_FIELDS.ORDER_ID.name]: orderId,
      [LINE_ITEM_FIELDS.DURATION.name]: Number(data.duration),
      [LINE_ITEM_FIELDS.DURATION_UNIT.name]: data.duration.unit,
      [LINE_ITEM_FIELDS.FREQUENCY.name]: Number(data.frequency),
      [LINE_ITEM_FIELDS.FREQUENCY_UNIT.name]: data.frequencyUnit,
      [LINE_ITEM_FIELDS.QUANTITY.name]: Number(data.quantity),
      [LINE_ITEM_FIELDS.QUANTITY_UNIT.name]: data.quantityUnit,
      [LINE_ITEM_FIELDS.USAGE.name]: Number(data.dosage),
      [LINE_ITEM_FIELDS.USAGE_UNIT.name]: data.dosageUnit,
      [LINE_ITEM_FIELDS.INSTRUCTIONS.name]: data.whenHow,
      [LINE_ITEM_FIELDS.MEDICINE.name]: { id: data?.medicine?.id },
      [LINE_ITEM_FIELDS.IS_APPROVED_BY_INSURANCE.name]:
        data.isApprovedByInsurance,
      [LINE_ITEM_FIELDS.AVAILABILITY.name]: data.isInStock
        ? LINE_ITEM_AVAILABILITY.IN_STOCK
        : LINE_ITEM_AVAILABILITY.OUT_OF_STOCK,
      [LINE_ITEM_FIELDS.IS_FIXED_DISCOUNT.name]: data.isFixedDiscount,
      [LINE_ITEM_FIELDS.REMINDER_DATE.name]: (() => {
        if (data.reminder) {
          const date = new Date(data.reminder);

          return formatISO(date).split('T')[0];
        }

        return data.reminder;
      })(),
      [LINE_ITEM_FIELDS.PATIENT_SHARE_PERCENTAGE.name]:
        patientSharePercentage / 100,
      [LINE_ITEM_FIELDS.DISCOUNT.name]: discountValue,
      [LINE_ITEM_FIELDS.DISCOUNT_PERCENTAGE.name]: discountPercentage,
      [LINE_ITEM_FIELDS.PATIENT_DISCOUNT.name]: patientDiscountValue.toFixed(2),
      [LINE_ITEM_FIELDS.PATIENT_DISCOUNT_PERCENTAGE.name]:
        patientDiscountPercentage / 100,
      [LINE_ITEM_FIELDS.PRICE_AFTER_INSURANCE_DISCOUNT.name]: {
        value: priceAfterInsuranceDiscount.toFixed(2),
        currency,
      },
      ...(hasPriceChanged
        ? {
            [LINE_ITEM_FIELDS.PRICE.name]: {
              value: price.toFixed(2),
              currency,
            },
          }
        : {}),
    };

    if (data.id) {
      updateLineItem(orderId, data.id, payload);
    } else {
      addLineItem(orderId, payload);
      resetForm();
    }

    onFormStateChange({
      isDirty: false,
    });
  };

  const calculatePrices = (form, changedValues = {}) => {
    const currency = form.watch('medicine')?.packagePrice?.currency;
    const patientDiscountPercentage = Number(
      form.watch('patientDiscountPercentage') || 0
    );
    const patientSharePercentage = Number(
      form.watch('patientSharePercentage') || 0
    );
    const price = Number(form.watch('price') || 0);
    const isApprovedByInsurance = form.watch('isApprovedByInsurance');
    const discountPercentage = Number(form.watch('discountPercentage') || 0);

    const priceAfterInsuranceDiscount =
      price * (1 - (isApprovedByInsurance ? discountPercentage : 0));

    const patientShareValue =
      (patientSharePercentage * priceAfterInsuranceDiscount) / 100;
    const patientDiscountValue =
      changedValues?.patientDiscountValue ||
      ((patientShareValue * patientDiscountPercentage) / 100).toFixed(2);
    const netPrice = priceAfterInsuranceDiscount - patientDiscountValue;

    const formattedNetPrice = `${netPrice.toFixed(2)} ${currency}`;
    const formattedPatientShareValue = `${patientShareValue.toFixed(
      2
    )} ${currency}`;
    const formattedPatientDiscountValue = `${patientDiscountValue} ${currency}`;
    const formattedPriceAfterInsuranceDiscount = `${priceAfterInsuranceDiscount} ${currency}`;

    form.setValue('netPrice', netPrice);
    form.setValue('patientShareValue', patientShareValue);

    if (changedValues?.patientDiscountValue === undefined) {
      form.setValue('patientDiscountValue', patientDiscountValue);
    }

    form.setValue('priceAfterInsuranceDiscount', priceAfterInsuranceDiscount);

    form.setValue('formattedNetPrice', formattedNetPrice);
    form.setValue('formattedPatientShareValue', formattedPatientShareValue);
    form.setValue(
      'formattedPatientDiscountValue',
      formattedPatientDiscountValue
    );
    form.setValue(
      'formattedPriceAfterInsuranceDiscount',
      formattedPriceAfterInsuranceDiscount
    );
  };

  return (
    <Card>
      <CardHeader
        title={
          <div ref={scrollRef}>
            <Typography variant="h5">Prescription</Typography>
          </div>
        }
        action={
          (isFormExpanded ||
            isAllowed('orderView', 'medicines', 'add') ||
            isAllowed('orderView', 'orderDetails', 'printMedicineSticker')) && (
            <Grid container alignItems="center" columnSpacing={2}>
              {isAllowed(
                'orderView',
                'orderDetails',
                'printMedicineSticker'
              ) && (
                <Grid item>
                  <Tooltip
                    placement="top"
                    injectWrapper
                    title="Print all medicine stickers"
                  >
                    <DownloadMultiplePdfButton
                      size="medium"
                      variant="text"
                      color="primary"
                      resource="medicine"
                      loading={isFetching && { width: 210 }}
                      fileNames={(list || []).map(
                        (lineItem) =>
                          `Order#${
                            lineItem.orderId
                          }_${lineItem.medicine.packageName.replace(/\s/g, '')}`
                      )}
                      payloads={(list || []).map((lineItem) => ({
                        name: lineItem.medicine.packageName,
                        instructionLine: lineItem.instructions,
                        frequency: lineItem.frequency,
                        frequencyUnit: lineItem.frequencyUnit,
                        frequencyUse: lineItem.frequencyUse,
                        frequencyUseUnit: lineItem.frequencyUseUnit,
                      }))}
                    >
                      Print all medicine stickers
                    </DownloadMultiplePdfButton>
                  </Tooltip>
                </Grid>
              )}
              {isAllowed('orderView', 'orderDetails', 'addMedicine') && (
                <Grid item>
                  {isFetching || isFormExpanded ? (
                    <Button
                      size="large"
                      color="primary"
                      loading={isFetching && { width: 200 }}
                      startIcon={<ExpandLessIcon />}
                      onClick={() => {
                        setFormExpanded(false);
                      }}
                      sx={{
                        width: 200,
                      }}
                    >
                      Hide Form
                    </Button>
                  ) : (
                    <Tooltip
                      placement="top"
                      title="Click here to show the medicine form"
                    >
                      <Button
                        size="large"
                        color="primary"
                        startIcon={<ExpandMoreIcon />}
                        onClick={() => {
                          // If the creation form has already been open, do not reset the form.
                          // 0 is the id of the creation form.
                          if (ref?.current.getMethods().watch('id') !== 0) {
                            resetForm();
                          }

                          setFormMode(FORM_MODE.NEW);
                          setFormExpanded(true);
                        }}
                        sx={{
                          width: 200,
                        }}
                      >
                        Add a Medicine
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              )}
            </Grid>
          )
        }
      />
      <CardContent
        noPadding
        sx={{
          '&:last-child': {
            pb: 0,
          },
        }}
      >
        <Grid container column rowSpacing={isFormExpanded ? 3 : 0}>
          <Grid item sx={{ px: 2 }}>
            <Form
              ref={ref}
              onSubmit={onSubmit}
              onChange={() => {
                onFormStateChange({
                  isDirty: true,
                });
              }}
            >
              {(form) => {
                return (
                  <Collapse in={isFormExpanded}>
                    <Grid container column rowSpacing={1}>
                      <Grid item container>
                        <FormField
                          label="MEDICINE"
                          required
                          field={
                            <Grid container columnSpacing={2}>
                              <Grid item flex={1}>
                                <Controller
                                  name="medicine"
                                  control={form.control}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => {
                                    return (
                                      <>
                                        <MedicineAutocomplete
                                          size="medium"
                                          onChange={(newValue) => {
                                            const packagePrice =
                                              newValue?.packagePrice?.value ||
                                              0;
                                            const quantity =
                                              Number(form.watch('quantity')) ||
                                              1;

                                            form.setValue(
                                              'price',
                                              Math.max(
                                                packagePrice * quantity,
                                                0
                                              ).toFixed(2)
                                            );

                                            onChange(newValue);
                                            calculatePrices(form);
                                          }}
                                          defaultValue={value}
                                          endpoint="/medicine"
                                          getOptionLabel={(option) => {
                                            const label = [];

                                            const packageName =
                                              medicineGetters.getPackageName(
                                                option
                                              );
                                            const genericName =
                                              medicineGetters.getGenericName(
                                                option
                                              );
                                            const strength =
                                              medicineGetters.getStrength(
                                                option
                                              );
                                            const dosageForm =
                                              medicineGetters.getDosageForm(
                                                option
                                              );
                                            const packageSize =
                                              medicineGetters.getPackageSizeSummary(
                                                option
                                              );
                                            if (packageName) {
                                              label.push(packageName);
                                            }
                                            if (genericName) {
                                              label.push(genericName);
                                            }
                                            if (strength) {
                                              label.push(strength);
                                            }
                                            if (dosageForm) {
                                              label.push(dosageForm);
                                            }
                                            if (packageSize) {
                                              label.push(packageSize);
                                            }
                                            return uniqBy(label).join(' - ');
                                          }}
                                          responseGetter={(response) =>
                                            get(response, 'data.data', [])
                                          }
                                          sx={{ width: '100%' }}
                                          getRequestParams={(searchQuery) => {
                                            const urlSearchParams =
                                              new URLSearchParams();

                                            urlSearchParams.append('limit', 35);
                                            urlSearchParams.append('offset', 0);
                                            urlSearchParams.append(
                                              'sort',
                                              'id,DESC'
                                            );

                                            urlSearchParams.append(
                                              'or',
                                              `genericName||$startsL||${searchQuery}`
                                            );
                                            urlSearchParams.append(
                                              'or',
                                              `packageName||$startsL||${searchQuery}`
                                            );
                                            urlSearchParams.append(
                                              'or',
                                              `drugCode||$contL||${searchQuery}`
                                            );

                                            return urlSearchParams;
                                          }}
                                          throttle={(fn, searchQuery) => {
                                            if (searchQuery.length >= 3) {
                                              fn();
                                            }
                                          }}
                                          renderInput={(inputProps) => {
                                            return (
                                              <TextField
                                                {...inputProps}
                                                fullWidth
                                                size="medium"
                                                name="medicine"
                                                placeholder="Medicine"
                                                error={Boolean(error)}
                                                helperText={
                                                  error?.message ?? null
                                                }
                                                sx={{
                                                  '& .MuiInputBase-root': {
                                                    pl: {
                                                      xs: 1,
                                                      xl: 2,
                                                    },
                                                  },
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                        {value?.packagePrice?.value && (
                                          <FormHelperText>
                                            {`Package price = ${value.packagePrice.value} ${value.packagePrice.currency}`}
                                          </FormHelperText>
                                        )}
                                      </>
                                    );
                                  }}
                                  rules={{
                                    required: 'Medicine is required',
                                  }}
                                />
                              </Grid>
                              <Grid item container flex={1} sx={{ height: 40 }}>
                                <Grid item>
                                  <Controller
                                    name="isApprovedByInsurance"
                                    control={form.control}
                                    defaultValue={isDefaultApprovedByInsurance()}
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <>
                                        <Switch
                                          label="Approved by Insurance"
                                          color="success"
                                          checked={value}
                                          onChange={(event, newValue) => {
                                            // TODO: set the previous value into form.
                                            // So that when you toggle to true it goes back to the previous value
                                            // rather than 0
                                            form.setValue(
                                              'patientSharePercentage',
                                              newValue ? 0 : 100
                                            );

                                            onChange(event, newValue);
                                            calculatePrices(form);
                                          }}
                                        />
                                        {value && (
                                          <FormHelperText sx={{ px: 1 }}>
                                            Insurance discount = %
                                            {(
                                              (formMode === FORM_MODE.EDIT
                                                ? form.watch(
                                                    'discountPercentage'
                                                  )
                                                : defaultInsuranceDiscount) *
                                              100
                                            ).toFixed(2)}
                                          </FormHelperText>
                                        )}
                                      </>
                                    )}
                                  />
                                </Grid>
                                <Grid item>
                                  <Controller
                                    name="isInStock"
                                    control={form.control}
                                    defaultValue
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <Switch
                                        label={
                                          value ? 'In Stock' : 'Out of Stock'
                                        }
                                        color="secondary"
                                        checked={value}
                                        onChange={onChange}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                      <Grid item container columnSpacing={2}>
                        <Grid item flex={1}>
                          <FormField
                            label="QUANTITY"
                            required
                            field={
                              <Controller
                                name="quantity"
                                control={form.control}
                                valueAsNumber
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    fullWidth
                                    size="medium"
                                    type="number"
                                    value={value}
                                    error={!!error}
                                    name="quantity"
                                    placeholder="Medicine unit count"
                                    onChange={(event) => {
                                      const quantity = Number(
                                        event.target.value || 1
                                      );
                                      const packagePrice = Number(
                                        form.watch('medicine')?.packagePrice
                                          ?.value || 0
                                      );

                                      form.setValue(
                                        'price',
                                        Math.max(
                                          packagePrice * quantity,
                                          0
                                        ).toFixed(2)
                                      );

                                      onChange(event);
                                      calculatePrices(form);
                                    }}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        pr: 0,
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Controller
                                            name="quantityUnit"
                                            defaultValue={
                                              QUANTITY_UNIT_TYPES.PACKET.key
                                            }
                                            control={form.control}
                                            render={({ field }) => (
                                              <Select
                                                value={field.value}
                                                size="medium"
                                                color="standard"
                                                variant="filled"
                                                onChange={field.onChange}
                                                renderValue={(val) =>
                                                  val
                                                    ? QUANTITY_UNIT_TYPES[val]
                                                        ?.label
                                                    : val
                                                }
                                              >
                                                {keys(QUANTITY_UNIT_TYPES)
                                                  .filter(
                                                    (item) =>
                                                      !QUANTITY_UNIT_TYPES[item]
                                                        .hidden
                                                  )
                                                  .map((item) => (
                                                    <MenuItem
                                                      value={item}
                                                      key={item}
                                                    >
                                                      {
                                                        QUANTITY_UNIT_TYPES[
                                                          item
                                                        ].label
                                                      }
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            )}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                rules={{
                                  required: 'Quantity is required',
                                  min: {
                                    value: 1,
                                    message: 'Quantity should be 1 or more',
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item flex={1}>
                          <FormField
                            label="DURATION"
                            required
                            field={
                              <Controller
                                name="duration"
                                control={form.control}
                                valueAsNumber
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    fullWidth
                                    size="medium"
                                    type="number"
                                    value={value}
                                    error={!!error}
                                    name="duration"
                                    onChange={onChange}
                                    placeholder="Medicine course period"
                                    helperText={error ? error.message : null}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        pr: 0,
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Controller
                                            name="durationUnit"
                                            defaultValue={
                                              DURATION_UNIT_TYPES.DAY.key
                                            }
                                            control={form.control}
                                            render={({ field }) => (
                                              <Select
                                                value={field.value}
                                                size="medium"
                                                color="standard"
                                                variant="filled"
                                                onChange={field.onChange}
                                                renderValue={(val) =>
                                                  val
                                                    ? DURATION_UNIT_TYPES[val]
                                                        ?.label
                                                    : val
                                                }
                                              >
                                                {keys(DURATION_UNIT_TYPES).map(
                                                  (item) => (
                                                    <MenuItem
                                                      value={item}
                                                      key={item}
                                                    >
                                                      {
                                                        DURATION_UNIT_TYPES[
                                                          item
                                                        ].label
                                                      }
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            )}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                rules={{
                                  required: 'Duration is required',
                                  min: {
                                    value: 1,
                                    message: 'Duration should 1 or more',
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item flex={1}>
                          <FormField
                            label="REMINDER DATE"
                            field={
                              <Controller
                                name="reminder"
                                control={form.control}
                                defaultValue={null}
                                valueAsDate
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <DatePicker
                                    value={value}
                                    inputFormat={dateFormat}
                                    onChange={onChange}
                                    renderInput={(props) => (
                                      <TextField
                                        fullWidth
                                        size="medium"
                                        error={!!error}
                                        name="reminder"
                                        placeholder="Reminder date"
                                        helperText={
                                          error ? error.message : null
                                        }
                                        {...props}
                                      />
                                    )}
                                    components={{
                                      PaperContent: PredefinedRenewalReminders,
                                    }}
                                    componentsProps={{
                                      paperContent: {
                                        onChange,
                                      },
                                    }}
                                  />
                                )}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item container columnSpacing={2}>
                        <Grid item flex={1}>
                          <FormField
                            label="DOSAGE"
                            required
                            field={
                              <Controller
                                name="dosage"
                                control={form.control}
                                valueAsNumber
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    fullWidth
                                    size="medium"
                                    type="number"
                                    value={value}
                                    error={!!error}
                                    name="dosage"
                                    placeholder="Frequency use"
                                    onChange={onChange}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        pr: 0,
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Controller
                                            name="dosageUnit"
                                            defaultValue={
                                              USE_UNIT_TYPES.TABLET.key
                                            }
                                            control={form.control}
                                            render={({ field }) => (
                                              <Select
                                                value={field.value}
                                                size="medium"
                                                color="standard"
                                                variant="filled"
                                                onChange={field.onChange}
                                                renderValue={(val) =>
                                                  val
                                                    ? USE_UNIT_TYPES[val]?.label
                                                    : val
                                                }
                                              >
                                                {keys(USE_UNIT_TYPES)
                                                  .filter(
                                                    (item) =>
                                                      !USE_UNIT_TYPES[item]
                                                        .hidden
                                                  )
                                                  .map((item) => (
                                                    <MenuItem
                                                      value={item}
                                                      key={item}
                                                    >
                                                      {
                                                        USE_UNIT_TYPES[item]
                                                          .label
                                                      }
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            )}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                rules={{
                                  required: 'Dosage is required',
                                  min: {
                                    value: 0.1,
                                    message:
                                      'Dosage amount should be more than 0',
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item flex={1}>
                          <FormField
                            label="FREQUENCY"
                            required
                            field={
                              <Controller
                                name="frequency"
                                control={form.control}
                                valueAsNumber
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    fullWidth
                                    size="medium"
                                    type="number"
                                    value={value}
                                    error={!!error}
                                    name="frequency"
                                    placeholder="Frequency"
                                    onChange={onChange}
                                    helperText={error ? error.message : null}
                                    sx={{
                                      '& .MuiInputBase-root': {
                                        pr: 0,
                                      },
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Controller
                                            name="frequencyUnit"
                                            defaultValue={
                                              FREQUENCY_UNIT_TYPES.DAY.key
                                            }
                                            control={form.control}
                                            render={({ field }) => (
                                              <Select
                                                value={field.value}
                                                size="medium"
                                                color="standard"
                                                variant="filled"
                                                onChange={field.onChange}
                                                renderValue={(val) =>
                                                  val
                                                    ? FREQUENCY_UNIT_TYPES[val]
                                                        ?.label
                                                    : val
                                                }
                                              >
                                                {keys(FREQUENCY_UNIT_TYPES)
                                                  .filter(
                                                    (item) =>
                                                      !FREQUENCY_UNIT_TYPES[
                                                        item
                                                      ].hidden
                                                  )
                                                  .map((item) => (
                                                    <MenuItem
                                                      value={item}
                                                      key={item}
                                                    >
                                                      {
                                                        FREQUENCY_UNIT_TYPES[
                                                          item
                                                        ].label
                                                      }
                                                    </MenuItem>
                                                  ))}
                                              </Select>
                                            )}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                                rules={{
                                  required: 'Frequency is required',
                                  min: {
                                    value: 1,
                                    message: 'Frequency should be 1 or more',
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item flex={1}>
                          <FormField
                            label="Intake Instructions"
                            required
                            field={
                              <Controller
                                name="whenHow"
                                control={form.control}
                                defaultValue={null}
                                rules={{
                                  required: 'Dosage instructions is required',
                                }}
                                render={({ field, fieldState: { error } }) => (
                                  <Select
                                    fullWidth
                                    value={field.value}
                                    size="medium"
                                    color="primary"
                                    variant="outlined"
                                    placeholder="Select.."
                                    onChange={field.onChange}
                                    error={Boolean(error)}
                                    helperText={error?.message ?? null}
                                    renderValue={(val) =>
                                      val ? WHEN_HOW[val]?.label : val
                                    }
                                    sx={{
                                      backgroundColor: (theme) =>
                                        theme.palette.grey[200],
                                    }}
                                  >
                                    {keys(WHEN_HOW)
                                      .filter((item) => !WHEN_HOW[item].hidden)
                                      .map((item) => (
                                        <MenuItem value={item} key={item}>
                                          {WHEN_HOW[item].label}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                )}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item container columnSpacing={2}>
                        <Grid item flex={1}>
                          <FormField
                            label="TOTAL LINE ITEM PRICE"
                            required
                            field={
                              <Controller
                                name="price"
                                control={form.control}
                                valueAsNumber
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <TextField
                                      fullWidth
                                      name="price"
                                      size="medium"
                                      type="number"
                                      value={value}
                                      error={!!error}
                                      placeholder="Price.."
                                      onChange={(e) => {
                                        onChange(e);
                                        calculatePrices(form);
                                      }}
                                      helperText={error ? error.message : null}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {
                                              form.watch('medicine')
                                                ?.packagePrice?.currency
                                            }
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                    {Boolean(
                                      form.watch('isApprovedByInsurance') &&
                                        form.watch(
                                          'priceAfterInsuranceDiscount'
                                        )
                                    ) && (
                                      <FormHelperText>
                                        {`Price after insurance discount = ${form.watch(
                                          'formattedPriceAfterInsuranceDiscount'
                                        )}`}
                                      </FormHelperText>
                                    )}
                                  </>
                                )}
                                rules={{
                                  required: 'Price is required',
                                  validate: {
                                    positive: (v) =>
                                      formValidationFunctions.positive(v),
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item flex={1}>
                          <FormField
                            label="PATIENT SHARE (%)"
                            help="Percentage of patient share (e.g, 10%). For self-pay/cash patients use 100%"
                            field={
                              <Controller
                                name="patientSharePercentage"
                                control={form.control}
                                valueAsNumber
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <>
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      type="number"
                                      value={value}
                                      error={!!error}
                                      name="patientSharePercentage"
                                      disabled={
                                        !form.watch('isApprovedByInsurance')
                                      }
                                      placeholder="Patient Share Percentage"
                                      onChange={(e) => {
                                        onChange(e);
                                        calculatePrices(form);
                                      }}
                                      helperText={error ? error.message : null}
                                    />
                                    {form.watch(
                                      'formattedPatientShareValue'
                                    ) && (
                                      <FormHelperText>
                                        Patient share ={' '}
                                        {form.watch(
                                          'formattedPatientShareValue'
                                        )}
                                      </FormHelperText>
                                    )}
                                  </>
                                )}
                                rules={{
                                  max: {
                                    value: 100,
                                    message:
                                      'A percentage cannot be more than 100',
                                  },
                                  min: {
                                    value: 0,
                                    message: 'Only positive numbers',
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                        <Grid item flex={1}>
                          <FormField
                            label={`PATIENT DISCOUNT
                                    ${
                                      !form.watch('isFixedDiscount')
                                        ? '(%)'
                                        : ''
                                    }
                                    `}
                            action={
                              <Controller
                                name="isFixedDiscount"
                                control={form.control}
                                defaultValue
                                render={({ field: { onChange, value } }) => (
                                  <Switch
                                    size="small"
                                    label={value ? 'Fixed value' : 'Percentage'}
                                    color="primary"
                                    checked={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            }
                            labelProps={{
                              sx: {
                                height: 28,
                              },
                            }}
                            help={`Discount on patient share. E.g, 10% or 10 ${
                              form.watch('medicine')?.packagePrice?.currency ||
                              'AED'
                            }`}
                            field={
                              form.watch('isFixedDiscount') ? (
                                <Controller
                                  key="patientDiscountValue"
                                  name="patientDiscountValue"
                                  control={form.control}
                                  defaultValue={0}
                                  valueAsNumber
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      type="number"
                                      value={value}
                                      error={!!error}
                                      name="patientDiscountValue"
                                      onChange={(e) => {
                                        const patientDiscountValue = Number(
                                          e.target.value
                                        );
                                        const patientShareValue = Number(
                                          form.watch('patientShareValue') || 0
                                        );

                                        form.setValue(
                                          'patientDiscountPercentage',
                                          (
                                            (patientDiscountValue /
                                              patientShareValue) *
                                            100
                                          ).toFixed(2)
                                        );

                                        calculatePrices(form, {
                                          patientDiscountValue,
                                        });
                                        onChange(e);
                                      }}
                                      placeholder="Patient discount value"
                                      helperText={error ? error.message : null}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {
                                              form.watch('medicine')
                                                ?.packagePrice?.currency
                                            }
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                  rules={{
                                    max: {
                                      value: form.watch('patientShareValue'),
                                      message:
                                        'Discount cannot be more than the actual patient share value',
                                    },
                                    min: {
                                      value: 0,
                                      message: 'Only positive numbers',
                                    },
                                  }}
                                />
                              ) : (
                                <Controller
                                  key="patientDiscountPercentage"
                                  name="patientDiscountPercentage"
                                  control={form.control}
                                  defaultValue={0}
                                  valueAsNumber
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      type="number"
                                      value={value}
                                      error={!!error}
                                      name="patientDiscountPercentage"
                                      onChange={(e) => {
                                        const patientShareValue =
                                          form.watch('patientShareValue');

                                        form.setValue(
                                          'patientDiscountValue',
                                          (
                                            (Number(e.target.value) / 100) *
                                            patientShareValue
                                          ).toFixed(2)
                                        );

                                        onChange(e);
                                        calculatePrices(form);
                                      }}
                                      placeholder="Patient discount percentage"
                                      helperText={error ? error.message : null}
                                    />
                                  )}
                                  rules={{
                                    max: {
                                      value: 100,
                                      message:
                                        'A percentage cannot be more than 100',
                                    },
                                    min: {
                                      value: 0,
                                      message: 'Only positive numbers',
                                    },
                                  }}
                                />
                              )
                            }
                          />
                          {Boolean(form.watch('patientShareValue')) && (
                            <FormHelperText>
                              {(() => {
                                const patientShareValue = Number(
                                  form.watch('patientShareValue') || 0
                                );
                                const patientDiscountValue = Number(
                                  form.watch('patientDiscountValue') || 0
                                );
                                const currency =
                                  form.watch('medicine')?.packagePrice.currency;

                                return `Patient share after discount = ${(
                                  patientShareValue - patientDiscountValue
                                ).toFixed(2)} ${currency}`;
                              })()}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item alignSelf="flex-end" sx={{ mt: 2 }}>
                        {form.watch('formattedNetPrice') && (
                          <Typography variant="p3" sx={{ mr: 2 }}>
                            <strong>Net line item price: </strong>
                            {form.watch('formattedNetPrice')}
                          </Typography>
                        )}
                        {formMode === FORM_MODE.EDIT && (
                          <Button
                            variant="text"
                            color="primary"
                            sx={{ mr: 2 }}
                            disabled={isUpdatingLineItems}
                            onClick={() => {
                              resetForm();
                              setFormMode(FORM_MODE.NEW);
                            }}
                          >
                            Cancel editing
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={
                            formMode === FORM_MODE.EDIT &&
                            !form.formState.isDirty
                          }
                          spinning={isUpdatingLineItems}
                        >
                          <Conditionally>
                            <If condition={formMode === FORM_MODE.NEW}>
                              Add to prescription
                            </If>
                            <Else>Save changes</Else>
                          </Conditionally>
                        </Button>
                      </Grid>
                    </Grid>
                  </Collapse>
                );
              }}
            </Form>
          </Grid>
          <Grid item>
            <DataGrid
              rows={list}
              loading={isFetching || isUpdatingLineItems}
              columns={getMedicineColumns({
                onEditButtonClicked: (medicineLineItem) => {
                  if (
                    ref.current.getMethods().watch('id') !==
                    lineItemGetters.getId(medicineLineItem)
                  ) {
                    populateForm(medicineLineItem);
                  }

                  setFormMode(FORM_MODE.EDIT);
                  setFormExpanded(true);

                  /**
                   * Where scrollRef is the header of the section:
                   * - scrollRef.current.getBoundingClientRect().top: The position (in pixels) of the top edge of scrollRef,
                   *    knowing that this value is relevant to the view port, so it changes if you scroll.
                   * - document.querySelector('html').scrollTop: How many (in pixels) has the document scrolled vertically
                   * - 100: it's an enough number to make sure the top nav bar doesn't cover our content
                   */
                  window.scrollTo(
                    0,
                    scrollRef.current.getBoundingClientRect().top +
                      document.querySelector('html').scrollTop -
                      100
                  );
                },
                onDelete: (medicineLineItem) => {
                  deleteLineItem(orderId, medicineLineItem.id);

                  const formMethods = ref?.current?.getMethods();

                  if (formMethods) {
                    const lineItemId = formMethods.watch('id');

                    if (lineItemId === medicineLineItem.id) {
                      resetForm();
                    }
                  }
                },
                beforeDelete,
              })}
              footer={{
                isCountShown: false,
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LineItemList;
