export const PACKAGE_NAME = 'Medicine';

export const LS_MEDICINE_FILTERS = 'medicine_filters';

export const FIELDS = {
  STATUS: {
    name: 'status',
  },
  SUMMARY: {
    name: 'summary',
  },
  PACKAGE_NAME: {
    name: 'packageName',
  },
  GENERIC_NAME: {
    name: 'genericName',
  },
  DRUG_CODE: {
    name: 'drugCode',
  },
  ID: {
    name: 'id',
  },
  LEGAL_NAME: {
    name: 'legalName',
  },
  STRENGTH: {
    name: 'strength',
  },
  DOSAGE_FORM: {
    name: 'dosageForm',
  },
  DISPENSE_MODE: {
    name: 'dispenseMode',
  },
  PACKAGE_SIZE_SUMMARY: {
    name: 'packageSizeSummary',
  },
  PACKAGE_PRICE: {
    name: 'packagePrice',
  },
  UNIT_PRICE: {
    name: 'unitPrice',
  },
  PACKAGE_COST: {
    name: 'packageCost',
  },
  UNIT_COST: {
    name: 'unitCost',
  },
  TENANT_KEY: {
    name: 'tenantKey',
  },
};

export const MEDICINE_STATUS = {
  ACTIVE: { key: 'Active', label: 'Active' },
  DELETED: { key: 'Deleted', label: 'Deleted' },
};

export const ROUTE_OF_ADMIN = [
  'Buccal',
  'Dental',
  'Apply Externally',
  'Epidural',
  'Endotrachial Tube',
  'Gastrostomy Tube',
  'GU Irrigant',
  'Immerse (Soak) Body Part',
  'Intra-arterial',
  'Intrabursal',
  'Intracardiac',
  'Intracervical (uterus)',
  'Intradermal',
  'Inhalation',
  'Intrahepatic Artery',
  'Intramuscular',
  'Intranasal',
  'Intraocular',
  'Intraperitoneal',
  'Intrasynovial',
  'Intrathecal',
  'Intrauterine',
  'Intravenous',
  'Mouth/Throat',
  'Mucous Membrane',
  'Nasal',
  'Nasogastric',
  'Nasal Prongs',
  'Nasotrachial Tube',
  'Ophthalmic',
  'Otic',
  'Other/Miscellaneous',
  'Perfusion',
  'Oral',
  'Rectal',
  'Rebreather Mask',
  'Soaked Dressing',
  'Subcutaneous',
  'Sublingual',
  'Topical',
  'Tracheostomy',
  'Transdermal',
  'Translingual',
  'Urethral',
  'Vaginal',
  'Ventimask',
  'Wound',
  'Intravesicular',
  'Intracaudal',
  'Perineural',
  'Intra-articular',
  'Intra-biliary',
  'Intra-cavernoasal',
  'Intracerebral',
  'Intralymphatic',
  'Intrapleural',
  'Retrobulbar',
  'Submucosal',
  'Not Available',
];

export const USE_UNIT_TYPES = {
  TABLET: {
    key: 'TABLET',
    label: 'Tablet',
    pluralable: true,
  },
  MG: {
    key: 'MG',
    label: 'mg',
  },
  ML: {
    key: 'ML',
    label: 'mL',
  },
  DROPS: {
    key: 'DROPS',
    label: 'Drop',
    pluralable: true,
  },
  GM: {
    key: 'GM',
    label: 'gm',
  },
  BOX: {
    key: 'BOX',
    label: 'Box',
    pluralable: true,
  },
  AMPOULES: {
    key: 'AMPOULES',
    label: 'Ampoule',
    pluralable: true,
  },
  VIALS: {
    key: 'VIALS',
    label: 'Vial',
    pluralable: true,
  },
  SYRUP: {
    key: 'SYRUP',
    label: 'Syrup',
    pluralable: true,
  },
  CAPSULES: {
    key: 'CAPSULES',
    label: 'Capsule',
    pluralable: true,
  },
  IU: {
    key: 'IU',
    label: 'IU',
  },
  MCG: {
    key: 'MCG',
    label: 'mcg',
  },
  PACKET: {
    key: 'PACKET',
    label: 'Packet',
    pluralable: true,
  },
  PUFF: {
    key: 'PUFF',
    label: 'Puff',
    pluralable: true,
  },
  PESSARY: {
    key: 'PESSARY',
    label: 'Pessary',
    pluralable: true,
  },
  SUPPOSITORY: {
    key: 'SUPPOSITORY',
    label: 'Suppository',
    pluralable: true,
  },
  GARGLE: {
    key: 'GARGLE',
    label: 'Gargle',
    pluralable: true,
  },
  PUMP: {
    key: 'PUMP',
    label: 'Pump',
    pluralable: true,
    hidden: true,
  },
  RESPULE: {
    key: 'RESPULE',
    label: 'Respule',
    pluralable: true,
  },
};

export const FREQUENCY_UNIT_TYPES = {
  HOUR: {
    key: 'HOUR',
    label: 'Per hour',
    /**
     * @deprecated
     */
    desc: 'every Hour',
  },
  DAY: {
    key: 'DAY',
    label: 'Per day',
    /**
     * @deprecated
     */
    desc: 'per day',
  },
  WEEK: {
    key: 'WEEK',
    label: 'Per week',
    /**
     * @deprecated
     */
    desc: 'per week',
  },
  TWO_WEEKS: {
    key: 'TWO_WEEKS',
    label: 'Every two weeks',
    /**
     * @deprecated
     */
    desc: 'every two weeks',
  },
  THREE_WEEKS: {
    key: 'THREE_WEEKS',
    label: 'Every three weeks',
    /**
     * @deprecated
     */
    desc: 'every three weeks',
  },
  MONTH: {
    key: 'MONTH',
    label: 'Per month',
    /**
     * @deprecated
     */
    desc: 'per month',
  },
  YEAR: {
    key: 'YEAR',
    label: 'Per year',
    /**
     * @deprecated
     */
    desc: 'per week',
  },
  SOS: {
    key: 'SOS',
    label: 'sos',
    /**
     * @deprecated
     */
    desc: 'sos',
  },
  AS_NEEDED: {
    key: 'AS_NEEDED',
    label: 'As needed',
    /**
     * @deprecated
     */
    desc: 'As needed',
  },
  EIGHT_HOURS: {
    key: 'EIGHT_HOURS',
    label: 'Every 8 hours',
    /**
     * @deprecated
     */
    desc: 'every 8 hours',
    hidden: true,
  },
  TWELVE_HOURS: {
    key: 'TWELVE_HOURS',
    label: '12 hours',
    /**
     * @deprecated
     */
    desc: 'every 12 hours',
    hidden: true,
  },
  SIX_HOURS: {
    key: 'SIX_HOURS',
    label: '6 hours',
    /**
     * @deprecated
     */
    desc: 'every 6 hours',
    hidden: true,
  },
};

export const WHEN_HOW = {
  'Before food': {
    key: 'Before food',
    label: 'Before food',
    desc: 'Before food',
  },
  'After food': {
    key: 'After food',
    label: 'After food',
    desc: 'After food',
  },
  Afternoon: {
    key: 'Afternoon',
    label: 'Afternoon',
    desc: 'Afternoon',
  },
  'With food': {
    key: 'With food',
    label: 'With food',
    desc: 'With food',
  },
  Evening: {
    key: 'Evening',
    label: 'Evening',
    desc: 'Evening',
  },
  Night: {
    key: 'Night',
    label: 'Night',
    desc: 'Night',
  },
  Morning: {
    key: 'Morning',
    label: 'Morning',
    desc: 'Morning',
  },
  sos: {
    key: 'sos',
    label: 'sos',
    desc: 'sos',
  },
  'Before breakfast': {
    key: 'Before breakfast',
    label: 'Before breakfast',
    desc: 'Before breakfast',
    hidden: true,
  },
  'After breakfast': {
    key: 'After breakfast',
    label: 'After breakfast',
    desc: 'After breakfast',
    hidden: true,
  },
  'Before lunch': {
    key: 'Before lunch',
    label: 'Before lunch',
    desc: 'Before lunch',
    hidden: true,
  },
  'After lunch': {
    key: 'After lunch',
    label: 'After lunch',
    desc: 'After lunch',
    hidden: true,
  },
  'Before dinner': {
    key: 'Before dinner',
    label: 'Before dinner',
    desc: 'Before dinner',
    hidden: true,
  },
  'After dinner': {
    key: 'After dinner',
    label: 'After dinner',
    desc: 'After dinner',
    hidden: true,
  },
  'Before meal': {
    key: 'Before meal',
    label: 'Before meal',
    desc: 'Before meal',
    hidden: true,
  },
  'After meal': {
    key: 'After meal',
    label: 'After meal',
    desc: 'After meal',
    hidden: true,
  },
  'At bedtime': {
    key: 'At bedtime',
    label: 'At bedtime',
    desc: 'At bedtime',
    hidden: true,
  },
  'At night': {
    key: 'At night',
    label: 'At night',
    desc: 'At night',
    hidden: true,
  },
  '1 hour before breakfast/food': {
    key: '1 hour before breakfast/food',
    label: '1 hour before breakfast/food',
    desc: '1 hour before breakfast/food',
    hidden: true,
  },
  '30 mins before meal': {
    key: '30 mins before meal',
    label: '30 mins before meal',
    desc: '30 mins before meal',
    hidden: true,
  },
  'With food/any meal': {
    key: 'With food/any meal',
    label: 'With food/any meal',
    desc: 'With food/any meal',
    hidden: true,
  },
  'After fatty/heavy meal': {
    key: 'After fatty/heavy meal',
    label: 'After fatty/heavy meal',
    desc: 'After fatty/heavy meal',
    hidden: true,
  },
  'Not related to food': {
    key: 'Not related to food',
    label: 'Not related to food',
    desc: 'Not related to food',
    hidden: true,
  },
  'Topical application': {
    key: 'Topical application',
    label: 'Topical application',
    desc: 'Topical application',
    hidden: true,
  },
  'Nasal spray': {
    key: 'Nasal spray',
    label: 'Nasal spray',
    desc: 'Nasal spray',
    hidden: true,
  },
  Inhalations: {
    key: 'Inhalations',
    label: 'Inhalations',
    desc: 'Inhalations',
    hidden: true,
  },
  'Eye drops': {
    key: 'Eye drops',
    label: 'Eye drops',
    desc: 'Eye drops',
    hidden: true,
  },
  'Ear drops': {
    key: 'Ear drops',
    label: 'Ear drops',
    desc: 'Ear drops',
    hidden: true,
  },
  'As needed': {
    key: 'As needed',
    label: 'As needed',
    desc: 'As needed',
    hidden: true,
  },
};

export const DURATION_UNIT_TYPES = {
  DAY: {
    key: 'DAY',
    label: 'Day',
    pluralable: true,
    optionLabel: 'Day(s)',
  },
  WEEK: {
    key: 'WEEK',
    label: 'Week',
    pluralable: true,
    optionLabel: 'Week(s)',
  },
  MONTH: {
    key: 'MONTH',
    label: 'Month',
    pluralable: true,
    optionLabel: 'Month(s)',
  },
  YEAR: {
    key: 'YEAR',
    label: 'Year',
    pluralable: true,
    optionLabel: 'Year(s)',
  },
};

export const QUANTITY_UNIT_TYPES = {
  TABLETS: {
    key: 'TABLETS',
    label: 'Tablet',
    pluralable: true,
  },
  TUBE: {
    key: 'TUBE',
    label: 'Tube',
    pluralable: true,
  },
  BOX: {
    key: 'BOX',
    label: 'Box',
    pluralable: true,
  },
  AMPOULES: {
    key: 'AMPOULES',
    label: 'Ampoule',
    pluralable: true,
  },
  VIALS: {
    key: 'VIALS',
    label: 'Vial',
    pluralable: true,
  },
  SYRUP: {
    key: 'SYRUP',
    label: 'Syrup',
    pluralable: true,
  },
  CAPSULES: {
    key: 'CAPSULES',
    label: 'Capsule',
    pluralable: true,
  },
  IU: {
    key: 'IU',
    label: 'IU',
  },
  MCG: {
    key: 'MCG',
    label: 'mcg',
  },
  PACKET: {
    key: 'PACKET',
    label: 'Packet',
    pluralable: true,
  },
  Bottle: {
    key: 'Bottle',
    label: 'Bottle',
    pluralable: true,
  },
  Pack: {
    key: 'Pack',
    label: 'Pack',
    pluralable: true,
    hidden: true,
  },
  Blister: {
    key: 'Blister',
    label: 'Blister',
    pluralable: true,
    hidden: true,
  },
  Bag: {
    key: 'Bag',
    label: 'Bag',
    pluralable: true,
    hidden: true,
  },
};

export const DOSAGE_FORMS = {
  CAPSULES: {
    key: 'CAPSULES',
    label: 'Capsules',
  },
  DROPS: {
    key: 'DROPS',
    label: 'Drops',
  },
  EFFERVESCENT_TABLETS: {
    key: 'EFFERVESCENT_TABLETS',
    label: 'Effervescent tablets',
  },
  INTRAVITREAL_INJECTION: {
    key: 'INTRAVITREAL_INJECTION',
    label: 'Intravitreal injection',
  },
  LIQUID_NASAL_SPRAY: {
    key: 'LIQUID_NASAL_SPRAY',
    label: 'Liquid nasal spray',
  },
  ORAL_DROPS: {
    key: 'ORAL_DROPS',
    label: 'Oral drops',
  },
  ORAL_SOLUTION: {
    key: 'ORAL_SOLUTION',
    label: 'Oral solution',
  },
  PILLS: {
    key: 'PILLS',
    label: 'Pills',
  },
  PILLULES: {
    key: 'PILLULES',
    label: 'Pillules',
  },
  POWDER_ORAL_SOLUTION: {
    key: 'POWDER_ORAL_SOLUTION',
    label: 'Powder oral solution',
  },
  POWDER_SOLUTION_INJECTION: {
    key: 'POWDER_SOLUTION_INJECTION',
    label: 'Powder solution injection',
  },
  POWDER_SOLUTION_INTRAVENOUSE_INJECTION: {
    key: 'POWDER_SOLUTION_INTRAVENOUSE_INJECTION',
    label: 'Powder solution intravitreal injection',
  },
  TABLETS: {
    key: 'TABLETS',
    label: 'Tablets',
  },
  CREAM: {
    key: 'CREAM',
    label: 'Cream',
  },
  SOLUTION: {
    key: 'SOLUTION',
    label: 'Solution',
  },
  SOLUTION_INJECTION: {
    key: 'SOLUTION_INJECTION',
    label: 'Solution injection',
  },
};
