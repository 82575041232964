import values from 'lodash/values';
import forEach from 'lodash/forEach';
import { styled } from '@mui/material/styles';
import React, { useEffect, useCallback } from 'react';
import {
  useSnackbar,
  SnackbarProvider,
  MaterialDesignContent,
} from 'notistack';

import { EVENT_NAMES } from './constants';

const DEFAULT_AUTO_HIDE_DURATION = 5000;
const SUCCESS_AUTO_HIDE_DURATION = 3000;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent': {
      borderLeft: '5px solid',
      backgroundColor: theme.palette.background.paper,
    },
    '&.notistack-MuiContent-success': {
      color: theme.palette.success.dark,
      borderLeftColor: theme.palette.success.dark,
    },
    '&.notistack-MuiContent-error': {
      color: theme.palette.error.main,
      borderLeftColor: theme.palette.error.main,
    },
    '&.notistack-MuiContent-info': {
      color: theme.palette.secondary.dark,
      borderLeftColor: theme.palette.secondary.dark,
    },
    '&.notistack-MuiContent-warning': {
      color: theme.palette.warning.main,
      borderLeftColor: theme.palette.warning.main,
    },
  })
);

const MessageConsumer = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handler = useCallback((event) => {
    const { message, options } = event.detail;
    const { variant } = options;

    enqueueSnackbar(message, {
      ...options,
      ...(variant !== 'success'
        ? {
            autoHideDuration: SUCCESS_AUTO_HIDE_DURATION,
          }
        : {}),
    });
  });

  useEffect(() => {
    forEach(values(EVENT_NAMES), (eventName) => {
      document.addEventListener(eventName, handler);
    });

    return () => {
      forEach(values(EVENT_NAMES), (eventName) => {
        document.removeEventListener(eventName, handler);
      });
    };
  });

  return children;
};

const MessageProvider = ({
  children,
  maxSnack = 4,
  preventDuplicate = true,
  autoHideDuration = DEFAULT_AUTO_HIDE_DURATION,
  anchorOrigin = { vertical: 'top', horizontal: 'right' },
}) => {
  return (
    <SnackbarProvider
      maxSnack={maxSnack}
      anchorOrigin={anchorOrigin}
      preventDuplicate={preventDuplicate}
      autoHideDuration={autoHideDuration}
      Components={{
        root: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
    >
      <MessageConsumer>{children}</MessageConsumer>
    </SnackbarProvider>
  );
};

export default MessageProvider;
