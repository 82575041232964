import { connect } from 'react-redux';

import { paymentActions, paymentSelectors } from 'domain/payment';

import PaymentSection from './PaymentSection';

const mapStateToProps = (state, { referenceId, referenceType, isFetching }) => {
  const isFetchingPaymentTransaction =
    paymentSelectors.isFetchingPaymentTransaction(state);
  const paymentTransaction =
    paymentSelectors.getPaymentTransaction(state) || null; // Response can be empty string if no transaction found

  return {
    isFetching,
    isFetchingPaymentTransaction,
    referenceId,
    referenceType,
    paymentTransaction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(paymentActions.paymentTransactionReceived(null)),
  getPaymentTransaction: (payload) =>
    dispatch(paymentActions.getPaymentTransactionByReference(payload)),
  sendPaymentNotification: (payload) =>
    dispatch(paymentActions.createPaymentTransaction(payload)),
  cancelPaymentTransaction: (payload) =>
    dispatch(paymentActions.cancelPaymentTransaction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSection);
