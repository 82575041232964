import parse from 'date-fns/parse';
import isEmpty from 'lodash/isEmpty';
import isValid from 'date-fns/isValid';
import formatISO from 'date-fns/formatISO';
import {
  all,
  put,
  call,
  take,
  fork,
  cancel,
  select,
  debounce,
  takeLatest,
} from 'redux-saga/effects';

import { Message } from 'design-system';
import { cacheUtils } from 'core/cache';
import { takeSequential } from 'core/saga';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';
import { userModelActions, userModelActionTypes } from 'model/user';

import * as actions from './actions';
import * as types from './actionTypes';
import memberSaga from './member/saga';
import * as selectors from './selectors';
import { FIELDS, GENDER, HEALTH_PROFILE_FIELDS } from './constants';

const currentState = (state) => state;

export function* fetchUser({ id }) {
  yield put(actions.setFetchingUser(true));
  yield put(actions.clearPatientHealthInfo());
  yield put(userModelActions.fetchUser(id));

  const { user = {} } = yield take(userModelActionTypes.USER_RECEIVED);

  yield put(actions.userReceived(user));
  yield put(actions.setFetchingUser(false));
}

export function* createUser({ payload }) {
  yield put(actions.setSubmittingUser(true));
  yield put(userModelActions.createUser(payload));
}

export function* userCreationResponded({ ok }) {
  if (ok) {
    // Why settimout? When we change the route using location.href,
    // the context changes and hides the Message, unless we add it to event loop
    // Only then, it will be executed after the routing is done
    setTimeout(() => {
      Message.success('Patient has been registered successfully');
    }, 0);
  }

  yield put(actions.setSubmittingUser(false));
}

export function* fetchPatientHealthInfo({ userId }) {
  yield put(userModelActions.fetchPatientHealthInfo(userId));

  const action = yield take(userModelActionTypes.PATIENT_HEALTH_INFO_RECEIVED);

  yield put(actions.patientHealthInfoReceived(action.patient));
}

export function* updateUser({ id, payload, successCallback }) {
  yield put(actions.setSubmittingUser(true));
  yield put(userModelActions.updateUser(id, payload));

  const { ok, response } = yield take(
    userModelActionTypes.USER_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.userReceived(response));

    if (successCallback) {
      successCallback(response);
    } else {
      Message.success('User updated successfully');
    }
  }

  yield put(actions.setSubmittingUser(false));
}

export function* updateUserHealthProfile({ id, payload, successCallback }) {
  yield put(actions.setSubmittingUser(true));
  yield put(
    userModelActions.updateHealthProfile(id, {
      ...payload,
      [HEALTH_PROFILE_FIELDS.USER_ID.name]: id,
    })
  );

  const { ok, response } = yield take(
    userModelActionTypes.UPDATE_HEALTH_PROFILE_RESPONDED
  );

  if (ok) {
    yield put(actions.patientHealthInfoReceived({ ...response }));

    if (successCallback) {
      successCallback(response);
    } else {
      Message.success('User health data updated successfully');
    }
  }

  yield put(actions.setSubmittingUser(false));
}

function* identityExtractionProcess(documents) {
  yield put(actions.setExtractingIdentity(true));
  yield put(actions.clearExtractedIdentityData());
  yield put(userModelActions.startIdentityExtraction(documents));

  const jobAction = yield take(
    userModelActionTypes.IDENTITY_EXTRACTION_JOBS_RECEIVED
  );

  if (jobAction) {
    yield put(actions.identityExtractionJobsReceived(jobAction.jobs));
  }

  const action = yield take(userModelActionTypes.IDENTITY_EXTRACTION_RESPONDED);

  const { ok, response } = action;

  if (ok) {
    const data = { ...response };

    if (data?.dateOfBirth?.Text) {
      data.dateOfBirth = {
        Text: (() => {
          try {
            return formatISO(
              parse(data.dateOfBirth.Text, 'dd/MM/yyyy', new Date())
            );
          } catch (e) {
            console.error(e);
            return null;
          }
        })(),
        Confidence: data.dateOfBirth.Confidence,
      };
    }

    if (data?.expiryDate?.Text) {
      data.expiryDate = {
        Text: (() => {
          try {
            return formatISO(
              parse(data.expiryDate.Text, 'dd/MM/yyyy', new Date())
            );
          } catch (e) {
            console.error(e);
            return null;
          }
        })(),
        Confidence: data.expiryDate.Confidence,
      };
    }

    if (data?.gender?.Text) {
      data.gender = {
        Text: GENDER[data.gender.Text.toLowerCase()]?.key || null,
        Confidence: GENDER[data.gender.Text.toLowerCase()]?.key
          ? data.gender.Confidence
          : 0,
      };
    }

    yield put(actions.identityExtractionSucceeded(data));
  } else {
    yield put(actions.identityExtractionFailed(response));
  }

  yield put(actions.setExtractingIdentity(false));
}

function* cancelIdentityExtraction() {
  const state = yield select(currentState);
  const jobs = selectors.getIdentityExtractionJobs(state);

  if (!isEmpty(jobs)) {
    yield put(userModelActions.cancelIdentityExtraction(jobs));
  }

  yield put(actions.clearExtractedIdentityData());
}

export function* startIdentityExtraction({ documents }) {
  const backgroundProcess = yield fork(identityExtractionProcess, documents);
  const cancelAction = yield take(types.CANCEL_IDENTITY_EXTRACTION);

  yield cancel(backgroundProcess);
  yield call(cancelIdentityExtraction, cancelAction?.jobs);
}

export function* saveExtractedIdentityData({
  userId,
  identityData,
  successCallback,
}) {
  const state = yield select(currentState);

  const user = selectors.getUser(state);
  const healthInfo = selectors.getHealthInfo(state);

  const healthPayload = {
    ...healthInfo,
    ...(identityData?.gender
      ? {
          [HEALTH_PROFILE_FIELDS.GENDER.name]: identityData.gender,
        }
      : {}),
    ...(identityData?.dateOfBirth && isValid(identityData.dateOfBirth)
      ? {
          [HEALTH_PROFILE_FIELDS.DATE_OF_BIRTH.name]: formatISO(
            identityData.dateOfBirth
          ).split('T')[0],
        }
      : {}),
  };

  yield put(actions.setSavingExtractedIdentityData(true));

  if (!isEmpty(healthPayload)) {
    yield put(
      userModelActions.updateHealthProfile(userId, {
        ...healthPayload,
        [HEALTH_PROFILE_FIELDS.USER_ID.name]: userId,
      })
    );

    yield take(userModelActionTypes.UPDATE_HEALTH_PROFILE_RESPONDED);
  }

  const userPayload = {
    ...(identityData?.fullName
      ? {
          [FIELDS.FULL_NAME.name]: identityData.fullName,
        }
      : {}),
    ...(identityData?.nationality?.label
      ? {
          [FIELDS.NATIONALITY.name]: identityData.nationality.label,
        }
      : {}),
    ...(identityData?.identityNumber
      ? {
          [FIELDS.IDENTITY_NUMBER.name]: identityData.identityNumber,
        }
      : {}),
  };

  if (!isEmpty(userPayload)) {
    yield put(
      userModelActions.updateUser(userId, {
        ...user,
        ...userPayload,
      })
    );

    yield take(userModelActionTypes.USER_UPDATE_RESPONDED);
  }

  if (!isEmpty(healthPayload)) {
    yield put(actions.fetchPatientHealthInfo(userId));
  }

  if (!isEmpty(userPayload)) {
    yield put(actions.fetchUser(userId));
  }

  yield put(actions.setSavingExtractedIdentityData(false));

  if (successCallback) {
    successCallback();
  }
}

export function* fetchUserAddresses({ id }) {
  yield put(actions.setFetchingUserAddresses(true));
  yield put(userModelActions.fetchUserAddresses(id));

  const { addresses } = yield take(
    userModelActionTypes.USER_ADDRESSES_RECEIVED
  );

  yield put(actions.userAddressesReceived(addresses));
  yield put(actions.setFetchingUserAddresses(false));
}

export function* fetchDoctors() {
  const doctors = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.DOCTORS
  );

  if (doctors) {
    yield put(actions.doctorsReceived(doctors));

    return;
  }

  yield put(userModelActions.fetchDoctors());

  const { ok, response } = yield take(
    userModelActionTypes.DOCTORS_FETCH_RESPONDED
  );

  if (ok) {
    cacheUtils.setCachedDomainObject(LOCAL_DOMAIN_CACHE_KEYS.DOCTORS, response);
    yield put(actions.doctorsReceived(response));
  }
}

export function* fetchDrivers() {
  const drivers = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.DRIVERS
  );

  if (drivers) {
    yield put(actions.driversReceived(drivers));

    return;
  }

  yield put(userModelActions.fetchDrivers());

  const { ok, response } = yield take(
    userModelActionTypes.DRIVERS_FETCH_RESPONDED
  );

  if (ok) {
    cacheUtils.setCachedDomainObject(LOCAL_DOMAIN_CACHE_KEYS.DRIVERS, response);
    yield put(actions.driversReceived(response));
  }
}

export function* fetchPharmacists() {
  const pharmacists = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.PHARMACISTS
  );

  if (pharmacists) {
    yield put(actions.pharmacistsReceived(pharmacists));
    return;
  }

  yield put(userModelActions.fetchPharmacists());

  const { ok, response } = yield take(
    userModelActionTypes.PHARMACISTS_FETCH_RESPONDED
  );

  if (ok) {
    cacheUtils.setCachedDomainObject(
      LOCAL_DOMAIN_CACHE_KEYS.PHARMACISTS,
      response
    );
    yield put(actions.pharmacistsReceived(response));
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(types.CREATE_USER, createUser),
    takeSequential(types.FETCH_USER, fetchUser),
    takeLatest(types.FETCH_DOCTORS, fetchDoctors),
    takeLatest(types.FETCH_DRIVERS, fetchDrivers),
    takeLatest(types.UPDATE_USER_PROFILE, updateUser),
    takeLatest(types.FETCH_PHARMACISTS, fetchPharmacists),
    takeLatest(types.FETCH_USER_ADDRESSES, fetchUserAddresses),
    debounce(1000, types.FETCH_PATIENT_HEALTH_INFO, fetchPatientHealthInfo),
    takeLatest(types.UPDATE_USER_HEALTH_PROFILE, updateUserHealthProfile),
    takeLatest(types.START_IDENTITY_EXTRACTION, startIdentityExtraction),
    takeLatest(types.SAVE_EXTRACTED_IDENTITY_DATA, saveExtractedIdentityData),
    takeLatest(
      userModelActionTypes.USER_CREATION_RESPONDED,
      userCreationResponded
    ),
    memberSaga(),
  ]);
}
