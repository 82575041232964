import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as DOCTOR_NOTES } from './constants';

// Action
export const FETCH_DOCTOR_NOTES = `${DOMAIN}/${DOCTOR_NOTES}/FETCH_DOCTOR_NOTES`;
export const SUBMIT_DOCTOR_NOTES = `${DOMAIN}/${DOCTOR_NOTES}/SUBMIT_DOCTOR_NOTES`;
export const CLEAR_DOCTOR_NOTES = `${DOMAIN}/${DOCTOR_NOTES}/CLEAR_DOCTOR_NOTES`;
export const CREATE_PATIENT_ASSESSMENT_SHEET = `${DOMAIN}/${DOCTOR_NOTES}/CREATE_PATIENT_ASSESSMENT_SHEET`;

// Status
export const SET_FETCHING_DOCTOR_NOTES = `${DOMAIN}/${DOCTOR_NOTES}/SET_FETCHING_DOCTOR_NOTES`;
export const SET_SUBMITTING_DOCTOR_NOTES = `${DOMAIN}/${DOCTOR_NOTES}/SET_SUBMITTING_DOCTOR_NOTES`;

// Response
export const DOCTOR_NOTES_RECEIVED = `${DOMAIN}/${DOCTOR_NOTES}/DOCTOR_NOTES_RECEIVED`;
export const DOCTOR_NOTES_SUBMITTED = `${DOMAIN}/${DOCTOR_NOTES}/DOCTOR_NOTES_SUBMITTED`;
export const PATIENT_ASSESSMENT_SHEET_CREATED = `${DOMAIN}/${DOCTOR_NOTES}/PATIENT_ASSESSMENT_SHEET_CREATED`;
