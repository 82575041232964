import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { dateTimeFormat } from '../../constants';

export const PACKAGE_NAME = 'UserManagement';

export const SYSTEM_ROLES = {
  user: { label: 'User', key: 'user' },
  admin: {
    label: 'Admin',
    key: 'admin',
  },
  doctor: { label: 'Doctor', key: 'doctor' },
  ScheduleManager: { label: 'Schedule Manager', key: 'ScheduleManager' },
  ClinicManager: { label: 'Clinic Manager', key: 'ClinicManager' },
  MarketingManager: { label: 'Marketing Manager', key: 'MarketingManager' },
  LogisticsAdmin: { label: 'Logistics Admin', key: 'LogisticsAdmin' },
  Driver: {
    label: 'Driver',
    key: 'Driver',
  },
  DriverAdmin: {
    label: 'Driver admin',
    key: 'DriverAdmin',
  },
  SuperAdmin: { label: 'Super Admin', key: 'SuperAdmin' },
  promoter: { label: 'Promoter', key: 'promoter' },
  PatientSuccess: { label: 'Patient Success', key: 'PatientSuccess' },
  Pharmacist: { label: 'Pharmacist', key: 'Pharmacist' },
};

function displayDate(date) {
  return date ? format(parseISO(date), dateTimeFormat) : '';
}

export const USER_MANAGEMENT_FIELDS = {
  ID: {
    name: 'id',
  },
  CREATED_AT: {
    name: 'createdAt',
    valueGetter: displayDate,
  },
  UPDATED_AT: {
    name: 'updatedAt',
    valueGetter: displayDate,
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  TENANT_KEY: {
    name: 'tenantKey',
  },
  USER_ID: {
    name: 'userId',
  },
  NAMESPACE_KEY: {
    name: 'namespaceKey',
  },
  USERNAME: {
    name: 'username',
  },
  ALLOWED_TENANTS: {
    name: 'allowedTenants',
  },
  SLACK_MEMBER_ID: {
    name: 'slackMemberId',
  },
  ROLES: {
    name: 'roles',
  },
};
