import { PACKAGE_NAME as CONSULTATION } from './constants';
import { PACKAGE_NAME as MODEL } from '../constants';

// Actions
export const FETCH_CONSULTATION_BY_ID = `${MODEL}/${CONSULTATION}/FETCH_CONSULTATION_BY_ID`;
export const UPDATE_CONSULTATION = `${MODEL}/${CONSULTATION}/UPDATE_CONSULTATION`;
export const CREATE_CONSULTATION = `${MODEL}/${CONSULTATION}/CREATE_CONSULTATION`;
export const ADD_CONSULTATION_LAB_TEST = `${MODEL}/${CONSULTATION}/ADD_CONSULTATION_LAB_TEST`;
export const ADD_CONSULTATION_JOIN_MEETING_STARTED = `${MODEL}/${CONSULTATION}/ADD_CONSULTATION_JOIN_MEETING_STARTED`;
export const UPDATE_CONSULTATION_MEMBERS = `${MODEL}/${CONSULTATION}/UPDATE_CONSULTATION_MEMBERS`;
export const DELETE_CONSULTATION_MEMBER = `${MODEL}/${CONSULTATION}/DELETE_CONSULTATION_MEMBER`;
export const UPDATE_CONSULTATION_PATIENT = `${MODEL}/${CONSULTATION}/UPDATE_CONSULTATION_PATIENT`;
export const CREATE_CONSULTATION_PATIENT_OUTCOME = `${MODEL}/${CONSULTATION}/CREATE_CONSULTATION_PATIENT_OUTCOME`;
export const UPDATE_CONSULTATION_PATIENT_OUTCOME = `${MODEL}/${CONSULTATION}/UPDATE_CONSULTATION_PATIENT_OUTCOME`;
export const DELETE_CONSULTATION_PATIENT_OUTCOME = `${MODEL}/${CONSULTATION}/DELETE_CONSULTATION_PATIENT_OUTCOME`;
export const SUBMIT_CONSULTATION_PRESCRIPTIONS = `${MODEL}/${CONSULTATION}/SUBMIT_CONSULTATION_PRESCRIPTIONS`;
export const SUBMIT_CONSULTATION_LAB_TESTS = `${MODEL}/${CONSULTATION}/SUBMIT_CONSULTATION_LAB_TESTS`;

// Responses
export const CONSULTATION_RECEIVED = `${MODEL}/${CONSULTATION}/CONSULTATION_RECEIVED`;
export const CONSULTATION_CREATED = `${MODEL}/${CONSULTATION}/CONSULTATION_CREATED`;
export const CONSULTATION_UPDATE_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_UPDATE_RESPONDED`;
export const CONSULTATION_CREATION_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_CREATION_RESPONDED`;
export const CONSULTATION_MEMBERS_UPDATING_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_MEMBERS_UPDATING_RESPONDED`;
export const CONSULTATION_MEMBER_DELETE_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_MEMBER_DELETE_RESPONDED`;
export const CONSULTATION_PATIENT_UPDATE_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_PATIENT_UPDATE_RESPONDED`;
export const CONSULTATION_PATIENT_OUTCOME_CREATION_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_PATIENT_OUTCOME_CREATION_RESPONDED`;
export const CONSULTATION_PATIENT_OUTCOME_UPDATE_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_PATIENT_OUTCOME_UPDATE_RESPONDED`;
export const CONSULTATION_PATIENT_OUTCOME_DELETE_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_PATIENT_OUTCOME_DELETE_RESPONDED`;
export const CONSULTATION_PRESCRIPTIONS_SUBMIT_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_PRESCRIPTIONS_SUBMIT_RESPONDED`;
export const CONSULTATION_LAB_TESTS_SUBMIT_RESPONDED = `${MODEL}/${CONSULTATION}/CONSULTATION_LAB_TESTS_SUBMIT_RESPONDED`;

// Statuses
export const SET_SUBMITTING_CONSULTATION = `${MODEL}/${CONSULTATION}/SET_SUBMITTING_CONSULTATION`;
