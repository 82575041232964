import { take, actionChannel, call } from 'redux-saga/effects';

function* takeSequential(actionType, handler) {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(actionType);

  while (true) {
    // 2- take from the channel
    const action = yield take(requestChan);

    // 3- Note that we're using a blocking call
    yield call(handler, action);
  }
}

export default takeSequential;
