import React, { useState } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  Box,
  Button,
  TextField,
  DatePicker,
  InputAdornment,
} from 'design-system';

const DatePickerFilter = ({ field }) => {
  const { defaultValue = null } = field;

  const [dateValue, setDateValue] = useState(defaultValue);
  const [inputValue, setInputValue] = useState(defaultValue);

  const onChange = (value) => {
    setDateValue(value);
    field.filter(value);
  };

  return (
    <DatePicker
      clearable
      inputFormat="PP"
      value={dateValue}
      onChange={onChange}
      {...field}
      renderInput={(params) => {
        if (field.icon) {
          params.InputProps = {
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <field.icon color="standard" fontSize="extraSmall" />
              </InputAdornment>
            ),
          };
        }

        if (field?.placeholder) {
          params.inputProps.placeholder = field.placeholder;
        }

        params.InputProps = {
          ...params.InputProps,
          endAdornment: (
            <>
              {dateValue && (
                <Button
                  size="small"
                  shape="round"
                  variant="icon"
                  color="standard"
                  startIcon={
                    <CloseRoundedIcon
                      sx={{
                        color: (theme) => theme.palette.grey[700],
                      }}
                    />
                  }
                  className="dateFilter_closeIcon"
                  onClick={() => {
                    onChange(null);
                    setInputValue(null);
                  }}
                  sx={{
                    m: 0,
                    display: 'none',
                    '& > .MuiButton-startIcon': {
                      m: 0,
                    },
                  }}
                />
              )}
              <Box
                sx={{
                  '& .MuiInputAdornment-positionEnd': {
                    margin: 0,
                  },
                }}
              >
                {params.InputProps.endAdornment}
              </Box>
            </>
          ),
        };

        return (
          <TextField
            {...params}
            fullWidth
            size="medium"
            shape="round"
            variant="filled"
            value={inputValue}
            sx={{
              '&:hover .dateFilter_closeIcon': {
                display: 'inline-flex',
              },
            }}
          />
        );
      }}
    />
  );
};

export default DatePickerFilter;
