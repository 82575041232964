import get from 'lodash/get';

import { FIELDS } from './constants';

export const getId = (file) => get(file, FIELDS.ID.name);

export const getFileName = (file) => get(file, FIELDS.FILE_NAME.name);

export const getType = (file) => get(file, FIELDS.TYPE.name);

export const getUrl = (file) => get(file, FIELDS.URL.name);
