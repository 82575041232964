import React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import Grid from '../Grid';
import Tooltip from '../Tooltip';
import Typography from '../Typography';
import RequiredMark from '../RequiredMark';
import { LinearProgress } from '../Progress';

const FormField = ({
  help,
  label,
  field,
  action,
  loading,
  updating,
  required,
  labelWidth,
  labelProps,
  ...rest
}) => (
  <Grid item container column rowSpacing={1} {...rest}>
    <Grid item container justifyContent="space-between" {...labelProps}>
      <Grid item>
        <Typography variant="p4" loading={loading && { width: labelWidth }}>
          {label}
        </Typography>
        {!loading && required && <RequiredMark />}
        {!loading && help && (
          <Tooltip placement="top" title={help} sx={{ ml: 1, mb: -5 / 8 }}>
            <HelpOutlineIcon color="secondary" fontSize="small" />
          </Tooltip>
        )}
      </Grid>
      <Grid item>{action}</Grid>
    </Grid>
    <Grid item>
      <>
        {field}
        {updating && <LinearProgress />}
      </>
    </Grid>
  </Grid>
);

export default FormField;
