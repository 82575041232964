import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useObjectMapper from 'core/objectMapper';
import { getDefaultCurrency } from 'core/siteConfig';
import { DOSAGE_FORMS } from 'domain/medicine/constants';
import {
  medicineGetters as getters,
  MEDICINE_FIELDS as FIELDS,
} from 'domain/medicine';
import {
  Grid,
  Card,
  Select,
  Button,
  MenuItem,
  PageTitle,
  TextField,
  FormField,
  Typography,
  CardContent,
  Divider,
  InputAdornment,
} from 'design-system';

const currency = getDefaultCurrency();

const MedicineView = ({
  isFetching,
  isSubmitting,
  fetchMedicine,
  updateMedicine,
  medicine: serverMedicine,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;

  useEffect(() => {
    if (id) {
      fetchMedicine(id);
    }
  }, [id]);

  const { mappedData: medicine, setter } = useObjectMapper(
    {
      condition: () =>
        !isEmpty(serverMedicine) &&
        Number(id) === Number(getters.getId(serverMedicine)),
      sourceData: serverMedicine,
    },
    [id, serverMedicine]
  );

  const submitChanges = () => {
    const payload = {
      [FIELDS.ID.name]: id,
      [FIELDS.DRUG_CODE.name]: getters.getDrugCode(medicine),
      [FIELDS.GENERIC_NAME.name]: getters.getGenericName(medicine),
      [FIELDS.PACKAGE_NAME.name]: getters.getPackageName(medicine),
      [FIELDS.PACKAGE_SIZE_SUMMARY.name]:
        getters.getPackageSizeSummary(medicine),
      [FIELDS.DISPENSE_MODE.name]: getters.getDispenseMode(medicine),
      [FIELDS.STRENGTH.name]: getters.getStrength(medicine),
      [FIELDS.DOSAGE_FORM.name]: getters.getDosageForm(medicine),
      [FIELDS.PACKAGE_PRICE.name]: getters.getPriceObject(medicine),
      [FIELDS.PACKAGE_COST.name]: getters.getCostObject(medicine),
      [FIELDS.UNIT_PRICE.name]: getters.getUnitPriceObject(medicine),
      [FIELDS.UNIT_COST.name]: getters.getUnitCostObject(medicine),
      [FIELDS.TENANT_KEY.name]: getters.getTenantKey(medicine),
    };

    updateMedicine(id, payload);
  };

  const SubmitButton = () => (
    <Button
      variant="filled"
      disabled={isFetching}
      onClick={submitChanges}
      spinning={isSubmitting}
      loading={isFetching && { width: 160 }}
    >
      Save Changes
    </Button>
  );
  return (
    <>
      <PageTitle title="Medicine Details" action={<SubmitButton />} />
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sx={{ width: '100%', mb: 4 }}>
              <Typography loading={isFetching} variant="h6">
                Medicine #{getters.getId(medicine)} (
                {getters.getSummary(medicine)}){' '}
              </Typography>
            </Grid>
            <Divider />
            <FormField
              label="Drug code"
              xs={12}
              md={6}
              field={
                <TextField
                  fullWidth
                  name="drugCode"
                  variant="filled"
                  size="large"
                  value={getters.getDrugCode(medicine)}
                  placeholder="Drug code.."
                  loading={isFetching}
                  onChange={(event) => {
                    const newValue = event.target.value;

                    setter(FIELDS.DRUG_CODE.name, newValue);
                  }}
                />
              }
            />

            <FormField
              label="GENERIC NAME"
              xs={12}
              md={6}
              field={
                <TextField
                  fullWidth
                  variant="filled"
                  size="large"
                  value={getters.getGenericName(medicine)}
                  placeholder="Generic name.."
                  loading={isFetching}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.GENERIC_NAME.name, newValue);
                  }}
                />
              }
            />
            <FormField
              label="PACKAGE NAME"
              required
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  variant="filled"
                  size="large"
                  loading={isFetching}
                  value={getters.getPackageName(medicine)}
                  placeholder="Package name.."
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.PACKAGE_NAME.name, newValue);
                  }}
                />
              }
            />
            <FormField
              label="PACKAGE SIZE SUMMARY"
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  variant="filled"
                  size="large"
                  value={getters.getPackageSizeSummary(medicine)}
                  placeholder="Package size summary.."
                  loading={isFetching}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.PACKAGE_SIZE_SUMMARY.name, newValue);
                  }}
                />
              }
            />
            <FormField
              label="DISPENSE MODE"
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  variant="filled"
                  size="large"
                  value={getters.getDispenseMode(medicine)}
                  placeholder="Dispense mode.."
                  loading={isFetching}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.DISPENSE_MODE.name, newValue);
                  }}
                />
              }
            />
            <FormField
              label="STRENGTH"
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  variant="filled"
                  size="large"
                  value={getters.getStrength(medicine)}
                  placeholder="Strength.."
                  loading={isFetching}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.STRENGTH.name, newValue);
                  }}
                />
              }
            />
            <FormField
              label="DOSAGE FORM"
              required
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <Select
                  fullWidth
                  value={getters.getDosageForm(medicine) || null}
                  size="large"
                  color="standard"
                  variant="filled"
                  soak="light"
                  loading={isFetching}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.DOSAGE_FORM.name, newValue);
                  }}
                  placeholder="Dosage form.."
                  renderValue={(value) => DOSAGE_FORMS[value]?.label ?? value}
                >
                  {keys(DOSAGE_FORMS).map((item) => (
                    <MenuItem value={item} key={item}>
                      {DOSAGE_FORMS[item].label}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            <Grid item sx={{ width: '100%' }}>
              <Typography variant="h5">Prices</Typography>
            </Grid>
            <FormField
              label="PACKAGE PRICE"
              required
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  type="number"
                  variant="filled"
                  size="large"
                  loading={isFetching}
                  value={getters.getPrice(medicine)}
                  placeholder="Package price.."
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.PACKAGE_PRICE.name, {
                      value: Number(newValue),
                      currency,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{currency}</InputAdornment>
                    ),
                  }}
                />
              }
            />
            <FormField
              label="PACKAGE COST"
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  type="number"
                  variant="filled"
                  size="large"
                  loading={isFetching}
                  value={getters.getCost(medicine)}
                  placeholder="Package cost.."
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.PACKAGE_COST.name, {
                      value: Number(newValue),
                      currency,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{currency}</InputAdornment>
                    ),
                  }}
                />
              }
            />
            <FormField
              label="UNIT PRICE"
              required
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  type="number"
                  variant="filled"
                  size="large"
                  loading={isFetching}
                  value={getters.getUnitPrice(medicine)}
                  placeholder="Unit price.."
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.UNIT_PRICE.name, {
                      value: Number(newValue),
                      currency,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{currency}</InputAdornment>
                    ),
                  }}
                />
              }
            />
            <FormField
              label="UNIT COST"
              xs={12}
              md={6}
              loading={isFetching}
              field={
                <TextField
                  fullWidth
                  type="number"
                  variant="filled"
                  size="large"
                  loading={isFetching}
                  value={getters.getUnitCost(medicine)}
                  placeholder="Unit cost.."
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setter(FIELDS.UNIT_COST.name, {
                      value: Number(newValue),
                      currency,
                    });
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{currency}</InputAdornment>
                    ),
                  }}
                />
              }
            />
          </Grid>
        </CardContent>
      </Card>
      <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
        <Grid item>
          <SubmitButton />
        </Grid>
      </Grid>
    </>
  );
};

export default MedicineView;
