import values from 'lodash/values';

export const PACKAGE_NAME = 'Outcome';

export const OUTCOME_OPTIONS = {
  ConsultationOnly: {
    key: 'ConsultationOnly',
    label: 'Consultation only',
    color: 'success',
  },
  Prescription: {
    key: 'Prescription',
    label: 'Prescription',
    color: 'success',
  },
  LabTestRequest: {
    key: 'LabTestRequest',
    label: 'Lab test request',
    color: 'success',
  },
  ScheduleForFollowUp: {
    key: 'ScheduleForFollowUp',
    label: 'Schedule for follow up',
    color: 'primary',
  },
  InternalReferral: {
    key: 'InternalReferral',
    label: 'Internal referral',
    color: 'primary',
  },
  ExternalReferral: {
    key: 'ExternalReferral',
    label: 'External referral',
    color: 'primary',
  },
  NoShow: {
    key: 'NoShow',
    label: 'No show',
    color: 'warning',
  },
};

export const OUTCOME_SUBMISSION_STATUS = {
  PENDING_SUBMISSION: {
    key: 'PENDING_SUBMISSION',
  },
  SUBMITTED: {
    key: 'SUBMITTED',
  },
};

export const REVIEW_OF_SYSTEMS = {
  CONSTITUTIONAL: {
    key: 'CONSTITUTIONAL',
    label: 'Constitutional',
  },
  EYES: {
    key: 'EYES',
    label: 'Eyes',
  },
  ENT: {
    key: 'ENT',
    label: 'ENT',
  },
  CARDIOVASCULAR: {
    key: 'CARDIOVASCULAR',
    label: 'Cardiovascular',
  },
  RESPIRATORY: {
    key: 'RESPIRATORY',
    label: 'Respiratory',
  },
  GASTROINTESTINAL: {
    key: 'GASTROINTESTINAL',
    label: 'Gastrointestinal',
  },
  INTEGUMENTARY: {
    key: 'INTEGUMENTARY',
    label: 'Integumentary',
  },
  ALLERGIC: {
    key: 'ALLERGIC',
    label: 'Allergic',
  },
  GENITOURINARY: {
    key: 'GENITOURINARY',
    label: 'Genitourinary',
  },
  MUSCULOSKELETAL: {
    key: 'MUSCULOSKELETAL',
    label: 'Musculoskeletal',
  },
  SKIN: {
    key: 'SKIN',
    label: 'Skin',
  },
  NEUROLOGICAL: {
    key: 'NEUROLOGICAL',
    label: 'Neurological',
  },
  PSYCHIATRIC: {
    key: 'PSYCHIATRIC',
    label: 'Psychiatric',
  },
  ENDOCRINE: {
    key: 'ENDOCRINE',
    label: 'Endocrine',
  },
  HEMATOLOGIC: {
    key: 'HEMATOLOGIC',
    label: 'Hematologic',
  },
  LOCAL_EXAMINATIONS: {
    key: 'LOCAL_EXAMINATIONS',
    label: 'Local Examinations/Others',
  },
};

export const DOCTOR_PRIVATE_NOTES_OPTIONS = {
  VIDEO_CONSULTATION_COMPLETED_WITH_CONSENT: {
    key: 'VIDEO_CONSULTATION_COMPLETED_WITH_CONSENT',
    label: 'Video consultation completed with consent',
    order: 1,
    mappedOutcome: null,
  },
  TELE_CONSULTATION_COMPLETED_WITH_CONSENT: {
    key: 'TELE_CONSULTATION_COMPLETED_WITH_CONSENT',
    label: 'Tele-consultation completed with consent',
    order: 2,
    mappedOutcome: null,
  },
  NO_SHOW: {
    key: 'NO_SHOW',
    label: 'No show',
    order: 3,
    mappedOutcome: OUTCOME_OPTIONS.NoShow.key,
  },
  PATIENT_REFUSED_CONSULTATION: {
    key: 'PATIENT_REFUSED_CONSULTATION',
    label: 'Patient refused consultation',
    order: 4,
    mappedOutcome: OUTCOME_OPTIONS.NoShow.key,
  },
  MISSING_LAB_RESULTS: {
    key: 'MISSING_LAB_RESULTS',
    label: 'Missing lab results',
    order: 5,
    mappedOutcome: OUTCOME_OPTIONS.NoShow.key,
  },
  CANCELED_FOR_OTHER_REASONS: {
    key: 'CANCELED_FOR_OTHER_REASONS',
    label: 'Canceled for other reasons',
    order: 6,
    mappedOutcome: OUTCOME_OPTIONS.NoShow.key,
  },
};

export const SORTED_DOCTOR_PRIVATE_NOTES_OPTIONS = values(
  DOCTOR_PRIVATE_NOTES_OPTIONS
).sort((a, b) => a.order - b.order);
