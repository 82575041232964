import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';
import httpClient from '../../core/httpClient';

export function* fetchInsuranceProvidersList() {
  const response = yield call(httpClient.get, constants.ENDPOINT);
  yield put(actions.insuranceProviderListReceived(response.data.data));
}

export default function* insuranceModelSaga() {
  yield all([
    takeLatest(
      types.FETCH_INSURANCE_PROVIDER_LIST,
      fetchInsuranceProvidersList
    ),
  ]);
}
