import isArray from 'lodash/isArray';
import React, { useRef, useState, useCallback } from 'react';

import { userGetters } from 'domain/user';
import { doctorGetters } from 'domain/doctor';
import { Form, Controller } from 'design-system/form';
import { Grid, Button, FormField } from 'design-system';
import { isLabTestListV2Enabled } from 'core/siteConfig';
import { consultationGetters } from 'domain/consultation';
import LabTestsAutocomplete from 'presentation/lab/components/LabTestsAutocomplete';

import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const LabRequestForm = ({
  // Id of the owner's user profile
  ownerId,
  // The consultation patient mapped to the current tab
  patient,
  // The consultation that contains this patient
  consultation,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Patient's diagnoses list
  patientDiagnosis,
  // State
  // A function to get patient's ELab Request from state
  patientELabRequestById,
  // Action to create ELab Request
  createPatientELabRequest,
  // Action to update ELab Request
  updatePatientELabRequest,
}) => {
  const formRef = useRef();

  const [isSaving, setSaving] = useState(false);

  const submitLabRequest = useCallback(
    (formData, successCallback) => {
      let diagnoses = [];
      const { id: patientId, userId } = patient;
      const patientELabRequest = patientELabRequestById(userId);
      const consultationId = consultationGetters.getId(consultation);
      const tenantId = userGetters.getTenant(
        consultationGetters.getUser(consultation)
      );
      const doctorId = doctorGetters.getId(
        consultationGetters.getDoctor(consultation)
      );

      if (isArray(patientDiagnosis?.diagnosis)) {
        diagnoses = patientDiagnosis?.diagnosis.map((diagnosis) => ({
          name: diagnosis.name,
          code: diagnosis.code,
          type: diagnosis.type,
          // id is excluded, as the one saved in doctor notes it's not the same as the one in lab request
        }));
      }

      const submitFunction = patientELabRequest?.id
        ? updatePatientELabRequest
        : createPatientELabRequest;

      submitFunction(
        userId,
        consultationId,
        {
          ownerId,
          tenantId,
          doctorId,
          patientId,
          diagnoses,
          labTests: formData?.labTests || [],
        },
        successCallback
      );
    },
    [
      patient,
      ownerId,
      consultation,
      patientDiagnosis,
      patientELabRequestById,
      createPatientELabRequest,
      updatePatientELabRequest,
    ]
  );

  const onSubmit = useCallback(
    (formData) => {
      setSaving(true);
      submitLabRequest(formData, () => {
        onSubmitted(formData);
        setSaving(false);
      });
    },
    [onSubmitted]
  );

  return (
    <Form ref={formRef} onSubmit={onSubmit} defaultValues={defaultValues}>
      {({ control }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.LAB_REQUEST.key}
          title={SECTIONS.LAB_REQUEST.label}
          actions={
            <Button
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              spinning={isSaving}
              onClick={() => {
                formRef?.current?.submit();
              }}
            >
              Save and continue
            </Button>
          }
        >
          <Grid container>
            <FormField
              required
              label="LAB TESTS"
              field={
                <Controller
                  name="labTests"
                  control={control}
                  defaultValue={[]}
                  render={({ field, fieldState: { error } }) => (
                    <LabTestsAutocomplete
                      size="medium"
                      error={error}
                      onChange={field.onChange}
                      defaultValue={field.value}
                      endpointV2={isLabTestListV2Enabled()}
                      getTagLabel={(option) =>
                        [option.code, option.name].filter(Boolean).join(' - ')
                      }
                    />
                  )}
                  rules={{
                    required: 'Please select at least one lab test',
                  }}
                />
              }
            />
          </Grid>
        </Section>
      )}
    </Form>
  );
};

export default LabRequestForm;
