export const getDefaultCurrency = () =>
  process.env.REACT_APP_DEFAULT_CURRENCY || 'AED';

export const isEPrescriptionEnabled = () => {
  return process.env.REACT_APP_CONFIG_ENABLE_EPRESCRIPTION === 'true';
};

export const isSendPrescriptionEnabled = () => {
  return process.env.REACT_APP_CONFIG_DISABLE_SEND_EPRESCRIPTION !== 'true';
};

export const isSendRequestLabTestsEnabled = () => {
  return process.env.REACT_APP_CONFIG_DISABLE_SEND_REQUEST_LAB_TESTS !== 'true';
};

export const isDefaultApprovedByInsurance = () => {
  return process.env.REACT_APP_DEFAULT_APPROVED_BY_INSURANCE === 'true';
};

export const getDefaultListSorting = () => {
  try {
    const [field, sort] = process.env.REACT_APP_DEFAULT_LIST_SORTING.split(',');

    return {
      field,
      sort,
    };
  } catch (_e) {
    return { field: 'updatedAt', sort: 'desc' };
  }
};

export const isIdentityExtractionEnabled = () => {
  return process.env.REACT_APP_IS_IDENTITY_EXTRACTION_ENABLED === 'true';
};

export const canDoctorViewPatientPhoneNumber = () => {
  return process.env.REACT_APP_CAN_DOCTOR_VIEW_PATIENT_PHONE_NUMBER === 'true';
};

export const getGoogleApiKey = () => {
  return process.env.REACT_APP_GOOGLE_API_KEY;
};

export const isShowEligibilitySection = () => {
  return process.env.REACT_APP_SHOW_ELIGIBILITY_SECTION === 'true';
};
export const isShowLogisticProvider = () => {
  return process.env.REACT_APP_SHOW_LOGISTIC_PROVIDER === 'true';
};

export const getDefaultCountryCode = () => {
  return process.env.REACT_APP_COUNTRY_CODE || 'AE';
};

export const getDefaultCountryCodeLong = () => {
  return process.env.REACT_APP_COUNTRY_CODE_LONG || 'UAE';
};

export const getDefaultTimeZone = () => {
  return process.env.REACT_APP_TIME_ZONE || 'Asia/Dubai';
};

export const getLogonBanner = () => {
  return process.env.REACT_APP_LOGON_BANNER === 'true';
};

export const getWlpConsultationTypeId = () => {
  return Number(process.env.REACT_APP_WLP_CONSULTATION_TYPE_ID);
};

export const isShowEnrollSubscription = () => {
  return process.env.REACT_APP_SHOW_ENROLL_TO_SUBSCRIPTION === 'true';
};

export const isElabShown = () => {
  return process.env.REACT_APP_IS_ELAB_SHOWN === 'true';
};

export const isOutcomeManagementEnabled = () => {
  return process.env.REACT_APP_CONFIG_ENABLE_OUTCOME_MANAGEMENT === 'true';
};

export const isLabTestListV2Enabled = () => {
  return process.env.REACT_APP_IS_LAB_TEST_LIST_V2_ENABLED === 'true';
};
export const isDeliveryConsentFormEnabled = () => {
  return process.env.REACT_APP_CONFIG_ENABLE_DELIVERY_CONSENT_FORM === 'true';
};
