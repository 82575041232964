import keys from 'lodash/keys';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

import { appUtils } from 'core/utils';
import { authSelectors } from 'domain/auth';
import {
  userGetters as getters,
  userConstants as constants,
} from 'domain/user';
import {
  Card,
  Grid,
  Button,
  Select,
  MenuItem,
  TextField,
  PageTitle,
  Typography,
  CardHeader,
  CardContent,
  RequiredMark,
  InputAdornment,
} from 'design-system';
import { isAllowed } from 'core/permission';

const { SIGN_UP_SOURCE, FIELDS } = constants;

const UserCreate = ({
  tenants,
  createUser,
  isSubmitting,
  pursueUserCreationResponse,
}) => {
  const navigate = useNavigate();

  const { isClinicManager } = useSelector(authSelectors.getRole);
  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  const [fullName, setFullname] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [signUpSource, setSignUpSource] = useState(null);

  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [tenant, setTenant] = useState(defaultTenant);

  const onSubmit = () => {
    const payload = {
      [FIELDS.FULL_NAME.name]: fullName,
      [FIELDS.SIGN_UP_SOURCE.name]: signUpSource,
      [FIELDS.PHONE_NUMBER
        .name]: `${appUtils.getDefaultCountryCallingCode()}${phoneNumber}`,
      [FIELDS.TENANT_KEY.name]: tenant,
    };

    createUser(payload);
    pursueUserCreationResponse(({ ok, response }) => {
      if (ok && response) {
        navigate(`/user/${getters.getId(response)}`);
      }
    });
  };
  useEffect(() => {
    if (isClinicManager) setSignUpSource(SIGN_UP_SOURCE['PARTNER CLINIC'].key);
  });
  useEffect(() => {
    setSubmitButtonEnabled(fullName && phoneNumber && signUpSource && tenant);
  }, [fullName, phoneNumber, signUpSource, tenant]);

  return (
    <>
      <PageTitle
        title="New Patient"
        action={
          <Button
            variant="filled"
            onClick={onSubmit}
            spinning={isSubmitting}
            disabled={!isSubmitButtonEnabled}
          >
            Add patient
          </Button>
        }
      />
      <Grid
        container
        spacing={3}
        row
        alignItems="stretch"
        justifyContent="left"
      >
        <Grid item xs={12}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title="Patient Info" sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              <Grid container py={2} sx={{ flexWrap: 'nowrap' }}>
                <Grid item container column xs={6}>
                  {/* Phone number */}
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">PHONE NUMBER</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <TextField
                      size="large"
                      fullWidth
                      variant="filled"
                      placeholder="Phone number"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {appUtils.getDefaultCountryCallingCode()}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  {/* Full name */}
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">FULL NAME</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <TextField
                      size="large"
                      fullWidth
                      variant="filled"
                      placeholder="Full name"
                      onChange={(e) => {
                        setFullname(e.target.value);
                      }}
                    />
                  </Grid>

                  {/* Sign up source */}
                  {isAllowed('userList', 'signupSource') && (
                    <>
                      <Grid item sx={{ mb: 1, px: 2 }}>
                        <Typography variant="p4">SIGN-UP SOURCE</Typography>
                        <RequiredMark />
                      </Grid>
                      <Grid item sx={{ mb: 1, px: 2 }}>
                        <Select
                          fullWidth
                          size="large"
                          soak="light"
                          value={signUpSource}
                          variant="filled"
                          placeholder="Select sign-up source..."
                          onChange={(event) => {
                            setSignUpSource(event.target.value);
                          }}
                          renderValue={(value) =>
                            value ? SIGN_UP_SOURCE[value].label : value
                          }
                        >
                          {keys(SIGN_UP_SOURCE).map((item) => (
                            <MenuItem value={item} key={item}>
                              {SIGN_UP_SOURCE[item].label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      {/* Branch */}
                      <Grid item sx={{ mb: 1, px: 2 }}>
                        <Typography variant="p4">BRANCH</Typography>
                        <RequiredMark />
                      </Grid>
                      <Grid item sx={{ mb: 1, px: 2 }}>
                        <Select
                          fullWidth
                          size="large"
                          soak="light"
                          variant="filled"
                          value={tenant}
                          onChange={(event) => {
                            setTenant(event.target.value);
                          }}
                        >
                          {(tenants || []).map((option) => (
                            <MenuItem value={option.id} key={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item container justifyContent="center" xs={6}>
                  <PersonAddRoundedIcon
                    sx={{
                      color: (theme) => theme.palette.secondary.tint,
                      fontSize: '18rem',
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

UserCreate.propTypes = {};

export default UserCreate;
