import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { labSelectors, labActions } from 'domain/lab';
import { userActions, userSelectors } from 'domain/user';

import LabRequestCreate from './LabRequestCreate';

const mapStateToProps = (state) => {
  const user = userSelectors.getUser(state);
  const isFetchingUser = userSelectors.isFetchingUser(state);
  const labPackages = [
    { id: '-1', name: 'Custom Lab Request' },
    ...labSelectors.getLabPackages(state),
  ];

  return {
    user,
    labPackages,
    isFetchingUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch(userActions.clearUser()),
  fetchUser: (id) => dispatch(userActions.fetchUser(id)),
  createLabRequest: (payload) =>
    dispatch(labActions.createLabRequest(payload, true)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(LabRequestCreate)
);
