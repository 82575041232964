import React from 'react';

import Typography from '../Typography';

const RequiredMark = ({ variant = 'p2' }) => (
  <Typography
    variant={variant}
    sx={{
      mx: 2 / 8,
      lineHeight: 1,
      verticalAlign: 'middle',
      color: (theme) => theme.palette.error.main,
    }}
  >
    *
  </Typography>
);

export default RequiredMark;
