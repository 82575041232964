import get from 'lodash/get';
import { createSelector } from 'reselect';

export const meetingSelectors = (state) => get(state, 'domain.meeting', {});

export const getMeetings = createSelector(meetingSelectors, (results) =>
  get(results, 'meetings')
);

export const isFetching = createSelector(meetingSelectors, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(meetingSelectors, (results) =>
  get(results, 'isSubmitting')
);

export const isDeleting = createSelector(meetingSelectors, (results) =>
  get(results, 'isDeleting')
);
