import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { useState, useEffect } from 'react';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { userGetters } from 'domain/user';
import { UserSearch } from 'presentation/user';
import { dateTimeFormat } from 'constants/dateTime';
import { DialerLink } from 'presentation/components';
import { Link, DataGrid, PageTitle } from 'design-system';
import { filtration, DataGridCard, useServerPagination } from 'core/dataGrid';

const ReviewsList = () => {
  const {
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/reviews',
    initialSortModel: { field: 'createdAt', sort: 'desc' },
    columnFieldsMap: {
      patient: 'user.fullName',
      phoneNumber: 'user.phoneNumber',
    },
  });
  const { updateFilter } = filtration.useServerFiltration({
    setQueryParams,
    fieldsConfig: [
      {
        name: 'patient',
        serverName: 'userId',
        valueGetter: userGetters.getId,
      },
    ],
    onQueryParamsChange: (urlSearchParams) => {
      const params = new URLSearchParams(urlSearchParams);
      return params;
    },
  });
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'patient',
      headerName: 'Patient',
      flex: 2,
      renderCell: (params) => {
        const user = userGetters.getUser(params.row);
        const content =
          userGetters.getFullName(user) || "Visit patient's profile";

        return user ? (
          <Link href={`/user/${userGetters.getId(user)}`}>{content}</Link>
        ) : (
          content
        );
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 2,
      renderCell: (params) => {
        const user = userGetters.getUser(params.row);
        const phoneNumber = userGetters.getPhoneNumber(user);
        const content = phoneNumber || '-';

        return user ? (
          <DialerLink phoneNumber={phoneNumber}>{content}</DialerLink>
        ) : (
          content
        );
      },
    },
    { field: 'comment', headerName: 'Review', flex: 2.5 },
    {
      field: 'rating',
      headerName: 'Rating',
      renderCell: (params) => {
        const { rating } = params.row;

        return rating > 0 ? (
          <ThumbUpIcon color="success" />
        ) : (
          <ThumbDown color="error" />
        );
      },
    },
    {
      field: 'appVersion',
      headerName: 'App Version',
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) =>
        format(parseISO(params.row?.createdAt), dateTimeFormat),
    },
    {
      field: 'source',
      headerName: 'Source',
    },
  ];

  const filtersDef = {
    fields: [
      {
        name: 'patient',
        type: 'custom',
        component: (
          <UserSearch
            disabled={isFetching}
            onUserSelect={(selectedPatient) => {
              updateFilter('patient', selectedPatient);
            }}
          />
        ),
      },
    ],
  };
  return (
    <>
      <PageTitle title="Reviews" />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          columns={columns}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
};

ReviewsList.propTypes = {};

export default ReviewsList;
