import React, { useState, useEffect } from 'react';
import MuiLoadingButton from '@mui/lab/LoadingButton';

import Skeleton from '../Skeleton';

const Button = ({
  loading,
  children,
  spinning,
  startIcon,
  size = 'large',
  variant = 'filled',
  loadingPosition = 'start',
  ...rest
}) => {
  const [mappedVariant, setMappedVariant] = useState();

  useEffect(() => {
    let internalVariant;

    switch (variant) {
      case 'filled':
        internalVariant = 'contained';
        break;
      case 'icon':
        internalVariant = 'text';
        break;
      default:
        internalVariant = variant;
        break;
    }

    setMappedVariant(internalVariant);
  }, [variant]);

  if (loading) {
    return (
      <Skeleton
        variant="rectangle"
        componentSize={size}
        componentName="button"
        {...(typeof loading !== 'boolean' ? loading : {})}
      />
    );
  }

  return (
    <MuiLoadingButton
      size={size}
      loading={spinning}
      variant={mappedVariant}
      secondaryVariant={variant}
      loadingPosition={loadingPosition}
      {...(!spinning
        ? {
            startIcon,
          }
        : {})}
      {...rest}
    >
      {variant === 'icon' ? null : children}
    </MuiLoadingButton>
  );
};

export default Button;
