/* Member model constants */

import {
  ENDPOINT as USER_ENDPOINT,
  HEALTH_PROFILE_ENDPOINT,
} from '../constants';

export const PACKAGE_NAME = 'Member';

// Endpoints
export const MEMBER_ENDPOINT = `${USER_ENDPOINT}/members`;
export const MEMBER_BY_ID_ENDPOINT = (id) => `${USER_ENDPOINT}/members/${id}`;
export const OWNER_MEMBER_ENDPOINT = (ownerId) =>
  `${USER_ENDPOINT}/${ownerId}/members`;
export const OWNER_MEMBER_BASIC_USER_INFO_ENDPOINT = (ownerId) =>
  `${USER_ENDPOINT}/${ownerId}/members/basic`;
export const OWNER_MEMBER_HEALTH_PROFILES_ENDPOINT = (ownerId) =>
  `${HEALTH_PROFILE_ENDPOINT}/${ownerId}/members`;
