import sub from 'date-fns/sub';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import isSameDay from 'date-fns/isSameDay';
import React, { useState, useEffect } from 'react';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import differenceInDays from 'date-fns/differenceInDays';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';

import { userGetters } from 'domain/user';
import { doctorGetters } from 'domain/doctor';
import { getDefaultCountryCodeLong, getDefaultTimeZone } from 'core/siteConfig';
import { dateTimeFormatWithDay, timeFormat } from 'constants/dateTime';
import { TIME_SLOT_STATUSES } from 'domain/schedule/constants';
import {
  If,
  Else,
  Grid,
  Button,
  Tooltip,
  Typography,
  Conditionally,
  LinearProgress,
} from 'design-system';

import {
  parseSchedule,
  parseDaySchedule,
  generateSlotsFromTimeRange,
} from './utils';

import BookingAvailabilityDialog from '../BookingAvailabilityDialog';

const BookingAvailability = ({
  clear,
  doctors,
  doctor,
  /**
   * Start and end times might be passed at first to figure out the bookedSlots.
   * They won't be consumed if bookedSlots available.
   */
  /** Date */
  endTime,
  /** Date */
  startTime,
  onConfirm,
  value = [],
  isFetching,
  isPage = false,
  consultationId,
  bookingSlots = [],
  fetchBookingSlots,
  dayBookingSlots = [],
  fetchDayBookingSlots,
}) => {
  const [pageDate, setPageDate] = useState();
  const [doctorId, setDoctorId] = useState();
  const [doctorSlotDuration, setDoctorSlotDuration] = useState(30);
  const [bookedSlots, setBookedSlots] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [passedEndTime, setPassedEndTime] = useState();
  const [passedStartTime, setPassedStartTime] = useState();
  const [conflictMessage, setConflictMessage] = useState();
  const [dayScheduleData, setDayScheduleData] = useState({});
  const [hasProcessedPassedRange, setProcessedPassedRange] = useState(true);
  const [isDoctorScheduleModalOpen, setDoctorScheduleModalOpen] =
    useState(false);

  useEffect(() => {
    clear();
  }, []);

  /**
   * PREPARING TIME RANGE FOR PROCESSING:
   *
   * In case the provided information is a time range (startTime - endTime),
   * eventually, we need to generate bookedSlots out of them if it's within the bookingSlots.
   *
   * However, to do that, they're saved in passedStartTime and passedEndTime.
   * So, if there's no passed times, or the passedTimes are different from the start and end times,
   * save them in state and mark processedPassedRange as false.
   *
   * We do that, because we cannot directly process startTime and endTime.
   */
  useEffect(() => {
    if (startTime && endTime) {
      if (
        (!passedStartTime && !passedEndTime) ||
        (startTime.getTime() !== passedStartTime.getTime() &&
          endTime.getTime() !== passedEndTime.getTime())
      ) {
        const today = new Date(formatISO(new Date()).split('T')[0]);
        const startDay = new Date(formatISO(startTime).split('T')[0]);
        const diff = differenceInDays(startDay, today);

        /**
         * If the startDay is in the future, subtract the necessary number of days to find the day that's similar to today.
         *
         * e.g: if today is Wed 4 Jan, and startDay is Thu 12 Jan
         *    => the beginning of the week that includes Thu 12 Jan should be Wednesday too
         *    => difference between today and startDay is 8
         *    => 8 % 7 equals 1
         *    => so, we subract 1 day from Thu 12 Jan => results in Wed 11 Jan
         *    => That's the beginning of the week relevant to the startDay
         *
         * If the startDay is in the past, today is used.
         */
        if (diff > 0) {
          setPageDate(sub(startDay, { days: diff % 7 }));
        } else {
          setPageDate(today);
        }

        setPassedEndTime(endTime);
        setPassedStartTime(startTime);
        setProcessedPassedRange(false);
      }
    }
  }, [startTime, endTime]);

  /**
   * VALUE SYNC:
   *
   * To keep the component in sync with the consumer component, value is provided.
   * When the value changes and it's different from the bookedSlots,
   * then we take it into the bookedSlots list.
   */
  useEffect(() => {
    if (bookedSlots.length !== value.length) {
      setBookedSlots(value);
    }
  }, [value]);

  /**
   * DOCTOR VALUE SYNC:
   *
   * If the doctor value changes from outside,
   * update doctorId, reset bookedSlots and confirm those changes to the consumer.
   */
  useEffect(() => {
    if (doctor) {
      if (doctorId !== userGetters.getId(doctor)) {
        setDoctorId(userGetters.getId(doctor));
        if (bookedSlots.length) {
          setBookedSlots([]);
          onConfirm([], doctor);
        }
      }
      setDoctorSlotDuration(
        doctors.find((d) => d.id === userGetters.getId(doctor))?.slotDuration
      );
    } else {
      setDoctorId();
    }
  }, [doctor]);

  /**
   * FETCH ON DOCTOR OR PAGE CHANGE:
   *
   * If pageDate or doctorID changed, fetch booking slots
   */
  useEffect(() => {
    if (doctorId && pageDate) {
      setScheduleData({});
      fetchBookingSlots(doctorId, formatISO(pageDate).split('T')[0]);
      setProcessedPassedRange(false);
    }
  }, [doctorId, pageDate, fetchBookingSlots]);

  /**
   * PARSING BOOKING SLOTS BY DOCTOR:
   *
   * As soon the bookingSlots arrive, we need to parse them into schedule-friendly schema;
   * 7 days from today for a specific doctor
   */
  useEffect(() => {
    if (!isEmpty(bookingSlots)) {
      const parsedSchedule = parseSchedule(bookingSlots, {
        consultationId,
        pageDate,
        doctorSlotDuration,
      });
      setScheduleData(parsedSchedule);
    } else {
      setScheduleData({});
    }
  }, [bookingSlots, consultationId]);

  /**
   * PARSING BOOKING SLOTS BY DAY:
   *
   * As soon the dayBookingSlots arrive, we need to parse them into schedule-friendly schema;
   * All doctors for a specific day
   */
  useEffect(() => {
    if (!isEmpty(dayBookingSlots)) {
      const parsedSchedule = parseDaySchedule(dayBookingSlots, {
        consultationId,
      });

      setDayScheduleData(parsedSchedule);
    } else {
      setDayScheduleData({});
    }
  }, [dayBookingSlots, consultationId]);

  /**
   * GENERATING BOOKED SLOTS OUT OF TIME RANGE:
   *
   * If scheduleData is now available, and it has not been processed,
   * we need to do the following:
   * 1. Generate possible slots out of the passed time range
   * 2. Filter slots, that do not belong in the available schedule, out
   * 3. Set the resulting slots as bookedSlots and mark the time range as processed
   * 4. If there are no resulting slots or they don't exactly match the passed time range,
   *    show a conflict error message
   */
  useEffect(() => {
    if (
      !isFetching &&
      !isEmpty(bookingSlots) &&
      !isEmpty(scheduleData) &&
      isEmpty(bookedSlots) &&
      passedStartTime &&
      passedEndTime &&
      !hasProcessedPassedRange
    ) {
      const rangeSlots = generateSlotsFromTimeRange(
        passedStartTime,
        passedEndTime,
        doctorSlotDuration
      );

      // If an attempt to find booked slots from between start and end times occurred
      // but the booked slot are not actually available,
      // then tell the user to find other available slots
      const actualBookedSlots = rangeSlots
        .filter((slot) => {
          const daySchedule = scheduleData.schedule.find((day) =>
            isSameDay(new Date(slot.startTime), new Date(day.date))
          );

          if (isEmpty(daySchedule?.slots)) {
            return false;
          }
          const slots = values(daySchedule.slots);
          const foundSlot = slots.find((item) =>
            isEqual(new Date(item.startTime), new Date(slot.startTime))
          );

          if (
            !foundSlot ||
            (foundSlot.status === TIME_SLOT_STATUSES.BOOKED.key &&
              consultationId !== foundSlot.consultationId)
          ) {
            return false;
          }

          return true;
        })
        .map((slot) => ({
          ...slot,
          doctor,
          doctorId,
        }));

      if (actualBookedSlots.length) {
        setBookedSlots(actualBookedSlots);
        onConfirm(actualBookedSlots, doctor, false);
      }

      setProcessedPassedRange(true);

      if (rangeSlots.length && !actualBookedSlots.length) {
        const message = `The selected consultation time (${format(
          startTime,
          dateTimeFormatWithDay
        )}) is unavailable or booked for another consultation.`;

        setConflictMessage(message);
      } else if (rangeSlots.length !== actualBookedSlots.length) {
        const message = `The selected consultation time (${format(
          startTime,
          dateTimeFormatWithDay
        )}) is partially unavailable or conflicted with another consultation.`;

        setConflictMessage(message);
      } else {
        setConflictMessage();
      }
    }
  }, [
    hasProcessedPassedRange,
    passedStartTime,
    passedEndTime,
    consultationId,
    bookedSlots,
    scheduleData,
    bookingSlots,
    isFetching,
  ]);

  const onDoctorChanged = (val) => {
    const id = userGetters.getId(val);
    setDoctorId(id);
    setDoctorSlotDuration(doctorGetters.getSlotDuration(val));
    setScheduleData({});
    fetchBookingSlots(id, formatISO(pageDate).split('T')[0]);
    setProcessedPassedRange(false);
  };

  const onDayChanged = (date) => {
    const day = formatISO(date).split('T')[0];

    setDayScheduleData({});
    fetchDayBookingSlots(day);
  };

  return (
    <>
      {!isPage && (
        <Grid container column sx={{ mb: 1 }}>
          {isEmpty(bookedSlots) && isFetching && (
            <Grid item sx={{ width: '100%' }}>
              <LinearProgress />
            </Grid>
          )}
          {(!isEmpty(bookedSlots) || !isFetching) && (
            <>
              <Grid item>
                <Tooltip
                  placement="top"
                  injectWrapper
                  title={!doctor ? 'Please select doctor first' : ''}
                >
                  <Button
                    size="small"
                    color="primary"
                    variant="text"
                    disabled={!doctor}
                    startIcon={<MoreTimeIcon />}
                    onClick={() => setDoctorScheduleModalOpen(true)}
                  >
                    <Typography variant="l5" align="left">
                      <Conditionally>
                        <If condition={isEmpty(bookedSlots)}>
                          Book an appointment
                        </If>
                        <Else>
                          <b>Local time: </b>
                          {bookedSlots[0]?.startTime &&
                            format(
                              parseISO(bookedSlots[0]?.startTime),
                              dateTimeFormatWithDay
                            )}{' '}
                          - {bookedSlots.last()?.formattedEndTime}
                          <br />
                          <b>{getDefaultCountryCodeLong()} time: </b>
                          {bookedSlots[0]?.startTime &&
                            formatInTimeZone(
                              bookedSlots[0]?.startTime,
                              getDefaultTimeZone(),
                              dateTimeFormatWithDay
                            )}{' '}
                          -{' '}
                          {bookedSlots.last()?.endTime &&
                            formatInTimeZone(
                              bookedSlots.last()?.endTime,
                              getDefaultTimeZone(),
                              timeFormat
                            )}
                        </Else>
                      </Conditionally>
                    </Typography>
                  </Button>
                </Tooltip>
              </Grid>
              {conflictMessage && (
                <Grid item sx={{ mt: 1 }}>
                  <Typography
                    variant="p3"
                    sx={{ color: (theme) => theme.palette.error.main }}
                  >
                    {conflictMessage}
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
      {/* Checking isDoctorScheduleModalOpen forces the dialog to re-render and start anew */}
      {(isPage || isDoctorScheduleModalOpen) && (
        <BookingAvailabilityDialog
          isPage={isPage}
          doctor={doctor}
          pageDate={pageDate}
          bookedSlots={bookedSlots}
          scheduleData={scheduleData}
          dayScheduleData={dayScheduleData}
          open={isDoctorScheduleModalOpen}
          onClose={() => {
            setDoctorScheduleModalOpen(false);
          }}
          onDayChange={onDayChanged}
          onDoctorChange={onDoctorChanged}
          onConfirm={(confirmedBookedSlots, selectedDoctor) => {
            setConflictMessage();
            setBookedSlots(confirmedBookedSlots);
            setDoctorScheduleModalOpen(false);
            setDoctorId(userGetters.getId(selectedDoctor));

            onConfirm(confirmedBookedSlots, selectedDoctor);
          }}
          onPageDateChange={(date) => {
            setPageDate(date);
            setScheduleData({});
            setProcessedPassedRange(false);
            fetchBookingSlots(doctorId, formatISO(date).split('T')[0]);
          }}
        />
      )}
    </>
  );
};

export default BookingAvailability;
