import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DvrOutlinedIcon from '@mui/icons-material/DvrOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import NoAccounts from '@mui/icons-material/NoAccounts';

import { isAllowed } from 'core/permission';
import { authSelectors } from 'domain/auth';
import { Grid, Button, Divider, Tooltip, IconButton } from 'design-system';

function NavMenu({ collapsed = true, collapseToggleButton = true }) {
  const navigate = useNavigate();
  const valueChanged = useSelector(authSelectors.getUserRoles);

  const [isCollapsed, setCollapsed] = useState(collapsed);

  const navLinks = [
    {
      label: 'Consultations',
      icon: <VideoCallOutlinedIcon />,
      path: '/consultations',
      shouldDisplay: () => isAllowed('routes', 'consultations'),
    },
    {
      label: 'Orders',
      icon: <ShoppingCartOutlinedIcon />,
      path: '/orders',
      shouldDisplay: () => isAllowed('routes', 'orders'),
    },
    {
      label: 'Lab Requests',
      icon: <ColorizeOutlinedIcon />,
      path: '/lab-requests',
      shouldDisplay: () => isAllowed('routes', 'labRequests'),
    },
    {
      label: 'Booking Schedule',
      icon: <CalendarMonthIcon />,
      path: '/booking-schedule',
      shouldDisplay: () => isAllowed('routes', 'bookingSchedule'),
    },
    {
      label: 'Users',
      icon: <PeopleOutlineOutlinedIcon />,
      path: '/users',
      shouldDisplay: () => isAllowed('routes', 'users'),
    },
    {
      label: 'User Management',
      icon: <ManageAccountsOutlinedIcon />,
      path: '/user-management',
      shouldDisplay: () => isAllowed('routes', 'userManagement'),
    },
    {
      label: 'Doctor Management',
      icon: <ScheduleOutlinedIcon />,
      path: '/doctor-management',
      shouldDisplay: () => isAllowed('routes', 'doctorManagement'),
    },
    {
      label: 'Consultation Types',
      icon: <DvrOutlinedIcon />,
      path: '/consultation-types',
      shouldDisplay: () => isAllowed('routes', 'consultationTypes'),
    },
    {
      label: 'Lead Users',
      icon: <ContactPhoneOutlinedIcon />,
      path: '/lead-users',
      shouldDisplay: () => isAllowed('routes', 'leadUsers'),
    },
    {
      label: 'Medicines',
      icon: <LocalHospitalOutlinedIcon />,
      path: '/medicines',
      shouldDisplay: () => isAllowed('routes', 'medicines'),
    },
    {
      label: 'Reviews',
      icon: <RateReviewOutlinedIcon />,
      path: '/reviews',
      shouldDisplay: () => isAllowed('routes', 'reviews'),
    },
    {
      label: 'Anonymous Users',
      icon: <NoAccounts />,
      path: '/anonymous-users',
      shouldDisplay: () => isAllowed('routes', 'anonymousUsers'),
    },
  ];

  return (
    <Grid
      container
      column
      py={2}
      px={{
        xs: 1,
        sm: 2,
      }}
      sx={{
        width: {
          xs: isCollapsed ? 66 : 210,
          sm: isCollapsed ? 82 : 235,
        },
      }}
    >
      <Grid item {...(!isCollapsed ? { alignSelf: 'center' } : {})}>
        <img src="/logo192.png" width="40" alt="Alma Health Logo" />
      </Grid>
      {collapseToggleButton && (
        <Grid
          item
          {...(!isCollapsed ? { alignSelf: 'flex-end' } : {})}
          sx={{
            position: 'relative',
          }}
        >
          <IconButton
            onClick={() => {
              setCollapsed(!isCollapsed);
            }}
            sx={{
              top: -12,
              width: 20,
              position: 'absolute',
              left: {
                xs: isCollapsed ? 58 : 8,
                sm: isCollapsed ? 66 : 16,
              },
              //
              opacity: 0.2,
              backgroundColor: (theme) => theme.palette.secondary.tint,
              '&:hover': {
                opacity: 0.7,
              },
              //
              borderRadius: 0,
              borderTopRightRadius: 40,
              borderBottomRightRadius: 40,
            }}
          >
            {isCollapsed ? (
              <ArrowForwardIosIcon fontSize="small" color="primary" />
            ) : (
              <ArrowBackIosIcon
                fontSize="small"
                color="primary"
                sx={{ ml: 0.7 }}
              />
            )}
          </IconButton>
        </Grid>
      )}
      <Grid item sx={{ width: '100%' }}>
        <Divider />
      </Grid>
      {valueChanged.length !== 0 &&
        navLinks
          .filter((item) => item.shouldDisplay())
          .map((item) => (
            <Grid item>
              <Tooltip
                title={item.label}
                injectWrapper
                autoHide
                placement="right"
              >
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => navigate(item.path)}
                  startIcon={item.icon}
                  sx={{
                    ...(isCollapsed
                      ? {
                          minWidth: 0,
                          '& > .MuiButton-startIcon': {
                            margin: 0,
                          },
                        }
                      : {
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-start',
                        }),
                  }}
                >
                  {isCollapsed ? '' : item.label}
                </Button>
              </Tooltip>
            </Grid>
          ))}
    </Grid>
  );
}

export default NavMenu;
