import * as types from './actionTypes';

export const createMeeting = (payload, callback) => ({
  type: types.CREATE_MEETING,
  payload,
  callback,
});

export const updateMeeting = (id, payload, callback) => ({
  type: types.UPDATE_MEETING,
  id,
  payload,
  callback,
});

export const deleteMeeting = (meeting, callback) => ({
  type: types.DELETE_MEETING,
  meeting,
  callback,
});

export const getDoctorMeetings = (doctorId) => ({
  type: types.GET_DOCTOR_MEETINGS,
  doctorId,
});

export const setSubmittingMeeting = (isSubmitting) => ({
  type: types.SET_SUBMITTING_MEETING,
  isSubmitting,
});

export const setFetchingMeetings = (isFetching) => ({
  type: types.SET_FETCHING_MEETINGS,
  isFetching,
});

export const setDeletingMeeting = (isDeleting) => ({
  type: types.SET_DELETING_MEETING,
  isDeleting,
});

export const meetingCreated = (meeting) => ({
  type: types.MEETING_CREATED,
  meeting,
});

export const meetingUpdated = (meeting) => ({
  type: types.MEETING_UPDATED,
  meeting,
});

export const meetingDeleted = (meeting) => ({
  type: types.MEETING_DELETED,
  meeting,
});

export const meetingsReceived = (meetings) => ({
  type: types.MEETINGS_RECEIVED,
  meetings,
});

export const clear = () => ({
  type: types.CLEAR,
});
