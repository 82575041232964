import add from 'date-fns/add';
import set from 'date-fns/set';
import clone from 'lodash/clone';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import { differenceInDays } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';

import { TIME_SLOT_STATUSES } from 'domain/schedule/constants';

export const BOOKING_SLOT_TIME = 30; // Minutes

const TODAY = new Date();

const formatSlotTime = (hours, minutes) => {
  const meridian = hours < 12 ? 'AM' : 'PM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${meridian}`;
};

const addMinutes = (time, minutes) => {
  const { hours, minutes: currentMinutes } = time;
  const totalMinutes = hours * 60 + currentMinutes + minutes;
  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = totalMinutes % 60;

  return { hours: newHours, minutes: newMinutes };
};

const getTimeSlots = ({ endTime, startTime, duration }) => {
  const slots = [];
  let currentTime = { hours: startTime.hours, minutes: startTime.minutes };

  while (
    currentTime.hours < endTime.hours ||
    (currentTime.hours === endTime.hours &&
      currentTime.minutes <= endTime.minutes)
  ) {
    slots.push({
      hours: currentTime.hours,
      minutes: currentTime.minutes,
      formatted: formatSlotTime(currentTime.hours, currentTime.minutes),
    });

    currentTime = addMinutes(currentTime, duration);
  }

  return slots;
};

export const parseSchedule = (bookingSlots = [], options = {}) => {
  const {
    pageDate = TODAY,
    consultationId,
    startTimeSlot = { hours: 8, minutes: 0 },
    endTimeSlot = { hours: 23, minutes: 30 },
    doctorSlotDuration,
  } = options;
  let results = Array(7)
    .fill()
    .map(() => ({ slots: {} })); // 0-6 Days from Sunday to Saturday

  bookingSlots.forEach((slot) => {
    const formattedEndTime = formatISO(parseISO(slot.endTime));
    const formattedStartTime = formatISO(parseISO(slot.startTime));

    const startTime = new Date(formattedStartTime);
    const endTime = new Date(formattedEndTime);

    const isLessThanAWeek = Math.abs(differenceInDays(pageDate, startTime)) < 7;

    if (isLessThanAWeek) {
      const dayIdx = startTime.getDay();
      const status = (() => {
        if (consultationId && slot.consultationId === consultationId) {
          return TIME_SLOT_STATUSES.FREE.key;
        }

        return slot.status;
      })();

      results[dayIdx].slots[format(startTime, 'p')] = {
        ...slot,
        status,
        formatted: formattedStartTime,
        formattedStartTime: format(startTime, 'p'),
        formattedEndTime: format(endTime, 'p'),
      };
    }
  });

  const today = set(pageDate, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  let currentDate = today;

  results = results.circleMap(today.getDay(), (item) => {
    const updatedItem = { ...item };

    updatedItem.date = clone(currentDate);
    currentDate = add(currentDate, { days: 1 });

    return updatedItem;
  });

  const timeSlots = getTimeSlots({
    endTime: endTimeSlot,
    startTime: startTimeSlot,
    duration: doctorSlotDuration || BOOKING_SLOT_TIME,
  });

  return {
    schedule: results,
    timeSlots,
  };
};

export const parseDaySchedule = (bookingSlots = [], options = {}) => {
  const {
    consultationId,
    startTimeSlot = { hours: 8, minutes: 0 },
    endTimeSlot = { hours: 23, minutes: 30 },
    possibleSlotsDuration = 60,
  } = options;
  const results = {};

  bookingSlots.forEach((slot) => {
    const { doctor } = slot;
    const { doctorId } = slot;
    const formattedEndTime = formatISO(parseISO(slot.endTime));
    const formattedStartTime = formatISO(parseISO(slot.startTime));

    const endTime = new Date(formattedEndTime);
    const startTime = new Date(formattedStartTime);

    const status = (() => {
      if (consultationId && slot.consultationId === consultationId) {
        return TIME_SLOT_STATUSES.FREE.key;
      }

      return slot.status;
    })();

    if (!results[doctorId]) {
      results[doctorId] = {
        doctor,
        slots: [],
      };
    }

    results[doctorId].slots[format(startTime, 'h:00 a')] = [
      ...(results[doctorId].slots[format(startTime, 'h:00 a')] || []),
      {
        ...slot,
        status,
        formatted: formattedStartTime,
        formattedEndTime: format(endTime, 'p'),
        formattedStartTime: format(startTime, 'p'),
      },
    ];
  });

  const timeSlots = getTimeSlots({
    endTime: endTimeSlot,
    startTime: startTimeSlot,
    duration: possibleSlotsDuration,
  });

  return {
    schedule: results,
    timeSlots,
  };
};

/**
 * roundToNearestDuration takes in three parameters: dateTime, duration, and roundUp. It calculates the number of milliseconds in the given duration, and then rounds the dateTime up or down to the nearest multiple of that duration. The rounded date is then returned.
 */
export const roundToNearestDuration = (dateTime, duration, roundUp) => {
  const ms = 1000 * 60 * duration;
  if (roundUp) {
    return new Date(Math.ceil(dateTime.getTime() / ms) * ms);
  }
  return new Date(Math.floor(dateTime.getTime() / ms) * ms);
};

export const generateSlotsFromTimeRange = (
  startTime,
  endTime,
  slotDuration = BOOKING_SLOT_TIME
) => {
  const slots = [];
  const closestStartTime = roundToNearestDuration(
    startTime,
    slotDuration,
    false
  );

  let diff =
    Math.round(differenceInMinutes(endTime, closestStartTime) / slotDuration) *
    slotDuration;
  let iStartTime = set(closestStartTime, { seconds: 0, milliseconds: 0 });
  let iEndTime = add(iStartTime, { minutes: slotDuration });

  do {
    slots.push({
      startTime: iStartTime.toISOString(),
      endTime: iEndTime.toISOString(),
      status: TIME_SLOT_STATUSES.FREE.key,
      formatted: formatISO(iStartTime),
      formattedStartTime: format(iStartTime, 'p'),
      formattedEndTime: format(iEndTime, 'p'),
    });

    iStartTime = add(iStartTime, { minutes: slotDuration });
    iEndTime = add(iEndTime, { minutes: slotDuration });
    diff -= slotDuration;
  } while (diff > 0);

  return slots;
};
