import React, { useState, useMemo } from 'react';

import { outcomeConstants } from 'domain/outcome';
import { useDeviceSize } from 'design-system/hooks';
import { Tab, Tabs, Grid, TabPanel } from 'design-system';
import { EmbeddedFilePreview } from 'core/file/FileUpload';

const { OUTCOME_OPTIONS } = outcomeConstants;

const DOCUMENT_TABS = {
  PRESCRIPTION: 'Prescription',
  LAB_REQUEST: 'Lab Request',
  ASSESSMENT_SHEET: 'Assessment Sheet',
};

const OutcomeManagementDialogDocumentsPreview = ({
  selectedOutcomes = [],
  labRequestDocument,
  prescriptionDocument,
  assessmentSheetDocument,
}) => {
  const { isMedium, isTiny, isMobile, width } = useDeviceSize();

  const [previewTab, setPreviewTab] = useState(0);

  /**
   * The pdf has fixed width by default, and doesn't fit to the small screens
   * so in order to make it fit, we need to pass an appropriate width when the screen size changes.
   * 48 is the padding around.
   */
  const pdfWidth = useMemo(() => {
    if (isMedium || isTiny || isMobile) {
      return width - 48;
    }

    return 793; // According to Figma
  }, [isTiny, isMedium, isMobile, width]);

  const documentTabs = useMemo(() => {
    const tabs = [];

    if (selectedOutcomes.includes(OUTCOME_OPTIONS.Prescription.key)) {
      tabs.push(DOCUMENT_TABS.PRESCRIPTION);
    }

    if (selectedOutcomes.includes(OUTCOME_OPTIONS.LabTestRequest.key)) {
      tabs.push(DOCUMENT_TABS.LAB_REQUEST);
    }

    tabs.push(DOCUMENT_TABS.ASSESSMENT_SHEET);

    return tabs;
  }, [selectedOutcomes]);

  return (
    <Grid container column rowSpacing={3} mt={3} alignItems="center">
      <Grid
        item
        sx={{
          width: {
            xs: '100%',
            md: pdfWidth,
          },
        }}
      >
        <Tabs
          value={previewTab}
          variant="scrollable"
          scrollButtons="auto"
          tabVariant="filled"
          handleChange={(_event, newTab) => {
            setPreviewTab(newTab);
          }}
        >
          {documentTabs.map((label, idx) => (
            <Tab
              tabVariant="filled"
              value={idx}
              label={label}
              sx={{
                maxWidth: 'none',
                whiteSpace: 'nowrap',
                width: `calc(100% / ${documentTabs?.length})`,
              }}
            />
          ))}
        </Tabs>
        {documentTabs.map((label, idx) => (
          <TabPanel value={previewTab} index={idx}>
            {(() => {
              switch (label) {
                case DOCUMENT_TABS.PRESCRIPTION:
                  return (
                    prescriptionDocument && (
                      <EmbeddedFilePreview
                        file={prescriptionDocument}
                        width={pdfWidth}
                      />
                    )
                  );
                case DOCUMENT_TABS.LAB_REQUEST:
                  return (
                    labRequestDocument && (
                      <EmbeddedFilePreview
                        file={labRequestDocument}
                        width={pdfWidth}
                      />
                    )
                  );
                case DOCUMENT_TABS.ASSESSMENT_SHEET:
                  return (
                    assessmentSheetDocument && (
                      <EmbeddedFilePreview
                        file={assessmentSheetDocument}
                        width={pdfWidth}
                      />
                    )
                  );
                default:
                  break;
              }
            })()}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default OutcomeManagementDialogDocumentsPreview;
