import keys from 'lodash/keys';
import { useSelector } from 'react-redux';
import AbcIcon from '@mui/icons-material/Abc';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';

import { authSelectors } from 'domain/auth';
import { Button, DataGrid, PageTitle } from 'design-system';
import { medicineConstants as constants } from 'domain/medicine';
import { filtration, DataGridCard, useServerPagination } from 'core/dataGrid';
// import {
//   ImportButton,
//   ImportCsvFileDialog,
// } from 'presentation/components/UploadCsv';

import { isAllowed } from 'core/permission';
import { getColumns, getFilterFieldsConfig } from './utils';

const { MEDICINE_STATUS, LS_MEDICINE_FILTERS } = constants;

const MedicinesList = ({ saveFilters }) => {
  const navigate = useNavigate();

  const selectedTenant = useSelector((state) =>
    authSelectors.getSelectedActiveTenant(state, true)
  );

  const { params, filterValues } = filtration.getFilterParamsFromLs(
    getFilterFieldsConfig(),
    LS_MEDICINE_FILTERS
  );

  if (isAllowed('common', 'tenantFilter')) {
    params.append('filter', `tenantKey||$in||${selectedTenant}`);
  }

  const [isFetching, setFetching] = useState(false);
  // const [showDialog, setShowDialog] = useState(false);

  const {
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/medicine',
    params: new URLSearchParams(
      isAllowed('common', 'tenantFilter')
        ? {
            filter: `tenantKey||$in||${selectedTenant}`,
          }
        : {}
    ),
    initialSortModel: { field: 'id', sort: 'desc' },
    columnFieldsMap: {},
  });

  const { updateFilter } = filtration.useServerFiltration({
    saveFilters,
    setQueryParams,
    initialValues: filterValues,
    fieldsConfig: getFilterFieldsConfig(),
    onQueryParamsChange: (urlSearchParams) => {
      const prms = new URLSearchParams(urlSearchParams);

      if (isAllowed('common', 'tenantFilter')) {
        prms.append('filter', `tenantKey||$in||${selectedTenant}`);
      }

      return prms;
    },
  });

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const filtersDef = {
    fields: [
      {
        name: 'drugCode',
        type: 'textInput',
        placeholder: 'Drug Code',
        icon: VaccinesIcon,
        onInputChange: (event) => {
          updateFilter('drugCode', event.target.value);
        },
      },
      {
        name: 'packageName',
        type: 'textInput',
        placeholder: 'Package name',
        icon: Inventory2Icon,
        onInputChange: (event) => {
          updateFilter('packageName', event.target.value);
        },
      },
      {
        name: 'genericName',
        type: 'textInput',
        placeholder: 'Generic name',
        icon: AbcIcon,
        onInputChange: (event) => {
          updateFilter('genericName', event.target.value);
        },
      },
      {
        name: 'status',
        type: 'autocomplete',
        placeholder: 'status',
        icon: MonitorHeartOutlinedIcon,
        options: keys(MEDICINE_STATUS),
        getOptionLabel: (option) => MEDICINE_STATUS[option].label,
        disabled: isFetching,
        filter: (selectedStatus) => {
          updateFilter('status', MEDICINE_STATUS[selectedStatus]?.key);
        },
      },
    ],
  };

  const onRowClick = (row) => {
    const { id } = row;

    navigate(`/medicine/${id}`);
  };

  return (
    <>
      <PageTitle
        title="Medicines"
        action={
          <>
            {/* <ImportButton
              setShowDialog={() => setShowDialog(true)}
              label="Import Medicines"
            /> */}
            <Button
              variant="filled"
              onClick={() => {
                navigate('/medicine');
              }}
            >
              New Medicine
            </Button>
          </>
        }
      />
      {/* <ImportCsvFileDialog
        showDialog={showDialog}
        handleCloseClick={() => setShowDialog(false)}
        resource="medicine"
      /> */}
      <br />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          columns={getColumns()}
          onRowClick={(prms) => onRowClick(prms?.row)}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => {
                window.open(`/medicine/${row.id}`, '_blank');
              },
            },
          ]}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
};

MedicinesList.propTypes = {};

export default MedicinesList;
