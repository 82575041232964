import * as types from './actionTypes';

export const fetchDoctorNotes = (userId, consultationId) => ({
  type: types.FETCH_DOCTOR_NOTES,
  userId,
  consultationId,
});

export const submitDoctorNotes = (userId, consultationId, payload) => ({
  type: types.SUBMIT_DOCTOR_NOTES,
  userId,
  payload,
  consultationId,
});

export const doctorNotesSubmissionResponded = (ok, response) => ({
  type: types.DOCTOR_NOTES_SUBMISSION_RESPONDED,
  ok,
  response,
});

export const doctorNotesFetchResponded = (ok, response) => ({
  type: types.DOCTOR_NOTES_FETCH_RESPONDED,
  ok,
  response,
});

export const createPatientAssessmentSheet = (payload) => ({
  type: types.CREATE_PATIENT_ASSESSMENT_SHEET,
  payload,
});
export const patientAssessmentSheetCreationResponded = (ok, response) => ({
  type: types.PATIENT_ASSESSMENT_SHEET_CREATION_RESPONDED,
  response,
  ok,
});
