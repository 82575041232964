// eslint-disable-next-line import/prefer-default-export
export const isMobileDesktopView = () => {
  const screenWidth = window.screen.width;
  const isMobile = screenWidth <= 480;

  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regexp.test(details);

  if (!isMobileDevice && isMobile) {
    return true;
  }

  return false;
  // Keep those for reference
  // if (isMobileDevice && isMobile) {
  //   alert('You are using a Mobile Device');
  // } else if (isMobile) {
  //   alert('You are using Desktop on Mobile'); // the most interesting
  // } else {
  //   alert('You are using Desktop');
  // }
};
