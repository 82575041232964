import values from 'lodash/values';
import forEach from 'lodash/forEach';

import { REFERENCE_TYPES } from 'constants/general';

import * as types from './actionTypes';

const initialState = {
  isCreatingFiles: 0,
  view: {},
  cache: {
    /* TODO: Cache by file id */
  },
  viewBy: {},
  isFetchingFilesOf: {},
};

forEach(values(REFERENCE_TYPES), (type) => {
  initialState.view[type] = [];
});

const fileReducer = (state = initialState, action) => {
  const countOfFilesUploading = state.isCreatingFiles;

  switch (action.type) {
    case types.FILES_CREATED: {
      const { files, isReplacing, referenceType } = action;

      return {
        ...state,
        view: {
          ...state.view,
          [referenceType]: isReplacing
            ? [...files]
            : [...(state.view[referenceType] || []), ...files],
        },
      };
    }
    case types.FILES_RECEIVED_OF: {
      return {
        ...state,
        viewBy: {
          ...state.viewBy,
          [action.referenceType]: {
            ...(state.viewBy[action.referenceType] || {}),
            [action.referenceId]: [...action.files],
          },
        },
      };
    }
    case types.SET_FETCHING_FILES_OF: {
      return {
        ...state,
        isFetchingFilesOf: {
          ...state.isFetchingFilesOf,
          [action.referenceType]: {
            ...(state.isFetchingFilesOf[action.referenceType] || {}),
            [action.referenceId]: action.isFetchingFiles,
          },
        },
      };
    }
    case types.CLEAR_FILES: {
      if (action.referenceType) {
        return {
          ...state,
          view: {
            ...state.view,
            [action.referenceType]: [],
          },
        };
      }

      return {
        ...state,
        view: {},
      };
    }
    case types.SET_CREATING_FILES: {
      return {
        ...state,
        isCreatingFiles: action.isCreatingFiles
          ? countOfFilesUploading + 1
          : (countOfFilesUploading || 1) - 1,
      };
    }
    default:
      return state;
  }
};

export default fileReducer;
