import { connect } from 'react-redux';

import {
  meetingActions as actions,
  meetingSelectors as selectors,
} from 'domain/meeting';

import ViewMeetingDialog from './ViewMeetingDialog';

const mapStateToProps = (state) => {
  const isDeleting = selectors.isDeleting(state);
  const isSubmitting = selectors.isSubmitting(state);

  return {
    isDeleting,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteMeeting: (meeting, callback) =>
    dispatch(actions.deleteMeeting(meeting, callback)),
  updateMeeting: (id, payload, callback) =>
    dispatch(actions.updateMeeting(id, payload, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMeetingDialog);
