import React from 'react';

import { Grid, Chip } from 'design-system';
import { userConstants } from 'domain/user';

const { ALLERGIES_LIST_TYPE } = userConstants;

const AllergiesDisplay = ({ selectedAllergies }) => (
  <Grid container rowSpacing={0.5} columnSpacing={1}>
    {selectedAllergies
      .filter((allergy) => allergy.type)
      .map((allergy) => (
        <Grid item>
          <Chip
            size="small"
            color="error"
            key={ALLERGIES_LIST_TYPE[allergy.type]?.key || allergy.type}
            label={ALLERGIES_LIST_TYPE[allergy.type]?.label || allergy.type}
          />
        </Grid>
      ))}
  </Grid>
);

export default AllergiesDisplay;
