import keys from 'lodash/keys';
import { alpha, createTheme, responsiveFontSizes } from '@mui/material/styles';

const pill = {
  tint: '#E9F1FF',
  light: '#A0DAF8',
  main: '#66CBFF',
  dark: '#329DD4',
  contrastText: '#FFF',
};

const care = {
  tint: '#ECFFFB',
  light: '#9EFCE9',
  main: '#00FFCB',
  dark: '#06C29C',
  contrastText: '#FFF',
};

const primary = {
  tint: '#E7EDFF',
  light: '#5476C9',
  main: '#2A4B9B',
  dark: '#1A3371',
  contrastText: '#FFF',
};

const error = {
  tint: '#FFEDED',
  light: '#FE9495',
  main: '#FF595A',
  dark: '#BF3D3D',
  contrastText: '#FFF',
};

const warning = {
  tint: '#FFFBED',
  light: '#FFD54F',
  main: '#FFB300',
  dark: '#E89A25',
  contrastText: '#FFF',
};

const grey = {
  900: '#1D1E20',
  800: '#25282B',
  700: '#3A3B40',
  600: '#70737C',
  500: '#A8ABB1',
  400: '#DBDDE0',
  300: '#E8E8E8',
  200: '#F4F6F8',
  100: '#FAFAFA',
  50: '#F9FAFD',
};

const white = {
  tint: '#FFF',
  light: '#FFF',
  main: '#FFF',
  dark: '#FFF',
  contrastText: '#000',
};

const standard = {
  tint: grey[200],
  light: grey[300],
  main: grey[400],
  dark: grey[700],
  contrastText: '#FFF',
};

const l2 = {
  fontSize: '1.125rem',
  fontWeight: 600,
  fontFamily: 'Helvetica, Arial, sans-serif',
};

const l3 = {
  fontSize: '1rem',
  fontWeight: 600,
  fontFamily: 'Helvetica, Arial, sans-serif',
};

const l4 = {
  fontSize: '0.875rem',
  fontWeight: 600,
  fontFamily: 'Helvetica, Arial, sans-serif',
};

const l5 = {
  fontSize: '0.75rem',
  fontWeight: 600,
  fontFamily: 'Helvetica, Arial, sans-serif',
};

const p3 = {
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: 1.4,
  fontFamily: 'Helvetica, Arial, sans-serif',
};

const colorVariants = {
  standard,
  white,
  primary,
  secondary: pill,
  error,
  warning,
  info: primary,
  success: care,
};

const lightTheme = responsiveFontSizes(
  createTheme({
    palette: {
      common: {
        black: grey[900],
        white: '#FFF',
      },
      ...colorVariants,
      grey,
    },
    typography: {
      fontFamily: 'Helvetica, Arial, sans-serif',
      // 1 rem = 16px
      h1: {
        // Heading XL
        fontSize: '2.5rem',
        fontWeight: 700,
        lineHeight: 1.1,
      },
      h2: {
        // Heading LG
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h3: {
        // Heading MD
        fontSize: '1.75rem',
        fontWeight: 700,
        lineHeight: 1.28,
      },
      h4: {
        // Heading SM
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: 1.3,
      },
      h5: {
        // Heading XS
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.4,
      },
      h6: {
        // Heading XXS
        fontSize: '1.2rem',
        fontWeight: 700,
        lineHeight: 1.4,
      },
      p1: {
        // Paragraph LG
        fontSize: '1.125rem',
        fontWeight: 400,
        lineHeight: 1.5,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      p2: {
        // Paragraph MD
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      p3: {
        // Paragraph SM
        ...p3,
      },
      p4: {
        // Paragraph XS
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.5,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      l1: {
        // Label XL
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.3,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      l2: {
        // Label LG
        ...l2,
        lineHeight: 1.3,
      },
      l3: {
        // Label MD
        ...l3,
        lineHeight: 1.25,
      },
      l4: {
        // Label SM
        ...l4,
        lineHeight: 1.14,
      },
      l5: {
        // Label XS
        ...l5,
        lineHeight: 1.3,
      },
      d1: {
        // Display LG
        fontSize: '6rem',
        fontWeight: 900,
        lineHeight: 1.16,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      d2: {
        // Display MD
        fontSize: '3.25rem',
        fontWeight: 900,
        lineHeight: 1.23,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      d3: {
        // Display SM
        fontSize: '2.75rem',
        fontWeight: 900,
        lineHeight: 1.18,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      d4: {
        // Display XS
        fontSize: '2.25rem',
        fontWeight: 900,
        lineHeight: 1.22,
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
    },
    components: {
      MuiGrid: {
        variants: [
          {
            props: { fullWidth: true },
            style: {
              width: '100%',
            },
          },
          {
            props: { fullHeight: true },
            style: {
              height: '100%',
            },
          },
        ],
      },
      MuiDataGrid: {
        defaultProps: {
          rowHeight: 56,
          autoHeight: true,
          disableColumnFilter: true,
        },
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              border: 0,
              borderRadius: 0,
            }),
          columnHeader: ({ theme }) =>
            theme.unstable_sx({
              p: 0,
              pl: 2,
              '&:last-child': {
                pr: 3,
              },
            }),
          columnHeaderTitle: ({ theme }) => theme.unstable_sx(l4),
          columnHeaderTitleContainer: ({ theme }) =>
            theme.unstable_sx({
              p: 0,
            }),
          cell: ({ theme }) =>
            theme.unstable_sx({
              p: 0,
              pl: 2,
              borderBottomColor: grey[300],
              '&:focus': {
                outline: 'none',
              },
            }),
          columnHeaders: ({ theme }) =>
            theme.unstable_sx({
              backgroundColor: grey[100],
              borderBottomColor: grey[300],
            }),
          columnSeparator: ({ theme }) =>
            theme.unstable_sx({
              display: 'none',
            }),
          row: ({ theme }) =>
            theme.unstable_sx({
              '&:hover, &.Mui-selected:hover': {
                backgroundColor: alpha(primary.tint, 0.6),
              },
              '&.Mui-selected': {
                backgroundColor: alpha(pill.light, 0.4),
              },
            }),
        },
      },
      MuiChip: {
        styleOverrides: {
          labelMedium: ({ theme }) => theme.unstable_sx(p3),
        },
        variants: (() => {
          return keys(colorVariants).reduce((variants, color) => {
            return variants.concat([
              // Filled
              {
                props: { variant: 'filled', color },
                style: {
                  color: '#FFF',
                  backgroundColor: colorVariants[color].dark,
                },
              },
              // Light
              {
                props: { variant: 'filled', soak: 'light', color },
                style: {
                  backgroundColor: colorVariants[color].tint,
                  color: colorVariants[color].dark,
                },
              },
              // Outlined
              {
                props: { variant: 'outlined', color },
                style: {
                  borderColor: colorVariants[color].dark,
                  color: colorVariants[color].dark,
                },
              },
            ]);
          }, []);
        })(),
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '0px 12px 26px rgba(16, 30, 115, 0.06)',
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          action: ({ theme }) =>
            theme.unstable_sx({
              m: 0,
              alignSelf: 'initial',
            }),
        },
      },
      MuiCardContent: {
        variants: [
          {
            props: { noPadding: true },
            style: ({ theme }) =>
              theme.unstable_sx({
                p: 0,
                '&:last-child': {
                  pb: 0,
                },
              }),
          },
        ],
      },
      MuiCardActions: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              p: 2,
              borderTop: '1px solid',
              borderTopColor: grey[200],
            }),
        },
      },
      MuiDialog: {
        variants: [
          {
            props: { scrollable: true },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiDialogContent-root': {
                  overflowY: 'scroll !important',
                },
                '& .MuiDialogActions-root': {
                  backgroundColor: theme.palette.common.white,
                  zIndex: 1,
                },
              }),
          },
        ],
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            backgroundColor: grey[100],
          },
        },
      },
      MuiOutlinedInput: {
        variants: (() => {
          return keys(colorVariants).map((color) => ({
            props: { color },
            style: {
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: colorVariants[color].dark,
              },
            },
          }));
        })(),
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              borderRadius: 1,
              '&:after, &:before': {
                display: 'none',
              },
            }),
        },
        variants: [
          {
            props: { shape: 'round' },
            style: {
              borderRadius: '6rem',
            },
          },
          {
            props: { size: 'medium' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(5),
              }),
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              '& .MuiInputBase-root': {
                pl: 2,
                color: colorVariants.standard.dark,
                backgroundColor: colorVariants.standard.tint,
                '&:hover, &.Mui-focused': {
                  backgroundColor: alpha(colorVariants.standard.light, 0.4),
                },
              },
              '& .MuiInputBase-input': {
                pl: 0,
                lineHeight: 1.5,
                boxSizing: 'border-box',
              },
            }),
        },
        variants: [
          {
            props: { shape: 'round' },
            style: {
              borderRadius: '6rem',
              '& .MuiInputBase-root': {
                borderRadius: '6rem',
              },
            },
          },
          {
            props: { multiline: true },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-root': {
                  height: 'auto',
                },
              }),
          },
          {
            props: { size: 'large' },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-input': {
                  py: 12.5 / 8,
                  fontSize: '0.875rem',
                  height: theme.spacing(6),
                },
              }),
          },
          {
            props: { size: 'large', multiline: true },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-root': {
                  py: 12.5 / 8,
                },
              }),
          },
          {
            props: { size: 'medium' },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-input': {
                  py: 10 / 8,
                  fontSize: '0.75rem',
                  height: theme.spacing(5),
                },
              }),
          },
          {
            props: { size: 'medium', multiline: true },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-root': {
                  py: 10 / 8,
                },
              }),
          },
          {
            props: { size: 'small' },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-input': {
                  py: 1,
                  fontSize: '0.625rem',
                  height: theme.spacing(4),
                },
              }),
          },
          {
            props: { size: 'small', multiline: true },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiInputBase-root': {
                  py: 1,
                },
              }),
          },
        ],
      },
      MuiSelect: {
        defaultProps: {
          autoHeight: true,
        },
        styleOverrides: {
          select: ({ theme }) =>
            theme.unstable_sx({
              pl: 2,
              backgroundColor: 'unset',
              boxSizing: 'border-box',
              '&.Mui-disabled': {
                '-webkit-text-fill-color': 'unset',
              },
            }),
        },
        variants: (() => {
          return keys(colorVariants)
            .reduce((variants, color) => {
              return variants.concat([
                // Filled
                {
                  props: { variant: 'filled', color },
                  style: {
                    color: colorVariants[color].contrastText,
                    backgroundColor: colorVariants[color].dark,
                    '&:hover, &.Mui-focused': {
                      backgroundColor: colorVariants[color].main,
                    },
                  },
                },
                // Light
                {
                  props: { variant: 'filled', soak: 'light', color },
                  style: {
                    color: colorVariants[color].dark,
                    backgroundColor: colorVariants[color].tint,
                    '&:hover, &.Mui-focused': {
                      backgroundColor: alpha(colorVariants[color].light, 0.4),
                    },
                  },
                },
              ]);
            }, [])
            .concat([
              {
                props: { size: 'large' },
                style: ({ theme }) =>
                  theme.unstable_sx({
                    '& .MuiSelect-select': {
                      py: 12.5 / 8,
                      fontSize: '0.875rem',
                      height: theme.spacing(6),
                    },
                  }),
              },
              {
                props: { size: 'medium' },
                style: ({ theme }) =>
                  theme.unstable_sx({
                    '& .MuiSelect-select': {
                      py: 10 / 8,
                      fontSize: '0.75rem',
                      height: theme.spacing(5),
                    },
                  }),
              },
              {
                props: { size: 'small' },
                style: ({ theme }) =>
                  theme.unstable_sx({
                    '& .MuiSelect-select': {
                      py: 1,
                      fontSize: '0.625rem',
                      height: theme.spacing(4),
                    },
                  }),
              },
              {
                props: { shape: 'round' },
                style: {
                  borderRadius: '6rem',
                },
              },
              {
                props: { autoHeight: true },
                style: {
                  height: 'max-content',
                  '& .MuiSelect-select': {
                    height: 'max-content',
                  },
                },
              },
            ]);
        })(),
      },
      MuiButton: {
        styleOverrides: {
          root: ({
            theme,
            ownerState: { size, variant, secondaryVariant },
          }) => {
            let sizeRelatedStyles = {};
            let variantRelatedStyles = {};

            switch (size) {
              case 'small':
                sizeRelatedStyles = l5;
                break;
              case 'medium':
                sizeRelatedStyles = l4;
                break;
              case 'large':
                sizeRelatedStyles = {
                  py: 1.5,
                  lineHeight: 1.5,
                  ...l3,
                };
                break;
              default:
                break;
            }

            if (variant !== 'text') {
              variantRelatedStyles = {
                borderRadius: '6rem',
              };
            } else if (secondaryVariant === 'icon') {
              // variant is text, and secondaryVariant is icon
              variantRelatedStyles = {
                minWidth: 0,
                '& > *': {
                  m: 0,
                },
              };
            }

            return theme.unstable_sx({
              textTransform: 'none',
              ...variantRelatedStyles,
              ...sizeRelatedStyles,
            });
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              '&.MuiLoadingButton-loading': {
                pl: 5,
              },
            }),
        },
      },
      MuiSvgIcon: {
        variants: [
          {
            props: { fontSize: 'extraSmall' },
            style: {
              fontSize: '1rem',
            },
          },
          {
            props: { fontSize: 'extraLarge' },
            style: {
              fontSize: '2.3rem',
            },
          },
          {
            props: { color: 'standard' },
            style: {
              color: grey[500],
            },
          },
        ],
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: grey[200],
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          previousNext: {
            backgroundColor: grey[50],
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: ({ theme }) =>
            theme.unstable_sx({
              marginTop: '0px !important',
            }),
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: ({ theme }) =>
            theme.unstable_sx({
              '& .MuiAutocomplete-inputRoot': {
                px: 2,
              },
            }),
        },
        variants: [
          {
            props: { size: 'large' },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiAutocomplete-inputRoot': {
                  py: 0,
                  fontSize: '0.875rem',
                  height: theme.spacing(6),
                  minHeight: theme.spacing(6),
                },
              }),
          },
          {
            props: { size: 'medium' },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiAutocomplete-inputRoot': {
                  py: 0,
                  fontSize: '0.75rem',
                  height: theme.spacing(5),
                  minHeight: theme.spacing(5),
                  '& .MuiInputBase-input': {
                    px: 0,
                  },
                },
              }),
          },
          {
            props: { size: 'small' },
            style: ({ theme }) =>
              theme.unstable_sx({
                '& .MuiAutocomplete-inputRoot': {
                  py: '0 !important',
                  fontSize: '0.625rem',
                  height: theme.spacing(4),
                  minHeight: theme.spacing(4),
                  '& .MuiInputBase-input': {
                    px: 0,
                  },
                },
              }),
          },
        ],
      },
      MuiTabScrollButton: {
        styleOverrides: {
          root: {
            width: '1.25rem',
            '&.Mui-disabled': {
              opacity: 0.3,
            },
          },
        },
      },
      MuiTabs: {
        variants: [
          {
            props: { tabVariant: 'border' },
            style: {
              '& .MuiTabs-indicator': {
                display: 'none',
              },
            },
          },
          {
            props: { tabVariant: 'filled' },
            style: ({ theme }) =>
              theme.unstable_sx({
                borderRadius: theme.spacing(4),
                backgroundColor: theme.palette.grey[200],
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }),
          },
        ],
      },
      MuiTab: {
        variants: (() => {
          return keys(colorVariants).reduce(
            (variants, color) => {
              return variants.concat([
                {
                  props: { color },
                  style: {
                    color: colorVariants[color].main,
                  },
                },
                {
                  props: { color, soak: 'dark' },
                  style: {
                    color: colorVariants[color].dark,
                  },
                },
              ]);
            },
            [
              // Other variants
              {
                props: { tabVariant: 'border' },
                style: ({ theme }) =>
                  theme.unstable_sx({
                    mr: 3,
                    height: '6rem',
                    borderRadius: '0.5rem',
                    backgroundColor: theme.palette.common.white,
                    boxShadow: '0px 0px 26px 0px rgba(16, 30, 115, 6%)',
                    '&.Mui-selected': {
                      border: `2px solid ${theme.palette.primary.main}`,
                      backgroundColor: theme.palette.secondary.tint,
                    },
                  }),
              },
              {
                props: { tabVariant: 'filled' },
                style: ({ theme }) =>
                  theme.unstable_sx({
                    textTransform: 'initial',
                    '&.Mui-selected': {
                      color: theme.palette.common.white,
                      borderRadius: theme.spacing(4),
                      backgroundColor: theme.palette.primary.main,
                    },
                  }),
              },
            ]
          );
        })(),
      },
      MuiSkeleton: {
        variants: [
          // Typography
          {
            props: { componentName: 'typography', componentVariant: 'p3' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(16 / 8),
              }),
          },
          {
            props: { componentName: 'typography', componentVariant: 'p4' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(14 / 8),
              }),
          },
          {
            props: { componentName: 'typography', componentVariant: 'l5' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(14 / 8),
              }),
          },
          // Select
          {
            props: { componentName: 'select', componentSize: 'large' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(6),
              }),
          },
          {
            props: { componentName: 'select', componentSize: 'medium' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(5),
              }),
          },
          {
            props: { componentName: 'select', componentSize: 'small' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(4),
              }),
          },
          // TextField
          {
            props: { componentName: 'textField', componentSize: 'large' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(6),
              }),
          },
          {
            props: { componentName: 'textField', componentSize: 'medium' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(5),
              }),
          },
          {
            props: { componentName: 'textField', componentSize: 'small' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(4),
              }),
          },
          {
            props: {
              componentName: 'textField',
              componentSize: 'large',
              componentShape: 'round',
            },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(6),
                borderRadius: theme.spacing(6),
              }),
          },
          {
            props: {
              componentName: 'textField',
              componentSize: 'medium',
              componentShape: 'round',
            },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(5),
                borderRadius: theme.spacing(5),
              }),
          },
          {
            props: {
              componentName: 'textField',
              componentSize: 'small',
              componentShape: 'round',
            },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(4),
                borderRadius: theme.spacing(4),
              }),
          },
          // Button
          {
            props: { componentName: 'button', componentSize: 'large' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(6),
              }),
          },
          {
            props: { componentName: 'button', componentSize: 'medium' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(5),
              }),
          },
          {
            props: { componentName: 'button', componentSize: 'small' },
            style: ({ theme }) =>
              theme.unstable_sx({
                height: theme.spacing(4),
              }),
          },
          // Chip
          {
            props: { componentName: 'chip' },
            style: ({ theme }) =>
              theme.unstable_sx({
                borderRadius: 16,
                height: theme.spacing(4),
              }),
          },
        ],
      },
    },
  })
);

export default lightTheme;
