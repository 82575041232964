import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import {
  consultationTypeActions as actions,
  consultationTypeSelectors as selectors,
} from 'domain/consultationType';

import AddEditDialog from './AddEditDialog';

const mapStateToProps = (state) => {
  const isFetching = selectors.isFetching(state);
  const isSubmitting = selectors.isSubmittingConfiguration(state);
  const consultationTypeConfigurations =
    selectors.getConsultationTypeConfigurations(state);
  const existingInsuranceIds = consultationTypeConfigurations?.map(
    (configuration) => {
      return configuration?.insuranceProviderId;
    }
  );

  return {
    isFetching,
    isSubmitting,
    existingInsuranceIds,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addConsultationTypeConfiguration: (payload) =>
    dispatch(actions.addConsultationTypeConfiguration(payload)),
  updateConsultationTypeConfiguration: (id, payload) =>
    dispatch(actions.updateConsultationTypeConfiguration(id, payload)),
});
export default appData(
  connect(mapStateToProps, mapDispatchToProps)(AddEditDialog)
);
