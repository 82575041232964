/* eslint-disable no-nested-ternary */
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import React, { useMemo, useState, useEffect } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import { DialerDialog } from 'presentation/components';

import { authSelectors } from 'domain/auth';
import { DataGridCard } from 'core/dataGrid';
import { dateTimeFormat } from 'constants/dateTime';
import { Grid, Chip, Button, Message, Tooltip, DataGrid } from 'design-system';

import addMinutes from 'date-fns/addMinutes';
import {
  ZOOM_MEETING_ACTION,
  CALL_MEETING_ACTION,
} from 'domain/consultation/constants';
import PhoneForwardedOutlined from '@mui/icons-material/PhoneForwardedOutlined';
import { isAllowed } from 'core/permission';
import {
  checkIfMeetingWithin10MinRange,
  checkIfMeetingStarted,
  isPatientJoined,
  formatTime,
} from '../../../utils';

const columns = [
  { field: 'name', flex: 1, headerName: 'Participant' },
  {
    field: 'joinedAt',
    headerName: 'Time',
    flex: 1,
    valueGetter: (params) =>
      format(parseISO(params.row.joinedAt), dateTimeFormat),
  },
  {
    field: 'event',
    headerName: 'Event',
    flex: 1,
    renderCell: (params) => {
      return (
        <Tooltip placement="top" title="Status">
          <Chip
            sx={{ minWidth: 100 }}
            variant="filled"
            soak="light"
            label={params.row.event || 'joined'}
            color={params.row.event === 'left' ? 'error' : 'success'}
          />
        </Tooltip>
      );
    },
  },
];

const FIVE_MINUTES = 5;

const ParticipantSection = ({
  endTime,
  startTime,
  isFetching,
  isCompletedByPhone,
  meetingLink,
  participants = [],
  patientName,
  patientPhoneNumber,
  enableCalls,
  onJoinMeetingClicked = () => {},
}) => {
  const { isDoctor, isSuperAdmin, isPatientSuccess } = useSelector(
    authSelectors.getRole
  );

  const [isMeetingWithin10MinRange, setMeetingWithin10MinRange] =
    useState(false);

  const [isMeetingWithinRange, setMeetingWithinRange] = useState();
  const [minutesAfterStartTime, setMinutesAfterStartTime] = useState();
  const [allowPhoneCall, setAllowPhoneCall] = useState(false);
  const [isCompletedViaPhone, setCompletedViaPhone] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(isMeetingWithinRange);

  /**
   * Check if the consultation is started and within the first X minutes
   */
  const checkAfterMinutesRules = () => {
    if (checkIfMeetingStarted(startTime, endTime)) {
      setMeetingWithinRange(true);
      // Count down from X minutes after till now
      setMinutesAfterStartTime(
        // Calculate the time difference between now and start time
        Math.floor(addMinutes(startTime, FIVE_MINUTES).getTime() / 1000) -
          Math.floor(new Date().getTime() / 1000)
      );
    }
  };

  useEffect(() => {
    // Allow phone call only if the meeting is happening now
    // and after X minutes and patient didn't join the meeting
    setAllowPhoneCall(
      enableCalls &&
        isMeetingWithinRange &&
        timeRemaining <= 0 &&
        !isPatientJoined(participants, patientName, patientPhoneNumber)
    );
  }, [isMeetingWithinRange, timeRemaining]);

  useEffect(() => {
    let timer;

    if (endTime && startTime) {
      setMeetingWithin10MinRange(
        checkIfMeetingWithin10MinRange(startTime, endTime)
      );
      checkAfterMinutesRules();

      timer = setInterval(() => {
        setMeetingWithin10MinRange(
          checkIfMeetingWithin10MinRange(startTime, endTime)
        );
        checkAfterMinutesRules();
        // Every 30 seconds
      }, 1000 * 30);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [endTime, startTime]);

  const isMeetingEnabled = useMemo(() => {
    return (
      meetingLink &&
      ((isDoctor && isMeetingWithin10MinRange) ||
        isSuperAdmin ||
        isPatientSuccess)
    );
  }, [
    isDoctor,
    isSuperAdmin,
    isPatientSuccess,
    meetingLink,
    isMeetingWithin10MinRange,
  ]);

  useEffect(() => {
    setTimeRemaining(minutesAfterStartTime);
  }, [isMeetingWithinRange]);

  useEffect(() => {
    setCompletedViaPhone(isCompletedByPhone);
  }, [isCompletedByPhone]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1);
      }
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [timeRemaining]);

  return (
    <DataGridCard
      header={{
        title: 'Meeting Participants',
        action: (
          <Grid container alignItems="center">
            <Tooltip
              autoHide
              injectWrapper
              placement="top"
              title={`Click here to call the patient – allowed only after ${FIVE_MINUTES} minutes from the start time`}
            >
              <Button
                size="small"
                sx={{ mr: 1 }}
                variant="filled"
                onClick={() => {
                  setOpen(true);
                  onJoinMeetingClicked(CALL_MEETING_ACTION);
                }}
                disabled={!isMeetingEnabled || !allowPhoneCall}
                loading={isFetching && { width: 110 }}
              >
                <PhoneForwardedOutlined sx={{ height: '19px' }} />
                {(() => {
                  if (
                    isAllowed('consultationView', 'enableCall') &&
                    enableCalls &&
                    timeRemaining > 0
                  ) {
                    return formatTime(timeRemaining);
                  }
                  if (isCompletedViaPhone) {
                    return 'Done';
                  }

                  return 'Call';
                })()}
              </Button>
            </Tooltip>
            <Tooltip
              autoHide
              injectWrapper
              placement="top"
              title="Click here to join the zoom meeting - allowed only for doctors"
            >
              <Button
                size="small"
                variant="filled"
                color="error"
                target="_blank"
                href={meetingLink}
                onClick={() => {
                  onJoinMeetingClicked(ZOOM_MEETING_ACTION);
                }}
                disabled={!isMeetingEnabled}
                startIcon={
                  <OnlinePredictionIcon
                    className={isMeetingEnabled ? 'big-1-5x-and-fade' : ''}
                  />
                }
                loading={isFetching && { width: 140 }}
              >
                Join meeting
              </Button>
            </Tooltip>
            {!isFetching && (
              <Tooltip
                autoHide
                injectWrapper
                placement="top"
                title="Copy - allowed only for doctors"
              >
                <Button
                  size="small"
                  variant="icon"
                  color="primary"
                  disabled={!isMeetingEnabled}
                  startIcon={<ContentCopyIcon />}
                  onClick={() => {
                    navigator.clipboard.writeText(meetingLink);
                    Message.success('Copied to clipboard!');
                  }}
                />
              </Tooltip>
            )}
          </Grid>
        ),
      }}
      sx={{ height: '100%' }}
    >
      <DialerDialog
        phoneNumber={patientPhoneNumber}
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
      />
      <DataGrid
        columns={columns}
        rows={participants}
        loading={isFetching}
        sx={{
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
        }}
      />
    </DataGridCard>
  );
};

export default ParticipantSection;
