import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as ORDER } from './constants';

export const FETCH_ORDER = `${MODEL}/${ORDER}/FETCH_ORDER`;
export const PATCH_ORDER = `${MODEL}/${ORDER}/PATCH_ORDER`;
export const CREATE_ORDER = `${MODEL}/${ORDER}/CREATE_ORDER`;
export const ADD_LINE_ITEM = `${MODEL}/${ORDER}/ADD_LINE_ITEM`;
export const UPDATE_LINE_ITEM = `${MODEL}/${ORDER}/UPDATE_LINE_ITEM`;
export const DELETE_LINE_ITEM = `${MODEL}/${ORDER}/DELETE_LINE_ITEM`;
export const FETCH_PINNED_ORDERS = `${MODEL}/${ORDER}/FETCH_PINNED_ORDERS`;
export const CREATE_PRESCRIPTIONS = `${MODEL}/${ORDER}/CREATE_PRESCRIPTIONS`;
export const ADD_DELIVERY_ADDRESS = `${MODEL}/${ORDER}/ADD_DELIVERY_ADDRESS`;
export const UPDATE_DELIVERY_ADDRESS = `${MODEL}/${ORDER}/UPDATE_DELIVERY_ADDRESS`;
export const FETCH_LOGISTIC_PROVIDERS = `${MODEL}/${ORDER}/FETCH_LOGISTIC_PROVIDERS`;
export const FETCH_SHIPMENT = `${MODEL}/${ORDER}/FETCH_SHIPMENT`;
export const SHIPMENT_FETCH_RESPONDED = `${MODEL}/${ORDER}/SHIPMENT_FETCH_RESPONDED`;
export const FETCH_SHIPMENT_LABEL = `${MODEL}/${ORDER}/FETCH_SHIPMENT_LABEL`;
export const CREATE_LOGISTIC_PROVIDER_SHIPMENT = `${MODEL}/${ORDER}/CREATE_LOGISTIC_PROVIDER_SHIPMENT`;

export const LINE_ITEM_ADDED = `${MODEL}/${ORDER}/LINE_ITEM_ADDED`;
export const LINE_ITEM_UPDATED = `${MODEL}/${ORDER}/LINE_ITEM_UPDATED`;
export const LINE_ITEM_DELETED = `${MODEL}/${ORDER}/LINE_ITEM_DELETED`;
export const ORDER_FETCH_RESPONDED = `${MODEL}/${ORDER}/ORDER_FETCH_RESPONDED`;
export const PRESCRIPTIONS_CREATED = `${MODEL}/${ORDER}/PRESCRIPTIONS_CREATED`;
export const ORDER_UPDATE_RESPONDED = `${MODEL}/${ORDER}/ORDER_UPDATE_RESPONDED`;
export const DELIVERY_ADDRESS_ADDED = `${MODEL}/${ORDER}/DELIVERY_ADDRESS_ADDED`;
export const DELIVERY_ADDRESS_UPDATED = `${MODEL}/${ORDER}/DELIVERY_ADDRESS_UPDATED`;
export const ORDER_CREATION_RESPONDED = `${MODEL}/${ORDER}/ORDER_CREATION_RESPONDED`;
export const PINNED_ORDERS_FETCH_RESPONDED = `${MODEL}/${ORDER}/PINNED_ORDERS_FETCH_RESPONDED`;
export const LOGISTIC_PROVIDERS_RESPONDED = `${MODEL}/${ORDER}/LOGISTIC_PROVIDERS_RESPONDED`;
export const SHIPMENT_LABEL_RESPONDED = `${MODEL}/${ORDER}/SHIPMENT_LABEL_RESPONDED`;
export const LOGISTIC_PROVIDER_SHIPMENT_CREATION_RESPONDED = `${MODEL}/${ORDER}/LOGISTIC_PROVIDER_SHIPMENT_CREATION_RESPONDED`;

export const FETCH_ORDER_HISTORY = `${MODEL}/${ORDER}/FETCH_ORDER_HISTORY`;
export const ORDER_HISTORY_FETCH_RESPONDED = `${MODEL}/${ORDER}/ORDER_HISTORY_FETCH_RESPONDED`;

export const FETCH_LINE_ITEMS_HISTORY = `${MODEL}/${ORDER}/FETCH_LINE_ITEMS_HISTORY`;
export const LINE_ITEMS_HISTORY_FETCH_RESPONDED = `${MODEL}/${ORDER}/FETCH_LINE_ITEMS_HISTORY_FETCH_RESPONDED`;
