import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';

import { DISEASES } from 'domain/user/constants';
import { userGetters as getters } from 'domain/user';
import { Grid, Divider, Typography, DataGrid } from 'design-system';

const formatTitle = (title) => {
  return title?.replace(/_/g, ' ');
};

const PatientHealthInfo = ({
  user,
  isFetching,
  healthInfo,
  fetchHealthInfo,
  isFetchingHealth,
  takePassedHealthProfile,
}) => {
  const [rows, setRows] = useState([]);
  const [gender, setGender] = useState();
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [bloodType, setBloodType] = useState();
  // Don't show this section unless its data and consultation data are ready
  const [isLoading, setLoading] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [chronicConditions, setChronicConditions] = useState([]);

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      flex: 2,
      valueGetter: (params) => formatTitle(params.row.type),
    },
    {
      field: 'averageValue',
      headerName: 'Average',
      flex: 1,
    },
    /* {
      field: 'latestValue',
      headerName: 'Latest Value',
      flex: 1,
    }, */
    {
      field: 'maxValue',
      headerName: 'Maximum',
      flex: 1,
    },
    {
      field: 'minValue',
      headerName: 'Minimum',
      flex: 1,
    },
  ];

  useEffect(() => {
    if (!takePassedHealthProfile && !isEmpty(user)) {
      fetchHealthInfo(getters.getId(user));
    }
  }, [user, fetchHealthInfo, takePassedHealthProfile]);

  useEffect(() => {
    if (!takePassedHealthProfile && !isEmpty(user) && !isFetchingHealth) {
      setBloodType(healthInfo?.bloodType);
      setGender(healthInfo?.gender);
      setWeight(healthInfo?.weight);
      setHeight(healthInfo?.height);
      setChronicConditions(healthInfo?.diseases);
      setDateOfBirth(healthInfo?.dob);
      setLoading(isFetching || isFetchingHealth);
      setRows(healthInfo?.healthRecords || []);
    }
  }, [healthInfo, isFetching, isFetchingHealth, takePassedHealthProfile]);

  useEffect(() => {
    if (takePassedHealthProfile) {
      setLoading(isFetching || isFetchingHealth);
    }
  }, [isFetching, isFetchingHealth, takePassedHealthProfile]);

  useEffect(() => {
    if (takePassedHealthProfile && !isEmpty(healthInfo)) {
      setBloodType(healthInfo?.bloodType);
      setGender(healthInfo?.gender);
      setWeight(healthInfo?.weight);
      setHeight(healthInfo?.height);
      setChronicConditions(healthInfo?.diseases);
      setDateOfBirth(healthInfo?.dob);
      setRows(healthInfo?.healthRecords || []);
    }
  }, [healthInfo, takePassedHealthProfile]);

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        sx={{ minHeight: (theme) => theme.spacing(7), px: 2 }}
      >
        <MedicalServicesIcon
          color="standard"
          fontSize="extraSmall"
          sx={{ pr: 1 }}
        />

        <Typography
          variant="p3"
          sx={{ lineHeight: 1, marginRight: 2 }}
          loading={isLoading && { width: 50 }}
        >
          {(chronicConditions &&
            // Capital case
            `Chronic Conditions: ${chronicConditions
              .map((chronic) => DISEASES[chronic]?.label || chronic)
              .join(', ')}`) ||
            'No chronic conditions provided'}
        </Typography>
      </Grid>
      <Divider />

      <Grid
        container
        item
        alignItems="center"
        sx={{ minHeight: (theme) => theme.spacing(7), px: 2 }}
      >
        <AccessibilityNewIcon
          color="standard"
          fontSize="extraSmall"
          sx={{ pr: 1 }}
        />

        <Typography
          variant="p3"
          sx={{ lineHeight: 1, marginRight: 2 }}
          loading={isLoading && { width: 50 }}
        >
          Weight: {Math.round(weight * 100) / 100 || '-'}
        </Typography>

        <Typography
          variant="p3"
          sx={{ lineHeight: 1, marginRight: 2 }}
          loading={isLoading && { width: 50 }}
        >
          Height: {Math.round(height * 100) / 100 || '-'}
        </Typography>

        <Typography
          variant="p3"
          sx={{ lineHeight: 1 }}
          loading={isLoading && { width: 50 }}
        >
          Gender: {gender || '-'}
        </Typography>
      </Grid>
      <Divider />

      <Grid
        container
        item
        alignItems="center"
        sx={{ minHeight: (theme) => theme.spacing(7), px: 2 }}
      >
        <CalendarMonthIcon
          color="standard"
          fontSize="extraSmall"
          sx={{ pr: 1 }}
        />
        <Typography
          variant="p3"
          sx={{ lineHeight: 1, marginRight: 2 }}
          loading={isLoading && { width: 50 }}
        >
          Date of birth: {dateOfBirth?.split('T')[0] || '-'}
        </Typography>

        <BloodtypeIcon color="standard" fontSize="extraSmall" sx={{ pr: 1 }} />
        <Typography
          variant="p3"
          sx={{ lineHeight: 1, marginRight: 2 }}
          loading={isLoading && { width: 50 }}
        >
          Blood type: {bloodType || '-'}
        </Typography>
      </Grid>
      <Divider />
      <Grid>
        <Typography
          variant="h5"
          sx={{ margin: 2 }}
          loading={isFetchingHealth && { width: 140 }}
        >
          Health data
        </Typography>
        <DataGrid
          rows={rows}
          pageSize={8}
          columns={columns}
          loading={isFetchingHealth}
          getRowId={(row) => row.id}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              minHeight: '35px !important',
              maxHeight: '35px !important',
            },
            '& .MuiDataGrid-cell': {
              minHeight: '35px !important',
              maxHeight: '35px !important',
            },
            '& .MuiDataGrid-row': {
              minHeight: '35px !important',
              maxHeight: '35px !important',
            },
            '& .MuiDataGrid-virtualScrollerContent': {
              height: '248px !important',
            },
            '& .MuiDataGrid-virtualScroller': {
              marginTop: '39px !important',
            },
          }}
        />
      </Grid>
    </>
  );
};

export default PatientHealthInfo;
