import { compose } from 'redux';
import { connect } from 'react-redux';
import appData from 'domain/app/HOCs/appData';

import { userSelectors } from 'domain/user';
import {
  prescriptionSelectors,
  prescriptionActions,
} from 'domain/prescription';

import { labActions, labSelectors } from 'domain/lab';
import { doctorNotesActions, doctorNotesSelectors } from 'domain/doctorNotes';
import { outcomeActions, outcomeHOC } from 'domain/outcome';
import { memberUtils, memberSelectors } from 'domain/user/member';

import OutcomeManagementDialog from './OutcomeManagementDialog';

const mapStateToProps = (state) => {
  const owner = userSelectors.getUser(state);
  const ownerHealthProfile = userSelectors.getHealthInfo(state);
  const members = memberSelectors.getMembers(state) || [];
  const patientsUserInfoById = memberUtils.ownerAndMembersToObjectById(
    owner,
    members
  );

  const membersHealthProfiles =
    memberSelectors.getMembersHealthProfiles(state) || [];
  const patientsHealthProfilesByUserId =
    memberUtils.ownerAndMembersToObjectById(
      ownerHealthProfile,
      membersHealthProfiles,
      'userId'
    );

  const patientsLabRequestDocumentsById =
    labSelectors.getPatientsLabRequestDocuments(state);

  const getPatientPrescriptionDocumentById = (userId) =>
    prescriptionSelectors.getPatientPrescriptionDocumentById(state, userId);

  const getPatientPrescriptionById = (userId) =>
    prescriptionSelectors.getPatientPrescriptionById(state, userId);

  const isCreatingLabRequestDocument =
    labSelectors.isSubmittingLabRequest(state);

  const isCreatingPatientPrescriptionDocument =
    prescriptionSelectors.isCreatingPatientPrescriptionDocument(state);

  const isSubmittingDoctorNotes =
    doctorNotesSelectors.isSubmittingDoctorNotes(state);

  const isSubmittingELabTestRequests =
    labSelectors.isSubmittingPatientELabRequests(state);
  const isSubmittingAllPrescriptions =
    prescriptionSelectors.isSubmittingAllPrescriptions(state);

  const getPatientAssessmentSheetById = (userId) =>
    doctorNotesSelectors.getPatientAssessmentSheetById(state, userId);

  return {
    owner,
    patientsUserInfoById,
    isCreatingLabRequestDocument,
    isCreatingPatientPrescriptionDocument,
    isSubmittingDoctorNotes,
    getPatientPrescriptionDocumentById,
    getPatientPrescriptionById,
    patientsHealthProfilesByUserId,
    patientsLabRequestDocumentsById,
    getPatientAssessmentSheetById,
    isSubmittingAllPrescriptions,
    isSubmittingELabTestRequests,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDoctorNotesAndOutcomes: (userId, consultationId, callbacks) =>
    dispatch(
      outcomeActions.fetchDoctorNotesAndOutcomes(
        userId,
        consultationId,
        callbacks
      )
    ),
  clearDoctorNotesAndOutcomes: () => {
    dispatch(doctorNotesActions.clearDoctorNotes());
    dispatch(labActions.clearPatientsELabRequests());
    dispatch(prescriptionActions.clearPatientsPrescriptions());
  },
  createPatientLabRequestDocument: (payload, patientId, successCallback) =>
    dispatch(
      labActions.createPatientLabRequestDocument(
        payload,
        patientId,
        successCallback
      )
    ),
  createPatientPrescriptionDocument: (payload, userId, successCallback) =>
    dispatch(
      prescriptionActions.createPatientPrescriptionDocument(
        payload,
        userId,
        successCallback
      )
    ),
  createPatientAssessmentSheet: (payload, userId, successCallback) =>
    dispatch(
      doctorNotesActions.createPatientAssessmentSheet(
        payload,
        userId,
        successCallback
      )
    ),
  submitAllPrescriptions: (payload, consultationId) =>
    dispatch(
      prescriptionActions.submitAllPrescriptions(payload, consultationId)
    ),
  submitELabTestRequests: (consultationId, payload) =>
    dispatch(labActions.submitPatientELabRequests(consultationId, payload)),
});

export default compose(
  // Adds attachPatientDocuments method
  outcomeHOC,
  // Adds app-wide properties: clinics
  appData,
  connect(mapStateToProps, mapDispatchToProps)
)(OutcomeManagementDialog);
