import { required } from 'core/validation/fnValidations';

/**
 *
 * @param {Set} setB
 * @returns a new set that contains the intersection of this & setB
 */
Set.prototype.intersection = function (setA = required('intersection(setA)')) {
  // eslint-disable-next-line no-underscore-dangle
  const _intersection = new Set();

  // eslint-disable-next-line no-restricted-syntax
  for (const elem of setA) {
    if (this.has(elem)) {
      _intersection.add(elem);
    }
  }

  return _intersection;
};

/**
 *
 * @param {Set} setA
 * @returns a boolean whether this and setA have an intersection
 */
Set.prototype.intersectsWith = function (
  setA = required('intersectsWith(setA)')
) {
  return Boolean(this.intersection(setA)?.size);
};
