import { STATUSES } from './constants';

export const getKey = (type, label, defaultValue) => {
  if (!label) return defaultValue;

  return (
    Object.values(type).filter((value) => value?.label === label)[0]?.key ||
    defaultValue
  );
};

export const getLabel = (type, key, defaultValue) => {
  if (!key) return;

  return (
    Object.values(type).filter((value) => value?.key === key)[0]?.label ||
    defaultValue
  );
};

export const isNewStatus = (status) =>
  [STATUSES.NEW_ORDER.key, STATUSES.NEW_PRESCRIPTION.key].includes(status);
