/* Notification model constants */
export const PACKAGE_NAME = 'Notification';
export const ENDPOINT = '/notification';
export const BULK_ENDPOINT = `${ENDPOINT}/messages/bulk-send`;

export const FIELDS = {
  MESSAGE: {
    name: 'message',
  },
  SEND_PUSH_NOTIFICATION: {
    name: 'sendPushNotification',
  },
  USER_ID: {
    name: 'userId',
  },
  REFERENCE_ID: {
    name: 'referenceId',
  },
  REFERENCE_TYPE: {
    name: 'referenceType',
  },
};
