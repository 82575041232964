import { PACKAGE_NAME as LAB } from './constants';
import { PACKAGE_NAME as MODEL } from '../constants';

// Actions
export const FETCH_LAB_REQUEST_BY_ID = `${MODEL}/${LAB}/FETCH_LAB_REQUEST_BY_ID`;
export const CREATE_LAB_REQUEST = `${MODEL}/${LAB}/CREATE_LAB_REQUEST`;
export const CREATE_E_LAB_REQUEST_DOCUMENT = `${MODEL}/${LAB}/CREATE_E_LAB_REQUEST_DOCUMENT`;
export const UPDATE_LAB_REQUEST = `${MODEL}/${LAB}/UPDATE_LAB_REQUEST`;
export const PATCH_LAB_REQUEST = `${MODEL}/${LAB}/PATCH_LAB_REQUEST`;
export const FETCH_LAB_PACKAGES = `${MODEL}/${LAB}/FETCH_LAB_PACKAGES`;
export const GET_LAB_REQUEST_BY_CONSULTATION_ID = `${MODEL}/${LAB}/GET_LAB_REQUEST_BY_CONSULTATION_ID`;

// Actions: Fetch, create, update and create document of patient's elab request
export const FETCH_PATIENT_E_LAB_REQUEST = `${MODEL}/${LAB}/FETCH_PATIENT_E_LAB_REQUEST`;
export const CREATE_PATIENT_E_LAB_REQUEST = `${MODEL}/${LAB}/CREATE_PATIENT_E_LAB_REQUEST`;
export const UPDATE_PATIENT_E_LAB_REQUEST = `${MODEL}/${LAB}/UPDATE_PATIENT_E_LAB_REQUEST`;
export const DELETE_PATIENT_E_LAB_REQUEST = `${MODEL}/${LAB}/DELETE_PATIENT_E_LAB_REQUEST`;
export const SUBMIT_PATIENT_E_LAB_REQUESTS = `${MODEL}/${LAB}/SUBMIT_PATIENT_E_LAB_REQUESTS`;
export const CREATE_PATIENT_LAB_REQUEST_DOCUMENT = `${MODEL}/${LAB}/CREATE_PATIENT_LAB_REQUEST_DOCUMENT`;

// Responses
export const LAB_REQUEST_CREATION_RESPONDED = `${MODEL}/${LAB}/LAB_REQUEST_CREATION_RESPONDED`;
export const E_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED = `${MODEL}/${LAB}/E_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED`;
export const LAB_PACKAGES_RECEIVED = `${MODEL}/${LAB}/LAB_PACKAGES_RECEIVED`;
export const LAB_REQUEST_RECEIVED = `${MODEL}/${LAB}/LAB_REQUEST_RECEIVED`;
export const LAB_REQUEST_UPDATE_RESPONDED = `${MODEL}/${LAB}/LAB_REQUEST_UPDATE_RESPONDED`;
export const CONSULTATION_LABS_RECEIVED = `${MODEL}/${LAB}/CONSULTATION_LABS_RECEIVED`;

// Responses: Fetch, create, update and create document of patient's elab request
export const PATIENT_E_LAB_REQUEST_FETCH_RESPONDED = `${MODEL}/${LAB}/PATIENT_E_LAB_REQUEST_FETCH_RESPONDED`;
export const PATIENT_E_LAB_REQUEST_CREATION_RESPONDED = `${MODEL}/${LAB}/PATIENT_E_LAB_REQUEST_CREATION_RESPONDED`;
export const PATIENT_E_LAB_REQUEST_UPDATE_RESPONDED = `${MODEL}/${LAB}/PATIENT_E_LAB_REQUEST_UPDATE_RESPONDED`;
export const PATIENT_E_LAB_REQUEST_DELETE_RESPONDED = `${MODEL}/${LAB}/PATIENT_E_LAB_REQUEST_DELETE_RESPONDED`;
export const PATIENT_E_LAB_REQUESTS_SUBMISSION_RESPONDED = `${MODEL}/${LAB}/PATIENT_E_LAB_REQUESTS_SUBMISSION_RESPONDED`;
export const PATIENT_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED = `${MODEL}/${LAB}/PATIENT_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED`;

export const CREATE_PATIENTS_LAB_TEST_DOCUMENTS = `${MODEL}/${LAB}/CREATE_PATIENTS_LAB_TEST_DOCUMENTS`;
export const LAB_REQUEST_DOCUMENTS_UPDATE_RESPONDED = `${MODEL}/${LAB}/LAB_REQUEST_DOCUMENTS_UPDATE_RESPONDED`;
export const PATIENTS_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED = `${MODEL}/${LAB}/PATIENTS_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED`;
