import keys from 'lodash/keys';
import isArray from 'lodash/isArray';
import React, { useState, useEffect } from 'react';

import Box from '../Box';
import Select from '../Select';
import Divider from '../Divider';
import Checkbox from '../Checkbox';
import MenuItem from '../Menu/MenuItem';

const MultiSelectDropdown = ({
  values, // The values already selected as object
  footer, // Component at the end of select
  options, // All options for the select as object
  onChange = () => {},
  onClose = () => {},
  open,
  getters = {
    getValue: (value) => value,
    getId: (id) => id,
  },
  setIsFormChanged = () => {},
  renderItem = ({ value }) => {
    return value.concat(', ');
  },
  disabledOptions = [],
  color = 'standard',
  minWidth,
  sx,
  ...rest
}) => {
  const [selectedValues, setSelectedValues] = useState(keys(values));

  const handleChange = (event) => {
    const filteredArray = event.target.value.filter(Boolean);
    onChange(filteredArray);
    setSelectedValues(filteredArray);
    setIsFormChanged?.(true);
  };

  const handleClose = () => {
    onClose(selectedValues);
  };

  useEffect(() => {
    const valueKeys = isArray(values) ? values : keys(values);

    onChange(valueKeys);
    setSelectedValues(valueKeys);
  }, [values]);

  return (
    <Select
      title={selectedValues
        .map(
          (value) =>
            getters.getValue(options[value]) ||
            getters.getValue(values[value]) ||
            'N/A'
        )
        .join(', ')}
      multiple
      fullWidth
      open={open}
      soak="light"
      variant="filled"
      color={color}
      value={selectedValues}
      onChange={handleChange}
      onClose={handleClose}
      MenuProps={{
        PaperProps: {
          style: {
            minWidth,
          },
        },
      }}
      sx={{
        ...sx,
        height: 'max-content',
        '& .MuiSelect-select': {
          height: 'max-content',
        },
      }}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => {
            return renderItem({
              value:
                getters.getValue(options[value]) ||
                getters.getValue(values[value]) ||
                'N/A',
              key: value,
            });
          })}
        </Box>
      )}
      {...rest}
    >
      {keys(options).map((key) => {
        return (
          <MenuItem
            key={key}
            value={key}
            disabled={disabledOptions?.includes(key)}
          >
            <Checkbox size="small" checked={selectedValues.includes(key)} />
            {getters.getValue(options[key]) || 'N/A'}
          </MenuItem>
        );
      })}
      {footer && <Divider />}

      {footer}
    </Select>
  );
};

export default MultiSelectDropdown;
