import * as types from './actionTypes';

export const deactivateAccount = (id) => ({
  type: types.DEACTIVATE_ACCOUNT,
  id,
});

export const createAlmaosUser = (payload) => ({
  type: types.CREATE_ALMAOS_USER,
  payload,
});

export const setSubmittingAlmaosUser = (isSubmitting) => ({
  type: types.SET_SUBMITTING_ALMAOS_USER,
  isSubmitting,
});

export const setFetchingAlmaosUser = (isFetching) => ({
  type: types.SET_FETCHING_ALMAOS_USER,
  isFetching,
});

export const resetPassword = (userId) => ({
  type: types.RESET_PASSWORD,
  userId,
});

export const updateAlmaOsUser = (id, payload) => ({
  type: types.UPDATE_ALMAOS_USER,
  id,
  payload,
});

export const getAlmaOsUser = (id) => ({
  type: types.GET_ALMAOS_USER,
  id,
});

export const setAlmaosUser = (user) => ({
  type: types.ALMAOS_USER_RECEIVED,
  user,
});
