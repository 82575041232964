export const PACKAGE_NAME = 'DoctorNotes';

export const COMPLAINT_FIELDS = {
  ID: {
    key: 'id',
  },
  CHIEF_COMPLAINT: {
    key: 'chiefComplaint',
  },
  ONSET_PERIOD: {
    key: 'onsetPeriod',
    fields: {
      VALUE: {
        key: 'value',
        path: 'onsetPeriod.value',
      },
      UNIT: {
        key: 'unit',
        path: 'onsetPeriod.unit',
      },
    },
  },
  BODY_SITE: {
    key: 'bodySite',
  },
  SEVERITY: {
    key: 'severity',
  },
  REMARKS: {
    key: 'remarks',
  },
};

export const MANAGEMENT_AND_TREATMENT_PLAN_FIELDS = {
  ID: {
    key: 'id',
  },
  MANAGEMENT_PLAN: {
    key: 'managementPlan',
  },
  HEALTH_EDUCATION: {
    key: 'healthEducation',
  },
  FOLLOW_UP: {
    key: 'followUp',
  },
};

export const PATIENT_AND_FAMILY_HISTORY_FIELDS = {
  ID: {
    key: 'id',
  },
  LAST_TAKEN_MEDICATION: {
    key: 'lastTakenMedication',
  },
  IS_PREGNANT: {
    key: 'isPregnant',
  },
  REMARKS: {
    key: 'remarks',
  },
  NUTRITION_STATUS: {
    key: 'nutritionStatus',
  },
  PATIENT_USER_ID: {
    key: 'patientUserId',
  },
  CONSULTATION_ID: {
    key: 'consultationId',
  },
};

export const DOCTOR_PRIVATE_NOTES_FIELDS = {
  ID: {
    key: 'id',
  },
  OPTION: {
    key: 'option',
  },
  REMARKS: {
    key: 'remarks',
  },
};

export const REVIEW_STATUS_TYPES = {
  NORMAL: {
    key: 'NORMAL',
    label: 'Normal',
  },
  ABNORMAL: {
    key: 'ABNORMAL',
    label: 'Abnormal',
  },
};

export const PATIENT_EXAMINATIONS_FIELDS = {
  ID: {
    key: 'id',
  },
  STATUS: {
    key: 'status',
  },
  REMARKS: {
    key: 'remarks',
  },
  EXAMINATION: {
    key: 'examination',
  },
};

export const REVIEW_OF_SYSTEMS_FIELDS = {
  ID: {
    key: 'id',
  },
  PATIENT_EXAMINATIONS: {
    key: 'patientExaminations',
    fields: PATIENT_EXAMINATIONS_FIELDS,
  },
};

export const DIAGNOSIS_TYPES = {
  PRIMARY: {
    key: 'PRIMARY',
  },
  SECONDARY: {
    key: 'SECONDARY',
  },
};

export const DIAGNOSIS_FIELDS = {
  ID: {
    key: 'id',
  },
  NAME: {
    key: 'name',
  },
  CODE: {
    key: 'code',
  },
  TYPE: {
    key: 'type',
  },
};

export const FIELDS = {
  ID: {
    key: 'id',
  },
  USER_ID: {
    key: 'userId',
  },
  OWNER_ID: {
    key: 'ownerId',
  },
  CONSULTATION_ID: {
    key: 'consultationId',
  },
  COMPLAINT: {
    key: 'complaints',
    fields: COMPLAINT_FIELDS,
  },
  MANAGEMENT_AND_TREATMENT_PLAN: {
    key: 'managementTreatmentPlan',
    fields: MANAGEMENT_AND_TREATMENT_PLAN_FIELDS,
  },
  PATIENT_AND_FAMILY_HISTORY: {
    key: 'patientHistoryInformation',
    fields: PATIENT_AND_FAMILY_HISTORY_FIELDS,
  },
  DOCTOR_PRIVATE_NOTES: {
    key: 'privateNotes',
    fields: DOCTOR_PRIVATE_NOTES_FIELDS,
  },
  REVIEW_OF_SYSTEMS: {
    key: 'patientExaminationsReview',
    fields: REVIEW_OF_SYSTEMS_FIELDS,
  },
  DIAGNOSIS: {
    key: 'patientDiagnosis',
    fields: DIAGNOSIS_FIELDS,
  },
};

export const ALLERGIES_SECTION_FIELDS = {
  ID: {
    key: 'id',
  },
  TYPE: {
    key: 'type',
  },
  SEVERITY: {
    key: 'severity',
  },
  REMARKS: {
    key: 'remarks',
  },
  REACTIONS: {
    key: 'reactions',
  },
};

export const COMPLAINT_SEVERITY = {
  NO_PAIN: {
    key: 'NO_PAIN',
    label: 'No Pain',
    severityValues: [0, 1],
  },
  MILD: {
    key: 'MILD',
    label: 'Mild',
    severityValues: [2, 3],
  },
  MODERATE: {
    key: 'MODERATE',
    label: 'Moderate',
    severityValues: [4, 5],
  },
  SEVERE: {
    key: 'SEVERE',
    label: 'Severe',
    severityValues: [6, 7],
  },
  VERY_SEVERE: {
    key: 'VERY_SEVERE',
    label: 'Very Severe',
    severityValues: [8, 9],
  },
  WORST_PAIN_POSSBILE: {
    key: 'WORST_PAIN_POSSBILE',
    label: 'Worst Pain Possible',
    severityValues: [10],
  },
};

export const COMPLAINT_SEVERITY_BY_VALUE = [
  COMPLAINT_SEVERITY.NO_PAIN, // 0 -> No pain
  COMPLAINT_SEVERITY.NO_PAIN, // 1 -> No pain
  COMPLAINT_SEVERITY.MILD, // 2 -> Mild, and so on
  COMPLAINT_SEVERITY.MILD,
  COMPLAINT_SEVERITY.MODERATE,
  COMPLAINT_SEVERITY.MODERATE,
  COMPLAINT_SEVERITY.SEVERE,
  COMPLAINT_SEVERITY.SEVERE,
  COMPLAINT_SEVERITY.VERY_SEVERE,
  COMPLAINT_SEVERITY.VERY_SEVERE,
  COMPLAINT_SEVERITY.WORST_PAIN_POSSBILE, // 10 -> Worst pain possible
];
