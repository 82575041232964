import React, { useState, useEffect } from 'react';

import { labModelConstants } from 'model/lab';
import { getAppConfigEndpoint } from 'core/endpoint';
import ServerAutocomplete from 'core/serverAutocomplete';
import { Tooltip, TextField, Chip } from 'design-system';

const { CUSTOM_LAB_TESTS_LIST_ENDPOINT, LAB_CPT_LIST_ENDPOINT } =
  labModelConstants;

const LabTestsAutocomplete = ({
  error,
  loading,
  defaultValue,
  size = 'large',
  multiple = true,
  variant = 'filled',
  onChange = () => {},
  endpointV2 = true,
  getTagLabel = (option) => option.code,
  ...rest
}) => {
  const [value, setValue] = useState(multiple ? [] : null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ServerAutocomplete
      multiple={multiple}
      fullWidth
      size={size}
      defaultValue={value}
      baseURL={getAppConfigEndpoint()}
      endpoint={
        endpointV2 ? LAB_CPT_LIST_ENDPOINT : CUSTOM_LAB_TESTS_LIST_ENDPOINT
      }
      getOptionLabel={(option) => {
        if (option) {
          if (endpointV2) {
            return `${option.code} - ${option.name}`;
          }

          return option.name;
        }

        return '';
      }}
      optionsEqual={(option1, option2) => {
        if (endpointV2) {
          return option1.code === option2.code;
        }

        return option1.name === option2.name;
      }}
      getRequestParams={(searchQuery) => {
        const urlSearchParams = new URLSearchParams();

        urlSearchParams.append('term', searchQuery);

        return urlSearchParams;
      }}
      throttle={(fn, searchQuery) => {
        if (searchQuery.length >= (endpointV2 ? 3 : 2)) {
          fn();
        }
      }}
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size={size}
          variant={variant}
          loading={loading}
          placeholder={endpointV2 ? 'Ex: 80050, or Blood count...' : 'Ex: CBC'}
          error={Boolean(error)}
          helperText={error?.message ?? null}
          sx={{
            '& .MuiInputBase-root': {
              height: 'auto',
            },
          }}
        />
      )}
      renderTags={(val, getTagProps) => {
        return val.map((option, index) => {
          const tagProps = getTagProps({ index });
          const label = getTagLabel(option);
          const slicedLabel = label.slice(0, 50);

          return (
            <Chip
              size={size}
              color="primary"
              label={
                <Tooltip title={label} placeholder="top">
                  {slicedLabel}
                  {label.length > slicedLabel.length ? '...' : ''}
                </Tooltip>
              }
              onClick={tagProps?.onDelete}
              {...tagProps}
            />
          );
        });
      }}
      {...rest}
    />
  );
};

export default LabTestsAutocomplete;
