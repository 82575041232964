import React from 'react';

import Button from '../Button';
import Dialog, { DialogTitle, DialogContent, DialogActions } from '../Dialog';

const Popup = ({
  onOk,
  title,
  children,
  onCancel,
  primaryButtonTitle = 'Ok',
  secondaryButtonTitle = 'Cancel',
  primaryButtonProps = {},
  secondaryButtonProps = {},
  ...rest
}) => {
  return (
    <Dialog
      {...rest}
      onClose={(_event, reason) => {
        if (['escapeKeyDown', 'backdropClick'].includes(reason)) {
          return;
        }

        onCancel();
      }}
      disableEscapeKeyDown
      aria-labelledby="popup-title"
      aria-describedby="popup-description"
    >
      {title && (
        <DialogTitle id="popup-title" sx={{ px: 3 }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions
        sx={{
          pt: 0,
          px: 3,
          pb: 2.5,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          color="error"
          {...secondaryButtonProps}
          onClick={onCancel}
        >
          {secondaryButtonTitle}
        </Button>
        <Button
          size="small"
          variant="filled"
          {...primaryButtonProps}
          onClick={onOk}
        >
          {primaryButtonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
