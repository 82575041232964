import { all, put, takeLatest, take, delay } from 'redux-saga/effects';

import { Message } from 'design-system';
import { cacheUtils } from 'core/cache';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';
import {
  subscriptionModelActionTypes,
  subscriptionModelActions,
} from 'model/subscription';

import * as actions from './actions';
import * as types from './actionTypes';

export function* updateSubscription({ id, payload }) {
  yield put(actions.setSubmitting(true));
  yield put(subscriptionModelActions.updateSubscription(id, payload));
}

export function* cancelSubscription({ userId }) {
  yield put(actions.setSubmitting(true));
  yield put(subscriptionModelActions.cancelSubscription(userId));
  const { ok } = yield take(
    subscriptionModelActionTypes.SUBSCRIPTION_CANCEL_RESPONDED
  );
  if (ok) {
    Message.info('Subscription cancelled successfully');
    // The delay here is needed in order to grantee that the payment link is generated
    yield delay(5000);
    yield put(subscriptionModelActions.getSubscription(userId));
    yield put(actions.subscriptionCreationResponded(true));
  }
  yield put(actions.setSubmitting(false));
}

export function* subscriptionUpdateResponded({ ok, response }) {
  if (ok) {
    yield put(actions.subscriptionReceived(response));

    // Why settimout? When we change the route using location.href,
    // the context changes and hides the Message, unless we add it to event loop
    // Only then, it will be executed after the routing is done
    setTimeout(() => {
      Message.success('Subscription has been updated successfully');
    }, 0);
  }

  yield put(actions.setSubmitting(false));
}

export function* getSubscription({ userId }) {
  yield put(actions.setFetching(true));
  yield put(subscriptionModelActions.getSubscription(userId));
}

export function* subscriptionReceived({ subscription }) {
  yield put(actions.subscriptionReceived(subscription));
  yield put(actions.setFetching(false));
}

export function* createSubscription({ userId, payload }) {
  yield put(actions.setSubmitting(true));
  yield put(subscriptionModelActions.createSubscription(userId, payload));
  const { ok } = yield take(
    subscriptionModelActionTypes.SUBSCRIPTION_CREATION_RESPONDED
  );
  if (ok) {
    Message.success('Subscription created successfully');
    // TODO: revisit this
    // The delay here is needed in order to grantee that the payment link is generated
    yield delay(5000);
    yield put(subscriptionModelActions.getSubscription(userId));
    yield put(actions.subscriptionCreationResponded(true));
  }
  yield put(actions.setSubmitting(false));
}

export function* fetchSubscriptionPlans() {
  const cachedSubscriptionPlans = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.SUBSCRIPTION_PLANS
  );

  if (cachedSubscriptionPlans) {
    yield put(actions.subscriptionPlansReceived(cachedSubscriptionPlans));
  } else {
    yield put(actions.setFetchingSubscriptionPlans(true));
    yield put(subscriptionModelActions.fetchSubscriptionPlans());
  }
}

export function* subscriptionPlansReceived({ subscriptionPlans }) {
  cacheUtils.setCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.SUBSCRIPTION_PLANS,
    subscriptionPlans
  );
  yield put(actions.subscriptionPlansReceived(subscriptionPlans));
  yield put(actions.setFetchingSubscriptionPlans(false));
}

export default function* subscriptionSaga() {
  yield all([
    takeLatest(types.GET_SUBSCRIPTION, getSubscription),
    takeLatest(types.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeLatest(types.UPDATE_SUBSCRIPTION, updateSubscription),
    takeLatest(
      subscriptionModelActionTypes.SUBSCRIPTION_UPDATE_RESPONDED,
      subscriptionUpdateResponded
    ),
    takeLatest(
      subscriptionModelActionTypes.SUBSCRIPTION_RECEIVED,
      subscriptionReceived
    ),
    takeLatest(types.CREATE_SUBSCRIPTION, createSubscription),
    takeLatest(types.FETCH_SUBSCRIPTION_PLANS, fetchSubscriptionPlans),
    takeLatest(
      subscriptionModelActionTypes.SUBSCRIPTION_PLANS_RECEIVED,
      subscriptionPlansReceived
    ),
  ]);
}
