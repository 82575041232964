import * as types from './actionTypes';

export const fetchDoctorNotesAndOutcomes = (
  userId,
  consultationId,
  callbacks
) => ({
  type: types.FETCH_DOCTOR_NOTES_AND_OUTCOMES,
  userId,
  callbacks,
  consultationId,
});
