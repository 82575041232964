import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';

import { Grid, CircularProgress } from 'design-system';

import BookingAvailability from '../BookingAvailability';

const BookingSchedule = ({ doctors }) => {
  const [doctor, setDoctor] = useState();

  useEffect(() => {
    if (!isEmpty(doctors) && !doctor) {
      setDoctor(doctors[0]);
    }
  }, [doctors]);

  if (!doctor) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress size={100} />
        </Grid>
      </Grid>
    );
  }

  return (
    <BookingAvailability
      isPage
      doctor={doctor}
      endTime={new Date()}
      startTime={new Date()}
      onConfirm={(_, selectedDoctor) => {
        setDoctor(selectedDoctor);
      }}
    />
  );
};

export default BookingSchedule;
