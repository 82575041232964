import { required } from 'core/validation/fnValidations';

/**
 * Circle: loops over an array in a circular way.
 * It starts processing from a given index {startIdx},
 * and ends up right before the given index.
 * Say you have an array of 4 elements,
 * and you want to circle start from 2,
 * here are the indices that will be processed in order: 2, 3, 0, 1
 *
 * @param {Integer} startIdx
 * @param {Function(any, Integer)} callback
 */
Array.prototype.circle = function (
  startIdx = required('circle(startIdx, )'),
  callback = required('circle(, callback)')
) {
  for (
    let i = 0, idx = startIdx;
    i < this.length;
    i += 1, idx = (startIdx + i) % this.length
  ) {
    callback(this[idx], idx, i);
  }
};

/**
 * Same as circle, but mutates the array elements
 *
 * @param {Integer} startIdx
 * @param {Function(any, Integer)} callback
 * @returns a new mapped array
 */
Array.prototype.circleMap = function (
  startIdx = required('circle(startIdx, )'),
  callback = required('circle(, callback)')
) {
  const updatedArray = [];

  for (
    let i = 0, idx = startIdx;
    i < this.length;
    i += 1, idx = (startIdx + i) % this.length
  ) {
    const updatedElement = callback(this[idx], idx, i);

    updatedArray[i] = updatedElement;
  }

  return updatedArray;
};

/**
 * Pushes an element to an array, while keeping the array sorted.
 * By default, it assumes that numbers are passed,
 * but you can define the following:
 *  -Value getter: If the elements of the array are objects,
 *    you want to say what will be the value you want to sort on.
 *    E.g: slot.startTime
 *  -Compare: Determines where the elements is added.
 *    E.g: new Date(a) >= new Date(b)
 *
 * @param {any} elm
 * @param {Function(any) => any}
 * @param {Function(any, any) => Number}
 */
Array.prototype.pushSorted = function (
  elm = required('pushSorted(elm)'),
  valueGetter = (el) => el,
  // "a" is an item of the array, "b" is the passed element
  compare = (a, b) => a - b
) {
  let idx = 0;

  const found = this.some((item, i) => {
    if (compare(valueGetter(item), valueGetter(elm))) {
      idx = i;
      return true;
    }

    return false;
  });

  if (this.length && !found) {
    idx = this.length;
  }

  this.splice(idx, 0, elm);
};

/**
 *
 * @returns The last element in the array
 */
Array.prototype.last = function () {
  const lastIndex = this.length ? this.length - 1 : 0;

  return this[lastIndex];
};

/**
 * Returns the index of each element that results in callback(elm, idx) == true
 *
 * @param {Function (element, index)} callback
 * @returns Number array
 */
Array.prototype.findIndices = function (callback) {
  const indices = [];

  this.forEach((elm, idx) => {
    if (callback(elm, idx)) {
      indices.push(idx);
    }
  });

  return indices;
};
