export const PACKAGE_NAME = 'AlmaAdmin';

export const REFERENCE_TYPES = {
  LAB_TEST_REQUEST: 'LabTestRequest',
  LAB_TEST_RESULTS: 'LabTestResults',
  CONSULTATION: 'Consultation', // Strict for server-side
  PRESCRIPTIONS: 'Prescriptions',
  EPRESCRIPTION: 'EPrescription',
  ORDER: 'Order',
  USER: 'User',
  LEAD_USER: 'LeadUser',
  IDENTITY_BACK: 'IdentityBack',
  IDENTITY_FRONT: 'IdentityFront',
  INSURANCE_CARD: 'InsuranceCard',
  LAB: 'Lab',
  CONSULTATION_PAYMENT: 'consultation',
};

export const YES_NO = {
  YES: {
    key: 'YES',
    label: 'Yes',
  },
  NO: {
    key: 'NO',
    label: 'No',
  },
};
