import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import formatISO from 'date-fns/formatISO';
import EventIcon from '@mui/icons-material/Event';
import React, { useState, useEffect } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

import { dateFormat } from 'constants/dateTime';
import { Form, Controller } from 'design-system/form';
import {
  followUpReminderGetters as getters,
  followUpReminderConstants as constants,
} from 'domain/followUpReminder';
import {
  List,
  Grid,
  Avatar,
  Dialog,
  Button,
  Tooltip,
  ListItem,
  FormField,
  TextField,
  DatePicker,
  DialogTitle,
  ListItemText,
  DialogContent,
  DialogActions,
  ListItemAvatar,
} from 'design-system';

const { FIELDS } = constants;

const FollowUpRemindersManagement = ({
  loading,
  followUpReminders = [],
  fetchFollowUpReminders,
  createFollowUpReminder,
}) => {
  const [isOpen, setOpen] = useState(false);

  const onClosed = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchFollowUpReminders();
  }, [fetchFollowUpReminders]);

  return (
    <>
      <Tooltip
        placement="top"
        injectWrapper
        title="Manage team follow-up reminders (only internaly not with patient)"
      >
        <Button
          loading={loading}
          size="medium"
          color="primary"
          variant="text"
          startIcon={<EventIcon color="secondary" />}
          onClick={() => setOpen(true)}
        >
          Manage follow-up reminders
        </Button>
      </Tooltip>
      <Dialog
        fullWidth
        open={isOpen}
        maxWidth="md"
        onClose={onClosed}
        aria-labelledby="follow-up-dialog"
      >
        <DialogTitle id="follow-up-dialog" onClose={onClosed}>
          Follow Up Reminders
        </DialogTitle>
        <DialogContent dividers>
          <Grid container column rowSpacing={3}>
            {!isEmpty(followUpReminders) && (
              <Grid item>
                <List sx={{ maxHeight: 300, overflowY: 'auto' }}>
                  {followUpReminders.map((reminder, index) => (
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            bgcolor: (theme) =>
                              index % 2
                                ? theme.palette.secondary.light
                                : theme.palette.primary.light,
                          }}
                        >
                          <NotificationsNoneIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          getters.getComment(reminder) ||
                          '<i>No comments available</i>'
                        }
                        secondary={
                          <>
                            <strong>Remember on</strong>{' '}
                            {format(
                              new Date(getters.getReminderDate(reminder)),
                              dateFormat
                            )}
                          </>
                        }
                        primaryTypographyProps={{
                          fontSize: '1.2rem',
                        }}
                        secondaryTypographyProps={{
                          fontSize: '1rem',
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            )}
            <Grid item>
              <Form
                resetOnSubmit
                onSubmit={(data) => {
                  const date = new Date(data[FIELDS.REMINDER_DATE.name]);
                  const updatedData = {
                    ...data,
                    [FIELDS.REMINDER_DATE.name]: formatISO(date).split('T')[0],
                    // 2022-09-07
                  };

                  createFollowUpReminder(updatedData);
                }}
              >
                {({ control }) => (
                  <Grid column container rowSpacing={2}>
                    <Grid item>
                      <FormField
                        label="REMINDER DATE"
                        required
                        field={
                          <Controller
                            name="reminderDate"
                            control={control}
                            valueAsDate
                            defaultValue={null}
                            rules={{
                              required: 'Reminder date is required',
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <DatePicker
                                name="reminderDate"
                                autoComplete="false"
                                value={value}
                                onChange={onChange}
                                inputFormat={dateFormat}
                                renderInput={(props) => (
                                  <TextField
                                    fullWidth
                                    size="medium"
                                    variant="filled"
                                    helperText={error?.message ?? null}
                                    {...props}
                                    error={Boolean(error)}
                                  />
                                )}
                              />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item>
                      <FormField
                        label="COMMENT"
                        required
                        field={
                          <Controller
                            name="comment"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: 'Comment is required',
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <TextField
                                name="comment"
                                autoComplete="false"
                                rows={2}
                                fullWidth
                                multiline
                                value={value}
                                size="medium"
                                variant="filled"
                                onChange={onChange}
                                error={Boolean(error)}
                                placeholder="Add a comment..."
                                helperText={error?.message ?? null}
                              />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item sx={{ width: '100%' }}>
                      <Button
                        type="submit"
                        size="medium"
                        color="primary"
                        variant="contained"
                        sx={{ width: '100%' }}
                      >
                        Add a follow up reminder
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="flex-end">
            <Grid item sx={{ pr: 2 }}>
              <Button variant="text" color="primary" onClick={onClosed}>
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FollowUpRemindersManagement;
