import { connect } from 'react-redux';

import {
  consultationTypeActions as actions,
  consultationTypeSelectors as selectors,
} from 'domain/consultationType';

import NewConsultationType from './NewConsultationType';

const mapStateToProps = (state) => {
  const isSubmitting = selectors.isSubmitting(state);

  return {
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createConsultationType: (payload) =>
    dispatch(actions.createConsultationType(payload)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewConsultationType);
