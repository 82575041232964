import React, { useRef, useState, useEffect } from 'react';

import { orderGetters } from 'domain/order';
import { Form, Controller } from 'design-system/form';
import { REFERENCE_TYPES } from 'constants/general';
import {
  Grid,
  Button,
  Dialog,
  Switch,
  Divider,
  Tooltip,
  FormField,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
} from 'design-system';

const OrderPaymentAmountDialog = ({
  open,
  order,
  onClose,
  onSubmit,
  createPaymentTransaction,
}) => {
  const formRef = useRef();

  const [price, setPrice] = useState();
  const [formError, setFormError] = useState();
  const [patientShare, setPatientShare] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isAutoNotification, setAutoNotification] = useState(true);

  useEffect(() => {
    if (order) {
      setPrice(orderGetters.getTotalPrice(order));
      setPatientShare(orderGetters.getTotalPatientShare(order));
    }
  }, [order]);

  const doClose = () => {
    setFormError('');
    setSubmitting(false);
    setAutoNotification(true);
    onClose();
  };

  const onSubmitted = (data) => {
    setSubmitting(true);

    const payload = {
      referenceId: orderGetters.getId(order),
      referenceType: REFERENCE_TYPES.ORDER,
      isAutoNotification,
      isFullAmount: data.isFullAmount,
      isPatientShare: data.isPatientShare,
      dueAmount: data.dueAmount
        ? {
            amount: Number(data.dueAmount),
            currency: price?.currency,
          }
        : {},
    };

    createPaymentTransaction(payload, () => {
      onSubmit(payload);
      setSubmitting(false);
      doClose();
    });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={doClose}
      aria-labelledby="order-payment-amount-dialog"
    >
      <DialogTitle id="order-payment-amount-dialog" onClose={doClose}>
        Create Payment Link
      </DialogTitle>
      <DialogContent dividers>
        <Form ref={formRef} onSubmit={onSubmitted}>
          {({ control, setValue, resetField }) => (
            <Grid container column rowSpacing={2}>
              <FormField
                label="DUE AMOUNT"
                field={
                  <Controller
                    name="dueAmount"
                    control={control}
                    defaultValue=""
                    valueAsNumber
                    rules={{
                      min: {
                        value: 0.01,
                        message: 'Only positive numbers',
                      },
                      max: {
                        value: Number(price?.value ?? 0),
                        message:
                          'Due amount cannot be more than the total price',
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        fullWidth
                        size="large"
                        value={value}
                        type="number"
                        onChange={(e) => {
                          onChange(e);
                          setValue('isFullAmount', false);
                          setValue('isPatientShare', false);
                        }}
                        error={Boolean(error)}
                        placeholder="Due amount.."
                        helperText={error?.message ?? null}
                      />
                    )}
                  />
                }
              />
              <Grid item>
                <Divider>
                  <Typography variant="p4">OR</Typography>
                </Divider>
              </Grid>
              <Grid item>
                <Controller
                  name="isPatientShare"
                  control={control}
                  defaultValue
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      label={`Patient will pay patient share (${
                        patientShare?.currency || ''
                      } ${(patientShare?.value || 0).toFixed(2)})`}
                      color="primary"
                      checked={value}
                      onChange={(event, newValue) => {
                        onChange(event, newValue);
                        setValue('isFullAmount', false);
                        resetField('dueAmount');
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Divider>
                  <Typography variant="p4">OR</Typography>
                </Divider>
              </Grid>
              <Grid item>
                <Controller
                  name="isFullAmount"
                  control={control}
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      label={`Patient will pay total price (${
                        price?.currency || ''
                      } ${(price?.value || 0).toFixed(2)})`}
                      color="secondary"
                      checked={value}
                      onChange={(event, newValue) => {
                        onChange(event, newValue);
                        setValue('isPatientShare', false);
                        resetField('dueAmount');
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          columnSpacing={3}
          alignItems="center"
          justifyContent="flex-end"
          sx={{ px: 1 }}
        >
          <Grid item>
            {formError && <FormHelperText error>{formError}</FormHelperText>}
          </Grid>
          <Grid item container ml={1} mt={2} justifyContent="space-between">
            <Tooltip
              placement="top"
              title="If checked, an SMS and push notification will be sent automatically to the patient "
              injectWrapper
            >
              <Switch
                label="Send payment message to patient"
                onChange={(e) => setAutoNotification(e.target.checked)}
                checked={isAutoNotification}
              />
            </Tooltip>
            <Button
              variant="filled"
              spinning={isSubmitting}
              onClick={() => {
                const values = formRef.current.getMethods().getValues();

                if (
                  !values.dueAmount &&
                  !values.isPatientShare &&
                  !values.isFullAmount
                ) {
                  setFormError('One of the options above should be fulfilled');
                } else if (
                  (values?.isPatientShare && patientShare?.value <= 0) ||
                  (values?.isFullAmount && price?.value <= 0)
                ) {
                  setFormError(
                    'The amount of the selected option is zero, please select another option'
                  );
                } else {
                  setFormError('');
                  formRef.current.submit();
                }
              }}
            >
              Create payment link
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default OrderPaymentAmountDialog;
