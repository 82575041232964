import isEmpty from 'lodash/isEmpty';
import React, { useRef, useLayoutEffect } from 'react';

import { documentUtils } from 'core/utils';

import Grid from '../Grid';
import { useDeviceSize } from '../../hooks';

/**
 * 1. Layouts the passed children into a row of equal widths.
 * 2. Children marked as heightFollower have no effect on the overall height. They take the height of other siblings. How?
 *  In CSS, if siblings are in a flex box, they should take the same height (or width for column direction).
 *  If one grows in height, the others grow too.
 *  In order for heightFollowers to take the same height, they should have the first level child to be position: relative,
 *   and the second level child to be position: absolute. This way, it's like it got detached from the flex box.
 *
 *   |------------------------|
 *   |   position: relative   |
 *   |                        |
 *   | |--------------------| |
 *   | | position: absolute | |
 *   | |                    | |
 *   | |____________________| |
 *   |________________________|
 *
 */
const RowLayout = ({ children, followerMinHeight = 500, ...rest }) => {
  const ref = useRef();
  const { isMobile, isTablet } = useDeviceSize();

  useLayoutEffect(() => {
    // Save the indices of children with heightFollower
    const followersIndices = children.reduce((indices, child, idx) => {
      if (child?.props?.heightFollower) {
        return indices.concat([idx]);
      }

      return indices;
    }, []);

    if (!(isMobile || isTablet)) {
      // Reset children's styles so that they don't affect the process
      children.forEach((_child, childrenIdx) => {
        // The child added as a wrapper by RowLayout
        const childWrapper = ref.current.children[0].children[childrenIdx];
        // The actual child added from outside
        const child = childWrapper?.children?.[0];

        if (child?.hasAttribute('data-stashed-style')) {
          const style = child.getAttribute('data-stashed-style') || {};

          child.removeAttribute('data-stashed-style');
          child.removeAttribute('style');

          documentUtils.addStyle(child, JSON.parse(style));
        }
      });

      if (!isEmpty(followersIndices)) {
        /**
         * Purpose:
         *
         * In order for the heightFollower children to maintain an equal height,
         * they should have the first level child to be position: relative,
         * and the second level child to be position: absolute with height and width 100%.
         *
         * This way, the child wrapper will have the same height as its siblings,
         * and the child will have height of the wrapper
         */
        followersIndices.forEach((followerIdx) => {
          // The child added as a wrapper by RowLayout
          const childWrapper = ref.current.children[0].children[followerIdx];
          // The actual child added from outside
          const child = childWrapper?.children?.[0];
          // The style as an object
          const styleObject = documentUtils.getStyleObject(child);
          // Get padding details
          const [pt, pr, pb, pl] =
            getComputedStyle(childWrapper)
              ?.padding?.split(' ')
              ?.map((item) => item.match(/\d+/).join(''))
              ?.map(Number) || [];

          if (!isEmpty(styleObject)) {
            // Maintain the styles before adding new ones
            child.setAttribute(
              'data-stashed-style',
              JSON.stringify(styleObject)
            );
          }

          // Add new styles
          documentUtils.addStyle(child, {
            ...styleObject,
            maxWidth: '100%',
            maxHeight: '100%',
            position: 'absolute',
            width: `calc(100% - ${pr + pl}px)`,
            height: `calc(100% - ${pt + pb}px)`,
          });
        });
      }
    }
  }, [isMobile, isTablet]);

  return (
    <div ref={ref}>
      <Grid container {...rest}>
        {children.map((child) => {
          return (
            <Grid
              key={child?.key || null}
              item
              sx={{
                width: {
                  xs: '100%',
                  lg: 'auto',
                },
                flex: {
                  xs: 'auto',
                  lg: 1,
                },
                '& > *': {
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                },
                ...(() => {
                  if (child?.props?.heightFollower) {
                    if (isMobile || isTablet) {
                      // Height follower children in mobile or tablet have a fixed height of {followerMinHeight}px
                      return {
                        height: followerMinHeight,
                      };
                    }

                    // Height follower children have position relative
                    // (To accommodate to the height of siblings without affecting the overall height)
                    return {
                      position: 'relative',
                    };
                  }

                  return {};
                })(),
              }}
            >
              {child}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default RowLayout;
