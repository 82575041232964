import React, { useState, useEffect } from 'react';
import {
  Grid,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DisplayField,
  Divider,
} from 'design-system';
import format from 'date-fns/format';
import { dateTimeFormat } from 'constants/dateTime';
import parseISO from 'date-fns/parseISO';

function EligibilitySummary({ open, onClose, eligibility }) {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="eligibility-summary-dialog"
    >
      <DialogTitle id="eligibility-summary-title" onClose={handleClose}>
        Eligibility
      </DialogTitle>
      <DialogContent dividers>
        <Grid item sx={{ mb: 0.5 }}>
          <Grid sx={{ mt: 0.5 }}>
            <Typography
              variant="p4"
              textTransform="uppercase"
              sx={{
                lineHeight: 1,
                color: (theme) => theme.palette.grey[600],
              }}
            >
              First name
            </Typography>
            <DisplayField
              value={eligibility.fullName || 'N/A'}
              labelWidth={60}
              valueWidth={200}
            />
            <Divider />
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Typography
              variant="p4"
              textTransform="uppercase"
              sx={{
                lineHeight: 1,
                color: (theme) => theme.palette.grey[600],
              }}
            >
              identity number
            </Typography>
            <DisplayField
              value={eligibility.identityNumber}
              labelWidth={60}
              valueWidth={200}
            />
            <Divider />
          </Grid>
          <Grid item sx={{ mt: 0.5 }}>
            <Typography
              variant="p4"
              textTransform="uppercase"
              sx={{
                lineHeight: 1,
                color: (theme) => theme.palette.grey[600],
              }}
            >
              status
            </Typography>
            <DisplayField
              value={eligibility.status}
              labelWidth={60}
              valueWidth={200}
            />
            <Divider />
          </Grid>
          {eligibility?.authorizationEndDate && (
            <Grid item sx={{ mt: 0.5 }}>
              <Typography
                variant="p4"
                textTransform="uppercase"
                sx={{
                  lineHeight: 1,
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                authorization end date
              </Typography>
              <DisplayField
                value={format(
                  parseISO(eligibility?.authorizationEndDate),
                  dateTimeFormat
                )}
                labelWidth={60}
                valueWidth={200}
              />
            </Grid>
          )}

          {eligibility?.notEligibleReason && (
            <Grid item sx={{ mt: 0.5 }}>
              <Divider />
              <Typography
                variant="p4"
                textTransform="uppercase"
                sx={{
                  lineHeight: 1,
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                not eligible reason
              </Typography>
              {eligibility?.notEligibleReason &&
                eligibility?.notEligibleReason.map((message) => (
                  <Grid item sx={{ mt: 0.5 }}>
                    <DisplayField
                      value={message}
                      style={{ whiteSpace: 'pre-line' }}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
          {eligibility?.eligibilitySummary && (
            <Grid item sx={{ mt: 0.5 }}>
              <Divider />
              <Typography
                variant="p4"
                textTransform="uppercase"
                sx={{
                  lineHeight: 1,
                  color: (theme) => theme.palette.grey[600],
                }}
              >
                more info
              </Typography>
              {eligibility?.eligibilitySummary &&
                eligibility?.eligibilitySummary.map((message) => (
                  <Grid
                    item
                    sx={{ mt: 0.5 }}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    <DisplayField value={message} />
                  </Grid>
                ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EligibilitySummary;
