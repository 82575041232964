/* Tenant model constants */
export const PACKAGE_NAME = 'Tenant';
export const TENANT_ENDPOINT = '/tenant';
export const CITIES_ENDPOINT = '/cities';

export const LIST_ENDPOINT = 'list';
export const FIELDS = {
  TENANT_ID: {
    name: 'id',
  },
  TENANT_NAME: {
    name: 'name',
  },
};

export const CITY_FIELDS = {
  CITY_ID: {
    name: 'id',
  },
  NAME: {
    name: 'name',
  },
  TENANT_ID: {
    name: 'tenantId',
  },
};
