import React from 'react';

import { Box, TextField, Autocomplete } from 'design-system';

import { COUNTRIES } from '../constants';

const CountrySelect = ({
  onBlur,
  onChange,
  autoFocus,
  value = '',
  placeholder,
  error = false,
  size = 'medium',
  loading = false,
  variant = 'outlined',
  ...rest
}) => {
  return (
    <Autocomplete
      {...rest}
      size={size}
      error={error}
      value={value}
      options={COUNTRIES}
      loading={loading}
      onChange={onChange}
      getOptionLabel={(option) => option?.label || ''}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size={size}
          error={error}
          onBlur={onBlur}
          loading={loading}
          variant={variant}
          autoFocus={autoFocus}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
