export const PACKAGE_NAME = 'FollowUpReminder';

export const FIELDS = {
  REMINDER_DATE: {
    name: 'reminderDate',
  },
  COMMENT: {
    name: 'comment',
  },
  REFERENCE_ID: {
    name: 'referenceId',
  },
  REFERENCE_TYPE: {
    name: 'referenceType',
  },
  TENANT_KEY: {
    name: 'tenantKey',
  },
  NAMESPACE_KEY: {
    name: 'namespaceKey',
  },
};
