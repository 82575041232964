/* Lab domain constants */
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { dateTimeFormat } from 'constants/dateTime';

export const PACKAGE_NAME = 'Lab';

const displayDate = (date) => {
  return date ? format(parseISO(date), dateTimeFormat) : '';
};

// TODO: have them in a unified place (duplicate of consultation fields)
const SCHEDULE_FIELDS = {
  START_TIME: {
    name: 'startTime',
    path: 'schedule.startTime',
    valueGetter: displayDate,
  },
  END_TIME: {
    name: 'endTime',
    path: 'schedule.endTime',
    valueGetter: displayDate,
  },
};

export const FIELDS = {
  ID: {
    name: 'id',
  },
  CREATED_AT: {
    name: 'createdAt',
    valueGetter: displayDate,
  },
  UPDATED_AT: {
    name: 'updatedAt',
    valueGetter: displayDate,
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  CREATED_BY_USER: {
    name: 'createdByUser',
  },
  UPDATED_BY_USER: {
    name: 'updatedByUser',
  },
  USER_ID: {
    name: 'userId',
  },
  DOCUMENTS: {
    name: 'documents',
  },
  STATUS: {
    name: 'status',
  },
  PACKAGE_ID: {
    name: 'packageId',
  },
  PACKAGE: {
    name: 'packageDetails',
  },
  COMMENTS: {
    name: 'comments',
  },
  SCHEDULE: {
    name: 'schedule',
    fields: SCHEDULE_FIELDS,
  },
  RESULTS: {
    name: 'results',
  },
  CONSULTATION_ID: {
    name: 'consultationId',
  },
  TENANT_ID: {
    name: 'tenantId',
  },
  MESSAGE: {
    name: 'message',
  },
  DOCTOR_ID: {
    name: 'doctorId',
  },
  TEST_LIST: {
    name: 'labTestsList',
  },
  CHANNEL: {
    name: 'channel',
  },
  APPEND_DOCUMENTS_ON_UPDATE: {
    name: 'appendDocumentsOnUpdate',
  },
  NAMESPACE_KEY: {
    name: 'namespaceKey',
  },
  HAS_PAYMENT_TRANSACTION: {
    name: 'hasPaymentTransaction',
  },
  USER_HAS_WLP_SUBSCRIPTION: {
    name: 'userHasWlpSubscription',
  },
  USER_DTO: {
    name: 'userDto',
  },
  PATIENTS: {
    name: 'labTestRequestPatient',
  },
  ...SCHEDULE_FIELDS,
};

export const PACKAGE_FIELDS = {
  ID: {
    name: 'id',
  },
  NAME: {
    name: 'name',
  },
  DESCRIPTION: {
    name: 'description',
  },
};

export const STATUSES = {
  DONE: {
    key: 'DONE',
    label: 'Completed',
    color: 'success',
    hide: true,
  },
  CONFIRMED: {
    key: 'CONFIRMED',
    label: 'Confirmed',
    color: 'primary',
    hide: false,
  },
  NEW: {
    key: 'NEW',
    label: 'New',
    color: 'warning',
    hide: true,
  },
  CANCELLED: {
    key: 'CANCELLED',
    label: 'Canceled',
    color: 'error',
    hide: false,
  },
  LAB_RESULTS_OUT: {
    // DONE
    key: 'LAB_RESULTS_OUT',
    label: 'Lab Results Out',
    color: 'success',
    hide: false,
  },
  PENDING: {
    // NEW
    key: 'PENDING',
    label: 'Pending',
    color: 'warning',
    hide: false,
  },
  PAID: {
    key: 'PAID',
    label: 'Paid',
    color: 'success',
    hide: true,
  },
  DELETED: {
    key: 'DELETED',
    label: 'Deleted',
    color: 'error',
    hide: false,
  },
  VERIFIED: {
    key: 'VERIFIED',
    label: 'Verified',
    color: 'success',
    hide: false,
  },
};
export const LAB_TEST_CHANNELS = {
  APP: { label: 'App', key: 'APP' },
  SUCCUSS_TEAM: { label: 'Success Team', key: 'Success Team' },
  CONSULTATION_FOLLOWUP: {
    label: 'consultation follow-up',
    key: 'Consultation follow-up',
  },
};
