import get from 'lodash/get';
import { createSelector } from 'reselect';

export const patientHistorySelector = (state) =>
  get(state, 'domain.patientHistory', {});

export const getPatientHistory = createSelector(
  patientHistorySelector,
  (results) => get(results, 'list')
);

export const isFetchingPatientHistory = createSelector(
  patientHistorySelector,
  (results) => get(results, 'isFetching')
);

export default {
  getPatientHistory,
  isFetchingPatientHistory,
};
