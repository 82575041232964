import { connect } from 'react-redux';

import { paymentActions } from 'domain/payment';

import CreatePaymentTransaction from './CreatePaymentTransaction';

const mapDispatchToProps = (dispatch) => ({
  createPaymentTransaction: (payload) =>
    dispatch(paymentActions.createPaymentTransaction(payload)),
});

export default connect(null, mapDispatchToProps)(CreatePaymentTransaction);
