import get from 'lodash/get';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import { takeSequential } from 'core/saga';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchFiles({ fileIds, referenceType }) {
  const fileByIdEndpoints = fileIds.map((id) =>
    [constants.ENDPOINT, id].join('/')
  );

  const responses = yield all(
    fileByIdEndpoints.map((endpoint) =>
      call(httpClient.get, endpoint, {
        responseType: 'blob',
      })
    )
  );

  const files = responses.map((response, idx) => {
    const blob = response.data;

    return {
      [constants.FIELDS.ID.name]: fileIds[idx],
      [constants.FIELDS.TYPE.name]: blob.type,
      [constants.FIELDS.URL.name]: URL.createObjectURL(blob),
    };
  });

  // Passing referenceType is very important to make sure different sets of files are fetched separately
  // Otherwise, wrong files will be stored in wrong state
  yield put(actions.filesReceived(files, referenceType));
}

export function* createFiles({ rawFiles, fileType: type, userId, patchId }) {
  try {
    const responses = yield all(
      rawFiles.map((file) => {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', type);

        if (userId) {
          formData.append('userId', userId);
        }

        return call(httpClient.post, constants.ENDPOINT, formData, {
          headers: {
            contentType: 'multipart/form-data',
          },
        });
      })
    );

    const files = responses.map((response) => response.data);

    yield put(actions.filesCreated(files));
    yield put(actions.filesCreationResponded(true, files, patchId));
  } catch (e) {
    yield put(
      actions.filesCreationResponded(
        false,
        `Please keep the page open and report the issue to the team.\n${get(
          e,
          'response.data.message'
        )}`,
        patchId
      )
    );
  }
}

export default function* fileModelSaga() {
  yield all([
    takeEvery(types.FETCH_FILES, fetchFiles),
    takeSequential(types.CREATE_FILES, createFiles),
  ]);
}
