import * as types from './actionTypes';

export const createEligibilityRequest = (payload) => ({
  type: types.CREATE_ELIGIBILITY_REQUEST,
  payload,
});

export const getEligibilityRequests = (referenceId, referenceType) => ({
  type: types.GET_ELIGIBILITY_REQUESTS,
  referenceId,
  referenceType,
});

export const eligibilityCreateResponded = (ok, response) => ({
  type: types.ELIGIBILITY_CREATE_RESPONDED,
  ok,
  response,
});

export const eligibilityRequestsReceived = (eligibilityRequests) => ({
  type: types.ELIGIBILITY_REQUESTS_RECEIVED,
  eligibilityRequests,
});
