import React, { useState } from 'react';

import Box from '../Box';

const Hover = ({ component, children, ...rest }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <Box
      component={component}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {children(isHovering)}
    </Box>
  );
};

export default Hover;
