import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as DOCTOR } from './constants';

export const FETCH_DOCTOR = `${MODEL}/${DOCTOR}/FETCH_DOCTOR`;
export const FETCH_DOCTORS = `${MODEL}/${DOCTOR}/FETCH_DOCTORS`;
export const UPDATE_DOCTOR = `${MODEL}/${DOCTOR}/UPDATE_DOCTOR`;

export const DOCTOR_UPDATE_RESPONDED = `${MODEL}/${DOCTOR}/DOCTOR_UPDATE_RESPONDED`;
export const DOCTOR_FETCH_RESPONDED = `${MODEL}/${DOCTOR}/DOCTOR_FETCH_RESPONDED`;
export const DOCTORS_FETCH_RESPONDED = `${MODEL}/${DOCTOR}/DOCTORS_FETCH_RESPONDED`;
