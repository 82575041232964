import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import { isAllowed } from 'core/permission';
import { authSelectors } from 'domain/auth';
import { CsvExportButton } from 'core/export';
import { UserSearch } from 'presentation/user';
import { USER_STATUSES } from 'domain/user/constants';
import { userConstants as constants } from 'domain/user';
import { Button, DataGrid, PageTitle } from 'design-system';
import { SendBulkNotifications } from 'presentation/notification';
import { filtration, DataGridCard, useServerPagination } from 'core/dataGrid';

import { getColumns, getFilterFieldsConfig } from './utils';

const { FIELDS, USER_TAGS, SIGN_UP_SOURCE } = constants;

const UsersList = ({
  lsKey,
  title,
  endpoint,
  saveFilters,
  referenceType,
  filterSignUpSources = [],
}) => {
  const navigate = useNavigate();

  const selectedTenant = useSelector((state) =>
    authSelectors.getSelectedActiveTenant(state, true)
  );

  const { params, filterValues } = filtration.getFilterParamsFromLs(
    getFilterFieldsConfig(),
    lsKey
  );

  if (!isEmpty(filterSignUpSources)) {
    params.append(
      'filter',
      `signupSource||$notin||${filterSignUpSources.join(',')}`
    );
  }

  if (isAllowed('common', 'tenantFilter')) {
    params.append('filter', `tenantKey||$in||${selectedTenant}`);
  }

  const [isFetching, setFetching] = useState(false);

  const {
    params: apiParams,
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint,
    params,
    initialSortModel: { field: 'createdAt', sort: 'desc' },
    columnFieldsMap: {
      patient: 'fullName',
      phoneNumber: 'phoneNumber',
    },
  });

  const { updateFilter } = filtration.useServerFiltration({
    saveFilters,
    setQueryParams,
    initialValues: filterValues,
    fieldsConfig: getFilterFieldsConfig(),
    onQueryParamsChange: (urlSearchParams, queryParamsState) => {
      const prms = new URLSearchParams(urlSearchParams);

      if (isEmpty(queryParamsState.signUpSource)) {
        if (!isEmpty(filterSignUpSources)) {
          prms.append(
            'filter',
            `signupSource||$notin||${filterSignUpSources.join(',')}`
          );
        }
      }

      if (isAllowed('common', 'tenantFilter')) {
        prms.append('filter', `tenantKey||$in||${selectedTenant}`);
      }

      return prms;
    },
  });

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const filtersDef = {
    fields: [
      {
        name: 'patient',
        type: 'custom',
        component: (
          <UserSearch
            disabled={isFetching}
            defaultValue={filterValues.patient}
            onUserSelect={(selectedPatient) =>
              updateFilter('patient', selectedPatient)
            }
          />
        ),
      },
      {
        name: 'createdDateFrom',
        type: 'date',
        placeholder: 'Created from',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.createdDateFrom,
        filter: (timestamp) => {
          updateFilter('createdDateFrom', timestamp);
        },
      },
      {
        name: 'createdDateUntil',
        type: 'date',
        placeholder: 'Created until',
        icon: CalendarMonthOutlinedIcon,
        disabled: isFetching,
        defaultValue: filterValues.createdDateUntil,
        filter: (timestamp) => {
          updateFilter('createdDateUntil', timestamp);
        },
      },
      {
        name: 'signUpSource',
        type: 'autocomplete',
        placeholder: 'Sign up source',
        hide: !isAllowed('userList', 'signupSource'),
        icon: MonitorHeartOutlinedIcon,
        defaultValue: filterValues.signUpSource,
        options: keys(SIGN_UP_SOURCE).filter(
          (item) => !filterSignUpSources.includes(SIGN_UP_SOURCE[item].key)
        ),
        getOptionLabel: (option) => SIGN_UP_SOURCE[option]?.label,
        disabled: isFetching,
        filter: (selectedSource) => {
          updateFilter('signUpSource', SIGN_UP_SOURCE[selectedSource]?.key);
        },
      },
      {
        name: 'tag',
        type: 'autocomplete',
        placeholder: 'Tag',
        icon: MonitorHeartOutlinedIcon,
        options: keys(USER_TAGS),
        withNone: true,
        defaultValue: filterValues.tags,
        hide: !isAllowed('userList', 'tags'),
        getOptionLabel: (option) => USER_TAGS[option]?.label || option,
        disabled: isFetching,
        filter: (selectedTag) => {
          updateFilter('tags', USER_TAGS[selectedTag]?.key || selectedTag);
        },
      },
      {
        name: 'createdBy',
        type: 'custom',
        placeholder: 'Created By',
        hide: !isAllowed('userList', 'createdBy'),
        component: (
          <UserSearch
            placeholder="Created by"
            disabled={isFetching}
            defaultValue={filterValues.createdBy}
            onUserSelect={(selectedUser) =>
              updateFilter('createdBy', selectedUser)
            }
          />
        ),
      },
      {
        name: 'status',
        type: 'autocomplete',
        placeholder: 'Status',
        icon: MonitorHeartOutlinedIcon,
        hide: !isAllowed('userList', 'status'),
        options: keys(USER_STATUSES),
        getOptionLabel: (option) => USER_STATUSES[option].label,
        defaultValue: filterValues.status,
        filter: (selectedStatus) => {
          updateFilter('status', selectedStatus);
        },
      },
      {
        name: 'isVerified',
        type: 'switch',
        label: 'Verified',
        disabled: isFetching,
        defaultValue: filterValues.isVerified,
        hide: !isAllowed('userList', 'verified'),
        filter: (isVerified) => {
          updateFilter('isVerified', isVerified);
        },
      },
    ],
  };

  const onRowClick = (row, isInNewTab) => {
    const { id } = row;

    if (isInNewTab) {
      window.open(`/user/${id}`, '_blank');
    } else {
      navigate(`/user/${id}`);
    }
  };

  return (
    <>
      <PageTitle
        title={title || 'Patients'}
        action={
          <>
            {isAllowed('common', 'export') && (
              <CsvExportButton
                endpoint={endpoint}
                params={apiParams}
                fields={FIELDS}
              />
            )}
            {isAllowed('bulkNotification', 'users') && (
              <SendBulkNotifications
                loading={isFetching}
                referenceType={referenceType}
                params={apiParams}
                rowCount={dataGridProps?.rowCount || 0}
              />
            )}
            <Button variant="filled" onClick={() => navigate('/user')}>
              Add a new patient
            </Button>
          </>
        }
      />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          columns={getColumns()}
          onRowClick={(prms) => onRowClick(prms?.row)}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => onRowClick(row, true),
            },
          ]}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
    </>
  );
};

export default UsersList;
