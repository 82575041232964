import { connect } from 'react-redux';

import { userActions, userSelectors } from 'domain/user';

import UserAddressFormDialog from './UserAddressFormDialog';

const mapStateToProps = (state) => {
  const addresses = userSelectors.getUserAddresses(state);
  const isFetching = userSelectors.isFetchingUserAddresses(state);

  return {
    addresses,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAddresses: (userId) => dispatch(userActions.fetchUserAddresses(userId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAddressFormDialog);
