import get from 'lodash/get';
import React, { useState, useEffect } from 'react';

import { TextField } from 'design-system';
import { medicineModelConstants } from 'model/medicine';
import ServerAutocomplete from 'core/serverAutocomplete';

const MedicineAutocomplete = ({
  loading,
  defaultValue,
  required,
  size = 'large',
  variant = 'filled',
  error,
  helperText,
  onChange = () => {},
  ...rest
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ServerAutocomplete
      fullWidth
      size={size}
      defaultValue={value}
      endpoint={medicineModelConstants.ENDPOINT}
      getOptionLabel={(option) => option.packageSummary}
      responseGetter={(response) => get(response, 'data.data', [])}
      getRequestParams={(searchQuery) => {
        const urlSearchParams = new URLSearchParams();

        urlSearchParams.append('limit', 35);
        urlSearchParams.append('offset', 0);
        urlSearchParams.append('sort', 'id,DESC');

        urlSearchParams.append('or', `genericName||$startsL||${searchQuery}`);
        urlSearchParams.append('or', `packageName||$startsL||${searchQuery}`);
        urlSearchParams.append('or', `drugCode||$contL||${searchQuery}`);

        return urlSearchParams;
      }}
      throttle={(fn, searchQuery) => {
        if (searchQuery.length >= 3) {
          fn();
        }
      }}
      onChange={(newValue) => {
        setValue(newValue);
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={error}
          helperText={helperText}
          required={required}
          size={size}
          variant={variant}
          loading={loading}
          placeholder="Medicine"
          sx={{
            '& .MuiInputBase-root': {
              height: 'auto',
            },
          }}
        />
      )}
      {...rest}
    />
  );
};

export default MedicineAutocomplete;
