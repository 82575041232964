import React, { useState, useEffect } from 'react';
import { ELIGIBILITY_FIELDS } from 'domain/eligibility';
import {
  Grid,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

function createEligibility({
  open,
  onClose,
  referenceId,
  referenceType,
  isSubmitting,
  userId,
  doctorId,
  defaultIdentityNumber,
  createEligibilityRequest,
}) {
  const [isOpen, setOpen] = useState(false);
  const [identityNumber, setIdentityNumber] = useState(
    defaultIdentityNumber || ''
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const resetFields = () => {
    setIdentityNumber('');
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
    resetFields();
  };

  const onCreateButtonClicked = () => {
    const payload = {
      [ELIGIBILITY_FIELDS.REFERENCE_ID.name]: referenceId,
      [ELIGIBILITY_FIELDS.REFERENCE_TYPE.name]: referenceType,
      [ELIGIBILITY_FIELDS.USER_ID.name]: userId,
      [ELIGIBILITY_FIELDS.DOCTOR_ID.name]: doctorId,
      [ELIGIBILITY_FIELDS.IDENTITY_NUMBER.name]: identityNumber,
    };
    createEligibilityRequest(payload);
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="create-eligibility-dialog"
    >
      <DialogTitle id="create-eligibility-title" onClose={handleClose}>
        Create eligibility request
      </DialogTitle>
      <DialogContent dividers>
        <Grid container column>
          <Grid item sx={{ mb: 1 }}>
            <Typography variant="p4">IDENTITY NUMBER</Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size="large"
              variant="filled"
              value={identityNumber}
              onChange={(event) => {
                setIdentityNumber(event.target.value);
              }}
              placeholder="Set identity number for the patient"
              sx={{
                '& > .MuiInputBase-root': {
                  pr: 0,
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item container ml={1} justifyContent="space-between">
          <Button
            variant="filled"
            disabled={!identityNumber}
            spinning={isSubmitting}
            onClick={() => {
              onCreateButtonClicked();
            }}
          >
            Create
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default createEligibility;
