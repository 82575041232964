import values from 'lodash/values';

export const CURRENCIES = {
  AED: {
    key: 'AED',
    label: 'AED',
  },
  SAR: {
    key: 'SAR',
    label: 'SAR',
  },
  EGP: {
    key: 'EGP',
    label: 'EGP',
  },
};

export const CURRENCIES_LIST = values(CURRENCIES);
