import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import appData from 'domain/app/HOCs/appData';
import {
  consultationActions as actions,
  consultationSelectors as selectors,
  consultationActionTypes as actionTypes,
} from 'domain/consultation';

import SendPrescriptionsDialog from './SendPrescriptionsDialog';

const mapStateToProps = (state) => {
  const isSubmittingPrescriptions =
    selectors.isSubmittingConsultationPrescriptions(state);

  return {
    isSubmittingPrescriptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitPrescriptions: (consultationId) =>
    dispatch(actions.submitConsultationPrescriptions(consultationId)),
  onPrescriptionsSubmitted: (onPrescriptionsSubmittedCallback) =>
    dispatch(
      appActions.pursueAction(
        actionTypes.CONSULTATION_PRESCRIPTIONS_SUBMITTED,
        onPrescriptionsSubmittedCallback
      )
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(SendPrescriptionsDialog)
);
