import { connect } from 'react-redux';
import appData from 'domain/app/HOCs/appData';

import {
  anonymousUserActions as actions,
  anonymousUserSelectors as selectors,
} from 'domain/anonymousUser';

import AnonymousUserView from './AnonymousUserView';

const mapStateToProps = (state) => {
  const anonymousUser = selectors.getAnonymousUser(state);
  const isFetching = selectors.isFetchingAnonymousUser(state);

  return {
    anonymousUser,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchAnonymousUser: (id) => dispatch(actions.fetchAnonymousUser(id)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(AnonymousUserView)
);
