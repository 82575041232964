import * as types from './actionTypes';

const initialState = {
  view: {},
  orderHistory: [],
  lineItemsHistory: [],
  logisticProviders: [],
  isFetching: false,
  isSubmitting: false,
  isUpdatingLineItems: false,
  isFetchingPrescriptions: false,
  isFetchingOrderHistory: false,
  isFetchingLineItemsHistory: false,
  import: {
    pinnedOrders: [],
    searchedOrder: {},
    searchedOrderFetchError: '',
    isFetchingPinnedOrders: false,
    isFetchingSearchedOrder: false,
  },
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ORDER_RECEIVED: {
      return {
        ...state,
        view: {
          ...(action.order || {}),
        },
      };
    }
    case types.SET_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.SET_FETCHING_PRESCRIPTIONS: {
      return {
        ...state,
        isFetchingPrescriptions: action.isFetchingPrescriptions,
      };
    }
    case types.SET_UPDATING_LINE_ITEMS: {
      return {
        ...state,
        isUpdatingLineItems: action.isUpdatingLineItems,
      };
    }
    // Import
    case types.SEARCHED_ORDER_RECEIVED: {
      return {
        ...state,
        import: {
          ...state.import,
          searchedOrder: { ...(action.searchedOrder || {}) },
        },
      };
    }
    case types.PINNED_ORDERS_RECEIVED: {
      return {
        ...state,
        import: {
          ...state.import,
          pinnedOrders: [...(action.pinnedOrders || [])],
        },
      };
    }
    case types.SET_SEARCHED_ORDER_FETCH_ERROR: {
      return {
        ...state,
        import: {
          ...state.import,
          searchedOrderFetchError: action.error || '',
        },
      };
    }
    case types.SET_FETCHING_SEARCHED_ORDER: {
      return {
        ...state,
        import: {
          ...state.import,
          isFetchingSearchedOrder: action.isFetchingSearchedOrder,
        },
      };
    }
    case types.SET_FETCHING_PINNED_ORDERS: {
      return {
        ...state,
        import: {
          ...state.import,
          isFetchingPinnedOrders: action.isFetchingPinnedOrders,
        },
      };
    }
    case types.LOGISTIC_PROVIDERS_RESPONDED: {
      return {
        ...state,
        logisticProviders: action.logisticProviders,
      };
    }
    case types.SHIPMENT_FETCH_RECEIVED: {
      return {
        ...state,
        shipment: action.shipment,
      };
    }
    case types.SHIPMENT_LABEL_RESPONDED: {
      return {
        ...state,
        shipmentLabel: action.shipmentLabel,
      };
    }
    case types.SET_FETCHING_TASK_ID: {
      return { ...state, isFetchingTaskId: action.isFetchingTaskId };
    }
    case types.ORDER_HISTORY_RECEIVED: {
      return { ...state, orderHistory: action.orderHistory || [] };
    }
    case types.SET_FETCHING_ORDER_HISTORY: {
      return {
        ...state,
        isFetchingOrderHistory: action.isFetchingOrderHistory,
      };
    }
    case types.LINE_ITEMS_HISTORY_RECEIVED: {
      return { ...state, lineItemsHistory: action.lineItemsHistory || [] };
    }
    case types.SET_FETCHING_LINE_ITEMS_HISTORY: {
      return {
        ...state,
        isFetchingLineItemsHistory: action.isFetchingLineItemsHistory,
      };
    }
    default:
      return state;
  }
};

export default orderReducer;
