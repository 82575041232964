import { connect } from 'react-redux';

import {
  meetingActions as actions,
  meetingSelectors as selectors,
} from 'domain/meeting';

import MeetingsSection from './MeetingsSection';

const mapStateToProps = (state) => {
  const meetings = selectors.getMeetings(state);
  const isFetching = selectors.isFetching(state);

  return {
    meetings,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(actions.clear()),
  getMeetings: (doctorId) => dispatch(actions.getDoctorMeetings(doctorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsSection);
