import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import {
  SYSTEM_ROLES,
  userManagementGetters as getters,
  USER_MANAGEMENT_FIELDS as FIELDS,
} from 'domain/userManagement';

import {
  Grid,
  Card,
  Select,
  MenuItem,
  TextField,
  PageTitle,
  CardContent,
  Checkbox,
  Chip,
  DisplayField,
  FormField,
  Button,
} from 'design-system';
import { getTenantName } from 'domain/tenant/utils';
import { getUsername } from 'domain/userManagement/getters';
import { userGetters } from 'domain/user';

const UserManagementView = ({
  userCredentials,
  tenants,
  isFetching,
  isSubmitting,
  getAlmaOsUser,
  updateAlmaOsUser,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;
  const [hasFormChanged, setFormChanged] = useState(false);

  const [username, setUsername] = useState('');
  const [roles, setRoles] = useState([]);
  const [slackMemberId, setSlackMemberId] = useState('');
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    if (id) {
      getAlmaOsUser(id);
    }
  }, [id]);

  const toggleFormChangedStatus = (isEnabled) => {
    if (!isFetching && !isEmpty(username) && !isEmpty(roles)) {
      setFormChanged(isEnabled);
    }
  };

  useEffect(() => {
    if (!isEmpty(userCredentials)) {
      setUsername(getters.getUsername(userCredentials));
      setRoles(getters.getRoles(userCredentials));
      setSlackMemberId(getters.getSlackMemberId(userCredentials));
      setUserId(getters.getUserId(userCredentials));
    }
  }, [userCredentials]);

  const submitChanges = (e) => {
    e.stopPropagation();
    const payload = {
      [FIELDS.ID.name]: id,
      [FIELDS.USERNAME.name]: username,
      [FIELDS.ROLES.name]: roles,
      [FIELDS.SLACK_MEMBER_ID.name]: slackMemberId,
      [FIELDS.USER_ID.name]: userId,
    };
    updateAlmaOsUser(id, payload);
    setFormChanged(false);
  };

  return (
    <Grid item sx={{ width: '40%' }}>
      <PageTitle
        title={`Account #${id}`}
        action={
          <Button
            variant="filled"
            onClick={submitChanges}
            spinning={isSubmitting}
            disabled={!hasFormChanged || isFetching}
          >
            Save changes
          </Button>
        }
      />
      <Grid item sx={{ width: '100%' }}>
        <Card key="user-management-card">
          <CardContent sx={{ height: '100%' }}>
            <Grid container column rowSpacing={2}>
              <DisplayField
                label="Full Name"
                value={userGetters.getFullName(userCredentials)}
                labelWidth={60}
                valueWidth={170}
                loading={isFetching}
              />
              <FormField
                label="Username"
                value={getUsername(userCredentials)}
                labelWidth={60}
                valueWidth={170}
                loading={isFetching}
                field={
                  <TextField
                    size="medium"
                    fullWidth
                    variant="filled"
                    value={username}
                    loading={isFetching}
                    placeholder="username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                      toggleFormChangedStatus(true);
                    }}
                  />
                }
              />
              <DisplayField
                label="Branch"
                value={getTenantName(
                  tenants,
                  getters.getTenant(userCredentials)
                )}
                labelWidth={60}
                valueWidth={170}
                loading={isFetching}
              />
              <DisplayField
                label="Branches"
                value={getters
                  .getAllowedTenants(userCredentials)
                  .map((item) => getTenantName(tenants, item))
                  .join(', ')}
                labelWidth={60}
                valueWidth={170}
                loading={isFetching}
              />
              <FormField
                label="Roles"
                labelWidth={60}
                valueWidth={170}
                loading={isFetching}
                field={
                  <Select
                    fullWidth
                    multiple
                    placeholder="Specify user roles"
                    soak="light"
                    variant="filled"
                    value={roles}
                    loading={isFetching}
                    onChange={(event) => {
                      setRoles(event.target.value);
                      toggleFormChangedStatus(true);
                    }}
                    renderValue={(selected) => (
                      <Grid
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => (
                          <Chip
                            size="small"
                            color="primary"
                            sx={{ maxHeight: 20 }}
                            key={SYSTEM_ROLES[value]?.key || value}
                            label={SYSTEM_ROLES[value]?.label || value}
                          />
                        ))}
                      </Grid>
                    )}
                  >
                    {keys(SYSTEM_ROLES).map((option) => (
                      <MenuItem value={option} key={option}>
                        <Checkbox checked={roles.indexOf(option) > -1} />
                        {SYSTEM_ROLES[option].label}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
              <FormField
                label="Slack Member ID"
                value={getUsername(userCredentials)}
                labelWidth={60}
                valueWidth={170}
                loading={isFetching}
                field={
                  <TextField
                    size="medium"
                    fullWidth
                    loading={isFetching}
                    variant="filled"
                    value={slackMemberId}
                    placeholder="Slack member id"
                    onChange={(e) => {
                      setSlackMemberId(e.target.value);
                      toggleFormChangedStatus(true);
                    }}
                  />
                }
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserManagementView;
