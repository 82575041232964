import get from 'lodash/get';
import { createSelector } from 'reselect';

export const fileSelector = (state) => get(state, 'domain.file', {});

export const getFiles = createSelector(
  [fileSelector, (_state, referenceType) => referenceType],
  (results, referenceType) => get(results, `view.${referenceType}`)
);

export const getFilesOf = createSelector(
  [
    fileSelector,
    (_state, referenceType, referenceId = 'any') =>
      [referenceType, referenceId].join('.'),
  ],
  (results, path) => get(results, `viewBy.${path}`)
);

export const isCreatingFiles = createSelector(
  fileSelector,
  (results) => get(results, 'isCreatingFiles', 0) > 0
);

export const isFetchingFilesOf = createSelector(
  [
    fileSelector,
    (_state, referenceType, referenceId = 'any') =>
      [referenceType, referenceId].join('.'),
  ],
  (results, path) => get(results, `isFetchingFilesOf.${path}`)
);
