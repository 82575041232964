import values from 'lodash/values';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

import { NONE, VALUE } from './constants';

const getFilterParams = (fields, filters) => {
  const filterValues = {};
  const params = new URLSearchParams();
  forEach(fields, (field) => {
    const {
      name,
      serverName,
      defaultValue,
      operation = '$eq',
      condition = true,
      valueGetter = (value) => value,
      joins = [],
    } = field;

    const value = filters?.[name];
    const serverValue = valueGetter(value);

    // The join is needed in case the filter for nested field, see https://github.com/nestjsx/crud/wiki/Requests#filter-conditions
    // If the server value is not null or the value is none, we check for the join condition
    if (
      (serverValue || value === NONE || values(value).includes(NONE)) &&
      joins.length
    ) {
      forEach(joins, (join) => {
        params.append('join', join);
      });
    }
    if (value === NONE || values(value).includes(NONE)) {
      // Related to "withNone" filters
      // If the value is NONE is a field is NONE (e.g: name, fullName..etc),
      // then filter for null values
      filterValues[name] = value;
      params.append('filter', `${serverName}||$isnull`);
    } else if (value === VALUE || values(value).includes(VALUE)) {
      filterValues[name] = value;
      params.append('filter', `${serverName}||$notnull`);
    } else if (condition && serverValue) {
      filterValues[name] = value;
      params.append('filter', `${serverName}||${operation}||${serverValue}`);
    } else if (condition && serverValue === null) {
      filterValues[name] = value;
    } else if (defaultValue && isEmpty(filters)) {
      filterValues[name] = defaultValue;
      params.append('filter', `${serverName}||${operation}||${defaultValue}`);
    }
  });

  return {
    params,
    filterValues,
  };
};

export default getFilterParams;
