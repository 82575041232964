import React, { useMemo, useState, useEffect } from 'react';

import { httpCache } from 'core/cache';
import { Link, Grid } from 'design-system';

import DialerLink from '../DialerLink';

const DEFAULT_CONFIG = {
  isPhoneNumberShown: true,
  isFullNameShown: true,
  namePlaceholder: "Visit patient's profile",
};

const UserInfoLink = ({ userId, config = {}, sx }) => {
  const conf = useMemo(
    () => ({ ...DEFAULT_CONFIG, ...(config || {}) }),
    [config]
  );

  const [fullName, setFullName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    async function getUserInfo() {
      setFetching(true);

      const response = await httpCache.get(`/user/${userId}/basic`);
      const user = response?.data;

      setFullName(user?.fullName);
      setPhoneNumber(user?.phoneNumber);
      setFetching(false);
    }

    getUserInfo();
  }, [userId]);

  const xs = useMemo(() => {
    return (
      12 /
      [conf.isFullNameShown, conf.isPhoneNumberShown].filter(Boolean).length
    );
  }, [conf]);

  return (
    <Grid container sx={sx}>
      {conf.isFullNameShown && (
        <Grid
          item
          xs={xs}
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          <Link href={`/user/${userId}`}>
            {fullName || conf.namePlaceholder}
          </Link>
        </Grid>
      )}
      {conf.isPhoneNumberShown && (
        <Grid item xs={xs}>
          <DialerLink phoneNumber={phoneNumber} isFetching={isFetching} />
        </Grid>
      )}
    </Grid>
  );
};

export default UserInfoLink;
