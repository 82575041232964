import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as DOCTOR } from './constants';

export const GET_DOCTOR = `${DOMAIN}/${DOCTOR}/GET_DOCTOR`;
export const GET_DOCTORS = `${DOMAIN}/${DOCTOR}/GET_DOCTORS`;
export const UPDATE_DOCTOR = `${DOMAIN}/${DOCTOR}/UPDATE_DOCTOR`;

export const SET_FETCHING = `${DOMAIN}/${DOCTOR}/SET_FETCHING`;
export const SET_SUBMITTING = `${DOMAIN}/${DOCTOR}/SET_SUBMITTING`;

export const DOCTOR_RECEIVED = `${DOMAIN}/${DOCTOR}/DOCTOR_RECEIVED`;
export const DOCTORS_RECEIVED = `${DOMAIN}/${DOCTOR}/DOCTORS_RECEIVED`;
