import { connect } from 'react-redux';
import { doctorNotesActions, doctorNotesSelectors } from 'domain/doctorNotes';
import { userActions } from 'domain/user';
import { memberActions } from 'domain/user/member';
import PatientAndFamilyHistoryForm from './PatientAndFamilyHistoryForm';

const mapStateToProps = (state) => {
  const getDoctorNotesById = (userId) =>
    doctorNotesSelectors.getDoctorNotesById(state, userId);
  return {
    getDoctorNotesById,
  };
};
const mapDispatchToProps = (dispatch) => ({
  submitPatientAndFamilyHistoryForm: (
    userId,
    consultationId,
    payload,
    successCallback
  ) =>
    dispatch(
      doctorNotesActions.submitDoctorNotes(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
  submitOwnerHealthProfile: (userId, payload, successCallback) =>
    dispatch(userActions.updateHealthProfile(userId, payload, successCallback)),
  submitMemberHealthProfile: (userId, payload, successCallback) =>
    dispatch(
      memberActions.updateMemberHealthProfile(userId, payload, successCallback)
    ),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAndFamilyHistoryForm);
