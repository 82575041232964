import get from 'lodash/get';
import { createSelector } from 'reselect';

export const insuranceProviderSelector = (state) =>
  get(state, 'domain.insuranceProvider', {});

export const getInsuranceProviderList = createSelector(
  insuranceProviderSelector,
  (results) => get(results, 'insuranceProviderList')
);
