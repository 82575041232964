import keys from 'lodash/keys';
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState, useCallback, forwardRef, useEffect } from 'react';

import { userConstants } from 'domain/user';
import { Form, Controller } from 'design-system/form';
import { ALLERGIES_SECTION_FIELDS } from 'domain/doctorNotes/constants';
import {
  Grid,
  Popup,
  Select,
  Divider,
  Message,
  Tooltip,
  MenuItem,
  TextField,
  FormField,
  IconButton,
  FormHelperText,
} from 'design-system';

const { ALLERGIES_LIST_TYPE, ALLERGIES_SEVERITY_LIST_TYPE } = userConstants;

const AllergiesForm = forwardRef(
  (
    {
      close,
      patient,
      isSaving,
      setSaving,
      defaultValues,
      saveAllergies,
      onAllergiesSubmitted,
    },
    formRef
  ) => {
    const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);
    const [pendingDeleteAllergyIndex, setPendingDeleteAllergyIndex] =
      useState();
    const [patientAllergiesPopulatedValue, setPatientAllergiesPopulatedValue] =
      useState(defaultValues);

    const handleDeleteAllergy = (index) => {
      const allergiesList = formRef?.current
        ?.getMethods?.()
        .getValues('allergies');
      const { length } = allergiesList;

      // Ensure there is at least one item to delete
      if (length <= 1) {
        return;
      }

      // Use splice to remove the item at the specified index
      allergiesList.splice(index, 1);
      formRef?.current?.getMethods?.().setValue('allergies', allergiesList);
    };

    const onSubmit = useCallback(
      (formData) => {
        const allergiesPayload = formData.allergies.map((allergy) => {
          // If the value is string then it's retuned from the mapper of the Form and
          // Don't need to add the id then other wise it's the retuned Id from DB then add it.
          const id = typeof allergy.id !== 'string' ? allergy.id : undefined;
          const allergyType = allergy[ALLERGIES_SECTION_FIELDS.TYPE.key];
          const severity = allergy[ALLERGIES_SECTION_FIELDS.SEVERITY.key];
          const remarks = allergy[ALLERGIES_SECTION_FIELDS.REMARKS.key];
          const reactions = allergy[ALLERGIES_SECTION_FIELDS.REACTIONS.key];

          return {
            id,
            [ALLERGIES_SECTION_FIELDS.TYPE.key]: allergyType,
            [ALLERGIES_SECTION_FIELDS.REACTIONS.key]: reactions,
            [ALLERGIES_SECTION_FIELDS.SEVERITY.key]: severity,
            [ALLERGIES_SECTION_FIELDS.REMARKS.key]: remarks,
          };
        });

        setSaving(true);
        saveAllergies(patient.userId, { allergies: allergiesPayload }, () => {
          onAllergiesSubmitted(allergiesPayload);
          Message.success('Allergies updated successfully');

          close();
          setSaving(false);
        });
      },
      [
        patient,
        saveAllergies,
        onAllergiesSubmitted,
        patientAllergiesPopulatedValue,
      ]
    );

    useEffect(() => {
      const formValue = isEmpty(defaultValues) ? [{}] : defaultValues;

      setPatientAllergiesPopulatedValue(formValue);
      formRef?.current?.getMethods?.().setValue('allergies', formValue);
    }, [defaultValues]);

    return (
      <>
        <Form
          ref={formRef}
          onSubmit={onSubmit}
          defaultValues={{ allergies: patientAllergiesPopulatedValue }}
        >
          {({ control, watch }) => (
            <Grid container column rowSpacing={3}>
              {watch('allergies', [{}]).map((allergy, index) => (
                <Grid
                  key={`allergy-${allergy.id || uuidv4()}`}
                  item
                  container
                  column
                  pb={1}
                  rowSpacing={0}
                >
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      flexWrap="nowrap"
                      columnSpacing={3}
                      alignItems="center"
                    >
                      <Grid
                        item
                        sx={{
                          width: (theme) => `calc(100% - ${theme.spacing(7)})`,
                        }}
                      >
                        <Grid container columnSpacing={3}>
                          <FormField
                            xs={3}
                            label="Allergy Type"
                            required
                            field={
                              <Controller
                                name={`allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      fullWidth
                                      size="medium"
                                      soak="light"
                                      variant="filled"
                                      value={field.value}
                                      disabled={isSaving}
                                      placeholder="Choose type"
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxHeight: 450,
                                            width: 100,
                                          },
                                        },
                                      }}
                                      onChange={field.onChange}
                                      renderValue={(selected) =>
                                        selected
                                          ? ALLERGIES_LIST_TYPE[selected]?.label
                                          : selected
                                      }
                                    >
                                      {keys(ALLERGIES_LIST_TYPE).map(
                                        (option) => (
                                          <MenuItem value={option} key={option}>
                                            {ALLERGIES_LIST_TYPE[option].label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                    {error ? (
                                      <FormHelperText error>
                                        {error?.message}
                                      </FormHelperText>
                                    ) : undefined}
                                  </>
                                )}
                                rules={{
                                  validate: {
                                    required: () => {
                                      if (
                                        !watch(
                                          `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                                        )
                                      ) {
                                        return 'This field is required';
                                      }
                                    },
                                  },
                                }}
                              />
                            }
                          />
                          <FormField
                            xs={3}
                            label="Allergy Severity"
                            required={
                              watch(
                                `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                              ) !== ALLERGIES_LIST_TYPE.NOT_AVAILABLE.key
                            }
                            field={
                              <Controller
                                name={`allergies[${index}].${ALLERGIES_SECTION_FIELDS.SEVERITY.key}`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <>
                                    <Select
                                      fullWidth
                                      size="medium"
                                      soak="light"
                                      variant="filled"
                                      value={field.value}
                                      disabled={isSaving}
                                      placeholder="Choose severity"
                                      MenuProps={{
                                        PaperProps: {
                                          style: {
                                            maxHeight: 450,
                                            width: 100,
                                          },
                                        },
                                      }}
                                      onChange={field.onChange}
                                      renderValue={(selected) =>
                                        selected
                                          ? ALLERGIES_SEVERITY_LIST_TYPE[
                                              selected
                                            ]?.label
                                          : selected
                                      }
                                    >
                                      {keys(ALLERGIES_SEVERITY_LIST_TYPE).map(
                                        (option) => (
                                          <MenuItem value={option} key={option}>
                                            {
                                              ALLERGIES_SEVERITY_LIST_TYPE[
                                                option
                                              ].label
                                            }
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                    {error ? (
                                      <FormHelperText error>
                                        {error?.message}
                                      </FormHelperText>
                                    ) : undefined}
                                  </>
                                )}
                                rules={{
                                  validate: {
                                    required: (val) => {
                                      if (
                                        watch(
                                          `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                                        ) !==
                                          ALLERGIES_LIST_TYPE.NOT_AVAILABLE
                                            .key &&
                                        !val
                                      ) {
                                        return 'This field is required';
                                      }
                                    },
                                  },
                                }}
                              />
                            }
                          />
                          <FormField
                            xs={3}
                            label="Reactions"
                            required={
                              watch(
                                `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                              ) !== ALLERGIES_LIST_TYPE.NOT_AVAILABLE.key
                            }
                            field={
                              <Controller
                                name={`allergies[${index}].${ALLERGIES_SECTION_FIELDS.REACTIONS.key}`}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <TextField
                                    fullWidth
                                    value={field.value}
                                    size="medium"
                                    variant="filled"
                                    disabled={isSaving}
                                    onChange={field.onChange}
                                    error={Boolean(error)}
                                    placeholder="Reactions"
                                    helperText={error?.message ?? null}
                                  />
                                )}
                                rules={{
                                  validate: {
                                    required: (val) => {
                                      if (
                                        watch(
                                          `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                                        ) !==
                                          ALLERGIES_LIST_TYPE.NOT_AVAILABLE
                                            .key &&
                                        !val
                                      ) {
                                        return 'This field is required';
                                      }
                                    },
                                  },
                                }}
                              />
                            }
                          />
                          <FormField
                            xs={3}
                            label="Remarks"
                            required={
                              watch(
                                `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                              ) !== ALLERGIES_LIST_TYPE.NOT_AVAILABLE.key
                            }
                            field={
                              <Controller
                                name={`allergies[${index}].${ALLERGIES_SECTION_FIELDS.REMARKS.key}`}
                                control={control}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <TextField
                                    fullWidth
                                    value={value}
                                    size="medium"
                                    variant="filled"
                                    onChange={onChange}
                                    disabled={isSaving}
                                    error={Boolean(error)}
                                    placeholder="Remarks"
                                    helperText={error?.message ?? null}
                                  />
                                )}
                                rules={{
                                  validate: {
                                    required: (val) => {
                                      if (
                                        watch(
                                          `allergies[${index}].${ALLERGIES_SECTION_FIELDS.TYPE.key}`
                                        ) !==
                                          ALLERGIES_LIST_TYPE.NOT_AVAILABLE
                                            .key &&
                                        !val
                                      ) {
                                        return 'This field is required';
                                      }
                                    },
                                  },
                                }}
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        pt={3}
                        sx={{ width: (theme) => theme.spacing(7) }}
                      >
                        <IconButton
                          disabled={watch('allergies')?.length <= 1 || isSaving}
                          onClick={() => {
                            if (typeof allergy.id !== 'string') {
                              setPendingDeleteAllergyIndex(index);
                              setConfirmPopupOpen(true);
                            } else {
                              handleDeleteAllergy(index);
                            }
                          }}
                        >
                          <Tooltip placement="top" title="Delete allergy">
                            <DeleteIcon fontSize="small" color="error" />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index < watch('allergies').length - 1 && (
                    <Grid item mt={3}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          )}
        </Form>
        <Popup
          id={isConfirmPopupOpen ? 'allergy-delete-confirm' : undefined}
          open={isConfirmPopupOpen}
          title="Delete Allergy"
          primaryButtonTitle="No, continue editing"
          secondaryButtonTitle="Yes, delete allergy"
          onOk={() => {
            setConfirmPopupOpen(false);
            setPendingDeleteAllergyIndex();
          }}
          onCancel={() => {
            // Delete
            handleDeleteAllergy(pendingDeleteAllergyIndex);

            // Reset
            setConfirmPopupOpen(false);
            setPendingDeleteAllergyIndex();
          }}
        >
          Are you sure you want to delete this allergy?
        </Popup>
      </>
    );
  }
);

export default AllergiesForm;
