import * as types from './actionTypes';

const initialState = {
  list: [],
  isFetching: false,
};

const patientHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PATIENT_HISTORY_RECEIVED: {
      return {
        ...state,
        list: action.patientHistory,
      };
    }
    case types.SET_FETCHING_PATIENT_HISTORY: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.CLEAR_PATIENT_HISTORY: {
      return {
        ...state,
        list: [],
      };
    }
    default:
      return state;
  }
};

export default patientHistoryReducer;
