import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import { ENDPOINT } from './constants';

export function* createMedicine({ payload }) {
  try {
    const response = yield call(httpClient.post, ENDPOINT, payload);
    yield put(actions.medicineCreationResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.medicineCreationResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchMedicine({ id }) {
  let response;

  try {
    response = yield call(httpClient.get, [ENDPOINT, id].join('/'));
    yield put(actions.medicineFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.medicineFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* patchMedicine({ id, payload }) {
  try {
    yield call(httpClient.patch, [ENDPOINT, id].join('/'), payload);
    yield put(actions.medicineUpdateResponded(true));
  } catch (_e) {
    yield put(actions.medicineUpdateResponded(false));
  }
}

export default function* medicineModelSaga() {
  yield all([
    takeLatest(types.CREATE_MEDICINE, createMedicine),
    takeLatest(types.PATCH_MEDICINE, patchMedicine),
    takeLatest(types.FETCH_MEDICNE, fetchMedicine),
  ]);
}
