import * as types from './actionTypes';

export const createFiles = (
  rawFiles,
  fileType,
  userId,
  referenceType,
  successCallback,
  failCallback
) => ({
  type: types.CREATE_FILES,
  rawFiles,
  fileType,
  userId,
  referenceType,
  successCallback,
  failCallback,
});

export const fetchFilesOf = (fileIds, referenceType, referenceId = 'any') => ({
  type: types.FETCH_FILES_OF,
  referenceType,
  referenceId,
  fileIds,
});

export const clearFiles = (referenceType) => ({
  type: types.CLEAR_FILES,
  referenceType,
});

export const filesCreated = (files, referenceType, isReplacing = false) => ({
  type: types.FILES_CREATED,
  referenceType,
  files,
  isReplacing,
});

export const filesReceivedOf = (files, referenceType, referenceId) => ({
  type: types.FILES_RECEIVED_OF,
  referenceType,
  referenceId,
  files,
});

export const setCreatingFiles = (isCreatingFiles) => ({
  type: types.SET_CREATING_FILES,
  isCreatingFiles,
});

export const setFetchingFilesOf = (
  isFetchingFiles,
  referenceType,
  referenceId
) => ({
  type: types.SET_FETCHING_FILES_OF,
  isFetchingFiles,
  referenceType,
  referenceId,
});
