import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* deactivateAccount({ id }) {
  const deactivateAccountByIdEndpoint = [
    constants.ENDPOINT,
    'deactivate',
    id,
  ].join('/');
  let response;
  try {
    response = yield call(httpClient.post, deactivateAccountByIdEndpoint);
    if (response.status) {
      yield put(actions.almaosUserDeactivatedResponded(true));
    }
  } catch (e) {
    yield put(
      actions.almaosUserDeactivatedResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* createAlmaosUser({ payload }) {
  const endpoint = [constants.ENDPOINT, constants.CREATE].join('/');
  let response;
  try {
    response = yield call(httpClient.post, endpoint, payload);

    if (response.status) {
      yield put(actions.almaosUserCreationResponded(true, response.data));
    }
  } catch (e) {
    yield put(
      actions.almaosUserCreationResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* resetPassword({ userId }) {
  const resetPasswordEndpoint = [
    constants.AUTH_ENDPOINT,
    userId,
    constants.RESET_PASSWORD,
  ].join('/');
  let response;
  try {
    response = yield call(httpClient.post, resetPasswordEndpoint);
    if (response.status) {
      yield put(actions.passwordResetResponded(true));
    }
  } catch (e) {
    yield put(
      actions.passwordResetResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* updateAlmaosUser({ id, payload }) {
  const endpoint = [constants.ENDPOINT, id].join('/');
  let response;
  try {
    response = yield call(httpClient.patch, endpoint, payload);
    if (response.status) {
      yield put(actions.almaosUserUpdateResponded(true));
    }
  } catch (e) {
    yield put(
      actions.almaosUserUpdateResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* getAlmaosUser({ id }) {
  const endpoint = [constants.ENDPOINT, id].join('/');
  let response;
  try {
    response = yield call(httpClient.get, endpoint);
    if (response.status) {
      yield put(actions.almaosUserFetchingResponded(true, response.data));
    }
  } catch (e) {
    yield put(
      actions.almaosUserFetchingResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export default function* userManagementModelSaga() {
  yield all([
    takeLatest(types.DEACTIVATE_ACCOUNT, deactivateAccount),
    takeLatest(types.CREATE_ALMAOS_USER, createAlmaosUser),
    takeLatest(types.RESET_PASSWORD, resetPassword),
    takeLatest(types.GET_ALMAOS_USER, getAlmaosUser),
    takeLatest(types.UPDATE_ALMAOS_USER, updateAlmaosUser),
  ]);
}
