import get from 'lodash/get';
import { createSelector } from 'reselect';

export const authSelector = (state) => get(state, 'domain.auth', {});

export const isLoggingIn = createSelector(authSelector, (results) =>
  get(results, 'isLoggingIn')
);

export const getErrorMessage = createSelector(authSelector, (results) =>
  get(results, 'errorMessage')
);

export const getToken = createSelector(authSelector, (results) =>
  get(results, 'token')
);

export const getUserid = createSelector(authSelector, (results) =>
  get(results, 'userId')
);

export const getMainRole = createSelector(authSelector, (results) =>
  get(results, 'mainRole')
);

export const getUserRoles = createSelector(authSelector, (results) =>
  get(results, 'userRoles', [])
);

export const getDefaultTenant = createSelector(authSelector, (results) =>
  get(results, 'defaultTenant')
);

export const getAllowedTenants = createSelector(authSelector, (results) =>
  get(results, 'allowedTenants', [])
);

export const getSelectedTenant = createSelector(authSelector, (results) =>
  get(results, 'selectedTenant', null)
);

export const getRole = createSelector(
  [getMainRole, getUserRoles],
  (role, roles) => {
    const isUser = role === 'user';
    const isAdmin = role === 'admin';
    const isDoctor = role === 'doctor';
    const isPromoter = role === 'promoter';
    const isClinicManager = role === 'ClinicManager';
    const isSuperAdmin = role === 'SuperAdmin' || roles?.includes('SuperAdmin');
    const isScheduleManager =
      role === 'ScheduleManager' || roles?.includes('ScheduleManager');
    const isMarketingManager =
      role === 'MarketingManager' || roles?.includes('MarketingManager');
    const logisticAdmin =
      role === 'LogisticsAdmin' || roles?.includes('LogisticsAdmin');
    const isPatientSuccess =
      role === 'PatientSuccess' || roles?.includes('PatientSuccess');

    return {
      isUser,
      isAdmin,
      isDoctor,
      isPromoter,
      isClinicManager,
      isScheduleManager,
      isSuperAdmin,
      logisticAdmin,
      isPatientSuccess,
      isMarketingManager,
    };
  }
);

export const getSelectedActiveTenant = createSelector(
  [
    getAllowedTenants,
    getDefaultTenant,
    getSelectedTenant,
    (_state, isFilter) => isFilter,
  ],
  (allowedTenants, defaultTenants, selectedTenant, isFilter) => {
    if (isFilter) {
      if (selectedTenant !== null && Number(selectedTenant) !== -1) {
        return selectedTenant;
      }

      return allowedTenants.join(',');
    }

    if (selectedTenant === null) {
      return defaultTenants;
    }

    if (Number(selectedTenant) !== -1) {
      return selectedTenant;
    }

    return defaultTenants;
  }
);
