import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as USER } from './constants';

export const FETCH_USER = `${MODEL}/${USER}/FETCH_USER`;
export const CREATE_USER = `${MODEL}/${USER}/CREATE_USER`;
export const FETCH_DOCTORS = `${MODEL}/${USER}/FETCH_DOCTORS`;
export const FETCH_DRIVERS = `${MODEL}/${USER}/FETCH_DRIVERS`;
export const FETCH_PHARMACISTS = `${MODEL}/${USER}/FETCH_PHARMACISTS`;

export const UPDATE_USER_PROFILE = `${MODEL}/${USER}/UPDATE_USER_PROFILE`;
export const FETCH_USER_ADDRESSES = `${MODEL}/${USER}/FETCH_USER_ADDRESSES`;
export const START_IDENTITY_EXTRACTION = `${MODEL}/${USER}/START_IDENTITY_EXTRACTION`;
export const FETCH_PATIENT_HEALTH_INFO = `${MODEL}/${USER}/FETCH_PATIENT_HEALTH_INFO`;
export const CANCEL_IDENTITY_EXTRACTION = `${MODEL}/${USER}/CANCEL_IDENTITY_EXTRACTION`;
export const UPDATE_USER_HEALTH_PROFILE = `${MODEL}/${USER}/UPDATE_USER_HEALTH_PROFILE`;

export const USER_RECEIVED = `${MODEL}/${USER}/USER_RECEIVED`;
export const DOCTORS_FETCH_RESPONDED = `${MODEL}/${USER}/DOCTORS_FETCH_RESPONDED`;
export const DRIVERS_FETCH_RESPONDED = `${MODEL}/${USER}/DRIVERS_FETCH_RESPONDED`;
export const PHARMACISTS_FETCH_RESPONDED = `${MODEL}/${USER}/PHARMACISTS_FETCH_RESPONDED`;

export const USER_UPDATE_RESPONDED = `${MODEL}/${USER}/USER_UPDATE_RESPONDED`;
export const USER_ADDRESSES_RECEIVED = `${MODEL}/${USER}/USER_ADDRESSES_RECEIVED`;
export const USER_CREATION_RESPONDED = `${MODEL}/${USER}/USER_CREATION_RESPONDED`;
export const PATIENT_HEALTH_INFO_RECEIVED = `${MODEL}/${USER}/PATIENT_HEALTH_INFO_RECEIVED`;
export const IDENTITY_EXTRACTION_RESPONDED = `${MODEL}/${USER}/IDENTITY_EXTRACTION_RESPONDED`;
export const UPDATE_HEALTH_PROFILE_RESPONDED = `${MODEL}/${USER}/UPDATE_HEALTH_PROFILE_RESPONDED`;
export const IDENTITY_EXTRACTION_JOBS_RECEIVED = `${MODEL}/${USER}/IDENTITY_EXTRACTION_JOBS_RECEIVED`;
