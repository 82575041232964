import { connect } from 'react-redux';

import { paymentActions } from 'domain/payment';

import OrderPaymentAmountDialog from './OrderPaymentAmountDialog';

const mapDispatchToProps = (dispatch) => ({
  createPaymentTransaction: (payload, callback) =>
    dispatch(paymentActions.createPaymentTransaction(payload, callback)),
});

export default connect(null, mapDispatchToProps)(OrderPaymentAmountDialog);
