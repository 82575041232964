import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  prescriptionActions,
  prescriptionSelectors,
} from 'domain/prescription';
import appData from 'domain/app/HOCs/appData';
import EPrescriptionDialog from './EPrescriptionDialog';

const mapStateToProps = (state) => ({
  isSubmitting: prescriptionSelectors.isSubmittingPrescription(state),
  document: prescriptionSelectors.getEPrescriptionDocument(state),
});

const mapDispatchToProps = (dispatch) => ({
  createPrescription: (payload) =>
    dispatch(prescriptionActions.createPrescription(payload)),
  createDocument: (payload) =>
    dispatch(prescriptionActions.createEPrescriptionDocument(payload)),
  clearFile: () => dispatch(prescriptionActions.ePrescriptionDocumentCreated()),
});

export default compose(
  // Adds app-wide properties: clinics
  appData,
  connect(mapStateToProps, mapDispatchToProps)
)(EPrescriptionDialog);
