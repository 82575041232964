import get from 'lodash/get';
import { getCountryByCode } from 'core/country/utils';

import { FIELDS, DOCTOR_LICENSE_TYPES } from './constants';

export const getId = (doctor) => get(doctor, FIELDS.ID.name);

export const getCreatedAt = (doctor) => get(doctor, FIELDS.CREATED_AT.name);

export const getCreatedBy = (doctor) => get(doctor, FIELDS.CREATED_BY.name);

export const getUpdatedAt = (doctor) => get(doctor, FIELDS.UPDATED_AT.name);

export const getUpdatedBy = (doctor) => get(doctor, FIELDS.UPDATED_BY.name);

export const getUserId = (doctor) => get(doctor, FIELDS.USER_ID.name);

export const getSlotDuration = (doctor) =>
  get(doctor, FIELDS.SLOT_DURATION.name);

export const isActive = (doctor) => get(doctor, FIELDS.ACTIVE.name);

export const isCallEnabled = (doctor) => get(doctor, FIELDS.CALL_ENABLED.name);

export const getSlackMemberId = (doctor) =>
  get(doctor, FIELDS.SLACK_MEMBER_ID.name);

export const getLicenseNumber = (doctor) =>
  get(doctor, FIELDS.LICENSE_NUMBER.name);

export const getEnglishName = (doctor) => get(doctor, FIELDS.ENGLISH_NAME.name);

export const getArabicName = (doctor) => get(doctor, FIELDS.ARABIC_NAME.name);

export const getLicenseTitleArabic = (doctor) =>
  get(doctor, FIELDS.LICENSE_TITLE_ARABIC.name);

export const getEducationTitleArabic = (doctor) =>
  get(doctor, FIELDS.EDUCATION_TITLE_ARABIC.name);

export const getEducationTitleEnglish = (doctor) =>
  get(doctor, FIELDS.EDUCATION_TITLE_ENGLISH.name);

export const getLicenseTitleEnglish = (doctor) =>
  get(doctor, FIELDS.LICENSE_TITLE_ENGLISH.name);

export const getLicenseFile = (doctor) => get(doctor, FIELDS.LICENSE_FILE.name);

export const getStampFile = (doctor) => get(doctor, FIELDS.STAMP_FILE.name);

export const getProfilePictureFile = (doctor) =>
  get(doctor, FIELDS.PROFILE_PICTURE_FILE.name);

export const getSignatureFile = (doctor) =>
  get(doctor, FIELDS.SIGNATURE_FILE.name);

export const getLicenseExpiryDate = (doctor) =>
  get(doctor, FIELDS.LICENSE_EXPIRY_DATE.name);

export const getNationality = (doctor) => get(doctor, FIELDS.NATIONALITY.name);

export const getNationalityObject = (doctor) => {
  const countryCode = getNationality(doctor);

  if (countryCode) {
    return getCountryByCode(countryCode);
  }

  return null;
};

export const getLicenseType = (doctor) => get(doctor, FIELDS.LICENSE_TYPE.name);

export const getEducation = (doctor) => get(doctor, FIELDS.EDUCATION.name);

export const getYearsOfExperience = (doctor) =>
  get(doctor, FIELDS.YEARS_OF_EXPERIENCE.name);

export const getClinics = (doctor) => get(doctor, FIELDS.CLINICS.name) || [];

export const getDefaultClinic = (doctor) =>
  get(doctor, FIELDS.DEFAULT_CLINIC.name);

export const getLicenseTypeEnumValue = (doctor) =>
  get(DOCTOR_LICENSE_TYPES, getLicenseType(doctor));

export const getFileObject = (fileId) => {
  if (fileId) {
    return {
      id: fileId,
    };
  }

  return fileId;
};
