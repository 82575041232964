import * as types from './actionTypes';

export const deactivateAccount = (id) => ({
  type: types.DEACTIVATE_ACCOUNT,
  id,
});

export const createAlmaosUser = (payload) => ({
  type: types.CREATE_ALMAOS_USER,
  payload,
});

export const almaosUserCreated = (payload) => ({
  type: types.ALMAOS_USER_CREATED,
  payload,
});

export const almaosUserCreationResponded = (ok, response) => ({
  type: types.ALMAOS_USER_CREATION_RESPONDED,
  ok,
  response,
});

export const almaosUserDeactivatedResponded = (ok, response) => ({
  type: types.ALMAOS_USER_DEACTIVATE_RESPONDED,
  ok,
  response,
});

export const resetPassword = (userId) => ({
  type: types.RESET_PASSWORD,
  userId,
});

export const passwordResetResponded = (ok, response) => ({
  type: types.PASSWORD_RESET_RESPONDED,
  ok,
  response,
});

export const getAlmaosUser = (id) => ({
  type: types.GET_ALMAOS_USER,
  id,
});

export const almaosUserFetchingResponded = (ok, response) => ({
  type: types.ALMAOS_USER_FETCHING_RESPONDED,
  ok,
  response,
});

export const updateAlmaosUser = (id, payload) => ({
  type: types.UPDATE_ALMAOS_USER,
  id,
  payload,
});

export const almaosUserUpdateResponded = (ok, response) => ({
  type: types.ALMAOS_USER_UPDATE_RESPONDED,
  ok,
  response,
});
