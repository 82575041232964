import React from 'react';

import Grid from '../Grid';
import RowLayout from '../RowLayout';

/**
 * Distribute the children into rows and columns according to colCount
 */
const RowColLayout = ({
  // The children we want to distribute into rows and columns
  children,
  // Spacing between rows and columns
  spacing = 3,
  // Count of columns in a row
  colCount = 3,
  // Minimum height of a follower
  followerMinHeight,
}) => {
  // Children with fullRow props
  const fullRowChildren = children
    .filter(Boolean)
    .filter((child) => child.props.fullRow); // 1
  // Other children
  const filteredChildren = children
    .filter(Boolean)
    .filter((child) => !child.props.fullRow); // 1
  const { length } = filteredChildren; // 2
  // How many rows we need to distribute other children (filteredChildren)
  const rowCount = Math.ceil(length / colCount); // 3

  return (
    <Grid container column rowSpacing={spacing} flexWrap="nowrap">
      {/* For each row:
       * Check if there is a fullRow child which has rowIdx matching the row index.
       * Then render {colCount} children in RowLayout.
       */}
      {[...Array(rowCount)].map((_, idx) => (
        <>
          {(() => {
            const fullRowChild = fullRowChildren.find(
              (child) => child.props.rowIdx === idx
            );

            if (fullRowChild) {
              return (
                <Grid item xs={12}>
                  {fullRowChild}
                </Grid>
              );
            }

            return null;
          })()}
          <Grid item>
            <RowLayout
              columnSpacing={spacing}
              followerMinHeight={followerMinHeight}
            >
              {[...Array(colCount)].map(
                (__, rIdx) => filteredChildren?.[idx * colCount + rIdx] || null
              )}
            </RowLayout>
          </Grid>
        </>
      ))}
      {/*
       * How about full-row children that didn't match a row index?
       *
       * Show them in order after the last row. Those with rowIdx = undefined, show them last
       */}
      {(() => {
        return fullRowChildren
          .filter(
            (child) =>
              child.props.rowIdx === undefined || child.props.rowIdx > rowCount
          )
          .sort((childA, childB) => {
            // Sort by rowIdx from low to high
            // If rowIdx is undefined, make it last
            if (childB.rowIdx !== undefined) {
              return childA.a - childB.a;
            }

            return -1;
          })
          .map((child) => (
            <Grid item xs={12}>
              {child}
            </Grid>
          ));
      })()}
    </Grid>
  );
};

export default RowColLayout;
