import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import {
  userManagementActions,
  userManagementSelectors,
} from 'domain/userManagement';

import UserManagementView from './UserManagementView';

const mapStateToProps = (state) => {
  const userCredentials = userManagementSelectors.getAlmaosUser(state);
  const isFetching = userManagementSelectors.isFetchingAlmaosUser(state);
  const isSubmitting = userManagementSelectors.isSubmittingAlmaosUser(state);

  return {
    isFetching,
    isSubmitting,
    userCredentials,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAlmaOsUser: (id) => dispatch(userManagementActions.getAlmaOsUser(id)),
  updateAlmaOsUser: (id, payload) =>
    dispatch(userManagementActions.updateAlmaOsUser(id, payload)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(UserManagementView)
);
