import { connect } from 'react-redux';
import { userManagementActions } from 'domain/userManagement';

import UserManagement from './UserManagement';

const mapDispatchToProps = (dispatch) => ({
  deactivateAccount: (id) => {
    dispatch(userManagementActions.deactivateAccount(id));
  },
  resetPassword: (userId) => {
    dispatch(userManagementActions.resetPassword(userId));
  },
});

export default connect(null, mapDispatchToProps)(UserManagement);
