import { PAYMENT_STATUS } from './constants';
import * as types from './actionTypes';

const initialState = {
  view: {
    isFetching: false,
    isSubmitting: false,
    paymentTransaction: null,
  },
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENT_TRANSACTION_RECEIVED: {
      return {
        ...state,
        view: {
          ...state.view,
          paymentTransaction: action.paymentTransaction,
        },
      };
    }
    case types.PAYMENT_TRANSACTION_CANCELLED: {
      return {
        ...state,
        view: {
          ...state.view,
          paymentTransaction: {
            ...state.view.paymentTransaction,
            status: PAYMENT_STATUS.CANCELLED,
          },
        },
      };
    }
    case types.SET_FETCHING_PAYMENT_TRANSACTION: {
      return {
        ...state,
        view: {
          ...state.view,
          isFetching: action.isFetching,
        },
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
