/* InsuranceProvider constants */
export const PACKAGE_NAME = 'InsuranceProvider';

export const FIELDS = {
  ENGLISH_NAME: {
    name: 'englishName',
  },
  ARABIC_NAME: {
    name: 'arabicName',
  },
  DISCOUNT: {
    name: 'discount',
  },
};
