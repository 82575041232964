import React from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import { labConstants } from 'domain/lab';
import { dateTimeFormat } from 'constants/dateTime';
import { UserInfoLink } from 'presentation/components';
import { Chip, Tooltip, Typography, IconButton, Grid } from 'design-system';

const { STATUSES } = labConstants;

export const STATUS_COLUMN = {
  field: 'status',
  headerName: 'Status',
  flex: 1,
  renderCell: (params) => {
    const { status } = params.row;

    if (!status) {
      return '---';
    }

    return (
      <Tooltip placement="top" title="Status">
        <Chip
          sx={{ minWidth: 100 }}
          variant="filled"
          soak="light"
          label={STATUSES[status]?.label}
          color={STATUSES[status]?.color}
        />
      </Tooltip>
    );
  },
};

export const getColumns = () =>
  [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'userId',
      renderHeader: () => (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="l4">Patient Info</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="l4">Phone Number</Typography>
          </Grid>
        </Grid>
      ),
      flex: 2,
      renderCell: (params) => {
        const { userId } = params.row;

        return <UserInfoLink userId={userId} />;
      },
    },
    STATUS_COLUMN,
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) =>
        params.row.createdAt
          ? format(parseISO(params.row.createdAt), dateTimeFormat)
          : '-',
    },
    {
      field: 'actions',
      headerName: '',
      width: 80,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: () => (
        <IconButton>
          <MessageOutlinedIcon fontSize="small" color="secondary" />
        </IconButton>
      ),
    },
  ].map((item) => ({
    // TODO: When sorting is supported in the lab request MS, remove that map
    ...item,
    sortable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
  }));
