import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import { takeSequential } from 'core/saga';

import * as actions from './actions';
import * as types from './actionTypes';
import {
  DOCTOR_SCHEDULE_ENDPOINT,
  AVAILABILITY_RANGE_ENDPOINT,
  BOOKING_AVAILABILITY_ENDPOINT,
  DOCTOR_BOOKING_AVAILABILITY_ENDPOINT,
  DOCTOR_FREE_SLOTS_SCHEDULE_ENDPOINT,
  FREE_SLOTS_SCHEDULE_ENDPOINT,
} from './constants';

export function* fetchDoctorSchedules() {
  try {
    const response = yield call(httpClient.get, DOCTOR_SCHEDULE_ENDPOINT);

    yield put(actions.doctorSchedulesFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorSchedulesFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}
export function* fetchDoctorFreeScheduleSlots({
  doctorId,
  dayOfWeekDate,
  dayOfWeek,
}) {
  try {
    const response = yield call(
      httpClient.get,
      DOCTOR_FREE_SLOTS_SCHEDULE_ENDPOINT(doctorId, dayOfWeekDate, dayOfWeek)
    );
    yield put(
      actions.doctorFreeSchedulesSlotsFetchResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.doctorFreeSchedulesSlotsFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}
export function* fetchDoctorBookingAvailability({ doctorId, pageDate }) {
  try {
    const response = yield call(
      httpClient.get,
      DOCTOR_BOOKING_AVAILABILITY_ENDPOINT(doctorId, pageDate),
      {
        params: {
          startDate: pageDate,
        },
      }
    );

    yield put(
      actions.doctorBookingAvailabilityFetchResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.doctorBookingAvailabilityFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchDailyBookingAvailability({ date }) {
  try {
    const response = yield call(httpClient.get, BOOKING_AVAILABILITY_ENDPOINT, {
      params: {
        date,
      },
    });

    yield put(
      actions.dailyBookingAvailabilityFetchResponded(true, response.data)
    );
  } catch (e) {
    yield put(
      actions.dailyBookingAvailabilityFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* removeAvailabilityRange({ rangeId }) {
  try {
    yield call(
      httpClient.delete,
      [AVAILABILITY_RANGE_ENDPOINT, rangeId].join('/')
    );

    yield put(actions.availabilityRangeRemovalResponded(true));
  } catch (e) {
    yield put(actions.availabilityRangeRemovalResponded(false));
  }
}
export function* removeFreeSlotRange({
  doctorId,
  startTime,
  endTime,
  dayOfWeek,
}) {
  try {
    yield call(httpClient.put, FREE_SLOTS_SCHEDULE_ENDPOINT, {
      doctorId,
      startTime,
      endTime,
      dayOfWeek,
    });

    yield put(actions.freeSlotRangeRemovalResponded(true));
  } catch (e) {
    yield put(actions.freeSlotRangeRemovalResponded(false));
  }
}

export function* addAvailabilityRange({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      AVAILABILITY_RANGE_ENDPOINT,
      payload
    );

    yield put(actions.availabilityRangeAddResponded(true, response));
  } catch (e) {
    yield put(
      actions.availabilityRangeAddResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export default function* scheduleModelSaga() {
  yield all([
    takeLatest(types.FETCH_DOCTOR_SCHEDULES, fetchDoctorSchedules),
    takeSequential(types.ADD_AVAILABILITY_RANGE, addAvailabilityRange),
    takeSequential(types.REMOVE_AVAILABILITY_RANGE, removeAvailabilityRange),
    takeLatest(
      types.FETCH_DOCTOR_BOOKING_AVAILABILITY,
      fetchDoctorBookingAvailability
    ),
    takeLatest(
      types.FETCH_DAILY_BOOKING_AVAILABILITY,
      fetchDailyBookingAvailability
    ),
    takeLatest(
      types.FETCH_DOCTOR_FREE_SCHEDULE_SLOTS,
      fetchDoctorFreeScheduleSlots
    ),
    takeLatest(types.REMOVE_FREE_SLOT_RANGE, removeFreeSlotRange),
  ]);
}
