import set from 'date-fns/set';
import add from 'date-fns/add';
import keys from 'lodash/keys';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import getYear from 'date-fns/getYear';
import getDate from 'date-fns/getDate';
import getMonth from 'date-fns/getMonth';
import parseISO from 'date-fns/parseISO';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MessageIcon from '@mui/icons-material/Message';
import BiotechIcon from '@mui/icons-material/Biotech';
import React, { useMemo, useState, useEffect } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import VideoCallOutlined from '@mui/icons-material/VideoCallOutlined';

import { userGetters } from 'domain/user';
import { fileGetters } from 'domain/file';
import Comments from 'presentation/comment';
import { isAllowed } from 'core/permission';
import { authSelectors } from 'domain/auth';
import FileUpload from 'core/file/FileUpload';
import ELab from 'presentation/lab/components/ELab';
import { useDeviceSize } from 'design-system/hooks';
import { REFERENCE_TYPES } from 'constants/general';
import { PaymentSection } from 'presentation/payment';
import { OutcomeManagement } from 'presentation/outcome';
import OwnerInfo from 'presentation/components/OwnerInfo';
import { OUTCOME_OPTIONS } from 'domain/outcome/constants';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import PatientInfo from 'presentation/components/PatientInfo';
import { timeFormat, dateTimeFormat } from 'constants/dateTime';
import { patientHistoryConstants } from 'domain/patientHistory';
import LatestDietPlan from 'presentation/dietPlan/LatestDietPlan';
import { isMidnight } from 'core/utils/date';
import ActionRequired from 'presentation/components/ActionRequired';
import { PatientHistorySection } from 'presentation/patientHistory';
import { EPrescription } from 'presentation/ePrescription/components';
import BookingAvailability from 'presentation/schedule/BookingAvailability';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';
import EligibilitySection from 'presentation/eligibility/EligibilitySection';
import DateTimeRangePicker from 'presentation/components/DateTimeRangePicker';
import {
  isElabShown,
  isEPrescriptionEnabled,
  isSendPrescriptionEnabled,
  isShowEligibilitySection,
  getWlpConsultationTypeId,
  isOutcomeManagementEnabled,
  isSendRequestLabTestsEnabled,
  getDefaultCountryCodeLong,
  getDefaultTimeZone,
} from 'core/siteConfig';
import {
  consultationUtils as utils,
  consultationGetters as getters,
  CONSULTATION_DOMAIN_FIELDS as FIELDS,
  consultationConstants as constants,
} from 'domain/consultation';
import {
  If,
  Box,
  Else,
  Grid,
  Popup,
  Card,
  Select,
  ElseIf,
  Button,
  Tooltip,
  Divider,
  MenuItem,
  FormField,
  CardHeader,
  Typography,
  Pagination,
  CardContent,
  CardActions,
  RowColLayout,
  DisplayField,
  Conditionally,
  Switch,
} from 'design-system';

// import AddLabTest from './components/AddLabTest';
import OutcomeSection from './components/OutcomeSection';
import SendNotification from './components/SendNotification';
import AddLabTestDialog from './components/AddLabTestDialog';
// import SendPrescription from './components/SendPrescription';
import ParticipantSection from './components/ParticipantSection';
import MemberSelection from './components/ConsultationMemberSelection';
import SendPrescriptionsDialog from './components/SendPrescriptionsDialog';
import { isOutputSectionCompleted } from '../utils';

const { STATUSES, CONSULTATION_ATTENDEE, MEETING_COMMUNICATION_METHOD } =
  constants;

function ConsultationView({
  // State
  owner,
  doctors,
  tenants,
  labTests,
  isFetching,
  isSubmitting,
  consultation,
  isFetchingOwner,
  isFetchingMembers,
  isUpdatingPatient,
  consultationPatients,
  patientsUserInfoById,
  isFetchingOwnerHealthProfile,
  patientsHealthProfilesByUserId,
  joinConsultationMeetingStarted,
  isFetchingMembersHealthProfiles,
  // Actions
  clear,
  fetchOwner,
  getConsultation,
  updateConsultation,
  fetchPatientsUserInfo,
  fetchOwnerHealthProfile,
  fetchPatientsHealthProfiles,
}) {
  const params = useParams();
  const { isMobile, isMedium } = useDeviceSize();
  const id = params?.id ? Number(params.id) : undefined;

  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);
  const { isDoctor } = useSelector(authSelectors.getRole);
  const showEligibilitySection =
    isAllowed('consultationView', 'eligibilitySection', 'view') &&
    isShowEligibilitySection();

  const [bookedSlots, setBookedSlots] = useState([]);
  const [hasFormChanged, setFormChanged] = useState(false);
  const [consultationDate, setConsultationDate] = useState();
  const [statusSelectColor, setStatusSelectColor] = useState();
  const [hasDocumentsChanged, setDocumentsChanged] = useState(false);
  const [ePrescriptionKey, setEPrescriptionKey] = useState(Date.now());
  const [isUserProfilesFetched, setUserProfilesFetched] = useState(false);
  const [isHealthProfilesFetched, setHealthProfilesFetched] = useState(false);
  const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [isPrescriptionDialogOpen, setPrescriptionDialogOpen] = useState(false);

  // Fields' states
  const [user, setUser] = useState();
  const [patients, setPatients] = useState([]);
  const [doctor, setDoctor] = useState();
  const [status, setStatus] = useState('');
  const [endTime, setEndTime] = useState();
  const [startTime, setStartTime] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [updatedAt, setUpdatedAt] = useState();
  const [updatedBy, setUpdatedBy] = useState();
  const [documents, setDocuments] = useState();
  const [namespace, setNamespace] = useState();
  const [userNotes, setUserNotes] = useState('');
  const [meetingLink, setMeetingLink] = useState();
  const [participants, setParticipants] = useState();
  const [tenant, setTenant] = useState(defaultTenant);
  const [documentsWithId, setDocumentsWithId] = useState([]);
  const [consultationType, setConsultationType] = useState('');
  const [associatedOrderId, setAssociatedOrderId] = useState();
  const [consultationChannel, setConsultationChannel] = useState('');
  const [hasPaymentTransaction, setHasPaymentTransaction] = useState(false);
  const [consultationTypeDetails, setConsultationTypeDetails] = useState('');
  const [attendee, setAttendee] = useState(CONSULTATION_ATTENDEE.PERSONAL.key);
  const [isEligibilityCheckRequested, setEligibilityCheckRequested] =
    useState(false);
  const [consultationTypeId, setConsultationTypeId] = useState(null);
  const [patientNumber, setPatientNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLabTestDialogOpen, setLabTestDialogOpen] = useState(false);
  const [isEditConfirmDialogOpen, setEditConfirmDialogOpen] = useState(false);
  const [meetingCommunicationMethod, setMeetingCommunicationMethod] =
    useState();
  const [enableCalls, setEnableCalls] = useState(false);
  const [isPaidByUser, setIsPaidByUser] = useState();

  useEffect(() => {
    getConsultation(id);

    return () => {
      clear();
    };
  }, [id]);

  useEffect(() => {
    if (isFetching) {
      setIsLoading(true);
    }
  }, [isFetching]);

  useEffect(() => {
    setEPrescriptionKey(Date.now());
    if (!isEmpty(consultation) && id === getters.getId(consultation)) {
      const endTimestamp = getters.getEndTime(consultation);
      const consultationUser = getters.getUser(consultation);
      const startTimestamp = getters.getStartTime(consultation);
      const meetingNumber = getters.getMeetingNumber(consultation);
      const meetingPassword = getters.getMeetingPassword(consultation);
      const meetingEncryptedPassword =
        getters.getMeetingEncryptedPassword(consultation);
      setUser(consultationUser);
      setConsultationTypeId(getters.getConsultationTypeId(consultation));
      setDoctor(getters.getDoctor(consultation));
      setPatients(getters.getPatients(consultation));
      setStatus(getters.getStatus(consultation));
      setTenant(getters.getTenant(consultation));
      setUserNotes(getters.getUserNotes(consultation));
      setDocuments(
        getters.getDocuments(consultation).map((file) => ({ id: file }))
      );
      setConsultationType(getters.getConsultationType(consultation));
      setEndTime(set(new Date(endTimestamp), { seconds: 0, milliseconds: 0 }));
      setStartTime(
        set(new Date(startTimestamp), { seconds: 0, milliseconds: 0 })
      );
      setConsultationTypeDetails(
        getters.getConsultationTypeDetails(consultation)
      );
      setConsultationChannel(getters.getConsultationChannel(consultation));
      setIsPaidByUser(getters.getIsPaidByUser(consultation));

      const associatedOrderIds = getters.getAssociatedOrderIds(consultation);
      if (associatedOrderIds && associatedOrderIds.length > 0) {
        setAssociatedOrderId(Math.max(...associatedOrderIds));
      } else {
        setAssociatedOrderId(null);
      }

      if (meetingNumber) {
        setMeetingLink(
          `https://zoom.us/j/${meetingNumber}?pwd=${
            meetingEncryptedPassword || meetingPassword
          }`
        );
      }
      setCreatedBy(
        userGetters.getFullName(getters.getCreatedByUser(consultation))
      );
      setUpdatedBy(
        userGetters.getFullName(getters.getUpdatedByUser(consultation))
      );
      setCreatedAt(
        format(parseISO(getters.getCreatedAt(consultation)), dateTimeFormat)
      );
      setUpdatedAt(
        format(parseISO(getters.getUpdatedAt(consultation)), dateTimeFormat)
      );

      setConsultationDate(new Date(startTimestamp));
      setParticipants(getters.getParticipants(consultation));
      setNamespace(getters.getNamespace(consultation));
      setAttendee(getters.getAttendee(consultation));
      setHasPaymentTransaction(getters.hasPaymentTransaction(consultation));
      setEligibilityCheckRequested(
        getters.isEligibilityCheckRequested(consultation)
      );
    }
    setIsLoading(false);
    setMeetingCommunicationMethod(
      getters.getMeetingCommunicationMethod(consultation)
    );
    setEnableCalls(getters.getEnableCalls(consultation));
  }, [id, consultation]);

  useEffect(() => {
    if (status) {
      setStatusSelectColor(STATUSES[status].color);
    }
  }, [status]);

  useEffect(() => {
    if (
      !isEmpty(user) &&
      // !isUserProfilesFetched is to make sure this fetch is executed once
      !isUserProfilesFetched &&
      !isEmpty(consultationPatients)
    ) {
      setUserProfilesFetched(true);
      // Initiate fetching patients user info.
      // The data will be used in patient info card and submit outcome dialog
      fetchPatientsUserInfo(
        userGetters.getId(user),
        consultationPatients
          .filter((patient) => patient.userId !== user.id)
          .map((patient) => patient.userId)
      );
      fetchOwner(userGetters.getId(user));
    }
  }, [
    user,
    fetchOwner,
    consultationPatients,
    isUserProfilesFetched,
    fetchPatientsUserInfo,
  ]);

  useEffect(() => {
    if (
      !isEmpty(user) &&
      // !isHealthProfilesFetched is to make sure this fetch is executed once
      !isHealthProfilesFetched &&
      !isEmpty(consultationPatients)
    ) {
      setHealthProfilesFetched(true);
      // Initiate fetching patients health profiles.
      // The data will be used in patient info card and submit outcome dialog
      fetchPatientsHealthProfiles(
        userGetters.getId(user),
        consultationPatients
          .filter((patient) => patient.userId !== user.id)
          .map((patient) => patient.userId)
      );
      fetchOwnerHealthProfile(userGetters.getId(user));
    }
  }, [
    user,
    consultationPatients,
    fetchOwnerHealthProfile,
    isHealthProfilesFetched,
    fetchPatientsHealthProfiles,
  ]);

  const isManualSchedule = useMemo(() => {
    return (
      !doctor ||
      status !== STATUSES.CONFIRMED.key ||
      status !== STATUSES.RESERVED.key ||
      status !== STATUSES.PENDING_APPROVAL.key
    );
  }, [doctor, status]);

  const isSubmitButtonDisabled = useMemo(() => {
    return (
      isFetching ||
      !hasFormChanged ||
      documents.length !== documentsWithId.length ||
      (utils.isDoctorRequired(status) && !userGetters.getId(doctor)) ||
      (!isDoctor && !isManualSchedule && isEmpty(bookedSlots))
    );
  }, [
    status,
    doctor,
    isDoctor,
    documents,
    isFetching,
    bookedSlots,
    hasFormChanged,
    documentsWithId,
    isManualSchedule,
  ]);

  const toggleFormChangedStatus = (isEnabled) => {
    if (!isFetching) {
      setFormChanged(isEnabled);
    }
  };

  const isStatusDisabled = (itemStatus) => {
    // Rule1: disable completed: If consultation output not complete
    return (
      itemStatus === STATUSES.COMPLETED.key &&
      !isOutputSectionCompleted(consultationPatients)
    );
  };
  const getTime = (time) => {
    if (consultationDate) {
      const date = getDate(consultationDate);
      const year = getYear(consultationDate);
      const month = getMonth(consultationDate);

      return set(time, { year, month, date });
    }
    return time;
  };

  /**
   *
   * @param {*} noShowConfirmed:true= user passes the validation of changing consultation to no show
   * @returns
   */
  const submitChangesConfirmed = (noShowConfirmed) => {
    if (
      status === STATUSES.NO_SHOW.key &&
      !noShowConfirmed &&
      isOutputSectionCompleted(consultationPatients)
    ) {
      // Confirm no show in case consultation has output
      setEditConfirmDialogOpen(true);
      return;
    }
    const date = getDate(consultationDate);
    const year = getYear(consultationDate);
    const month = getMonth(consultationDate);

    const fullStartTime = set(startTime, { year, month, date });
    let fullEndTime = set(endTime, { year, month, date });
    if (isMidnight(fullEndTime)) {
      fullEndTime = add(fullEndTime, { days: 1 });
    }

    setStartTime(fullStartTime);
    setEndTime(fullEndTime);

    const payload = {
      [FIELDS.ID.name]: id,
      [FIELDS.STATUS.name]: status,
      [FIELDS.END_TIME.name]:
        bookedSlots.last()?.endTime || fullEndTime.toISOString(),
      [FIELDS.DOCTOR_ID.name]: userGetters.getId(doctor),
      [FIELDS.START_TIME.name]:
        bookedSlots[0]?.startTime || fullStartTime.toISOString(),
      [FIELDS.DOCUMENTS.name]: hasDocumentsChanged
        ? documentsWithId.map((file) => fileGetters.getId(file))
        : null,
      [FIELDS.TENANT_KEY.name]: tenant,
      [FIELDS.ATTENDEE.name]: attendee,
    };
    const documentsChanged = hasDocumentsChanged;
    setDocumentsChanged(false);
    setEditConfirmDialogOpen(false);

    updateConsultation(
      id,
      payload,
      () => {
        setFormChanged(false);
      },
      () => {
        setDocumentsChanged(documentsChanged);
      }
    );
  };
  const submitChanges = () => {
    submitChangesConfirmed(false);
  };

  return (
    <>
      <Grid container spacing={3} column>
        <Grid item sx={{ width: '100%' }}>
          <RowColLayout>
            {/* ConsultationInfo */}
            <Card
              key="consultation-info"
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardHeader
                title={`Consultation #${id}`}
                subheader={namespace?.id > 1 ? namespace?.name : ''} // Don't show clinic when its the default(Alma)
                sx={{ pb: 0 }}
                action={
                  MEETING_COMMUNICATION_METHOD.PHONE_CALL.key ===
                  meetingCommunicationMethod ? (
                    <Tooltip
                      autoHide
                      injectWrapper
                      placement="top"
                      title="The consultation was conducted over a phone call."
                    >
                      <PhoneOutlinedIcon color="black" fontSize="small" />
                    </Tooltip>
                  ) : (
                    MEETING_COMMUNICATION_METHOD.ZOOM_MEETING.key ===
                      meetingCommunicationMethod && (
                      <Tooltip
                        autoHide
                        injectWrapper
                        placement="top"
                        title="The consultation was conducted via Zoom"
                      >
                        <VideoCallOutlined color="black" fontSize="small" />
                      </Tooltip>
                    )
                  )
                }
              />
              <CardContent
                noPadding
                sx={{
                  p: 2,
                  height: '100%',
                }}
              >
                <Grid container column rowSpacing={1} pb={3}>
                  <Conditionally>
                    <If condition={isAllowed('consultationView', 'editBranch')}>
                      <>
                        <Grid item>
                          <Typography
                            variant="p4"
                            loading={isFetching && { width: 80 }}
                          >
                            BRANCH
                          </Typography>
                        </Grid>
                        <Grid item sx={{ mb: 1 }}>
                          <Select
                            fullWidth
                            size="large"
                            soak="light"
                            variant="filled"
                            color="primary"
                            value={tenant}
                            loading={isFetching}
                            onChange={(event) => {
                              setTenant(event.target.value);
                              toggleFormChangedStatus(true);
                            }}
                          >
                            {(tenants || []).map((option) => (
                              <MenuItem value={option.id} key={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      </>
                    </If>
                    <Else>
                      <DisplayField
                        label="BRANCH"
                        labelWidth={60}
                        valueWidth={100}
                        loading={isFetching}
                        value={
                          tenants?.find((item) => item.id === tenant)?.name
                        }
                      />
                    </Else>
                  </Conditionally>

                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                    >
                      CONSULTATION TYPE
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: consultationTypeDetails ? 0 : 1 }}>
                    <Typography
                      variant="l5"
                      loading={isFetching && { width: 200 }}
                    >
                      {consultationType || 'N/A'}
                    </Typography>
                  </Grid>
                  {(consultationTypeDetails || isFetching) && (
                    <Grid item sx={{ mb: 1 }}>
                      <Typography
                        variant="l5"
                        loading={isFetching && { width: 200 }}
                      >
                        {consultationTypeDetails}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item sx={{ mb: 1 }}>
                    <Conditionally>
                      <If
                        condition={isAllowed('consultationView', 'editStatus')}
                      >
                        <>
                          <Grid item>
                            <Typography
                              variant="p4"
                              loading={isFetching && { width: 50 }}
                            >
                              STATUS
                            </Typography>
                          </Grid>
                          <Select
                            fullWidth
                            size="large"
                            soak="light"
                            value={status}
                            variant="filled"
                            loading={isFetching}
                            color={statusSelectColor}
                            placeholder="Update status..."
                            onChange={(event) => {
                              const newValue = event.target.value;

                              setStatus(newValue);
                              toggleFormChangedStatus(true);

                              if (newValue !== STATUSES.CONFIRMED.key) {
                                setBookedSlots([]);
                              }
                            }}
                            renderValue={(value) =>
                              value ? STATUSES[value].label : value
                            }
                          >
                            {keys(STATUSES).map((item) => (
                              <MenuItem
                                value={item}
                                key={item}
                                disabled={isStatusDisabled(item)}
                              >
                                {STATUSES[item]?.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </>
                      </If>
                      <Else>
                        <DisplayField
                          label="STATUS"
                          value={getters.getStatus(consultation)}
                          labelWidth={60}
                          valueWidth={170}
                          loading={isFetching}
                        />
                      </Else>
                    </Conditionally>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 50 }}
                    >
                      CONSULTATION CHANNEL
                    </Typography>
                  </Grid>
                  <Grid item sx={{ mb: 1 }}>
                    <Typography
                      variant="l5"
                      loading={isFetching && { width: 200 }}
                    >
                      {consultationChannel || 'N/A'}
                    </Typography>
                  </Grid>

                  {(utils.isDoctorRequired(status) ||
                    utils.isPendingStatuses(status, doctor)) && (
                    <Conditionally>
                      <If
                        condition={!isAllowed('consultationView', 'editDoctor')}
                      >
                        <DisplayField
                          label="DOCTOR"
                          value={(() => {
                            if (!doctor) {
                              return 'No doctor has been assigned yet.';
                            }

                            return userGetters.getFullName(doctor);
                          })()}
                          labelWidth={60}
                          valueWidth={150}
                          loading={isFetching}
                        />
                      </If>
                      <Else>
                        <>
                          <Grid item>
                            <Typography
                              variant="p4"
                              loading={isFetching && { width: 50 }}
                            >
                              DOCTOR
                            </Typography>
                          </Grid>

                          <Grid item sx={{ mb: 1 }}>
                            <Select
                              fullWidth
                              size="large"
                              soak="light"
                              value={doctor}
                              variant="filled"
                              loading={isFetching}
                              placeholder="Select a doctor..."
                              onChange={(event) => {
                                setDoctor(event.target.value);
                                toggleFormChangedStatus(true);
                              }}
                              renderValue={(value) =>
                                value ? userGetters.getFullName(value) : value
                              }
                            >
                              {doctors.map((item) => (
                                <MenuItem
                                  value={item}
                                  key={userGetters.getFullName(item)}
                                >
                                  {userGetters.getFullName(item)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </>
                      </Else>
                    </Conditionally>
                  )}
                  {/* ATTENDEES */}
                  {isAllowed('consultationView', 'attendee', 'view') && (
                    <Conditionally>
                      <If
                        condition={isAllowed(
                          'consultationView',
                          'attendee',
                          'edit'
                        )}
                      >
                        <>
                          <Grid item sx={{ mb: 1 }}>
                            <Typography
                              variant="p4"
                              loading={isFetching && { width: 50 }}
                            >
                              ATTENDEES
                            </Typography>
                          </Grid>
                          <Grid item sx={{ mb: 3 }}>
                            <Select
                              fullWidth
                              size="large"
                              soak="light"
                              value={attendee}
                              variant="filled"
                              loading={isFetching}
                              placeholder="Select attendee..."
                              onChange={(event) => {
                                setAttendee(event.target.value);
                                toggleFormChangedStatus(true);
                              }}
                              renderValue={(value) =>
                                CONSULTATION_ATTENDEE[value]?.label || value
                              }
                            >
                              {keys(CONSULTATION_ATTENDEE).map((item) => (
                                <MenuItem value={item} key={item}>
                                  {CONSULTATION_ATTENDEE[item]?.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                        </>
                      </If>
                      <Else>
                        <DisplayField
                          label="ATTENDEES"
                          value={
                            CONSULTATION_ATTENDEE[attendee]?.label || attendee
                          }
                          labelWidth={90}
                          valueWidth={170}
                          loading={isFetching}
                        />
                      </Else>
                    </Conditionally>
                  )}
                  {/* PATIENTS */}
                  {isAllowed('consultationView', 'patients', 'view') && (
                    <>
                      <Grid item sx={{ mb: 1 }}>
                        <Typography
                          variant="p4"
                          loading={isFetching && { width: 80 }}
                        >
                          PATIENTS
                        </Typography>
                      </Grid>
                      <MemberSelection
                        owner={{
                          id: userGetters.getId(user),
                          fullName: userGetters.getFullName(user),
                        }}
                        referenceId={id}
                        loading={isFetching}
                        members={patients}
                        size="small"
                        permissions={{
                          edit: isAllowed(
                            'consultationView',
                            'patients',
                            'edit'
                          ),
                          delete: isAllowed(
                            'consultationView',
                            'patients',
                            'delete'
                          ),
                          hideShow: false,
                        }}
                        onMemberCreated={(member) => {
                          // Fetch new member's health profile
                          fetchPatientsHealthProfiles(userGetters.getId(user), [
                            member.id,
                          ]);
                        }}
                        onMembersUpdated={
                          (/* patients */) => {
                            // Enable fetching in useEffect
                            setUserProfilesFetched(false);
                            setHealthProfilesFetched(false);
                          }
                        }
                      />
                    </>
                  )}
                  {/* Time */}
                  {isAllowed('consultationView', 'time', 'view') && (
                    <Conditionally>
                      <If
                        condition={
                          !isAllowed('consultationView', 'time', 'edit')
                        }
                      >
                        <DisplayField
                          label="CONSULTATION TIME"
                          value={(() => {
                            if (!startTime) {
                              return 'Consultation time is not selected yet.';
                            }

                            return `${format(
                              startTime,
                              dateTimeFormat
                            )} - ${format(endTime, timeFormat)}`;
                          })()}
                          labelWidth={170}
                          valueWidth={150}
                          loading={isFetching}
                        />
                      </If>
                      <ElseIf
                        condition={
                          status === STATUSES.CONFIRMED.key ||
                          status === STATUSES.RESERVED.key ||
                          status === STATUSES.PENDING_APPROVAL.key
                        }
                      >
                        <FormField
                          labelWidth={170}
                          label="CONSULTATION TIME"
                          field={
                            <BookingAvailability
                              key={id}
                              doctor={doctor}
                              value={bookedSlots}
                              consultationId={id}
                              endTime={getTime(endTime)}
                              startTime={getTime(startTime)}
                              onConfirm={(
                                slots,
                                selectedDoctor,
                                toggleForm = true
                              ) => {
                                setBookedSlots(slots);
                                setDoctor(selectedDoctor);
                                if (toggleForm) {
                                  toggleFormChangedStatus(true);
                                }
                              }}
                            />
                          }
                        />
                      </ElseIf>
                      <Else>
                        <DateTimeRangePicker
                          endTime={endTime}
                          title="CONSULTATION"
                          startTime={startTime}
                          date={consultationDate}
                          isFetching={isFetching}
                          onDateChange={(newValue) => {
                            setConsultationDate(newValue);
                            toggleFormChangedStatus(true);
                          }}
                          onStartTimeChange={(newValue) => {
                            const time = set(newValue, {
                              seconds: 0,
                              milliseconds: 0,
                            });

                            setStartTime(time);
                            // Less than 30 minutes by default to allow adding consecutive consulations
                            setEndTime(add(time, { minutes: 28 }));
                            toggleFormChangedStatus(true);
                          }}
                          onEndTimeChange={(newValue) => {
                            const time = set(newValue, {
                              seconds: 0,
                              milliseconds: 0,
                            });

                            setEndTime(time);
                            toggleFormChangedStatus(true);
                          }}
                        />
                        <Grid
                          item
                          container
                          flexWrap="nowrap"
                          alignItems="center"
                          sx={{
                            mb: 1,
                          }}
                        >
                          <Grid
                            item
                            column
                            container
                            sx={{ width: '100%', gap: '10px' }}
                          >
                            <Typography
                              variant="p4"
                              loading={isFetching && { width: 50 }}
                            >
                              {getDefaultCountryCodeLong()} START TIME
                            </Typography>
                            <Typography
                              variant="l5"
                              loading={isFetching && { width: 200 }}
                            >
                              {startTime &&
                                formatInTimeZone(
                                  startTime,
                                  getDefaultTimeZone(),
                                  timeFormat
                                )}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            column
                            container
                            sx={{ width: '100%', gap: '10px' }}
                          >
                            <Typography
                              variant="p4"
                              loading={isFetching && { width: 50 }}
                            >
                              {getDefaultCountryCodeLong()} END TIME
                            </Typography>
                            <Typography
                              variant="l5"
                              loading={isFetching && { width: 200 }}
                            >
                              {endTime &&
                                formatInTimeZone(
                                  endTime,
                                  getDefaultTimeZone(),
                                  timeFormat
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Else>
                    </Conditionally>
                  )}

                  {isAllowed('consultationView', 'documents', 'view') && (
                    <>
                      <Grid item>
                        <Typography
                          variant="p4"
                          loading={isFetching && { width: 80 }}
                        >
                          DOCUMENTS
                        </Typography>
                      </Grid>

                      <Grid item sx={{ mb: 1 }}>
                        <FileUpload
                          multiple
                          files={documents}
                          loading={isFetching}
                          userId={userGetters.getId(user)}
                          onChange={(
                            files,
                            isActualChange,
                            _completeFiles,
                            filesWithServerId
                          ) => {
                            setDocuments(files);
                            setDocumentsWithId(filesWithServerId);

                            if (isActualChange) {
                              toggleFormChangedStatus(true);
                              setDocumentsChanged(true);
                            }
                          }}
                          config={{
                            deletable: isAllowed(
                              'consultationView',
                              'documents',
                              'edit'
                            ),
                            readonly: !isAllowed(
                              'consultationView',
                              'documents',
                              'edit'
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 130 }}
                    >
                      Consultation Created
                    </Typography>
                  </Grid>

                  <UpdatedOrCreatedBy
                    isFetching={isFetching}
                    user={createdBy}
                    time={createdAt}
                  />

                  <Grid item>
                    <Typography
                      variant="p4"
                      loading={isFetching && { width: 90 }}
                    >
                      Last Updated
                    </Typography>
                  </Grid>
                  <UpdatedOrCreatedBy
                    isFetching={isFetching}
                    user={updatedBy}
                    time={updatedAt}
                  />
                  {isAllowed('consultation', 'editConsultation') && (
                    <Grid item sx={{ mt: 1 }}>
                      <Button
                        variant="filled"
                        onClick={submitChanges}
                        spinning={isSubmitting}
                        loading={isFetching && { width: 160 }}
                        disabled={isSubmitButtonDisabled}
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
            <Box key="user-account-and-patient-info-and-diet-plan">
              {/* user-account */}
              <Grid
                item
                sx={{
                  mb: 3,
                }}
                key="user-account"
              >
                <OwnerInfo owner={owner} ownerNotes={userNotes} />
              </Grid>
              {/* patient-info-and-diet-plan */}
              <Grid
                key="patient-info-and-diet-plan"
                item
                container
                column
                flexWrap="nowrap"
                sx={{
                  flexGrow: 1,
                }}
              >
                {/* Patient Info */}
                <Grid item sx={{ height: '100%' }}>
                  <Card
                    key="patient-info"
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {patients.length > 1 && (
                      <CardHeader
                        title={`Patients ${
                          !isLoading
                            ? `(${patientNumber + 1} of ${patients.length})`
                            : ' '
                        }`}
                        sx={{ pb: 0 }}
                      />
                    )}
                    <CardContent
                      noPadding
                      sx={{
                        height: '100%',
                      }}
                    >
                      {/* map of all patients */}
                      <PatientInfo
                        key={userGetters.getUserId(patients[patientNumber])}
                        user={
                          patientsUserInfoById[
                            userGetters.getUserId(patients[patientNumber])
                          ]
                        }
                        healthProfile={
                          patientsHealthProfilesByUserId[
                            userGetters.getUserId(patients[patientNumber])
                          ]
                        }
                        takePassedHealthProfile
                        config={{
                          fields: {
                            memberManagement: {
                              show: false,
                            },
                            addresses: {
                              show: false,
                            },
                            tags: {
                              show: false,
                            },
                          },
                        }}
                      />
                    </CardContent>
                    {isAllowed('consultationView', 'isPaidByUser') && (
                      <Grid
                        item
                        container
                        alignItems="center"
                        sx={{ height: (theme) => theme.spacing(7), px: 2 }}
                      >
                        <Switch
                          size="small"
                          label="Cash Order"
                          checked={isPaidByUser}
                          disabled={isSubmitting || isFetching}
                          onChange={(e) => {
                            setIsPaidByUser(e.target.checked);
                            updateConsultation(id, {
                              id,
                              isPaidByUser: e.target.checked,
                            });
                          }}
                        />
                      </Grid>
                    )}
                    <CardActions sx={{ p: 0 }}>
                      <Grid container column>
                        {isAllowed('consultationView', 'sendNotification') && (
                          <>
                            <Grid item container sx={{ py: 1, px: 2 }}>
                              <Tooltip
                                placement="right"
                                injectWrapper
                                title="Send a new sms or mobile push notification to the owner account"
                              >
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="text"
                                  loading={isFetching && { width: 150 }}
                                  startIcon={<MessageIcon color="secondary" />}
                                  onClick={() =>
                                    setNotificationDialogOpen(true)
                                  }
                                >
                                  Send a notification
                                </Button>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {isOutcomeManagementEnabled() &&
                          isAllowed('consultationView', 'outcome', 'edit') &&
                          !isEmpty(doctor) && (
                            <>
                              <Grid item sx={{ py: 1, px: 2 }}>
                                <OutcomeManagement
                                  doctor={doctor}
                                  consultation={consultation}
                                  loading={
                                    isFetching ||
                                    isFetchingOwner ||
                                    isFetchingMembers ||
                                    isFetchingOwnerHealthProfile ||
                                    isFetchingMembersHealthProfiles
                                  }
                                  userHasWlpSubscription={
                                    consultationTypeId ===
                                    getWlpConsultationTypeId()
                                  }
                                  updating={isUpdatingPatient}
                                />
                              </Grid>
                              <Grid item>
                                <Divider />
                              </Grid>
                            </>
                          )}
                        {isSendPrescriptionEnabled() &&
                          isAllowed('consultation', 'sendPrescription') && (
                            <>
                              <Grid
                                item
                                container
                                alignItems="center"
                                sx={{ py: 1, px: 2 }}
                              >
                                <Grid item sx={{ minWidth: 150 }}>
                                  <Tooltip
                                    placement="right"
                                    injectWrapper
                                    title="Send prescriptions for the consultation's patients"
                                  >
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="text"
                                      loading={isFetching && { width: 150 }}
                                      startIcon={
                                        <ShoppingCartIcon color="success" />
                                      }
                                      onClick={() =>
                                        setPrescriptionDialogOpen(true)
                                      }
                                    >
                                      Send prescriptions
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                {!isFetching &&
                                  utils.arePatientsOutcomesPendingSubmission(
                                    patients,
                                    OUTCOME_OPTIONS.Prescription.key
                                  ) && (
                                    <Grid item sx={{ ml: 1 }}>
                                      <ActionRequired tooltipTitle="Prescription is not submitted" />
                                    </Grid>
                                  )}
                              </Grid>
                              <Grid item>
                                <Divider />
                              </Grid>
                            </>
                          )}
                        {isSendRequestLabTestsEnabled() &&
                          isAllowed('consultationView', 'addLabTest') && (
                            <>
                              <Grid
                                item
                                container
                                alignItems="center"
                                sx={{ py: 1, px: 2 }}
                              >
                                <Grid item sx={{ minWidth: 150 }}>
                                  <Tooltip
                                    placement="right"
                                    injectWrapper
                                    title="Request lab tests for the consultation's patients"
                                  >
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="text"
                                      loading={isFetching && { width: 150 }}
                                      startIcon={
                                        <BiotechIcon color="warning" />
                                      }
                                      onClick={() => setLabTestDialogOpen(true)}
                                    >
                                      Request lab tests
                                    </Button>
                                  </Tooltip>
                                </Grid>
                                {!isFetching &&
                                  utils.arePatientsOutcomesPendingSubmission(
                                    patients,
                                    OUTCOME_OPTIONS.LabTestRequest.key
                                  ) && (
                                    <Grid item sx={{ ml: 1 }}>
                                      <ActionRequired tooltipTitle="Lab test request is not submitted" />
                                    </Grid>
                                  )}
                              </Grid>
                              <Grid item>
                                <Divider />
                              </Grid>
                            </>
                          )}
                        {((!(isMobile || isMedium) &&
                          isEPrescriptionEnabled() &&
                          isAllowed('consultationView', 'eprescribe') &&
                          !isEmpty(user) &&
                          !isEmpty(doctor)) ||
                          isFetching) && (
                          <>
                            <Grid item container sx={{ py: 1, px: 2 }}>
                              <EPrescription
                                key={ePrescriptionKey}
                                user={user}
                                doctor={doctor}
                                consultation={consultation}
                                loading={isFetching}
                                healthInfo={
                                  patientsHealthProfilesByUserId[
                                    userGetters.getId(user)
                                  ]
                                }
                                isUpdate={
                                  associatedOrderId !== null &&
                                  associatedOrderId !== undefined
                                }
                                associatedOrderId={associatedOrderId}
                              />
                            </Grid>
                            <Grid item>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {((!(isMobile || isMedium) &&
                          isElabShown() &&
                          isAllowed('consultationView', 'elab') &&
                          !isEmpty(user) &&
                          !isEmpty(doctor)) ||
                          isFetching) && (
                          <>
                            <Grid item container sx={{ py: 1, px: 2 }}>
                              <ELab
                                user={user}
                                doctor={doctor}
                                loading={isFetching}
                                labRequests={labTests}
                                tenantKey={userGetters.getTenant(user)}
                                healthInfo={
                                  patientsHealthProfilesByUserId[
                                    userGetters.getId(user)
                                  ]
                                }
                                userHasWlpSubscription={
                                  consultationTypeId ===
                                  getWlpConsultationTypeId()
                                }
                                consultation={consultation}
                                isUpdate={labTests.length > 0}
                              />
                            </Grid>
                            <Grid item>
                              <Divider />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CardActions>
                    {patients.length > 1 && (
                      <Grid
                        item
                        container
                        display="flex"
                        justifyContent="center"
                        sx={{ height: 'auto', py: 3 }}
                      >
                        <Pagination
                          onChange={(_event, value) =>
                            setPatientNumber(value - 1)
                          }
                          count={patients.length}
                        />
                      </Grid>
                    )}
                  </Card>
                </Grid>
                {/* Diet Plan */}
                {consultationTypeId === getWlpConsultationTypeId() && (
                  <Grid
                    item
                    container
                    sx={{ mt: 3, height: 'auto', minHeight: 256 }}
                  >
                    <Card
                      key="diet-plan"
                      sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <CardHeader title="Diet Plan" sx={{ pb: 0 }} />
                      <CardContent>
                        <LatestDietPlan userId={userGetters.getId(user)} />
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Grid item heightFollower key="comments-and-eligibility">
              {/* Comments Card */}
              <Grid
                item
                sx={{
                  mb: showEligibilitySection ? 3 : 0,
                  height: showEligibilitySection ? '50%' : '100%',
                }}
              >
                <Comments
                  key="comments"
                  referenceId={id}
                  referenceType={REFERENCE_TYPES.CONSULTATION}
                />
              </Grid>
              {/* Eligibility */}
              {showEligibilitySection && (
                <Grid
                  item
                  sx={{
                    height: 'calc(50% - 24px)',
                  }}
                >
                  <EligibilitySection
                    isEligibilityCheckRequested={isEligibilityCheckRequested}
                    defaultIdentityNumber={userGetters.getIdentityNumber(user)}
                    userId={userGetters.getId(user)}
                    doctorId={userGetters.getId(doctor)}
                    referenceId={id}
                    referenceType={REFERENCE_TYPES.CONSULTATION}
                  />
                </Grid>
              )}
            </Grid>
            {/** Meeting Participants  */}
            <ParticipantSection
              key="meeting-participants"
              endTime={endTime}
              startTime={startTime}
              isFetching={isFetching}
              isCompletedByPhone={
                status === STATUSES.COMPLETED.key &&
                MEETING_COMMUNICATION_METHOD.PHONE_CALL.key ===
                  meetingCommunicationMethod
              }
              meetingLink={meetingLink}
              participants={participants}
              patientName={userGetters.getFullName(user)}
              patientPhoneNumber={userGetters.getPhoneNumber(user)}
              onJoinMeetingClicked={(meetingMethod) =>
                joinConsultationMeetingStarted(id, meetingMethod)
              }
              enableCalls={enableCalls}
            />
            {/** Consultation Outcome  */}
            {isAllowed('consultationView', 'outcome', 'view') && (
              <OutcomeSection
                isFetching={isFetching}
                consultationId={id}
                patients={consultationPatients}
                tenantKey={userGetters.getTenant(user)}
              />
            )}
            {/** Payment Section */}
            {isAllowed('consultationView', 'payment', 'view') && (
              <PaymentSection
                user={user}
                referenceId={id}
                key="payment-section"
                isFetching={isFetching}
                hasPaymentTransaction={hasPaymentTransaction}
                referenceType={REFERENCE_TYPES.CONSULTATION_PAYMENT}
                permissions={{
                  create: isAllowed('consultationView', 'payment', 'create'),
                  update: isAllowed('consultationView', 'payment', 'update'),
                  resend: isAllowed('consultationView', 'payment', 'resend'),
                  cancel: isAllowed('consultationView', 'payment', 'cancel'),
                }}
              />
            )}
            {/** Patient History Section */}
            {isAllowed('patientHistory') && (
              <PatientHistorySection
                fullRow
                referenceId={id}
                userId={userGetters.getId(user)}
                referenceType={
                  patientHistoryConstants.ACTIVITY_TYPES.CONSULTATION.key
                }
              />
            )}
          </RowColLayout>
        </Grid>
      </Grid>
      <SendNotification
        referenceId={id}
        open={isNotificationDialogOpen}
        userId={userGetters.getId(user)}
        referenceType={REFERENCE_TYPES.CONSULTATION}
        onClose={() => setNotificationDialogOpen(false)}
      />
      <SendPrescriptionsDialog
        key={isPrescriptionDialogOpen ? 'send-prescription-modal' : undefined}
        patients={patients}
        consultationId={id}
        initialTab={patientNumber}
        open={isPrescriptionDialogOpen}
        userId={userGetters.getId(user)}
        tenantKey={userGetters.getTenant(user)}
        onClose={() => {
          setPrescriptionDialogOpen(false);
        }}
      />
      {/* <SendPrescription
        key={isPrescriptionDialogOpen ? 'send-prescription-modal' : undefined}
        consultationId={id}
        consultationStartTime={getters.getStartTime(consultation)}
        associatedOrderId={associatedOrderId}
        open={isPrescriptionDialogOpen}
        userId={userGetters.getId(user)}
        patientName={
          userGetters.getFullName(user) || userGetters.getPhoneNumber(user)
        }
        onClose={() => setPrescriptionDialogOpen(false)}
        tenantKey={userGetters.getTenant(user)}
      /> */}
      {/* <AddLabTest
        consultation={consultation}
        open={isLabTestDialogOpen}
        user={user}
        doctor={doctor}
        tenantKey={userGetters.getTenant(user)}
        patientName={
          userGetters.getFullName(user) || userGetters.getPhoneNumber(user)
        }
        labTests={labTests}
        userHasWlpSubscription={
          consultationTypeId === getWlpConsultationTypeId()
        }
        onClose={() => setLabTestDialogOpen(false)}
      /> */}

      <AddLabTestDialog
        consultation={consultation}
        consultationId={id}
        open={isLabTestDialogOpen}
        userId={userGetters.getId(user)}
        initialTab={patientNumber}
        patients={patients}
        onClose={() => setLabTestDialogOpen(false)}
      />
      <Popup
        id="consultation-validation-popup"
        title="Confirm Deleting Outcomes"
        open={isEditConfirmDialogOpen}
        primaryButtonTitle="Confirm"
        onOk={() => {
          submitChangesConfirmed(true);
        }}
        secondaryButtonTitle="Discard changes"
        onCancel={() => setEditConfirmDialogOpen(false)}
      >
        Please note that an outcome file/s is added to this consultation. All
        files will be deleted if you move forward.
      </Popup>
    </>
  );
}

export default ConsultationView;
