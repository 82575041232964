import { connect } from 'react-redux';

import {
  scheduleActions,
  scheduleSelectors,
  doctorScheduleGetters as getters,
  availabilityRangeGetters as rangeGetters,
} from 'domain/schedule';

import DoctorAvailabilitySchedule from './DoctorAvailabilitySchedule';

const mapStateToProps = (state) => {
  const schedules = scheduleSelectors.getDoctorSchedules(state);
  const isFetching = scheduleSelectors.isFetchingSchedules(state);
  const isRemovingRange = scheduleSelectors.isRemovingRange(state);
  const isAddingRange = scheduleSelectors.isAddingRange(state);
  const freeScheduleSlot = scheduleSelectors.getDoctorFreeScheduleSlots(state);
  const isFetchingDoctorFreeScheduleSlots =
    scheduleSelectors.isFetchingDoctorFreeScheduleSlots(state);
  return {
    schedules,
    freeScheduleSlot,
    isFetching,
    isFetchingDoctorFreeScheduleSlots,
    isRemovingRange: (rangeId) => isRemovingRange[rangeId] || false,
    isAddingRange: (scheduleId) => isAddingRange[scheduleId] || false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSchedules: () => dispatch(scheduleActions.fetchDoctorSchedules()),
  fetchDoctorFreeScheduleSlots: (doctorId, dayOfWeekDate, dayOfWeek) =>
    dispatch(
      scheduleActions.fetchDoctorFreeScheduleSlots(
        doctorId,
        dayOfWeekDate,
        dayOfWeek
      )
    ),
  addRange: (scheduleId, doctorId, dayOfWeek, startTime, endTime, callback) =>
    dispatch(
      scheduleActions.addAvailabilityRange(
        scheduleId,
        doctorId,
        dayOfWeek,
        startTime,
        endTime,
        callback
      )
    ),
  removeRange: (range, row) => {
    dispatch(
      scheduleActions.removeAvailabilityRange(
        rangeGetters.getId(range),
        getters.getId(row)
      )
    );
  },
  removeFreeSlot: (range, row, dayOfWeek) => {
    dispatch(
      scheduleActions.removeFreeSlot(
        row.doctorId,
        range.startTime,
        range.endTime,
        dayOfWeek
      )
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorAvailabilitySchedule);
