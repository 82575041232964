import React from 'react';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import { Chip, Tooltip, IconButton } from 'design-system';
import { medicineGetters as getters } from 'domain/medicine';

export const getColumns = () => [
  { field: 'id', headerName: 'ID', width: 80 },

  {
    field: 'drugCode',
    headerName: 'Drug code',
    flex: 1,
  },
  {
    field: 'packageName',
    headerName: 'Package name',
    flex: 2,
  },
  {
    field: 'genericName',
    headerName: 'Generic name',
    flex: 2,
  },
  {
    field: 'packagePrice',
    headerName: 'Price',
    sortable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    valueGetter: (prms) => getters.getPrice(prms.row),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    renderCell: (prms) => {
      const status = getters.getStatus(prms.row);
      return (
        <Tooltip placement="top" title="Status">
          <Chip
            variant="filled"
            soak="light"
            label={status}
            color={status === 'Active' ? 'success' : 'error'}
          />
        </Tooltip>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 80,
    disableColumnMenu: true,
    hideSortIcons: true,
    sortable: false,
    renderCell: () => (
      <IconButton>
        <MessageOutlinedIcon fontSize="small" color="secondary" />
      </IconButton>
    ),
  },
];

export const getFilterFieldsConfig = () => [
  {
    name: 'dragCode',
    serverName: 'dragCode',
    operation: '$contL',
  },
  {
    name: 'packageName',
    serverName: 'packageName',
    operation: '$cont',
  },
  {
    name: 'genericName',
    serverName: 'genericName',
    operation: '$cont',
  },
  {
    name: 'status',
    serverName: 'status',
  },
];
