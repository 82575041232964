import * as types from './actionTypes';

export const fetchClinics = () => ({
  type: types.FETCH_CLINICS,
});

export const clinicsFetchResponded = (ok, response) => ({
  type: types.CLINICS_FETCH_RESPONDED,
  ok,
  response,
});
