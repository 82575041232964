export const valueField = {
  '& .MuiInputBase-root': {
    pr: 0,
    pl: {
      xs: 1,
      xl: 2,
    },
  },
};

export const unitField = {
  m: 0,
  '& .MuiInputBase-root': {
    pl: 0,
    width: (theme) => theme.spacing(15),
    backgroundColor: (theme) => theme.palette.grey[300],
  },
  '& .MuiSelect-select': {
    pl: {
      xs: 0.5,
      xl: 1,
    },
    pr: {
      xs: '16px !important',
      xl: '20px !important',
    },
  },
  '& .MuiSelect-icon': {
    right: (theme) => ({
      xs: '-3px',
      xl: theme.spacing(0.25),
    }),
  },
};
