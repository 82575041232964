import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import { medicineConstants } from 'domain/medicine';

import MedicinesList from './MedicinesList';

const { LS_MEDICINE_FILTERS } = medicineConstants;

const mapDispatchToProps = (dispatch) => ({
  saveFilters: (filters) =>
    dispatch(appActions.saveFilters(filters, LS_MEDICINE_FILTERS)),
});

export default connect(null, mapDispatchToProps)(MedicinesList);
