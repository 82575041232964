import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useEffect, useState, useCallback } from 'react';

import { CONSULTATION_TYPE_CONFIGURATIONS_FIELDS } from 'domain/consultationType';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormField,
  Select,
  Chip,
  MenuItem,
  Checkbox,
  Button,
} from 'design-system';

const AddEditDialog = ({
  isModalOpen,
  setModalOpen,
  isAdd,
  configurationId,
  consultationTypeId,
  insuranceId,
  insuranceDoctors,
  updateConsultationTypeConfiguration,
  addConsultationTypeConfiguration,
  isSubmitting,
  doctors,
  insuranceProvidersList,
  existingInsuranceIds,
}) => {
  const [formChanged, setFormChanged] = useState(false);
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [selectedInsuranceId, setSelectedInsuranceId] = useState(insuranceId);

  const activeDoctorIds = useMemo(() => {
    return doctors?.map((doctor) => doctor.id);
  }, [doctors]);

  const insuranceOptions = useMemo(() => {
    return insuranceProvidersList.filter(
      (insurance) => !existingInsuranceIds.includes(insurance.id)
    );
  }, [insuranceProvidersList, existingInsuranceIds]);

  useEffect(() => {
    if (insuranceDoctors?.length) {
      setSelectedDoctors(insuranceDoctors);
    }
  }, [insuranceDoctors]);

  const submitChange = useCallback(() => {
    let payload = {
      [CONSULTATION_TYPE_CONFIGURATIONS_FIELDS.CONSULTATION_TYPE_ID.name]:
        consultationTypeId,
      [CONSULTATION_TYPE_CONFIGURATIONS_FIELDS.INSURANCE_PROVIDER_ID.name]:
        insuranceId || selectedInsuranceId,
      [CONSULTATION_TYPE_CONFIGURATIONS_FIELDS.DOCTORS.name]: selectedDoctors,
    };

    if (isAdd) {
      addConsultationTypeConfiguration(payload);
    } else {
      payload = {
        ...payload,
        [CONSULTATION_TYPE_CONFIGURATIONS_FIELDS.ID.name]: configurationId,
      };
      updateConsultationTypeConfiguration(configurationId, payload);
    }
  }, [
    isAdd,
    insuranceId,
    selectedDoctors,
    configurationId,
    consultationTypeId,
    selectedInsuranceId,
  ]);

  const reset = useCallback(() => {
    setFormChanged(false);
    setSelectedDoctors([]);
    setSelectedInsuranceId('');
  }, []);

  const onClosed = useCallback(() => {
    setModalOpen(false);
    reset();
  }, [reset]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isModalOpen}
      onClose={() => {
        onClosed();
      }}
      aria-labelledby={`${insuranceId}-edit-doctors-dialog-title`}
    >
      <DialogTitle>
        {isAdd ? 'Add ' : 'Edit '}
        {
          insuranceProvidersList.find(
            (insurance) => insurance.id === insuranceId
          )?.englishName
        }
        {' doctors'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container columnSpacing={isAdd ? 1 : 2}>
          {isAdd && (
            <Grid item sx={{ mb: 1 }}>
              <FormField
                label="Insurance"
                labelWidth={60}
                valueWidth={170}
                field={
                  <Select
                    fullWidth
                    size="large"
                    soak="light"
                    value={selectedInsuranceId}
                    variant="filled"
                    placeholder="Select insurance..."
                    onChange={(event) => {
                      setSelectedInsuranceId(event.target.value);
                      setFormChanged(true);
                    }}
                    renderValue={(value) =>
                      value
                        ? insuranceOptions?.find(
                            (insurance) => insurance.id === value
                          )?.englishName
                        : value
                    }
                  >
                    {insuranceOptions.map((item) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.englishName}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            </Grid>
          )}
          <FormField
            label="Doctors for insurance"
            labelWidth={60}
            valueWidth={170}
            field={
              <Select
                fullWidth
                multiple
                placeholder="Specify doctors..."
                soak="light"
                variant="filled"
                value={selectedDoctors}
                onChange={(event) => {
                  setSelectedDoctors(event.target.value);
                  setFormChanged(true);
                }}
                renderValue={(selected) => (
                  <Grid
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip
                        size="small"
                        color="primary"
                        sx={{ maxHeight: 20 }}
                        key={value}
                        label={
                          doctors?.find((doctor) => doctor.id === value)
                            ?.fullName || value
                        }
                      />
                    ))}
                  </Grid>
                )}
              >
                {doctors.map((doctor) => (
                  <MenuItem value={doctor.id} key={doctor.id}>
                    <Checkbox
                      checked={
                        selectedDoctors.filter((c) => c === doctor.id).length >
                        0
                      }
                    />
                    {doctor.fullName}
                  </MenuItem>
                ))}
                {/** Show the of inactive doctor IDs */}
                {selectedDoctors
                  ?.filter((doctorId) => !activeDoctorIds.includes(doctorId))
                  .map((doctorId) => (
                    <MenuItem value={doctorId} key={doctorId}>
                      <Checkbox checked />
                      Inactive doctor Id: {doctorId}
                    </MenuItem>
                  ))}
              </Select>
            }
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          spinning={isSubmitting}
          disabled={
            isSubmitting ||
            !formChanged ||
            isEmpty(selectedDoctors) ||
            !selectedInsuranceId
          }
          onClick={() => {
            submitChange();
            onClosed();
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditDialog;
