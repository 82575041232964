import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as PAYMENT } from './constants';

// Create Payment transaction details and link
export const CREATE_PAYMENT_TRANSACTION = `${DOMAIN}/${PAYMENT}/CREATE_PAYMENT_TRANSACTION`;
export const PAYMENT_TRANSACTION_CREATED = `${DOMAIN}/${PAYMENT}/PAYMENT_TRANSACTION_CREATED`;
export const GET_PAYMENT_TRANSACTION_BY_REFERENCE = `${DOMAIN}/${PAYMENT}/GET_PAYMENT_TRANSACTION_BY_REFERENCE`;
export const PAYMENT_TRANSACTION_RECEIVED = `${DOMAIN}/${PAYMENT}/PAYMENT_TRANSACTION_RECEIVED`;
export const PAYMENT_TRANSACTION_CANCELLED = `${DOMAIN}/${PAYMENT}/PAYMENT_TRANSACTION_CANCELLED`;
export const SET_FETCHING_PAYMENT_TRANSACTION = `${DOMAIN}/${PAYMENT}/SET_FETCHING_PAYMENT_TRANSACTION`;
export const CANCEL_PAYMENT_TRANSACTION = `${DOMAIN}/${PAYMENT}/CANCEL_PAYMENT_TRANSACTION`;
