import { connect } from 'react-redux';

import { pursueAction } from 'domain/app/actions';
import {
  memberActions as actions,
  memberSelectors as selectors,
  memberActionTypes as actionTypes,
} from 'domain/user/member';

import CreateMemberDialog from './CreateMemberDialog';

const mapStateToProps = (state) => {
  const isSubmitting = selectors.isSubmittingMember(state);

  return {
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createMember: (ownerId, payload) =>
    dispatch(actions.createMember(ownerId, payload)),
  onMemberCreated: (callback) =>
    dispatch(pursueAction(actionTypes.MEMBER_CREATED, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMemberDialog);
