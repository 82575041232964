import * as types from './actionTypes';

const initialState = {
  doctorNotes: {}, // By userId
  isFetching: false,
  isSubmitting: false,
  patientAssessmentSheet: {},
};

const doctorNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOCTOR_NOTES_RECEIVED: {
      const { doctorNotes, userId } = action;

      return {
        ...state,
        doctorNotes: {
          ...state.doctorNotes,
          [userId]: doctorNotes,
        },
      };
    }
    case types.DOCTOR_NOTES_SUBMITTED: {
      const { doctorNotes, userId } = action;

      return {
        ...state,
        doctorNotes: {
          ...state.doctorNotes,
          [userId]: doctorNotes,
        },
      };
    }
    case types.CLEAR_DOCTOR_NOTES: {
      return {
        ...state,
        doctorNotes: {},
      };
    }
    case types.SET_FETCHING_DOCTOR_NOTES: {
      return {
        ...state,
        isFetching: action.isFetchingDoctorNotes,
      };
    }
    case types.SET_SUBMITTING_DOCTOR_NOTES: {
      return {
        ...state,
        isSubmitting: action.isSubmittingDoctorNotes,
      };
    }
    case types.PATIENT_ASSESSMENT_SHEET_CREATED: {
      const { patientAssessmentSheet, userId } = action;
      return {
        ...state,
        patientAssessmentSheet: {
          ...state.patientAssessmentSheet,
          [userId]: patientAssessmentSheet,
        },
      };
    }
    default:
      return state;
  }
};

export default doctorNotesReducer;
