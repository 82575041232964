import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Usage:
 * import { useDeviceSize } from 'design-system/hooks';
 *
 * // Inside a component
 * const { isMobile, isTablet } = useDeviceSize();
 */
const useDeviceSize = () => {
  const theme = useTheme();

  const isTiny = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isLarge = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    isTiny,
    isLarge,
    isMobile,
    isTablet,
    isMedium,
    isDesktop,
    width: window.innerWidth,
  };
};

export default useDeviceSize;
