import values from 'lodash/values';
import React, { useRef, useMemo, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { YES_NO } from 'constants/general';
import { Form, Controller } from 'design-system/form';
import {
  Grid,
  Select,
  Button,
  MenuItem,
  FormField,
  TextField,
  Message,
} from 'design-system';

import {
  FIELDS,
  PATIENT_AND_FAMILY_HISTORY_FIELDS,
} from 'domain/doctorNotes/constants';
import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const PatientAndFamilyHistoryForm = ({
  // Owner's user ID
  ownerId,
  // Patient's user ID
  userId,
  // Relevant consultation ID
  consultationId,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Action to update/create
  submitPatientAndFamilyHistoryForm,
  // Get doctor notes by Id from state
  getDoctorNotesById,

  submitOwnerHealthProfile,

  submitMemberHealthProfile,
}) => {
  const formRef = useRef();

  const [isSaving, setSaving] = useState(false);

  const patientAndFamilyHistory = useMemo(() => {
    return getDoctorNotesById(userId)?.[FIELDS.PATIENT_AND_FAMILY_HISTORY.key];
  }, [getDoctorNotesById, userId]);

  const healthProfileUpdateFunction = useMemo(() => {
    return ownerId === userId
      ? submitOwnerHealthProfile
      : submitMemberHealthProfile;
  }, [ownerId, userId, submitOwnerHealthProfile, submitMemberHealthProfile]);

  const patientAndFamilyHistoryPopulatedValue = useMemo(() => {
    if (isEmpty(defaultValues)) {
      return [{}];
    }
    const lastTakenMedication =
      defaultValues?.[
        PATIENT_AND_FAMILY_HISTORY_FIELDS.LAST_TAKEN_MEDICATION.key
      ];
    if (defaultValues) {
      let hasTakenMedicationLately;
      if (lastTakenMedication !== undefined) {
        hasTakenMedicationLately = isEmpty(lastTakenMedication?.trim())
          ? YES_NO.NO.key
          : YES_NO.YES.key;
      }
      let isPregnant;
      if (defaultValues?.isPregnant !== undefined) {
        isPregnant = defaultValues?.isPregnant ? YES_NO.YES.key : YES_NO.NO.key;
      }
      return {
        ...defaultValues,
        isPregnant,
        // If it was yes then lastTakenMedication is filled
        hasTakenMedicationLately,
      };
    }
    return defaultValues;
  }, [defaultValues]);

  // Define a mapping function to convert YES/NO to true/false
  const mapYesNoToBoolean = (yesNoValue) => {
    return yesNoValue === YES_NO.YES.key; // If 'YES', return true; otherwise, return false
  };

  const onSubmit = useCallback(
    (formData) => {
      // Convert the "isPregnant" value to a boolean before sending to the API
      const isPregnant = mapYesNoToBoolean(
        formData[PATIENT_AND_FAMILY_HISTORY_FIELDS.IS_PREGNANT.key]
      );

      const payload = {
        ...formData,
        [PATIENT_AND_FAMILY_HISTORY_FIELDS.IS_PREGNANT.key]: isPregnant,
        [PATIENT_AND_FAMILY_HISTORY_FIELDS.ID.key]:
          patientAndFamilyHistory?.[PATIENT_AND_FAMILY_HISTORY_FIELDS.ID.key],
        [PATIENT_AND_FAMILY_HISTORY_FIELDS.CONSULTATION_ID.key]: consultationId,
        [PATIENT_AND_FAMILY_HISTORY_FIELDS.PATIENT_USER_ID.key]: userId,
      };

      setSaving(true);

      // Both patientMedicalHistory and familyHistory are parts of the health information.
      // Send them to the health service for storage in the user's health profile.

      healthProfileUpdateFunction(
        userId,
        {
          patientMedicalHistory: formData.patientMedicalHistory,
          familyHistory: formData.familyHistory,
        },
        () => {
          // Show success message
          Message.success(`Health profile updated successfully`);
        }
      );

      submitPatientAndFamilyHistoryForm(
        userId,
        consultationId,
        {
          [FIELDS.USER_ID.key]: userId,
          [FIELDS.OWNER_ID.key]: ownerId,
          [FIELDS.CONSULTATION_ID.key]: consultationId,
          [FIELDS.PATIENT_AND_FAMILY_HISTORY.key]: payload,
        },
        () => {
          onSubmitted(payload);
          setSaving(false);
        }
      );
    },
    [
      userId,
      ownerId,
      onSubmitted,
      consultationId,
      patientAndFamilyHistory,
      submitPatientAndFamilyHistoryForm,
      patientAndFamilyHistoryPopulatedValue,
    ]
  );

  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      defaultValues={patientAndFamilyHistoryPopulatedValue}
    >
      {({ watch, control, setValue }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.PATIENT_AND_FAMILY_HISTORY.key}
          title={SECTIONS.PATIENT_AND_FAMILY_HISTORY.label}
          actions={
            <Button
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              spinning={isSaving}
              onClick={() => {
                formRef?.current?.submit();
              }}
            >
              Save and continue
            </Button>
          }
        >
          <Grid container spacing={3}>
            <FormField
              xs={12}
              md={8}
              required
              flexWrap="nowrap"
              label="TAKING ANY MEDICATION IN THE LAST 6 MONTHS"
              field={
                <Grid container columnSpacing={2}>
                  <Grid item xs={4} sm={2}>
                    <Controller
                      name="hasTakenMedicationLately"
                      control={control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => {
                        const handleSelectChange = (selectedValues) => {
                          onChange(selectedValues);

                          // Clear the medication field if the value is NO
                          if (
                            watch('hasTakenMedicationLately') === YES_NO.NO.key
                          ) {
                            setValue(
                              PATIENT_AND_FAMILY_HISTORY_FIELDS
                                .LAST_TAKEN_MEDICATION.key,
                              ''
                            );
                          }
                        };
                        return (
                          <Select
                            fullWidth
                            size="medium"
                            soak="light"
                            variant="filled"
                            value={value}
                            placeholder="Select..."
                            onChange={handleSelectChange}
                            renderValue={(selectedValue) =>
                              YES_NO[selectedValue].label
                            }
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                          >
                            {values(YES_NO).map((item) => (
                              <MenuItem key={item.key} value={item.key}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                      rules={{
                        required: 'Please select a value',
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={10}>
                    <Controller
                      name={
                        PATIENT_AND_FAMILY_HISTORY_FIELDS.LAST_TAKEN_MEDICATION
                          .key
                      }
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          fullWidth
                          size="medium"
                          variant="filled"
                          value={field.value}
                          placeholder="If yes, name of medications"
                          onChange={field.onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          disabled={
                            watch('hasTakenMedicationLately') === YES_NO.NO.key
                          }
                        />
                      )}
                      rules={{
                        validate: {
                          required: (value = '') => {
                            if (
                              watch('hasTakenMedicationLately') ===
                                YES_NO.YES.key &&
                              !value.trim()
                            ) {
                              return 'Please name the taken medications';
                            }
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
            <FormField
              xs={12}
              md={4}
              label="PREGNANT"
              field={
                <Controller
                  name={PATIENT_AND_FAMILY_HISTORY_FIELDS.IS_PREGNANT.key}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      fullWidth
                      size="medium"
                      soak="light"
                      variant="filled"
                      value={value}
                      placeholder="Select..."
                      onChange={onChange}
                      renderValue={(selectedValue) =>
                        YES_NO[selectedValue].label
                      }
                    >
                      {values(YES_NO).map((item) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              }
            />
            {/* TODO add pastMedicalHistory and familyHistory to HealthProfile constants */}
            <FormField
              xs={12}
              md={6}
              label="PATIENT PAST MEDICAL HISTORY"
              field={
                <Controller
                  name="patientMedicalHistory"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add patient past medical history"
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
            <FormField
              xs={12}
              md={6}
              label="FAMILY HISTORY"
              field={
                <Controller
                  name="familyHistory"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add family history"
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
            <FormField
              xs={12}
              md={6}
              label="NUTRITION STATUS"
              field={
                <Controller
                  name={PATIENT_AND_FAMILY_HISTORY_FIELDS.NUTRITION_STATUS.key}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add nutrition status"
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
            <FormField
              xs={12}
              md={6}
              label="OTHER"
              field={
                <Controller
                  name={PATIENT_AND_FAMILY_HISTORY_FIELDS.REMARKS.key}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      variant="filled"
                      value={field.value}
                      placeholder="Add other notes"
                      onChange={field.onChange}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Section>
      )}
    </Form>
  );
};

export default PatientAndFamilyHistoryForm;
