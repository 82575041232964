import React, { useState, useEffect } from 'react';

import {
  Grid,
  Dialog,
  Select,
  Button,
  MenuItem,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

const SubscriptionPlanDialog = ({
  userId,
  onClosed,
  open = false,
  isSubmitting,
  isSubscriptionCreated,
  createSubscription,
  subscriptionPlans = [],
  isFetchingSubscriptionPlans,
}) => {
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (isSubscriptionCreated) onClosed();
  }, [isSubscriptionCreated]);

  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const onCreateSubscriptionButtonClicked = () => {
    const payload = {
      planName: subscriptionPlan,
    };
    createSubscription(userId, payload);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClosed}
      aria-labelledby="create-subscription-dialog"
    >
      <DialogTitle id="create-subscription-dialog" onClose={onClosed}>
        Enroll Patient to Subscription
      </DialogTitle>
      <DialogContent dividers>
        <Grid container column>
          <Grid item sx={{ mb: 1 }}>
            <Typography variant="p2">PLAN</Typography>
          </Grid>
          <Grid item>
            <Select
              fullWidth
              loading={isFetchingSubscriptionPlans}
              size="large"
              soak="light"
              variant="filled"
              value={subscriptionPlan}
              placeholder="Choose a plan..."
              onChange={(event) => {
                setSubscriptionPlan(event.target.value);
              }}
            >
              {subscriptionPlans.map((item) => (
                <MenuItem value={item.name} key={item.name}>
                  {item.name} - {item?.price?.value} {item?.price?.currency}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          spinning={isSubmitting}
          disabled={!subscriptionPlan || isSubmitting}
          onClick={onCreateSubscriptionButtonClicked}
        >
          Enroll
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionPlanDialog;
