import * as types from './actionTypes';

export const fetchTenants = (ids) => ({
  type: types.FETCH_TENANTS,
  ids,
});

export const tenantsReceived = (tenants) => ({
  type: types.TENANTS_RECEIVED,
  tenants,
});

export const fetchCities = () => ({
  type: types.FETCH_CITIES,
});

export const citiesReceived = (cities) => ({
  type: types.CITIES_RECEIVED,
  cities,
});
