import React from 'react';
import MuiChip from '@mui/material/Chip';

import Skeleton from '../Skeleton';

const Chip = ({ color = 'standard', loading, ...rest }) => {
  if (loading) {
    return (
      <Skeleton
        variant="rounded"
        componentName="chip"
        {...(typeof loading !== 'boolean' ? loading : {})}
      />
    );
  }

  return <MuiChip color={color} {...rest} />;
};

export default Chip;
