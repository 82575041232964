import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import { userActions } from 'domain/user';
import appData from 'domain/app/HOCs/appData';
import { REFERENCE_TYPES } from 'constants/general';
import { fileActions, fileSelectors } from 'domain/file';
import {
  consultationActions,
  consultationSelectors,
} from 'domain/consultation';
import {
  orderActions as actions,
  orderSelectors as selectors,
  orderActionTypes as actionTypes,
} from 'domain/order';

import OrderView from './OrderView';

const mapStateToProps = (state) => {
  const order = selectors.getOrder(state);
  const shipment = selectors.getShipment(state);
  const isFetching = selectors.isFetching(state);
  const isSubmitting = selectors.isSubmitting(state);
  const shipmentLabel = selectors.getShipmentLabel(state);
  const isFetchingTaskId = selectors.isFetchingTaskId(state);
  const isCreatingFiles = fileSelectors.isCreatingFiles(state);
  const isUpdatingLineItems = selectors.isUpdatingLineItems(state);
  const consultation = consultationSelectors.getConsultation(state);
  const isFetchingConsultation =
    consultationSelectors.isFetchingConsultation(state);
  const isFetchingPrescriptions = selectors.isFetchingPrescriptions(state);
  const uploadedPrescriptions =
    fileSelectors.getFiles(state, REFERENCE_TYPES.ORDER) || [];

  return {
    order,
    shipment,
    isFetching,
    consultation,
    isSubmitting,
    shipmentLabel,
    isCreatingFiles,
    isFetchingTaskId,
    isUpdatingLineItems,
    uploadedPrescriptions,
    isFetchingConsultation,
    isFetchingPrescriptions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchOrder: (id) => dispatch(actions.fetchOrder(id)),
  updateOrder: (id, payload, successCallback, failureCallback) =>
    dispatch(actions.patchOrder(id, payload, successCallback, failureCallback)),
  addDeliveryAddress: (orderId, payload) =>
    dispatch(actions.addDeliveryAddress(orderId, payload)),
  updateDeliveryAddress: (id, orderId, payload) =>
    dispatch(actions.updateDeliveryAddress(id, orderId, payload)),
  updateStateFiles: (files) =>
    dispatch(fileActions.filesCreated(files, REFERENCE_TYPES.ORDER, true)),
  onDeliveryAddressSubmitted: (callback) =>
    dispatch(
      appActions.pursueAction(actionTypes.DELIVERY_ADDRESS_CHANGED, callback)
    ),
  fetchShipmentLabel: (orderId, callback) =>
    dispatch(actions.fetchShipmentLabel(orderId, callback)),
  fetchShipment: (orderId) => dispatch(actions.fetchShipment(orderId)),
  fetchUser: (id) => dispatch(userActions.fetchUser(id)),
  getConsultation: (id) =>
    dispatch(consultationActions.getConsultationById(id)),
  clearConsultation: () => dispatch(consultationActions.clearConsultation()),
});

export default appData(connect(mapStateToProps, mapDispatchToProps)(OrderView));
