import * as types from './actionTypes';

export const createDietPlan = (payload) => ({
  type: types.CREATE_DIET_PLAN,
  payload,
});

export const fetchLatestDietPlan = (userId) => ({
  type: types.FETCH_LATEST_DIET_PLAN,
  userId,
});

export const dietPlanCreationResponded = (ok, response) => ({
  type: types.DIET_PLAN_CREATION_RESPONDED,
  ok,
  response,
});

export const latestDietPlanReceived = (latestDietPlan) => ({
  type: types.LATEST_DIET_PLAN_RECEIVED,
  latestDietPlan,
});
