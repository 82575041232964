import isEmpty from 'lodash/isEmpty';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { cacheUtils } from 'core/cache';
import { authSelectors } from 'domain/auth';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';
import { tenantModelActions, tenantModelActionTypes } from 'model/tenant';

import * as actions from './actions';
import * as types from './actionTypes';

const currentState = (state) => state;

export function* fetchTenants() {
  const state = yield select(currentState);
  const ids = authSelectors.getAllowedTenants(state);

  if (!isEmpty(ids)) {
    // Fetch tenants from local storage cache if exist
    const cachedTenants = cacheUtils.getCachedDomainObject(
      LOCAL_DOMAIN_CACHE_KEYS.TENANTS
    );
    if (cachedTenants) {
      yield put(tenantModelActions.tenantsReceived(cachedTenants));
      return;
    }
    yield put(tenantModelActions.fetchTenants(ids.join(',')));
  }
}

export function* tenantsReceived({ tenants }) {
  cacheUtils.setCachedDomainObject(LOCAL_DOMAIN_CACHE_KEYS.TENANTS, tenants);
  yield put(actions.tenantsReceived(tenants));
}

export function* fetchCities() {
  const cities = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.CITIES
  );

  if (cities) {
    yield put(actions.citiesReceived(cities));
    return;
  }
  yield put(tenantModelActions.fetchCities());
}

export function* citiesReceived({ cities }) {
  cacheUtils.setCachedDomainObject(LOCAL_DOMAIN_CACHE_KEYS.CITIES, cities);
  yield put(actions.citiesReceived(cities));
}

export default function* tenantSaga() {
  yield all([
    takeLatest(types.FETCH_TENANTS, fetchTenants),
    takeLatest(tenantModelActionTypes.TENANTS_RECEIVED, tenantsReceived),
    takeLatest(types.FETCH_CITIES, fetchCities),
    takeLatest(tenantModelActionTypes.CITIES_RECEIVED, citiesReceived),
  ]);
}
