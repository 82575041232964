import * as types from './actionTypes';

const initialState = {
  prescription: {},
  isSubmitting: false,
  isSubmittingPrescription: false,
  isFetchingPatientPrescription: false,
  isSubmittingAllPrescriptions: false,
  ePrescriptionDocument: undefined,
  patientPrescriptionDocument: undefined,
  isUpdating: false,
  isCreatingPatientPrescriptionDocument: false,
};

const prescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SUBMITTING_PRESCRIPTION: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.SET_UPDATING_PATIENT_PRESCRIPTION: {
      return {
        ...state,
        isUpdating: action.isUpdating,
      };
    }
    case types.SET_SUBMITTING_PATIENT_PRESCRIPTION: {
      return {
        ...state,
        isSubmittingPrescription: action.isSubmittingPrescription,
      };
    }
    case types.E_PRESCRIPTION_DOCUMENT_CREATED: {
      return {
        ...state,
        ePrescriptionDocument: action.document,
      };
    }
    case types.PATIENT_PRESCRIPTION_DOCUMENT_CREATED: {
      const { patientPrescriptionDocument, userId } = action;
      return {
        ...state,
        patientPrescriptionDocument: {
          ...state.patientPrescriptionDocument,
          [userId]: patientPrescriptionDocument,
        },
      };
    }
    case types.PATIENT_PRESCRIPTION_CREATED: {
      const { prescription, userId } = action;
      return {
        ...state,
        prescription: {
          ...state.prescription,
          [userId]: prescription,
        },
      };
    }
    case types.CLEAR_PATIENTS_PRESCRIPTIONS: {
      return {
        ...state,
        prescription: {},
      };
    }
    case types.SET_FETCHING_PATIENT_PRESCRIPTION: {
      return {
        ...state,
        isFetchingPatientPrescription: action.isFetchingPatientPrescription,
      };
    }
    case types.SET_SUBMITTING_ALL_PRESCRIPTIONS: {
      return {
        ...state,
        isSubmittingAllPrescriptions: action.isSubmittingAllPrescriptions,
      };
    }
    case types.SET_CREATING_PATIENT_PRESCRIPTION_DOCUMENT: {
      return {
        ...state,
        isCreatingPatientPrescriptionDocument:
          action.isCreatingPatientPrescriptionDocument,
      };
    }
    case types.PATIENT_PRESCRIPTION_RECEIVED: {
      const { prescription, userId } = action;
      return {
        ...state,
        prescription: {
          ...state.prescription,
          [userId]: prescription,
        },
      };
    }
    case types.PATIENT_PRESCRIPTION_UPDATED: {
      const { prescription, userId } = action;
      return {
        ...state,
        prescription: {
          ...state.prescription,
          [userId]: prescription,
        },
      };
    }
    default:
      return state;
  }
};

export default prescriptionReducer;
