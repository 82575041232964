import get from 'lodash/get';

import { USER_MANAGEMENT_FIELDS as FIELDS } from './constants';

export const getId = (userCredentials) => get(userCredentials, FIELDS.ID.name);

export const getCreatedAt = (userCredentials) =>
  get(userCredentials, FIELDS.CREATED_AT.name);

export const getUserId = (userCredentials) =>
  get(userCredentials, FIELDS.USER_ID.name);

export const getUsername = (userCredentials) =>
  get(userCredentials, FIELDS.USERNAME.name, '');

export const getSlackMemberId = (userCredentials) =>
  get(userCredentials, FIELDS.SLACK_MEMBER_ID.name, '');

export const getAllowedTenants = (userCredentials) =>
  get(userCredentials, FIELDS.ALLOWED_TENANTS.name, []);

export const getRoles = (userCredentials) =>
  get(userCredentials, FIELDS.ROLES.name, []);

export const getTenant = (userCredentials) =>
  get(userCredentials, FIELDS.TENANT_KEY.name, '');

export const getNamespace = (userCredentials) =>
  get(userCredentials, FIELDS.NAMESPACE_KEY.name);
