import get from 'lodash/get';
import { createSelector } from 'reselect';

export const eligibilitySelectors = (state) =>
  get(state, 'domain.eligibility', {});

export const getEligibilityRequests = createSelector(
  eligibilitySelectors,
  (results) => get(results, `eligibilityRequests`)
);

export const isFetching = createSelector(eligibilitySelectors, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(eligibilitySelectors, (results) =>
  get(results, 'isSubmitting')
);
