import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import { calculateAge } from 'core/utils/date';
import React, { useRef, useMemo, useState, useEffect, useContext } from 'react';

import { FILE_TYPES } from 'domain/file';
import * as fileAPI from 'core/file/apis';
import * as fileUtils from 'core/file/utils';
import { doctorGetters } from 'domain/doctor';
import { dateFormat } from 'constants/dateTime';
import { getAppConfigEndpoint } from 'core/endpoint';
import { Form, Controller } from 'design-system/form';
import ServerAutocomplete from 'core/serverAutocomplete';
import { LAB_TEST_CHANNELS } from 'domain/lab/constants';
import { userGetters, userConstants } from 'domain/user';
import { consultationGetters } from 'domain/consultation';
import { LAB_REQUEST_FIELDS as FIELDS } from 'domain/lab';
import FilePreview from 'core/file/FileUpload/FilePreview';
import { convertToStringList } from 'presentation/lab/utils';
import ELabContext from 'presentation/lab/context/ELabContext';
import { CUSTOM_LAB_TESTS_LIST_ENDPOINT } from 'model/lab/constants';
import DiagnosisAutocomplete from 'presentation/components/DiagnosisAutocomplete';
import {
  If,
  Else,
  Card,
  Chip,
  Grid,
  Popup,
  Radio,
  Button,
  Dialog,
  Select,
  Message,
  MenuItem,
  TextField,
  Typography,
  RadioGroup,
  CardHeader,
  DatePicker,
  CardContent,
  DialogTitle,
  RequiredMark,
  Conditionally,
  EditableField,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from 'design-system';

const { GENDER } = userConstants;

const ELabDialog = ({
  open,
  user,
  doctor,
  onClose,
  document,
  isUpdate,
  clearFile,
  tenantKey,
  labRequests,
  isSubmitting,
  consultation,
  createDocument,
  updateLabRequest,
  createLabRequest,
  userHasWlpSubscription,
  clinics = [],
}) => {
  const ref = useRef();
  const eLabContext = useContext(ELabContext);

  const [isOpen, setOpen] = useState(false);
  const [isCreatingFile, setCreatingFile] = useState(false);
  const [isSubmitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);
  const [isFilePreviewOpen, setFilePreviewOpen] = useState(false);
  const [shouldAppendDocuments, setShouldAppendDocuments] = useState();

  const isCreatingOrUpdating = useMemo(() => {
    return isSubmitting || isCreatingFile;
  }, [isCreatingFile, isSubmitting]);

  const onClosed = () => {
    setOpen(false);
    onClose();
  };

  const onPreviewClicked = (data) => {
    eLabContext.setFormData(data);

    createDocument({
      clinic: clinics[0],
      patient: {
        id: userGetters.getId(user),
        fullName: data.patientName,
        age: data.dateOfBirth
          ? calculateAge(new Date(data.dateOfBirth)).ageDisplay
          : '',
        phoneNumber: userGetters.getPhoneNumber(user),
        gender: data.gender ? GENDER[data.gender]?.label || data.gender : '',
        dateOfBirth: data.dateOfBirth
          ? format(new Date(data.dateOfBirth), dateFormat)
          : '',
      },
      doctor: {
        id: userGetters.getId(doctor),
        fullName: userGetters.getFullName(doctor),
        licenseNumber: doctorGetters.getLicenseNumber(doctor),
        stampFile: doctorGetters.getStampFile(doctor),
        signatureFile: doctorGetters.getSignatureFile(doctor),
      },
      consultation: {
        id: consultationGetters.getId(consultation),
        startTime: consultationGetters.getStartTime(consultation),
      },
      diagnosis: {
        primaryDiagnosis: (data.primaryDiagnosis || []).map(
          (item) => item.name
        ),
        secondaryDiagnosis: (data.secondaryDiagnosis || []).map(
          (item) => item.name
        ),
      },
      labTests: data.labTests,
      doctorNotes: data.doctorNotes,
    });
  };

  const handleFilePreviewClose = () => {
    clearFile();
    setFilePreviewOpen(false);
  };

  const onSubmit = async (callback = () => {}) => {
    if (!document?.url) {
      Message.error('Document not available. Please report it to the team.');
      return;
    }

    const blob = await fileUtils.objectUrlToBlob(document?.url);

    setCreatingFile(true);
    fileAPI
      .uploadBlob(blob, FILE_TYPES.DOCUMENTS, userGetters.getId(user))
      .then((file) => {
        const payload = {
          [FIELDS.CONSULTATION_ID.name]:
            consultationGetters.getId(consultation),
          [FIELDS.DOCUMENTS.name]: [file.id],
          [FIELDS.USER_ID.name]: userGetters.getId(user),
          [FIELDS.USER_HAS_WLP_SUBSCRIPTION.name]: userHasWlpSubscription,
          [FIELDS.TEST_LIST.name]: convertToStringList(
            eLabContext?.formData?.labTests || {}
          ),
          [FIELDS.TENANT_ID.name]: tenantKey,
          [FIELDS.DOCTOR_ID.name]: userGetters.getId(doctor),
          [FIELDS.CHANNEL.name]: LAB_TEST_CHANNELS.CONSULTATION_FOLLOWUP.key,
          // Not part of lab request payload,
          // but in the domain/lab/saga, if found, it's added as a comment
          doctorNotes: eLabContext?.formData?.doctorNotes,
        };
        callback(payload);
        handleFilePreviewClose();
        onClosed();
      })
      .finally(() => {
        setCreatingFile(false);
      });
  };

  const onCreate = () => {
    onSubmit((payload) => {
      createLabRequest(payload);
    });
  };

  const onUpdate = () => {
    onSubmit((payload) => {
      updateLabRequest(labRequests[0].id, {
        ...payload,
        [FIELDS.APPEND_DOCUMENTS_ON_UPDATE.name]: shouldAppendDocuments,
      });
      setShouldAppendDocuments();
    });
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    if (!isEmpty(document)) {
      setFilePreviewOpen(true);
    } else {
      setOpen(open);
    }
  }, [open, document]);

  return (
    <>
      <Dialog
        fullScreen
        onClose={onClosed}
        open={isOpen && !isFilePreviewOpen}
        aria-labelledby="e-lab-dialog-title"
      >
        <DialogTitle id="e-lab-dialog-title" onClose={onClosed}>
          {isUpdate ? 'Update E-Lab Request' : 'E-Lab Request'}
        </DialogTitle>
        <DialogContent dividers>
          <Form
            ref={ref}
            onSubmit={(data) => {
              onPreviewClicked(data);
            }}
            onError={() => {
              Message.error('Please fill in the required fields');
            }}
          >
            {({ control }) => (
              <Grid container column spacing={2}>
                {/* Patient Profile */}
                <Grid item>
                  <Card>
                    <CardHeader title="Patient Profile" sx={{ pb: 2 }} />
                    <CardContent>
                      <Grid container column>
                        {/* Row 1 */}
                        <Grid
                          item
                          container
                          spacing={2}
                          justifyContent="space-between"
                          sx={{ mb: 1 }}
                        >
                          {/* Patient Name */}
                          <Grid item xs={6}>
                            <Controller
                              name="patientName"
                              control={control}
                              defaultValue={
                                eLabContext.formData?.patientName ||
                                userGetters.getFullName(user)
                              }
                              rules={{
                                required: 'Patient name is required',
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <EditableField
                                  label="PATIENT NAME"
                                  required
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  error={Boolean(error)}
                                  render={({ value, onChange }) => (
                                    <TextField
                                      fullWidth
                                      autoFocus
                                      size="small"
                                      value={value}
                                      onChange={onChange}
                                      placeholder="Patient Name.."
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* Phone No */}
                          <Grid item xs={6}>
                            <EditableField
                              readonly
                              label="PHONE NO"
                              defaultValue={userGetters.getPhoneNumber(user)}
                            />
                          </Grid>
                        </Grid>
                        {/* Row 2 */}
                        <Grid
                          item
                          container
                          spacing={2}
                          justifyContent="space-between"
                          sx={{ mb: 1 }}
                        >
                          {/* Gender */}
                          <Grid item xs={6}>
                            <Controller
                              name="gender"
                              control={control}
                              defaultValue={eLabContext.formData?.gender}
                              render={({ field }) => (
                                <EditableField
                                  label="GENDER"
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  valueGetter={(val) =>
                                    GENDER[val]?.label || val
                                  }
                                  render={({ value, onChange }) => (
                                    <Select
                                      fullWidth
                                      size="small"
                                      soak="light"
                                      variant="filled"
                                      value={value}
                                      onChange={onChange}
                                      renderValue={(val) =>
                                        GENDER[val]?.label || val
                                      }
                                    >
                                      {keys(GENDER).map((type) => (
                                        <MenuItem key={type} value={type}>
                                          {GENDER[type].label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                />
                              )}
                            />
                          </Grid>
                          {/* DOB */}
                          <Grid item xs={6}>
                            <Controller
                              name="dateOfBirth"
                              control={control}
                              defaultValue={eLabContext.formData?.dateOfBirth}
                              render={({ field }) => (
                                <EditableField
                                  label="DATE OF BIRTH"
                                  onSave={field.onChange}
                                  defaultValue={field.value}
                                  valueGetter={(val) =>
                                    val ? format(val, dateFormat) : val
                                  }
                                  render={({
                                    value,
                                    onBlur,
                                    onAccept,
                                    onChange,
                                  }) => (
                                    <DatePicker
                                      value={value}
                                      onChange={onChange}
                                      onAccept={onAccept}
                                      maxDate={new Date()}
                                      inputFormat={dateFormat}
                                      renderInput={(props) => (
                                        <TextField
                                          fullWidth
                                          size="small"
                                          {...props}
                                          onBlur={onBlur}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Diagnosis */}
                <Grid item>
                  <Card>
                    <CardHeader title="Diagnosis" sx={{ pb: 0 }} />
                    <CardContent>
                      <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                      >
                        {/* Primary diagnosis */}
                        <Grid item xs={6}>
                          <Controller
                            name="primaryDiagnosis"
                            control={control}
                            defaultValue={
                              eLabContext.formData?.primaryDiagnosis
                            }
                            rules={{
                              required: 'Primary diagnosis is required',
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <EditableField
                                label="PRIMARY DIAGNOSIS"
                                required
                                onSave={field.onChange}
                                defaultValue={field.value}
                                error={Boolean(error)}
                                valueGetter={(value) => {
                                  return (value || [])
                                    .map((item) => `${item.cod} - ${item.name}`)
                                    .join(' / ');
                                }}
                                render={({ value, onChange }) => (
                                  <DiagnosisAutocomplete
                                    multiple
                                    size="medium"
                                    onChange={onChange}
                                    defaultValue={value}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        {/* Secondary diagnosis */}
                        <Grid item xs={6}>
                          <Controller
                            name="secondaryDiagnosis"
                            control={control}
                            defaultValue={
                              eLabContext.formData?.secondaryDiagnosis
                            }
                            render={({ field }) => (
                              <EditableField
                                label="SECONDARY DIAGNOSIS"
                                onSave={field.onChange}
                                defaultValue={field.value}
                                valueGetter={(value) => {
                                  return (value || [])
                                    .map((item) => `${item.cod} - ${item.name}`)
                                    .join(' / ');
                                }}
                                render={({ value, onChange }) => (
                                  <DiagnosisAutocomplete
                                    multiple
                                    size="medium"
                                    defaultValue={value}
                                    onChange={onChange}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Lab tests */}
                <Grid item>
                  <Card>
                    <CardHeader
                      title={
                        <>
                          Lab Tests
                          <RequiredMark />
                        </>
                      }
                      sx={{ pb: 0 }}
                    />
                    <CardContent>
                      <Controller
                        name="labTests"
                        control={control}
                        defaultValue={eLabContext.formData?.labTests}
                        rules={{
                          required: 'At least one lab test is required',
                        }}
                        render={({ field }) => (
                          // TODO: Use LabTestsAutocomplete and fix how we populate this list (check the todo below)
                          <ServerAutocomplete
                            fullWidth
                            multiple
                            defaultValue={field.value || []}
                            baseURL={getAppConfigEndpoint()}
                            endpoint={CUSTOM_LAB_TESTS_LIST_ENDPOINT}
                            getOptionLabel={(option) =>
                              option ? option?.name : ''
                            }
                            optionsEqual={(optionA, optionB) => {
                              // TODO: Why did we have to add ?? optionA ?
                              const nameA = optionA?.name ?? optionA;
                              const nameB = optionB?.name ?? optionB;

                              return nameA === nameB;
                            }}
                            onChange={field.onChange}
                            getRequestParams={(searchQuery) => {
                              const urlSearchParams = new URLSearchParams();

                              urlSearchParams.append('term', searchQuery);

                              return urlSearchParams;
                            }}
                            throttle={(fn, searchQuery) => {
                              if (searchQuery.length >= 2) {
                                fn();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                placeholder="ex: CBC"
                                {...params}
                              />
                            )}
                            renderTags={(value, getTagProps) => {
                              return value.map((option, index) => {
                                const tagProps = getTagProps({ index });

                                return (
                                  <Chip
                                    size="medium"
                                    color="primary"
                                    label={option.name || option}
                                    onClick={tagProps?.onDelete}
                                    {...tagProps}
                                  />
                                );
                              });
                            }}
                            sx={{
                              '& .MuiAutocomplete-inputRoot': {
                                height: 'auto',
                              },
                            }}
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                {/* Doctor Notes */}
                <Grid item>
                  <Card>
                    <CardHeader title="Doctor Notes" sx={{ pb: 0 }} />
                    <CardContent>
                      <Controller
                        name="doctorNotes"
                        control={control}
                        defaultValue={eLabContext.formData?.doctorNotes}
                        render={({ field }) => (
                          <TextField
                            rows={3}
                            fullWidth
                            multiline
                            variant="filled"
                            value={field.value}
                            placeholder="Add notes.."
                            onChange={field.onChange}
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Form>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            variant="filled"
            onClick={() => {
              ref.current.submit();
            }}
            disabled={isCreatingOrUpdating}
          >
            Preview E-Lab Document
          </Button>
        </DialogActions>
      </Dialog>
      <FilePreview
        files={(() => {
          return document ? [document] : [];
        })()}
        open={isFilePreviewOpen}
        dialogActions={
          <>
            <Button
              variant="filled"
              disabled={isCreatingOrUpdating}
              onClick={() => {
                handleFilePreviewClose();
                setOpen(true);
              }}
            >
              Edit Lab Request
            </Button>
            <Conditionally>
              <If condition={isUpdate}>
                <Button
                  variant="filled"
                  spinning={isCreatingOrUpdating}
                  onClick={() => {
                    setUpdatePopupOpen(true);
                  }}
                >
                  Update Lab Request
                </Button>
              </If>
              <Else>
                <Button
                  variant="filled"
                  spinning={isCreatingOrUpdating}
                  onClick={() => {
                    setSubmitPopupOpen(true);
                  }}
                >
                  Create Lab Request
                </Button>
              </Else>
            </Conditionally>
          </>
        }
        dialogProps={{
          scrollable: true,
        }}
        onClose={() => {
          setFilePreviewOpen(false);
        }}
        showCloseConfirm
        closeConfirmProps={{
          title: 'Close E-Lab',
          content:
            "You're about to lose E-Lab changes and document. Are you sure you want to close and start over later?",
          secondaryButtonTitle: 'Yes, discard changes',
          onYes: () => {
            onClosed();
            handleFilePreviewClose();
          },
        }}
      />
      <Popup
        id={isSubmitPopupOpen ? 'e-lab-submit-confirm' : undefined}
        open={isSubmitPopupOpen}
        primaryButtonTitle="Yes"
        onOk={() => {
          onCreate();
          setSubmitPopupOpen(false);
        }}
        secondaryButtonTitle="No"
        onCancel={() => {
          setSubmitPopupOpen(false);
        }}
        title="Create Lab Request"
      >
        Do you want to create a lab request from this E-Lab document?
      </Popup>
      <Popup
        id={isUpdatePopupOpen ? 'e-lab-update-mode' : undefined}
        open={isUpdatePopupOpen}
        primaryButtonTitle="Update"
        onOk={() => {
          onUpdate();
          setUpdatePopupOpen(false);
        }}
        primaryButtonProps={{
          disabled: shouldAppendDocuments === undefined,
        }}
        secondaryButtonTitle="Cancel"
        onCancel={() => {
          setUpdatePopupOpen(false);
          setShouldAppendDocuments();
        }}
        title="Update Lab Request"
      >
        <Grid>
          <Typography variant="l3">
            There is at least one Lab request already sent for this
            consultation. Please choose one of the below options to reflect it
            on the existing lab request:
          </Typography>
          <RadioGroup
            row
            aria-labelledby="elab-request-update-mode-radio-buttons-group-label"
            name="elab-request-update-mode-radio-buttons-group"
            onChange={(e) => {
              if (e.target.value === 'append') {
                setShouldAppendDocuments(true);
              } else if (e.target.value === 'replace') {
                setShouldAppendDocuments(false);
              }
            }}
          >
            <FormControlLabel
              value="append"
              control={<Radio size="small" />}
              label="Append the new lab request in addition to the existing one"
            />
            <FormControlLabel
              value="replace"
              control={<Radio size="small" />}
              label="Replace the new lab request with all old lab requests"
            />
          </RadioGroup>
        </Grid>
      </Popup>
    </>
  );
};

export default ELabDialog;
