export const PACKAGE_NAME = 'ConsultationType';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  ARABIC_SUBTITLE: {
    name: 'arabicSubtitle',
  },
  ARABIC_TITLE: {
    name: 'arabicTitle',
  },
  ASSIGN_DOCTOR_ON_BOOKING: {
    name: 'assignDoctorOnBooking',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  CONSULTATION_TYPE_CONFIGURATIONS: {
    name: 'consultationTypeConfigurations',
  },
  DISPLAY_ORDER: {
    name: 'displayOrder',
  },
  SLOT_DURATION: {
    name: 'slotDuration',
  },
  DOCTOR_IDS: {
    name: 'doctorIds',
  },
  ENGLISH_TITLE: {
    name: 'englishTitle',
  },
  ENGLISH_SUBTITLE: {
    name: 'englishSubtitle',
  },
  FOCUSED: {
    name: 'focused',
  },
  IS_VISIBLE_FOR_USERS: {
    name: 'isVisibleForUsers',
  },
  PRICE: {
    name: 'price',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
};

export const CONSULTATION_TYPE_CONFIGURATIONS_FIELDS = {
  ID: {
    name: 'id',
  },
  CONSULTATION_TYPE_ID: {
    name: 'consultationTypeId',
  },
  DOCTORS: {
    name: 'doctors',
  },
  INSURANCE_PROVIDER_ID: {
    name: 'insuranceProviderId',
  },
  IS_ENABLED: {
    name: 'isEnable',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
};
