import { connect } from 'react-redux';

import { labSelectors } from 'domain/lab';
import appData from 'domain/app/HOCs/appData';
import { userActions, userSelectors } from 'domain/user';
import {
  memberUtils,
  memberActions,
  memberSelectors,
} from 'domain/user/member';
import {
  consultationActions as actions,
  consultationSelectors as selectors,
} from 'domain/consultation';

import ConsultationView from './ConsultationView';

const mapStateToProps = (state) => {
  const consultation = selectors.getConsultation(state);
  const isFetching = selectors.isFetchingConsultation(state);
  const isSubmitting = selectors.isSubmittingConsultation(state);
  const labTests = labSelectors.getConsultationLabRequests(state);
  const consultationPatients = selectors.getConsultationMembers(state);
  const isUpdatingPatient = selectors.isUpdatingConsultationPatient(state);

  const owner = userSelectors.getUser(state);
  const isFetchingOwner = userSelectors.isFetchingUser(state);

  const ownerHealthProfile = userSelectors.getHealthInfo(state);
  const isFetchingOwnerHealthProfile = userSelectors.isFetchingHealth(state);

  const members = memberSelectors.getMembers(state) || [];
  const isFetchingMembers = memberSelectors.isFetchingMembers(state);
  const patientsUserInfoById = memberUtils.ownerAndMembersToObjectById(
    owner,
    members
  );

  // Includes members health profiles
  const membersHealthProfiles =
    memberSelectors.getMembersHealthProfiles(state) || [];
  const isFetchingMembersHealthProfiles =
    memberSelectors.isFetchingMembersHealthProfiles(state);
  const patientsHealthProfilesByUserId =
    memberUtils.ownerAndMembersToObjectById(
      ownerHealthProfile,
      membersHealthProfiles,
      'userId'
    );

  return {
    owner,
    labTests,
    isFetching,
    isSubmitting,
    consultation,
    isFetchingOwner,
    isFetchingMembers,
    isUpdatingPatient,
    consultationPatients,
    patientsUserInfoById,
    isFetchingOwnerHealthProfile,
    patientsHealthProfilesByUserId,
    isFetchingMembersHealthProfiles,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(actions.clearConsultation()),
  getConsultation: (id) => dispatch(actions.getConsultationById(id)),
  updateConsultation: (id, payload, successCb, failureCb) =>
    dispatch(actions.updateConsultation(id, payload, successCb, failureCb)),
  joinConsultationMeetingStarted: (id, action) =>
    dispatch(actions.addConsultationMeetingStarted(id, action)),
  fetchOwner: (ownerId) => dispatch(userActions.fetchUser(ownerId)),
  fetchOwnerHealthProfile: (ownerId) =>
    dispatch(userActions.fetchPatientHealthInfo(ownerId)),
  fetchPatientsUserInfo: (ownerId, patientsUserIds) =>
    dispatch(memberActions.fetchMembers(ownerId, patientsUserIds)),
  fetchPatientsHealthProfiles: (ownerId, patientsUserIds) =>
    dispatch(
      memberActions.fetchMembersHealthProfiles(ownerId, patientsUserIds)
    ),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(ConsultationView)
);
