import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as AUTH } from './constants';

export const LOG_IN = `${DOMAIN}/${AUTH}/LOG_IN`;
export const SET_TOKEN = `${DOMAIN}/${AUTH}/SET_TOKEN`;
export const SET_USER_ID = `${DOMAIN}/${AUTH}/SET_USER_ID`;
export const SET_MAIN_ROLE = `${DOMAIN}/${AUTH}/SET_MAIN_ROLE`;
export const SET_USER_ROLES = `${DOMAIN}/${AUTH}/SET_USER_ROLES`;
export const SET_ERROR_MESSAGE = `${DOMAIN}/${AUTH}/SET_ERROR_MESSAGE`;
export const SET_DEFAULT_TENANT = `${DOMAIN}/${AUTH}/SET_DEFAULT_TENANT`;
export const SET_ALLOWED_TENANTS = `${DOMAIN}/${AUTH}/SET_ALLOWED_TENANTS`;
export const SET_SELECTED_TENANT = `${DOMAIN}/${AUTH}/SET_SELECTED_TENANT`;

export const SET_LOGGING_IN = `${DOMAIN}/${AUTH}/SET_LOGGING_IN`;

export const LOG_IN_SUCCEEDED = `${DOMAIN}/${AUTH}/LOG_IN_SUCCEEDED`;
