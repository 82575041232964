/* File model constants */
export const PACKAGE_NAME = 'File';
export const ENDPOINT = '/file';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  FILE_NAME: {
    name: 'fileName',
  },
  TYPE: {
    name: 'type',
  },
  URL: {
    name: 'url',
  },
};
