import * as types from './actionTypes';

export const fetchConsultationTypeById = (id) => ({
  type: types.FETCH_CONSULTATION_TYPE_BY_ID,
  id,
});

export const fetchConsultationTypes = () => ({
  type: types.FETCH_CONSULTATION_TYPES,
});

export const updateConsultationType = (id, payload) => ({
  type: types.UPDATE_CONSULTATION_TYPE,
  id,
  payload,
});

export const createConsultationType = (payload) => ({
  type: types.CREATE_CONSULTATION_TYPE,
  payload,
});

export const consultationTypeReceived = (consultationType, id) => ({
  type: types.CONSULTATION_TYPE_RECEIVED,
  consultationType,
  id,
});

export const consultationTypesReceived = (consultationTypes) => ({
  type: types.CONSULTATION_TYPES_RECEIVED,
  consultationTypes,
});

export const consultationTypeUpdatedResponded = (ok, response) => ({
  type: types.CONSULTATION_TYPE_UPDATE_RESPONDED,
  ok,
  response,
});

export const consultationTypeCreateResponded = (ok, response) => ({
  type: types.CONSULTATION_TYPE_CREATE_RESPONDED,
  ok,
  response,
});

export const fetchConsultationTypeConfigurations = (consultationTypeId) => ({
  type: types.FETCH_CONSULTATION_TYPE_CONFIGURATIONS,
  consultationTypeId,
});

export const addConsultationTypeConfiguration = (payload) => ({
  type: types.ADD_CONSULTATION_TYPE_CONFIGURATION,
  payload,
});

export const deleteConsultationTypeConfiguration = (id) => ({
  type: types.DELETE_CONSULTATION_TYPE_CONFIGURATION,
  id,
});

export const updateConsultationTypeConfiguration = (id, payload) => ({
  type: types.UPDATE_CONSULTATION_TYPE_CONFIGURATION,
  id,
  payload,
});

export const consultationTypeConfigurationsReceived = (
  consultationTypeConfigurations,
  consultationTypeId
) => ({
  type: types.CONSULTATION_TYPE_CONFIGURATIONS_RECEIVED,
  consultationTypeConfigurations,
  consultationTypeId,
});

export const consultationTypeConfigurationUpdateResponded = (ok, response) => ({
  type: types.CONSULTATION_TYPE_CONFIGURATION_UPDATE_RESPONDED,
  ok,
  response,
});

export const consultationTypeConfigurationAddResponded = (ok, response) => ({
  type: types.CONSULTATION_TYPE_CONFIGURATION_ADD_RESPONDED,
  ok,
  response,
});

export const consultationTypeConfigurationDeleteResponded = (ok, response) => ({
  type: types.CONSULTATION_TYPE_CONFIGURATION_DELETE_RESPONDED,
  ok,
  response,
});
