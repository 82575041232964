import get from 'lodash/get';
import { createSelector } from 'reselect';

export const tenantSelector = (state) => get(state, 'domain.tenant', {});

export const getAllowedTenants = createSelector(tenantSelector, (results) =>
  get(results, 'list')
);

export const getCities = createSelector(tenantSelector, (results) =>
  get(results, 'cities')
);
