import * as types from './actionTypes';

export const fetchInsuranceProviderList = () => ({
  type: types.FETCH_INSURANCE_PROVIDER_LIST,
});

export const insuranceProviderListReceived = (data) => ({
  type: types.INSURANCE_PROVIDER_LIST_RECEIVED,
  data,
});
