import keys from 'lodash/keys';
import { v4 as uuidv4 } from 'uuid';
import isArray from 'lodash/isArray';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { useRef, useState, useCallback, useMemo } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { doctorGetters } from 'domain/doctor';
import { consultationGetters } from 'domain/consultation';
import { medicineUtils, medicineConstants } from 'domain/medicine';
import { Form, Controller, formValidationFunctions } from 'design-system/form';
import MedicineAutocomplete from 'presentation/medicine/components/MedicineAutocomplete';
import {
  Grid,
  Popup,
  Select,
  Button,
  Divider,
  Tooltip,
  MenuItem,
  FormField,
  TextField,
  PageSpinner,
  Autocomplete,
  InputAdornment,
} from 'design-system';

import * as styles from '../../styles';
import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const {
  WHEN_HOW,
  ROUTE_OF_ADMIN,
  USE_UNIT_TYPES,
  DURATION_UNIT_TYPES,
  QUANTITY_UNIT_TYPES,
  FREQUENCY_UNIT_TYPES,
} = medicineConstants;

const PrescriptionForm = ({
  onEdit,
  expanded,
  confirmed,
  onSubmitted,
  patient,
  defaultValues,
  consultation,
  prescriptionDocumentFileId,
  createPatientPrescription,
  fetchPatientPrescription,
  patientDiagnosis,
  isFetchingPatientPrescription,
  getPatientPrescriptionById,
  updatePatientPrescription,
}) => {
  const prescriptionFormRef = useRef();

  const [isSaving, setSaving] = useState(false);
  const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const [pendingDeleteLineItemIndex, setPendingDeleteLineItemIndex] =
    useState();

  const refillInfoDetails =
    'Refill denotes the process to facilitate the patients receiving their maintenance medications from the Pharmacist, without referring to the Physician, within the limits of the law. In the above prescription, as the Physician denoted 1 under refill, the patient can approach the Pharmacy again (after consumption of his first month medication) and get his prescription filled (refilled) for another month, without referring back to his Physician. Pharmacists shall then document the number of refills with the date on the back of prescription.';

  const extractMedicines = (fromData) => {
    return fromData.medicines.map((lineItem) => ({
      // If the value is string then it's retuned from the mapper of the Form and
      // Don't need to add the id then other wise it's the retuned Id from DB then add it.
      id: typeof lineItem.id !== 'string' ? lineItem.id : undefined,
      prescriptionId: lineItem.prescriptionId,
      medicineId: lineItem.medicine.id,
      medicineSummary: lineItem.medicine.packageSummary,
      genericSummary: lineItem.medicine.genericSummary,
      routeOfAdmin: lineItem.roa,
      medicineTiming: lineItem.timing,
      instructions: lineItem.instructions,
      additionalInstructions: lineItem.additionalInstructions,
      use: lineItem.use,
      frequency: lineItem.frequency,
      duration: lineItem.duration,
      totalQuantity: lineItem.totalQuantity,
      refill: lineItem.refill,
    }));
  };

  const extractLineItems = useCallback((medicines) => {
    return medicines?.map((medicine) => ({
      medicine: {
        id: medicine.medicineId,
        packageSummary: medicine.medicineSummary,
        genericSummary: medicine.genericSummary,
      },
      id: medicine.id,
      prescriptionId: medicine.prescriptionId,
      roa: medicine.routeOfAdmin,
      timing: medicine.medicineTiming,
      instructions: medicine.instructions,
      additionalInstructions: medicine.additionalInstructions,
      use: medicine.use,
      frequency: medicine.frequency,
      duration: medicine.duration,
      totalQuantity: medicine.totalQuantity,
      refill: medicine.refill,
    }));
  }, []);

  const submitPrescription = useCallback(
    (data, successCallback) => {
      const { userId } = patient;
      const patientId = patient.id;
      const ownerId = consultationGetters.getUserId(consultation);
      const consultationId = consultationGetters.getId(consultation);
      const doctorId = consultationGetters.getDoctorId(consultation);
      const { tenantKey } = consultation.user;
      const medicines = extractMedicines(data);
      let patientDiagnosisDtos = [];

      if (isArray(patientDiagnosis?.diagnosis)) {
        patientDiagnosisDtos = patientDiagnosis?.diagnosis.map((diagnosis) => ({
          name: diagnosis.name,
          code: diagnosis.code,
          type: diagnosis.type,
          // id is excluded, as the one saved in doctor notes it's not the same as the one in prescription
        }));
      }

      if (!getPatientPrescriptionById(userId)) {
        createPatientPrescription(
          {
            userId,
            patientId,
            consultationId,
            doctorId,
            ownerId,
            tenantKey,
            medicines,
            patientDiagnosisDtos,
          },
          {
            success: successCallback,
          }
        );
      } else {
        updatePatientPrescription(
          {
            userId,
            patientId,
            consultationId,
            doctorId,
            ownerId,
            tenantKey,
            medicines,
            patientDiagnosisDtos,
          },
          {
            success: (prescriptionsResponse) => {
              prescriptionFormRef?.current
                ?.getMethods?.()
                .setValue(
                  'medicines',
                  extractLineItems(prescriptionsResponse?.medicines)
                );
              successCallback(prescriptionsResponse);
            },
          }
        );
      }
    },
    [
      patient,
      consultation,
      doctorGetters,
      consultationGetters,
      patientDiagnosis,
      prescriptionDocumentFileId,
      getPatientPrescriptionById,
    ]
  );

  const onSubmit = useCallback(
    (formData) => {
      setSaving(true);
      submitPrescription(formData, () => {
        onSubmitted(formData);
        setSaving(false);
      });
    },
    [onSubmitted, submitPrescription]
  );

  const handleDeleteLineItem = (lineItemIdx) => {
    const medicines = prescriptionFormRef?.current
      ?.getMethods?.()
      .getValues('medicines');
    const { length } = medicines;

    // Ensure there is at least one item to delete
    if (length <= 1) {
      return;
    }

    // Use splice to remove the item at the specified index
    medicines.splice(lineItemIdx, 1);

    // Update the medicines field in the form with the modified array
    prescriptionFormRef?.current
      ?.getMethods?.()
      .setValue('medicines', medicines);
  };

  const handleAddAnotherLineItem = () => {
    const newLineItem = {
      id: uuidv4(), // Function to generate a random ID
      // other properties of the new line item
    }; // Define your new line item here

    const lineItemsArray = prescriptionFormRef?.current
      ?.getMethods?.()
      .getValues('medicines');
    const updatedLineItems = lineItemsArray
      ? [...lineItemsArray, newLineItem]
      : [newLineItem];

    // Update the medicines field in the form
    prescriptionFormRef?.current
      ?.getMethods?.()
      .setValue('medicines', updatedLineItems);
  };

  const handleEdit = useCallback(async () => {
    const { userId } = patient;

    if (!getPatientPrescriptionById(userId)) {
      await fetchPatientPrescription(
        userId,
        consultationGetters.getId(consultation),
        {
          success: (prescriptionsResponse) => {
            if (prescriptionsResponse) {
              prescriptionFormRef?.current
                ?.getMethods?.()
                .setValue(
                  'medicines',
                  extractLineItems(prescriptionsResponse?.medicines)
                );
            }
          },
        }
      );
    } else if (getPatientPrescriptionById(userId)) {
      prescriptionFormRef?.current
        ?.getMethods?.()
        .setValue(
          'medicines',
          extractLineItems(getPatientPrescriptionById(userId)?.medicines)
        );
    }
    onEdit();
  }, [
    patient,
    consultation,
    getPatientPrescriptionById,
    fetchPatientPrescription,
    prescriptionFormRef,
    extractLineItems,
    onEdit,
  ]);

  const setInstructionsValues = (lineItemIdx) => {
    prescriptionFormRef?.current?.getMethods?.().setValue(
      `medicines.${lineItemIdx}.instructions`,
      medicineUtils.buildInstructions({
        useValue: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.use.value`),
        useUnit: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.use.unit`),
        frequencyValue: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.frequency.value`),
        frequencyUnit: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.frequency.unit`),
        durationValue: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.duration.value`),
        durationUnit: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.duration.unit`),
        whenHow: prescriptionFormRef?.current
          ?.getMethods?.()
          .watch(`medicines.${lineItemIdx}.timing`),
        isLong: true,
      })
    );
  };

  const mapDefaultValues = useMemo(() => {
    const updatedDefaults = {
      ...(defaultValues || {}),
      medicines: extractLineItems(defaultValues?.medicines),
    };

    return updatedDefaults;
  }, [defaultValues]);

  return (
    <>
      <Form
        ref={prescriptionFormRef}
        onSubmit={onSubmit}
        defaultValues={mapDefaultValues}
      >
        {({ control, watch }) => (
          <Section
            onEdit={handleEdit}
            expanded={expanded}
            confirmed={confirmed}
            identifier={SECTIONS.PRESCRIPTION.key}
            title={SECTIONS.PRESCRIPTION.label}
            actions={
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
              >
                <Grid item>
                  <Button
                    size="medium"
                    color="primary"
                    variant="text"
                    onClick={() => handleAddAnotherLineItem()}
                    startIcon={<AddCircleOutlineIcon fontSize="medium" />}
                  >
                    Add another
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: {
                      xs: '100%',
                      md: 'auto',
                    },
                  }}
                >
                  <Button
                    spinning={isSaving}
                    onClick={() => {
                      prescriptionFormRef?.current?.submit();
                    }}
                    sx={{
                      width: '100%',
                    }}
                  >
                    Save and continue
                  </Button>
                </Grid>
              </Grid>
            }
          >
            <Grid container column rowSpacing={3}>
              {/* Loop over medicines and render them */}
              {watch('medicines', [{ lineItemReferenceId: uuidv4() }]).map(
                (lineItem, lineItemIdx) => (
                  <Grid item key={lineItem.lineItemReferenceId}>
                    <Grid container columnSpacing={5}>
                      <Grid
                        item
                        sx={{
                          width: (theme) => ({
                            xs: '100%',
                            md: `calc(100% - ${theme.spacing(
                              7 /* Delete button width */ +
                                5 /* columnSpacing */
                            )})`,
                          }),
                        }}
                      >
                        {/* Form rows */}
                        <Grid container column rowSpacing={2}>
                          {/* First row: medicine and route of admin */}
                          <Grid item>
                            <Grid
                              container
                              direction={{
                                xs: 'column',
                                md: 'row',
                              }}
                              spacing={5}
                            >
                              <FormField
                                xs={12}
                                md={6}
                                label="MEDICINE"
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.medicine`}
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <MedicineAutocomplete
                                        size="medium"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        onChange={field.onChange}
                                        defaultValue={field.value}
                                      />
                                    )}
                                    rules={{
                                      required: 'Medicine is required',
                                    }}
                                  />
                                }
                              />
                              <FormField
                                xs={12}
                                md={6}
                                label="ROUTE OF ADMIN"
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.roa`}
                                    control={control}
                                    defaultValue=""
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Autocomplete
                                        fullWidth
                                        value={field.value}
                                        size="medium"
                                        options={ROUTE_OF_ADMIN}
                                        onChange={(_e, newValue) =>
                                          field.onChange(newValue)
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            error={Boolean(error)}
                                            helperText={error?.message ?? null}
                                            size="medium"
                                            variant="filled"
                                            placeholder="Select ROA.."
                                            sx={{
                                              '& .MuiInputBase-root': {
                                                pl: {
                                                  xs: '8px !important',
                                                  xl: '16px !important',
                                                },
                                              },
                                            }}
                                          />
                                        )}
                                      />
                                    )}
                                    rules={{
                                      required: 'Route of admin is required',
                                    }}
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                          {/* Second row: values and units */}
                          <Grid item>
                            <Grid container spacing={5}>
                              <FormField
                                xs={12}
                                sm={4}
                                md={2}
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.use.value`}
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        value={field.value}
                                        type="number"
                                        size="medium"
                                        min="1"
                                        variant="filled"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        placeholder="Take..."
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setInstructionsValues(lineItemIdx);
                                        }}
                                        sx={styles.valueField}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={styles.unitField}
                                            >
                                              <Controller
                                                name={`medicines.${lineItemIdx}.use.unit`}
                                                control={control}
                                                defaultValue={
                                                  USE_UNIT_TYPES.TABLET.key
                                                }
                                                render={({
                                                  field: unitField,
                                                  fieldState: {
                                                    error: unitError,
                                                  },
                                                }) => (
                                                  <Select
                                                    size="medium"
                                                    value={unitField.value}
                                                    error={Boolean(unitError)}
                                                    helperText={
                                                      unitError?.message ?? null
                                                    }
                                                    onChange={(e) => {
                                                      unitField.onChange(e);
                                                      setInstructionsValues(
                                                        lineItemIdx
                                                      );
                                                    }}
                                                    placeholder="Choose unit"
                                                    variant="filled"
                                                    color="standard"
                                                    renderValue={(value) =>
                                                      value
                                                        ? USE_UNIT_TYPES[value]
                                                            .label
                                                        : value
                                                    }
                                                  >
                                                    {keys(USE_UNIT_TYPES)
                                                      .filter(
                                                        (item) =>
                                                          !USE_UNIT_TYPES[item]
                                                            .hidden
                                                      )
                                                      .map((item) => (
                                                        <MenuItem
                                                          value={item}
                                                          key={item}
                                                        >
                                                          {
                                                            USE_UNIT_TYPES[item]
                                                              .label
                                                          }
                                                        </MenuItem>
                                                      ))}
                                                  </Select>
                                                )}
                                                rules={{
                                                  required:
                                                    'Use unit is required',
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                    rules={{
                                      required: 'Use value is required',
                                      validate: {
                                        positive: (v) =>
                                          formValidationFunctions.positive(v),
                                      },
                                    }}
                                  />
                                }
                                label="USAGE UNIT"
                              />
                              <FormField
                                xs={12}
                                sm={4}
                                md={2}
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.frequency.value`}
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        value={field.value}
                                        type="number"
                                        size="medium"
                                        variant="filled"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        min="1"
                                        placeholder="...times"
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setInstructionsValues(lineItemIdx);
                                        }}
                                        sx={styles.valueField}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={styles.unitField}
                                            >
                                              <Controller
                                                name={`medicines.${lineItemIdx}.frequency.unit`}
                                                control={control}
                                                defaultValue={
                                                  FREQUENCY_UNIT_TYPES.DAY.key
                                                }
                                                render={({
                                                  field: unitField,
                                                  fieldState: {
                                                    error: unitError,
                                                  },
                                                }) => (
                                                  <Select
                                                    value={unitField.value}
                                                    size="medium"
                                                    color="standard"
                                                    variant="filled"
                                                    error={Boolean(unitError)}
                                                    helperText={
                                                      unitError?.message ?? null
                                                    }
                                                    onChange={(e) => {
                                                      unitField.onChange(e);
                                                      setInstructionsValues(
                                                        lineItemIdx
                                                      );
                                                    }}
                                                    placeholder="Choose unit"
                                                    renderValue={(value) =>
                                                      value
                                                        ? FREQUENCY_UNIT_TYPES[
                                                            value
                                                          ]?.label
                                                        : value
                                                    }
                                                  >
                                                    {keys(FREQUENCY_UNIT_TYPES)
                                                      .filter(
                                                        (item) =>
                                                          !FREQUENCY_UNIT_TYPES[
                                                            item
                                                          ].hidden
                                                      )
                                                      .map((item) => (
                                                        <MenuItem
                                                          value={item}
                                                          key={item}
                                                        >
                                                          {
                                                            FREQUENCY_UNIT_TYPES[
                                                              item
                                                            ].label
                                                          }
                                                        </MenuItem>
                                                      ))}
                                                  </Select>
                                                )}
                                                rules={{
                                                  required:
                                                    'Frequency unit is required',
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                    rules={{
                                      required: 'Frequency value is required',
                                      validate: {
                                        positive: (v) =>
                                          formValidationFunctions.positive(v),
                                      },
                                    }}
                                  />
                                }
                                label="FREQUENCY"
                              />
                              <FormField
                                xs={12}
                                sm={4}
                                md={2}
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.duration.value`}
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        value={field.value}
                                        type="number"
                                        size="medium"
                                        variant="filled"
                                        min="1"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        placeholder="Until..."
                                        onChange={(e) => {
                                          field.onChange(e);
                                          setInstructionsValues(lineItemIdx);
                                        }}
                                        sx={styles.valueField}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={styles.unitField}
                                            >
                                              <Controller
                                                name={`medicines.${lineItemIdx}.duration.unit`}
                                                control={control}
                                                defaultValue={
                                                  DURATION_UNIT_TYPES.DAY.key
                                                }
                                                render={({
                                                  field: unitField,
                                                  fieldState: {
                                                    error: unitError,
                                                  },
                                                }) => (
                                                  <Select
                                                    value={unitField.value}
                                                    size="medium"
                                                    color="standard"
                                                    variant="filled"
                                                    error={Boolean(unitError)}
                                                    helperText={
                                                      unitError?.message ?? null
                                                    }
                                                    onChange={(e) => {
                                                      unitField.onChange(e);
                                                      setInstructionsValues(
                                                        lineItemIdx
                                                      );
                                                    }}
                                                    placeholder="Choose unit"
                                                    renderValue={(value) =>
                                                      value
                                                        ? DURATION_UNIT_TYPES[
                                                            value
                                                          ].label
                                                        : value
                                                    }
                                                  >
                                                    {keys(
                                                      DURATION_UNIT_TYPES
                                                    ).map((item) => (
                                                      <MenuItem
                                                        value={item}
                                                        key={item}
                                                      >
                                                        {
                                                          DURATION_UNIT_TYPES[
                                                            item
                                                          ].optionLabel
                                                        }
                                                      </MenuItem>
                                                    ))}
                                                  </Select>
                                                )}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                        rules={{
                                          required: 'Duration unit is required',
                                        }}
                                      />
                                    )}
                                    rules={{
                                      required: 'Duration value is required',
                                      validate: {
                                        positive: (v) =>
                                          formValidationFunctions.positive(v),
                                      },
                                    }}
                                  />
                                }
                                label="DURATION"
                              />
                              <FormField
                                xs={12}
                                sm={4}
                                md={2}
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.timing`}
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <Select
                                        fullWidth
                                        value={field.value}
                                        size="medium"
                                        color="standard"
                                        soak="light"
                                        variant="filled"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        placeholder="Select Timing.."
                                        onChange={(e) => {
                                          field.onChange(e); // Update the field value with the label
                                          setInstructionsValues(lineItemIdx);
                                        }}
                                        renderValue={(value) =>
                                          value ? WHEN_HOW[value]?.label : value
                                        }
                                      >
                                        {keys(WHEN_HOW)
                                          .filter(
                                            (item) => !WHEN_HOW[item].hidden
                                          )
                                          .map((item) => (
                                            <MenuItem value={item} key={item}>
                                              {WHEN_HOW[item].label}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    )}
                                    rules={{
                                      required: 'Timing is required',
                                    }}
                                  />
                                }
                                label="TIMING"
                              />
                              <FormField
                                xs={12}
                                sm={4}
                                md={2}
                                required
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.totalQuantity.value`}
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        value={field.value}
                                        type="number"
                                        size="medium"
                                        min="1"
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        variant="filled"
                                        placeholder="Total quantity"
                                        onChange={field.onChange}
                                        sx={styles.valueField}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={styles.unitField}
                                            >
                                              <Controller
                                                name={`medicines.${lineItemIdx}.totalQuantity.unit`}
                                                control={control}
                                                defaultValue={
                                                  QUANTITY_UNIT_TYPES.PACKET.key
                                                }
                                                render={({
                                                  field: unitField,
                                                  fieldState: {
                                                    error: unitError,
                                                  },
                                                }) => (
                                                  <Select
                                                    value={unitField.value}
                                                    size="medium"
                                                    color="standard"
                                                    variant="filled"
                                                    error={Boolean(unitError)}
                                                    helperText={
                                                      unitError?.message ?? null
                                                    }
                                                    onChange={
                                                      unitField.onChange
                                                    }
                                                    placeholder="Choose unit"
                                                    renderValue={(value) =>
                                                      value
                                                        ? QUANTITY_UNIT_TYPES[
                                                            value
                                                          ].label
                                                        : value
                                                    }
                                                  >
                                                    {keys(QUANTITY_UNIT_TYPES)
                                                      .filter(
                                                        (item) =>
                                                          !QUANTITY_UNIT_TYPES[
                                                            item
                                                          ].hidden
                                                      )
                                                      .map((item) => (
                                                        <MenuItem
                                                          value={item}
                                                          key={item}
                                                        >
                                                          {
                                                            QUANTITY_UNIT_TYPES[
                                                              item
                                                            ].label
                                                          }
                                                        </MenuItem>
                                                      ))}
                                                  </Select>
                                                )}
                                                rules={{
                                                  required:
                                                    'Quantity unit is required',
                                                }}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                    rules={{
                                      required: 'Quantity value is required',
                                      validate: {
                                        positive: (v) =>
                                          formValidationFunctions.positive(v),
                                      },
                                    }}
                                  />
                                }
                                label="QUANTITY"
                              />
                              <FormField
                                xs={12}
                                sm={4}
                                md={2}
                                help={refillInfoDetails}
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.refill.value`}
                                    control={control}
                                    defaultValue={null}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        value={field.value}
                                        type="number"
                                        size="medium"
                                        min="1"
                                        variant="filled"
                                        placeholder="Refill in..."
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        onChange={(e) => {
                                          field.onChange(e); // This updates the refill field value
                                        }}
                                        sx={styles.valueField}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={styles.unitField}
                                            >
                                              <Controller
                                                name={`medicines.${lineItemIdx}.refill.unit`}
                                                control={control}
                                                defaultValue={
                                                  DURATION_UNIT_TYPES.DAY.key
                                                }
                                                render={({
                                                  field: unitField,
                                                  fieldState: {
                                                    error: unitError,
                                                  },
                                                }) => (
                                                  <Select
                                                    value={unitField.value}
                                                    size="medium"
                                                    color="standard"
                                                    variant="filled"
                                                    error={Boolean(unitError)}
                                                    helperText={
                                                      unitError?.message ?? null
                                                    }
                                                    onChange={(e) => {
                                                      unitField.onChange(e); // This updates the refill field value
                                                    }}
                                                    placeholder="Choose unit"
                                                    renderValue={(value) =>
                                                      value
                                                        ? DURATION_UNIT_TYPES[
                                                            value
                                                          ].label
                                                        : value
                                                    }
                                                  >
                                                    {keys(
                                                      DURATION_UNIT_TYPES
                                                    ).map((item) => (
                                                      <MenuItem
                                                        value={item}
                                                        key={item}
                                                      >
                                                        {
                                                          DURATION_UNIT_TYPES[
                                                            item
                                                          ].optionLabel
                                                        }
                                                      </MenuItem>
                                                    ))}
                                                  </Select>
                                                )}
                                              />
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    )}
                                    rules={{
                                      validate: {
                                        positive: (v) =>
                                          formValidationFunctions.positive(v),
                                      },
                                    }}
                                  />
                                }
                                label="REFILL"
                              />

                              <FormField
                                xs={12}
                                sm={4}
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.instructions`}
                                    control={control}
                                    defaultValue="Instructions ..."
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        disabled
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                        type="text"
                                        size="medium"
                                        min="1"
                                        variant="filled"
                                        placeholder="Refill in..."
                                        sx={styles.valueField}
                                      />
                                    )}
                                  />
                                }
                                label="INSTRUCTIONS"
                              />

                              <FormField
                                xs={12}
                                sm={4}
                                field={
                                  <Controller
                                    name={`medicines.${lineItemIdx}.additionalInstructions`}
                                    control={control}
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <TextField
                                        fullWidth
                                        error={Boolean(error)}
                                        helperText={error?.message ?? null}
                                        value={field.value}
                                        onChange={(e) => {
                                          field.onChange(e);
                                        }}
                                        type="text"
                                        size="medium"
                                        min="1"
                                        variant="filled"
                                        placeholder="Additional instructions..."
                                        sx={styles.valueField}
                                      />
                                    )}
                                  />
                                }
                                label="ADDITIONAL INSTRUCTIONS"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          sx={{
                            display: { xs: 'none', md: 'flex' },
                            p: 0,
                            minWidth: 0,
                            height: '100%',
                            boxShadow: 'none',
                            width: (theme) => theme.spacing(7),
                            borderRadius: (theme) => theme.spacing(1),
                            backgroundColor: (theme) => theme.palette.grey[200],
                            '&:hover': {
                              backgroundColor: (theme) =>
                                theme.palette.grey[300],
                            },
                          }}
                          disabled={watch('medicines')?.length <= 1}
                          onClick={() => {
                            if (typeof lineItem.id !== 'string') {
                              setPendingDeleteLineItemIndex(lineItemIdx);
                              setConfirmPopupOpen(true);
                            } else {
                              handleDeleteLineItem(lineItemIdx);
                            }
                          }}
                        >
                          <Tooltip placement="top" title="Delete medicine">
                            <DeleteOutlinedIcon
                              fontSize="small"
                              color="error"
                            />
                          </Tooltip>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      sx={{
                        marginTop: '15px',
                        display: { xs: 'flex', md: 'none' },
                      }}
                      item
                    >
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Button
                            size="small"
                            color="error"
                            variant="text"
                            startIcon={<DeleteIcon />}
                            disabled={watch('medicines')?.length <= 1}
                            onClick={() => {
                              if (typeof lineItem.id !== 'string') {
                                setPendingDeleteLineItemIndex(lineItemIdx);
                                setConfirmPopupOpen(true);
                              } else {
                                handleDeleteLineItem(lineItemIdx);
                              }
                            }}
                          >
                            Remove medicine
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {(watch('medicines')?.length ?? 0) - 1 !== lineItemIdx && (
                      <Grid item sx={{ mt: 3 }}>
                        <Divider />
                      </Grid>
                    )}
                  </Grid>
                )
              )}
            </Grid>
            <PageSpinner open={isFetchingPatientPrescription} />
          </Section>
        )}
      </Form>
      <Popup
        id={isConfirmPopupOpen ? 'line-item-delete-confirm' : undefined}
        open={isConfirmPopupOpen}
        title="Delete Line Item"
        primaryButtonTitle="No, continue editing"
        secondaryButtonTitle="Yes, delete line item"
        onOk={() => {
          setConfirmPopupOpen(false);
          setPendingDeleteLineItemIndex();
        }}
        onCancel={() => {
          // Delete
          handleDeleteLineItem(pendingDeleteLineItemIndex);

          // Reset
          setConfirmPopupOpen(false);
          setPendingDeleteLineItemIndex();
        }}
      >
        Are you sure you want to delete this line item?
      </Popup>
    </>
  );
};

export default PrescriptionForm;
