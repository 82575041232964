export const textToColor = (text) => {
  let i;
  let color = '#';
  let hash = 0;

  for (i = 0; i < text.length; i += 1) {
    /* eslint-disable no-bitwise */
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }

  for (i = 0; i < 3; i += 1) {
    /* eslint-disable no-bitwise */
    const value = (hash >> (i * 8)) & 0xff;

    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
};

export const textToAvatarProps = ({ name, sx: otherSxProps }) => {
  return {
    sx: {
      bgcolor: textToColor(name),
      ...otherSxProps,
    },
    children: (() => {
      const parts = name.trim().split(' ').filter(Boolean);
      const firstName = parts[0];
      const lastName = parts.last();

      return `${firstName[0]}${lastName[0]}`;
    })(),
  };
};
