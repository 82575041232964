import { connect } from 'react-redux';

import { consultationActions } from 'domain/consultation';

import ConsultationOutcomesForm from './ConsultationOutcomesForm';

const mapDispatchToProps = (dispatch) => ({
  updateConsultationOutcomes: (
    consultationId,
    patientId,
    outcomes,
    tenantKey,
    successCallback
  ) =>
    dispatch(
      consultationActions.updateConsultationPatient(
        consultationId,
        patientId,
        {
          output: outcomes.map((outcomeKey) => ({
            tenantKey,
            referenceType: outcomeKey,
          })),
        },
        successCallback
      )
    ),
});

export default connect(null, mapDispatchToProps)(ConsultationOutcomesForm);
