import keys from 'lodash/keys';
import React, { useRef, useEffect, useState, useCallback } from 'react';

import { userGetters } from 'domain/user';
import { outcomeConstants } from 'domain/outcome';
import { Form, Controller } from 'design-system/form';
import { consultationGetters } from 'domain/consultation';
import {
  Grid,
  Chip,
  Select,
  Button,
  Checkbox,
  MenuItem,
  FormField,
} from 'design-system';

import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const { OUTCOME_OPTIONS } = outcomeConstants;

const ConsultationOutcomesForm = ({
  // Patient
  patient,
  // Relevant consultation
  consultation,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Update consultation outcomes for a patient
  updateConsultationOutcomes,
}) => {
  const formRef = useRef();

  const [isSaving, setSaving] = useState(false);

  // Exclude no show, because it's managed in Doctor Private Notes
  const outcomeOptionKeys = keys(OUTCOME_OPTIONS).filter(
    (outcomeKey) => outcomeKey !== OUTCOME_OPTIONS.NoShow.key
  );

  const onSubmit = useCallback(
    (formData) => {
      setSaving(true);
      updateConsultationOutcomes(
        consultationGetters.getId(consultation),
        patient.id,
        formData.outcomes,
        userGetters.getTenant(consultationGetters.getUser(consultation)),
        () => {
          onSubmitted(formData);
          setSaving(false);
        }
      );
    },
    [patient, onSubmitted, consultation, updateConsultationOutcomes]
  );

  useEffect(() => {
    const formMethods = formRef?.current?.getMethods?.();
    const currentOutcomes = formMethods?.getValues?.()?.outcomes || [];
    const passedDefaultOutcomes = defaultValues?.outcomes || [];

    if (currentOutcomes.length !== passedDefaultOutcomes.length) {
      // If default values changed from outside (Check how in ManagementAndTreatmentPlanForm),
      // and if the outcomes actually changed, then reset the form with the new outcomes
      formMethods?.reset(defaultValues);
    }
  }, [formRef, defaultValues]);

  return (
    <Form ref={formRef} onSubmit={onSubmit} defaultValues={defaultValues}>
      {({ control }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.CONSULTATION_OUTCOMES.key}
          title={SECTIONS.CONSULTATION_OUTCOMES.label}
          actions={
            <Button
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
              spinning={isSaving}
              onClick={() => {
                formRef?.current?.submit();
              }}
            >
              Save and continue
            </Button>
          }
        >
          <FormField
            required
            label="CONSULTATION OUTCOMES"
            field={
              <Controller
                name="outcomes"
                control={control}
                defaultValue={[]}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Select
                    fullWidth
                    multiple
                    color="success"
                    soak="light"
                    size="medium"
                    variant="filled"
                    value={value}
                    onChange={onChange}
                    placeholder="Select consultations outcomes..."
                    error={Boolean(error)}
                    helperText={error?.message ?? null}
                    renderValue={(selectedValues) => (
                      <Grid container flexWrap="wrap" gap={0.5}>
                        {selectedValues.map((outcome) => (
                          <Chip
                            size="small"
                            color="primary"
                            key={OUTCOME_OPTIONS[outcome]?.key || outcome}
                            label={OUTCOME_OPTIONS[outcome]?.label || outcome}
                          />
                        ))}
                      </Grid>
                    )}
                  >
                    {outcomeOptionKeys.map((outcomeKey) => (
                      <MenuItem
                        key={outcomeKey}
                        value={outcomeKey}
                        disabled={
                          // If the outcome option is lab test request or prescription
                          [
                            OUTCOME_OPTIONS.LabTestRequest.key,
                            OUTCOME_OPTIONS.Prescription.key,
                          ].includes(outcomeKey) &&
                          // and if the patient already has that outcome
                          (patient?.output || [])
                            .map((outcome) => outcome.referenceType)
                            .includes(outcomeKey)
                        }
                      >
                        <Checkbox checked={value.includes(outcomeKey)} />
                        {OUTCOME_OPTIONS[outcomeKey].label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                rules={{
                  required: 'Please select consultation outcomes',
                }}
              />
            }
          />
        </Section>
      )}
    </Form>
  );
};

export default ConsultationOutcomesForm;
