import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as PRESCRIPTION } from './constants';

export const CREATE_PRESCRIPTION = `${MODEL}/${PRESCRIPTION}/CREATE_PRESCRIPTION`;
export const CREATE_PATIENT_PRESCRIPTION = `${MODEL}/${PRESCRIPTION}/CREATE_PATIENT_PRESCRIPTION`;
export const UPDATE_PATIENT_PRESCRIPTION = `${MODEL}/${PRESCRIPTION}/UPDATE_PATIENT_PRESCRIPTION`;
export const CREATE_E_PRESCRIPTION_DOCUMENT = `${MODEL}/${PRESCRIPTION}/CREATE_E_PRESCRIPTION_DOCUMENT`;
export const CREATE_PATIENT_PRESCRIPTION_DOCUMENT = `${MODEL}/${PRESCRIPTION}/CREATE_PATIENT_PRESCRIPTION_DOCUMENT`;
export const SUBMIT_ALL_PRESCRIPTIONS = `${MODEL}/${PRESCRIPTION}/SUBMIT_ALL_PRESCRIPTIONS`;
export const E_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED = `${MODEL}/${PRESCRIPTION}/E_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED`;
export const PATIENT_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED = `${MODEL}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED`;
export const PATIENT_PRESCRIPTION_CREATION_RESPONDED = `${MODEL}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_CREATION_RESPONDED`;
export const ALL_PRESCRIPTIONS_SUBMISSION_RESPONDED = `${MODEL}/${PRESCRIPTION}/ALL_PRESCRIPTIONS_SUBMISSION_RESPONDED`;
export const PATIENT_PRESCRIPTION_UPDATE_RESPONDED = `${MODEL}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_UPDATE_RESPONDED`;
export const PRESCRIPTION_CREATION_RESPONDED = `${MODEL}/${PRESCRIPTION}/PRESCRIPTION_CREATION_RESPONDED`;
export const FETCH_PATIENT_PRESCRIPTION = `${MODEL}/${PRESCRIPTION}/FETCH_PATIENT_PRESCRIPTION`;
export const PATIENT_PRESCRIPTION_FETCH_RESPONDED = `${MODEL}/${PRESCRIPTION}/PATIENT_PRESCRIPTION_FETCH_RESPONDED`;
