import values from 'lodash/values';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import { useParams } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import MessageIcon from '@mui/icons-material/Message';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { FireTruckOutlined, InfoOutlined } from '@mui/icons-material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { fileGetters } from 'domain/file';
import Comments from 'presentation/comment';
import { isAllowed } from 'core/permission';
import FileUpload from 'core/file/FileUpload';
import useObjectMapper from 'core/objectMapper';
import { downloadPdfFile } from 'core/pdf/utils';
import {
  isShowLogisticProvider,
  isDeliveryConsentFormEnabled,
} from 'core/siteConfig';
import { REFERENCE_TYPES } from 'constants/general';
import { consultationGetters } from 'domain/consultation';
import DownloadPdfButton from 'core/pdf/DownloadPdfButton';
import PatientInfo from 'presentation/components/PatientInfo';
import { userGetters, userAddressGetters } from 'domain/user';
import { dateFormat, dateTimeFormat } from 'constants/dateTime';
import { patientHistoryConstants } from 'domain/patientHistory';
import { PatientHistorySection } from 'presentation/patientHistory';
import FollowUpRemindersManagement from 'presentation/followUpReminder';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';
import UserAddressField from 'presentation/user/components/UserAddressField';
import DiagnosisAutocomplete from 'presentation/components/DiagnosisAutocomplete';
import { LOCATION_SHARE_CHANNELS } from 'presentation/user/components/LocationShare';
import ChronicConditionsField from 'presentation/user/components/ChronicConditionsField';
import SendNotification from 'presentation/consultation/ConsultationView/components/SendNotification';
import {
  orderUtils as utils,
  orderGetters as getters,
  orderConstants as constants,
} from 'domain/order';
import {
  If,
  Else,
  Grid,
  Card,
  Link,
  Chip,
  Select,
  Dialog,
  Button,
  Tooltip,
  Message,
  Divider,
  MenuItem,
  PageTitle,
  TextField,
  FormField,
  IconButton,
  CardHeader,
  Typography,
  DatePicker,
  CardContent,
  CardActions,
  DialogTitle,
  DisplayField,
  RowColLayout,
  DialogContent,
  DialogActions,
  Conditionally,
  LinearProgress,
} from 'design-system';

import OrderHistory from '../OrderHistory';
import LineItemList from './components/LineItemList';
import OrderPaymentSection from './components/OrderPaymentSection';
import LogisticProviderDialog from './components/LogisticProviderDialog';
import PredefinedRenewalReminders from './components/PredefinedRenewalReminders';

const {
  FIELDS,
  STATUSES,
  CHANNELS,
  DELIVERY_ADDRESS_FIELDS,
  DELIVERY_FAILURE_REASONS,
} = constants;

const moneyToString = (money) =>
  money ? `${money.currency} ${Number(money.value).toFixed(2)}` : '';

const OrderView = ({
  tenants,
  drivers,
  pharmacists,
  fetchOrder,
  updateOrder,
  consultation,
  isSubmitting,
  getConsultation,
  isFetchingTaskId,
  logisticProviders,
  shipment,
  order: serverOrder,
  addDeliveryAddress,
  fetchShipmentLabel,
  fetchShipment,
  clearConsultation,
  isUpdatingLineItems,
  updateDeliveryAddress,
  isFetchingConsultation,
  onDeliveryAddressSubmitted,
  isFetching: isFetchingOrder,
  fetchUser,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;

  const isUpdating = useRef({});
  const deliveryAddressRef = useRef();

  const [errors, setErrors] = useState({});
  const [isFetching, setFetching] = useState(true);

  const [logisticProvider, setLogisticProvider] = useState();
  useState();
  const [selectedLogisticProviderId, setSelectedLogisticProviderId] =
    useState();
  const [isLogisticProviderDialogOpen, setLogisticProviderDialogOpen] =
    useState(false);
  const [isShipmentSentToLogisticProvider, setShipmentSentToLogisticProvider] =
    useState(false);

  const [statusSelectColor, setStatusSelectColor] = useState();
  const [isNotificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [isUpdateReviewDialogOpen, setUpdateReviewDialogOpen] = useState(false);
  useEffect(() => {
    fetchOrder(id);
  }, [id]);

  const { mappedData: order, setter } = useObjectMapper(
    {
      condition: () =>
        !isEmpty(serverOrder) &&
        Number(id) === Number(getters.getId(serverOrder)),
      sourceData: serverOrder,
      fieldMappers: {
        [FIELDS.CREATED_BY.name]: (ordr) =>
          userGetters.getFullName(getters.getCreatedByUser(ordr)),
        [FIELDS.UPDATED_BY.name]: (ordr) =>
          userGetters.getFullName(getters.getUpdatedByUser(ordr)),
        [FIELDS.LINE_ITEMS.name]: (_, lineItems) =>
          [...lineItems].sort((a, b) => a.id - b.id),
        [FIELDS.PRESCRIPTIONS.name]: (_, prescriptions) =>
          (prescriptions || []).map((file) => ({ id: file })),
        [FIELDS.DOCUMENTS.name]: (_, documents) =>
          (documents || []).map((file) => ({ id: file })),
        [FIELDS.PRIMARY_DIAGNOSIS.name]: (_, primaryDiagnosis) =>
          isEmpty(primaryDiagnosis) ? null : primaryDiagnosis[0],
        [FIELDS.STATUS.name]: (_, status) =>
          utils.getKey(STATUSES, status, status),
        [FIELDS.CREATED_AT.name]: (_, createdAt) =>
          format(parseISO(createdAt), dateTimeFormat),
        [FIELDS.UPDATED_AT.name]: (_, updatedAt) =>
          format(parseISO(updatedAt), dateTimeFormat),
        stateDoctor: (ordr) => getters.getDoctor(ordr),
        stateInstitute: (ordr) => getters.getInstitute(ordr),
      },
    },
    [id, serverOrder]
  );
  useEffect(() => {
    // Don't fetch shipment for old orders
    if (
      order.logisticProviderId &&
      !getters.getShippingDetails(order)?.logisticProviderId
    ) {
      fetchShipment(id);
    }
  }, [id, order]);

  useEffect(() => {
    const userId = getters.getUserId(order);
    if (userId) {
      fetchUser(userId);
    }
  }, [order]);

  useEffect(() => {
    const consultationId = getters.getConsultationId(order);
    if (
      consultationId &&
      /**
       * We check if the user has access to getConsultation to prevent executing
       * getConsultation for users who don't have access.
       */
      isAllowed('orderView', 'orderDetails', 'cashOrderChip')
    ) {
      getConsultation(consultationId);
    }

    return () => {
      clearConsultation();
    };
  }, [order]);

  useEffect(() => {
    if (getters.getStatus(order)) {
      setStatusSelectColor(
        STATUSES[getters.getStatus(order)]?.color || 'error'
      );
    }

    setUpdateReviewDialogOpen(getters.isUpdatePendingReview(order));
  }, [order]);

  const findLogisticProvider = useCallback((logisticProviderId) => {
    const provider = logisticProviders.filter(
      (logistic) => logistic.id === logisticProviderId
    )?.[0];
    return provider;
  });

  useEffect(() => {
    if (order.logisticProviderId) {
      setLogisticProvider(findLogisticProvider(order.logisticProviderId));
      setShipmentSentToLogisticProvider(true);
    }
  }, [order, logisticProviders, findLogisticProvider]);

  useEffect(() => {
    if (order && !isFetchingOrder && !isFetchingConsultation) {
      setFetching(false);
    } else {
      setFetching(true);
    }
  }, [isFetchingConsultation, isFetchingOrder, order]);

  const onDeliveryAddressConfirm = (data) => {
    const deliveryAddressPayload = {
      [DELIVERY_ADDRESS_FIELDS.ORDER_ID.name]: id,
      [DELIVERY_ADDRESS_FIELDS.USER_ID.name]: userGetters.getId(
        getters.getUser(order)
      ),
      [DELIVERY_ADDRESS_FIELDS.APARTMENT.name]: data.apartment || '',
      [DELIVERY_ADDRESS_FIELDS.BUILDING.name]: data.building || '',
      [DELIVERY_ADDRESS_FIELDS.STREET.name]: data.street,
      [DELIVERY_ADDRESS_FIELDS.AREA.name]: data.area || '',
      [DELIVERY_ADDRESS_FIELDS.CITY.name]: data.city,
      [DELIVERY_ADDRESS_FIELDS.COUNTRY.name]: data.country,
      [DELIVERY_ADDRESS_FIELDS.LATITUDE.name]: data.latitude || 0,
      [DELIVERY_ADDRESS_FIELDS.LONGITUDE.name]: data.longitude || 0,
      [DELIVERY_ADDRESS_FIELDS.PLACE_ID.name]: data.placeId || '',
      [DELIVERY_ADDRESS_FIELDS.FORMATTED_ADDRESS.name]: data.formattedAddress,
    };

    if (getters.getDeliveryAddressId(serverOrder)) {
      deliveryAddressPayload[DELIVERY_ADDRESS_FIELDS.ID.name] =
        getters.getDeliveryAddressId(serverOrder);

      updateDeliveryAddress(
        getters.getDeliveryAddressId(serverOrder),
        id,
        deliveryAddressPayload
      );
    } else {
      addDeliveryAddress(id, deliveryAddressPayload);
    }
  };

  const printShipmentLabel = async (orderId) => {
    fetchShipmentLabel(orderId, (label) => {
      downloadPdfFile(label);
    });
  };

  const patch = () => {
    return {
      status: (newValue) => {
        // let isValid;
        const errs = { ...errors };
        const status = getters.getStatus(order);
        // const channel = getters.getChannel(order);
        // TEMP: remove this rule
        // if (
        //   isEmpty(getters.getDeliveryAddress(order)) &&
        //   DELIVERY_ADDRESS_REQUIRED_STATUSES.includes(newValue) &&
        //   !DELIVERY_ADDRESS_OPTIONAL_CHANNELS.includes(channel)
        // ) {
        //   isValid = false;
        //   errs.status = `Delivery address is required before setting status to ${STATUSES[newValue]?.label}`;
        // } else {
        const isValid = true;
        delete errs.status;
        // }

        setErrors({ ...errs });

        if (isValid) {
          const payload = {
            [FIELDS.STATUS.name]: STATUSES[newValue].label,
          };
          isUpdating.current = {
            ...isUpdating.current,
            status: true,
          };

          setter(FIELDS.STATUS.name, newValue);
          updateOrder(
            id,
            payload,
            () => {
              Message.success('Status updated successfully');

              isUpdating.current = {
                ...isUpdating.current,
                status: false,
              };
            },
            () => {
              Message.error(
                'Status failed to update. Please refresh the page and try again or report it to the team'
              );

              setter(FIELDS.STATUS.name, status);
              isUpdating.current = {
                ...isUpdating.current,
                status: false,
              };
            }
          );
        }
      },
      prescriptions: (
        files,
        isActualChange,
        completeFiles,
        filesWithServerId
      ) => {
        setter(FIELDS.PRESCRIPTIONS.name, files, {
          keepReference: true,
        });

        if (isActualChange) {
          const payload = {
            [FIELDS.PRESCRIPTIONS.name]: filesWithServerId.map((file) =>
              fileGetters.getId(file)
            ),
          };
          isUpdating.current = {
            ...isUpdating.current,
            prescriptions: true,
          };

          updateOrder(
            id,
            payload,
            () => {
              Message.success(
                `Prescription${
                  completeFiles.length > 1 ? 's' : ''
                } updated successfully`
              );

              isUpdating.current = {
                ...isUpdating.current,
                prescriptions: false,
              };
            },
            () => {
              Message.error(
                'Prescriptions failed to update. Please refresh the page and try again or report it to the team'
              );

              isUpdating.current = {
                ...isUpdating.current,
                prescriptions: false,
              };
            }
          );
        }
      },
      uploadDocuments: (
        files,
        isActualChange,
        completeFiles,
        filesWithServerId
      ) => {
        setter(FIELDS.DOCUMENTS.name, files, {
          keepReference: true,
        });

        if (isActualChange) {
          const payload = {
            [FIELDS.DOCUMENTS.name]: filesWithServerId.map((file) =>
              fileGetters.getId(file)
            ),
          };
          isUpdating.current = {
            ...isUpdating.current,
            documents: true,
          };

          updateOrder(
            id,
            payload,
            () => {
              Message.success(
                `Document${
                  completeFiles.length > 1 ? 's' : ''
                } updated successfully`
              );

              isUpdating.current = {
                ...isUpdating.current,
                documents: false,
              };
            },
            () => {
              Message.error(
                'Documents failed to update. Please refresh the page and try again or report it to the team'
              );

              isUpdating.current = {
                ...isUpdating.current,
                documents: false,
              };
            }
          );
        }
      },
      driver: (newValue) => {
        const payload = {
          [FIELDS.SHIPPING_DETAILS.name]: {
            driverId: newValue,
          },
        };
        isUpdating.current = {
          ...isUpdating.current,
          driver: true,
        };

        setter(FIELDS.SHIPPING_DETAILS.name, {
          driverId: newValue,
        });

        updateOrder(
          id,
          payload,
          () => {
            Message.success('Driver updated successfully');
            isUpdating.current = {
              ...isUpdating.current,
              driver: false,
            };
          },
          () => {
            Message.error(
              'Driver failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              driver: false,
            };
          }
        );
      },
      primaryDiagnosis: (newValue) => {
        const primaryDiagnosis = getters.getPrimaryDiagnosis(order);
        const payload = {
          [FIELDS.PRIMARY_DIAGNOSIS.name]: isEmpty(newValue) ? [] : [newValue],
        };
        isUpdating.current = {
          ...isUpdating.current,
          primaryDiagnosis: true,
        };

        setter(FIELDS.PRIMARY_DIAGNOSIS.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Primary diagnosis updated successfully');

            isUpdating.current = {
              ...isUpdating.current,
              primaryDiagnosis: false,
            };
          },
          () => {
            setter(FIELDS.PRIMARY_DIAGNOSIS.name, primaryDiagnosis);
            Message.error(
              'Primary diagnosis failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              primaryDiagnosis: false,
            };
          }
        );
      },
      secondaryDiagnosis: (newValue) => {
        const secondaryDiagnosis = getters.getSecondaryDiagnosis(order);
        const payload = {
          [FIELDS.SECONDARY_DIAGNOSIS.name]: !isEmpty(newValue) ? newValue : [],
        };
        isUpdating.current = {
          ...isUpdating.current,
          secondaryDiagnosis: true,
        };

        setter(FIELDS.SECONDARY_DIAGNOSIS.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Secondary diagnosis updated successfully');

            isUpdating.current = {
              ...isUpdating.current,
              secondaryDiagnosis: false,
            };
          },
          () => {
            setter(FIELDS.SECONDARY_DIAGNOSIS.name, secondaryDiagnosis);
            Message.error(
              'Secondary diagnosis failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              secondaryDiagnosis: false,
            };
          }
        );
      },
      branch: (newValue) => {
        const tenantKey = getters.getTenantKey(order);
        const payload = {
          [FIELDS.TENANT_KEY.name]: newValue,
        };
        isUpdating.current = {
          ...isUpdating.current,
          tenantKey: true,
        };

        setter(FIELDS.TENANT_KEY.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Branch updated successfully');

            isUpdating.current = {
              ...isUpdating.current,
              tenantKey: false,
            };
          },
          () => {
            setter(FIELDS.TENANT_KEY.name, tenantKey);
            Message.error(
              'Branch failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              tenantKey: false,
            };
          }
        );
      },

      institute: (newValue) => {
        const institute = order.stateInstitute;

        if (institute === newValue) {
          return;
        }

        const payload = {
          [FIELDS.INSTITUTE.name]: newValue,
        };
        isUpdating.current = {
          ...isUpdating.current,
          institute: true,
        };

        setter(FIELDS.INSTITUTE.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Institute updated successfully');
            setter('stateInstitute', newValue);

            isUpdating.current = {
              ...isUpdating.current,
              institute: false,
            };
          },
          () => {
            setter(FIELDS.INSTITUTE.name, institute);
            Message.error(
              'Institute failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              institute: false,
            };
          }
        );
      },
      doctor: (newValue) => {
        const doctor = order.stateDoctor;

        if (doctor === newValue) {
          return;
        }

        const payload = {
          [FIELDS.DOCTOR.name]: newValue,
        };
        isUpdating.current = {
          ...isUpdating.current,
          doctor: true,
        };

        setter(FIELDS.DOCTOR.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Doctor updated successfully');
            setter('stateDoctor', newValue);

            isUpdating.current = {
              ...isUpdating.current,
              doctor: false,
            };
          },
          () => {
            setter(FIELDS.DOCTOR.name, doctor);
            Message.error(
              'Doctor failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              doctor: false,
            };
          }
        );
      },
      renewalReminder: (newValue) => {
        const renewalReminder = getters.getReminderDate(serverOrder);
        const payload = {
          [FIELDS.REMINDER_DATE.name]: formatISO(newValue).split('T')[0],
        };
        isUpdating.current = {
          ...isUpdating.current,
          renewalReminder: true,
        };

        setter(FIELDS.REMINDER_DATE.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Renewal reminder updated successfully');

            isUpdating.current = {
              ...isUpdating.current,
              renewalReminder: false,
            };
          },
          () => {
            setter(FIELDS.REMINDER_DATE.name, renewalReminder);
            Message.error(
              'Renewal reminder failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              renewalReminder: false,
            };
          }
        );
      },
      pin: (isPinned) => {
        const wasPinned = getters.isPinned(serverOrder);
        const payload = {
          [FIELDS.IS_PINNED.name]: isPinned,
        };
        isUpdating.current = {
          ...isUpdating.current,
          pin: true,
        };

        setter(FIELDS.IS_PINNED.name, isPinned);
        updateOrder(
          id,
          payload,
          () => {
            if (isPinned) {
              Message.success('Order has been pinned successfully');
            } else {
              Message.info('Order has been unpinned successfully');
            }

            isUpdating.current = {
              ...isUpdating.current,
              pin: false,
            };
          },
          () => {
            setter(FIELDS.IS_PINNED.name, wasPinned);

            if (isPinned) {
              Message.error(
                'Failed to unpin the order. Please refresh the page and try again or report it to the team'
              );
            } else {
              Message.error(
                'Failed to pin the order. Please refresh the page and try again or report it to the team'
              );
            }

            isUpdating.current = {
              ...isUpdating.current,
              pin: false,
            };
          }
        );
      },
      updateReviewStatus: (isUpdatePendingReview) => {
        const wasUpdatePendingReview =
          getters.isUpdatePendingReview(serverOrder);
        const payload = {
          [FIELDS.IS_UPDATE_PENDING_REVIEW.name]: isUpdatePendingReview,
        };
        isUpdating.current = {
          ...isUpdating.current,
          updateReviewStatus: true,
        };

        setter(FIELDS.IS_UPDATE_PENDING_REVIEW.name, isUpdatePendingReview);
        updateOrder(
          id,
          payload,
          () => {
            isUpdating.current = {
              ...isUpdating.current,
              updateReviewStatus: false,
            };
            setUpdateReviewDialogOpen(false);
          },
          () => {
            setter(
              FIELDS.IS_UPDATE_PENDING_REVIEW.name,
              wasUpdatePendingReview
            );

            Message.error(
              'Failed to confirm the pending update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              updateReviewStatus: false,
            };
            setUpdateReviewDialogOpen(false);
          }
        );
      },
      showHidePrescriptions: () => {
        const isPrescriptionShown = getters.isPrescriptionShownToPatient(order);
        const payload = {
          [FIELDS.IS_PRESCRIPTION_SHOWN_TO_PATIENT.name]: !isPrescriptionShown,
        };
        isUpdating.current = {
          ...isUpdating.current,
          isUpdateShowHide: true,
        };

        updateOrder(
          id,
          payload,
          () => {
            const message = isPrescriptionShown
              ? 'Prescription is now hidden from the patient.'
              : 'Prescription is now shown to the patient.';
            Message.success(message);
            isUpdating.current = {
              ...isUpdating.current,
              isUpdateShowHide: false,
            };
            setter(
              FIELDS.IS_PRESCRIPTION_SHOWN_TO_PATIENT.name,
              !isPrescriptionShown
            );
          },
          () => {
            setter(
              FIELDS.IS_PRESCRIPTION_SHOWN_TO_PATIENT.name,
              isPrescriptionShown
            );
            Message.error(
              'Failed to update the prescription visibility. Please refresh the page and try again or report it to the team'
            );
            isUpdating.current = {
              ...isUpdating.current,
              isUpdateShowHide: false,
            };
          }
        );
      },
      assignee: (newValue) => {
        const assigneeId = getters.getAssigneeId(order);
        const payload = {
          [FIELDS.ASSIGNEE_ID.name]: newValue,
        };
        isUpdating.current = {
          ...isUpdating.current,
          assigneeId: true,
        };

        setter(FIELDS.ASSIGNEE_ID.name, newValue);
        updateOrder(
          id,
          payload,
          () => {
            Message.success('Order Assignee updated successfully');

            isUpdating.current = {
              ...isUpdating.current,
              assigneeId: false,
            };
          },
          () => {
            setter(FIELDS.ASSIGNEE_ID.name, assigneeId);
            Message.error(
              'Order assignee failed to update. Please refresh the page and try again or report it to the team'
            );

            isUpdating.current = {
              ...isUpdating.current,
              assigneeId: false,
            };
          }
        );
      },
    };
  };

  const onUpdateReviewClicked = () => {
    patch().updateReviewStatus(false);
  };
  const onShowHidePrescriptionsClicked = () => {
    patch().showHidePrescriptions();
  };

  const onQuickStatusUpdate = (statusKey) => {
    patch().status(statusKey);
  };

  return (
    <>
      <PageTitle
        title={
          <>
            Order #{id}
            {isAllowed('orderView', 'orderDetails', 'pinOrder') && (
              <IconButton
                fontSize="large"
                aria-label="Pin/unpin order"
                disabled={isFetching || isUpdating?.current?.pin}
                onClick={() => {
                  if (getters.isPinned(order)) {
                    patch().pin(false);
                  } else {
                    patch().pin(true);
                  }
                }}
                sx={{
                  p: 0,
                  mt: -1,
                  mx: 1,
                }}
              >
                <Conditionally>
                  <If condition={getters.isPinned(order)}>
                    <Tooltip title="Unpin Order" placement="top">
                      <StarIcon
                        color={isFetching ? 'standard' : 'warning'}
                        fontSize="large"
                      />
                    </Tooltip>
                  </If>
                  <Else>
                    <Tooltip title="Pin Order" placement="top">
                      <StarBorderIcon
                        color={isFetching ? 'standard' : 'warning'}
                        fontSize="large"
                      />
                    </Tooltip>
                  </Else>
                </Conditionally>
              </IconButton>
            )}
          </>
        }
      />
      <Grid container rowSpacing={3} column>
        <Grid item sx={{ width: '100%' }}>
          <RowColLayout followerMinHeight={528}>
            <Card key="order-details">
              <CardHeader
                title="Order Details"
                sx={{ pb: 0 }}
                action={
                  <Grid>
                    {utils.isNewStatus(getters.getStatus(order)) && (
                      <>
                        {isAllowed(
                          'orderView',
                          'orderDetails',
                          'refuseButton'
                        ) && (
                          <IconButton
                            aria-label="refuse"
                            onClick={() =>
                              onQuickStatusUpdate(
                                STATUSES.REFUSED_BY_PHARMACY.key
                              )
                            }
                          >
                            <Tooltip title="Refuse" placement="top">
                              <CloseIcon color="error" />
                            </Tooltip>
                          </IconButton>
                        )}
                        {isAllowed(
                          'orderView',
                          'orderDetails',
                          'pharmacyReviewButton'
                        ) && (
                          <IconButton
                            aria-label="pharmacy-review"
                            onClick={() =>
                              onQuickStatusUpdate(STATUSES.PHARMACY_REVIEW.key)
                            }
                          >
                            <Tooltip title="Pharmacy Review" placement="top">
                              <DoneIcon color="success" />
                            </Tooltip>
                          </IconButton>
                        )}
                      </>
                    )}
                  </Grid>
                }
              />
              <CardContent sx={{ height: '100%' }}>
                <Grid container column rowSpacing={2}>
                  <Conditionally>
                    <If condition={isAllowed('orderView', 'status', 'edit')}>
                      <FormField
                        label="STATUS"
                        labelWidth={60}
                        loading={isFetching}
                        updating={isUpdating?.current?.status}
                        field={
                          <Select
                            fullWidth
                            size="large"
                            soak="light"
                            variant="filled"
                            loading={isFetching}
                            color={statusSelectColor}
                            helperText={errors.status}
                            placeholder="Update status.."
                            error={Boolean(errors.status)}
                            value={getters.getStatus(order)}
                            onChange={(event) => {
                              patch().status(event.target.value);
                            }}
                            renderValue={(value) => {
                              return STATUSES[value]?.label || value;
                            }}
                          >
                            {values(STATUSES).map((option) => (
                              <MenuItem value={option.key} key={option.key}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        }
                      />
                    </If>
                    <Else>
                      <DisplayField
                        label="STATUS"
                        value={getters.getStatus(order)}
                        labelWidth={60}
                        valueWidth={170}
                        loading={isFetching}
                      />
                    </Else>
                  </Conditionally>
                  {isAllowed('orderView', 'assignee', 'view') && (
                    <Conditionally>
                      <If
                        condition={isAllowed('orderView', 'assignee', 'edit')}
                      >
                        <FormField
                          label="ASSIGN A PHARMACIST"
                          labelWidth={60}
                          loading={isFetching}
                          updating={isUpdating?.current?.assigneeId}
                          field={
                            <Select
                              fullWidth
                              size="large"
                              soak="light"
                              variant="filled"
                              loading={isFetching}
                              placeholder="Select a pharmacist..."
                              value={getters.getAssigneeId(order)}
                              onChange={(event) => {
                                patch().assignee(event.target.value);
                              }}
                              renderValue={(value) => {
                                const assignee = pharmacists.filter(
                                  (pharmacist) => pharmacist.id === value
                                );
                                return assignee?.[0]?.fullName ?? '';
                              }}
                            >
                              {pharmacists?.map((item) => (
                                <MenuItem value={item.id} key={item.fullName}>
                                  {item.fullName}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        />
                      </If>
                      <Else>
                        <DisplayField
                          label="ASSIGNED PHARMACIST"
                          value={
                            pharmacists.filter(
                              (pharmacist) =>
                                pharmacist.id === getters.getAssigneeId(order)
                            )?.[0]?.fullName ?? 'N/A'
                          }
                          labelWidth={190}
                          valueWidth={170}
                          loading={isFetching}
                        />
                      </Else>
                    </Conditionally>
                  )}
                  {isAllowed('orderView', 'prescriptions', 'view') && (
                    <Grid item container column rowSpacing={1}>
                      <Grid item>
                        <Typography
                          variant="p4"
                          loading={isFetching && { width: 130 }}
                        >
                          PRESCRIPTIONS
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FileUpload
                          multiple
                          loading={isFetching}
                          files={getters.getPrescriptions(order)}
                          userId={userGetters.getId(getters.getUser(order))}
                          onChange={(
                            files,
                            isActualChange,
                            completeFiles,
                            filesWithServerId
                          ) => {
                            patch().prescriptions(
                              files,
                              isActualChange,
                              completeFiles,
                              filesWithServerId
                            );
                          }}
                          config={{
                            readonly: !isAllowed(
                              'orderView',
                              'prescriptions',
                              'edit'
                            ),
                            deletable: isAllowed(
                              'orderView',
                              'prescriptions',
                              'delete'
                            ),
                          }}
                        />
                        {isUpdating?.current?.prescriptions && (
                          <LinearProgress />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {/* Source channel */}
                  <DisplayField
                    label="CHANNEL"
                    value={
                      CHANNELS?.[getters.getChannel(order)]?.label ||
                      getters.getChannel(order) ||
                      'N/A'
                    }
                    labelWidth={80}
                    valueWidth={200}
                    loading={isFetching}
                  />
                  {/* Original Consultation # */}
                  <DisplayField
                    label="ORIGINAL CONSULTATION"
                    value={
                      getters.getConsultationId(order) ? (
                        <Link
                          href={`/consultation/${getters.getConsultationId(
                            order
                          )}`}
                        >
                          {`Consultation #${getters.getConsultationId(order)}`}
                        </Link>
                      ) : (
                        'N/A'
                      )
                    }
                    labelWidth={210}
                    valueWidth={170}
                    loading={isFetching}
                  />
                  {isAllowed('orderView', 'reminder', 'view') && (
                    <Conditionally>
                      <If
                        condition={isAllowed('orderView', 'reminder', 'edit')}
                      >
                        <FormField
                          label="PRESCRIPTION RENEWAL REMINDER"
                          help="Setup a reminder date for prescription renewal/refill"
                          labelWidth={80}
                          loading={isFetching}
                          updating={isUpdating?.current?.renewalReminder}
                          field={
                            <DatePicker
                              inputFormat={dateFormat}
                              value={getters.getReminderDate(order)}
                              renderInput={(props) => (
                                <TextField
                                  fullWidth
                                  disabled
                                  size="large"
                                  variant="filled"
                                  name="renewalReminder"
                                  loading={isFetching}
                                  {...props}
                                />
                              )}
                              onChange={(newValue) => {
                                patch().renewalReminder(newValue);
                              }}
                              components={{
                                PaperContent: PredefinedRenewalReminders,
                              }}
                              componentsProps={{
                                paperContent: {
                                  onChange: (newValue) => {
                                    patch().renewalReminder(newValue);

                                    setTimeout(() => {
                                      document
                                        .querySelector(
                                          '.MuiCalendarPicker-root .Mui-selected'
                                        )
                                        .click();
                                    }, 0);
                                  },
                                },
                              }}
                            />
                          }
                        />
                      </If>
                      <Else>
                        <DisplayField
                          label="PRESCRIPTION RENEWAL REMINDER"
                          value={(() => {
                            const date = getters.getReminderDate(order);
                            const timestamp = (() => {
                              if (date instanceof Date) {
                                return date;
                              }
                              return date ? parseISO(date) : date;
                            })();

                            return timestamp
                              ? format(timestamp, dateFormat)
                              : 'N/A';
                          })()}
                          labelWidth={290}
                          valueWidth={170}
                          loading={isFetching}
                        />
                      </Else>
                    </Conditionally>
                  )}
                  {/* Upload documents */}
                  <Conditionally>
                    <If condition={isAllowed('orderView', 'uploadDocuments')}>
                      <Grid item container column rowSpacing={1}>
                        <Grid item>
                          <DisplayField
                            labelWidth={100}
                            valueWidth={200}
                            label="UPLOAD FILES"
                            loading={isFetching}
                          />
                        </Grid>
                        <Grid item>
                          <FileUpload
                            multiple
                            loading={isFetching}
                            files={getters.getDocuments(order)}
                            userId={userGetters.getId(getters.getUser(order))}
                            onChange={(
                              files,
                              completeFiles,
                              isActualChange,
                              filesWithServerId
                            ) => {
                              patch().uploadDocuments(
                                files,
                                completeFiles,
                                isActualChange,
                                filesWithServerId
                              );
                            }}
                            config={{
                              readonly: !isAllowed(
                                'orderView',
                                'uploadDocuments',
                                'edit'
                              ),
                              deletable: isAllowed(
                                'orderView',
                                'uploadDocuments',
                                'delete'
                              ),
                            }}
                          />
                          {isUpdating?.current?.documents && <LinearProgress />}
                        </Grid>
                      </Grid>
                    </If>
                  </Conditionally>
                  <DisplayField
                    label="Order Created"
                    value={
                      <UpdatedOrCreatedBy
                        isFetching={isFetching}
                        user={getters.getCreatedBy(order)}
                        time={getters.getCreatedAt(order)}
                      />
                    }
                    labelWidth={130}
                    valueWidth={150}
                    loading={isFetching}
                  />
                  <DisplayField
                    label="Last Updated"
                    value={
                      <UpdatedOrCreatedBy
                        isFetching={isFetching}
                        user={getters.getUpdatedBy(order)}
                        time={getters.getUpdatedAt(order)}
                      />
                    }
                    labelWidth={120}
                    valueWidth={150}
                    loading={isFetching}
                  />
                </Grid>
              </CardContent>
              {(isAllowed('orderView', 'orderDetails', 'printOrderSticker') ||
                isAllowed('orderView', 'orderDetails', 'followUpReminder') ||
                (isAllowed('orderView', 'prescriptions', 'showPrescriptions') &&
                  !getters.isPrescriptionShownToPatient(order))) && (
                <CardActions sx={{ pb: 0 }}>
                  <Grid container column>
                    {isAllowed(
                      'orderView',
                      'orderDetails',
                      'printOrderSticker'
                    ) && (
                      <Grid item container sx={{ py: 1 }}>
                        <Tooltip
                          placement="top"
                          injectWrapper
                          title="Print the bag sticker that involves the order summary"
                        >
                          <DownloadPdfButton
                            size="medium"
                            variant="text"
                            color="primary"
                            resource="order"
                            fileName={`Order#${id}_Sticker`}
                            loading={isFetching && { width: 190 }}
                            payload={{
                              orderId: id,
                              payment: {
                                value:
                                  getters.getTotalPatientShare(order)?.value ||
                                  0,
                                currency:
                                  getters.getTotalPatientShare(order)?.currency,
                              },
                              name: userGetters.getFullName(
                                getters.getUser(order)
                              ),
                              phoneNumber: userGetters.getPhoneNumber(
                                getters.getUser(order)
                              ),
                              formattedAddress:
                                userAddressGetters.getFormattedAddress(
                                  getters.getDeliveryAddress(order)
                                ),
                            }}
                          >
                            Print bag sticker
                          </DownloadPdfButton>
                        </Tooltip>
                      </Grid>
                    )}
                    {isAllowed(
                      'orderView',
                      'prescriptions',
                      'showPrescriptions'
                    ) && (
                      <Grid item container sx={{ py: 1 }}>
                        <Tooltip
                          placement="top"
                          injectWrapper
                          title={
                            getters.isPrescriptionShownToPatient(order)
                              ? 'Make the prescriptions invisible to the patient'
                              : 'Make the prescriptions visible to the patient'
                          }
                        >
                          <Button
                            size="medium"
                            color="primary"
                            variant="text"
                            loading={isFetching && { width: 190 }}
                            spinning={isUpdating?.current.isUpdateShowHide}
                            disabled={isUpdating?.current.isUpdateShowHide}
                            startIcon={
                              getters.isPrescriptionShownToPatient(order) ? (
                                <VisibilityOffOutlinedIcon color="secondary" />
                              ) : (
                                <VisibilityOutlinedIcon color="secondary" />
                              )
                            }
                            onClick={onShowHidePrescriptionsClicked}
                          >
                            {getters.isPrescriptionShownToPatient(order)
                              ? 'Hide '
                              : 'Show '}
                            prescriptions
                          </Button>
                        </Tooltip>
                      </Grid>
                    )}

                    {isAllowed(
                      'orderView',
                      'orderDetails',
                      'followUpReminder'
                    ) && (
                      <Grid item container sx={{ py: 1 }}>
                        <FollowUpRemindersManagement
                          referenceId={id}
                          referenceType={REFERENCE_TYPES.ORDER}
                          tenantKey={getters.getTenantKey(order)}
                          namespaceKey={getters.getNamespaceKey(order)}
                          loading={isFetching}
                        />
                      </Grid>
                    )}
                    {isAllowed('orderView', 'orderHistory') && (
                      <Grid item container sx={{ py: 1 }}>
                        <OrderHistory id={id} loading={isFetching} />
                      </Grid>
                    )}
                  </Grid>
                </CardActions>
              )}
            </Card>
            {isAllowed('orderView', 'orderDetails') && (
              // All the fields here are strictly for admin
              <Card key="more-details">
                <CardHeader
                  title="More Details"
                  sx={{ pb: 0 }}
                  action={
                    consultationGetters.getIsPaidByUser(consultation) &&
                    isAllowed('orderView', 'orderDetails', 'cashOrderChip') && (
                      <Chip
                        variant="filled"
                        label="Cash Order"
                        loading={
                          isFetching && {
                            width: 100,
                          }
                        }
                        sx={{
                          color: (theme) => theme.palette.error.dark,
                          backgroundColor: (theme) => theme.palette.error.tint,
                        }}
                      />
                    )
                  }
                />
                <CardContent sx={{ height: '100%' }}>
                  <Grid container column rowSpacing={2}>
                    <ChronicConditionsField
                      key={userGetters.getId(getters.getUser(order))}
                      userId={userGetters.getId(getters.getUser(order))}
                      label="Does the patient have chronic conditions?"
                    />

                    {/* Branch */}
                    {isAllowed('orderView', 'branch', 'view') && (
                      <Conditionally>
                        <If
                          condition={isAllowed('orderView', 'branch', 'edit')}
                        >
                          <FormField
                            label="BRANCH"
                            labelWidth={60}
                            loading={isFetching}
                            updating={isUpdating?.current?.tenantKey}
                            field={
                              <Select
                                fullWidth
                                size="large"
                                soak="light"
                                variant="filled"
                                color="primary"
                                value={getters.getTenantKey(order)}
                                loading={isFetching}
                                onChange={(event) => {
                                  patch().branch(event.target.value);
                                }}
                              >
                                {(tenants || []).map((option) => (
                                  <MenuItem value={option.id} key={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            }
                          />
                        </If>
                        <Else>
                          <DisplayField
                            label="BRANCH"
                            value={
                              tenants.find(
                                (tenant) =>
                                  tenant.id === getters.getTenantKey(order)
                              )?.name ?? 'N/A'
                            }
                            labelWidth={60}
                            valueWidth={170}
                            loading={isFetching}
                          />
                        </Else>
                      </Conditionally>
                    )}

                    {/* Institute */}
                    <Conditionally>
                      <If
                        condition={isAllowed('orderView', 'institute', 'edit')}
                      >
                        <FormField
                          label="INSTITUTE"
                          labelWidth={90}
                          loading={isFetching}
                          updating={isUpdating?.current?.institute}
                          field={
                            <TextField
                              fullWidth
                              size="large"
                              variant="filled"
                              name="institute"
                              loading={isFetching}
                              placeholder="Institute name.."
                              value={getters.getInstitute(order)}
                              onChange={(event) => {
                                const newValue = event.target.value;

                                setter(FIELDS.INSTITUTE.name, newValue);
                              }}
                              onBlur={(event) => {
                                const newValue = event.target.value;

                                if (order.stateInstitute !== newValue) {
                                  patch().institute(newValue);
                                }
                              }}
                            />
                          }
                        />
                      </If>
                      <Else>
                        <DisplayField
                          label="INSTITUTE"
                          value={getters.getInstitute(order) || 'N/A'}
                          labelWidth={90}
                          valueWidth={170}
                          loading={isFetching}
                        />
                      </Else>
                    </Conditionally>

                    <Conditionally>
                      <If
                        condition={isAllowed('orderView', 'diagnosis', 'edit')}
                      >
                        <FormField
                          label="PRIMARY DIAGNOSIS"
                          labelWidth={170}
                          loading={isFetching}
                          updating={isUpdating?.current?.primaryDiagnosis}
                          field={
                            <DiagnosisAutocomplete
                              loading={isFetching}
                              defaultValue={getters.getPrimaryDiagnosis(order)}
                              onChange={(newValue) => {
                                patch().primaryDiagnosis(newValue);
                              }}
                            />
                          }
                        />
                        <FormField
                          label="SECONDARY DIAGNOSIS"
                          labelWidth={190}
                          loading={isFetching}
                          updating={isUpdating?.current?.secondaryDiagnosis}
                          field={
                            // TODO: show non-existing values beneath the field
                            <DiagnosisAutocomplete
                              multiple
                              loading={isFetching}
                              defaultValue={getters.getSecondaryDiagnosis(
                                order
                              )}
                              onChange={(newValue) => {
                                patch().secondaryDiagnosis(newValue);
                              }}
                            />
                          }
                        />
                      </If>
                      <Else>
                        <>
                          <DisplayField
                            label="PRIMARY DIAGNOSIS"
                            value={
                              getters.getPrimaryDiagnosis(order)?.cod || 'N/A'
                            }
                            labelWidth={170}
                            valueWidth={60}
                            loading={isFetching}
                          />
                          <DisplayField
                            label="SECONDARY DIAGNOSIS"
                            value={
                              (getters.getSecondaryDiagnosis(order) || [])
                                .map((item) => item.cod)
                                .join(', ') || 'N/A'
                            }
                            labelWidth={190}
                            valueWidth={40}
                            loading={isFetching}
                          />
                        </>
                      </Else>
                    </Conditionally>

                    {/* Doctor */}
                    {isAllowed('orderView', 'doctor', 'view') && (
                      <Conditionally>
                        <If
                          condition={isAllowed('orderView', 'doctor', 'edit')}
                        >
                          <FormField
                            label="DOCTOR"
                            labelWidth={60}
                            loading={isFetching}
                            updating={isUpdating?.current?.doctor}
                            field={
                              <TextField
                                fullWidth
                                size="large"
                                name="doctor"
                                variant="filled"
                                loading={isFetching}
                                placeholder="Doctor name.."
                                value={getters.getDoctor(order)}
                                onChange={(event) => {
                                  const newValue = event.target.value;

                                  setter(FIELDS.DOCTOR.name, newValue);
                                }}
                                onBlur={(event) => {
                                  const newValue = event.target.value;

                                  if (order.stateDoctor !== newValue) {
                                    patch().doctor(newValue);
                                  }
                                }}
                              />
                            }
                          />
                        </If>
                        <Else>
                          <DisplayField
                            label="DOCTOR"
                            value={getters.getDoctor(order) || 'N/A'}
                            labelWidth={60}
                            valueWidth={170}
                            loading={isFetching}
                          />
                        </Else>
                      </Conditionally>
                    )}

                    {/* User notes */}
                    <DisplayField
                      label="USER NOTES"
                      value={
                        isEmpty(getters.getUserNotes(order))
                          ? 'No notes provided by patient'
                          : getters.getUserNotes(order)
                      }
                      labelWidth={100}
                      valueWidth={200}
                      loading={isFetching}
                    />
                    {/* Failed delivery attempt reason */}
                    <Conditionally>
                      <If
                        condition={getters.getFailedDeliveryAttemptReason(
                          order
                        )}
                      >
                        <DisplayField
                          label="FAILED DELIVERY ATTEMPT REASON"
                          value={(() => {
                            const reason =
                              getters.getFailedDeliveryAttemptReason(order);

                            return (
                              DELIVERY_FAILURE_REASONS[reason]?.label || reason
                            );
                          })()}
                          labelWidth={100}
                          valueWidth={200}
                          loading={isFetching}
                        />
                      </If>
                    </Conditionally>
                    <Grid item>
                      {!isFetching && <Divider />}
                      <Typography variant="h6" loading={isFetching} mt={2}>
                        Logistics and delivery details
                      </Typography>
                    </Grid>
                    {/*  Delivery Address */}
                    {/*
                      1. If none is selected -> Add Delivery Address
                      2. If one is selected, show the address, with (change delivery address text button)
                    */}
                    <FormField
                      label="DELIVERY ADDRESS"
                      labelWidth={160}
                      loading={isFetching}
                      field={
                        <UserAddressField
                          ref={deliveryAddressRef}
                          userId={userGetters.getId(getters.getUser(order))}
                          value={getters.getDeliveryAddress(order)}
                          // To avoid unnecessary auto-close, wait until all fetching is done
                          loading={isFetching || isSubmitting}
                          onEditOrNew={() => {
                            deliveryAddressRef.current.open();
                          }}
                          onChange={(newValue) => {
                            onDeliveryAddressConfirm(newValue);

                            onDeliveryAddressSubmitted(() => {
                              setter(FIELDS.DELIVERY_ADDRESS.name, newValue);

                              if (deliveryAddressRef?.current) {
                                deliveryAddressRef.current.close();
                              }
                            });
                          }}
                          amendSharedMessage={(message, channel) => {
                            switch (channel) {
                              case LOCATION_SHARE_CHANNELS.WHATSAPP: {
                                return `Order #${id}\n Full address: ${message}\n`;
                              }
                              default:
                                return message;
                            }
                          }}
                          permissions={{
                            add: isAllowed(
                              'orderView',
                              'deliveryAddress',
                              'add'
                            ),
                            edit: isAllowed(
                              'orderView',
                              'deliveryAddress',
                              'edit'
                            ),
                            share: isAllowed(
                              'orderView',
                              'deliveryAddress',
                              'share'
                            ),
                          }}
                        />
                      }
                    />
                    <Conditionally>
                      <If
                        condition={
                          isDeliveryConsentFormEnabled() &&
                          getters.getDeliveryAddress(order) &&
                          isAllowed(
                            'orderView',
                            'deliveryAddress',
                            'printDeliveryConsentForm'
                          )
                        }
                      >
                        <Grid item container sx={{ py: 1 }}>
                          <Tooltip
                            placement="top"
                            injectWrapper
                            title="Print the delivery consent report"
                          >
                            <DownloadPdfButton
                              size="medium"
                              variant="text"
                              color="primary"
                              resource="order-delivery-consent"
                              fileName={`Order#${id}_Delivery_Consent`}
                              loading={isFetching && { width: 190 }}
                              payload={{
                                orderId: id,
                                formattedAddress:
                                  userAddressGetters.getFormattedAddress(
                                    getters.getDeliveryAddress(order)
                                  ),
                                phoneNumber: userGetters.getPhoneNumber(
                                  getters.getUser(order)
                                ),
                                orderPatients: getters.getOrderPatients(order),
                                tenant: tenants.find(
                                  (tenant) =>
                                    tenant.id === getters.getTenantKey(order)
                                ),
                              }}
                            >
                              Print delivery consent form
                            </DownloadPdfButton>
                          </Tooltip>
                        </Grid>
                      </If>
                    </Conditionally>
                    <Conditionally>
                      <If
                        condition={
                          isShowLogisticProvider() &&
                          isAllowed('orderView', 'driver', 'view')
                        }
                      >
                        <FormField
                          label="LOGISTIC PROVIDER"
                          labelWidth={60}
                          loading={isFetching}
                          updating={isUpdating?.current?.logisticProvider}
                          field={
                            <>
                              <Select
                                fullWidth
                                size="large"
                                soak="light"
                                variant="filled"
                                loading={isFetching}
                                disabled={isShipmentSentToLogisticProvider}
                                value={
                                  isShipmentSentToLogisticProvider
                                    ? logisticProvider?.id
                                    : null
                                }
                                onChange={(event) => {
                                  setSelectedLogisticProviderId(
                                    event.target.value
                                  );
                                  setLogisticProviderDialogOpen(true);
                                }}
                              >
                                {(logisticProviders || []).map((item) => (
                                  <MenuItem value={item.id} key={item.id}>
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {isShipmentSentToLogisticProvider && (
                                <Button
                                  startIcon={<InfoOutlined />}
                                  variant="text"
                                  loading={isFetching}
                                  color="primary"
                                  size="medium"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setLogisticProviderDialogOpen(true);
                                  }}
                                >
                                  <Typography variant="l5">
                                    Show shipment details
                                  </Typography>
                                </Button>
                              )}
                            </>
                          }
                        />
                      </If>
                    </Conditionally>
                    <Conditionally>
                      <If condition={isAllowed('orderView', 'driver', 'view')}>
                        <FormField
                          label="DRIVER"
                          labelWidth={60}
                          loading={isFetching}
                          updating={isUpdating?.current?.driver}
                          field={
                            <Select
                              fullWidth
                              size="large"
                              soak="light"
                              variant="filled"
                              loading={isFetching}
                              placeholder="Select a driver..."
                              value={getters.getDriverId(order)}
                              onChange={(event) => {
                                patch().driver(event.target.value);
                              }}
                              renderValue={(value) => {
                                const driver = drivers.filter(
                                  (drv) => drv.id === value
                                );

                                return driver?.[0]?.fullName ?? '';
                              }}
                            >
                              {drivers?.map((item) => (
                                <MenuItem value={item.id} key={item.fullName}>
                                  {item.fullName}
                                </MenuItem>
                              ))}
                            </Select>
                          }
                        />
                      </If>
                    </Conditionally>
                    <Conditionally>
                      <If
                        condition={
                          order.logisticProviderId &&
                          isAllowed('orderView', 'driver', 'view')
                        }
                      >
                        <FormField
                          label="SHIPMENT LABEL"
                          labelWidth={60}
                          loading={isFetching}
                          field={
                            <Grid item>
                              <Button
                                startIcon={<FireTruckOutlined />}
                                variant="text"
                                loading={isFetching}
                                color="primary"
                                size="medium"
                                spinning={isFetchingTaskId}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  printShipmentLabel(
                                    id ?? shipment?.referenceId
                                  );
                                }}
                              >
                                Print shipment label
                              </Button>
                            </Grid>
                          }
                        />
                      </If>
                    </Conditionally>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <Card key="patient-info">
              <CardContent noPadding sx={{ height: '100%' }}>
                <PatientInfo
                  userId={userGetters.getId(getters.getUser(order))}
                  config={{
                    fields: {
                      addresses: {
                        show: false,
                      },
                    },
                  }}
                />
              </CardContent>
              {isAllowed('orderView', 'sendNotification') && (
                // If new other actions are not strict to admin,
                // check if isAllowed differently
                <CardActions sx={{ p: 0 }}>
                  <Grid container column>
                    <Grid item container sx={{ py: 1, px: 2 }}>
                      <Tooltip
                        placement="top"
                        injectWrapper
                        title="Send a new sms or mobile push notification to the patient"
                      >
                        <Button
                          size="medium"
                          color="primary"
                          variant="text"
                          loading={isFetching && { width: 150 }}
                          startIcon={<MessageIcon color="secondary" />}
                          onClick={() => setNotificationDialogOpen(true)}
                        >
                          Send a notification
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </CardActions>
              )}
            </Card>
            {isAllowed('orderView', 'medicines') && (
              <LineItemList
                fullRow
                rowIdx={1}
                orderId={id}
                isFetching={isFetching}
                list={getters.getLineItems(order)}
              />
            )}
            {isAllowed('orderView', 'totals') && (
              <Card key="order-totals">
                <CardHeader title="Totals" sx={{ pb: 0 }} />
                <CardContent>
                  <Grid container column rowSpacing={2}>
                    {/* Price */}
                    <DisplayField
                      label="PRICE"
                      value={moneyToString(getters.getTotalPrice(order))}
                      labelWidth={50}
                      valueWidth={70}
                      loading={isFetching || isUpdatingLineItems}
                    />
                    {/* Discount */}
                    <DisplayField
                      label="INSURANCE DISCOUNT"
                      value={moneyToString(getters.getTotalDiscount(order))}
                      labelWidth={180}
                      valueWidth={60}
                      loading={isFetching || isUpdatingLineItems}
                    />
                    {/* Price After Insurance Discount */}
                    <DisplayField
                      label="PRICE AFTER INSURANCE DISCOUNT"
                      value={moneyToString({
                        value:
                          (getters.getTotalPrice(order)?.value || 0) -
                          (getters.getTotalDiscount(order)?.value || 0),
                        currency: getters.getTotalPrice(order)?.currency,
                      })}
                      labelWidth={300}
                      valueWidth={60}
                      loading={isFetching || isUpdatingLineItems}
                    />
                    {/* Patient Discount */}
                    <DisplayField
                      label="PATIENT DISCOUNT"
                      value={moneyToString(
                        getters.getTotalPatientDiscount(order)
                      )}
                      labelWidth={160}
                      valueWidth={60}
                      loading={isFetching || isUpdatingLineItems}
                    />
                    {/* Patient Share */}
                    <DisplayField
                      label="PATIENT SHARE"
                      value={moneyToString({
                        value: getters.getTotalPatientShare(order)?.value || 0,
                        currency: getters.getTotalPatientShare(order)?.currency,
                      })}
                      labelWidth={130}
                      valueWidth={60}
                      loading={isFetching || isUpdatingLineItems}
                    />
                    {/* Payer Share */}
                    <DisplayField
                      label="PAYER SHARE"
                      value={moneyToString(getters.getTotalPayerShare(order))}
                      labelWidth={110}
                      valueWidth={60}
                      loading={isFetching || isUpdatingLineItems}
                    />
                  </Grid>
                </CardContent>
              </Card>
            )}
            {isAllowed('orderView', 'comments') && (
              <Comments
                key="comments"
                heightFollower
                referenceId={id}
                referenceType={REFERENCE_TYPES.ORDER}
                permissions={{
                  add: isAllowed('orderView', 'comments', 'add'),
                }}
              />
            )}
            {isAllowed('orderView', 'payment', 'view') && (
              <OrderPaymentSection
                key="payment"
                user={getters.getUser(order)}
                order={order}
                loading={isFetching}
                onSubmit={() => fetchOrder(id)}
              />
            )}
          </RowColLayout>
        </Grid>
        {/** Patient History Section */}
        {isAllowed('patientHistory') && (
          <Grid item xs={12}>
            <PatientHistorySection
              referenceId={id}
              userId={userGetters.getId(getters.getUser(order))}
              referenceType={patientHistoryConstants.ACTIVITY_TYPES.ORDER.key}
            />
          </Grid>
        )}
      </Grid>
      {isAllowed('orderView', 'sendNotification') && (
        <SendNotification
          referenceId={id}
          open={isNotificationDialogOpen}
          userId={userGetters.getId(getters.getUser(order))}
          referenceType={REFERENCE_TYPES.ORDER}
          onClose={() => setNotificationDialogOpen(false)}
        />
      )}
      <LogisticProviderDialog
        orderId={id}
        fullName={userGetters.getFullName(getters.getUser(order))}
        phoneNumber={userGetters.getPhoneNumber(getters.getUser(order))}
        address={getters.getDeliveryAddress(order)}
        isOpen={isLogisticProviderDialogOpen}
        setOpen={setLogisticProviderDialogOpen}
        shippingDetails={getters.getShippingDetails(order)}
        orderTotalPrice={getters.getTotalPrice(order)}
        orderPatientShare={getters.getTotalPatientShare(order)}
        shipment={shipment}
        paymentTransaction={getters.getPaymentTransaction(order)}
        isShipmentSentToLogisticProvider={isShipmentSentToLogisticProvider}
        setShipmentSentToLogisticProvider={setShipmentSentToLogisticProvider}
        logisticProvider={
          findLogisticProvider(selectedLogisticProviderId) || logisticProvider
        }
        setLogisticProvider={setLogisticProvider}
      />
      <Dialog
        disableEscapeKeyDown
        open={isUpdateReviewDialogOpen}
        aria-labelledby="update-review-dialog"
        aria-describedby="update-review-dialog"
        onClose={() => {}}
      >
        <DialogTitle id="update-review-dialog">
          Prescription has been changed
        </DialogTitle>
        <DialogContent>
          Please note that the prescription has been changed by the doctor,
          please review the updated version
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            spinning={isUpdating?.current?.updateReviewStatus}
            onClick={() => {
              onUpdateReviewClicked();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderView;
