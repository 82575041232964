import { connect } from 'react-redux';

import { doctorNotesActions } from 'domain/doctorNotes';

import ComplaintForm from './ComplaintForm';

const mapDispatchToProps = (dispatch) => ({
  submitComplaintForm: (userId, consultationId, payload, successCallback) =>
    dispatch(
      doctorNotesActions.submitDoctorNotes(
        userId,
        consultationId,
        payload,
        successCallback
      )
    ),
});

export default connect(null, mapDispatchToProps)(ComplaintForm);
