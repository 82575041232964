import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { anonymousUserGetters as getters } from 'domain/anonymousUser';
import {
  PageTitle,
  Grid,
  Card,
  CardHeader,
  CardContent,
  DisplayField,
} from 'design-system';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';

const AnonymousUserView = ({
  anonymousUser,
  fetchAnonymousUser,
  isFetching,
}) => {
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;

  useEffect(() => {
    fetchAnonymousUser(id);
  }, [id]);

  return (
    <>
      <PageTitle title={`Anonymous App User #${id}`} />
      <Grid container rowSpacing={3} column>
        <Grid item sx={{ width: '50%' }}>
          <Card key="anonymous-user-details">
            <CardHeader title="Anonymous User Details" sx={{ pb: 0 }} />
            <CardContent sx={{ height: '100%' }}>
              <Grid container column rowSpacing={2}>
                {/* Device Id */}
                <DisplayField
                  label="DEVICE ID"
                  value={getters.getDeviceId(anonymousUser)}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* First Open */}
                <DisplayField
                  label="FIRST OPEN"
                  value={
                    <UpdatedOrCreatedBy
                      isFetching={isFetching}
                      time={getters.getCreatedAt(anonymousUser)}
                    />
                  }
                  labelWidth={130}
                  valueWidth={150}
                  loading={isFetching}
                />
                {/* Last Active */}
                <DisplayField
                  label="LAST ACTIVE"
                  value={
                    <UpdatedOrCreatedBy
                      isFetching={isFetching}
                      time={getters.getLastActive(anonymousUser)}
                    />
                  }
                  labelWidth={130}
                  valueWidth={150}
                  loading={isFetching}
                />

                {/* City */}
                <DisplayField
                  label="CITY"
                  value={getters.getCity(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Country */}
                <DisplayField
                  label="COUNTRY"
                  value={getters.getCountry(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Detected City */}
                <DisplayField
                  label="DETECTED CITY"
                  value={getters.getDetectedCity(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Detected Country */}
                <DisplayField
                  label="DETECTED COUNTRY"
                  value={getters.getDetectedCountry(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Detected Region */}
                <DisplayField
                  label="DETECTED REGION"
                  value={getters.getDetectedRegion(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Mobile App Version */}
                <DisplayField
                  label="MOBILE APP VERSION"
                  value={getters.getMobileAppVersion(anonymousUser)}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Platform */}
                <DisplayField
                  label="PLATFORM"
                  value={getters.getPlatform(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Platform Version */}
                <DisplayField
                  label="PLATFORM VERSION"
                  value={getters.getPlatformVersion(anonymousUser) || 'N/A'}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Latitude */}
                <DisplayField
                  label="LATITUDE"
                  value={getters.getLatitude(anonymousUser)}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* Longtitude */}
                <DisplayField
                  label="LONGTITUDE"
                  value={getters.getLongtitude(anonymousUser)}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
                {/* IP Address */}
                <DisplayField
                  label="IP ADDRESS"
                  value={getters.getIpAddress(anonymousUser)}
                  labelWidth={80}
                  valueWidth={200}
                  loading={isFetching}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default AnonymousUserView;
