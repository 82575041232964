import React, { useState, useEffect } from 'react';

import {
  Grid,
  Button,
  Switch,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

const SendNotification = ({
  open,
  userId,
  onClose,
  referenceId,
  referenceType,
  sendNotification,
}) => {
  const SMS_LABEL = 'Send SMS notification';
  const PUSH_LABEL = 'Send push notification';

  const [message, setMessage] = useState();
  const [isOpen, setOpen] = useState(false);
  const [switchLabel, setSwitchLabel] = useState(PUSH_LABEL);
  const [isPushNotification, setPushNotification] = useState(true);

  const resetFields = () => {
    setMessage('');
    setPushNotification(true);
    setSwitchLabel(PUSH_LABEL);
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    onClose();
    resetFields();
  };

  const onSendButtonClicked = () => {
    sendNotification(
      message,
      isPushNotification,
      userId,
      referenceId,
      referenceType
    );
    handleClose();
  };

  const onSwitchChanged = (event) => {
    const { checked } = event.target;

    setPushNotification(checked);

    if (checked) {
      setSwitchLabel(PUSH_LABEL);
    } else {
      setSwitchLabel(SMS_LABEL);
    }
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="send-notification-dialog-title"
    >
      <DialogTitle id="send-notification-dialog-title" onClose={handleClose}>
        Send a Notification
      </DialogTitle>
      <DialogContent dividers>
        <Grid container column>
          <Grid item sx={{ mb: 1 }}>
            <Typography variant="p4">MESSAGE</Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size="large"
              value={message}
              variant="filled"
              placeholder="Type the message here"
              onChange={(event) => {
                setMessage(event.target.value);
              }}
            />
          </Grid>
          <Grid item sx={{ mt: 1 }}>
            <Switch
              label={switchLabel}
              onChange={onSwitchChanged}
              checked={isPushNotification}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          disabled={!message}
          onClick={onSendButtonClicked}
        >
          Send Notification
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendNotification;
