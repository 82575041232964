import { all, take, put, takeLatest } from 'redux-saga/effects';

import { cacheUtils } from 'core/cache';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';
import {
  insuranceProviderModelActionTypes,
  insuranceProviderModelActions,
} from 'model/insuranceProvider';

import * as actions from './actions';
import * as types from './actionTypes';

export function* fetchInsuranceProvidersList() {
  const cachedInsuranceProviders = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.INSURANCE_PROVIDERS
  );

  if (cachedInsuranceProviders) {
    yield put(actions.insuranceProviderListReceived(cachedInsuranceProviders));
  } else {
    yield put(insuranceProviderModelActions.fetchInsuranceProviderList());

    const { data } = yield take(
      insuranceProviderModelActionTypes.INSURANCE_PROVIDER_LIST_RECEIVED
    );

    cacheUtils.setCachedDomainObject(
      LOCAL_DOMAIN_CACHE_KEYS.INSURANCE_PROVIDERS,
      data
    );
    yield put(actions.insuranceProviderListReceived(data));
  }
}

export default function* insuranceProviderSaga() {
  yield all([
    takeLatest(
      types.FETCH_INSURANCE_PROVIDER_LIST,
      fetchInsuranceProvidersList
    ),
  ]);
}
