import React, { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Grid,
  Card,
  Select,
  MenuItem,
  TextField,
  CardHeader,
  IconButton,
  CardContent,
  Autocomplete,
  InputAdornment,
} from 'design-system';

import { NONE, VALUE } from '../filtration/constants';
import SwitchFilter from './components/SwitchFilter';
import DatePickerFilter from './components/DatePickerFilter';
import DateTimePickerFilter from './components/DateTimePickerFilter';

const DataGridCard = ({
  header: { title, action, variant = 'h5' } = {},
  filters,
  children,
  sx,
}) => {
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(false);

  return (
    <>
      {filters?.fields?.length && (
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={filters?.title || 'Filter'}
            variant={filters?.variant || 'h5'}
            action={
              <IconButton
                sx={{ display: { xs: 'block', lg: 'none' } }}
                onClick={() => setFiltersCollapsed(!isFiltersCollapsed)}
              >
                {isFiltersCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            }
          />
          <CardContent
            noPadding
            sx={{
              display: {
                xs: isFiltersCollapsed ? 'none' : 'block',
                lg: 'block',
              },
            }}
          >
            <Grid
              container
              px={1}
              pb={3}
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 12, md: 20 }}
            >
              {filters.fields
                .filter((field) => !field?.hide)
                .map((field, idx) => (
                  <Grid
                    item
                    xs={2}
                    sm={4}
                    md={4}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${field?.name ?? ''}-${idx}`}
                  >
                    {(() => {
                      // TODO: Optimize this
                      switch (field.type) {
                        case 'custom':
                          return field.component;
                        case 'autocomplete':
                          return (
                            <Autocomplete
                              size="medium"
                              forcePopupIcon={false}
                              {...field}
                              options={[
                                ...(field.withNone ? [NONE] : []), // Filter : field value is null
                                ...(field.withValue ? [VALUE] : []), // Filter: field value is not null
                                ...(field.options ? field.options : []),
                              ]}
                              onChange={(e, value) => field?.filter(value)}
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    shape="round"
                                    variant="filled"
                                    placeholder={field?.placeholder}
                                    {...(field.icon && {
                                      InputProps: {
                                        ...params.InputProps,
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <field.icon
                                              color="standard"
                                              fontSize="extraSmall"
                                            />
                                          </InputAdornment>
                                        ),
                                      },
                                    })}
                                  />
                                );
                              }}
                            />
                          );
                        case 'select':
                          return (
                            <Select
                              fullWidth
                              soak="light"
                              size="medium"
                              shape="round"
                              variant="filled"
                              onChange={field.filter}
                              placeholder={field.placeholder}
                              {...(field.icon && {
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <field.icon
                                        color="standard"
                                        fontSize="extraSmall"
                                      />
                                    </InputAdornment>
                                  ),
                                },
                              })}
                            >
                              {(field.options || []).map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                              ))}
                            </Select>
                          );
                        case 'date':
                          return <DatePickerFilter field={field} />;
                        case 'datetime':
                          return <DateTimePickerFilter field={field} />;
                        case 'switch':
                          return <SwitchFilter field={field} />;
                        case 'textInput':
                          return (
                            <TextField
                              size="medium"
                              shape="round"
                              variant="filled"
                              fullWidth
                              type={field.inputType}
                              field={field}
                              placeholder={field?.placeholder}
                              onChange={field.onInputChange}
                              {...(field.icon && {
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <field.icon
                                        color="standard"
                                        fontSize="extraSmall"
                                      />
                                    </InputAdornment>
                                  ),
                                },
                              })}
                            />
                          );
                        default:
                          return (
                            <TextField
                              fullWidth
                              size="medium"
                              shape="round"
                              variant="filled"
                              placeholder={field?.placeholder}
                              {...(field.icon && {
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <field.icon
                                        color="standard"
                                        fontSize="extraSmall"
                                      />
                                    </InputAdornment>
                                  ),
                                },
                              })}
                            />
                          );
                      }
                    })()}
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
      )}
      <Card sx={sx}>
        {(title || action) && (
          <CardHeader title={title} action={action} variant={variant} />
        )}
        <CardContent
          noPadding
          sx={{
            '&:last-child': {
              pb: 0,
            },
          }}
        >
          {children}
        </CardContent>
      </Card>
    </>
  );
};

export default DataGridCard;
