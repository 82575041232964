import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box } from 'design-system';

const Link = ({ to, children, onClick = {}, target = '_blank' }) => {
  const theme = useTheme();

  return (
    <Box
      component="span"
      sx={{
        '& > a': {
          color: theme.palette.primary.main,
          textDecoration: 'none',
          '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
          },
        },
      }}
    >
      <ReactRouterLink to={to} onClick={onClick} target={target}>
        {children}
      </ReactRouterLink>
    </Box>
  );
};

export default Link;
