import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as SCHEDULE } from './constants';

// Actions
export const FETCH_DOCTOR_SCHEDULES = `${DOMAIN}/${SCHEDULE}/FETCH_DOCTOR_SCHEDULES`;
export const ADD_AVAILABILITY_RANGE = `${DOMAIN}/${SCHEDULE}/ADD_AVAILABILITY_RANGE`;
export const REMOVE_AVAILABILITY_RANGE = `${DOMAIN}/${SCHEDULE}/REMOVE_AVAILABILITY_RANGE`;
export const CLEAR_BOOKING_AVAILIABLITY = `${DOMAIN}/${SCHEDULE}/CLEAR_BOOKING_AVAILIABLITY`;
export const FETCH_DAILY_BOOKING_AVAILABILITY = `${DOMAIN}/${SCHEDULE}/FETCH_DAILY_BOOKING_AVAILABILITY`;
export const FETCH_DOCTOR_BOOKING_AVAILABILITY = `${DOMAIN}/${SCHEDULE}/FETCH_DOCTOR_BOOKING_AVAILABILITY`;
export const FETCH_DOCTOR_FREE_SCHEDULE_SLOTS = `${DOMAIN}/${SCHEDULE}/FETCH_DOCTOR_FREE_SCHEDULE_SLOTS`;
export const REMOVE_FREE_SLOT_RANGE = `${DOMAIN}/${SCHEDULE}/REMOVE_FREE_SLOT_RANGE`;

// Responses
export const DOCTOR_SCHEDULES_RECEIVED = `${DOMAIN}/${SCHEDULE}/DOCTOR_SCHEDULES_RECEIVED`;
export const DAILY_BOOKING_AVAILABILITY_RECEIVED = `${DOMAIN}/${SCHEDULE}/DAILY_BOOKING_AVAILABILITY_RECEIVED`;
export const DOCTOR_BOOKING_AVAILABILITY_RECEIVED = `${DOMAIN}/${SCHEDULE}/DOCTOR_BOOKING_AVAILABILITY_RECEIVED`;
export const DOCTOR_FREE_SCHEDULES_SLOTS_RECEIVED = `${DOMAIN}/${SCHEDULE}/DOCTOR_FREE_SCHEDULES_SLOTS_RECEIVED`;

// Statuses
export const SET_FETCHING = `${DOMAIN}/${SCHEDULE}/SET_FETCHING`;
export const SET_ADDING_RANGE = `${DOMAIN}/${SCHEDULE}/SET_ADDING_RANGE`;
export const SET_REMOVING_RANGE = `${DOMAIN}/${SCHEDULE}/SET_REMOVING_RANGE`;
export const SET_FETCHING_DAILY_BOOKING_AVAILABILITY = `${DOMAIN}/${SCHEDULE}/SET_FETCHING_DAILY_BOOKING_AVAILABILITY`;
export const SET_FETCHING_DOCTOR_FREE_SCHEDULE_SLOTS = `${DOMAIN}/${SCHEDULE}/SET_FETCHING_DOCTOR_FREE_SCHEDULE_SLOTS`;
