import React from 'react';
import { Tabs as MuiTabs } from '@mui/material';

const Tabs = ({ value, children, handleChange, tabVariant, ...rest }) => {
  return (
    <MuiTabs
      value={value}
      onChange={handleChange}
      tabVariant={tabVariant}
      {...rest}
    >
      {children}
    </MuiTabs>
  );
};

export default Tabs;
