import get from 'lodash/get';

import { FIELDS } from './constants';

export const getId = (comment) => get(comment, FIELDS.ID.name);

export const getContent = (comment) => get(comment, FIELDS.CONTENT.name);

export const getCreatedBy = (comment) => get(comment, FIELDS.CREATED_BY.name);

export const getCreatedByUser = (comment) =>
  get(comment, FIELDS.CREATED_BY_USER.name);

export const getCreatedAt = (comment) => get(comment, FIELDS.CREATED_AT.name);

export const getReferenceType = (comment) =>
  get(comment, FIELDS.REFERENCE_TYPE.name);

export const getReferenceId = (comment) =>
  get(comment, FIELDS.REFERENCE_ID.name);
