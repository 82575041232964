import keys from 'lodash/keys';
import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import { useParams } from 'react-router-dom';
import React, { useRef, useEffect, useCallback } from 'react';

import { CountrySelect } from 'core/country';
import FileUpload from 'core/file/FileUpload';
import { clinicGetters } from 'domain/clinic';
import { Form, Controller } from 'design-system/form';
import PatientInfo from 'presentation/components/PatientInfo';
import { DOCTOR_LICENSE_TYPES } from 'domain/doctor/constants';
import { dateTimeFormat, dateFormat } from 'constants/dateTime';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';
import MeetingsSection from 'presentation/doctorManagement/meetingManagement/MeetingsSection';
import {
  doctorGetters as getters,
  DOCTOR_FIELDS as FIELDS,
} from 'domain/doctor';
import {
  Grid,
  Card,
  Button,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  FormField,
  PageTitle,
  DatePicker,
  CardHeader,
  Typography,
  CardContent,
  FormControlLabel,
} from 'design-system';

const maxNumberOfCharsAllowed = 50;
const maxNumberOfCharsMessage = `Text exceeds maximum length of ${maxNumberOfCharsAllowed} characters`;

const DoctorProfileView = ({
  doctor,
  clinics,
  isFetching,
  clearDoctor,
  fetchDoctor,
  updateDoctor,
  isSubmitting,
}) => {
  const formRef = useRef(null);
  const params = useParams();
  const id = params?.id ? Number(params.id) : undefined;

  useEffect(() => {
    fetchDoctor(id);

    return () => {
      clearDoctor();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(doctor) && formRef?.current) {
      formRef.current
        .getMethods()
        .setValue('defaultClinic', getters.getDefaultClinic(doctor));
    }
  }, [doctor, formRef?.current]);

  const isProcessingFiles = useCallback(() => {
    if (!formRef.current) {
      return false;
    }

    const { watch } = formRef.current.getMethods();

    return (
      (watch('licenseFile') && !watch('licenseFileComplete')) ||
      (watch('signatureFile') && !watch('signatureFileComplete')) ||
      (watch('stampFile') && !watch('stampFileComplete')) ||
      (watch('profilePictureFile') && !watch('profilePictureFileComplete'))
    );
  }, [formRef?.current]);

  const onSubmit = useCallback(
    (data) => {
      const payload = {
        [FIELDS.ACTIVE.name]: data.active,
        [FIELDS.CALL_ENABLED.name]: data[FIELDS.CALL_ENABLED.name],
        [FIELDS.ARABIC_NAME.name]: data.arabicName,
        [FIELDS.ENGLISH_NAME.name]: data.englishName,
        [FIELDS.LICENSE_TYPE.name]: data.licenseType,
        [FIELDS.LICENSE_NUMBER.name]: data.licenseNumber,
        [FIELDS.SLACK_MEMBER_ID.name]: data.slackMemberId,
        [FIELDS.YEARS_OF_EXPERIENCE.name]: data.yearsOfExperience,
        [FIELDS.PROFILE_PICTURE_FILE.name]: data.profilePictureFile?.id || null,
        [FIELDS.STAMP_FILE.name]: data.stampFile?.id || null,
        [FIELDS.LICENSE_FILE.name]: data.licenseFile?.id || null,
        [FIELDS.NATIONALITY.name]: data.nationality?.code || null,
        [FIELDS.LICENSE_TITLE_ARABIC.name]: data.licenseTitleArabic,
        [FIELDS.LICENSE_TITLE_ENGLISH.name]: data.licenseTitleEnglish,
        [FIELDS.EDUCATION_TITLE_ARABIC.name]: data.educationTitleArabic,
        [FIELDS.EDUCATION_TITLE_ENGLISH.name]: data.educationTitleEnglish,
        [FIELDS.SIGNATURE_FILE.name]: data.signatureFile?.id || null,
        [FIELDS.LICENSE_EXPIRY_DATE.name]: data.licenseExpiryDate
          ? formatISO(new Date(data.licenseExpiryDate)).split('T')[0]
          : null,
        [FIELDS.CLINICS.name]: data.clinics || [],
        [FIELDS.DEFAULT_CLINIC.name]: data.defaultClinic || null,
      };

      updateDoctor(id, payload);
    },
    [id, updateDoctor]
  );

  const onClinicsChange = useCallback(
    (event, onChange) => {
      const selectedClinicIds = event.target.value;
      const { watch, setValue } = formRef.current.getMethods();

      if (isEmpty(selectedClinicIds)) {
        // If the selection is clear, auto clear the default clinic
        setValue('defaultClinic', null);
      } else if (selectedClinicIds.length === 1) {
        // If there's one selection, auto select the default clinic
        setValue('defaultClinic', selectedClinicIds[0]);
      } else if (!selectedClinicIds.includes(watch('defaultClinic'))) {
        // If the default clinic has been removed from the selected list,
        // auto select the first selection as default clinic
        setValue('defaultClinic', selectedClinicIds[0]);
      }

      onChange(event);
    },
    [formRef?.current]
  );

  const renderClinicValue = useCallback(
    (selectedClinicIds) => {
      if (isEmpty(selectedClinicIds) || !formRef?.current) {
        return '';
      }

      const { watch } = formRef.current.getMethods();

      return selectedClinicIds
        .map((clinicId) => {
          const name = clinicGetters.getName(
            // Get the clinic object out of clinicId
            clinics.find((clinic) => clinicGetters.getId(clinic) === clinicId)
          );

          // If defautlClinic matches clinicId, add (Default)
          return `${name}${
            watch('defaultClinic') === clinicId ? ' (Default)' : ''
          }`;
        })
        .join(', ');
    },
    [clinics, formRef?.current]
  );

  const ClinicMenuItem = useCallback(
    ({ clinic }) => {
      const { watch, setValue } = formRef.current.getMethods();

      return (
        <Grid container justifyContent="space-between">
          <Grid item>{clinicGetters.getName(clinic)}</Grid>
          <Grid item>
            {watch('clinics').includes(clinicGetters.getId(clinic)) && (
              // If this clinic option is part of the selection
              <>
                {watch('defaultClinic') === clinicGetters.getId(clinic) && (
                  // If the selected clinic is marked as default clinic
                  <Typography variant="l5" color="secondary">
                    Selected default
                  </Typography>
                )}
                {watch('defaultClinic') !== clinicGetters.getId(clinic) && (
                  // If the selected clinic is not marked as default clinic
                  <Button
                    size="small"
                    variant="text"
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue('defaultClinic', clinicGetters.getId(clinic));
                    }}
                    sx={{
                      p: 0,
                    }}
                  >
                    Set as default
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      );
    },
    [formRef?.current]
  );

  return (
    <>
      <PageTitle title={`Doctor #${id}`} />
      <Grid container columnSpacing={3}>
        <Grid item xs={6}>
          <Card key="doctor-details">
            <CardHeader title="Doctor Details" />
            <CardContent>
              <Grid container column rowSpacing={2}>
                <Grid item>
                  {!isFetching && !isEmpty(doctor) && (
                    <Form ref={formRef} onSubmit={onSubmit}>
                      {({ control, setValue }) => (
                        <Grid container column rowSpacing={1}>
                          <Grid item>
                            <Controller
                              name="active"
                              control={control}
                              defaultValue={getters.isActive(doctor)}
                              render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={value}
                                      onChange={onChange}
                                    />
                                  }
                                  label={value ? 'Active' : 'Inactive'}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <Controller
                              name={FIELDS.CALL_ENABLED.name}
                              control={control}
                              defaultValue={getters.isCallEnabled(doctor)}
                              render={({ field: { onChange, value } }) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={value}
                                      onChange={onChange}
                                    />
                                  }
                                  label={
                                    value
                                      ? 'Phone calls enabled'
                                      : 'Phone calls disabled'
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="English Name"
                              field={
                                <Controller
                                  name="englishName"
                                  control={control}
                                  defaultValue={getters.getEnglishName(doctor)}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="Arabic Name"
                              field={
                                <Controller
                                  name="arabicName"
                                  control={control}
                                  defaultValue={getters.getArabicName(doctor)}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="License Number"
                              field={
                                <Controller
                                  name="licenseNumber"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getLicenseNumber(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="License Title (En)"
                              field={
                                <Controller
                                  name="licenseTitleEnglish"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getLicenseTitleEnglish(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="License Title (Ar)"
                              field={
                                <Controller
                                  name="licenseTitleArabic"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getLicenseTitleArabic(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>

                          <Grid item>
                            <FormField
                              label="Education (En)"
                              field={
                                <Controller
                                  name="educationTitleEnglish"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getEducationTitleEnglish(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>

                          <Grid item>
                            <FormField
                              label="Education (Ar)"
                              field={
                                <Controller
                                  name="educationTitleArabic"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getEducationTitleArabic(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>

                          <Grid item>
                            <FormField
                              label="Slack Member Id"
                              field={
                                <Controller
                                  name="slackMemberId"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getSlackMemberId(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="License Expiry Date"
                              field={
                                <Controller
                                  name="licenseExpiryDate"
                                  control={control}
                                  defaultValue={getters.getLicenseExpiryDate(
                                    doctor
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                      name="licenseExpiryDate"
                                      autoComplete="false"
                                      value={value}
                                      onChange={onChange}
                                      inputFormat={dateFormat}
                                      renderInput={(props) => (
                                        <TextField
                                          fullWidth
                                          size="medium"
                                          value={value}
                                          variant="outlined"
                                          onChange={onChange}
                                          {...props}
                                        />
                                      )}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="License Type"
                              field={
                                <Controller
                                  name="licenseType"
                                  control={control}
                                  defaultValue={getters.getLicenseType(doctor)}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      fullWidth
                                      soak="light"
                                      size="medium"
                                      value={value}
                                      onChange={onChange}
                                      renderValue={(val) =>
                                        DOCTOR_LICENSE_TYPES[val]?.label || val
                                      }
                                    >
                                      {keys(DOCTOR_LICENSE_TYPES).map(
                                        (type) => (
                                          <MenuItem key={type} value={type}>
                                            {DOCTOR_LICENSE_TYPES[type].label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  )}
                                />
                              }
                            />
                          </Grid>

                          <Grid item>
                            <FormField
                              label="Years Of Experience"
                              field={
                                <Controller
                                  name="yearsOfExperience"
                                  control={control}
                                  rules={{
                                    maxLength: {
                                      value: maxNumberOfCharsAllowed,
                                      message: maxNumberOfCharsMessage,
                                    },
                                  }}
                                  defaultValue={getters.getYearsOfExperience(
                                    doctor
                                  )}
                                  render={({
                                    field: { onChange, value },
                                    fieldState: { error },
                                  }) => (
                                    <TextField
                                      fullWidth
                                      type="number"
                                      value={value}
                                      variant="outlined"
                                      error={Boolean(error)}
                                      helperText={error?.message ?? null}
                                      onChange={onChange}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>

                          <Grid item>
                            <FormField
                              label="Nationality"
                              field={
                                <Controller
                                  name="nationality"
                                  control={control}
                                  defaultValue={getters.getNationalityObject(
                                    doctor
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <CountrySelect
                                      fullWidth
                                      size="medium"
                                      value={value}
                                      variant="outlined"
                                      onChange={(_e, newValue) => {
                                        onChange(newValue);
                                      }}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="Clinics"
                              field={
                                <Controller
                                  name="clinics"
                                  control={control}
                                  defaultValue={getters.getClinics(doctor)}
                                  render={({ field: { onChange, value } }) => (
                                    <Select
                                      fullWidth
                                      multiple
                                      size="medium"
                                      soak="light"
                                      variant="filled"
                                      value={value}
                                      onChange={(event) => {
                                        onClinicsChange(event, onChange);
                                      }}
                                      placeholder="Select doctor clinics..."
                                      renderValue={renderClinicValue}
                                    >
                                      {clinics.map((clinic) => (
                                        <MenuItem
                                          value={clinicGetters.getId(clinic)}
                                          key={clinicGetters.getKey(clinic)}
                                        >
                                          <ClinicMenuItem clinic={clinic} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="License File"
                              field={
                                <Controller
                                  name="licenseFile"
                                  control={control}
                                  defaultValue={getters.getFileObject(
                                    getters.getLicenseFile(doctor)
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <FileUpload
                                      files={value ? [value] : []}
                                      userId={getters.getUserId(doctor)}
                                      onChange={(
                                        files,
                                        _1,
                                        _2,
                                        completeFiles
                                      ) => {
                                        onChange(files?.[0] || null);
                                        setValue(
                                          'licenseFileComplete',
                                          completeFiles?.[0] || null
                                        );
                                      }}
                                      config={{
                                        deletable: true,
                                      }}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="Signature File"
                              field={
                                <Controller
                                  name="signatureFile"
                                  control={control}
                                  defaultValue={getters.getFileObject(
                                    getters.getSignatureFile(doctor)
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <FileUpload
                                      files={value ? [value] : []}
                                      userId={getters.getUserId(doctor)}
                                      accept={{
                                        'image/*': ['.jpeg', '.png', '.jpg'],
                                      }}
                                      placeholder="Drop your image file here"
                                      onChange={(
                                        files,
                                        _1,
                                        _2,
                                        completeFiles
                                      ) => {
                                        onChange(files?.[0] || null);
                                        setValue(
                                          'signatureFileComplete',
                                          completeFiles?.[0] || null
                                        );
                                      }}
                                      config={{
                                        deletable: true,
                                      }}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="Stamp File"
                              field={
                                <Controller
                                  name="stampFile"
                                  control={control}
                                  defaultValue={getters.getFileObject(
                                    getters.getStampFile(doctor)
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <FileUpload
                                      files={value ? [value] : []}
                                      userId={getters.getUserId(doctor)}
                                      accept={{
                                        'image/*': ['.jpeg', '.png', '.jpg'],
                                      }}
                                      placeholder="Drop your image file here"
                                      onChange={(
                                        files,
                                        _1,
                                        _2,
                                        completeFiles
                                      ) => {
                                        onChange(files?.[0] || null);
                                        setValue(
                                          'stampFileComplete',
                                          completeFiles?.[0] || null
                                        );
                                      }}
                                      config={{
                                        deletable: true,
                                      }}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>
                          <Grid item>
                            <FormField
                              label="Profile Picture"
                              field={
                                <Controller
                                  name="profilePictureFile"
                                  control={control}
                                  defaultValue={getters.getFileObject(
                                    getters.getProfilePictureFile(doctor)
                                  )}
                                  render={({ field: { onChange, value } }) => (
                                    <FileUpload
                                      files={value ? [value] : []}
                                      userId={getters.getUserId(doctor)}
                                      isPublic
                                      accept={{
                                        'image/*': ['.jpeg', '.png', '.jpg'],
                                      }}
                                      placeholder="Drop your image file here"
                                      onChange={(
                                        files,
                                        _1,
                                        _2,
                                        completeFiles
                                      ) => {
                                        onChange(files?.[0] || null);
                                        setValue(
                                          'profilePictureFileComplete',
                                          completeFiles?.[0] || null
                                        );
                                      }}
                                      config={{
                                        deletable: true,
                                      }}
                                    />
                                  )}
                                />
                              }
                            />
                          </Grid>

                          <Grid item>
                            <Typography variant="p4">First Created</Typography>
                          </Grid>
                          <UpdatedOrCreatedBy
                            user={getters.getCreatedBy(doctor)}
                            time={format(
                              parseISO(getters.getCreatedAt(doctor)),
                              dateTimeFormat
                            )}
                          />
                          <Grid item>
                            <Typography variant="p4">Last Updated</Typography>
                          </Grid>
                          <UpdatedOrCreatedBy
                            user={getters.getUpdatedBy(doctor)}
                            time={format(
                              parseISO(getters.getUpdatedAt(doctor)),
                              dateTimeFormat
                            )}
                          />
                          <Grid item>
                            <Button
                              type="submit"
                              variant="filled"
                              spinning={isSubmitting}
                              disabled={isProcessingFiles()}
                            >
                              Save Doctor Profile
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Form>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Grid container column spacing={3} sx={{ width: '100%' }}>
            <Grid item>
              <Card key="patient-info">
                <CardContent noPadding>
                  <PatientInfo
                    userId={getters.getUserId(doctor)}
                    config={{
                      fields: {
                        addresses: {
                          show: false,
                        },
                        insuranceProvider: {
                          show: false,
                        },
                        identity: {
                          show: false,
                        },
                        tags: {
                          show: false,
                        },
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <MeetingsSection
                key="meeting-management"
                doctorId={getters.getUserId(doctor)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorProfileView;
