import { connect } from 'react-redux';

import {
  medicineActions as actions,
  medicineSelectors as selectors,
} from 'domain/medicine';

import NewMedicine from './NewMedicine';

const mapStateToProps = (state) => ({
  isSubmitting: selectors.isSubmittingMedicine(state),
});

const mapDispatchToProps = (dispatch) => ({
  createMedicine: (payload) => dispatch(actions.createMedicine(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMedicine);
