import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';

import { PACKAGE_NAME as LAB } from './constants';

// Actions
export const GET_LAB_REQUEST_BY_ID = `${DOMAIN}/${LAB}/GET_LAB_REQUEST_BY_ID`;
export const CREATE_LAB_REQUEST = `${DOMAIN}/${LAB}/CREATE_CONSULTATION`;
export const CREATE_E_LAB_REQUEST_DOCUMENT = `${DOMAIN}/${LAB}/CREATE_E_LAB_REQUEST_DOCUMENT`;
export const PATCH_LAB_REQUEST = `${DOMAIN}/${LAB}/PATCH_LAB_TEST_REQUEST`;
export const UPDATE_LAB_REQUEST = `${DOMAIN}/${LAB}/UPDATE_LAB_REQUEST`;
export const FETCH_LAB_PACKAGES = `${DOMAIN}/${LAB}/FETCH_LAB_PACKAGES`;
export const GET_LAB_REQUEST_BY_CONSULTATION_ID = `${DOMAIN}/${LAB}/GET_LAB_REQUEST_BY_CONSULTATION_ID`;

// Actions: Fetch, create, update and create document of patient ELab test request
export const FETCH_PATIENT_E_LAB_REQUEST = `${DOMAIN}/${LAB}/FETCH_PATIENT_E_LAB_REQUEST`;
export const CREATE_PATIENT_E_LAB_REQUEST = `${DOMAIN}/${LAB}/CREATE_PATIENT_E_LAB_REQUEST`;
export const UPDATE_PATIENT_E_LAB_REQUEST = `${DOMAIN}/${LAB}/UPDATE_PATIENT_E_LAB_REQUEST`;
export const DELETE_PATIENT_E_LAB_REQUEST = `${DOMAIN}/${LAB}/DELETE_PATIENT_E_LAB_REQUEST`;
export const SUBMIT_PATIENT_E_LAB_REQUESTS = `${DOMAIN}/${LAB}/SUBMIT_PATIENT_E_LAB_REQUESTS`;
export const CREATE_PATIENT_LAB_REQUEST_DOCUMENT = `${DOMAIN}/${LAB}/CREATE_PATIENT_LAB_REQUEST_DOCUMENT`;
export const CLEAR_PATIENTS_E_LAB_REQUESTS = `${DOMAIN}/${LAB}/CLEAR_PATIENTS_E_LAB_REQUESTS`;

// Responses
export const LAB_PACKAGES_RECEIVED = `${DOMAIN}/${LAB}/LAB_PACKAGES_RECEIVED`;
export const LAB_REQUEST_RECEIVED = `${DOMAIN}/${LAB}/LAB_REQUEST_RECEIVED`;
export const LAB_REQUEST_UPDATED = `${DOMAIN}/${LAB}/LAB_REQUEST_UPDATED`;
export const LAB_PACKAGE_CREATED = `${DOMAIN}/${LAB}/LAB_PACKAGE_CREATED`;
export const E_LAB_REQUEST_DOCUMENT_CREATED = `${DOMAIN}/${LAB}/E_LAB_REQUEST_DOCUMENT_CREATED`;
export const CONSULTATION_LABS_RECEIVED = `${DOMAIN}/${LAB}/CONSULTATION_LABS_RECEIVED`;

// Responses: Fetch, create, update and create document of patient ELab test request
export const PATIENT_E_LAB_REQUEST_RECEIVED = `${DOMAIN}/${LAB}/PATIENT_E_LAB_REQUEST_RECEIVED`;
export const PATIENT_E_LAB_REQUEST_SUBMITTED = `${DOMAIN}/${LAB}/PATIENT_E_LAB_REQUEST_SUBMITTED`;
export const PATIENT_E_LAB_REQUEST_DELETED = `${DOMAIN}/${LAB}/PATIENT_E_LAB_REQUEST_DELETED`;
export const PATIENT_E_LAB_REQUESTS_SUBMITTED = `${DOMAIN}/${LAB}/PATIENT_E_LAB_REQUESTS_SUBMITTED`;
export const PATIENT_LAB_REQUEST_DOCUMENT_CREATED = `${DOMAIN}/${LAB}/PATIENT_LAB_REQUEST_DOCUMENT_CREATED`;

// Statuses
export const SET_FETCHING_LAB_REQUEST = `${DOMAIN}/${LAB}/SET_FETCHING_LAB_REQUEST`;
export const SET_SUBMITTING_LAB_REQUEST = `${DOMAIN}/${LAB}/SET_SUBMITTING_LAB_REQUEST`;
export const SET_FETCHING_LAB_REQUEST_OTHERS = `${DOMAIN}/${LAB}/SET_FETCHING_LAB_REQUEST_OTHERS`;
export const SET_FETCHING_DOCUMENTS = `${DOMAIN}/${LAB}/SET_FETCHING_DOCUMENTS`;

// Statuses: Submitting Patient ELab Test Requests
export const SET_SUBMITTING_PATIENT_E_LAB_REQUESTS = `${DOMAIN}/${LAB}/SET_SUBMITTING_PATIENT_E_LAB_REQUESTS`;
export const CREATE_PATIENTS_LAB_TEST_DOCUMENTS = `${DOMAIN}/${LAB}/CREATE_PATIENTS_LAB_TEST_DOCUMENTS`;
