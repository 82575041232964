import * as types from './actionTypes';

export const login = (username, password) => ({
  type: types.LOG_IN,
  username,
  password,
});

export const setErrorMessage = (errorMessage) => ({
  type: types.SET_ERROR_MESSAGE,
  errorMessage,
});

export const setToken = (token) => ({
  type: types.SET_TOKEN,
  token,
});

export const setUserId = (userId) => ({
  type: types.SET_USER_ID,
  userId,
});

export const setMainRole = (mainRole) => ({
  type: types.SET_MAIN_ROLE,
  mainRole,
});

export const setUserRoles = (userRoles) => ({
  type: types.SET_USER_ROLES,
  userRoles,
});

export const setDefaultTenant = (defaultTenant) => ({
  type: types.SET_DEFAULT_TENANT,
  defaultTenant,
});

export const setAllowedTenants = (allowedTenants) => ({
  type: types.SET_ALLOWED_TENANTS,
  allowedTenants,
});

export const setSelectedTenant = (selectedTenant) => ({
  type: types.SET_SELECTED_TENANT,
  selectedTenant,
});

export const setLoggingIn = (isLoggingIn) => ({
  type: types.SET_LOGGING_IN,
  isLoggingIn,
});

export const logInSucceeded = () => ({
  type: types.LOG_IN_SUCCEEDED,
});
