import * as types from './actionTypes';

const initialState = {
  isFetching: false,
  isSubmitting: false,
  latestDietPlan: null,
};

const dietPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LATEST_DIET_PLAN_RECEIVED: {
      return {
        ...state,
        latestDietPlan: action.latestDietPlan,
      };
    }
    case types.SET_FETCHING_DIET_PLAN: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING_DIET_PLAN: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.CLEAR_LATEST_DIET_PLAN: {
      return {
        ...state,
        latestDietPlan: null,
      };
    }
    default:
      return state;
  }
};

export default dietPlanReducer;
