import keys from 'lodash/keys';
import add from 'date-fns/add';
import sub from 'date-fns/sub';
import isEmpty from 'lodash/isEmpty';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import isSameDay from 'date-fns/isSameDay';
import React, { useState, useEffect } from 'react';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { userGetters } from 'domain/user';
import { countryUtils } from 'core/country';
import { getDefaultCountryCodeLong } from 'core/siteConfig';
import { TIME_SLOT_STATUSES } from 'domain/schedule/constants';
import { consultationUtils as utils } from 'domain/consultation';
import { dateFormatWithDay, dateTimeFormatWithDay } from 'constants/dateTime';
import {
  If,
  Tab,
  Tabs,
  Else,
  Grid,
  Card,
  Select,
  ElseIf,
  Dialog,
  Button,
  Tooltip,
  TabPanel,
  MenuItem,
  FormField,
  TextField,
  Typography,
  DatePicker,
  CardHeader,
  CardContent,
  DialogTitle,
  DialogContent,
  DialogActions,
  Conditionally,
  CircularProgress,
} from 'design-system';

const TODAY = new Date(formatISO(new Date()).split('T')[0]);

const BookingAvailabilityDialog = ({
  open,
  doctor,
  doctors,
  onClose,
  pageDate,
  onConfirm,
  isFetching,
  onDayChange,
  bookingSlots,
  isFetchingDay,
  isPage = false,
  onDoctorChange,
  dayBookingSlots,
  onPageDateChange,
  bookedSlots: booked,
  scheduleData: schedule,
  dayScheduleData: daySchedule,
}) => {
  const [tab, setTab] = useState(0);
  const [isOpen, setOpen] = useState(open);
  const [bookedSlots, setBookedSlots] = useState(booked);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [scheduleData, setScheduleData] = useState(schedule);
  const [selectedDoctor, setSelectedDoctor] = useState(doctor);
  const [dayScheduleData, setDayScheduleData] = useState(daySchedule);

  const onTabChanged = (_event, newValue) => {
    setTab(newValue);

    switch (newValue) {
      case 1: {
        const day = !isEmpty(bookedSlots)
          ? new Date(bookedSlots[0]?.startTime)
          : new Date();

        if (isEmpty(daySchedule) || !isSameDay(selectedDay, day)) {
          onDayChange(day);
        }

        setSelectedDay(day);
        break;
      }
      default:
        break;
    }
  };
  useEffect(() => {
    setSelectedDoctor(doctor);
  }, [doctor]);

  useEffect(() => {
    setScheduleData(schedule);
  }, [schedule]);

  useEffect(() => {
    setDayScheduleData(daySchedule);
  }, [daySchedule]);

  useEffect(() => {
    setBookedSlots(booked);
  }, [booked]);

  const addToBookedSlots = (slot, slotDoctor) => {
    const list = [...bookedSlots];
    const doctorSelection = [selectedDoctor, slotDoctor];
    list.pushSorted(
      {
        ...slot,
        doctor: doctorSelection[tab],
        doctorId: userGetters.getId(doctorSelection[tab]),
      },
      (s) => s.startTime,
      (item, elm) => {
        return new Date(item).getTime() > new Date(elm).getTime();
      }
    );

    setBookedSlots(list);

    if (tab === 1 && slotDoctor) {
      setSelectedDoctor(slotDoctor);
      onDoctorChange(slotDoctor);
    }
  };

  const removeFromBookedSlots = (slot) => {
    const list = [...bookedSlots];
    const idx = list.findIndex((s) => s.startTime === slot.startTime);

    list.splice(idx, 1);

    setBookedSlots(list);

    if (tab === 1 && isEmpty(list)) {
      setSelectedDoctor();
    }
  };

  const isSlotBooked = (slot) => {
    return Boolean(
      slot &&
        (!bookedSlots?.[0] ||
          Number(slot.doctorId) === Number(bookedSlots[0]?.doctorId)) &&
        bookedSlots.some((s) => s.startTime === slot.startTime)
    );
  };

  const getDoctorSlotDuration = (doctorId) => {
    return doctors.find((d) => d.id === doctorId)?.slotDuration;
  };

  const isSlotBookable = (slot) => {
    if (!slot) return;

    if (!bookedSlots.length) {
      return true;
    }

    const startTime = new Date(slot.startTime);
    const rangeStartTime = new Date(bookedSlots[0].startTime);
    const rangeEndTime = new Date(bookedSlots.last().startTime);
    return (
      (!bookedSlots?.[0] ||
        Number(slot.doctorId) === Number(bookedSlots[0]?.doctorId)) &&
      (Math.abs(differenceInMinutes(startTime, rangeStartTime)) ===
        getDoctorSlotDuration(slot.doctorId) ||
        Math.abs(differenceInMinutes(rangeEndTime, startTime)) ===
          getDoctorSlotDuration(slot.doctorId))
    );
  };

  const isSlotUnbookable = (slot) => {
    if (!slot || isEmpty(bookedSlots)) return;

    const { startTime } = slot;
    const rangeStartTime = bookedSlots[0].startTime;
    const rangeEndTime = bookedSlots.last().startTime;

    return (
      (!bookedSlots?.[0] ||
        Number(slot.doctorId) === Number(bookedSlots[0]?.doctorId)) &&
      (startTime === rangeStartTime || startTime === rangeEndTime)
    );
  };

  const onTodayClicked = () => {
    onPageDateChange(TODAY);
  };

  const onPreviousWeekClicked = () => {
    const newDate = sub(pageDate, { days: 7 });

    onPageDateChange(newDate);
  };

  const onNextWeekClicked = () => {
    const newDate = add(pageDate, { days: 7 });

    onPageDateChange(newDate);
  };

  const Wrapper = isPage ? Card : Dialog;
  // eslint-disable-next-line react/no-unstable-nested-components
  const Header = ({ children, ...rest }) =>
    isPage ? (
      <CardHeader title={children} {...rest} />
    ) : (
      <DialogTitle {...rest}>{children}</DialogTitle>
    );
  const Content = isPage ? CardContent : DialogContent;

  return (
    <Wrapper
      fullScreen
      scrollable
      open={isOpen}
      aria-labelledby="doctor-schedule-dialog"
      onClose={() => {
        setOpen(false);
        onClose();
      }}
    >
      <Header
        id="doctor-schedule-dialog"
        onClose={() => {
          setOpen(false);
          onClose();
        }}
      >
        <Tabs value={tab} handleChange={onTabChanged}>
          <Tab
            value={0}
            label={
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: 0,
                  textTransform: 'none',
                }}
              >
                Doctor&apos;s Schedule
              </Typography>
            }
          />
          <Tab
            value={1}
            label={
              <Typography
                variant="h5"
                sx={{
                  paddingBottom: 0,
                  textTransform: 'none',
                }}
              >
                Doctors&apos; Daily Schedule
              </Typography>
            }
          />
        </Tabs>
        <Conditionally>
          <If condition={tab === 0}>
            <Grid container justifyContent="space-around" alignItems="center">
              <Grid item xs={4} />
              <Grid item container xs={4} justifyContent="center">
                <FormField
                  label="DOCTOR"
                  field={
                    <Select
                      fullWidth
                      size="large"
                      soak="light"
                      value={selectedDoctor}
                      variant="filled"
                      loading={isFetching}
                      placeholder="Select a doctor..."
                      onChange={(event) => {
                        const newValue = event.target.value;
                        setBookedSlots([]);
                        onDoctorChange(newValue);
                        setSelectedDoctor(newValue);
                      }}
                      renderValue={(value) =>
                        value ? userGetters.getFullName(value) : value
                      }
                    >
                      {doctors.map((item) => (
                        <MenuItem
                          value={item}
                          key={userGetters.getFullName(item)}
                        >
                          {userGetters.getFullName(item)}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  sx={{
                    my: 0,
                    width: 300,
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={4}
                spacing={1}
                alignSelf="flex-end"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    size="medium"
                    color="primary"
                    variant="text"
                    onClick={onTodayClicked}
                    disabled={isFetching || isSameDay(TODAY, pageDate)}
                  >
                    Today
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="medium"
                    variant="text"
                    color="primary"
                    onClick={onPreviousWeekClicked}
                    startIcon={<ArrowBackIosNewIcon />}
                    disabled={isFetching || isSameDay(TODAY, pageDate)}
                  >
                    Previous week
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="medium"
                    variant="text"
                    color="primary"
                    disabled={isFetching}
                    onClick={onNextWeekClicked}
                    endIcon={<ArrowForwardIosIcon />}
                  >
                    Next week
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </If>
          <ElseIf condition={tab === 1}>
            <FormField
              label="DAY"
              field={
                <DatePicker
                  name="reminderDate"
                  autoComplete="false"
                  value={selectedDay}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    setSelectedDay(newValue);

                    setBookedSlots([]);
                    onDayChange(newValue);
                  }}
                  inputFormat={dateFormatWithDay}
                  renderInput={(props) => (
                    <TextField
                      fullWidth
                      size="medium"
                      variant="filled"
                      {...props}
                    />
                  )}
                />
              }
              sx={{
                my: 0,
                width: 300,
              }}
            />
          </ElseIf>
        </Conditionally>
      </Header>
      <Content dividers>
        <TabPanel
          value={tab}
          index={0}
          id="doctor-booking-availability-tabpanel"
          sx={{
            height: '100%',
          }}
        >
          <Conditionally>
            <If condition={isFetching}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%' }}
              >
                <Grid item>
                  <CircularProgress size={150} />
                </Grid>
              </Grid>
            </If>
            <ElseIf condition={isEmpty(selectedDoctor)}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="p1">Select a doctor first</Typography>
                </Grid>
              </Grid>
            </ElseIf>
            <ElseIf condition={isEmpty(bookingSlots)}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="p1">No available time slots.</Typography>
                </Grid>
              </Grid>
            </ElseIf>
            <Else>
              <Grid
                container
                sx={{
                  borderStyle: 'solid',
                  borderColor: (theme) => theme.palette.standard.main,
                  borderRightStyle: 'none',
                }}
              >
                <>
                  <Grid
                    item
                    container
                    column
                    flex={1}
                    sx={{
                      borderRightStyle: 'solid',
                      borderColor: (theme) => theme.palette.standard.light,
                    }}
                  >
                    <Grid
                      item
                      textAlign="center"
                      sx={{
                        px: 0.7,
                        py: 0.7,
                        height: 35,
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.grey[100],
                      }}
                    >
                      <Typography variant="l4">Time</Typography>
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        textAlign="center"
                        sx={{
                          px: 0.7,
                          py: 0.7,
                          width: '50%',
                          backgroundColor: (theme) => theme.palette.grey[100],
                          whiteSpace: 'nowrap',
                          borderTopStyle: 'solid',
                          borderRightStyle: 'solid',
                          borderTopColor: (theme) =>
                            theme.palette.standard.light,
                          borderRightColor: (theme) =>
                            theme.palette.standard.light,
                        }}
                      >
                        <Typography variant="l4">Local time</Typography>
                      </Grid>
                      <Grid
                        item
                        textAlign="center"
                        sx={{
                          px: 0.7,
                          py: 0.7,
                          width: '50%',
                          backgroundColor: (theme) => theme.palette.grey[100],
                          whiteSpace: 'nowrap',
                          borderTopStyle: 'solid',
                          borderTopColor: (theme) =>
                            theme.palette.standard.light,
                        }}
                      >
                        <Typography variant="l4">
                          {getDefaultCountryCodeLong()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container column>
                      {!isEmpty(scheduleData?.timeSlots) &&
                        scheduleData.timeSlots.map((slot) => (
                          <Grid container>
                            <Grid
                              item
                              alignSelf="center"
                              sx={{
                                py: 1,
                                height: 35,
                                width: '50%',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                borderTopStyle: 'solid',
                                borderRightStyle: 'solid',
                                borderTopColor: (theme) =>
                                  theme.palette.standard.light,
                                borderRightColor: (theme) =>
                                  theme.palette.standard.light,
                              }}
                            >
                              <Typography variant="body2">
                                {slot.formatted}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              alignSelf="center"
                              sx={{
                                py: 1,
                                height: 35,
                                width: '50%',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                borderTopStyle: 'solid',
                                borderTopColor: (theme) =>
                                  theme.palette.standard.light,
                              }}
                            >
                              <Typography variant="body2">
                                {countryUtils.convertTimeToPatientTimeZone(
                                  slot
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  {!isEmpty(scheduleData?.schedule) &&
                    scheduleData.schedule.map((day) => {
                      return (
                        <Grid
                          item
                          container
                          column
                          flex={1}
                          sx={{
                            borderRightStyle: 'solid',
                            borderColor: (theme) =>
                              theme.palette.standard.light,
                          }}
                        >
                          <Grid
                            item
                            textAlign="center"
                            sx={{
                              px: 2,
                              py: 1,
                              height: 35,
                              width: '100%',
                              backgroundColor: (theme) =>
                                !isEmpty(bookedSlots) &&
                                isSameDay(
                                  new Date(bookedSlots[0].startTime),
                                  day.date
                                )
                                  ? theme.palette.success.tint
                                  : theme.palette.grey[100],
                            }}
                          >
                            <Typography
                              variant="l4"
                              sx={{
                                color: (theme) => theme.palette.standard.dark,
                              }}
                            >
                              {format(day.date, 'E')}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            textAlign="center"
                            sx={{
                              px: 2,
                              py: 1,
                              height: 35,
                              width: '100%',
                              backgroundColor: (theme) =>
                                !isEmpty(bookedSlots) &&
                                isSameDay(
                                  new Date(bookedSlots[0].startTime),
                                  day.date
                                )
                                  ? theme.palette.success.tint
                                  : theme.palette.grey[100],
                            }}
                          >
                            <Typography
                              variant="l5"
                              sx={{ whiteSpace: 'nowrap' }}
                            >
                              {format(day.date, 'dd/MM')}
                              {isSameDay(new Date(), day.date) && ' (Today)'}
                            </Typography>
                          </Grid>
                          <Grid item container column>
                            {scheduleData.timeSlots.map((slot) => (
                              <Conditionally>
                                <If condition={day.slots[slot.formatted]}>
                                  <Grid
                                    item
                                    alignSelf="center"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                      px: 2,
                                      py: 1,
                                      height: 35,
                                      width: '100%',
                                      display: 'flex',
                                      borderTopStyle: 'solid',
                                      borderTopColor: (theme) =>
                                        theme.palette.standard.light,
                                      ...(isSlotBooked(
                                        day.slots[slot.formatted]
                                      )
                                        ? {
                                            backgroundColor: (theme) =>
                                              theme.palette.success.tint,
                                          }
                                        : {}),
                                    }}
                                  >
                                    <Conditionally>
                                      <If
                                        condition={
                                          day.slots[slot.formatted]?.status ===
                                          TIME_SLOT_STATUSES.FREE.key
                                        }
                                      >
                                        <Tooltip
                                          autoHide
                                          placement="right"
                                          injectWrapper
                                          title={`${slot.formatted} - ${
                                            day.slots[slot.formatted]
                                              ?.formattedEndTime
                                          }`}
                                        >
                                          <Conditionally>
                                            <If
                                              condition={isSlotBooked(
                                                day.slots[slot.formatted]
                                              )}
                                            >
                                              <Button
                                                size="small"
                                                color="primary"
                                                variant="text"
                                                disabled={
                                                  !isSlotUnbookable(
                                                    day.slots?.[slot.formatted]
                                                  )
                                                }
                                                onClick={() => {
                                                  removeFromBookedSlots(
                                                    day.slots[slot.formatted]
                                                  );
                                                }}
                                              >
                                                Unbook
                                              </Button>
                                            </If>
                                            <Else>
                                              <Button
                                                size="small"
                                                color="primary"
                                                variant="text"
                                                disabled={
                                                  !isSlotBookable(
                                                    day.slots?.[slot.formatted]
                                                  )
                                                }
                                                onClick={() => {
                                                  if (!isPage) {
                                                    addToBookedSlots(
                                                      day.slots[slot.formatted]
                                                    );
                                                  }
                                                }}
                                              >
                                                {isPage ? 'Available' : 'Book'}
                                              </Button>
                                            </Else>
                                          </Conditionally>
                                        </Tooltip>
                                      </If>
                                      <Else>
                                        <Tooltip
                                          autoHide
                                          placement="right"
                                          injectWrapper
                                          title={`${
                                            utils.getConsultationBookingStatus(
                                              day.slots[slot.formatted]
                                                ?.consultationStatus
                                            )?.title
                                          } #${
                                            day.slots[slot.formatted]
                                              ?.consultationId
                                          }. Time: ${slot.formatted} - ${
                                            day.slots[slot.formatted]
                                              ?.formattedEndTime
                                          }`}
                                        >
                                          <Button
                                            size="small"
                                            color={
                                              utils.getConsultationBookingStatus(
                                                day.slots[slot.formatted]
                                                  ?.consultationStatus
                                              )?.color
                                            }
                                            variant="text"
                                            onClick={() => {
                                              window.open(
                                                `/consultation/${
                                                  day.slots[slot.formatted]
                                                    ?.consultationId
                                                }`,
                                                '_blank'
                                              );
                                            }}
                                          >
                                            #
                                            {
                                              day.slots[slot.formatted]
                                                ?.consultationId
                                            }
                                          </Button>
                                        </Tooltip>
                                      </Else>
                                    </Conditionally>
                                  </Grid>
                                </If>
                                <Else>
                                  <Grid
                                    item
                                    alignSelf="center"
                                    sx={{
                                      px: 2,
                                      py: 1,
                                      height: 35,
                                      width: '100%',
                                      borderTopStyle: 'solid',
                                      borderTopColor: (theme) =>
                                        theme.palette.standard.light,
                                      backgroundColor: (theme) =>
                                        theme.palette.standard.tint,
                                    }}
                                  />
                                </Else>
                              </Conditionally>
                            ))}
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              </Grid>
            </Else>
          </Conditionally>
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          id="daily-booking-availability-tabpanel"
          sx={{
            height: '100%',
            position: 'relative',
          }}
        >
          <Conditionally>
            <If condition={isFetchingDay}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%' }}
              >
                <Grid item>
                  <CircularProgress size={150} />
                </Grid>
              </Grid>
            </If>
            <ElseIf condition={isEmpty(dayBookingSlots)}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="p1">No available time slots.</Typography>
                </Grid>
              </Grid>
            </ElseIf>
            <Else>
              <Grid
                container
                flexWrap="nowrap"
                sx={{
                  borderStyle: 'solid',
                  borderColor: (theme) => theme.palette.standard.main,
                  borderRightStyle: 'none',
                }}
              >
                <>
                  <Grid
                    item
                    container
                    column
                    flex={1}
                    sx={{
                      minWidth: 200,
                      borderRightStyle: 'solid',
                      borderColor: (theme) => theme.palette.standard.light,
                    }}
                  >
                    <Grid
                      item
                      textAlign="center"
                      sx={{
                        px: 0.7,
                        py: 0.7,
                        height: 35,
                        width: '100%',
                        backgroundColor: (theme) => theme.palette.grey[100],
                      }}
                    >
                      <Typography variant="l4">Time</Typography>
                    </Grid>
                    <Grid container sx={{ height: 35 }}>
                      <Grid
                        item
                        textAlign="center"
                        sx={{
                          px: 0.7,
                          py: 0.7,
                          width: '50%',
                          backgroundColor: (theme) => theme.palette.grey[100],
                          whiteSpace: 'nowrap',
                          borderTopStyle: 'solid',
                          borderRightStyle: 'solid',
                          borderTopColor: (theme) =>
                            theme.palette.standard.light,
                          borderRightColor: (theme) =>
                            theme.palette.standard.light,
                        }}
                      >
                        <Typography variant="l4">Local time</Typography>
                      </Grid>
                      <Grid
                        item
                        textAlign="center"
                        sx={{
                          px: 0.7,
                          py: 0.7,
                          width: '50%',
                          backgroundColor: (theme) => theme.palette.grey[100],
                          whiteSpace: 'nowrap',
                          borderTopStyle: 'solid',
                          borderTopColor: (theme) =>
                            theme.palette.standard.light,
                        }}
                      >
                        <Typography variant="l4">
                          {getDefaultCountryCodeLong()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container column>
                      {!isEmpty(dayScheduleData?.timeSlots) &&
                        dayScheduleData.timeSlots.map((slot) => (
                          <Grid container>
                            <Grid
                              item
                              display="flex"
                              alignSelf="center"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                py: 1,
                                height: 70,
                                width: '50%',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                borderTopStyle: 'solid',
                                borderRightStyle: 'solid',
                                borderTopColor: (theme) =>
                                  theme.palette.standard.light,
                                borderRightColor: (theme) =>
                                  theme.palette.standard.light,
                              }}
                            >
                              <Typography variant="body2">
                                {slot.formatted}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              container
                              alignSelf="center"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                py: 1,
                                height: 70,
                                width: '50%',
                                textAlign: 'center',
                                whiteSpace: 'nowrap',
                                borderTopStyle: 'solid',
                                borderTopColor: (theme) =>
                                  theme.palette.standard.light,
                              }}
                            >
                              <Typography variant="body2">
                                {countryUtils.convertTimeToPatientTimeZone(
                                  slot
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  {!isEmpty(dayScheduleData?.schedule) &&
                    keys(dayScheduleData.schedule).map((doctorId) => {
                      const column = dayScheduleData.schedule[doctorId];
                      return (
                        <Grid
                          item
                          container
                          column
                          flex={1}
                          sx={{
                            borderRightStyle: 'solid',
                            borderColor: (theme) =>
                              theme.palette.standard.light,
                          }}
                        >
                          <Grid
                            item
                            textAlign="center"
                            sx={{
                              px: 2,
                              py: 1,
                              height: 70,
                              width: '100%',
                              backgroundColor: (theme) =>
                                !isEmpty(bookedSlots) &&
                                bookedSlots[0].doctorId === doctorId
                                  ? theme.palette.success.tint
                                  : theme.palette.grey[100],
                            }}
                          >
                            <Typography
                              variant="l4"
                              sx={{
                                whiteSpace: 'nowrap',
                                color: (theme) => theme.palette.standard.dark,
                              }}
                            >
                              {userGetters.getFullName(column.doctor)}
                            </Typography>
                          </Grid>
                          <Grid item container column>
                            {dayScheduleData.timeSlots.map((slot) => {
                              const slotsForRaw = column.slots[slot.formatted];
                              if (!slotsForRaw) {
                                return (
                                  <Grid
                                    item
                                    alignSelf="center"
                                    sx={{
                                      px: 2,
                                      py: 1,
                                      height: 70,
                                      width: '100%',
                                      borderTopStyle: 'solid',
                                      borderTopColor: (theme) =>
                                        theme.palette.standard.light,
                                      backgroundColor: (theme) =>
                                        theme.palette.standard.tint,
                                    }}
                                  />
                                );
                              }
                              return slotsForRaw?.map((slotForRaw) => (
                                <Conditionally>
                                  <If condition={slotForRaw}>
                                    <Grid
                                      item
                                      alignSelf="center"
                                      alignItems="center"
                                      justifyContent="center"
                                      sx={{
                                        px: 2,
                                        py: 1,
                                        height: 70 / slotsForRaw.length,
                                        width: '100%',
                                        display: 'flex',
                                        borderTopStyle: 'solid',
                                        borderTopColor: (theme) =>
                                          theme.palette.standard.light,
                                        ...(isSlotBooked(slotForRaw)
                                          ? {
                                              backgroundColor: (theme) =>
                                                theme.palette.success.tint,
                                            }
                                          : {}),
                                      }}
                                    >
                                      <Conditionally>
                                        <If
                                          condition={
                                            slotForRaw?.status ===
                                            TIME_SLOT_STATUSES.FREE.key
                                          }
                                        >
                                          <Tooltip
                                            autoHide
                                            placement="right"
                                            injectWrapper
                                            title={`${slotForRaw.formattedStartTime} - ${slotForRaw?.formattedEndTime}`}
                                          >
                                            <Conditionally>
                                              <If
                                                condition={isSlotBooked(
                                                  slotForRaw
                                                )}
                                              >
                                                <Button
                                                  size="small"
                                                  color="primary"
                                                  variant="text"
                                                  disabled={
                                                    !isSlotUnbookable(
                                                      slotForRaw
                                                    )
                                                  }
                                                  onClick={() => {
                                                    removeFromBookedSlots(
                                                      slotForRaw
                                                    );
                                                  }}
                                                >
                                                  Unbook
                                                </Button>
                                              </If>
                                              <Else>
                                                <Button
                                                  size="small"
                                                  color="primary"
                                                  variant="text"
                                                  disabled={
                                                    !isSlotBookable(slotForRaw)
                                                  }
                                                  onClick={() => {
                                                    if (!isPage) {
                                                      addToBookedSlots(
                                                        slotForRaw,
                                                        column.doctor
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {isPage
                                                    ? 'Available'
                                                    : 'Book'}
                                                </Button>
                                              </Else>
                                            </Conditionally>
                                          </Tooltip>
                                        </If>
                                        <Else>
                                          <Tooltip
                                            autoHide
                                            placement="right"
                                            injectWrapper
                                            title={`${
                                              utils.getConsultationBookingStatus(
                                                slotForRaw?.consultationStatus
                                              )?.title
                                            } #${
                                              slotForRaw?.consultationId
                                            }. Time: ${
                                              slotForRaw.formattedStartTime
                                            } - ${
                                              slotForRaw?.formattedEndTime
                                            }`}
                                          >
                                            <Button
                                              size="small"
                                              color={
                                                utils.getConsultationBookingStatus(
                                                  slotForRaw?.consultationStatus
                                                )?.color
                                              }
                                              variant="text"
                                              onClick={() => {
                                                window.open(
                                                  `/consultation/${slotForRaw?.consultationId}`,
                                                  '_blank'
                                                );
                                              }}
                                            >
                                              #{slotForRaw?.consultationId}
                                            </Button>
                                          </Tooltip>
                                        </Else>
                                      </Conditionally>
                                    </Grid>
                                  </If>
                                </Conditionally>
                              ));
                            })}
                          </Grid>
                        </Grid>
                      );
                    })}
                </>
              </Grid>
            </Else>
          </Conditionally>
        </TabPanel>
      </Content>
      {!isPage && (
        <DialogActions>
          {!isEmpty(bookedSlots) && (
            <Typography variant="p3" sx={{ border: 1, p: 0.5 }}>
              {bookedSlots[0]?.doctor && (
                <>
                  <strong>Selected doctor: </strong>
                  {userGetters.getFullName(bookedSlots[0].doctor)}
                  <br />
                </>
              )}
              <strong>Selected time: </strong>
              {format(
                parseISO(bookedSlots[0]?.startTime),
                dateTimeFormatWithDay
              )}{' '}
              to {bookedSlots.last()?.formattedEndTime}
            </Typography>
          )}
          <Button
            variant="text"
            onClick={() => {
              setBookedSlots([]);
            }}
            sx={{
              p: 2,
            }}
          >
            Clear selection
          </Button>
          <Button
            variant="filled"
            onClick={() => {
              setSelectedDoctor();
              onConfirm(bookedSlots, selectedDoctor);

              onClose();
              setOpen(false);
            }}
            sx={{
              ml: 2,
              p: 2,
            }}
          >
            Confirm Booking
          </Button>
        </DialogActions>
      )}
    </Wrapper>
  );
};

export default BookingAvailabilityDialog;
