import { connect } from 'react-redux';

import { memberSelectors } from 'domain/user/member';

import ELab from './ELab';

const mapStateToProps = (state) => {
  const isFetchingHealth =
    memberSelectors.isFetchingMembersHealthProfiles(state);

  return {
    isFetchingHealth,
  };
};

export default connect(mapStateToProps)(ELab);
