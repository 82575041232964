import get from 'lodash/get';
import { createSelector } from 'reselect';

export const labSelector = (state) => get(state, 'domain.lab', {});

export const getLabRequest = createSelector(labSelector, (results) =>
  get(results, 'view')
);

export const getELabRequests = createSelector(labSelector, (results) =>
  get(results, 'eLabRequests')
);

export const getELabRequestById = createSelector(
  [getELabRequests, (_state, userId) => userId],
  (results, userId) => get(results, userId)
);

export const getLabPackages = createSelector(labSelector, (results) =>
  get(results, 'labPackages')
);

export const getELabRequestDocument = createSelector(labSelector, (results) =>
  get(results, 'eLabRequestDocument')
);

export const getPatientsLabRequestDocuments = createSelector(
  labSelector,
  (results) => get(results, 'patientsLabRequestDocuments')
);

export const isFetchingLabRequest = createSelector(labSelector, (results) =>
  get(results, 'isFetching')
);
export const isFetchingDocuments = createSelector(labSelector, (results) =>
  get(results, 'isFetchingDocuments')
);

export const isFetchingLabRequestOthers = createSelector(
  labSelector,
  (results) => get(results, 'isFetchingOthers')
);

export const isSubmittingLabRequest = createSelector(labSelector, (results) =>
  get(results, 'isSubmitting')
);

export const isSubmittingPatientELabRequests = createSelector(
  labSelector,
  (results) => get(results, 'isSubmittingPatientELabRequests')
);

export const getConsultationLabRequests = createSelector(
  labSelector,
  (results) => get(results, 'consultationLabRequests') || []
);
