import React from 'react';
import MuiCardContent from '@mui/material/CardContent';

const CardContent = ({ children, noPadding = false, ...rest }) => (
  <MuiCardContent noPadding={noPadding} {...rest}>
    {children}
  </MuiCardContent>
);

export default CardContent;
