import { connect } from 'react-redux';

import { fileActions, fileSelectors } from 'domain/file';
import {
  patientHistoryActions as actions,
  patientHistorySelectors as selectors,
} from 'domain/patientHistory';

import PatientHistorySection from './PatientHistorySection';

const mapStateToProps = (state) => ({
  patientHistory: selectors.getPatientHistory(state),
  isFetching: selectors.isFetchingPatientHistory(state),
  getFilesOf: (referenceType, referenceId) =>
    fileSelectors.getFilesOf(state, referenceType, referenceId),
  isFetchingFilesOf: (referenceType, referenceId) =>
    fileSelectors.isFetchingFilesOf(state, referenceType, referenceId),
});

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(actions.clearPatientHistory()),
  fetchPatientHistory: (userId) =>
    dispatch(actions.fetchPatientHistory(userId)),
  fetchFilesOf: (fileIds, referenceType, referenceId) =>
    dispatch(fileActions.fetchFilesOf(fileIds, referenceType, referenceId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientHistorySection);
