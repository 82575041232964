import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as constants from './constants';
import httpClient from '../../core/httpClient';
import * as actions from './actions';

export function* fetchTenants({ ids }) {
  const fetchTenantsEndpoint = `${constants.TENANT_ENDPOINT}/${constants.LIST_ENDPOINT}?ids=[${ids}]`;
  const response = yield call(httpClient.get, fetchTenantsEndpoint);
  yield put(actions.tenantsReceived(response.data));
}

export function* fetchCities() {
  const fetchCitiesEndpoint = `${constants.CITIES_ENDPOINT}`;
  const response = yield call(httpClient.get, fetchCitiesEndpoint);
  yield put(actions.citiesReceived(response.data));
}

export default function* tenantModelSaga() {
  yield all([
    takeLatest(types.FETCH_TENANTS, fetchTenants),
    takeLatest(types.FETCH_CITIES, fetchCities),
  ]);
}
