import React, { useEffect, useState } from 'react';
import {
  Grid,
  CardContent,
  Chip,
  DataGrid,
  Button,
  CardHeader,
  Card,
  PopConfirm,
} from 'design-system';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddEditDialog from '../AddEditDialog';

const ConsultationTypeConfigurationSection = ({
  consultationTypeId,
  consultationTypeConfigurations,
  getConsultationTypeConfigurations,
  deleteConsultationTypeConfiguration,
  insuranceProvidersList,
  doctors,
  isSubmitting,
  isFetching,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [popConfirmAnchors, setPopConfirmAnchors] = useState({});

  useEffect(() => {
    if (consultationTypeId) {
      getConsultationTypeConfigurations(consultationTypeId);
    }
  }, [consultationTypeId, isSubmitting]);

  const showEditPopup = (row) => {
    setSelectedRow(row);
    setIsEditModalOpen(true);
  };

  const showPopConfirm = (id, event) => {
    setPopConfirmAnchors({
      ...popConfirmAnchors,
      [id]: event.currentTarget,
    });
  };

  const closePopConfirm = (id, confirm) => {
    setPopConfirmAnchors({
      ...popConfirmAnchors,
      [id]: null,
    });
    if (confirm) deleteConsultationTypeConfiguration(id);
  };
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.5,
      valueGetter: (param) =>
        insuranceProvidersList.find(
          (insurance) => insurance.id === param.row.insuranceProviderId
        )?.englishName || param.row.insuranceProviderId,
    },
    {
      field: 'doctors',
      headerName: 'Doctors',
      flex: 1,

      renderCell: (param) => {
        const rowDoctors = param?.row?.doctors;
        const doctorNames = rowDoctors?.map(
          (doctorId) =>
            doctors.find((doctor) => doctor.id === doctorId)?.fullName ||
            doctorId
        );
        return (
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            {doctorNames?.map((value) => (
              <Grid>
                <Chip
                  size="small"
                  color="primary"
                  sx={{ maxHeight: 20 }}
                  key={value}
                  label={
                    doctors?.find((doctor) => doctor.id === value)?.fullName ||
                    value
                  }
                />
              </Grid>
            ))}
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (param) => {
        return (
          <Grid
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
            }}
          >
            <Button
              variant="text"
              size="medium"
              startIcon={<EditIcon />}
              onClick={(e) => {
                e.stopPropagation();
                showEditPopup(param.row);
              }}
            />
            <Button
              variant="text"
              color="error"
              size="medium"
              startIcon={<DeleteIcon />}
              onClick={(e) => {
                e.stopPropagation();
                showPopConfirm(param.row.id, e);
              }}
            />
            <PopConfirm
              primaryButtonTitle="Cancel"
              secondaryButtonTitle="Delete"
              anchorEl={popConfirmAnchors[param.row.id]}
              onOk={(e) => {
                e.stopPropagation();
                closePopConfirm(param.row.id);
              }}
              open={Boolean(popConfirmAnchors[param.row.id])}
              onCancel={(e) => {
                e.stopPropagation();
                closePopConfirm(param.row.id, true);
              }}
              id={
                popConfirmAnchors[param.row.id]
                  ? `delete-configuration-${param.row.id}-confirm`
                  : undefined
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              Are you sure you want to delete this insurance configuration?
            </PopConfirm>
          </Grid>
        );
      },
    },
  ];
  return (
    <Card
      key="insurances-per-consultation-type"
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title="Active insurances per consultation type"
        sx={{ pb: 0 }}
        action={
          <Button
            variant="filled"
            onClick={() => setIsAddModalOpen(true)}
            spinning={isSubmitting}
          >
            Add
          </Button>
        }
      />

      <CardContent
        noPadding
        sx={{
          p: 2,
        }}
      >
        <DataGrid
          rows={consultationTypeConfigurations}
          pageSize={7}
          loading={isFetching || isSubmitting}
          columns={columns}
          rowHeight={120}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
        {isEditModalOpen && (
          <AddEditDialog
            isModalOpen={isEditModalOpen}
            setModalOpen={setIsEditModalOpen}
            isAdd={false}
            configurationId={selectedRow.id}
            consultationTypeId={consultationTypeId}
            insuranceId={selectedRow.insuranceProviderId}
            insuranceDoctors={selectedRow.doctors}
          />
        )}
        {isAddModalOpen && (
          <AddEditDialog
            isModalOpen={isAddModalOpen}
            setModalOpen={setIsAddModalOpen}
            isAdd
            consultationTypeId={consultationTypeId}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ConsultationTypeConfigurationSection;
