import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { userActions, userSelectors } from 'domain/user';
import {
  subscriptionActions,
  subscriptionSelectors,
} from 'domain/subscription';

import UserView from './UserView';

const mapStateToProps = (state) => {
  const user = userSelectors.getUser(state);
  const isFetching = userSelectors.isFetchingUser(state);
  const isSubmitting = userSelectors.isSubmittingUser(state);
  const subscription = subscriptionSelectors.getSubscription(state);
  const isFetchingSubscriptions = subscriptionSelectors.isFetching(state);
  const subscriptionPlans = subscriptionSelectors.getSubscriptionPlans(state);
  const isSubmittingSubscriptions = subscriptionSelectors.isSubmitting(state);
  const isSavingExtractedIdentityData =
    userSelectors.isSavingExtractedIdentityData(state);

  return {
    user,
    isFetching,
    subscription,
    isSubmitting,
    subscriptionPlans,
    isFetchingSubscriptions,
    isSubmittingSubscriptions,
    isSavingExtractedIdentityData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: (id) => dispatch(userActions.fetchUser(id)),
  getSubscription: (userId) =>
    dispatch(subscriptionActions.getSubscription(userId)),
  updateSubscription: (id, payload) =>
    dispatch(subscriptionActions.updateSubscription(id, payload)),
  updateUser: (id, payload) => dispatch(userActions.updateUser(id, payload)),
  fetchSubscriptionPlans: () =>
    dispatch(subscriptionActions.fetchSubscriptionPlans()),
  cancelSubscription: (userId) =>
    dispatch(subscriptionActions.cancelSubscription(userId)),
});

export default appData(connect(mapStateToProps, mapDispatchToProps)(UserView));
