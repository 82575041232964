import * as types from './actionTypes';

export const initalizeApp = () => ({
  type: types.INITIALIZE_APP,
});

export const saveFilters = (filters, lsKey) => ({
  type: types.SAVE_FILTERS,
  filters,
  lsKey,
});

export const pursueAction = (actionType, callback) => ({
  type: types.PURSUE_ACTION,
  actionType,
  callback,
});

export const routeChanged = (location) => ({
  type: types.ROUTE_CHANGED,
  location,
});
