import keys from 'lodash/keys';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { Form } from 'design-system/form';
import {
  arePatientsOutcomesPendingSubmission,
  arePatientsOutcomesPendingResubmission,
  havePatientsOutcomesBeenSubmittedBefore,
} from 'domain/consultation/utils';
import {
  OUTCOME_OPTIONS,
  OUTCOME_SUBMISSION_STATUS,
} from 'domain/outcome/constants';
import {
  If,
  Tab,
  Tabs,
  Popup,
  ElseIf,
  Button,
  Dialog,
  Message,
  Tooltip,
  TabPanel,
  Typography,
  DialogTitle,
  Conditionally,
  DialogContent,
  DialogActions,
} from 'design-system';

const CLOSE_IDENTIFIER = -1;

const PatientOutcomeWizardDialog = ({
  // Props
  /* Whether the dialog is open */
  open,
  /* */
  labels,
  /* What happens on close */
  onClose,
  /* List of consultation patients */
  patients,
  /* Form fields. Used as a function to pass form methods and
  other variables to the form fields defined in the consumer component */
  children,
  /* Default values. Used to initialize the form data */
  defaultValues,
  /* The type of the managed outcome. E.g: Prescription or LabTestRequest */
  referenceType,
  /* The first shown tab, as per the initially selected patient */
  initialTab = 0,
  /* Takes saved data and current data and compares them to check if a change has happened */
  hasFormChanged,
  /* The consultation ID, used to manage the outcome on the backend (CRUD) */
  consultationId,
  /* Takes an outcome as per the backend, and transforms it into form data (matching the field names) */
  outcomeToFormData,
  /* Triggers submitting all outcomes (E.g: of type prescription), and calls the passed callback on success */
  submitAllPatients,
  /* A name of the form field that determines whether we added an outcome to the selected patient or not */
  hasOutcomeFieldName,
  /* A flag that determines if the submitAllPatients is in progress */
  isSubmittingPatients,
  /* Takes the form data, and transforms it into a backend compatible outcome's payload */
  formDataToOutcomePayload,

  // State
  /* Whether the outcome of the selected patient is being updated */
  isUpdating,
  /* Whether the outcome of the selected patient is being deleted */
  isDeleting,
  /* Whether the outcome of the selected patient is being created */
  isSubmitting,
  /* Creates an outcome on the backend */
  createOutcome,
  /* Updates the outcome on the backend */
  updateOutcome,
  /* Deletes the outcome from the backend */
  deleteOutcome,
  /* The response action called when an outcome has been updated */
  onOutcomeUpdated,
  /* The response action called when an outcome has been created */
  onOutcomeCreated,
  /* The response action called when an outcome has been deleted */
  onOutcomeDeleted,
}) => {
  const formRef = useRef();

  const [tab, setTab] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [isNoShow, setNoShow] = useState(false);
  const [formState, setFormState] = useState({});
  const [dirtyFields, setDirtyFields] = useState({});
  const [confirmedTabs, setConfirmedTabs] = useState({});
  const [patientOutcomeFields, setPatientOutcomeFields] = useState();
  const [tabToGoToAfterConfirm, setTabToGoToAfterConfirm] = useState();
  const [formChangedPopupOpen, setFormChangedPopupOpen] = useState(false);

  const tabPatient = useMemo(() => {
    return patients?.[tab];
  }, [tab, patients]);

  /**
   * Checks whether all tabs before the last tab are confirmed
   */
  const areAllPreviousTabsConfirmed = useMemo(() => {
    if (!patients?.length) {
      return false;
    }

    // All tabs except the last tab should be confirmed (with status true)
    const allPreviousTabsConfirmed = patients?.every((_patient, idx) => {
      if (idx === patients.length - 1) {
        // The last tab is not included
        return true;
      }

      return confirmedTabs[idx];
    });

    return allPreviousTabsConfirmed;
  }, [confirmedTabs, patients]);

  /**
   * Checks whether there're outcomes with pending submission (and they have files)
   */
  const isPendingSubmission = useMemo(() => {
    return arePatientsOutcomesPendingSubmission(patients, referenceType);
  }, [patients, referenceType]);

  /**
   * Checks whether there're outcomes pending resubmission
   * (Submitted before with refereneceId and pending submission)
   */
  const isPendingResubmission = useMemo(() => {
    return arePatientsOutcomesPendingResubmission(patients, referenceType);
  }, [patients, referenceType]);

  /**
   * Checks whether there're outcomes with referenceId
   */
  const haveBeenSubmittedBefore = useMemo(() => {
    return havePatientsOutcomesBeenSubmittedBefore(patients, referenceType);
  }, [patients, referenceType]);

  /**
   * If it's not the last tab or not all previous tabs are confirmed, then show next button
   */
  const isNextButtonShown = useMemo(() => {
    return tab < patients.length - 1 || !areAllPreviousTabsConfirmed;
  }, [tab, patients, areAllPreviousTabsConfirmed]);

  /**
   * Get the index of a tab before the last tab that is unconfirmed
   */
  const getAPreviousUnconfirmedTab = useCallback(() => {
    let unconfirmedTab = null;

    /**
     * By definition, Array.prototype.some breaks on the first truthy condition.
     * So, on the first unconfirmed tab we meet, save its index and return true (break the loop)
     *  except the last tab.
     */
    patients?.some((_patient, idx) => {
      if (!confirmedTabs[idx] && idx !== patients.length - 1) {
        unconfirmedTab = idx;
        return true;
      }

      return false;
    });

    return unconfirmedTab;
  }, [confirmedTabs, patients]);

  /**
   * Whther to enable the submit all button or not
   */
  const isSubmitButtonDisabled = useMemo(() => {
    if (isNextButtonShown) {
      // If the Next button is shown, do not disable
      return false;
    }

    if (isPendingResubmission || isPendingSubmission) {
      // If any of the outcomes is pending submission, always enable
      return false;
    }

    if (getAPreviousUnconfirmedTab() === null && tab === patients.length - 1) {
      // If it's the last tab and all of the previous tabs are confirmed (Submit all is shown)
      // Then if there are no dirty fields, disable
      return !values(dirtyFields).some(Boolean);
    }

    return true;
  }, [
    tab,
    patients,
    formState,
    dirtyFields,
    isNextButtonShown,
    isPendingSubmission,
    isPendingResubmission,
    getAPreviousUnconfirmedTab,
  ]);

  const onTabChanged = useCallback(
    (_event, newTab) => {
      const savedTabFormState = formState[tab];
      const formData = formRef.current?.getMethods().getValues();

      if (hasFormChanged(savedTabFormState, formData)) {
        setTabToGoToAfterConfirm(newTab);
        setFormChangedPopupOpen(true);
      } else {
        setTab(newTab);
        setDirtyFields({});
      }
    },
    [tab, formState, hasFormChanged]
  );

  const close = useCallback(() => {
    setFormState({});
    setOpen(false);
    onClose();
    setTab(0);
    setConfirmedTabs({});
  }, [onClose]);

  const handleClose = useCallback(() => {
    const savedData = formState[tab];
    const formData = formRef.current?.getMethods().getValues();

    if (hasFormChanged(savedData, formData)) {
      setTabToGoToAfterConfirm(CLOSE_IDENTIFIER);
      setFormChangedPopupOpen(true);
    } else {
      close();
    }
  }, [tab, close, formState, hasFormChanged]);

  const handleCommonSuccess = useCallback(() => {
    const anUnconfirmedTab = getAPreviousUnconfirmedTab();

    setConfirmedTabs((previousConfirmedTabs) => ({
      ...previousConfirmedTabs,
      [tab]: true,
    }));

    if (tab === patients.length - 1 && anUnconfirmedTab !== null) {
      // If you're in the last tab, go to the first unconfirmed tab
      setTab(anUnconfirmedTab);
      setDirtyFields({});
    } else if (tab < patients.length - 1) {
      // Go to next tab
      setTab(tab + 1);
      setDirtyFields({});
    } else {
      submitAllPatients(() => {
        // Close without checking form change
        close();
      });
    }
  }, [tab, close, submitAllPatients, getAPreviousUnconfirmedTab]);

  /**
   * Called by the form when it's valid and we need to call an action.
   * Either delete, create, update an outcome and/or submit.
   */
  const onSubmitted = useCallback(
    (data) => {
      setFormState((previousFormState) => ({
        ...previousFormState,
        [tab]: data,
      }));

      // Create, update or delete outcome
      const payload = formDataToOutcomePayload(data);

      if (patientOutcomeFields?.id) {
        // If there's a saved outcome, either update or delete
        if (!data[hasOutcomeFieldName]) {
          // If {hasOutcomeFieldName} is set from true to false, then delete outcome
          deleteOutcome(consultationId, tabPatient.id, patientOutcomeFields.id);

          onOutcomeDeleted(() => {
            Message.info('Patient outcome has been removed successfully!');

            handleCommonSuccess();
          });
        } else {
          updateOutcome(
            consultationId,
            tabPatient.id,
            patientOutcomeFields.id,
            payload
          );

          onOutcomeUpdated(() => {
            Message.success('Patient outcome has been updated successfully!');

            handleCommonSuccess();
          });
        }
      } else if (data[hasOutcomeFieldName]) {
        // Create mode, and {hasOutcomeFieldName} is true
        createOutcome(consultationId, tabPatient.id, payload);

        onOutcomeCreated(() => {
          Message.success('Patient outcome has been added successfully!');

          handleCommonSuccess();
        });
      } else {
        // No action required
        handleCommonSuccess();
      }
    },
    [
      tab,
      patients,
      tabPatient,
      updateOutcome,
      createOutcome,
      deleteOutcome,
      consultationId,
      onOutcomeCreated,
      onOutcomeUpdated,
      onOutcomeDeleted,
      handleCommonSuccess,
      patientOutcomeFields,
      formDataToOutcomePayload,
    ]
  );

  /**
   * Called upon clicking the submit button. Triggers form validation
   */
  const onSubmit = useCallback(async () => {
    const savedData = formState[tab];
    const formData = formRef.current?.getMethods().getValues();
    const isValid = await formRef.current?.getMethods()?.trigger();
    const isWithOutcome = formData?.[hasOutcomeFieldName];
    const anUnconfirmedTab = getAPreviousUnconfirmedTab();

    if (!isValid) {
      return;
    }

    if (anUnconfirmedTab === null && tab === patients.length - 1) {
      // If there's no unconfirmed tabs (All tabs should be confirmed)
      // and it's the last step (submitting all prescriptions)
      const patientsWithOutcome = patients.filter((patient) => {
        return patient.output.some((outcome) => {
          return outcome.referenceType === referenceType;
        });
      });

      // If patients do not have prescriptions, and doctor didn't add a prescription for the current patient
      if (isEmpty(patientsWithOutcome) && !isWithOutcome) {
        Message.warning(`No patient has a ${labels.outcomeLabel}!`);
        return;
      }
    }

    if (hasFormChanged(savedData, formData)) {
      formRef.current.submit();
    } else {
      handleCommonSuccess();
    }
  }, [
    tab,
    labels,
    patients,
    formState,
    referenceType,
    hasFormChanged,
    hasOutcomeFieldName,
    handleCommonSuccess,
    getAPreviousUnconfirmedTab,
  ]);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    setTab(initialTab);
    setDirtyFields({});
  }, [initialTab]);

  /**
   * Make sure formState matches the saved patient outcome coming from consultation
   */
  useEffect(() => {
    if (isOpen) {
      const formData = {};

      patients.forEach((patient, index) => {
        const outcomeByType = patient.output.find(
          (outcome) => outcome.referenceType === referenceType
        );

        if (outcomeByType) {
          formData[index] = outcomeToFormData(outcomeByType);

          if (
            // If it's submitted, show the tab as confirmed
            outcomeByType.submissionStatus ===
            OUTCOME_SUBMISSION_STATUS.SUBMITTED.key
          ) {
            setConfirmedTabs((previousConfirmedTabs) => ({
              ...previousConfirmedTabs,
              [index]: true,
            }));
          }
        } else {
          // Maintain default values if that patient doesn't have a prescription outcome
          formData[index] = {
            ...defaultValues,
          };
        }
      });

      setFormState((previousFormState) => {
        /**
         * This use effect is run on each change on its dependencies.
         * The problem is with "patients". If a patient's outcome is updated,
         * this use effect is run and setFormState sets the default values (Check the code above),
         * for the patients with no outcome. This resets the doctor selection from:
         * "no prescription" to "has prescription" (Same for lab).
         *
         * So in order to maintain that, we need to look into the previousFormState,
         * and keep that selection.
         */
        return keys(formData).reduce((combinedFormState, patientIdx) => {
          // combinedFormState is the accumulative that we build each iteration for each patientIdx
          return {
            ...combinedFormState,
            [patientIdx]: {
              // Take the formData we collected (check the code above)
              ...formData[patientIdx],
              // If [hasOutcomeFieldName] is set, take it.
              // Otherwise, take the one from formData (Which is the default)
              [hasOutcomeFieldName]: (() => {
                if (previousFormState?.[patientIdx]) {
                  return previousFormState[patientIdx][hasOutcomeFieldName];
                }

                return formData[patientIdx][hasOutcomeFieldName];
              })(),
            },
          };
        }, {});
      });
    } else {
      setFormState({});
    }
  }, [patients, isOpen, defaultValues, outcomeToFormData]);

  useEffect(() => {
    const patient = patients?.[tab];
    const fields = formState?.[tab];
    let patientNoShow = false;
    let outcomeFormFields = { ...defaultValues };

    if (patient) {
      const isPatientNoShow = patient.output.find(
        (outcome) => outcome.referenceType === OUTCOME_OPTIONS.NoShow.key
      );

      if (isPatientNoShow) {
        patientNoShow = true;

        outcomeFormFields = {
          ...outcomeFormFields,
          [hasOutcomeFieldName]: false,
        };
      } else {
        const foundOutcome = patient.output.find(
          (outcome) => outcome.referenceType === referenceType
        );

        outcomeFormFields = {
          ...outcomeFormFields,
          id: foundOutcome?.id,
          ...fields,
        };
      }
    }

    setNoShow(patientNoShow);
    setPatientOutcomeFields(outcomeFormFields);
    formRef.current?.getMethods().reset(outcomeFormFields);
  }, [tab, patients, formState, defaultValues, hasOutcomeFieldName]);

  return (
    <>
      <Dialog
        fullWidth
        scrollable
        open={isOpen}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby={`${labels.ariaLabel}-dialog-title`}
      >
        <DialogTitle
          id={`${labels.ariaLabel}-dialog-title`}
          onClose={handleClose}
        >
          {labels.dialogTitle}
        </DialogTitle>
        <DialogContent dividers sx={{ minHeight: 500 }}>
          <Tabs
            value={tab}
            variant="scrollable"
            scrollButtons="auto"
            handleChange={onTabChanged}
            sx={{
              mb: 2,
            }}
          >
            {patients.map((patient, index) => (
              <Tab
                key={patient.id}
                value={index}
                label={
                  <Typography
                    variant="h5"
                    sx={{
                      paddingBottom: 0,
                      textTransform: 'none',
                    }}
                  >
                    {patient.fullName ?? 'N/A'}
                  </Typography>
                }
                iconPosition="start"
                {...(confirmedTabs[index]
                  ? {
                      color: 'success',
                      soak: 'dark',
                      icon: <CheckCircleIcon />,
                    }
                  : {
                      icon: <RadioButtonUncheckedIcon />,
                    })}
              />
            ))}
          </Tabs>
          {patients.map((_patient, index) => (
            <TabPanel
              value={tab}
              index={index}
              id={`${labels.ariaLabel}-patients-tabpanel`}
              key={`${labels.ariaLabel}-patients-tabpanel`}
            >
              {!isEmpty(formState) && (
                <Form ref={formRef} onSubmit={onSubmitted}>
                  {(formMethods) =>
                    children({
                      isNoShow,
                      patientOutcomeFields,
                      setDirtyFields,
                      ...formMethods,
                    })
                  }
                </Form>
              )}
            </TabPanel>
          ))}
        </DialogContent>
        <DialogActions>
          <Typography variant="p3">
            <Conditionally>
              <If condition={isSubmitting}>
                Creating {labels.outcomeLabel}...
              </If>
              <ElseIf condition={isUpdating}>
                Updating {labels.outcomeLabel}...
              </ElseIf>
              <ElseIf condition={isDeleting}>
                Deleting {labels.outcomeLabel}...
              </ElseIf>
              <ElseIf condition={isSubmittingPatients}>
                {labels.submittingLabel}
              </ElseIf>
            </Conditionally>
          </Typography>
          <Tooltip
            placement="top"
            injectWrapper
            title={labels.submitButtonTooltip}
          >
            <Button
              variant="filled"
              spinning={
                isUpdating || isDeleting || isSubmitting || isSubmittingPatients
              }
              disabled={isSubmitButtonDisabled}
              onClick={onSubmit}
            >
              {isNextButtonShown
                ? 'Next'
                : `${haveBeenSubmittedBefore ? 'Resubmit' : 'Submit'} all ${
                    labels.outcomeLabel
                  }s`}
            </Button>
          </Tooltip>
        </DialogActions>
      </Dialog>
      <Popup
        id={
          formChangedPopupOpen
            ? `${labels.ariaLabel}-form-changed-confirm`
            : undefined
        }
        open={formChangedPopupOpen}
        primaryButtonTitle="Continue editing"
        onOk={() => {
          setFormChangedPopupOpen(false);
        }}
        secondaryButtonTitle="Discard changes"
        onCancel={() => {
          setFormChangedPopupOpen(false);

          if (tabToGoToAfterConfirm !== undefined) {
            if (tabToGoToAfterConfirm === CLOSE_IDENTIFIER) {
              // Either coming from close action
              close();
            } else {
              // Or coming from tab switch
              setTab(tabToGoToAfterConfirm);
              setDirtyFields({});
            }

            // Reset value
            setTabToGoToAfterConfirm(undefined);
          }
        }}
        title="You have unsaved changes"
      >
        To save, continue editing and click on{' '}
        {isNextButtonShown
          ? 'Next'
          : `${haveBeenSubmittedBefore ? 'Resubmit' : 'Submit'} all ${
              labels.outcomeLabel
            }s`}
      </Popup>
    </>
  );
};

export default PatientOutcomeWizardDialog;
