import React from 'react';

import { Grid, Chip } from 'design-system';
import { userConstants } from 'domain/user';

const { DISEASES } = userConstants;

const ChronicConditionsDisplay = ({ selectedChronicConditions }) => (
  <Grid container rowSpacing={0.5} columnSpacing={1}>
    {selectedChronicConditions.map((value) => (
      <Grid item>
        <Chip
          size="small"
          color="error"
          key={DISEASES[value]?.key || value}
          label={DISEASES[value]?.label || value}
        />
      </Grid>
    ))}
  </Grid>
);

export default ChronicConditionsDisplay;
