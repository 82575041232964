import keys from 'lodash/keys';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState, useMemo, useCallback } from 'react';

import { Form, Controller } from 'design-system/form';
import { REVIEW_OF_SYSTEMS } from 'domain/outcome/constants';
import {
  Box,
  Grid,
  Select,
  Button,
  MenuItem,
  FormField,
  TextField,
  FormHelperText,
} from 'design-system';
import {
  FIELDS,
  REVIEW_OF_SYSTEMS_FIELDS,
  REVIEW_STATUS_TYPES as OPTIONS,
} from 'domain/doctorNotes/constants';

import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const ReviewOfSystemsForm = ({
  // Owner's user ID
  ownerId,
  // Patient's user ID
  userId,
  // Relevant consultation ID
  consultationId,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Action to update/create
  submitPatientReviewOfSystemsForm,
  // Get doctor notes by Id from state
  getDoctorNotesById,
}) => {
  const formRef = useRef();
  const [isSaving, setSaving] = useState(false);

  const patientReviewOfSystems = useMemo(() => {
    return getDoctorNotesById(userId)?.[FIELDS.REVIEW_OF_SYSTEMS.key];
  }, [getDoctorNotesById, userId]);

  const [systemsFormError, setSystemsFormError] = useState({});

  const onSubmit = useCallback(
    (reviewsOfSystems) => {
      // TODO: Do they have IDs on update? No. Needs handling.
      const updatedFormData = {};
      const patientExaminations = [];

      // Use keys to iterate through the keys of the reviewsOfSystems object
      keys(reviewsOfSystems).forEach((systemKey) => {
        const systemReview = reviewsOfSystems[systemKey];
        if (systemReview.status && systemReview.status !== null) {
          // Accumulate the data into the new object
          updatedFormData[systemKey] = {
            status: systemReview?.status,
            remarks: systemReview?.remarks || '',
            examination: systemKey,
            id: systemReview?.id,
          };
          // Prepare patientExaminations payload
          patientExaminations.push({
            ...updatedFormData[systemKey],
          });
        }
      });
      const payload = {
        [FIELDS.REVIEW_OF_SYSTEMS.fields.PATIENT_EXAMINATIONS.key]:
          patientExaminations,
      };

      setSaving(true);
      submitPatientReviewOfSystemsForm(
        userId,
        consultationId,
        {
          [FIELDS.USER_ID.key]: userId,
          [FIELDS.OWNER_ID.key]: ownerId,
          [FIELDS.CONSULTATION_ID.key]: consultationId,
          [REVIEW_OF_SYSTEMS_FIELDS.ID.key]:
            patientReviewOfSystems?.[REVIEW_OF_SYSTEMS_FIELDS.ID.key],
          [FIELDS.REVIEW_OF_SYSTEMS.key]: payload,
        },
        () => {
          onSubmitted(updatedFormData);
          setSaving(false);
        }
      );
    },
    [
      userId,
      ownerId,
      onSubmitted,
      consultationId,
      patientReviewOfSystems,
      submitPatientReviewOfSystemsForm,
    ]
  );

  return (
    <Form ref={formRef} onSubmit={onSubmit} defaultValues={defaultValues}>
      {({ control, watch }) => (
        <Section
          onEdit={onEdit}
          expanded={expanded}
          confirmed={confirmed}
          identifier={SECTIONS.REVIEW_OF_SYSTEMS.key}
          title={SECTIONS.REVIEW_OF_SYSTEMS.label}
          actions={
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              columnSpacing={3}
              rowSpacing={1}
              flexDirection={{
                xs: 'column',
                sm: 'row-reverse',
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'auto',
                  },
                }}
              >
                <Button
                  sx={{ width: '100%' }}
                  spinning={isSaving}
                  onClick={async () => {
                    const isFormValid = await formRef?.current
                      ?.getMethods?.()
                      ?.trigger?.();

                    if (!isFormValid) {
                      return;
                    }

                    const reviewsOfSystems = formRef?.current
                      ?.getMethods()
                      ?.getValues();

                    const reviewedSystems = values(reviewsOfSystems).filter(
                      (reviewOfSystem) =>
                        reviewOfSystem?.status && reviewOfSystem?.remarks // If a system has been reviewed (status and remarks are filled)
                    );
                    const atLeastFour = reviewedSystems.length >= 4;
                    const atLeastOneIsAbnormal = values(reviewsOfSystems).some(
                      (reviewOfSystem) =>
                        reviewOfSystem?.status === OPTIONS.ABNORMAL.key
                    );

                    const isValid = atLeastFour && atLeastOneIsAbnormal;
                    const error = {};

                    if (isValid) {
                      formRef?.current?.submit();
                    }

                    if (!atLeastFour) {
                      error.atLeastFour =
                        'At least four systems must be reviewed';
                    }

                    if (!atLeastOneIsAbnormal) {
                      error.atLeastOneIsAbnormal =
                        'At least one system review must be abnormal';
                    }

                    setSystemsFormError(error);
                  }}
                >
                  Save and continue
                </Button>
              </Grid>
              {!isEmpty(systemsFormError) && (
                <Grid item>
                  <Box component="ul" sx={{ m: 0 }}>
                    {values(systemsFormError).map((errorMessage) => (
                      <Box
                        component="li"
                        sx={{ color: (theme) => theme.palette.error.main }}
                      >
                        <FormHelperText error>{errorMessage}</FormHelperText>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              )}
            </Grid>
          }
        >
          <Grid container spacing={3}>
            {values(REVIEW_OF_SYSTEMS).map((system) => {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  rowSpacing={0.25}
                >
                  <FormField
                    label={system.label.toUpperCase()}
                    field={
                      <Controller
                        name={`${system.key}.status`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <Select
                            fullWidth
                            value={field.value}
                            size="medium"
                            color="standard"
                            variant="filled"
                            soak="light"
                            onChange={field.onChange}
                            placeholder="Select..."
                            error={Boolean(error)}
                            helperText={error?.message ?? null}
                            renderValue={(value) =>
                              value ? OPTIONS[value]?.label : value
                            }
                          >
                            {values(OPTIONS).map((option) => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        rules={{
                          validate: {
                            required: (value = '') => {
                              if (
                                // Check if the value is defined
                                watch(`${system.key}.remarks`) &&
                                watch(`${system.key}.remarks`).trim() &&
                                !value
                              ) {
                                return 'Status is required';
                              }
                            },
                          },
                        }}
                      />
                    }
                  />
                  <Grid item sx={{ width: '100%' }}>
                    <Controller
                      name={`${system.key}.remarks`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          rows={2}
                          fullWidth
                          multiline
                          size="medium"
                          variant="filled"
                          value={field.value}
                          placeholder="Remarks..."
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          onChange={field.onChange}
                        />
                      )}
                      rules={{
                        validate: {
                          required: (value = '') => {
                            if (
                              watch(`${system.key}.status`) &&
                              !value.trim()
                            ) {
                              return 'Remarks is required';
                            }
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Section>
      )}
    </Form>
  );
};

export default ReviewOfSystemsForm;
