/* eslint-disable react/no-unstable-nested-components */
import get from 'lodash/get';
// Important for rendering the text within the pdf file
import 'react-pdf/dist/esm/Page/TextLayer.css';
import CloseIcon from '@mui/icons-material/Close';
import { pdfjs, Page, Document } from 'react-pdf';
import BlockIcon from '@mui/icons-material/Block';
import ImageIcon from '@mui/icons-material/Image';
import React, { useState, useEffect } from 'react';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ReplayIcon from '@mui/icons-material/Replay';
// Important for rendering the annotations within the pdf file
// Although we don't have annotations, but that protects the view from extra height
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import GetAppTwoToneIcon from '@mui/icons-material/GetAppTwoTone';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import logger from 'core/logger';
import {
  Box,
  Grid,
  Popup,
  Hover,
  Button,
  Dialog,
  Tooltip,
  Skeleton,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  ReactFileViewer,
} from 'design-system';

import PdfPageNavigator from './PdfPageNavigator';
import { FILE_STATUS as STATUS } from './constants';

const DocumentOptions = {
  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

const sizeMap = {
  small: {
    spacing: 1,
    width: 5,
    height: 5,
    fontSize: '2rem',
  },
  medium: {
    spacing: 2,
    width: 12,
    height: 12,
    fontSize: '3.5rem',
  },
};

const FilePreview = ({
  sx,
  loading,
  onDelete,
  files = [],
  open = false,
  dialogActions,
  size = 'medium',
  dialogProps = {},
  closeConfirmProps,
  onClose = () => {},
  showThumbnails = false,
  showCloseConfirm = false,
  customIcon,
}) => {
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [angle, setAngle] = useState(0);
  const [isOpen, setOpen] = useState(open);
  const [filePageCount, setFilePageCount] = useState(1);
  const [selectedFileIdx, setSelectedFileIdx] = useState(0);
  const [isClosePopupOpen, setClosePopupOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const navigateToFile = (index) => {
    setSelectedFileIdx(index);
    setScale(1);
    setAngle(0);
    setPage(1);
    setFilePageCount(1);
  };

  const handleClick = (index) => {
    setSelectedFileIdx(index);
    setScale(1);
    setAngle(0);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const onCloseClicked = () => {
    if (showCloseConfirm) {
      setClosePopupOpen(true);
    } else {
      handleClose();
    }
  };

  const getFileUrl = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }

    return file.url;
  };

  const downloadFile = (file) => {
    const url = getFileUrl(file);
    const link = document.createElement('a');

    link.download = url?.split('/')?.pop() || '';
    link.href = url;
    link.click();
  };

  const DeleteFileDialog = ({
    open: isDialogOpen,
    onConfirmDelete,
    onClose: onClosed,
  }) => {
    // TODO: Use design-system's PopConfirm
    return (
      <Dialog
        fullWidth
        open={isDialogOpen}
        onClose={onClosed}
        aria-labelledby="delete-file-dialog-title"
        aria-describedby="delete-file-dialog-title"
      >
        <DialogTitle id="delete-file-dialog-title" onClose={onClosed}>
          Are sure you want to delete this file?
        </DialogTitle>
        <DialogActions>
          <Button onClick={onConfirmDelete} color="error">
            Yes
          </Button>
          <Button onClick={onClosed} autoFocus color="success">
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ViewByType = ({ file, index }) => {
    if (customIcon) {
      return (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index);
          }}
        >
          {customIcon}
        </Grid>
      );
    }

    if (file?.type?.includes('image')) {
      if (showThumbnails) {
        return (
          <Box
            onClick={(e) => {
              e.stopPropagation();
              handleClick(index);
            }}
            sx={{
              width: '100%',
              height: '100%',
              cursor: 'pointer',
              '& > img': {
                objectFit: 'cover',
                width: '100%',
                height: '100%',
              },
            }}
          >
            <img src={getFileUrl(file)} alt={file.name || ''} />
          </Box>
        );
      }

      return (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index);
          }}
        >
          <ImageIcon
            color="primary"
            sx={{
              fontSize: sizeMap[size].fontSize,
              cursor: 'pointer',
            }}
          />
        </Grid>
      );
    }

    if (file?.type?.includes('pdf')) {
      return (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          sx={{ height: '100%' }}
          onClick={(e) => {
            e.stopPropagation();
            handleClick(index);
          }}
        >
          <PictureAsPdfIcon
            color="error"
            sx={{
              fontSize: sizeMap[size].fontSize,
              cursor: 'pointer',
            }}
          />
        </Grid>
      );
    }

    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <InsertDriveFileIcon
          color="error"
          sx={{
            fontSize: sizeMap[size].fontSize,
            cursor: 'pointer',
          }}
        />
        {file.name}
      </Grid>
    );
  };

  if (loading) {
    return (
      <Grid item container flexWrap="wrap" spacing={1} sx={{ mb: 1 }}>
        {Array.from(
          Array(
            files?.length || loading?.count === undefined ? 3 : loading.count
          )
        ).map(() => (
          <Grid
            item
            sx={{
              mr: 1,
              width: (theme) =>
                theme.spacing(sizeMap[size].width - sizeMap[size].spacing),
            }}
          >
            <Skeleton
              variant="rectangle"
              sx={{
                height: (theme) =>
                  theme.spacing(sizeMap[size].height - sizeMap[size].spacing),
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  const FileView = ({ file, index }) => {
    const { id, status } = file;

    const InnerFileView = () => {
      switch (status) {
        case STATUS.FETCHING.key:
          return (
            <Skeleton
              variant="rectangle"
              sx={{
                height: (theme) =>
                  theme.spacing(sizeMap[size].height - sizeMap[size].spacing),
              }}
            />
          );
        case STATUS.COMPLETE.key:
          return <ViewByType file={file} index={index} />;
        case STATUS.UPLOADING.key:
          return (
            <Hover>
              {(isHovering) => {
                return (
                  <Box
                    sx={{ position: 'relative', width: '100%', height: '100%' }}
                  >
                    <Skeleton
                      variant="rectangle"
                      sx={{
                        height: (theme) =>
                          theme.spacing(
                            sizeMap[size].height - sizeMap[size].spacing - 0.5
                          ),
                      }}
                    />
                    <LinearProgress />
                    {false && isHovering && (
                      // TODO if needed: Cancel upload
                      <BlockIcon
                        sx={{
                          position: 'absolute',
                          top: 'calc(50% - 12px)',
                          right: 'calc(50% - 12px)',
                        }}
                      />
                    )}
                  </Box>
                );
              }}
            </Hover>
          );
        case STATUS.UPLOAD_FAILED.key:
          return (
            <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
              <ViewByType file={file} index={index} />
              <ReplayIcon
                onClick={() => file.retry()}
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 12px)',
                  right: 'calc(50% - 12px)',
                  cursor: 'pointer',
                }}
              />
            </Box>
          );
        default:
          logger.error('Error showing file', file, index);
      }
    };

    return (
      <Grid
        item
        id={`file-id-${id}`}
        className={`status-${status}`}
        sx={{
          width: (theme) =>
            theme.spacing(sizeMap[size].width - sizeMap[size].spacing),
          height: (theme) =>
            theme.spacing(sizeMap[size].height - sizeMap[size].spacing),
        }}
      >
        <InnerFileView />
      </Grid>
    );
  };

  const getTooltipTitle = (file) => {
    switch (file?.status) {
      case STATUS.FETCHING.key:
        return 'Fetching';
      case STATUS.UPLOADING.key:
        return 'Uploading';
      case STATUS.COMPLETE.key:
        return 'Click on file to view';
      case STATUS.UPLOAD_FAILED.key:
        return 'Upload failed. Click to retry';
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={sizeMap[size].spacing} sx={{ mb: 1, ...sx }}>
        {files &&
          files.map((file, idx) => (
            <Grid
              item
              sx={{
                width: (theme) => theme.spacing(sizeMap[size].width),
                height: (theme) => theme.spacing(sizeMap[size].height),
              }}
            >
              {getTooltipTitle(file) ? (
                <Tooltip
                  autoHide
                  injectWrapper
                  placement="top"
                  title={getTooltipTitle(file)}
                >
                  <FileView file={file} index={idx} />
                </Tooltip>
              ) : (
                <FileView file={file} index={idx} />
              )}
            </Grid>
          ))}
      </Grid>

      <Dialog
        fullScreen
        open={isOpen}
        onClose={onCloseClicked}
        {...dialogProps}
      >
        <DialogTitle>
          <Grid
            container
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item sx={{ width: '100%' }}>
              {files[selectedFileIdx]?.name || ''}
            </Grid>
            <Grid item container sx={{ width: '100%' }} justifyContent="center">
              <IconButton
                disabled={selectedFileIdx <= 0}
                onClick={() => navigateToFile(selectedFileIdx - 1)}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <IconButton
                style={{ opacity: loading ? 0 : 1 }}
                onClick={() => downloadFile(files[selectedFileIdx])}
              >
                <GetAppTwoToneIcon />
              </IconButton>
              {onDelete && (
                <IconButton
                  disabled={!onDelete}
                  onClick={() => {
                    setDeleteDialogOpen(true);
                  }}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  setAngle((angle + 90) % 360);
                }}
              >
                <RotateRightIcon />
              </IconButton>
              <IconButton
                disabled={scale >= 2}
                onClick={() => setScale(scale + 0.2)}
              >
                <ZoomInIcon />
              </IconButton>
              <IconButton
                disabled={scale <= 0.5}
                onClick={() => setScale(scale - 0.2)}
              >
                <ZoomOutIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setScale(1);
                  setAngle(0);
                }}
                disabled={scale === 1}
              >
                <ZoomInMapIcon />
              </IconButton>
              <IconButton
                disabled={selectedFileIdx >= files.length - 1}
                onClick={() => navigateToFile(selectedFileIdx + 1)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Grid>
            <Grid item container sx={{ width: '100%' }} rowReverse>
              <IconButton onClick={onCloseClicked}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          {files[selectedFileIdx]?.type?.includes('pdf') && (
            <>
              <PdfPageNavigator
                page={page}
                setPage={setPage}
                filePageCount={filePageCount}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={2}
              >
                <Grid
                  item
                  sx={{
                    '& .react-pdf__Document': {
                      ...(scale !== 1 && {
                        transform: `scale(${scale})`,
                        transformOrigin: '50% 0',
                      }),
                    },
                  }}
                >
                  <Document
                    rotate={angle}
                    file={getFileUrl(files[selectedFileIdx])}
                    options={DocumentOptions}
                    onLoadSuccess={({ numPages }) => setFilePageCount(numPages)}
                  >
                    <Page pageNumber={page} />
                  </Document>
                </Grid>
              </Grid>
              <PdfPageNavigator
                page={page}
                setPage={setPage}
                filePageCount={filePageCount}
              />
            </>
          )}
          {!files[selectedFileIdx]?.type?.includes('pdf') &&
            files[selectedFileIdx]?.type && (
              <Box
                sx={{
                  height: '100%',
                  '& .pdf-canvas': {
                    display: 'flex',
                    justifyContent: 'center',
                  },
                  ...(scale !== 1 && {
                    '& .pg-viewer-wrapper': {
                      transform: `scale(${scale})`,
                      transformOrigin: '50% 0',
                    },
                  }),
                  ...(angle !== 0 && {
                    '& .pg-viewer': {
                      transform: `rotate(${angle}deg)`,
                    },
                  }),
                }}
              >
                <ReactFileViewer
                  key={getFileUrl(files[selectedFileIdx])}
                  filePath={getFileUrl(files[selectedFileIdx])}
                  fileType={files[selectedFileIdx].type.split('/')[1]}
                />
              </Box>
            )}
        </DialogContent>
        {dialogActions && (
          <DialogActions sx={{ px: 3, py: 2 }}>{dialogActions}</DialogActions>
        )}
      </Dialog>
      {onDelete && (
        <DeleteFileDialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          onConfirmDelete={() => {
            onDelete(files[selectedFileIdx]?.id);
            setDeleteDialogOpen(false);
            setOpen(false);
          }}
        />
      )}
      <Popup
        id={isClosePopupOpen ? 'file-preview-close-confirm' : undefined}
        open={isClosePopupOpen}
        title={get(closeConfirmProps, 'title', 'Close')}
        primaryButtonTitle={get(closeConfirmProps, 'primaryButtonTitle', 'No')}
        onOk={() => {
          // No
          setClosePopupOpen(false);
          // Make sure it remains open
          setOpen(true);
          get(closeConfirmProps, 'onNo', () => {})();
        }}
        secondaryButtonTitle={get(
          closeConfirmProps,
          'secondaryButtonTitle',
          'Yes'
        )}
        onCancel={() => {
          // Yes
          handleClose();
          setClosePopupOpen(false);
          get(closeConfirmProps, 'onYes', () => {})();
        }}
      >
        {get(closeConfirmProps, 'content', 'Are you sure you want to close?')}
      </Popup>
    </>
  );
};

export default FilePreview;
