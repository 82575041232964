import { connect } from 'react-redux';

import { Message } from 'design-system';
import { labActions } from 'domain/lab';
import { FILE_TYPES } from 'domain/file';
import * as fileAPI from 'core/file/apis';
import * as fileUtils from 'core/file/utils';
import { prescriptionActions } from 'domain/prescription';
import { OUTCOME_OPTIONS } from 'domain/outcome/constants';
import { consultationActions as actions } from 'domain/consultation';
import { doctorNotesActions } from 'domain/doctorNotes';
import { updateConsultationPatient } from 'domain/consultation/actions';

const mapDispatchToProps = (dispatch) => {
  /**
   * Uploads E-lab test request file and attaches it to the patient's e-lab request
   *
   * @param {number} ownerId
   * @param {number} userId
   * @param {number} consultationId
   * @param {File} labRequestDocument
   */
  const attachLabRequestDocument = async (
    ownerId,
    userId,
    consultationId,
    labRequestDocument
  ) => {
    if (!labRequestDocument?.url) {
      Message.error(
        'E-lab test request document not available. Please report it to the team.'
      );
      return;
    }

    const blob = await fileUtils.objectUrlToBlob(labRequestDocument?.url);

    try {
      const file = await fileAPI.uploadBlob(
        blob,
        FILE_TYPES.DOCUMENTS,
        ownerId
      );

      dispatch(
        labActions.updatePatientELabRequest(userId, consultationId, {
          documentFileId: file.id,
        })
      );

      return file.id;
    } catch (error) {
      Message.error(
        'Error uploading E-lab test request document. Please try again or refresh the page.'
      );
    }
  };

  const attachPrescriptionDocument = async (
    ownerId,
    userId,
    consultationId,
    prescriptionDocument
  ) => {
    if (!prescriptionDocument?.url) {
      Message.error(
        'Prescription document not available. Please report it to the team.'
      );
      return;
    }

    const blob = await fileUtils.objectUrlToBlob(prescriptionDocument?.url);

    try {
      const file = await fileAPI.uploadBlob(
        blob,
        FILE_TYPES.DOCUMENTS,
        ownerId
      );

      dispatch(
        prescriptionActions.updatePatientPrescription(userId, consultationId, {
          prescriptionDocumentFileId: file.id,
        })
      );

      return file.id;
    } catch (error) {
      Message.error(
        'Error uploading prescription document. Please try again or refresh the page.'
      );
    }
  };

  const attachAssessmentSheet = async (
    ownerId,
    userId,
    consultationId,
    assessmentSheet
  ) => {
    if (!assessmentSheet?.url) {
      Message.error(
        'Assessment sheet not available. Please report it to the team.'
      );
      return;
    }
    const blob = await fileUtils.objectUrlToBlob(assessmentSheet?.url);
    try {
      const file = await fileAPI.uploadBlob(
        blob,
        FILE_TYPES.DOCUMENTS,
        ownerId
      );
      dispatch(
        doctorNotesActions.submitDoctorNotes(userId, consultationId, {
          assessmentSheetDocumentId: file.id,
        })
      );
      return file.id;
    } catch (error) {
      Message.error(
        'Error uploading assessment sheet. Please try again or refresh the page.'
      );
    }
  };

  const attachDocumentToOutcome = (
    consultationId,
    patient,
    documentFileId,
    outcomeReferenceType
  ) => {
    const outcomeOfType = patient.output.find(
      (outcome) => outcome.referenceType === outcomeReferenceType
    );

    if (outcomeOfType?.id) {
      dispatch(
        actions.updateConsultationPatientOutcome(
          consultationId,
          patient.id,
          outcomeOfType.id,
          {
            referenceFiles: [documentFileId],
          }
        )
      );
    }
  };

  /**
   * Handles uploading e-lab test request document, prescription document and assessmentsheet document
   *  and attaching them accordingly.
   *
   * @param {number} ownerId
   * @param {number} consultationId
   * @param {{ id: number, userId: number, output: { id: number, referenceType: string}[]}} patient
   * @param {{[key: string]: File}} documents
   */
  const attachPatientDocuments = async (
    ownerId,
    consultationId,
    patient,
    documents
  ) => {
    const {
      labRequestDocument,
      prescriptionDocument,
      assessmentSheetDocument,
    } = documents;

    if (labRequestDocument) {
      const labRequestDocumentFileId = await attachLabRequestDocument(
        ownerId,
        patient.userId,
        consultationId,
        labRequestDocument
      );

      attachDocumentToOutcome(
        consultationId,
        patient,
        labRequestDocumentFileId,
        OUTCOME_OPTIONS.LabTestRequest.key
      );
    }

    if (prescriptionDocument) {
      const prescriptionDocumentFileId = await attachPrescriptionDocument(
        ownerId,
        patient.userId,
        consultationId,
        prescriptionDocument
      );

      attachDocumentToOutcome(
        consultationId,
        patient,
        prescriptionDocumentFileId,
        OUTCOME_OPTIONS.Prescription.key
      );
    }
    if (assessmentSheetDocument) {
      const assessmentSheetFileId = await attachAssessmentSheet(
        ownerId,
        patient.userId,
        consultationId,
        assessmentSheetDocument
      );
      dispatch(
        updateConsultationPatient(consultationId, patient.id, {
          assessmentSheet: assessmentSheetFileId,
        })
      );
    }
  };
  return {
    attachPatientDocuments,
  };
};

export default connect(null, mapDispatchToProps);
