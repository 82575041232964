import * as types from './actionTypes';

const initialState = {
  doctorSchedules: [],
  bookingSlots: [],
  dayBookingSlots: [],
  isFetching: false,
  isRemovingRange: {},
  isAddingRange: {},
  isFetchingDailyBookingAvailability: false,
  isFetchingDoctorFreeScheduleSlots: false,
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DOCTOR_SCHEDULES_RECEIVED: {
      return {
        ...state,
        doctorSchedules: [...(action.doctorSchedules || [])],
      };
    }
    case types.DOCTOR_BOOKING_AVAILABILITY_RECEIVED: {
      return {
        ...state,
        bookingSlots: [...(action.bookingSlots || [])],
      };
    }
    case types.DAILY_BOOKING_AVAILABILITY_RECEIVED: {
      return {
        ...state,
        dayBookingSlots: [...(action.dayBookingSlots || [])],
      };
    }
    case types.DOCTOR_FREE_SCHEDULES_SLOTS_RECEIVED: {
      return {
        ...state,
        freeBookingSlots: [...(action.freeBookingSlots || [])],
      };
    }
    case types.CLEAR_BOOKING_AVAILIABLITY: {
      return {
        ...state,
        bookingSlots: [],
        isFetching: false,
        dayBookingSlots: [],
        isFetchingDailyBookingAvailability: false,
      };
    }
    case types.SET_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_FETCHING_DAILY_BOOKING_AVAILABILITY: {
      return {
        ...state,
        isFetchingDailyBookingAvailability:
          action.isFetchingDailyBookingAvailability,
      };
    }
    case types.SET_FETCHING_DOCTOR_FREE_SCHEDULE_SLOTS: {
      return {
        ...state,
        isFetchingDoctorFreeScheduleSlots:
          action.isFetchingDoctorFreeScheduleSlots,
      };
    }
    case types.SET_ADDING_RANGE: {
      return {
        ...state,
        isAddingRange: {
          ...state.isAddingRange,
          [action.scheduleId]: action.isAddingRange,
        },
      };
    }
    case types.SET_REMOVING_RANGE: {
      return {
        ...state,
        isRemovingRange: {
          ...state.isRemovingRange,
          [action.rangeId]: action.isRemovingRange,
        },
      };
    }
    default:
      return state;
  }
};

export default scheduleReducer;
