/* eslint-disable import/prefer-default-export */
import React from 'react';

import { DataGrid } from 'design-system';

export const TABS = {
  STATUS: {
    key: 0,
    // identitier used to match the correct data grid set of props. Check utils#historyToDataGridProps
    // and TabPanel code in the JSX file
    identifier: 'status',
    label: 'Status',
    id: 'status-change-history-tabpanel',
    component: (props) => {
      return (
        <DataGrid
          loading={props?.isFetching}
          rows={props?.rows || []}
          columns={props?.columns || []}
        />
      );
    },
  },
  ASSIGNEE: {
    key: 1,
    identifier: 'assigneeid',
    label: 'Assignee',
    id: 'assignee-change-history-tabpanel',
    component: (props) => (
      <DataGrid
        loading={props?.isFetching}
        rows={props?.rows || []}
        columns={props?.columns || []}
      />
    ),
  },
  GENERAL_CHANGES: {
    key: 2,
    identifier: 'generalChanges',
    label: 'General Changes',
    id: 'general-changes-history-tabpanel',
    component: (props) => (
      <DataGrid
        loading={props?.isFetching}
        rows={props?.rows || []}
        columns={props?.columns || []}
      />
    ),
  },
  LINE_ITEMS: {
    key: 3,
    identifier: 'lineItems',
    label: 'Medications',
    id: 'line-items-history-tabpanel',
    component: (props) => (
      <DataGrid
        loading={props?.isFetching}
        rows={props?.rows || []}
        columns={props?.columns || []}
      />
    ),
  },
};

/**
 * Only those columns are shown in the order history, and label is their presentable name.
 */
export const COLUMN_CONFIG = {
  status: {
    label: 'Status',
  },
  doctor: {
    label: 'Doctor',
  },
  institute: {
    label: 'Institute',
  },
  reminderdate: {
    label: 'Reminder date',
  },
  tenantkey: {
    label: 'Branch',
  },
  followupreminderdate: {
    label: 'Follow-up reminder date',
  },
  prescriptions: {
    label: 'Prescriptions',
  },
  haspaymenttransaction: {
    label: 'Added payment transaction',
  },
  showprescriptions: {
    label: 'Show prescriptions',
  },
  driverid: {
    label: 'Driver',
  },
  logisticprovidername: {
    label: 'Logistic provider',
  },
  assigneeid: {
    label: 'Assignee',
  },
  formatteddeliveryaddress: {
    label: 'Delivery address',
  },
};
