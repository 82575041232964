import React, { useMemo, useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { WhatsappShareButton, WhatsappIcon } from 'react-share';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { locationUtils } from 'core/location';

import {
  Grid,
  Dialog,
  Button,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from 'design-system';

export const CHANNELS = {
  COPY: 'copy',
  WHATSAPP: 'whatsapp',
  MAPS: 'maps',
};

/**
 * Reference: https://developers.google.com/maps/documentation/urls/get-started
 */

const LocationShare = ({
  open,
  onClose,
  address,
  // Usage ex: amendMessage = (message, channel) => newMessage
  amendMessage = (message) => message,
}) => {
  const [isTooltipShown, setTooltipShown] = useState(false);

  const mapUrl = useMemo(() => {
    return locationUtils.getAddressMapUrl(address);
  }, [address]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="location-share"
    >
      <DialogTitle onClose={onClose}>Share address</DialogTitle>
      <DialogContent>
        <Grid container column rowSpacing={2}>
          <Grid item container columnSpacing={1} alignItems="center">
            <Grid item>
              <Typography variant="p3">{address.formattedAddress}</Typography>
            </Grid>
            <Grid item>
              <Tooltip
                placement="top"
                title="Copied!"
                open={isTooltipShown}
                onClose={() => setTooltipShown(false)}
              >
                <IconButton
                  onClick={() => {
                    setTooltipShown(true);
                    window.navigator.clipboard.writeText(
                      amendMessage(
                        mapUrl || address?.formattedAddress,
                        CHANNELS.COPY
                      )
                    );
                  }}
                >
                  <ContentCopyOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          {mapUrl && (
            <>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <WhatsappShareButton
                  url={mapUrl}
                  title={amendMessage(
                    address.formattedAddress,
                    CHANNELS.WHATSAPP
                  )}
                >
                  <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    startIcon={<WhatsappIcon size={20} round />}
                  >
                    Share via Whatsapp
                  </Button>
                </WhatsappShareButton>
              </Grid>
            </>
          )}
          {mapUrl && (
            <Grid item>
              <Button
                variant="text"
                color="primary"
                size="medium"
                startIcon={
                  <OpenInNewIcon color="secondary" fontSize="medium" />
                }
                onClick={() =>
                  window.open(amendMessage(mapUrl, CHANNELS.MAPS), '_blank')
                }
              >
                Open in a new tab
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LocationShare;
