import AddIcon from '@mui/icons-material/Add';
import React, { useState, useEffect } from 'react';

import { DataGridCard } from 'core/dataGrid';
import { Button, DataGrid } from 'design-system';

import { getColumns } from './utils';
import ViewMeetingDialog from '../ViewMeetingDialog';
import CreateMeetingDialog from '../CreateMeetingDialog';

const MeetingsSection = ({
  doctorId,
  // state
  meetings,
  isFetching,
  // actions
  clear,
  getMeetings,
}) => {
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [isViewDialogOpen, setViewDialogOpen] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const onRowClick = (row) => {
    setSelectedMeeting(row);
    setViewDialogOpen(true);
  };

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (doctorId) {
      getMeetings(doctorId);
    }
  }, [doctorId]);

  return (
    <>
      <DataGridCard
        sx={{ height: '100%' }}
        header={{
          title: 'Meeting Accounts',
          action: (
            <Button
              size="small"
              color="primary"
              variant="text"
              startIcon={<AddIcon />}
              onClick={() => setCreateDialogOpen(true)}
            >
              Create Meeting Account
            </Button>
          ),
        }}
      >
        <DataGrid
          columns={getColumns()}
          pageSize={5}
          onRowClick={(prms) => onRowClick(prms.row)}
          rows={meetings || []}
          loading={isFetching}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
        />
      </DataGridCard>
      {/* View Dialog */}
      <ViewMeetingDialog
        open={isViewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        doctorId={doctorId}
        meeting={selectedMeeting}
      />
      {/* Create Dialog */}
      <CreateMeetingDialog
        open={isCreateDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        doctorId={doctorId}
      />
    </>
  );
};

export default MeetingsSection;
