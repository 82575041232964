import get from 'lodash/get';

import { getKey } from '../utils';
import { FIELDS, STATUSES } from '../constants';

export const getId = (order) => get(order, FIELDS.ID.name);

export const getCreatedAt = (order) => get(order, FIELDS.CREATED_AT.name);

export const getUpdatedAt = (order) => get(order, FIELDS.UPDATED_AT.name);

export const getCreatedByUser = (order) =>
  get(order, FIELDS.CREATED_BY_USER.name);

export const getUpdatedByUser = (order) =>
  get(order, FIELDS.UPDATED_BY_USER.name);

export const getCreatedBy = (order) => get(order, FIELDS.CREATED_BY.name);

export const getUpdatedBy = (order) => get(order, FIELDS.UPDATED_BY.name);

export const getTenantKey = (order) => get(order, FIELDS.TENANT_KEY.name);

export const getNamespaceKey = (order) => get(order, FIELDS.NAMESPACE_KEY.name);

export const getUser = (order) => get(order, FIELDS.USER.name);

export const getUserId = (order) => get(order, FIELDS.USER_ID.name);

export const getOrderPatients = (order) =>
  get(order, FIELDS.ORDER_PATIENTS.name);

export const getStatus = (order) => {
  const status = get(order, FIELDS.STATUS.name);

  return getKey(STATUSES, status, status);
};

export const getChannel = (order) => get(order, FIELDS.CHANNEL.name);

export const getDoctor = (order) => get(order, FIELDS.DOCTOR.name);

export const getInstitute = (order) => get(order, FIELDS.INSTITUTE.name);

export const getPrescriptions = (order) =>
  get(order, FIELDS.PRESCRIPTIONS.name, []);

export const getDocuments = (order) => get(order, FIELDS.DOCUMENTS.name, []);

export const getLineItems = (order) => get(order, FIELDS.LINE_ITEMS.name, []);

export const getConsultationId = (order) =>
  get(order, FIELDS.CONSULTATION_ID.name);

export const getDeliveryAddress = (order) =>
  get(order, FIELDS.DELIVERY_ADDRESS.name);

export const getShippingDetails = (order) =>
  get(order, FIELDS.SHIPPING_DETAILS.name);

export const getDriverId = (order) => get(order, FIELDS.DRIVER_ID.path);

export const getFailedDeliveryAttemptReason = (order) =>
  get(order, FIELDS.FAILED_DELIVERY_ATTEMPT_REASON.path);

export const getDeliveryAddressId = (order) =>
  get(order, FIELDS.DELIVERY_ADDRESS_ID.name);

export const getDeliveryAddressDetails = (order) =>
  get(order, FIELDS.DELIVERY_ADDRESS_DETAILS.name);

export const getTotalPrice = (order) => get(order, FIELDS.TOTAL_PRICE.name);

export const getTotalDiscount = (order) =>
  get(order, FIELDS.TOTAL_DISCOUNT.name);

export const getTotalPatientDiscount = (order) =>
  get(order, FIELDS.TOTAL_PATIENT_DISCOUNT.name);

export const getTotalPatientShare = (order) =>
  get(order, FIELDS.TOTAL_PATIENT_SHARE.name);

export const getTotalPayerShare = (order) =>
  get(order, FIELDS.TOTAL_PAYER_SHARE.name);

export const hasPaymentTransaction = (order) =>
  get(order, FIELDS.HAS_PAYMENT_TRANSACTION.name);

export const getUserNotes = (order) => get(order, FIELDS.USER_NOTES.name);

export const isGenericMedicine = (order) =>
  get(order, FIELDS.IS_GENERIC_MEDICINE.name);

export const isAppendPrescription = (order) =>
  get(order, FIELDS.IS_APPEND_PRESCRIPTION.name);

export const getReminderDate = (order) =>
  get(order, FIELDS.REMINDER_DATE.name, '');

export const getFollowUpReminderDate = (order) =>
  get(order, FIELDS.FOLLOW_UP_REMINDER_DATE.name);

export const getReferenceOrderId = (order) =>
  get(order, FIELDS.REFERENCE_ORDER_ID.name);

export const getPrimaryDiagnosis = (order) =>
  get(order, FIELDS.PRIMARY_DIAGNOSIS.name);

export const getSecondaryDiagnosis = (order) =>
  get(order, FIELDS.SECONDARY_DIAGNOSIS.name);

export const isPrescriptionShownToPatient = (order) =>
  get(order, FIELDS.IS_PRESCRIPTION_SHOWN_TO_PATIENT.name);

export const isPinned = (order) => get(order, FIELDS.IS_PINNED.name);

export const isUpdatePendingReview = (order) =>
  get(order, FIELDS.IS_UPDATE_PENDING_REVIEW.name);

export const getLogisticProviderId = (order) => {
  return get(order, FIELDS.LOGISTICS_PROVIDERS_ID.path);
};

export const getExternalTaskId = (order) => {
  return get(order, FIELDS.EXTERNAL_TASK_ID.path);
};

export const getAssigneeId = (order) => {
  return get(order, FIELDS.ASSIGNEE_ID.name);
};

export const getPaymentTransaction = (order) =>
  get(order, FIELDS.PAYMENT_TRANSACTION.name);
