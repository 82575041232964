import isEmpty from 'lodash/isEmpty';
import { all, put, take, select, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import { userModelActions, userModelActionTypes } from 'model/user';
import { memberModelActions, memberModelActionTypes } from 'model/user/member';

import * as actions from './actions';
import { FIELDS } from './constants';
import * as types from './actionTypes';
import * as selectors from './selectors';

import { HEALTH_PROFILE_FIELDS } from '../constants';

const currentState = (state) => state;

export function* fetchMembers({ ownerId, membersUserIds }) {
  if (isEmpty(membersUserIds)) {
    return;
  }

  yield put(actions.setFetchingMembers(true));
  yield put(memberModelActions.fetchMembers(ownerId, membersUserIds));

  const { ok, response } = yield take(
    memberModelActionTypes.MEMBERS_FETCH_RESPONDED
  );
  if (ok) {
    yield put(
      actions.membersReceived(
        // Members
        [...(response?.data || [])]
      )
    );
  }

  yield put(actions.setFetchingMembers(false));
}

export function* fetchMembersHealthProfiles({ ownerId, membersUserIds }) {
  if (isEmpty(membersUserIds)) {
    return;
  }

  const state = yield select(currentState);
  const membersHealthProfiles = selectors.getMembersHealthProfiles(state) || [];

  yield put(actions.setFetchingMembersHealthProfiles(true));
  yield put(
    memberModelActions.fetchMembersHealthProfiles(ownerId, membersUserIds)
  );

  const { ok, response } = yield take(
    memberModelActionTypes.MEMBERS_HEALTH_PROFILES_FETCH_RESPONDED
  );

  if (ok) {
    // Maintain existing members health profiles.
    // The only way to override them is by clearing them
    yield put(
      actions.membersHealthProfilesReceived([
        // Already fetched members health profiles
        ...membersHealthProfiles,
        // New members health profiles
        ...(response?.data || []),
      ])
    );
  }

  yield put(actions.setFetchingMembersHealthProfiles(false));
}

export function* fetchBasicMembers({ ownerId }) {
  yield put(actions.setFetchingBasicMembers(true));
  yield put(memberModelActions.fetchBasicMembers(ownerId));

  const { ok, response } = yield take(
    memberModelActionTypes.BASIC_MEMBERS_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.basicMembersReceived([...(response?.data || [])]));
  }

  yield put(actions.setFetchingBasicMembers(false));
}

export function* createMember({ ownerId, member }) {
  yield put(actions.setSubmittingMember(true));
  yield put(memberModelActions.createMember(ownerId, member));

  const { ok, response } = yield take(
    memberModelActionTypes.MEMBER_CREATE_RESPONDED
  );

  if (ok) {
    yield put(actions.memberCreated(response));

    Message.success('Member has been added successfully');
  }
  yield put(actions.setSubmittingMember(false));
}

export function* deleteMember({ memberId }) {
  yield put(actions.setDeletingMember(true));
  yield put(memberModelActions.deleteMember(memberId));

  const { ok } = yield take(memberModelActionTypes.MEMBER_DELETE_RESPONDED);

  if (ok) {
    yield put(actions.memberDeleted(memberId));

    Message.info('Member has been hidden successfully');
  }

  yield put(actions.setDeletingMember(false));
}

export function* undeleteMember({ memberId }) {
  yield put(actions.setSubmittingMember(true));
  yield put(memberModelActions.updateMember(memberId));

  const { ok } = yield take(memberModelActionTypes.MEMBER_UPDATE_RESPONDED);

  if (ok) {
    yield put(
      actions.memberUpdated(memberId, {
        [FIELDS.DELETED_FROM_OWNER_ACCOUNT.name]: false,
      })
    );

    Message.success('Member has been shown successfully');
  }

  yield put(actions.setSubmittingMember(false));
}

export function* updateMemberUserProfile({
  memberUserId,
  payload,
  successCallback,
}) {
  yield put(actions.setSubmittingMember(true));
  yield put(userModelActions.updateUser(memberUserId, payload));

  const { ok, response } = yield take(
    userModelActionTypes.USER_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.memberUpdated(memberUserId, response));

    if (successCallback) {
      successCallback(response);
    } else {
      Message.success("Member's user profile updated successfully");
    }
  }

  yield put(actions.setSubmittingMember(false));
}

export function* updateMemberHealthProfile({
  memberUserId,
  payload,
  successCallback,
}) {
  yield put(actions.setSubmittingMember(true));
  yield put(
    userModelActions.updateHealthProfile(memberUserId, {
      ...payload,
      [HEALTH_PROFILE_FIELDS.USER_ID.name]: memberUserId,
    })
  );

  const { ok, response } = yield take(
    userModelActionTypes.UPDATE_HEALTH_PROFILE_RESPONDED
  );

  if (ok) {
    yield put(actions.memberHealthProfileUpdated(memberUserId, response));

    if (successCallback) {
      successCallback(response);
    } else {
      Message.success('User health data updated successfully');
    }
  }

  yield put(actions.setSubmittingMember(false));
}

export default function* memberSaga() {
  yield all([
    takeLatest(types.FETCH_MEMBERS, fetchMembers),
    takeLatest(types.CREATE_MEMBER, createMember),
    takeLatest(types.DELETE_MEMBER, deleteMember),
    takeLatest(types.UNDELETE_MEMBER, undeleteMember),
    takeLatest(types.FETCH_BASIC_MEMBERS, fetchBasicMembers),
    takeLatest(types.UPDATE_MEMBER_USER_PROFILE, updateMemberUserProfile),
    takeLatest(types.UPDATE_MEMBER_HEALTH_PROFILE, updateMemberHealthProfile),
    takeLatest(types.FETCH_MEMBERS_HEALTH_PROFILES, fetchMembersHealthProfiles),
  ]);
}
