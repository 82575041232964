import * as types from './actionTypes';

const initialState = {
  isFetching: false,
  isSubmitting: false,
  view: {},
  isFetchingSubscriptionPlans: false,
  subscriptionPlans: [],
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUBSCRIPTION_RECEIVED: {
      return {
        ...state,
        view: action.subscription || {},
      };
    }
    case types.SET_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.SUBSCRIPTION_CREATION_RESPONDED: {
      return {
        ...state,
        isSubscriptionCreated: action.isSubscriptionCreated,
      };
    }
    case types.CLEAR: {
      return {
        ...state,
        view: {},
      };
    }
    case types.SET_FETCHING_SUBSCRIPTION_PLANS: {
      return {
        ...state,
        isFetchingSubscriptionPlans: action.isFetchingSubscriptionPlans,
      };
    }
    case types.SUBSCRIPTION_PLANS_RECEIVED: {
      return {
        ...state,
        subscriptionPlans: action.subscriptionPlans || [],
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
