import get from 'lodash/get';
import { FIELDS } from './constants';

export const getId = (anonymousUser) => get(anonymousUser, FIELDS.ID.name);

export const getDeviceId = (anonymousUser) =>
  get(anonymousUser, FIELDS.DEVICE_ID.name);

export const getCreatedAt = (anonymousUser) =>
  get(anonymousUser, FIELDS.CREATED_AT.name);

export const getLastActive = (anonymousUser) =>
  get(anonymousUser, FIELDS.LAST_ACTIVE.name);

export const getCity = (anonymousUser) => get(anonymousUser, FIELDS.CITY.name);

export const getCountry = (anonymousUser) =>
  get(anonymousUser, FIELDS.COUNTRY.name);

export const getDetectedCity = (anonymousUser) =>
  get(anonymousUser, FIELDS.DETECTED_CITY.name);

export const getDetectedCountry = (anonymousUser) =>
  get(anonymousUser, FIELDS.DETECTED_COUNTRY.name);

export const getDetectedRegion = (anonymousUser) =>
  get(anonymousUser, FIELDS.DETECTED_REGION.name);

export const getLatitude = (anonymousUser) =>
  get(anonymousUser, FIELDS.LATITUDE.name);

export const getLongtitude = (anonymousUser) =>
  get(anonymousUser, FIELDS.LONGTITUDE.name);

export const getMobileAppVersion = (anonymousUser) =>
  get(anonymousUser, FIELDS.MOBILE_APP_VERSION.name);

export const getIpAddress = (anonymousUser) =>
  get(anonymousUser, FIELDS.IP_ADDRESS.name);

export const getPlatform = (anonymousUser) =>
  get(anonymousUser, FIELDS.PLATFORM.name);

export const getPlatformVersion = (anonymousUser) =>
  get(anonymousUser, FIELDS.PLATFORM_VERSION.name);
