import get from 'lodash/get';
import { createSelector } from 'reselect';

export const dietPlanSelectors = (state) => get(state, 'domain.dietPlan', {});

export const getLatestDietPlan = createSelector(dietPlanSelectors, (results) =>
  get(results, `latestDietPlan`)
);

export const isFetching = createSelector(dietPlanSelectors, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(dietPlanSelectors, (results) =>
  get(results, 'isSubmitting')
);
