import { connect } from 'react-redux';

import {
  memberActions as actions,
  memberSelectors as selectors,
} from 'domain/user/member';
import { userGetters } from 'domain/user';

import MemberRow from '../MemberManagement/components/MemberRow';

const mapStateToProps = (state) => {
  const isDeleting = selectors.isDeletingMember(state);
  const isSubmitting = selectors.isSubmittingMember(state);
  return {
    isDeleting,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  deleteMember: (memberId) =>
    dispatch(actions.deleteMember(memberId || userGetters.getId(props.member))),
  undeleteMember: (memberId) =>
    dispatch(
      actions.undeleteMember(memberId || userGetters.getId(props.member))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberRow);
