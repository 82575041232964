import get from 'lodash/get';
import forEach from 'lodash/forEach';
import React, { useEffect, useState } from 'react';
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined';

import logger from '../logger';
import httpClient from '../httpClient';
import { Button, Message } from '../../design-system';

const DEFAULT_LIMIT = 1000;
const DEFAULT_OFFSET = 0;

// TODO: to entirly remove it
const CsvExportButton = ({ endpoint, params, fields }) => {
  const [offset, setOffset] = useState();
  const [limit, setLimit] = useState();
  const [isFetching, setFetching] = useState();
  const [isCsvExportButtonShown, setCsvExportButtonShown] = useState();

  useEffect(() => {
    const query = window.location.href.split('?')[1]; // 1
    const queryParams = Object.fromEntries(new URLSearchParams(query)); // 2
    const showExport = queryParams.showExportDisabled === 'true_'; // 3 disabled for now

    setLimit(params?.limit || DEFAULT_LIMIT);
    setOffset(params?.offset || DEFAULT_OFFSET);

    setCsvExportButtonShown(showExport);
  }, []);

  const onClick = async () => {
    setFetching(true);

    try {
      const urlSearchParams = new URLSearchParams(params);

      urlSearchParams.append('limit', limit);
      urlSearchParams.append('offset', offset);

      const {
        data: { data },
      } = await httpClient.get(endpoint, {
        params: urlSearchParams,
      });
      const columns = [];
      forEach(fields, (field) => {
        if (!field.nonExportable) {
          columns.push(field.name);
        }
      });
      const rows = data
        .map((row) => {
          const cells = [];
          forEach(fields, (field) => {
            if (!field.nonExportable) {
              const cellValue =
                (field.valueGetter
                  ? field.valueGetter(get(row, field.name))
                  : get(row, field.path || field.name, '')) || '---';
              cells.push(
                typeof cellValue === 'string'
                  ? cellValue.replace(/,/g, ' ')
                  : cellValue
              );
            }
          });
          return cells.join(',');
        })
        .join('\n');
      const csvFile = new Blob([`${columns.join(',')}\n`, ...rows], {
        type: 'text/csv',
      });
      const objectURL = URL.createObjectURL(csvFile);
      const link = document.createElement('a');
      link.download = `export${new Date().toISOString()}.csv`;
      link.href = objectURL;
      link.click();
    } catch (e) {
      Message.error("Couldn't export to CSV");
      logger.log("Couldn't export to CSV", e);
    }
    setFetching(false);
  };

  return (
    isCsvExportButtonShown && (
      <Button
        size="medium"
        variant="text"
        color="primary"
        onClick={onClick}
        spinning={isFetching}
        disabled={isFetching}
        startIcon={<GridOnOutlinedIcon />}
        sx={{
          mr: 1,
        }}
      >
        Export to CSV
      </Button>
    )
  );
};

export default CsvExportButton;
