import * as types from './actionTypes';

export const updateSubscription = (id, payload) => ({
  type: types.UPDATE_SUBSCRIPTION,
  id,
  payload,
});

export const getSubscription = (userId) => ({
  type: types.GET_SUBSCRIPTION,
  userId,
});

export const cancelSubscription = (userId) => ({
  type: types.CANCEL_SUBSCRIPTION,
  userId,
});

export const subscriptionUpdateResponded = (ok, response) => ({
  type: types.SUBSCRIPTION_UPDATE_RESPONDED,
  ok,
  response,
});

export const subscriptionCancelResponded = (ok, response) => ({
  type: types.SUBSCRIPTION_CANCEL_RESPONDED,
  ok,
  response,
});

export const subscriptionReceived = (subscription) => ({
  type: types.SUBSCRIPTION_RECEIVED,
  subscription,
});

export const createSubscription = (userId, payload) => ({
  type: types.CREATE_SUBSCRIPTION,
  userId,
  payload,
});

export const subscriptionCreationResponded = (ok, response) => ({
  type: types.SUBSCRIPTION_CREATION_RESPONDED,
  ok,
  response,
});

export const fetchSubscriptionPlans = () => ({
  type: types.FETCH_SUBSCRIPTION_PLANS,
});

export const subscriptionPlansReceived = (subscriptionPlans) => ({
  type: types.SUBSCRIPTION_PLANS_RECEIVED,
  subscriptionPlans,
});
