import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import { doctorSelectors, doctorActions } from 'domain/doctor';

import DoctorProfileView from './DoctorProfileView';

const mapStateToProps = (state) => {
  const doctor = doctorSelectors.getDoctor(state);
  const isFetching = doctorSelectors.isFetching(state);
  const isSubmitting = doctorSelectors.isSubmitting(state);

  return {
    doctor,
    isFetching,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchDoctor: (id) => dispatch(doctorActions.getDoctor(id)),
  clearDoctor: () => dispatch(doctorActions.doctorReceived()),
  updateDoctor: (id, payload) =>
    dispatch(doctorActions.updateDoctor(id, payload)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(DoctorProfileView)
);
