import { all, put, call, take, takeLatest, delay } from 'redux-saga/effects';

import { Message } from 'design-system';
import { cacheUtils } from 'core/cache';
import { navigate } from 'routing/utils';
import { userActions } from 'domain/user';
import { commentActions } from 'domain/comment';
import { REFERENCE_TYPES } from 'constants/general';
import { notificationActions } from 'domain/notification';
import { consultationActions } from 'domain/consultation';
import { labModelActions, labModelActionTypes } from 'model/lab';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';
import { userModelActions, userModelActionTypes } from 'model/user';

import * as actions from './actions';
import { FIELDS } from './constants';
import * as types from './actionTypes';
import * as getters from './getters/labRequest';

export function* getLabRequestById({ id }) {
  yield put(actions.setFetchingLabRequest(true));
  yield put(labModelActions.fetchLabRequestById(id));
}

export function* labRequestReceived({ labRequest, id }) {
  const createdById = getters.getCreatedBy(labRequest);
  const updatedById = getters.getUpdatedBy(labRequest);

  let createdByUser;
  let updatedByUser;

  if (createdById) {
    yield put(userModelActions.fetchUser(createdById));
    const action = yield take(userModelActionTypes.USER_RECEIVED);

    createdByUser = action.user;
  }

  if (updatedById) {
    yield put(userModelActions.fetchUser(updatedById));
    const action = yield take(userModelActionTypes.USER_RECEIVED);

    updatedByUser = action.user;
  }

  const extensiveLabRequest = {
    ...labRequest,
    [FIELDS.CREATED_BY_USER.name]: createdByUser,
    [FIELDS.UPDATED_BY_USER.name]: updatedByUser,
  };

  yield put(actions.labRequestReceived(extensiveLabRequest, id));
  yield put(actions.setFetchingLabRequest(false));
  yield put(actions.setFetchingLabDocuments(false));
}

export function* fetchLabPackages() {
  const cachedLabPackages = cacheUtils.getCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.LAB_PACKAGES
  );
  if (cachedLabPackages) {
    yield put(actions.labPackagesReceived(cachedLabPackages));
    return;
  }

  yield put(labModelActions.fetchLabPackages());

  const { labPackages } = yield take(labModelActionTypes.LAB_PACKAGES_RECEIVED);
  cacheUtils.setCachedDomainObject(
    LOCAL_DOMAIN_CACHE_KEYS.LAB_PACKAGES,
    labPackages
  );
  yield put(actions.labPackagesReceived(labPackages));
}

export function* labRequestUpdateResponded({ ok, id, payload, response }) {
  yield put(actions.setSubmittingLabRequest(false));

  if (ok && id) {
    Message.success('Lab test updated successfully');

    if (payload.doctorNotes) {
      yield put(
        commentActions.createCommentOf(
          REFERENCE_TYPES.LAB_TEST_REQUEST,
          id,
          payload.doctorNotes
        )
      );
    }
    if (Number(response?.consultationId)) {
      yield put(actions.getLabRequestByConsultationId(response.consultationId));
    }
  }
}

export function* labRequestCreationResponded({
  ok,
  payload,
  response,
  redirect,
}) {
  yield put(actions.setSubmittingLabRequest(false));

  if (ok && response?.id) {
    if (payload.doctorNotes) {
      yield put(
        commentActions.createCommentOf(
          REFERENCE_TYPES.LAB_TEST_REQUEST,
          response.id,
          payload.doctorNotes
        )
      );
    }

    if (redirect) {
      navigate(`/lab-request/${response.id}`);
      // response?.consultationId is string
    } else if (Number(response?.consultationId)) {
      yield put(
        actions.getLabRequestByConsultationId(response?.consultationId)
      );
    }

    yield put(userActions.clearUser());

    // Why settimout? When we change the route using location.href,
    // the context changes and hides the Message, unless we add it to event loop
    // Only then, it will be executed after the routing is done
    setTimeout(() => {
      Message.success('Lab test request created successfully');
    }, 0);
  }
}

export function* updateLabRequest({ id, payload, isUpdateLabResults }) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(labModelActions.updateLabRequest(id, payload));

  if (isUpdateLabResults) {
    yield put(
      notificationActions.sendGeneralNotification(
        payload.message,
        payload.userId,
        id
      )
    );
  }

  const responseAction = yield take(
    labModelActionTypes.LAB_REQUEST_UPDATE_RESPONDED
  );
  yield call(labRequestUpdateResponded, {
    ...responseAction,
    payload,
  });
}

export function* patchLabRequest({ id, payload }) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(labModelActions.patchLabRequest(id, payload));

  const responseAction = yield take(
    labModelActionTypes.LAB_REQUEST_UPDATE_RESPONDED
  );
  yield call(labRequestUpdateResponded, {
    ...responseAction,
    payload,
  });
}

export function* createLabRequest({ payload, redirect }) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(labModelActions.createLabRequest(payload, redirect));

  const consultationId = payload[FIELDS.CONSULTATION_ID.name];

  if (consultationId) {
    // Update the reference consultation to be marked with lab test
    yield put(consultationActions.addConsultationLab(consultationId));
  }

  const responseAction = yield take(
    labModelActionTypes.LAB_REQUEST_CREATION_RESPONDED
  );
  yield call(labRequestCreationResponded, {
    ...responseAction,
    payload,
  });
}

export function* createELabRequestDocument({ payload }) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(labModelActions.createELabRequestDocument(payload));

  const { response, ok } = yield take(
    labModelActionTypes.E_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.eLabRequestDocumentCreated(response));
  } else {
    yield put(actions.eLabRequestDocumentCreated());
  }

  yield put(actions.setSubmittingLabRequest(false));
}

export function* getLabRequestByConsultationId({ consultationId }) {
  yield put(labModelActions.getLabRequestByConsultationId(consultationId));

  const { labTests } = yield take(
    labModelActionTypes.CONSULTATION_LABS_RECEIVED
  );

  yield put(actions.labRequestListReceived(labTests));
}

export function* fetchPatientELabRequest({
  userId,
  consultationId,
  callbacks,
}) {
  yield put(actions.setFetchingLabRequest(true));
  yield put(labModelActions.fetchPatientELabRequest(userId, consultationId));

  const { ok, response } = yield take(
    labModelActionTypes.PATIENT_E_LAB_REQUEST_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.patientELabRequestReceived(userId, response));
    callbacks?.success?.(response);
  }

  yield put(actions.setFetchingLabRequest(false));
  callbacks?.finally?.();

  return response;
}

export function* createPatientELabRequest({
  userId,
  consultationId,
  payload,
  successCallback,
}) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(
    labModelActions.createPatientELabRequest(userId, consultationId, payload)
  );

  const { ok, response } = yield take(
    labModelActionTypes.PATIENT_E_LAB_REQUEST_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.patientELabRequestSubmitted(userId, response));
    successCallback?.(response);
  }

  yield put(actions.setSubmittingLabRequest(false));
}

export function* updatePatientELabRequest({
  userId,
  consultationId,
  payload,
  successCallback,
}) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(
    labModelActions.updatePatientELabRequest(userId, consultationId, payload)
  );

  const { ok, response } = yield take(
    labModelActionTypes.PATIENT_E_LAB_REQUEST_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.patientELabRequestSubmitted(userId, response));
    successCallback?.(response);
  }

  yield put(actions.setSubmittingLabRequest(false));
}

export function* deletePatientELabRequest({ userId, consultationId }) {
  yield put(labModelActions.deletePatientELabRequest(userId, consultationId));

  const { ok } = yield take(
    labModelActionTypes.PATIENT_E_LAB_REQUEST_DELETE_RESPONDED
  );

  if (ok) {
    yield put(actions.patientELabRequestDeleted(userId));
  }
}

export function* submitPatientELabRequests({ consultationId, payload }) {
  yield put(actions.setSubmittingPatientELabRequests(true));
  yield put(labModelActions.submitPatientELabRequests(consultationId, payload));

  const { ok, response } = yield take(
    labModelActionTypes.PATIENT_E_LAB_REQUESTS_SUBMISSION_RESPONDED
  );
  // response contains { id: id of the created lab test request }

  if (ok) {
    yield put(actions.patientELabRequestsSubmitted(response));
  }

  yield put(actions.setSubmittingPatientELabRequests(false));
}

export function* createPatientLabRequestDocument({
  payload,
  patientId,
  successCallback,
}) {
  yield put(actions.setSubmittingLabRequest(true));
  yield put(labModelActions.createPatientLabRequestDocument(payload));

  const { response, ok } = yield take(
    labModelActionTypes.PATIENT_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.patientLabRequestDocumentCreated(response, patientId));
    successCallback?.(response);
  } else {
    yield put(actions.patientLabRequestDocumentCreated());
  }

  yield put(actions.setSubmittingLabRequest(false));
}

export function* createPatientsLabRequestDocument({ labId, payload }) {
  yield put(actions.setFetchingLabDocuments(true));
  yield put(labModelActions.createPatientsLabRequestDocument(labId, payload));

  const responseAction = yield take(
    labModelActionTypes.PATIENTS_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED
  );
  if (responseAction.ok) {
    yield delay(8000);
    Message.success('Documents Created Successfully');
    yield call(getLabRequestById, {
      id: labId,
    });
  }
}

export default function* labSaga() {
  yield all([
    takeLatest(types.UPDATE_LAB_REQUEST, updateLabRequest),
    takeLatest(types.PATCH_LAB_REQUEST, patchLabRequest),
    takeLatest(types.FETCH_LAB_PACKAGES, fetchLabPackages),
    takeLatest(types.CREATE_LAB_REQUEST, createLabRequest),
    takeLatest(types.CREATE_E_LAB_REQUEST_DOCUMENT, createELabRequestDocument),
    takeLatest(types.GET_LAB_REQUEST_BY_ID, getLabRequestById),
    takeLatest(labModelActionTypes.LAB_REQUEST_RECEIVED, labRequestReceived),
    takeLatest(
      types.GET_LAB_REQUEST_BY_CONSULTATION_ID,
      getLabRequestByConsultationId
    ),
    // Patient ELabTestRequest Sagas
    takeLatest(types.FETCH_PATIENT_E_LAB_REQUEST, fetchPatientELabRequest),
    takeLatest(types.CREATE_PATIENT_E_LAB_REQUEST, createPatientELabRequest),
    takeLatest(types.UPDATE_PATIENT_E_LAB_REQUEST, updatePatientELabRequest),
    takeLatest(types.DELETE_PATIENT_E_LAB_REQUEST, deletePatientELabRequest),
    takeLatest(types.SUBMIT_PATIENT_E_LAB_REQUESTS, submitPatientELabRequests),
    takeLatest(
      types.CREATE_PATIENT_LAB_REQUEST_DOCUMENT,
      createPatientLabRequestDocument
    ),
    takeLatest(
      types.CREATE_PATIENTS_LAB_TEST_DOCUMENTS,
      createPatientsLabRequestDocument
    ),
  ]);
}
