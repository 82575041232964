import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  Accordion,
  Typography,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from 'design-system';

const DoctorNotesSection = ({
  identifier,
  title,
  onEdit,
  expanded,
  confirmed,
  actions,
  children,
}) => {
  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        aria-controls={`${identifier}-content`}
        id={`${identifier}-header`}
        onClick={confirmed && !expanded ? onEdit : () => {}}
        sx={{
          ...(!expanded
            ? {
                backgroundColor: (theme) => theme.palette.grey[200],
              }
            : {}),
        }}
      >
        {confirmed && !expanded && (
          <CheckCircleIcon color="success" sx={{ pr: 2 }} />
        )}
        <Typography
          variant="l2"
          sx={{
            ...(!expanded
              ? {
                  color: (theme) => theme.palette.grey[600],
                }
              : {}),
          }}
        >
          {title}
        </Typography>
        {confirmed && !expanded && <EditIcon color="standard" sx={{ pl: 1 }} />}
      </AccordionSummary>
      {/*
        Why checking expanded?
        Because rendering the whole fields while they're actually not present is costly in terms of performance.
        Keep them unrendered until expanded
      */}
      <AccordionDetails sx={{ px: 3 }}>
        {expanded ? children : null}
      </AccordionDetails>
      <AccordionActions sx={{ p: 3 }}>
        {expanded ? actions : null}
      </AccordionActions>
    </Accordion>
  );
};

export default DoctorNotesSection;
