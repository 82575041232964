import React, { useMemo, useState } from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

import { Grid, Tooltip, Button } from 'design-system';
import { OUTCOME_OPTIONS } from 'domain/outcome/constants';
import ActionRequired from 'presentation/components/ActionRequired';
import { consultationUtils, consultationGetters } from 'domain/consultation';

import OutcomeManagementDialog from '../OutcomeManagementDialog';

const OutcomeManagement = ({ loading, updating, consultation, ...rest }) => {
  const [isOpen, setOpen] = useState();

  const patients = useMemo(() => {
    return consultationGetters.getPatients(consultation);
  }, [consultation]);

  const isPrescriptionOutcomesPendingSubmission = useMemo(() => {
    return consultationUtils.arePatientsOutcomesPendingSubmission(
      patients,
      OUTCOME_OPTIONS.Prescription.key
    );
  }, [patients]);

  const isLabRequestOutcomesPendingSubmission = useMemo(() => {
    return consultationUtils.arePatientsOutcomesPendingSubmission(
      patients,
      OUTCOME_OPTIONS.LabTestRequest.key
    );
  }, [patients]);

  const actionRequiredTitle = useMemo(() => {
    if (
      isPrescriptionOutcomesPendingSubmission ||
      isLabRequestOutcomesPendingSubmission
    ) {
      return 'Outcomes are not submitted';
    }

    return '';
  }, [
    isPrescriptionOutcomesPendingSubmission,
    isLabRequestOutcomesPendingSubmission,
  ]);

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          <Tooltip
            placement="right"
            injectWrapper
            title="Manage patients' outcomes"
          >
            <Button
              size="small"
              color="primary"
              variant="text"
              onClick={() => setOpen(true)}
              loading={(loading || updating) && { width: 150 }}
              startIcon={<AssignmentOutlinedIcon color="primary" />}
            >
              Submit Outcomes
            </Button>
          </Tooltip>
        </Grid>
        {!loading && actionRequiredTitle && (
          <Grid item sx={{ ml: 1 }}>
            <ActionRequired tooltipTitle={actionRequiredTitle} />
          </Grid>
        )}
      </Grid>
      {!loading && (
        <OutcomeManagementDialog
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          consultation={consultation}
          {...rest}
        />
      )}
    </>
  );
};

export default OutcomeManagement;
