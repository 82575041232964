import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { userGetters } from 'domain/user';
import { isAllowed } from 'core/permission';
import ServerAutocomplete from 'core/serverAutocomplete';
import { TextField, InputAdornment } from 'design-system';

const UserSearch = ({
  size,
  disabled,
  placeholder,
  defaultValue,
  onUserSelect,
}) => {
  const getOptionLabel = (option) => {
    const label = [];
    const fullName = userGetters.getFullName(option);
    const phoneNumber = userGetters.getPhoneNumber(option);

    if (fullName) {
      label.push(fullName);
    }

    if (isAllowed('patientInfo', 'viewPhoneNumber')) {
      label.push(phoneNumber);
    }

    return label.join(' - ');
  };

  return (
    <ServerAutocomplete
      disabled={disabled}
      size={size || 'medium'}
      onChange={onUserSelect}
      defaultValue={defaultValue}
      endpoint="/user/search/search"
      getOptionLabel={getOptionLabel}
      getRequestParams={(searchQuery) => {
        const allNumsReg = /^[\d|\s|+]+$/g;
        const urlSearchParams = new URLSearchParams();
        let newSearchQuery = searchQuery;

        if (allNumsReg.test(newSearchQuery)) {
          newSearchQuery = newSearchQuery.replace(/\s/g, '');
        }

        urlSearchParams.append('q', newSearchQuery);

        return urlSearchParams;
      }}
      throttle={(fn, searchQuery) => {
        const allNumsReg = /^[\d]+$/g;
        const newSearchQuery = searchQuery.replace('+', '');

        if (allNumsReg.test(newSearchQuery)) {
          if (newSearchQuery.length >= 4) {
            fn();
          }
        } else if (newSearchQuery.length >= 3) {
          fn();
        }
      }}
      getNoOptionsText={(searchQuery) => {
        if (defaultValue) {
          return '';
        }

        const allNumsReg = /^\d+$/g;
        const newSearchQuery =
          typeof searchQuery !== 'string'
            ? getOptionLabel(searchQuery)
            : (searchQuery || '').replace('+', '');

        if (!newSearchQuery.length) {
          return 'Start typing...';
        }
        if (allNumsReg.test(newSearchQuery)) {
          if (newSearchQuery.length < 4) {
            return 'Please type more numbers';
          }
        } else if (newSearchQuery.length < 3) {
          return 'Please type more letters';
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          shape="round"
          variant="filled"
          defaultValue={getOptionLabel(defaultValue)}
          placeholder={
            placeholder ||
            (isAllowed('patientInfo', 'viewPhoneNumber')
              ? 'Patient Name/Phone Number'
              : 'Patient Name')
          }
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon
                  color="standard"
                  fontSize="extraSmall"
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default UserSearch;
