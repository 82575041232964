import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { useRef, useState, useEffect, useCallback } from 'react';

import { dateTimeFormat } from 'constants/dateTime';
import UpdatedOrCreatedBy from 'presentation/components/UpdatedOrCreatedBy';
import {
  MEETING_FIELDS as FIELDS,
  meetingGetters as getters,
} from 'domain/meeting';
import {
  Popup,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

import MeetingForm from '../MeetingForm';

const ViewMeetingDialog = ({
  // Props
  open,
  onClose,
  meeting,
  doctorId,
  // State
  isDeleting,
  isSubmitting,
  // actions
  deleteMeeting,
  updateMeeting,
}) => {
  const formRef = useRef();

  const [isOpen, setOpen] = useState(open);
  const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(
    (data) => {
      const id = getters.getId(meeting);
      const payload = {
        ...data,
        [FIELDS.ID.name]: id,
        [FIELDS.DOCTOR_ID.name]: doctorId,
      };

      updateMeeting(id, payload, () => {
        onClosed();
      });
    },
    [meeting, doctorId]
  );

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <>
      <Dialog
        fullWidth
        scrollable
        open={isOpen}
        maxWidth="sm"
        onClose={onClosed}
        aria-labelledby="meeting-view-dialog"
      >
        <DialogTitle id="meeting-view-dialog-title" onClose={onClosed}>
          Meeting Account #{getters.getId(meeting)}
        </DialogTitle>
        <DialogContent dividers>
          <MeetingForm
            ref={formRef}
            onSubmit={onSubmit}
            defaultValues={meeting}
          />
          <UpdatedOrCreatedBy
            user={getters.getCreatedBy(meeting)}
            time={
              getters.getCreatedAt(meeting)
                ? format(
                    parseISO(getters.getCreatedAt(meeting)),
                    dateTimeFormat
                  )
                : ''
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            spinning={isDeleting}
            disabled={isSubmitting}
            onClick={() => {
              setDeleteConfirmOpen(true);
            }}
          >
            Delete Meeting Account
          </Button>
          <Button
            variant="filled"
            color="primary"
            disabled={isDeleting}
            spinning={isSubmitting}
            onClick={() => {
              formRef.current.submit();
            }}
          >
            Update Meeting Account
          </Button>
        </DialogActions>
      </Dialog>
      {/* Popup - Confirm Deletion */}
      <Popup
        id={isDeleteConfirmOpen ? 'meeting-account-delete-confirm' : undefined}
        open={isDeleteConfirmOpen}
        primaryButtonTitle="No, cancel"
        onOk={() => {
          setDeleteConfirmOpen(false);
        }}
        primaryButtonProps={{
          disabled: isDeleting,
        }}
        secondaryButtonTitle="Yes, delete"
        onCancel={() => {
          deleteMeeting(meeting, () => {
            setDeleteConfirmOpen(false);
            onClosed();
          });
        }}
        secondaryButtonProps={{
          spinning: isDeleting,
        }}
        title={`Delete Meeting Account #${getters.getId(meeting)}`}
      >
        You are about to delete meeting account of ID {getters.getId(meeting)}.
        Are you sure you want to continue?
      </Popup>
    </>
  );
};

export default ViewMeetingDialog;
