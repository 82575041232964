import MuiSwitch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState, useEffect, useCallback } from 'react';

const LabelWrapper = ({ size = 'medium', children, disabled, label }) => (
  <FormControlLabel
    label={label}
    control={children}
    disabled={disabled}
    sx={{
      m: 0,
      '& > .MuiTypography-root': {
        fontSize: (() => {
          switch (size) {
            case 'small':
              return '0.75rem';
            case 'medium':
              return '1rem';
            case 'large':
              return '1.5rem';
            default:
              return '1rem';
          }
        })(),
      },
    }}
  />
);

const Switch = ({
  size,
  label,
  checked,
  disabled,
  onChange,
  positiveLabel,
  negativeLabel,
  ...rest
}) => {
  const [switchLabel, setSwitchLabel] = useState(label);

  /**
   * Determines which label to use.
   *
   * If positive and negative labels are provided then we show one of them based on isChecked.
   * Otherwise, we default to label
   */
  const handleLabel = useCallback(
    (isChecked) => {
      if (positiveLabel && negativeLabel) {
        setSwitchLabel(isChecked ? positiveLabel : negativeLabel);
      } else {
        setSwitchLabel(label);
      }
    },
    [label, positiveLabel, negativeLabel]
  );

  /**
   * Event handler on switch changed
   */
  const onSwitchChanged = useCallback(
    (event) => {
      onChange(event);
      handleLabel(event.target.checked);
    },
    [handleLabel]
  );

  useEffect(() => {
    handleLabel(checked);
  }, [checked, handleLabel]);

  if (switchLabel) {
    return (
      <LabelWrapper disabled={disabled} label={switchLabel} size={size}>
        <MuiSwitch
          size={size}
          checked={checked}
          onChange={onSwitchChanged}
          {...rest}
        />
      </LabelWrapper>
    );
  }

  return (
    <MuiSwitch
      checked={checked}
      disabled={disabled}
      onChange={onSwitchChanged}
      {...rest}
    />
  );
};

export default Switch;
