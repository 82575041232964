import formatISO from 'date-fns/formatISO';

import { appUtils } from '../utils';

export const log = (message, ...optionalParams) => {
  if (appUtils.isDev()) {
    // eslint-disable-next-line no-console
    console.log(
      `Alma OS - [${formatISO(new Date())}]: `,
      message,
      ...optionalParams
    );
  }
};

export const error = (message, ...optionalParams) => {
  if (appUtils.isDev()) {
    // eslint-disable-next-line no-console
    console.log(
      `AlmaOS - ERROR: [${formatISO(new Date())}]: `,
      message,
      ...optionalParams
    );
  }
};
