import { connect } from 'react-redux';

import { appActions } from 'domain/app';

import PageContainer from './PageContainer';

const mapDispatchToProps = (dispatch) => ({
  routeChanged: (location) => dispatch(appActions.routeChanged(location)),
});

export default connect(null, mapDispatchToProps)(PageContainer);
