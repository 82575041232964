import keys from 'lodash/keys';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import { appUtils } from 'core/utils';
import { authSelectors } from 'domain/auth';
import { userConstants } from 'domain/user';
import {
  Grid,
  Button,
  Select,
  Dialog,
  MenuItem,
  TextField,
  Typography,
  DialogTitle,
  RequiredMark,
  DialogContent,
  DialogActions,
  InputAdornment,
} from 'design-system';
import { isAllowed } from 'core/permission';

const { SIGN_UP_SOURCE, FIELDS } = userConstants;

const CreateUserModal = ({
  open,
  onClose,
  saveUser,
  createUser,
  isSubmitting,
  tenants,
  pursueUserCreationResponse,
}) => {
  const { isClinicManager } = useSelector(authSelectors.getRole);
  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  const [isOpen, setOpen] = useState(false);
  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const [fullName, setFullname] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [tenant, setTenant] = useState(defaultTenant);
  const [signUpSource, setSignUpSource] = useState(null);

  const handleClose = (user) => {
    setOpen(false);
    onClose(user);
  };

  const onSubmit = () => {
    const payload = {
      [FIELDS.FULL_NAME.name]: fullName,
      [FIELDS.SIGN_UP_SOURCE.name]: signUpSource,
      [FIELDS.PHONE_NUMBER
        .name]: `${appUtils.getDefaultCountryCallingCode()}${phoneNumber}`,
    };

    createUser(payload);
    pursueUserCreationResponse(({ ok, response }) => {
      if (ok) {
        saveUser(response);
        handleClose(response);
      }
    });
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  useEffect(() => {
    setSubmitButtonEnabled(Boolean(fullName && phoneNumber && signUpSource));
  }, [fullName, phoneNumber, signUpSource]);

  useEffect(() => {
    if (isClinicManager) {
      setSignUpSource(SIGN_UP_SOURCE.CLINIC.key);
      setTenant(defaultTenant);
    }
  }, [open, defaultTenant, isClinicManager]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="create-user-dialog-title"
    >
      <DialogTitle id="create-user-dialog-title" onClose={handleClose}>
        New Patient
      </DialogTitle>
      <DialogContent dividers>
        <Grid container column>
          {/* Phone number */}
          <Grid item sx={{ mb: 1, px: 2 }}>
            <Typography variant="p4">PHONE NUMBER</Typography>
            <RequiredMark />
          </Grid>
          <Grid item sx={{ mb: 1, px: 2 }}>
            <TextField
              size="large"
              fullWidth
              variant="filled"
              placeholder="Phone number"
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {appUtils.getDefaultCountryCallingCode()}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* Full name */}
          <Grid item sx={{ mb: 1, px: 2 }}>
            <Typography variant="p4">FULL NAME</Typography>
            <RequiredMark />
          </Grid>
          <Grid item sx={{ mb: 1, px: 2 }}>
            <TextField
              size="large"
              fullWidth
              variant="filled"
              placeholder="Full name"
              onChange={(e) => {
                setFullname(e.target.value);
              }}
            />
          </Grid>

          {/* Sign up source */}
          {isAllowed('userList', 'signupSource') && (
            <>
              <Grid item sx={{ mb: 1, px: 2 }}>
                <Typography variant="p4">SIGN-UP SOURCE</Typography>
                <RequiredMark />
              </Grid>
              <Grid item sx={{ mb: 1, px: 2 }}>
                <Select
                  fullWidth
                  size="large"
                  soak="light"
                  value={signUpSource}
                  variant="filled"
                  placeholder="Select sign-up source..."
                  onChange={(event) => {
                    setSignUpSource(event.target.value);
                  }}
                  renderValue={(value) =>
                    value ? SIGN_UP_SOURCE[value].label : value
                  }
                >
                  {keys(SIGN_UP_SOURCE).map((item) => (
                    <MenuItem value={item} key={item}>
                      {SIGN_UP_SOURCE[item].label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item sx={{ mb: 1, px: 2 }}>
                <Typography variant="p4">BRANCH</Typography>
                <RequiredMark />
              </Grid>
              <Grid item sx={{ mb: 1, px: 2 }}>
                <Select
                  fullWidth
                  size="large"
                  soak="light"
                  variant="filled"
                  value={tenant}
                  onChange={(event) => {
                    setTenant(event.target.value);
                  }}
                >
                  {(tenants || []).map((option) => (
                    <MenuItem value={option.id} key={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          onClick={onSubmit}
          spinning={isSubmitting}
          disabled={!isSubmitButtonEnabled || isSubmitting}
        >
          Add patient
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserModal;
