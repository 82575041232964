import { FIELDS } from './constants';
import * as types from './actionTypes';

const initialState = {
  members: [],
  // Members' basic user info
  basicMembers: [],
  // Members' health profiles
  membersHealthProfiles: [],
  isFetchingMembers: false,
  isSubmittingMember: false,
  isDeletingMember: false,
  isFetchingBasicMembers: false,
  isFetchingMembersHealthProfiles: false,
};

const memberReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MEMBERS_RECEIVED: {
      const members = action.members || [];

      return {
        ...state,
        members: [...members],
      };
    }
    case types.MEMBERS_HEALTH_PROFILES_RECEIVED: {
      const membersHealthProfiles = action.membersHealthProfiles || [];

      return {
        ...state,
        membersHealthProfiles: [...membersHealthProfiles],
      };
    }
    case types.MEMBER_HEALTH_PROFILE_UPDATED: {
      const { membersHealthProfiles = [] } = state;

      const memberIdx = membersHealthProfiles?.findIndex(
        (member) => member.userId === action.memberUserId
      );
      if (memberIdx >= 0) {
        membersHealthProfiles[memberIdx] = {
          ...membersHealthProfiles[memberIdx],
          ...action.memberHealthProfile,
        };
      } else {
        // When the member had no health profile before
        membersHealthProfiles.push(action.memberHealthProfile);
      }

      return {
        ...state,
        membersHealthProfiles: [...membersHealthProfiles],
      };
    }
    case types.BASIC_MEMBERS_RECEIVED: {
      const members = action.members || [];

      return {
        ...state,
        basicMembers: [...members],
      };
    }
    case types.MEMBER_CREATED: {
      const { members = [], basicMembers = [] } = state;

      return {
        ...state,
        // On create, update both lists (whole and basic)
        members: [...members, action.member],
        basicMembers: [...basicMembers, action.member],
      };
    }
    case types.MEMBER_DELETED: {
      const { members = [], basicMembers = [] } = state;

      const memberIdx = members?.findIndex(
        (member) => member.id === action.memberId
      );
      const basicMemberIdx = basicMembers?.findIndex(
        (member) => member.id === action.memberId
      );

      if (memberIdx >= 0) {
        members[memberIdx][FIELDS.DELETED_FROM_OWNER_ACCOUNT.name] = true;
      }

      if (basicMemberIdx >= 0) {
        basicMembers[basicMemberIdx][
          FIELDS.DELETED_FROM_OWNER_ACCOUNT.name
        ] = true;
      }

      return {
        ...state,
        // On delete, update both lists (whole and basic)
        basicMembers: [...basicMembers],
        members: [...members],
      };
    }
    case types.MEMBER_UPDATED: {
      const { members = [], basicMembers = [] } = state;

      const memberIdx = members?.findIndex(
        (member) => member.id === action.memberId
      );
      const basicMemberIdx = basicMembers?.findIndex(
        (member) => member.id === action.memberId
      );

      if (memberIdx >= 0) {
        members[memberIdx] = {
          ...members[memberIdx],
          ...action.member,
        };
      }

      if (basicMemberIdx >= 0) {
        basicMembers[basicMemberIdx] = {
          ...basicMembers[basicMemberIdx],
          ...action.member,
        };
      }

      return {
        ...state,
        // On update, update both lists (whole and basic)
        basicMembers: [...basicMembers],
        members: [...members],
      };
    }
    case types.SET_FETCHING_MEMBERS: {
      return {
        ...state,
        isFetchingMembers: action.isFetchingMembers,
      };
    }
    case types.SET_FETCHING_MEMBERS_HEALTH_PROFILES: {
      return {
        ...state,
        isFetchingMembersHealthProfiles: action.isFetchingMembersHealthProfiles,
      };
    }
    case types.SET_FETCHING_BASIC_MEMBERS: {
      return {
        ...state,
        isFetchingBasicMembers: action.isFetchingBasicMembers,
      };
    }
    case types.SET_SUBMITTING_MEMBER: {
      return {
        ...state,
        isSubmittingMember: action.isSubmittingMember,
      };
    }
    case types.SET_DELETING_MEMBER: {
      return {
        ...state,
        isDeletingMember: action.isDeletingMember,
      };
    }
    default:
      return state;
  }
};

export default memberReducer;
