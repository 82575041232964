import { connect } from 'react-redux';
import React, { forwardRef } from 'react';

import { userActions, userSelectors } from 'domain/user';

import ChronicConditionsField from './ChronicConditionsField';

const mapStateToProps = (state) => {
  const healthInfo = userSelectors.getHealthInfo(state);

  return {
    healthInfo,
    chronicConditions: healthInfo?.diseases || [],
    isSubmittingUser: userSelectors.isSubmittingUser(state),
    hasChronicDiseases: [true, false].includes(healthInfo?.hasChronicDiseases)
      ? healthInfo.hasChronicDiseases
      : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHealthInfo: (userId) =>
    dispatch(userActions.fetchPatientHealthInfo(userId)),
  updateHealthProfile: (userId, payload) =>
    dispatch(userActions.updateHealthProfile(userId, payload)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  updateHealthProfile: (userId, payload) =>
    dispatchProps.updateHealthProfile(userId, {
      ...payload,
    }),
  ...stateProps,
  ...ownProps,
});

const Connected = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ChronicConditionsField);

export default forwardRef((props, ref) => (
  <Connected {...props} forwardedRef={ref} />
));
