import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as USER_MANAGEMENT } from './constants';

export const DEACTIVATE_ACCOUNT = `${DOMAIN}/${USER_MANAGEMENT}/DEACTIVATE_ACCOUNT`;
export const CREATE_ALMAOS_USER = `${DOMAIN}/${USER_MANAGEMENT}/CREATE_ALMAOS_USER`;
export const RESET_PASSWORD = `${DOMAIN}/${USER_MANAGEMENT}/RESET_PASSWORD`;

export const UPDATE_ALMAOS_USER = `${DOMAIN}/${USER_MANAGEMENT}/UPDATE_ALMAOS_USER`;
export const GET_ALMAOS_USER = `${DOMAIN}/${USER_MANAGEMENT}/GET_ALMAOS_USER`;
export const SET_SUBMITTING_ALMAOS_USER = `${DOMAIN}/${USER_MANAGEMENT}/SET_SUBMITTING_ALMAOS_USER`;
export const SET_FETCHING_ALMAOS_USER = `${DOMAIN}/${USER_MANAGEMENT}/SET_FETCHING_ALMAOS_USER`;
export const ALMAOS_USER_RECEIVED = `${DOMAIN}/${USER_MANAGEMENT}/ALMAOS_USER_RECEIVED`;
