/* Member domain constants */
export const PACKAGE_NAME = 'Member';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  OWNER_ID: {
    name: 'ownerId',
  },
  RELATIONSHIP_WITH_OWNER: {
    name: 'relationshipWithOwner',
  },
  DELETED_FROM_OWNER_ACCOUNT: {
    name: 'deletedFromOwnerAccount',
  },
};
