import { all, call, takeLatest, put } from 'redux-saga/effects';
import get from 'lodash/get';
import httpClient from 'core/httpClient';

import { getPaymentServiceEndpoint } from 'core/endpoint';
import { getPaymentBaseUrl } from './utils'; // Remove ./utils on using the new create payment from payment service
import * as actions from './actions';
import * as types from './actionTypes';
import {
  ENDPOINT,
  PAYMENT_TRANSACTION_ENDPOINT,
  CREATE_PAYMENT_LINK_ENDPOINT,
} from './constants';

export function* createPaymentTransaction({ payload }) {
  const createPaymentTransactionEndPoint = [
    ENDPOINT,
    CREATE_PAYMENT_LINK_ENDPOINT,
  ].join('/');
  const response = yield call(
    httpClient.post,
    createPaymentTransactionEndPoint,
    payload,
    {
      baseURL: getPaymentBaseUrl(
        payload.referenceType || payload.paymentTransaction.referenceType
      ),
    }
  );

  yield put(actions.paymentTransactionCreated(response?.data));
}

export function* getPaymentTransactionByReference({
  referenceId,
  referenceType,
}) {
  const response = yield call(
    httpClient.get,
    PAYMENT_TRANSACTION_ENDPOINT(referenceId, referenceType),
    {
      baseURL: getPaymentServiceEndpoint(),
    }
  );
  yield put(actions.paymentTransactionReceived(response.data));
}

export function* cancelPaymentTransaction({ referenceId, referenceType }) {
  try {
    const response = yield call(
      httpClient.delete,
      PAYMENT_TRANSACTION_ENDPOINT(referenceId, referenceType),
      {
        baseURL: getPaymentServiceEndpoint(),
      }
    );
    yield put(actions.cancelPaymentTransactionResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.cancelPaymentTransactionResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export default function* paymentModelSaga() {
  yield all([
    takeLatest(types.CREATE_PAYMENT_TRANSACTION, createPaymentTransaction),
    takeLatest(
      types.GET_PAYMENT_TRANSACTION_BY_REFERENCE,
      getPaymentTransactionByReference
    ),
    takeLatest(types.CANCEL_PAYMENT_TRANSACTION, cancelPaymentTransaction),
  ]);
}
