import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as MEETING } from './constants';

export const CREATE_MEETING = `${MODEL}/${MEETING}/CREATE_MEETING`;
export const MEETING_CREATION_RESPONDED = `${MODEL}/${MEETING}/MEETING_CREATION_RESPONDED`;

export const UPDATE_MEETING = `${MODEL}/${MEETING}/UPDATE_MEETING`;
export const MEETING_UPDATE_RESPONDED = `${MODEL}/${MEETING}/MEETING_UPDATE_RESPONDED`;

export const FETCH_DOCTOR_MEETINGS = `${MODEL}/${MEETING}/FETCH_DOCTOR_MEETINGS`;
export const DOCTOR_MEETINGS_FETCH_RESPONDED = `${MODEL}/${MEETING}/DOCTOR_MEETINGS_FETCH_RESPONDED`;

export const DELETE_MEETING = `${MODEL}/${MEETING}/DELETE_MEETING`;
export const MEETING_DELETE_RESPONDED = `${MODEL}/${MEETING}/MEETING_DELETE_RESPONDED`;
