import React from 'react';
import MuiIconButton from '@mui/material/IconButton';

const IconButton = ({ size = 'medium', children, ...rest }) => (
  <MuiIconButton size={size} {...rest}>
    {children}
  </MuiIconButton>
);

export default IconButton;
