import * as types from './actionTypes';

export const createEligibilityRequest = (payload) => ({
  type: types.CREATE_ELIGIBILITY_REQUEST,
  payload,
});

export const getEligibilityRequests = (referenceId, referenceType) => ({
  type: types.GET_ELIGIBILITY_REQUESTS,
  referenceId,
  referenceType,
});

export const eligibilityRequestsReceived = (eligibilityRequests) => ({
  type: types.ELIGIBILITY_REQUESTS_RECEIVED,
  eligibilityRequests,
});

export const setFetchingEligibility = (isFetching) => ({
  type: types.SET_FETCHING_ELIGIBILITY,
  isFetching,
});

export const setSubmittingEligibility = (isSubmitting) => ({
  type: types.SET_SUBMITTING_ELIGIBILITY,
  isSubmitting,
});

export const clear = () => ({
  type: types.CLEAR,
});
