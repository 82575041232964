import pluralize from 'pluralize';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import lowerCase from 'lodash/lowerCase';

import * as getters from './getters';
import {
  USE_UNIT_TYPES,
  QUANTITY_UNIT_TYPES,
  DURATION_UNIT_TYPES,
  FREQUENCY_UNIT_TYPES,
} from './constants';

export const getKey = (map, label) =>
  values(map).find((item) => item.label === label)?.key;

export const toReadableValueUnit = (value, unit, type) => {
  return value && type[unit]
    ? `${value} ${
        type[unit].pluralable
          ? lowerCase(pluralize(type[unit].label, Number(value)))
          : type[unit].label
      }`
    : value || '-';
};

export const toReadableQuantity = (value, unit) => {
  return toReadableValueUnit(value, unit, QUANTITY_UNIT_TYPES);
};

export const toReadableDuration = (value, unit) => {
  return toReadableValueUnit(value, unit, DURATION_UNIT_TYPES);
};

export const toReadableUse = (value, unit) => {
  return toReadableValueUnit(value, unit, USE_UNIT_TYPES);
};

export const toReadableFrequency = (value, unit) => {
  return value && FREQUENCY_UNIT_TYPES[unit]
    ? `${Number(value) === 1 ? 'once' : `${value} times`} ${lowerCase(
        FREQUENCY_UNIT_TYPES[unit].label
      )}`
    : value || '-';
};

export const buildInstructions = ({
  useValue,
  useUnit,
  frequencyValue,
  frequencyUnit,
  durationValue,
  durationUnit,
  whenHow,
  isLong,
}) => {
  return `${isLong ? 'Take' : ''} ${toReadableUse(useValue, useUnit)}, ${
    !Number(frequencyValue)
      ? ''
      : `${toReadableFrequency(frequencyValue, frequencyUnit)},`
  } ${lowerCase(whenHow)}${
    isLong && durationValue
      ? `, for ${toReadableDuration(durationValue, durationUnit)}`
      : ''
  }`;
};

export const getName = (medicine) => {
  const strength = getters.getStrength(medicine);
  const packageName = getters.getPackageName(medicine);
  const genericName = getters.getGenericName(medicine);
  const packageSizeSummary = getters.getPackageSizeSummary(medicine);

  return uniqBy([packageName, genericName, strength, packageSizeSummary])
    .filter(Boolean)
    .join(' - ');
};
