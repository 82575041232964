import * as types from './actionTypes';

export const fetchMembers = (ownerId, membersUserIds) => ({
  type: types.FETCH_MEMBERS,
  ownerId,
  membersUserIds,
});

export const membersReceived = (members) => ({
  type: types.MEMBERS_RECEIVED,
  members,
});

export const setFetchingMembers = (isFetchingMembers) => ({
  type: types.SET_FETCHING_MEMBERS,
  isFetchingMembers,
});

export const fetchMembersHealthProfiles = (ownerId, membersUserIds) => ({
  type: types.FETCH_MEMBERS_HEALTH_PROFILES,
  ownerId,
  membersUserIds,
});

export const membersHealthProfilesReceived = (membersHealthProfiles) => ({
  type: types.MEMBERS_HEALTH_PROFILES_RECEIVED,
  membersHealthProfiles,
});

export const setFetchingMembersHealthProfiles = (
  isFetchingMembersHealthProfiles
) => ({
  type: types.SET_FETCHING_MEMBERS_HEALTH_PROFILES,
  isFetchingMembersHealthProfiles,
});

export const fetchBasicMembers = (ownerId) => ({
  type: types.FETCH_BASIC_MEMBERS,
  ownerId,
});

export const basicMembersReceived = (members) => ({
  type: types.BASIC_MEMBERS_RECEIVED,
  members,
});

export const setFetchingBasicMembers = (isFetchingBasicMembers) => ({
  type: types.SET_FETCHING_BASIC_MEMBERS,
  isFetchingBasicMembers,
});

export const createMember = (ownerId, member) => ({
  type: types.CREATE_MEMBER,
  ownerId,
  member,
});

export const memberCreated = (member) => ({
  type: types.MEMBER_CREATED,
  member,
});

export const setSubmittingMember = (isSubmittingMember) => ({
  type: types.SET_SUBMITTING_MEMBER,
  isSubmittingMember,
});

export const deleteMember = (memberId) => ({
  type: types.DELETE_MEMBER,
  memberId,
});

export const memberDeleted = (memberId) => ({
  type: types.MEMBER_DELETED,
  memberId,
});

export const setDeletingMember = (isDeletingMember) => ({
  type: types.SET_DELETING_MEMBER,
  isDeletingMember,
});

export const undeleteMember = (memberId) => ({
  type: types.UNDELETE_MEMBER,
  memberId,
});

export const memberUpdated = (memberId, member) => ({
  type: types.MEMBER_UPDATED,
  memberId,
  member,
});

export const updateMemberUserProfile = (
  memberUserId,
  payload,
  successCallback
) => ({
  type: types.UPDATE_MEMBER_USER_PROFILE,
  memberUserId,
  payload,
  successCallback,
});

export const updateMemberHealthProfile = (
  memberUserId,
  payload,
  successCallback
) => ({
  type: types.UPDATE_MEMBER_HEALTH_PROFILE,
  memberUserId,
  payload,
  successCallback,
});

export const memberHealthProfileUpdated = (
  memberUserId,
  memberHealthProfile
) => ({
  type: types.MEMBER_HEALTH_PROFILE_UPDATED,
  memberUserId,
  memberHealthProfile,
});
