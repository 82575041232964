const ROLES = {
  ALL: 'all',
  USER: 'user',
  ADMIN: 'admin',
  DOCTOR: 'doctor',
  PROMOTOR: 'promoter',
  CLINIC_MANAGER: 'ClinicManager',
  SCHEDULE_MANAGER: 'ScheduleManager',
  SUPER_ADMIN: 'SuperAdmin',
  LOGISTICS_ADMIN: 'LogisticsAdmin',
  PHARMACIST: 'Pharmacist',
  PATIENT_SUCCESS: 'PatientSuccess',
  MARKETING_MANAGER: 'MarketingManager',
};

export default ROLES;
