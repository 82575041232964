import * as types from './actionTypes';

export const updateLabRequest = (id, payload, isUpdateLabResults) => ({
  type: types.UPDATE_LAB_REQUEST,
  payload,
  id,
  isUpdateLabResults,
});

export const patchLabRequest = (id, payload) => ({
  type: types.PATCH_LAB_REQUEST,
  payload,
  id,
});

export const createLabRequest = (payload, redirect) => ({
  type: types.CREATE_LAB_REQUEST,
  payload,
  redirect,
});

export const createELabRequestDocument = (payload) => ({
  type: types.CREATE_E_LAB_REQUEST_DOCUMENT,
  payload,
});

export const getLabRequestById = (id) => ({
  type: types.GET_LAB_REQUEST_BY_ID,
  id,
});

export const fetchLabPackages = () => ({
  type: types.FETCH_LAB_PACKAGES,
});

export const fetchPatientELabRequest = (userId, consultationId, callbacks) => ({
  type: types.FETCH_PATIENT_E_LAB_REQUEST,
  userId,
  callbacks,
  consultationId,
});

export const createPatientELabRequest = (
  userId,
  consultationId,
  payload,
  successCallback
) => ({
  type: types.CREATE_PATIENT_E_LAB_REQUEST,
  userId,
  payload,
  consultationId,
  successCallback,
});

export const updatePatientELabRequest = (
  userId,
  consultationId,
  payload,
  successCallback
) => ({
  type: types.UPDATE_PATIENT_E_LAB_REQUEST,
  userId,
  payload,
  consultationId,
  successCallback,
});

export const deletePatientELabRequest = (userId, consultationId) => ({
  type: types.DELETE_PATIENT_E_LAB_REQUEST,
  userId,
  consultationId,
});

export const submitPatientELabRequests = (consultationId, payload) => ({
  type: types.SUBMIT_PATIENT_E_LAB_REQUESTS,
  consultationId,
  payload,
});

export const createPatientLabRequestDocument = (
  payload,
  patientId,
  successCallback
) => ({
  type: types.CREATE_PATIENT_LAB_REQUEST_DOCUMENT,
  payload,
  patientId,
  successCallback,
});

export const clearPatientsELabRequests = () => ({
  type: types.CLEAR_PATIENTS_E_LAB_REQUESTS,
});

export const getLabRequestByConsultationId = (consultationId) => ({
  type: types.GET_LAB_REQUEST_BY_CONSULTATION_ID,
  consultationId,
});

export const labRequestReceived = (labRequest, id) => ({
  type: types.LAB_REQUEST_RECEIVED,
  labRequest,
  id,
});

export const labRequestUpdated = (labRequest) => ({
  type: types.LAB_REQUEST_UPDATED,
  labRequest,
});

export const eLabRequestDocumentCreated = (document) => ({
  type: types.E_LAB_REQUEST_DOCUMENT_CREATED,
  document,
});

export const labPackagesReceived = (labPackages) => ({
  type: types.LAB_PACKAGES_RECEIVED,
  labPackages,
});

export const labPackageCreated = (labPackage) => ({
  type: types.LAB_PACKAGE_CREATED,
  labPackage,
});

export const patientELabRequestReceived = (userId, eLabRequest) => ({
  type: types.PATIENT_E_LAB_REQUEST_RECEIVED,
  eLabRequest,
  userId,
});

export const patientELabRequestSubmitted = (userId, eLabRequest) => ({
  type: types.PATIENT_E_LAB_REQUEST_SUBMITTED,
  eLabRequest,
  userId,
});

export const patientELabRequestDeleted = (userId) => ({
  type: types.PATIENT_E_LAB_REQUEST_DELETED,
  userId,
});

export const patientELabRequestsSubmitted = (submissionResponse) => ({
  type: types.PATIENT_E_LAB_REQUESTS_SUBMITTED,
  submissionResponse,
});

export const patientLabRequestDocumentCreated = (document, patientId) => ({
  type: types.PATIENT_LAB_REQUEST_DOCUMENT_CREATED,
  document,
  patientId,
});

export const labRequestListReceived = (labTests) => ({
  type: types.CONSULTATION_LABS_RECEIVED,
  labTests,
});

export const setFetchingLabRequest = (isFetching) => ({
  type: types.SET_FETCHING_LAB_REQUEST,
  isFetching,
});

export const setFetchingLabDocuments = (isFetchingDocuments) => ({
  type: types.SET_FETCHING_DOCUMENTS,
  isFetchingDocuments,
});

export const setFetchingLabRequestOthers = (isFetching) => ({
  type: types.SET_FETCHING_LAB_REQUEST_OTHERS,
  isFetching,
});

export const setSubmittingLabRequest = (isSubmitting) => ({
  type: types.SET_SUBMITTING_LAB_REQUEST,
  isSubmitting,
});

export const setSubmittingPatientELabRequests = (
  isSubmittingPatientELabRequests
) => ({
  type: types.SET_SUBMITTING_PATIENT_E_LAB_REQUESTS,
  isSubmittingPatientELabRequests,
});

export const createPatientsLabRequestDocument = (labId, payload) => ({
  type: types.CREATE_PATIENTS_LAB_TEST_DOCUMENTS,
  labId,
  payload,
});
