import * as types from './actionTypes';

export const updateSubscription = (id, payload) => ({
  type: types.UPDATE_SUBSCRIPTION,
  id,
  payload,
});

export const getSubscription = (userId) => ({
  type: types.GET_SUBSCRIPTION,
  userId,
});

export const cancelSubscription = (userId) => ({
  type: types.CANCEL_SUBSCRIPTION,
  userId,
});

export const subscriptionReceived = (subscription) => ({
  type: types.SUBSCRIPTION_RECEIVED,
  subscription,
});

export const setFetching = (isFetching) => ({
  type: types.SET_FETCHING,
  isFetching,
});

export const setSubmitting = (isSubmitting) => ({
  type: types.SET_SUBMITTING,
  isSubmitting,
});

export const clear = () => ({
  type: types.CLEAR,
});

export const createSubscription = (userId, payload) => ({
  type: types.CREATE_SUBSCRIPTION,
  userId,
  payload,
});

export const subscriptionCreationResponded = (
  isSubscriptionCreated,
  response
) => ({
  type: types.SUBSCRIPTION_CREATION_RESPONDED,
  isSubscriptionCreated,
  response,
});

export const fetchSubscriptionPlans = () => ({
  type: types.FETCH_SUBSCRIPTION_PLANS,
});

export const subscriptionPlansReceived = (subscriptionPlans) => ({
  type: types.SUBSCRIPTION_PLANS_RECEIVED,
  subscriptionPlans,
});

export const setFetchingSubscriptionPlans = (isFetchingSubscriptionPlans) => ({
  type: types.SET_FETCHING_SUBSCRIPTION_PLANS,
  isFetchingSubscriptionPlans,
});
