import React, { useState } from 'react';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import logger from 'core/logger';
import { Button, Message } from 'design-system';

import { createPdfFile, downloadPdfFile } from '../utils';

const DownloadMultiplePdfButton = ({
  payloads,
  resource,
  fileNames,
  children,
  iconProps = {
    color: 'secondary',
  },
  sx,
  ...rest
}) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Button
      spinning={isLoading}
      startIcon={<PrintOutlinedIcon {...iconProps} />}
      onClick={(e) => {
        e.stopPropagation();
        setLoading(true);

        Promise.allSettled(
          payloads.map((payload, idx) =>
            createPdfFile(resource, fileNames[idx], payload)
          )
        )
          .then((promises) => {
            promises.forEach((promise, idx) => {
              downloadPdfFile(promise.value, fileNames[idx]);
            });

            if (promises.some((promise) => promise.status === 'rejected')) {
              logger.error('Some pdf files could not be loaded');
              Message.error(
                'An error occurred during downloading some files. Please try again later or report the issue to the support team.'
              );
            }
          })
          .finally(() => setLoading(false));
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default DownloadMultiplePdfButton;
