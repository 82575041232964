import * as types from './actionTypes';

export const sendNotification = (
  message,
  sendPushNotification,
  userId,
  referenceType,
  referenceId
) => ({
  type: types.SEND_NOTIFICATION,
  userId,
  message,
  referenceId,
  referenceType,
  sendPushNotification,
});

export const sendBulkNotifications = (payload, params) => ({
  type: types.SEND_BULK_NOTIFICATIONS,
  payload,
  params,
});

export const notificationSent = () => ({
  type: types.NOTIFICATION_SENT,
});

export const bulkNotificationsSent = () => ({
  type: types.BULK_NOTIFICATIONS_SENT,
});

export const sendGeneralNotification = (key, userId, referenceId) => ({
  type: types.SEND_GENERAL_NOTIFICATION,
  key,
  userId,
  referenceId,
});
