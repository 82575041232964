import get from 'lodash/get';
import { createSelector } from 'reselect';

export const doctorSelectors = (state) => get(state, 'domain.doctor', {});

export const getDoctors = createSelector(doctorSelectors, (results) =>
  get(results, 'list')
);

export const getDoctor = createSelector(doctorSelectors, (results) =>
  get(results, 'view')
);

export const isFetching = createSelector(doctorSelectors, (results) =>
  get(results, 'isFetching')
);

export const isSubmitting = createSelector(doctorSelectors, (results) =>
  get(results, 'isSubmitting')
);
