import * as types from './actionTypes';

const initialState = {
  insuranceProviderList: [],
};

const insuranceProvidersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INSURANCE_PROVIDER_LIST_RECEIVED: {
      return {
        ...state,
        insuranceProviderList: [...(action.insuranceProviderList || [])],
      };
    }
    default:
      return state;
  }
};

export default insuranceProvidersReducer;
