export const PACKAGE_NAME = 'subscription';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  USER_ID: {
    name: 'userId',
  },
  STATUS: {
    name: 'status',
  },
  STAGE: {
    name: 'stage',
  },
  PLAN: {
    name: 'plan',
  },
  PLAN_ID: {
    name: 'planId',
  },
  PAYMENT_TRANSACTION: {
    name: 'paymentTransaction',
  },
};

export const STATUSES = {
  PENDING_PAYMENT: {
    key: 'PENDING_PAYMENT',
    label: 'Pending payment',
    color: 'warning',
  },
  PAID: {
    key: 'PAID',
    label: 'Paid',
    color: 'success',
  },
  CANCELLED: {
    key: 'CANCELLED',
    label: 'Cancelled',
    color: 'error',
  },
};

export const STAGES = {
  PAID_NOT_USED: {
    key: 'PAID_NOT_USED',
    label: 'Paid not used',
    color: 'warning',
  },
  FIRST_CONSULTATION_BOOKED: {
    key: 'FIRST_CONSULTATION_BOOKED',
    label: 'First consultation booked',
    color: 'primary',
  },
  FIRST_CONSULTATION_COMPLETED: {
    key: 'FIRST_CONSULTATION_COMPLETED',
    label: 'First consultation completed',
    color: 'success',
  },
  LAB_TEST_OUT: {
    key: 'LAB_TEST_OUT',
    label: 'Lab result out',
    color: 'warning',
  },
  INITIAL_ASSESSMENT_COMPLETED: {
    key: 'INITIAL_ASSESSMENT_COMPLETED',
    label: 'Initial assessment completed',
    color: 'success',
  },
};
