import keys from 'lodash/keys';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useRef, useState, useCallback } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { medicineConstants } from 'domain/medicine';
import { Form, Controller, formValidationFunctions } from 'design-system/form';

import {
  FIELDS,
  COMPLAINT_FIELDS,
  COMPLAINT_SEVERITY_BY_VALUE,
} from 'domain/doctorNotes/constants';
import {
  Grid,
  Radio,
  Popup,
  Select,
  Button,
  Divider,
  Tooltip,
  MenuItem,
  FormField,
  TextField,
  RadioGroup,
  FormHelperText,
  InputAdornment,
  FormControlLabel,
} from 'design-system';

import * as styles from '../../styles';
import { SECTIONS } from '../../constants';
import Section from '../DoctorNotesSection';

const { DURATION_UNIT_TYPES } = medicineConstants;

const ComplaintForm = ({
  // Owner's user ID
  ownerId,
  // Patient's user ID
  userId,
  // Relevant consultation ID
  consultationId,
  // On edit button click
  onEdit,
  // Flag whether the section's accordion is expanded/active
  expanded,
  // Flag whether the section is confirmed (Marked with Green icon)
  confirmed,
  // External logic on submit
  onSubmitted,
  // Default values coming from
  defaultValues,
  // Action to update/create complaint
  submitComplaintForm,
}) => {
  const formRef = useRef();

  const [isSaving, setSaving] = useState(false);
  const [isConfirmPopupOpen, setConfirmPopupOpen] = useState(false);
  const [pendingDeleteComplaintIndex, setPendingDeleteComplaintIndex] =
    useState();

  const handleAddAnotherComplaint = () => {
    const newComplaint = {
      id: uuidv4(), // Function to generate a random ID
      // other properties of the new line item
    }; // Define your new line item here

    const complaintsArray = formRef?.current
      ?.getMethods?.()
      .getValues('complaints');
    const updatedComplaints = complaintsArray
      ? [...complaintsArray, newComplaint]
      : [newComplaint];

    // Update the medicines field in the form
    formRef?.current?.getMethods?.().setValue('complaints', updatedComplaints);
  };

  const handleDeleteComplaint = (index) => {
    const complaints = formRef?.current?.getMethods?.().getValues('complaints');
    const { length } = complaints;

    // Ensure there is at least one item to delete
    if (length <= 1) {
      return;
    }

    // Use splice to remove the item at the specified index
    complaints.splice(index, 1);

    // Update the medicines field in the form with the modified array
    formRef?.current?.getMethods?.().setValue('complaints', complaints);
  };

  const onSubmit = useCallback(
    (formData) => {
      const complaintsPayload = formData.complaints.map((complaint) => {
        // If the value is string then it's retuned from the mapper of the Form and
        // Don't need to add the id then other wise it's the retuned Id from DB then add it.
        const id = typeof complaint.id !== 'string' ? complaint.id : undefined;
        const onsetPeriod = complaint[COMPLAINT_FIELDS.ONSET_PERIOD.key];
        const chiefComplaint = complaint[COMPLAINT_FIELDS.CHIEF_COMPLAINT.key];
        const bodySite = complaint[COMPLAINT_FIELDS.BODY_SITE.key];
        const severity = complaint[COMPLAINT_FIELDS.SEVERITY.key];
        const remarks = complaint[COMPLAINT_FIELDS.REMARKS.key];

        return {
          id,
          [COMPLAINT_FIELDS.CHIEF_COMPLAINT.key]: chiefComplaint,
          [COMPLAINT_FIELDS.ONSET_PERIOD.key]: onsetPeriod,
          [COMPLAINT_FIELDS.BODY_SITE.key]: bodySite,
          [COMPLAINT_FIELDS.SEVERITY.key]: severity,
          [COMPLAINT_FIELDS.REMARKS.key]: remarks,
        };
      });

      setSaving(true);
      submitComplaintForm(
        userId,
        consultationId,
        {
          [FIELDS.USER_ID.key]: userId,
          [FIELDS.OWNER_ID.key]: ownerId,
          [FIELDS.CONSULTATION_ID.key]: consultationId,
          [FIELDS.COMPLAINT.key]: complaintsPayload,
        },
        () => {
          onSubmitted(complaintsPayload);
          setSaving(false);
        }
      );
    },
    [userId, ownerId, onSubmitted, consultationId, submitComplaintForm]
  );

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={onSubmit}
        defaultValues={{
          complaints: defaultValues,
        }}
      >
        {({ control, watch }) => (
          <Section
            onEdit={onEdit}
            expanded={expanded}
            confirmed={confirmed}
            identifier={SECTIONS.COMPLAINT.key}
            title={SECTIONS.COMPLAINT.label}
            actions={
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                flexDirection={{
                  xs: 'column',
                  md: 'row',
                }}
              >
                <Grid item>
                  <Button
                    size="medium"
                    color="primary"
                    variant="text"
                    onClick={() => handleAddAnotherComplaint()}
                    startIcon={<AddCircleOutlineIcon fontSize="medium" />}
                  >
                    Add another
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: {
                      xs: '100%',
                      md: 'auto',
                    },
                  }}
                >
                  <Button
                    spinning={isSaving}
                    onClick={() => {
                      formRef?.current?.submit();
                    }}
                    sx={{
                      width: '100%',
                    }}
                  >
                    Save and continue
                  </Button>
                </Grid>
              </Grid>
            }
          >
            <Grid container column rowSpacing={3}>
              {watch('complaints', [
                {
                  id: uuidv4(),
                },
              ]).map((complaint, index) => (
                <Grid item key={complaint.id || uuidv4()}>
                  <Grid container columnSpacing={5}>
                    <Grid
                      item
                      container
                      rowSpacing={3}
                      sx={{
                        width: (theme) => ({
                          xs: '100%',
                          md: `calc(100% - ${theme.spacing(
                            7 /* Delete button width */ + 5 /* columnSpacing */
                          )})`,
                        }),
                      }}
                    >
                      {/* Chief Complaint */}
                      <FormField
                        xs={12}
                        required
                        label="CHIEF COMPLAINT"
                        field={
                          <Controller
                            name={`complaints[${index}].${COMPLAINT_FIELDS.CHIEF_COMPLAINT.key}`}
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                rows={3}
                                fullWidth
                                multiline
                                variant="filled"
                                value={field.value}
                                placeholder="Add chief complaint.."
                                onChange={field.onChange}
                                error={Boolean(error)}
                                helperText={error?.message ?? null}
                              />
                            )}
                            rules={{
                              validate: {
                                required: (value) => {
                                  if (!value || !value.trim()) {
                                    return 'Chief complaint is required';
                                  }
                                  return true; // Indicates validation success
                                },
                              },
                            }}
                          />
                        }
                      />
                      <Grid item container spacing={3}>
                        {/* Complaint Duration */}
                        <FormField
                          xs={12}
                          md={6}
                          lg={2}
                          required
                          label="DURATION"
                          field={
                            <Controller
                              name={`complaints[${index}].${COMPLAINT_FIELDS.ONSET_PERIOD.fields.VALUE.path}`}
                              control={control}
                              defaultValue={1}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  value={field.value}
                                  type="number"
                                  size="medium"
                                  variant="filled"
                                  min="1"
                                  placeholder="Duration of complaint"
                                  onChange={field.onChange}
                                  sx={styles.valueField}
                                  error={Boolean(error)}
                                  helperText={error?.message ?? null}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment
                                        position="end"
                                        sx={styles.unitField}
                                      >
                                        <Controller
                                          name={`complaints[${index}].${COMPLAINT_FIELDS.ONSET_PERIOD.fields.UNIT.path}`}
                                          control={control}
                                          defaultValue={
                                            DURATION_UNIT_TYPES.DAY.key
                                          }
                                          render={({ field: unitField }) => (
                                            <Select
                                              value={unitField.value}
                                              size="medium"
                                              color="standard"
                                              variant="filled"
                                              onChange={unitField.onChange}
                                              renderValue={(value) =>
                                                value
                                                  ? DURATION_UNIT_TYPES[value]
                                                      ?.label
                                                  : value
                                              }
                                            >
                                              {keys(DURATION_UNIT_TYPES).map(
                                                (item) => (
                                                  <MenuItem
                                                    value={item}
                                                    key={item}
                                                  >
                                                    {
                                                      DURATION_UNIT_TYPES[item]
                                                        .label
                                                    }
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          )}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                              rules={{
                                required: 'Complaint duration is required',
                                validate: {
                                  positive: (v) =>
                                    formValidationFunctions.positive(v),
                                },
                              }}
                            />
                          }
                        />
                        {/* Complaint Location */}
                        <FormField
                          xs={12}
                          md={6}
                          lg={2}
                          required
                          label="LOCATION"
                          field={
                            <Controller
                              name={`complaints[${index}].${COMPLAINT_FIELDS.BODY_SITE.key}`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <TextField
                                  fullWidth
                                  value={field.value}
                                  size="medium"
                                  variant="filled"
                                  placeholder="Location of complaint"
                                  onChange={field.onChange}
                                  error={Boolean(error)}
                                  helperText={error?.message ?? null}
                                />
                              )}
                              rules={{
                                validate: {
                                  required: (value) => {
                                    if (!value || !value.trim()) {
                                      return 'Complaint location is required';
                                    }
                                    return true; // Indicates validation success
                                  },
                                },
                              }}
                            />
                          }
                        />
                        {/* Severity */}
                        <FormField
                          xs={12}
                          lg={8}
                          required
                          label="SEVERITY (0 = No pain, 10 = Worst Pain Possible)"
                          field={
                            <Controller
                              name={`complaints[${index}].${COMPLAINT_FIELDS.SEVERITY.key}`}
                              control={control}
                              render={({ field, fieldState: { error } }) => (
                                <>
                                  <RadioGroup
                                    row
                                    value={field.value}
                                    aria-labelledby="complaint-severity-radio-buttons-group-label"
                                    name="complaint-severity-radio-buttons-group"
                                    onChange={field.onChange}
                                    sx={{
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    {[...Array(11).keys()].map(
                                      // Array from 0 to 10
                                      (severityValue) => (
                                        <Tooltip
                                          injectWrapper
                                          placement="top"
                                          title={
                                            COMPLAINT_SEVERITY_BY_VALUE[
                                              severityValue
                                            ].label
                                          }
                                        >
                                          <FormControlLabel
                                            value={severityValue}
                                            control={<Radio size="small" />}
                                            label={severityValue}
                                          />
                                        </Tooltip>
                                      )
                                    )}
                                  </RadioGroup>
                                  {error && (
                                    <FormHelperText error>
                                      {error?.message}
                                    </FormHelperText>
                                  )}
                                </>
                              )}
                              rules={{
                                required: 'Complaint severity is required',
                              }}
                            />
                          }
                        />
                      </Grid>
                      {/* Complaint Remarks */}
                      <FormField
                        xs={12}
                        label="REMARKS"
                        field={
                          <Controller
                            name={`complaints[${index}].${COMPLAINT_FIELDS.REMARKS.key}`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                rows={3}
                                fullWidth
                                multiline
                                variant="filled"
                                value={field.value}
                                placeholder="Add any remarks..."
                                onChange={field.onChange}
                              />
                            )}
                          />
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                          p: 0,
                          minWidth: 0,
                          height: '100%',
                          boxShadow: 'none',
                          width: (theme) => theme.spacing(7),
                          borderRadius: (theme) => theme.spacing(1),
                          backgroundColor: (theme) => theme.palette.grey[200],
                          '&:hover': {
                            backgroundColor: (theme) => theme.palette.grey[300],
                          },
                        }}
                        disabled={watch('complaints', [])?.length <= 1}
                        onClick={() => {
                          if (typeof complaint.id !== 'string') {
                            setPendingDeleteComplaintIndex(index);
                            setConfirmPopupOpen(true);
                          } else {
                            handleDeleteComplaint(index);
                          }
                        }}
                      >
                        <Tooltip placement="top" title="Delete complaint">
                          <DeleteIcon fontSize="small" color="error" />
                        </Tooltip>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      marginTop: '15px',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    item
                  >
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button
                          size="small"
                          color="error"
                          variant="text"
                          startIcon={<DeleteIcon />}
                          disabled={watch('complaints', [])?.length <= 1}
                          onClick={() => {
                            if (typeof complaint.id !== 'string') {
                              setPendingDeleteComplaintIndex(index);
                              setConfirmPopupOpen(true);
                            } else {
                              handleDeleteComplaint(index);
                            }
                          }}
                        >
                          Remove complaint
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {index < watch('complaints', []).length - 1 && (
                    <Grid item mt={3}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </Section>
        )}
      </Form>
      <Popup
        id={isConfirmPopupOpen ? 'complaint-delete-confirm' : undefined}
        open={isConfirmPopupOpen}
        title="Delete Complaint"
        primaryButtonTitle="No, continue editing"
        secondaryButtonTitle="Yes, delete complaint"
        onOk={() => {
          setConfirmPopupOpen(false);
          setPendingDeleteComplaintIndex();
        }}
        onCancel={() => {
          // Delete
          handleDeleteComplaint(pendingDeleteComplaintIndex);

          // Reset
          setConfirmPopupOpen(false);
          setPendingDeleteComplaintIndex();
        }}
      >
        Are you sure you want to delete this complaint?
      </Popup>
    </>
  );
};

export default ComplaintForm;
