import keys from 'lodash/keys';
import values from 'lodash/values';

import * as authConstants from 'core/auth/constants';

import ROLES from './roles';
import PERMISSIONS from './permissions';

export const getUserAllRoles = () => {
  const mainRole = localStorage.getItem(authConstants.LS_MAIN_ROLE);
  const userRoles =
    JSON.parse(localStorage.getItem(authConstants.LS_USER_ROLES) || '') || [];

  return new Set([...(mainRole ? [mainRole] : []), ...userRoles]);
};

export const isAllowed = (screen, section, component) => {
  const all = new Set([ROLES.ALL]);
  const role = getUserAllRoles();
  let object;

  if (screen && section && component) {
    object = PERMISSIONS[screen]?.sections[section]?.components[component];
  } else if (screen && section) {
    object = PERMISSIONS[screen]?.sections[section];
  } else {
    object = PERMISSIONS[screen];
  }

  if (object) {
    const roles = object.roles || new Set();
    const except = object.except || new Set();

    if (roles.size !== 0 && except.size !== 0) {
      if (roles.intersectsWith(all)) {
        return !except.intersectsWith(role);
      }

      return roles.intersectsWith(role) && !except.intersectsWith(role);
    }

    if (roles.size !== 0) {
      return roles.intersectsWith(all) || roles.intersectsWith(role);
    }

    if (except.size !== 0) {
      return !except.intersectsWith(role);
    }
  }

  return false;
};

export const getMainRoute = (roles) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const route in authConstants.MAIN_ROUTES) {
    if (authConstants.MAIN_ROUTES[route].roles.intersectsWith(roles)) {
      return route;
    }
  }
};

export const pathsMatch = (path1, path2) => {
  const trimmedPath1 = path1.trim().replace(/^\//g, '');
  const trimmedPath2 = path2.trim().replace(/^\//g, '');

  const path1Components = trimmedPath1.split('/');
  const path2Components = trimmedPath2.split('/');

  if (path1Components.length === path2Components.length) {
    const matched = path1Components.every((component, idx) => {
      return (
        component === path2Components[idx] ||
        path2Components[idx].startsWith(':')
      );
    });

    return matched;
  }

  return false;
};

/**
 * Checks if a route requires permissions or not
 *
 * @param {string} path
 * @returns boolean
 */
export const isPermissionFreeRoute = (path) => {
  const pathsRequiringPermissions = values(PERMISSIONS.routes.sections)
    .map((permission) => permission.path)
    .map((pth) => `/${pth}`);

  const pathRequiresPermission = pathsRequiringPermissions.some(
    (permissionPath) => {
      return pathsMatch(path, permissionPath);
    }
  );

  return !pathRequiresPermission;

  /* Alternative solution */
  // const allowedPaths = ['/', '/login'];
  // // Remove white spaces and initial slash
  // const trimmedPath = path.trim().replace(/^\//g, '');

  // if (allowedPaths.includes(`/${trimmedPath}`)) {
  //   return true;
  // }

  // return false;
};

/**
 * Returns a string representing a permission key found in keys of PERMISSIONS.routes.sections
 *
 * @param {string} path
 * @returns string
 */
export const getRoutePermissionKey = (path) => {
  let routePermissionKey;
  // Remove white spaces and initial slash
  const trimmedPath = path.trim().replace(/^\//g, '');
  const routePermissions = PERMISSIONS.routes.sections;

  keys(routePermissions).some((permissionKey) => {
    const permission = routePermissions[permissionKey];

    if (
      trimmedPath === permission.path ||
      pathsMatch(trimmedPath, permission.path) ||
      trimmedPath === permissionKey
    ) {
      routePermissionKey = permissionKey;
      return true;
    }

    return false;
  });

  return routePermissionKey;
};

/**
 * Checks permissions of a path. Returns true if allowed, false otherwise
 *
 * @param {string} path
 * @returns boolean
 */
export const isAllowedRoute = (path) => {
  const routePermissionKey = getRoutePermissionKey(path);

  return routePermissionKey && isAllowed('routes', routePermissionKey);
};

export function* navigateToMainPage() {
  /**
   * Distinguish between a path and a href.
   * Path is the key that we check permissions upon.
   * Href is where we want to redirect after checking permissions. It includes path + search params + hash params.
   */
  const currentPath = window.location.pathname;
  const currentHref = window.location.href.replace(window.location.origin, '');
  const preLoginHref =
    localStorage.getItem(authConstants.LS_PRE_LOGIN_PATH) || '';
  const preLoginPath = (() => {
    if (preLoginHref) {
      try {
        return preLoginHref.split('?')[0].split('#')[0];
      } catch (_e) {
        return preLoginHref;
      }
    } else {
      return '';
    }
  })();

  /**
   * If prelogin path exists and it requires permission check
   * and it's not the same path we are in right now,
   * check permission
   */
  if (
    preLoginPath &&
    !isPermissionFreeRoute(preLoginPath) &&
    preLoginPath !== currentPath
  ) {
    const isAllowedToView = yield isAllowedRoute(preLoginPath);

    if (isAllowedToView) return preLoginHref;
  }

  /**
   * If the current path requires permission check,
   * check permission
   */
  if (!isPermissionFreeRoute(currentPath)) {
    const isAllowedToView = yield isAllowedRoute(currentPath);

    if (isAllowedToView) return currentHref;
  }

  /**
   * If none of prelogin path or current path were allowed,
   * then we fallback to the default route of each user role
   */
  const roles = yield getUserAllRoles();
  const path = yield getMainRoute(roles);

  return `/${path}`;
}
