/* Comment model constants */
export const PACKAGE_NAME = 'Comment';
export const ENDPOINT = '/comments';

const defaultParams = {
  limit: 25,
  offset: 0,
  sort: 'id,DESC',
};

export const COMMENTS_PARAMS = (type, id) => {
  const params = new URLSearchParams(defaultParams);

  params.append('filter', `referenceType||$eq||${type}`);
  params.append('filter', `referenceId||$eq||${id}`);

  return params;
};

export const FIELDS = {
  ID: {
    name: 'id',
  },
  CONTENT: {
    name: 'comment',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  CREATED_BY_USER: {
    name: 'createdByUser',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  REFERENCE_TYPE: {
    name: 'referenceType',
  },
  REFERENCE_ID: {
    name: 'referenceId',
  },
};
