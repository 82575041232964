import * as types from './actionTypes';

export const fetchDoctors = () => ({
  type: types.FETCH_DOCTORS,
});

export const fetchDrivers = () => ({
  type: types.FETCH_DRIVERS,
});

export const fetchUser = (id) => ({
  type: types.FETCH_USER,
  id,
});

export const createUser = (payload) => ({
  type: types.CREATE_USER,
  payload,
});

export const fetchUserAddresses = (id) => ({
  type: types.FETCH_USER_ADDRESSES,
  id,
});

export const startIdentityExtraction = (documents) => ({
  type: types.START_IDENTITY_EXTRACTION,
  documents,
});

export const cancelIdentityExtraction = (jobs) => ({
  type: types.CANCEL_IDENTITY_EXTRACTION,
  jobs,
});

export const doctorsFetchResponded = (ok, response) => ({
  type: types.DOCTORS_FETCH_RESPONDED,
  ok,
  response,
});

export const driversFetchResponded = (ok, response) => ({
  type: types.DRIVERS_FETCH_RESPONDED,
  ok,
  response,
});

export const pharmacistsFetchResponded = (ok, response) => ({
  type: types.PHARMACISTS_FETCH_RESPONDED,
  ok,
  response,
});

export const fetchPharmacists = () => ({
  type: types.FETCH_PHARMACISTS,
});

export const userReceived = (user) => ({
  type: types.USER_RECEIVED,
  user,
});

export const userCreationResponded = (ok, response) => ({
  type: types.USER_CREATION_RESPONDED,
  response,
  ok,
});

export const fetchPatientHealthInfo = (userId) => ({
  type: types.FETCH_PATIENT_HEALTH_INFO,
  userId,
});

export const patientHealthInfoReceived = (patient) => ({
  type: types.PATIENT_HEALTH_INFO_RECEIVED,
  patient,
});

export const updateUser = (id, payload) => ({
  type: types.UPDATE_USER_PROFILE,
  id,
  payload,
});

export const userUpdateResponded = (ok, response) => ({
  type: types.USER_UPDATE_RESPONDED,
  response,
  ok,
});

export const updateHealthProfile = (id, payload) => ({
  type: types.UPDATE_USER_HEALTH_PROFILE,
  id,
  payload,
});

export const updateHealthProfileResponded = (ok, response) => ({
  type: types.UPDATE_HEALTH_PROFILE_RESPONDED,
  response,
  ok,
});

export const identityExtractionResponded = (ok, response) => ({
  type: types.IDENTITY_EXTRACTION_RESPONDED,
  response,
  ok,
});

export const identityExtractionJobsReceived = (jobs) => ({
  type: types.IDENTITY_EXTRACTION_JOBS_RECEIVED,
  jobs,
});

export const userAddressesReceived = (addresses) => ({
  type: types.USER_ADDRESSES_RECEIVED,
  addresses,
});
