import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';

import { userGetters } from 'domain/user';
import { authSelectors } from 'domain/auth';
import { UserSearch } from 'presentation/user';
import PatientInfo from 'presentation/components/PatientInfo';
import { CreateUserModal } from 'presentation/user/components';
import {
  ORDER_FIELDS as FIELDS,
  orderConstants as constants,
} from 'domain/order';
import {
  Card,
  Grid,
  Button,
  Select,
  Switch,
  MenuItem,
  PageTitle,
  TextField,
  FileInput,
  Typography,
  CardHeader,
  CardContent,
  RequiredMark,
  LinearProgress,
  Tooltip,
} from 'design-system';
import { isAllowed } from 'core/permission';

const { CHANNELS } = constants;

const NewOrder = ({
  user,
  tenants,
  fetchUser,
  clearUser,
  createOrder,
  isSubmitting,
  isFetchingUser,
}) => {
  const { isClinicManager } = useSelector(authSelectors.getRole);
  const defaultTenant = useSelector(authSelectors.getSelectedActiveTenant);

  const [channel, setChannel] = useState();
  const [tenant, setTenant] = useState(defaultTenant);
  const [prescriptions, setPrescriptions] = useState([]);
  const [consultationId, setConsultationId] = useState(0);
  const [isGenericMedicine, setGenericMedicine] = useState(true);
  const [isShowPrescriptions, setShowPrescriptions] = useState(true);
  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);
  const [createUserModalKey, setCreateUserModalKey] = useState(Date.now());
  const [isUserCreationModalOpen, setUserCreationModalOpen] = useState(false);

  useEffect(() => {
    clearUser();
  }, []);

  useEffect(() => {
    if (isClinicManager) setChannel(CHANNELS.PARTNER_CLINIC.key);
  }, []);

  useEffect(() => {
    if (channel && tenant && !isEmpty(user) && prescriptions.length > 0) {
      setSubmitButtonEnabled(true);
    } else if (
      !isAllowed('newOrder', 'submitButton') &&
      prescriptions.length > 0
    ) {
      setSubmitButtonEnabled(true);
    } else {
      setSubmitButtonEnabled(false);
    }
  }, [user, channel, prescriptions]);
  // Although allFiles is not used, it is important to pass it to this method
  const onFilesUploaded = (allFiles, newFiles) => {
    setPrescriptions(newFiles);
  };

  const onSubmit = () => {
    const payload = {
      [FIELDS.PRESCRIPTIONS.name]: prescriptions,
      [FIELDS.USER_ID.name]: userGetters.getId(user),
      [FIELDS.TENANT_KEY.name]: tenant,
      [FIELDS.CONSULTATION_ID.name]: consultationId
        ? Number(consultationId)
        : 0,
      [FIELDS.IS_GENERIC_MEDICINE.name]: isGenericMedicine,
      [FIELDS.CHANNEL.name]: channel,
      [FIELDS.IS_PRESCRIPTION_SHOWN_TO_PATIENT.name]: isShowPrescriptions,
    };

    createOrder(payload);
  };

  return (
    <>
      <PageTitle
        title="New Order"
        action={
          <Button
            variant="filled"
            onClick={onSubmit}
            spinning={isSubmitting}
            disabled={!isSubmitButtonEnabled}
          >
            Create order
          </Button>
        }
      />
      <Grid
        container
        spacing={3}
        row
        alignItems="stretch"
        justifyContent="left"
      >
        {isAllowed('orderList', 'searchByPhone') && (
          <Grid item xs={6}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent
                noPadding
                sx={{
                  height: '100%',
                }}
              >
                <Grid container column py={2}>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">
                      Search by phone number or name
                    </Typography>
                  </Grid>
                  <Grid item container spacing={1} sx={{ mb: 1, px: 2 }}>
                    <Grid item xs={7}>
                      <UserSearch
                        size="large"
                        onUserSelect={(selectedPatient) => {
                          const userId = userGetters.getId(selectedPatient);

                          if (userId) {
                            fetchUser(userId);
                          } else {
                            clearUser();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        variant="text"
                        onClick={() => {
                          setCreateUserModalKey(Date.now());
                          setUserCreationModalOpen(true);
                        }}
                      >
                        Or create a new user
                      </Button>
                    </Grid>
                  </Grid>
                  {!isEmpty(user) && (
                    <Grid item container column>
                      <PatientInfo
                        user={user}
                        isFetching={isFetchingUser}
                        config={{
                          fields: {
                            identity: {
                              show: false,
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}
                  {/* TODO: unify add/selecting new user for */}
                  {isEmpty(user) && (
                    <Grid
                      item
                      container
                      justifyContent="center"
                      loading={isFetchingUser}
                    >
                      <PersonAddRoundedIcon
                        sx={{
                          color: (theme) => theme.palette.secondary.tint,
                          fontSize: '20rem',
                        }}
                      />
                    </Grid>
                  )}
                  {isFetchingUser && <LinearProgress />}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item xs={isAllowed('orderList', 'searchByPhone') ? 6 : 12}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardHeader title="Order Details" sx={{ pb: 0 }} />
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              <Grid container column p={2}>
                {/* Source/ Channel */}
                {isAllowed('newOrder', 'signupSource') && (
                  <>
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="p4">SOURCE (CHANNEL)</Typography>
                      <RequiredMark />
                    </Grid>
                    <Grid item sx={{ mb: 3 }}>
                      <Select
                        fullWidth
                        size="large"
                        soak="light"
                        variant="filled"
                        value={channel}
                        disabled={isFetchingUser}
                        placeholder="Select channel ..."
                        onChange={(event) => {
                          setChannel(CHANNELS[event.target.value]?.key);
                        }}
                        renderValue={(value) =>
                          value ? CHANNELS[value]?.label : value
                        }
                      >
                        {keys(CHANNELS).map((item) => (
                          <MenuItem value={item} key={item}>
                            {CHANNELS[item].label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
                {/* Branch */}
                {isAllowed('common', 'tenantFilter') && (
                  <>
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="p4">BRANCH</Typography>
                      <RequiredMark />
                    </Grid>
                    <Grid item sx={{ mb: 3 }}>
                      <Select
                        fullWidth
                        size="large"
                        soak="light"
                        value={tenant}
                        variant="filled"
                        disabled={isFetchingUser}
                        onChange={(event) => {
                          setTenant(event.target.value);
                        }}
                      >
                        {(tenants || []).map((option) => (
                          <MenuItem value={option.id} key={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </>
                )}
                {/* Generic medicine */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">GENERIC MEDICINE</Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Switch
                    label="Is generic medicine"
                    disabled={isFetchingUser}
                    onChange={() => setGenericMedicine(!isGenericMedicine)}
                    checked={isGenericMedicine}
                  />
                </Grid>
                {/* Show/Hide prescriptions */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">SHOW PRESCRIPTIONS</Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Tooltip
                    placement="top"
                    injectWrapper
                    title="If show prescription is not checked the prescription and line items will not appear to the patient in the app"
                  >
                    <Switch
                      label="Show prescription to the patient"
                      disabled={isFetchingUser}
                      onChange={() =>
                        setShowPrescriptions(!isShowPrescriptions)
                      }
                      checked={isShowPrescriptions}
                    />
                  </Tooltip>
                </Grid>
                {/* Consultation Id */}
                {isAllowed('newOrder', 'canViewConsultationId') && (
                  <>
                    <Grid item sx={{ mb: 1 }}>
                      <Typography variant="p4">CONSULTATION ID</Typography>
                    </Grid>

                    <Grid item sx={{ mb: 1 }}>
                      <TextField
                        fullWidth
                        size="large"
                        type="number"
                        variant="filled"
                        source={consultationId}
                        disabled={isFetchingUser}
                        placeholder="Enter here ..."
                        InputProps={{ inputProps: { min: 1 } }}
                        onChange={(event) => {
                          setConsultationId(event.target.value);
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* Prescriptions */}
                <Grid item sx={{ mb: 1 }}>
                  <Typography variant="p4">PRESCRIPTIONS</Typography>
                  <RequiredMark />
                </Grid>

                <Grid item sx={{ mb: 3 }}>
                  <FileInput
                    multiple
                    files={prescriptions}
                    onFilesUploaded={onFilesUploaded}
                    loading={isFetchingUser && { count: 0 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <CreateUserModal
        key={createUserModalKey}
        open={isUserCreationModalOpen}
        onClose={() => setUserCreationModalOpen(false)}
      />
    </>
  );
};

export default NewOrder;
