export const PACKAGE_NAME = 'Doctor';

export const FIELDS = {
  ID: {
    name: 'id',
  },
  CREATED_AT: {
    name: 'createdAt',
  },
  CREATED_BY: {
    name: 'createdBy',
  },
  UPDATED_AT: {
    name: 'updatedAt',
  },
  UPDATED_BY: {
    name: 'updatedBy',
  },
  USER_ID: {
    name: 'userId',
  },
  SLOT_DURATION: {
    name: 'slotDuration',
  },
  ACTIVE: {
    name: 'active',
  },
  CALL_ENABLED: {
    name: 'enableCalls',
  },
  SLACK_MEMBER_ID: {
    name: 'slackMemberId',
  },
  LICENSE_NUMBER: {
    name: 'licenseNumber',
  },
  ENGLISH_NAME: {
    name: 'englishName',
  },
  ARABIC_NAME: {
    name: 'arabicName',
  },
  LICENSE_TITLE_ARABIC: {
    name: 'licenseTitleArabic',
  },
  EDUCATION_TITLE_ARABIC: {
    name: 'educationTitleArabic',
  },
  EDUCATION_TITLE_ENGLISH: {
    name: 'educationTitleEnglish',
  },
  LICENSE_TITLE_ENGLISH: {
    name: 'licenseTitleEnglish',
  },
  LICENSE_FILE: {
    name: 'licenseFile',
  },
  STAMP_FILE: {
    name: 'stampFile',
  },
  SIGNATURE_FILE: {
    name: 'signatureFile',
  },
  PROFILE_PICTURE_FILE: {
    name: 'profilePictureFile',
  },
  LICENSE_EXPIRY_DATE: {
    name: 'licenseExpiryDate',
  },
  NATIONALITY: {
    name: 'nationality',
  },
  LICENSE_TYPE: {
    name: 'licenseType',
  },
  EDUCATION: {
    name: 'education',
  },
  YEARS_OF_EXPERIENCE: {
    name: 'yearsOfExperience',
  },
  CLINICS: {
    name: 'clinics',
  },
  DEFAULT_CLINIC: {
    name: 'defaultClinic',
  },
};

export const DOCTOR_LICENSE_TYPES = {
  FULL_TIME: {
    key: 'FULL_TIME',
    label: 'Full Time',
  },
  PART_TIME: {
    key: 'PART_TIME',
    label: 'Part Time',
  },
};
