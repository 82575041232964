import { connect } from 'react-redux';
import { eligibilityActions, eligibilitySelectors } from 'domain/eligibility';

import CreateEligibility from './CreateEligibility';

const mapDispatchToProps = (dispatch) => ({
  createEligibilityRequest: (payload) =>
    dispatch(eligibilityActions.createEligibilityRequest(payload)),
});

const mapStateToProps = (state) => {
  const isSubmitting = eligibilitySelectors.isSubmitting(state);

  return {
    isSubmitting,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateEligibility);
