import * as types from './actionTypes';

// Actions
export const logIn = (payload) => ({
  type: types.LOG_IN,
  payload,
});

// Responses
export const logInSucceeded = (response) => ({
  type: types.LOG_IN_SUCCEEDED,
  response,
});

export const logInFailed = (response) => ({
  type: types.LOG_IN_FAILED,
  response,
});
