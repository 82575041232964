import React from 'react';
import get from 'lodash/get';
import format from 'date-fns/format';
import findKey from 'lodash/findKey';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import { isAllowed } from 'core/permission';
import { dateTimeFormat } from 'constants/dateTime';
import { DialerLink } from 'presentation/components';
import { USER_STATUSES } from 'domain/user/constants';
import { Chip, Tooltip, IconButton } from 'design-system';
import { userGetters, userConstants as constants } from 'domain/user';

const { USER_TAGS, SIGN_UP_SOURCE } = constants;

export const getColumns = () => [
  { field: 'id', headerName: 'ID', width: 80 },
  {
    field: 'patient',
    headerName: 'Name',
    flex: 2,
    renderCell: (prms) => {
      return userGetters.getFullName(prms.row) || 'N/A';
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 2,
    renderCell: (prms) => {
      const phoneNumber = userGetters.getPhoneNumber(prms.row) || '-';

      return <DialerLink phoneNumber={phoneNumber}>{phoneNumber}</DialerLink>;
    },
  },

  {
    field: 'signupSource',
    headerName: 'Sign up source',
    width: 180,
    hide: !isAllowed('userList', 'signupSource'),
    renderCell: (prms) => {
      const signUpSource = userGetters.getSignUpSource(prms.row);

      return (
        <Tooltip placement="top" title="Sign up source">
          <Chip
            variant="filled"
            soak="light"
            label={SIGN_UP_SOURCE[signUpSource]?.label || signUpSource}
            color={SIGN_UP_SOURCE[signUpSource]?.color || 'primary'}
          />
        </Tooltip>
      );
    },
  },

  {
    field: 'createdAt',
    headerName: 'Created At',
    flex: 2,
    valueGetter: (prms) =>
      format(parseISO(userGetters.getCreatedAt(prms.row)), dateTimeFormat),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    hide: !isAllowed('userList', 'status'),
    valueGetter: (prms) => {
      const statusKey = findKey(USER_STATUSES, {
        key: userGetters.getStatus(prms.row) || USER_STATUSES.LeadImported.key,
      });

      return get(USER_STATUSES, `${statusKey}.label`);
    },
  },
  {
    field: 'verified',
    headerName: 'Verified',
    type: 'boolean',
    flex: 2,
    hide: !isAllowed('userList', 'verified'),
  },
  {
    field: 'language',
    headerName: 'Language',
    flex: 2,
    renderCell: (prms) => {
      return userGetters.getLanguage(prms.row);
    },
  },
  {
    field: 'tags',
    headerName: 'Tags',
    flex: 2,
    hide: !isAllowed('userList', 'tags'),
    renderCell: (prms) => {
      const tags = userGetters.getTags(prms.row) || [];
      const tagLabels = tags.map((tag) => USER_TAGS[tag]?.label);
      return tags.length > 0 ? (
        <Chip
          soak="light"
          variant="filled"
          title={tagLabels.join(', ')}
          color={USER_TAGS[tags[0]]?.color}
          label={tagLabels.length > 1 ? `${tagLabels[0]}, ...` : tagLabels[0]}
        />
      ) : null;
    },
  },
  {
    field: 'actions',
    headerName: '',
    width: 80,
    sortable: false,
    disableColumnMenu: true,
    hideSortIcons: true,
    renderCell: () => (
      <IconButton>
        <MessageOutlinedIcon fontSize="small" color="secondary" />
      </IconButton>
    ),
  },
];

export const getFilterFieldsConfig = () => [
  {
    name: 'patient',
    serverName: 'id',
    valueGetter: userGetters.getId,
  },
  {
    name: 'signUpSource',
    serverName: 'signupSource',
  },
  {
    name: 'status',
    serverName: 'status',
  },
  {
    name: 'tags',
    serverName: 'tags',
    operation: '$cont',
  },
  {
    name: 'createdBy',
    serverName: 'createdBy',
    valueGetter: userGetters.getId,
  },
  {
    name: 'isVerified',
    serverName: 'verified',
    valueGetter: (isVerified) => (isVerified ? true : null),
  },
  {
    name: 'createdDateFrom',
    serverName: 'createdAt',
    operation: '$gte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T00:00:00`
        : value;
    },
  },
  {
    name: 'createdDateUntil',
    serverName: 'createdAt',
    operation: '$lte',
    valueGetter: (value) => {
      const timestamp = new Date(value);

      return value && isValid(timestamp)
        ? `${formatISO(timestamp, {
            representation: 'date',
          })}T23:59:59`
        : value;
    },
  },
];
