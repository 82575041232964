import React, { useState, useEffect } from 'react';

import { CONSULTATION_TYPE_DOMAIN_FIELDS as FIELDS } from 'domain/consultationType';
import {
  Card,
  Grid,
  Button,
  TextField,
  PageTitle,
  Typography,
  CardContent,
  RequiredMark,
} from 'design-system';
import { getDefaultCurrency } from 'core/siteConfig';

const NewConsultationType = ({ isSubmitting, createConsultationType }) => {
  const [englishTitle, setEnglishTitle] = useState();
  const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const onSubmit = () => {
    const payload = {
      [FIELDS.ID.name]: 0,
      [FIELDS.ENGLISH_TITLE.name]: englishTitle,
      [FIELDS.ENGLISH_SUBTITLE.name]: '',
      [FIELDS.ARABIC_TITLE.name]: '',
      [FIELDS.ARABIC_SUBTITLE.name]: '',
      [FIELDS.PRICE.name]: {
        value: 0,
        currency: getDefaultCurrency(),
      },
      [FIELDS.DISPLAY_ORDER.name]: 0,
      [FIELDS.CONSULTATION_TYPE_CONFIGURATIONS.name]: [],
      [FIELDS.DOCTOR_IDS.name]: [],
    };

    createConsultationType(payload);
  };

  useEffect(() => {
    if (englishTitle?.trim()) {
      setSubmitButtonEnabled(englishTitle);
    } else {
      setSubmitButtonEnabled(false);
    }
  }, [englishTitle]);

  return (
    <>
      <PageTitle
        title="New Consultation Type"
        action={
          <Button
            variant="filled"
            onClick={onSubmit}
            spinning={isSubmitting}
            disabled={!isSubmitButtonEnabled}
          >
            Add Consultation Type
          </Button>
        }
      />
      <Grid
        container
        spacing={3}
        row
        alignItems="stretch"
        justifyContent="left"
      >
        <Grid item xs={12}>
          <Card
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardContent
              noPadding
              sx={{
                height: '100%',
              }}
            >
              <Grid container py={2} sx={{ flexWrap: 'nowrap' }}>
                <Grid item container column xs={6}>
                  {/* English title */}
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <Typography variant="p4">English Title</Typography>
                    <RequiredMark />
                  </Grid>
                  <Grid item sx={{ mb: 1, px: 2 }}>
                    <TextField
                      size="large"
                      fullWidth
                      variant="filled"
                      placeholder="English title"
                      onChange={(e) => {
                        setEnglishTitle(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default NewConsultationType;
