import get from 'lodash/get';

import { FIELDS } from './constants';

export const getReminderDate = (followUpReminder) => {
  return get(followUpReminder, FIELDS.REMINDER_DATE.name);
};

export const getComment = (followUpReminder) =>
  get(followUpReminder, FIELDS.COMMENT.name);

export const getReferenceId = (followUpReminder) =>
  get(followUpReminder, FIELDS.REFERENCE_ID.name);

export const getReferenceType = (followUpReminder) =>
  get(followUpReminder, FIELDS.REFERENCE_TYPE.name);
