import { connect } from 'react-redux';

import {
  followUpReminderActions as actions,
  followUpReminderSelectors as selectors,
} from 'domain/followUpReminder';

import FollowUpRemindersManagement from './FollowUpRemindersManagement';

const mapStateToProps = (state, ownProps) => {
  const followUpReminders = selectors.getFollowUpRemindersof(
    state,
    ownProps.referenceType
  );

  return {
    followUpReminders,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchFollowUpReminders: () =>
    dispatch(
      actions.fetchFollowUpReminders(
        ownProps.referenceId,
        ownProps.referenceType
      )
    ),
  createFollowUpReminder: (params) =>
    dispatch(
      actions.createFollowUpReminders(
        ownProps.referenceId,
        ownProps.referenceType,
        ownProps.tenantKey,
        ownProps.namespaceKey,
        params
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowUpRemindersManagement);
