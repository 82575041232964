/* Consultation domain constants */
import { CONSULTATION_MODEL_FIELDS } from 'model/consultation';

export const PACKAGE_NAME = 'Consultation';

export const LS_CONSULTATION_FILTERS = 'consultation_filters';

export const ZOOM_MEETING_ACTION = 'JoinMeetingStarted';

export const CALL_MEETING_ACTION = 'DoctorStartedCall';

export const FIELDS = {
  ...CONSULTATION_MODEL_FIELDS,
};

export const STATUSES = {
  PENDING_APPROVAL: {
    key: 'PENDING_APPROVAL',
    label: 'Pending',
    color: 'warning',
  },
  PAID: {
    key: 'PAID',
    label: 'Paid',
    color: 'success',
  },
  UNCONFIRMED: {
    key: 'UNCONFIRMED',
    label: 'Unconfirmed',
    color: 'warning',
  },
  PENDING_DOCUMENTS_UPLOAD: {
    key: 'PENDING_DOCUMENTS_UPLOAD',
    label: 'Pending Documents Upload',
    color: 'warning',
  },
  CHECK_DOCUMENTS: {
    key: 'CHECK_DOCUMENTS',
    label: 'Check Documents',
    color: 'warning',
  },
  RESERVED: {
    key: 'RESERVED',
    label: 'Reserved',
    color: 'primary',
  },
  CONFIRMED: {
    key: 'CONFIRMED',
    label: 'Confirmed',
    color: 'primary',
  },
  COMPLETED: {
    key: 'COMPLETED',
    label: 'Completed',
    color: 'success',
  },
  NO_ANSWER: {
    key: 'NO_ANSWER',
    label: 'No Answer',
    color: 'warning',
  },
  NO_SHOW: {
    key: 'NO_SHOW',
    label: 'No Show',
    color: 'warning',
  },
  TIME_FINISHED: {
    key: 'TIME_FINISHED',
    label: 'Time Finished',
    color: 'primary',
  },
  REGISTRATION_REQUIRED: {
    key: 'REGISTRATION_REQUIRED',
    label: 'Registration Required',
    color: 'warning',
  },
  CANCELED: {
    key: 'CANCELED',
    label: 'Cancelled',
    color: 'error',
  },
};

export const CONSULTATION_CHANNELS = {
  APP: { label: 'Mobile App', key: 'APP' },
  SUCCUSS_TEAM: { label: 'Success Team', key: 'Success Team' },
  LAB_TEST_FOLLOWUP: { label: 'Lab test follow up', key: 'Lab Test Followup' },
  PARTNER_CLINIC: { label: 'Partner Clinic', key: 'PARTNER CLINIC' },
};

export const CONSULTATION_ATTENDEE = {
  PERSONAL: { label: 'Personal', key: 'PERSONAL' },
  FAMILY: { label: 'Family', key: 'FAMILY' },
  UNKNOWN: { label: 'Unknown', key: 'UNKNOWN' },
};

/** This enum used to define the consultation status properties on booking schedule  */
export const SCHEDULE_BOOKED_STATUSES = {
  PENDING: {
    color: 'warning',
    key: 'PENDING',
    title: 'Booked for a pending consultation',
  },
  RESERVED: {
    color: 'secondary',
    key: 'RESERVED',
    title: 'Booked for a reserved consultation ',
  },
  CONFIRMED: {
    color: 'error',
    key: 'CONFIRMED',
    title: 'Booked for a confirmed consultation',
  },
};

export const MEETING_COMMUNICATION_METHOD = {
  PHONE_CALL: {
    key: 'PHONE_CALL',
    label: 'Phone Call',
  },
  ZOOM_MEETING: {
    key: 'ZOOM_MEETING',
    label: 'Zoom meeting',
  },
};
