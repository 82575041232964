import React from 'react';
import keys from 'lodash/keys';

import { getDefaultCurrency } from 'core/siteConfig';
import { DOSAGE_FORMS } from 'domain/medicine/constants';
import { Form, Controller, formValidationFunctions } from 'design-system/form';
import {
  Card,
  Grid,
  Button,
  Select,
  MenuItem,
  FormField,
  PageTitle,
  TextField,
  Typography,
  CardContent,
  InputAdornment,
} from 'design-system';

const SubmitButton = ({ spinning }) => (
  <Button type="submit" variant="filled" spinning={spinning}>
    Create medicine
  </Button>
);

const NewMedicine = ({ createMedicine, isSubmitting }) => {
  const onSubmit = (data) => {
    const payload = {
      ...data,
      packagePrice: {
        value: Number(data.packagePrice),
      },
      ...(data.packageCost
        ? {
            packageCost: {
              value: Number(data.packageCost),
            },
          }
        : {}),
      ...(data.unitCost
        ? {
            packageCost: {
              value: Number(data.unitCost),
            },
          }
        : {}),
      ...(data.unitPrice
        ? {
            packageCost: {
              value: Number(data.unitPrice),
            },
          }
        : {}),
    };

    createMedicine(payload);
  };

  return (
    <Form resetOnSubmit onSubmit={onSubmit}>
      {(form) => (
        <>
          <PageTitle
            title="New Medicine"
            action={<SubmitButton spinning={isSubmitting} />}
          />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item sx={{ width: '100%' }}>
                  <Typography variant="h5">Medicine Details</Typography>
                </Grid>
                <FormField
                  label="DRUG CODE"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="drugCode"
                      defaultValue=""
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Drug code.."
                          onChange={onChange}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="GENERIC NAME"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="genericName"
                      defaultValue=""
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Generic name.."
                          onChange={onChange}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="PACKAGE NAME"
                  required
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="packageName"
                      defaultValue=""
                      control={form.control}
                      rules={{
                        required: 'Package name is required',
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Package name.."
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="PACKAGE SIZE SUMMARY"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="packageSizeSummary"
                      defaultValue=""
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Package size summary.."
                          onChange={onChange}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="DISPENSE MODE"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="dispenseMode"
                      defaultValue=""
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Dispense mode.."
                          onChange={onChange}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="STRENGTH"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="strength"
                      defaultValue=""
                      control={form.control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Strength.."
                          onChange={onChange}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="DOSAGE FORM"
                  required
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="dosageForm"
                      defaultValue={null}
                      control={form.control}
                      rules={{
                        required: 'Dosage form is required',
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Select
                          fullWidth
                          value={value}
                          size="large"
                          color="standard"
                          variant="filled"
                          soak="light"
                          onChange={onChange}
                          error={Boolean(error)}
                          placeholder="Dosage form.."
                          helperText={error?.message ?? null}
                          renderValue={(val) => DOSAGE_FORMS[val]?.label ?? val}
                        >
                          {keys(DOSAGE_FORMS).map((item) => (
                            <MenuItem value={item} key={item}>
                              {DOSAGE_FORMS[item].label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  }
                />
                <Grid item sx={{ width: '100%' }}>
                  <Typography variant="h5">Prices</Typography>
                </Grid>
                <FormField
                  label="PACKAGE PRICE"
                  required
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="packagePrice"
                      defaultValue=""
                      valueAsNumber
                      control={form.control}
                      rules={{
                        required: 'Package price is required',
                        validate: {
                          positive: (v) => formValidationFunctions.positive(v),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          type="number"
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Package price.."
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getDefaultCurrency()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="PACKAGE COST"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="packageCost"
                      defaultValue=""
                      valueAsNumber
                      control={form.control}
                      rules={{
                        validate: {
                          positive: (v) => formValidationFunctions.positive(v),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          type="number"
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Package cost.."
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getDefaultCurrency()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="UNIT PRICE"
                  required
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="unitPrice"
                      defaultValue=""
                      valueAsNumber
                      control={form.control}
                      rules={{
                        required: 'Unit price is required',
                        validate: {
                          positive: (v) => formValidationFunctions.positive(v),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          type="number"
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Unit price.."
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getDefaultCurrency()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  }
                />
                <FormField
                  label="UNIT COST"
                  xs={12}
                  md={6}
                  field={
                    <Controller
                      name="unitCost"
                      defaultValue=""
                      valueAsNumber
                      control={form.control}
                      rules={{
                        validate: {
                          positive: (v) => formValidationFunctions.positive(v),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          fullWidth
                          type="number"
                          variant="filled"
                          size="large"
                          value={value}
                          placeholder="Unit cost.."
                          onChange={onChange}
                          error={Boolean(error)}
                          helperText={error?.message ?? null}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {getDefaultCurrency()}
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </CardContent>
          </Card>
          <Grid container justifyContent="flex-end" sx={{ mt: 3 }}>
            <Grid item>
              <SubmitButton />
            </Grid>
          </Grid>
        </>
      )}
    </Form>
  );
};

export default NewMedicine;
