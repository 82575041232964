import { connect } from 'react-redux';
import { compose } from 'redux';
import { labSelectors as selectors, labActions as actions } from 'domain/lab';
import appData from 'domain/app/HOCs/appData';
import ELabDialog from './ELabDialog';

const mapStateToProps = (state) => {
  const document = selectors.getELabRequestDocument(state);
  const isSubmitting = selectors.isSubmittingLabRequest(state);

  return { document, isSubmitting };
};

const mapDispatchToProps = (dispatch) => ({
  createLabRequest: (payload) =>
    dispatch(actions.createLabRequest(payload, false)),
  updateLabRequest: (id, payload) =>
    dispatch(actions.patchLabRequest(id, payload)),
  createDocument: (payload) =>
    dispatch(actions.createELabRequestDocument(payload)),
  clearFile: () => dispatch(actions.eLabRequestDocumentCreated()),
});

export default compose(
  // Adds app-wide properties: clinics
  appData,
  connect(mapStateToProps, mapDispatchToProps)
)(ELabDialog);
