import * as types from './actionTypes';
import memberReducer from './member/reducer';

const initialState = {
  view: {},
  doctors: [],
  drivers: [],
  addresses: [],
  pharmacists: [],
  patientHealthInfo: {},
  isFetchingUser: false,
  isFetchingFiles: false,
  isSubmittingUser: false,
  isFetchingBackId: false,
  isFetchingHealth: false,
  extractedIdentity: null,
  isFetchingFrontId: false,
  identityExtractionJobs: [],
  isExtractingIdentity: false,
  identityExtractionError: '',
  isFetchingInsuranceCard: false,
  isFetchingUserAddresses: false,
  isSavingExtractedIdentityData: false,
  // Sub reducer
  member: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_RECEIVED: {
      const user = action.user || {};

      return {
        ...state,
        view: { ...user },
      };
    }
    case types.DOCTORS_RECEIVED: {
      const doctors = action.doctors || [];

      return {
        ...state,
        doctors: [...doctors],
      };
    }
    case types.DRIVERS_RECEIVED: {
      const drivers = action.drivers || [];

      return {
        ...state,
        drivers: [...drivers],
      };
    }
    case types.CLEAR_USER: {
      return {
        ...state,
        view: {},
      };
    }
    case types.SET_FETCHING_USER: {
      return {
        ...state,
        isFetchingUser: action.isFetching,
      };
    }
    case types.SET_SUBMITTING_USER: {
      return {
        ...state,
        isSubmittingUser: action.isSubmitting,
      };
    }
    case types.SET_SAVING_EXTRACTED_IDENTITY_DATA: {
      return {
        ...state,
        isSavingExtractedIdentityData: action.isSavingExtractedIdentityData,
      };
    }
    case types.FETCH_PATIENT_HEALTH_INFO: {
      return {
        ...state,
        isFetchingHealth: true,
      };
    }
    case types.PATIENT_HEALTH_INFO_RECEIVED: {
      return {
        ...state,
        patientHealthInfo: { ...(action.patient || {}) },
        isFetchingHealth: false,
      };
    }
    case types.USER_ADDRESSES_RECEIVED: {
      return {
        ...state,
        addresses: action.addresses,
      };
    }
    case types.CLEAR_PATIENT_HEALTH_INFO: {
      return {
        ...state,
        patientHealthInfo: {},
      };
    }
    case types.SET_FETCHING_USER_FILES: {
      return {
        ...state,
        isFetchingFiles: action.isFetchingFiles,
      };
    }
    case types.SET_FETCHING_FRONT_ID: {
      return {
        ...state,
        isFetchingFrontId: action.isFetchingFrontId,
      };
    }
    case types.SET_FETCHING_BACK_ID: {
      return {
        ...state,
        isFetchingBackId: action.isFetchingBackId,
      };
    }
    case types.SET_FETCHING_INSURANCE_CARD: {
      return {
        ...state,
        isFetchingInsuranceCard: action.isFetchingInsuranceCard,
      };
    }
    case types.SET_FETCHING_USER_ADDRESSES: {
      return {
        ...state,
        isFetchingUserAddresses: action.isFetchingUserAddresses,
      };
    }
    case types.SET_EXTRACTING_IDENTITY: {
      return {
        ...state,
        isExtractingIdentity: action.isExtractingIdentity,
      };
    }
    case types.IDENTITY_EXTRACTION_JOBS_RECEIVED: {
      return {
        ...state,
        identityExtractionJobs: action.jobs,
      };
    }
    case types.IDENTITY_EXTRACTION_SUCCEEDED: {
      return {
        ...state,
        extractedIdentity: action.extractedData,
      };
    }
    case types.IDENTITY_EXTRACTION_FAILED: {
      return {
        ...state,
        identityExtractionError: action.extractionError,
      };
    }
    case types.CLEAR_EXTRACTED_IDENTITY_DATA: {
      return {
        ...state,
        extractedIdentity: null,
        identityExtractionJobs: [],
        isExtractingIdentity: false,
        identityExtractionError: '',
      };
    }
    case types.PHARMACISTS_RECEIVED: {
      const pharmacists = action.pharmacists || [];

      return {
        ...state,
        pharmacists: [...pharmacists],
      };
    }
    default:
      // Sub reducers can be added from here
      return { ...state, member: memberReducer(state.member, action) };
  }
};

export default userReducer;
