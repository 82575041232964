import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { useState, useEffect } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { userGetters } from 'domain/user';
import { UserSearch } from 'presentation/user';
import { dateTimeFormat } from 'constants/dateTime';
import { DialerLink } from 'presentation/components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Link from 'core/router';
import LockResetIcon from '@mui/icons-material/LockReset';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AbcIcon from '@mui/icons-material/Abc';
import {
  PopConfirm,
  IconButton,
  Tooltip,
  Button,
  DataGrid,
  PageTitle,
  Typography,
} from 'design-system';
import { DataGridCard, useServerPagination, filtration } from 'core/dataGrid';
import { isEmpty } from 'lodash';
import { cacheUtils } from 'core/cache';
import { authSelectors } from 'domain/auth';
import { LOCAL_DOMAIN_CACHE_KEYS } from 'domain/constants/general';

const UserManagement = ({ deactivateAccount, resetPassword }) => {
  const navigate = useNavigate();
  const selectedTenant = useSelector((state) =>
    authSelectors.getSelectedActiveTenant(state, true)
  );

  let queryParams = new URLSearchParams();
  queryParams.append('filter', 'active||eq||true');
  queryParams.append('filter', `tenantKey||$in||${selectedTenant}`);

  const {
    props: dataGridProps,
    setters: { setParams: setQueryParams },
  } = useServerPagination({
    endpoint: '/user-management',
    params: queryParams,
    initialSortModel: { field: 'createdAt', sort: 'desc' },
  });

  const [popConfirmAnchors, setPopConfirmAnchors] = useState({});

  const { updateFilter } = filtration.useServerFiltration({
    setQueryParams,
    fieldsConfig: [
      {
        name: 'user',
        serverName: 'userId',
        valueGetter: userGetters.getId,
      },
      {
        name: 'username',
        serverName: 'username',
        operation: '$contL',
      },
    ],
    onQueryParamsChange: (urlSearchParams) => {
      queryParams = new URLSearchParams(urlSearchParams);
      queryParams.append('filter', 'active||eq||true');
      queryParams.append('filter', `tenantKey||$in||${selectedTenant}`);
      return queryParams;
    },
  });
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (dataGridProps) {
      setFetching(dataGridProps.loading);
    }
  }, [dataGridProps]);

  const showPopConfirm = (id, event) => {
    setPopConfirmAnchors({
      ...popConfirmAnchors,
      [id]: event.currentTarget,
    });
  };

  const onRowClick = (row) => {
    navigate(`/user-management/${row.id}`);
  };

  const closePopConfirm = (id, userId, confirm) => {
    setPopConfirmAnchors({
      ...popConfirmAnchors,
      [id]: null,
    });
    if (confirm) {
      deactivateAccount(id);
      const currentDoctors = cacheUtils.getCachedDomainObject(
        LOCAL_DOMAIN_CACHE_KEYS.DOCTORS
      );

      // To update the doctors list in the local storage
      // It fixes the issue when the user is deactivated and still appears in
      // the doctors list in consultation type page and possibly other places
      if (currentDoctors) {
        const newDoctors = currentDoctors.filter(
          (doctor) => doctor.id !== userId
        );
        cacheUtils.setCachedDomainObject(
          LOCAL_DOMAIN_CACHE_KEYS.DOCTORS,
          newDoctors
        );
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 80 },
    {
      field: 'fullName',
      headerName: 'Full name',
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        const { fullName } = params.row;
        const content = fullName || 'Almaos user';
        return <Link to={`/user/${params.row.userId}`}>{content}</Link>;
      },
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      flex: 1.5,
      renderCell: (params) => {
        const { phoneNumber } = params.row;
        const content = phoneNumber || '-';
        return phoneNumber ? (
          <DialerLink phoneNumber={phoneNumber}>{content}</DialerLink>
        ) : (
          content
        );
      },
    },
    {
      field: 'roles',
      headerName: 'Roles',
      flex: 2,
      sortable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        const rolesValue = (params.row.roles || [])
          .map((role) => role.toUpperCase())
          .join(', ');
        return <Typography variant="small">{rolesValue}</Typography>;
      },
    },
    {
      field: 'slackMemberId',
      headerName: 'Slack account',
      flex: 1,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      renderCell: (params) => {
        return (
          <Link to={`/user/${params.row.userId}`}>{params.row.username}</Link>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      valueGetter: (params) =>
        format(parseISO(params.row?.createdAt), dateTimeFormat),
    },
    {
      field: 'lastLoginAt',
      headerName: 'Last Login',
      flex: 1,
      valueGetter: (params) =>
        params.row?.lastLoginAt
          ? format(parseISO(params.row?.lastLoginAt), dateTimeFormat)
          : '',
    },
    {
      field: 'actions',
      headerName: '',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => (
        <>
          <>
            <IconButton
              sx={{ mr: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                showPopConfirm(params.row.id, e);
              }}
            >
              <Tooltip placement="top" title="Deactivate account">
                <HighlightOffIcon fontSize="small" color="error" />
              </Tooltip>
            </IconButton>
            <PopConfirm
              primaryButtonTitle="Cancel"
              secondaryButtonTitle="Deactivate"
              anchorEl={popConfirmAnchors[params.row.id]}
              onOk={(e) => {
                e.stopPropagation();
                closePopConfirm(params.row.id, params.row.userId);
              }}
              open={Boolean(popConfirmAnchors[params.row.id])}
              onCancel={(e) => {
                e.stopPropagation();
                closePopConfirm(params.row.id, params.row.userId, true);
              }}
              id={
                popConfirmAnchors[params.row.id]
                  ? `delete-range-${params.row.id}-confirm`
                  : undefined
              }
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              Are you sure you want to deactivate this account?
            </PopConfirm>
          </>
          <IconButton
            sx={{ mr: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              resetPassword(params.row.userId);
            }}
          >
            <Tooltip placement="top" title="Reset Password">
              <LockResetIcon fontSize="small" color="warning" />
            </Tooltip>
          </IconButton>
          <IconButton sx={{ mr: 1 }}>
            <Tooltip placement="top" title="View">
              <MessageOutlinedIcon fontSize="small" color="secondary" />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const filtersDef = {
    fields: [
      {
        name: 'user',
        type: 'custom',
        component: (
          <UserSearch
            disabled={isFetching}
            onUserSelect={(selectedUser) => {
              updateFilter('user', selectedUser);
            }}
          />
        ),
      },
      {
        name: 'username',
        type: 'textInput',
        placeholder: 'Username',
        icon: AbcIcon,
        disabled: isFetching,
        onInputChange: (event) => {
          const { value } = event.target;
          if (value.length > 2) {
            updateFilter('username', event.target.value);
          } else if (isEmpty(value)) {
            updateFilter('username', '');
          }
        },
      },
    ],
  };

  return (
    <>
      <PageTitle
        title="User Management"
        action={
          <Button
            variant="filled"
            onClick={() => {
              navigate(`/user-management/create`);
            }}
          >
            Add new user
          </Button>
        }
      />
      <DataGridCard filters={filtersDef}>
        <DataGrid
          onRowClick={(prms) => onRowClick(prms.row)}
          columns={columns}
          {...dataGridProps}
          sx={{
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },
          }}
          contextMenuItems={[
            {
              label: 'Open',
              handler: onRowClick,
            },
            {
              label: 'Open in a new tab',
              handler: (row) => {
                window.open(`/user-management/${row.id}`, '_blank');
              },
            },
          ]}
        />
      </DataGridCard>
    </>
  );
};

export default UserManagement;
