import { all, put, take, select, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import {
  followUpReminderModelActions,
  followUpReminderModelActionTypes,
} from 'model/followUpReminder';

import { FIELDS } from './constants';
import * as actions from './actions';
import * as getters from './getters';
import * as types from './actionTypes';
import * as selectors from './selectors';

const currentState = (state) => state;

const sort = (reminders) => {
  return reminders.sort(
    (a, b) =>
      new Date(getters.getReminderDate(b)) -
      new Date(getters.getReminderDate(a))
  );
};

export function* fetchFollowUpReminders({ referenceId, referenceType }) {
  yield put(
    followUpReminderModelActions.fetchFollowUpReminders(
      referenceId,
      referenceType
    )
  );

  const { followUpReminders } = yield take(
    followUpReminderModelActionTypes.FOLLOW_UP_REMINDER_RECEIVED
  );

  yield put(
    actions.followUpRemindersReceived(
      sort(followUpReminders),
      referenceId,
      referenceType
    )
  );
}
export function* createFollowUpReminder({
  tenantKey,
  referenceId,
  namespaceKey,
  referenceType,
  params,
}) {
  const state = yield select(currentState);
  const followUpReminders =
    selectors.getFollowUpRemindersof(state, referenceType) || [];

  yield put(
    followUpReminderModelActions.createFollowUpReminders({
      ...params,
      [FIELDS.TENANT_KEY.name]: tenantKey,
      [FIELDS.REFERENCE_ID.name]: referenceId,
      [FIELDS.NAMESPACE_KEY.name]: namespaceKey,
      [FIELDS.REFERENCE_TYPE.name]: referenceType,
    })
  );

  const { followUpReminder } = yield take(
    followUpReminderModelActionTypes.FOLLOW_UP_REMINDER_CREATED
  );

  yield put(
    actions.followUpRemindersReceived(
      sort([...followUpReminders, followUpReminder]),
      referenceId,
      referenceType
    )
  );

  Message.success('Follow up reminder has been added successfully');
}

export default function* followUpReminderSaga() {
  yield all([
    takeLatest(types.FETCH_FOLLOW_UP_REMINDERS, fetchFollowUpReminders),
    takeLatest(types.CREATE_FOLLOW_UP_REMINDER, createFollowUpReminder),
  ]);
}
