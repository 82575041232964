import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePicker = ({ renderInput, ...rest }) => {
  return (
    <MuiDatePicker
      renderInput={(props) => {
        return renderInput({
          ...props,
          inputProps: {
            ...props.inputProps,
            disabled: true,
            title: 'Click the icon to the right',
          },
        });
      }}
      {...rest}
    />
  );
};

export default DatePicker;
