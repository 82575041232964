import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as FILE } from './constants';

export const CREATE_FILES = `${DOMAIN}/${FILE}/CREATE_FILES`;
export const CLEAR_FILES = `${DOMAIN}/${FILE}/CLEAR_FILES`;
export const FETCH_FILES_OF = `${DOMAIN}/${FILE}/FETCH_FILES_OF`;

export const FILES_CREATED = `${DOMAIN}/${FILE}/FILES_CREATED`;
export const FILES_RECEIVED_OF = `${DOMAIN}/${FILE}/FILES_RECEIVED_OF`;

export const SET_CREATING_FILES = `${DOMAIN}/${FILE}/SET_CREATING_FILES`;
export const SET_FETCHING_FILES_OF = `${DOMAIN}/${FILE}/SET_FETCHING_FILES_OF`;
