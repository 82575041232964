import React from 'react';

import Box from '../Box';

const TabPanel = ({ children, value, index, id, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-${index}`}
      aria-labelledby={`${id}-${index}`}
      {...rest}
    >
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
