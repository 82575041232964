import React, { useRef, useState, useEffect, useCallback } from 'react';

import { memberConstants as constants } from 'domain/user/member';
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'design-system';

import { userConstants } from 'domain/user';
import MemberForm from 'presentation/user/components/MemberManagement/components/MemberForm';

const { FIELDS } = constants;
const { FIELDS: USER_FIELDS } = userConstants;

const CreateMemberDialog = ({
  // Props
  open,
  onClose,
  owner,
  referenceId,
  membersList,
  // State
  isSubmitting,
  // actions
  updateMembers,
  createMember,
  onMemberCreated,
  setIsFormChanged,
  ...rest
}) => {
  const formRef = useRef();

  const [isOpen, setOpen] = useState(open);

  const onClosed = useCallback(() => {
    setOpen(false);
    onClose();
  }, []);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      [FIELDS.OWNER_ID.name]: owner.id,
      [USER_FIELDS.FULL_NAME.name]: data?.fullName?.trim(),
    };
    createMember(owner.id, payload);

    onMemberCreated((action) => {
      updateMembers(referenceId, [
        ...membersList.map((member) => member.userId),
        action.member.id,
      ]);
      onClosed();
    });
  };

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClosed}
      aria-labelledby="member-create-dialog"
      {...rest}
    >
      <DialogTitle id="member-create-dialog-title" onClose={onClosed}>
        Add a new patient to the consulation
      </DialogTitle>
      <DialogContent dividers>
        <MemberForm ref={formRef} onSubmit={onSubmit} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="filled"
          color="primary"
          spinning={isSubmitting}
          onClick={() => {
            formRef.current.submit();
            setIsFormChanged(false);
          }}
        >
          Save and add to consultation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMemberDialog;
