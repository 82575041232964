import { combineReducers } from 'redux';

import labReducer from './lab/reducer';
import authReducer from './auth/reducer';
import userReducer from './user/reducer';
import fileReducer from './file/reducer';
import orderReducer from './order/reducer';
import doctorReducer from './doctor/reducer';
import tenantReducer from './tenant/reducer';
import clinicReducer from './clinic/reducer';
import commentReducer from './comment/reducer';
import meetingReducer from './meeting/reducer';
import paymentReducer from './payment/reducer';
import medicineReducer from './medicine/reducer';
import scheduleReducer from './schedule/reducer';
import dietPlanReducer from './dietPlan/reducer';
import doctorNotesReducer from './doctorNotes/reducer';
import eligibilityReducer from './eligibility/reducer';
import subscriptionReducer from './subscription/reducer';
import consultationReducer from './consultation/reducer';
import prescriptionReducer from './prescription/reducer';
import userManagementReducer from './userManagement/reducer';
import patientHistoryReducer from './patientHistory/reducer';
import followUpReminderReducer from './followUpReminder/reducer';
import consultationTypeReducer from './consultationType/reducer';
import insuranceProviderReducer from './insuranceProvider/reducer';
import anonymousUserReducer from './anonymousUser/reducer';

const domainReducer = combineReducers({
  lab: labReducer,
  user: userReducer,
  auth: authReducer,
  file: fileReducer,
  order: orderReducer,
  doctor: doctorReducer,
  clinic: clinicReducer,
  tenant: tenantReducer,
  comment: commentReducer,
  meeting: meetingReducer,
  payment: paymentReducer,
  medicine: medicineReducer,
  schedule: scheduleReducer,
  dietPlan: dietPlanReducer,
  doctorNotes: doctorNotesReducer,
  eligibility: eligibilityReducer,
  subscription: subscriptionReducer,
  consultation: consultationReducer,
  prescription: prescriptionReducer,
  userManagement: userManagementReducer,
  followUpReminder: followUpReminderReducer,
  consultationType: consultationTypeReducer,
  patientHistory: patientHistoryReducer,
  insuranceProvider: insuranceProviderReducer,
  anonymousUser: anonymousUserReducer,
});

export default domainReducer;
