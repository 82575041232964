import forEach from 'lodash/forEach';
import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import { userSelectors } from 'domain/user';
import { insuranceProviderSelectors } from 'domain/insuranceProvider';
import { consultationConstants } from 'domain/consultation';

import ConsultationsList from './ConsultationsList';

const { LS_CONSULTATION_FILTERS } = consultationConstants;

const mapStateToProps = (state) => {
  const doctorsFullNamesMap = {};
  const insuranceProvidersNameMap = {};
  const insuranceProvidersList =
    insuranceProviderSelectors.getInsuranceProviderList(state);
  const rawDoctors = userSelectors.getDoctors(state);
  const lsKey = LS_CONSULTATION_FILTERS;

  // TODO: What if more than one doctor have the same name?
  // TODO: use lodash/groupBy to prevent that issue
  forEach(rawDoctors, (doctor) => {
    if (doctor.fullName && !doctorsFullNamesMap[doctor.fullName]) {
      doctorsFullNamesMap[doctor.fullName] = doctor.id;
    }
  });

  forEach(insuranceProvidersList, (insuranceProvider) => {
    if (
      insuranceProvider.englishName &&
      !insuranceProvidersNameMap[insuranceProvider.englishName]
    ) {
      insuranceProvidersNameMap[insuranceProvider.englishName] =
        insuranceProvider.id;
    }
  });

  return {
    doctorsFullNamesMap,
    lsKey,
    insuranceProvidersNameMap,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveFilters: (filters) =>
    dispatch(appActions.saveFilters(filters, LS_CONSULTATION_FILTERS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsList);
