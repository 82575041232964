import isEmpty from 'lodash/isEmpty';
import React, { useRef, useMemo, forwardRef } from 'react';

import { Form, Controller } from 'design-system/form';
import { Grid, FormField, MultiSelectDropdown, Chip } from 'design-system';

const MemberSelectForm = forwardRef(
  (
    {
      // Props
      onSubmit,
      members = [],
      allMembers = [],
      footer,
      setIsFormChanged,
      permissions = {},
    },
    ref
  ) => {
    const perms = {
      delete: true,
      ...permissions,
    };

    const formRef = useRef();

    const reference = useMemo(() => {
      return ref || formRef;
    }, [ref, formRef]);

    function arrayToMap(array, id, fullName) {
      const object = array.reduce((obj, item) => {
        const result = { ...obj };

        result[item[id]] = item[fullName];

        return result;
      }, {});

      return object;
    }

    return (
      <Form ref={reference} onSubmit={onSubmit}>
        {({ control }) => (
          <Grid container column rowSpacing={2}>
            <Grid item>
              <FormField
                required
                label="Choose patients"
                field={
                  <Controller
                    name="newMembersConsultation"
                    control={control}
                    defaultValue={arrayToMap(members, 'userId', 'fullName')}
                    rules={{
                      required: 'Consultation must have at least one patient',
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => {
                      const handleDropdownChange = (selectedValues) => {
                        onChange(selectedValues);
                      };

                      return (
                        <MultiSelectDropdown
                          onChange={handleDropdownChange}
                          options={arrayToMap(allMembers, 'id', 'fullName')}
                          values={value}
                          footer={footer}
                          color="standard"
                          minWidth="30%"
                          error={Boolean(error)}
                          setIsFormChanged={setIsFormChanged}
                          disabledOptions={(() => {
                            let disabled = [];

                            if (!perms?.delete) {
                              disabled = [
                                ...disabled,
                                // Disable selected patients
                                ...(members || [])
                                  .map((member) => member.userId)
                                  // MultiSelectDropDown takes keys of objects, which are strings
                                  .map(String),
                              ];
                            }

                            disabled = [
                              ...disabled,
                              ...(members || [])
                                // Disable patients with outcome
                                .filter((member) => !isEmpty(member.output))
                                .map((member) => member.userId)
                                .map(String),
                            ];

                            return disabled;
                          })()}
                          helperText={error?.message ?? null}
                          renderItem={({ value: chipValue, key }) => {
                            return (
                              <Chip
                                key={key}
                                label={chipValue}
                                size="small"
                                sx={{
                                  color: (theme) => theme.palette.primary.main,
                                  backgroundColor: (theme) =>
                                    theme.palette.primary.tint,
                                }}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        )}
      </Form>
    );
  }
);

export default MemberSelectForm;
