import httpClient from 'core/httpClient';

const ENDPOINT = (resource) =>
  `${process.env.REACT_APP_PDF_GENERATOR_SERVICE_URL}${resource}/pdf`;

export const createPdfFile = async (resource, fileName, payload) => {
  return httpClient
    .post(
      ENDPOINT(resource),
      { pdfDetails: { fileName, ...payload } },
      {
        responseType: 'blob',
      }
    )
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.data;
    });
};

export const downloadPdfFile = (blob) => {
  const objectURL = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = objectURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();

      if (iframe?.contentWindow?.print) {
        iframe.contentWindow.print();
      }
    }, 1);
  };
};
