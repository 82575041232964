import { connect } from 'react-redux';

import { memberSelectors } from 'domain/user/member';
import { userActions, userSelectors } from 'domain/user';

import PatientHealthInfo from './PatientHealthInfo';

const mapStateToProps = (state, ownProps) => {
  const healthInfo = ownProps?.takePassedHealthProfile
    ? ownProps.healthProfile
    : userSelectors.getHealthInfo(state);
  const isFetchingHealth = ownProps?.takePassedHealthProfile
    ? memberSelectors.isFetchingMembersHealthProfiles(state)
    : userSelectors.isFetchingHealth(state);

  return {
    healthInfo,
    isFetchingHealth,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchHealthInfo: (userId) =>
    dispatch(userActions.fetchPatientHealthInfo(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientHealthInfo);
