import get from 'lodash/get';

import { FIELDS } from '../constants';

export const getId = (consultationType) =>
  get(consultationType, FIELDS.ID.name);

export const getCreatedAt = (consultationType) =>
  get(consultationType, FIELDS.CREATED_AT.name);

export const getUpdatedAt = (consultationType) =>
  get(consultationType, FIELDS.UPDATED_AT.name);

export const getCreatedBy = (consultationType) =>
  get(consultationType, FIELDS.CREATED_BY.name);

export const getUpdatedBy = (consultationType) =>
  get(consultationType, FIELDS.UPDATED_BY.name);

export const getArabicSubtitle = (consultationType) =>
  get(consultationType, FIELDS.ARABIC_SUBTITLE.name);

export const getArabicTitle = (consultationType) =>
  get(consultationType, FIELDS.ARABIC_TITLE.name);

export const getEnglishTitle = (consultationType) =>
  get(consultationType, FIELDS.ENGLISH_TITLE.name);

export const getEnglishSubtitle = (consultationType) =>
  get(consultationType, FIELDS.ENGLISH_SUBTITLE.name);

export const getIsAssignDoctorOnBooking = (consultationType) =>
  get(consultationType, FIELDS.ASSIGN_DOCTOR_ON_BOOKING.name);

export const getDisplayOrder = (consultationType) =>
  get(consultationType, FIELDS.DISPLAY_ORDER.name);

export const getSlotDuration = (consultationType) =>
  get(consultationType, FIELDS.SLOT_DURATION.name);

export const getDoctorIds = (consultationType) =>
  get(consultationType, FIELDS.DOCTOR_IDS.name);

export const getIsFocused = (consultationType) =>
  get(consultationType, FIELDS.FOCUSED.name);

export const getConsultationTypeConfigurations = (consultationType) =>
  get(consultationType, FIELDS.CONSULTATION_TYPE_CONFIGURATIONS.name);

export const getPrice = (consultationType) =>
  get(consultationType, FIELDS.PRICE.name);

export const getIsVisibleForUsers = (consultationType) =>
  get(consultationType, FIELDS.IS_VISIBLE_FOR_USERS.name);
