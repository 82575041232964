import { all, put, takeLatest } from 'redux-saga/effects';
import { Message } from 'design-system';
import { navigate } from 'routing/utils';

import {
  consultationTypeModelActions,
  consultationTypeModelActionTypes,
} from 'model/consultationType';
import * as actions from './actions';
import * as types from './actionTypes';

export function* getConsultationTypeById({ id }) {
  yield put(actions.setFetchingConsultationType(true));
  yield put(consultationTypeModelActions.fetchConsultationTypeById(id));
}

export function* getConsultationTypes() {
  yield put(actions.setFetchingConsultationType(true));
  yield put(consultationTypeModelActions.fetchConsultationTypes());
}

export function* updateConsultationType({ id, payload }) {
  yield put(actions.setIsSubmittingConsultationType(true));
  yield put(consultationTypeModelActions.updateConsultationType(id, payload));
}

export function* consultationTypeReceived({ consultationType, id }) {
  yield put(actions.consultationTypeReceived(consultationType, id));
  yield put(actions.setFetchingConsultationType(false));
}

export function* consultationTypesReceived({ consultationTypes }) {
  yield put(actions.consultationTypesReceived(consultationTypes));
  yield put(actions.setFetchingConsultationType(false));
}

export function* createConsultationType({ payload }) {
  yield put(actions.setIsSubmittingConsultationType(true));
  yield put(consultationTypeModelActions.createConsultationType(payload));
}

export function* consultationTypeCreateResponded({ ok, response }) {
  yield put(actions.setIsSubmittingConsultationType(false));

  if (ok) {
    navigate(`/consultation-type/${response?.data?.id}`);
    // Why settimout? When we change the route using location.href,
    // the context changes and hides the Message, unless we add it to event loop
    // Only then, it will be executed after the routing is done
    setTimeout(() => {
      Message.success('Consultation type has been created successfully');
    }, 0);
  }
}

export function* consultationTypeUpdateResponded({ ok }) {
  yield put(actions.setIsSubmittingConsultationType(false));
  if (ok) {
    Message.success('Consultation type has been updated successfully');
  }
}

export function* getConsultationTypeConfigurations({ consultationTypeId }) {
  yield put(actions.setFetchingConsultationTypeConfigurations(true));
  yield put(
    consultationTypeModelActions.fetchConsultationTypeConfigurations(
      consultationTypeId
    )
  );
}

export function* consultationTypeConfigurationsReceived({
  consultationTypeConfigurations,
  consultationTypeId,
}) {
  yield put(
    actions.consultationTypeConfigurationsReceived(
      consultationTypeConfigurations,
      consultationTypeId
    )
  );
  yield put(actions.setFetchingConsultationTypeConfigurations(false));
}

export function* addConsultationTypeConfiguration({ payload }) {
  yield put(actions.setIsSubmittingConsultationTypeConfiguration(true));
  yield put(
    consultationTypeModelActions.addConsultationTypeConfiguration(payload)
  );
}

export function* updateConsultationTypeConfiguration({ id, payload }) {
  yield put(actions.setIsSubmittingConsultationTypeConfiguration(true));
  yield put(
    consultationTypeModelActions.updateConsultationTypeConfiguration(
      id,
      payload
    )
  );
}

export function* deleteConsultationTypeConfiguration({ id }) {
  yield put(actions.setIsSubmittingConsultationTypeConfiguration(true));
  yield put(
    consultationTypeModelActions.deleteConsultationTypeConfiguration(id)
  );
}

export function* consultationTypeConfigurationDeleteResponded({ ok }) {
  yield put(actions.setIsSubmittingConsultationTypeConfiguration(false));
  if (ok) {
    Message.success(
      'Consultation type configuration has been deleted successfully'
    );
  }
}

export function* consultationTypeConfigurationAddResponded({ ok }) {
  yield put(actions.setIsSubmittingConsultationTypeConfiguration(false));
  if (ok) {
    Message.success(
      'Consultation type configuration has been added successfully'
    );
  }
}

export function* consultationTypeConfigurationUpdateResponded({ ok }) {
  yield put(actions.setIsSubmittingConsultationTypeConfiguration(false));
  if (ok) {
    Message.success(
      'Consultation type configuration has been updated successfully'
    );
  }
}

export default function* consultationTypeSaga() {
  yield all([
    takeLatest(types.GET_CONSULTATION_TYPE_BY_ID, getConsultationTypeById),
    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_RECEIVED,
      consultationTypeReceived
    ),
    takeLatest(types.GET_CONSULTATION_TYPES, getConsultationTypes),
    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPES_RECEIVED,
      consultationTypesReceived
    ),
    takeLatest(
      types.GET_CONSULTATION_TYPE_CONFIGURATIONS,
      getConsultationTypeConfigurations
    ),
    takeLatest(types.CREATE_CONSULTATION_TYPE, createConsultationType),
    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_CREATE_RESPONDED,
      consultationTypeCreateResponded
    ),
    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_CONFIGURATIONS_RECEIVED,
      consultationTypeConfigurationsReceived
    ),
    takeLatest(types.UPDATE_CONSULTATION_TYPE, updateConsultationType),
    takeLatest(
      types.ADD_CONSULTATION_TYPE_CONFIGURATION,
      addConsultationTypeConfiguration
    ),

    takeLatest(
      types.UPDATE_CONSULTATION_TYPE_CONFIGURATION,
      updateConsultationTypeConfiguration
    ),

    takeLatest(
      types.DELETE_CONSULTATION_TYPE_CONFIGURATION,
      deleteConsultationTypeConfiguration
    ),

    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_CONFIGURATION_ADD_RESPONDED,
      consultationTypeConfigurationAddResponded
    ),

    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_CONFIGURATION_UPDATE_RESPONDED,
      consultationTypeConfigurationUpdateResponded
    ),

    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_UPDATE_RESPONDED,
      consultationTypeUpdateResponded
    ),

    takeLatest(
      consultationTypeModelActionTypes.CONSULTATION_TYPE_CONFIGURATION_DELETE_RESPONDED,
      consultationTypeConfigurationDeleteResponded
    ),
  ]);
}
