import get from 'lodash/get';
import { createSelector } from 'reselect';

export const consultationModelSelector = (state) =>
  get(state, 'model.consultation', {});

export const getConsultation = createSelector(
  consultationModelSelector,
  (results) => get(results, 'view')
);
