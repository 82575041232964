import { all, put, take, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import {
  prescriptionModelActions,
  prescriptionModelActionTypes,
} from 'model/prescription';

import * as actions from './actions';
import * as types from './actionTypes';

export function* createPrescription({ payload }) {
  yield put(actions.setSubmittingPrescription(true));
  yield put(prescriptionModelActions.createPrescription(payload));
}

export function* createPatientPrescriptionDocument({
  payload,
  userId,
  successCallback,
}) {
  yield put(actions.setCreatingPatientPrescriptionDocument(true));
  yield put(
    prescriptionModelActions.createPatientPrescriptionDocument(payload)
  );

  const { response, ok } = yield take(
    prescriptionModelActionTypes.PATIENT_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.patientPrescriptionDocumentCreated(response, userId));
    successCallback?.(response);
  } else {
    yield put(actions.patientPrescriptionDocumentCreated());
  }

  yield put(actions.setCreatingPatientPrescriptionDocument(false));
}

export function* submitAllPrescriptions({ payload, consultationId }) {
  yield put(actions.setSubmittingAllPrescriptions(true));
  yield put(
    prescriptionModelActions.submitAllPrescriptions(payload, consultationId)
  );
  const { response, ok } = yield take(
    prescriptionModelActionTypes.ALL_PRESCRIPTIONS_SUBMISSION_RESPONDED
  );

  if (ok) {
    yield put(actions.allPrescriptionsSubmited(response));
  }

  yield put(actions.setSubmittingAllPrescriptions(false));
}

export function* createEPrescriptionDocument({ payload }) {
  yield put(actions.setSubmittingPrescription(true));
  yield put(prescriptionModelActions.createEPrescriptionDocument(payload));

  const { response, ok } = yield take(
    prescriptionModelActionTypes.E_PRESCRIPTION_DOCUMENT_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.ePrescriptionDocumentCreated(response));
  }

  yield put(actions.setSubmittingPrescription(false));
}

export function* createPatientPrescription({ payload, callbacks }) {
  yield put(actions.setSubmittingPatientPrescription(true));
  yield put(prescriptionModelActions.createPatientPrescription(payload));

  const { response, ok } = yield take(
    prescriptionModelActionTypes.PATIENT_PRESCRIPTION_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.patientPrescriptionCreated(response, response.userId));
    callbacks?.success?.(response);
  }

  callbacks?.finally?.();
  yield put(actions.setSubmittingPatientPrescription(false));
}

export function* updatePatientPrescription({
  userId,
  consultationId,
  payload,
  callbacks,
}) {
  yield put(actions.setUpdatingPatientPrescription(true));
  yield put(
    prescriptionModelActions.updatePatientPrescription(
      userId,
      consultationId,
      payload
    )
  );

  const { response, ok } = yield take(
    prescriptionModelActionTypes.PATIENT_PRESCRIPTION_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.patientPrescriptionUpdated(response, response.userId));
    callbacks?.success?.(response);
  }

  callbacks?.finally?.();
  yield put(actions.setUpdatingPatientPrescription(false));
}

export function* fetchPatientPrescription({
  userId,
  consultationId,
  callbacks,
}) {
  yield put(actions.setFetchingPatientPrescription(true));
  yield put(
    prescriptionModelActions.fetchPatientPrescription(userId, consultationId)
  );

  const { ok, response } = yield take(
    prescriptionModelActionTypes.PATIENT_PRESCRIPTION_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.patientPrescriptionReceived(response, userId));
    callbacks?.success?.(response);
  }

  yield put(actions.setFetchingPatientPrescription(false));
  callbacks?.finally?.();

  return response;
}

export function* prescriptionCreationResponded({ ok }) {
  yield put(actions.setSubmittingPrescription(false));
  if (ok) {
    Message.success('Prescription has been sent successfully');
  }
}

export function* patientPrescriptionCreationResponded({ ok }) {
  yield put(actions.setSubmittingPrescription(false));
  if (ok) {
    Message.success('Prescription has been added successfully');
  }
}

export function* patientPrescriptionUpdateResponded({ ok }) {
  yield put(actions.setSubmittingPrescription(false));
  if (ok) {
    Message.success('Prescription has been updated successfully');
  }
}

export default function* prescriptionSaga() {
  yield all([
    takeLatest(types.CREATE_PRESCRIPTION, createPrescription),
    takeLatest(
      types.CREATE_E_PRESCRIPTION_DOCUMENT,
      createEPrescriptionDocument
    ),

    takeLatest(
      types.CREATE_PATIENT_PRESCRIPTION_DOCUMENT,
      createPatientPrescriptionDocument
    ),

    takeLatest(types.FETCH_PATIENT_PRESCRIPTION, fetchPatientPrescription),
    takeLatest(types.CREATE_PATIENT_PRESCRIPTION, createPatientPrescription),
    takeLatest(types.UPDATE_PATIENT_PRESCRIPTION, updatePatientPrescription),
    takeLatest(types.SUBMIT_ALL_PRESCRIPTIONS, submitAllPrescriptions),
    takeLatest(
      prescriptionModelActionTypes.PATIENT_PRESCRIPTION_CREATION_RESPONDED,
      patientPrescriptionCreationResponded
    ),
    takeLatest(
      prescriptionModelActionTypes.PATIENT_PRESCRIPTION_UPDATE_RESPONDED,
      patientPrescriptionUpdateResponded
    ),
    takeLatest(
      prescriptionModelActionTypes.PRESCRIPTION_CREATION_RESPONDED,
      prescriptionCreationResponded
    ),
  ]);
}
