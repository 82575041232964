import * as types from './actionTypes';

export const createMedicine = (payload) => ({
  type: types.CREATE_MEDICINE,
  payload,
});

export const updateMedicine = (id, payload) => ({
  type: types.PATCH_MEDICINE,
  payload,
  id,
});
export const medicineCreated = (medicine) => ({
  type: types.MEDICINE_CREATED,
  medicine,
});

export const setSubmittingMedicine = (isSubmitting) => ({
  type: types.SET_SUBMITTING_MEDICINE,
  isSubmitting,
});

export const medicineReceived = (medicine) => ({
  type: types.MEDICINE_RECEIVED,
  medicine,
});
export const setFetching = (isFetching) => ({
  type: types.SET_FETCHING,
  isFetching,
});

export const fetchMedicine = (id) => ({
  type: types.FETCH_MEDICINE,
  id,
});
