import * as types from './actionTypes';

export const updateLabRequest = (id, payload) => ({
  type: types.UPDATE_LAB_REQUEST,
  payload,
  id,
});

export const patchLabRequest = (id, payload) => ({
  type: types.PATCH_LAB_REQUEST,
  payload,
  id,
});

export const createLabRequest = (payload, redirect) => ({
  type: types.CREATE_LAB_REQUEST,
  payload,
  redirect,
});

export const createELabRequestDocument = (payload) => ({
  type: types.CREATE_E_LAB_REQUEST_DOCUMENT,
  payload,
});

export const fetchLabRequestById = (id) => ({
  type: types.FETCH_LAB_REQUEST_BY_ID,
  id,
});

export const fetchLabPackages = () => ({
  type: types.FETCH_LAB_PACKAGES,
});

export const fetchPatientELabRequest = (userId, consultationId) => ({
  type: types.FETCH_PATIENT_E_LAB_REQUEST,
  userId,
  consultationId,
});

export const createPatientELabRequest = (userId, consultationId, payload) => ({
  type: types.CREATE_PATIENT_E_LAB_REQUEST,
  userId,
  payload,
  consultationId,
});

export const updatePatientELabRequest = (userId, consultationId, payload) => ({
  type: types.UPDATE_PATIENT_E_LAB_REQUEST,
  userId,
  payload,
  consultationId,
});

export const deletePatientELabRequest = (userId, consultationId) => ({
  type: types.DELETE_PATIENT_E_LAB_REQUEST,
  userId,
  consultationId,
});

export const submitPatientELabRequests = (consultationId, payload) => ({
  type: types.SUBMIT_PATIENT_E_LAB_REQUESTS,
  consultationId,
  payload,
});

export const createPatientLabRequestDocument = (payload) => ({
  type: types.CREATE_PATIENT_LAB_REQUEST_DOCUMENT,
  payload,
});

export const labRequestCreationResponded = (ok, response, redirect) => ({
  type: types.LAB_REQUEST_CREATION_RESPONDED,
  response,
  ok,
  redirect,
});

export const eLabRequestDocumentCreationResponded = (ok, response) => ({
  type: types.E_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED,
  response,
  ok,
});

export const labRequestReceived = (labRequest, id) => ({
  type: types.LAB_REQUEST_RECEIVED,
  labRequest,
  id,
});

export const labRequestUpdateResponded = (ok, response, id) => ({
  type: types.LAB_REQUEST_UPDATE_RESPONDED,
  response,
  ok,
  id,
});

export const labPackagesReceived = (labPackages) => ({
  type: types.LAB_PACKAGES_RECEIVED,
  labPackages,
});

export const getLabRequestByConsultationId = (consultationId) => ({
  type: types.GET_LAB_REQUEST_BY_CONSULTATION_ID,
  consultationId,
});

export const labRequestListReceived = (labTests) => ({
  type: types.CONSULTATION_LABS_RECEIVED,
  labTests,
});

export const patientELabRequestFetchResponded = (ok, response) => ({
  type: types.PATIENT_E_LAB_REQUEST_FETCH_RESPONDED,
  response,
  ok,
});

export const patientELabRequestCreationResponded = (ok, response) => ({
  type: types.PATIENT_E_LAB_REQUEST_CREATION_RESPONDED,
  response,
  ok,
});

export const patientELabRequestUpdateResponded = (ok, response) => ({
  type: types.PATIENT_E_LAB_REQUEST_UPDATE_RESPONDED,
  response,
  ok,
});

export const patientELabRequestDeleteResponded = (ok, response) => ({
  type: types.PATIENT_E_LAB_REQUEST_DELETE_RESPONDED,
  response,
  ok,
});

export const patientELabRequestsSubmissionResponded = (ok, response) => ({
  type: types.PATIENT_E_LAB_REQUESTS_SUBMISSION_RESPONDED,
  response,
  ok,
});

export const patientLabRequestDocumentCreationResponded = (ok, response) => ({
  type: types.PATIENT_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED,
  response,
  ok,
});

export const createPatientsLabRequestDocument = (labId, payload) => {
  return {
    type: types.CREATE_PATIENTS_LAB_TEST_DOCUMENTS,
    labId,
    payload,
  };
};

export const patientsLabRequestDocumentCreationResponded = (ok, response) => ({
  type: types.PATIENTS_LAB_REQUEST_DOCUMENT_CREATION_RESPONDED,
  response,
  ok,
});
