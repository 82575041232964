import React, { useState, useEffect } from 'react';
import CircularProgress from '../Progress';

import Backdrop from '../Backdrop';

const PageSpinner = ({ open }) => {
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <Backdrop
      // TODO: setup zindex in theme. (dialog = 1300)
      sx={{ color: (theme) => theme.palette.common.white, zIndex: 1300 }}
      open={isOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default PageSpinner;
