import format from 'date-fns/format';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import isSameDay from 'date-fns/isSameDay';
import React, { useState, useEffect } from 'react';
import formatRelative from 'date-fns/formatRelative';

import Grid from '../Grid';
import Chip from '../Chip';
import Divider from '../Divider';
import Skeleton from '../Skeleton';
import Typography from '../Typography';
import Avatar, { avatarUtils } from '../Avatar';

const ChatView = ({ messages, loading, emptyView }) => {
  const [parsedMessages, setParsedMessages] = useState([]);

  useEffect(() => {
    if (messages) {
      const today = new Date();
      let latestDate = new Date();

      const processedMessages = messages.map((message, idx) => {
        let day;
        const createdAtDate = new Date(message.createdAt);
        const isSameAsLatestDate = isSameDay(latestDate, createdAtDate);

        if (idx === 0 || !isSameAsLatestDate) {
          latestDate = createdAtDate;
          day = capitalize(
            formatRelative(createdAtDate, today).split('at')[0].trim()
          );
        }

        return {
          ...message,
          day,
          time: format(createdAtDate, 'p'),
          author: message.author || 'Anonymous',
        };
      });

      setParsedMessages(processedMessages);
    }
  }, [messages]);

  if (loading) {
    return (
      <Grid item container column>
        <Grid item container justifyContent="center" sx={{ mb: 1 }}>
          <Grid item>
            <Skeleton
              variant="rectangle"
              width={62}
              height={32}
              sx={{ borderRadius: 32 }}
            />
          </Grid>
        </Grid>
        <Grid item container column flexWrap="nowrap" sx={{ mb: 1 }}>
          <Grid item container flexWrap="nowrap" alignItems="center">
            <Grid item sx={{ mr: 1 }}>
              <Skeleton variant="circular" width={40} height={40} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={120} />
            </Grid>
          </Grid>
          <Grid item sx={{ pl: 6 }}>
            <Skeleton />
            <Skeleton />
            <Skeleton width="60%" />
          </Grid>
        </Grid>
        <Grid item justifyContent="center" sx={{ mb: 1 }}>
          <Grid item sx={{ mb: 1 }}>
            <Divider>
              <Skeleton
                variant="rectangle"
                width={62}
                height={32}
                sx={{ borderRadius: 32 }}
              />
            </Divider>
          </Grid>
        </Grid>
        <Grid item container column flexWrap="nowrap" sx={{ mb: 1 }}>
          <Grid item container flexWrap="nowrap" alignItems="center">
            <Grid item sx={{ mr: 1 }}>
              <Skeleton variant="circular" width={40} height={40} />
            </Grid>
            <Grid item>
              <Skeleton variant="text" width={100} />
            </Grid>
          </Grid>
          <Grid item sx={{ pl: 6 }}>
            <Skeleton />
            <Skeleton width="40%" />
            <Skeleton />
            <Skeleton width="60%" />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container column flexWrap="nowrap" sx={{ height: '100%' }}>
      {parsedMessages &&
        parsedMessages.map((message, idx) => {
          return (
            <Grid item container column>
              {/* Date/Day */}
              {idx === 0 && (
                <Grid item container justifyContent="center" sx={{ mb: 1 }}>
                  <Grid item>
                    <Chip label={message.day} soak="light" />
                  </Grid>
                </Grid>
              )}
              {/* Divider */}
              {idx > 0 && message.day && (
                <Grid item sx={{ mb: 1 }}>
                  <Divider>
                    <Chip label={message.day} soak="light" />
                  </Divider>
                </Grid>
              )}

              {/* Message combo */}
              <Grid item container column flexWrap="nowrap" sx={{ mb: 1 }}>
                {/* Header */}
                <Grid item container flexWrap="nowrap" alignItems="center">
                  <Grid item sx={{ mr: 1 }}>
                    <Avatar
                      {...avatarUtils.textToAvatarProps({
                        name: message.author,
                      })}
                    />
                  </Grid>
                  <Grid item container>
                    <Grid item>
                      <Typography variant="l5" sx={{ color: 'grey.600' }}>
                        {message.author}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ ml: 1 }}>
                      <Typography variant="p4" sx={{ color: 'grey.500' }}>
                        {message.time}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Body */}
                <Grid item sx={{ pl: 6 }}>
                  <Typography
                    variant="p4"
                    dangerouslySetInnerHTML={{
                      __html: message.content,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      {isEmpty(parsedMessages) && (
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: '100%' }}
        >
          <Grid item>{emptyView}</Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ChatView;
