import { combineReducers } from 'redux';

import { modelReducer } from './model';
import { domainReducer } from './domain';

const reducer = combineReducers({
  model: modelReducer,
  domain: domainReducer,
});

export default reducer;
