import ShareIcon from '@mui/icons-material/Share';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

import { userAddressGetters as getters } from 'domain/user';
import {
  If,
  Else,
  Grid,
  Button,
  Tooltip,
  Typography,
  IconButton,
  Conditionally,
} from 'design-system';

import LocationShare from '../LocationShare';
import UserAddressFormDialog from '../UserAddressFormDialog';

const UserAddressField = forwardRef(
  (
    {
      value,
      userId,
      loading,
      onChange,
      permissions,
      onEditOrNew,
      amendSharedMessage,
    },
    ref
  ) => {
    const { add = true, edit = true, share = true } = permissions;

    const [isShareDialogOpen, setShareDialogOpen] = useState(false);
    const [isAddressDialogOpen, setAddressDialogOpen] = useState(false);

    const onClosed = () => {
      setAddressDialogOpen(false);
    };

    const onConfirmed = (newValue) => {
      onChange(newValue);
    };

    useImperativeHandle(ref, () => ({
      open() {
        setAddressDialogOpen(true);
      },
      close() {
        setAddressDialogOpen(false);
      },
    }));

    return (
      <>
        <Grid container item sx={{ width: '100%' }}>
          <Conditionally>
            <If condition={loading || value}>
              <Grid
                container
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="l5" loading={loading && { width: 300 }}>
                    {getters.getFormattedAddress(value)}
                  </Typography>
                </Grid>
                {!loading && edit && share && (
                  <Grid item container flexWrap="nowrap" sx={{ width: 'auto' }}>
                    {edit && (
                      <Grid item>
                        <IconButton
                          aria-label="edit"
                          onClick={
                            onEditOrNew
                              ? onEditOrNew
                              : () => setAddressDialogOpen(true)
                          }
                        >
                          <Tooltip
                            title="Edit Delivery Address"
                            placement="top"
                          >
                            <EditLocationAltIcon color="primary" />
                          </Tooltip>
                        </IconButton>
                      </Grid>
                    )}
                    {share && (
                      <>
                        <Grid item>
                          <IconButton
                            aria-label="share"
                            onClick={() => setShareDialogOpen(true)}
                          >
                            <Tooltip
                              title="Share Delivery Address"
                              placement="top"
                            >
                              <ShareIcon color="secondary" />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                        <LocationShare
                          address={value}
                          open={isShareDialogOpen}
                          onClose={() => setShareDialogOpen(false)}
                          amendMessage={amendSharedMessage}
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </If>
            <Else>
              <Conditionally>
                <If condition={add}>
                  <Button
                    size="small"
                    color="primary"
                    variant="text"
                    onClick={
                      onEditOrNew
                        ? onEditOrNew
                        : () => setAddressDialogOpen(true)
                    }
                  >
                    <Typography variant="l5">
                      Select delivery address
                    </Typography>
                  </Button>
                </If>
                <Else>
                  <Typography variant="l5">
                    No delivery address selected
                  </Typography>
                </Else>
              </Conditionally>
            </Else>
          </Conditionally>
        </Grid>
        {isAddressDialogOpen && (
          <UserAddressFormDialog
            value={value}
            userId={userId}
            onClose={onClosed}
            onConfirm={onConfirmed}
            open={isAddressDialogOpen}
          />
        )}
      </>
    );
  }
);

export default UserAddressField;
