import * as types from './actionTypes';

const initialState = {
  isSubmitting: false,
};

const medicineReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SUBMITTING_MEDICINE: {
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      };
    }
    case types.MEDICINE_RECEIVED: {
      return {
        ...state,
        view: {
          ...(action.medicine || {}),
        },
      };
    }
    case types.SET_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    default:
      return state;
  }
};

export default medicineReducer;
