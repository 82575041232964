import keys from 'lodash/keys';
import values from 'lodash/values';
import format from 'date-fns/format';

import { doctorGetters } from 'domain/doctor';
import { calculateAge } from 'core/utils/date';
import { dateFormat } from 'constants/dateTime';
import { medicineUtils } from 'domain/medicine';
import { userGetters, userConstants } from 'domain/user';
import { consultationGetters } from 'domain/consultation';
import { prescriptionConstants } from 'domain/prescription';
import { REVIEW_OF_SYSTEMS } from 'domain/outcome/constants';
import { insuranceProviderGetters } from 'domain/insuranceProvider';
import {
  DIAGNOSIS_TYPES,
  COMPLAINT_FIELDS,
  ALLERGIES_SECTION_FIELDS,
  COMPLAINT_SEVERITY_BY_VALUE,
  FIELDS as DOCTOR_NOTES_FIELDS,
} from 'domain/doctorNotes/constants';

import { SECTIONS } from '../../constants';

const { FIELDS } = prescriptionConstants;
const { GENDER, ALLERGIES_LIST_TYPE, ALLERGIES_SEVERITY_LIST_TYPE } =
  userConstants;

// Use the common parts in preparePrescription, prepareLab and prepareAssessmentSheet
const prepareDocumentPayload = ({
  owner,
  doctor,
  patient,
  consultation,
  patientFormData,
  patientUserProfile,
  patientHealthProfile,
}) => {
  const patientUserId = patient.userId;
  const diagnosisObjects = patientFormData?.[SECTIONS.DIAGNOSIS.formField];

  const medicines =
    patientFormData?.[SECTIONS.PRESCRIPTION.formField]?.medicines;
  const labRequest = patientFormData?.[SECTIONS.LAB_REQUEST.formField];

  let mappedDiagnosisObject;
  if (Array.isArray(diagnosisObjects?.diagnosis)) {
    mappedDiagnosisObject = {
      primaryDiagnosis: [],
      secondaryDiagnosis: [],
    };

    diagnosisObjects.diagnosis.forEach((diagnosis) => {
      if (diagnosis.type === DIAGNOSIS_TYPES.PRIMARY.key) {
        mappedDiagnosisObject.primaryDiagnosis.push(diagnosis.code);
      } else if (diagnosis.type === DIAGNOSIS_TYPES.SECONDARY.key) {
        mappedDiagnosisObject.secondaryDiagnosis.push(diagnosis.code);
      }
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(
      'diagnosisObjects.diagnosis is not an array or is not defined.'
    );
  }
  const payload = {
    consultation: {
      id: consultationGetters.getId(consultation),
      startTime: consultationGetters.getStartTime(consultation),
    },
    doctor: {
      id: userGetters.getId(doctor),
      fullName: userGetters.getFullName(doctor),
      licenseNumber: doctorGetters.getLicenseNumber(doctor),
      stampFile: doctorGetters.getStampFile(doctor),
      signatureFile: doctorGetters.getSignatureFile(doctor),
    },
    patient: {
      userId: patientUserId,
      fullName: userGetters.getFullName(patientUserProfile),
      cardNumber: userGetters.getInsuranceCardNumber(patientUserProfile),
      address: userGetters.getCity(owner),
      phoneNumber: userGetters.getPhoneNumber(owner),
      age: patientHealthProfile?.dob
        ? calculateAge(new Date(patientHealthProfile.dob)).ageDisplay
        : null,
      weight: patientHealthProfile?.weight,
      height: patientHealthProfile?.height,
      gender: patientHealthProfile?.gender
        ? GENDER[patientHealthProfile.gender]?.label ||
          patientHealthProfile.gender
        : null,
      insuranceProviderName: insuranceProviderGetters.getName(
        userGetters.getInsuranceProvider(patientUserProfile)
      ),
      dateOfBirth: patientHealthProfile?.dob
        ? format(new Date(patientHealthProfile.dob), dateFormat)
        : null,
      identityNumber: userGetters.getIdentityNumber(patientUserProfile),
      nationality: userGetters.getNationality(patientUserProfile),
    },
    allergies: patientHealthProfile?.allergies?.map((allergy) => {
      const allergySeverity = allergy[ALLERGIES_SECTION_FIELDS.SEVERITY.key];
      const allergyType = allergy[ALLERGIES_SECTION_FIELDS.TYPE.key];

      return {
        ...allergy,
        [ALLERGIES_SECTION_FIELDS.SEVERITY.key]:
          ALLERGIES_SEVERITY_LIST_TYPE[allergySeverity]?.label ||
          allergySeverity,
        [ALLERGIES_SECTION_FIELDS.TYPE.key]:
          ALLERGIES_LIST_TYPE[allergyType]?.label || allergyType,
      };
    }),
    diagnosis: mappedDiagnosisObject,
    [FIELDS.MEDICINES.name]:
      medicines?.map((item) => ({
        medicineId: item.medicineId || item.medicine?.id,
        genericSummary: item.genericSummary || item.medicine?.genericSummary,
        routeOfAdmin: item.routeOfAdmin || item.roa,
        use: {
          value: item.use.value,
          unit: item.use.unit,
          displayValue: medicineUtils.toReadableUse(
            item.use.value,
            item.use.unit
          ),
        },
        frequency: {
          value: item.frequency.value,
          unit: item.frequency.unit,
          displayValue: medicineUtils.toReadableFrequency(
            item.frequency.value,
            item.frequency.unit
          ),
        },
        medicineTiming: item.medicineTiming || item.timing,
        duration: {
          value: item.duration.value,
          unit: item.duration.unit,
          displayValue: medicineUtils.toReadableDuration(
            item.duration.value,
            item.duration.unit
          ),
        },
        totalQuantity: {
          value: item.totalQuantity.value,
          unit: item.totalQuantity.unit,
          displayValue: medicineUtils.toReadableQuantity(
            item.totalQuantity.value,
            item.totalQuantity.unit
          ),
        },
        refill: {
          value: item.refill.value,
          unit: item.refill.unit,
          displayValue: medicineUtils.toReadableDuration(
            item.refill.value,
            item.refill.unit
          ),
        },
        instructions: item.instructions,
        additionalInstructions: item.additionalInstructions,
      })) || [],
    labTests: labRequest?.labTests || [],
  };

  return payload;
};

export const prepareLabRequestDocumentPayload = ({
  owner,
  doctor,
  clinic,
  patient,
  consultation,
  patientFormData,
  patientUserProfile,
  patientHealthProfile,
}) => {
  const patientFormPayload = prepareDocumentPayload({
    owner,
    doctor,
    patient,
    consultation,
    patientFormData,
    patientUserProfile,
    patientHealthProfile,
  });
  const labRequest = patientFormData?.[SECTIONS.LAB_REQUEST.formField];

  const labRequestDocumentPayload = {
    ...patientFormPayload,
    clinic,
    labTests: labRequest?.labTests || [],
  };

  return labRequestDocumentPayload;
};

export const preparePrescriptionDocumentPayload = ({
  owner,
  doctor,
  clinic,
  patient,
  consultation,
  patientFormData,
  patientUserProfile,
  patientHealthProfile,
}) => {
  const patientFormPayload = prepareDocumentPayload({
    owner,
    doctor,
    patient,
    consultation,
    patientFormData,
    patientUserProfile,
    patientHealthProfile,
  });
  const payload = {
    ...patientFormPayload,
    clinic,
    [FIELDS.MEDICINES.name]: patientFormPayload.medicines,
  };

  return payload;
};

export const prepareAssessmentSheetPayload = ({
  owner,
  doctor,
  clinic,
  patient,
  consultation,
  patientFormData,
  patientUserProfile,
  patientHealthProfile,
}) => {
  const patientFormPayload = prepareDocumentPayload({
    owner,
    doctor,
    patient,
    consultation,
    patientFormData,
    patientUserProfile,
    patientHealthProfile,
  });
  const complaints = patientFormData?.[DOCTOR_NOTES_FIELDS.COMPLAINT.key].map(
    (complaint) => {
      const onsetPeriodValue =
        complaint[COMPLAINT_FIELDS.ONSET_PERIOD.key][
          COMPLAINT_FIELDS.ONSET_PERIOD.fields.VALUE.key
        ];
      const onsetPeriodUnit =
        complaint[COMPLAINT_FIELDS.ONSET_PERIOD.key][
          COMPLAINT_FIELDS.ONSET_PERIOD.fields.UNIT.key
        ];

      return {
        ...complaint,
        // Map 0...10 to labels
        [COMPLAINT_FIELDS.SEVERITY.key]:
          COMPLAINT_SEVERITY_BY_VALUE?.[
            complaint[COMPLAINT_FIELDS.SEVERITY.key]
          ]?.label || complaint[COMPLAINT_FIELDS.SEVERITY.key],
        // Parse unit to readable text e.g: 2 DAY -> 2 days
        [COMPLAINT_FIELDS.ONSET_PERIOD.key]: {
          ...complaint[COMPLAINT_FIELDS.ONSET_PERIOD.key],
          displayValue: medicineUtils.toReadableDuration(
            onsetPeriodValue,
            onsetPeriodUnit
          ),
        },
      };
    }
  );
  const patientHistoryInformation =
    patientFormData?.[DOCTOR_NOTES_FIELDS.PATIENT_AND_FAMILY_HISTORY.key];
  const managementTreatmentPlan =
    patientFormData?.[DOCTOR_NOTES_FIELDS.MANAGEMENT_AND_TREATMENT_PLAN.key];
  const patientExaminationsReview =
    patientFormData?.[DOCTOR_NOTES_FIELDS.REVIEW_OF_SYSTEMS.key];
  const patientExaminations = [];

  keys(patientExaminationsReview).forEach((systemKey) => {
    const systemReview = patientExaminationsReview[systemKey];
    if (systemReview.status) {
      // Accumulate the data into the new object
      const updatedSystemReview = {
        status: systemReview?.status,
        remarks: systemReview?.remarks || '',
        examination: systemKey,
      };
      // Prepare patientExaminations payload
      patientExaminations.push({
        ...updatedSystemReview,
      });
    }
  });

  const reviewOfSystems = patientExaminations.map((systemReview) => ({
    examination:
      REVIEW_OF_SYSTEMS[systemReview?.examination]?.label?.toLowerCase() ||
      systemReview?.examination?.toLowerCase(),
    status: systemReview.status,
    remarks: systemReview.remarks || '',
  }));

  const payload = {
    ...patientFormPayload,
    clinic,
    doctorNotes: {
      complaints,
      patientHistoryInformation,
      managementTreatmentPlan,
      reviewOfSystems,
    },
    // We need them to create prescription and lab tests in the assessment sheet
    medicines: patientFormPayload.medicines || [],
    labTests: patientFormPayload.labTests,
  };

  return payload;
};

/**
 * Check if all sections are confirmed, by mapping SECTIONS against confirmedSections.
 * However, not all sections have to be confirmed,
 *  Prescription and LabRequest sections have to be confirmed only if their corresponding outcome is selected.
 *
 * @param {[key]: boolean} confirmedSections: A key-value object containing sectionKey and whether it's confirmed or not (boolean)
 * @param string[] selectedOutcomes: a list of all selected outcomes
 * @param boolean isNoShowSelected: A flag stating whether one of Doctor Private Notes that are mapped to NoShow outcome is selected
 */
export const areAllSectionsConfirmed = (
  confirmedSections = {},
  selectedOutcomes = [],
  isNoShowSelected
) => {
  if (isNoShowSelected) {
    // If one of the mapped Doctor Private Notes that are mapped to NoShow outcome is selected
    // Then only check DOCTOR_PRIVATE_NOTES section
    return confirmedSections[SECTIONS.DOCTOR_PRIVATE_NOTES.key];
  }

  // Otherwise, check the other sections
  return values(SECTIONS).every((section) => {
    const isConfirmed = confirmedSections[section.key];

    if (section.mappedOutcome) {
      // Special case: if the section has a mapped outcome =>
      if (selectedOutcomes.includes(section.mappedOutcome)) {
        // And if there's a selected outcome corresponding to the mapped outcome
        // then return whether the section is confirmed or not
        return isConfirmed;
      }

      // Otherwise, assume it's confirmed, because we don't really want to check it.
      return true;
    }

    // Default cause, returns whether the section is confirmed or not
    return isConfirmed;
  });
};

/**
 * If there's an assessment sheet, then it's a confirmed tab
 */
export const isPatientTabConfirmed = (patient) => {
  return patient?.assessmentSheet;
};
