import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as PATIENT_HISTORY } from './constants';

export const FETCH_PATIENT_HISTORY = `${DOMAIN}/${PATIENT_HISTORY}/FETCH_PATIENT_HISTORY`;

export const PATIENT_HISTORY_RECEIVED = `${DOMAIN}/${PATIENT_HISTORY}/PATIENT_HISTORY_RECEIVED`;

export const SET_FETCHING_PATIENT_HISTORY = `${DOMAIN}/${PATIENT_HISTORY}/SET_FETCHING_PATIENT_HISTORY`;

export const CLEAR_PATIENT_HISTORY = `${DOMAIN}/${PATIENT_HISTORY}/CLEAR_PATIENT_HISTORY`;
