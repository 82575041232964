import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState } from 'react';

import Button from '../Button';

/**
 * -data: List of data to show
 * -limit: The viewable items before expanding
 * -renderItem: How to render each item (JSX)
 * -prioritize: Which items have the priority to be shown within the limit. Example: (item) => !item.isDeleted
 */
const ShowMoreShowLess = ({ data, limit, renderItem, prioritize }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (isEmpty(data)) {
    return null;
  }

  const renderedList = useMemo(() => {
    if (prioritize) {
      // If we want to prioritize
      // Why [...data] ? Because sort function sorts in place
      const priorityList = [...data].sort(
        (itemA, itemB) => prioritize(itemB) - prioritize(itemA)
      );

      // Only show {limit} items
      return priorityList.slice(0, limit);
    }

    // If we don't want to prioritize, show the list as is
    return data.slice(0, limit);
  }, [data, limit, prioritize]);

  return (
    <>
      {(isExpanded ? data : renderedList).map((item, index) => {
        return renderItem({ item, index });
      })}
      {data.length > 5 && (
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? 'See Less' : 'See More'}
        </Button>
      )}
    </>
  );
};

export default ShowMoreShowLess;
