import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as MEDICINE } from './constants';

export const CREATE_MEDICINE = `${MODEL}/${MEDICINE}/CREATE_MEDICINE`;
export const MEDICINE_CREATION_RESPONDED = `${MODEL}/${MEDICINE}/MEDICINE_CREATION_RESPONDED`;

export const FETCH_MEDICNE = `${MODEL}/${MEDICINE}/FETCH_MEDICINE`;
export const MEDICINE_FETCH_RESPONDED = `${MODEL}/${MEDICINE}/MEDICINE_FETCH_RESPONDED`;

export const PATCH_MEDICINE = `${MODEL}/${MEDICINE}/PATCH_MEDICINE`;
export const MEDICINE_UPDATE_RESPONDED = `${MODEL}/${MEDICINE}/MEDICINE_UPDATE_RESPONDED`;
