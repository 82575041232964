import * as types from './actionTypes';

export const fetchInsuranceProviderList = () => ({
  type: types.FETCH_INSURANCE_PROVIDER_LIST,
});

export const insuranceProviderListReceived = (insuranceProviderList) => ({
  type: types.INSURANCE_PROVIDER_LIST_RECEIVED,
  insuranceProviderList,
});
