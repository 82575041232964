import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { pursueAction } from 'domain/app/actions';

import { userSelectors } from 'domain/user';
import MemberSelection from 'presentation/components/MemberSelection';
import {
  consultationActions,
  consultationSelectors,
  consultationActionTypes,
} from 'domain/consultation';
import {
  memberActions,
  memberSelectors,
  memberActionTypes,
} from 'domain/user/member';

const mapStateToProps = (state, ownProps) => {
  const user = userSelectors.getUser(state);
  const owner = isEmpty(user) ? ownProps.owner : user;
  const basicMembers = memberSelectors.getBasicMembers(state) || [];
  const allMembers = basicMembers.filter(
    (member) => !member.deletedFromOwnerAccount
  );

  const isSubmittingMembers =
    consultationSelectors.isSubmittingConsultationMembers(state);
  const isSubmitting = memberSelectors.isSubmittingMember(state);
  const isDeleting = consultationSelectors.isDeletingConsultationMember(state);
  const isFetching = memberSelectors.isFetchingBasicMembers(state);
  const members = consultationSelectors.getConsultationMembers(state);

  return {
    owner,
    members,
    allMembers,
    isDeleting,
    isFetching,
    isSubmitting,
    isSubmittingMembers,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onMembersUpdated: (callback) =>
    dispatch(
      pursueAction(
        consultationActionTypes.CONSULTATION_MEMBERS_UPDATED,
        callback
      )
    ),

  onMemberCreated: (callback) =>
    dispatch(pursueAction(memberActionTypes.MEMBER_CREATED, callback)),

  updateMembers: (consultationId, memberIds) =>
    dispatch(
      consultationActions.updateConsultationMembers(consultationId, memberIds)
    ),

  createMember: (ownerId, payload) =>
    dispatch(memberActions.createMember(ownerId, payload)),

  undeleteMember: (memberId) =>
    dispatch(memberActions.undeleteMember(memberId)),

  deleteMember: (consultationId, memberId) =>
    dispatch(
      consultationActions.deleteConsultationMember(consultationId, memberId)
    ),

  fetchAllMembers: (ownerId) =>
    dispatch(memberActions.fetchBasicMembers(ownerId)),

  clearMembers: () => dispatch(memberActions.basicMembersReceived([])),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  onMemberCreated: (callback) =>
    dispatchProps.onMemberCreated((action) => {
      // Execute the callback coming from MemberSelection
      callback(action);
      // Execute the callback coming from the consumer component
      if (ownProps.onMemberCreated) {
        ownProps.onMemberCreated(action.member);
      }
    }),
  onMembersUpdated: (callback) =>
    dispatchProps.onMembersUpdated((action) => {
      // Execute the callback coming from MemberSelection
      callback(action);
      // Execute the callback coming from the consumer component
      if (ownProps.onMembersUpdated) {
        ownProps.onMembersUpdated(action.consultationMembers);
      }
    }),
  ...stateProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MemberSelection);
