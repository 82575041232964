import * as types from './actionTypes';

const initialState = {
  list: [],
};

const clinicReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLINICS_RECEIVED: {
      return {
        ...state,
        list: action.clinics || [],
      };
    }
    default:
      return state;
  }
};

export default clinicReducer;
