import { all, call, takeLatest, put } from 'redux-saga/effects';
import * as types from './actionTypes';
import * as constants from './constants';
import httpClient from '../../core/httpClient';
import * as actions from './actions';

export function* fetchAnonymousUser({ id }) {
  const fetchAnonymousUserEndpoint = `${constants.ENDPOINT}/${id}`;
  const response = yield call(httpClient.get, fetchAnonymousUserEndpoint);
  yield put(actions.anonymousUserReceived(response.data));
}
export default function* anonymousUserModelSaga() {
  yield all([takeLatest(types.FETCH_ANONYMOUS_USER, fetchAnonymousUser)]);
}
