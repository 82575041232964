import { all, put, take, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import {
  doctorNotesModelActions,
  doctorNotesModelActionTypes,
} from 'model/doctorNotes';

import * as actions from './actions';
import * as types from './actionTypes';

export function* fetchDoctorNotes({ userId, consultationId, callbacks }) {
  yield put(actions.setFetchingDoctorNotes(true));
  yield put(doctorNotesModelActions.fetchDoctorNotes(userId, consultationId));

  const { ok, response } = yield take(
    doctorNotesModelActionTypes.DOCTOR_NOTES_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.doctorNotesReceived(response, userId));

    callbacks?.success?.(response);
  }

  yield put(actions.setFetchingDoctorNotes(false));
  callbacks?.finally?.();

  return response;
}

export function* submitDoctorNotes({
  userId,
  consultationId,
  payload,
  successCallback,
}) {
  yield put(actions.setSubmittingDoctorNotes(true));
  yield put(
    doctorNotesModelActions.submitDoctorNotes(userId, consultationId, payload)
  );

  const { ok, response } = yield take(
    doctorNotesModelActionTypes.DOCTOR_NOTES_SUBMISSION_RESPONDED
  );

  if (ok) {
    yield put(actions.doctorNotesSubmitted(response, userId));
    Message.success('Doctor notes have been updated successfully');

    successCallback?.(response);
  }

  yield put(actions.setSubmittingDoctorNotes(false));
}

export function* createPatientAssessmentSheet({
  payload,
  userId,
  successCallback,
}) {
  yield put(actions.setSubmittingDoctorNotes(true));
  yield put(doctorNotesModelActions.createPatientAssessmentSheet(payload));
  const { response, ok } = yield take(
    doctorNotesModelActionTypes.PATIENT_ASSESSMENT_SHEET_CREATION_RESPONDED
  );
  if (ok) {
    yield put(actions.patientAssessmentSheetCreated(response, userId));
    successCallback?.(response);
  } else {
    yield put(actions.patientAssessmentSheetCreated());
  }
  yield put(actions.setSubmittingDoctorNotes(false));
}

export default function* doctorNotesSaga() {
  yield all([
    takeLatest(types.SUBMIT_DOCTOR_NOTES, submitDoctorNotes),
    takeLatest(types.FETCH_DOCTOR_NOTES, fetchDoctorNotes),
    takeLatest(
      types.CREATE_PATIENT_ASSESSMENT_SHEET,
      createPatientAssessmentSheet
    ),
  ]);
}
