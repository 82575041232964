import get from 'lodash/get';
import { all, put, call, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import { getHealthEndpointV1, getPdfServiceEndpoint } from 'core/endpoint';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* fetchDoctorNotes({ userId, consultationId }) {
  try {
    const response = yield call(
      httpClient.get,
      constants.ENDPOINT(userId, consultationId),
      {
        baseURL: getHealthEndpointV1(),
      }
    );

    yield put(actions.doctorNotesFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorNotesFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* submitDoctorNotes({ userId, consultationId, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      constants.ENDPOINT(userId, consultationId),
      payload,
      {
        baseURL: getHealthEndpointV1(),
      }
    );

    yield put(actions.doctorNotesSubmissionResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.doctorNotesSubmissionResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* createPatientAssessmentSheet({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.ASSESSMENT_SHEET_PDF_ENDPOINT,
      payload,
      {
        responseType: 'blob',
        baseURL: getPdfServiceEndpoint(),
      }
    );
    const blob = response.data;
    yield put(
      actions.patientAssessmentSheetCreationResponded(true, {
        type: blob.type,
        url: URL.createObjectURL(blob),
      })
    );
  } catch (e) {
    yield put(
      actions.patientAssessmentSheetCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export default function* doctorNotesModelSaga() {
  yield all([
    takeLatest(types.FETCH_DOCTOR_NOTES, fetchDoctorNotes),
    takeLatest(types.SUBMIT_DOCTOR_NOTES, submitDoctorNotes),
    takeLatest(
      types.CREATE_PATIENT_ASSESSMENT_SHEET,
      createPatientAssessmentSheet
    ),
  ]);
}
