import { connect } from 'react-redux';

import appData from 'domain/app/HOCs/appData';
import {
  medicineActions as actions,
  medicineSelectors as selectors,
} from 'domain/medicine';

import MedicineView from './MedicineView';

const mapStateToProps = (state) => {
  const medicine = selectors.getMedicine(state);
  const isFetching = selectors.isFetching(state);
  const isSubmitting = selectors.isSubmittingMedicine(state);

  return {
    medicine,
    isFetching,
    isSubmitting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchMedicine: (id) => dispatch(actions.fetchMedicine(id)),
  updateMedicine: (id, payload) =>
    dispatch(actions.updateMedicine(id, payload)),
});

export default appData(
  connect(mapStateToProps, mapDispatchToProps)(MedicineView)
);
