/* eslint-disable import/prefer-default-export */
import jwtDecode from 'jwt-decode';
import { put } from 'redux-saga/effects';

import logger from 'core/logger';
import { LS_TOKEN, LS_MAIN_ROLE, LS_USER_ROLES } from 'core/auth/constants';

import * as actions from './actions';

export function* onTokenReceived(token) {
  try {
    const {
      role,
      userId,
      tenant,
      userRoles = [],
      allowedTenants = [],
    } = jwtDecode(token);
    const selectedTenant = localStorage.getItem('selectedTenant');

    localStorage.setItem(LS_TOKEN, token);
    localStorage.setItem(LS_MAIN_ROLE, role);
    localStorage.setItem(LS_USER_ROLES, JSON.stringify(userRoles));

    yield put(actions.setToken(token));
    yield put(actions.setUserId(userId));
    yield put(actions.setMainRole(role));
    yield put(actions.setUserRoles(userRoles));
    yield put(actions.setDefaultTenant(tenant));
    yield put(
      actions.setSelectedTenant(
        selectedTenant ? Number(selectedTenant) : selectedTenant
      )
    );
    yield put(actions.setAllowedTenants(allowedTenants));
  } catch (e) {
    logger.error(e);
  }
}
