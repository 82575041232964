import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import logger from 'core/logger';

import httpClient from 'core/httpClient';
import {
  getLabEndpoint,
  getLabEndpointV1,
  getPdfServiceEndpoint,
} from 'core/endpoint';

import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';

export function* updateLabRequest({ id, payload }) {
  try {
    const response = yield call(
      httpClient.put,
      [constants.ENDPOINT, id].join('/'),
      payload,
      {
        baseURL: getLabEndpoint(),
      }
    );

    yield put(actions.labRequestUpdateResponded(true, response.data, id));
  } catch (e) {
    yield put(
      actions.labRequestUpdateResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* patchLabRequest({ id, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      [constants.ENDPOINT, id].join('/'),
      payload,
      {
        baseURL: getLabEndpoint(),
      }
    );

    yield put(actions.labRequestUpdateResponded(true, response.data, id));
  } catch (e) {
    yield put(
      actions.labRequestUpdateResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* createLabRquest({ payload, redirect }) {
  try {
    const response = yield call(
      httpClient.post,
      `${constants.ENDPOINT}/admin`,
      payload,
      {
        baseURL: getLabEndpoint(),
      }
    );
    yield put(
      actions.labRequestCreationResponded(true, response.data, redirect)
    );
  } catch (e) {
    yield put(
      actions.labRequestCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* createELabRquestDocument({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.LAB_REQUEST_PDF_ENDPOINT,
      payload,
      {
        responseType: 'blob',
        baseURL: getPdfServiceEndpoint(),
      }
    );

    const blob = response.data;

    yield put(
      actions.eLabRequestDocumentCreationResponded(true, {
        type: blob.type,
        url: URL.createObjectURL(blob),
      })
    );
  } catch (e) {
    yield put(
      actions.eLabRequestDocumentCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* fetchLabRequestById({ id }) {
  const response = yield call(
    httpClient.get,
    [constants.ENDPOINT, id].join('/'),
    {
      baseURL: getLabEndpoint(),
    }
  );

  yield put(actions.labRequestReceived(response.data, id));
}

export function* fetchLabPackages() {
  try {
    const response = yield call(
      httpClient.get,
      constants.LAB_PACKAGES_ENDPOINT,
      {
        baseURL: getLabEndpoint(),
      }
    );

    yield put(actions.labPackagesReceived(response.data));
  } catch (err) {
    logger.error(
      `An error happened while fetching lab packages ${err.toString()}`
    );
  }
}

export function* getLabRequestByConsultationId({ consultationId }) {
  try {
    const response = yield call(
      httpClient.get,
      constants.CONSULTATION_LAB_LIST_ENDPOINT(consultationId),
      {
        baseURL: getLabEndpoint(),
      }
    );
    yield put(actions.labRequestListReceived(response.data));
  } catch (error) {
    logger.error(error);
  }
}

export function* fetchPatientELabRequest({ userId, consultationId }) {
  try {
    const response = yield call(
      httpClient.get,
      constants.PATIENT_ELAB_REQUEST_ENDPOINT,
      {
        baseURL: getLabEndpointV1(),
        params: {
          userId,
          consultationId,
        },
      }
    );
    yield put(actions.patientELabRequestFetchResponded(true, response.data));
  } catch (error) {
    actions.patientELabRequestFetchResponded(
      false,
      get(error, 'response.data.message')
    );
  }
}

export function* createPatientELabRequest({ userId, consultationId, payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.PATIENT_ELAB_REQUEST_ENDPOINT,
      payload,
      {
        baseURL: getLabEndpointV1(),
        params: {
          userId,
          consultationId,
        },
      }
    );
    yield put(actions.patientELabRequestCreationResponded(true, response.data));
  } catch (error) {
    actions.patientELabRequestCreationResponded(
      false,
      get(error, 'response.data.message')
    );
  }
}

export function* updatePatientELabRequest({ userId, consultationId, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      constants.PATIENT_ELAB_REQUEST_ENDPOINT,
      payload,
      {
        baseURL: getLabEndpointV1(),
        params: {
          userId,
          consultationId,
        },
      }
    );
    yield put(actions.patientELabRequestUpdateResponded(true, response.data));
  } catch (error) {
    actions.patientELabRequestUpdateResponded(
      false,
      get(error, 'response.data.message')
    );
  }
}

export function* deletePatientELabRequest({ userId, consultationId }) {
  try {
    const response = yield call(
      httpClient.delete,
      constants.PATIENT_ELAB_REQUEST_ENDPOINT,
      {
        baseURL: getLabEndpointV1(),
        params: {
          userId,
          consultationId,
        },
      }
    );
    yield put(actions.patientELabRequestDeleteResponded(true, response.data));
  } catch (error) {
    actions.patientELabRequestDeleteResponded(
      false,
      get(error, 'response.data.message')
    );
  }
}

export function* submitPatientELabRequests({ consultationId, payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.PATIENT_ELAB_REQUEST_SUBMISSION_ENDPOINT,
      payload,
      {
        baseURL: getLabEndpointV1(),
        params: {
          consultationId,
        },
      }
    );
    yield put(
      actions.patientELabRequestsSubmissionResponded(true, response.data)
    );
  } catch (error) {
    actions.patientELabRequestsSubmissionResponded(
      false,
      get(error, 'response.data.message')
    );
  }
}

export function* createPatientLabRquestDocument({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.PATIENT_LAB_REQUEST_PDF_ENDPOINT,
      payload,
      {
        responseType: 'blob',
        baseURL: getPdfServiceEndpoint(),
      }
    );

    const blob = response.data;

    yield put(
      actions.patientLabRequestDocumentCreationResponded(true, {
        type: blob.type,
        url: URL.createObjectURL(blob),
      })
    );
  } catch (e) {
    yield put(
      actions.patientLabRequestDocumentCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* createPatientsLabRequestDocument({ labId, payload }) {
  try {
    const response = yield call(
      httpClient.post,
      constants.PATIENTS_LAB_REQUEST_DOCUMENT_ENDPOINT(labId),
      payload,
      {
        baseURL: getLabEndpoint(),
      }
    );
    yield put(
      actions.patientsLabRequestDocumentCreationResponded(true, response.data)
    );
  } catch (error) {
    actions.patientLabRequestDocumentCreationResponded(
      false,
      get(error, 'response.data.message')
    );
  }
}
export default function* labModelSaga() {
  yield all([
    takeLatest(types.FETCH_LAB_REQUEST_BY_ID, fetchLabRequestById),
    takeLatest(types.UPDATE_LAB_REQUEST, updateLabRequest),
    takeLatest(types.PATCH_LAB_REQUEST, patchLabRequest),
    takeLatest(types.FETCH_LAB_PACKAGES, fetchLabPackages),
    takeLatest(types.CREATE_LAB_REQUEST, createLabRquest),
    takeLatest(types.CREATE_E_LAB_REQUEST_DOCUMENT, createELabRquestDocument),
    takeLatest(
      types.GET_LAB_REQUEST_BY_CONSULTATION_ID,
      getLabRequestByConsultationId
    ),
    // Patient ELab Request Sagas
    takeLatest(types.FETCH_PATIENT_E_LAB_REQUEST, fetchPatientELabRequest),
    takeLatest(types.CREATE_PATIENT_E_LAB_REQUEST, createPatientELabRequest),
    takeLatest(types.UPDATE_PATIENT_E_LAB_REQUEST, updatePatientELabRequest),
    takeLatest(types.DELETE_PATIENT_E_LAB_REQUEST, deletePatientELabRequest),
    takeLatest(types.SUBMIT_PATIENT_E_LAB_REQUESTS, submitPatientELabRequests),
    takeLatest(
      types.CREATE_PATIENT_LAB_REQUEST_DOCUMENT,
      createPatientLabRquestDocument
    ),
    takeLatest(
      types.CREATE_PATIENTS_LAB_TEST_DOCUMENTS,
      createPatientsLabRequestDocument
    ),
  ]);
}
