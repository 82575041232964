import get from 'lodash/get';

import { FIELDS } from './constants';

export const getId = (member) => get(member, FIELDS.ID.name);

export const getOwnerId = (member) => get(member, FIELDS.OWNER_ID.name);

export const getRelationshipWithOwner = (member) =>
  get(member, FIELDS.RELATIONSHIP_WITH_OWNER.name);

export const isDeletedFromOwnerAccount = (member) =>
  get(member, FIELDS.DELETED_FROM_OWNER_ACCOUNT.name);
