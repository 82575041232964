import { PACKAGE_NAME as MODEL } from '../constants';
import { PACKAGE_NAME as SCHEDULE } from './constants';

export const FETCH_DOCTOR_SCHEDULES = `${MODEL}/${SCHEDULE}/FETCH_DOCTOR_SCHEDULES`;
export const ADD_AVAILABILITY_RANGE = `${MODEL}/${SCHEDULE}/ADD_AVAILABILITY_RANGE`;
export const REMOVE_AVAILABILITY_RANGE = `${MODEL}/${SCHEDULE}/REMOVE_AVAILABILITY_RANGE`;
export const FETCH_DAILY_BOOKING_AVAILABILITY = `${MODEL}/${SCHEDULE}/FETCH_DAILY_BOOKING_AVAILABILITY`;
export const FETCH_DOCTOR_BOOKING_AVAILABILITY = `${MODEL}/${SCHEDULE}/FETCH_DOCTOR_BOOKING_AVAILABILITY`;
export const FETCH_DOCTOR_FREE_SCHEDULE_SLOTS = `${MODEL}/${SCHEDULE}/FETCH_DOCTOR_FREE_SCHEDULE_SLOTS`;
export const REMOVE_FREE_SLOT_RANGE = `${MODEL}/${SCHEDULE}/REMOVE_FREE_SLOT_RANGE`;

export const DOCTOR_SCHEDULES_FETCH_RESPONDED = `${MODEL}/${SCHEDULE}/DOCTOR_SCHEDULES_FETCH_RESPONDED`;
export const DOCTOR_BOOKING_AVAILABILITY_FETCH_RESPONDED = `${MODEL}/${SCHEDULE}/DOCTOR_BOOKING_AVAILABILITY_FETCH_RESPONDED`;
export const DAILY_BOOKING_AVAILABILITY_FETCH_RESPONDED = `${MODEL}/${SCHEDULE}/DAILY_BOOKING_AVAILABILITY_FETCH_RESPONDED`;
export const AVAILABILITY_RANGE_ADD_RESPONDED = `${MODEL}/${SCHEDULE}/AVAILABILITY_RANGE_ADD_RESPONDED`;
export const AVAILABILITY_RANGE_REMOVAL_RESPONDED = `${MODEL}/${SCHEDULE}/AVAILABILITY_RANGE_REMOVAL_RESPONDED`;
export const DOCTOR_FREE_SCHEDULES_SLOTS_FETCH_RESPONDED = `${MODEL}/${SCHEDULE}/DOCTOR_FREE_SCHEDULES_SLOTS_FETCH_RESPONDED`;
export const FREE_SLOT_RANGE_REMOVAL_RESPONDED = `${MODEL}/${SCHEDULE}/FREE_SLOT_RANGE_REMOVAL_RESPONDED`;
