import { connect } from 'react-redux';

import {
  consultationActions as actions,
  consultationSelectors as selectors,
} from 'domain/consultation';

import PatientOutcomeDropDown from './PatientOutcomeDropDown';

const mapStateToProps = (state) => {
  const isUpdating = selectors.isUpdatingConsultationPatient(state);

  return {
    isUpdating,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateConsultationPatient: (consultationId, patientId, payload) =>
    dispatch(
      actions.updateConsultationPatient(consultationId, patientId, payload)
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientOutcomeDropDown);
