import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  useGridSelector,
  useGridApiContext,
  gridPaginationModelSelector,
} from '@mui/x-data-grid';

import Pagination from '../Pagination';
import { gridPageCountSelector } from './gridSelectors';

const DataGridPagination = () => {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector);

  const { page = 0 } = paginationModel;

  return (
    Boolean(pageCount) && (
      <Pagination
        size={isXS ? 'small' : 'medium'}
        color="primary"
        page={page + 1}
        count={pageCount}
        onChange={(_event, value) => apiRef.current.setPage(value - 1)}
        {...(isXS
          ? {
              boundaryCount: 0,
            }
          : {})}
      />
    )
  );
};

export default DataGridPagination;
