import MenuIcon from '@mui/icons-material/Menu';
import { Outlet, useLocation } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';

import { useDeviceSize } from 'design-system/hooks';
import { Box, Grid, IconButton } from 'design-system';

import AppBar from '../AppBar';
import NavMenu from '../NavMenu';

const PageContainer = ({ routeChanged }) => {
  const location = useLocation();
  const { isMobile, isTablet } = useDeviceSize();
  const isSmall = useMemo(() => isMobile || isTablet, [isMobile, isTablet]);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    routeChanged(location);
  }, [location]);

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Grid container flexWrap="nowrap" sx={{ height: '100%' }}>
        <Grid
          item
          sx={{
            mr: {
              xs: 1,
              sm: 0,
            },
            backgroundColor: (theme) => theme.palette.common.white,
            ...(!isMobileMenuOpen && isSmall ? { display: 'none' } : {}),
          }}
        >
          <NavMenu
            {...(isSmall
              ? {
                  collapsed: false,
                  collapseToggleButton: false,
                }
              : {})}
          />
        </Grid>
        <Grid
          item
          px={{
            xs: 1,
            sm: 2,
            md: 4,
          }}
          mb={6}
          sx={{ width: '100%' }}
        >
          <Grid container column>
            <Grid item sx={{ mb: 2, ...(isSmall ? { display: 'flex' } : {}) }}>
              {isSmall && (
                <IconButton
                  size="large"
                  // aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              )}
              <AppBar
                sx={{
                  ...(isSmall ? { width: '100%' } : {}),
                }}
              />
            </Grid>
            <Grid item>
              <Box component="main" pb={2}>
                <Outlet />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PageContainer;
