import axios from 'axios';
import get from 'lodash/get';

import { Message } from 'design-system';
import { authUtils, authConstants } from 'core/auth';

import logger from '../logger';
import { getAdminEndpoint } from '../endpoint';

const NONREPORTABLES = ['canceled'];

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem(authConstants.LS_TOKEN);
  const conf = { ...config };

  conf.baseURL = config.baseURL || getAdminEndpoint();
  conf.headers = {
    ...conf.headers,
    Authorization: `Bearer ${token}`,
  };

  return conf;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    /**
     * Example on error.response:
     * {
     *  config, // axios config
     *  headers,
     *  request,
     *  status, // status code
     *  statusText,
     *  data : {
     *    message,
     *    path,
     *    timestamp,
     *    statusCode
     *  }
     * }
     */

    const isAutoErrorHandling = get(
      error,
      'response.config.isAutoErrorHandling',
      true
    );
    const isAutoAuthErrorHandling = get(
      error,
      'response.config.isAutoAuthErrorHandling',
      true
    );
    const errorBrowserMessage = error.message;
    const errorPath = error.response?.data?.path;
    const statusCode = error.response?.status || error?.status;

    const errorMessage = (() => {
      // Build error message
      const serverError =
        error.response?.data?.message?.message ||
        error.response?.data?.message?.objectOrError ||
        error.response?.data?.message;
      const message = serverError || errorBrowserMessage;

      return `${message} at ${errorPath || 'unknown'}. Error code: ${
        statusCode || 'unknown'
      }`;
    })();

    if (
      isAutoAuthErrorHandling &&
      ([401, 403].includes(statusCode) ||
        errorBrowserMessage === 'Unauthorized')
    ) {
      authUtils.logOutClean();
      window.location.href = '/login';

      return Promise.reject(error);
    }

    if (isAutoErrorHandling && !NONREPORTABLES.includes(errorBrowserMessage)) {
      Message.error(errorMessage);
      logger.error(errorMessage);
    }

    return Promise.reject(error);
  }
);
