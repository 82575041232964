import forEach from 'lodash/forEach';
import { connect } from 'react-redux';

import { appActions } from 'domain/app';
import { userSelectors } from 'domain/user';
import { insuranceProviderSelectors } from 'domain/insuranceProvider';

import {
  orderActions,
  orderConstants,
  ORDER_FIELDS as FIELDS,
  orderSelectors as selectors,
} from 'domain/order';

import OrdersList from './OrdersList';

const { LS_ORDER_FILTERS } = orderConstants;

const mapStateToProps = (state) => {
  const pharmacistsMap = {};
  const driversFullNamesMap = {};
  const logisticProvidersMap = {};
  const insuranceProvidersNameMap = {};

  const doctors = userSelectors.getDoctors(state);
  const rawDrivers = userSelectors.getDrivers(state) || [];
  const pharmacists = userSelectors.getPharmacists(state) || [];
  const doctorsNames = doctors.map((doctor) => doctor.fullName);
  const logisticProviders = selectors.getLogisticProviders(state);
  const insuranceProvidersList =
    insuranceProviderSelectors.getInsuranceProviderList(state);

  const lsKey = LS_ORDER_FILTERS;

  // TODO: maybe move map to state
  forEach(rawDrivers, (driver) => {
    if (driver.fullName && !driversFullNamesMap[driver.fullName]) {
      driversFullNamesMap[driver.fullName] = driver.id;
    }
  });

  forEach(pharmacists, (pharmacist) => {
    if (pharmacist.fullName && !pharmacistsMap[pharmacist.fullName]) {
      pharmacistsMap[pharmacist.fullName] = pharmacist.id;
    }
  });

  forEach(logisticProviders, (provider) => {
    if (provider.name && !logisticProvidersMap[provider.name]) {
      logisticProvidersMap[provider.name] = provider.id;
    }
  });

  forEach(insuranceProvidersList, (insuranceProvider) => {
    if (
      insuranceProvider.englishName &&
      !insuranceProvidersNameMap[insuranceProvider.englishName]
    ) {
      insuranceProvidersNameMap[insuranceProvider.englishName] =
        insuranceProvider.id;
    }
  });

  return {
    doctorsNames,
    pharmacistsMap,
    driversFullNamesMap,
    logisticProvidersMap,
    insuranceProvidersNameMap,
    lsKey,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateStatus: (id, newStatus) =>
    dispatch(
      orderActions.patchOrder(id, {
        [FIELDS.STATUS.name]: newStatus,
      })
    ),
  saveFilters: (filters) =>
    dispatch(appActions.saveFilters(filters, LS_ORDER_FILTERS)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
