import get from 'lodash/get';
import { createSelector } from 'reselect';

export const scheduleSelector = (state) => get(state, 'domain.schedule', {});

export const getDoctorSchedules = createSelector(scheduleSelector, (results) =>
  get(results, 'doctorSchedules')
);

export const getBookingSlots = createSelector(scheduleSelector, (results) =>
  get(results, 'bookingSlots')
);

export const getDoctorFreeScheduleSlots = createSelector(
  scheduleSelector,
  (results) => get(results, 'freeBookingSlots')
);

export const getDayBookingSlots = createSelector(scheduleSelector, (results) =>
  get(results, 'dayBookingSlots')
);

export const isFetchingSchedules = createSelector(scheduleSelector, (results) =>
  get(results, 'isFetching')
);

export const isFetchingDailyBookingAvailability = createSelector(
  scheduleSelector,
  (results) => get(results, 'isFetchingDailyBookingAvailability')
);
export const isFetchingDoctorFreeScheduleSlots = createSelector(
  scheduleSelector,
  (results) => get(results, 'isFetchingDoctorFreeScheduleSlots')
);
export const isRemovingRange = createSelector(scheduleSelector, (results) =>
  get(results, 'isRemovingRange')
);

export const isAddingRange = createSelector(scheduleSelector, (results) =>
  get(results, 'isAddingRange')
);
