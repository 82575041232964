import isEmpty from 'lodash/isEmpty';
import BiotechIcon from '@mui/icons-material/Biotech';
import React, { useMemo, useState, useEffect } from 'react';

import { Button, Tooltip } from 'design-system';
import ELabContext from 'presentation/lab/context/ELabContext';

import ELabDialog from '../ELabDialog';

/**
 * Flows:
 * 1. Create flow
 * 2. Update after create flow
 * 3. Update flow - amend or replace
 */
const ELab = ({
  user,
  loading,
  isUpdate,
  healthInfo,
  isFetchingHealth,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const [formData, setFormData] = useState({});

  const contextValue = useMemo(() => {
    return { formData, setFormData };
  }, [formData, setFormData]);

  useEffect(() => {
    if (!isEmpty(healthInfo)) {
      if (healthInfo.dob || healthInfo.gender) {
        setFormData({
          ...formData,
          ...(healthInfo.dob
            ? {
                dateOfBirth: new Date(healthInfo.dob),
              }
            : {}),
          ...(healthInfo.gender
            ? {
                gender: healthInfo.gender,
              }
            : {}),
        });
      }
    }
  }, [healthInfo]);

  return (
    <>
      <Tooltip
        placement="right"
        injectWrapper
        title="Request a lab test for the consultation's owner account"
      >
        <Button
          size="small"
          color="primary"
          variant="text"
          loading={(isFetchingHealth || loading) && { width: 120 }}
          startIcon={<BiotechIcon color="warning" />}
          onClick={() => setOpen(true)}
        >
          {isUpdate ? 'Update E-Lab Request' : 'E-Lab Request'}
        </Button>
      </Tooltip>
      <ELabContext.Provider value={contextValue}>
        <ELabDialog
          user={user}
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          isUpdate={isUpdate}
          {...rest}
        />
      </ELabContext.Provider>
    </>
  );
};

export default ELab;
