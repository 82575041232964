import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';

import { authUtils } from 'core/auth';
import { authSelectors } from 'domain/auth';
import { useDeviceSize } from 'design-system/hooks';
import {
  Box,
  Grid,
  Avatar,
  Select,
  Button,
  Popover,
  MenuItem,
  avatarUtils,
  InputAdornment,
} from 'design-system';
import { isAllowed } from 'core/permission';

const AppBar = ({ tenants, sx = {} }) => {
  const { isMobile } = useDeviceSize();
  const defaultTenant = useSelector(authSelectors.getSelectedTenant);

  const [tenantList, setTenantList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTenant, setSelectedTenant] = useState();
  const [isLoadingTenants, setLoadingTenants] = useState(true);

  useEffect(() => {
    if (!isEmpty(tenants)) {
      const tenantOfAll = {
        id: -1,
        name: 'All',
      };

      setLoadingTenants(false);
      setSelectedTenant(
        tenants.find((item) => item.id === defaultTenant) || tenantOfAll
      );
      setTenantList([tenantOfAll, ...tenants]);
    }
  }, [tenants, defaultTenant]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'app-bar-drop-down' : undefined;

  return (
    <Box
      component="nav"
      sx={{
        ...sx,
      }}
    >
      <Grid
        container
        alignItems="center"
        flexWrap="nowrap"
        justifyContent="space-between"
        sx={{ height: 64 }}
      >
        <Grid
          item
          sx={{
            width: {
              xs: 'auto',
              sm: 200,
            },
          }}
        >
          {isAllowed('common', 'tenantFilter') && (
            <Select
              fullWidth
              color="white"
              size="large"
              shape="round"
              variant="filled"
              value={selectedTenant}
              loading={isLoadingTenants}
              onChange={(event) => {
                const { value } = event.target;

                setSelectedTenant(value);
                localStorage.setItem('selectedTenant', Number(value?.id));
                window.location.reload();
              }}
              renderValue={(tenant) => {
                if (isMobile && tenant?.name) {
                  return tenant.name.shorten();
                }

                return tenant?.name;
              }}
              startAdornment={
                <InputAdornment position="start">
                  <LocationCityOutlinedIcon color="primary" />
                </InputAdornment>
              }
            >
              {tenantList.map((tenant) => (
                <MenuItem value={tenant} key={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Grid>
        <Grid item>
          <Avatar
            aria-describedby={id}
            onClick={handleClick}
            {...avatarUtils.textToAvatarProps({
              name: 'Alma Health',
            })}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Grid
              container
              sx={{
                width: 150,
                p: 1,
              }}
            >
              <Grid item>
                <Button
                  variant="text"
                  size="small"
                  color="error"
                  onClick={() => {
                    authUtils.logOutClean();
                    window.location.reload();
                  }}
                >
                  Log out
                </Button>
              </Grid>
            </Grid>
          </Popover>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppBar;
