import get from 'lodash/get';
import { createSelector } from 'reselect';

export const consultationSelector = (state) =>
  get(state, 'domain.consultation', {});

export const getConsultation = createSelector(consultationSelector, (results) =>
  get(results, 'view')
);

export const getConsultationMembers = createSelector(
  getConsultation,
  (results) => get(results, 'consultationPatients')
);

export const isFetchingConsultation = createSelector(
  consultationSelector,
  (results) => get(results, 'isFetching')
);

export const isSubmittingConsultation = createSelector(
  consultationSelector,
  (results) => get(results, 'isSubmitting')
);

export const isDeletingConsultationMember = createSelector(
  consultationSelector,
  (results) => get(results, 'isDeletingConsultationMember')
);

export const isSubmittingConsultationMembers = createSelector(
  consultationSelector,
  (results) => get(results, 'isSubmittingConsultationMembers')
);

export const isUpdatingConsultationPatient = createSelector(
  consultationSelector,
  (results) => get(results, 'isUpdatingConsultationPatient')
);

export const isSubmittingConsultationPatientOutcome = createSelector(
  consultationSelector,
  (results) => get(results, 'isSubmittingConsultationPatientOutcome')
);

export const isUpdatingConsultationPatientOutcome = createSelector(
  consultationSelector,
  (results) => get(results, 'isUpdatingConsultationPatientOutcome')
);

export const isDeletingConsultationPatientOutcome = createSelector(
  consultationSelector,
  (results) => get(results, 'isDeletingConsultationPatientOutcome')
);

export const isSubmittingConsultationPrescriptions = createSelector(
  consultationSelector,
  (results) => get(results, 'isSubmittingConsultationPrescriptions')
);
export const isSubmittingConsultationLabTests = createSelector(
  consultationSelector,
  (results) => get(results, 'isSubmittingConsultationLabTests')
);
