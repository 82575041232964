import { PACKAGE_NAME as DOMAIN } from 'domain/constants/general';
import { PACKAGE_NAME as USER } from '../constants';
import { PACKAGE_NAME as MEMBER } from './constants';

export const FETCH_MEMBERS = `${DOMAIN}/${USER}/${MEMBER}/FETCH_MEMBERS`;
export const MEMBERS_RECEIVED = `${DOMAIN}/${USER}/${MEMBER}/MEMBERS_RECEIVED`;
export const SET_FETCHING_MEMBERS = `${DOMAIN}/${USER}/${MEMBER}/SET_FETCHING_MEMBERS`;

export const FETCH_BASIC_MEMBERS = `${DOMAIN}/${USER}/${MEMBER}/FETCH_BASIC_MEMBERS`;
export const BASIC_MEMBERS_RECEIVED = `${DOMAIN}/${USER}/${MEMBER}/BASIC_MEMBERS_RECEIVED`;
export const SET_FETCHING_BASIC_MEMBERS = `${DOMAIN}/${USER}/${MEMBER}/SET_FETCHING_BASIC_MEMBERS`;

export const FETCH_MEMBERS_HEALTH_PROFILES = `${DOMAIN}/${USER}/${MEMBER}/FETCH_MEMBERS_HEALTH_PROFILES`;
export const MEMBER_HEALTH_PROFILE_UPDATED = `${DOMAIN}/${USER}/${MEMBER}/MEMBER_HEALTH_PROFILE_UPDATED`;
export const MEMBERS_HEALTH_PROFILES_RECEIVED = `${DOMAIN}/${USER}/${MEMBER}/MEMBERS_HEALTH_PROFILES_RECEIVED`;
export const SET_FETCHING_MEMBERS_HEALTH_PROFILES = `${DOMAIN}/${USER}/${MEMBER}/SET_FETCHING_MEMBERS_HEALTH_PROFILES`;

export const CREATE_MEMBER = `${DOMAIN}/${USER}/${MEMBER}/CREATE_MEMBER`;
export const MEMBER_CREATED = `${DOMAIN}/${USER}/${MEMBER}/MEMBER_CREATED`;
export const SET_SUBMITTING_MEMBER = `${DOMAIN}/${USER}/${MEMBER}/SET_SUBMITTING_MEMBER`;

export const DELETE_MEMBER = `${DOMAIN}/${USER}/${MEMBER}/DELETE_MEMBER`;
export const MEMBER_DELETED = `${DOMAIN}/${USER}/${MEMBER}/MEMBER_DELETED`;
export const SET_DELETING_MEMBER = `${DOMAIN}/${USER}/${MEMBER}/SET_DELETING_MEMBER`;

export const UNDELETE_MEMBER = `${DOMAIN}/${USER}/${MEMBER}/UNDELETE_MEMBER`;
export const MEMBER_UPDATED = `${DOMAIN}/${USER}/${MEMBER}/MEMBER_UPDATED`;

export const UPDATE_MEMBER_USER_PROFILE = `${DOMAIN}/${USER}/${MEMBER}/UPDATE_MEMBER_USER_PROFILE`;
export const UPDATE_MEMBER_HEALTH_PROFILE = `${DOMAIN}/${USER}/${MEMBER}/UPDATE_MEMBER_HEALTH_PROFILE`;
