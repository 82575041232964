import get from 'lodash/get';
import { all, call, takeLatest, put } from 'redux-saga/effects';

import httpClient from 'core/httpClient';

import * as actions from './actions';
import * as types from './actionTypes';
import {
  ENDPOINT,
  CREATE_SUBSCRIPTION_ENDPOINT,
  LIST_SUBSCRIPTION_PLANS_ENDPOINT,
} from './constants';

export function* updateSubscription({ id, payload }) {
  try {
    const response = yield call(
      httpClient.patch,
      [ENDPOINT, id].join('/'),
      payload
    );

    yield put(actions.subscriptionUpdateResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.subscriptionUpdateResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* cancelSubscription({ userId }) {
  try {
    const response = yield call(
      httpClient.delete,
      [CREATE_SUBSCRIPTION_ENDPOINT, userId].join('/')
    );
    yield put(actions.subscriptionCancelResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.subscriptionCancelResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* getSubscription({ userId }) {
  const response = yield call(httpClient.get, `${ENDPOINT}?userId=${userId}`);
  yield put(actions.subscriptionReceived(response.data));
}

export function* createSubscription({ userId, payload }) {
  try {
    const response = yield call(
      httpClient.post,
      `${CREATE_SUBSCRIPTION_ENDPOINT}/${userId}`,
      payload
    );
    if (response.status) {
      yield put(actions.subscriptionCreationResponded(true, response.data));
    }
  } catch (e) {
    yield put(
      actions.subscriptionCreationResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* fetchSubscriptionPlans() {
  const response = yield call(httpClient.get, LIST_SUBSCRIPTION_PLANS_ENDPOINT);
  yield put(actions.subscriptionPlansReceived(response.data));
}

export default function* subscriptionModelSaga() {
  yield all([
    takeLatest(types.UPDATE_SUBSCRIPTION, updateSubscription),
    takeLatest(types.CANCEL_SUBSCRIPTION, cancelSubscription),
    takeLatest(types.GET_SUBSCRIPTION, getSubscription),
    takeLatest(types.CREATE_SUBSCRIPTION, createSubscription),
    takeLatest(types.FETCH_SUBSCRIPTION_PLANS, fetchSubscriptionPlans),
  ]);
}
