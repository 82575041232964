import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './actionTypes';
import * as constants from './constants';
import httpClient from '../../core/httpClient';

export function* fetchConsultationTypeById({ id }) {
  const consultationTypeEndpoint = [constants.ENDPOINT, id].join('/');
  const response = yield call(httpClient.get, consultationTypeEndpoint);
  yield put(actions.consultationTypeReceived(response.data, id));
}

export function* fetchConsultationTypes() {
  const response = yield call(httpClient.get, constants.ENDPOINT);
  yield put(actions.consultationTypesReceived(response.data.data));
}

export function* updateConsultationType({ id, payload }) {
  const endpoint = [constants.ENDPOINT, id].join('/');
  let response;
  try {
    response = yield call(httpClient.patch, endpoint, payload);
    if (response.status) {
      yield put(actions.consultationTypeUpdatedResponded(true));
    }
  } catch (e) {
    yield put(
      actions.consultationTypeUpdatedResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* createConsultationType({ payload }) {
  let response;
  try {
    response = yield call(httpClient.post, constants.ENDPOINT, payload);
    if (response.status) {
      yield put(actions.consultationTypeCreateResponded(true, response));
    }
  } catch (e) {
    yield put(
      actions.consultationTypeCreateResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchConsultationTypeConfigurations({ consultationTypeId }) {
  const consultationTypeConfigurationsEndpoint = [
    constants.CONFIGURATION_ENDPOINT,
    'consultation-type',
    consultationTypeId,
  ].join('/');
  const response = yield call(
    httpClient.get,
    consultationTypeConfigurationsEndpoint
  );

  yield put(
    actions.consultationTypeConfigurationsReceived(
      response.data,
      consultationTypeId
    )
  );
}

export function* addConsultationTypeConfiguration({ payload }) {
  let response;
  try {
    response = yield call(
      httpClient.post,
      constants.CONFIGURATION_ENDPOINT,
      payload
    );
    if (response.status) {
      yield put(actions.consultationTypeConfigurationAddResponded(true));
    }
  } catch (e) {
    yield put(
      actions.consultationTypeConfigurationAddResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* updateConsultationTypeConfiguration({ id, payload }) {
  let response;
  const endpoint = [constants.CONFIGURATION_ENDPOINT, id].join('/');

  try {
    response = yield call(httpClient.patch, endpoint, payload);
    if (response.status) {
      yield put(actions.consultationTypeConfigurationUpdateResponded(true));
    }
  } catch (e) {
    yield put(
      actions.consultationTypeConfigurationUpdateResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* deleteConsultationTypeConfiguration({ id }) {
  let response;
  const endpoint = [constants.CONFIGURATION_ENDPOINT, id].join('/');

  try {
    response = yield call(httpClient.delete, endpoint);
    if (response.status) {
      yield put(actions.consultationTypeConfigurationDeleteResponded(true));
    }
  } catch (e) {
    yield put(
      actions.consultationTypeConfigurationDeleteResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export default function* consultationModelSaga() {
  yield all([
    takeLatest(types.FETCH_CONSULTATION_TYPE_BY_ID, fetchConsultationTypeById),
    takeLatest(types.FETCH_CONSULTATION_TYPES, fetchConsultationTypes),
    takeLatest(
      types.FETCH_CONSULTATION_TYPE_CONFIGURATIONS,
      fetchConsultationTypeConfigurations
    ),
    takeLatest(types.CREATE_CONSULTATION_TYPE, createConsultationType),
    takeLatest(types.UPDATE_CONSULTATION_TYPE, updateConsultationType),
    takeLatest(
      types.ADD_CONSULTATION_TYPE_CONFIGURATION,
      addConsultationTypeConfiguration
    ),
    takeLatest(
      types.UPDATE_CONSULTATION_TYPE_CONFIGURATION,
      updateConsultationTypeConfiguration
    ),
    takeLatest(
      types.DELETE_CONSULTATION_TYPE_CONFIGURATION,
      deleteConsultationTypeConfiguration
    ),
  ]);
}
