import get from 'lodash/get';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import httpClient from 'core/httpClient';
import {
  getIntegrationEndpoint,
  getChangeHistoryEndpoint,
} from 'core/endpoint';

import * as actions from './actions';
import * as types from './actionTypes';
import {
  ENDPOINT,
  LINE_ITEM_ENDPOINT,
  ORDER_HISTORY_ENDPOINT,
  PINNED_ORDERS_ENDPOINT,
  DELIVERY_ADDRESS_ENDPOINT,
  LOGISTIC_PROVIDER_ENDPOINT,
  LINE_ITEMS_HISTORY_ENDPOINT,
  SHIPMENT_ENDPOINT,
  SHIPMENT_LABEL_ENDPOINT,
  SHIPMENTS,
} from './constants';

export function* createPrescriptions({ payload }) {
  const response = yield call(httpClient.post, ENDPOINT, payload);

  if (response?.data) {
    yield put(actions.prescriptionsCreated(response?.data));
  }
}

export function* fetchShipmentLabel({ orderId }) {
  try {
    if (orderId) {
      const response = yield call(
        httpClient.get,
        `${SHIPMENT_LABEL_ENDPOINT}/${orderId}`,
        {
          baseURL: getIntegrationEndpoint(),
          responseType: 'blob',
        }
      );

      if (response?.data) {
        yield put(actions.orderShipmentLabelResponded(response.data));
      }
    }
  } catch (_e) {
    yield put(actions.orderUpdateResponded(false));
  }
}

export function* fetchOrder({ id }) {
  let response;
  try {
    response = yield call(httpClient.get, [ENDPOINT, id].join('/'));

    yield put(actions.orderFetchResponded(true, response.data));
  } catch (e) {
    yield put(
      actions.orderFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* createOrder({ payload }) {
  let response;

  try {
    response = yield call(httpClient.post, ENDPOINT, payload);
    yield put(actions.orderCreationResponded(true, response.data, undefined));
  } catch (e) {
    yield put(
      actions.orderCreationResponded(
        false,
        undefined,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* patchOrder({ id, payload }) {
  try {
    yield call(httpClient.patch, [ENDPOINT, id].join('/'), payload);
    yield put(actions.orderUpdateResponded(true));
  } catch (_e) {
    yield put(actions.orderUpdateResponded(false));
  }
}

export function* fetchPinnedOrders() {
  let response;

  try {
    response = yield call(httpClient.get, PINNED_ORDERS_ENDPOINT);
    yield put(actions.pinnedOrdersFetchResponded(true, response?.data));
  } catch (e) {
    yield put(
      actions.pinnedOrdersFetchResponded(
        false,
        get(
          e,
          'response.data.message',
          'An error occurred. Please refresh the page'
        )
      )
    );
  }
}

export function* fetchOrderHistory({ id }) {
  let response;

  try {
    response = yield call(httpClient.get, ORDER_HISTORY_ENDPOINT(id), {
      baseURL: getChangeHistoryEndpoint(),
    });

    yield put(actions.orderHistoryFetchResponded(true, response?.data));
  } catch (e) {
    yield put(
      actions.orderHistoryFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* fetchLineItemsHistory({ orderId }) {
  let response;

  try {
    response = yield call(
      httpClient.get,
      LINE_ITEMS_HISTORY_ENDPOINT(orderId),
      {
        baseURL: getChangeHistoryEndpoint(),
      }
    );

    yield put(actions.lineItemsHistoryFetchResponded(true, response?.data));
  } catch (e) {
    yield put(
      actions.lineItemsHistoryFetchResponded(
        false,
        get(e, 'response.data.message')
      )
    );
  }
}

export function* addLineItem({ payload }) {
  const response = yield call(httpClient.post, LINE_ITEM_ENDPOINT, payload);
  yield put(actions.lineItemAdded(response.data));
}

export function* updateLineItem({ id, payload }) {
  const response = yield call(
    httpClient.patch,
    [LINE_ITEM_ENDPOINT, id].join('/'),
    payload
  );
  yield put(actions.lineItemUpdated(response.data, id));
}

export function* deleteLineItem({ id }) {
  yield call(httpClient.delete, [LINE_ITEM_ENDPOINT, id].join('/'));
  yield put(actions.lineItemDeleted(id));
}

export function* updateDeliveryAddress({ payload, id }) {
  yield call(
    httpClient.patch,
    [DELIVERY_ADDRESS_ENDPOINT, id].join('/'),
    payload
  );
  yield put(actions.deliveryAddressUpdated());
}

export function* fetchLogisticProviders() {
  const response = yield call(httpClient.get, LOGISTIC_PROVIDER_ENDPOINT, {
    baseURL: getIntegrationEndpoint(),
  });
  yield put(actions.logisticProvidersResponded(response.data));
}

export function* fetchShipment({ orderId }) {
  try {
    if (orderId) {
      const response = yield call(
        httpClient.get,
        `${SHIPMENT_ENDPOINT}/${orderId}`,
        {
          baseURL: getIntegrationEndpoint(),
        }
      );
      if (response?.data) {
        yield put(actions.shipmentFetchResponded(true, response.data));
      }
    }
  } catch (e) {
    yield put(
      actions.shipmentFetchResponded(false, get(e, 'response.data.message'))
    );
  }
}

export function* addDeliveryAddress({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      DELIVERY_ADDRESS_ENDPOINT,
      payload
    );
    if (response?.data) {
      yield put(actions.deliveryAddressAdded(response.data));
    }
  } catch (e) {
    yield put(actions.orderUpdateResponded(false));
  }
}

export function* createLogisticProviderShipment({ payload }) {
  try {
    const response = yield call(
      httpClient.post,
      [SHIPMENTS].join('/'),
      payload,
      {
        baseURL: getIntegrationEndpoint(),
      }
    );
    if (response?.data) {
      yield put(
        actions.logisticProviderShipmentCreationResponded(true, response.data)
      );
    }
    // Extract the address payload from the shipment payload
    yield call(addDeliveryAddress, {
      payload: {
        orderId: payload.shipmentId,
        latitude: 0,
        longitude: 0,
        ...payload.addressDetails,
      },
    });
  } catch (e) {
    yield put(actions.logisticProviderShipmentCreationResponded(false));
  }
}

export default function* orderModelSaga() {
  yield all([
    takeLatest(types.FETCH_ORDER, fetchOrder),
    takeLatest(types.PATCH_ORDER, patchOrder),
    takeLatest(types.CREATE_ORDER, createOrder),
    takeLatest(types.ADD_LINE_ITEM, addLineItem),
    takeLatest(types.UPDATE_LINE_ITEM, updateLineItem),
    takeLatest(types.DELETE_LINE_ITEM, deleteLineItem),
    takeLatest(types.FETCH_ORDER_HISTORY, fetchOrderHistory),
    takeLatest(types.FETCH_PINNED_ORDERS, fetchPinnedOrders),
    takeLatest(types.ADD_DELIVERY_ADDRESS, addDeliveryAddress),
    takeLatest(types.CREATE_PRESCRIPTIONS, createPrescriptions),
    takeLatest(types.UPDATE_DELIVERY_ADDRESS, updateDeliveryAddress),
    takeLatest(types.FETCH_LINE_ITEMS_HISTORY, fetchLineItemsHistory),
    takeLatest(types.FETCH_LOGISTIC_PROVIDERS, fetchLogisticProviders),
    takeLatest(types.FETCH_SHIPMENT_LABEL, fetchShipmentLabel),
    takeLatest(types.FETCH_SHIPMENT, fetchShipment),

    takeLatest(
      types.CREATE_LOGISTIC_PROVIDER_SHIPMENT,
      createLogisticProviderShipment
    ),
  ]);
}
