import * as types from './actionTypes';

const initialState = {
  view: {},
  isFetching: false,
};

const anonymousUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case types.ANONYMOUS_USER_RECEIVED: {
      const anonymousUser = action.anonymousUser || {};
      return {
        ...state,
        view: { ...anonymousUser },
      };
    }
    default:
      return state;
  }
};

export default anonymousUserReducer;
