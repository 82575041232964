import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';

import FileUpload from 'core/file/FileUpload';
import { Grid, FormField } from 'design-system';
import { isAllowed } from 'core/permission';

const LatestDietPlan = ({
  // Variables
  userId,
  isFetching,
  isSubmitting,
  latestDietPlan,

  // Functions
  clear,
  addDietPlan,
  getLatestDietPlan,
}) => {
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (userId) {
      getLatestDietPlan(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (!isEmpty(latestDietPlan)) {
      setDocuments([
        {
          id: latestDietPlan.documentFileId,
        },
      ]);
    }
  }, [latestDietPlan]);

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);

  return (
    <Grid container column rowSpacing={2}>
      <Grid item>
        <FormField
          label={latestDietPlan ? 'LATEST DIET PLAN' : 'UPLOAD A DIET PLAN'}
          labelWidth={4}
          field={
            <FileUpload
              multiple
              userId={userId}
              files={documents}
              loading={(isFetching || isSubmitting) && { count: 1 }}
              {...(latestDietPlan
                ? {
                    placeholder: 'Upload a new diet plan',
                  }
                : {})}
              onChange={(files, isActualChange) => {
                const newFile = files.last();
                setDocuments([newFile]);

                if (isActualChange && newFile) {
                  addDietPlan({
                    userId,
                    documentFileId: newFile.id,
                  });
                }
              }}
              config={{
                readonly: !isAllowed(
                  'consultationView',
                  'dietPlanSection',
                  'edit'
                ),
              }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default LatestDietPlan;
