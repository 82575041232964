import { all, put, take, select, takeLatest } from 'redux-saga/effects';

import { Message } from 'design-system';
import { meetingModelActions, meetingModelActionTypes } from 'model/meeting';

import * as actions from './actions';
import * as types from './actionTypes';
import * as selectors from './selectors';

const currentState = (state) => state;

export function* getDoctorMeetings({ doctorId }) {
  yield put(actions.setFetchingMeetings(true));
  yield put(meetingModelActions.fetchDoctorMeetings(doctorId));

  const { ok, response } = yield take(
    meetingModelActionTypes.DOCTOR_MEETINGS_FETCH_RESPONDED
  );

  if (ok) {
    yield put(actions.meetingsReceived(response));
  }

  yield put(actions.setFetchingMeetings(false));
}

export function* createMeeting({ payload, callback }) {
  yield put(actions.setSubmittingMeeting(true));
  yield put(meetingModelActions.createMeeting(payload));

  const { ok, response } = yield take(
    meetingModelActionTypes.MEETING_CREATION_RESPONDED
  );

  if (ok) {
    yield put(actions.meetingCreated(response));
    Message.success('Meeting account has been added successfully');
  }

  yield put(actions.setSubmittingMeeting(false));

  if (callback) {
    callback();
  }
}

export function* updateMeeting({ id, payload, callback }) {
  yield put(actions.setSubmittingMeeting(true));
  yield put(meetingModelActions.updateMeeting(id, payload));

  const { ok, response } = yield take(
    meetingModelActionTypes.MEETING_UPDATE_RESPONDED
  );

  if (ok) {
    yield put(actions.meetingUpdated(response));
    Message.success('Meeting account has been updated successfully');
  }

  yield put(actions.setSubmittingMeeting(false));

  if (callback) {
    callback();
  }
}

export function* deleteMeeting({ meeting, callback }) {
  const state = yield select(currentState);
  const currentMeetings = selectors.getMeetings(state);

  yield put(actions.setDeletingMeeting(true));
  // Preemptively remove meeting from state
  yield put(actions.meetingDeleted(meeting));
  yield put(meetingModelActions.deleteMeeting(meeting.id));

  const { ok } = yield take(meetingModelActionTypes.MEETING_DELETE_RESPONDED);

  if (ok) {
    Message.info('Meeting account has been deleted successfully');
  } else {
    // Revert to previous state
    yield put(actions.meetingsReceived(currentMeetings));
  }

  yield put(actions.setDeletingMeeting(false));

  if (callback) {
    callback();
  }
}

export default function* meetingSaga() {
  yield all([
    takeLatest(types.GET_DOCTOR_MEETINGS, getDoctorMeetings),
    takeLatest(types.CREATE_MEETING, createMeeting),
    takeLatest(types.UPDATE_MEETING, updateMeeting),
    takeLatest(types.DELETE_MEETING, deleteMeeting),
  ]);
}
